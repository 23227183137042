import { useState } from 'react';
import { notification, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import api from '@shared/api';
import type { DefaultPhotoUploadType } from '@components/shared/Admin/MultimediaUploader';
import { AdminListingPhoto, Room } from '@root/types';
import CollapsableMediaPhotosSection from '@components/shared/Admin/EditPhotos/CollapsableMediaPhotosSection';
import MediaPhotosSection from '@components/shared/Admin/EditPhotos/MediaPhotosSection';
import { CustomIcon } from '@components/shared';
import css from './ListingMediaPhotoSection.module.less';
import AreaSelector from '../AreaSelector';

export type Props = {
  fetchListing: () => void;
  photos: AdminListingPhoto[];
  archivedPhotos: AdminListingPhoto[];
  rooms: Room[];
  listingId: string;
};

const ListingMediaPhotoSection = ({
  photos,
  fetchListing,
  listingId,
  rooms,
  archivedPhotos,
}: Props) => {
  const { t } = useTranslation('admin');

  const [selectedPublicRows, setSelectedPublicRows] = useState([]);
  const [selectedHiddenRows, setSelectedHiddenRows] = useState([]);
  const allSelectedRows = [...selectedPublicRows, ...selectedHiddenRows];

  const clearSelectedPhotos = () => {
    setSelectedPublicRows([]);
    setSelectedHiddenRows([]);
  };

  const handleUploadPhotos = (uploadedFiles: DefaultPhotoUploadType[]) =>
    api.post(routes.api.admin.listingPhotosCreate(listingId), {
      photos: uploadedFiles,
    });

  const handleUpdatePhotoPosition = async (photoIds: string[]) => {
    const response = await api.put(routes.api.admin.listingPhotosOrder(listingId), {
      photo_ids: photoIds,
    });

    if (response.ok) {
      notification.success({ message: t('common.photos.orderSaveSuccess') });
      fetchListing();
    } else {
      notification.error({ message: t('common.photos.orderSaveFailure') });
    }
  };

  const handleDeletePhoto = async (photoId: string) => {
    const response = await api.delete(routes.api.admin.listingPhotoDelete(listingId, photoId));
    const json = await response.json();

    if (response.ok) {
      fetchListing();
      notification.success({ message: json.message });
      clearSelectedPhotos();
    } else {
      notification.error({ message: json.errors[0].message });
    }
  };

  const handleMakePhotosPublic = async photoIds => {
    const response = await api.put(routes.api.admin.listingPhotosArchiveToggle(listingId), {
      photo_ids: photoIds,
      archive: false,
    });

    if (response.ok) {
      notification.success({
        message: t('media.makePhotosPublicSuccess', { count: photoIds.length }),
      });

      fetchListing();
      return Promise.resolve();
    }
    notification.error({
      message: t('media.makePhotosPublicFailure', { count: photoIds.length }),
    });
    return Promise.reject();
  };

  const handleHidePhotos = async photoIds => {
    const response = await api.put(routes.api.admin.listingPhotosArchiveToggle(listingId), {
      photo_ids: photoIds,
      archive: true,
    });

    if (response.ok) {
      notification.success({
        message: t('media.hidePhotosSuccess', { count: photoIds.length }),
      });
      fetchListing();
      return Promise.resolve();
    }
    notification.error({
      message: t('media.hidePhotosFailure', { count: photoIds.length }),
    });
    return Promise.reject();
  };

  return (
    <>
      <div className="pt-5">
        <MediaPhotosSection
          title={t('media.listingPhotosTitle')}
          description={t('media.listingPhotosDescription')}
          photos={photos}
          fetchRecord={fetchListing}
          handleHidePhotos={handleHidePhotos}
          onPhotoUpload={handleUploadPhotos}
          onUpdatePhotoPosition={handleUpdatePhotoPosition}
          onDeletePhoto={handleDeletePhoto}
          setSelectedRows={setSelectedPublicRows}
          selectedRows={selectedPublicRows}
          selectedDownloadRows={!allSelectedRows.length}
          downloadUrl={routes.api.admin.listingPhotosZipDownload(listingId, allSelectedRows)}
          downloadButtonOnClick={clearSelectedPhotos}
          uploadSizeLimitInMb={20}
          additionalColumn={{
            title: (
              <>
                <span>{t('media.mediaManagement.columns.area')}</span>
                <Tooltip
                  overlayClassName={css.areaOverlay}
                  trigger={['hover', 'click']}
                  placement="top"
                  title={t('media.mediaManagement.areaInfo')}
                >
                  <span data-testid="area-tooltip" className={css.areaTooltip}>
                    <CustomIcon type="info" />
                  </span>
                </Tooltip>
              </>
            ),
            render: (photo: AdminListingPhoto) => (
              <AreaSelector
                areas={rooms}
                listingId={listingId}
                photo={photo}
                fetchListing={fetchListing}
              />
            ),
          }}
        />
      </div>
      <div className="pt-5">
        <CollapsableMediaPhotosSection
          title={t('media.listingPhotosTitleArchived')}
          description={t('media.listingArchivedDescription')}
          photos={archivedPhotos}
          makePhotosPublic={handleMakePhotosPublic}
          onDeletePhoto={handleDeletePhoto}
          setSelectedRows={setSelectedHiddenRows}
          selectedRows={selectedHiddenRows}
        />
      </div>
    </>
  );
};

export default ListingMediaPhotoSection;
