import api from '@shared/api';
import routes from '@root/routes';
import { Tourbook, ErrorResponse } from '@root/types';

type SubmissionValues = {
  name: string;
  description?: string;
};

type ReceiveUpdatedTourbookAction = {
  type: 'RECEIVE_UPDATED_TOURBOOK';
  payload: {
    tourbook: Tourbook;
  };
};

export const receiveUpdatedTourbook = (tourbook: Tourbook): ReceiveUpdatedTourbookAction => ({
  type: 'RECEIVE_UPDATED_TOURBOOK',
  payload: {
    tourbook,
  },
});

type ErrorUpdatingTourbookAction = {
  type: 'ERROR_UPDATING_TOURBOOK';
  payload: SubmissionValues;
};

export const errorUpdatingTourbook = (values: SubmissionValues): ErrorUpdatingTourbookAction => ({
  type: 'ERROR_UPDATING_TOURBOOK',
  payload: values,
});

export const updateTourbook =
  (
    id: string,
    values: SubmissionValues,
    { onSuccess, onError }: { onSuccess: () => void; onError: (e: ErrorResponse) => void } = {
      onSuccess: () => {},
      onError: () => {},
    },
  ) =>
  async dispatch => {
    await api.put(routes.api.tourbook(id), values).then(async response => {
      if (response.ok) {
        const tourbook: Tourbook = await response.json();
        dispatch(receiveUpdatedTourbook(tourbook));
        onSuccess();
      } else {
        const errorResponse: ErrorResponse = await response.json();
        dispatch(errorUpdatingTourbook(values));
        onError(errorResponse);
      }
    });
  };

export type Actions = ReceiveUpdatedTourbookAction | ErrorUpdatingTourbookAction;
