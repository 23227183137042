import { Route, Switch } from 'react-router-dom';
import routes from '@root/routes';
import IndexPage from './IndexPage';
import CreatePage from './CreatePage';
import EditPage from './EditPage';

const Tenants = () => (
  <Switch>
    <Route exact path={routes.admin.companies} component={IndexPage} />
    <Route path={routes.admin.companyCreate} component={CreatePage} />
    <Route path={routes.admin.company(':id')} component={EditPage} />
  </Switch>
);

export default Tenants;
