import { createContext, useContext } from 'react';
import * as React from 'react';
import { Breakpoint } from '@root/types';
import { useCustomBreakpoint, breakpoints } from '.';

type BreakpointInfo = {
  breakpoint: Breakpoint;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const BreakpointContext = createContext<BreakpointInfo | undefined>(undefined);

type BreakpointContextProps = { children: React.ReactNode };
export const BreakpointProvider = ({ children }: BreakpointContextProps) => {
  const breakpoint = useCustomBreakpoint() as Breakpoint;
  return (
    <BreakpointContext.Provider
      value={{
        breakpoint,
        isMobile: breakpoint === breakpoints.MOBILE,
        isTablet: breakpoint === breakpoints.TABLET,
        isDesktop: breakpoint === breakpoints.DESKTOP,
      }}
    >
      {children}
    </BreakpointContext.Provider>
  );
};

export const useBreakpoint = (): BreakpointInfo => {
  const context = useContext(BreakpointContext);
  if (context === undefined) {
    throw new Error('useBreakpoint must be used within a BreakpointProvider');
  }
  return context;
};
