import { AdminBuilding } from '@root/types';
import { Form, Select, NumberInput, LengthInput } from '@components/shared/forms';
import * as yup from 'yup';
import { AdminFormSubmitter } from '@components/shared';

type Props = {
  building: AdminBuilding;
  updateIndustryAttributes: (attrs: Object) => Promise<boolean>;
};

const IndustrialAttributes = ({ building, updateIndustryAttributes }: Props) => {
  const validationSchema = yup.object({
    dockHighDoors: yup.number(),
    gradeLevelDoors: yup.number(),
    driveInDoors: yup.number(),
    fireSprinklers: yup.string(),
    minimumIndoorClearHeightMagnitude: yup.number(),
    minimumIndoorClearHeightUnits: yup.string(),
    percentOffice: yup.number().max(100),
    parkingSpaces: yup.number(),
  });

  return (
    <Form
      id="buildingIndustrialAttributesForm"
      initialValues={{
        dockHighDoors: building.dockHighDoors || '',
        gradeLevelDoors: building.gradeLevelDoors || '',
        driveInDoors: building.driveInDoors || '',
        fireSprinklers: building.fireSprinklers || '',
        minimumIndoorClearHeightMagnitude: building.minimumIndoorClearHeight?.magnitude || '',
        minimumIndoorClearHeightUnit: 'Inches',
        parkingSpaces: building.parkingSpaces || '',
        percentOffice: building.percentOffice || '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, helpers) => {
        const successful = await updateIndustryAttributes({
          ...values,
          ...(values.minimumIndoorClearHeightMagnitude
            ? {
                minimumIndoorClearHeight: {
                  magnitude: values.minimumIndoorClearHeightMagnitude,
                  unit: values.minimumIndoorClearHeightUnit,
                },
              }
            : {}),
        });

        if (successful) {
          helpers.resetForm({ values });
        }
      }}
    >
      <NumberInput labelText="Dock High Doors" name="dockHighDoors" />
      <NumberInput labelText="Grade Level Doors" name="gradeLevelDoors" />
      <NumberInput labelText="Drive In Doors" name="driveInDoors" />
      <Select
        labelText="Fire Sprinklers"
        name="fireSprinklers"
        options={[
          { value: '.33/3000', label: '.33/3000' },
          { value: '.45/3000', label: '.45/3000' },
          { value: '.60/3000', label: '.60/3000' },
          { value: 'ESFR K-14', label: 'ESFR K-14' },
          { value: 'ESFR K-17', label: 'ESFR K-17' },
          { value: 'ESFR Other', label: 'ESFR Other' },
          { value: 'Wet', label: 'Wet' },
          { value: 'Dry', label: 'Dry' },
          { value: 'Ordinary Hazard', label: 'Ordinary Hazard' },
          { value: 'Other', label: 'Other' },
        ]}
      />
      <LengthInput
        labelText="Minimum indoor clear height"
        magnitudeName="minimumIndoorClearHeightMagnitude"
        unitsName="minimumIndoorClearHeightUnit"
      />
      <NumberInput labelText="Percent Office" name="percentOffice" />
      <NumberInput labelText="Parking Spaces" name="parkingSpaces" />
      <AdminFormSubmitter />
    </Form>
  );
};

export default IndustrialAttributes;
