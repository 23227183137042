import { ReactNode, useRef, useState, useContext } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cn from 'classnames';

import SidebarMenu from '../SidebarMenu';
import Navigation from '../Navigation';
import s from '../App.module.less';
import AdminContext from '../AdminContext';

const useFixedHeader = ({ className }: { className?: string }) => {
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(true);
  const scrollElement = useRef();
  const boundingElement = useRef();

  useScrollPosition(
    ({ currPos }) => {
      setIsHeaderCollapsed(currPos.y < 100);
    },
    [],
    scrollElement,
    false,
    100,
    boundingElement,
  );

  const fixedPageHeaderProps = {
    isCollapsed: isHeaderCollapsed,
    className,
  };

  return {
    FixedPageHeader: FixedHeader,
    fixedPageHeaderProps,
    scrollElement,
    boundingElement,
  };
};

const FixedHeader = ({ children, isCollapsed, className }) => {
  return (
    <div
      className={cn(
        'sticky top-0 z-10 bg-background-primary p-1 shadow-lg',
        isCollapsed && 'hidden',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default function Layout({
  children,
  fixedHeaderContents,
  fixedHeaderClassName,
}: {
  children: ReactNode;
  fixedHeaderContents?: ReactNode;
  fixedHeaderClassName?: string;
}) {
  const { currentUser, numRequestedListings } = useContext(AdminContext);

  const { FixedPageHeader, fixedPageHeaderProps, scrollElement, boundingElement } = useFixedHeader({
    className: fixedHeaderClassName,
  });

  return (
    <div style={{ height: '100%' }}>
      <div className={s.header}>
        <Navigation email={currentUser!.email} marketSlug={currentUser!.marketSlug} />
      </div>
      <div className="flex h-[calc(100%_-_48px)]">
        <div className="min-w-28 border-r-[1px] border-solid border-general-neutral-tertiary bg-white">
          <SidebarMenu requestedListingsCount={numRequestedListings} />
        </div>
        {/* @ts-expect-error */}
        <div id="scrollElem" className="w-full overflow-auto" ref={boundingElement}>
          {fixedHeaderContents ? (
            <FixedPageHeader {...fixedPageHeaderProps}>{fixedHeaderContents}</FixedPageHeader>
          ) : null}
          {/* @ts-expect-error */}
          <div className="p-2" ref={scrollElement}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
