import * as React from 'react';
import UtmAwareLink from '@components/shared/UtmAwareLink/UtmAwareLink';
import routes from '@root/routes';
import type { SpaceProvider } from '@root/types';
import cn from 'classnames';
import useAltText from '@root/shared/useAltText';
import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';

// Even thought this component uses the terminology "Building Owner"
// We kept the name of the component as SpaceProvider
// the final terminology is still not settled
// and the user facing terminology changes more often
// than we want to keep renaming code. When we feel like
// we are settled on a terminology, we can rename this component.

type LinkToSpaceProviderProps = {
  onClick: React.MouseEventHandler;
  slug: string | null;
  children: React.ReactNode;
};

const LinkToSpaceProvider = ({ slug, children, onClick }: LinkToSpaceProviderProps) =>
  slug ? (
    <UtmAwareLink
      className="text-indigo-100 hover:text-indigo-90"
      to={routes.landlords(slug)}
      onClick={onClick}
    >
      {children}
    </UtmAwareLink>
  ) : (
    <>{children}</>
  );

type SpaceProviderInfoProps = {
  spaceProvider: SpaceProvider;
  sourcePage: typeof PARAMETERS.listingPage | typeof PARAMETERS.buildingPage;
};

const SpaceProviderInfo = ({ spaceProvider, sourcePage }: SpaceProviderInfoProps) => {
  const { clickToPage } = useAnalytics();
  const { getAltTextForCompanyLogo } = useAltText();

  const onClick = () => {
    clickToPage({
      actionType: 'CLICKED_TO_BRAND_PAGE',
      sourcePage,
      sourceContent: PARAMETERS.landlordName,
      destination: PARAMETERS.landlordPage,
    });
  };

  return (
    <div
      className={cn(
        'my-2 flex py-2',
        'mobile:mt-0 mobile:items-center',
        'border-b-[1px] border-solid border-black-010 desktop:mr-[22px]',
      )}
      data-testid="basic-building-space-provider-info"
    >
      {spaceProvider.logo ? (
        <LinkToSpaceProvider slug={spaceProvider.slug} onClick={onClick as React.MouseEventHandler}>
          <img
            className={cn(
              'mr-2 max-h-5 pr-2',
              'desktop:max-w-[240px] tablet:max-w-[136px] mobile:max-w-[136px]',
              'border-r-[1px] border-solid border-black-010',
            )}
            alt={getAltTextForCompanyLogo(spaceProvider.name || '')}
            src={spaceProvider.logo.path}
            data-testid="basic-building-space-provider-logo"
            data-qa-testid="landlord-logo"
          />
        </LinkToSpaceProvider>
      ) : null}
      <h3
        className="flex items-center mobile:flex-col"
        data-testid="basic-building-space-provider-name"
      >
        <LinkToSpaceProvider slug={spaceProvider.slug} onClick={onClick as React.MouseEventHandler}>
          <span className="text-inherit" data-qa-testid="landlord-name">
            {spaceProvider.name}
          </span>
        </LinkToSpaceProvider>
      </h3>
    </div>
  );
};

export default SpaceProviderInfo;
