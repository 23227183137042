import { AdminBuilding } from '@root/types';
import { Form, Formik } from 'formik';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { AdminFormSubmitter } from '@components/shared';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import s from './styles.module.less';
import HeroMedia from './AutomatedMarketing/HeroMedia/HeroMedia';
import BuildingDescription from './AutomatedMarketing/BuildingDescription/BuildingDescription';
import BuildingFact from './AutomatedMarketing/BuildingFact/BuildingFact';
import Sustainability from './AutomatedMarketing/Sustainability/Sustainability';
import BuildingFeatures from './AutomatedMarketing/BuildingFeature/BuildingFeature';
import Neighborhood from './AutomatedMarketing/Neighborhood/Neighborhood';
import BuildingManagement from './AutomatedMarketing/BuildingManagement/BuildingManagement';

type AutomatedMarketingBuildingFormProps = {
  building: AdminBuilding;
  updateBuilding: $TSFixMeFunction;
};

const AutomatedMarketingBuildingForm = ({
  building,
  updateBuilding,
}: AutomatedMarketingBuildingFormProps) => {
  const { t } = useTranslation('admin');
  const defaultMedia = {
    hero_image: [
      {
        mediaType: 'hero_image',
        title: '',
        description: '',
        order: 1,
        cloudinaryId: '',
      },
    ],
    heroVideo: [
      {
        mediaType: 'hero_video',
        title: '',
        description: '',
        order: 1,
        cloudinaryId: '',
      },
    ],
    buildingDescription: [
      {
        mediaType: 'building_description',
        title: '',
        description: '',
        order: 1,
        cloudinaryId: '',
      },
    ],
    buildingFact: [
      {
        mediaType: 'building_fact',
        title: '',
        description: '',
        order: 1,
        cloudinaryId: '',
      },
    ],
    buildingFeature: [
      {
        mediaType: 'building_feature',
        title: '',
        description: '',
        order: 1,
        cloudinaryId: '',
      },
    ],
    sustainability: [
      {
        mediaType: 'sustainability',
        title: '',
        description: '',
        order: 1,
        cloudinaryId: '',
      },
    ],
    neighborhood: [
      {
        mediaType: 'neighborhood',
        title: '',
        description: '',
        order: 1,
        cloudinaryId: '',
      },
    ],
    buildingManagement: [
      {
        mediaType: 'building_management',
        title: '',
        cloudinaryId: '',
        name: '',
        email: '',
        telephone: '',
        order: 1,
      },
    ],
  };

  const initialValues = {
    heroImage:
      building?.marketingMedia?.heroImage?.sort((a, b) => a.order - b.order) ||
      defaultMedia.hero_image,
    heroVideo:
      building?.marketingMedia?.heroVideo?.sort((a, b) => a.order - b.order) ||
      defaultMedia.heroVideo,
    buildingDescription:
      building?.marketingMedia?.buildingDescription?.sort((a, b) => a.order - b.order) ||
      defaultMedia.buildingDescription,
    buildingFact:
      building?.marketingMedia?.buildingFact?.sort((a, b) => a.order - b.order) ||
      defaultMedia.buildingFact,
    buildingFeature:
      building?.marketingMedia?.buildingFeature?.sort((a, b) => a.order - b.order) ||
      defaultMedia.buildingFeature,
    sustainability: building?.marketingMedia?.sustainability || defaultMedia.sustainability,
    neighborhood: building?.marketingMedia?.neighborhood || defaultMedia.neighborhood,
    buildingManagement:
      building?.marketingMedia?.buildingManagement?.sort((a, b) => a.order - b.order) ||
      defaultMedia.buildingManagement,
  };

  const validateAllFields = values => {
    const fields = ['buildingFact', 'sustainability', 'buildingFeature', 'neighborhood'];
    const heroMediafields = ['heroImage', 'heroVideo'];
    let errors = '';

    if (values.heroImage && values.heroImage.length > 1) {
      values.heroImage.forEach(val => {
        if (!val.title && !val.cloudinaryId) {
          errors = t(`marketingMedia.heroImage.errorMessage`);
        }
      });
    }

    if (values.buildingDescription) {
      values.buildingDescription.forEach(val => {
        if (!val.description && (val.title || val.cloudinaryId)) {
          errors = t(`marketingMedia.buildingDescription.errorMessage`);
        }
      });
      if (values.buildingDescription.length > 1) {
        values.buildingDescription.forEach(val => {
          if (!val.description) {
            errors = t(`marketingMedia.buildingDescription.errorMessage`);
          }
        });
      }
    }

    if (values.buildingManagement) {
      values.buildingManagement.forEach(val => {
        if (!val.name && (val.cloudinaryId || val.title || val.email || val.telephone)) {
          errors = t(`marketingMedia.buildingManagement.errorMessage`);
        }
      });
      if (values.buildingManagement.length > 1) {
        values.buildingManagement.forEach(val => {
          if (!val.name) {
            errors = t(`marketingMedia.buildingManagement.errorMessage`);
          }
        });
      }
    }

    fields.forEach(field => {
      if (values[field]) {
        values[field].forEach(val => {
          if (!val.title && (val.description || val.cloudinaryId)) {
            errors = t(`marketingMedia.${field}.errorMessage`);
          }
        });
      }
      if (values[field].length > 1) {
        values[field].forEach(val => {
          if (!val.title) {
            errors = t(`marketingMedia.${field}.errorMessage`);
          }
        });
      }
    });

    heroMediafields.forEach(field => {
      if (values[field]) {
        values[field].forEach(val => {
          if (!val.cloudinaryId && val.title) {
            errors = t(`marketingMedia.${field}.errorMessage`);
          }
        });
      }
    });

    return errors;
  };

  const handleSubmit = async values => {
    const error = validateAllFields(values);

    if (error.length !== 0) {
      notification.error({
        message: error,
      });
    } else {
      const payload = JSON.parse(JSON.stringify({ id: building.id, marketingMedia: values }));
      await updateBuilding(payload);
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form className={(s.form, s.automatedMarketingBuildingForm)}>
          <FormThemeContextProvider theme="vertical">
            <HeroMedia values={values} />
            <BuildingDescription data={values.buildingDescription} />
            <BuildingFact data={values.buildingFact} />
            <Sustainability data={values.sustainability} />
            <BuildingFeatures data={values.buildingFeature} />
            <Neighborhood data={values.neighborhood} />
            <BuildingManagement data={values.buildingManagement} />
            <AdminFormSubmitter />
          </FormThemeContextProvider>
        </Form>
      )}
    </Formik>
  );
};

export default AutomatedMarketingBuildingForm;
