import { Address } from '@root/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import { useParams } from 'react-router-dom';
import routes from '@root/routes';
import Layout from '../../Layout';
import { Item } from '../utils';
import AlphabeticalList from '../AlphabeticalList';

function alphabetizeAndGroupItems(
  items: Array<Item & { floor: string; suite: string | null }>,
): Record<string, Item[]> {
  const alphabetizedItems = items.sort((a, b) =>
    `${a.floor}|${a.suite}`.localeCompare(`${b.floor}|${b.suite}`),
  );
  const groupedItems = alphabetizedItems.reduce((acc, { floor, value, label }) => {
    const floorStr = floor.toLocaleLowerCase();
    if (!acc[floorStr]) {
      acc[floorStr] = [];
    }
    acc[floorStr].push({ value, label });
    return acc;
  }, {});
  return groupedItems;
}

type ListingsData = {
  listings: Array<Item & { floor: string; suite: string | null }>;
  building: {
    address: Address;
    slug: string;
    locality: { abbreviatedRegion: string; region: string; name: string; slug: string };
  };
};

const Building = () => {
  const { buildingSlug } = useParams<{
    buildingSlug: string;
  }>();
  const { t } = useTranslation('common');

  const { data: listingsData } = useQuery([routes.api.browseListings(buildingSlug)], async () =>
    get<ListingsData>(routes.api.browseListings(buildingSlug)),
  );

  const itemsToRender = alphabetizeAndGroupItems(listingsData?.listings || []);

  const building = listingsData?.building;

  return building ? (
    <Layout>
      <AlphabeticalList
        breadcrumbs={[
          { content: t('home'), link: routes.root },
          { content: t('country.unitedStates'), link: routes.browseRegionList },
          {
            content: t('browse.cities', { state: building.locality.region }),
            link: routes.browseRegion(building.locality.abbreviatedRegion.toLocaleLowerCase()),
            // specifying key because of New York, New York and other
            // times a locality and region have the same name
            key: `region-${building.locality.region}`,
          },
          {
            content: t('browse.buildings', { city: building.locality.name }),
            link: routes.browseLocality(
              building.locality.slug,
              building.locality.abbreviatedRegion.toLocaleLowerCase(),
            ),
            key: `locality-${building.locality.name}`,
          },
          {
            content: t('browse.listings', { address: building.address }),
          },
        ]}
        title={t('browse.listings', { address: building.address })}
        items={itemsToRender}
      />
    </Layout>
  ) : (
    <Layout showFooter={false} />
  );
};

export default Building;
