import { useState } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { MinMaxArea } from '@root/types';
import { useTranslation } from 'react-i18next';
import { CustomIcon } from '..';
import s from './FlexibleSizeArea.module.less';

type Props = {
  minMaxArea: MinMaxArea | null;
  showIconTitle?: boolean;
  iconClassName?: string;
  textClassName?: string;
  flexibleSizeAreaClassName?: string;
  labelQaTestId?: string;
};

const FlexibleSizeListing = ({
  minMaxArea,
  showIconTitle = true,
  flexibleSizeAreaClassName,
  iconClassName,
  textClassName,
  labelQaTestId,
}: Props) => {
  const { t } = useTranslation('units');
  const [tooltipIsVisible, setTooltipIsVisible] = useState(false);

  return minMaxArea ? (
    <Tooltip placement="bottomLeft" visible={tooltipIsVisible} title={t('flexibleSizeIcon')}>
      <div
        className={cn(s.flexibleSizeArea, flexibleSizeAreaClassName)}
        data-qa-testid={labelQaTestId}
      >
        <span
          onMouseEnter={() => {
            setTooltipIsVisible(true);
          }}
          onMouseLeave={() => {
            setTooltipIsVisible(false);
          }}
        >
          <CustomIcon
            title={showIconTitle ? t('flexibleSizeIcon') : undefined}
            type="flexible-size-area"
            className={cn(s.flexibleSizeIcon, iconClassName)}
          />
        </span>
        <span className={cn(s.text, textClassName)}>
          {t('minMaxArea', { minMaxAreaValue: minMaxArea })}
        </span>
      </div>
    </Tooltip>
  ) : null;
};

export default FlexibleSizeListing;
