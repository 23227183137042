import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Tooltip } from '@viewthespace/components';
import { Button, TourbookOverlay } from '@components/shared';
import { connect, ConnectedProps } from 'react-redux';
import { some } from 'lodash';
import { StoreState, Listing } from '@root/types';
import { tourbookCreateOnly } from '@store/selectors';
import useToggle from '@shared/useToggle';
import actions from '@store/actions/tourbooks';
import useFetchTourbookSummaries from '@root/shared/useFetchTourbookSummaries';
import styles from './ActionButtons.module.less';

type Props = { listing: Listing; hasCurrentUser: boolean };

const mapState = (state: StoreState) => ({
  istourbookCreateOnly: tourbookCreateOnly(state),
});
const mapDispatch = {
  fetchTourbooks: () => actions.fetchTourbookSummaries(),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

export function RawTourbooks({
  listing,
  fetchTourbooks,
  hasCurrentUser,
  istourbookCreateOnly,
}: Props & ReduxProps) {
  const { t } = useTranslation('listing');
  const {
    value: tourbookOverlayIsOpen,
    setTrue: openTourbookOverlay,
    setFalse: closeTourbookOverlay,
  } = useToggle();

  useEffect(() => {
    if (hasCurrentUser) fetchTourbooks();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCurrentUser]);

  const { data: tourbooks } = useFetchTourbookSummaries();

  const inAnyTourbooks = some(tourbooks, tourbook => tourbook.listingIds.includes(listing.id));

  return (
    <span className={styles.tourbookContainer}>
      <Tooltip
        className="tablet:hidden mobile:hidden"
        placement="bottom"
        content={t('tourbook.tourbookTooltip')}
        closeDelay={0}
        trigger={
          <div className="ml-1">
            <Button
              className={styles.tourbookButton}
              focus={tourbookOverlayIsOpen}
              type="tertiary"
              size="small"
              onClick={() => {
                openTourbookOverlay();
              }}
              icon={inAnyTourbooks ? 'tourbook-filled' : 'tourbook'}
              iconClass={cn(inAnyTourbooks && '!text-text-link')}
              data-testid="addToTourbookButton"
              labelQaTestId="add-to-tourbook"
            />
          </div>
        }
      />
      <TourbookOverlay
        isOpen={tourbookOverlayIsOpen}
        close={closeTourbookOverlay}
        listing={listing}
        createOnly={istourbookCreateOnly}
        className={styles.listingTourbookMobile}
      />
    </span>
  );
}

export default connector(RawTourbooks);
