import cn from 'classnames';
import buttonCSS from '@components/shared/V2Button/Button.module.less';
import css from '../styles.module.less';

type ChannelsButtonProps = {
  show?: boolean;
  children: React.ReactNode;
  link: string;
};

const ChannelsButton = ({ children, show, link }: ChannelsButtonProps) => {
  if (!show) return null;

  return (
    <a
      className={cn(buttonCSS.button, buttonCSS.secondary, buttonCSS.medium, css.previewLink)}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

export default ChannelsButton;
