import routes from '@root/routes';
import { Building } from '@root/types';
import { useTranslation } from 'react-i18next';
import PageHead from '@components/shared/PageHead/PageHead';

type Props = {
  building: Building;
  isPreview?: boolean;
};

const BuildingHead = ({ building, isPreview = false }: Props) => {
  const { t } = useTranslation('building');

  const { photos: buildingPhotos, slug } = building;

  const title = t('meta.title', {
    street: building.address.street,
    buildingName: building.address.buildingName,
    address: building.address,
  });

  const description = t(
    !building.rentableBuildingArea
      ? 'meta.description.withoutRentableArea'
      : 'meta.description.withRentableArea',
    {
      address: building.address,
      size: building.rentableBuildingArea,
    },
  );

  const previewImageUrl = buildingPhotos[0] ? buildingPhotos[0].path : undefined;
  const previewTitle = t(
    building.neighborhood
      ? 'meta.previewTitle.withNeighborhood'
      : 'meta.previewTitle.withoutNeighborhood',
    {
      neighborhood: building.neighborhood,
      address: building.address,
    },
  );

  const canonicalUrl = routes.building(slug);

  return (
    <PageHead
      title={title}
      description={description}
      previewTitle={previewTitle}
      previewImageUrl={previewImageUrl}
      canonicalURL={canonicalUrl}
      removeFromSEOIndex={isPreview || building.isDemo}
    />
  );
};

export default BuildingHead;
