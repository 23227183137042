import {
  TourbookOwner,
  PowerpointTourbook,
  PowerpointListingEntry,
  PowerpointBuildingEntry,
  PowerpointExternalListingEntry,
} from '@root/types';
import ListingShareUrlHelper from '@shared/listingShareUrlHelper';
import routes from '@root/routes';
import { compact, uniq, camelCase } from 'lodash';
import {
  generateLine,
  addElementsToSlide,
  generateElementsFromSections,
  truncate,
  makeElementsRelative,
  calculateHeightFromCharacterLength,
} from './util';
import { generateFooterElements, PAGE_MARGIN } from './portrait';
import { generateListingMosaicElements } from './mosaic';
import { generateAmenitiesElements } from './amenities';
import { generateBuildingHeaderElements } from './buildingHeader';
import { addFloorPlanSlide } from './floorplan';
import {
  h3,
  smallText,
  PptxUtils,
  FONT_FACE,
  staticImagesPathsInCloudinary,
  PowerpointElement,
  PowerpointTextElementProps,
  entityDetailsHeadingText,
} from './globals';
import { getDisplayPricePerArea, getDisplayPrice } from '../helper';

type PowerpointTourbookListing = PowerpointListingEntry | ExtendedPowerpointExternalListingEntry;

type ExtendedPowerpointExternalListingEntry = PowerpointExternalListingEntry & {
  capacity: null;
  minMaxArea: null;
  finishedCeilingHeight: null;
  slabToSlabCeilingHeight: null;
  shortestTerm: null;
  floorPlan: null;
  amenities: undefined;
};

// TODO: the descriptions used here are slightly different than the one for util
// reconcile the two after MVP release
const generateDescriptionElement = (
  description: string | null,
  {
    heading,
    y,
    limit,
    dynamicSize,
  }: { heading: string; y: number; limit: number; dynamicSize?: boolean | undefined },
): PowerpointElement[] => {
  const elements: PowerpointElement[] = [];

  if (description) {
    elements.push({
      type: 'textBox',
      data: heading,
      textOptions: {
        ...h3,
        breakLine: true,
      },
      placement: {
        x: 0.38,
        y,
        w: 6.85,
        h: 0.2,
        valign: 'top',
      },
    });

    const desc = truncate(description, limit);
    const w = 6.85;
    const h = dynamicSize ? calculateHeightFromCharacterLength(desc, 6.85, 0.16, 19) : 0.8;

    elements.push({
      type: 'textBox',
      data: truncate(description, limit),
      textOptions: { ...smallText },
      placement: {
        x: 0.38,
        y: y + 0.37,
        w,
        h,
        valign: 'top',
      },
    });
  }
  return elements;
};

export const generateListingDetailsElements = (
  {
    type,
    size,
    availableToday,
    capacity,
    condition,
    dateAvailable,
    leaseType,
    minMaxArea,
    askingRentInMoneyPerPeriod,
    askingRentInMoneyPerAreaPerPeriod,
    finishedCeilingHeight,
    slabToSlabCeilingHeight,
    shortestTerm,
    minAskingRentInMoneyPerPeriod,
    minAskingRentInMoneyPerAreaPerPeriod,
    maxAskingRentInMoneyPerPeriod,
    maxAskingRentInMoneyPerAreaPerPeriod,
  }: PowerpointTourbookListing,
  { t, flags }: PptxUtils,
  { showSecondRow }: { showSecondRow?: boolean } = { showSecondRow: true },
): PowerpointElement[] => {
  const elements: PowerpointElement[] = [];

  const headerText = (titleKey: string): PowerpointTextElementProps => ({
    data: t(titleKey)?.toUpperCase() || '',
    textOptions: entityDetailsHeadingText({ breakLine: true }),
  });
  const detailText = (
    data: string,
    options: PptxGenJS.default.TextPropsOptions = {},
  ): PowerpointTextElementProps => ({
    data,
    textOptions: { ...smallText, ...options },
  });

  const availability = availableToday
    ? t('common:listing.immediateAvailability')
    : t('units:date', { date: dateAvailable });

  const hasEditedAskingRent =
    flags['tourbook-asking-rent'] &&
    (minAskingRentInMoneyPerAreaPerPeriod || maxAskingRentInMoneyPerAreaPerPeriod);

  const price =
    (askingRentInMoneyPerPeriod && askingRentInMoneyPerAreaPerPeriod) || hasEditedAskingRent
      ? [
          detailText(
            `${getDisplayPricePerArea({
              t,
              askingRent: askingRentInMoneyPerAreaPerPeriod,
              minAskingRent: minAskingRentInMoneyPerAreaPerPeriod,
              maxAskingRent: maxAskingRentInMoneyPerAreaPerPeriod,
              acceptRange: flags['tourbook-asking-rent'],
            })!}${hasEditedAskingRent && type !== 'externalListing' ? ' *' : ''}`,
            { breakLine: true },
          ),
          detailText(
            getDisplayPrice({
              t,
              askingRent: askingRentInMoneyPerPeriod,
              minAskingRent: minAskingRentInMoneyPerPeriod,
              maxAskingRent: maxAskingRentInMoneyPerPeriod,
              acceptRange: flags['tourbook-asking-rent'],
            })!,
            { breakLine: true, color: '#737373' },
          ),
        ]
      : [detailText(t('tourbook:powerpoint.listing.nullBaseRent'), { breakLine: true })];

  const detailWidth = 1.3;

  const detailsFirstRow: PowerpointTextElementProps[][] = [
    [
      headerText('tourbook:powerpoint.listing.size'),
      detailText(t('units:area', { area: size }), { breakLine: true }),
      detailText(`      ${t('units:minMaxArea', { minMaxAreaValue: minMaxArea })}`),
    ],
    compact([
      headerText('tourbook:listingCard.baseRentTitle'),
      ...price,
      shortestTerm
        ? detailText(t('tourbook:powerpoint.listing.term', { years: shortestTerm.magnitude }), {
            color: '737373',
          })
        : null,
    ]),
    [headerText('tourbook:powerpoint.listing.available'), detailText(availability)],
    [
      headerText('tourbook:powerpoint.listing.condition'),
      condition ? detailText(t(`spaceConditions:${camelCase(condition)}`)) : detailText('-'),
    ],
    [
      headerText('tourbook:powerpoint.listing.leaseType'),
      detailText(t(`listing:leaseTypes:${leaseType}`)),
    ], //
  ];

  if (minMaxArea) {
    elements.push({
      type: 'image',
      data: staticImagesPathsInCloudinary.minMaxArea,
      placement: { h: 0.11, w: 0.14, x: PAGE_MARGIN, y: 4.68 },
    });
  }

  const detailsSecondRow: PowerpointTextElementProps[][] = [];

  if (capacity) {
    detailsSecondRow.push([
      headerText('tourbook:powerpoint.listing.capacity'),
      detailText(t('common:listing.capacity', { capacity })),
    ]);
  }

  if (slabToSlabCeilingHeight || finishedCeilingHeight) {
    detailsSecondRow.push(
      compact([
        headerText('tourbook:powerpoint.listing.height'),
        finishedCeilingHeight
          ? detailText(t('common:listing.finishedHeight', { height: finishedCeilingHeight }), {
              breakLine: true,
            })
          : null,
        slabToSlabCeilingHeight
          ? detailText(t('common:listing.slabToSlabHeight', { height: slabToSlabCeilingHeight }))
          : null,
      ]),
    );
  }

  detailsFirstRow.forEach((detail, index) => {
    const xPostion = detailWidth * index + PAGE_MARGIN;
    elements.push({
      type: 'textBox',
      data: detail,
      placement: {
        x: xPostion,
        y: 4.32,
        w: detailWidth,
        h: 0.45,
        margin: 0,
        valign: 'top',
      },
    });
  });

  if (showSecondRow) {
    detailsSecondRow.forEach((detail, index) => {
      const xPostion = detailWidth * index + PAGE_MARGIN;
      elements.push({
        type: 'textBox',
        data: detail,
        placement: {
          x: xPostion,
          y: 5.07,
          w: detailWidth,
          h: 0.45,
          margin: 0,
          valign: 'top',
        },
      });
    });
  }

  return elements;
};

export const generateListingPageElements = ({
  tourbook,
  listing,
  building,
  utils,
  owner,
}: {
  listing: PowerpointTourbookListing;
  building: PowerpointBuildingEntry;
  tourbook: PowerpointTourbook;
  utils: PptxUtils;
  owner: TourbookOwner;
}): PowerpointElement[] => {
  const { flags, t } = utils;
  const shouldListingHeaderLink = flags['tourbook-export-listing-link'];
  const { railsEnv } = utils;

  const encodedData = ListingShareUrlHelper.encode({
    listingId: listing.id,
    userId: owner.id,
    hideLocation: false,
  });

  const hypertext = () => {
    if (railsEnv === 'production') {
      return 'https://';
    }
    return 'http://';
  };

  const listingNameHeaderElement: PowerpointElement = shouldListingHeaderLink
    ? {
        type: 'textBox',
        data: listing.name,
        textOptions: {
          ...h3,
          fontSize: 14,
          color: '#0C63C1',
          hyperlink: {
            url: `${hypertext()}${window.location.host}${routes.listingShare(encodedData)}`,
          },
        },
        placement: { x: PAGE_MARGIN, y: 1.04, w: 5.72, h: 0.33 },
      }
    : {
        type: 'textBox',
        data: listing.name,
        textOptions: {
          ...h3,
          fontSize: 14,
        },
        placement: { x: PAGE_MARGIN, y: 1.04, w: 5.72, h: 0.33 },
      };

  const listingDetailsElements = generateListingDetailsElements(listing, utils);
  const listingDetailsRows = uniq(
    listingDetailsElements.filter(e => e.type === 'textBox').map(e => e.placement?.y),
  ).length;

  const staticElements = [
    ...generateBuildingHeaderElements(building, utils),
    listingNameHeaderElement,
    ...generateListingMosaicElements(
      utils,
      listing.photos,
      0.39,
      building,
      listing.isCurrentlyExclusive,
    ),
    ...listingDetailsElements,
    ...generateFooterElements(tourbook, utils),
  ];

  const hasEditedAskingRent =
    flags['tourbook-asking-rent'] &&
    listing.type !== 'externalListing' &&
    (listing.minAskingRentInMoneyPerAreaPerPeriod || listing.maxAskingRentInMoneyPerAreaPerPeriod);

  const helperTextElements: PowerpointElement[] = [];

  const hasOneRow = listingDetailsRows === 1;
  const yOffset = hasEditedAskingRent && !hasOneRow ? 4.04 : 4.29;
  const relativeElementsYCoordinate = yOffset + 0.75 * listingDetailsRows;

  // Add modified listing attribute helper text.
  if (hasEditedAskingRent) {
    helperTextElements.push({
      type: 'textBox',
      data: t('tourbook:modifiedAskingRentHelpText'),
      textOptions: {
        color: '737373',
        fontSize: 8,
        fontFace: FONT_FACE,
      },
      placement: { x: 4.43, y: relativeElementsYCoordinate, w: 3 },
    });
  }

  const descriptionElements = generateDescriptionElement(listing.description, {
    heading: utils.t('tourbook:powerpoint.listing.description'),
    y: 6.1,
    limit: 500,
    dynamicSize: true,
  });

  const amenitiesElements = generateAmenitiesElements(
    listing.amenities,
    {
      model: 'Listing',
      y: 7.32,
    },
    utils,
  );

  const computedRelativeElements = generateElementsFromSections(
    [
      [generateLine(utils.pptx, 0, 6.8, 0)],
      descriptionElements.map(makeElementsRelative),
      amenitiesElements.map(makeElementsRelative),
    ],
    {
      x: PAGE_MARGIN,
      y: relativeElementsYCoordinate + 0.25,
    },
  );

  return [...staticElements, ...helperTextElements, ...computedRelativeElements];
};

export const createListing = (
  utils: PptxUtils,
  tourbook: PowerpointTourbook,
  tourbookListing: PowerpointTourbookListing,
  building: PowerpointBuildingEntry,
) => {
  const { pptx } = utils;
  const slide = pptx.addSlide();

  // // TODO: Remove and move the elements to the generate listing page elements
  // createListingMosaic(
  //   utils,
  //   slide,
  //   tourbookListing.photos,
  //   0.39,
  //   tourbookListing!.isCurrentlyExclusive,
  //   building,
  // );

  const listingPageElements = generateListingPageElements({
    listing: tourbookListing,
    building,
    tourbook,
    utils,
    owner: tourbook.owner,
  });
  addElementsToSlide(slide, listingPageElements);

  if (tourbookListing.floorPlan) {
    addFloorPlanSlide({
      floorPlan: tourbookListing.floorPlan,
      tourbook,
      building,
      listingId: tourbookListing.id,
      userId: tourbook.owner.id,
      heading: tourbookListing.name,
      utils,
      isInternalListing: true,
    });
  }
};

export default createListing;
