import { ReactNode } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { CustomIcon } from '@components/shared';
import { ValidIconTypes } from '@components/shared/CustomIcon';
import Button, { ButtonSize, ButtonType } from '@components/shared/V2Button';
import s from './SubmitButton.module.less';

type Props = {
  children: ReactNode;
  disabled?: boolean;
  size?: ButtonSize;
  icon?: ValidIconTypes;
  'data-testid'?: string;
  className?: string;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: ButtonType;
  hideSubmitting?: boolean;
  submittingText?: string;
};

const SubmitButton = ({
  disabled = false,
  children,
  size,
  icon,
  'data-testid': testId,
  className,
  onClick,
  onMouseDown,
  type = 'primary',
  hideSubmitting = false,
  submittingText,
}: Props) => {
  const formik = useFormikContext();
  const { t } = useTranslation('common');

  return (
    <Button
      htmlType="submit"
      type={type}
      size={size}
      disabled={disabled || formik.isSubmitting}
      data-testid={testId}
      className={cn(s.submitButton, className)}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {formik.isSubmitting && !hideSubmitting ? (
        <>
          <CustomIcon className={s.submittingIcon} type="loadingOutlined" />
          {submittingText || t('sending')}
        </>
      ) : (
        <>
          {icon ? <CustomIcon className={s.icon} data-testid={`${icon}-icon`} type={icon} /> : null}
          {children}
        </>
      )}
    </Button>
  );
};

export default SubmitButton;
