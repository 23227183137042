import React from 'react';
import cn from 'classnames';
import { FieldWrapper } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { AdminMicrosite, MicrositeLayouts } from '@root/types';
import { CustomIcon } from '@components/shared';
import { ValidIconTypes } from '@components/shared/CustomIcon/CustomIcon';

const PageLayoutSelector = () => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();

  const layouts: MicrositeLayouts[] = ['multi_page', 'single_page'];
  const iconMappings = {
    multi_page: 'multi-page' as ValidIconTypes,
    single_page: 'single-page' as ValidIconTypes,
  };

  const { t } = useTranslation('admin');

  return (
    <FieldWrapper name="layout" labelText={t(`microsite.layout.title`)} containerClass="!mb-0">
      <div className="inline-flex h-[100px] w-full gap-2">
        {layouts.map(layout => (
          <div
            key={layout}
            role="button"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setFieldValue('layout', layout);
                setFieldTouched('layout', true);
              }
            }}
            tabIndex={0}
            className={cn(
              'grow flex self-end bg-background-primary border-solid border rounded-[3px] h-full cursor-pointer hover:bg-cta-tertiary-hover hover:border-general-indigo-primary hover:shadow-[0px_0px_0px_1px_rgba(72,_55,_185)] focus-visible:!border-general-indigo-primary focus-visible:!shadow-[0px_0px_0px_1px_rgba(72,_55,_185)] focus-visible:outline-none focus-visible:bg-cta-tertiary-hover',
              values.layout === layout
                ? ' border-cta-primary-default shadow-[0px_0px_0px_1px_rgba(72,_55,_185)]'
                : ' border-[#cccccc]',
            )}
          >
            <label
              htmlFor={`layout-${layout}`}
              className="w-full flex items-end justify-center cursor-pointer m-2"
            >
              <input
                name={`layout-${layout}`}
                type="radio"
                id={`layout-${layout}`}
                value={layout}
                checked={values.layout === layout}
                className="hidden"
                onChange={() => {
                  setFieldValue('layout', layout);
                  setFieldTouched('layout', true);
                }}
              />
              <div className="flex flex-col items-center gap-0.5">
                <CustomIcon type={iconMappings[layout]} className="[&_svg]:h-5 [&_svg]:w-5" />
                <span className="font-body-medium">{t(`microsite.layout.${layout}`)}</span>
              </div>
            </label>
          </div>
        ))}
      </div>
    </FieldWrapper>
  );
};

export default PageLayoutSelector;
