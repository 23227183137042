import { useEffect, useState } from 'react';
import * as React from 'react';
import { useCustomBreakpoint, defaultConfig } from '@shared/useBreakpoints';
import {
  Button,
  ConfirmationModal,
  Notification,
  OnlyInDesktopTablet,
  OnlyInMobile,
  List,
} from '@components/shared';
import useStickyState from '@shared/useStickyState';
import { TourbookSummary, StoreState } from '@root/types';
import useToggle from '@root/shared/useToggle';
import { useHistory, Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import tourbooksActions from '@store/actions/tourbooks';
import { RawSelect } from '@components/shared/forms';
import actions from '@store/actions';
import analyticsActions from '@store/actions/tourbooks/analyticsActions';
import TourbookCard from '@components/shared/TourbookCard';
import EditTourbookModal from '@components/shared/EditTourbookModal';
import { tourbooksLoaded, getVisibleTourbookSummaries } from '@root/store/selectors';
import cn from 'classnames';

import routes from '@root/routes';
import api from '@shared/api';
import { isInIframe, sendHostToMyTourbooksPage } from '@root/shared/iframeUtils';
import EmptyState from './EmptyState';
import styles from './Tourbooks.module.less';
import Layout from '../Layout';

type deleteCallbacks = {
  onSuccess: () => void;
  onError: () => void;
};

const mapState = (state: StoreState) => ({
  tourbooksLoading: !tourbooksLoaded(state),
  tourbooks: getVisibleTourbookSummaries(state),
});

const mapDispatch = {
  loadPage: () => tourbooksActions.manageTourbookPageLoad(),
  fetchTourbooks: (sort: string) => tourbooksActions.fetchTourbookSummaries(sort),
  deleteTourbook: (tourbook: any, listingsCount: number, callbacks: deleteCallbacks) =>
    tourbooksActions.deleteTourbook(tourbook, listingsCount, callbacks),
  clickedToTourbook: () => actions.clickedToTourbook(),
};

const emptyTourbook = {
  id: '',
  name: '',
  clientLogoCloudinaryId: '',
  headerImage: null,
  clientName: '',
};
const screenWidths: { [k: string]: 'largeMobile' } = {
  LARGE_MOBILE: 'largeMobile',
};

const breakpointsConfig = {
  ...defaultConfig,
  [screenWidths.LARGE_MOBILE]: 670,
};

const connector = connect(mapState, mapDispatch);
export type ReduxProps = ConnectedProps<typeof connector>;

export const Tourbooks = ({
  loadPage,
  tourbooksLoading,
  tourbooks,
  deleteTourbook,
  clickedToTourbook,
  fetchTourbooks,
}: ReduxProps) => {
  const history = useHistory();

  const {
    value: isCreateModalOpen,
    setTrue: openCreateModal,
    setFalse: closeCreateModal,
  } = useToggle();

  useEffect(() => {
    sendHostToMyTourbooksPage();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tourbookToDelete, setTourbookToDelete] = useState({ listingCount: 0 });
  const [sortValue, setSortValue] = useStickyState('newest', 'tourbooks_sort');
  const { t } = useTranslation('tourbook');
  const dispatch = useDispatch();
  const breakpoint = useCustomBreakpoint(breakpointsConfig);
  const isLargeMobile = breakpoint === screenWidths.LARGE_MOBILE;

  useEffect(() => {
    loadPage();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTourbooks(sortValue);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  const onRetryDuplicationClick = async (tourbook: TourbookSummary, event: React.MouseEvent) => {
    dispatch(analyticsActions.tourbookRetryDuplicate(tourbook, true));
    await duplicateTourbook(tourbook, event);
  };

  const triggerFailureNotification = (tourbook: TourbookSummary) => {
    Notification.error({
      title: t('notification.duplicationError.title'),
      text: (
        <>
          {/* eslint-disable react/jsx-no-literals */}
          <Trans t={t} i18nKey="notification.duplicationError.text"></Trans>
          {/* eslint-enable react/jsx-no-literals */}
          <br></br>
          <Button
            type="primary"
            size="small"
            className={styles.notificationRetryButton}
            onClick={e => onRetryDuplicationClick(tourbook, e)}
          >
            {t('notification.duplicationError.retryButton')}
          </Button>
        </>
      ),
      iframe: {
        text: t('notification.duplicationError.text'),
      },
    });
  };

  const duplicateTourbook = async (tourbook: TourbookSummary, event: React.MouseEvent) => {
    event.preventDefault();
    try {
      const response = await api.post(routes.api.tourbookDuplicate(tourbook.id), {
        inIframe,
      });
      if (response.ok) {
        const duplicatedTourbook = await response.json();
        const href = routes.tourbook(duplicatedTourbook.id);
        fetchTourbooks(sortValue);
        Notification.info({
          title: t('notification.duplicationSuccess.title'),
          text: (
            <>
              {/* eslint-disable react/jsx-no-literals */}
              <Trans t={t} i18nKey="notification.duplicate.success">
                View your <a href={href}>duplicated Tourbook</a>
              </Trans>
              {/* eslint-enable react/jsx-no-literals */}
            </>
          ),
          placement: 'bottomLeft',
          duration: 3.5,
          iframe: {
            text: t('notification.duplicate.success'),
            links: [{ url: href, method: 'push' }],
          },
        });
        dispatch(analyticsActions.tourbookDuplicate(tourbook, duplicatedTourbook, true));
      } else {
        triggerFailureNotification(tourbook);
      }
    } catch {
      triggerFailureNotification(tourbook);
    }
  };

  const onConfirmDeleteTourbook = () => {
    deleteTourbook(tourbookToDelete, tourbookToDelete.listingCount, {
      onSuccess: () => {
        Notification.info({
          title: t('notification.deleteSuccess'),
          placement: 'bottomLeft',
          duration: 3.5,
        });
        closeModal();
      },
      onError: () => {
        Notification.error({
          title: t('notification.deleteError'),
          placement: 'bottomLeft',
          duration: 3.5,
        });
      },
    });
  };

  const openModal = (tourbook: TourbookSummary, event: React.MouseEvent) => {
    event.preventDefault();
    setTourbookToDelete(tourbook);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const createTourbook = () => {
    openCreateModal();
  };

  const onCreateSuccess = (tourbook: TourbookSummary) => {
    history.push(routes.tourbook(tourbook.id));
  };

  const createTourbookModal = (
    <EditTourbookModal
      tourbook={emptyTourbook}
      isModalOpen={isCreateModalOpen}
      onCloseModal={closeCreateModal}
      onCreateSuccess={onCreateSuccess}
      actionType="create"
    />
  );

  if (tourbooksLoading) {
    return null;
  }

  if (!tourbooks.length) {
    return (
      <>
        <EmptyState onClickCreateTourbook={openCreateModal} />
        {createTourbookModal}
      </>
    );
  }

  const handleSortChange = (newSortValue: string) => {
    setSortValue(newSortValue);
  };

  const sortDropdown = () => {
    return (
      <RawSelect
        onChange={val => handleSortChange(val as string)}
        value={sortValue}
        name="tourbook-sort-select"
        styles={{
          control: base => ({
            ...base,
            minHeight: '40px',
            '@media only screen and (max-width: 767px)': {
              minHeight: '32px',
            },
          }),
        }}
        className="ml-2 w-[240px]"
        data-testid="tourbooks-sort-select"
        options={[
          { value: 'newest', label: t('newest') },
          { value: 'oldest', label: t('oldest') },
          { value: 'nameaz', label: t('nameAZ') },
          { value: 'nameza', label: t('nameZA') },
        ]}
      />
    );
  };

  const inIframe = isInIframe();

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        title={t('deleteModal.title')}
        confirmationText={t('deleteModal.confirmationText')}
        cancelButtonText={t('common:cancel')}
        confirmButtonText={t('common:delete')}
        onClose={closeModal}
        onConfirm={onConfirmDeleteTourbook}
      />
      <div className={cn('!bg-background-secondary', inIframe && 'min-h-screen')}>
        <Layout
          activePage="myTourbooks"
          mobileFullWidth
          containerClassName={cn(inIframe ? 'desktop:!px-3' : 'desktop:!px-4')}
          fixedHeaderContents={
            <div
              className={cn(
                'm-auto flex w-full items-center justify-between',
                inIframe ? 'mt-[4px]' : 'max-w-[1376px]', // 1440px - 32px * 2
              )}
            >
              <div className="flex items-center">
                <h1
                  className={cn(
                    'text-black-100 font-headline mobile:font-title',
                    inIframe && '!font-headline-de-emphasis',
                  )}
                >
                  {t('myTourbooks')}
                </h1>
                <OnlyInDesktopTablet>{sortDropdown()}</OnlyInDesktopTablet>
              </div>
              <OnlyInMobile>
                <Button
                  data-testid="addTourbookButton"
                  type="primary"
                  size="small"
                  onClick={() => {
                    createTourbook();
                  }}
                >
                  <span className={styles.buttonText}>{t('createTourbook')}</span>
                </Button>
              </OnlyInMobile>
              <OnlyInDesktopTablet>
                <Button
                  data-testid="addTourbookButton"
                  type="primary"
                  size="medium"
                  onClick={() => {
                    createTourbook();
                  }}
                >
                  <span className={styles.buttonText}>{t('createTourbook')}</span>
                </Button>
              </OnlyInDesktopTablet>
            </div>
          }
        >
          <div className={cn(inIframe && '!pt-[45px]')} />
          <OnlyInMobile className="mr-2 flex justify-end pt-2">{sortDropdown()}</OnlyInMobile>
          <List<TourbookSummary>
            className={cn(
              'mb-8 grid justify-center gap-x-2 gap-y-4 pt-4',
              'mobile:grid-cols-1 mobile:pt-2',
              isLargeMobile && '!grid-cols-2',
              'tablet:grid-cols-2',
              'desktop:grid-cols-3',
            )}
            itemClassName={styles.card}
            items={tourbooks}
            renderItem={tourbookSummary => (
              <Link
                to={routes.tourbook(tourbookSummary.id)}
                data-testid={`${tourbookSummary.name}`}
                onClick={clickedToTourbook}
              >
                <TourbookCard
                  tourbook={tourbookSummary}
                  onDelete={event => openModal(tourbookSummary, event)}
                  onDuplicate={event => duplicateTourbook(tourbookSummary, event)}
                />
              </Link>
            )}
          />
        </Layout>
      </div>
      {createTourbookModal}
    </>
  );
};

export default connector(Tourbooks);
