import * as React from 'react';
import { Field } from 'formik';
import cn from 'classnames';
import { useFormTheme } from '../ThemeContext';
import RawCheckbox, { CheckboxSize } from '../RawCheckbox';
import s from './Checkbox.module.less';

type Props = {
  'aria-describedby'?: string;
  name: string;
  className?: string;
  children: React.ReactNode;
  labelClassName?: string;
  size?: CheckboxSize;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  'data-testid'?: string;
};

export default function Checkbox({
  'aria-describedby': ariaDescribedby,
  name,
  className,
  children,
  labelClassName,
  onChange,
  size = 'medium',
  disabled = false,
  'data-testid': dataTestId = name,
}: Props) {
  const layout = useFormTheme();
  return (
    <Field type="checkbox" name={name}>
      {({ field }) => (
        <RawCheckbox
          aria-describedby={ariaDescribedby}
          checked={field.checked}
          name={field.name}
          className={className}
          labelClassName={cn(s.container, labelClassName)}
          onChange={e => {
            if (onChange) onChange(e);
            field.onChange(e);
          }}
          onBlur={field.onBlur}
          value={field.value}
          horizontal={layout === 'horizontal'}
          size={size}
          disabled={disabled}
          data-testid={dataTestId}
        >
          {children}
        </RawCheckbox>
      )}
    </Field>
  );
}
