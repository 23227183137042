import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Form, Select } from '@components/shared/forms';
import api from '@shared/api';
import routes from '@root/routes';
import { CreateAskingRents, Rent, Currency, Units } from '@root/types';
import { notification } from 'antd';
import s from './AskingRentForm.module.less';
import TermRange from './TermRange';
import TermList from './TermList';

type Props = {
  fetchListing: () => void;
  listingId: string;
  rents: Array<Rent>;
  currency?: Currency | null;
  units?: Units | null;
  visibility: boolean | undefined;
};

const AskingRentForm = ({ fetchListing, listingId, rents, currency, units, visibility }: Props) => {
  const { t } = useTranslation('admin');
  const [yearMostRecentlyEdited, setYearMostRecentlyEdited] = useState(0);
  const monthsInAYear = 12;
  // filter out partial year terms for now, remove after data migration
  const filteredRents = rents.filter(rent => rent.month % monthsInAYear === 0);
  const formattedInitialAskingRents = () =>
    filteredRents.reduce(
      (accumulator, rent) =>
        Object.assign(accumulator, { [rent.month / monthsInAYear]: rent.magnitude }),
      {},
    );

  const initialAskingRents = formattedInitialAskingRents();
  const hasExistingRents = !isEmpty(initialAskingRents);

  const displaySuccessNotification = isDeleting => {
    if (isDeleting) {
      notification.success({ message: t('listing.askingRent.deleteSuccess') });
    } else if (!hasExistingRents) {
      notification.success({ message: t('listing.askingRent.createSuccess') });
    } else {
      notification.success({ message: t('listing.askingRent.updateSuccess') });
    }
  };

  const displayErrorNotification = isDeleting => {
    if (isDeleting) {
      notification.error({ message: t('listing.askingRent.deleteFailure') });
    } else if (!hasExistingRents) {
      notification.error({ message: t('listing.askingRent.createFailure') });
    } else {
      notification.error({ message: t('listing.askingRent.updateFailure') });
    }
  };

  const handleSubmit = async (values: CreateAskingRents, actions) => {
    const newAskingRents = {};
    for (let i = values.minimum; i <= values.maximum; i += 1) {
      newAskingRents[i] = values.askingRents[i];
    }

    actions.setFieldValue('askingRents', newAskingRents);
    setYearMostRecentlyEdited(0);

    const formattedAskingRents = Object.keys(newAskingRents).map(term => ({
      month: parseInt(term, 10) * monthsInAYear,
      amount: newAskingRents[term],
    }));

    const res = await api.post(routes.api.admin.listingPrices(listingId), {
      prices: formattedAskingRents,
      visibility: values.visibility,
    });

    const isDeleting = isEmpty(formattedAskingRents);

    if (res.ok) {
      displaySuccessNotification(isDeleting);
      fetchListing();
    } else {
      displayErrorNotification(isDeleting);
    }
  };

  const getInitialValues = () => {
    const askingRents = initialAskingRents;

    const terms = Object.keys(askingRents).map(term => parseInt(term, 10));

    const initialValues = { askingRents, visibility };

    if (!isEmpty(terms)) {
      /* eslint-disable dot-notation */
      initialValues['minimum'] = Math.min(...terms);
      initialValues['maximum'] = Math.max(...terms);
      /* eslint-enable dot-notation */
    }

    return initialValues;
  };

  const visibilityOptions = [
    { label: t('listing.askingRent.visibilityShow'), value: true },
    { label: t('listing.askingRent.visibilityHide'), value: false },
  ];

  return (
    <div className="u-p-top-2x">
      <div className="font-title">{t('listing.askingRent.title')}</div>
      <Form
        id="askingRentForm"
        className={s.form}
        // @ts-expect-error
        initialValues={getInitialValues()}
        onSubmit={handleSubmit}
        enableReinitialize
        enablePrompt
      >
        <div data-test="rent-visibility" className={s.rentVisibility}>
          <Select
            name="visibility"
            required
            options={visibilityOptions}
            isClearable={false}
            labelText={t('listing.askingRent.visibility')}
            placeholder={t('listing.askingRent.visibilityPlaceholder')}
          />
        </div>
        <TermRange setYearMostRecentlyEdited={setYearMostRecentlyEdited} />
        <TermList
          yearMostRecentlyEdited={yearMostRecentlyEdited}
          setYearMostRecentlyEdited={setYearMostRecentlyEdited}
          currency={currency}
          units={units}
        />
      </Form>
    </div>
  );
};

export default AskingRentForm;
