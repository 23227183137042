import { useEffect, useState, useRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import classNames from 'classnames';
import useEnv from '@shared/useEnv';
import CustomIcon from '@components/shared/CustomIcon';
import {
  DefaultPhotoUploadType,
  DefaultCloudinaryResponseType,
  MIME_TYPES,
} from '@components/shared/Admin/MultimediaUploader';
import get from 'lodash/get';
import api from '@shared/api';
import s from './DraggableMediaUploader.module.less';
import filterOutLargeFiles from '../MultimediaUploader/utils';

const pluckFileAttributesFromCloudinary = (
  cloudinaryJson: DefaultCloudinaryResponseType,
): DefaultPhotoUploadType => {
  /* eslint-disable camelcase */
  const { public_id, original_filename, format, created_at } = cloudinaryJson;
  return {
    cloudinaryId: public_id,
    filename: `${original_filename}.${format}`,
    processedAt: created_at,
  };
  /* eslint-enable camelcase */
};

type Props<CloudinaryResponseType, PhotoUploadType> = {
  accept: string;
  marketplaceRequest: (
    files: (PhotoUploadType | DefaultPhotoUploadType)[],
    imageType?: string,
  ) => PromiseLike<{ ok: boolean }>;
  cloudinaryRoute: string;
  cloudinaryTransformer?: (
    json: CloudinaryResponseType | DefaultCloudinaryResponseType,
  ) => PhotoUploadType | DefaultPhotoUploadType;
  multiple?: boolean;
  fetchRecord: () => void;
  draggableContainer?: string;
  placeholderElement?: ReactNode;
  imageType?: string;
  dataTestId?: string;
  uploadSizeLimitInMb?: number;
};

const DraggableMediaUploader = <CloudinaryResponseType, PhotoUploadType>({
  accept,
  marketplaceRequest,
  cloudinaryRoute,
  // @ts-expect-error
  cloudinaryTransformer = pluckFileAttributesFromCloudinary,
  multiple,
  fetchRecord,
  placeholderElement,
  draggableContainer,
  imageType,
  dataTestId,
  uploadSizeLimitInMb,
}: Props<CloudinaryResponseType, PhotoUploadType>) => {
  const { t } = useTranslation('admin');
  const { cloudinaryUploadPreset } = useEnv();
  const [totalFilesCount, setTotalFilesCount] = useState<number>(0);
  const [successfullyUploadedFiles, setSuccessfullyUploadedFiles] = useState<
    (PhotoUploadType | DefaultPhotoUploadType)[]
  >([]);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const onSuccess = () => {
    notification.success({
      message: t('media.photoUploadSuccess', { count: totalFilesCount }),
    });
    fetchRecord();
  };

  const onError = () => {
    notification.error({
      message: t('media.photoUploadFailure', { count: totalFilesCount }),
    });
  };

  const uploadToMarketplace = async () => {
    const marketplaceResponse = await marketplaceRequest(successfullyUploadedFiles, imageType);
    if (marketplaceResponse.ok) {
      onSuccess();
    } else {
      onError();
    }
  };

  const incrementUploadedFilesCount = () => {
    setUploadedFilesCount(prevCount => prevCount + 1);
  };

  useEffect(() => {
    if (uploadedFilesCount === totalFilesCount && isUploading) {
      if (successfullyUploadedFiles.length === totalFilesCount) {
        uploadToMarketplace();
      } else {
        onError();
      }

      setUploadedFilesCount(0);
      setTotalFilesCount(0);
      setSuccessfullyUploadedFiles([]);
      setIsUploading(false);
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFilesCount]);

  useEffect(() => {
    if (isUploading) {
      incrementUploadedFilesCount();
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullyUploadedFiles]);

  const prepareForUpload = (length: number) => {
    setTotalFilesCount(length);
    setIsUploading(true);
  };

  const handleMediaUpload = (attachments: any) => {
    const validAttachments = filterOutLargeFiles({
      files: attachments,
      maxFileSizeInMb: uploadSizeLimitInMb,
      t,
    });

    if (validAttachments.length === 0) {
      return;
    }

    prepareForUpload(validAttachments.length);

    validAttachments.forEach(async (attachment: any) => {
      const formData = new FormData();

      formData.append('file', attachment);
      formData.append('upload_preset', cloudinaryUploadPreset || '');

      const cloudinaryResponse = await api.fetch(cloudinaryRoute, {
        method: 'POST',
        body: formData,
      });

      const cloudinaryJson = await cloudinaryResponse.json();

      if (cloudinaryResponse.ok) {
        const uploadedFile = cloudinaryTransformer(cloudinaryJson);

        setSuccessfullyUploadedFiles(prevUploadedFiles => prevUploadedFiles.concat(uploadedFile));
      } else {
        incrementUploadedFilesCount();
      }
    });
  };

  const handleClick = (e: any) => {
    const currentTarget = e.currentTarget ? e.currentTarget : e.srcElement;
    const attachments = Array.from(get(currentTarget, 'files'));

    handleMediaUpload(attachments);
  };

  const handleDrag = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const attachments = Array.from(e.dataTransfer.files);

      handleMediaUpload(attachments);
    }
  };

  return (
    <div
      role="button"
      className={classNames(
        s.draggableUploadArea,
        dragActive ? s.activeBorder : s.inactiveBorder,
        draggableContainer,
      )}
      onClick={() => fileInput?.current?.click()}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {placeholderElement || (
        <>
          <CustomIcon type="uploadOutlined" className={s.uploadIcon} />
          <div>{t('media.uploadPhotoHelpText')}</div>
        </>
      )}

      <input
        ref={fileInput}
        data-test
        type="file"
        data-testid={dataTestId}
        multiple={multiple}
        style={{
          position: 'absolute',
          width: 1,
          height: 1,
          top: -500,
        }}
        onChange={handleClick}
        accept={accept || MIME_TYPES.imageOnly}
      />
    </div>
  );
};

export default DraggableMediaUploader;
