import api from '@shared/api';
import routes from '@root/routes';
import type { Listing } from '@root/types';
import actions from '../index';
import analyticsActions from './analyticsActions';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};

type ReceiveShareListingAction = { type: 'RECEIVE_FETCH_SHARED_LISTING'; payload: Listing };

const receiveShareListing = (listing: Listing): ReceiveShareListingAction => ({
  type: 'RECEIVE_FETCH_SHARED_LISTING',
  payload: listing,
});

const listingSharePageLoad =
  ({ id, encodedData }: { id: string; encodedData: string }) =>
  async dispatch => {
    await dispatch(actions.setSharedListingPage(id));

    try {
      await api
        .fetch(routes.api.listingShare(encodedData))
        .then(handleErrors)
        .then(response => response.json())
        .then((listing: Listing) => {
          dispatch(receiveShareListing(listing));
          dispatch(analyticsActions.listingPageLoaded());
        });
    } catch {
      dispatch(actions.setNotFoundPage());
    }
  };

export default listingSharePageLoad;
export type Actions = ReceiveShareListingAction;
