import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import {
  OnlyInDesktopTablet,
  OnlyInMobile,
  Notification,
  OnlyInTabletMobile,
} from '@components/shared';
import { Address, BuildingListing, MultipathImage, SpaceProvider } from '@root/types';
import { Button, Tooltip } from '@viewthespace/components';
import useCurrentUser from '@root/shared/useCurrentUser';
import { useRef } from 'react';
import useSize from '@react-hook/size';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import routes from '@root/routes';
import { PARAMETERS } from '@root/tracking/constants';
import analyticsActions from '@store/actions/saved/analytics';
import useAnalytics from '@root/shared/useAnalytics';
import i18next from 'i18next';
import UtmAwareLink from '@components/shared/UtmAwareLink/UtmAwareLink';
import { create, destroy } from '@shared/typedApi';

type Props = {
  address: Address;
  buildingSlug: string;
  isSaved: boolean;
  neighborhood: string | null;
  bannerImage: MultipathImage | null;
  lettermarkLogo: MultipathImage | null;
  owningCompany: SpaceProvider | null;
  onClickAvailability: () => void;
  listings: BuildingListing[];
};

const Separator = ({
  pipe = false,
  alwaysShow = false,
}: {
  pipe?: boolean;
  alwaysShow?: boolean;
}) => (
  <span
    className={cn(
      'inline-block px-1 py-0',
      pipe && 'w-2.5  text-general-neutral-tertiary',
      alwaysShow && 'mobile:inline',
    )}
  >
    {pipe ? '|' : '-'}
  </span>
);

const Header = ({
  address,
  bannerImage,
  isSaved,
  lettermarkLogo,
  buildingSlug,
  owningCompany,
  onClickAvailability,
  listings,
  neighborhood = null,
}: Props) => {
  const { t } = useTranslation('building');

  const { currentUser } = useCurrentUser();
  const hasCurrentUser = !!currentUser;

  const buildingDetailsRef = useRef<HTMLDivElement | null>(null);
  const buildingNameRef = useRef<HTMLHeadingElement | null>(null);
  const buildingDetailsHeight = useSize(buildingDetailsRef)[1];
  const buildingNameHeight = useSize(buildingNameRef)[1];

  const { saveInteraction } = useAnalytics();

  const queryClient = useQueryClient();
  const { clickToPage } = useAnalytics();

  const toggleSaveBuildingMutation = useMutation(
    async (shouldBeSaved: boolean) => {
      if (shouldBeSaved) {
        await create(routes.api.buildingSave(buildingSlug), {});
      } else {
        await destroy(routes.api.buildingSave(buildingSlug), {});
      }
    },
    {
      onSuccess: async (_, wasSaved) => {
        saveInteraction({
          action: wasSaved ? PARAMETERS.saveItem : PARAMETERS.unsaveItem,
          sourcePage: PARAMETERS.buildingPage,
        });
        if (wasSaved) {
          Notification.saveSuccess({
            title: i18next.t('building:saved'),
            /* eslint-disable react/jsx-no-literals */
            text: (
              <Trans i18nKey="viewInFavorites" ns="building">
                View it in your
                <a
                  href="/saved/buildings"
                  onClick={analyticsActions.clickToSavedBuildingsFromToast}
                >
                  saved buildings
                </a>
                .
              </Trans>
            ),
            /* eslint-enable react/jsx-no-literals */
          });
        }
        await queryClient.invalidateQueries([routes.api.saveBuilding()]);
      },
    },
  );

  const renderCityLocalityRegion = (hasLettermarkLogo: boolean) => (
    <>
      <OnlyInDesktopTablet>
        {neighborhood ? (
          <>
            {neighborhood}
            <Separator pipe alwaysShow />
          </>
        ) : null}
        {t('common:cityLocalityRegion', { address })}
        <Separator pipe alwaysShow />
        {t(`country:${address.countryCode}`)}
      </OnlyInDesktopTablet>
      <OnlyInMobile>
        {neighborhood ? (
          <div className={cn(hasLettermarkLogo && 'mt-[-2px]', 'inline')}>
            <>
              {neighborhood}
              {hasLettermarkLogo ? <br></br> : <Separator pipe alwaysShow />}
            </>
          </div>
        ) : null}
        {t('common:cityLocalityRegion', { address })}
        <Separator pipe alwaysShow />
        {t(`country:${address.countryCode}`)}
      </OnlyInMobile>
    </>
  );

  return (
    <div
      className={cn(
        'flex flex-col',
        bannerImage && 'desktop:pt-1 tablet:pt-1 mobile:mx-[-16px] mobile:mt-0',
      )}
      data-testid="building-header-section"
    >
      {bannerImage ? (
        <>
          <img
            className={cn(
              'h-[190px] rounded-[3px] object-cover tablet:h-[102px] mobile:h-[56px] mobile:rounded-[0px]',
              'w-full max-w-[1440px]',
            )}
            src={bannerImage.rawPath}
            alt={bannerImage.description}
          />
        </>
      ) : null}
      <div
        className={cn(
          'flex desktop:mb-3 tablet:mb-3',
          !bannerImage && lettermarkLogo && 'mt-2',
          bannerImage && !lettermarkLogo && 'mt-1 mobile:mx-2',
          bannerImage && lettermarkLogo && 'mt-[-16px] desktop:mt-[-30px] tablet:!mb-2',
        )}
      >
        {lettermarkLogo ? (
          <img
            className={cn(
              'mr-2 h-[90px] w-[90px] rounded-[3px] bg-white object-cover desktop:h-[110px] desktop:w-[110px]',
              bannerImage &&
                '!pointer-events-auto ml-2 border-[3px] border-solid border-white mobile:!top-[88px]',
            )}
            src={lettermarkLogo.rawPath}
            alt={lettermarkLogo.description}
          />
        ) : null}
        <div
          className={cn(
            'flex flex-col',
            'desktop:mr-2 tablet:mr-2 mobile:w-full',
            !bannerImage && lettermarkLogo && 'desktop:mt-2.5 tablet:mt-2.5',
            !bannerImage &&
              lettermarkLogo &&
              (buildingDetailsHeight >= 64 && buildingDetailsHeight <= 80
                ? 'mobile:mt-2'
                : 'mobile:mt-0'),
            !bannerImage &&
              lettermarkLogo &&
              buildingNameHeight > 40 &&
              'desktop:!mt-0 tablet:!mt-0',
            !bannerImage && !lettermarkLogo && 'mt-2',
            bannerImage && lettermarkLogo && 'desktop:mt-5 tablet:mt-3 mobile:mt-2.5',
          )}
          ref={buildingDetailsRef}
        >
          <div className="flex mobile:w-full mobile:justify-between">
            <h1
              ref={buildingNameRef}
              className="desktop:font-headline tablet:font-subtitle mobile:font-body-large-emphasis"
            >
              {address.buildingName || address.street}
              {address.buildingName ? (
                <div className="inline">
                  <Separator />
                  {address.street}
                </div>
              ) : null}
            </h1>
            {hasCurrentUser ? (
              <Tooltip
                placement="bottom"
                content={t('saveTooltip')}
                trigger={
                  <div className={cn('ml-1 pt-[2px] mobile:!ml-0 mobile:mr-2')}>
                    <Button
                      iconName={isSaved ? 'HeartFilled' : 'HeartDefault'}
                      variant="secondary-neutral"
                      iconTitle="save-building-button"
                      className={cn(
                        'h-3 w-3 desktop:h-4 desktop:w-4 mobile:[&>svg]:h-[12px] mobile:[&>svg]:w-[12px]',
                        'tablet:[&>svg]:h-[12px] tablet:[&>svg]:w-[12px]',
                        'border border-solid border-black-035',
                        isSaved && '[&>svg]:text-[#5528ff]',
                      )}
                      size="small"
                      backgroundStyle="filled"
                      name="save-building-button"
                      onClick={() => toggleSaveBuildingMutation.mutate(!isSaved)}
                    />
                  </div>
                }
                triggerAction="hover"
              />
            ) : null}
          </div>

          <h2 className="inline desktop:font-subtitle-de-emphasis tablet:font-body-medium mobile:font-body-medium">
            {renderCityLocalityRegion(!!lettermarkLogo)}
          </h2>
        </div>
        <OnlyInDesktopTablet>
          <div
            className={cn(
              'desktop: mr-1 flex desktop:ml-auto tablet:ml-auto',
              !bannerImage && lettermarkLogo && 'desktop:mt-2 tablet:mt-2.5',
              !bannerImage &&
                lettermarkLogo &&
                buildingNameHeight > 40 &&
                'desktop:!mt-0 tablet:!mt-0',
              !bannerImage && !lettermarkLogo && 'mt-2',
              bannerImage && lettermarkLogo && 'desktop:mt-5 tablet:hidden',
            )}
          >
            {owningCompany?.slug ? (
              <UtmAwareLink
                className="text-indigo-100 hover:text-indigo-90"
                to={routes.landlords(owningCompany.slug)}
                onClick={() => {
                  clickToPage({
                    actionType: 'CLICKED_TO_BRAND_PAGE',
                    sourcePage: PARAMETERS.buildingPage,
                    sourceContent: PARAMETERS.viewPortfolio,
                    destination: PARAMETERS.landlordPage,
                  });
                }}
              >
                <Button
                  name="view-portfolio"
                  className="mr-1 border !border-solid !border-black-020 font-body-large desktop:h-5 desktop:w-[148px] tablet:h-3.5 tablet:w-[128px] tablet:font-body-medium desktop:[&>div]:font-body-large"
                  variant="secondary"
                  size="large"
                  text={t('header.viewPortfolio')!}
                  ariaLabel={t('header.viewPortfolio')}
                />
              </UtmAwareLink>
            ) : null}
            {listings && listings?.length ? (
              <Button
                variant="primary"
                size="large"
                ariaLabel={t('header.viewAvailability')!}
                text={t('header.viewAvailability')}
                className="font-body-large desktop:h-5 desktop:w-[164px] tablet:h-3.5 tablet:w-[133px] tablet:font-body-medium desktop:[&>div]:font-body-large"
                onClick={onClickAvailability}
              />
            ) : null}
          </div>
        </OnlyInDesktopTablet>
      </div>
      <OnlyInTabletMobile>
        <div
          className={cn(
            'mb-2 flex mobile:mt-2',
            bannerImage && 'mx-2',
            (!bannerImage || !lettermarkLogo) && 'tablet:hidden',
          )}
        >
          {owningCompany?.slug ? (
            <UtmAwareLink
              className="flex"
              to={routes.landlords(owningCompany.slug)}
              onClick={() => {
                clickToPage({
                  actionType: 'CLICKED_TO_BRAND_PAGE',
                  sourcePage: PARAMETERS.buildingPage,
                  sourceContent: PARAMETERS.viewPortfolio,
                  destination: PARAMETERS.landlordPage,
                });
              }}
            >
              <Button
                name="view-portfolio"
                className="mr-1 h-3.5 w-[120px] border !border-solid !border-black-020 tablet:w-[128px] tablet:font-body-medium"
                variant="secondary"
                size="medium"
                text={t('header.viewPortfolio')!}
                ariaLabel={t('header.viewPortfolio')}
              />
            </UtmAwareLink>
          ) : null}
          {listings && listings?.length ? (
            <Button
              variant="primary"
              size="medium"
              ariaLabel={t('header.viewAvailability')!}
              text={t('header.viewAvailability')}
              className="h-3.5 w-[133px] tablet:font-body-medium"
              onClick={onClickAvailability}
            />
          ) : null}
        </div>
      </OnlyInTabletMobile>
    </div>
  );
};

export default Header;
