import { useState, useEffect } from 'react';
import Container from '@components/shared/Admin/Container';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Company } from '@root/types';
import routes from '@root/routes';
import { useBreadcrumbs } from '@components/shared/withBreadcrumbs';
import api from '@shared/api';
import Form from '../Form';
import Layout from '../../Layout/Layout';

const fetchCompany = async ({ id, callback }) => {
  const response = await api.fetch(routes.api.admin.company(id));
  callback(await response.json());
};

export const EditPage = () => {
  const { t } = useTranslation('admin');
  const { id } = useParams<{ id: string }>();

  const [company, setCompany] = useState<Company | null>(null);
  useEffect(() => {
    fetchCompany({ id, callback: setCompany });
  }, [id, setCompany]);

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    if (!company) return;

    setBreadcrumbs([t('breadcrumbs.admin'), t('breadcrumbs.companies'), company.name]);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  if (!company) return null;
  return (
    <Layout>
      <Container title={t('companies.editCompany')}>
        <Form company={company} submitText={t('common.updateButton')} />
      </Container>
    </Layout>
  );
};

export default EditPage;
