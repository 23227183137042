import { useTranslation } from 'react-i18next';
import { CurrentUser } from '@root/types';
import { Toggle, Form } from '@components/shared/forms';
import { update } from '@root/shared/typedApi';
import routes from '@root/routes';
import { useMutation } from '@tanstack/react-query';
import { Divider, Notification, PopperTooltip } from '@components/shared';
import useCurrentUser from '@root/shared/useCurrentUser';
import * as Yup from 'yup';
import useEnv from '@root/shared/useEnv';
import FormSubmitSection from './FormSubmitSection';

type Props = {
  currentUser: CurrentUser;
};

type FormValues = {
  tourbookEmailFrequency: boolean;
};

const EmailNotifications = ({ currentUser }: Props) => {
  const { t } = useTranslation('accountSettings');
  const { refetch: refetchUser } = useCurrentUser();
  const { vtsUrl } = useEnv();

  const tourbookNotificationsEnabled = (tourbookEmailFrequency: string | null): boolean => {
    return tourbookEmailFrequency === 'daily';
  };

  const updateNotificationSettingsMutation = useMutation(
    async ({ user }: { user: Partial<CurrentUser> }) => {
      await update(routes.api.currentUser, user);
    },
    {
      onSuccess: async () => {
        refetchUser();
        Notification.info({
          title: t('emailNotifications.notification.title'),
          text: t('emailNotifications.notification.text'),
        });
      },
    },
  );

  const updateNotificationSettings = (formValues: FormValues) => {
    updateNotificationSettingsMutation.mutate({
      user: {
        id: currentUser.id,
        ...formValues,
        tourbookEmailFrequency: formValues.tourbookEmailFrequency ? 'daily' : 'never',
      },
    });
  };

  const validationSchema = Yup.object().shape({
    tourbookEmailFrequency: Yup.boolean(),
  });

  return (
    <Form
      id="notificationSettingsForm"
      enableReinitialize
      initialValues={{
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        jobTitle: currentUser.jobTitle,
        phoneNumber: currentUser.phoneNumber,
        phoneExtension: currentUser?.phoneExtension,
        tourbookEmailFrequency: currentUser.tourbookEmailFrequency === 'daily',
      }}
      validationSchema={validationSchema}
      onSubmit={updateNotificationSettings}
    >
      <div className="mb-2.5 flex">
        <div className="flex w-6/12 flex-col tablet:w-8/12 mobile:w-10/12">
          <span className="font-body-large-emphasis">{t('emailNotifications.tourbook.title')}</span>
          <span className="font-body-medium">{t('emailNotifications.tourbook.description')}</span>
        </div>
        <div className="pt-1">
          {currentUser.receivesEmail ? (
            <Toggle
              dataTestId="tourbookNotificationsToggle"
              name="tourbookEmailFrequency"
              labelText={
                tourbookNotificationsEnabled(currentUser.tourbookEmailFrequency)
                  ? t('emailNotifications.tourbook.labelTextEnabled')
                  : t('emailNotifications.tourbook.labelTextDisabled')
              }
            />
          ) : (
            <PopperTooltip
              showArrow
              trigger="click"
              toolTipPlacement="top"
              popperContentClassName="ml-[-16px]"
              triggerElement={
                <Toggle
                  dataTestId="tourbookNotificationsToggle"
                  name="tourbookEmailFrequency"
                  disabled
                  labelText={
                    tourbookNotificationsEnabled(currentUser.tourbookEmailFrequency)
                      ? t('emailNotifications.tourbook.labelTextEnabled')
                      : t('emailNotifications.tourbook.labelTextDisabled')
                  }
                />
              }
              popperElementClassName="!bg-[#000000] desktop:!max-w-none tablet:!max-w-none !text-white"
              popperElement={
                <span className="!text-white font-body-medium">
                  {t('emailNotifications.tourbook.disabled')}{' '}
                  <a
                    href={`${vtsUrl}/user-settings/email-notifications`}
                    className="!text-text-link-darkmode"
                  >
                    {t('emailNotifications.tourbook.vtsSettings')}
                  </a>
                </span>
              }
            />
          )}
        </div>
      </div>
      <Divider />
      <FormSubmitSection submittingText={t('common:saving')} />
    </Form>
  );
};

export default EmailNotifications;
