import { ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import { useBreakpoint } from '@shared/useBreakpoints';
import { Placement } from '@popperjs/core';
import { usePopperTooltip, TriggerType } from 'react-popper-tooltip';
import cn from 'classnames';
import s from './PopperTooltip.module.less';
import CustomIcon from '../CustomIcon';

export type PopperTooltipProps = {
  triggerElement: ReactNode;
  onClick?: (e) => void;
  onClose?: () => void;
  popperElement: ReactNode;
  trigger: TriggerType;
  toolTipPlacement?: Placement;
  triggerElementClassName?: string;
  popperElementClassName?: string;
  popperContentClassName?: string;
  offset?: [number, number];
  showArrow?: boolean;
  showCloseButton?: boolean;
  ignoreTouchscreenClicks?: boolean;
  arrowElementClassName?: string;
};

const PopperTooltip = ({
  triggerElement,
  popperElement,
  trigger,
  toolTipPlacement = 'right-start',
  triggerElementClassName,
  popperElementClassName,
  popperContentClassName,
  offset,
  showArrow,
  showCloseButton,
  ignoreTouchscreenClicks = false,
  arrowElementClassName,
  onClose = () => {},
  onClick,
}: PopperTooltipProps) => {
  // When visible is set to undefined, react-popper-tooltip will handle the visibiity state
  const [isVisible, setIsVisible] = useState<boolean | undefined>(undefined);
  const { isMobile } = useBreakpoint();

  const closeTooltip = () => {
    setIsVisible(false);
    onClose();
  };

  const handleOnVisibleChange = visible => {
    if (!visible) {
      onClose();
    }
    // eslint-disable-next-line max-len
    // Give back control of the visibility state to react-popper-tooltip if the tooltip had been previously closed with the close button
    setIsVisible(undefined);
  };

  const { getArrowProps, getTooltipProps, setTriggerRef, setTooltipRef, visible } =
    usePopperTooltip({
      placement: toolTipPlacement,
      delayHide: 200,
      delayShow: 200,
      closeOnOutsideClick: true,
      trigger,
      visible: isVisible,
      onVisibleChange: handleOnVisibleChange,
      offset: offset || (!isMobile && [0, 10]) || undefined,
    });

  const handleClickTriggerElement = e => {
    onClick?.(e);
    switch (trigger) {
      case 'hover':
        // Force close tooltip when hover trigger is clicked
        closeTooltip();
        break;
      default:
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  };

  const handleClickCloseButton = e => {
    e.preventDefault();
    e.stopPropagation();
    closeTooltip();
  };

  const CloseButton = (
    <button
      data-testid="tooltip-close-button"
      type="button"
      className={s.tooltipCloseButton}
      onClick={handleClickCloseButton}
    >
      <CustomIcon type="close" />
    </button>
  );

  const Arrow = (
    <div {...getArrowProps({ className: cn(s.tooltipArrow, arrowElementClassName) })} />
  );

  return (
    <>
      <div
        className={cn(s.popperTooltipTarget, triggerElementClassName, [visible && s.popperVisible])}
        ref={setTriggerRef}
        onClick={handleClickTriggerElement}
        role="button"
      >
        {triggerElement}
      </div>
      {visible &&
        createPortal(
          <div
            ref={setTooltipRef}
            {...getTooltipProps({ className: cn(s.tooltipContent, popperContentClassName) })}
          >
            {/* Using the IconButton component within this component causes
            infintite re-renders */}
            {showCloseButton && CloseButton}
            <div
              className={cn(
                s.popperElementWrapper,
                popperElementClassName,
                ignoreTouchscreenClicks && s.ignoreTouchscreenClicks,
                'font-body-medium',
              )}
            >
              {popperElement}
            </div>
            {showArrow && Arrow}
          </div>,
          document.body,
        )}
    </>
  );
};

export default PopperTooltip;
