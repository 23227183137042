import { PageHeader } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { SortableList, SortableListItem } from '@components/shared/sortable';
import ListingAgentOption from '@components/shared/ListingAgentOption/ListingAgentOption';
import { AgentContact } from '@root/types';
import s from './BuildingContacts.module.less';

const Contacts = ({ data, onSort }) => {
  return (
    <SortableList onSort={onSort}>
      {data.map((datum, index) => (
        // eslint-disable-next-line max-len
        // @ts-expect-error ts-migrate(2741) FIXME: Property 'index' is missing in type '{ children: E... Remove this comment to see the full error message
        <SortableListItem
          className={cn({ [s.agentWrapper]: true, [s.topElement]: index === 0 })}
          sortableId={datum.sortableId}
          key={datum.sortableId}
        >
          <div>
            <ListingAgentOption displayAgentContact listingAgent={datum} />
          </div>
        </SortableListItem>
      ))}
    </SortableList>
  );
};

type Props = {
  data: Array<AgentContact>;
  onSort: Function;
};

const BuildingContacts = ({ data = [], onSort }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <div className={s.buildingContacts}>
      <PageHeader className={s.buildingContactsHeader} title={t(`building.menuItems.contacts`)} />
      <p>{t('building.contacts.howToChange')}</p>
      <Contacts data={data} onSort={onSort} />
    </div>
  );
};

export default BuildingContacts;
