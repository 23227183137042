import Checkbox from '@components/shared/forms/RawCheckbox/Checkbox';
import Select, { components } from 'react-select';
import COLORS from '@root/shared/colorConstants';
import { CustomIcon } from '@components/shared';
import { useBreakpoint } from '@shared/useBreakpoints';
import cn from 'classnames';
import { OptionTypeBase, ValueType, OptionType } from '@components/shared/forms/Dropdowns/types';

type Props = {
  options: OptionType[];
  selectedOptions: OptionType[];
  onSelect: (val: ValueType<OptionTypeBase>) => void;
  controlText: string;
};

const MultiSelectCheckboxDropdown = ({
  options,
  selectedOptions,
  onSelect,
  controlText,
}: Props) => {
  const { isMobile } = useBreakpoint();

  const customStyles = {
    container: base => ({
      ...base,
      minWidth: '93px',
    }),
    control: () => ({
      display: 'flex',
      alignItems: 'flex-start',
      cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: base => ({
      ...base,
      fontSize: '14px',
      color: COLORS.black100,
      lineHeight: '20px',
    }),
    menu: base => ({
      ...base,
      minWidth: '209px',
      cursor: 'pointer',
      right: !isMobile && '0px',
    }),
    option: (base, state) => ({
      ...base,
      cursor: 'pointer',
      backgroundColor: state.isSelected && COLORS.white,
      '&:active': {
        backgroundColor: COLORS.egg50,
      },
      '&:hover': {
        backgroundColor: COLORS.egg50,
      },
      '&:focus': {
        backgroundColor: COLORS.egg50,
      },
    }),
    valueContainer: base => ({
      ...base,
      paddingLeft: '0px',
    }),
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CustomIcon
          className="[&>svg]:h-[14px] [&>svg]:w-[14px] [&>svg]:text-black-100"
          type={props.selectProps.menuIsOpen ? 'chevron-up' : 'chevron-down'}
        />
      </components.DropdownIndicator>
    );
  };

  const Option = props => {
    const { children, isSelected, selectOption } = props;
    return (
      <components.Option {...props} className={cn(isSelected && 'bg-egg-50')}>
        <li className="flex list-none " role="option" aria-selected={isSelected}>
          <Checkbox
            checked={isSelected}
            size="small"
            onChange={selectOption}
            name={children}
            value={children}
          />
          <div className="text-black-100 font-body-medium">{children}</div>
        </li>
      </components.Option>
    );
  };

  return (
    <Select
      options={options}
      isMulti
      components={{ Option, DropdownIndicator }}
      isSearchable={false}
      placeholder={controlText}
      styles={customStyles}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      closeMenuOnSelect={false}
      value={selectedOptions}
      onChange={newValue => onSelect(newValue)}
    />
  );
};

export default MultiSelectCheckboxDropdown;
