import { createInfoWindow } from '@shared/googleMaps';
import { BuildingSearchResult, Listing, TourbookListing } from '@root/types';
import MarkerGenerator from '@root/shared/map/MarkerGenerator/MarkerGenerator';
import s from './BuildingInfoWindow/InfoWindow.module.less';

// This is the map marker and infowindow that appear when you hover
//  over a listing card in desktop view.

// this is just the max zoom level for google street map
const MAX_ZOOM_LEVEL = 19;

type IndicatorOptions = {
  disableAutoPan?: boolean;
  enableZoomToPoint?: boolean;
  mapMarkerText?: string | null;
  shouldUseSearchRedesignMarkerStyles?: boolean;
  hideNumbers?: boolean;
};

class HoveredListingIndicator {
  marker: google.maps.Marker | null;

  infoWindow: google.maps.InfoWindow | null;

  markerGenerator: MarkerGenerator;

  enableZoomToPoint: boolean;

  mapMarkerText: string | null;

  constructor({
    disableAutoPan = false,
    enableZoomToPoint = false,
    mapMarkerText = null,
    shouldUseSearchRedesignMarkerStyles = false,
    hideNumbers = false,
  }: IndicatorOptions) {
    this.marker = null;
    this.enableZoomToPoint = enableZoomToPoint;

    const infoWindowOptions: google.maps.InfoWindowOptions = {};

    infoWindowOptions.disableAutoPan = disableAutoPan;

    this.infoWindow = createInfoWindow(infoWindowOptions);
    this.markerGenerator = new MarkerGenerator({
      shouldUseSearchRedesignStyles: shouldUseSearchRedesignMarkerStyles,
      hideNumbers,
    });
    this.mapMarkerText = mapMarkerText;
  }

  addHoveredListingMarker = (
    building: BuildingSearchResult | Listing | TourbookListing,
    map: google.maps.Map,
  ) => {
    this.clearHoveredListingMarker();

    if (building) {
      this.marker = this.markerGenerator.createHoveredListingIndicator(map, building);
      this.marker?.setIcon(this.markerGenerator.getHoveredListingIndicatorIcon(building));

      this.setHoveredListingInfoWindow(map);

      if (this.enableZoomToPoint && this.marker) {
        map.setZoom(MAX_ZOOM_LEVEL - 3);
        map.panTo(this.marker.getPosition() as google.maps.LatLng);
      }
    }
  };

  clearHoveredListingMarker = () => {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;

      this.clearHoveredListingInfoWindow();
    }
  };

  setHoveredListingInfoWindow = map => {
    if (this.marker) {
      const listingIndicatorInfowWindow = `
      <div class="${s.hoveredListingIndicatorInfoWindow}">
        ${this.mapMarkerText}
      </div>`;

      this.infoWindow?.setContent(listingIndicatorInfowWindow);
      this.infoWindow?.open(map, this.marker);
    }
  };

  clearHoveredListingInfoWindow = () => {
    this.infoWindow?.close();
  };
}

export default HoveredListingIndicator;
