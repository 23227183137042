import * as React from 'react';
import { Tabs as AntdTabs } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';

type Props = { tabs: string[]; children: React.ReactNode; destroyInactiveTabPane?: boolean };

const useTabs = (tabs: string[]) => {
  const location = useLocation();
  const history = useHistory();

  const queryString = parse(location.search, { ignoreQueryPrefix: true });
  let currentTab = queryString.page || tabs[0];

  if (!tabs.includes(currentTab as string)) {
    /* eslint-disable no-console */
    console.warn(
      `Invalid page queryParam given. Expected ${tabs
        .map(tab => `'${tab}'`)
        .join(' or ')}. Given '${currentTab}'`,
    );
    /* eslint-enable no-console */
    [currentTab] = tabs;
  }

  const changeTab = (tab: string) => {
    history.push(`${location.pathname}?page=${tab}`);
  };

  return { changeTab, currentTab };
};

export function QueryStringTabs({ tabs, children, destroyInactiveTabPane = false }: Props) {
  const { changeTab, currentTab } = useTabs(tabs);

  return (
    <AntdTabs
      onChange={changeTab}
      activeKey={currentTab as string}
      destroyInactiveTabPane={destroyInactiveTabPane}
    >
      {children}
    </AntdTabs>
  );
}

export function StatefulTabs({ tabs, children, destroyInactiveTabPane = false }: Props) {
  const [currentTab, changeTab] = React.useState(tabs[0]);

  return (
    <AntdTabs
      onChange={changeTab}
      activeKey={currentTab}
      destroyInactiveTabPane={destroyInactiveTabPane}
    >
      {children}
    </AntdTabs>
  );
}

// eslint-disable-next-line prefer-destructuring
export const TabPane = AntdTabs.TabPane;
