import cn from 'classnames';
import { Link } from 'react-router-dom';
import s from './Tabs.module.less';
import { TabOption } from './Tabs';

type TabOptionWithLocation = TabOption & { location: string };

const TabNav = ({
  tabs,
  currentTab,
  changeTab,
  ulClassName,
  liClassName,
  liSelectedClassName,
}: {
  tabs: Array<TabOption | TabOptionWithLocation>;
  currentTab: string;
  changeTab: (tab: string) => void;
  ulClassName?: string;
  liClassName?: string;
  liSelectedClassName?: string;
}) => (
  <ul className={cn(s.tabNavList, ulClassName)}>
    {tabs.map(tab => (
      <li
        key={tab.value}
        className={cn(
          s.tabNavItem,
          liClassName,
          currentTab === tab.value && (liSelectedClassName || s.selected),
        )}
      >
        {'location' in tab ? (
          <Link to={tab.location}>{tab.label}</Link>
        ) : (
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              changeTab(tab.value);
            }}
          >
            {tab.label}
          </button>
        )}
      </li>
    ))}
  </ul>
);

export default TabNav;
