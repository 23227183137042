import { Button, Notification } from '@components/shared';
import { create } from '@shared/typedApi';
import routes from '@root/routes';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MicrositeLayouts } from '@root/types';
import MultiPageWebsite from './MultiPageWebsite.svg';
import SinglePageWebsite from './SinglePageWebsite.svg';

type Props = {
  refetch: () => void;
  buildingId: string;
};

export default function NewMicrosite({ refetch, buildingId }: Props) {
  const { t } = useTranslation('admin');
  const flags = useFlags();

  const layouts: MicrositeLayouts[] = ['multi_page', 'single_page'];

  const componentMappings = {
    single_page: { Component: SinglePageWebsite },
    multi_page: { Component: MultiPageWebsite },
  };

  const mutation = useMutation(
    (layout: MicrositeLayouts) =>
      create(routes.api.admin.buildingMicrosite(buildingId), { layout }),
    {
      onSuccess: () => {
        Notification.success({
          title: t('common:changesSaved'),
          placement: 'topRight',
        });
        refetch();
      },
    },
  );

  return (
    <section>
      {flags['market-office.flexible-layouts-on-microsite'] ? (
        <div>
          <div className="font-title pb-4">{t('microsite.create.title')}</div>
          <div className="inline-flex gap-4 flex-wrap">
            {layouts.map(layout => {
              const IllustrationComponent = componentMappings[layout].Component;
              return (
                <button
                  type="button"
                  key={layout}
                  aria-label={t(`microsite.create.${layout}.title`)}
                  onClick={() => mutation.mutate(layout)}
                  className="p-3 bg-background-secondary border border-solid border-general-neutral-tertiary rounded-[3px] w-[530px] hover:bg-general-indigo-quaternary hover:border-general-indigo-primary"
                >
                  <div className="font-subtitle flex pb-1">
                    {t(`microsite.create.${layout}.title`)}
                  </div>
                  <div className="inline-flex gap-4">
                    <div className="flex flex-col w-[270px] gap-2">
                      <p className="text-left font-body-medium">
                        {t(`microsite.create.${layout}.description`)}
                      </p>
                      <ul className="list-disc text-left pl-2">
                        <li className="font-body-medium">
                          <span>{t(`microsite.create.${layout}.subDescription1`)}</span>
                        </li>
                        <li className="font-body-medium">
                          <span>{t(`microsite.create.${layout}.subDescription2`)}</span>
                        </li>
                      </ul>
                    </div>
                    <IllustrationComponent className="w-[180px] h-[180px]" />
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      ) : (
        <Button onClick={() => mutation.mutate('multi_page')}>{t('microsite.createText')}</Button>
      )}
    </section>
  );
}
