import routes from '@root/routes';
import { parse, stringify } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

type Tabs = 'location' | 'media' | 'listing';

export default function useTabs({ tourbookId }: { tourbookId: string }) {
  const { search } = useLocation();
  const queryParams = parse(search, { ignoreQueryPrefix: true });

  const currentPage: Tabs = (queryParams.page as Tabs | undefined) || 'location';

  const routeWithPage = (page: Tabs) =>
    `${routes.tourbookNewExternalListing(tourbookId)}${stringify(
      { page },
      { addQueryPrefix: true },
    )}`;
  const history = useHistory();
  const goTo = (page: Tabs) => () => history.push(routeWithPage(page));

  return {
    currentPage,
    isOnLocation: currentPage === 'location',
    isOnMedia: currentPage === 'media',
    isOnListing: currentPage === 'listing',
    goToLocation: goTo('location'),
    goToMedia: goTo('media'),
    goToListing: goTo('listing'),
  };
}
