import { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import actions from '@store/actions/saved';
import globalActions from '@store/actions';
import { StoreState, SavedSearch } from '@root/types';
import { TabPane, ConfirmationModal, Notification, Spinner } from '@components/shared';
import { getSavedSearchesLoading, getSavedSearches } from '@store/selectors';
import { RawToggle } from '@components/shared/forms';
import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import Layout from '../Layout';
import SearchRow from './SearchRow';
import s from './Searches.module.less';

const mapState = (state: StoreState) => ({
  savedSearches: getSavedSearches(state),
  isLoading: getSavedSearchesLoading(state),
});

const mapDispatch = dispatch => ({
  deleteSavedSearch: (search: SavedSearch, { onSuccess, onFailure }) =>
    dispatch(actions.deleteSavedSearch(search, { onSuccess, onFailure })),
  goToSavedSearch: () => {
    dispatch(globalActions.setDrawnPolygons([]));
    dispatch(actions.goToSavedSearch());
  },
  loadPage: () => dispatch(actions.loadSavedSearchesPage()),
  toggleNotification: (id: string, receivesNotifications: boolean) =>
    dispatch(actions.updateSavedSearch(id, { receivesNotifications })),
  toggleAllNotifications: (receivesNotifications: boolean) =>
    dispatch(actions.updateAllSavedSearchNotifications(receivesNotifications)),
});
const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const defaultModalState = { isOpen: false, search: null };
export const RawSearches = ({
  savedSearches,
  deleteSavedSearch,
  goToSavedSearch,
  loadPage,
  isLoading,
  toggleNotification,
  toggleAllNotifications,
}: ReduxProps) => {
  useEffect(() => {
    loadPage();
  }, [loadPage]);
  const [idOfSavedSearchBeingEdited, setIdOfSavedSearchBeingEdited] = useState<string | null>(null);
  const { t } = useTranslation('favorites');
  const { savedSearchInteraction } = useAnalytics();

  const [modalState, setModalState] = useState<{ isOpen: boolean; search: SavedSearch | null }>(
    defaultModalState,
  );

  const onDeleteClick = (search: SavedSearch) => {
    setModalState({ isOpen: true, search });
  };

  const resetModalState = () => setModalState(defaultModalState);
  const closeModal = () => setModalState({ ...modalState, isOpen: false });
  const onConfirmDelete = () => {
    closeModal();
    deleteSavedSearch(modalState.search as SavedSearch, {
      onSuccess: () => {
        Notification.success({
          title: t('deleteSavedSearchSuccess'),
        });
        resetModalState();
        setIdOfSavedSearchBeingEdited(null);
      },
      onFailure: () => {
        Notification.info({
          title: t('common:genericFailureTitle'),
          text: t('common:genericFailureMessage'),
        });
        resetModalState();
      },
    });
  };

  const isToggleAllAlertsEnabled = useMemo(
    () => savedSearches.some(search => search.receivesNotifications),
    [savedSearches],
  );

  const nameOfDeletingSavedSearch = modalState.search
    ? modalState.search.name || modalState.search.description
    : '';

  return (
    <Layout active="searches">
      <TabPane key="searches">
        <div className={s.searchesContainer}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <h1 data-testid="savedSearchCount" className={s.title}>
                {/* eslint-disable react/jsx-no-literals */}
                <Trans i18nKey="favorites:numSavedSearches" count={savedSearches.length}>
                  <span className="u-translation--bold">{{ count: savedSearches.length }}</span>{' '}
                  Saved Searches
                </Trans>
                {/* eslint-enable react/jsx-no-literals */}
              </h1>
              <ConfirmationModal
                title={t('deleteSavedSearch')}
                confirmationText={t('confirmDelete', {
                  name: nameOfDeletingSavedSearch,
                })}
                onClose={resetModalState}
                onConfirm={onConfirmDelete}
                isOpen={modalState.isOpen}
                cancelButtonText={t('common:cancel')}
                confirmButtonText={t('common:delete')}
              />
              {savedSearches.length > 0 && (
                <div className={s.searches}>
                  <div className={s.headerRow}>
                    <div className={s.name} />
                    <div className={s.alerts}>
                      <RawToggle
                        name="toggleAllAlerts"
                        dataTestId="toggleAllAlerts"
                        checked={isToggleAllAlertsEnabled}
                        onChange={() => toggleAllNotifications(!isToggleAllAlertsEnabled)}
                        disabled={idOfSavedSearchBeingEdited !== null}
                      />
                      <div className={s.alertsHeaderText}>{t('emailAlerts')}</div>
                    </div>
                    <div className={s.edit}>{t('edit')}</div>
                    <div className={s.delete}>{t('delete')}</div>
                  </div>
                  <ul>
                    {savedSearches.map(search => (
                      <SearchRow
                        key={search.id}
                        search={search}
                        goToSavedSearch={() => {
                          savedSearchInteraction({
                            actionType: 'SELECT_SAVED_SEARCH',
                            action: PARAMETERS.selectSavedSearch,
                            sourcePage: PARAMETERS.savedSearchPage,
                            savedSearchCount: savedSearches.length,
                            savedSearchName: search.name ?? search.description,
                            savedSearchId: search.id,
                          });
                          goToSavedSearch();
                        }}
                        onDeleteClick={savedSearch => {
                          savedSearchInteraction({
                            actionType: 'DELETE_SAVED_SEARCH',
                            action: PARAMETERS.deleteSavedSearch,
                            sourcePage: PARAMETERS.savedSearchPage,
                            savedSearchCount: savedSearches.length,
                            savedSearchName: search.name ?? search.description,
                            savedSearchId: search.id,
                          });
                          onDeleteClick(savedSearch);
                        }}
                        toggleNotification={(id, receivesNotifications) => {
                          savedSearchInteraction({
                            actionType: search.receivesNotifications
                              ? 'TURN_OFF_ALERTS'
                              : 'TURN_ON_ALERTS',
                            action: search.receivesNotifications
                              ? PARAMETERS.turnOffAlerts
                              : PARAMETERS.turnOnAlerts,
                            sourcePage: PARAMETERS.savedSearchPage,
                            savedSearchCount: savedSearches.length,
                            savedSearchName: search.name ?? search.description,
                            savedSearchId: search.id,
                          });

                          return toggleNotification(id, receivesNotifications);
                        }}
                        isBeingEdited={idOfSavedSearchBeingEdited === search.id}
                        setIsBeingEdited={isOpen => {
                          setIdOfSavedSearchBeingEdited(isOpen ? search.id : null);
                        }}
                        isDisabled={
                          idOfSavedSearchBeingEdited !== null &&
                          idOfSavedSearchBeingEdited !== search.id
                        }
                        trackSaveSearchName={nameOrDescription => {
                          savedSearchInteraction({
                            actionType: 'SAVE_SEARCH_NAME',
                            action: PARAMETERS.saveSearchName,
                            sourcePage: PARAMETERS.savedSearchPage,
                            savedSearchCount: savedSearches.length,
                            savedSearchName: nameOrDescription,
                            savedSearchId: search.id,
                          });
                        }}
                      />
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      </TabPane>
    </Layout>
  );
};

export default connector(RawSearches);
