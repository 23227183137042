import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CustomIcon } from '@components/shared';
import cn from 'classnames';
import s from './UploadStatus.module.less';

const UploadStatus = ({ status, errorMessage }) => {
  const { t } = useTranslation('common');

  switch (status) {
    case null:
      return null;
    case 'uploading':
      return <CustomIcon type="loadingOutlined" />;
    case 'done':
      return (
        <div className={s.statusNotification} data-testid="upload-done">
          <CustomIcon type="checkmark" className="mr-1" />
          {t('csvUploadSuccess')}
        </div>
      );
    case 'error':
      return (
        <div className={cn([s.error, s.statusNotification])}>
          <CustomIcon type="exclamation-circle" className="mr-1" />
          {errorMessage || t('fileUploadFailure')}
        </div>
      );
    default:
      return status;
  }
};
UploadStatus.propTypes = { status: PropTypes.string, errorMessage: PropTypes.string };
UploadStatus.defaultProps = { status: null, errorMessage: null };
export default UploadStatus;
