import { useRef } from 'react';
import { parseISO, format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AdminFormSubmitter } from '@components/shared';
import css from './styles.module.less';
import BasicInfo from './BasicInfo';
import Details from './Details';
import Location from './Location';
import PropertyTable from './PropertyTable';
import { validationSchema } from './validationSchema';

const FormikBuildingForm = ({ building, fetchAdminBuilding, updateBuilding }) => {
  const { t } = useTranslation('admin');
  const initialValues = {
    ...building,
    owningCompanyId: building.owningCompany?.id || '',
    landlordId: building.landlord?.id || '',
    rentableBuildingArea: building.rentableBuildingArea?.magnitude || '',
    ceilingHeightUnits: building.ceilingHeightUnits || 'imperial',
    rentableBuildingAreaUnit:
      building.rentableBuildingArea?.unit || building.rentableBuildingAreaUnit || '',
    liveAt: building.liveAt ? format(parseISO(building.liveAt), 'M/d/yyyy') : '',
    retiredAt: building.retiredAt ? format(parseISO(building.retiredAt), 'M/d/yyyy') : '',
  };

  const flags = useFlags();
  const notableTenants = useRef(
    initialValues.notableTenants.map(nt => ({
      label: nt.companyName,
      value: nt.companyId,
    })),
  );

  return (
    <Formik
      enableReinitialize
      initialValues={JSON.parse(JSON.stringify(initialValues, (k, v) => (v === null ? '' : v)))}
      onSubmit={async (values, actions) => {
        const { htmlDescription, description, ...restOfValues } = values;
        const params = JSON.parse(JSON.stringify(restOfValues, (k, v) => (v === '' ? null : v)));

        params.liveAt = params.liveAt ? new Date(params.liveAt) : null;
        params.retiredAt = params.retiredAt ? new Date(params.retiredAt) : null;

        if (flags['market.building-descriptions-support-rich-text']) {
          params.htmlDescription = htmlDescription;
        } else {
          params.description = description;
        }

        await updateBuilding(params);
        actions.setSubmitting(false);
      }}
      validationSchema={validationSchema(t)}
    >
      {({ values, dirty }) => (
        <Form className={css.form}>
          <FormThemeContextProvider theme="vertical">
            <BasicInfo building={building} status={values.status} />
            <Details
              owningCompanyId={values.owningCompany.id}
              owningCompanyName={values.owningCompany.name}
              landlordId={values.landlord.id}
              landlordName={values.landlord.name}
              notableTenants={notableTenants.current}
              rentableBuildingAreaUnit={values.rentableBuildingAreaUnit}
              ceilingHeightUnits={values.ceilingHeightUnits}
              initialValues={initialValues}
            />
            <Location
              building={building}
              neighborhoodId={values.neighborhoodId}
              countryCode={values?.countryCode}
            />
            <PropertyTable
              buildingName={building.primaryName}
              buildingId={building.id}
              fetchAdminBuilding={fetchAdminBuilding}
              properties={building.properties}
            />

            <AdminFormSubmitter />
            <Prompt when={dirty} message={t('common:prompt.unsavedChanges')} />
          </FormThemeContextProvider>
        </Form>
      )}
    </Formik>
  );
};

export default FormikBuildingForm;
