import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Form as AntdForm } from '@ant-design/compatible';

import { Input, Alert, notification } from 'antd';
import { useHistory, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import api, { csrfTokenHeader } from '@shared/api';
import Uploader from '@root/components/shared/Uploader';
import Button from '@components/shared/V2Button';
import { Company, DeprecatedErrorResponse } from '@root/types';
import usePhotoPreview from '@shared/usePhotoPreview';

const formItemLayout = {
  labelCol: {
    sm: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
};

type Props = {
  tenant?: Company | null;
  submitText: string;
};

export const useForm = (tenant: Company | null) => {
  const history = useHistory();
  const [name, setName] = useState<string | undefined>(tenant ? tenant.name : undefined);
  const [logo, setLogo] = useState<Blob | null>(null);
  const { photoPreview: logoPreview } = usePhotoPreview(
    logo,
    tenant ? tenant.logo.path : undefined,
  );
  const [fetching, setFetching] = useState(false);
  const [errors, setErrors] = useState<DeprecatedErrorResponse | null>(null);
  const { t } = useTranslation('admin');

  const formIsFilledOut = name && (logo || tenant);

  const onSubmit = async () => {
    setFetching(true);
    const formData = new FormData();
    if (name) {
      formData.append('name', name);
    }
    if (logo) {
      formData.append('logo', logo);
    }

    const route = tenant ? routes.api.admin.company(tenant.id) : routes.api.admin.companies();
    const method = tenant ? 'PUT' : 'POST';
    const response = await api.fetch(route, {
      method,
      body: formData,
      headers: { ...csrfTokenHeader() },
    });
    const json = await response.json();
    setFetching(false);
    if (response.ok) {
      notification.success({ message: t('tenants.successMessage') });
      history.push(routes.admin.companies);
    } else {
      setErrors(json as DeprecatedErrorResponse);
    }
  };

  const fetchErrorMessage = () => {
    if (errors?.invalidFields.includes('name')) {
      return `${name} ${errors?.messages.join(', ')}`;
    }
    return errors?.messages.join(', ');
  };

  const hasErrors = !!errors;

  return {
    name,
    setName,
    logo,
    setLogo,
    logoPreview,
    submitIsDisabled: !formIsFilledOut || fetching,
    onSubmit,
    hasErrors,
    errorMessage: hasErrors ? fetchErrorMessage() : null,
    errorFields: hasErrors ? errors.invalidFields : [],
  };
};

const Form = ({ tenant = null, submitText }: Props) => {
  const { t } = useTranslation('admin');

  const {
    name,
    setName,
    setLogo,
    logoPreview,
    submitIsDisabled,
    onSubmit,
    hasErrors,
    errorMessage,
    errorFields,
  } = useForm(tenant);

  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = event => {
      if (isFormDirty) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isFormDirty]);

  const handleInputChange = event => {
    setName(event.target.value);
    setIsFormDirty(true);
  };

  return (
    <AntdForm onSubmit={onSubmit} {...formItemLayout}>
      {hasErrors ? <Alert type="error" message={errorMessage} className="mb-3" /> : null}
      {tenant ? (
        <AntdForm.Item htmlFor="tenantId" required label={t('tenants.id')}>
          <Input value={tenant.id} disabled id="tenantId" />
        </AntdForm.Item>
      ) : null}
      <AntdForm.Item
        htmlFor="name"
        required
        label={
          <span className={cn(errorFields.includes('name') && 'text-text-critical')}>
            {t('tenants.name')}
          </span>
        }
      >
        <Input value={name} onChange={handleInputChange} id="name" />
      </AntdForm.Item>
      <AntdForm.Item required htmlFor="formUpload" label={t('tenants.logo')}>
        <Uploader
          id="formUpload"
          formUpload
          successCallback={({ file }) => setLogo(file)}
          accept=".png,.jpg,.jpeg,.jpg,.svg"
        />
        <div className="text-text-attention">{t('tenants.logoUploadWarning')}</div>
      </AntdForm.Item>
      <AntdForm.Item label={t('tenants.logoThumbnail')}>
        {logoPreview ? (
          <img src={logoPreview} alt="Logo Preview" className="max-h-[250px] max-w-[500px]" />
        ) : null}
      </AntdForm.Item>
      <Button disabled={submitIsDisabled} onClick={() => onSubmit()}>
        {submitText}
      </Button>
      <Prompt when={isFormDirty} message={t('common:prompt.unsavedChanges')} />
    </AntdForm>
  );
};

export default Form;
