import { useSetArrivedOnMarketLink } from '@shared/sessionStorageForSiteVisitors';
import Listing from './Listing';

export const VtsMarketListingShare = props => {
  useSetArrivedOnMarketLink();

  return <Listing {...props} />;
};

export default VtsMarketListingShare;
