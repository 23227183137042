import { useState } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { Field, useFormikContext } from 'formik';
import { get } from 'lodash';
import { Currency, Locale } from '@root/types';
import { currencyCodeToSymbol } from '@root/shared/currencyByCountry';
import matchNumericInput from '@components/layouts/Truva/ListingSearch/utils/matchNumericInput';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';
import Input from '../RawInput';
import s from './CurrencyInput.module.less';

type Props = {
  displayUnit?: string | null;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FormEvent<HTMLInputElement>) => void;
  name: string;
  required?: boolean;
  decimal?: boolean;
  validate?: (value: any) => string | undefined;
  containerClass?: string;
  inputContainerClass?: string;
  inputClass?: string;
  showCurrencyUnit?: boolean;
  unitsClass?: string;
  placeholder?: string;
  currency?: Currency;
  locale?: Locale;
  autoComplete?: boolean;
  children?: React.ReactNode;
  displayError?: boolean;
} & Omit<FieldWrapperProps, 'children'>;

const CurrencyInput = ({
  onChange,
  onBlur,
  onFocus,
  name,
  required = false,
  validate,
  containerClass,
  currency = 'USD',
  locale = 'en-us',
  labelText,
  labelClass,
  description,
  autoComplete,
  children,
  displayUnit,
  decimal = true,
  showCurrencyUnit = true,
  inputContainerClass,
  inputClass,
  unitsClass,
  placeholder = '0.00',
  displayError = true,
}: Props) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const fieldValue = get(values, name, '');

  const sanitizeCurrencyInputValue = (value: string) => {
    const parseFieldValue = parseFloat(value);
    if (Number.isNaN(parseFieldValue)) {
      return '';
    }
    return decimal ? parseFieldValue.toFixed(2) : value;
  };

  const [inputValue, setInputValue] = useState(sanitizeCurrencyInputValue(fieldValue));
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = e => {
    setIsEditing(false);
    setFieldTouched(name, true);
    setInputValue(sanitizeCurrencyInputValue(fieldValue));

    if (onBlur) onBlur(e);
  };

  const formatValue = value => {
    if (typeof value === 'undefined' || value === '') return '';
    if (decimal) return parseFloat(value).toFixed(2);
    return value;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
    setFieldTouched(name, true);
    const parsedValue = parseFloat(matchNumericInput(value) ?? '');
    setFieldValue(
      name,
      value !== '' && parsedValue !== null && !Number.isNaN(parsedValue) ? parsedValue : '',
    );
  };

  return (
    <FieldWrapper
      {...{ name, containerClass, labelText, labelClass, required, description, displayError }}
    >
      <Field name={name} validate={validate} required={required}>
        {({ meta }) => (
          <span
            className={cn(
              s.inputContainer,
              inputContainerClass,
              meta.touched && meta.error && s.error,
              isEditing && s.focus,
            )}
          >
            {showCurrencyUnit && currencyCodeToSymbol(currency, locale)}
            {isEditing ? (
              <>
                <Input
                  className={cn(s.currencyInput, inputClass)}
                  type="text"
                  name={name}
                  value={inputValue}
                  allowChange={decimal ? val => /^\d*\.?\d*$/.test(val) : val => /^\d*$/.test(val)}
                  onChange={e => {
                    if (onChange) {
                      onChange(e);
                    }
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  placeholder={placeholder}
                  autoComplete={autoComplete}
                />
                {displayUnit ? (
                  <span className={cn(s.units, unitsClass)}>{displayUnit}</span>
                ) : null}
              </>
            ) : (
              <>
                <Input
                  className={cn(s.currencyInput, inputClass)}
                  type="text"
                  readOnly
                  onChange={() => {}}
                  value={formatValue(fieldValue)}
                  onFocus={e => {
                    setIsEditing(true);
                    if (onFocus) onFocus(e);
                  }}
                  placeholder={placeholder}
                  name={name}
                  autoComplete={autoComplete}
                />
                {displayUnit ? (
                  <span className={cn(s.units, unitsClass)}>{displayUnit}</span>
                ) : null}
              </>
            )}
          </span>
        )}
      </Field>
      {children}
    </FieldWrapper>
  );
};

export default CurrencyInput;
