import { OnlyInDesktopTablet, OnlyInMobile } from '@components/shared';
import s from './ListingSkeleton.module.less';
import BuildingInfoSkeleton from './BuildingInfoSkeleton';
import TourThisSpaceSkeleton from './TourThisSpaceSkeleton';
import AmenitiesSkeleton from './AmenitiesSkeleton';
import ListingDetailsSkeleton from './ListingDetailsSkeleton';
import GallerySkeleton from './GallerySkeleton';

const ListingSkeleton = () => {
  return (
    <div data-testid="listingSkeleton">
      <OnlyInDesktopTablet>
        <div className={s.container}>
          <section className={s.leftContent}>
            <GallerySkeleton />
            <BuildingInfoSkeleton />
            <TourThisSpaceSkeleton />
            <AmenitiesSkeleton />
          </section>
          <section className={s.rightContent}>
            <ListingDetailsSkeleton />
          </section>
        </div>
      </OnlyInDesktopTablet>
      <OnlyInMobile>
        <ListingDetailsSkeleton />
      </OnlyInMobile>
    </div>
  );
};

export default ListingSkeleton;
