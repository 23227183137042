import { Helmet } from 'react-helmet';

type Props = {
  title?: string | null;
  description?: string | null;
  previewTitle?: string | null;
  previewDescription?: string | null;
  previewImageUrl?: string | null;
  canonicalURL?: string | null;
  removeFromSEOIndex?: boolean;
};

const PageHead = ({
  title,
  description,
  previewTitle,
  previewDescription,
  previewImageUrl,
  canonicalURL,
  removeFromSEOIndex = false,
}: Props) => {
  return (
    <Helmet>
      {/* Title default is set in App.tsx */}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />

      {previewTitle ? <meta property="og:title" content={previewTitle} /> : null}
      {previewTitle ? <meta name="twitter:title" content={previewTitle} /> : null}

      {previewDescription ? <meta property="og:description" content={previewDescription} /> : null}
      {previewDescription ? <meta name="twitter:description" content={previewDescription} /> : null}

      {previewImageUrl ? <meta property="og:image" content={previewImageUrl} /> : null}
      {previewImageUrl ? <meta name="twitter:image" content={previewImageUrl} /> : null}

      {canonicalURL ? (
        <link rel="canonical" href={`${window.location.origin}${canonicalURL}`} />
      ) : null}
      {removeFromSEOIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

export default PageHead;
