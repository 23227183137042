import { flatten } from 'lodash';
import {
  FONT_FACE,
  PowerpointElement,
  PowerpointTextElementProps,
  PowerpointSlide,
  smallText,
  h3,
  SlideSection,
} from './globals';

export const addLine = (
  slide: PptxGenJS.default.Slide,
  pptx,
  y: number,
  w: number,
  x: number = 0,
) => {
  slide.addShape(pptx.ShapeType.line, {
    x,
    y,
    w,
    h: 0.0,
    line: { color: 'DDDDDD', width: 1 },
  });
};

export const generateLine = (
  pptx: PptxGenJS.default,
  y: number,
  w: number,
  x: number = 0,
): PowerpointElement => {
  return {
    type: 'shape',
    placement: { x, y, w, h: 0.0 },
    shapeOptions: {
      line: { color: 'DDDDDD', width: 1 },
    },
    shapeType: pptx.ShapeType.line,
  };
};

export const deprecatedPipe = () => {
  return {
    text: '  |  ',
    options: {
      color: 'A7A7A7',
      fontSize: 8,
      fontFace: FONT_FACE,
      lineSpacingMultiple: 0.9,
    },
  };
};

export const pipe = (): PowerpointTextElementProps => ({
  data: '  |  ',
  textOptions: {
    color: 'A7A7A7',
    fontSize: 8,
    fontFace: FONT_FACE,
    lineSpacingMultiple: 0.9,
  },
});

export const pipeElement = (): PowerpointTextElementProps => {
  return {
    data: '  |  ',
    textOptions: {
      color: 'A7A7A7',
      fontSize: 8,
      fontFace: FONT_FACE,
      lineSpacingMultiple: 0.9,
    },
  };
};

export const truncate = (text: string | null | undefined, length: number): string => {
  if (!text) return '';
  if (text.length < length) return text;

  return text
    .replace(/\n+/g, '\n')
    .slice(0, length - 1)
    .concat('…');
};

// there is an edge case where you want to add a percent and absolute measure
// i don't think the library can even handle that, or that we even use percentages at all
const addCoords = (
  a: PptxGenJS.default.Coord = 0,
  b: PptxGenJS.default.Coord = 0,
): PptxGenJS.default.Coord =>
  typeof a === 'string'
    ? `${parseFloat(a) + parseFloat(b as string)}%`
    : a + parseFloat(b as string);

// TODO: generalize math so we can math out based on width/height/characters/font size/line spacing
// and not have to rely on magic numbers
const countLines = (charactersPerLine: number) => text =>
  Math.ceil(text.length / charactersPerLine);

export const calculateHeightFromCharacterLength = (
  desc: string,
  w: number,
  inchesPerLine: number,
  charactersPerInch: number,
  additionalHeight = 0,
) => {
  // the actual height in inches is:
  // (inches per line) * (character count / (width in inches * characters per inch))
  return (
    inchesPerLine *
      desc
        .split('\n')
        .map(countLines(w * charactersPerInch))
        .reduce((sum, lines) => sum + lines, 0) +
    additionalHeight
  );
};

export const generateDescriptionElement = (
  description: string | null,
  {
    heading,
    y,
    limit,
    h,
    dynamicSize,
  }: { heading: string; y: number; h: number; limit: number; dynamicSize?: boolean | undefined },
): PowerpointElement[] => {
  if (description?.length) {
    const desc = truncate(description, limit);
    const w = 6.93;
    const computedH = dynamicSize ? calculateHeightFromCharacterLength(desc, w, 0.16, 19, 0.54) : h;

    return [
      {
        type: 'textBox',
        data: [
          {
            data: heading,
            textOptions: {
              ...h3,
              breakLine: true,
            },
          },
          {
            data: desc,
            textOptions: { ...smallText },
          },
        ],
        placement: {
          x: 0.27,
          y,
          w,
          h: computedH,
          valign: 'top',
        },
      },
    ];
  }

  return [
    {
      type: 'textBox',
      data: heading,
      textOptions: {
        ...h3,
        breakLine: true,
      },
      placement: {
        x: 0.38,
        y,
        w: 6.93,
        h: 0.2,
        valign: 'top',
      },
    },
  ];
};

export const addElementsToSlide = (
  slide: PptxGenJS.default.Slide,
  elements: PowerpointElement[],
) => {
  elements.forEach((element: PowerpointElement) => {
    switch (element.type) {
      case 'textBox':
        if (Array.isArray(element.data)) {
          slide.addText(
            element.data.map(e => {
              return {
                text: e.data || '',
                options: { ...e.textOptions },
              };
            }),
            { ...element.placement },
          );
        } else {
          slide.addText(
            [
              {
                text: element.data || '',
                options: { ...element.textOptions },
              },
            ],
            { valign: 'top', margin: 0, ...element.placement },
          );
        }
        break;
      case 'image':
        slide.addImage({
          ...element.placement,
          ...element.imageOptions,
          path: element.data,
        });
        break;
      case 'shape':
        slide.addShape(element.shapeType, {
          ...element.placement,
          ...element.shapeOptions,
        });
        break;
      case 'slideNumber':
        // eslint-disable-next-line no-param-reassign
        slide.slideNumber = {
          ...element.placement,
          ...element.textOptions,
        };
        break;
      case 'table':
        slide.addTable(element.data, {
          ...element.placement,
          ...element.tableOptions,
        });
        break;
      default:
        break;
    }
  });
};

const farthestPoint = placements =>
  placements
    .map(p => p.placement)
    .map(p => ({ ...p, y: addCoords(p.y, p.h) }))
    .reduce(
      (farthest, placement) => ((placement?.y ?? 0) > (farthest?.y ?? 0) ? placement : farthest),
      placements[0],
    );

const calculateRelativeToAbsolutePlacement = (from: { x: number; y: number }) => element => {
  const { x, y } = from;
  const placement = {
    ...element.placement,
    x: addCoords(x, element.placement?.x),
    y: addCoords(y, element.placement?.y),
  };

  return { ...element, placement };
};

export const generateElementsFromSections = (
  sections: SlideSection[],
  { x, y }: PptxGenJS.default.PositionProps,
  sectionSpacing = 0.4,
): PowerpointElement[] => {
  return flatten(
    sections
      .filter((section: SlideSection) => section.length)
      .reduce(
        (
          acc: { elements: PowerpointElement[]; position: { x; y } },
          section: PowerpointElement[],
        ) => {
          const calculated = section.map(calculateRelativeToAbsolutePlacement(acc.position));
          const sectionCorner = farthestPoint(calculated);
          return {
            elements: [...acc.elements, ...calculated],
            position: {
              x: sectionCorner.x,
              y: addCoords(sectionCorner.y, sectionSpacing),
            },
          };
        },
        { elements: [], position: { x, y } },
      ).elements,
  );
};

export const makeElementsRelative = (
  element: PowerpointElement,
  _i: number,
  elements: SlideSection,
) => {
  return {
    ...element,
    placement: {
      ...element.placement,
      x: addCoords(element.placement?.x ?? 0, -(elements[0].placement?.x ?? 0)),
      y: addCoords(element.placement?.y ?? 0, -(elements[0].placement?.y ?? 0)),
    },
  };
};

export const addSlides = (pptx: PptxGenJS.default, slides: PowerpointSlide[]): void => {
  slides.forEach(slide => {
    const currentSlide: PptxGenJS.default.Slide = pptx.addSlide();
    addElementsToSlide(currentSlide, slide.elements);
  });
};

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const getMarkerLabel = (
  entryCount: number,
  entryIndex: number,
  allowEmpty: boolean,
): string => {
  if (entryCount > 26) {
    if (allowEmpty) {
      // no label
      return '';
    }
    // number labels
    return (entryIndex + 1).toString();
  }

  if (entryCount > 9) {
    // letter labels
    return letters[entryIndex];
  }

  // number labels
  return (entryIndex + 1).toString();
};
