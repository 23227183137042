import { useState, ReactNode } from 'react';
import CustomIcon, { ValidIconTypes } from '@components/shared/CustomIcon';
import { Dropdown } from 'antd';
import cn from 'classnames';
import s from './OverflowMenu.module.less';

// TECH DEBT TRV-2924
// This component should be converted to react-select
// It was also given a lot of customization. When it is changed
// please audit the usage and remove customization options that are not in use

type Props = {
  children: ReactNode;
  overflowClassName?: string;
  overlayClassName?: string;
  iconType?: ValidIconTypes;
  iconClass?: string;
  activeIconClass?: string;
  dropdownPlacement?: 'bottomRight' | 'topRight';
};
const OverflowMenu = ({
  overflowClassName,
  overlayClassName,
  iconType = 'overflow',
  iconClass,
  activeIconClass,
  dropdownPlacement = 'bottomRight',
  children,
}: Props) => {
  const [activeOutline, setActiveOutline] = useState(false);

  const onVisibleChange = () => {
    setActiveOutline(!activeOutline);
  };
  const onMenuItemClick = () => {
    setActiveOutline(false);
  };
  const menu = (
    <div
      role="button"
      onClick={onMenuItemClick}
      className={cn(s.overflowMenuItemsWrapper, s.overflowMenuWrapper)}
    >
      {children}
    </div>
  );

  const overlayStyle = overlayClassName || s.overflowDropdown;
  const overflowStyle = overflowClassName || s.overflowMenu;
  const iconStyle = iconClass || s.overflowIcon;

  return (
    <Dropdown
      onVisibleChange={onVisibleChange}
      placement={dropdownPlacement}
      overlay={menu}
      trigger={['click']}
      overlayClassName={overlayStyle}
    >
      <div
        data-testid="overflow-menu"
        className={cn(
          s.overflowMenuIconWrapper,
          { [s.activeOutline]: activeOutline },
          overflowStyle,
          activeIconClass && { [activeIconClass]: activeOutline },
        )}
      >
        <CustomIcon className={cn(s.icon, iconStyle)} type={iconType} />
      </div>
    </Dropdown>
  );
};

export default OverflowMenu;
