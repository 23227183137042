import * as React from 'react';
import { KeyboardEventHandler } from 'react';
import Select from 'react-select/creatable';
import type { ActionMeta, InputActionMeta, FocusEventHandler } from 'react-select/src/types';
import type { ValueType, OptionType } from '../Dropdowns/types';

export type Props = {
  name: string;
  placeholder?: string;
  onBlur?: FocusEventHandler;
  onChange?: (value: ValueType<ReadonlyArray<OptionType>>, action: ActionMeta<OptionType>) => void;
  onKeyDown?: KeyboardEventHandler | undefined;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  value?: any;
  inputValue?: string;
};

const RawCreatableSelect = ({
  name,
  placeholder,
  onChange,
  onKeyDown,
  onInputChange,
  onBlur,
  value,
  inputValue,
}: Props) => (
  <Select
    name={name}
    placeholder={placeholder}
    isMulti
    openMenuOnClick={false}
    onBlur={onBlur}
    onChange={onChange}
    onKeyDown={onKeyDown}
    onInputChange={onInputChange}
    inputValue={inputValue}
    components={{
      DropdownIndicator: null,
      Menu: () => null,
    }}
    value={value}
  />
);

export default RawCreatableSelect;
