import { useState } from 'react';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import Select from 'react-select';
import useCurrentUser from '@root/shared/useCurrentUser';
import { isUndefined } from 'lodash';
import { OnlyInDesktop, OnlyInTabletMobile } from '@components/shared';
import StandardComponents from '../StandardComponents';
import { OptionType, SelectValue } from '../types';
import s from '../Dropdowns.module.less';

export type Props = {
  name: string;
  placeholder?: string;
  value?: SelectValue;
  onChange: (val: SelectValue) => void;
  isClearable?: boolean;
  isSearchable?: boolean;
  options: OptionType[];
  onBlur?: () => void;
  components?: any;
  className?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
};

const LocaleSelect = ({
  options,
  name,
  placeholder,
  value,
  onChange,
  isClearable,
  isSearchable = true,
  onBlur,
  isDisabled,
  components = {},
  className,
  isMulti = false,
  menuPlacement = 'bottom',
}: Props) => {
  const { currentUser } = useCurrentUser();
  const loggedOut = !currentUser;

  const getValue = () => {
    // guard clause so that this can be uncontrolled if needed
    if (isUndefined(value)) return value;

    const foundValue = options.find(option => option.value === value);
    return foundValue || null;
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const mouseOver = () => {
    setIsMenuOpen(true);
  };

  const mouseLeave = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <OnlyInDesktop>
        <div onFocus={mouseOver} onMouseOver={mouseOver} onMouseLeave={mouseLeave}>
          <Select
            name={name}
            className={cn(s.select, className)}
            placeholder={placeholder}
            value={getValue()}
            // @ts-expect-error
            onChange={(option: OptionType | null) => {
              const newValue = option ? option.value : null;
              onChange(newValue);
            }}
            onBlur={onBlur}
            options={options}
            menuIsOpen={isMenuOpen}
            isOpen
            isClearable={isClearable}
            isSearchable={isSearchable}
            inputId={name}
            isMulti={isMulti}
            isDisabled={isDisabled}
            components={{
              ...StandardComponents,
              ...components,
            }}
            styles={{
              menu: provided => ({
                ...provided,
                zIndex: 9999,
                width: '200px',
                right: 0,
              }),
              control: provided => ({
                ...provided,
                width: '42px',
                height: '48px',
                paddingTop: '3px',
                paddingLeft: '2px',
                border: '0 !important',
                boxShadow: 'none',
                backgroundColor: loggedOut ? '#FFFFFF' : '#140532',
                // acts as hover selector that persists highlighted state in menu
                ...(isMenuOpen && {
                  backgroundColor: loggedOut ? '#F5F5FE' : '#280f96',
                  textDecoration: 'none',
                  cursor: 'pointer',
                }),
              }),
            }}
            menuPlacement={menuPlacement}
          />
        </div>
      </OnlyInDesktop>
      <OnlyInTabletMobile>
        <Select
          name={name}
          className={cn(s.select, className)}
          placeholder={placeholder}
          value={getValue()}
          // @ts-expect-error
          onChange={(option: OptionType | null) => {
            const newValue = option ? option.value : null;
            onChange(newValue);
          }}
          onMenuOpen={() => {
            mouseOver();
          }}
          onMenuClose={() => {
            mouseLeave();
          }}
          onBlur={onBlur}
          options={options}
          isOpen
          isClearable={isClearable}
          isSearchable={isSearchable}
          inputId={name}
          isMulti={isMulti}
          isDisabled={isDisabled}
          components={{
            ...StandardComponents,
            ...components,
          }}
          styles={{
            menu: provided => ({
              ...provided,
              zIndex: 9999,
              width: '200px',
              right: 0,
              '@media only screen and (max-width: 767px)': {
                left: '8px',
              },
            }),
            control: provided => ({
              ...provided,
              width: '42px',
              height: '48px',
              paddingTop: '3px',
              paddingLeft: '2px',
              border: '0 !important',
              boxShadow: 'none',
              backgroundColor: loggedOut ? '#FFFFFF' : '#140532',
              // acts as hover selector that persists highlighted state in menu
              ...(isMenuOpen && {
                backgroundColor: loggedOut ? '#F5F5FE' : '#280f96',
                textDecoration: 'none',
                cursor: 'pointer',
              }),
              ...(!isMenuOpen && {
                backgroundColor: loggedOut ? '#FFFFFF' : '#140532',
              }),
            }),
          }}
          menuPlacement={menuPlacement}
        />
      </OnlyInTabletMobile>
    </div>
  );
};

export default LocaleSelect;
