import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'antd';
import { TourbookSummary } from '@root/types';
import routes from '@root/routes';
import CustomIcon from '@components/shared/CustomIcon';
import truncateText from '@shared/truncateText';
import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import useAltText from '@root/shared/useAltText';
import cn from 'classnames';
import s from './TourbookCard.module.less';

type TourbookCardProps = {
  tourbook: TourbookSummary;
  onDelete: (event: React.MouseEvent) => void;
  onDuplicate: (event: React.MouseEvent) => void;
};

const TourbookCard = ({ tourbook, onDelete, onDuplicate }: TourbookCardProps) => {
  const { t } = useTranslation('tourbook');
  const { getAltTextForTourbookHeader } = useAltText();

  const history = useHistory();

  return (
    <div className={s.container}>
      <div
        className={cn(
          'flex h-full min-w-0 flex-1 overflow-hidden bg-black-003',
          s.halfWidthOnSmallMobile,
        )}
      >
        <img
          className="h-full w-[max(260px,_100%)] min-w-[max(260px,_100%)] object-cover"
          src={
            tourbook.headerImage?.smallPath
              ? tourbook.headerImage.smallPath
              : '/images/tourbook-default.jpg'
          }
          alt={getAltTextForTourbookHeader(tourbook.name)}
        />
      </div>
      <div className={s.summaryContainer}>
        <div className={s.summaryTop}>
          <h2 className="mb-1 font-body-medium-emphasis">{truncateText(tourbook.name, 40)}</h2>
          <p className={cn(s.description, 'max-h-[62px]')}>
            {truncateText(tourbook.description, 50)}
          </p>
        </div>
        <div className={s.summaryBottom}>
          <p className={s.lastUpdated}>{t('updated_ago', { time: tourbook.lastUpdatedString })}</p>
          <div className={s.iconsContainer}>
            <div>
              <span className="font-body-small">
                {t('numEntries', { count: tourbook.listingCount })}
              </span>
            </div>
            <figure className="mx-1 h-[20px] w-[1px] bg-black-020"></figure>
            <Tooltip
              overlayClassName={s.tooltip}
              trigger="hover"
              placement="bottom"
              arrowPointAtCenter
              title={t('listingCard.viewCountTooltip')}
            >
              <div>
                <CustomIcon type="tourbook" className="mb-[-2px] mr-1" />
                <span className="font-body-small">{t('numViews', { count: tourbook.views })}</span>
              </div>
            </Tooltip>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              className={s.overFlowMenu}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <OverflowMenu
                iconType="overflow"
                iconClass={s.overflowIcon}
                overflowClassName="!h-max"
              >
                {tourbook.analyticsSupport ? (
                  <OverflowMenuItem
                    onClick={() => history.push(routes.tourbookAnalytics(tourbook.id))}
                  >
                    <>
                      <CustomIcon type="analytics" />
                      {t('viewAnalytics')}
                    </>
                  </OverflowMenuItem>
                ) : null}
                <OverflowMenuItem
                  className={s.duplicateButton}
                  onClick={event => onDuplicate(event)}
                >
                  <>
                    <CustomIcon type="duplicate" />
                    {t('header.duplicate')}
                  </>
                </OverflowMenuItem>
                <OverflowMenuItem onClick={event => onDelete(event)}>
                  <>
                    <CustomIcon type="trash" />
                    {t('header.delete')}
                  </>
                </OverflowMenuItem>
              </OverflowMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourbookCard;
