import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import AsyncSelect from 'react-select/async';
import StandardComponents from '../StandardComponents';
import { OptionType, OptionTypeBase, ValueType } from '../types';
import s from '../Dropdowns.module.less';

export type Props = {
  name: string;
  placeholder?: string;
  onChange: (val: ValueType<OptionTypeBase>) => void;
  isClearable?: boolean;
  onBlur?: () => void;
  components?: any;
  className?: string;
  loadOptions: (val: string, callback?: Function) => void;
  initialValue?: OptionType | OptionType[];
  isMulti?: boolean;
};

const RawSelect = ({
  loadOptions,
  name,
  placeholder,
  onChange,
  isClearable,
  onBlur,
  components = {},
  className,
  initialValue,
  isMulti = false,
}: Props) => {
  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      isMulti={isMulti}
      defaultOptions
      name={name}
      className={cn(s.select, className)}
      placeholder={placeholder}
      onChange={onChange}
      defaultValue={initialValue}
      onBlur={onBlur}
      isOpen
      isClearable={isClearable}
      inputId={name}
      components={{
        ...StandardComponents,
        ...components,
      }}
    />
  );
};

export default RawSelect;
