import { NotableTenant } from '@root/types';
import { useTranslation } from 'react-i18next';
import s from './NotableTenants.module.less';

type Props = {
  notableTenants: NotableTenant[];
};

const NotableTenants = ({ notableTenants = [] }: Props) => {
  const { t } = useTranslation('building');

  return (
    <div className="mb-5">
      <h3 className="text-text-primary font-subtitle">{t('notableTenants')}</h3>
      <div className="flex items-center mt-2 flex-wrap gap-y-2">
        {notableTenants.map(notableTenant => (
          <img
            key={notableTenant.companyId}
            className={s.tenantLogo}
            alt={notableTenant.logo.description}
            src={notableTenant.logo.path}
          />
        ))}
      </div>
    </div>
  );
};

export default NotableTenants;
