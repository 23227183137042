import { CurrentUser } from '@root/types';
import { createContext } from 'react';

const AdminContext = createContext<{
  currentUser: CurrentUser | null;
  numRequestedListings: number;
}>({
  currentUser: null,
  numRequestedListings: 0,
});

export default AdminContext;
