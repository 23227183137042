import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '@components/shared/forms';
import Divider from '@components/shared/Divider';
import { Room } from '@root/types';
import validationSchema from './validationSchema';
import Navigation from './Navigation';
import BasicInfo from './BasicInfo';
import Structure from './Structure';
import Utilities from './Utilities';
import Windows from './Windows';
import Amenities from './Amenities';
import Kitchen from './Kitchen';
import Bathroom from './Bathroom';
import Furniture from './Furniture';

import SubmitFooter from './SubmitFooter';
import css from './styles.module.less';

type Props = {
  room?: Room | null;
  onSubmit: Function;
  onDelete: Function;
  roomType: string;
};

const ListingRoomForm = ({ room, onSubmit, onDelete, roomType }: Props) => {
  const { t } = useTranslation('admin');

  const basicInfoRef = useRef<HTMLHeadingElement>(null);
  const structureRef = useRef<HTMLHeadingElement>(null);
  const utilitiesRef = useRef<HTMLHeadingElement>(null);
  const windowsRef = useRef<HTMLHeadingElement>(null);
  const amenitiesRef = useRef<HTMLHeadingElement>(null);
  const furnitureRef = useRef<HTMLHeadingElement>(null);
  const kitchenRef = useRef<HTMLHeadingElement>(null);
  const bathroomRef = useRef<HTMLHeadingElement>(null);

  const initialValues = {
    name: room?.name ? room.name : '',
    maximumCapacity: room?.maximumCapacity || '',
    worksAsOffice: room?.worksAsOffice || false,
    roomType,
    ceilingStyle: room?.ceilingStyle || '',
    ceilingHeight: room?.ceilingHeight?.magnitude || '',
    columnCount: room?.columnCount || '',
    walls: room?.walls || '',
    floorFinish: room?.floorFinish || '',
    functionalLighting: room?.functionalLighting || '',
    decorativeLighting: room?.decorativeLighting || '',
    electric: room?.electric || '',
    heatType: room?.heatType || '',
    ventilation: room?.ventilation || false,
    hvac: room?.hvac || false,
    airConditioning: room?.airConditioning || false,
    overtimeAc: room?.overtimeAc || false,
    independentTemperatureControl: room?.independentTemperatureControl || false,
    vendingMachineCount: room?.vendingMachineCount || '',
    pingPongTableCount: room?.pingPongTableCount || '',
    poolTableCount: room?.poolTableCount || '',
    airHockeyTableCount: room?.airHockeyTableCount || '',
    storageClosetArea: room?.storageClosetArea || false,
    lockers: room?.lockers || false,
    windowCount: room?.windowCount || '',
    windowTreatments: room?.windowTreatments || '',
    windowHeight: room?.windowHeight?.magnitude || '',
    windowSillHeight: room?.windowSillHeight?.magnitude || '',
    windowSides: room?.windowSides || '',
    lightScore: room?.lightScore || '',
    windowsOpen: room?.windowsOpen || false,
    atrium: room?.atrium || false,
    viewObstructions: room?.viewObstructions || false,
    bathroomType: room?.bathroomType || '',
    bathroomStallCount: room?.bathroomStallCount || '',
    bathroomSinkCount: room?.bathroomSinkCount || '',
    refrigeratorCount: room?.refrigeratorCount || '',
    dishwasherCount: room?.dishwasherCount || '',
    kitchenSinkCount: room?.kitchenSinkCount || '',
    microwaveCount: room?.microwaveCount || '',
    ovenCount: room?.ovenCount || '',
    stoveCount: room?.stoveCount || '',
    beverageDispenser: room?.beverageDispenser || '',
    kitchenTableCount: room?.kitchenTableCount || '',
    kitchenChairCount: room?.kitchenChairCount || '',
    deskCount: room?.deskCount || '',
    deskStyle: room?.deskStyle || '',
    deskStorage: room?.deskStorage || false,
    deskElectric: room?.deskElectric || false,
    deskWired: room?.deskWired || false,
    electricAdjustableDeskCount: room?.electricAdjustableDeskCount || '',
    deskChairCount: room?.deskChairCount || '',
    tableCount: room?.tableCount || '',
    tableStyle: room?.tableStyle || '',
    tableShape: room?.tableShape || '',
    tableElectric: room?.tableElectric || false,
    tableWired: room?.tableWired || false,
    conferenceTableCount: room?.conferenceTableCount || '',
    couchesCount: room?.couchesCount || '',
    loungeChairCount: room?.loungeChairCount || '',
    stoolCount: room?.stoolCount || '',
    workbenchCount: room?.workbenchCount || '',
  };

  const formatHeightField = (height: number | string) => {
    if (height === '') {
      return null;
    }

    return { magnitude: parseFloat(String(height)), unit: 'Inches' };
  };

  const formatInt = (val: number | string) => {
    if (val === '') {
      return null;
    }

    return Math.trunc(Number(val));
  };

  return (
    <Form
      id="listing-room-form"
      initialValues={initialValues}
      onSubmit={async values => {
        const transformedValues = {
          ...values,
          ceilingHeight: formatHeightField(values.ceilingHeight),
          windowHeight: formatHeightField(values.windowHeight),
          windowSillHeight: formatHeightField(values.windowSillHeight),
          refrigeratorCount: formatInt(values.refrigeratorCount),
          dishwasherCount: formatInt(values.dishwasherCount),
          kitchenSinkCount: formatInt(values.kitchenSinkCount),
          microwaveCount: formatInt(values.microwaveCount),
          ovenCount: formatInt(values.ovenCount),
          stoveCount: formatInt(values.stoveCount),
          kitchenTableCount: formatInt(values.kitchenTableCount),
          kitchenChairCount: formatInt(values.kitchenChairCount),
          columnCount: formatInt(values.columnCount),
          windowCount: formatInt(values.windowCount),
          windowSides: formatInt(values.windowSides),
          vendingMachineCount: formatInt(values.vendingMachineCount),
          pingPongTableCount: formatInt(values.pingPongTableCount),
          poolTableCount: formatInt(values.poolTableCount),
          airHockeyTableCount: formatInt(values.airHockeyTableCount),
          bathroomStallCount: formatInt(values.bathroomStallCount),
          bathroomSinkCount: formatInt(values.bathroomSinkCount),
          deskCount: formatInt(values.deskCount),
          electricAdjustableDeskCount: formatInt(values.electricAdjustableDeskCount),
          deskChairCount: formatInt(values.deskChairCount),
          tableCount: formatInt(values.tableCount),
          conferenceTableCount: formatInt(values.conferenceTableCount),
          couchesCount: formatInt(values.couchesCount),
          loungeChairCount: formatInt(values.loungeChairCount),
          stoolCount: formatInt(values.stoolCount),
          workbenchCount: formatInt(values.workbenchCount),
        };
        await onSubmit(transformedValues);
      }}
      validationSchema={validationSchema(t)}
    >
      <div className={css.noWrap}>
        <div className={css.header}>
          <h3 className="font-title">
            {room
              ? t(`listing.rooms.form.edit_${roomType}`)
              : t(`listing.rooms.form.add_${roomType}`)}
          </h3>
        </div>
        <Divider className={css.headerDivider} />
        <Navigation
          basicInfoRef={basicInfoRef}
          structureRef={structureRef}
          utilitiesRef={utilitiesRef}
          windowsRef={windowsRef}
          amenitiesRef={amenitiesRef}
          furnitureRef={furnitureRef}
          kitchenRef={kitchenRef}
          bathroomRef={bathroomRef}
        />
        <Divider className={css.headerDivider} />
        <BasicInfo roomPhotos={room?.photos || []} ref={basicInfoRef} />
        <Structure ref={structureRef} />
        <Utilities ref={utilitiesRef} />
        <Windows ref={windowsRef} />
        <Amenities ref={amenitiesRef} />
        <Furniture ref={furnitureRef} />
        <Kitchen ref={kitchenRef} />
        <Bathroom ref={bathroomRef} />
        <div className={css.footerContainer}>
          {room ? (
            <div role="button" className={css.deleteArea} onClick={() => onDelete(room?.id)}>
              {t('listing.rooms.form.deleteArea')}
            </div>
          ) : null}
          <SubmitFooter />
        </div>
      </div>
    </Form>
  );
};

export default ListingRoomForm;
