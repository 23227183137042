import { Button, CustomIcon, Modal } from '@components/shared';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import useToggle from '@root/shared/useToggle';
import { TourbookSummary, Locale } from '@root/types';
import routes from '@root/routes';
import { useHistory } from 'react-router-dom';
import { reformatDateString } from '@root/shared/dateUtils';
import { dateFormat } from '@root/shared/TranslationHelpers/formatDate';
import useEnv from '@root/shared/useEnv';
import { useBreakpoint } from '@root/shared/useBreakpoints';
import { isInIframe } from '@root/shared/iframeUtils';
import AnalyticsIllustration from './analytics-illustration.png';
import s from './ModalForOldTourbooks.module.less';
import EditTourbookModal from '../../../../../shared/EditTourbookModal';

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
};

const CUT_OFF_DATE = '2022-12-15';

const ModalForOldTourbooks = ({ isModalOpen, closeModal }: Props) => {
  const { t } = useTranslation('tourbook');
  const { locale } = useEnv();
  const history = useHistory();

  const {
    value: isCreateModalOpen,
    setTrue: openCreateModal,
    setFalse: closeCreateModal,
  } = useToggle();

  const onClickCreateNewTourbook = () => {
    closeModal();
    openCreateModal();
  };

  const onCreateTourbookSuccess = (tourbook: TourbookSummary) => {
    history.push(routes.tourbook(tourbook.id));
  };

  const { isMobile } = useBreakpoint();

  return (
    <>
      <Modal
        className={cn(s.modalDiv, isInIframe() && 'left-0.5')}
        isOpen={isModalOpen}
        title={t('analytics.modal.title')}
        closeModal={closeModal}
      >
        {!isMobile && <div className={cn(s.fancyDiv, 'absolute inset-0 z-[1] mt-8')} />}
        <div className="relative flex h-[419px] w-full flex-col justify-between md:flex-wrap lg:flex-row lg:pt-5 tablet:h-[644px] mobile:h-fit mobile:min-h-[calc(100vh-105px)] mobile:gap-2">
          {isMobile && (
            <div
              className={cn(s.fancyDiv, 'absolute inset-0 -bottom-2 -left-2 -right-2 z-[1] mt-8')}
            />
          )}
          <div className="z-[2] font-body-large-emphasis md:pt-1 lg:w-[59%]">
            <h3 className="mb-1">{t('analytics.modal.subject')}</h3>
            <ul className="!list-disc pl-[19px] pt-1 leading-9 font-body-large marker:text-[22px] marker:text-indigo-100 lg:max-w-[400px]">
              <li className="pb-2">{t('analytics.modal.body1')}</li>
              <li className="pb-2">{t('analytics.modal.body2')}</li>
              <li className="pb-2">{t('analytics.modal.body3')}</li>
            </ul>
            <div className="flex w-fit items-center rounded bg-general-aqua-quaternary p-1 font-body-medium">
              <CustomIcon type="lightbulb" className="mr-1 text-[18px]" />
              {t('analytics.modal.analyticsAvailabilty', {
                cutOffDate: reformatDateString({
                  value: CUT_OFF_DATE,
                  beforeFormat: 'yyyy-MM-dd',
                  afterFormat: dateFormat(locale as Locale),
                }),
              })}
            </div>
          </div>
          <div className="z-[2] flex w-full justify-center lg:mt-0 lg:block lg:w-[41%]">
            <img src={AnalyticsIllustration} className="w-[330px]" alt="analytics illustration" />
          </div>
          <div className="z-[2] flex h-fit w-full justify-center lg:mt-4 mobile:pb-1">
            <Button type="secondary" size="small" className="mr-2" onClick={closeModal}>
              {t('analytics.modal.cancel')}
            </Button>
            <Button type="primary" size="medium" onClick={onClickCreateNewTourbook}>
              {t('analytics.modal.createTourbook')}
            </Button>
          </div>
        </div>
      </Modal>
      <EditTourbookModal
        isModalOpen={isCreateModalOpen}
        onCloseModal={closeCreateModal}
        onCreateSuccess={onCreateTourbookSuccess}
        actionType="create"
      />
    </>
  );
};

export default ModalForOldTourbooks;
