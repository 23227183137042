import { ChangeEvent } from 'react';
import cn from 'classnames';
import { isUndefined } from 'lodash';

type Props = {
  options: { label: string; value: string; disabled?: boolean }[];
  optionsInRow?: boolean;
  value: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export default function RawRadioGroup({
  options,
  optionsInRow = false,
  value,
  name,
  onChange,
  disabled = false,
}: Props) {
  return (
    <div className={cn('flex', optionsInRow ? 'flex-row gap-2' : 'flex-col')}>
      {options.map(({ label, value: optionValue, disabled: optionDisabled }) => (
        <div key={`${name}-${optionValue}`} className="flex items-center gap-1">
          <input
            type="radio"
            id={`${name}-${optionValue}`}
            name={`${name}-${optionValue}`}
            value={optionValue}
            checked={value === optionValue}
            onChange={onChange}
            disabled={disabled || (!isUndefined(optionDisabled) ? optionDisabled : false)}
          />
          <label className={cn(!!disabled && 'text-black-035')} htmlFor={`${name}-${optionValue}`}>
            {label}
          </label>
        </div>
      ))}
    </div>
  );
}
