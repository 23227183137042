import { addListener, createPolygon, getDrawingManager } from '@shared/googleMaps';

type Coordinates = [number, number];

type Trackers = {
  trackMapBoundaryDrawingStarted: Function;
  trackMapBoundaryDrawingFinished: Function;
};

type IntialArgs = {
  map: any;
  onDrawComplete: Function;
  setUserMovedMap: Function;
  closeInfoWindow: Function;
  trackers: Trackers;
};

class Drawer {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  drawingManager: google.maps.drawing.DrawingManager;
  drawnPolygons: google.maps.Polygon[];
  setUserMovedMap: Function;
  onDrawComplete: Function;
  closeInfoWindow: Function;
  trackers: Trackers;
  drawingCancelled: Boolean;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor({ map, onDrawComplete, setUserMovedMap, closeInfoWindow, trackers }: IntialArgs) {
    this.drawingManager = getDrawingManager();
    this.drawingManager.setMap(map);
    this.drawnPolygons = [];
    this.onDrawComplete = onDrawComplete;
    this.setUserMovedMap = setUserMovedMap;
    this.closeInfoWindow = closeInfoWindow;
    this.trackers = trackers;
    this.drawingCancelled = false;

    addListener(this.drawingManager, 'polygoncomplete', this.onPolygonComplete);
  }

  // public
  buildPolygons = (polygons: Coordinates[], map: google.maps.Map) => {
    this.clearDrawnPolygons();

    if (polygons && polygons.length > 0) {
      const points = polygons[0].map(point => ({
        lng: parseFloat(point[0]),
        lat: parseFloat(point[1]),
      }));

      const poly = createPolygon(points);
      this.drawnPolygons.push(poly);
    }

    if (this.drawnPolygons && this.drawnPolygons.length > 0) {
      this.drawnPolygons[0].setMap(map);
      addListener(this.drawnPolygons[0], 'click', this.closeInfoWindow);
    }
  };

  startDrawing = () => {
    this.drawingCancelled = false;
    this.clearDrawnPolygons();
    this.trackers.trackMapBoundaryDrawingStarted();
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  };

  cancelDrawing = () => {
    this.drawingCancelled = true;
    this.drawingManager.setDrawingMode(null);
    this.clearDrawnPolygons();
  };

  clearDrawnPolygons = () => {
    this.drawnPolygons.forEach(polygon => {
      polygon.setMap(null);
    });
    this.drawnPolygons = [];
  };

  // private
  private onPolygonComplete = (polygon: google.maps.Polygon) => {
    this.setUserMovedMap(false);
    if (polygon.getPath().getLength() > 2) {
      /*
      https://viewthespace.atlassian.net/browse/TRV-1946
      only stop drawing a polygon if it's actually .. a polygon
      and not a line otherwise, clear it and user can keep drawing..
      .. or cancel I suppose.
      */
      this.stopDrawing();
      this.drawnPolygons.push(polygon);
    } else {
      polygon.setMap(null);
    }
    if (!this.drawingCancelled) {
      this.onDrawComplete(this.getDrawnPolygons(), () => this.cancelDrawing);
    }
    this.trackers.trackMapBoundaryDrawingFinished();
  };

  private stopDrawing = () => {
    this.drawingManager.setDrawingMode(null);
  };

  private getDrawnPolygons = () => {
    // this converts polygons from google maps type to string of Coordinates for the url
    if (this.drawnPolygons.length === 0) return null;

    const result: Coordinates[][] = [];

    this.drawnPolygons.forEach(polygon => {
      // each line that defines the polygon
      const linePaths = polygon.getPaths();
      linePaths.forEach(linePath => {
        const pointsOnThePolygon: Coordinates[] = [];
        linePath.forEach(point => {
          pointsOnThePolygon.push([
            parseFloat(point.lng().toFixed(6)),
            parseFloat(point.lat().toFixed(6)),
          ]);
        });
        result.push(pointsOnThePolygon);
      });
    });

    return JSON.stringify(result);
  };
}

export default Drawer;
