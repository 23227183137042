import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useAnalytics from '@shared/useAnalytics';
import { useParams } from 'react-router-dom';
import { StoreState } from '@root/types';
import { getListing, getLastInquiryForListing } from '@store/selectors';
import actions from '@store/actions';
import useMarketo from '@root/shared/useMarketo';
import TruvaLayout from '../Layout';
import ListingLayout from './Layout';
import ListingHead from './ListingHead';

// eslint-disable-next-line no-empty-pattern
export const ListingPreview = ({}: {}) => {
  const { pageLoaded, PARAMETERS } = useAnalytics();
  const dispatch = useDispatch();
  const { listingId } = useParams<{ listingId: string }>();
  const listing = useSelector((state: StoreState) => getListing(state, listingId));
  const lastInquiry = useSelector((state: StoreState) =>
    getLastInquiryForListing(state, listingId),
  );

  useEffect(() => {
    dispatch(actions.setListingPage(listing, true));
    pageLoaded({
      pageName: PARAMETERS.listingPage,
      actionType: 'LISTING_PAGE_LOADED',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMarketo();

  return (
    <TruvaLayout>
      <ListingHead listing={listing} isPreview />
      <ListingLayout listing={listing} lastInquiry={lastInquiry} />
    </TruvaLayout>
  );
};

export default ListingPreview;
