import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import StringListWithSource from '@components/shared/Admin/DataCollectionFields/StringListWithSource';
import MultiSelectStringListWithSource from '@components/shared/Admin/DataCollectionFields/MultiSelectStringListWithSource';
import YesNoMaybeWithSource from '@components/shared/Admin/DataCollectionFields/YesNoMaybeWithSource';
import CountWithSource from '@components/shared/Admin/DataCollectionFields/CountWithSource';
import TextWithSource from '@components/shared/Admin/DataCollectionFields/TextWithSource';

type Props = {
  ceilingType: string;
  currentSpaceCondition: string;
  exposedColumns: string;
  exposureEast: string;
  exposureNorth: string;
  exposureSouth: string;
  exposureWest: string;
  floorType: string;
  gym: string;
  mainPantryKitchen: string;
  officeFronts: string;
  officeLayout: string;
  privateBathroomsShower: string;
  receptionCeilingType: string;
  receptionFloorType: string;
  viewLandmarkDetail: string;
  windowSidesCount: number;
  windowViews: string;
};

const ListingDataCollectionList = ({ ...props }: Props) => {
  const { t } = useTranslation('admin');

  const amenities = [
    {
      label: t('listing.windowSidesCount'),
      render: (
        <CountWithSource
          fieldName="windowSidesCount"
          key="windowSidesCount"
          pageName="listing"
          value={props.windowSidesCount}
        />
      ),
    },
    {
      label: t(`listing.ceilingType.label`),
      render: (
        <StringListWithSource
          key="ceilingType"
          pageName="listing"
          fieldName="ceilingType"
          value={props.ceilingType}
          options={[
            { name: 'exposed' },
            { name: 'drop' },
            { name: 'sheetRock' },
            { name: 'exposedDrop' },
            { name: 'exposedSheetRock' },
            { name: 'dropSheetRock' },
          ]}
        />
      ),
    },
    {
      label: t(`listing.currentSpaceCondition.label`),
      render: (
        <StringListWithSource
          key="currentSpaceCondition"
          pageName="listing"
          fieldName="currentSpaceCondition"
          value={props.currentSpaceCondition}
          options={[
            { name: '2nd generation' },
            { name: 'category a+' },
            { name: 'challenged' },
            { name: 'core and shell' },
            { name: 'floor and shell' },
            { name: 'pre-built' },
            { name: 'pre-existing' },
            { name: 'raw' },
            { name: 'turnkey' },
            { name: 'under construction' },
            { name: 'white box' },
          ]}
        />
      ),
    },
    {
      label: t('listing.exposedColumns'),
      render: (
        <YesNoMaybeWithSource
          fieldName="exposedColumns"
          key="exposedColumns"
          pageName="listing"
          value={props.exposedColumns}
        />
      ),
    },
    {
      label: t('listing.exposureEast'),
      render: (
        <YesNoMaybeWithSource
          fieldName="exposureEast"
          key="exposureEast"
          pageName="listing"
          value={props.exposureEast}
        />
      ),
    },
    {
      label: t('listing.exposureNorth'),
      render: (
        <YesNoMaybeWithSource
          fieldName="exposureNorth"
          key="exposureNorth"
          pageName="listing"
          value={props.exposureNorth}
        />
      ),
    },
    {
      label: t('listing.exposureSouth'),
      render: (
        <YesNoMaybeWithSource
          fieldName="exposureSouth"
          key="exposureSouth"
          pageName="listing"
          value={props.exposureSouth}
        />
      ),
    },
    {
      label: t('listing.exposureWest'),
      render: (
        <YesNoMaybeWithSource
          fieldName="exposureWest"
          key="exposureWest"
          pageName="listing"
          value={props.exposureWest}
        />
      ),
    },
    {
      label: t(`listing.floorType.label`),
      render: (
        <StringListWithSource
          key="floorType"
          pageName="listing"
          fieldName="floorType"
          value={props.floorType}
          options={[
            { name: 'carpet' },
            { name: 'concrete' },
            { name: 'polishedConcrete' },
            { name: 'hardwood' },
            { name: 'marble' },
            { name: 'tiled' },
            { name: 'carpetConcrete' },
            { name: 'carpetPolishedConcrete' },
            { name: 'carpetHardwood' },
            { name: 'carpetMarble' },
            { name: 'concreteHardwood' },
            { name: 'polishedConcreteHardwood' },
            { name: 'polishedConcreteMarble' },
            { name: 'hardwoodMarble' },
          ]}
        />
      ),
    },
    {
      label: t('listing.gym'),
      render: (
        <YesNoMaybeWithSource fieldName="gym" key="gym" pageName="listing" value={props.gym} />
      ),
    },
    {
      label: t('listing.mainPantryKitchen.label'),
      render: (
        <StringListWithSource
          key="mainPantryKitchen"
          pageName="listing"
          fieldName="mainPantryKitchen"
          value={props.mainPantryKitchen}
          sortOptionsByIndex
          options={[{ name: 'open' }, { name: 'closed' }]}
        />
      ),
    },
    {
      label: t(`listing.officeFronts.label`),
      render: (
        <StringListWithSource
          key="officeFronts"
          pageName="listing"
          fieldName="officeFronts"
          value={props.officeFronts}
          options={[
            { name: 'glassWallGlassDoor' },
            { name: 'glassWallWoodDoor' },
            { name: 'glassWallMetalDoor' },
            { name: 'woodWallWoodDoor' },
            { name: 'woodWallGlassDoor' },
          ]}
        />
      ),
    },
    {
      label: t(`listing.officeLayout.label`),
      render: (
        <StringListWithSource
          key="officeLayout"
          pageName="listing"
          fieldName="officeLayout"
          value={props.officeLayout}
          options={[
            { name: 'mixOfficeOpen' },
            { name: 'officeIntensive' },
            { name: 'open' },
            { name: 'perimeterOfficesWithOpen' },
            { name: 'perimeterOfficesWithSupport' },
            { name: 'interiorOfficesOpen' },
          ]}
        />
      ),
    },
    {
      label: t('listing.privateBathroomsShower'),
      render: (
        <YesNoMaybeWithSource
          fieldName="privateBathroomsShower"
          key="privateBathroomsShower"
          pageName="listing"
          value={props.privateBathroomsShower}
        />
      ),
    },

    {
      label: t(`listing.receptionCeilingType.label`),
      render: (
        <StringListWithSource
          key="receptionCeilingType"
          pageName="listing"
          fieldName="receptionCeilingType"
          value={props.receptionCeilingType}
          options={[
            { name: 'exposed' },
            { name: 'drop' },
            { name: 'sheetRock' },
            { name: 'exposedDrop' },
            { name: 'exposedSheetRock' },
            { name: 'dropSheetRock' },
          ]}
        />
      ),
    },
    {
      label: t(`listing.receptionFloorType.label`),
      render: (
        <StringListWithSource
          key="receptionFloorType"
          pageName="listing"
          fieldName="receptionFloorType"
          value={props.receptionFloorType}
          options={[
            { name: 'carpet' },
            { name: 'concrete' },
            { name: 'polishedConcrete' },
            { name: 'hardwood' },
            { name: 'marble' },
            { name: 'tiled' },
            { name: 'carpetConcrete' },
            { name: 'carpetPolishedConcrete' },
            { name: 'carpetHardwood' },
            { name: 'carpetMarble' },
            { name: 'concreteHardwood' },
            { name: 'polishedConcreteHardwood' },
            { name: 'polishedConcreteMarble' },
            { name: 'hardwoodMarble' },
          ]}
        />
      ),
    },
    {
      label: t(`listing.viewLandmarkDetail`),
      render: (
        <TextWithSource
          key="viewLandmarkDetail"
          fieldName="viewLandmarkDetail"
          pageName="listing"
          value={props.viewLandmarkDetail}
        />
      ),
    },
    {
      label: t(`listing.windowViews.label`),
      render: (
        <MultiSelectStringListWithSource
          key="windowViews"
          pageName="listing"
          fieldName="windowViews"
          values={props.windowViews}
          options={[
            { name: 'riverView' },
            { name: 'cityStreet' },
            { name: 'park' },
            { name: 'building' },
            { name: 'citySkyline' },
          ]}
        />
      ),
    },
  ];

  const orderedDataCollectionItems = amenities
    .sort((amenityA, amenityB) => amenityA.label.localeCompare(amenityB.label))
    .map(dataCollectionItem => {
      return dataCollectionItem.render;
    });

  return <>{orderedDataCollectionItems}</>;
};

export default memo(ListingDataCollectionList);
