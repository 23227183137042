import { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import COLORS from '@shared/colorConstants';
import CustomIcon, { ValidIconTypes } from '@components/shared/CustomIcon';
import useAnalytics from '@shared/useAnalytics';
import { useHistory } from 'react-router-dom';
import api from '@shared/api';
import routes from '@root/routes';
import { GlobalSearchResponse, GlobalSearchBuilding, Address, MultipathImage } from '@root/types';
import SidePanel from '@components/shared/SidePanel';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Typography from '@styles/typography.module.less';
import useAltText from '@root/shared/useAltText';
import useHandleClickOutside from '@shared/useHandleClickOutside';
import s from './Navigation.module.less';

type Props = {
  searchOpen: boolean;
  setSearchOpen: (open: boolean) => void;
  setMobileOverlayOpen: (open: boolean) => void;
};

type OptionType = {
  label: string;
  value: string;
  group: 'building' | 'location';
  labelAsideText: string;
  photo: MultipathImage;
  address: Address;
};
type GroupType = {
  label: string;
  icon: ValidIconTypes;
  options: OptionType[];
};

const Control = props => {
  const { children } = props;

  return (
    <components.Control {...props} className={s.selectContainer}>
      <CustomIcon className="u-m-left-1x" type="magnifying-glass" />
      {children}
    </components.Control>
  );
};

const Option = props => {
  /* eslint-disable react/no-danger */
  const { getAltText } = useAltText({ address: props.data.address });

  const { photo, label, labelAsideText } = props.data;
  return (
    <components.Option {...props} className={s.buildingOption}>
      <div className="flex">
        {photo ? (
          <img
            className="mr-[10px] h-[40px] w-[40px]"
            src={photo.smallPath}
            alt={getAltText({ type: photo.type, options: { roomName: photo.description } })}
          />
        ) : null}
        <div className="grow">
          <div className={s.buildingOptionText} dangerouslySetInnerHTML={{ __html: label }} />
          <div className={s.buildingOptionText}>{labelAsideText}</div>
        </div>
      </div>
    </components.Option>
  );

  /* eslint-enable react/no-danger */
};

const Menu = props => <components.Menu {...props} className={s.menu} />;

const customComponents = {
  Control,
  Menu,
  Option,
  DropdownIndicator: null,
  IndicatorSeparator: null,
};

export default function BuildingSearch({ searchOpen, setSearchOpen, setMobileOverlayOpen }: Props) {
  const { t } = useTranslation('navigation');
  const history = useHistory();

  const customStyles = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    control: base => ({
      ...base,
      border: 0,
    }),
    valueContainer: base => ({
      ...base,
      height: '36px',
    }),
    option: (base, state) => ({
      ...base,
      background: state.isFocused ? COLORS.egg50 : 'white',
    }),
    groupHeading: base => ({
      ...base,
      marginBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    }),
    group: base => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    menuList: base => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      // These maxHeights insure 24px padding on the bottom of the menuList
      // on mobile and tablet/desktop
      maxHeight: 'calc(100vh - 186px)',
      '@media only screen and (max-width: 767px)': {
        maxHeight: 'calc(100vh - 138px)',
      },
    }),
    menu: base => ({ ...base, marginTop: 0 }),
    placeholder: base => ({
      ...base,
      fontWeight: 400,
      color: COLORS.black35,
    }),
  };

  const { clickToPage, PARAMETERS } = useAnalytics();
  const [buildingOptions, setBuildingOptions] = useState<GlobalSearchBuilding[]>([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const handleChange = ({ value: slug }: OptionType, { action }) => {
    if (action === 'select-option') {
      const building = buildingOptions.find(b => b.value === slug);
      clickToPage(
        {
          sourceContent: PARAMETERS.searchBar,
          destination: PARAMETERS.buildingPage,
          actionType: 'CLICK_ON_BUILDING_IN_SEARCH',
        },
        {
          searchValue,
          destinationBuilding: {
            ...(building?.meta.analytics || {}),
            buildingPreview: false,
          },
        },
      );
      setSearchOpen(false);
      history.push(routes.building(slug));
    }
  };

  const loadOptions = async (inputValue: string): Promise<GroupType[]> => {
    const response = await api.fetch(routes.api.globalSearches(inputValue));
    const rawSearchResults: GlobalSearchResponse = await response.json();
    setBuildingOptions(rawSearchResults.buildings);

    return [
      {
        label: t('homepage:search.buildingsGroupLabel'),
        icon: 'buildings-multiple',
        options: rawSearchResults.buildings.map(
          (b): OptionType => ({
            label: b.highlightedLabel,
            value: b.value,
            group: 'building',
            labelAsideText: b.labelAsideText,
            photo: b.photo,
            address: b.address,
          }),
        ),
      },
    ];
  };
  const searchInputRef = useRef<HTMLInputElement>(null);
  const ref = useHandleClickOutside<HTMLLIElement>(() => setSearchOpen(false));
  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      setSearchOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  useEffect(() => {
    if (searchOpen) {
      searchInputRef.current?.focus();
    }
  }, [searchOpen]);

  const getBuildingSearchHelpText = () => (
    <div className={s.buildingSearchHelpText}>{t('buildingSearchHelpText')}</div>
  );

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <li ref={ref}>
      <div className="relative" data-qa-testid="nav-building-search">
        <button
          type="button"
          className={cn(s.navigationLink, searchOpen && s.active, '!pb-[14px] !pt-[13px]')}
          onClick={() => {
            setSearchValue(null);
            setSearchOpen(true);
            setMobileOverlayOpen(false);
          }}
        >
          <div className={s.buildingSearchNavigationLink}>
            <CustomIcon className={s.icon} type="buildings-multiple"></CustomIcon>
            <span className={s.navText}>{t('building')}</span>
          </div>
        </button>
        <SidePanel
          className="md:top-[50px]"
          direction="right"
          isOpen={searchOpen}
          onClose={() => {
            setSearchOpen(false);
          }}
        >
          <div className={cn(Typography.subtitle, 'u-m-top-3x', 'u-m-left-3x')}>
            {t('buildingSearchHeader')}
            <div className={s.sidePanelSearchContainer} data-qa-testid="building-search-name">
              {/* @ts-expect-error */}
              <AsyncSelect
                key={searchOpen} // ensure the input is cleared after closing the side panel
                autoFocus
                className={s.search}
                name="building-search"
                components={customComponents}
                styles={customStyles}
                ref={searchInputRef}
                placeholder={t('enterBuildingName')}
                loadOptions={loadOptions}
                inputValue={searchValue || ''}
                onChange={handleChange}
                formatGroupLabel={getBuildingSearchHelpText}
                aria-label={t('buildingSearch')}
                noOptionsMessage={() => t('homepage:search.noResultsMessage')}
                openMenuOnClick={false}
                onInputChange={(value, action) => {
                  // Only update input when someone has typed something, this
                  // prevents some default behaviour, like clearing the input
                  // when the user clicks off it (aka onBlur).
                  if (action.action === 'input-change') setSearchValue(value);
                }}
              />
            </div>
          </div>
        </SidePanel>
      </div>
    </li>
  );
}
/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
