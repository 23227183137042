import { TextInput, TextArea } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import buttonCSS from '@components/shared/V2Button/Button.module.less';
import Button from '@components/shared/V2Button';
import css from '@components/layouts/Admin/BuildingPage/Form/styles.module.less';
import { Close } from '@components/shared/CustomIcon/icons';
import FileInput from './FileInput/FileInputMedia';
import s from './MarketingMediaForm.module.less';
import { BuildingMarketingMedia } from './types';

const MarketingMediaForm = ({
  data,
  onUpload,
  onPhotoDelete,
  showCancelUploadMediaButton = false,
  onDelete,
  addNewForm,
  disableButton = false,
  identifier,
  onDragOver,
  onDrop,
  onDragStart,
  showDesciption = true,
  selected = 'photo',
  accept = '.png,.jpg,.jpeg,.jpg,.svg',
  showMediaUploader = true,
}: {
  data: BuildingMarketingMedia[];
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>, photoOrder) => void;
  onPhotoDelete?: (photoOrder: number) => void;
  showCancelUploadMediaButton?: boolean;
  onDelete: (photoOrder: number) => void;
  addNewForm?: () => void;
  disableButton?: boolean;
  identifier: string;
  onDragOver?: (event, index) => void;
  onDrop?: (event, index) => void;
  onDragStart?: (item) => void;
  showDesciption?: boolean;
  selected?: string;
  accept?: string;
  showMediaUploader?: boolean;
}) => {
  const { t } = useTranslation('admin');

  return (
    <div className={s.mediaContainer}>
      {data.map((item, index) => (
        <div
          key={`${identifier}-${item.order}`}
          onDragOver={event => onDragOver?.(event, index)}
          onDrop={event => onDrop?.(event, index)}
          className={s.dragableDiv}
        >
          <div
            draggable
            onDragStart={() => onDragStart?.(item)}
            className={s.heroMediaFormWrapper}
            data-testid="media-wrapper"
          >
            {showMediaUploader ? (
              <FileInput
                cloudinaryId={item.cloudinaryId}
                accept={accept}
                onUpload={onUpload}
                order={item.order}
                onPhotoDelete={onPhotoDelete}
                showPhoto={selected === 'photo'}
                selected={selected}
                identifier={identifier}
              />
            ) : null}
            <div className={s.textFieldsWrapper}>
              <TextInput
                name={`${identifier}.${index}.title`}
                placeholder={t(`marketingMedia.${identifier}.inputPlaceholder`)}
                className={s.textfield}
              />
              {showDesciption && (
                <TextArea
                  name={`${identifier}.${index}.description`}
                  placeholder={t('marketingMedia.descriptionInputPlaceholder')}
                  className={s.textfield}
                />
              )}
            </div>
            {!showCancelUploadMediaButton && (
              <div className={s.cancelUploadButton} data-testid="cancel-upload-media-button">
                <Close onClick={() => onDelete(item.order)} />
              </div>
            )}
          </div>
        </div>
      ))}
      {disableButton ? (
        <Button
          className={classnames(
            buttonCSS.button,
            buttonCSS.secondary,
            buttonCSS.medium,
            css.previewLink,
            s.addMarketingMediaButton,
          )}
          type="primary"
          icon="plus"
          data-testid={`add-${identifier}-button`}
          onClick={() => addNewForm?.()}
        >
          {t(`marketingMedia.${identifier}.submitButton`)}
        </Button>
      ) : null}
    </div>
  );
};

export default MarketingMediaForm;
