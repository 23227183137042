import defaultMapStyles from './googleMapsStyles';
import { TruvaMarkerOptions } from './map/MarkerGenerator/MarkerGenerator';

const MAP_MAX_ZOOM = 18;

const loadMap = (key: string, callback: () => void) => {
  if (!window) return;
  if (window.google && window.google.maps) {
    callback();
  } else if (!window.googleMapsLoaded) {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?`;
    googleMapScript.src += `key=${key}&libraries=places,drawing`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', callback);
    window.googleMapsLoaded = true;
  }
};

const createStyledMapType = (mapStyles: google.maps.MapTypeStyle[] = defaultMapStyles) =>
  new window.google.maps.StyledMapType(mapStyles || defaultMapStyles, {
    name: 'styled_map',
  });

const createPolyline = coordinates =>
  new window.google.maps.Polyline({
    path: coordinates,
    geodesic: true,
    strokeColor: '#FFC328',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  });

const createPolygon = coordinates =>
  new window.google.maps.Polygon({
    paths: coordinates,
    geodesic: true,
    strokeColor: '#FFC328',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  });

type MapOptions = {
  long?: number;
  lat?: number;
  zoom?: number;
  streetViewControl?: boolean;
  fullscreenControl?: boolean;
  mapTypeControl?: boolean;
  scaleControl?: boolean;
  zoomControl?: boolean;
  center?: { lng: number; lat: number };
  streetViewControlOptions?: { position: number };
  zoomControlOptions?: {};
  gestureHandling?: 'none' | 'auto';
  clickableIcons?: boolean;
  draggingCursor?: 'default';
  draggableCursor?: 'default';
};

const createMap = (container: HTMLDivElement, { long, lat, zoom, ...mapOptions }: MapOptions) => {
  const styledMapType = createStyledMapType();

  const overriddenOptions = mapOptions;
  if (long && lat) {
    overriddenOptions.center = { lng: long, lat };
  }

  const map = new window.google.maps.Map(container, {
    zoom,
    maxZoom: MAP_MAX_ZOOM,
    clickableIcons: false,
    ...overriddenOptions,
  });

  map.mapTypes.set('styled_map', styledMapType);
  map.setMapTypeId('styled_map');

  return map;
};

const createMarker = (map, { lng, lat, label, icon }: TruvaMarkerOptions, isClustered, clickable) =>
  new window.google.maps.Marker({
    position: { lng, lat },
    label,
    optimized: false, // should be false when using PNGs to avoid flickering after zoom
    icon,
    clickable,
    ...(!isClustered && { map }),
  });

const createInfoWindow = (options?: google.maps.InfoWindowOptions) =>
  new window.google.maps.InfoWindow(options);

const latLngBounds = (southWest?, northEeast?) =>
  new window.google.maps.LatLngBounds(southWest, northEeast);

const latLng = (northEeastCorner, southWestCorner) =>
  new window.google.maps.LatLng(northEeastCorner, southWestCorner);

const addListenerOnce = (instance, eventName, handler) => {
  window.google.maps.event.addListenerOnce(instance, eventName, handler);
};

const addListener = (instance, eventName, handler) => {
  window.google.maps.event.addListener(instance, eventName, handler);
};

const getSymbolPath = () => window.google.maps.SymbolPath;

const getGoogleMapsControlPosition = () => window.google.maps.ControlPosition.RIGHT_TOP;

const getDrawingManager = () =>
  new window.google.maps.drawing.DrawingManager({
    drawingControl: false,
    polygonOptions: {
      geodesic: true,
      strokeColor: '#FFC328',
      strokeOpacity: 1.0,
      strokeWeight: 3,
    },
  });

const getPolygonBounds = polygon => {
  if (!window) return null;
  const bounds = new window.google.maps.LatLngBounds();
  const paths = polygon.getPaths();
  let path;
  for (let i = 0; i < paths.getLength(); i += 1) {
    path = paths.getAt(i);
    for (let ii = 0; ii < path.getLength(); ii += 1) {
      bounds.extend(path.getAt(ii));
    }
  }
  return bounds;
};

const getMapOptions = (overrides: Partial<MapOptions> = {}): MapOptions => {
  const defaults = {
    streetViewControl: true,
    fullscreenControl: true,
    mapTypeControl: false,
    scaleControl: true,
    zoomControl: true,
  };

  const result: MapOptions = Object.assign(defaults, overrides);

  if (result.streetViewControl) {
    result.streetViewControlOptions = {
      position: getGoogleMapsControlPosition(),
    };
  }

  if (result.zoomControl) {
    result.zoomControlOptions = {
      position: getGoogleMapsControlPosition(),
    };
  }

  return result;
};

const showMapLogs = () => !!window.showMapLogs;

const getGeocoder = () => new window.google.maps.Geocoder();

export {
  addListener,
  addListenerOnce,
  createMap,
  createMarker,
  createPolyline,
  loadMap,
  createInfoWindow,
  latLngBounds,
  latLng,
  getSymbolPath,
  getGoogleMapsControlPosition,
  getDrawingManager,
  getMapOptions,
  createPolygon,
  getPolygonBounds,
  showMapLogs,
  getGeocoder,
  MAP_MAX_ZOOM,
};
