import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import Checkbox from '@components/shared/forms/Checkbox';
import { NumberInput } from '@components/shared/forms';
import Divider from '@components/shared/Divider';

import css from './Amenities.module.less';

const Amenities = React.forwardRef((_props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
  const { t } = useTranslation('admin');
  return (
    <>
      <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-4x', 'u-p-bottom-2x')}>
        {t('listing.rooms.form.amenities.title')}
      </h2>
      <section className={css.listingRoomFormSection}>
        <div className={css.vendingMachineCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.amenities.vendingMachineCount')}
            name="vendingMachineCount"
          />
        </div>
        <div className={css.pingPongTableCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.amenities.pingPongTableCount')}
            name="pingPongTableCount"
          />
        </div>
        <div className={css.poolTableCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.amenities.poolTableCount')}
            name="poolTableCount"
          />
        </div>
        <div className={css.airHockeyTableCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.amenities.airHockeyTableCount')}
            name="airHockeyTableCount"
          />
        </div>
        <div className={css.storageClosetArea}>
          <Checkbox name="storageClosetArea">
            <span>{t('listing.rooms.form.amenities.storageClosetArea')}</span>
          </Checkbox>
        </div>
        <div className={css.lockers}>
          <Checkbox name="lockers">
            <span>{t('listing.rooms.form.amenities.lockers')}</span>
          </Checkbox>
        </div>
      </section>
      <Divider />
    </>
  );
});

export default React.memo(Amenities);
