import { useLayoutEffect, useState } from 'react';
import {
  AdminMicrosite,
  AdminMicrositeContentBlock,
  AdminMicrositePage,
  ContentBlockType,
} from '@root/types';
import { useFormikContext } from 'formik';
import { v4 as uuid } from 'uuid';
import { Button } from '@viewthespace/components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Notification } from '@components/shared';
import { useTranslation } from 'react-i18next';
import {
  HeaderContentBlock,
  AmenitiesContentBlock,
  AvailabilityContentBlock,
  CertificationsContentBlock,
  ContactContentBlock,
  GalleryContentBlock,
  BuildingInfoContentBlock,
  LocationContentBlock,
  GenericContentBlock,
} from './ContentBlocks';
import {
  getNewContentBlocks,
  getNewGenericContentBlocks,
  isSinglePageLayout,
  useMicrositeFormContext,
} from './utils';

const ContentBlockMap = {
  'AR::MicrositeAmenitiesContentBlock': AmenitiesContentBlock,
  'AR::MicrositeAvailabilityContentBlock': AvailabilityContentBlock,
  'AR::MicrositeBuildingInfoContentBlock': BuildingInfoContentBlock,
  'AR::MicrositeCertificationsContentBlock': CertificationsContentBlock,
  'AR::MicrositeContactContentBlock': ContactContentBlock,
  'AR::MicrositeGalleryContentBlock': GalleryContentBlock,
  'AR::MicrositeHeaderContentBlock': HeaderContentBlock,
  'AR::MicrositeGoogleMapContentBlock': LocationContentBlock,
  'AR::MicrositeGenericContentBlock': GenericContentBlock,
};

export default function Pages() {
  const { values, setFieldValue, setValues } = useFormikContext<Partial<AdminMicrosite>>();
  const { sendPreviewTo, microsite, setOpen } = useMicrositeFormContext();
  const { t } = useTranslation('admin');
  const contentBlocksByPageId = values.contentBlocks!.reduce(
    (acc, block) => {
      if (!acc[block.pageId]) {
        acc[block.pageId] = [block];
      } else {
        acc[block.pageId].push(block);
      }
      return acc;
    },
    {} as Record<string, AdminMicrositeContentBlock[]>,
  );

  const [brandNewContentBlockId, setBrandNewContentBlockId] = useState<null | string>(null);
  useLayoutEffect(() => {
    if (brandNewContentBlockId) {
      const block = document.querySelector<HTMLDivElement>(
        `[data-rbd-draggable-id='${brandNewContentBlockId}']`,
      );
      if (block) {
        block.style.scrollMarginTop = '127px'; // display the above content block as well.
        block.scrollIntoView({ behavior: 'smooth' });
        setBrandNewContentBlockId(null);
      }
    }
  }, [brandNewContentBlockId]);

  const handleClickAddContentBlock = (page: AdminMicrositePage) => {
    const temporaryId = uuid();

    const newContentBlock = {
      id: temporaryId,
      position: page.maxContentBlockPosition + 10,
      type: 'AR::MicrositeGenericContentBlock' as ContentBlockType,
      pageId: page.id,
      pageSlug: page.slug,
      anchorSlug: `generic-content-block-${temporaryId}`,
    };

    const newGenericContentBlock = {
      ...microsite.genericContentBlockTemplate,
      page: page.slug,
      id: temporaryId,
      visible: true,
    };

    const newPage = {
      ...page,
      maxContentBlockPosition: newContentBlock.position,
    };

    setBrandNewContentBlockId(temporaryId);
    setValues({
      ...values,
      genericContentBlocks: [...values.genericContentBlocks!, newGenericContentBlock],
      contentBlocks: [...values.contentBlocks!, newContentBlock],
      pages: values.pages!.map(p => (p.id === page.id ? newPage : p)),
    });
    setOpen(`generic-content-block-${temporaryId}`);
    sendPreviewTo({
      page: isSinglePageLayout(values.layout!) ? 'home' : page.slug,
      element: `generic-content-block-${temporaryId}`,
    });
  };

  return (
    <DragDropContext
      onDragEnd={({ destination, draggableId }) => {
        const [destinationPageId, destinationPageSlug] = destination.droppableId.split('|');

        if (destinationPageSlug === 'home' && destination.index === 0) {
          Notification.error({
            title: t('microsite.reorderingDisabled'),
            placement: 'topRight',
          });
          return;
        }

        setFieldValue(
          'contentBlocks',
          getNewContentBlocks({
            contentBlocks: values.contentBlocks!,
            destinationPageId,
            destinationPageSlug,
            destinationIndex: destination.index,
            movedContentBlockId: draggableId,
          }),
        );

        setFieldValue(
          'genericContentBlocks',
          getNewGenericContentBlocks({
            genericContentBlocks: values.genericContentBlocks!,
            contentBlockId: draggableId,
            destinationPageSlug,
          }),
        );

        sendPreviewTo({
          page: isSinglePageLayout(values.layout!) ? 'home' : destinationPageSlug,
          element: `generic-content-block-${draggableId}`,
        });
      }}
    >
      <>
        {(values.pages || []).map((page, index) => (
          <div className="flex flex-col gap-2" key={page.id} data-testid={`page-${page.slug}`}>
            <div className="flex gap-1 items-center">
              <span className="uppercase font-body-small-emphasis text-general-neutral-primary !tracking-[0.5px]">
                {t(`microsite.pages.${page.slug}`)}
              </span>
              <div className="border-solid border-t-[1px] !border-general-neutral-secondary grow" />
              <Button
                variant="tertiary"
                text={t('microsite.genericContentBlock.addContentBlock')}
                iconName="Plus"
                iconPosition="right"
                ariaLabel="Add content block"
                onClick={() => handleClickAddContentBlock(page)}
              />
            </div>

            <Droppable
              type="PAGE"
              droppableId={`${page.id}|${page.slug}`}
              index={index}
              ignoreContainerClipping
            >
              {(provided, snapshot) => (
                <div
                  className="flex flex-col gap-2"
                  ref={provided.innerRef}
                  style={{
                    paddingBottom: snapshot.isDraggingOver ? 40 : 0,
                  }}
                  {...provided.droppableProps}
                >
                  {contentBlocksByPageId[page.id].map((block, i) => {
                    const Component = ContentBlockMap[block.type];
                    return (
                      <Component
                        index={i}
                        key={block.id}
                        id={block.id}
                        page={page.slug}
                        anchorSlug={block.anchorSlug}
                      />
                    );
                  })}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </>
    </DragDropContext>
  );
}
