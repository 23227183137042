import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import RelinkPropertyModal from './RelinkPropertyModal';
import css from './styles.module.less';

const PropertyTable = ({ buildingName, buildingId, fetchAdminBuilding, properties }) => {
  const { t } = useTranslation('admin');
  return (
    <>
      <h2>{t('building.propertyTable.title')}</h2>
      <table className={css.propertyTable}>
        <thead>
          <tr>
            <th>{t('building.primaryProperty')}</th>
            <th>{t('building.propertyId')}</th>
            <th>{t('building.primaryAccountName')}</th>
            <th>{t('building.secondaryName')}</th>
            <th>{t('property.street')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {properties.map(property => (
            <tr key={property.id}>
              <td>
                <label>
                  <span className={css.visuallyHidden}>
                    {t('property.table.setPrimary', { name: property.name })}
                  </span>
                  <Field name="primaryPropertyId" type="radio" value={property.id} />
                </label>
              </td>
              <td>{property.vtsId}</td>
              <td>{property.primaryAccountName}</td>
              <td>{property.name}</td>
              <td>{property.street}</td>
              <td>
                <RelinkPropertyModal
                  buildingHasManyProperties={properties.length > 1}
                  buildingName={buildingName}
                  buildingId={buildingId}
                  fetchAdminBuilding={fetchAdminBuilding}
                  property={property}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PropertyTable;
