import normalize from '@root/shared/normalize';
import { Submarket } from '@root/types';

const convertArrayToObject = (array, key) => {
  return array.reduce((obj, item) => {
    return { ...obj, [item[key]]: item };
  }, {});
};

export type SubmarketsType = {
  [slug: string]: Submarket;
};

export type BuildingsType = {
  [id: string]: { id: string; displayName: string };
};

export type LandLordsType = {
  [id: string]: { name: string; id: string };
};

export type FilterOptionsState = {
  submarkets: SubmarketsType;
  buildings: BuildingsType;
  landlords: LandLordsType;
};

export const initialState: FilterOptionsState = {
  submarkets: {},
  buildings: {},
  landlords: {},
};

export const filterOptionsReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_INITIAL_FILTER_OPTIONS':
      return {
        ...state,
        submarkets: {
          ...state.submarkets,
          ...convertArrayToObject(action.payload.submarkets, 'slug'),
        },
        landlords: {
          ...state.landlords,
          ...convertArrayToObject(action.payload.landlords, 'id'),
        },
        buildings: {
          ...state.buildings,
          ...normalize(action.payload.buildings),
        },
      };
    case 'ADD_SUBMARKET_OPTIONS':
      return {
        ...state,
        submarkets: {
          ...state.submarkets,
          ...convertArrayToObject(action.payload, 'slug'),
        },
      };
    case 'ADD_BUILDING_OPTIONS':
      return {
        ...state,
        buildings: { ...state.buildings, ...normalize(action.payload) },
      };
    case 'ADD_LANDLORDS_OPTIONS':
      return {
        ...state,
        landlords: {
          ...state.landlords,
          ...convertArrayToObject(action.payload, 'id'),
        },
      };

    default:
      return state;
  }
};
