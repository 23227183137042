import type { Actions as AnalyticsAction } from './analyticsActions';
import analyticsActions from './analyticsActions';
import loadPage from './loadPage';
import setSavedStatuses, { Actions as SetSavedStatusesAction } from './setSavedStatuses';

export default {
  loadPage,
  setSavedStatuses,
  ...analyticsActions,
};
export type Actions = AnalyticsAction | SetSavedStatusesAction;
