import { useTranslation } from 'react-i18next';
import { RawTextArea } from '@components/shared/forms';
import File from './File';
import s from './File.module.less';

type Props = {
  filename: string;
  url: string;
  uploadedAt: string | null;
  description: string | null;
  className?: string;
};

const FileWithDescription = ({ filename, url, uploadedAt, description, className }: Props) => {
  const { t } = useTranslation('admin');

  const noop = () => {};

  return (
    <div className={className}>
      <File filename={filename} url={url} uploadedAt={uploadedAt} />
      <div className={s.descriptionLabel}>
        <span>{t('media.existingContent.description')}</span>
      </div>
      <RawTextArea
        className={s.description}
        name=""
        disabled
        value={description || undefined}
        onChange={noop}
      />
    </div>
  );
};

export default FileWithDescription;
