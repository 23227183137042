/* eslint-disable max-len */
import { errorType } from '@propTypes';
import {
  AdminBuilding,
  BuildingAmenitiesMetadata,
  BuildingDataCollectionMetadata,
  UpdateDemographicsParams,
  MarketingMedia,
} from '@root/types';
import FormikBuildingForm from './Form';
import FormikAmenitiesForm from './Form/AmenitiesBuildingForm';
import FormikDataCollectionForm from './Form/DataCollectionBuildingForm';
import FormikDemographicsForm from './Form/DemographicsBuildingForm';
import Listings from './Listings';
import UnlistedSpaces from './UnlistedSpaces';
import BuildingMediaTable from './BuildingMediaTable';
import BuildingContacts from './BuildingContacts';
import IndustrialAttributes from './IndustrialAttributes';
import s from './BuildingPage.module.less';
import {
  formatAmenityPayload,
  formatDataCollectionPayload,
} from './utils/updateAmenitySetBodyBuilder';
import ChannelsBuildingForm from './Form/ChannelsBuildingForm';
import FormikAutomatedMarketingForm from './Form/AutomatedMarketingForm';
import BrandedAssets from './BrandedAssets/BrandedAssets';
import Microsite from './Microsite';

export const TABS = [
  'form',
  'amenities',
  'dataCollection',
  'media',
  'contacts',
  'listedSpaces',
  'unlistedSpaces',
  'microsite',
  'brandedAssets',
  'demographics',
  'industrial',
  'channels',
  'automatedMarketing',
];

type Props = {
  building: AdminBuilding;
  updateBuilding: $TSFixMeFunction;
  updateBuildingDemographics: ({ buildingId, body }: UpdateDemographicsParams) => {};
  updateAmenitySet: ({
    buildingId,
    body,
  }: {
    buildingId: string;
    body: BuildingAmenitiesMetadata | BuildingDataCollectionMetadata;
  }) => {};
  currentPage: $TSFixMe; // TODO: PropTypes.oneOf(TABS)
  fetchAdminBuilding: $TSFixMeFunction;
  errors: errorType[];
  updateIndustryAttributes: (attrs: any) => Promise<boolean>;
  updateBuildingMarketingMedia: ({
    buildingId,
    body,
  }: {
    buildingId: string;
    body: MarketingMedia;
  }) => {};
};
const MenuSwitcher = ({
  building,
  updateBuilding,
  updateBuildingDemographics,
  updateAmenitySet,
  currentPage,
  fetchAdminBuilding,
  updateIndustryAttributes,
  errors,
  updateBuildingMarketingMedia,
}: Props) => {
  switch (currentPage) {
    case 'form':
      return (
        <section className={s.contentSection}>
          <FormikBuildingForm
            building={building}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ building: any; errors: any[]; fetchAdminBu... Remove this comment to see the full error message
            errors={errors}
            fetchAdminBuilding={fetchAdminBuilding}
            updateBuilding={updateBuilding}
          />
        </section>
      );
    case 'brandedAssets':
      return <BrandedAssets building={building} refetchBuilding={fetchAdminBuilding} />;
    case 'amenities':
      return (
        <section className={s.contentSection}>
          <FormikAmenitiesForm
            building={building}
            updateBuilding={params => {
              updateAmenitySet({
                buildingId: params.id,
                body: formatAmenityPayload(params.amenitySet),
              });
            }}
          />
        </section>
      );
    case 'dataCollection':
      return (
        <section className={s.contentSection}>
          <FormikDataCollectionForm
            building={building}
            updateBuilding={params => {
              updateAmenitySet({
                buildingId: params.id,
                body: formatDataCollectionPayload(params),
              });
            }}
          />
        </section>
      );
    case 'industrial':
      return (
        <IndustrialAttributes
          building={building}
          updateIndustryAttributes={updateIndustryAttributes}
        />
      );
    case 'demographics':
      return (
        <section className={s.contentSection}>
          <FormikDemographicsForm
            building={building}
            updateBuilding={params => {
              updateBuildingDemographics({ buildingId: params.id, body: params });
            }}
          />
        </section>
      );
    case 'listedSpaces':
      return (
        <section className={s.contentSection}>
          <Listings buildingId={building.id} />
        </section>
      );
    case 'unlistedSpaces':
      return (
        <section className={s.contentSection}>
          <UnlistedSpaces buildingId={building.id} />
        </section>
      );
    case 'media':
      return (
        <section className={s.contentSection}>
          <BuildingMediaTable
            buildingId={building.id}
            fetchAdminBuilding={fetchAdminBuilding}
            photos={building.photos}
            archivedPhotos={building.archivedPhotos}
            video={building.video}
            archivedVideos={building.archivedVideos}
            firstPhotoOrientation={building.firstPhotoOrientation}
            tourEmbedUrl={building.tourEmbedUrl}
            virtualTourSpotlight={building.virtualTourSpotlight}
          />
        </section>
      );

    case 'contacts':
      return (
        <section>
          <BuildingContacts data={building.listingAgents} onSort={fetchAdminBuilding} />
        </section>
      );

    case 'channels':
      return (
        <section className={s.contentSection}>
          <ChannelsBuildingForm building={building} updateBuilding={updateBuilding} />
        </section>
      );
    case 'automatedMarketing':
      return (
        <section className={s.contentSection}>
          <FormikAutomatedMarketingForm
            building={building}
            updateBuilding={params => {
              updateBuildingMarketingMedia({ buildingId: params.id, body: params });
            }}
          />
        </section>
      );
    case 'microsite':
      return (
        <section className={s.contentSection}>
          <Microsite building={building} />
        </section>
      );
    default:
      return null;
  }
};
export default MenuSwitcher;
