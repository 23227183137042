import { ReactElement, ReactNode, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, CustomIcon, Modal } from '@components/shared';
import 'cropperjs/dist/cropper.css';
import { CloudinaryTransformations, CropperCanvasData } from '@root/types';
import useCloudinary from '@root/shared/useCloudinary';
import { InlineNotice, Tooltip } from '@viewthespace/components';
import { trim } from '@cloudinary/url-gen/actions/reshape';
import { defaults } from 'lodash';
import useDetectImageBrightness from '@root/shared/useDetectImageBrightness';
import useCropper, { CropBoxDimentions, onZoomProps } from './useCropper';
import useCloudinaryUploader from '../Cloudinary/useCloudinaryUploader';

type UseCropperOutput<T = any> = {
  CropperModal: typeof CropperModal<T>;
  cropperModalProps: CropperModalProps<T>;
  openCropperModal: (CropperModalState: CropperModalState<T>) => void;
};

export type OnSaveArgs<T = any> = {
  cloudinaryId: string;
  cropperCanvasData: CropperCanvasData | null;
  cloudinaryTransformations: CloudinaryTransformations | null;
  customAttributes?: T;
};

export type LogoTypes = 'bannerImage' | 'wordmarkLogo' | 'lettermarkLogo';

export const cropBoxDimensionPresets: {
  lettermarkLogo: CropBoxDimentions;
  wordmarkLogo: CropBoxDimentions;
  bannerImage: CropBoxDimentions;
} = {
  lettermarkLogo: { height: 295, width: 295 },
  wordmarkLogo: { height: 80, width: 482 },
  bannerImage: { height: 93, width: 649 },
};

export const warningDimensionPresets: {
  lettermarkLogo: CropBoxDimentions;
  wordmarkLogo: CropBoxDimentions;
  bannerImage: CropBoxDimentions;
} = {
  lettermarkLogo: { width: 120, height: 120 },
  wordmarkLogo: { width: 120, height: 20 },
  bannerImage: { width: 1440, height: 190 },
};

export type ViewModes = 'edit' | 'preview' | 'simplePreview';

export type CropperModalState<T = any> = {
  cloudinaryId?: string | null;
  imageSrc?: string | null;
  cropBoxDimentions?: CropBoxDimentions;
  warningDimensions?: CropBoxDimentions;
  initialCanvasData?: CropperCanvasData | null;
  initialCloudinaryTransformations?: CloudinaryTransformations | null;
  viewMode?: ViewModes;
  trimImage?: boolean;
  notEditableMessage?: string;
  alt?: string;
  afterUpload?: (
    state: CropperModalState<T> | null,
    updateState: (newPartialState: Partial<CropperModalState<T>>) => void,
  ) => void;
  renderAdditionalFooterContent?:
    | ((
        state: CropperModalState<T>,
        updateState: (newPartialState: Partial<CropperModalState<T>>) => void,
      ) => ReactElement)
    | null;
  customAttributes?: T;
  landlordImageSrc?: string | null;
  isCropBoxResizable?: boolean;
  showZoomControls?: boolean;
};
const useCropperModal = <T,>(): UseCropperOutput<T> => {
  const cld = useCloudinary();
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState<null | CropperModalState<T>>(null);

  const getImageSrc = () => {
    if (state?.landlordImageSrc) return state.landlordImageSrc;
    if (state?.imageSrc) return state.imageSrc;
    if (!state?.cloudinaryId) return '';

    let image = cld.image(state?.cloudinaryId).format('auto');
    if (state.trimImage) {
      image = image.reshape(trim());
    }

    return image.toURL();
  };

  const isDark = useDetectImageBrightness(getImageSrc());

  return {
    CropperModal: CropperModal<T>,
    cropperModalProps: {
      state,
      isOpen,
      imageSrc: getImageSrc(),
      isDark,
      close: () => {
        setIsOpen(false);
        setState(null);
      },
      updateState: (newPartialState: Partial<CropperModalState>) => {
        setState(prevState => ({ ...prevState, ...newPartialState }));
      },
    },
    openCropperModal: cropperModalState => {
      setIsOpen(true);
      setState(cropperModalState);
    },
  };
};

type CropperModalProps<T> = {
  state: null | CropperModalState<T>;
  isOpen: boolean;
  imageSrc: string;
  close: () => void;
  onSave?: (args: OnSaveArgs<T>) => void;
  title?: React.ReactNode;
  isDark: boolean | null;
  updateState: (newPartialState: Partial<CropperModalState<T>>) => void;
  isCropBoxResizable?: boolean;
  primaryButtonText?: string;
};
export const CropperModal = <T,>({
  state,
  isOpen,
  imageSrc,
  isDark,
  close,
  onSave,
  updateState,
  isCropBoxResizable = false,
  title,
  primaryButtonText,
}: CropperModalProps<T>) => {
  const { t } = useTranslation('common');
  const [croppedImageDimensions, setCroppedImageDimensions] = useState<null | CropBoxDimentions>(
    null,
  );

  const {
    cloudinaryId,
    initialCanvasData,
    initialCloudinaryTransformations,
    warningDimensions,
    cropBoxDimentions,
    viewMode,
    notEditableMessage,
    alt,
    afterUpload,
    renderAdditionalFooterContent,
    showZoomControls,
  } = defaults(state, { editable: true, viewMode: 'edit', showZoomControls: true });

  const {
    Cropper,
    cropperProps,
    ZoomRange,
    zoomRangeProps,
    getCloudinaryTransformations,
    getCanvasData,
  } = useCropper({
    imageSrc,
    initialCanvasData,
    initialCloudinaryTransformations,
    cropBoxDimentions,
    maxZoomAmount: 7,
    onZoom: useCallback(({ newHeight, newWidth }: onZoomProps) => {
      if (newHeight && newWidth) {
        setCroppedImageDimensions({
          height: newHeight,
          width: newWidth,
        });
      }
    }, []),
    isCropBoxResizable,
    enableZoom: showZoomControls,
  });

  const {
    isUploading,
    selectAndUploadToCloudinary,
    CloudinaryUploaderFileInput,
    cloudinaryUploaderFileInputProps,
  } = useCloudinaryUploader({
    onFileUpload: blobUrl => {
      // When it's in preview mode, we don't want show the local image.
      // We want to show the uploaded cloudinary image.
      if (viewMode !== 'preview') {
        updateState({ imageSrc: blobUrl, initialCanvasData: null });
      }
    },
    onReady: ({ public_id: newCloudinaryId }) => {
      updateState({ cloudinaryId: newCloudinaryId, initialCanvasData: null });
      if (afterUpload) afterUpload(state, updateState);
    },
    onError: () => {},
  });

  useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setCroppedImageDimensions({
        height: img.naturalHeight,
        width: img.naturalWidth,
      });
    };

    img.src = imageSrc;
  }, [imageSrc]);

  const imageSizeWarning =
    warningDimensions &&
    croppedImageDimensions &&
    (croppedImageDimensions?.width < warningDimensions.width ||
      croppedImageDimensions?.height < warningDimensions.height);

  const viewModes: { [k in ViewModes]: ReactNode } = {
    edit: (
      <>
        <Cropper
          {...cropperProps}
          dragTextClassName="!bg-background-secondary/60 !text-general-gray-primary"
          className={cn(
            'h-[430px] w-full',
            '[&_.cropper-crop-box]:!bg-background-primary',
            '[&_.cropper-view-box]:!outline-[#BBBFC4]',
            isDark && '[&_.cropper-wrap-box]:!opacity-30',
            isDark === false && '[&_.cropper-wrap-box]:!opacity-80',
          )}
          alt={alt}
        />
        {imageSizeWarning && (
          <div className="absolute mt-[-52px] flex w-full justify-center">
            <InlineNotice
              className="!z-[1] !w-auto !bg-general-yellow-tertiary"
              variant="attention"
              content={t('common:cropper.imageWithZoomSizeWarning')}
            />
          </div>
        )}
        {showZoomControls ? (
          <div>
            <ZoomRange
              {...zoomRangeProps}
              className=" h-9 justify-center rounded-none !bg-background-secondary !text-general-gray-primary hover:[&_button]:!bg-general-indigo-secondary hover:[&_button]:!text-white"
              inputClassName="!w-[356px] !bg-black-010 appearance-none"
            />
          </div>
        ) : (
          <div className="!bg-background-secondary h-9"></div>
        )}
      </>
    ),
    preview: (
      <div className="relative h-[512px] w-full bg-background-secondary">
        <div className="absolute bottom-12 left-6 right-6 top-2 flex items-center justify-center bg-general-neutral-secondary">
          <img src={imageSrc} alt={alt} style={cropBoxDimentions} className="object-contain" />
        </div>
        {notEditableMessage && (
          <div className="pointer-events-none absolute top-4 flex w-full justify-center">
            <div
              className={cn(
                'flex items-center rounded-[3px] bg-background-secondary/60 p-1 !text-general-gray-primary font-body-medium',
              )}
            >
              {notEditableMessage}
            </div>
          </div>
        )}
        <div
          className={cn(
            'pointer-events-none absolute bottom-4 flex w-full flex-col items-center justify-center',
            imageSizeWarning && 'bottom-3',
          )}
        >
          {imageSizeWarning && (
            <InlineNotice
              className="!z-[1] mb-[18px] !w-auto !bg-general-yellow-tertiary"
              variant="attention"
              content={t('common:cropper.imageSizeWarning')}
            />
          )}
          <div
            className={cn(
              'flex items-center gap-1 rounded-[3px] p-1 !text-general-gray-primary font-body-medium',
            )}
          >
            {t('cropper.actualDisplaySize')}
            <img src={imageSrc} alt={alt} className="max-h-[20px] max-w-[120px] object-contain" />
          </div>
        </div>
      </div>
    ),
    simplePreview: (
      <div className="relative h-[512px] w-full bg-background-secondary">
        <div className="absolute inset-0 flex items-center justify-center">
          <img src={imageSrc} alt={alt} style={cropBoxDimentions} className="object-contain" />
        </div>
      </div>
    ),
  };

  return (
    <Modal
      data-testid="edit-modal"
      isOpen={isOpen}
      closeModal={close}
      title={title}
      centered
      closable={false}
      maskClosable={false}
      bodyStyle={{ padding: 0 }}
      className="[&_.ant-modal-title]:!mx-[-8px] [&_.ant-modal-title]:!my-[-4px] [&_.ant-modal-title]:!text-left [&_.ant-modal-title]:!font-body-large-emphasis"
      footer={
        <div className="flex w-full items-center justify-end gap-1 py-[10px]">
          <div className="mr-auto flex items-center ">
            <div
              role="button"
              className="font-body-medium"
              onClick={() => selectAndUploadToCloudinary && selectAndUploadToCloudinary()}
            >
              <CustomIcon type="upload" className="pr-1" />
              {t('cropper.uploadNewImage')}
            </div>
            {renderAdditionalFooterContent && state ? (
              <>
                <div className="mx-1.5 h-3 w-[1px] bg-black-035" />
                {renderAdditionalFooterContent(state, updateState)}
              </>
            ) : null}
          </div>
          <Button
            type="tertiary"
            size="small"
            onClick={close}
            data-testid="cancelCoverPhotoChangesButton"
          >
            {t('common:cancel')}
          </Button>
          {!cloudinaryId || isUploading ? (
            <Tooltip
              className="z-[1000]"
              placement="top"
              content={t('common:cropper:imageProcessing')}
              trigger={
                <span>
                  <Button type="primary" size="small" disabled>
                    {t('common:save')}
                  </Button>
                </span>
              }
              triggerAction="hover"
            />
          ) : (
            <Button
              type="primary"
              size="small"
              data-testid="saveCoverPhotoChangesButton"
              onClick={() => {
                if (onSave && cloudinaryId)
                  onSave({
                    cloudinaryId,
                    cropperCanvasData: getCanvasData(),
                    cloudinaryTransformations: getCloudinaryTransformations(),
                    customAttributes: state?.customAttributes,
                  });
                close();
              }}
            >
              {primaryButtonText || t('common:save')}
            </Button>
          )}
        </div>
      }
    >
      <div className="relative h-full w-full">{viewModes[viewMode]}</div>
      <CloudinaryUploaderFileInput {...cloudinaryUploaderFileInputProps} />
    </Modal>
  );
};

export default useCropperModal;
