import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '@store/actions/listingPage';
import {
  useSetListingSharerAsSessionStorageContacts,
  useSetArrivedOnShareLink,
} from '@shared/sessionStorageForSiteVisitors';
import TruvaLayout from '../Layout';
import ListingLayout from './Layout';
import NotFound from '../404/NotFound';
import ListingHead from './ListingHead';
import ListingSkeleton from './ListingSkeleton/ListingSkeleton';

const mapStateToProps = ({ listings: { listingShare } }) => ({
  listing: listingShare,
});

const mapDispatch = {
  listingSharePageLoad: (listingId: string, encodedData: string) =>
    actions.listingSharePageLoad({ id: listingId, encodedData }),
};

const connector = connect(mapStateToProps, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

export const RawListingShare = ({
  listing,
  listingSharePageLoad,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isPreview,
}: ReduxProps & { isPreview: false }) => {
  const listingId = listing?.id;
  const [loading, setLoading] = useState(true);

  const { encodedData } = useParams<{ encodedData: string }>();

  useEffect(() => {
    if (!listingId) return;
    listingSharePageLoad(listingId, encodedData).then(() => setLoading(false));
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingId]);

  useSetArrivedOnShareLink();

  useSetListingSharerAsSessionStorageContacts(listing?.listingSharer || {});

  if (!listing) return <NotFound isTransientPage />;
  if (loading) {
    return (
      <TruvaLayout>
        <ListingSkeleton />
      </TruvaLayout>
    );
  }

  return listing ? (
    <TruvaLayout>
      <ListingHead listing={listing} />
      <ListingLayout listing={listing} />
    </TruvaLayout>
  ) : null;
};

export default connector(RawListingShare);
