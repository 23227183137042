import { useDispatch } from 'react-redux';
import { EVENTS, EVENT_TYPES, PARAMETERS } from '@root/tracking/constants';
import { anonymousId } from '@root/tracking/analytics';
import {
  Brochure,
  Listing,
  SearchListing,
  TourbookListing,
  TourbookListingAnalyticsInformation,
} from '@root/types';

/* should probably move these types to a dedicated file for analytics types */
export type InquirySourcePage =
  | typeof PARAMETERS.listingPage
  | typeof PARAMETERS.searchResultsPage
  | typeof PARAMETERS.buildingPage
  | typeof PARAMETERS.tourbookPage;
export type InquirySourceContent =
  | typeof PARAMETERS.inquiryForm
  | typeof PARAMETERS.listingPreview
  | typeof PARAMETERS.siteVisitorInquiryForm;
export type ContactSourcePage = typeof PARAMETERS.listingPage | typeof PARAMETERS.buildingPage;
export type ContactSourceContent = typeof PARAMETERS.inquiryForm | typeof PARAMETERS.contactSection;
export type ListingPreviewSourcePageType =
  | typeof PARAMETERS.searchResultsPage
  | typeof PARAMETERS.buildingPage
  | typeof PARAMETERS.tourbookPage;
export type TourbookSourceContent =
  | typeof PARAMETERS.tableOfContents
  | typeof PARAMETERS.tableOfContentsDropdown
  | typeof PARAMETERS.tourbookListing;

export default function useAnalyticsEvent() {
  const dispatch = useDispatch();

  const clickToPage = (
    {
      sourcePage,
      sourceContent,
      destination,
      actionType = 'USER_NAVIGATION',
    }: {
      sourcePage?: string;
      sourceContent?: string;
      destination: string;
      actionType?: string;
    },
    otherAttrs: Object = {},
  ) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.clickToPage,
            sourceContent,
            sourcePage,
            destination,
            ...otherAttrs,
          },
        },
      },
    });
  };

  const pageLoaded = (
    {
      pageName,
      actionType,
    }: {
      pageName: string;
      actionType: string;
    },
    otherAttrs: Object = {},
  ) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.page,
            event: pageName,
            ...otherAttrs,
          },
        },
      },
    });
  };

  const mapInteraction = (
    {
      actionType,
      action,
      sourcePage,
      sourceContent,
    }: {
      actionType: string;
      action: string;
      sourcePage: string;
      sourceContent?: string;
    },
    localAttrs: Object = {},
  ) =>
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.mapInteraction,
            action,
            sourcePage,
            sourceContent,
            ...localAttrs,
          },
        },
      },
    });

  const sortInteraction = ({
    actionType,
    sortValue,
    sourcePage,
    sourceContent,
  }: {
    actionType: string;
    sortValue: string;
    sourcePage: string;
    sourceContent?: string;
  }) =>
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.sortInteraction,
            sortValue,
            sourcePage,
            sourceContent,
          },
        },
      },
    });

  const areasInteraction = ({
    actionType,
    action,
    sourcePage,
    sourceContent,
  }: {
    actionType: string;
    action: string;
    sourceContent: string;
    sourcePage: string;
  }) =>
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.areasInteraction,
            action,
            sourceContent,
            sourcePage,
          },
        },
      },
    });

  const galleryInteraction = ({
    actionType,
    action,
    sourcePage,
    sourceContent,
    analyticsInformation,
  }: {
    actionType: string;
    action: string;
    sourceContent: string;
    sourcePage: string;
    analyticsInformation?: TourbookListingAnalyticsInformation;
  }) =>
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.galleryInteraction,
            action,
            sourceContent,
            sourcePage,
            ...analyticsInformation,
          },
        },
      },
    });

  const lightboxInteraction = ({
    actionType,
    action,
    sourcePage,
    sourceContent,
  }: {
    actionType: string;
    action: string;
    sourceContent: string;
    sourcePage: string;
  }) =>
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.lightboxInteraction,
            action,
            sourceContent,
            sourcePage,
          },
        },
      },
    });

  const videoInteraction = ({
    actionType,
    action,
    sourcePage,
    sourceContent,
    analyticsInformation,
  }: {
    actionType: string;
    action: string;
    sourceContent: string;
    sourcePage: string;
    analyticsInformation?: TourbookListingAnalyticsInformation;
  }) =>
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.videoInteraction,
            action,
            sourceContent,
            sourcePage,
            ...analyticsInformation,
          },
        },
      },
    });

  const threeDTourInteraction = ({
    actionType,
    action,
    sourcePage,
    sourceContent,
    analyticsInformation,
  }: {
    actionType: string;
    action: string;
    sourceContent: string;
    sourcePage: string;
    analyticsInformation?: TourbookListingAnalyticsInformation;
  }) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.threeDTourInteraction,
            action,
            sourceContent,
            sourcePage,
            ...analyticsInformation,
          },
        },
      },
    });
  };

  const filterInteraction = ({
    action,
    actionType,
    sourcePage = PARAMETERS.searchResultsPage,
    ...otherAttrs
  }: {
    action: string;
    actionType: string;
    sourcePage?: string;
    sourceContent?: string;
    currentFilters?: Object;
    filter?: string;
    value?: any;
    filterType?: string;
  }) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.filterInteraction,
            action,
            sourcePage,
            ...otherAttrs,
          },
        },
      },
    });
  };

  const savedSearchInteraction = (
    {
      action,
      actionType,
      sourcePage,
      sourceContent,
      savedSearchId,
      savedSearchName,
      savedSearchCount,
    }: {
      action: string;
      actionType: string;
      sourcePage: string;
      sourceContent?: string;
      savedSearchCount?: number;
      savedSearchId: string | null;
      savedSearchName: string | null;
    },
    ...otherAttrs
  ) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.savedSearchInteraction,
            sourcePage,
            sourceContent,
            action,
            savedSearchId,
            savedSearchName,
            savedSearchCount,
            ...otherAttrs,
          },
        },
      },
    });
  };

  /**
   * Returns listing analytic information based on whether it is a listing or search listing.
   *
   * @param {(Listing | SearchListing)} listing
   * @return {*}
   */

  const listingAnalytics = (listing: Listing | SearchListing | TourbookListing) => {
    const listingForAnalytics = listing as Listing;

    if (
      listingForAnalytics.building !== undefined &&
      listingForAnalytics.listingAgents !== undefined
    ) {
      const listingAgentsEmails = listingForAnalytics?.listingAgents?.map(({ email }) => email);
      const buildingEmployerId = listingForAnalytics?.building.employerId;

      return { ...listing.analyticsInformation, listingAgentsEmails, buildingEmployerId };
    }
    return listing.analyticsInformation;
  };

  type SearchListingActionType =
    | ListingPreviewActionType
    | QuickInquiryInteractionActionType
    | InquiryInteractionActionType
    | ContactInteractionActionType;
  type SearchListingAction =
    | ListingPreviewAction
    | QuickInquiryInteractionAction
    | InquiryInteractionAction
    | ContactInteractionAction;
  type SearchListingEvent =
    | ListingPreviewEvent
    | QuickInquiryInteractionEvent
    | InquiryInteractionEvent
    | ContactInteractionEvent;
  const searchListingAnalytics = ({
    action,
    actionType,
    event,
    listing,
    otherAttributes,
  }: {
    action: SearchListingAction;
    actionType: SearchListingActionType;
    event: SearchListingEvent;
    listing?: Listing | SearchListing | TourbookListing;
    otherAttributes?: { [x: string]: any };
  }) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            action,
            event,
            eventType: EVENT_TYPES.track,
            ...(listing && listingAnalytics(listing)),
            ...otherAttributes,
          },
        },
      },
    });
  };

  type ListingPreviewActionType =
    | 'OPEN_LISTING_PREVIEW'
    | 'VIEW_LISTING'
    | 'CLOSE_LISTING_PREVIEW'
    | 'PAGINATE_LISTING'
    | 'VIEW_FULL_DETAILS'
    | 'PAGINATE_GALLERY'
    | 'FLOOR_PLAN_CLICKED'
    | 'BROCHURE_CLICKED'
    | QuickInquiryInteractionActionType;
  type ListingPreviewAction =
    | typeof PARAMETERS.openListingPreview
    | typeof PARAMETERS.viewListing
    | typeof PARAMETERS.closeListingPreview
    | typeof PARAMETERS.paginate
    | typeof PARAMETERS.viewFloorPlan
    | typeof PARAMETERS.download
    | QuickInquiryInteractionAction;
  type ListingPreviewEvent =
    | typeof EVENTS.listingPreviewInteraction
    | typeof EVENTS.galleryInteraction
    | typeof EVENTS.saveInteraction
    | typeof EVENTS.contactInteraction
    | typeof EVENTS.clickToPage
    | typeof EVENTS.tourbookInteraction
    | typeof EVENTS.attachmentInteraction
    | typeof EVENTS.inquiryInteraction
    | QuickInquiryInteractionEvent;

  const listingPreviewAnalytics = ({
    listingPreviewAction,
    listingPreviewactionType,
    listingPreviewEvent,
    listing,
    otherAttributes,
    sourcePage,
  }: {
    listingPreviewactionType: ListingPreviewActionType;
    listingPreviewAction: ListingPreviewAction;
    listingPreviewEvent: ListingPreviewEvent;
    listing: Listing | SearchListing;
    otherAttributes?: { [x: string]: any };
    sourcePage: ListingPreviewSourcePageType;
  }) => {
    searchListingAnalytics({
      action: listingPreviewAction,
      actionType: listingPreviewactionType,
      event: listingPreviewEvent,
      listing,
      otherAttributes: {
        listingPreview: true,
        sourceContent: PARAMETERS.listingPreview,
        sourcePage,
        ...otherAttributes,
      },
    });
  };

  type QuickInquiryInteractionAction =
    | typeof PARAMETERS.inquiry.resend
    | typeof PARAMETERS.inquiry.send
    | typeof PARAMETERS.inquiry.error
    | typeof PARAMETERS.quickInquiry.inquire
    | typeof PARAMETERS.quickInquiry.defaultMessage;
  type QuickInquiryInteractionActionType =
    | 'QUICK_INQUIRY_INQUIRE'
    | 'QUICK_INQUIRY_DEFAULT_MESSAGE'
    | 'QUICK_INQUIRY_SEND_MESSAGE'
    | 'QUICK_INQUIRY_RESEND_MESSAGE'
    | 'QUICK_INQUIRY_ERROR';
  type QuickInquiryInteractionEvent = typeof EVENTS.inquiryInteraction;

  const quickInquiryInteraction = ({
    action,
    actionType,
    event,
    listing,
    oneClickInquiryActivated,
    otherAttributes,
  }: {
    action: QuickInquiryInteractionAction;
    actionType: QuickInquiryInteractionActionType;
    event: QuickInquiryInteractionEvent;
    listing: Listing | SearchListing;
    oneClickInquiryActivated: boolean;
    otherAttributes?: { [x: string]: any };
  }) => {
    searchListingAnalytics({
      action,
      actionType,
      event,
      listing,
      otherAttributes: {
        sourceContent: PARAMETERS.listingCard,
        sourcePage: PARAMETERS.searchResultsPage,
        inquiryType: oneClickInquiryActivated
          ? PARAMETERS.quickInquiry.oneClick
          : PARAMETERS.quickInquiry.quick,
        ...otherAttributes,
      },
    });
  };

  type InquiryInteractionAction = typeof PARAMETERS.inquiry.send | typeof PARAMETERS.inquiry.error;
  type InquiryInteractionActionType = 'INQUIRY_SEND_MESSAGE' | 'INQUIRY_ERROR';
  type InquiryInteractionEvent = typeof EVENTS.inquiryInteraction;
  const inquiryInteraction = ({
    action,
    actionType,
    listing,
    sourcePage = PARAMETERS.listingPage,
    sourceContent = PARAMETERS.inquiryForm,
    otherAttributes,
  }: {
    action: InquiryInteractionAction;
    actionType: InquiryInteractionActionType;
    listing: Listing | SearchListing;
    sourcePage?: InquirySourcePage;
    sourceContent?: InquirySourceContent;
    otherAttributes?: { [x: string]: any };
  }) => {
    searchListingAnalytics({
      action,
      actionType,
      event: EVENTS.inquiryInteraction,
      listing,
      otherAttributes: {
        sourcePage,
        sourceContent,
        inquiryType: PARAMETERS.inquiry.general,
        ...otherAttributes,
      },
    });
  };

  type ContactInteractionAction =
    | typeof PARAMETERS.contactDropdown
    | typeof PARAMETERS.contactShowAllContact
    | typeof PARAMETERS.contactRevealContact
    | typeof PARAMETERS.contactSelectContact;
  type ContactInteractionActionType =
    | 'CONTACT_DROPDOWN_CLICK'
    | 'CONTACT_SHOW_ALL_CONTACT'
    | 'CONTACT_REVEAL_CONTACT'
    | 'CONTACT_SELECT_CONTACT';

  type ContactInteractionEvent = typeof EVENTS.contactInteraction;

  const contactInteraction = ({
    action,
    actionType,
    listing,
    otherAttributes,
    sourceContent = PARAMETERS.inquiryForm,
    sourcePage = PARAMETERS.listingPage,
  }: {
    action: ContactInteractionAction;
    actionType: ContactInteractionActionType;
    listing?: Listing | SearchListing;
    sourcePage?: ContactSourcePage | InquirySourcePage;
    sourceContent?: ContactSourceContent | InquirySourceContent;
    otherAttributes?: { [x: string]: any };
  }) => {
    searchListingAnalytics({
      action,
      actionType,
      event: EVENTS.contactInteraction,
      listing,
      otherAttributes: {
        sourceContent,
        sourcePage,
        ...otherAttributes,
      },
    });
  };

  type UserAccountInteractionAction =
    | typeof PARAMETERS.updateProfessionalInfo
    | typeof PARAMETERS.updateInquirySettings;

  type UserAccountSourceContent = 'onboarding modal' | 'profile tab' | 'inquiry tab';

  type UserAccountSourcePage = 'account settings';

  const userAccountInteraction = ({
    action,
    actionType,
    sourceContent,
    sourcePage,
    otherAttributes,
  }: {
    action: UserAccountInteractionAction;
    actionType: string;
    sourceContent: UserAccountSourceContent;
    sourcePage: UserAccountSourcePage;
    otherAttributes?: { [x: string]: any };
  }) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.userAccountInteraction,
            action,
            sourceContent,
            sourcePage,
            ...otherAttributes,
          },
        },
      },
    });
  };

  type FloorplanSourcePage = ListingPreviewSourcePageType | typeof PARAMETERS.homepage;
  type FloorplanSourceContent =
    | typeof PARAMETERS.listingCard
    | typeof PARAMETERS.listingPreview
    | typeof PARAMETERS.onMarketListings;

  const floorplanInteraction = ({
    listing,
    sourcePage,
    sourceContent,
    listingPreview = false,
    otherAttributes,
  }: {
    listing: Listing | SearchListing;
    sourcePage: FloorplanSourcePage;
    sourceContent: FloorplanSourceContent;
    listingPreview?: boolean;
    otherAttributes?: { [x: string]: any };
  }) => {
    searchListingAnalytics({
      action: PARAMETERS.viewFloorPlan,
      actionType: 'FLOOR_PLAN_CLICKED',
      event: EVENTS.galleryInteraction,
      listing,
      otherAttributes: {
        sourcePage,
        sourceContent,
        listingPreview,
        ...otherAttributes,
      },
    });
  };

  type BrochureSourcePage =
    | ListingPreviewSourcePageType
    | typeof PARAMETERS.listingPage
    | typeof PARAMETERS.marketTourbookPage;
  type BrochureSourceContent =
    | typeof PARAMETERS.listingPreview
    | typeof PARAMETERS.onMarketListings
    | typeof PARAMETERS.tourbookListing;

  const brochureInteraction = ({
    sourcePage,
    listing,
    sourceContent,
    listingPreview = false,
    otherAttributes,
  }: {
    sourcePage: BrochureSourcePage;
    listing?: Listing | SearchListing | TourbookListing;
    sourceContent?: BrochureSourceContent;
    listingPreview?: boolean;
    otherAttributes: {
      attachmentId: Brochure['id'];
      attachmentName: Brochure['name'];
      [x: string]: any;
    };
  }) => {
    searchListingAnalytics({
      actionType: 'BROCHURE_CLICKED',
      action: PARAMETERS.download,
      event: EVENTS.attachmentInteraction,
      listing,
      otherAttributes: {
        sourcePage,
        sourceContent,
        listingPreview,
        attachmentType: PARAMETERS.brochure,
        ...otherAttributes,
      },
    });
  };

  type TourbookInteractionActions =
    | typeof PARAMETERS.saveRentOverride
    | typeof PARAMETERS.selectListing
    | typeof PARAMETERS.tableOfContentsExpand
    | typeof PARAMETERS.tableOfContentsCollapse
    | typeof PARAMETERS.saveChanges
    | typeof PARAMETERS.save
    | typeof PARAMETERS.addListing
    | typeof PARAMETERS.deleteListing
    | typeof PARAMETERS.createTourbook
    | typeof PARAMETERS.viewAnalytics;

  const tourbookInteraction = ({
    action,
    actionType,
    sourceContent = null,
    sourcePage = PARAMETERS.tourbookPage,
    otherAttributes,
  }: {
    action: TourbookInteractionActions;
    actionType: string;
    sourceContent?: string | null;
    sourcePage?: string;
    otherAttributes?: { [x: string]: any };
  }) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.tourbookInteraction,
            action,
            sourceContent,
            sourcePage,
            ...otherAttributes,
          },
        },
      },
    });
  };

  const saveInteraction = ({
    action,
    actionType = 'SAVE_INTERACTION',
    sourcePage,
    ...otherAttributes
  }: {
    action: string;
    actionType?: string;
    sourcePage: string;
    otherAttributes?: { [x: string]: any };
  }) => {
    dispatch({
      type: actionType,
      payload: {
        meta: {
          analytics: {
            eventType: EVENT_TYPES.track,
            event: EVENTS.saveInteraction,
            action,
            sourcePage,
            ...otherAttributes,
          },
        },
      },
    });
  };

  return {
    anonymousId: anonymousId(),
    areasInteraction,
    brochureInteraction,
    clickToPage,
    contactInteraction,
    filterInteraction,
    floorplanInteraction,
    galleryInteraction,
    inquiryInteraction,
    lightboxInteraction,
    listingPreviewAnalytics,
    mapInteraction,
    pageLoaded,
    quickInquiryInteraction,
    savedSearchInteraction,
    sortInteraction,
    threeDTourInteraction,
    tourbookInteraction,
    userAccountInteraction,
    videoInteraction,
    saveInteraction,
    PARAMETERS,
  };
}
