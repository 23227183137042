import { Form } from '@ant-design/compatible';
import { Row, Col, Input, Select, Checkbox, Tooltip } from 'antd';
import { parseISO, format } from 'date-fns';
import {
  AdminListing,
  ListingStatus,
  LISTING_STATUSES,
  LEASE_TYPES,
  SUITE_NAME_TYPES,
} from '@root/types';
import { Button } from '@components/shared';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { InlineNotice, RichTextEditor } from '@viewthespace/components';
import RelinkArchivedSpaceModal from './RelinkArchivedSpaceModal';

type Props = {
  listing: AdminListing;
  form: any; // not worth getting the type from antd 3 forms
};

const ListingDetailsSection = ({ listing, form }: Props) => {
  const { t } = useTranslation('admin');
  const { getFieldDecorator } = form;
  const { Option } = Select;
  const liveListingStatuses: ListingStatus[] = ['on_market', 'off_market'];
  const flags = useFlags();

  const viewListingButtonText = (status: ListingStatus) => {
    if (liveListingStatuses.includes(status)) {
      return t('listing.viewListingButton.live');
    }

    return t('listing.viewListingButton.preview');
  };

  const suiteTypeName = type => {
    let suiteType = t(`listing.suiteNameTypes.${type}`);

    if (type === '') {
      suiteType = t('listing.suiteNameTypes.none');
    }
    return suiteType;
  };

  const suiteTypeIndex = SUITE_NAME_TYPES.indexOf('');
  const defaultSuiteType = SUITE_NAME_TYPES[suiteTypeIndex];

  return (
    <>
      <div className="flex items-center gap-1 py-2">
        <h2 className="font-subtitle">{t('listing.listingDataTitle')}</h2>
      </div>
      <Form.Item label={t('listing.vtsSpaceId')}>
        {getFieldDecorator('vtsSpaceId', { initialValue: listing.space.vtsId })(<Input disabled />)}
        <RelinkArchivedSpaceModal
          spaceId={listing.space.vtsId}
          buildingId={listing.building.id}
          listingId={listing.id}
        />
      </Form.Item>
      <Form.Item label={t('listing.vtsMarketplaceListingId')}>
        {getFieldDecorator('vtsMarketplaceListingId', { initialValue: listing.id })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label={t('listing.current')}>
        {getFieldDecorator('current', {
          initialValue: listing.current,
          valuePropName: 'checked',
        })(<Checkbox disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.vtsFloorName')}>
        {getFieldDecorator('vtsFloorName', { initialValue: listing.space.floorName })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label={t('listing.displayFloorName')} required>
        {getFieldDecorator('floor', {
          initialValue: listing.floor,
          rules: [
            {
              required: true,
              message: t('listing.floorNameValidation'),
              suppressWarnings: true,
            },
          ],
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('listing.vtsSuiteName')}>
        {getFieldDecorator('vtsSuiteName', { initialValue: listing.space.suite })(
          <Input disabled />,
        )}
      </Form.Item>
      {flags['demand.multi-asset-suite'] ? (
        <Row gutter={[8, 0]}>
          <Col span={12}>
            <Form.Item
              label={t('listing.displaySuiteName')}
              labelCol={{ sm: { span: 10 } }}
              wrapperCol={{ flex: 'flex' }}
            >
              {getFieldDecorator('suiteType', {
                initialValue: listing.suiteType ? listing.suiteType : defaultSuiteType,
              })(
                <Select data-testid="suiteTypeDropdown">
                  {[...SUITE_NAME_TYPES]
                    .sort((typeA, typeB) =>
                      suiteTypeName(typeA).localeCompare(suiteTypeName(typeB)),
                    )
                    .map(type => (
                      <Option key={type} value={type}>
                        {suiteTypeName(type)}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item wrapperCol={{ flex: 'flex' }}>
              {getFieldDecorator('suite', { initialValue: listing.suite })(<Input />)}
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Form.Item label={t('listing.displaySuiteName')}>
          {getFieldDecorator('suite', { initialValue: listing.suite })(<Input />)}
        </Form.Item>
      )}
      <Form.Item label={t('listing.availabilityType')}>
        {getFieldDecorator('availabilityType', { initialValue: listing.space.availabilityType })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label={t('listing.availabilityDate')}>
        {getFieldDecorator('availabilityDate', {
          initialValue: listing.space.availabilityDate,
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.size')}>
        {getFieldDecorator('spaceArea', {
          initialValue: get(listing.space.remeasuredArea, 'magnitude'),
        })(<Input suffix={t(`units:${get(listing.space.remeasuredArea, 'unit', '')}`)} disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.maximumContiguousArea')}>
        {getFieldDecorator('maximumContiguousArea', {
          initialValue: get(listing.space.maximumContiguousArea, 'magnitude'),
        })(
          <Input
            suffix={t(`units:${get(listing.space.maximumContiguousArea, 'unit', '')}`)}
            disabled
          />,
        )}
      </Form.Item>
      <Form.Item label={t('listing.floorComposition')}>
        {getFieldDecorator('floorComposition', {
          initialValue: listing.space.floorComposition,
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.condition')}>
        {getFieldDecorator('condition', {
          initialValue: listing.space.condition,
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.listingStatus')}>
        {getFieldDecorator('status', { initialValue: listing.status })(
          <Select>
            {LISTING_STATUSES.map(status => (
              <Option key={status} value={status}>
                {t(`listing.statuses.${status}`)}
              </Option>
            ))}
          </Select>,
        )}
        {listing.status !== 'retired' ? (
          <a href={listing.url as string} target="_blank" rel="noopener noreferrer">
            <Button type="secondary" data-testid="view-listing-button">
              {viewListingButtonText(listing.status)}
            </Button>
          </a>
        ) : null}
      </Form.Item>
      <Form.Item label={t('listing.requestedDate')}>
        {getFieldDecorator('requestedAt', {
          initialValue: listing.requestedAt ? format(parseISO(listing.requestedAt), 'PP - p') : '',
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.onMarketDate')}>
        {getFieldDecorator('onMarketAt', {
          initialValue: listing.onMarketAt ? format(parseISO(listing.onMarketAt), 'M/d/yyyy') : '',
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.offMarketDate')}>
        {getFieldDecorator('offMarketAt', {
          initialValue: listing.offMarketAt
            ? format(parseISO(listing.offMarketAt), 'M/d/yyyy')
            : '',
        })(<Input disabled />)}
      </Form.Item>
      <Tooltip trigger="hover" placement="topLeft" title={t('listing.notMarketableTooltip')}>
        <Form.Item label={t('listing.notMarketable')}>
          {getFieldDecorator('notMarketable', {
            initialValue: listing.notMarketable,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </Form.Item>
      </Tooltip>
      <Form.Item label={t('listing.exclusive')}>
        {getFieldDecorator('exclusive', {
          initialValue: listing.exclusive,
          valuePropName: 'checked',
        })(
          <Checkbox
            disabled={
              !!listing.onMarketAt &&
              new Date(new Date(listing.onMarketAt).getTime() + 30 * 24 * 60 * 60 * 1000).setHours(
                0,
                0,
                0,
                0,
              ) <= new Date().setHours(0, 0, 0, 0)
            }
          />,
        )}
      </Form.Item>
      <Form.Item label={t('listing.exclusiveUntilDate')}>
        {getFieldDecorator('exclusiveUntil', {
          initialValue: listing.exclusiveUntil
            ? format(parseISO(listing.exclusiveUntil), 'M/d/yyyy')
            : '',
        })(<Input disabled />)}
      </Form.Item>

      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item
            label={t('listing.slabToSlabHeight')}
            labelCol={{ sm: { span: 10 } }}
            wrapperCol={{ flex: 'flex' }}
          >
            {getFieldDecorator('slabToSlabHeightMeasure.magnitude', {
              initialValue: listing.slabToSlabHeightMeasure
                ? listing.slabToSlabHeightMeasure.magnitude
                : '',
            })(<Input type="number" />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item wrapperCol={{ flex: 'flex' }}>
            {getFieldDecorator('slabToSlabHeightMeasure.units.length', {
              initialValue: listing.slabToSlabHeightMeasure
                ? listing.slabToSlabHeightMeasure.units.length
                : listing.buildingCeilingHeightUnits,
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[8, 0]}>
        <Col span={12}>
          <Form.Item
            label={t('listing.finishedCeilingHeight')}
            labelCol={{ sm: { span: 10 } }}
            wrapperCol={{ flex: 'flex' }}
          >
            {getFieldDecorator('finishedCeilingHeightMeasure.magnitude', {
              initialValue: listing.finishedCeilingHeightMeasure
                ? listing.finishedCeilingHeightMeasure.magnitude
                : '',
            })(<Input type="number" />)}
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item wrapperCol={{ flex: 'flex' }}>
            {getFieldDecorator('finishedCeilingHeightMeasure.units.length', {
              initialValue: listing.finishedCeilingHeightMeasure
                ? listing.finishedCeilingHeightMeasure.units.length
                : listing.buildingCeilingHeightUnits,
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={t('listing.maximumCapacityPersons')}>
        {getFieldDecorator('maximumCapacityPersons', {
          initialValue: listing.maximumCapacityPersons,
        })(<Input />)}
      </Form.Item>
      <Form.Item label={t('listing.leaseType')}>
        {getFieldDecorator('leaseType', {
          initialValue: listing.leaseType,
        })(
          <Select>
            {LEASE_TYPES.map(leaseType => (
              <Option key={leaseType} value={leaseType}>
                {t(`listing.leaseTypes.${leaseType}`)}
              </Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      {flags['market.listing-space-descriptions-support-rich-text'] ? (
        <Form.Item label={t('listing.description')}>
          {getFieldDecorator('descriptionHtml', { initialValue: listing.space.descriptionHtml })(
            <RichTextEditor
              label="ignore"
              hideLabel
              editorClassName="min-h-[100px]"
              initialContent={listing.space.descriptionHtml || ''}
              isDisabled={listing.space.integrationEnabledForDescription}
              excludeMenuItems={['textSize', 'textAlign']}
            />,
          )}
          {listing.space.integrationEnabledForDescription && (
            <div className="mt-1">
              <InlineNotice
                testId="integration-enabled-notice"
                content={t('listing.integrationEnabled')}
              />
            </div>
          )}
        </Form.Item>
      ) : null}
    </>
  );
};

export default ListingDetailsSection;
