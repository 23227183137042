import { ForwardedRef, useRef, useState } from 'react';
import {
  UploadApiResponse as CloudinaryResponse,
  UploadApiErrorResponse as CloudinaryErrorResponse,
} from 'cloudinary';
import { Button, Spinner } from '@components/shared';
import api from '@shared/api';
import useEnv from '@shared/useEnv';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ButtonSize, ButtonType } from '@components/shared/V2Button/Button';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';
import s from '../../RawInput/Input.module.less';

export type Props = {
  name: string;
  onLoading: (x: boolean) => void;
  onReady: (x: CloudinaryResponse) => void;
  onError: (x: string) => void;
  buttonClassName?: string;
  buttonSize?: ButtonSize;
  buttonContent?: String | React.ReactNode;
  buttonType?: ButtonType;
  buttonRef?: ForwardedRef<HTMLButtonElement>;
  spinnerClass?: string;
  uploadToCore?: boolean;
} & Omit<FieldWrapperProps, 'children'>;

const defaultProps = {
  buttonType: 'primary',
  buttonSize: 'small',
};

const CloudinaryFileButtonInput = ({
  name,
  onLoading,
  onReady,
  onError,
  labelClass,
  containerClass,
  labelText,
  required,
  buttonClassName,
  buttonSize,
  buttonContent,
  buttonType,
  buttonRef,
  spinnerClass,
  inputContainerClass,
  uploadToCore = false,
}: Props) => {
  const {
    cloudinaryCloud,
    cloudinaryUploadPreset,
    cloudinaryVtsImageUploadPreset,
    cloudinaryVtsImageCloud,
  } = useEnv();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('');

  const cloudName = uploadToCore ? cloudinaryVtsImageCloud : cloudinaryCloud;
  const cloudinaryPreset = uploadToCore ? cloudinaryVtsImageUploadPreset : cloudinaryUploadPreset;

  const uploadToCloudinary = form => async event => {
    if (!event.target.files[0]) {
      return;
    }
    setLoading(true);
    onLoading(true);

    const file = event.target.files[0];

    if (file.size > 15_000_000) {
      await form.setFieldTouched(name);
      await form.setFieldError(name, t('common:formErrors.imageSizeTooLarge'));
      setLoading(false);
      return;
    }

    await form.setFieldError(name, null);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', cloudinaryPreset || '');

    const route = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
    const response = await api.fetch(route, { method: 'POST', body: formData });

    setLoading(false);
    onLoading(false);

    if (response.ok) {
      const json: CloudinaryResponse = await response.json();
      onReady(json);
    } else {
      const json: CloudinaryErrorResponse = await response.json();
      onError(json.error.message);
    }
  };

  const inputRef = useRef(null);

  const handleClickButton = () => {
    if (!inputRef.current) return;
    // @ts-ignore
    inputRef.current?.click();
  };

  return (
    <FieldWrapper
      {...{ name, labelClass, containerClass, labelText, required, inputContainerClass }}
    >
      <Field name={name}>
        {({ meta, form }) => (
          <>
            {/* Use raw input element to use inputRef.
                Somehow defining a prop ref in RawInput and using passing it to input does not work
            */}
            <input
              className={classNames(
                s.input,
                loading || (meta.touched && meta.error && s.error),
                s.cloudinaryFileButtonInput,
              )}
              name={name}
              data-testid={name}
              id={name}
              type="file"
              onChange={uploadToCloudinary(form)}
              ref={inputRef}
            />
            <Button
              data-testid="cloudinary-input-button"
              onClick={handleClickButton}
              className={buttonClassName}
              size={buttonSize}
              type={buttonType}
              buttonRef={buttonRef}
            >
              {loading ? <Spinner className={spinnerClass} /> : buttonContent || t('common:upload')}
            </Button>
          </>
        )}
      </Field>
    </FieldWrapper>
  );
};

CloudinaryFileButtonInput.defaultProps = defaultProps;

export default CloudinaryFileButtonInput;
