import { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import api from '@shared/api';
import routes from '@root/routes';
import { debounce } from 'lodash';
import actions from '@store/actions/navigation';
import useMarket from '@root/shared/useMarket';
import AsyncFilterDropdown from '../../FilterDropdown/AsyncFilterDropdown';
import { OptionType } from '../../FilterDropdown/types';
import useListingSearchCriteria from '../../utils/useListingSearchCriteria';
import { useFilterOptions } from '../../utils/FilterOptions/FilterOptionsProvider';

interface ReadonlySubmarketResults {
  readonly name: string;
  readonly slug: string;
}

const SUBMARKET_RESULT_LIMIT = 50;
const DEBOUNCE_DURATION = 750;

const SubmarketFilter = () => {
  const { t } = useTranslation('filters');
  const criteria = useListingSearchCriteria();
  const { isOnNonPublicMarket } = useMarket();

  const [state, dispatch] = useFilterOptions();
  const [defaultOptions, setDefaultOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dropdownFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const dispatchAnalytics = useDispatch();

  const loadOptions = (inputValue: string, callback: Function) => {
    api
      .fetch(
        routes.api.marketSubmarkets({
          marketSlug: criteria.marketSlug,
          query: inputValue,
          limit: SUBMARKET_RESULT_LIMIT,
        }),
      )
      .then(async response => {
        const rawSearchResults: ReadonlySubmarketResults[] = await response.json();
        dispatch({
          type: 'ADD_SUBMARKET_OPTIONS',
          payload: rawSearchResults,
        });
        callback(
          rawSearchResults.map(
            (submarket): OptionType => ({
              label: submarket.name,
              value: submarket.slug,
            }),
          ),
        );
      });
  };

  const debouncedLoadOptions = debounce(loadOptions, DEBOUNCE_DURATION);

  const submarkets = criteria?.currentFilters?.submarkets || [];

  const currentFilterOptions =
    submarkets
      .map(submarket => {
        return { label: state.submarkets[submarket]?.name, value: submarket };
      })
      .sort((a, b) => (a.label || '').localeCompare(b.label)) || [];

  const handleChange = currentValues => {
    const isAdding = criteria.submarketSlugs().length < currentValues.length;
    const currentSubmarketSlugs: string[] = currentValues?.map(option => option.value) || [];
    const submarketSlug = isAdding
      ? currentSubmarketSlugs.find(slug => !criteria.submarketSlugs().includes(slug))
      : criteria.submarketSlugs().find(slug => !currentSubmarketSlugs.includes(slug));

    criteria.addSubmarkets(currentSubmarketSlugs);
    criteria.pushToHistory();

    if (flags['search-analytics-refactor']) {
      dropdownFilterInteraction({
        filterType: 'submarkets',
        isAdding,
        value: submarketSlug!,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatchAnalytics(actions.searchLocationAdded(criteria.currentFilters.submarkets?.join(',')));
    }
  };

  useEffect(() => {
    setDefaultOptions([]);
  }, [criteria.marketSlug]);

  const loadDefaultOptions = () => {
    if (defaultOptions.length === 0) {
      setIsLoading(true);
      loadOptions('', response => {
        setDefaultOptions(response);
        setIsLoading(false);
      });
    }
  };

  return (
    <div className="u-p-top-2x" data-testid="submarketsFilter">
      <AsyncFilterDropdown
        loadOptions={debouncedLoadOptions}
        onFocus={() => loadDefaultOptions()}
        defaultOptions={defaultOptions}
        name="submarket filter"
        isLoading={isLoading}
        placeholder={t('submarket.placeholder')}
        onChange={handleChange}
        value={currentFilterOptions}
        disabled={isOnNonPublicMarket}
      />
    </div>
  );
};

export default SubmarketFilter;
