import React, { useRef, useState } from 'react';
import useMarket from '@root/shared/useMarket';

import cn from 'classnames';
import { isNull, uniqueId } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RadioInput } from '@components/shared';
import {
  ListingSearchCriteria,
  useListingSearchCriteria,
} from '@components/layouts/Truva/ListingSearch/utils';
import actions from '@store/actions/listingSearchPage';
import { CeilingHeight, CeilingHeightMeasurementType } from '@root/types/Listing';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import ImperialCeilingHeightInput from './ImperialCeilingHeightInput';
import MetricCeilingHeightFilter from './MetricCeilingHeightInput';
import s from '../Filters.module.less';

const defaultCountryCode = 'US';

const validCeilingTypes = {
  finished: 'finished',
  slabToSlab: 'slabToSlab',
};

const buildAnalyticStringValue = ({ type, magnitude }: CeilingHeight): string =>
  magnitude ? `${type}-${magnitude}` : `${type}-unset-height`;

const CeilingHeightFilter = () => {
  const criteria = useListingSearchCriteria({
    onUrlChange: c => {
      setCeilingHeightType(c.currentFilters.ceilingHeightType ?? 'finished');
    },
  });
  const [ceilingHeightType, setCeilingHeightType] = useState<CeilingHeightMeasurementType>(
    criteria.currentFilters.ceilingHeightType ?? 'finished',
  );
  const { t } = useTranslation('filters');
  const { ceilingHeightFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const dispatch = useDispatch();
  const componentUniqueId = useRef(uniqueId('CeilingHeightFilter'));

  const { currentMarket } = useMarket();
  const countryCode = currentMarket?.countryCode || defaultCountryCode;
  const useImperialMeasurements = countryCode === 'US';

  const pushCriteriaUpdates = (
    newCeilingHeightFilter: CeilingHeight,
    criteriaObject: ListingSearchCriteria,
  ) => {
    criteriaObject.add('ceilingHeightType', newCeilingHeightFilter.type);
    if (flags['search-analytics-refactor']) {
      ceilingHeightFilterInteraction({
        value: newCeilingHeightFilter.magnitude,
        currentFilters: criteriaObject.toAnalyticsProperties(),
        units: useImperialMeasurements ? 'imperial' : 'metric',
        filterType: newCeilingHeightFilter.type,
      });
    } else {
      dispatch(
        actions.ceilingHeightFilterChanged(buildAnalyticStringValue(newCeilingHeightFilter)),
      );
    }
    criteriaObject.pushToHistory();
  };

  const handleCeilingHeightTypeChange = (ceilingHeightFilterType: CeilingHeightMeasurementType) => {
    setCeilingHeightType(ceilingHeightFilterType);

    if (criteria.currentFilters.ceilingHeightMagnitude) {
      const newCeilingHeight = {
        type: ceilingHeightFilterType,
        magnitude: Number(criteria.currentFilters.ceilingHeightMagnitude),
      };
      pushCriteriaUpdates(newCeilingHeight, criteria);
    }
  };

  return (
    <div>
      <ul className={s.ceilingHeightList}>
        <li className={cn()}>
          <RadioInput
            name={`${componentUniqueId.current}-ceiling-height-finished`}
            value={validCeilingTypes.finished}
            onChange={() =>
              handleCeilingHeightTypeChange(
                validCeilingTypes.finished as CeilingHeightMeasurementType,
              )
            }
            labelText={t(`ceilingHeight.${validCeilingTypes.finished}`)}
            checked={isNull(ceilingHeightType) || ceilingHeightType === validCeilingTypes.finished}
            id={`${componentUniqueId.current}-ceiling-height-finished`}
          />
        </li>
        <li className={cn()}>
          <RadioInput
            name={`${componentUniqueId.current}-ceiling-height-slab-to-slab`}
            value={validCeilingTypes.slabToSlab}
            onChange={() =>
              handleCeilingHeightTypeChange(
                validCeilingTypes.slabToSlab as CeilingHeightMeasurementType,
              )
            }
            labelText={t(`ceilingHeight.${validCeilingTypes.slabToSlab}`)}
            checked={ceilingHeightType === validCeilingTypes.slabToSlab}
            id={`${componentUniqueId.current}-ceiling-height-slab-to-slab`}
          />
        </li>
      </ul>
      {useImperialMeasurements ? (
        <ImperialCeilingHeightInput ceilingHeightType={ceilingHeightType} />
      ) : (
        <MetricCeilingHeightFilter ceilingHeightType={ceilingHeightType} />
      )}
    </div>
  );
};
export default React.memo(CeilingHeightFilter);
