import { useRef } from 'react';
import { Form, Formik } from 'formik';
import { AdminBuilding } from '@root/types';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { SubmitButton } from '@components/shared/forms';
import css from './styles.module.less';
import { amenitySetValidationSchema } from './validationSchema';
import BuildingDataCollectionList from './BuildingDataCollectionList';

type Props = {
  building: AdminBuilding;
  updateBuilding: Function;
};

const DataCollectionBuildingForm = ({ building, updateBuilding }: Props) => {
  const { t } = useTranslation('admin');

  const initialValues = { ...building, amenitySet: building.amenitySet };

  const fiberProviders = useRef(
    initialValues.fiberProviders.map(nt => ({
      label: nt.name,
      value: nt.id,
    })),
  );

  return (
    <Formik
      enableReinitialize
      initialValues={JSON.parse(JSON.stringify(initialValues, (k, v) => (v === null ? '' : v)))}
      onSubmit={async (values, actions) => {
        const params = JSON.parse(JSON.stringify(values, (k, v) => (v === '' ? null : v)));

        await updateBuilding(params);
        actions.setSubmitting(false);
      }}
      validationSchema={amenitySetValidationSchema(t)}
    >
      {({ dirty, isValid, values }) => (
        <Form className={css.form}>
          <FormThemeContextProvider theme="vertical">
            <section className={css.amenities}>
              <h2>{t('building.dataCollection.title')}</h2>
              <BuildingDataCollectionList
                entranceCount={values.amenitySet.entranceCount}
                passengerElevatorPosition={values.amenitySet.passengerElevatorPosition}
                landmarkBuilding={values.amenitySet.landmarkBuilding}
                mobileConnectivityImprovements={values.amenitySet.mobileConnectivityImprovements}
                fiberProviders={fiberProviders.current}
                constructionType={values.amenitySet.constructionType}
                thirteenthFloor={values.amenitySet.thirteenthFloor}
                architecturalClassification={values.amenitySet.architecturalClassification}
                energyStarRated={values.amenitySet.energyStarRated}
                flexSpaceThirdParty={values.amenitySet.flexSpaceThirdParty}
                flexSpaceOwnerOperated={values.amenitySet.flexSpaceOwnerOperated}
                hotelAttached={values.amenitySet.hotelAttached}
                hvacFiltration={values.amenitySet.hvacFiltration}
                lobbyAttendantAfterHours={values.amenitySet.lobbyAttendantAfterHours}
                lobbyAttendantBusinessHours={values.amenitySet.lobbyAttendantBusinessHours}
                parking={values.amenitySet.parking}
                parkingRatio={values.amenitySet.parkingRatio}
                passengerElevatorType={values.amenitySet.passengerElevatorType}
                rentableBuildingAreaOffice={values.amenitySet.rentableBuildingAreaOffice}
                rentableBuildingAreaOther={values.amenitySet.rentableBuildingAreaOther}
                rentableBuildingAreaRetail={values.amenitySet.rentableBuildingAreaRetail}
                restaurant={values.amenitySet.restaurant}
                storyClassification={values.amenitySet.storyClassification}
                walkScore={values.amenitySet.walkScore}
                wellScore={values.amenitySet.wellScore}
              />
            </section>
            {dirty ? (
              <div className={css.submitFooter}>
                <SubmitButton disabled={!isValid}>{t('common:saveChanges')}</SubmitButton>
              </div>
            ) : null}
            <Prompt when={dirty} message={t('common:prompt.unsavedChanges')} />
          </FormThemeContextProvider>
        </Form>
      )}
    </Formik>
  );
};

export default DataCollectionBuildingForm;
