import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import { AgentContact } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Card from '../Card';
import DeprecatedContact from './DeprecatedContact';
import Contact from './Contact';
import s from './ContactCard.module.less';

type ContactProps = {
  contacts: AgentContact[];
  title: string;
  emailSubjectName?: string;
  sourcePage: typeof PARAMETERS.listingPage | typeof PARAMETERS.buildingPage;
};
const ContactCard = ({ title, contacts, emailSubjectName, sourcePage }: ContactProps) => {
  const { contactInteraction } = useAnalytics();
  const flags = useFlags();
  const handleEmailContact = (contact: AgentContact, contactRanking: number) => {
    contactInteraction({
      action: PARAMETERS.contactSelectContact,
      actionType: 'CONTACT_SELECT_CONTACT',
      sourceContent: PARAMETERS.contactSection,
      sourcePage,
      otherAttributes: {
        contactRanking,
        contactInfo: {
          name: contact.name,
          role: contact.role,
          company: contact.company,
        },
        contactAvatar: contact.photoUrl ? 'yes' : 'no',
      },
    });
  };
  const displayRebrandedContacts =
    sourcePage === PARAMETERS.buildingPage ||
    (sourcePage === PARAMETERS.listingPage && flags['outreach.branding-general']);

  return contacts.length ? (
    <Card
      className={s.contactCard}
      titleClassName={s.title}
      contentClassName={s.content}
      title={title}
    >
      {contacts.map((contact, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={s.contactContainer} key={i}>
          {displayRebrandedContacts ? (
            <Contact
              contact={contact}
              emailSubjectName={emailSubjectName}
              onContactEmailClick={() => handleEmailContact(contact, i + 1)}
              compact={false}
            />
          ) : (
            <DeprecatedContact
              contact={contact}
              emailSubjectName={emailSubjectName}
              compact={false}
              onContactEmailClick={() => handleEmailContact(contact, i + 1)}
            />
          )}
        </div>
      ))}
    </Card>
  ) : null;
};

export default ContactCard;
