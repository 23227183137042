import actions from '..';
import savedActions from '.';

const loadSavedBuildingOwnersPage = () => async dispatch => {
  await dispatch(actions.setSavedBuildingOwnersPage());
  dispatch(savedActions.fetchSavedBuildingOwners());
  dispatch(savedActions.loadedSavedBuildingOwnersPage());
};

export default loadSavedBuildingOwnersPage;
