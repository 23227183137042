import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@store/actions/ui';
import { Breadcrumbs, StoreState } from '@root/types';

/*
THIS IS ONLY IN USE FOR ADMIN
DO NOT USE IN ANY OTHER PART OF THE APP
*/

export const useBreadcrumbs = () => {
  const crumbs = useSelector((state: StoreState) => state.ui.breadcrumbs);
  const dispatch = useDispatch();
  const setBreadcrumbs = useCallback(
    (breadcrumbs: Breadcrumbs) => {
      dispatch(actions.addBreadcrumbs(breadcrumbs ?? []));
    },
    [dispatch],
  );

  useEffect(
    () => () => {
      dispatch(actions.removeBreadcrumbs());
    },
    [dispatch],
  );

  return {
    crumbs,
    setBreadcrumbs,
  };
};

const withBreadcrumbs = Component => props => {
  const { crumbs, setBreadcrumbs } = useBreadcrumbs();
  return <Component crumbs={crumbs} setBreadcrumbs={setBreadcrumbs} {...props} />;
};

export default withBreadcrumbs;
