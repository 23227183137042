import * as React from 'react';
import { useBreakpoint, breakpoints } from '@shared/useBreakpoints';
import { Breakpoint } from '@root/types';
import cn from 'classnames';
import s from './OnlyIn.module.less';

type OnlyInProps = {
  children: React.ReactNode;
  classNameWhenNotPreventingRender?: string;
  givenClassName?: string;
  preventRendering?: boolean;
  displayedBreakpoints: Breakpoint[];
};

const OnlyIn = ({
  children,
  classNameWhenNotPreventingRender,
  givenClassName,
  displayedBreakpoints,
  preventRendering = true,
}: OnlyInProps) => {
  const { breakpoint } = useBreakpoint();

  if (preventRendering) {
    if (!displayedBreakpoints.includes(breakpoint)) return null;
    return givenClassName ? <div className={givenClassName}>{children}</div> : <>{children}</>;
  }

  return <div className={cn(classNameWhenNotPreventingRender, givenClassName)}>{children}</div>;
};

type Props = {
  children: React.ReactNode;
  preventRendering?: boolean | undefined;
  className?: string;
};

export const OnlyInDesktop = ({ children, preventRendering, className }: Props) => (
  <OnlyIn
    displayedBreakpoints={[breakpoints.DESKTOP]}
    classNameWhenNotPreventingRender={s.desktopOnly}
    givenClassName={className}
    preventRendering={preventRendering}
  >
    {children}
  </OnlyIn>
);

export const OnlyInDesktopTablet = ({ children, preventRendering, className }: Props) => (
  <OnlyIn
    displayedBreakpoints={[breakpoints.DESKTOP, breakpoints.TABLET]}
    classNameWhenNotPreventingRender={s.desktopAndTabletOnly}
    givenClassName={className}
    preventRendering={preventRendering}
  >
    {children}
  </OnlyIn>
);

export const OnlyInMobile = ({ children, preventRendering, className }: Props) => (
  <OnlyIn
    displayedBreakpoints={[breakpoints.MOBILE]}
    classNameWhenNotPreventingRender={s.mobileOnly}
    givenClassName={className}
    preventRendering={preventRendering}
  >
    {children}
  </OnlyIn>
);

export const OnlyInTabletMobile = ({ children, preventRendering, className }: Props) => (
  <OnlyIn
    displayedBreakpoints={[breakpoints.TABLET, breakpoints.MOBILE]}
    classNameWhenNotPreventingRender={s.tabletMobileOnly}
    givenClassName={className}
    preventRendering={preventRendering}
  >
    {children}
  </OnlyIn>
);

export const OnlyInTablet = ({ children, preventRendering, className }: Props) => (
  <OnlyIn
    displayedBreakpoints={[breakpoints.TABLET]}
    classNameWhenNotPreventingRender={s.tabletOnly}
    givenClassName={className}
    preventRendering={preventRendering}
  >
    {children}
  </OnlyIn>
);

export default {
  OnlyInTablet,
  OnlyInTabletMobile,
  OnlyInMobile,
  OnlyInDesktopTablet,
  OnlyInDesktop,
};
