import api from '@shared/api';
import routes from '@root/routes';
import { SavedSearch } from '@root/types';

const requestSavedSearches = {
  type: 'REQUEST_SAVED_SEARCHES',
  payload: null,
} as const;
type RequestSavedSearchesAction = typeof requestSavedSearches;

type ReceiveSavedSearchesAction = {
  type: 'RECEIVE_SAVED_SEARCHES';
  payload: SavedSearch[];
};

const receiveSavedSearches = (savedSearches: SavedSearch[]): ReceiveSavedSearchesAction => ({
  type: 'RECEIVE_SAVED_SEARCHES',
  payload: savedSearches,
});

const fetchSavedSearches = () => async dispatch => {
  dispatch(requestSavedSearches);
  const searches = await api.fetch(routes.api.savedSearches).then(response => response.json());
  dispatch(receiveSavedSearches(searches));
};

export default fetchSavedSearches;

export type Actions = ReceiveSavedSearchesAction | RequestSavedSearchesAction;
