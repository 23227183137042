import { ComponentProps } from 'react';
import cn from 'classnames';
import Select from 'react-select';
import { isUndefined } from 'lodash';
import StandardComponents from '../StandardComponents';
import { OptionType, SelectValue } from '../types';
import s from '../Dropdowns.module.less';

export const rawSelectStyles = isDisabled => {
  return {
    menu: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: provided => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      borderRadius: '4px',
    }),
    control: provided => ({
      ...provided,
      ...(isDisabled && {
        pointerEvents: 'all',
        cursor: 'not-allowed',
        borderColor: '#CCCCCC !important',
        backgroundColor: '#F7F7F7 !important',
      }),
    }),
    dropdownIndicator: provided => ({
      ...provided,
      ...(isDisabled && {
        color: '#A6A6A6 !important',
      }),
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      ...(state.data.isFixed && {
        display: 'none',
      }),
    }),
    multiValue: (base, state) => ({
      ...base,
      ...(state.data.isFixed && {
        'div:first-of-type': {
          paddingRight: '6px',
        },
      }),
    }),
  };
};

type ValueProps =
  | {
      isMulti: true;
      onChange: (val: SelectValue[]) => void;
      value: SelectValue[];
    }
  | {
      isMulti?: false;
      onChange: (val: SelectValue) => void;
      value?: SelectValue;
    };
export type Props = {
  name: string;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  options: OptionType[];
  onBlur?: () => void;
  components?: any;
  className?: string;
  isDisabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  menuIsOpen?: boolean | undefined;
  styles?: ComponentProps<typeof Select>['styles'];
} & ValueProps;

const RawSelect = ({
  options,
  name,
  placeholder,
  value,
  onChange,
  isClearable,
  isSearchable = true,
  onBlur,
  isDisabled,
  components = {},
  className,
  menuPlacement = 'bottom',
  isMulti,
  menuIsOpen,
  styles,
}: Props) => {
  const getValue = () => {
    // guard clause so that this can be uncontrolled if needed
    if (isUndefined(value)) return value;

    if (isMulti) {
      return (value as SelectValue[]).map(val => options.find(option => option.value === val));
    }

    const foundValue = options.find(option => option.value === value);
    return foundValue || null;
  };

  return (
    <Select
      name={name}
      className={cn(s.select, className)}
      placeholder={placeholder}
      value={getValue()}
      onChange={option => {
        if (isMulti) {
          onChange((option as OptionType[]).map(o => o.value));
        } else {
          const newValue = option ? (option as OptionType).value : null;
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
      options={options}
      isOpen
      isClearable={isClearable}
      isSearchable={isSearchable}
      inputId={name}
      isDisabled={isDisabled}
      components={{
        ...StandardComponents,
        ...components,
      }}
      styles={{ ...rawSelectStyles(isDisabled), ...styles }}
      menuPlacement={menuPlacement}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
    />
  );
};

export default RawSelect;
