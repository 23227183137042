import { CustomIcon } from '@components/shared';
import DatePicker from 'react-datepicker';
import { forwardRef, useState } from 'react';
import { addDays, format, min, startOfToday } from 'date-fns';
import s from './DateRangeDropdown.module.less';

type Props = {
  maxSelectableDays?: number;
  startDate?: Date;
  endDate?: Date;
  onChange?: (newValue: [start: Date, end: Date]) => void;
  calendarPlacement?: string;
};

const DateRangeDropdown = ({
  maxSelectableDays = 13,
  startDate: controlledStartDate,
  endDate: controlledEndDate,
  onChange = () => {},
  calendarPlacement = 'bottom-end',
}: Props) => {
  const today = startOfToday();
  const [startDate, setStartDate] = useState<Date | null>(controlledStartDate || null);
  const [endDate, setEndDate] = useState<Date | null>(controlledEndDate || null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const maxDate =
    startDate && !endDate ? min([today, addDays(startDate, maxSelectableDays)]) : today;

  const onChangeSelection = ([start, end]: [null | Date, null | Date]) => {
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      onChange([start, end]);
    }
  };

  const CustomInput = forwardRef<HTMLDivElement>((props, ref) => (
    <div {...props} ref={ref} className="cursor-pointer select-none pt-[2px]">
      <span className="mr-2 text-black-100 font-body-medium">{`${
        controlledStartDate ? format(controlledStartDate, 'MMM d') : ''
      } - ${controlledEndDate ? format(controlledEndDate, 'MMM d') : ''}`}</span>
      <CustomIcon
        className="text-[14px] [&>svg]:text-black-100"
        type={isDropdownOpen ? 'chevron-up' : 'chevron-down'}
      />
    </div>
  ));

  const RenderCustomHeader = ({ monthDate, decreaseMonth, increaseMonth }) => (
    <div className="">
      <div className="flex w-full justify-between rounded-t-[3px] border-b-[1px] border-solid border-black-055 bg-white px-1.5 py-1 font-body-medium">
        <span className="w-[40%] text-left">{startDate && format(startDate, 'E, MMM d')}</span>
        <CustomIcon
          className="flex w-[20%] items-center justify-center text-[10px] !text-black-055"
          type="back-arrow"
        />
        <span className="w-[40%] text-right">{endDate && format(endDate, 'E, MMM d')}</span>
      </div>
      <div className="flex select-none items-center justify-between pt-[4px]">
        <CustomIcon
          className="cursor-pointer px-[10px] text-[14px] [&>svg]:text-black-055"
          type="chevron-left"
          onClick={decreaseMonth}
        />
        <span className="font-body-medium-emphasis">{format(monthDate, 'MMMM Y')}</span>
        <CustomIcon
          className="cursor-pointer px-[10px] text-[14px] [&>svg]:text-black-055"
          type="chevron-right"
          onClick={increaseMonth}
        />
      </div>
    </div>
  );

  return (
    <div>
      <DatePicker
        popperPlacement={calendarPlacement}
        selected={startDate}
        onChange={onChangeSelection}
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDate}
        calendarClassName={s.datepicker}
        selectsRange
        showPopperArrow={false}
        customInput={<CustomInput />}
        onCalendarClose={() => setIsDropdownOpen(false)}
        onCalendarOpen={() => setIsDropdownOpen(true)}
        renderCustomHeader={RenderCustomHeader}
      />
    </div>
  );
};

export default DateRangeDropdown;
