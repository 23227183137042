import { EVENTS, PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';

const listingPageLoadedAction = {
  type: 'LISTING_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.listingPage,
      },
    },
  },
} as const;
type ListingPageLoadedAction = typeof listingPageLoadedAction;
const listingPageLoaded = () => listingPageLoadedAction;

const lightboxOpenedAction = {
  type: 'LIGHTBOX_OPENED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.openLightbox,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.listingGallery,
      },
    },
  },
} as const;
type LightboxOpenedAction = typeof lightboxOpenedAction;
const lightboxOpened = () => lightboxOpenedAction;

const galleryPaginationClickedAction = {
  type: 'GALLERY_PAGINATION_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.paginate,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.listingGallery,
      },
    },
  },
} as const;
type GalleryPaginationClickedAction = typeof galleryPaginationClickedAction;
const galleryPaginationClicked = () => galleryPaginationClickedAction;

const lightboxThumbnailClickedAction = {
  type: 'LIGHTBOX_THUMBNAIL_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.clickThumbnail,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.lightboxGallery,
      },
    },
  },
} as const;
type LightboxThumbnailClickedAction = typeof lightboxThumbnailClickedAction;
const lightboxThumbnailClicked = () => lightboxThumbnailClickedAction;

const lightboxFloorPlanClickedAction = {
  type: 'LIGHTBOX_FLOOR_PLAN_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.viewFloorPlan,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.lightboxGallery,
      },
    },
  },
} as const;
type LightboxFloorPlanClickedAction = typeof lightboxFloorPlanClickedAction;
const lightboxFloorPlanClicked = () => lightboxFloorPlanClickedAction;

const lightboxPaginationClickedAction = {
  type: 'LIGHTBOX_PAGINATION_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.paginate,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.lightboxGallery,
      },
    },
  },
} as const;
type LightboxPaginationClickedAction = typeof lightboxPaginationClickedAction;
const lightboxPaginationClicked = () => lightboxPaginationClickedAction;

const leaseTermChangedAction = {
  type: 'LEASE_TERM_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.pricingInteraction,
        action: PARAMETERS.selectLeaseTerm,
      },
    },
  },
} as const;
type LeaseTermChangedAction = typeof leaseTermChangedAction;
const leaseTermChanged = () => leaseTermChangedAction;

const leaseTermDropdownOpenedAction = {
  type: 'LEASE_TERM_DROPDOWN_OPENED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.pricingInteraction,
        action: PARAMETERS.openDropdown,
      },
    },
  },
} as const;
type LeaseTermDropdownOpenedAction = typeof leaseTermDropdownOpenedAction;
const leaseTermDropdownOpened = () => leaseTermDropdownOpenedAction;

const viewBuildingClickedAction = {
  type: 'VIEW_BUILDING_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.buildingInfo,
        sourcePage: PARAMETERS.listingPage,
        destination: PARAMETERS.buildingPage,
      },
    },
  },
} as const;
type ViewBuildingClickedAction = typeof viewBuildingClickedAction;
const viewBuildingClicked = () => viewBuildingClickedAction;

const buildingVideoPlayedAction = {
  type: 'BUILDING_VIDEO_PLAYED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.videoInteraction,
        sourceContent: PARAMETERS.buildingVideo,
        sourcePage: PARAMETERS.listingPage,
        action: PARAMETERS.playVideo,
      },
    },
  },
} as const;
type BuildingVideoPlayedAction = typeof buildingVideoPlayedAction;
const buildingVideoPlayed = () => buildingVideoPlayedAction;

const contactClickedAction = {
  type: 'CONTACT_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.contactInteraction,
        sourcePage: PARAMETERS.listingPage,
        action: PARAMETERS.selectEmail,
      },
    },
  },
} as const;
type ContactClickedAction = typeof contactClickedAction;
const contactClicked = () => contactClickedAction;

export type Thumbnail = {
  clickThumbnail: 'click thumbnail';
  open3DTour: 'open 3d tour';
  view3DTour: 'view 3D tour';
  viewFloorPlan: 'view floor plan';
  viewMap: 'view map';
  viewVideo: 'view video';
};

type GalleryThumbnailClickedAction = {
  type: 'GALLERY_THUMBNAIL_CLICKED';
  payload: {
    meta: {
      analytics: {
        action: Thumbnail[keyof Thumbnail];
        eventType: 'track';
        event: 'Gallery Interaction';
        sourcePage: 'listing page';
        sourceContent: 'listing gallery';
      };
    };
  };
};

const galleryThumbnailClicked = (
  thumbnailKey: keyof Thumbnail = 'clickThumbnail',
  sourcePage?,
  sourceContent?,
): GalleryThumbnailClickedAction => {
  const thumbnailTypeMap = {
    clickThumbnail: PARAMETERS.clickThumbnail,
    open3DTour: PARAMETERS.open3DTour,
    view3DTour: PARAMETERS.view3DTour,
    viewFloorPlan: PARAMETERS.viewFloorPlan,
    viewMap: PARAMETERS.viewMap,
    viewVideo: PARAMETERS.viewVideo,
  };

  return {
    type: 'GALLERY_THUMBNAIL_CLICKED',
    payload: {
      meta: {
        analytics: {
          action: thumbnailTypeMap[thumbnailKey],
          eventType: EVENT_TYPES.track,
          event: EVENTS.galleryInteraction,
          sourcePage: sourcePage || PARAMETERS.listingPage,
          sourceContent: sourceContent || PARAMETERS.listingGallery,
        },
      },
    },
  };
};

//
// SHARE ACTIONS
//
const openShareModalAction = {
  type: 'OPEN_SHARE_MODAL',
  payload: {
    meta: {
      analytics: {
        event: EVENTS.shareInteraction,
        eventType: EVENT_TYPES.track,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.shareForm,
        action: PARAMETERS.openModal,
      },
    },
  },
} as const;
type OpenShareModalAction = typeof openShareModalAction;
const openShareModal = () => openShareModalAction;

const dismissShareAction = {
  type: 'DISMISS_SHARE',
  payload: {
    meta: {
      analytics: {
        event: EVENTS.shareInteraction,
        eventType: EVENT_TYPES.track,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.shareForm,
        action: PARAMETERS.closeModal,
      },
    },
  },
} as const;
type DismissShareAction = typeof dismissShareAction;
const dismissShare = () => dismissShareAction;

const successfulShareAction = {
  type: 'SHARE_SUCCESSFUL',
  payload: {
    meta: {
      analytics: {
        event: EVENTS.shareInteraction,
        eventType: EVENT_TYPES.track,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.shareForm,
        action: PARAMETERS.shareSent,
      },
    },
  },
} as const;
type SuccessfulShareAction = typeof successfulShareAction;
const successfulShare = () => successfulShareAction;

const successfulCustomShareAction = {
  type: 'SHARE_SUCCESSFUL',
  payload: {
    meta: {
      analytics: {
        event: EVENTS.customShareInteraction,
        eventType: EVENT_TYPES.track,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.shareForm,
        action: PARAMETERS.customShareSent,
      },
    },
  },
} as const;
type SuccessfulCustomShareAction = typeof successfulCustomShareAction;
const successfulCustomShare = () => successfulCustomShareAction;

const shareErrorAction = {
  type: 'SHARE_ERRORED',
  payload: {
    meta: {
      analytics: {
        event: EVENTS.shareInteraction,
        eventType: EVENT_TYPES.track,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.shareForm,
        action: PARAMETERS.shareError,
      },
    },
  },
} as const;
type ShareErrorAction = typeof shareErrorAction;
const shareError = () => shareErrorAction;

type ImageDownloadClickedAction = {
  type: 'LISTING_GALLERY_IMAGE_CLICKED';
  payload: {
    meta: {
      analytics: {
        event: 'Gallery Interaction';
        eventType: 'track';
        sourcePage: 'listing page' | 'building page' | 'brand page';
        sourceContent: 'listing gallery' | 'lightbox gallery';
        action: 'download' | 'bulk download';
      };
    };
  };
};
const imageDownloadClicked = (
  sourcePage: 'listing page' | 'building page' | 'brand page',
  sourceContent: 'listing gallery' | 'lightbox gallery',
  action: 'download' | 'bulk download' = 'download',
): ImageDownloadClickedAction => ({
  type: 'LISTING_GALLERY_IMAGE_CLICKED',
  payload: {
    meta: {
      analytics: {
        event: EVENTS.galleryInteraction,
        eventType: EVENT_TYPES.track,
        sourcePage,
        sourceContent,
        action,
      },
    },
  },
});

export type Action =
  | ListingPageLoadedAction
  | LightboxOpenedAction
  | GalleryPaginationClickedAction
  | GalleryThumbnailClickedAction
  | LightboxThumbnailClickedAction
  | LightboxFloorPlanClickedAction
  | LightboxPaginationClickedAction
  | LeaseTermChangedAction
  | LeaseTermDropdownOpenedAction
  | ViewBuildingClickedAction
  | BuildingVideoPlayedAction
  | ContactClickedAction
  | OpenShareModalAction
  | DismissShareAction
  | SuccessfulShareAction
  | SuccessfulCustomShareAction
  | ShareErrorAction
  | ImageDownloadClickedAction;

export default {
  listingPageLoaded,
  lightboxOpened,
  galleryPaginationClicked,
  galleryThumbnailClicked,
  lightboxThumbnailClicked,
  lightboxFloorPlanClicked,
  lightboxPaginationClicked,
  leaseTermChanged,
  leaseTermDropdownOpened,
  viewBuildingClicked,
  buildingVideoPlayed,
  contactClicked,
  openShareModal,
  dismissShare,
  successfulShare,
  successfulCustomShare,
  shareError,
  imageDownloadClicked,
};
