import { MultipathImage } from '@root/types';

export { default as NewPage } from './NewPage';
export { default as EditPage } from './EditPage';
export { default as Drawer } from './Drawer';
export { default as MediaFields } from './MediaFields';
export { default as Layout } from './Layout';
export { default as ListingFields } from './ListingFields';
export { default as LocationFields } from './LocationFields';
export { ImagesContext, ImagesContextProvider } from './ImagesContext';
export { default as FormGb } from './FormGb';
export type { FormValuesGb } from './FormGb';
export { default as FormUs } from './FormUs';
export type { FormValuesUs } from './FormUs';
export { default as Form, defaultValidationSchema } from './Form';
export type { FormValues } from './Form';

export type ImageForUpload = {
  name: string;
  type: string;
  image: Omit<MultipathImage, 'type'>;
  id: string;
  isUploaded: boolean;
  isFloorPlan?: boolean;
};

export const MAX_IMAGE_COUNT = 10;
