import { Card } from '@components/shared';
import { useTranslation } from 'react-i18next';
import { AgentContact } from '@root/types';
import { isEmpty } from 'lodash';
import s from './DeprecatedListingAgentsContactCard.module.less';
import Agent from './DeprecatedAgent';

type Props = {
  listingAgents: AgentContact[];
  emailSubjectName: string;
  title: string;
  trackContactClick: () => void;
};
const ListingAgentsContactCard = ({
  listingAgents,
  emailSubjectName,
  title,
  trackContactClick,
}: Props) => {
  const { t } = useTranslation('listingAgentsContactCard');

  const emailSubject = t('emailSubject', { emailSubjectName });

  if (isEmpty(listingAgents)) return null;
  return (
    <Card className={s.listingCard} title={title}>
      <ul className={s.list}>
        {listingAgents.map((agent, index) => (
          <Agent
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...agent}
            subject={emailSubject}
            trackContactClick={trackContactClick}
          />
        ))}
      </ul>
    </Card>
  );
};

export default ListingAgentsContactCard;
