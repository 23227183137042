import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import {
  StoreState,
  SearchListing,
  MarketSlug,
  Market,
  Submarket,
  SearchCriteriaKeys,
} from '@root/types';
import { getSearchListingIds } from '@store/selectors';
import listingSearchPageActions from '@store/actions/listingSearchPage';
import actions from '@store/actions';
import cn from 'classnames';
import {
  OnlyInDesktopTablet,
  ConfirmationModal,
  OnlyInMobile,
  ErrorBoundary,
} from '@components/shared';
import { useBreakpoint } from '@shared/useBreakpoints';
import useMarket from '@root/shared/useMarket';
import useEnv from '@root/shared/useEnv';
import savedActions from '@root/store/actions/saved';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import SearchCriteriaBar from './SearchCriteriaBar';
import MobileSearchCriteriaBar from './SearchCriteriaBar/MobileSearchCriteriaBar';
import Layout from '../Layout';
import { useListingSearchCriteria, getSearchCriteriaPills } from './utils';
import s from './ListingSearch.module.less';
import Cards from './Cards';
import ListingSearchFiltersSidebar from './Filters/ListingSearchFiltersSidebar';
import Map from './Map';
import PaginationSection from './PaginationSection';
import MarketSelector from './MarketSelector';
import { useFilterOptions } from './utils/FilterOptions/FilterOptionsProvider';
import NotFound from '../404/NotFound';
import useMapMovementCriteria from './useMapMovementCriteria';
import NamedSavedSearch from './NamedSaveSearch';
import { SearchCriteriaWithMultipleOptionsKeys } from './utils/Criteria/ListingSearchCriteria';
import useFilterAnalytics from './utils/useFilterAnalytics';
import ListingSearchHead from './ListingSearchHead';

const mapState = (state: StoreState) => ({
  ids: getSearchListingIds(state),
  buildingSearchResults: state.buildings.searchResults,
  currentUser: state.currentUser,
  userMovedMap: state.map.moved,
  currentPage: state.listings.pagination?.currentPage,
  drawnPolygons: state.map.drawnPolygons,
  activeSavedSearchId: state.savedSearches.activeId,
});

const mapDispatch = {
  loadSearchResults: ({
    pageNumber,
    apiBuildingsUrl,
    apiListingsUrl,
  }: {
    pageNumber: number;
    apiBuildingsUrl: string;
    apiListingsUrl: string;
  }) =>
    listingSearchPageActions.loadSearchResults({
      pageNumber,
      apiBuildingsUrl,
      apiListingsUrl,
    }),
  setActiveSavedSearchId: savedActions.setActiveSavedSearchId,
};

const DEFAULT_MARKET_SLUG = 'new_york_city';

const connector = connect(mapState, mapDispatch);
export type ReduxProps = ConnectedProps<typeof connector>;

export const RawListingSearch = ({
  buildingSearchResults,
  loadSearchResults,
  currentUser,
  userMovedMap,
  currentPage,
  setActiveSavedSearchId,
  activeSavedSearchId,
  drawnPolygons,
}: ReduxProps) => {
  const dispatch = useDispatch();
  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(false);
  const toggleSidebar = useCallback(
    () => setSidebarIsCollapsed(!sidebarIsCollapsed),
    [sidebarIsCollapsed],
  );

  const [hoveredListing, setHoveredListing] = useState<SearchListing | null>(null);
  const [state, dispatchFilter, fetchInitialFilterOptionsFromCriteria] = useFilterOptions();
  const [should404, setShould404] = useState<boolean>(false);
  const { currentMarket, isOnCustomMarket } = useMarket();
  const {
    confirmMapMovement,
    exitMapMovementConfirmation,
    moveMap,
    onMapReset,
    onRedoSearch,
    onDrawComplete,
    invalidSearchModalOpen,
    closeInvalidSearchModal,
  } = useMapMovementCriteria();
  const [mostRecentNonCustomMarket, setMostRecentNonCustomMarket] = useState<Market | undefined>(
    currentMarket,
  );
  const { locale } = useEnv();
  const startingMarketSlug = currentMarket?.id ?? currentUser?.marketSlug ?? DEFAULT_MARKET_SLUG;
  const previousMarket = mostRecentNonCustomMarket?.id || currentUser?.marketSlug;
  const { t } = useTranslation('listingSearch');
  const { isMobile } = useBreakpoint();
  const { pillRemovalInteraction } = useFilterInteraction();

  const criteria = useListingSearchCriteria({
    onUrlChange: c => {
      loadSearchResults({
        pageNumber: c.page(),
        apiBuildingsUrl: c.toApiBuildingsUrl(),
        apiListingsUrl: c.toApiListingsUrl(),
      });
      if (c.currentFilters.polygons) {
        dispatch(actions.setDrawnPolygons(c.parsedPolygons()));
      } else if (drawnPolygons.length) {
        dispatch(actions.setDrawnPolygons([]));
      }

      // Switch to the previous saved search when hitting the back button
      if (c.savedSearchId !== activeSavedSearchId) {
        setActiveSavedSearchId(c.savedSearchId || null);
      }

      // Clear the saved search if the user got rid of all filters manually
      // or if previous search had no filters and the user hit the back button
      if ((c.savedSearchId || activeSavedSearchId) && !c.hasNonDefaultFilters()) {
        setActiveSavedSearchId(null);
        c.setSavedSearchId(null);
        c.pushToHistory({ replace: true });
      }
    },
  });

  /* We don't need to persist filtersList in state - we can(should) calculate it
   everytime the criteria/url updates and pass to child components as part of a
   render cycle instead of in a useEffect after the criteria has updated. */
  const filtersList = getSearchCriteriaPills(criteria.currentFilters, state, {
    currentMarket,
    currentUser,
    locale,
  });

  useEffect(() => {
    /* Some of the filters like submarkets, buildings, and landlords don't have translations for
       their values. On page load the url/criteria only has access to the slugs/ids not the names.
       So this useEffect will query the backend on page load for all the initial listing search
       criteria filters so that the app has access to the filters respective names/labels. */
    fetchInitialFilterOptionsFromCriteria(dispatchFilter, criteria);

    return function cleanup() {
      setActiveSavedSearchId(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Fire filter analytic events on page load if a saved search is selected */
  const { fireAnalyticEventsForCurrentFilters } = useFilterAnalytics();
  useEffect(() => {
    if (criteria.savedSearchId) fireAnalyticEventsForCurrentFilters(criteria);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const container = document.getElementById('listing-cards-container');
    if (container?.scrollTo) {
      container.scrollTo(0, 0);
    }
  }, [currentPage]);

  const selectedSubmarkets: Submarket[] =
    criteria.currentFilters.submarkets
      ?.map(submarketSlug => state.submarkets[submarketSlug])
      .filter(submarket => submarket != null) || [];

  const removeAllFilters = () => {
    criteria.removeAll();
    criteria.changeMarket(previousMarket as MarketSlug);
    setActiveSavedSearchId(null);
    criteria.pushToHistory();
  };

  const removeFilters = (filterKeys: string, filter: string, value: string | undefined) => {
    const filters = filterKeys.split(',');
    filters.forEach(filterKey => {
      const [f, v] = filterKey.split('|');
      if (value) {
        criteria.removeValue(f as SearchCriteriaWithMultipleOptionsKeys, v);
      } else {
        criteria.remove(f as SearchCriteriaKeys);
        if (f === 'polygons') {
          criteria.removePolygons(previousMarket as MarketSlug);
        }
        if (f === 'map') {
          criteria.removeMap(previousMarket as MarketSlug);
        }
      }
    });

    const containsExcludeNegotiable = filters.includes('excludeNegotiable');
    const filterType = containsExcludeNegotiable ? { filterType: filters[0] } : {};

    pillRemovalInteraction({
      filter,
      ...filterType,
      currentFilters: criteria.toAnalyticsProperties(),
    });
    criteria.pushToHistory();
  };

  useEffect(() => {
    const isInvalidCriteria = !isOnCustomMarket && currentMarket?.id !== startingMarketSlug;
    if (should404 !== isInvalidCriteria) setShould404(isInvalidCriteria);
    if (currentMarket != null) setMostRecentNonCustomMarket(currentMarket);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarket]);

  if (should404) return <NotFound />;

  const showTopSaveSearchButton = !isMobile && currentUser;

  return (
    <Layout
      key="search-layout"
      activePage="listingSearch"
      data-testid="listing-search-v2"
      showFooter={false}
      containerClassName={s.layout}
      stickyNav
    >
      <ListingSearchHead />
      <section className={s.topSection}>
        <ErrorBoundary>
          <div className={s.topSectionTopRow}>
            <div className={s.listingSearchResultCount}>
              <MarketSelector />
            </div>
            {showTopSaveSearchButton ? (
              <NamedSavedSearch isSaveSearchDisabled={filtersList.length < 1} />
            ) : null}
          </div>
          <div className={s.listingSearchResultCriteria}>
            <OnlyInDesktopTablet>
              <SearchCriteriaBar
                options={filtersList}
                onFilterPillClick={removeFilters}
                removeAll={removeAllFilters}
              />
            </OnlyInDesktopTablet>
            <OnlyInMobile preventRendering>
              <MobileSearchCriteriaBar
                options={filtersList}
                onFilterPillClick={removeFilters}
                removeAll={removeAllFilters}
              />
            </OnlyInMobile>
          </div>
        </ErrorBoundary>
      </section>
      <div className={s.listingSearchResultsContainer}>
        <ErrorBoundary>
          <OnlyInDesktopTablet>
            <div
              className={cn(s.filterSidebarContainer, {
                [s['filterSidebarContainer--collapsed']]: sidebarIsCollapsed,
              })}
              data-testid="filter-sidebar-container"
            >
              <ListingSearchFiltersSidebar
                isCollapsed={sidebarIsCollapsed}
                toggleSidebar={toggleSidebar}
              />
            </div>
          </OnlyInDesktopTablet>
        </ErrorBoundary>
        <div
          className={cn(s.listingResults, {
            [s['listingResults--withExpandedSidebar']]: !sidebarIsCollapsed,
          })}
        >
          <div id="listing-cards-container" className={s.listingCardsContainer}>
            <ul>
              <ErrorBoundary>
                <Cards searchCardHovered={setHoveredListing} />
              </ErrorBoundary>
            </ul>
          </div>
          <div className={s.paginationContainer}>
            <ErrorBoundary>
              <PaginationSection />
            </ErrorBoundary>
          </div>
        </div>
        <div
          className={cn(s.mapContainer, {
            [s['mapContainer--withExpandedSidebar']]: !sidebarIsCollapsed,
          })}
        >
          <ErrorBoundary>
            <ConfirmationModal
              onConfirm={moveMap}
              onClose={exitMapMovementConfirmation}
              isOpen={confirmMapMovement}
              confirmationText={t('marketMoveConfirmationModal.bodyText')}
              confirmButtonText={t('marketMoveConfirmationModal.confirm')}
              cancelButtonText={t('common:cancel')}
              title={t('marketMoveConfirmationModal.title')}
            />
            <ConfirmationModal
              isOpen={invalidSearchModalOpen}
              onClose={closeInvalidSearchModal}
              title={t('invalidSearchModal.title')}
              confirmationText={t('invalidSearchModal.bodyText')}
              confirmButtonText={t('common:ok')}
              onConfirm={closeInvalidSearchModal}
              hideCancelButton
            />
            <Map
              buildingSearchResults={buildingSearchResults}
              customMapCoordinates={criteria.mapCoordinates()}
              selectedSubmarkets={selectedSubmarkets}
              hoveredListing={hoveredListing}
              userMovedMap={userMovedMap}
              marketSlug={(isOnCustomMarket && 'custom') || currentMarket?.id || startingMarketSlug}
              onRedoSearch={onRedoSearch}
              onMapReset={onMapReset}
              onDrawComplete={onDrawComplete}
            ></Map>
          </ErrorBoundary>
        </div>
      </div>
    </Layout>
  );
};

export default connector(RawListingSearch);
