import { getListingCount, getLoadingState } from '@root/store/selectors';
import { StoreState } from '@root/types';
import { connect, ConnectedProps } from 'react-redux';
import savedActions from '@store/actions/saved';
import { useTranslation } from 'react-i18next';
import { useListingSearchCriteria } from '@components/layouts/Truva/ListingSearch/utils';
import useMarket from '@shared/useMarket';
import { Spinner } from '@components/shared';
import { OptionType, GroupedOptionType } from '@components/shared/forms/Dropdowns/types';
import { PARAMETERS } from '@root/tracking/constants';
import MarketToggleSelect from './MarketToggleSelect';
import s from './MarketToggleSelect.module.less';

const mapState = (state: StoreState) => ({
  listingsCount: getListingCount(state),
  isLoading: getLoadingState(state, 'listingSearch'),
});

const mapDispatch = {
  clearActiveSavedSearch: () => savedActions.setActiveSavedSearchId(null),
};

const connector = connect(mapState, mapDispatch);
export type ReduxProps = ConnectedProps<typeof connector>;

function MarketSelector({ listingsCount, isLoading, clearActiveSavedSearch }: ReduxProps) {
  const { t } = useTranslation();
  const criteria = useListingSearchCriteria();
  const { options, currentMarket, defaultValue, isOnCustomMarket } = useMarket();

  const toggleMarket = market => {
    clearActiveSavedSearch();
    criteria.removeAll();
    criteria.changeMarket(market);
    criteria.pushToHistory();
  };

  let allOptions: Array<GroupedOptionType | OptionType> = options;
  let value = defaultValue;

  if (isOnCustomMarket) {
    value = { label: t('common:marketSelector:custom'), value: 'custom' };
    allOptions = [value, ...options];
  }

  if (currentMarket && !currentMarket.isPublic) {
    value = {
      label: t(`common:marketSelector.${currentMarket.id}`),
      value: `${currentMarket.id}`,
    };

    allOptions = options.map(group => {
      if (group.label === t(`country:${currentMarket.countryCode}`)) {
        return {
          ...group,
          options: [...group.options, value!],
        };
      }
      return group;
    });
  }

  return (
    <h1 data-testid="marketSelectorContainer" className={s.resultsContainer}>
      {isLoading ? <Spinner className={s.spinner} /> : `${listingsCount} `}
      <span className={s.resultsInText}>{`${t('common:marketSelector.results', {
        count: listingsCount,
      })} `}</span>
      <MarketToggleSelect
        name="marketSelector"
        options={allOptions}
        value={value}
        onChange={toggleMarket}
        isDisabled={isLoading}
        sourcePage={PARAMETERS.searchResultsPage}
        sourceContent={PARAMETERS.searchCriteriaBar}
        showCurrentFilters
      />
    </h1>
  );
}

export default connector(MarketSelector);
