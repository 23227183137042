import actions from '@store/actions/listingSearchPage';
import { LeaseTypes } from '@root/types';
import CheckboxFilter from '../CheckboxFilter';

const leaseTypes: Array<{ label: string; value: LeaseTypes; translationKey: string }> = [
  {
    label: 'Direct',
    value: 'direct',
    translationKey: 'filters:leaseType:direct',
  },
  {
    label: 'flex',
    value: 'flex',
    translationKey: 'filters:leaseType:flex',
  },
  {
    label: 'sublease',
    value: 'sublease',
    translationKey: 'filters:leaseType:sublease',
  },
];

const LeaseTypeFilter = () => {
  return (
    <CheckboxFilter
      filters={leaseTypes}
      filterType="leaseTypes"
      filterDispatchAction={actions.leaseTypeFilterChanged}
    />
  );
};

export default LeaseTypeFilter;
