import COLORS from '@root/shared/colorConstants';
import cn from 'classnames';
import RelatedListingTag from './RelatedListingTag';

type Props = {
  listingsData: ListingEngagementMetadata[];
  relatedListingIds: string[];
  showRelatedListingTag?: boolean;
};

export type ListingEngagementMetadata = {
  id: string;
  value: number;
  color: string;
  label: string;
  displayValue: string;
};

const ListingDataList = ({
  listingsData,
  relatedListingIds,
  showRelatedListingTag = true,
}: Props) => {
  return (
    <ul>
      {listingsData.map(listing => (
        <li key={listing.id} className={cn('mb-1', !listing.value && 'text-black-020')}>
          <div className="flex items-center justify-between font-body-small">
            <div className="flex items-center">
              <section
                className="mr-1 h-[10px] min-w-[10px] rounded-[2px]"
                style={{ backgroundColor: !listing.value ? COLORS.black20 : listing.color }}
              />
              <section>
                {listing.label}
                {showRelatedListingTag && relatedListingIds.includes(listing.id) && (
                  <div className="mt-1">
                    <RelatedListingTag />
                  </div>
                )}
              </section>
            </div>
            <section
              className={cn('ml-1 font-body-small-emphasis', !listing.value && 'w-3 text-right')}
            >
              {!listing.displayValue || listing.displayValue === '0' ? '-' : listing.displayValue}
            </section>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ListingDataList;
