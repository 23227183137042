import { isEmpty, isNull, lowerCase } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import { update } from '@shared/typedApi';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import cn from 'classnames';
import {
  CompanyLogoDisplay,
  YourPhotoDisplay,
} from '@components/layouts/Truva/AccountSettings/utils';
import { Notification, CustomIcon, Divider } from '@components/shared';
import { Select, TextInput } from '@components/shared/forms';
import useAnalyticsEvent from '@root/shared/useAnalytics';
import useCurrentUser from '@root/shared/useCurrentUser';
import { CurrentUser, DeprecatedErrorResponse, MarketSlug } from '@root/types';
import useEnums from '@shared/useEnums';
import { PARAMETERS } from '@root/tracking/constants';
import Metric from '@components/shared/Metric';
import { Formik, Form } from 'formik';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import FormSubmitSection from './FormSubmitSection';
import s from './AccountSettings.module.less';

type Props = {
  currentUser: CurrentUser;
};

const ProfileForm = ({ currentUser }: Props) => {
  const { t } = useTranslation('accountSettings');
  const { userAccountInteraction } = useAnalyticsEvent();
  const { refetch: refetchUser } = useCurrentUser();
  const [errors, setErrors] = useState<DeprecatedErrorResponse>();

  const updateUserMutation = useMutation(
    async ({ user }: { user: Partial<CurrentUser>; onSuccess: (values) => void }) => {
      await update(routes.api.currentUser, user);
    },
    {
      onSuccess: async (_, { user: userValues, onSuccess }) => {
        refetchUser();
        Notification.info({
          title: t('notificationSuccessTitle'),
          text: t`successfullyUpdatedAccount`,
        });
        onSuccess(userValues);
      },
      onError: (errorsObject: DeprecatedErrorResponse) => {
        setErrors(errorsObject);
      },
    },
  );

  const { markets, changableRoles } = useEnums();
  const marketOptions: Array<{ value: MarketSlug; label: string }> = markets
    .filter(market => market.isPublic)
    .sort((marketA, marketB) =>
      t(`common:markets.${marketA.id}`).localeCompare(t(`common:markets.${marketB.id}`)),
    )
    .map(market => ({
      value: market.id,
      label: t(`common:markets.${market.id}`),
    }));

  const professionalRoles: Array<{ value: string; label: string }> = changableRoles.map(role => ({
    value: role,
    label: t(`common:roles.${role}`),
  }));

  const errorsSection =
    errors && !isEmpty(errors.messages) ? (
      <div className={s.formErrorsContainer}>
        {errors.messages.map(e => (
          <div className={s.formError} key={e}>
            <CustomIcon type="close" className={s.errorIcon} />
            {e}
          </div>
        ))}
      </div>
    ) : null;

  const handleCloudinaryFileInputError = (error: string) => {
    Notification.info({
      title: t('common:fileUploadFailure'),
      text: error,
      placement: 'bottomLeft',
    });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('profileForm.validations.firstName')),
    lastName: Yup.string().required(t('profileForm.validations.lastName')),
    role: Yup.string().required(t('profileForm.validations.professionalRole')),
    marketSlug: Yup.string(),
    phoneExtension: Yup.number()
      .nullable()
      .typeError(t('profileForm.validations.phoneExtension'))
      .integer(t('profileForm.validations.phoneExtension')),
  });

  const updateUser = formValues => {
    updateUserMutation.mutate({
      user: {
        id: currentUser.id,
        ...formValues,
        role: formValues.role === t(`common:roles.admin`) ? 'admin' : formValues.role,
      },
      onSuccess: values => {
        userAccountInteraction({
          action: PARAMETERS.updateProfessionalInfo,
          actionType: 'UPDATE_USER_PROFESSIONAL_INFO',
          sourceContent: 'profile tab',
          sourcePage: 'account settings',
          otherAttributes: {
            userAccountType: lowerCase(currentUser.role),
            userMarket: t(`common:markets.${values.marketSlug}`),
            userTourbookAvatar: isNull(values.tourbookAvatarImage) ? 'no' : 'yes',
            userTourbookLogo: isNull(values.tourbookCompanyImage) ? 'no' : 'yes',
            userTourbookPhoneNumber: t('common:formattedPhone', {
              formattedPhone: values.tourbookPhoneNumber,
            }),
          },
        });
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        jobTitle: currentUser.jobTitle,
        role:
          currentUser.role === 'admin' ? t(`common:roles.${currentUser.role}`) : currentUser.role,
        marketSlug: currentUser.marketSlug,
        tourbookAvatarCloudinaryId: currentUser.tourbookAvatarImage?.cloudinaryId,
        tourbookCompanyLogoCloudinaryId: currentUser.tourbookCompanyImage?.cloudinaryId,
        phoneNumber: currentUser.phoneNumber,
        phoneExtension: currentUser.phoneExtension || undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={updateUser}
    >
      {({ values }) => (
        <Form
          id="accountProfileForm"
          data-testid="accountSettingsForm"
          className={cn(s.profileForm, 'mt-[-38px]')}
        >
          <FormThemeContextProvider theme="vertical">
            {errorsSection}
            <div className="mt-5 flex items-center justify-between pb-2">
              <h2 className="font-subtitle">{t('professionalInfoTitle')}</h2>
            </div>
            <Divider className="mb-3" />
            <div className="mt-3">
              <Metric label={t('workEmail')} value={currentUser.email} />
            </div>
            <div className="col-gap-2 !mobile:col-gap-0 mt-2 flex flex-col">
              <div className="flex flex-row gap-2 mobile:flex-col mobile:gap-0">
                <TextInput
                  labelClass="!mb-[4px] font-body-medium"
                  name="firstName"
                  containerClass="w-3/12 tablet:w-1/2 mobile:w-full max-w-[307px] !mobile:mb-0"
                  labelText={t('firstName')}
                  required
                />
                <TextInput
                  labelClass="!mb-[4px] font-body-medium"
                  name="lastName"
                  containerClass="w-3/12 tablet:w-1/2 mobile:w-full max-w-[307px] !mobile:mb-0"
                  labelText={t('lastName')}
                  required
                />
              </div>
              <div className="flex flex-row gap-2 mobile:flex-col mobile:gap-0">
                <TextInput
                  labelClass="!mb-[4px] font-body-medium"
                  name="jobTitle"
                  containerClass="w-3/12 tablet:w-1/2 mobile:w-full max-w-[307px] !mobile:mb-0"
                  labelText={t('jobTitle')}
                />
                {currentUser.role === 'admin' ? (
                  <TextInput
                    labelClass="!mb-[4px] font-body-medium"
                    name="role"
                    containerClass="w-3/12 tablet:w-1/2 mobile:w-full max-w-[307px] !mobile:mb-0"
                    labelText={t('professionalRole')}
                    disabled
                  />
                ) : (
                  <Select
                    containerClass="w-3/12 tablet:w-1/2 mobile:w-full max-w-[307px] !mobile:mb-0"
                    name="role"
                    labelText={t('professionalRole')}
                    options={professionalRoles}
                    required
                    isClearable={false}
                  />
                )}
              </div>
              <div className="flex flex-row gap-2 mobile:flex-col mobile:gap-0">
                <div className="!mobile:mb-0 flex w-3/12 max-w-[307px] flex-col tablet:w-1/2 mobile:w-full">
                  <TextInput
                    containerClass="!mb-0"
                    labelClass="!mb-[4px]"
                    labelText={t('phoneNumber')}
                    name="phoneNumber"
                  />
                  {values?.phoneNumber && (
                    <div className="mb-1 ml-1 mt-0.5 w-full font-body-medium">
                      {`Preview: `}
                      {values?.phoneExtension
                        ? t('common:formattedPhoneWithExtension', {
                            formattedPhone: values?.phoneNumber,
                            extension: values?.phoneExtension,
                          })
                        : t('common:formattedPhone', {
                            formattedPhone: values?.phoneNumber,
                          })}
                    </div>
                  )}
                </div>

                <TextInput
                  labelClass="!mb-[4px]"
                  name="phoneExtension"
                  containerClass="w-3/12 tablet:w-1/2 mobile:w-full max-w-[307px] !mobile:mb-0"
                  labelText={t('extension')}
                />
              </div>
              <div>
                <Select
                  containerClass="w-3/12 tablet:w-1/2 mobile:w-full max-w-[307px] !mobile:mb-0"
                  name="marketSlug"
                  labelClass="!mb-[4px]"
                  options={marketOptions}
                  labelText={t('market')}
                  placeholder={t('common:onboarding.marketPlaceholder')}
                  required
                  isClearable={false}
                />
              </div>
              <div className="flex flex-row gap-2 mobile:flex-col">
                <YourPhotoDisplay
                  className="w-3/12 max-w-[307px] tablet:w-1/2 mobile:w-full"
                  currentUser={currentUser}
                  isEditable
                  image={currentUser.tourbookAvatarImage}
                  handleCloudinaryFileInputError={handleCloudinaryFileInputError}
                />
                <CompanyLogoDisplay
                  className="w-3/12 max-w-[307px] tablet:w-1/2 mobile:w-full"
                  currentUser={currentUser}
                  isEditable
                  image={currentUser.tourbookCompanyImage}
                  handleCloudinaryFileInputError={handleCloudinaryFileInputError}
                />
              </div>
            </div>
            <FormSubmitSection />
          </FormThemeContextProvider>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
