import { useState, useRef, useEffect } from 'react';
import Initials from './Initials';
import s from './InquiryForm.module.less';

type Props = { name: string; photoUrl?: string | null; isCompact?: boolean };

const Avatar = ({ name, photoUrl, isCompact = false }: Props) => {
  const [imageHasError, setImageHasError] = useState(false);
  const image = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    if (image.current) {
      image.current.addEventListener('error', () => setImageHasError(true));
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image.current]);

  return (
    <span data-testid="avatar" className={isCompact ? s.compactAvatar : s.avatar}>
      {photoUrl && !imageHasError ? (
        <img src={photoUrl} alt={name} ref={image} />
      ) : (
        <Initials name={name} />
      )}
    </span>
  );
};

export default Avatar;
