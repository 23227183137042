import cn from 'classnames';
import { Tooltip } from 'antd';
import { IconButton } from '@components/shared';
import s from '../FullWidthLightbox.module.less';

type SingleDownloadButtonLinkTypes = {
  disabled?: boolean;
  downloadPath: string;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClick: () => void;
  title: string;
  className?: string;
};

const SingleDownloadButtonLink = ({
  disabled,
  downloadPath,
  onMouseOver = () => null,
  onMouseLeave = () => null,
  onClick,
  title,
  className,
}: SingleDownloadButtonLinkTypes) => {
  return (
    <Tooltip placement="topLeft" trigger={['hover']} title={title}>
      <a
        href={downloadPath}
        aria-label={title}
        download
        onClick={onClick}
        className={cn([
          s.downloadButton,
          'left-4 !z-standard-2',
          disabled && 'pointer-events-none',
          className,
        ])}
      >
        <IconButton
          icon="download2"
          disabled={disabled}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        />
      </a>
    </Tooltip>
  );
};

export default SingleDownloadButtonLink;
