import * as React from 'react';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { CustomIcon } from '@components/shared';
import { ValidIconTypes } from '@components/shared/CustomIcon';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonType } from '@components/shared/V2Button';

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  size?: ButtonSize;
  icon?: ValidIconTypes;
  'data-testid'?: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: ButtonType;
};

const CancelButton = ({
  disabled = false,
  children,
  size,
  icon,
  'data-testid': testId,
  className,
  onClick,
  type = 'secondary',
}: Props) => {
  const formik = useFormikContext();
  const { t } = useTranslation('common');

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick(e);
    }
    formik.resetForm();
  };
  return (
    <Button
      htmlType="button"
      type={type}
      size={size}
      disabled={disabled}
      data-testid={testId}
      className={cn(className)}
      onClick={onClickHandler}
      onMouseDown={onClickHandler}
    >
      {icon ? <CustomIcon className="mr-1" data-testid={`${icon}-icon`} type={icon} /> : null}
      {children || t('cancel')}
    </Button>
  );
};

export default CancelButton;
