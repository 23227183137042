import { TFunction } from 'i18next';
import { partition } from 'lodash';
import { Notification } from '@components/shared';

type File = { size: number; name: string };
type Args = {
  files: Array<File>;
  maxFileSizeInMb?: number;
  t: TFunction;
};

const filterOutLargeFiles = ({ files, maxFileSizeInMb = Infinity, t }: Args): File[] => {
  const maxFileSize = maxFileSizeInMb * 1_000_000;
  const [validFiles, oversizedFiles] = partition(files, f => f.size <= maxFileSize);

  if (oversizedFiles.length > 0) {
    const isSimpleErrorMessage = validFiles.length === 0 && oversizedFiles.length === 1;

    Notification.error({
      title: t('media.filesOverSizeLimit', {
        count: oversizedFiles.length,
      }),
      text: isSimpleErrorMessage ? (
        t('media.singleFileOverSizeLimitDescription', {
          size: `${maxFileSizeInMb}MB`,
        })
      ) : (
        <div>
          <ul className="my-2 ml-[22px]">
            {oversizedFiles.map(file => (
              <li key={file.name} className="list-disc">
                {file.name}
              </li>
            ))}
          </ul>
          <span>
            {t('media.multipleFileOverSizeLimitDescription', {
              size: maxFileSizeInMb,
            })}
          </span>
        </div>
      ),
      placement: 'topRight',
    });
  }

  return validFiles;
};

export default filterOutLargeFiles;
