import { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { FormikTouched, useFormikContext } from 'formik';
import renderingInServer from '@shared/renderingInServer';
import useEnv from '@shared/useEnv';
import { useTranslation } from 'react-i18next';

type Props<FormValues> = {
  currentPagePath?: string;
  enabled: boolean;
  checkIfShouldTriggerPrompt?: (
    dirty: boolean,
    touched: FormikTouched<FormValues>,
    location: any,
  ) => boolean;
};

export default function FormCancellationPrompt<FormValues>({
  currentPagePath,
  enabled,
  checkIfShouldTriggerPrompt,
}: Props<FormValues>) {
  const { dirty, touched } = useFormikContext<FormValues>();
  const { t } = useTranslation('common');
  const isSSR = renderingInServer();
  const { railsEnv } = useEnv();

  // part 1: for refreshes, can't use the React-Router prompt
  // so we use window.beforeunload to prompt the user to
  // cancel
  const initBeforeUnLoad = showExitPrompt => {
    /* eslint-disable */
    if (!isSSR) {
      window.onbeforeunload = event => {
        if (showExitPrompt) {
          const e = event || window.event;
          e.preventDefault();
          if (e) {
            e.returnValue = '';
          }
          return '';
        }
      };
    }
    /* eslint-enable */
  };

  if (!isSSR) {
    window.onload = () => {
      // added the rails env development thing because this confirm to refresh behavior
      // is massively annoying while developing
      initBeforeUnLoad(dirty && enabled && railsEnv !== 'development');
    };
  }

  useEffect(() => {
    if (!isSSR) {
      initBeforeUnLoad(dirty && enabled && railsEnv !== 'development');
    }

    return () => {
      window.onbeforeunload = null;
    };
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, enabled]);

  if (!enabled) {
    return null;
  }

  // part 2: for navigation moves, use the react-router prompt function
  // to ask the user to confirm changes (can't use the built in one for the form
  // because on the create page we go from ?page=listing to ?page=location and that
  // counts as a navigation change

  const message = location => {
    const shouldTriggerPrompt = checkIfShouldTriggerPrompt
      ? checkIfShouldTriggerPrompt(dirty, touched, location)
      : dirty && location.pathname !== currentPagePath;

    return shouldTriggerPrompt ? t('prompt.unsavedChanges') : true;
  };

  return <Prompt message={message} />;
}
