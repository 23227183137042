import cn from 'classnames';
import CustomIcon from '@components/shared/CustomIcon';
import { Address, MultipathImage } from '@root/types';
import useAltText from '@root/shared/useAltText';
import s from '../FullWidthLightbox.module.less';

type MapThumbnailProps = {
  onClick: () => void;
  isSelected: boolean;
  address: Address;
  mapImage?: MultipathImage | undefined;
};
const MapIconButton = ({ onClick, isSelected, mapImage, address }: MapThumbnailProps) => {
  const { getAltText } = useAltText({ address });
  if (!mapImage) {
    return null;
  }

  return (
    <button className={cn(s.mapIconButton)} type="button" onClick={onClick}>
      <div className={cn(s.mapIconWrapper)}>
        <CustomIcon type="pin-drop" className={s.mapIcon} />
        <img
          src={mapImage.smallPath}
          alt={getAltText({ type: mapImage.type })}
          className={cn(s.mapIconButtonImage, isSelected && s.selectedMapThumbnail)}
        />
      </div>
    </button>
  );
};

export default MapIconButton;
