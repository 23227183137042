/* eslint-disable no-else-return */
import { useRef } from 'react';
import mediaConstants from '@shared/mediaConstants';
import { Address, MultipathImage, Video } from '@root/types';
import { SourcePage, SourceContent } from '@root/store/actions/videoAnalytics';
import cn from 'classnames';
import VideoPlayer from '@components/shared/VideoPlayer';
import useAltText from '@root/shared/useAltText';
import s from '../FullWidthLightbox.module.less';

const srcSetFor = photo =>
  `${photo.largePath} 1500w, ${photo.mediumPath} 900w, ${photo.smallPath} 400w`;

type Props = {
  asset: MultipathImage | (Video & { isVideo: true });
  autoplay: boolean;
  sourceContent?: SourceContent;
  sourcePage?: SourcePage;
  withTabbedTop: boolean;
  isTabletDisplay: boolean;
  onClick?: () => void;
  address: Address;
  companyName?: string;
};

const Asset = ({
  asset,
  autoplay,
  sourceContent,
  sourcePage,
  withTabbedTop,
  isTabletDisplay,
  onClick = () => {},
  address,
  companyName,
}: Props) => {
  const { getAltText } = useAltText({ address });
  const containerRef = useRef<HTMLDivElement | null>(null);

  if ('isVideo' in asset) {
    /**
     * This exists because antd's carousel duplicates the elements passed in.
     * When autoplaying a video, we only want the uncloned slide version to
     * be able to autoplay and not any of its duplicates.
     */
    const isClonedVideo =
      !!containerRef.current &&
      containerRef.current.parentElement!.parentElement!.classList.contains('slick-cloned');

    return (
      <div className={cn(s.assetContainer, withTabbedTop && s.withTabbedTop)} ref={containerRef}>
        <VideoPlayer
          video={asset}
          autoplay={autoplay && !isClonedVideo && false}
          testId="videoPlayer"
          sourcePage={sourcePage!}
          sourceContent={sourceContent!}
        />
      </div>
    );
  } else {
    return (
      <div className={s.assetContainerWrapper}>
        <div
          className={cn(
            s.assetContainer,
            withTabbedTop && s.withTabbedTop,
            isTabletDisplay && withTabbedTop && s.withTabbedTopTablet,
          )}
          onClick={onClick}
          role="button"
        >
          <img
            alt={getAltText({
              type: asset.type,
              options: { roomName: asset.description, companyName },
            })}
            /* eslint-disable-next-line react/jsx-no-literals */
            sizes={`(min-width: ${mediaConstants['screen-md-min']}) 66.6vw, 100vw`}
            srcSet={srcSetFor(asset)}
            src={asset.largePath}
          />
        </div>
      </div>
    );
  }
};

export default Asset;
