import { useTranslation } from 'react-i18next';
import s from './ContentShootDetails.module.less';

type Props = {
  availableForContentShoot: boolean;
};

const Availability = ({ availableForContentShoot }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <>
      <div className={s.availableForContentShootSection}>
        <span className={s.sectionTitle}>
          {t('media.contentShootDetails.availableForContentShoot')}
        </span>
        <div>
          <span>{availableForContentShoot ? t('common:yes') : t('common:no')}</span>
        </div>
      </div>
    </>
  );
};

export default Availability;
