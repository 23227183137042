import loadSearchResults, {
  receiveBuildings,
  Actions as PageLoadActions,
} from './listingSearchPageLoad';
import analyticsActions, { Actions as AnalyticsActions } from './analyticsActions';
import { createQuickInquiry, Actions as CreateInquiryActions } from './createInquiry';

export default {
  loadSearchResults,
  receiveBuildings,
  createQuickInquiry,
  ...analyticsActions,
};

export type Actions = AnalyticsActions | PageLoadActions | CreateInquiryActions;
