import { notification } from 'antd';
import api from '@shared/api';
import { FormikValues } from 'formik';

export type DefaultCloudinaryResponseType = {
  public_id: string;
  original_filename: string;
  format: string;
  created_at: string;
  secure_url: string;
  ok: boolean;
};

export async function uploadToCloudinary(
  file: File,
  cloudinaryUploadPreset: string,
  cloudinaryCloud: string,
  type: 'image' | 'video',
): Promise<DefaultCloudinaryResponseType> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', cloudinaryUploadPreset);

  const cloudinaryResponse = await api.fetch(
    `https://api.cloudinary.com/v1_1/${cloudinaryCloud}/${type}/upload`,
    {
      method: 'POST',
      body: formData,
    },
  );

  return cloudinaryResponse.json();
}

export async function handleResponse(
  response: DefaultCloudinaryResponseType,
  successMessage?: string,
  errorMessage?: string,
) {
  if (response.secure_url) {
    if (successMessage) {
      notification.success({
        message: successMessage,
      });
    }
  } else if (errorMessage) {
    notification.error({
      message: errorMessage,
    });
  }
}
export const deleteMedia = async (values, mediaType, photoOrder, deleteMessage, mediaTypeVal) => {
  const { [mediaType]: media } = values;
  const updatedFormikValues =
    media.length === 1
      ? {
          ...values,
          [mediaType]: [
            { mediaType: mediaTypeVal, title: '', description: '', cloudinaryId: '', order: 1 },
          ],
        }
      : {
          ...values,
          [mediaType]: media
            .filter(m => m.order !== photoOrder)
            .map((m, index) => ({ ...m, order: index + 1 })),
        };

  const mediaToDelete = media.find(m => m.order === photoOrder);
  if (mediaToDelete?.cloudinaryId || mediaToDelete?.title || mediaToDelete?.description) {
    notification.success({ message: deleteMessage });
  }

  return updatedFormikValues;
};

export async function deletePhoto(values, mediaType, photoOrder) {
  const updatedFormikValues = {
    ...values,
    [mediaType]: [
      ...values[mediaType].map(media => {
        if (media.order === photoOrder) {
          return {
            ...media,
            cloudinaryId: '',
          };
        }
        return media;
      }),
    ],
  };
  return updatedFormikValues;
}

export async function updateOrder(values, draggedItem, index) {
  const newArray = [...values];
  const draggedItemIndex = values.findIndex(item => item.order === draggedItem.order);
  newArray.splice(draggedItemIndex, 1);
  newArray.splice(index, 0, draggedItem);
  newArray.forEach((item, i) => {
    const newItem = { ...item, order: i + 1 };
    newArray[i] = newItem;
  });
  return newArray;
}

export async function updatePhoto(values, mediaType, photoOrder, mediaTypeVal, secureUrl) {
  const formikValues = (values as FormikValues)?.[mediaType] || [];
  const index = formikValues.findIndex(val => val.order === photoOrder);

  const updatedVal = {
    mediaType: mediaTypeVal,
    title: formikValues[index]?.title || '',
    description: formikValues[index]?.description || '',
    cloudinaryId: secureUrl || '',
    order: photoOrder,
  };

  const newArray =
    index !== -1
      ? [...formikValues.slice(0, index), updatedVal, ...formikValues.slice(index + 1)]
      : [...formikValues, updatedVal];

  const updatedFormikValues = {
    ...((values as FormikValues) || {}),
    [mediaType]: newArray,
  };
  return updatedFormikValues;
}
