import cn from 'classnames';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { Button } from '@components/shared';
import { useTranslation } from 'react-i18next';
import { RawInput } from '../forms';
import ColorPickerColorGroup, { ColorGroup } from './ColorPickerColorGroup';
import s from './ColorPicker.module.less';

type Props = {
  color: string;
  name?: string;
  colorGroups?: ColorGroup[];
  onChange: (color: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  showTransparencyOption?: boolean;
  transparencyDisabled?: boolean;
};
const ColorPicker = ({
  color,
  name,
  colorGroups,
  onChange,
  onFocus,
  onBlur,
  showTransparencyOption,
  transparencyDisabled,
}: Props) => {
  const { t } = useTranslation('common');
  const inputName = `${name}-input`;

  return (
    <div className="relative rounded-[3px] border border-solid border-black-020 bg-white">
      <HexColorPicker
        color={color}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={cn('!w-full rounded-none p-1', s.colorPicker)}
      />
      <div className="flex items-center gap-0.5 px-1 pb-1">
        <label htmlFor={inputName} className="font-body-small">
          {t('hex')}
        </label>
        {color === 'transparent' ? (
          <RawInput
            disabled
            value="-"
            onChange={() => {}}
            className="w-full rounded-[3px] border border-solid border-black-020 !px-1 !py-0 font-body-medium focus:outline-egg-100"
          />
        ) : (
          <HexColorInput
            color={color}
            id={inputName}
            name={inputName}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            className="w-full rounded-[3px] border border-solid border-black-020 px-1 font-body-medium focus:outline-egg-100"
          />
        )}
        {showTransparencyOption && (
          <Button
            className="ml-1 flex flex-nowrap items-center"
            type="tertiary"
            size="small"
            icon="transparent"
            disabled={transparencyDisabled}
            onClick={() => onChange('transparent')}
          >
            {t('transparent')}
          </Button>
        )}
      </div>
      <div>
        {colorGroups?.map(colorGroup => (
          <ColorPickerColorGroup
            key={colorGroup.title}
            {...colorGroup}
            selectedColor={color}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
