import { useState } from 'react';
import cn from 'classnames';
import { Form as AntdForm } from '@ant-design/compatible';

import { Input, Alert, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import api, { csrfTokenHeader } from '@shared/api';
import Uploader from '@root/components/shared/Uploader';
import Button from '@components/shared/V2Button';
import { Company, DeprecatedErrorResponse } from '@root/types';
import usePhotoPreview from '@shared/usePhotoPreview';
import s from './Form.module.less';

const formItemLayout = {
  labelCol: {
    sm: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 12 },
  },
};

type Props = {
  company?: Company | null;
  submitText: string;
};

export const useForm = (company: Company | null) => {
  const history = useHistory();
  const [name, setName] = useState<string | undefined>(company ? company.name : undefined);
  const [logo, setLogo] = useState<Blob | null>(null);
  const { photoPreview: logoPreview } = usePhotoPreview(
    logo,
    company ? company.logo.path : undefined,
  );
  const [fetching, setFetching] = useState(false);
  const [errors, setErrors] = useState<DeprecatedErrorResponse | null>(null);
  const { t } = useTranslation('admin');

  const formIsFilledOut = name && (logo || company);

  const onSubmit = async () => {
    setFetching(true);
    const formData = new FormData();
    if (name) {
      formData.append('name', name);
    }
    if (logo) {
      formData.append('logo', logo);
    }

    const route = company ? routes.api.admin.company(company.id) : routes.api.admin.companies();
    const method = company ? 'PUT' : 'POST';

    const response = await api.fetch(route, {
      method,
      body: formData,
      headers: { ...csrfTokenHeader() },
    });
    const json = await response.json();
    setFetching(false);
    if (response.ok) {
      notification.success({ message: t('companies.successMessage') });
      history.push(routes.admin.companies);
    } else {
      setErrors(json as DeprecatedErrorResponse);
    }
  };

  const hasErrors = !!errors;

  return {
    name,
    setName,
    logo,
    setLogo,
    logoPreview,
    submitIsDisabled: !formIsFilledOut || fetching,
    onSubmit,
    hasErrors,
    errorMessage: hasErrors ? errors.messages.join(', ') : null,
    errorFields: hasErrors ? errors.invalidFields : [],
  };
};

const Form = ({ company = null, submitText }: Props) => {
  const { t } = useTranslation('admin');

  const {
    name,
    setName,
    setLogo,
    logoPreview,
    submitIsDisabled,
    onSubmit,
    hasErrors,
    errorMessage,
    errorFields,
  } = useForm(company);

  return (
    <AntdForm onSubmit={onSubmit} {...formItemLayout}>
      {hasErrors ? <Alert type="error" message={errorMessage} className={s.alert} /> : null}
      {company ? (
        <AntdForm.Item htmlFor="companyId" required label={t('companies.id')}>
          <Input value={company.id} disabled id="companyId" />
        </AntdForm.Item>
      ) : null}
      <AntdForm.Item
        htmlFor="name"
        required
        label={
          <span className={cn(errorFields.includes('name') && s.withError)}>
            {t('companies.name')}
          </span>
        }
      >
        <Input value={name} onChange={({ target }) => setName(target.value)} id="name" />
      </AntdForm.Item>
      <AntdForm.Item required htmlFor="formUpload" label={t('companies.logo')}>
        <Uploader
          id="formUpload"
          formUpload
          successCallback={({ file }) => setLogo(file)}
          accept=".png,.jpg,.jpeg,.jpg,.svg"
        />
        <div className="text-text-attention">{t('companies.logoUploadWarning')}</div>
      </AntdForm.Item>
      <AntdForm.Item label={t('companies.logoThumbnail')}>
        {logoPreview ? (
          <img src={logoPreview} alt="Logo Preview" className={s.imagePreview} />
        ) : null}
      </AntdForm.Item>
      <Button disabled={submitIsDisabled} onClick={() => onSubmit()}>
        {submitText}
      </Button>
    </AntdForm>
  );
};

export default Form;
