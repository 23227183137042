import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import routes from '@root/routes';
import { isNull } from 'lodash';
import { AdminBuilding, AdminMicrosite } from '@root/types';
import { Spinner } from '@components/shared';
import NewMicrosite from './NewMicrosite';
import EditMicrosite from './EditMicrosite';

type Props = {
  building: AdminBuilding;
};

export default function Microsite({ building }: Props) {
  const {
    data: microsite,
    isLoading,
    refetch,
  } = useQuery<AdminMicrosite | null>([routes.api.admin.buildingMicrosite(building.id)], () =>
    get(routes.api.admin.buildingMicrosite(building.id)),
  );

  if (isLoading) return <Spinner />;

  return isNull(microsite) ? (
    <NewMicrosite buildingId={building.id} refetch={refetch} />
  ) : (
    <EditMicrosite
      buildingId={building.id}
      microsite={microsite!}
      refetch={refetch}
      hasVideo={!!building.video}
    />
  );
}
