import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Input, Row, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import routes from '@root/routes';
import api from '@shared/api';
import Button from '@components/shared/V2Button';
import LinkBuildingModal from './LinkBuildingModal';
import s from './PropertyPage.module.less';

const {
  api: {
    admin: { buildings: apiAdminBuildingsRoute },
  },
  admin: { building: adminBuildingRoute },
} = routes;
const fields = t => [
  { label: t('property.vtsId'), key: 'vtsId' },
  { label: t('property.name'), key: 'name', placeholder: t('property.namePlaceholder') },
  { label: t('property.street'), key: 'street', placeholder: t('property.streetPlaceholder') },
];

type Props = {
  property: $TSFixMe;
};

function PropertyForm({ property }: Props) {
  const history = useHistory();
  const { t } = useTranslation('admin');
  const [creating, setCreating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const createBuilding = async () => {
    setCreating(true);
    const res = await api.post(apiAdminBuildingsRoute, { propertyId: property.id });

    if (res.ok) {
      const { id: buildingId } = await res.json();
      history.push(adminBuildingRoute(buildingId));
    } else {
      notification.error({
        message: t('property.saveBuildingError'),
      });
      setCreating(false);
    }
  };

  return (
    <>
      {fields(t).map(({ label, key, placeholder }) => (
        <Row key={key} className={s['form-row']}>
          <Col span={5}>
            <label htmlFor={key}>{label}</label>
          </Col>
          <Col span={7}>
            <Input
              id={key}
              name={key}
              placeholder={placeholder || label}
              disabled
              value={property[key]}
            />
          </Col>
        </Row>
      ))}
      {property.buildingId ? (
        <Row className={s['form-row']}>
          <Col span={5}>
            <label htmlFor="buildingLink">{t('property.buildingLinkLabel')}</label>
          </Col>
          <Col span={7}>
            <a
              data-testid="buildingLink"
              id="buildingLink"
              href={adminBuildingRoute(property.buildingId)}
            >
              {adminBuildingRoute(property.buildingId)}
            </a>
          </Col>
        </Row>
      ) : null}
      <Row className={s['form-row']}>
        <Col>
          <Button
            type="primary"
            onClick={e => {
              e.preventDefault();
              createBuilding();
            }}
            disabled={!!(creating || property.buildingId)}
          >
            {t('property.createBuilding')}
          </Button>
          <Button
            type="secondary"
            onClick={() => setShowModal(true)}
            className={s['link-button']}
            disabled={!!(creating || property.buildingId)}
          >
            {t('property.linkBuilding')}
          </Button>
        </Col>
      </Row>
      <LinkBuildingModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        propertyId={property.id}
      />
    </>
  );
}

export default PropertyForm;
