import { useState } from 'react';
import { Form, TextAreaAutosize as TextArea, SubmitButton } from '@components/shared/forms';
import { useParams, Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Button from '@components/shared/V2Button';
import api from '@shared/api';
import routes from '@root/routes';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import SafeRender from '@components/shared/SafeRender';
import s from './ListingDescription.module.less';

type Props = {
  description: string;
  readOnly: boolean;
  tourbookListingId: string;
  className?: string;
};

const ListingDescription = ({
  description: rawDescription,
  readOnly,
  tourbookListingId,
  className,
}: Props) => {
  const { t } = useTranslation('tourbook');
  const { tourbookId } = useParams<{ tourbookId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [previousDescription, setPreviousDescription] = useState(rawDescription);
  const [description, setDescription] = useState(rawDescription);
  const handleSubmit = async () => {
    await api.put(routes.api.tourbookTourbookListing({ tourbookId, tourbookListingId }), {
      description,
    });
    setPreviousDescription(description);
    setIsEditing(false);
  };
  const handleBlur = () => description === previousDescription && setIsEditing(false);
  const handleCancel = () => {
    setDescription(previousDescription);
    setIsEditing(false);
  };
  const unsavedChangesExist = () => description !== previousDescription;

  if (readOnly) {
    return <pre className={cn(s.listingDescription, className)}>{rawDescription}</pre>;
  }

  return (
    <>
      <Prompt
        when={unsavedChangesExist()}
        message={t('listingCard.listingDescription.unsavedChangesPrompt')}
      />
      <div className={s.listingDescription}>
        <Form id={tourbookListingId} initialValues={{ description }} onSubmit={handleSubmit}>
          <TextArea
            name="description"
            className={cn(s.textArea, isEditing && s.isEditing)}
            labelText={null}
            placeholder={t('listingCard.listingDescription.placeholder')}
            onFocus={() => setIsEditing(true)}
            onBlur={handleBlur}
            onChange={e => setDescription(e.target.value)}
            value={description}
            containerClass={s.formContainer}
          />
          <div className={cn(s.actions, !isEditing && s.hidden)}>
            <SubmitButton size="medium" onMouseDown={e => e.preventDefault()}>
              {t('listingCard.listingDescription.save')}
            </SubmitButton>
            <Button size="medium" type="secondary" onMouseDown={handleCancel} className={s.cancel}>
              {t('listingCard.listingDescription.cancel')}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

type HtmlDescriptionProps = {
  description: string | null;
  readOnly: boolean;
  tourbookListingId: string;
};

export const ListingDescriptionHtml = ({
  description: rawDescription,
  readOnly,
  tourbookListingId,
}: HtmlDescriptionProps) => {
  const { t } = useTranslation('tourbook');
  const { tourbookId } = useParams<{ tourbookId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [previousDescription, setPreviousDescription] = useState(rawDescription);
  const [description, setDescription] = useState(rawDescription);

  const handleSubmit = async () => {
    await api.put(routes.api.tourbookTourbookListing({ tourbookId, tourbookListingId }), {
      descriptionHtml: description,
    });
    setPreviousDescription(description);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setDescription(previousDescription);
    setIsEditing(false);
  };
  const unsavedChangesExist = () => description !== previousDescription;
  const isDescriptionEmpty = () =>
    !description || description.replace(/(<([^>]+)>)/gi, '').length === 0;

  if (readOnly) {
    return (
      <div className={s.descriptionContainer}>
        {rawDescription ? (
          <SafeRender unsafeContent={rawDescription}>
            <span />
          </SafeRender>
        ) : null}
      </div>
    );
  }

  return (
    <>
      <Prompt
        when={unsavedChangesExist()}
        message={t('listingCard.listingDescription.unsavedChangesPrompt')}
      />
      <div className={s.listingDescription}>
        <Form
          id={tourbookListingId}
          initialValues={{ descriptionHtml: description }}
          onSubmit={handleSubmit}
        >
          {isEditing ? (
            <div className="border border-solid rounded-[3px] border-black-010 outline-[#ffb200]">
              <div className="py-[12px] px-2">
                <RichTextEditorInput
                  name="descriptionHtml"
                  hideLabel
                  onChange={value => {
                    setDescription(value);
                  }}
                  placeholder={t('listingCard.listingDescription.placeholder')}
                  initialContent={description}
                  excludeMenuItems={['textSize', 'textAlign']}
                />
                <div className="flex gap-x-1 mobile:justify-end">
                  <SubmitButton size="medium" onMouseDown={e => e.preventDefault()}>
                    {t('listingCard.listingDescription.save')}
                  </SubmitButton>
                  <Button
                    size="medium"
                    type="secondary"
                    onClick={e => {
                      e.preventDefault();
                      handleCancel();
                    }}
                    className={s.cancel}
                  >
                    {t('listingCard.listingDescription.cancel')}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              onClick={() => {
                setIsEditing(true);
              }}
              data-testid="description"
              className="border border-solid rounded-[3px] border-black-010"
            >
              <div className={cn(s.descriptionContainer)}>
                <div className="px-2 py-1.5">
                  {!isDescriptionEmpty() ? (
                    <SafeRender unsafeContent={description || ''}>
                      <span />
                    </SafeRender>
                  ) : (
                    <span className="text-black-035">
                      {t('listingCard.listingDescription.placeholder')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default ListingDescription;
