import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Field } from 'formik';
import cn from 'classnames';
import FieldWrapper from '@components/shared/forms/FieldWrapper/FieldWrapper';
import { rawSelectStyles } from '@components/shared/forms/Dropdowns/RawSelect/RawSelect';
import StandardComponents from '@components/shared/forms/Dropdowns/StandardComponents';
import s from '@components/shared/forms/Dropdowns/Dropdowns.module.less';
import Input from './Input';
import css from './styles.module.less';

type Props = {
  fieldName: string;
  values: string | string[];
  pageName: 'building' | 'listing';
  options: { name: string; value?: string }[];
  sortOptionsByIndex?: boolean;
  isMulti?: boolean;
};

const MultiSelectStringListWithSource = memo(
  ({ fieldName, values, pageName, options, isMulti = true, sortOptionsByIndex = false }: Props) => {
    const { t } = useTranslation('admin');

    const metadataPath = `amenitySet.${fieldName}`;

    const optionsObject = options.map((option, index) => {
      return {
        label: t(`${pageName}.${fieldName}.${option.name}`),
        value: option.value || option.name,
        index,
      };
    });

    const sortedOptions = sortOptionsByIndex
      ? optionsObject.sort((optionA, optionB) => optionA.index - optionB.index)
      : optionsObject.sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));

    const initialValueObjects =
      isMulti && Array.isArray(values)
        ? values.map(value => optionsObject.find(option => option.value === value))
        : optionsObject.find(option => option.value === values);

    const showDataSourceField = values?.length > 0;

    return (
      <fieldset className={css.sideBySide}>
        <FieldWrapper
          name={metadataPath}
          labelText={t(`${pageName}.${fieldName}.label`)}
          containerClass={showDataSourceField ? css.left : css.fullWidth}
          data-testid={metadataPath}
        >
          <Field name={metadataPath}>
            {({ field, form, meta }) => (
              <Select
                name={field.name}
                className={cn(s.select, cn(css.select, meta.error && meta.touched && s.error))}
                placeholder=""
                defaultValue={initialValueObjects}
                onChange={async option => {
                  if (isMulti && Array.isArray(option)) {
                    await field.onChange({
                      target: {
                        name: field.name,
                        value: option.map(selectedOption => selectedOption.value),
                      },
                    });
                  } else {
                    await field.onChange({
                      // @ts-ignore
                      target: { name: field.name, value: option?.value },
                    });
                  }
                  form.setFieldTouched(field.name, true);
                }}
                onBlur={field.onBlur}
                options={sortedOptions}
                isOpen
                isClearable
                isSearchable
                inputId={metadataPath}
                isMulti={isMulti}
                components={StandardComponents}
                styles={rawSelectStyles(false)}
                menuPlacement="bottom"
              />
            )}
          </Field>
        </FieldWrapper>
        {showDataSourceField ? (
          <Input
            key={`${pageName}.${fieldName}DataSource`}
            containerClass={css.right}
            labelText={t(`${pageName}.dataSource`)}
            name={`${metadataPath}DataSource`}
          />
        ) : null}
      </fieldset>
    );
  },
);

export default MultiSelectStringListWithSource;
