import { Cloudinary } from '@cloudinary/url-gen';
import { createContext, useContext } from 'react';
import { EnvVariables } from './useEnv';

export const getCloudinary = (env: EnvVariables) => {
  return new Cloudinary({
    cloud: {
      cloudName: env.cloudinaryCloud,
    },
    url: {
      secureDistribution: env.cloudinaryCdn,
      secure: true,
      privateCdn: true,
    },
  });
};

export const CloudinaryContext = createContext<Cloudinary | null>(null);
const useCloudinary = () => useContext(CloudinaryContext)!;

export const CloudinaryContextProvider = CloudinaryContext.Provider;

export default useCloudinary;
