import { RawRadioGroup, FieldWrapper } from '@components/shared/forms';
import { Select } from '@viewthespace/components';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

export default function HeaderBackgroundOverlayField() {
  const [overlayField, _meta, overlayHelpers] = useField({ name: 'homeHeroBackgroundOverlay' });
  const [overlayColorField, _meta2, overlayColorHelpers] = useField({
    name: 'homeHeroBackgroundOverlayColor',
  });
  const { t } = useTranslation('admin');
  const options = [
    { label: t('microsite.headerBackgroundOverlay.none'), value: 'none' },
    {
      label: t('microsite.headerBackgroundOverlay.fadeLeftToRight'),
      value: 'fade_left_to_right',
    },
    {
      label: t('microsite.headerBackgroundOverlay.fadeRightToLeft'),
      value: 'fade_right_to_left',
    },
    {
      label: t('microsite.headerBackgroundOverlay.fadeTopToBottom'),
      value: 'fade_top_to_bottom',
    },
    {
      label: t('microsite.headerBackgroundOverlay.fadeBottomToTop'),
      value: 'fade_bottom_to_top',
    },
  ];
  const selectedOption = options.find(option => option.value === overlayField.value);
  return (
    <FieldWrapper name={overlayField.name} labelText={t('microsite.headerBackgroundOverlay.title')}>
      <div className="flex gap-1">
        <Select
          options={options}
          value={selectedOption}
          name={overlayField.name}
          onChange={option => {
            overlayHelpers.setValue(option?.value);
            overlayHelpers.setTouched(true);
          }}
          label=""
          hideLabel
          selectionType="single"
          className="grow"
          backgroundStyle="filled"
        />
        <RawRadioGroup
          optionsInRow
          disabled={overlayField.value === 'none'}
          name={overlayColorField.name}
          value={overlayColorField.value}
          onChange={({ target: { value } }) => {
            overlayColorHelpers.setValue(value);
            overlayColorHelpers.setTouched(true);
          }}
          options={[
            { label: 'Black', value: '#000000' },
            { label: 'White', value: '#ffffff' },
          ]}
        />
      </div>
    </FieldWrapper>
  );
}
