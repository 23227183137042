import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MarketSlug } from '@root/types';
import ListingSearchCriteria from './Criteria/ListingSearchCriteria';

interface RouteParams {
  marketSlug: MarketSlug | 'custom';
}

type Options = {
  onUrlChange: (criteria: ListingSearchCriteria) => void;
};

const useListingSearchCriteria = (options: Partial<Options> = {}) => {
  const { marketSlug } = useParams<RouteParams>();
  const { search: queryString } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const criteria = new ListingSearchCriteria({ queryString, marketSlug, history, dispatch });

  useEffect(() => {
    if (options.onUrlChange) options.onUrlChange(criteria);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString, marketSlug]);

  return criteria;
};

export default useListingSearchCriteria;
