import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { capitalize } from 'lodash';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { AdminFormSubmitter } from '@components/shared';
import api from '@shared/api';
import routes from '@root/routes';
import { Prompt } from 'react-router-dom';
import type { AdminBuilding, BrochureTemplates } from '@root/types';
import Channels from './Channels/Channels';
import css from './styles.module.less';

const {
  api: {
    admin: { websitePreviewLink: websitePreviewRoute, micrositePreviewLink: micrositePreviewRoute },
  },
} = routes;

const ChannelsBuildingForm = ({
  building,
  updateBuilding,
}: {
  building: AdminBuilding;
  updateBuilding: $TSFixMeFunction;
}) => {
  const { t } = useTranslation('admin');
  const [websitePreviewLink, setwebsitePreviewLink] = useState(undefined);
  const [micrositePreviewLink, setMicrositePreviewLink] = useState(undefined);

  const fetchWebsitePreview = async () => {
    if (building.owningCompany && building.owningCompany.id) {
      const response = await api.fetch(
        websitePreviewRoute(building.owningCompany.id, building.slug),
      );
      try {
        const websiteLink = await response.json();
        setwebsitePreviewLink(websiteLink.buildingUrl);
      } catch {
        setwebsitePreviewLink(undefined);
      }
    }
  };

  const fetchMicrositePreview = async () => {
    if (building.owningCompany && building.owningCompany.id) {
      const response = await api.fetch(
        micrositePreviewRoute(building.owningCompany.id, building.slug),
      );

      try {
        const micrositeLink = await response.json();

        micrositeLink.buildingMicrositeUrl =
          micrositeLink.buildingMicrositeUrl.indexOf('http://') === -1 &&
          micrositeLink.buildingMicrositeUrl.indexOf('https://') === -1
            ? `http://${micrositeLink.buildingMicrositeUrl}`
            : micrositeLink.buildingMicrositeUrl;

        setMicrositePreviewLink(micrositeLink.buildingMicrositeUrl);
      } catch {
        setMicrositePreviewLink(undefined);
      }
    }
  };

  useEffect(() => {
    fetchWebsitePreview();
    fetchMicrositePreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultTemplate = (templates: BrochureTemplates) =>
    templates.find(template => template.selected === true)?.id || templates[0].id;

  const getSelectedBrochureId = (templates: BrochureTemplates) =>
    typeof templates !== 'undefined' && templates.length > 0
      ? defaultTemplate(templates)
      : undefined;

  const formatDate = (date: string | null) => (date ? format(parseISO(date), 'M/d/yyyy') : '');

  const initialValues = {
    websiteStatus: building.websiteStatus,
    brochureStatus: building.brochureStatus,
    micrositeStatus: building.micrositeStatus,
    siteplanStatus: capitalize(building.siteplanStatus),
    websitePublishedAt: formatDate(building.websitePublishedAt),
    websiteRetiredAt: formatDate(building.websiteRetiredAt),
    brochurePublishedAt: formatDate(building.brochurePublishedAt),
    brochureRetiredAt: formatDate(building.brochureRetiredAt),
    micrositePublishedAt: formatDate(building.micrositePublishedAt),
    micrositeRetiredAt: formatDate(building.micrositeRetiredAt),
    brochureTemplates: building.brochureTemplates,
    selectedBrochureTemplate: getSelectedBrochureId(building?.brochureTemplates),
    showComingAvailableSpaces: building.showComingAvailableSpaces,
    showOccupiedSpaces: building.showOccupiedSpaces,
    showListedSpaces: building.showListedSpaces,
    siteplanJsViewerUrl: building.siteplanJsViewerUrl,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={JSON.parse(JSON.stringify(initialValues, (_, v) => (v === null ? '' : v)))}
      onSubmit={async (values, actions) => {
        const channelStatuses = {
          websiteStatus: values.websiteStatus,
          brochureStatus: values.brochureStatus,
          micrositeStatus: values.micrositeStatus,
          selectedBrochureTemplate: values.selectedBrochureTemplate,
          showComingAvailableSpaces: values.showComingAvailableSpaces,
          showOccupiedSpaces: values.showOccupiedSpaces,
          showListedSpaces: values.showListedSpaces,
        };
        const params = JSON.parse(JSON.stringify(channelStatuses, (_, v) => (v === '' ? null : v)));

        await updateBuilding(params);
        fetchWebsitePreview();
        fetchMicrositePreview();
        actions.setSubmitting(false);
      }}
    >
      {({ values, dirty }) => (
        <Form className={css.form}>
          <FormThemeContextProvider theme="vertical">
            <Channels
              websiteStatus={values.websiteStatus}
              brochureStatus={values.brochureStatus}
              websitePreviewLink={websitePreviewLink || ''}
              brochureTemplates={values.brochureTemplates}
              selectedBrochureTemplate={values.selectedBrochureTemplate}
              micrositeStatus={values.micrositeStatus}
              micrositePreviewLink={micrositePreviewLink || ''}
              building={building}
            />
            <AdminFormSubmitter />
            <Prompt when={dirty} message={t('common:prompt.unsavedChanges')} />
          </FormThemeContextProvider>
        </Form>
      )}
    </Formik>
  );
};

export default ChannelsBuildingForm;
