import { useState } from 'react';
import { Divider, notification } from 'antd';
import cn from 'classnames';
import { AdminRoom, Room as RoomType } from '@root/types';
import Typography from '@styles/typography.module.less';
import Button from '@components/shared/V2Button';
import useToggle from '@shared/useToggle';
import { useTranslation } from 'react-i18next';
import Modal from '@components/shared/Modal';
import api from '@shared/api';
import routes from '@root/routes';
import CustomIcon from '@components/shared/CustomIcon';
import Room from './Room';
import ListingRoomForm from './ListingRoomForm';
import css from './ListingRoomSection.module.less';

type Props = {
  rooms: AdminRoom[];
  header: string;
  roomType: string;
  listingId: string;
  fetchListing: Function;
};

const {
  api: {
    admin: { listingRoomsCreate, listingRoomsUpdate },
  },
} = routes;

const ListingRoomSection = ({ rooms, listingId, header, roomType, fetchListing }: Props) => {
  const { value: isModalOpen, setTrue: openModal, setFalse: closeModal } = useToggle();
  const { t } = useTranslation('admin');
  const [roomToUpdate, setRoomToUpdate] = useState<RoomType | null>(null);

  const createRoom = async params => {
    const response = await api.post(listingRoomsCreate(listingId), params);

    if (response.ok) {
      fetchListing();
      return notification.info({ message: t('listing.rooms.form.updateSuccess') });
    }
    return notification.error({ message: t('listing.rooms.form.updateFailure') });
  };

  const updateRoom = async params => {
    const response = await api.put(listingRoomsUpdate(listingId, roomToUpdate?.id), params);

    setRoomToUpdate(null);

    if (response.ok) {
      fetchListing();
      return notification.info({ message: t('listing.rooms.form.updateSuccess') });
    }
    return notification.error({ message: t('listing.rooms.form.updateFailure') });
  };

  const deleteRoom = async id => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('listing.rooms.form.deleteConfirmation'))) {
      closeModalWithoutRoom();
      const resp = await api.delete(routes.api.admin.listingRoomsDelete(listingId, id));
      if (resp.ok) {
        fetchListing();
        notification.info({ message: t('listing.rooms.form.deleteSuccess') });
      } else {
        notification.error({ message: t('listing.rooms.form.deleteFailure') });
      }
    }
  };

  const openUpdateModal = room => {
    setRoomToUpdate(room);
    openModal();
  };

  const closeModalWithoutRoom = async () => {
    await closeModal();
    // During the fade-out transition on closeModal, the user sees the form update when state is
    // cleared. The setTimeout delays the clearing of state long enough for the modal to fully close
    setTimeout(() => setRoomToUpdate(null), 50);
  };

  return (
    <div>
      <div className={css.section}>
        <div className={cn(Typography.subtitle, css.header)}>{header}</div>
        <div className={css.button}>
          <Button type="secondary" data-testid="create-area-button" onClick={openModal}>
            <CustomIcon type="plus" className={css.plusIcon} />
            {t(`listing.rooms.${roomType}`)}
          </Button>
        </div>
      </div>
      {rooms.map(room => {
        return (
          <div
            role="button"
            key={room.id}
            className={css.room}
            onClick={() => openUpdateModal(room)}
          >
            <Room room={room} />
          </div>
        );
      })}
      <Divider />
      <Modal
        className={css.listingRoomFormModal}
        isOpen={isModalOpen}
        closeModal={() => closeModalWithoutRoom()}
        maskClosable={false}
      >
        <ListingRoomForm
          room={roomToUpdate}
          roomType={roomType}
          onSubmit={params => {
            const createOrUpdate = roomToUpdate ? updateRoom : createRoom;
            createOrUpdate(params);
            closeModal();
          }}
          onDelete={deleteRoom}
        />
      </Modal>
    </div>
  );
};

export default ListingRoomSection;
