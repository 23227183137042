import { useEffect } from 'react';
import Container from '@components/shared/Admin/Container';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbs } from '@components/shared/withBreadcrumbs';
import Form from '../Form';
import Layout from '../../Layout/Layout';

const CreatePage = () => {
  const { t } = useTranslation('admin');

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      t('breadcrumbs.admin'),
      t('breadcrumbs.companies'),
      t('breadcrumbs.companyCreate'),
    ]);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Container title={t('companies.createPageTitle')}>
        <Form submitText={t('common.createButton')} />
      </Container>
    </Layout>
  );
};

export default CreatePage;
