export default function copyToClipboard({
  text,
  onSuccess = () => {},
}: {
  text: string;
  onSuccess?: () => void;
}) {
  const link = document.createElement('textarea');

  document.body.appendChild(link);
  link.textContent = text;
  link.select();
  document.execCommand('copy');
  document.body.removeChild(link);
  onSuccess();
}
