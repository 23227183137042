import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import style from '../common.module.less';
import MarketingMediaForm from '../MarketingMediaForm';
import MarketingMediaHeader from '../MarketingMediaHeader/MarketingMediaHeader';
import { BuildingMarketingMedia } from '../types';
import { deleteMedia, updateOrder } from '../helper';

const BuildingFact = ({ data }: { data: BuildingMarketingMedia[] }) => {
  const { t } = useTranslation('admin');
  const [collapseMedia, setCollapseMedia] = useState(true);

  const showCancelUploadMediaButton =
    !data[0].title.length && !data[0].description.length && data.length === 1;
  const [draggedItem, setDraggedItem] = useState<BuildingMarketingMedia | undefined>(undefined);
  const [, setDragOverIndex] = useState<number | undefined>(undefined);

  const { setValues, values } = useFormikContext();

  const sortedBuildingFact: BuildingMarketingMedia[] = (values as FormikValues)?.buildingFact.sort(
    (a, b) => a.order - b.order,
  );

  const currentMediaLength = data.length;

  const addNewForm = () => {
    const newMedia = {
      mediaType: 'building_fact',
      title: '',
      description: '',
      order: sortedBuildingFact[sortedBuildingFact.length - 1].order + 1,
    };

    const updatedFormikValues = {
      ...((values as FormikValues) || {}),
      buildingFact: [...((values as FormikValues)?.buildingFact || []), newMedia],
    };

    setValues(updatedFormikValues);
  };

  const disableButton = () => {
    return !(currentMediaLength === 12 || !data[data.length - 1].title.length);
  };

  const onDelete = async (photoOrder: number) => {
    const deleteMessage = t('marketingMedia.buildingFact.deleteSuccessMessage');
    const updatedFormikValues = await deleteMedia(
      values,
      'buildingFact',
      photoOrder,
      deleteMessage,
      'building_fact',
    );

    setValues(updatedFormikValues);
  };

  const onDragStart = (item: BuildingMarketingMedia) => {
    setDraggedItem(item);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    setDragOverIndex(index);
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    if (!draggedItem) return;
    const updatedArray = await updateOrder(
      (values as FormikValues)?.buildingFact || [],
      draggedItem,
      index,
    );

    setValues({
      ...((values as FormikValues) || {}),
      buildingFact: updatedArray,
    });
    setDraggedItem(undefined);
    setDragOverIndex(undefined);
  };

  return (
    <section className={style.mainSection}>
      <MarketingMediaHeader
        collapseMedia={collapseMedia}
        mediaTitle={t('marketingMedia.buildingFact.title')}
        mediaLimit={t('marketingMedia.buildingFact.maxCount')}
        setCollapseMedia={setCollapseMedia}
      />
      {collapseMedia && (
        <MarketingMediaForm
          data={data}
          showCancelUploadMediaButton={showCancelUploadMediaButton}
          onDelete={onDelete}
          addNewForm={addNewForm}
          disableButton={disableButton()}
          identifier="buildingFact"
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragStart={onDragStart}
          showMediaUploader={false}
        />
      )}
    </section>
  );
};

export default BuildingFact;
