import Pptxgen from 'pptxgenjs';
import { Cloudinary } from 'cloudinary-core';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { TFunction } from 'i18next';
import { Locale, ValidSubwayLine } from '@root/types';

export const FONT_FACE = 'Helvetica';

export const baseTypography = {
  color: '000000',
  fontFace: FONT_FACE,
};

export const footerSmallText = {
  ...baseTypography,
  fontSize: 8,
  // lineSpacingMultiple: 0.9,
};

export const smallText = {
  ...baseTypography,
  fontSize: 8,
  lineSpacingMultiple: 1.2,
};

export const h1 = {
  ...baseTypography,
  fontSize: 24,
  bold: true,
  lineSpacingMultiple: 1.2,
};

export const h2 = {
  ...baseTypography,
  fontSize: 14,
};

export const h3 = {
  ...h1,
  fontSize: 12,
};

export const p = {
  ...baseTypography,
  fontSize: 10,
};

export const entityDetailsHeadingText = (
  overrides: PptxGenJS.default.TextPropsOptions = {},
): PptxGenJS.default.TextPropsOptions => ({
  color: '000000',
  fontSize: 7,
  bold: true,
  fontFace: FONT_FACE,
  lineSpacingMultiple: 1.5,
  ...overrides,
});

// these are uploaded directly to cloudinary via the console
export const staticImagesPathsInCloudinary = {
  minMaxArea: 'https://media.truva.com/assets/flexible-size-area',
  buildingTemplate: 'https://media.truva.com/h_100,w_100/r_15/assets/building-template',
  firstToMarket: 'https://media.truva.com/assets/first-to-market',
} as const;

// these images for the lines were uploaded directly to cloudinary via the console
export const getSubwayLineImageFromStaticPathInCloudinary = (line: ValidSubwayLine): string =>
  `https://media.truva.com/assets/subway_lines/new_york_city/${line.toLowerCase()}.png`;

export type SlideImage = {
  x: number;
  y: number;
  w: number;
  h: number;
  path: string;
};

export type PptxUtils = {
  pptx: Pptxgen;
  googleMapsKey: string;
  cloudinary: Cloudinary;
  vtsCloudinary: Cloudinary;
  toImageUrl: (cloudinaryId: string, transformations?: any) => string;
  t: TFunction;
  flags: LDFlagSet;
  railsEnv: 'development' | 'test' | 'production';
  locale: Locale;
};

export type PowerpointSlide = {
  elements: PowerpointElement[];
};

export type PowerpointElement =
  | PowerpointTextElement
  | PowerpointImageElement
  | PowerpointShapeElement
  | PowerpointSlideNumberElement
  | PowerpointTableElement;

export type SlideSection = PowerpointElement[];

export type PowerpointTextElementProps = {
  data: string | null;
  textOptions?: PptxGenJS.default.TextPropsOptions;
};

export type PowerpointTextElement = {
  type: 'textBox';
  data: string | null | PowerpointTextElementProps[];
  placement?: PptxGenJS.default.PositionProps & {
    valign?: 'top' | 'middle' | 'bottom';
    margin?: number;
  };
  textOptions?: PptxGenJS.default.TextPropsOptions;
};

export type PowerpointImageElement = {
  type: 'image';
  placement: PptxGenJS.default.PositionProps;
  data: string;
  imageOptions?: PptxGenJS.default.ImageProps;
};

export type PowerpointShapeElement = {
  type: 'shape';
  placement: PptxGenJS.default.PositionProps;
  shapeType: PptxGenJS.default.ShapeType;
  shapeOptions: PptxGenJS.default.ShapeProps;
};

export type PowerpointSlideNumberElement = {
  type: 'slideNumber';
  placement: PptxGenJS.default.PositionProps;
  textOptions?: PptxGenJS.default.TextPropsOptions;
};

export type PowerpointTableElement = {
  type: 'table';
  placement: PptxGenJS.default.PositionProps;
  tableOptions: PptxGenJS.default.TableProps;
  data: PptxGenJS.default.TableRow[];
};
