import { PowerpointBuildingEntry, PowerpointTourbook } from '@root/types';
import { round } from 'lodash';
import { generateAmenitiesElements } from './amenities';
import { generateBuildingHeaderElements } from './buildingHeader';
import {
  entityDetailsHeadingText,
  FONT_FACE,
  footerSmallText,
  getSubwayLineImageFromStaticPathInCloudinary,
  h3,
  PowerpointElement,
  PptxUtils,
  SlideSection,
} from './globals';
import { buildBuildingMapUrl } from './map';
import { generateFooterElements, PAGE_MARGIN } from './portrait';
import {
  addElementsToSlide,
  generateElementsFromSections,
  makeElementsRelative,
  pipe,
} from './util';

export const generateMapBodyElements = (
  building: PowerpointBuildingEntry,
  { pptx, googleMapsKey }: PptxUtils,
): PowerpointElement[] => {
  const mapHeight = 1.86;
  const mapWidth = 6.79;
  const elements: PowerpointElement[] = [];

  elements.push({
    type: 'image',
    placement: {
      x: PAGE_MARGIN,
      y: 3.33,
      w: mapWidth,
      h: mapHeight,
    },
    data: buildBuildingMapUrl(building, googleMapsKey, mapHeight, mapWidth),
  });

  elements.push({
    type: 'shape',
    shapeType: pptx.ShapeType.rect,
    placement: {
      x: PAGE_MARGIN,
      y: 3.33,
      w: mapWidth,
      h: mapHeight,
    },
    shapeOptions: {
      line: { dashType: 'solid', color: 'BFBFBF' },
    },
  });

  elements.push({
    type: 'textBox',
    data: [
      {
        data: `${building.street}`,
        textOptions: {
          color: '000000',
          fontSize: 8,
          fontFace: FONT_FACE,
        },
      },
      pipe(),
      {
        data: `${building.cityLocalityRegion}`,
        textOptions: { color: '000000', fontSize: 8, fontFace: FONT_FACE },
      },
    ],
    placement: { x: PAGE_MARGIN, y: 5.26, w: 6, h: 0.24, margin: 0 },
  });

  return elements;
};

export const generateLocationInformationElements = (
  building: PowerpointBuildingEntry,
  { t }: PptxUtils,
): PowerpointElement[] => {
  const elements: PowerpointElement[] = [];
  if (building.transitOptions.length > 0) {
    elements.push({
      type: 'textBox',
      data: [
        {
          data: t('tourbook:powerpoint.transportation'),
          textOptions: { ...h3 },
        },
      ],
      placement: { x: 0.27, y: 5.75, h: 0.3, w: 2.56 },
    });

    elements.push({
      type: 'textBox',
      data: [
        {
          data: t('tourbook:powerpoint.nearbyTrains').toUpperCase(),
          textOptions: entityDetailsHeadingText(),
        },
      ],
      placement: { x: 0.27, y: 6.15, h: 0.22, w: 1.28 },
    });

    const startingPoints = [
      { x: 0.38, y: 6.55 },
      { x: 4.1, y: 6.55 },
      { x: 0.38, y: 6.92 },
      { x: 4.1, y: 6.92 },
      { x: 0.38, y: 7.26 },
      { x: 4.1, y: 7.26 },
      { x: 0.38, y: 7.63 },
      { x: 4.1, y: 7.63 },
    ];
    building.transitOptions.forEach((option, index) => {
      const { x, y } = startingPoints[index];

      // Put the images for each line
      option.lines.forEach((line, lineIndex) => {
        const imagePath = getSubwayLineImageFromStaticPathInCloudinary(line);
        elements.push({
          type: 'image',
          data: imagePath,
          placement: { x: x + 0.24 * lineIndex, y, w: 0.16, h: 0.16 },
        });
      });

      const textStartingX = x + option.lines.length * 0.24 - 0.11;
      elements.push({
        type: 'textBox',
        data: [
          {
            data: t('tourbook:powerpoint.distance', {
              magnitude: round(option.distanceInMiles, 2).toString().slice(1),
            }),
            textOptions: { ...footerSmallText },
          },
          {
            data: t('tourbook:powerpoint.fromStop', { stop: option.stopName }),
            textOptions: { ...footerSmallText, color: '737373' },
          },
        ],
        placement: { x: textStartingX, y, h: 0.18, w: 2.95 },
      });
    });
  }

  return elements;
};

export const generateBuildingPageTwoSlide = ({
  tourbook,
  building,
  utils,
}: {
  tourbook: PowerpointTourbook;
  building: PowerpointBuildingEntry;
  utils: PptxUtils;
}) => {
  const { pptx } = utils;
  const mapSlide = pptx.addSlide();

  const staticElements: PowerpointElement[] = [
    ...generateBuildingHeaderElements(building, utils),
    ...generateFooterElements(tourbook, utils),
  ];

  const relativeElements: SlideSection[] = [
    generateAmenitiesElements(
      building.amenities,
      {
        model: 'Building',
        y: 1.04,
      },
      utils,
    ),
    generateMapBodyElements(building, utils),
    generateLocationInformationElements(building, utils),
  ];
  const computedRelativeElements = generateElementsFromSections(
    relativeElements.map(sections => sections.map(makeElementsRelative)),
    {
      x: 0.38,
      y: 1.04,
    },
  );

  const buildingPageTwoElements = [...staticElements, ...computedRelativeElements];
  addElementsToSlide(mapSlide, buildingPageTwoElements);
};
