import { PARAMETERS, EVENTS, EVENT_TYPES } from '@root/tracking/constants';
import { LeaseTypes, Possession, SpaceCondition } from '@root/types';

type ListingSearchPageLoadedAction = {
  type: 'LISTING_SEARCH_PAGE_LOADED';
  payload: {
    meta: {
      analytics: {
        eventType: 'page';
        event: 'search results page';
        skipPageInfo: true;
        pageNumber: number | null;
        resultsCount: number | null;
      };
    };
  };
};
const listingSearchPageLoaded = ({ pageNumber, resultsCount }): ListingSearchPageLoadedAction => ({
  type: 'LISTING_SEARCH_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.searchResultsPage,
        skipPageInfo: true,
        pageNumber,
        resultsCount,
      },
    },
  },
});

/* eslint-disable camelcase */
type SortMap = {
  newest: 'Newest';
  largest: 'Largest';
  smallest: 'Smallest';
  available_soonest: 'Available Soonest';
  available_latest: 'Available Latest';
};
/* eslint-enable camelcase */
type SortChangedAction = {
  type: 'SORT_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Sort Interaction';
        sourcePage: 'search results page';
        sortValue: SortMap[keyof SortMap];
      };
    };
  };
};

const sortChanged = (sortKey: keyof SortMap): SortChangedAction => {
  const sortTypeToValueMap: SortMap = {
    newest: 'Newest',
    largest: 'Largest',
    smallest: 'Smallest',
    available_soonest: 'Available Soonest',
    available_latest: 'Available Latest',
  };
  return {
    type: 'SORT_CHANGED',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.sortInteraction,
          sourcePage: PARAMETERS.searchResultsPage,
          sortValue: sortTypeToValueMap[sortKey],
        },
      },
    },
  };
};

type FilterFirstToMarketChangedAction = {
  type: 'FIRST_TO_MARKET_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'first to market';
        filterFirstToMarketListings: boolean;
      };
    };
  };
};
const filterFirstToMarketChanged = (filtering: boolean): FilterFirstToMarketChangedAction => ({
  type: 'FIRST_TO_MARKET_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.firstToMarket,
        filterFirstToMarketListings: filtering,
      },
    },
  },
});

type SpaceConditionFilterChangedAction = {
  type: 'SPACE_CONDITION_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'space condition';
        filterSpaceCondition: string[];
      };
    };
  };
};
const spaceConditionFilterChanged = (
  filter: SpaceCondition[],
): SpaceConditionFilterChangedAction => ({
  type: 'SPACE_CONDITION_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.spaceCondition,
        filterSpaceCondition: filter,
      },
    },
  },
});

type TermsFilterChangedAction = {
  type: 'TERMS_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'terms';
        filterTerms: string[];
      };
    };
  };
};
const termsFilterChanged = (filter: string[]): TermsFilterChangedAction => ({
  type: 'TERMS_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.terms,
        filterTerms: filter,
      },
    },
  },
});

type PriceFilterChangedAction = {
  type: 'PRICE_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'price';
        minPrice: string;
        maxPrice: string;
        filterIncludeNegotiable: boolean;
      };
    };
  };
};

const priceFilterChanged = (
  minPrice: string,
  maxPrice: string,
  filterIncludeNegotiable: boolean,
): PriceFilterChangedAction => ({
  type: 'PRICE_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.price,
        minPrice,
        maxPrice,
        filterIncludeNegotiable,
      },
    },
  },
});

type PriceFilterChangedActionDeprecated = {
  type: 'PRICE_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'price';
        filterPrice: string;
        filterIncludeNegotiable: boolean;
      };
    };
  };
};
const priceFilterChangedDeprecated = (
  filterPrice: string,
  filterIncludeNegotiable: boolean,
): PriceFilterChangedActionDeprecated => ({
  type: 'PRICE_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.price,
        filterPrice,
        filterIncludeNegotiable,
      },
    },
  },
});

type CeilingHeightFilterChangedAction = {
  type: 'CEILING_HEIGHT_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'ceiling height';
        filterCeilingHeight: string;
      };
    };
  };
};
const ceilingHeightFilterChanged = (
  filterCeilingHeight: string,
): CeilingHeightFilterChangedAction => ({
  type: 'CEILING_HEIGHT_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.ceilingHeight,
        filterCeilingHeight,
      },
    },
  },
});

type LeaseTypeFilterChangedAction = {
  type: 'LEASE_TYPE_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'lease type';
        filterLeaseType: string[];
      };
    };
  };
};
const leaseTypeFilterChanged = (filter: LeaseTypes[]): LeaseTypeFilterChangedAction => ({
  type: 'LEASE_TYPE_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.leaseType,
        filterLeaseType: filter,
      },
    },
  },
});

type PossessionFilterChangedAction = {
  type: 'POSSESSION_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'possession';
        filterPossession: Possession | null;
      };
    };
  };
};
const possessionFilterChanged = (filter: Possession | null): PossessionFilterChangedAction => ({
  type: 'POSSESSION_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.possession,
        filterPossession: filter,
      },
    },
  },
});

type SizeFilterChangedActionDeprecated = {
  type: 'SIZE_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'size';
        filterSize: string;
      };
    };
  };
};

type SizeFilterChangedAction = {
  type: 'SIZE_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'size';
        minSize: string;
        maxSize: string;
      };
    };
  };
};

const sizeFilterChanged = (minSize: string, maxSize: string): SizeFilterChangedAction => ({
  type: 'SIZE_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.size,
        minSize,
        maxSize,
      },
    },
  },
});

const sizeFilterChangedDeprecated = (filterSize: string): SizeFilterChangedActionDeprecated => ({
  type: 'SIZE_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.size,
        filterSize,
      },
    },
  },
});

type KeywordsFilterChangedAction = {
  type: 'KEYWORDS_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'keywords';
        filterKeywords: string[];
      };
    };
  };
};

const keywordsFilterChanged = (keywords: string[]): KeywordsFilterChangedAction => ({
  type: 'KEYWORDS_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.keywords,
        filterKeywords: keywords,
      },
    },
  },
});

type KeywordsFilterChangedActionDeprecated = {
  type: 'KEYWORDS_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'keywords';
        filterKeywords: string;
      };
    };
  };
};
const keywordsFilterChangedDeprecated = (
  keywords: string,
): KeywordsFilterChangedActionDeprecated => ({
  type: 'KEYWORDS_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.keywords,
        filterKeywords: keywords,
      },
    },
  },
});

type AmenitiesFilterChangedAction = {
  type: 'AMENITIES_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'amenities';
        filterAmenities: string[];
      };
    };
  };
};
const amenitiesFilterChanged = (selectedAmenities: string[]): AmenitiesFilterChangedAction => ({
  type: 'AMENITIES_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.amenities,
        filterAmenities: selectedAmenities,
      },
    },
  },
});

type BuildingsFilterChangedAction = {
  type: 'BUILDINGS_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterOption: 'buildings';
        filterBuildings: Array<{ id: string; displayName: string }>;
      };
    };
  };
};

const buildingsFilterChanged = (
  selectedBuildings: Array<{ id: string; displayName: string }>,
): BuildingsFilterChangedAction => ({
  type: 'BUILDINGS_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterOption: PARAMETERS.buildings,
        filterBuildings: selectedBuildings,
      },
    },
  },
});

type LandlordFilterChangedAction = {
  type: 'LANDLORD_FILTER_CHANGED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        filterLandlords: Array<{ name: string }>;
        filterOption: 'landlord';
      };
    };
  };
};

const landlordFilterChanged = (
  selectedLandlords: Array<{ id: string; name: string }>,
): LandlordFilterChangedAction => ({
  type: 'LANDLORD_FILTER_CHANGED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterLandlords: selectedLandlords,
        filterOption: PARAMETERS.landlord,
      },
    },
  },
});

type OpenFilterAction = {
  type: 'OPEN_FILTER';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'open filter';
        sourcePage: 'search results page';
        filterName: string;
      };
    };
  };
};
const openFilter = (filterName: string): OpenFilterAction => ({
  type: 'OPEN_FILTER',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.openFilter,
        sourcePage: PARAMETERS.searchResultsPage,
        filterName,
      },
    },
  },
});

type AllFiltersOpenedAction = {
  type: 'ALL_FILTERS_OPENED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'open all filters';
      };
    };
  };
};
const allFiltersOpened = (): AllFiltersOpenedAction => ({
  type: 'ALL_FILTERS_OPENED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.openAllFilters,
      },
    },
  },
});

type AllFiltersClosedAction = {
  type: 'ALL_FILTERS_CLOSED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'close filters';
      };
    };
  };
};
const allFiltersClosed = (): AllFiltersClosedAction => ({
  type: 'ALL_FILTERS_CLOSED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.closeAllFilters,
      },
    },
  },
});

type AllFiltersClearedAction = {
  type: 'ALL_FILTERS_CLEARED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'clear all filters';
      };
    };
  };
};
const allFiltersCleared = (): AllFiltersClearedAction => ({
  type: 'ALL_FILTERS_CLEARED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.clearAllFilters,
      },
    },
  },
});
// MAP ACTIONS

type MapBoundaryDrawingStartedAction = {
  type: 'MAP_BOUNDARY_DRAWING_STARTED';
  payload: {
    meta: {
      analytics: {
        action: 'start polygon';
        eventType: 'track';
        event: 'Map Interaction';
        sourcePage: 'search results page';
        sourceContent: 'map';
      };
    };
  };
};
const mapBoundaryDrawingStarted = (): MapBoundaryDrawingStartedAction => ({
  type: 'MAP_BOUNDARY_DRAWING_STARTED',
  payload: {
    meta: {
      analytics: {
        action: PARAMETERS.startPolygon,
        eventType: EVENT_TYPES.track,
        event: EVENTS.mapInteraction,
        sourcePage: PARAMETERS.searchResultsPage,
        sourceContent: PARAMETERS.map,
      },
    },
  },
});

type MapBoundaryDrawingFinishedAction = {
  type: 'MAP_BOUNDARY_DRAWING_FINISHED';
  payload: {
    meta: {
      analytics: {
        action: 'finish polygon';
        eventType: 'track';
        event: 'Map Interaction';
        sourcePage: 'search results page';
        sourceContent: 'map';
      };
    };
  };
};
const mapBoundaryDrawingFinished = (): MapBoundaryDrawingFinishedAction => ({
  type: 'MAP_BOUNDARY_DRAWING_FINISHED',
  payload: {
    meta: {
      analytics: {
        action: PARAMETERS.finishPolygon,
        eventType: EVENT_TYPES.track,
        event: EVENTS.mapInteraction,
        sourcePage: PARAMETERS.searchResultsPage,
        sourceContent: PARAMETERS.map,
      },
    },
  },
});

type MapCustomAreaSearchedAction = {
  type: 'MAP_SEARCHED_IN_CUSTOM_AREA';
  payload: {
    meta: {
      analytics: {
        action: 'custom area search';
        eventType: 'track';
        event: 'Map Interaction';
        sourcePage: 'search results page';
        sourceContent: 'map';
      };
    };
  };
};
const mapCustomAreaSearched = (): MapCustomAreaSearchedAction => ({
  type: 'MAP_SEARCHED_IN_CUSTOM_AREA',
  payload: {
    meta: {
      analytics: {
        action: PARAMETERS.customAreaSearch,
        eventType: EVENT_TYPES.track,
        event: EVENTS.mapInteraction,
        sourcePage: PARAMETERS.searchResultsPage,
        sourceContent: PARAMETERS.map,
      },
    },
  },
});

type savedListingNotificationLinkClickedAction = {
  type: 'NOTIFICATION_SAVED_LISTING_LINK_CLICKED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourceContent: 'toast';
        destination: 'saved listings page';
      };
    };
  };
};
const savedListingNotificationLinkClicked = (): savedListingNotificationLinkClickedAction => ({
  type: 'NOTIFICATION_SAVED_LISTING_LINK_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.toast,
        destination: PARAMETERS.savedListingsPage,
      },
    },
  },
});

type savedSearchNotificationLinkClickedAction = {
  type: 'NOTIFICATION_SAVED_SEARCH_LINK_CLICKED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourceContent: 'toast';
        destination: 'saved searches page';
      };
    };
  };
};
const savedSearchNotificationLinkClicked = (): savedSearchNotificationLinkClickedAction => ({
  type: 'NOTIFICATION_SAVED_SEARCH_LINK_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.toast,
        destination: PARAMETERS.savedSearchPage,
      },
    },
  },
});

export default {
  listingSearchPageLoaded,
  sortChanged,
  filterFirstToMarketChanged,
  spaceConditionFilterChanged,
  termsFilterChanged,
  priceFilterChanged,
  priceFilterChangedDeprecated,
  ceilingHeightFilterChanged,
  leaseTypeFilterChanged,
  possessionFilterChanged,
  sizeFilterChanged,
  sizeFilterChangedDeprecated,
  openFilter,
  keywordsFilterChanged,
  keywordsFilterChangedDeprecated,
  amenitiesFilterChanged,
  buildingsFilterChanged,
  landlordFilterChanged,
  mapBoundaryDrawingStarted,
  mapBoundaryDrawingFinished,
  mapCustomAreaSearched,
  allFiltersOpened,
  allFiltersClosed,
  allFiltersCleared,
  savedListingNotificationLinkClicked,
  savedSearchNotificationLinkClicked,
};

export type Actions =
  | SortChangedAction
  | ListingSearchPageLoadedAction
  | FilterFirstToMarketChangedAction
  | SpaceConditionFilterChangedAction
  | TermsFilterChangedAction
  | PriceFilterChangedAction
  | PriceFilterChangedActionDeprecated
  | CeilingHeightFilterChangedAction
  | LeaseTypeFilterChangedAction
  | PossessionFilterChangedAction
  | SizeFilterChangedAction
  | SizeFilterChangedActionDeprecated
  | OpenFilterAction
  | KeywordsFilterChangedAction
  | KeywordsFilterChangedActionDeprecated
  | AmenitiesFilterChangedAction
  | BuildingsFilterChangedAction
  | LandlordFilterChangedAction
  | MapBoundaryDrawingStartedAction
  | MapBoundaryDrawingFinishedAction
  | MapCustomAreaSearchedAction
  | AllFiltersClosedAction
  | AllFiltersOpenedAction
  | AllFiltersClearedAction
  | savedListingNotificationLinkClickedAction
  | savedSearchNotificationLinkClickedAction;
