import { Address, Market } from '@root/types';
import routes from '@root/routes';
import { stringify } from 'qs';
import { TFunction } from 'i18next';

type Region = {
  region: string;
  abbreviation: string;
};

type Listing = {
  address: Address;
  buildingSlug: string;
  id: string;
  name: string;
};

type TopSubmarket = {
  slug: string;
  name: string;
  market: string;
};

type TopBuilding = {
  slug: string;
  address: Address;
};

export type InternalLink = {
  id: string;
  url: string;
  name: string;
};

export type InternalLinksType = {
  markets: Market[];
  newListings: Listing[];
  browseListings: Region[];
  topSubmarkets: TopSubmarket[];
  topBuildings: TopBuilding[];
};

export const topSubmarketToInternalLink = (
  topSubmarket: TopSubmarket,
  t: TFunction,
): InternalLink => ({
  id: topSubmarket.slug,
  name: `${topSubmarket.name}, ${t(`common:marketSelector.${topSubmarket.market}`)}`,
  url: `${routes.search(topSubmarket.market)}?${stringify({ submarkets: topSubmarket.slug })}`,
});

export const topBuildingToInternalLink = (
  { address, slug }: TopBuilding,
  t: TFunction,
): InternalLink => ({
  id: slug,
  name: t('internalLinks.topBuilding', { address, city: address.city }),
  url: routes.building(slug),
});
