import { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { notification, Col, Input, Row } from 'antd';
import Container from '@components/shared/Admin/Container';
import Button from '@components/shared/V2Button';
import { flow } from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Spinner from '@components/shared/Spinner';
import routes from '@root/routes';
import api from '@shared/api';
import actions from '@store/actions';
import { History } from 'history';
import s from '../PropertyPage/PropertyPage.module.less';
import Layout from '../Layout/Layout';

const {
  api: {
    admin: { vtsProperty: apiPropertyRoute },
  },
  admin: { vtsProperty: propertyRoute },
} = routes;

type Props = {
  history: History;
  displayBreadcrumbs: () => void;
  removeBreadcrumbs: () => void;
  t: any;
  form: any;
};

type State = {};

class RawPropertySearch extends Component<Props, State> {
  state = {
    searching: false,
  };

  componentDidMount() {
    const { displayBreadcrumbs } = this.props;
    displayBreadcrumbs();
  }

  componentWillUnmount() {
    const { removeBreadcrumbs } = this.props;
    removeBreadcrumbs();
  }

  goToProperty = async e => {
    e.preventDefault();
    const { history, form } = this.props;
    const vtsPropertyId = form.getFieldValue('query');

    try {
      this.setState({ searching: true });
      const res = await api.fetch(apiPropertyRoute(vtsPropertyId));
      const { id: propertyId } = await res.json();

      history.push(propertyRoute(propertyId));
    } catch {
      const { t } = this.props;
      notification.error({ message: t('property.searchError', { vtsPropertyId }) });
      this.setState({ searching: false });
    }
  };

  render() {
    const {
      form: { getFieldDecorator },
      t,
    } = this.props;

    const { searching } = this.state;

    return (
      <Layout>
        <Container>
          <Form data-testid="form" onSubmit={this.goToProperty} layout="inline">
            <Row className={s['form-row']}>
              <Col>
                <Form.Item label={t('property.searchLabel')}>
                  {getFieldDecorator('query')(
                    <Input
                      placeholder={t('property.searchPlaceholder')}
                      style={{ width: 200 }}
                      name="property-search"
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row className={s['form-row']}>
              <Col>
                {searching ? (
                  <Spinner />
                ) : (
                  <Button type="primary" htmlType="submit">
                    {t('property.searchButton')}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Container>
      </Layout>
    );
  }
}

const PropertySearchWithForm = Form.create({ name: 'vts-property-search' })(RawPropertySearch);

const EnhancedPropertySearch = flow(
  withTranslation('admin'),
  connect(
    () => ({}),
    dispatch => ({
      displayBreadcrumbs() {
        dispatch(
          actions.addBreadcrumbs([
            'Admin',
            { content: 'Building', link: routes.admin.buildingSearch },
            '+ Building',
          ]),
        );
      },
      removeBreadcrumbs() {
        dispatch(actions.removeBreadcrumbs());
      },
    }),
  ),
)(PropertySearchWithForm);

export { RawPropertySearch, PropertySearchWithForm };
export default EnhancedPropertySearch;
