import { forwardRef, useEffect, useState } from 'react';
import { Tourbook } from '@root/types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import EditTourbookModal from '@components/shared/EditTourbookModal';
import { isInIframe } from '@root/shared/iframeUtils';
import { InvisibleMask, Notification } from '@components/shared';
import COLORS from '@root/shared/colorConstants';
import { useMutation } from '@tanstack/react-query';
import { update } from '@root/shared/typedApi';
import routes from '@root/routes';
import useCurrentUser from '@root/shared/useCurrentUser';
import colorsInImage from '@root/shared/imageColorExtractor/imageColorExtractor';
import { ColorGroup } from '@components/shared/ColorPicker/ColorPickerColorGroup';
import ColorPickerSidePanel, { useColorPickerSidePanel } from './ColorPickerSidePanel';
import BasicHeader from './BasicHeader';

type Props = {
  tourbook: Tourbook;
  readOnly: boolean;
  onCloseModal: () => void;
  isModalOpen: boolean;
  hidden: boolean;
};
const Header = forwardRef<HTMLDivElement | null, Props>(
  ({ tourbook, readOnly, onCloseModal, isModalOpen, hidden }: Props, ref) => {
    const { t } = useTranslation('tourbook');
    const { currentUser } = useCurrentUser();

    const containsDescriptionBodyElements = tbook =>
      tbook.name && (tbook.description || tbook.clientName || tbook.clientLogoImage);

    const updateTourbookColorsMutation = useMutation(async (colors: { [key: string]: string }) =>
      update(routes.api.tourbook(tourbook.id), { colors }),
    );

    const [isCustomizingColors, setIsCustomizingColors] = useState(false);

    const clientLogo = tourbook?.clientLogoImage;
    const [clientLogoColors, setClientLogoColors] = useState<string[]>([]);

    const companyLogo = currentUser?.tourbookCompanyImage;
    const [companyLogoColors, setCompanyLogoColors] = useState<string[]>([]);

    useEffect(() => {
      if (clientLogo?.path) {
        colorsInImage({ imgSource: clientLogo.path }).then(colors =>
          setClientLogoColors(colors.slice(0, 7)),
        );
      }

      if (companyLogo?.path) {
        colorsInImage({ imgSource: companyLogo.path }).then(colors =>
          setCompanyLogoColors(colors.slice(0, 7)),
        );
      }
    }, [clientLogo, companyLogo]);

    const colorGroups: ColorGroup[] = [];

    if (clientLogo?.path)
      colorGroups.push({
        title: t('header.clientLogoColors'),
        colors: clientLogoColors,
        imgPath: clientLogo?.smallPath,
        altText: clientLogo?.description,
      });

    if (companyLogo?.path)
      colorGroups.push({
        title: t('header.companyLogoColors'),
        colors: companyLogoColors,
        imgPath: companyLogo?.smallPath,
        altText: companyLogo?.description,
      });

    const { colorModules, colorPickerSidePanelProps } = useColorPickerSidePanel({
      isOpen: isCustomizingColors,
      onClose: () => setIsCustomizingColors(false),
      onSave: newColorModules => {
        updateTourbookColorsMutation.mutate({
          header_color_1: newColorModules[0].color,
          header_color_2: newColorModules[1].color,
        });
        setIsCustomizingColors(false);
        Notification.info({
          title: t('colorPickerSidePanel.savedSuccessfully'),
        });
      },
      colorGroups,
      initialColorModules: [
        {
          title: t('colorPickerSidePanel.titleDescriptionBackground'),
          color: tourbook.colors?.headerColor1,
          defaultColor: COLORS.white,
        },
        {
          title: t('colorPickerSidePanel.contactInfoBackground'),
          color: tourbook.colors?.headerColor2,
          defaultColor: COLORS.indigo120,
        },
      ],
    });

    const renderHeader = ({ isPreview = false }: { isPreview?: boolean } = {}) => (
      <BasicHeader
        tourbook={tourbook}
        readOnly={readOnly}
        hidden={hidden}
        isCustomizingColors={isCustomizingColors}
        containsDescriptionBodyElements={containsDescriptionBodyElements(tourbook)}
        colorModules={colorModules}
        setIsCustomizingColors={setIsCustomizingColors}
        isPreview={isPreview}
      />
    );

    return (
      <>
        <div
          id="tourbook-header"
          className={cn('phone:m-0 tablet:m-0', isInIframe() && '!mt-3')}
          ref={ref}
        >
          {renderHeader()}
          <EditTourbookModal
            tourbook={tourbook}
            isModalOpen={isModalOpen}
            onCloseModal={onCloseModal}
          />
        </div>
        <ColorPickerSidePanel
          {...colorPickerSidePanelProps}
          previewElement={renderHeader({ isPreview: true })}
        />
        {isCustomizingColors && isInIframe() && (
          <InvisibleMask className="!bottom-[calc(100%_-_64px)] !z-[53] cursor-default !bg-black-100/50" />
        )}
      </>
    );
  },
);

export default Header;
