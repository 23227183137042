import { PageHeader } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { SortableList, SortableListItem } from '@components/shared/sortable';
import { CustomIcon } from '@components/shared';
import ListingAgentOption from '@components/shared/ListingAgentOption/ListingAgentOption';
import { AgentContact } from '@root/types';
import routes from '@root/routes';
import SafeRender from '@components/shared/SafeRender';
import s from './ListingContacts.module.less';

const EditableContacts = ({ data, onSort }) => {
  return (
    <SortableList onSort={onSort}>
      {data.map((datum, index) => (
        // @ts-ignore
        <SortableListItem
          className={cn({ [s.agentWrapper]: true, [s.topElement]: index === 0 })}
          sortableId={datum.sortableId}
          key={datum.sortableId}
        >
          <div>
            <ListingAgentOption displayAgentContact listingAgent={datum} />
          </div>
        </SortableListItem>
      ))}
    </SortableList>
  );
};

const StaticContacts = ({ data, buildingId }) => {
  const { t } = useTranslation('admin');

  return (
    <>
      <CustomIcon className={s.lockIcon} type="lock" />
      <SafeRender
        unsafeContent={t(`listing.contacts.uneditable`, {
          buildingContactsUrl: routes.admin.buildingContacts(buildingId),
        })}
      >
        <span />
      </SafeRender>
      <div className={s.disabled}>
        <ul>
          {data.map((datum, index) => (
            <li
              key={datum.id}
              className={cn({ [s.agentWrapper]: true, [s.topElement]: index === 0 })}
            >
              <div>
                <ListingAgentOption displayAgentContact listingAgent={datum} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

type Props = {
  data: Array<AgentContact>;
  editable: Boolean;
  onSort: Function;
  buildingId: string;
};

const ListingContacts = ({ data = [], editable = false, onSort, buildingId }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <div className={s.listingContacts}>
      <PageHeader className={s.listingContactsHeader} title={t(`listing.menuItems.contacts`)} />
      <p>{t('listing.contacts.howToChange')}</p>
      {editable ? (
        <EditableContacts data={data} onSort={onSort} />
      ) : (
        <StaticContacts data={data} buildingId={buildingId} />
      )}
    </div>
  );
};

export default ListingContacts;
