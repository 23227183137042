import { ReactNode } from 'react';
import routes from '@root/routes';
import { Pages } from '@root/types';
import { ControlledTabs } from '@components/shared';
import Layout from '../Layout';
import s from './Layout.module.less';

type Props = {
  children: ReactNode;
  active: 'searches' | 'listings' | 'buildings' | 'buildingOwners';
  mobileFullWidth?: boolean;
};

const Tabs = ({ children, active, mobileFullWidth }: Props) => {
  const tabs: Array<{ value: string; label: string; location: string }> = [
    { value: 'listings', label: 'Listings', location: routes.savedListings },
    { value: 'searches', label: 'Searches', location: routes.savedSearches },
    { value: 'buildings', label: 'Buildings', location: routes.savedBuildings },
    {
      value: 'buildingOwners',
      label: 'Building owners',
      location: routes.savedBuildingOwners,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const page = `saved${capitalizeFirstLetter(active)}` as Pages;

  return (
    <Layout mobileFullWidth={mobileFullWidth} activePage={page}>
      <div className={s.wrapper}>
        <ControlledTabs className={s.tabs} currentTab={active} tabs={tabs}>
          {children}
        </ControlledTabs>
      </div>
    </Layout>
  );
};

export default Tabs;

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
