import { useQuery } from '@tanstack/react-query';
import routes from '@root/routes';
import { Landlord as LandlordType } from '@root/types';
import { useParams } from 'react-router-dom';
import { get } from '@root/shared/typedApi';
import useMarketo from '@root/shared/useMarketo';
import LandlordLayout from './Layout';
import LandlordSkeleton from './LandlordSkeleton';
import NotFound from '../404/NotFound';
import Layout from '../Layout';
import usePageLoadAnalyticsEvent from './usePageLoadAnalyticsEvent';

export const LandlordPreview = () => {
  const { landlordId } = useParams<{ landlordId: string }>();
  const { hasLoadedOnce, onPageLoad } = usePageLoadAnalyticsEvent();

  const {
    data: previewLandlord,
    isLoading,
    error,
  } = useQuery<LandlordType>(
    [routes.api.landlordPreview(landlordId)],
    () => get(routes.api.landlordPreview(landlordId)),
    {
      onSuccess: async fetchedLandlord => {
        onPageLoad(fetchedLandlord, true);
        window.prerenderReady = true;
      },
    },
  );

  useMarketo();

  if (isLoading) {
    return (
      <Layout>
        <LandlordSkeleton />
      </Layout>
    );
  }

  if (error) {
    return <NotFound />;
  }
  return previewLandlord ? (
    <LandlordLayout landlord={previewLandlord} hasLoadedOnce={hasLoadedOnce} isPreview />
  ) : null;
};

export default LandlordPreview;
