const emailHref = (
  to: string,
  {
    subject,
    body,
  }: {
    subject?: string;
    body?: string;
  } = {},
): string => {
  const queryParams = new URLSearchParams();

  if (subject) queryParams.append('subject', subject);
  if (body) queryParams.append('body', body);

  const queryString = queryParams.toString();
  if (queryString) return `mailto:${to}?${queryString}`;

  return `mailto:${to}`;
};

export default emailHref;
