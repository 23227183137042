import { some } from 'lodash';
import { useState } from 'react';
import { Placement } from '@popperjs/core';
import { TourbookOverlay } from '@components/shared';
import TourbookIconButton from '@components/shared/TourbookIconButton';
import { BuildingListing, Listing, SearchListing } from '@root/types';
import useFetchTourbookSummaries from './useFetchTourbookSummaries';

type Arguments = {
  listing: SearchListing | Listing | BuildingListing;
  createOnly: boolean;
  useInvisibleMask: boolean;
  onToggleTourbookDialog?: (isOpen: boolean) => void;
  calculateCorrectedTop?: (height: number) => number | undefined;
};

const useTourbookIconButton = ({
  listing,
  createOnly,
  useInvisibleMask,
  onToggleTourbookDialog,
  calculateCorrectedTop,
}: Arguments) => {
  const [tourbookDialogIsOpen, setTourbookDialogIsOpen] = useState(false);
  const { data: tourbooks } = useFetchTourbookSummaries();

  const setTourbookDialogIsOpenWithCallback = (isOpen: boolean) => {
    onToggleTourbookDialog?.(isOpen);
    setTourbookDialogIsOpen(isOpen);
  };

  const tourbookButtonProps = {
    isInTourbook: some(tourbooks, tourbook => tourbook.listingIds.includes(listing.id)),
    showButtonTitle: false,
    toolTipPlacement: 'bottom' as Placement,
    setTourbookDialogIsOpen: setTourbookDialogIsOpenWithCallback,
    tourbookDialogIsOpen,
  };

  const tourbookOverlayProps = {
    isOpen: tourbookDialogIsOpen,
    close: () => setTourbookDialogIsOpenWithCallback(false),
    listing,
    createOnly,
    useInvisibleMask,
    calculateCorrectedTop,
  };

  return {
    TourbookIconButton,
    tourbookButtonProps,
    TourbookOverlay,
    tourbookOverlayProps,
    tourbookDialogIsOpen,
  };
};

export default useTourbookIconButton;
