import { useState, useEffect, useRef } from 'react';
import { AdminListingPhoto, Room } from '@root/types';
import { useTranslation } from 'react-i18next';
import api from '@shared/api';
import cn from 'classnames';
import { notification } from 'antd';
import routes from '@root/routes';
import CustomIcon from '@components/shared/CustomIcon';
import { Select } from '@components/antd';
import s from './AreaSelector.module.less';

type Props = {
  areas: Room[];
  listingId: string;
  photo: AdminListingPhoto;
  fetchListing: Function;
};

const AreaSelector = ({ areas, listingId, photo, fetchListing }: Props) => {
  const { t } = useTranslation('admin');
  const [roomId, setRoomId] = useState(photo.listingRoomId);
  const [showSuccessCheckmark, setShowSuccessCheckmark] = useState(false);
  const didMountRef = useRef(false);

  const assignPhotoToArea = async () => {
    const resp = await api.put(routes.api.admin.listingPhoto(listingId, photo.id), {
      room_id: roomId,
    });

    if (resp.ok) {
      fetchListing();
      setShowSuccessCheckmark(true);
      setTimeout(() => setShowSuccessCheckmark(false), 2250);
    } else {
      notification.error({ message: t('listing.mediaManagement.photos.areaUpdateFailure') });
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      assignPhotoToArea();
    } else {
      didMountRef.current = true;
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  useEffect(() => {
    if (!photo.listingRoomId) {
      setRoomId(null);
    }
  }, [photo.listingRoomId]);

  return (
    <div className={s.selector}>
      <Select
        value={roomId || undefined}
        allowClear={!!roomId}
        onChange={setRoomId}
        placeholder={t('listing.mediaManagement.photos.selectAreaPlaceholder')}
        className={s.dropdown}
        dropdownAlign={{
          points: ['bl', 'tl'],
          offset: [0, -4],
        }}
      >
        {areas.map(area => (
          // Cleanup: Using area.roomType as a fallback until
          // we run a data migration to backfill area.names for all the old areas
          <Select.Option key={area.id} value={area.id}>
            {area.name || area.roomType}
          </Select.Option>
        ))}
      </Select>
      <CustomIcon className={cn(s.checkmark, showSuccessCheckmark && s.show)} type="checkmark" />
    </div>
  );
};

export default AreaSelector;
