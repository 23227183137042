import { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import useAltText from '@root/shared/useAltText';
import s from './Avatar.module.less';
import Initials from './Initials';

type Props = { name: string; photoUrl?: string | null; className?: string };

const Avatar = ({ name, photoUrl, className }: Props) => {
  const { getAltTextForListingAgent } = useAltText();
  const [imageHasError, setImageHasError] = useState(false);
  const image = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    if (image.current) {
      image.current.addEventListener('error', () => setImageHasError(true));
    }
  }, []);

  return (
    <span data-testid="avatar" className={cn(className, s.avatar)}>
      {photoUrl && !imageHasError ? (
        <img
          src={photoUrl}
          alt={getAltTextForListingAgent(name)}
          ref={image}
          className="bg-white"
        />
      ) : (
        <Initials name={name} />
      )}
    </span>
  );
};

export default Avatar;
