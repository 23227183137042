import routes from '@root/routes';
import { CurrentUser, StoreState } from '@root/types';
import { useQueryClient } from '@tanstack/react-query';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';

// when getting something from current user state, get it from this function
// which uses redux _now_ but can be refactored to not in the future
export const currentUserKey = [routes.api.currentUser];

const hasCurrentUserLoaded = (state: StoreState) => !!state.loadingStates.currentUser;
const getCurrentUser = (state: StoreState) => state.currentUser;
const hasAdminUser = (state: StoreState): boolean =>
  !!state.currentUser && state.currentUser.role === 'admin';
export default function useCurrentUser() {
  const queryClient = useQueryClient();
  const refetch = () => queryClient.invalidateQueries(currentUserKey);

  const currentUser: CurrentUser | null = useSelector(getCurrentUser);
  const hasLoaded = useSelector(hasCurrentUserLoaded);
  const hasCurrentUser = useSelector((state: StoreState) => !isNull(state.currentUser));
  const isAdmin = useSelector(hasAdminUser);

  return {
    currentUser,
    refetch,
    hasLoaded,
    isLoggedIn: hasLoaded && hasCurrentUser,
    isAdmin,
  };
}
