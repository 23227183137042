import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { useBreakpoint } from '@shared/useBreakpoints';
import { OptionType } from '@components/shared/forms/Dropdowns/types';
import { SavedSearch } from '@root/types';
import truncateText from '@shared/truncateText';
import cn from 'classnames';
import COLORS from '@shared/colorConstants';
import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import s from './SaveSearchSelector.module.less';

export type Props = {
  savedSearches: SavedSearch[];
  name: string;
  value: string | null;
  onChange: (
    savedSearch: SavedSearch | null,
    opts?: { createsOrModifiesSavedSearch: boolean },
  ) => void;
  isDisabled?: boolean;
  menuPlacement?: ComponentProps<typeof Select>['menuPlacement'];
  isInSearchFilter?: boolean;
  currentSelectedSearch: SavedSearch | null;
};

const SaveSearchSelector = ({
  name,
  value,
  onChange,
  savedSearches,
  isDisabled = false,
  menuPlacement = 'bottom',
  isInSearchFilter = false,
  currentSelectedSearch,
}: Props) => {
  const { isMobile, isTablet } = useBreakpoint();
  const { savedSearchInteraction } = useAnalytics();

  const customStyles = {
    container: base => ({
      ...base,
      display: 'inline-block',
      pointerEvents: 'all',
      ...(isMobile && {
        fontSize: '14px',
        lineHeight: '24px',
      }),
    }),
    control: () => ({
      display: 'flex',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: 'black',
      paddingRight: '0',
      borderBottom: isInSearchFilter ? 0 : `1px dashed ${value ? COLORS.indigo : COLORS.black35}`,
      'svg[class*="css"]': {
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        '&:hover': {
          color: COLORS.black100,
        },
      },
    }),
    indicatorSeparator: () => ({
      margin: '1px',
    }),
    indicatorsContainer: base => ({
      ...base,
      'div[class*="indicatorContainer"]': {
        paddingLeft: '0px',
        color: COLORS.black55,
        ...(isMobile && { padding: '2px', height: '100%' }),
      },
    }),
    menu: base => ({
      ...base,
      cursor: 'pointer',
      width: '300px',
      minWidth: '210px',
      zIndex: 2,
      marginTop: '12px',
      right: 0,
      ...(isMobile && {
        right: '',
        width: 'calc(100vw - 32px)',
        position: 'absolute',
      }),
    }),
    menuList: base => ({
      ...base,
      paddingTop: '0',
      paddingBottom: '0',
      borderRadius: '4px',
    }),
    option: (base): any => ({
      ...base,
      backgroundColor: 'white',
      color: '',
      cursor: 'inherit',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '',
      justifyContent: 'space-between',
      '&:active': {
        backgroundColor: COLORS.egg50,
      },
      '&:hover': {
        backgroundColor: COLORS.egg50,
      },
    }),
    placeholder: base => ({
      ...base,
      position: 'static',
      transform: 'none',
      textOverflow: '',
      fontSize: `${isMobile ? '14px' : ''}`,
    }),
    singleValue: base => ({
      ...base,
      color: COLORS.indigo,
      marginLeft: '0',
      marginRight: '0',
      position: 'static',
      transform: 'none',
      textOverflow: 'ellipsis',
      maxWidth: '300px',
      ...(isMobile && { maxWidth: 'calc(100vw - 76px)' }),
      ...(isTablet && { maxWidth: '195px' }),
      fontWeight: 600,
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
      borderBottom: isInSearchFilter ? 0 : `1px dashed ${value ? COLORS.indigo : COLORS.black35}`,
      ...(isTablet && { width: 'max-content' }),
    }),
  };

  const { t } = useTranslation('listingSearch');

  const options = savedSearches.map(
    (ss: SavedSearch): OptionType => ({ label: ss.name || ss.description, value: ss.id }),
  );

  const Option = props => {
    const { children, isSelected, isFocused, data } = props;
    const search = savedSearches.find(savedSearch => savedSearch.id === data.value);
    const handleOptionClick = () => {
      savedSearchInteraction({
        actionType: 'SELECT_SAVED_SEARCH',
        action: PARAMETERS.selectSavedSearch,
        sourcePage: PARAMETERS.searchResultsPage,
        sourceContent: PARAMETERS.searchCriteriaBar,
        savedSearchCount: savedSearches.length,
        savedSearchName: search!.name ?? search!.description,
        savedSearchId: search!.id,
      });
    };
    return (
      <components.Option
        {...props}
        className={cn(s.option, isSelected && s.selected, isFocused && s.focused)}
      >
        <div role="option" aria-selected={isSelected} onClick={handleOptionClick}>
          <div className={s.optionHeader}>{truncateText(children, 70)}</div>
          <div className={s.savedSearchDescription}>{truncateText(search?.description, 70)}</div>
        </div>
      </components.Option>
    );
  };

  const handleOnMenuOpen = () => {
    savedSearchInteraction({
      actionType: 'OPEN_SAVED_SEARCH_DROPDOWN',
      action: PARAMETERS.openSavedSearchDropdown,
      sourcePage: PARAMETERS.searchResultsPage,
      sourceContent: PARAMETERS.searchCriteriaBar,
      savedSearchCount: savedSearches.length,
      savedSearchName: currentSelectedSearch?.name ?? currentSelectedSearch?.description ?? null,
      savedSearchId: currentSelectedSearch?.id ?? null,
    });
  };

  return (
    <Select
      name={name}
      value={options.find(opt => opt.value === value) || null}
      options={options}
      isSearchable={false}
      inputId={name}
      components={{
        Option,
      }}
      styles={customStyles}
      onChange={option => {
        const newSavedSearchId = option ? option.value : null;
        const savedSearch = savedSearches.find(ss => ss.id === newSavedSearchId) || null;
        onChange(savedSearch);
      }}
      menuPlacement={menuPlacement}
      isDisabled={isDisabled}
      placeholder={t('saveSearch:saveSearchSelectorPlaceholder')}
      onMenuOpen={handleOnMenuOpen}
    />
  );
};

export default SaveSearchSelector;
