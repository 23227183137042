import { useTranslation } from 'react-i18next';
import { MarketSlug } from '@root/types';
import Logo from './LogoWrapper';

type Props = {
  marketSlug: MarketSlug;
  email: string;
};

const Navigation = ({ marketSlug, email }: Props) => {
  const { t } = useTranslation();

  return (
    <nav className="flex h-6 items-center bg-indigo-120 px-3">
      <div>
        <Logo marketSlug={marketSlug} />
      </div>
      <div className="flex-grow" />
      <div className="flex gap-6">
        <div key="user-settings-link">
          <a className="text-white hover:text-egg-100 font-body-medium" href="/users/account">
            {email}
          </a>
        </div>
        <div>
          <a className="text-white hover:text-egg-100 font-body-medium" href="/users/sign_out">
            {t('navigation:signOut')}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
