import { KeyboardEventHandler, useState } from 'react';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import actions from '@store/actions/listingSearchPage';
import DropdownStyles from '../../FilterDropdown/DropdownStyles';
import SelectComponents from '../../FilterDropdown/SelectComponents';
import { OptionType } from '../../FilterDropdown/types';
import { useListingSearchCriteria } from '../../utils/index';

const noDropdown = { ...SelectComponents, Menu: () => null };

const KeywordFilter = () => {
  const [inputValue, setInputValues] = useState('');
  const { t } = useTranslation('filters');
  const dispatch = useDispatch();
  const criteria = useListingSearchCriteria();
  const { dropdownFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const handleChange = currentValues => {
    const previousKeywords = criteria.currentFilters.keywords || [];
    const isAdding = previousKeywords.length < currentValues.length;
    const currentKeywords: string[] = currentValues?.map(option => option.value) || [];
    const keyword = isAdding
      ? currentKeywords.find(id => !previousKeywords.includes(id))
      : previousKeywords.find(id => !currentKeywords.includes(id));

    criteria.add(
      'keywords',
      currentValues?.map(option => option.value),
    );
    criteria.pushToHistory();

    if (flags['search-analytics-refactor']) {
      dropdownFilterInteraction({
        filterType: 'keywords',
        isAdding,
        value: keyword!,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatch(actions.keywordsFilterChanged(criteria.currentFilters.keywords || []));
    }
  };

  const getSelectedKeywords = (): OptionType[] =>
    criteria.currentFilters.keywords?.map(keyword => ({
      label: keyword,
      value: keyword,
    })) || [];

  const handleInputChange = (input: string) => {
    setInputValues(input);
  };

  const handleKeyDown: KeyboardEventHandler = event => {
    const trimmedInput = inputValue.trim();
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ',':
        if (trimmedInput)
          handleChange([...getSelectedKeywords(), { label: trimmedInput, value: trimmedInput }]);
        setInputValues('');
        event.preventDefault();
      // no default
    }
  };

  const handleBlur = () => {
    if (inputValue.trim())
      handleChange([...getSelectedKeywords(), { label: inputValue, value: inputValue }]);
    setInputValues('');
  };

  return (
    <div className="u-p-top-2x">
      <CreatableSelect
        isMulti
        isClearable={false}
        components={noDropdown}
        styles={DropdownStyles}
        name="keyword filter"
        placeholder={t('keywords.placeholder')}
        value={getSelectedKeywords()}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default KeywordFilter;
