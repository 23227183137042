import api from '@shared/api';
import routes from '@root/routes';
import { SearchListing } from '@root/types';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};
const ignoreErrors = () => {};

type ReceiveSavedListingsAction = {
  type: 'RECEIVE_FETCHED_SAVED_LISTINGS';
  payload: {
    listings: SearchListing[];
  };
};

export const receiveSavedListings = (listings: SearchListing[]): ReceiveSavedListingsAction => ({
  type: 'RECEIVE_FETCHED_SAVED_LISTINGS',
  payload: {
    listings,
  },
});

const fetchSavedListings = () => dispatch => {
  api
    .fetch(routes.api.favoritedListings)
    .then(handleErrors)
    .then(response => response.json())
    .then((listings: SearchListing[]) => {
      dispatch(receiveSavedListings(listings));
    })
    .catch(ignoreErrors);
};

export default fetchSavedListings;
export type Actions = ReceiveSavedListingsAction;
