/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import useAltText from '@root/shared/useAltText';
import type { Address, MultipathImage } from '@root/types';
import cn from 'classnames';
import s from '../FullWidthLightbox.module.less';

type MapThumbnailProps = {
  onClick: () => void;
  mapImage?: MultipathImage | undefined;
  isSelected: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  address: Address;
};
const MapThumbnail = ({
  onClick,
  mapImage,
  isSelected,
  onMouseOver = () => null,
  onMouseLeave = () => null,
  address,
}: MapThumbnailProps) => {
  const { getAltText } = useAltText({ address });
  if (!mapImage) {
    return null;
  }

  const WrappedButton = () => (
    <button className={cn(s.thumbnail, s.mapThumbnailButton)} type="button" onClick={onClick}>
      <img
        src={mapImage.smallPath}
        alt={getAltText({ type: mapImage.type })}
        className={cn(isSelected ? s.selectedMapThumbnail : s.mapThumbnailButtonImage)}
      />
    </button>
  );
  return (
    <div onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <WrappedButton />
    </div>
  );
};

export default MapThumbnail;
