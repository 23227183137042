import { isEmpty, flatMap } from 'lodash/fp';
import { BuildingMapPoint, InfoWindowListing, MarkerTrackers } from '@root/types';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { createMarker, MAP_MAX_ZOOM } from '@shared/googleMaps';
import MarkerGenerator from './MarkerGenerator/MarkerGenerator';
import BuildingInfoWindow from './BuildingInfoWindow';

const NUMBER_OF_LEVELS_TO_ZOOM = 1;

class Markers {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  activeBuilding: BuildingMapPoint | null;
  activeMarker: google.maps.Marker | null;
  buildingInfoWindow: BuildingInfoWindow | null;
  cachedHighlightedMarkerIcons: { [k: number]: boolean } = {};
  fetchBuildingMapMarkers: (building: BuildingMapPoint) => Promise<InfoWindowListing[]>;
  isClustered: boolean;
  isEventHandlersEnabled: boolean;
  markerClusterer: MarkerClusterer | null;
  markerGenerator: MarkerGenerator;
  markers: google.maps.Marker[];
  trackers: MarkerTrackers | null;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor({
    buildingInfoWindow = null,
    fetchBuildingMapMarkers,
    isClustered,
    isEventHandlersEnabled,
    hideNumbers = false,
    trackers,
  }: {
    buildingInfoWindow?: BuildingInfoWindow | null;
    fetchBuildingMapMarkers: (building: BuildingMapPoint) => Promise<InfoWindowListing[]>;
    isClustered: boolean;
    isEventHandlersEnabled: boolean;
    hideNumbers?: boolean;
    trackers: MarkerTrackers | null;
  }) {
    this.trackers = trackers;
    this.isClustered = isClustered || false;
    this.markerGenerator = new MarkerGenerator({
      shouldUseSearchRedesignStyles: true,
      hideNumbers,
    });
    this.buildingInfoWindow = buildingInfoWindow;
    this.markerClusterer = null;
    this.activeBuilding = null;
    this.activeMarker = null;
    this.markers = [];
    this.isEventHandlersEnabled = isEventHandlersEnabled;
    this.fetchBuildingMapMarkers = fetchBuildingMapMarkers;
  }

  createMarkers = (
    buildingSearchResults: BuildingMapPoint[],
    map,
    selectedMarkets,
    clusterRadius = 20,
  ) => {
    this.clearAllMarkers();
    const marketBounds = flatMap('envelope', selectedMarkets);

    if (buildingSearchResults.length === 0 && isEmpty(marketBounds)) return;

    this.markers = buildingSearchResults.map(building => this.createBuildingMarker(building, map));

    if (this.isClustered && this.markers.length) {
      this.markerClusterer = new MarkerClusterer({
        calculator: this.markerGenerator.calculateClusterStyle,
        maxZoom: MAP_MAX_ZOOM - 1,
        map,
        markers: this.markers,
        algorithm: new SuperClusterAlgorithm({ radius: clusterRadius }),
        renderer: {
          render: cluster => {
            const { text, exclusive } = this.markerGenerator.calculateClusterStyle(cluster.markers);

            const icon = this.markerGenerator.getMarkerIcon(text, {
              exclusive,
              highlighted: false,
            });

            const marker = createMarker(
              map,
              {
                lat: cluster.position.lat(),
                lng: cluster.position.lng(),
                icon,
              },
              true,
              true,
            );

            marker.addListener('mouseover', () => {
              this.handleMarkerMouseOver(text, exclusive, marker);
            });

            marker.addListener('mouseout', () => {
              this.handleMarkerMouseOut(text, exclusive, marker);
            });

            this.cacheHighlightedMarkerIcon(text);

            return marker;
          },
        },
        onClusterClick: (e, cluster) => {
          // This resolves an issue where sometimes the cluster you
          // clicked on doesnt zoom because its already at a good
          // zoom level.
          const zoom = map.getZoom();
          if (zoom < MAP_MAX_ZOOM) {
            const newZoomLevel = Math.min(zoom + NUMBER_OF_LEVELS_TO_ZOOM, MAP_MAX_ZOOM);

            map.setCenter(cluster.position);
            map.setZoom(newZoomLevel);
          }
          if (this.trackers) {
            this.trackers.trackMapDeclustered();
          }
          this.markerClusterer?.render();
        },
      } as any);
    }
  };

  cacheHighlightedMarkerIcon = (number: number): void => {
    if (!this.cachedHighlightedMarkerIcons[number]) {
      new Image().src = this.markerGenerator.generateMarkerIconUrl(number, true);
      this.cachedHighlightedMarkerIcons[number] = true;
    }
  };

  rerenderMarkers = () => {
    this.markerClusterer?.render();
  };

  createBuildingMarker = (building: BuildingMapPoint, map: google.maps.Map) => {
    const marker = this.markerGenerator.createMarker({
      map,
      building,
      isClustered: this.isClustered,
      clickable: this.isEventHandlersEnabled,
    });

    if (this.isEventHandlersEnabled) {
      marker.addListener('click', () => {
        this.handleMarkerOnClick(building, marker, map);
      });

      marker.addListener('mouseover', () => {
        this.handleMarkerMouseOver(building.count, building.exclusive, marker);
      });

      marker.addListener('mouseout', () => {
        this.handleMarkerMouseOut(building.count, building.exclusive, marker);
      });
    }

    this.cacheHighlightedMarkerIcon(marker.count);

    return marker;
  };

  clearAllMarkers = () => {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
    }

    this.markers.forEach(marker => marker.setMap(null));
    this.markers = [];
  };

  handleMarkerMouseOver = (count: number, exclusive: boolean, marker: google.maps.Marker) => {
    marker.setIcon(
      this.markerGenerator.getMarkerIcon(count, {
        highlighted: true,
        exclusive,
      }),
    );

    // clusters are at MAX_ZINDEX + 1 by default so +2 brings marker to very front
    marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 2);
  };

  handleMarkerMouseOut = (count: number, exclusive: boolean, marker: google.maps.Marker) => {
    if (!this.activeMarker || this.activeMarker !== marker) {
      marker.setIcon(
        this.markerGenerator.getMarkerIcon(count, {
          exclusive,
          highlighted: false,
        }),
      );
    }

    // reset marker to default positioning
    marker.setZIndex(undefined);
  };

  handleMarkerOnClick = async (
    building: BuildingMapPoint,
    marker: google.maps.Marker,
    map: google.maps.Map,
  ) => {
    this.removeActiveMarker();

    this.activeMarker = marker;
    this.activeBuilding = building;

    const markerData: InfoWindowListing[] = await this.fetchBuildingMapMarkers(building);
    const listingCount = building.count;

    if (this.buildingInfoWindow) {
      this.buildingInfoWindow.setInfoWindow(marker, map, markerData);
      this.buildingInfoWindow.infoWindow.setOptions({ disableAutoPan: false });
    }

    if (this.trackers) {
      this.trackers.trackBuildingViewedOnMap();
    }

    marker.setIcon(
      this.markerGenerator.getMarkerIcon(listingCount, {
        highlighted: true,
        exclusive: building.exclusive,
      }),
    );
  };

  removeActiveMarker = () => {
    if (this.activeMarker && this.activeBuilding) {
      this.activeMarker.setIcon(
        this.markerGenerator.getMarkerIcon(this.activeBuilding.count, {
          highlighted: false,
          exclusive: this.activeBuilding.exclusive,
        }),
      );
      this.activeMarker = null;
      this.activeBuilding = null;
    }
  };

  getMarkers = () => this.markers;
}

export default Markers;
