import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { AdminListingPhoto, AdminLandlordPhoto } from '@root/types';
import IconButton from '@components/shared/IconButton';
import { useCallback, useState } from 'react';
import useToggle from '@shared/useToggle';
import Button from '@components/shared/V2Button';
import { format, parseISO } from 'date-fns';
import { Tooltip } from 'antd';
import AdminTable from '@components/shared/AdminTable';
import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import CustomIcon from '@components/shared/CustomIcon';
import s from './CollapsableMediaPhotosSection.module.less';

// TODO: TRV-2844 & TRV-2845 Update these types to include Landlord and Building photos
type PhotoTypes = AdminListingPhoto | AdminLandlordPhoto;

type Props = {
  photos: PhotoTypes[];
  title: string;
  description: string;
  makePhotosPublic: (photoIds: Array<string>) => Promise<void>;
  setSelectedRows?: $TSFixMe;
  selectedRows?: Array<string>;
  onDeletePhoto: (photoId: string) => void;
};

const CollapsableMediaPhotosSection = ({
  title,
  photos,
  description = '',
  makePhotosPublic,
  setSelectedRows = () => {},
  selectedRows = [],
  onDeletePhoto,
}: Props) => {
  const { t } = useTranslation('admin');
  const [photosTableCollapsed, setSidebarIsCollapsed] = useState(false);

  const { value: isDeleting, setTrue: startDeleting, setFalse: stopDeleting } = useToggle();

  const deletePhotoButtonHandler = async (id: string) => {
    startDeleting();
    // eslint-disable-next-line no-alert
    if (window.confirm(t('media.deleteConfirmation'))) {
      onDeletePhoto(id);
    }
    stopDeleting();
  };

  const toggleSidebar = useCallback(
    () => setSidebarIsCollapsed(!photosTableCollapsed),
    [photosTableCollapsed],
  );

  const renderPosition = (_position, _photo, index) => (
    <div data-testid="position-cell">{index + 1}</div>
  );

  const renderThumbnail = (path: string) => {
    return (
      <div className={s.thumbnailContainer}>
        <img src={path} alt="Space" />
      </div>
    );
  };

  const renderDescription = (path: string, photo: PhotoTypes) => {
    const { filename, processedAt }: any = photo;

    return (
      <div className={s.fileContainer}>
        <div className={s.filename}>{filename}</div>
        {processedAt ? (
          <div className={s.processedAt}>
            {t('media.processedAt', {
              date: format(parseISO(processedAt), 'PP'),
              time: format(parseISO(processedAt), 'p'),
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const renderOverflow = (_, photo: PhotoTypes) => {
    return (
      <OverflowMenu overflowClassName={s.overflowMenu}>
        <a href={photo.downloadPath} aria-label="download image" download>
          <OverflowMenuItem className={s.overflowMenuItem} onClick={() => {}}>
            <>
              <CustomIcon type="download"></CustomIcon>
              {t('media.mediaManagement.download')}
            </>
          </OverflowMenuItem>
        </a>
        <OverflowMenuItem
          disabled={isDeleting}
          className={s.overflowMenuItem}
          onClick={() => deletePhotoButtonHandler(photo.id)}
        >
          <>
            <CustomIcon type="trash"></CustomIcon>
            {t('media.mediaManagement.delete')}
          </>
        </OverflowMenuItem>
      </OverflowMenu>
    );
  };

  const renderHideHistory = () => {
    return <div className={s.fileContainer}></div>;
  };

  const columns = () => [
    {
      title: t('media.mediaManagement.columns.position'),
      dataIndex: 'position',
      render: renderPosition,
    },
    {
      title: t('media.mediaManagement.columns.thumbnail'),
      dataIndex: 'path',
      render: renderThumbnail,
    },
    {
      title: t('media.mediaManagement.columns.fileName'),
      dataIndex: 'description',
      render: renderDescription,
    },
    {
      title: t('media.mediaManagement.columns.hideHistory'),
      dataIndex: 'hideHistory',
      render: renderHideHistory,
    },
    {
      title: '',
      key: 'actions',
      width: '10%',
      render: renderOverflow,
    },
  ];

  return (
    <div className={cn(s.collapsableTable)}>
      <div className={cn(s.mediaTableHeader, !photosTableCollapsed && s.collapsedTableHeader)}>
        <div>
          <div className={cn(s.tableCollapse)}>
            <IconButton
              className={s.expandCollapseSidebarButton}
              icon={photosTableCollapsed ? 'chevron-down' : 'chevron-right'}
              data-testid="expand-collapse-sidebar-button"
              onClick={toggleSidebar}
            />
            <div className="inline font-subtitle">{title}</div>
          </div>
          <div>{description}</div>
        </div>
        <Tooltip
          trigger={['hover', 'click']}
          placement="topLeft"
          title={t('media.makePhotosPublicTooltip')}
        >
          <span>
            <Button
              className={s.makePhotosPublicButton}
              disabled={!selectedRows.length}
              onClick={() => makePhotosPublic(selectedRows).then(() => setSelectedRows([]))}
              type="primary"
            >
              {t('media.makePhotosPublic')}
            </Button>
          </span>
        </Tooltip>
      </div>
      {photosTableCollapsed ? (
        <div data-testid="v2-photos-table">
          {/* key is necessary to rerender when photo is deleted */}
          <AdminTable
            className={s.adminTableStyles}
            rowClassName={s.termListRow}
            loading={false}
            // @ts-ignore:next-line
            columns={columns()}
            dataSource={photos}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            enableCheckboxes
          />
        </div>
      ) : null}
    </div>
  );
};

export default CollapsableMediaPhotosSection;
