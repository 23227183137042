import cn from 'classnames';
import { useTranslation } from 'react-i18next';

type MetricProps = {
  label: string;
  className?: string | undefined;
  value: string | null;
};

const Metric = ({ label, value, className }: MetricProps) => {
  if (!value) return null;
  return (
    <div className={cn(className, 'flex flex-col')}>
      <label className="overflow-hidden text-black-055 font-body-medium mobile:font-body-small">
        {label}
      </label>
      <div className="font-subtitle-de-emphasis">{value}</div>
    </div>
  );
};

type Props = {
  inventory: string | null;
  space: string | null;
  underConstruction: string | null;
  premierRetailDestinations: string | null;
  numberOfFields?: number;
};

const BasicInfo = ({
  inventory,
  space,
  underConstruction,
  premierRetailDestinations,
  numberOfFields,
}: Props) => {
  const { t } = useTranslation('landlord');

  return (
    <div
      className={cn(
        'w-full gap-5 mobile:mb-2 mobile:gap-2',
        'grid desktop:flex tablet:mb-3 tablet:grid-cols-4',
        numberOfFields === 3 ? 'mobile:grid-cols-3' : 'mobile:grid-cols-2',
      )}
    >
      <Metric label={t('inventory')} value={inventory} />
      <Metric label={t('space')} value={space} />
      <Metric label={t('underConstruction')} value={underConstruction} />
      <Metric
        label={t('premierRetailDestinations')}
        className="tablet:hidden mobile:hidden"
        value={premierRetailDestinations}
      />
      <Metric
        label={t('premierRetail')}
        className="desktop:hidden"
        value={premierRetailDestinations}
      />
    </div>
  );
};

export default BasicInfo;
