import * as React from 'react';
import CustomIcon, { ValidIconTypes } from '@components/shared/CustomIcon';
import Spinner from '@components/shared/Spinner';
import cn from 'classnames';
import s from './Button.module.less';

export type ButtonSize = 'large' | 'medium' | 'small' | 'small-header';
export type ButtonType = 'primary' | 'secondary' | 'tertiary' | 'quarternary';
export type ClassSpecificProps = {
  size: ButtonSize;
  type: ButtonType;
  className?: string;
  focus?: boolean;
  hover?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

export const getClassNameForButton = ({
  disabled,
  type,
  size,
  hover,
  focus,
  className,
  loading,
}: ClassSpecificProps) =>
  cn(
    s.button,
    disabled || loading ? s.disabled : null,
    s[type],
    s[size],
    { [s.hover]: hover, [s.focus]: focus },
    className,
  );

type Props = {
  icon?: ValidIconTypes;
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  'data-testid'?: string;
  iconClass?: string;
  htmlType: 'button' | 'submit' | 'reset';
  buttonRef: React.ForwardedRef<HTMLButtonElement>;
  labelQaTestId?: string;
} & ClassSpecificProps;

const defaultProps: Props = {
  children: undefined,
  type: 'primary',
  size: 'medium',
  onClick: undefined,
  onMouseDown: undefined,
  disabled: false,
  focus: false,
  hover: false,
  htmlType: 'button',
  loading: false,
  buttonRef: null,
} as const;

/* eslint-disable react/button-has-type,react/jsx-no-literals */
const V2Button = (props: Props) => {
  const {
    buttonRef,
    children,
    type,
    size,
    onClick,
    onMouseDown,
    disabled,
    hover,
    focus,
    className,
    icon,
    htmlType,
    iconClass,
    loading,
    labelQaTestId,
  } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const testIdAttribute = props['data-testid'] ? { 'data-testid': props['data-testid'] } : {};

  return (
    <button
      type={htmlType}
      className={getClassNameForButton({
        type,
        size,
        hover,
        focus,
        disabled,
        className,
        loading,
      })}
      onClick={onClick}
      onMouseDown={onMouseDown}
      disabled={disabled || loading}
      ref={buttonRef}
      {...testIdAttribute}
      data-qa-testid={labelQaTestId}
    >
      {loading ? <Spinner className={s['spinner-wrapper']} /> : null}
      {icon && !loading ? (
        <CustomIcon className={cn(s.icon, iconClass)} data-testid={`${icon}-icon`} type={icon} />
      ) : null}
      {children}
    </button>
  );
};
/* eslint-enable react/button-has-type,react/jsx-no-literals */

V2Button.defaultProps = defaultProps;

export default V2Button;
