import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { stringify } from 'qs';
import api from '@shared/api';
import routes from '@root/routes';
import { Market, MarketSlug } from '@root/types';
import actions from '@store/actions';
import { useListingSearchCriteria } from './utils';

const useMapMovementCriteria = () => {
  const dispatch = useDispatch();
  const criteria = useListingSearchCriteria();

  const [confirmRedoModalOpen, setRedoConfirmModalOpen] = useState(false);
  const [confirmDrawingModalOpen, setDrawingConfirmModalOpen] = useState(false);
  const [invalidSearchModalOpen, setInvalidSearchModalOpen] = useState(false);
  const [onDrawCancel, setOnDrawCancel] = useState<Function>(() => () => {});

  const [stateMarkets, setMarkets] = useState<Market[]>([]);
  const [stateBounds, setBounds] = useState<string | null>(null);
  const [statePolygons, setPolygons] = useState<string | null>(null);

  const onMapReset = () => {
    criteria.remove('submarkets');
    criteria.removeMap(criteria.marketSlug as MarketSlug);
    criteria.removePolygons(criteria.marketSlug as MarketSlug);
    criteria.pushToHistory();
  };

  const invalidSearch = (markets: Market[]): boolean => {
    const countries = new Set();
    markets.forEach(m => countries.add(m.countryCode));

    return countries.size > 1;
  };

  const getMarkets = async (within: { bounds: string } | { polygons: string }) => {
    const response = await api.fetch(`${routes.api.markets}?${stringify(within)}`);
    const markets: Market[] = await response.json();

    return markets;
  };

  const redoSearch = ({
    passedMarkets,
    passedBounds,
  }: {
    passedMarkets?: Market[];
    passedBounds?: string;
  } = {}) => {
    criteria.changeMarketBasedOn(passedMarkets || stateMarkets);
    criteria.addMap(passedBounds || stateBounds!);
    criteria.pushToHistory();
    setRedoConfirmModalOpen(false);
  };

  const onRedoSearch = async (bounds: string) => {
    setBounds(bounds);
    const markets = await getMarkets({ bounds });
    setMarkets(markets);

    if (invalidSearch(markets)) {
      setInvalidSearchModalOpen(true);
      return;
    }

    if (criteria.shouldConfirmMarketChange(markets)) {
      setRedoConfirmModalOpen(true);
    } else {
      redoSearch({ passedMarkets: markets, passedBounds: bounds });
    }
  };

  const completeDrawing = ({
    passedMarkets,
    passedPolygons,
  }: {
    passedMarkets?: Market[];
    passedPolygons?: string;
  } = {}) => {
    criteria.changeMarketBasedOn(passedMarkets || stateMarkets!);
    dispatch(actions.mapDrawingPolygonComplete);
    criteria.addPolygons(passedPolygons || statePolygons!);
    criteria.pushToHistory();
    setDrawingConfirmModalOpen(false);
  };

  const onDrawComplete = async (polygons: string, cancelDrawing: Function) => {
    if (!polygons) return;

    setPolygons(polygons);
    const markets = await getMarkets({ polygons });
    setMarkets(markets);

    if (invalidSearch(markets)) {
      setInvalidSearchModalOpen(true);
      return;
    }

    if (criteria.shouldConfirmMarketChange(markets)) {
      setOnDrawCancel(cancelDrawing);
      setDrawingConfirmModalOpen(true);
    } else {
      completeDrawing({ passedMarkets: markets, passedPolygons: polygons });
    }
  };

  const confirmMapMovement = confirmRedoModalOpen || confirmDrawingModalOpen;

  const exitMapMovementConfirmation = () => {
    onDrawCancel();
    dispatch(actions.mapDrawEnd);
    setRedoConfirmModalOpen(false);
    setDrawingConfirmModalOpen(false);
  };

  let moveMap = () => {};
  if (confirmRedoModalOpen) moveMap = redoSearch;
  if (confirmDrawingModalOpen) moveMap = completeDrawing;

  return {
    confirmMapMovement,
    exitMapMovementConfirmation,
    moveMap,
    onMapReset,
    onDrawComplete,
    onRedoSearch,
    invalidSearchModalOpen,
    closeInvalidSearchModal: () => setInvalidSearchModalOpen(false),
  };
};

export default useMapMovementCriteria;
