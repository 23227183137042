import { useTranslation } from 'react-i18next';
import { RawTextArea } from '@components/shared/forms';
import s from './ContentShootDetails.module.less';

type Props = {
  directions: string | null;
  additionalComments: string | null;
};

const Directions = ({ directions, additionalComments }: Props) => {
  const { t } = useTranslation('admin');

  const noop = () => {};

  return (
    <>
      <div className={s.section}>
        <span className={s.sectionTitle}>{t('media.contentShootDetails.directions')}</span>
        <RawTextArea name="directions" disabled value={directions || undefined} onChange={noop} />
      </div>
      <div className={s.section}>
        <span className={s.sectionTitle}>{t('media.contentShootDetails.additionalComments')}</span>
        <RawTextArea
          name="additionalComments"
          disabled
          value={additionalComments || undefined}
          onChange={noop}
        />
      </div>
    </>
  );
};

export default Directions;
