import { useTranslation } from 'react-i18next';
import { LandlordPortfolio } from '@root/types';
import s from './Portfolio.module.less';

type Props = {
  portfolio: LandlordPortfolio;
  buildingCount: number;
  selectedRegionSlug: string | null;
  hideRegionName: boolean;
};

const SelectedRegion = ({
  portfolio,
  buildingCount,
  selectedRegionSlug,
  hideRegionName,
}: Props) => {
  const { t } = useTranslation('landlord');

  return (
    <div className={s.selectedRegionSection}>
      <div className={s.selectedRegionHeader}>
        {hideRegionName ? null : (
          <h3 className={s.portfolioName}>{t(`common:markets.${selectedRegionSlug}`)}</h3>
        )}
        <div className={s.divider} />
        <div className={s.buildingCount}>{t('buildingCount', { count: buildingCount })}</div>
      </div>

      {portfolio.notableTenants.length > 0 ? (
        <>
          <div className={s.notableTenantsLabel}>{t('notableTenants')}</div>
          <ul className={s.logos}>
            {portfolio.notableTenants.map(tenant => (
              <li key={tenant.name}>
                <img src={tenant.logo.smallPath} alt={tenant.logo.description} />
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  );
};

export default SelectedRegion;
