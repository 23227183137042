import { useEffect, useState } from 'react';
import { LandlordBuilding } from '@root/types';
import { TabPane, BuildingCard, List, Notification } from '@components/shared';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import routes from '@root/routes';
import useAnalyticsEvent from '@root/shared/useAnalytics';
import actions from '@store/actions';
import { get, create, destroy } from '@shared/typedApi';
import { useDispatch } from 'react-redux';
import Layout from '../Layout';
import favoriteStyles from './Buildings.module.less';

const queryKey = [routes.api.saveBuilding()];
export const SavedBuildings = () => {
  const { t } = useTranslation('favorites');
  const { saveInteraction, PARAMETERS, pageLoaded, clickToPage } = useAnalyticsEvent();
  const dispatch = useDispatch();

  useEffect(() => {
    pageLoaded({ pageName: PARAMETERS.savedBuildingsPage, actionType: 'PAGE_LOAD' });
    dispatch(actions.setSavedBuildingsPage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // we have the building ids separate because we want to show
  // the buildings that have been toggle off as not saved in the UI
  // for a few seconds before they disappear
  const [buildingIds, setBuildingIds] = useState<string[]>([]);

  // we set the initial order of buildings and keep that constant because
  // the fetch comes back in updated_at order and we dont want to reorder
  // when someone toggles on and off the favorite
  const [initialOrderOfBuildingIds, setInitialOrder] = useState<string[] | null>(null);

  const {
    data: savedBuildings,
    isLoading,
    refetch,
  } = useQuery(queryKey, () => get<LandlordBuilding[]>(routes.api.saveBuilding()));

  useEffect(() => {
    if (savedBuildings) {
      setBuildingIds(savedBuildings.map(building => building.id));
      if (!initialOrderOfBuildingIds) {
        setInitialOrder(savedBuildings.map(building => building.id));
      }
    }
  }, [savedBuildings, initialOrderOfBuildingIds]);

  const toggleSaveMutation = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async ({ slug, on, id }: { slug: string; on: boolean; id: string }) => {
      if (on) {
        await create(routes.api.buildingSave(slug), {});
      } else {
        await destroy(routes.api.buildingSave(slug), {});
      }
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onMutate: ({ slug, on, id }) => {
        if (on) {
          setBuildingIds([...buildingIds, id]);
        } else {
          setBuildingIds(buildingIds.filter(buildingId => buildingId !== id));
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSettled: (data, errors, { slug, on, id }) => {
        saveInteraction({
          action: on ? PARAMETERS.saveItem : PARAMETERS.unsaveItem,
          sourcePage: PARAMETERS.savedBuildingsPage,
        });
        if (on) {
          Notification.saveSuccess({
            title: t('building:saved'),
            /* eslint-disable react/jsx-no-literals */
            text: (
              <Trans i18nKey="viewInFavorites" ns="building">
                View it in your
                <a
                  href="/saved/buildings"
                  onClick={() =>
                    clickToPage({
                      destination: PARAMETERS.savedBuildingsPage,
                      sourceContent: PARAMETERS.toast,
                    })
                  }
                >
                  saved buildings
                </a>
                .
              </Trans>
            ),
            /* eslint-enable react/jsx-no-literals */
          });
        }
        setTimeout(refetch, 2000);
      },
    },
  );

  if (isLoading || !initialOrderOfBuildingIds) return <Layout active="buildings">{null}</Layout>;

  const buildingsInInitialOrder = savedBuildings!.sort(
    (a, b) => initialOrderOfBuildingIds.indexOf(a.id) - initialOrderOfBuildingIds.indexOf(b.id),
  );
  const count = savedBuildings!.length;

  return (
    <Layout active="buildings">
      <TabPane key="buildings">
        <h1 className={favoriteStyles.title}>{t('numSavedBuildings', { count })}</h1>
        <List<LandlordBuilding>
          items={buildingsInInitialOrder}
          className={favoriteStyles.cardList}
          renderItem={building => (
            <BuildingCard
              onSave={(id, slug) => toggleSaveMutation.mutate({ id, slug, on: true })}
              onUnsave={(id, slug) => toggleSaveMutation.mutate({ id, slug, on: false })}
              isSaved={buildingIds.includes(building.id)}
              showSaveButton
              building={building}
              onBuildingClick={() =>
                clickToPage({
                  destination: PARAMETERS.buildingPage,
                  sourcePage: PARAMETERS.savedBuildingsPage,
                  sourceContent: PARAMETERS.savedItem,
                })
              }
            />
          )}
        ></List>
      </TabPane>
    </Layout>
  );
};

export default SavedBuildings;
