import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from './Input';
import css from './styles.module.less';

type Props = {
  fieldName: string;
  value: string;
  pageName: 'building' | 'listing';
};

const TextWithSource = memo(({ fieldName, value, pageName }: Props) => {
  const { t } = useTranslation('admin');

  const metadataPath = `amenitySet.${fieldName}`;

  return (
    <fieldset className={css.sideBySide}>
      <Input
        key={`${pageName}.${fieldName}`}
        containerClass={value ? css.left : css.fullWidth}
        labelText={t(`${pageName}.${fieldName}`)}
        name={metadataPath}
      />
      {value ? (
        <Input
          key={`${pageName}.${fieldName}DataSource`}
          containerClass={css.right}
          labelText={t(`${pageName}.dataSource`)}
          name={`${metadataPath}DataSource`}
        />
      ) : null}
    </fieldset>
  );
});

export default TextWithSource;
