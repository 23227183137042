import api from '@shared/api';
import routes from '@root/routes';
import { PARAMETERS, EVENT_TYPES, EVENTS } from '@root/tracking/constants';
import { TourbookSummary, ListingAnalyticsInformation, StoreState } from '@root/types';
import { getSourcePageFrom, getListingAnalyticsFromSearchResults } from '@root/tracking/helpers';

type Args = {
  listingId: string;
  tourbookId: string;
  onSuccess: (tourbook: TourbookSummary) => void;
};

type AddListingInTourbookAction = {
  type: 'LISTING_ADDED_TO_TOURBOOK';
  payload: {
    tourbook: TourbookSummary;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Tourbook Interaction';
        action: 'add listing';
        sourcePage: string;
        sourceContent: typeof PARAMETERS.tourbookOverlay | typeof PARAMETERS.listingPreview;
        destinationTourbookId: string;
        destinationTourbookName: string;
        destinationTourbookOwnerId: string;
        destinationTourbookOwnerVTSId: string;
        destinationTourbookListingCount: number;
      };
    };
  };
};

export const addListingToTourbook = ({
  tourbook,
  sourcePage,
  listingAnalyticsInfo,
  sourceContent,
}: {
  tourbook: TourbookSummary;
  sourcePage: string;
  listingAnalyticsInfo: ListingAnalyticsInformation;
  sourceContent: typeof PARAMETERS.tourbookOverlay | typeof PARAMETERS.listingPreview;
}): AddListingInTourbookAction => ({
  type: 'LISTING_ADDED_TO_TOURBOOK',
  payload: {
    tourbook,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.addListing,
        sourcePage,
        sourceContent,
        destinationTourbookId: tourbook.id,
        destinationTourbookName: tourbook.name,
        destinationTourbookOwnerId: tourbook.ownerId,
        destinationTourbookOwnerVTSId: tourbook.ownerVTSId,
        destinationTourbookListingCount: tourbook.listingIds ? tourbook.listingIds.length : 0,
        ...listingAnalyticsInfo,
      },
    },
  },
});

export function createTourbookListing({ onSuccess, tourbookId, listingId }: Args) {
  return async (dispatch, getState) => {
    await api.post(routes.api.tourbookListings(tourbookId), { listingId }).then(async response => {
      if (response.ok) {
        const tourbook: TourbookSummary = await response.json();
        const state: StoreState = getState();
        const sourceContentIsListingPreviewModal = state.ui.islistingPreviewModalOpen;

        dispatch(
          addListingToTourbook({
            tourbook,
            sourcePage: getSourcePageFrom(state)!,
            sourceContent: sourceContentIsListingPreviewModal
              ? PARAMETERS.listingPreview
              : PARAMETERS.tourbookOverlay,
            listingAnalyticsInfo: getListingAnalyticsFromSearchResults(state, listingId),
          }),
        );
        onSuccess(tourbook);
      }
    });
  };
}

export type Actions = AddListingInTourbookAction;
