import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PARAMETERS } from '@root/tracking/constants';
import darkContentCss from '@styles/darkContentSection.module.less';
import classNames from 'classnames';
import { Building, BuildingListing, SearchListing } from '@root/types';
import { filter, uniqBy } from 'lodash';
import ControlledTabs from '@components/shared/Tabs/ControlledTabs';
import actions from '@store/actions/buildingPage';
import { useQuery } from '@tanstack/react-query';
import routes from '@root/routes';
import api from '@shared/api';
import useCurrentUser from '@root/shared/useCurrentUser';
import useFavoritedListingsQuery from '@root/shared/useFavoritedListingsQuery';
import css from './AvailableSpaces.module.less';
import { ListingCard } from '../../ListingSearch/Cards/ListingCard';
import useListingPreviewModal from '../../ListingSearch/ListingPreviewModal/useListingPreviewModal';

type Props = {
  building: Building;
  listingClicked: (listing: BuildingListing, tab: ListingTab | '') => void;
  isPreview: boolean;
};

export type ListingTab = 'all' | 'direct';

// Note this component filters listings between direct and all lease types
// with the assumption a building won't have more than 20 listings
export const AvailableSpaces = ({ building, listingClicked, isPreview }: Props) => {
  const { t } = useTranslation('building');
  const { currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const [filteredListings, setFilteredListings] = useState<BuildingListing[]>([]);
  const [listingTab, setListingTab] = useState<ListingTab>('all');

  const { data: listings } = useQuery(
    [routes.api.buildingListings(building.slug)],
    async () => {
      const response = await api.fetch(routes.api.buildingListings(building.slug));
      return response.json() as BuildingListing[];
    },
    {
      enabled: !isPreview,
    },
  );

  const { isListingSaved } = useFavoritedListingsQuery();

  const listingLeaseTypes = uniqBy(listings, 'leaseType').map(listing => listing.leaseType);
  const showTabs = listingLeaseTypes.length >= 2 && listingLeaseTypes.includes('direct');
  const tabSelectedForAnalytics = showTabs ? listingTab : '';

  const { id, slug, neighborhood, owningCompany, address } = building;

  useEffect(() => {
    if (listingTab === 'direct') {
      setFilteredListings(filter(listings, { leaseType: 'direct' }));
    } else {
      setFilteredListings(listings || []);
    }
  }, [listingTab, listings]);

  useEffect(() => {
    if (currentUser && showTabs) {
      setListingTab('direct');
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, listings]);

  const listingFormatter = (listing: BuildingListing): SearchListing => ({
    ...listing,
    buildingId: id,
    buildingSlug: slug,
    landlordName: owningCompany?.name || '',
    landlordLogo: null, // Building page doesn't need this in listing card
    neighborhoodName: neighborhood,
    photo: listing.previewPhoto,
    dateAvailable: listing.availabilityDate,
    address,
  });

  const { ListingPreviewModal, listingPreviewModalProps, openListingPreviewModal } =
    useListingPreviewModal({
      partialListings: filteredListings && filteredListings.map(l => listingFormatter(l)),
      sourcePage: PARAMETERS.buildingPage,
      isSaved: isListingSaved,
    });

  if (!listings || listings.length === 0) return null;

  const isUserLoggedIn = !!currentUser;

  const changeListingTab = (tab: ListingTab) => {
    setListingTab(tab);
    if (tab === 'all') {
      dispatch(actions.listingTableAllListingsTabClicked());
    } else if (tab === 'direct') {
      dispatch(actions.listingTableDirectListingsTabClicked());
    }
  };

  return (
    <div
      data-testid="available-spaces"
      className={classNames(css.sectionContainer, darkContentCss.darkContentSection)}
    >
      <h2 className="text-text-primary font-headline">{t('availableSpaces.sectionHeader')}</h2>
      {isUserLoggedIn && showTabs && (
        <ControlledTabs
          aligned="left"
          currentTab={listingTab}
          tabs={[
            { value: 'direct', label: t('availableSpaces.tabs.directListings') },
            { value: 'all', label: t('availableSpaces.tabs.allListings') },
          ]}
          changeTab={tab => changeListingTab(tab as ListingTab)}
        />
      )}
      <ul className={css.cardList} id="listing-cards">
        {filteredListings &&
          filteredListings.map((listing, i) => (
            <li className={css.listingCardWrapper} key={listing.id}>
              <ListingCard
                listing={listingFormatter(listing)}
                openListingPreviewView={() => openListingPreviewModal(i)}
                isSaved={isListingSaved(listing.id)}
                loggedInUser={currentUser}
                onClick={() => listingClicked(listing, tabSelectedForAnalytics)}
                showBrochureCol={filteredListings.some(l => l.brochures.length > 0)}
                noQuickInquiry
              />
            </li>
          ))}
      </ul>
      <ListingPreviewModal {...listingPreviewModalProps} />
    </div>
  );
};

export default AvailableSpaces;
