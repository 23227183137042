import { UnitAndMagnitudeMeasure } from '@root/types';
import { Trans } from 'react-i18next';

const formatCeilingHeightMeasure = (ceilingHeight: {
  finished: UnitAndMagnitudeMeasure | null;
  slabToSlab: UnitAndMagnitudeMeasure | null;
}) => {
  return (
    <span>
      {ceilingHeight.finished && (
        <div>
          <Trans i18nKey="common:listing.finishedHeight">
            {{ height: ceilingHeight.finished }}
          </Trans>
        </div>
      )}
      {ceilingHeight.slabToSlab && (
        <div>
          <Trans i18nKey="common:listing.slabToSlabHeight">
            {{ height: ceilingHeight.slabToSlab }}
          </Trans>
        </div>
      )}
    </span>
  );
};

export default formatCeilingHeightMeasure;
