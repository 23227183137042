import * as React from 'react';
import { Form as SharedForm } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import { SchemaOf, string } from 'yup';
import { FormValues, defaultValidationSchema } from '.';
import { defaultFormValues } from './Form';

type Props = {
  children: React.ReactNode;
  initialValues?: Partial<FormValuesGb>;
  onSubmit: (values: FormValuesGb) => void;
};

export type FormValuesGb = Omit<FormValues, 'state'>;

const validationSchema = (t: Function): SchemaOf<FormValuesGb> =>
  defaultValidationSchema(t)
    .omit(['state'])
    .shape({
      addressLine2: string()
        .required()
        .max(30, t('externalListing.address2Max'))
        .label(t('externalListing.address2LabelGB')),
      zipCode: string().required().label(t('externalListing.postcodeLabel')),
    });

export default function FormGb({ children, onSubmit, initialValues = {} }: Props) {
  const { t } = useTranslation('tourbook');

  return (
    <SharedForm<FormValuesGb>
      initialValues={defaultFormValues({ initialValues, countryCode: 'GB' })}
      validationSchema={validationSchema(t)}
      id="createExternalTourbookListingForm"
      onSubmit={onSubmit}
    >
      {children}
    </SharedForm>
  );
}
