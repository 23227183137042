import React, { useEffect, useRef, useState } from 'react';
import { CustomIcon } from '@components/shared';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './FileInput.module.less';
import HoverMenu from '../HoverMenu';

type FileInputProps = {
  cloudinaryId: string;
  accept: string;
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>, photoOrder) => void;
  order: number;
  showPhoto: boolean;
  selected: string;
  identifier: string;
  onPhotoDelete?: (photoOrder: number) => void;
  className?: string;
};

const FileInputMedia = (props: FileInputProps) => {
  const { t } = useTranslation('admin');
  const { onUpload, onPhotoDelete } = props;
  const [errorText, setErrorText] = useState('');
  const fileUploadRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (errorText !== '') {
      timerId = setTimeout(() => {
        setErrorText('');
      }, 5000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [errorText]);

  const SortablePhoto = ({
    path,
    position,
    showPhoto,
  }: {
    path: string;
    position: number;
    showPhoto: boolean;
  }) => {
    const [isShown, setIsShown] = useState(false);

    return showPhoto ? (
      <div
        className="relative"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {!isShown && <div className={s.position}>{position}</div>}
        {isShown && (
          <HoverMenu
            photoUrl={path}
            order={position}
            onPhotoDelete={onPhotoDelete}
            onHoverTitle={t('marketingMedia.photoHoverTitle')}
            hoverBackdrop={s.hoverImageBackdrop}
          />
        )}
        <img className={s.image} src={path} alt="alt" data-testid="marketing-media-image" />
      </div>
    ) : (
      <div
        className="relative"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {isShown && (
          <HoverMenu
            photoUrl={path}
            order={position}
            onPhotoDelete={onPhotoDelete}
            onHoverTitle={t('marketingMedia.videoHoverTitle')}
            hoverBackdrop={classNames(s.hoverVideoBackdrop, s.hoverImageBackdrop)}
          />
        )}
        <video data-testid="marketing-media-video" width="224" height="142" controls>
          <source src={props.cloudinaryId} />
        </video>
      </div>
    );
  };

  return (
    <>
      {props.cloudinaryId ? (
        <SortablePhoto
          path={props.cloudinaryId}
          position={props.order}
          showPhoto={props.showPhoto}
        />
      ) : (
        <div className={classNames(s.activeBorder)}>
          <label
            htmlFor={`${props.identifier}${props.order}`}
            className={classNames(s.draggableUploadArea, props.className)}
          >
            <CustomIcon type="uploadOutlined" className={s.uploadIcon} />
            {t(`marketingMedia.upload${props.selected}`)}
          </label>

          <input
            id={`${props.identifier}${props.order}`}
            data-testid="marketing-form-file-input"
            accept={props.accept}
            hidden
            onChange={e => onUpload && onUpload(e, props.order)}
            ref={fileUploadRef}
            type="file"
          />
        </div>
      )}
      {/* {!props.showCancelUploadMediaButton ? (
        <div className={s.cancelUploadButton} data-testid="cancel-upload-media-button">
          <Close onClick={() => props.onDelete(props.order)} />
        </div>
      ) : null} */}
    </>
  );
};

export default FileInputMedia;
