import api from '@shared/api';
import { SavedSearch } from '@root/types';
import routes from '@root/routes';

type ReceivedUpdatedAllSavedSearchNotificationsAction = {
  type: 'RECEIVED_UPDATED_ALL_SAVED_SEARCH_NOTIFICATIONS_ACTION';
  payload: SavedSearch[];
};

const receiveUpdatedAllSavedSearchNotifications = (
  savedSearches: SavedSearch[],
): ReceivedUpdatedAllSavedSearchNotificationsAction => ({
  type: 'RECEIVED_UPDATED_ALL_SAVED_SEARCH_NOTIFICATIONS_ACTION',
  payload: savedSearches,
});

const updateAllSavedSearchNotifications =
  (receivesNotifications?: boolean, successCallback?: Function) => async dispatch => {
    const response = await api.put(routes.api.savedSearchNotifications, {
      receivesNotifications,
    });

    if (response.ok) {
      const savedSearches: SavedSearch[] = await response.json();
      dispatch(receiveUpdatedAllSavedSearchNotifications(savedSearches));
      if (successCallback) successCallback(response);
    }
  };

export default updateAllSavedSearchNotifications;

export type Actions = ReceivedUpdatedAllSavedSearchNotificationsAction;
