import { Item } from '@components/shared/EditableAsset/EditableAsset';
import { TFunction } from 'i18next';
import { BuildingLogoTypes } from './BrandedAssets';

type Props = {
  isHidden: boolean;
  assetType: BuildingLogoTypes;
  onSelect: () => void;
  t: TFunction;
};

export const menuItemOptions = (isUsingLandlordAsset: boolean, isHidingLandlordAsset: boolean) => {
  let menuItems;

  if (isHidingLandlordAsset) {
    menuItems = ['upload', 'unhide'];
  } else if (isUsingLandlordAsset) {
    menuItems = ['upload', 'hide'];
  } else {
    menuItems = ['upload', 'edit', 'delete'];
  }

  return menuItems;
};

export const hideAssetMenuItemOption = ({
  isHidden,
  assetType,
  onSelect,
  t,
}: Props): { [key: string]: Item } => {
  return isHidden
    ? {
        unhide: {
          label: `${t(`common:editableAsset.editMenuOptions.unhideLandlord.${assetType}`)}`,
          iconName: 'Preview',
          onSelect: () => onSelect(),
        },
      }
    : {
        hide: {
          label: `${t(`common:editableAsset.editMenuOptions.hideLandord.${assetType}`)}`,
          iconName: 'Hide',
          onSelect: () => onSelect(),
        },
      };
};
