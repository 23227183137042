import api from '@shared/api';
import routes from '@root/routes';
import { PARAMETERS, EVENT_TYPES, EVENTS } from '@root/tracking/constants';
import { getSourcePageFrom } from '@root/tracking/helpers';
import { ErrorResponse, TourbookSummary } from '@root/types';

export type RemoveTourbookAction = {
  type: 'REMOVE_TOURBOOK';
  payload: {
    tourbook: string;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Tourbook Interaction';
        action: 'delete tourbook';
        sourcePage: string;
        sourceContent: 'tourbook overlay';
        tourbookId: string;
        tourbookName: string;
        tourbookOwnerId: string;
        tourbookOwnerVTSId: string;
        listingCount: number;
      };
    };
  };
};

export const removeTourbook = (
  tourbook: TourbookSummary,
  sourcePage: string,
  listingCount: number,
): RemoveTourbookAction => ({
  type: 'REMOVE_TOURBOOK',
  payload: {
    tourbook: tourbook.id,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.deleteTourbook,
        sourcePage,
        sourceContent: PARAMETERS.tourbookOverlay,
        tourbookId: tourbook.id,
        tourbookName: tourbook.name,
        tourbookOwnerId: tourbook.ownerId,
        tourbookOwnerVTSId: tourbook.ownerVTSId,
        listingCount,
      },
    },
  },
});

export const deleteTourbook =
  (
    tourbook: any,
    listingCount: number,
    { onSuccess, onError }: { onSuccess: () => void; onError: (e: ErrorResponse) => void } = {
      onSuccess: () => {},
      onError: () => {},
    },
  ) =>
  (dispatch, getState) => {
    api.delete(routes.api.tourbook(tourbook.id)).then(async response => {
      if (response.ok) {
        dispatch(removeTourbook(tourbook, getSourcePageFrom(getState())!, listingCount));
        onSuccess();
      } else {
        onError(await response.json());
      }
    });
  };
