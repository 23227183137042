import { useTranslation } from 'react-i18next';
import Checkbox from '@components/shared/forms/Checkbox';
import type { AdminBuilding } from '@root/types';
import Input from '../../Input';
import ChannelsHeader from '../Header';
import css from '../../styles.module.less';

type SitemapSettingType = {
  name: string;
};

type ChannlesSiteplanProps = {
  building: AdminBuilding;
};

const ChannelsSiteplan = ({ building }: ChannlesSiteplanProps) => {
  const { t } = useTranslation('admin');

  const sitemapSettings: Array<SitemapSettingType> = [
    { name: 'showComingAvailableSpaces' },
    { name: 'showOccupiedSpaces' },
    { name: 'showListedSpaces' },
  ];

  const sitemapSettingsCheckboxes = (items: Array<SitemapSettingType>) => {
    const checkboxList: Array<React.ReactElement> = [];

    items.forEach((setting: SitemapSettingType, index: number) => {
      checkboxList.push(
        <li key={`${setting.name}.${index + 1}`} className={css.sitemapSettingsCheckboxItem}>
          <Checkbox name={setting.name} data-test-id={`${setting.name}-test`}>
            <span className={css.checkboxLabel}>{t(`building.channels.${setting.name}`)}</span>
          </Checkbox>
        </li>,
      );
    });

    return checkboxList;
  };

  return (
    <>
      <ChannelsHeader channel="siteplan" />

      {building.siteplanJsViewerUrl ? (
        <a
          className={css.buildingURL}
          href={building.siteplanJsViewerUrl}
          target="_blank"
          rel="noreferrer"
        >
          {building.siteplanJsViewerUrl}
        </a>
      ) : null}

      <div className={css.sideBySide} data-testid="building-sitemap-status">
        <Input
          containerClass={css.left}
          labelText={t('building.basicInfo.siteplanStatus')}
          name="siteplanStatus"
          disabled
        />
      </div>
      {sitemapSettingsCheckboxes(sitemapSettings)}
    </>
  );
};

export default ChannelsSiteplan;
