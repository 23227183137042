import * as React from 'react';
import { FastField, Field } from 'formik';
import Input from '../RawInput';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';

type Props = {
  className?: string;
  description?: string;
  disabled?: boolean;
  fast?: boolean;
  placeholder?: string;
  autoComplete?: boolean;
  showCharacterCount?: boolean;
  maxLength?: number;
} & Omit<FieldWrapperProps, 'children'>;

const TextInput = React.forwardRef(
  (
    {
      className,
      description,
      disabled = false,
      fast = false,
      name,
      labelClass,
      containerClass,
      labelText,
      placeholder,
      required,
      autoComplete = true,
      showCharacterCount = false,
      maxLength,
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const Component = fast ? FastField : Field;
    return (
      <FieldWrapper {...{ description, name, labelClass, containerClass, labelText, required }}>
        <Component name={name}>
          {({ field, meta }) => (
            <Input
              aria-describedby={`${name}-description ${name}-error`}
              autoComplete={autoComplete}
              className={className}
              disabled={disabled}
              {...field}
              hasError={meta.touched && meta.error}
              placeholder={placeholder}
              showCharacterCount={showCharacterCount}
              maxLength={maxLength}
              ref={ref}
              data-testid={`textInput-${name}`}
            />
          )}
        </Component>
      </FieldWrapper>
    );
  },
);

export default TextInput;
