import api from '@shared/api';
import routes from '@root/routes';
import { BuildingOwnerSummary } from '@root/types';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};
const ignoreErrors = () => {};

type ReceiveBuildingOwnersAction = {
  type: 'RECEIVE_FETCHED_BUILDING_OWNERS';
  payload: {
    buildingOwners: BuildingOwnerSummary[];
  };
};

export const receiveBuidingOwners = (
  buildingOwners: BuildingOwnerSummary[],
): ReceiveBuildingOwnersAction => ({
  type: 'RECEIVE_FETCHED_BUILDING_OWNERS',
  payload: {
    buildingOwners,
  },
});

const fetchSavedBuildingOwners = () => dispatch => {
  api
    .fetch(routes.api.savedBuildingOwners)
    .then(handleErrors)
    .then(response => response.json())
    .then((buildingOwners: BuildingOwnerSummary[]) => {
      dispatch(receiveBuidingOwners(buildingOwners));
    })
    .catch(ignoreErrors);
};

export default fetchSavedBuildingOwners;
export type Actions = ReceiveBuildingOwnersAction;
