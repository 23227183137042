import { Listing } from '@root/types';
import UtmAwareLink from '@components/shared/UtmAwareLink/UtmAwareLink';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ts from '@styles/typography.module.less';
import routes from '@root/routes';
import useAltText from '@root/shared/useAltText';
import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import ActionButtons from './ActionButtons';

import s from './ListingLocationDeprecated.module.less';

type Props = { listing: Listing };

const ListingLocationDeprecated = ({ listing }: Props) => {
  const {
    building: { neighborhood: buildingNeighborhood, slug: buildingSlug },
    name: listingName,
    address: listingAddress,
  } = listing;
  const { t } = useTranslation('listing');
  const { clickToPage } = useAnalytics();

  const { getAltText } = useAltText({ address: listingAddress });

  const linkToBuildingPage = routes.building(buildingSlug);

  const buildingPhoto =
    listing.building.photo || listing.photos.find(photo => photo.type === 'staticMap');

  return listing.hideLocation ? (
    <div className={s.container}>
      <div className={s.locationAndActionButtons}>
        {!!buildingPhoto && (
          <div className={s.buildingPhoto} data-qa-testid="building-logo">
            <img alt={getAltText({ type: buildingPhoto!.type })} src={buildingPhoto!.smallPath} />
          </div>
        )}

        <div className={s.spacer} />
      </div>
      <h1 className={s.neighborhoodTitle}>
        {t('location.neighborhoodInShare', { neighborhood: buildingNeighborhood })}
      </h1>
      {listing.status === 'off_market' ? (
        <div className={s.offMarketLabel}>{t('offMarket')}</div>
      ) : null}
    </div>
  ) : (
    <ul className={s.container} data-testid="listingLocation">
      <li className={s.locationAndActionButtons}>
        <UtmAwareLink
          to={linkToBuildingPage}
          dataTestid="view-building-button"
          dataQaTestid="building-logo"
          onClick={() => {
            clickToPage({
              actionType: 'BUILDING_THUMBNAIL_CLICKED',
              sourcePage: PARAMETERS.listingPage,
              sourceContent: PARAMETERS.buildingThumbnail,
              destination: PARAMETERS.buildingPage,
            });
          }}
        >
          {!!buildingPhoto && (
            <figure className={s.buildingPhoto}>
              <img alt={getAltText({ type: buildingPhoto!.type })} src={buildingPhoto!.smallPath} />
            </figure>
          )}
        </UtmAwareLink>
        <div className={s.spacer} />
        <ActionButtons listing={listing} />
      </li>
      <li>
        <h1 className={s.primaryInfo} data-qa-testid="building-name">
          <ul>
            <li>{listing.address.buildingName} </li>
            <li>{listing.address.street}</li>
          </ul>
        </h1>
      </li>
      <section data-qa-testid="listing-address">
        <li>
          <h2 className={s.secondaryInfo}>{listingName}</h2>
        </li>
        <li>
          <h2 className={cn(s.secondaryInfo, s.ensureOneline)}>
            {buildingNeighborhood ? (
              <>
                {buildingNeighborhood}
                <figure className={s.line}></figure>{' '}
              </>
            ) : null}
            {t('common:cityLocalityRegion', { address: listing.address })}
          </h2>
        </li>
        <li>
          <h2 className={s.secondaryInfo}>{t(`country:${listing.address.countryCode}`)}</h2>
        </li>
        {listing.address.crossStreets ? (
          <li className={ts.bodyMedium}>
            {t('crossStreets', { crossStreets: listing.address.crossStreets })}
          </li>
        ) : null}
      </section>
      {listing.status === 'off_market' ? (
        <li className={s.offMarketLabel}>{t('offMarket')}</li>
      ) : null}
    </ul>
  );
};

export default ListingLocationDeprecated;
