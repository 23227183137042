type SetActiveSavedSearchIdAction = {
  type: 'SAVED_SEARCH_SELECTED';
  payload: string | null;
};

const setActiveSavedSearchId = (savedSearchId: string | null): SetActiveSavedSearchIdAction => ({
  type: 'SAVED_SEARCH_SELECTED',
  payload: savedSearchId,
});

export default setActiveSavedSearchId;

export type Actions = SetActiveSavedSearchIdAction;
