import { useFormikContext } from 'formik';
import Title from '@components/layouts/Truva/Tourbook/Title';
import cn from 'classnames';
import { CustomIcon } from '@components/shared';
import { countryToCurrencyCode } from '@root/shared/currencyByCountry';
import { PARAMETERS } from '@root/tracking/constants';
import TourbookMosaicGallery from '@components/layouts/Truva/Tourbook/TourbookMosaicGallery';
import { useTranslation } from 'react-i18next';
import { Address, MultipathImage } from '@root/types';
import { uniqueId } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormValues } from '..';
import ListingAttributes from '../../Tourbook/ListingAttributes';
import ListingDescription, { ListingDescriptionHtml } from '../../Tourbook/ListingDescription';
import s from './TourbookExternalListingCard.module.less';
import {
  formatAskingRentInMoneyPerAreaPerPeriod,
  formatAskingRentInMoneyPerPeriod,
} from '../../Tourbook/helper';

type TourbookExternalListingCardProps = {
  readOnly: boolean;
  isPreview?: boolean;
};

export const TourbookExternalListingCard = ({
  readOnly,
  isPreview = false,
}: TourbookExternalListingCardProps) => {
  const { t } = useTranslation('tourbook');

  const { values } = useFormikContext<FormValues>();
  const flags = useFlags();

  const onOpenDirections = () => {
    // trackMapDirectionsClicked(listing.analyticsInformation);
  };

  const openMapDirections = location => {
    const baseMapsURL = `https://www.google.com/maps/dir/?api=1&destination=`;
    const url = `${baseMapsURL}${location.latitude},${location.longitude}`;
    onOpenDirections();
    window.open(url, '_blank');
  };

  const mapButton = location => {
    if (!location.latitude || !location.longitude) {
      return '';
    }

    return (
      <CustomIcon
        className={s.directionsIcon}
        data-testid="directions-icon"
        type="location"
        onClick={() => openMapDirections(location)}
      />
    );
  };

  const buildingName = () => {
    if (values.buildingName === '') {
      return t('externalListing.buildingName');
    }

    return values.buildingName ?? null;
  };

  const address2Localization =
    values.countryCode === 'GB'
      ? t('externalListing.address2PlaceholderGB')
      : t('externalListing.address2Placeholder');

  const zipCodeLocalization =
    values.countryCode === 'GB'
      ? t('externalListing.postcodePlaceholder')
      : t('externalListing.zipPlaceholder');

  const { latitude, longitude } = values;

  // tell the child component which attributes should have preview styling
  // by detecting if we are using the placeholder values
  const previewStatus = {
    submarket: !values.submarket,
    buildingName: buildingName() === t('externalListing.buildingName'),
    street: !values.addressLine1,
    floorAndSuite: !values.addressLine2,
    cityState: !values.city || !values.state,
  };
  const address: Address = {
    buildingName: buildingName(),
    street: values.addressLine1 || t('externalListing.addressLabel'),
    floorAndSuite: values.addressLine2 || address2Localization,
    postalCode: values.zipCode || zipCodeLocalization,
    city: values.city || t('externalListing.cityLabel'),
    countryCode: values.countryCode,
    abbreviatedRegion: values.state || `${t('externalListing.stateLabel')},`,
    crossStreets: null,
    formattedAddress: `${values.addressLine1} ${values.city}, ${values.state}, ${values.zipCode}`,
  };

  const currency = countryToCurrencyCode(values.countryCode);

  const photosFromValues: MultipathImage[] = values.photos.map(photo => ({
    ...photo,
    type: photo.description.toLowerCase() === 'floor plan' ? 'floorPlan' : 'listingPhoto',
  }));

  return (
    <div className={s.container}>
      <div className={s.titleLinkAndDeleteIcon}>
        <Title
          isPreview={isPreview}
          address={address}
          neighborhood={values.submarket || t('externalListing.submarketLabel')}
          directionsButton={mapButton({ latitude, longitude })}
          previewStatus={previewStatus}
        />
      </div>
      <div className={s.mosaicGalleryContainer}>
        <TourbookMosaicGallery
          photos={photosFromValues}
          video={null}
          sourceContent={PARAMETERS.tourbookListingGallery}
          sourcePage={PARAMETERS.tourbookPage}
          noLightbox
          address={address}
          external
        />
      </div>
      <ListingAttributes
        isExternal
        tourbookListingId={uniqueId()}
        // TODO: replace flags for passing in external
        // TODO: add min/max asking rent in convertable format from tourbook/helper.ts
        askingRentInMoneyPerAreaPerPeriod={formatAskingRentInMoneyPerAreaPerPeriod(
          values.minAskingRent,
          values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
          values.size,
          currency,
        )}
        askingRentInMoneyPerPeriod={formatAskingRentInMoneyPerPeriod(
          values.minAskingRent,
          values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
          values.size,
          currency,
        )}
        minAskingRentInMoneyPerAreaPerPeriod={formatAskingRentInMoneyPerAreaPerPeriod(
          // If we are on range mode, and if there is maxAskingRent, treat minAskingRent
          // as 0 so we still display range.
          values.askingRentUseRange && values.maxAskingRent
            ? values.minAskingRent || 0
            : values.minAskingRent,
          values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
          values.size,
          currency,
        )}
        minAskingRentInMoneyPerPeriod={formatAskingRentInMoneyPerPeriod(
          // If we are on range mode, and if there is maxAskingRent, treat minAskingRent
          // as 0 so we still display range.
          values.askingRentUseRange && values.maxAskingRent
            ? values.minAskingRent || 0
            : values.minAskingRent,
          values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
          values.size,
          currency,
        )}
        maxAskingRentInMoneyPerAreaPerPeriod={
          values.askingRentUseRange
            ? formatAskingRentInMoneyPerAreaPerPeriod(
                values.maxAskingRent,
                values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
                values.size,
                currency,
              )
            : null
        }
        maxAskingRentInMoneyPerPeriod={
          values.askingRentUseRange
            ? formatAskingRentInMoneyPerPeriod(
                values.maxAskingRent,
                values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
                values.size,
                currency,
              )
            : null
        }
        condition={values.condition}
        leaseType={values.leaseType}
        dateAvailable={values.availabilityDate}
        availableToday={values.availableImmediately}
        minMaxArea={null}
        readOnly
        size={
          values.size
            ? { magnitude: values.size, type: 'area', units: { area: 'SquareFoot' } }
            : null
        }
        showNullAskingRent
        currencyCode={currency}
      />
      {flags['market.listing-space-descriptions-support-rich-text'] ? (
        <ListingDescriptionHtml
          description={values.descriptionHtml || t('externalListing.description')}
          readOnly={readOnly}
          tourbookListingId={uniqueId()}
        />
      ) : (
        <ListingDescription
          className={cn(!values.description && s.preview)}
          description={values.description || t('externalListing.description')}
          readOnly={readOnly}
          tourbookListingId={uniqueId()}
        />
      )}
    </div>
  );
};

export default TourbookExternalListingCard;
