import { RawSelect, RawLabel } from '@components/shared/forms';
import { FirstPhotoOrientation } from '@root/types';
import { useTranslation } from 'react-i18next';
import s from './AspectRatioSelector.module.less';

type Props = {
  onChange: (newValue: FirstPhotoOrientation) => void;
  firstPhotoOrientation: FirstPhotoOrientation;
};

export default function AspectRatioSelector({ onChange, firstPhotoOrientation }: Props) {
  const { t } = useTranslation('admin');

  return (
    <div className={s.aspectRatioSelector}>
      <RawLabel name="dontmatter">{t('media.firstPhotoLabel')}</RawLabel>
      <RawSelect
        onChange={val => onChange(val as FirstPhotoOrientation)}
        name="first_photo_orientation"
        value={firstPhotoOrientation}
        options={[
          { value: 'landscape', label: t('media.landscapeView') },
          { value: 'portrait', label: t('media.portraitView') },
        ]}
      />
    </div>
  );
}
