/* eslint-disable react/sort-comp */
import { Component } from 'react';
import classNames from 'classnames';
import actions from '@store/actions';
import { useDispatch } from 'react-redux';
import { Form } from '@ant-design/compatible';
import Container from '@components/shared/Admin/Container';
import { Table, Row, Input, Select } from 'antd';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { parse } from 'qs';
import { debounce, startCase, sortBy } from 'lodash';
import api from '@shared/api';
import routes from '@root/routes';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import queryStringSerializer from '@shared/queryStringSerializer';
import addKeyTo from '@shared/addKeyTo';
import PaginationRow from '@components/shared/PaginationRow';
import { ControlledTabs, CustomIcon, Breadcrumbs } from '@components/shared';
import utils from '@styles/utils.module.less';
import { Tag } from '@viewthespace/components';
import s from './ListingSearch.module.less';
import Layout from '../Layout/Layout';

const pageFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.page as string | null) ? Number(queryParams.page) : 1;
};

const queryFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.query as string | null) || '';
};

const tabFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.tab as string | null) || 'all';
};

const spaceProviderSelectionsFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.space_providers as string[] | null) || [];
};

const statusSelectionsFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.statuses as string[] | null) || [];
};

const citySelectionsFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.cities as string[] | null) || [];
};

const stateSelectionsFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.states as string[] | null) || [];
};

const countrySelectionsFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.countries as string[] | null) || [];
};

type Props = {
  history: $TSFixMe;
  location: $TSFixMe;
  t: $TSFixMe;
  setBreadcrumbs: (breadcrumbs) => void;
  removeBreadcrumbs: () => void;
  requestedListingsCount: number;
  refreshRequestedListingsCount: () => void;
  flags: Object;
};

export class RawListingSearch extends Component<Props, $TSFixMe> {
  abortControllers = [] as any[];

  state = {
    listings: [],
    pagination: {},
    queryValue: queryFrom(this.props.location),
    isLoading: true,
    spaceProviders: [],
    cities: [],
    states: [],
    countries: [],
    countryCodeMap: {},
    spaceProviderSelections: spaceProviderSelectionsFrom(this.props.location),
    statusSelections: statusSelectionsFrom(this.props.location),
    citySelections: citySelectionsFrom(this.props.location),
    stateSelections: stateSelectionsFrom(this.props.location),
    countrySelections: countrySelectionsFrom(this.props.location),
    currentTab: tabFrom(this.props.location),
  };

  async componentDidMount() {
    const { setBreadcrumbs, t, refreshRequestedListingsCount } = this.props;
    await this.fetchFilters();
    refreshRequestedListingsCount();
    this.fetchListings();
    setBreadcrumbs([t('breadcrumbs.admin'), t('breadcrumbs.listings')]);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const pageChanged = pageFrom(location) !== pageFrom(prevProps.location);
    const queryChanged = queryFrom(location) !== queryFrom(prevProps.location);

    if (pageChanged || queryChanged) {
      this.fetchListings();
    }
  }

  componentWillUnmount() {
    const { removeBreadcrumbs } = this.props;
    removeBreadcrumbs();
  }

  onSearch = debounce(() => {
    this.pushToHistory();
  }, 250);

  pushToHistory = (page = 1) => {
    const { history } = this.props;
    const {
      queryValue,
      spaceProviderSelections,
      statusSelections,
      citySelections,
      stateSelections,
      countrySelections,
      currentTab,
    } = this.state;

    const params = {
      query: queryValue,
      page,
      tab: currentTab,
      space_providers: spaceProviderSelections,
      statuses: statusSelections,
      cities: citySelections,
      states: stateSelections,
      countries: countrySelections,
    };

    const queryString = queryStringSerializer(params);

    history.push(`${routes.admin.listingSearch}?${queryString}`);
  };

  currentTabShowsRequested = () => {
    const { currentTab } = this.state;

    return currentTab === 'requested';
  };

  fetchListings = async () => {
    this.abortControllers.forEach(controller => {
      controller.abort();
    });
    const abortController = new AbortController();
    this.abortControllers.push(abortController);

    this.setState({ isLoading: true });
    const { t, location } = this.props;
    const {
      spaceProviderSelections,
      statusSelections,
      citySelections,
      stateSelections,
      countrySelections,
      countryCodeMap,
    } = this.state;

    const params: $TSFixMe = {
      query: queryFrom(location),
      page: pageFrom(location),
      spaceProviders: spaceProviderSelections,
      statuses: statusSelections,
      cities: citySelections,
      states: stateSelections,
      countries: countrySelections.map(countryName => countryCodeMap[countryName]),
    };

    if (this.currentTabShowsRequested()) {
      params.statuses = ['requested'];
      params.sortBy = 'requested_at';
    }

    let response;
    try {
      response = await api.fetch(routes.api.admin.listingSearch(params), {
        signal: abortController.signal,
      });
    } catch (err) {
      // @ts-expect-error
      if (err.name === 'AbortError') {
        return;
      }
      throw err;
    }

    const { listings, pagination } = await response.json();

    const updatedListings = [...listings];
    updatedListings.forEach(listing => {
      // eslint-disable-next-line no-param-reassign
      listing.building.countryName = t(`country:${listing.building.countryCode}`);
    });
    this.setState({ listings, pagination, isLoading: false });
  };

  fetchFilters = async () => {
    const response = await api.fetch(routes.api.admin.listingFilters);
    const { landlords, localities, regions, countryCodes } = await response.json();

    const countryCodeMap = {};
    countryCodes.forEach(code => {
      countryCodeMap[this.props.t(`country:${code}`)] = code;
    });

    this.setState({
      spaceProviders: landlords,
      cities: localities,
      states: regions,
      countries: countryCodes,
      countryCodeMap,
    });
  };

  clearAllFilters = () => {
    this.setState(
      {
        queryValue: '',
        spaceProviderSelections: [],
        statusSelections: [],
        citySelections: [],
        stateSelections: [],
        countrySelections: [],
      },
      () => {
        this.onFilter();
        this.fetchListings();
      },
    );
  };

  renderSpaceProviderFilterOptions = () =>
    this.state.spaceProviders.map(spaceProvider => (
      // @ts-expect-error
      <Select.Option key={spaceProvider.id} value={spaceProvider.id}>
        {/* @ts-expect-error */}
        {spaceProvider.name}
      </Select.Option>
    ));

  renderFilterOptions = options =>
    options.map(option => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    ));

  renderFilterOptionsForCountries = (countryCodeList, t) => {
    const countryList = countryCodeList.map(countryCode => ({
      countryCode,
      countryName: t(`country:${countryCode}`),
    }));
    const countryListSorted = sortBy(countryList, ['countryName']);

    return countryListSorted.map(({ countryName }) => (
      <Select.Option key={countryName} value={countryName} label={countryName}>
        {countryName}
      </Select.Option>
    ));
  };

  handleSetSpaceProviderFilterSelections = spaceProviderSelections => {
    this.setState({ spaceProviderSelections }, () => {
      this.onFilter();
      this.fetchListings();
    });
  };

  handleSetStatusFilterSelections = statusSelections => {
    this.setState({ statusSelections }, () => {
      this.onFilter();
      this.fetchListings();
    });
  };

  handleSetCityFilterSelections = citySelections => {
    this.setState({ citySelections }, () => {
      this.onFilter();
      this.fetchListings();
    });
  };

  handleSetStateFilterSelections = stateSelections => {
    this.setState({ stateSelections }, () => {
      this.onFilter();
      this.fetchListings();
    });
  };

  handleSetCountryFilterSelections = countrySelections => {
    this.setState({ countrySelections }, () => {
      this.onFilter();
      this.fetchListings();
    });
  };

  onFilter = () => {
    this.pushToHistory();
  };

  changePage = newPage => {
    this.pushToHistory(newPage);
  };

  changeTab = tab => {
    const { refreshRequestedListingsCount } = this.props;
    this.setState(
      {
        currentTab: tab,
        statusSelections: [],
      },
      () => {
        refreshRequestedListingsCount();
        this.pushToHistory();
        this.fetchListings();
      },
    );
  };

  renderSpaceProvider = spaceProvider => {
    if (!spaceProvider) {
      /* eslint-disable react/jsx-no-literals */
      return <span className={s['column-empty-state']}>&#8212;</span>;
      /* eslint-enable react/jsx-no-literals */
    }

    return spaceProvider;
  };

  renderStatus = (status, listing) => {
    const { t } = this.props;
    const listingRequested = listing.requestedAt && listing.status === 'requested';
    return (
      <div>
        {startCase(status)}
        {listingRequested ? (
          <div>
            {t('listing.requestedAt', {
              date: format(parseISO(listing.requestedAt), 'PP'),
              time: format(parseISO(listing.requestedAt), 'p'),
            })}
          </div>
        ) : null}
      </div>
    );
  };

  renderBuildingName = building => {
    const buildingName = [building.primaryName, building.secondaryName]
      .filter(obj => obj)
      .join(' - ');

    return (
      <Link className={utils.link} to={routes.admin.building(building.id)}>
        {buildingName}
      </Link>
    );
  };

  renderAction = id => {
    const { t } = this.props;

    return (
      <Link className={utils.link} to={routes.admin.listingPage(id)}>
        <CustomIcon type="edit" /> {t('listing.tableActions.edit')}
      </Link>
    );
  };

  flags = this.props.flags;

  renderBuildingPill = building => {
    const buildingDemo = building && building.demo;
    const { t } = useTranslation('admin');

    return (
      buildingDemo && <Tag testId="demoTag" color="informational" text={t('building.demoTag')} />
    );
  };

  columns = () => {
    const { t } = this.props;

    const buildingColumns = [
      {
        title: t('listing.columnHeaders.building'),
        dataIndex: 'building',
        render: this.renderBuildingName,
      },
      {
        title: '',
        dataIndex: 'building',
        render: this.renderBuildingPill,
      },
      {
        title: t('listing.columnHeaders.floorName'),
        dataIndex: 'floor',
      },
      {
        title: t('listing.columnHeaders.suiteName'),
        dataIndex: 'suite',
      },
      {
        title: t('listing.columnHeaders.city'),
        dataIndex: ['building', 'locality'],
      },
      {
        title: t('listing.columnHeaders.stateRegion'),
        dataIndex: ['building', 'abbreviatedRegion'],
      },
      {
        title: t('listing.columnHeaders.spaceProvider'),
        dataIndex: ['building', 'owningCompany'],
        render: this.renderSpaceProvider,
      },
      {
        title: t('listing.columnHeaders.status'),
        dataIndex: 'status',
        render: this.renderStatus,
      },
      {
        title: t('listing.columnHeaders.action'),
        dataIndex: 'id',
        render: this.renderAction,
      },
    ];
    const buildingColumnsWithCountry = [...buildingColumns];
    buildingColumnsWithCountry.splice(7, 0, {
      title: t('listing.columnHeaders.country'),
      dataIndex: ['building', 'countryName'],
    });
    return buildingColumnsWithCountry;
  };

  renderRegionLabel = stateSelections => {
    const { t } = this.props;

    return stateSelections.length > 0
      ? t('listing.filters.stateRegionWithCount', { count: stateSelections.length })
      : t('listing.filters.stateRegion');
  };

  render() {
    const { t, requestedListingsCount } = this.props;
    const {
      isLoading,
      listings,
      pagination,
      queryValue,
      cities,
      states,
      countries,
      spaceProviderSelections,
      statusSelections,
      citySelections,
      stateSelections,
      countrySelections,
      currentTab,
    } = this.state;

    return (
      <Layout>
        <Breadcrumbs
          className="mb-1"
          items={[{ content: t('breadcrumbs.admin') }, { content: t('breadcrumbs.listings') }]}
        />
        <Container title={t('listing.searchTitle')} extraPadding>
          <Form>
            <Row className="mb-[-33px]">
              <div className={s.tabs}>
                <ControlledTabs
                  changeTab={this.changeTab}
                  currentTab={currentTab}
                  tabs={[
                    { label: t('listing.tabs.all'), value: 'all' },
                    {
                      label: t('listing.tabs.requested', { count: requestedListingsCount }),
                      value: 'requested',
                    },
                  ]}
                  selectedClassName={s.listingTabSelected}
                />
              </div>
            </Row>
            <Form.Item>
              <Row>
                <div className={s.filtersRow}>
                  <Input
                    placeholder={t('listing.placeholder')}
                    value={queryValue}
                    style={{ width: 225, marginRight: 15, marginTop: 37, maxHeight: 34 }}
                    name="query"
                    prefix={<CustomIcon type="magnifying-glass" className={s.searchIcon} />}
                    onChange={e =>
                      this.setState({ queryValue: e.target.value }, () => {
                        this.onSearch();
                      })
                    }
                  />
                  {!this.currentTabShowsRequested() ? (
                    <div className={classNames(s.filterStatus, 'u-m-right-2x')}>
                      <div className={utils.label}>
                        {statusSelections.length > 0
                          ? t('listing.filters.statusWithCount', { count: statusSelections.length })
                          : t('listing.filters.status')}
                      </div>
                      <Select
                        placeholder={t('listing.status')}
                        className={s.filterDropdown}
                        mode="multiple"
                        value={statusSelections}
                        onChange={this.handleSetStatusFilterSelections}
                      >
                        <Select.Option value="inactive">
                          {t('listing.statuses.inactive')}
                        </Select.Option>
                        <Select.Option value="requested">
                          {t('listing.statuses.requested')}
                        </Select.Option>
                        <Select.Option value="onboarding">
                          {t('listing.statuses.onboarding')}
                        </Select.Option>
                        <Select.Option value="ready_for_approval">
                          {t('listing.statuses.ready_for_approval')}
                        </Select.Option>
                        <Select.Option value="on_market">
                          {t('listing.statuses.on_market')}
                        </Select.Option>
                        <Select.Option value="off_market">
                          {t('listing.statuses.off_market')}
                        </Select.Option>
                        <Select.Option value="retired">
                          {t('listing.statuses.retired')}
                        </Select.Option>
                      </Select>
                    </div>
                  ) : null}
                  <div>
                    <div className={utils.label}>
                      {spaceProviderSelections.length > 0
                        ? t('listing.filters.spaceProviderWithCount', {
                            count: spaceProviderSelections.length,
                          })
                        : t('listing.filters.spaceProvider')}
                    </div>
                    <Select
                      style={{ width: 165, marginRight: 15 }}
                      placeholder={t('listing.spaceProvider')}
                      className={s.filterDropdown}
                      onChange={this.handleSetSpaceProviderFilterSelections}
                      mode="multiple"
                      value={spaceProviderSelections}
                      optionFilterProp="children"
                    >
                      {this.renderSpaceProviderFilterOptions()}
                    </Select>
                  </div>
                  <div>
                    <div className={utils.label}>
                      {citySelections.length > 0
                        ? t('listing.filters.cityWithCount', {
                            count: citySelections.length,
                          })
                        : t('listing.filters.city')}
                    </div>
                    <Select
                      style={{ width: 150, marginRight: 15 }}
                      placeholder={t('listing.city')}
                      className={s.filterDropdown}
                      mode="multiple"
                      value={citySelections}
                      onChange={this.handleSetCityFilterSelections}
                    >
                      {this.renderFilterOptions(cities)}
                    </Select>
                  </div>
                  <div>
                    <div className={utils.label}>{this.renderRegionLabel(stateSelections)}</div>
                    <Select
                      style={{ width: 130, marginRight: 15 }}
                      placeholder={t('listing.stateRegion')}
                      className={s.filterDropdown}
                      mode="multiple"
                      value={stateSelections}
                      onChange={this.handleSetStateFilterSelections}
                    >
                      {this.renderFilterOptions(states)}
                    </Select>
                  </div>
                  <div>
                    <div className={utils.label}>
                      {countrySelections.length > 0
                        ? t('listing.filters.countryWithCount', { count: countrySelections.length })
                        : t('listing.filters.country')}
                    </div>
                    <Select
                      style={{ width: 140 }}
                      placeholder={t('listing.country')}
                      className={s.filterDropdown}
                      mode="multiple"
                      value={countrySelections}
                      onChange={this.handleSetCountryFilterSelections}
                    >
                      {this.renderFilterOptionsForCountries(countries, t)}
                    </Select>
                  </div>
                  {spaceProviderSelections.length > 0 ||
                  statusSelections.length > 0 ||
                  citySelections.length > 0 ||
                  stateSelections.length > 0 ||
                  countrySelections.length > 0 ||
                  queryValue ? (
                    <div onClick={this.clearAllFilters} role="button" className={s.clearAllButton}>
                      {t('listing.clearAllButton')}
                    </div>
                  ) : null}
                </div>
              </Row>
            </Form.Item>
          </Form>
          <Table
            loading={isLoading}
            columns={this.columns()}
            dataSource={addKeyTo(listings)}
            pagination={false}
          />
          <PaginationRow {...pagination} changePage={this.changePage} />
        </Container>
      </Layout>
    );
  }
}

const ListingSearchWrapper = props => {
  const dispatch = useDispatch();
  const setBreadcrumbs = breadcrumbs => dispatch(actions.addBreadcrumbs(breadcrumbs));
  const removeBreadcrumbs = () => dispatch(actions.removeBreadcrumbs());
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const history = useHistory();
  const location = useLocation();

  return (
    <RawListingSearch
      {...props}
      setBreadcrumbs={setBreadcrumbs}
      removeBreadcrumbs={removeBreadcrumbs}
      flags={flags}
      t={t}
      history={history}
      location={location}
    />
  );
};

export default ListingSearchWrapper;
