import React, { useMemo, useRef, useState } from 'react';
import { isNull } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RawInput from '@components/shared/forms/RawInput';
import {
  ListingSearchCriteria,
  useListingSearchCriteria,
} from '@components/layouts/Truva/ListingSearch/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import actions from '@store/actions/listingSearchPage';
import { CeilingHeight, CeilingHeightMeasurementType } from '@root/types/Listing';
import debounce from 'lodash/debounce';
import s from '../Filters.module.less';

const debounceLength = 1500;

export const convertMagnitudeToUnits = (magnitude: number | null | undefined) => {
  if (magnitude) {
    return {
      feet: Math.trunc(magnitude),
      inches: Math.trunc(+((magnitude - Math.trunc(magnitude)) * 12).toFixed(2)),
    };
  }
  return {
    feet: null,
    inches: null,
  };
};

type Props = {
  ceilingHeightType: CeilingHeightMeasurementType;
};

const buildAnalyticStringValue = ({ type, magnitude }: CeilingHeight): string =>
  magnitude ? `${type}-${magnitude}` : `${type}-unset-height`;

const ImperialCeilingHeightInput = ({ ceilingHeightType }: Props) => {
  const { t } = useTranslation('filters');

  const dispatch = useDispatch();
  const { ceilingHeightFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const criteria = useListingSearchCriteria({
    onUrlChange: c => {
      setCeilingHeightFilter({
        magnitude: c.currentFilters.ceilingHeightMagnitude ?? null,
        type: c.currentFilters.ceilingHeightType ?? ceilingHeightType,
      });
      setCeilingHeight({
        ...convertMagnitudeToUnits(c.currentFilters.ceilingHeightMagnitude ?? null),
      });
    },
  });

  const [ceilingHeight, setCeilingHeight] = useState<{
    feet: number | null | undefined;
    inches: number | null | undefined;
  }>({
    ...convertMagnitudeToUnits(criteria.currentFilters.ceilingHeightMagnitude ?? null),
  });

  const [ceilingHeightFilter, setCeilingHeightFilter] = useState<CeilingHeight>({
    magnitude: criteria.currentFilters.ceilingHeightMagnitude ?? null,
    type: ceilingHeightType ?? 'finished',
  });

  const ceilingHeightFeetRef = useRef<HTMLInputElement | null>(null);
  const ceilingHeightInchesRef = useRef<HTMLInputElement | null>(null);

  const pushCriteriaUpdates = (
    newCeilingHeightFilter: CeilingHeight,
    criteriaObject: ListingSearchCriteria,
  ) => {
    const currentMagnitude = Number(criteriaObject.currentFilters.ceilingHeightMagnitude) || null;
    const coercedNewMagnitude = Number(newCeilingHeightFilter.magnitude) || null;
    const isMagnitudeCleared = !coercedNewMagnitude;
    criteriaObject.add('ceilingHeightMagnitude', isMagnitudeCleared ? null : coercedNewMagnitude);
    criteriaObject.add(
      'ceilingHeightType',
      isMagnitudeCleared ? undefined : newCeilingHeightFilter.type,
    );

    if (currentMagnitude !== coercedNewMagnitude) {
      if (flags['search-analytics-refactor']) {
        ceilingHeightFilterInteraction({
          value: coercedNewMagnitude,
          currentFilters: criteriaObject.toAnalyticsProperties(),
          units: 'imperial',
          filterType: newCeilingHeightFilter.type,
        });
      } else {
        dispatch(
          actions.ceilingHeightFilterChanged(buildAnalyticStringValue(newCeilingHeightFilter)),
        );
      }
    }
    criteriaObject.pushToHistory();
  };

  // FIXME: Either add the exhaustive deps or delete this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCriteria = useMemo(() => debounce(pushCriteriaUpdates, debounceLength), []);

  const handleBlur = () => {
    debouncedUpdateCriteria.cancel();
    pushCriteriaUpdates(ceilingHeightFilter, criteria);
  };

  const handleInputChange = (event, unitOfMeasurement) => {
    const ceilingHeightValue = event.target.value.replace(/[^0-9]/g, '').substring(0, 10);
    const isMagnitudeCleared = ceilingHeightValue === '';

    let coercedNewMagnitude;
    if (unitOfMeasurement === 'feet') {
      coercedNewMagnitude = isMagnitudeCleared ? null : +Number(ceilingHeightValue);
      coercedNewMagnitude += +(Number(ceilingHeight.inches) / 12).toFixed(2);
    } else if (unitOfMeasurement === 'inches') {
      if (ceilingHeightValue < 0 || ceilingHeightValue > 11) {
        return;
      }
      coercedNewMagnitude = isMagnitudeCleared ? null : Number(ceilingHeightValue) / 12;
      coercedNewMagnitude += +Number(ceilingHeight.feet).toFixed(2);
    }

    setCeilingHeight(convertMagnitudeToUnits(coercedNewMagnitude));
    setCeilingHeightFilter(() => {
      const newCeilingHeightFilter = {
        type: ceilingHeightType,
        magnitude: coercedNewMagnitude,
      };
      debouncedUpdateCriteria.cancel();
      debouncedUpdateCriteria(newCeilingHeightFilter, criteria);
      return newCeilingHeightFilter;
    });
  };

  return (
    <fieldset>
      <label htmlFor="ceiling-height-magnitude" className={s.ceilingHeightInputLabel}>
        {t('ceilingHeight.minHeight')}
      </label>
      <div className={s.minimumFeetContainer}>
        <RawInput
          ref={ceilingHeightFeetRef}
          className={s.ceilingHeightInput}
          units={t('ceilingHeight.units.ft')}
          name="ceiling-height-magnitude"
          onChange={e => handleInputChange(e, 'feet')}
          onBlur={handleBlur}
          value={!isNull(ceilingHeight.feet) ? ceilingHeight.feet : ''}
          data-testid="ceiling-height-magnitude-feet"
        />
        <RawInput
          ref={ceilingHeightInchesRef}
          className={s.ceilingHeightInput}
          units={t('ceilingHeight.units.in')}
          name="ceiling-height-magnitude"
          onChange={e => handleInputChange(e, 'inches')}
          onBlur={handleBlur}
          value={!isNull(ceilingHeight.inches) ? ceilingHeight.inches : ''}
          data-testid="ceiling-height-magnitude-inches"
        />
      </div>
    </fieldset>
  );
};
export default React.memo(ImperialCeilingHeightInput);
