import { EVENTS, PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';

const homepageLoadedAction = {
  type: 'HOMEPAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.homepage,
      },
    },
  },
} as const;

type HomepageLoadedAction = typeof homepageLoadedAction;
const homepageLoaded = (): HomepageLoadedAction => homepageLoadedAction;

type SearchLocationSelectedAction = {
  type: 'SEARCH_LOCATION_SELECTED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Filter Interaction';
        action: 'apply filter';
        sourcePage: 'homepage';
        sourceContent: 'search module';
        filterName: 'location';
        filterLocation: string;
      };
    };
  };
};

const searchLocationSelected = ({
  filterLocation,
}: {
  filterLocation: string;
}): SearchLocationSelectedAction => ({
  type: 'SEARCH_LOCATION_SELECTED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        sourcePage: PARAMETERS.homepage,
        sourceContent: PARAMETERS.searchModule,
        filterName: PARAMETERS.location,
        filterLocation,
      },
    },
  },
});

type SearchBuildingSelectedAction = {
  type: 'SEARCH_BUILDING_SELECTED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourcePage: 'homepage';
        sourceContent: 'search module';
        destination: 'building page';
      };
    };
  };
};

const searchBuildingSelected = (): SearchBuildingSelectedAction => ({
  type: 'SEARCH_BUILDING_SELECTED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.homepage,
        sourceContent: PARAMETERS.searchModule,
        destination: PARAMETERS.buildingPage,
      },
    },
  },
});

type ViewAllExclusiveListingsClickedAction = {
  type: 'VIEW_ALL_EXCLUSIVE_LISTINGS_CLICKED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourcePage: 'homepage';
        sourceContent: 'first to market search link';
        destination: 'search results page';
      };
    };
  };
};

const viewAllExclusiveListingsClicked = (): ViewAllExclusiveListingsClickedAction => ({
  type: 'VIEW_ALL_EXCLUSIVE_LISTINGS_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.homepage,
        sourceContent: PARAMETERS.firstToMarketSearchLink,
        destination: PARAMETERS.searchResultsPage,
      },
    },
  },
});

type ValuePropAndFeatureTriptychClickedAction = {
  type: 'VALUE_PROP_AND_FEATURE_TRIPTYCH_CLICKED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourcePage: 'homepage';
        sourceContent: 'value prop';
        destination: 'external';
        url: string;
      };
    };
  };
};

const valuePropAndFeatureTriptychClicked = ({
  url,
}: {
  url: string;
}): ValuePropAndFeatureTriptychClickedAction => ({
  type: 'VALUE_PROP_AND_FEATURE_TRIPTYCH_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.homepage,
        sourceContent: PARAMETERS.valueProp,
        destination: PARAMETERS.external,
        url,
      },
    },
  },
});

type FirstToMarketCarouselPaginatedAction = {
  type: 'FIRST_TO_MARKET_CAROUSEL_PAGINATED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Carousel Interaction';
        action: 'paginate';
        sourcePage: 'homepage';
        sourceContent: 'first to market carousel';
      };
    };
  };
};

const firstToMarketCarouselPaginated = (): FirstToMarketCarouselPaginatedAction => ({
  type: 'FIRST_TO_MARKET_CAROUSEL_PAGINATED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.carouselInteraction,
        action: PARAMETERS.paginate,
        sourcePage: PARAMETERS.homepage,
        sourceContent: PARAMETERS.firstToMarketCarousel,
      },
    },
  },
});

type FirstToMarketCarouselCardClickedAction = {
  type: 'FIRST_TO_MARKET_CAROUSEL_CARD_CLICKED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourcePage: 'homepage';
        sourceContent: 'first to market carousel';
        destination: 'listing page';
        isExclusiveListing: boolean;
      };
    };
  };
};

const firstToMarketCarouselCardClicked = (): FirstToMarketCarouselCardClickedAction => ({
  type: 'FIRST_TO_MARKET_CAROUSEL_CARD_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.homepage,
        sourceContent: PARAMETERS.firstToMarketCarousel,
        destination: PARAMETERS.listingPage,
        isExclusiveListing: true,
      },
    },
  },
});

export default {
  homepageLoaded,
  searchLocationSelected,
  searchBuildingSelected,
  viewAllExclusiveListingsClicked,
  valuePropAndFeatureTriptychClicked,
  firstToMarketCarouselPaginated,
  firstToMarketCarouselCardClicked,
};

export type Actions =
  | FirstToMarketCarouselPaginatedAction
  | HomepageLoadedAction
  | SearchBuildingSelectedAction
  | FirstToMarketCarouselCardClickedAction
  | SearchLocationSelectedAction
  | ValuePropAndFeatureTriptychClickedAction;
