import { useState } from 'react';
import * as React from 'react';
import { Field } from 'formik';
import { isNil } from 'lodash';
import type { ActionMeta } from 'react-select/src/types';
import FieldWrapper from '../FieldWrapper';
import RawCreatableSelect from '../RawCreatableSelect';
import type { OptionType } from '../Dropdowns/types';

export type Props = {
  name: string;
  labelText?: string;
  required?: boolean;
  placeholder?: string;
  labelClass?: string;
  containerClass?: string;
};

const CreatableSelect = ({
  name,
  labelText,
  required = false,
  placeholder,
  labelClass,
  containerClass,
}: Props) => {
  interface FieldInput {
    value: any;
    name: string;
  }

  const [value, setValue] = useState([] as any);
  const [inputValue, setInputValue] = useState('');
  const createOption = (label: string) => ({
    label,
    value: label,
  });
  const optionAlreadyExists = (optionValue: string) =>
    value.find(option => option.label === optionValue);
  const updateFieldValues = (form: any, field: FieldInput, newOption: string) => {
    const currentCollection = value.map(option => option.value);
    const newCollection = isNil(newOption)
      ? currentCollection
      : currentCollection.concat(newOption);
    form.setFieldValue(field.name, newCollection);
    form.setFieldTouched(field.name);
  };
  const handleCreate = (optionValue: any) => {
    const newOption = createOption(optionValue);
    setValue([...value, newOption]);
    setInputValue('');
  };

  const handleBlur = (field: FieldInput, form: any) => {
    if (!inputValue) return;
    if (optionAlreadyExists(inputValue)) return;
    handleCreate(inputValue);
    updateFieldValues(form, field, inputValue);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    field: FieldInput,
    form: any,
  ) => {
    if (!inputValue) return;
    if (optionAlreadyExists(inputValue)) return;
    switch (event.key) {
      case ' ':
      case ',':
        handleCreate(inputValue);
        updateFieldValues(form, field, inputValue);
        event.preventDefault();
        break;
      case 'Enter':
      case 'Tab':
        handleCreate(inputValue);
        break;
      default:
    }
  };
  const handleInputChange = (newValue: string) => setInputValue(newValue);
  const handleChange = (
    newValue: { value: string; label: string }[],
    actionMeta: ActionMeta<OptionType>,
    form: any,
    field: any,
  ) => {
    // https://stackoverflow.com/questions/56613496/issue-with-values-formik/57686391#57686391
    // https://github.com/formium/formik/issues/529
    // this duplicated behavior here with setFieldValue() exists due to peculiarities with the
    // form form retaining stale values
    const cleanNewValue = isNil(newValue) ? [] : newValue;
    const collection = cleanNewValue.map(option => option.value);
    setValue(cleanNewValue);
    form.setFieldValue(field.name, collection);
    form.setFieldTouched(field.name);
  };

  return (
    <FieldWrapper
      name={name}
      labelText={labelText}
      required={required}
      labelClass={labelClass}
      containerClass={containerClass}
    >
      <Field name={name}>
        {({ field, form }) => (
          <RawCreatableSelect
            name={field.name}
            placeholder={placeholder}
            inputValue={inputValue}
            value={value}
            onChange={(newValue: any, actionMeta: any) =>
              handleChange(newValue, actionMeta, form, field)
            }
            onBlur={() => handleBlur(field, form)}
            onInputChange={handleInputChange}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
              handleKeyDown(event, field, form)
            }
          />
        )}
      </Field>
    </FieldWrapper>
  );
};

export default CreatableSelect;
