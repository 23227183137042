import dataSource from '@components/layouts/Admin/utils/amenitySetHelper';
import { ListingAmenitiesMetadata, ListingDataCollectionMetadata } from '@root/types';

export const formatDataCollectionPayload = (body): ListingDataCollectionMetadata => ({
  ceilingType: body.ceilingType,
  ceilingTypeDataSource: dataSource(body, 'ceilingType'),
  currentSpaceCondition: body.currentSpaceCondition,
  currentSpaceConditionDataSource: dataSource(body, 'currentSpaceCondition'),
  exposedColumns: body.exposedColumns,
  exposedColumnsDataSource: dataSource(body, 'exposedColumns'),
  exposureEast: body.exposureEast,
  exposureEastDataSource: dataSource(body, 'exposureEast'),
  exposureNorth: body.exposureNorth,
  exposureNorthDataSource: dataSource(body, 'exposureNorth'),
  exposureSouth: body.exposureSouth,
  exposureSouthDataSource: dataSource(body, 'exposureSouth'),
  exposureWest: body.exposureWest,
  exposureWestDataSource: dataSource(body, 'exposureWest'),
  floorType: body.floorType,
  floorTypeDataSource: dataSource(body, 'floorType'),
  gym: body.gym,
  gymDataSource: dataSource(body, 'gym'),
  mainPantryKitchen: body.mainPantryKitchen,
  mainPantryKitchenDataSource: dataSource(body, 'mainPantryKitchen'),
  officeFronts: body.officeFronts,
  officeFrontsDataSource: dataSource(body, 'officeFronts'),
  officeLayout: body.officeLayout,
  officeLayoutDataSource: dataSource(body, 'officeLayout'),
  privateBathroomsShower: body.privateBathroomsShower,
  privateBathroomsShowerDataSource: dataSource(body, 'privateBathroomsShower'),
  receptionCeilingType: body.receptionCeilingType,
  receptionCeilingTypeDataSource: dataSource(body, 'receptionCeilingType'),
  receptionFloorType: body.receptionFloorType,
  receptionFloorTypeDataSource: dataSource(body, 'receptionFloorType'),
  viewLandmarkDetail: body.viewLandmarkDetail,
  viewLandmarkDetailDataSource: dataSource(body, 'viewLandmarkDetail'),
  windowSidesCount: body.windowSidesCount,
  windowSidesCountDataSource: dataSource(body, 'windowSidesCount'),
  windowViews: body.windowViews,
  windowViewsDataSource: dataSource(body, 'windowViews'),
});

export const formatAmenityPayload = (body): ListingAmenitiesMetadata => ({
  breakOutSpace: body.breakOutSpace,
  breakOutSpaceDataSource: dataSource(body, 'breakOutSpace'),
  furnished: body.furnished,
  furnishedDataSource: dataSource(body, 'furnished'),
  keyCardSpaceEntry: body.keyCardSpaceEntry,
  keyCardSpaceEntryDataSource: dataSource(body, 'keyCardSpaceEntry'),
  passengerElevatorCount: body.passengerElevatorCount,
  passengerElevatorCountDataSource: dataSource(body, 'passengerElevatorCount'),
  phoneBooths: body.phoneBooths,
  phoneBoothsDataSource: dataSource(body, 'phoneBooths'),
  privateOutdoorSpace: body.privateOutdoorSpace,
  privateOutdoorSpaceDataSource: dataSource(body, 'privateOutdoorSpace'),
  serviceElevatorCount: body.serviceElevatorCount,
  serviceElevatorCountDataSource: dataSource(body, 'serviceElevatorCount'),
  sharedPantry: body.sharedPantry,
  sharedPantryDataSource: dataSource(body, 'sharedPantry'),
});
