import { PowerpointBuildingEntry, PowerpointTourbook } from '@root/types';
import { PptxUtils } from './globals';
import { createListing } from './listing';
import { generateBuildingPageOneSlide } from './buildingPageOne';
import { generateBuildingPageTwoSlide } from './buildingPageTwo';

export const createBuilding = (
  tourbook: PowerpointTourbook,
  building: PowerpointBuildingEntry,
  utils: PptxUtils,
) => {
  generateBuildingPageOneSlide({ tourbook, building, utils });
  generateBuildingPageTwoSlide({ tourbook, building, utils });

  // create listing slides
  building.listings.forEach(tourbookListing => {
    createListing(utils, tourbook, tourbookListing, building);
  });
};

export default createBuilding;
