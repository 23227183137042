import Header from '@components/shared/Header';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import V2Button from '@components/shared/V2Button';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import DataPoint from '@components/layouts/Truva/Listing/AboutBuilding/DataPoint';
import ExpandableDescription from '@components/shared/ExpandableDescription';
import routes from '@root/routes';
import type { Address, BuildingInListing, SpaceProvider } from '@root/types';
import darkContentCss from '@styles/darkContentSection.module.less';
import useAltText from '@root/shared/useAltText';
import SafeRender from '@components/shared/SafeRender';
import UtmAwareLink from '@components/shared/UtmAwareLink/UtmAwareLink';
import s from './AboutBuilding.module.less';

type Props = {
  building: BuildingInListing;
  viewBuildingClicked: () => void;
  spaceProvider: SpaceProvider | null;
  address: Address;
};

const AboutBuilding = ({ building, viewBuildingClicked, spaceProvider, address }: Props) => {
  const flags = useFlags();
  const { t } = useTranslation('listing');

  const { getAltText } = useAltText({ address });

  const notableTenants = building.notableTenants || [];

  const sortedTenants = notableTenants.slice().sort((a, b) => a.localeCompare(b));

  return (
    <div className={cn(s.row, darkContentCss.darkContentSection)}>
      <div>
        <div className={cn(s.aboutHeaderRow)}>
          <Header labelQaTestId="about-building-heading">{t('aboutBuilding.title')}</Header>
          <UtmAwareLink to={routes.building(building.slug)} dataQaTestid="view-building">
            <V2Button
              className={s.viewBuildingButton}
              type="tertiary"
              size="medium"
              onClick={() => {
                viewBuildingClicked();
              }}
            >
              {t('aboutBuilding.viewBuilding')}
            </V2Button>
          </UtmAwareLink>
          <UtmAwareLink className="hidden mobile:inline" to={routes.building(building.slug)}>
            <Button
              className={s.viewBuildingMobileButton}
              onClick={() => {
                viewBuildingClicked();
              }}
            >
              {t('aboutBuilding.viewBuildingShort')}
            </Button>
          </UtmAwareLink>
        </div>
        <Row gutter={16}>
          {building.photo ? (
            <Col lg={8} md={12} sm={24}>
              <Row>
                <Col>
                  <div className={s.heroImageContainer} data-qa-testid="building-thumbnail">
                    <UtmAwareLink to={routes.building(building.slug)}>
                      <img
                        className={s.heroImage}
                        src={building.photo.mediumPath}
                        alt={getAltText({ type: building.photo.type })}
                      />
                    </UtmAwareLink>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : null}
          <Col lg={building.photo ? 16 : 24} md={building.photo ? 12 : 24} sm={24}>
            <Row className={s.infoSection}>
              <Col>
                <h4 className={s.buildingName} data-qa-testid="about-building-name">
                  {address.buildingName || address.street}
                </h4>
              </Col>
            </Row>
            <Row className={s.infoSection}>
              <Col>
                <ExpandableDescription labelQaTestId="building-description">
                  {flags['market.building-descriptions-support-rich-text'] ? (
                    <SafeRender unsafeContent={building.htmlDescription || ''}>
                      <span />
                    </SafeRender>
                  ) : (
                    building.description
                  )}
                </ExpandableDescription>
              </Col>
            </Row>
            {isEmpty(notableTenants) ? null : (
              <p className={s.notableTenants}>
                <strong className={s.notableTenantsText}>
                  {t('aboutBuilding.notableTenants')}
                </strong>
                {sortedTenants.join(', ')}
              </p>
            )}
            <Row data-qa-testid="building-details">
              <DataPoint
                label={t('aboutBuilding.numberOfFloors')}
                value={building.numberOfFloors}
              />
              <DataPoint label={t('aboutBuilding.yearRenovated')} value={building.yearRenovated} />
              <DataPoint label={t('aboutBuilding.yearBuilt')} value={building.yearBuilt} />
              <DataPoint
                label={t('aboutBuilding.rentableArea')}
                value={
                  building.rentableArea ? t('units:area', { area: building.rentableArea }) : null
                }
              />
              <DataPoint
                label={t('aboutBuilding.buildingOwner')}
                value={building.owningCompany}
                href={spaceProvider?.slug ? routes.landlords(spaceProvider.slug) : null}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutBuilding;
