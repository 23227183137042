import * as React from 'react';
import cn from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import css from './ListItem.module.less';

type Props = {
  children: React.ReactNode;
  className?: string;
  draggingClassName?: string;
  sortableId: string;
  index: number;
  isDragDisabled?: boolean;
};

const SortableListItem = ({
  index,
  sortableId,
  draggingClassName,
  isDragDisabled = false,
  ...restProps
}: Props) => {
  return (
    <Draggable draggableId={sortableId} index={index} isDragDisabled={isDragDisabled}>
      {(draggable, snapshot) => (
        <li
          id={sortableId}
          ref={draggable.innerRef}
          className={cn(
            css.draggableTableRow,
            snapshot.isDragging ? draggingClassName : null,
            restProps.className,
          )}
          {...draggable.draggableProps}
          {...draggable.dragHandleProps}
          {...restProps}
        />
      )}
    </Draggable>
  );
};

export default SortableListItem;
