import { TourbookListing, Locale } from '@root/types';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { parse } from 'date-fns';
import { reformatDateString } from '@shared/dateUtils';
import { dateFormat } from '@shared/TranslationHelpers/formatDate';
import useEnv from '@root/shared/useEnv';
import { camelCase } from 'lodash';
import useAnalytics, { TourbookSourceContent } from '@shared/useAnalytics';
import { useBreakpoint } from '@shared/useBreakpoints';
import cn from 'classnames';
import useAltText from '@root/shared/useAltText';
import s from './ListingItem.module.less';
import { getDisplayPricePerArea, scrollToTourbookListingCard } from '../helper';

type Props = {
  listing: TourbookListing;
  sourceContent: TourbookSourceContent;
  draggable?: boolean;
  withinDropdown?: boolean;
};

// eslint-disable-next-line react/jsx-no-literals
const EmptyField = () => <span className={s.emptyField}>–</span>;

const ListingItem = ({
  listing,
  sourceContent,
  draggable = false,
  withinDropdown = false,
}: Props) => {
  const { t } = useTranslation('tourbook');
  const flags = useFlags();
  const { locale } = useEnv();
  const { isMobile } = useBreakpoint();
  const { tourbookInteraction, PARAMETERS } = useAnalytics();

  const {
    address,
    askingRentInMoneyPerAreaPerPeriod,
    availableToday,
    building,
    condition,
    dateAvailable,
    id,
    leaseType,
    name,
    photos,
    size,
    external,
    minAskingRentInMoneyPerAreaPerPeriod,
    maxAskingRentInMoneyPerAreaPerPeriod,
  } = listing;

  const hasSize = size && size?.magnitude > 0;

  const price = getDisplayPricePerArea({
    t,
    askingRent: askingRentInMoneyPerAreaPerPeriod,
    minAskingRent: minAskingRentInMoneyPerAreaPerPeriod,
    maxAskingRent: maxAskingRentInMoneyPerAreaPerPeriod,
    acceptRange: flags['tourbook-asking-rent'],
  });

  const { getAltText } = useAltText({ address });

  const getAvailabilityMessage = () => {
    if (availableToday) return t('externalListing.availableNow');
    if (!dateAvailable) return '–';
    const asDate = parse(dateAvailable, 'yyyy-MM-dd', new Date());
    if (!(asDate instanceof Date && !Number.isNaN(asDate.getTime()))) return '-';
    return reformatDateString({
      value: dateAvailable,
      beforeFormat: 'yyyy-MM-dd',
      afterFormat: dateFormat(locale as Locale),
    });
  };

  const firstPhoto = photos[0];

  const isAskingRentEdited =
    hasSize && minAskingRentInMoneyPerAreaPerPeriod && flags['tourbook-asking-rent'] && !external;

  return (
    <div
      className={cn(s.listingItem, draggable && s.draggable, withinDropdown && s.withinDropdown)}
      role="button"
      onClick={() => {
        tourbookInteraction({
          action: PARAMETERS.selectListing,
          actionType: 'TOURBOOK_SELECT_LISTING',
          sourceContent,
        });
        scrollToTourbookListingCard({ listingId: id, draggable, isMobile });
      }}
    >
      <div className={s.photoAndAddressContainer}>
        {firstPhoto ? (
          <img
            src={firstPhoto.smallPath}
            alt={getAltText({
              type: firstPhoto.type,
              options: { roomName: firstPhoto.description },
            })}
          />
        ) : null}
        <div className={s.addressContainer}>
          <span className={s.buildingName}>
            {address.buildingName ? `${address.buildingName} - ${address.street}` : address.street}
          </span>
          <span className={s.listingName}>
            {name}
            {building.neighborhood && (
              <span className={s.neighborhood}>{building.neighborhood}</span>
            )}
          </span>
        </div>
      </div>
      <div className={s.areaContainer}>
        <span className={s.area}>{t('units:area', { area: size })}</span>
      </div>
      <div className={s.priceContainer} data-testid="priceContainer">
        <span className={s.price}>{hasSize && price ? price : t('listingCard.nullBaseRent')}</span>
        {isAskingRentEdited ? (
          <span className={s.price} data-testid="editedAskingRentIndicator">
            {' *'}
          </span>
        ) : null}
      </div>
      <div className={s.availabilityContainer} data-testid="availabilityContainer">
        <span className={s.availability}>{getAvailabilityMessage()}</span>
      </div>
      <div className={s.conditionContainer} data-testid="conditionContainer">
        {condition ? (
          <span className={s.condition}>{t(`spaceConditions:${camelCase(condition)}`)}</span>
        ) : (
          <EmptyField />
        )}
      </div>
      <div className={s.leaseTypeContainer}>
        <span className={s.leaseType}>{t(`listingCard.leaseTypes.${leaseType}`)}</span>
      </div>
    </div>
  );
};

export default ListingItem;
