import { useTranslation } from 'react-i18next';
import { AdminListing } from '@root/types';
import { Formik, Form } from 'formik';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { SubmitButton } from '@components/shared/forms';
import { Prompt } from 'react-router-dom';
import validationSchema from './validationSchema';
import css from './styles.module.less';
import ListingDataCollectionList from './ListingDataCollectionList';

type Props = {
  listing: AdminListing;
  updateListing: (
    listingId: string,
    body: AdminListing & { amenitySet: {} },
    message: string,
    successCallback?: () => void,
  ) => void;
};

const DataCollectionListingForm = ({ listing, updateListing }: Props) => {
  const { t } = useTranslation('admin');

  const initialValues = { ...listing, amenitySet: listing.amenitySet };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={JSON.parse(JSON.stringify(initialValues, (k, v) => (v === null ? '' : v)))}
        onSubmit={async (values, actions) => {
          const params = JSON.parse(JSON.stringify(values, (k, v) => (v === '' ? null : v)));
          await updateListing(listing.id, params, t('listing.updateSuccess'));
          actions.setSubmitting(false);
        }}
        validationSchema={validationSchema(t)}
      >
        {({ dirty, isValid, values }) => (
          <Form className={css.form}>
            <FormThemeContextProvider theme="vertical">
              <section className={css.dataCollection}>
                <div className="font-title">{t('listing.dataCollection.title')}</div>
                <div className={css.dataCollection}>
                  <ListingDataCollectionList
                    ceilingType={values.amenitySet.ceilingType}
                    currentSpaceCondition={values.amenitySet.currentSpaceCondition}
                    exposedColumns={values.amenitySet.exposedColumns}
                    exposureEast={values.amenitySet.exposureEast}
                    exposureNorth={values.amenitySet.exposureNorth}
                    exposureSouth={values.amenitySet.exposureSouth}
                    exposureWest={values.amenitySet.exposureWest}
                    floorType={values.amenitySet.floorType}
                    gym={values.amenitySet.gym}
                    mainPantryKitchen={values.amenitySet.mainPantryKitchen}
                    officeFronts={values.amenitySet.officeFronts}
                    officeLayout={values.amenitySet.officeLayout}
                    privateBathroomsShower={values.amenitySet.privateBathroomsShower}
                    receptionCeilingType={values.amenitySet.receptionCeilingType}
                    receptionFloorType={values.amenitySet.receptionFloorType}
                    viewLandmarkDetail={values.amenitySet.viewLandmarkDetail}
                    windowSidesCount={values.amenitySet.windowSidesCount}
                    windowViews={values.amenitySet.windowViews}
                  />
                </div>
              </section>
              {dirty ? (
                <div className={css.submitFooter}>
                  <SubmitButton disabled={!isValid}>{t('common:saveChanges')}</SubmitButton>
                </div>
              ) : null}
            </FormThemeContextProvider>
            <Prompt when={dirty} message={t('common:prompt.unsavedChanges')} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DataCollectionListingForm;
