import { useEffect } from 'react';
import useEnv from '@shared/useEnv';

let didInit = false;
function initMunchkin(marketoAccountId: string) {
  if (didInit === false && (window as any).Munchkin) {
    didInit = true;
    (window as any).Munchkin.init(marketoAccountId);
  }
}

const useMarketo = () => {
  const { marketoAccountId } = useEnv();
  useEffect(() => {
    if (!marketoAccountId) return;

    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//munchkin.marketo.net/munchkin.js';
    (s as any).onreadystatechange = function stateChange() {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        initMunchkin(marketoAccountId);
      }
    };
    s.onload = initMunchkin.bind(s, marketoAccountId);
    document.head.appendChild(s);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useMarketo;
