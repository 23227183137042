import * as React from 'react';
import cn from 'classnames';
import s from './Checkbox.module.less';

export type CheckboxSize = 'medium' | 'small' | 'xSmall';
export type Props = {
  children?: React.ReactNode;
  checked?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  className?: string;
  value?: any;
  horizontal?: boolean;
  labelClassName?: string;
  size?: CheckboxSize;
  disabled?: boolean;
  'data-testid'?: string;
};

const RawCheckbox = ({
  checked = false,
  onChange,
  name,
  className = '',
  children,
  onBlur,
  value,
  horizontal = false,
  labelClassName,
  size = 'medium',
  disabled = false,
  'data-testid': dataTestId = name,
}: Props) => (
  <label className={cn(s.checkbox, horizontal && s.horizontal, labelClassName)} htmlFor={name}>
    {horizontal ? <span className={cn(s.labelText, s[size], className)}>{children}</span> : null}
    <input
      id={name}
      type="checkbox"
      name={name}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      data-testid={dataTestId}
    />
    <span className={cn(s.innerCheckbox, s[size], disabled && s.disabled)} />
    {!horizontal ? (
      <span className={cn(s.labelText, s[size], disabled && s.disabled, className)}>
        {children}
      </span>
    ) : null}
  </label>
);

export default RawCheckbox;
