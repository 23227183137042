import { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import Button from '@components/shared/V2Button';
import { useTranslation } from 'react-i18next';
import api, { csrfTokenHeader } from '@shared/api';
import UploadStatus from './UploadStatus';

const sendToApi =
  ({ route, additionalParams, successCallback, formUpload }) =>
  async ({ file, onSuccess, onError }) => {
    if (formUpload) {
      successCallback({ file });
      onSuccess();
      return;
    }

    const formData = new FormData();
    Object.keys(additionalParams).forEach(key => {
      formData.append(key, additionalParams[key]);
    });
    formData.append('file', file);
    const response = await api.fetch(route, {
      method: 'POST',
      body: formData,
      headers: { ...csrfTokenHeader() },
    });

    if (response.ok) {
      successCallback({ file });
      onSuccess();
    } else {
      onError();
    }
  };

const Uploader = ({
  accept,
  helpText,
  successCallback,
  route,
  additionalParams,
  formUpload,
  errorMessage,
}) => {
  const [uploadStatus, setUploadStatus] = useState(null);

  const onUploadChange = ({ file }) => {
    if (formUpload) {
      setUploadStatus(file.name);
    } else {
      setUploadStatus(file.status);
    }
  };

  const { t } = useTranslation('common');

  return (
    <div data-testid="uploader">
      <Upload
        accept={accept}
        customRequest={sendToApi({ route, additionalParams, successCallback, formUpload })}
        onChange={onUploadChange}
        showUploadList={false}
      >
        <Button icon="uploadOutlined" type="secondary">
          {t('upload')}
        </Button>
        <span style={{ marginLeft: 10 }}>{helpText}</span>
      </Upload>
      <div>
        <UploadStatus status={uploadStatus} errorMessage={errorMessage} />
      </div>
    </div>
  );
};

Uploader.defaultProps = {
  additionalParams: {},
  uploadProps: {},
  successCallback: () => undefined,
  helpText: '',
  accept: undefined,
  formUpload: false,
  route: null,
  errorMessage: null,
};

Uploader.propTypes = {
  route: PropTypes.string,
  additionalParams: PropTypes.shape({
    [PropTypes.string]: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  accept: PropTypes.string,
  uploadProps: PropTypes.shape({
    name: PropTypes.string,
    accept: PropTypes.string,
  }),
  helpText: PropTypes.node,
  successCallback: PropTypes.func,
  formUpload: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default Uploader;
