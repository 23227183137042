import { useFormikContext } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import useEnv from '@shared/useEnv';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';

type Props = {
  className?: string;
  handleOnChange?: () => void;
} & Omit<FieldWrapperProps, 'children'>;

export default function ReCAPTCHAField({ className, name, containerClass, handleOnChange }: Props) {
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const { googleReCAPTCHAKey } = useEnv();

  return (
    <FieldWrapper {...{ name, containerClass }}>
      <ReCAPTCHA
        className={className}
        sitekey={googleReCAPTCHAKey!}
        onChange={token => {
          setFieldValue(name, token);
          setFieldTouched(name);
          if (handleOnChange) handleOnChange();
        }}
      />
    </FieldWrapper>
  );
}
