import AsyncSelect from 'react-select/async';
import { ActionMeta, createFilter, OptionTypeBase } from 'react-select';
import { ValueType, OptionType } from './types';
import DropdownStyles from './DropdownStyles';
import SelectComponents from './SelectComponents';

type Props = {
  loadOptions: (val: string, callback: Function) => void;
  name: string;
  isLoading?: boolean;
  onChange: (val: ValueType<OptionTypeBase>, action: ActionMeta<OptionType>) => void;
  onFocus?: () => void;
  placeholder: string;
  value: OptionType[];
  defaultOptions: OptionType[] | boolean;
  disabled?: boolean;
};

const AsyncFilterDropdown = ({
  defaultOptions = true,
  loadOptions,
  name,
  isLoading = false,
  onChange,
  onFocus,
  placeholder,
  value,
  disabled = false,
}: Props) => {
  // This height allows first 5 options in the menu to be visible
  // when passing through SelectComponents as the option components
  const defaultMaxMenuHeight = 208;
  const filterConfig = { trim: false };

  return (
    <AsyncSelect
      closeMenuOnSelect={false}
      components={SelectComponents}
      defaultOptions={defaultOptions}
      filterOption={createFilter(filterConfig)}
      hideSelectedOptions={false}
      inputId={name}
      isClearable={false}
      isLoading={isLoading}
      isMulti
      loadOptions={loadOptions}
      maxMenuHeight={defaultMaxMenuHeight}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      styles={DropdownStyles}
      tabSelectsValue={false}
      value={value}
      onFocus={onFocus}
      isDisabled={disabled}
    />
  );
};

export default AsyncFilterDropdown;
