import { connect, ConnectedProps } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '@shared/useBreakpoints';
import Transportation from '@components/shared/Transportation';
import Location from '@components/shared/Location';
import Divider from '@components/shared/Divider';
import { getSessionStorageValues } from '@shared/sessionStorageForSiteVisitors';
import ExpandableDescription from '@components/shared/ExpandableDescription';
import actions, { Thumbnail } from '@store/actions/listingPage';
import routes from '@root/routes';
import Breadcrumbs from '@components/shared/Breadcrumbs';
import { Listing, LastInquiry } from '@root/types';
import { OnlyInDesktopTablet, OnlyInMobile, ErrorBoundary } from '@components/shared';
import { PARAMETERS } from '@root/tracking/constants';
import Bartender from '@components/shared/Bartender';
import Amenities from '@components/shared/Amenity/Amenities';
import { useState } from 'react';
import InquiryForm, { SiteVisitorInquiryForm } from '@components/shared/InquiryForm';
import useContactViewChooser from '@components/shared/ContactList/useContactViewChooser';
import BrochureDownloadLink from '@components/shared/BrochureDownloadLink/BrochureDownloadLink';
import SpaceProviderInfo from '@components/shared/SpaceProviderInfo';
import FloatingActionButton from '@components/shared/FloatingActionButton';
import useAnalytics from '@shared/useAnalytics';
import useCurrentUser from '@shared/useCurrentUser';
import SafeRender from '@components/shared/SafeRender';
import ListingLocation, { ListingLocationDeprecated } from './ListingLocation';
import BasicInfo from './BasicInfo';
import Financials from './Financials';
import Gallery from './Gallery';
import AboutBuilding from './AboutBuilding';
import Rooms from './Rooms';
import VideoTour from './VideoTour';
import s from './Layout.module.less';

export type PassedProps = {
  listing: Listing;
  lastInquiry?: LastInquiry;
};

const videoSourceContent = {
  unset: PARAMETERS.spotlightTour,
  video: PARAMETERS.spaceVideo,
  three_d_tour: PARAMETERS.space3DTour,
};

const mapDispatch = {
  contactClicked: () => actions.contactClicked(),
  galleryPaginationClicked: () => actions.galleryPaginationClicked(),
  galleryThumbnailClicked: (thumbnailType: keyof Thumbnail = 'clickThumbnail') =>
    actions.galleryThumbnailClicked(thumbnailType),
  leaseTermChanged: () => actions.leaseTermChanged(),
  leaseTermDropdownOpened: () => actions.leaseTermDropdownOpened(),
  lightboxFloorPlanClicked: () => actions.lightboxFloorPlanClicked(),
  lightboxOpened: () => actions.lightboxOpened(),
  lightboxPaginationClicked: () => actions.lightboxPaginationClicked(),
  lightboxThumbnailClicked: () => actions.lightboxThumbnailClicked(),
  viewBuildingClicked: () => actions.viewBuildingClicked(),
};

const connector = connect(null, mapDispatch);
export type ReduxProps = ConnectedProps<typeof connector>;

const Layout = ({
  galleryPaginationClicked,
  galleryThumbnailClicked,
  leaseTermChanged,
  leaseTermDropdownOpened,
  lightboxOpened,
  listing,
  viewBuildingClicked,
  lastInquiry,
}: PassedProps & ReduxProps) => {
  const { t } = useTranslation('listing');
  const flags = useFlags();
  const { currentUser } = useCurrentUser();
  const userIsLoggedIn = !!currentUser;
  const { brochureInteraction, clickToPage } = useAnalytics();
  const { isMobile, isDesktop } = useBreakpoint();

  const { arrivedOnMarketLink, arrivedOnShareLink, sessionStorageListingSharer } =
    getSessionStorageValues();

  const listingSharerForDisplay = sessionStorageListingSharer ? [sessionStorageListingSharer] : [];

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const {
    showContactList,
    showLinkSharerContactInfo,
    ContactList,
    LinkSharerContactInfo,
    makeContactEmailSubject,
  } = useContactViewChooser({ arrivedOnMarketLink, arrivedOnShareLink });

  const areCommonRequirementsToShowInquiryMet =
    !['off_market', 'onboarding'].includes(listing.status) && listing?.listingAgents?.length > 0;

  const isLoggedOutUserFromShareLink =
    !userIsLoggedIn && (arrivedOnMarketLink || arrivedOnShareLink);

  const showBreadcrumbLink = userIsLoggedIn || !isLoggedOutUserFromShareLink;

  const showInquiryForm = areCommonRequirementsToShowInquiryMet && userIsLoggedIn;
  const showSiteVisitorInquiryForm =
    areCommonRequirementsToShowInquiryMet && !userIsLoggedIn && !arrivedOnShareLink;

  const contactEmailSubject: string = makeContactEmailSubject({
    address: [listing.buildingName, listing.name].join(' '),
    userName: currentUser?.firstName,
  });

  return (
    <>
      <OnlyInDesktopTablet preventRendering>
        <Breadcrumbs
          items={[
            ...(listing.marketName
              ? [
                  {
                    content: listing.marketName,
                    link: showBreadcrumbLink ? routes.search(listing.marketSlug!) : '',
                    onClick: () => {
                      clickToPage({
                        actionType: 'SEARCH_BREADCRUMB_CLICKED',
                        sourcePage: PARAMETERS.listingPage,
                        sourceContent: PARAMETERS.breadcrumb,
                        destination: PARAMETERS.searchResultsPage,
                      });
                    },
                  },
                ]
              : []),
            {
              persistTrackingParams: true,
              content: listing.address.buildingName || listing.address.street,
              link: showBreadcrumbLink ? routes.building(listing.buildingSlug) : '',
              onClick: () => {
                clickToPage({
                  actionType: 'BUILDING_BREADCRUMB_CLICKED',
                  sourcePage: PARAMETERS.listingPage,
                  sourceContent: PARAMETERS.breadcrumb,
                  destination: PARAMETERS.buildingPage,
                });
              },
            },
            { content: listing.address.floorAndSuite! },
          ]}
        />
      </OnlyInDesktopTablet>
      <Bartender
        drinks={[
          {
            url: routes.admin.listingPage(listing.id),
            tooltipText: 'Admin Page of the Listing',
            icon: 'desktop',
          },
          {
            url: routes.admin.building(listing.building.id),
            tooltipText: 'Admin Page of the Building',
            icon: 'buildings-multiple',
          },
        ]}
        className={s.bartender}
      />
      <div data-testid="listingLayout" className={s.grid}>
        <section className={s.rightContent}>
          <ErrorBoundary>
            <OnlyInMobile preventRendering>
              {flags['outreach.branding-general'] ? (
                <div data-testid="location-mobile">
                  <ListingLocation listing={listing} />
                </div>
              ) : (
                <div data-testid="location-mobile">
                  <ListingLocationDeprecated listing={listing} />
                </div>
              )}
            </OnlyInMobile>
            <Gallery
              address={listing.address}
              photos={listing.photos}
              video={listing.video}
              tourEmbedUrl={listing.tourEmbedUrl}
              isCurrentlyExclusive={listing.isCurrentlyExclusive}
              lightboxOpened={lightboxOpened}
              galleryPaginationClicked={galleryPaginationClicked}
              galleryThumbnailClicked={galleryThumbnailClicked}
              imagesZipUrl={listing.createImageZipUrl}
              onLightboxOpened={() => setIsLightboxOpen(true)}
              onLightboxClosed={() => setIsLightboxOpen(false)}
            />
            {listing.spaceProvider || (listing.brochures && listing.brochures.length > 0) ? (
              <div
                className={
                  flags['outreach.branding-general'] &&
                  'mt-2 md:pb-2 desktop:inline-flex desktop:items-start'
                }
              >
                {listing.spaceProvider &&
                  (flags['outreach.branding-general'] ? (
                    <div className="flex whitespace-nowrap font-subtitle">
                      {listing.spaceProvider.name}
                    </div>
                  ) : (
                    <SpaceProviderInfo
                      spaceProvider={listing.spaceProvider}
                      sourcePage={PARAMETERS.listingPage}
                    />
                  ))}
                {isDesktop &&
                  listing.spaceProvider &&
                  listing.brochures?.length > 0 &&
                  flags['outreach.branding-general'] && (
                    <figure className="mx-2 mt-0.5 h-[22px] w-[1px] bg-black-035"></figure>
                  )}
                {listing.brochures && listing.brochures.length > 0 ? (
                  <div
                    className={
                      flags['outreach.branding-general']
                        ? 'pt-1 desktop:mt-0.5 desktop:inline-flex desktop:items-baseline desktop:pt-0'
                        : cn(
                            'mb-3 mr-[22px] grid grid-cols-3 tablet:grid-cols-none mobile:grid-cols-none',
                            !listing.spaceProvider && 'mt-3',
                          )
                    }
                  >
                    {listing.brochures.map(brochure => (
                      <div
                        key={brochure.url}
                        className="pb-1 pr-2 desktop:pb-0 tablet:last:pb-0 mobile:last:pb-0"
                      >
                        <BrochureDownloadLink
                          brochure={brochure}
                          onClick={() =>
                            brochureInteraction({
                              listing,
                              sourcePage: PARAMETERS.listingPage,
                              otherAttributes: {
                                attachmentId: brochure.id,
                                attachmentName: brochure.name,
                              },
                            })
                          }
                          lineWrap={2}
                        />
                      </div>
                    ))}
                  </div>
                ) : null}
                {isMobile && flags['outreach.branding-general'] && (
                  <hr className="my-2 text-general-neutral-tertiary" />
                )}
              </div>
            ) : null}
            {!flags['market.listing-space-descriptions-support-rich-text'] &&
              listing.description && (
                <ExpandableDescription
                  className={cn(!listing.spaceProvider && listing.brochures.length < 1 && 'mt-3')}
                >
                  {listing.description}
                </ExpandableDescription>
              )}
            {flags['market.listing-space-descriptions-support-rich-text'] &&
              listing.descriptionHtml && (
                <ExpandableDescription
                  className={cn(!listing.spaceProvider && listing.brochures.length < 1 && 'mt-3')}
                >
                  <SafeRender unsafeContent={listing.descriptionHtml || ''}>
                    <span />
                  </SafeRender>
                </ExpandableDescription>
              )}
            <OnlyInMobile preventRendering>
              <BasicInfo listing={listing} />
              <Financials
                leaseTermDropdownOpened={leaseTermDropdownOpened}
                leaseTermChanged={leaseTermChanged}
                baseRents={listing.baseRents}
                listingArea={listing.area}
              />
            </OnlyInMobile>
            {showLinkSharerContactInfo && flags['outreach.branding-general'] && isMobile && (
              <div className="mt-5">
                <LinkSharerContactInfo
                  contacts={listingSharerForDisplay}
                  emailSubjectName={contactEmailSubject}
                  title={t('tourThisSpace')}
                  sourcePage={PARAMETERS.listingPage}
                />
              </div>
            )}
            {showContactList && (
              <ContactList
                contacts={listing.listingAgents}
                title={t('tourThisSpace')}
                emailSubjectName={contactEmailSubject}
                removeBottomBorder={Object.values(listing.amenities).every(amenityVal => {
                  if (typeof amenityVal === 'boolean') return !amenityVal;
                  if (typeof amenityVal === 'number') return amenityVal === 0;
                  return amenityVal === null || amenityVal === 'No';
                })}
              />
            )}
            <div className="mt-7">
              <Amenities amenities={listing.amenities} />
            </div>
            <Rooms listing={listing} />
          </ErrorBoundary>
        </section>
        <section className={s.leftContent}>
          <ErrorBoundary>
            <OnlyInDesktopTablet preventRendering>
              {flags['outreach.branding-general'] ? (
                <div data-testid="location-desktoptablet">
                  <ListingLocation listing={listing} />
                </div>
              ) : (
                <div data-testid="location-desktoptablet">
                  <ListingLocationDeprecated listing={listing} />
                </div>
              )}
              <BasicInfo listing={listing} />
              <Financials
                leaseTermDropdownOpened={leaseTermDropdownOpened}
                leaseTermChanged={leaseTermChanged}
                baseRents={listing.baseRents}
                listingArea={listing.area}
              />
            </OnlyInDesktopTablet>
            {showInquiryForm && (
              <>
                <OnlyInDesktopTablet preventRendering>
                  <InquiryForm
                    listing={listing}
                    key={listing.id}
                    sourceContent={PARAMETERS.inquiryForm}
                    sourcePage={PARAMETERS.listingPage}
                    lastInquiry={lastInquiry}
                  />
                </OnlyInDesktopTablet>
                <OnlyInMobile preventRendering>
                  <FloatingActionButton
                    icon="email"
                    disabled={!listing?.listingAgents?.length}
                    getDialogContent={closeDialog => (
                      <InquiryForm
                        listing={listing}
                        key={listing.id}
                        dialog
                        closeDialog={closeDialog}
                        sourceContent={PARAMETERS.inquiryForm}
                        sourcePage={PARAMETERS.listingPage}
                        lastInquiry={lastInquiry}
                      />
                    )}
                  />
                </OnlyInMobile>
              </>
            )}
            {showLinkSharerContactInfo &&
              (flags['outreach.branding-general'] ? (
                <OnlyInDesktopTablet preventRendering>
                  <LinkSharerContactInfo
                    contacts={listingSharerForDisplay}
                    emailSubjectName={contactEmailSubject}
                    title={t('tourThisSpace')}
                    sourcePage={PARAMETERS.listingPage}
                  />
                </OnlyInDesktopTablet>
              ) : (
                <LinkSharerContactInfo
                  contacts={listingSharerForDisplay}
                  emailSubjectName={contactEmailSubject}
                  title={t('tourThisSpace')}
                  sourcePage={PARAMETERS.listingPage}
                />
              ))}
            {showSiteVisitorInquiryForm && (
              <>
                <OnlyInDesktopTablet>
                  <SiteVisitorInquiryForm
                    listing={listing}
                    sourceContent={PARAMETERS.siteVisitorInquiryForm}
                    sourcePage={PARAMETERS.listingPage}
                    isMarketShare={arrivedOnMarketLink}
                    contactListIsShown={showContactList}
                  />
                </OnlyInDesktopTablet>
                <OnlyInMobile>
                  <FloatingActionButton
                    icon="email"
                    getDialogContent={closeDialog => (
                      <SiteVisitorInquiryForm
                        listing={listing}
                        sourceContent={PARAMETERS.siteVisitorInquiryForm}
                        sourcePage={PARAMETERS.listingPage}
                        isMarketShare={arrivedOnMarketLink}
                        closeDialog={closeDialog}
                        isDialog
                        contactListIsShown={showContactList}
                      />
                    )}
                  />
                </OnlyInMobile>
              </>
            )}
          </ErrorBoundary>
        </section>
      </div>
      <OnlyInDesktopTablet preventRendering>
        <Divider />
      </OnlyInDesktopTablet>
      <ErrorBoundary>
        <VideoTour
          video={listing.video}
          tourEmbedUrl={listing.tourEmbedUrl}
          sourceContent={
            flags['media-analytics']
              ? PARAMETERS.spotlightTour
              : videoSourceContent[listing.virtualTourSpotlight]
          }
          sourcePage={PARAMETERS.listingPage}
          virtualTourSpotlight={listing.virtualTourSpotlight}
          isVisible={!isLightboxOpen}
        />
        <Divider className={cn(!listing.video && !listing.tourEmbedUrl && s.hidden)} />
        <div className={s.locationContainer}>
          <Location
            address={listing.locationAddress}
            latitude={listing.building.latitude}
            longitude={listing.building.longitude}
            hideLocation={listing.hideLocation}
          />
          <Transportation transitOptions={listing.building.transitOptions} />
        </div>
        {listing.hideLocation ? null : (
          <AboutBuilding
            address={listing.address}
            viewBuildingClicked={viewBuildingClicked}
            building={listing.building}
            spaceProvider={listing.spaceProvider}
          />
        )}
      </ErrorBoundary>
    </>
  );
};

export default connector(Layout);
