import { connect } from 'react-redux';
import actions from '@store/actions/buildingPage';
import RawImages from './RawImages';

const mapDispatchToProps = dispatch => ({
  lightboxPaginationClicked: () => dispatch(actions.lightboxPaginationClicked()),
  lightboxThumbnailClicked: () => dispatch(actions.lightboxThumbnailClicked()),
  mediaAssetOpened: () => dispatch(actions.mediaAssetOpened()),
});

export default connect(null, mapDispatchToProps)(RawImages);
