import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import cn from 'classnames';
import { Listing, InquiryFormFields, LastInquiry } from '@root/types';
import { CustomIcon, Notification } from '@components/shared';
import { Form, SubmitButton } from '@components/shared/forms';
import { defaultInquiryMessage } from '@components/layouts/Truva/AccountSettings/InquirySettingsForm';
import routes from '@root/routes';
import Card from '@components/shared/Card';
import useAnalytics, { InquirySourceContent, InquirySourcePage } from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import useCurrentUser from '@root/shared/useCurrentUser';
import useCreateInquiryMutation from '@root/shared/useCreateInquiryMutation';
import { isInIframe } from '@root/shared/iframeUtils';
import InquiryFormBody from './InquiryFormBody';
import useSuccessCard from './useSuccessCard';
import s from './InquiryForm.module.less';

type Props = {
  listing: Listing | null;
  sourceContent: InquirySourceContent;
  sourcePage: InquirySourcePage;
  dialog?: boolean;
  closeDialog?: () => void;
  isOnPreview?: boolean;
  lastInquiry?: LastInquiry;
};

const InquiryForm = ({
  listing,
  dialog = false,
  closeDialog,
  isOnPreview = false,
  sourcePage,
  sourceContent,
  lastInquiry,
}: Props) => {
  const { t } = useTranslation('common');
  const { inquiryInteraction } = useAnalytics();
  const { name: listingName, id: listingId, address } = listing || {};
  const { buildingName, street } = address || {};
  const [isSendingInquiry, setIsSendingInquiry] = useState<boolean>(false);
  const { SuccessCard, successCardProps, showSuccessCard, shouldShowSuccessCard } = useSuccessCard({
    isOnPreview,
  });
  const queryClient = useQueryClient();

  const listingAddress = [buildingName || street, listingName].join(' ');
  const { currentUser } = useCurrentUser();
  const disabled = !listing?.listingAgents?.length || isInIframe();

  const validationSchema = Yup.object().shape({
    receiverIds: Yup.array().min(1, t('inquiry.error.requiredTo')),
    message: Yup.string().required(t('inquiry.error.requiredMessage')),
  });

  const sendInquiryMutation = useCreateInquiryMutation({
    listingId: listingId!,
    isQuickInquiry: false,
  });

  const handleSendInquiry = async (fields: InquiryFormFields) => {
    setIsSendingInquiry(true);
    if (dialog && closeDialog) closeDialog();

    const body = {
      ...fields,
      subject: t('inquiry.subject.field', {
        address: listingAddress,
        first_name: currentUser?.firstName,
      }),
    };

    const onSuccess = () => {
      if (listing) {
        inquiryInteraction({
          action: PARAMETERS.inquiry.send,
          actionType: 'INQUIRY_SEND_MESSAGE',
          listing,
          sourcePage,
          sourceContent,
        });
        queryClient.invalidateQueries([routes.api.listing(listing.id)]);
      }
      Notification.info({
        title: t('inquiry:sentInquiryTitle'),
        text: t('inquiry:sentInquiryBody'),
      });
      showSuccessCard();
    };

    const onError = () => {
      if (listing) {
        inquiryInteraction({
          action: PARAMETERS.inquiry.error,
          actionType: 'INQUIRY_ERROR',
          listing,
          sourcePage,
          sourceContent,
        });
      }

      Notification.error({
        title: t('inquiry.failureNotification.title'),
        text: t('inquiry.failureNotification.body'),
      });
    };

    sendInquiryMutation.mutate({
      body,
      onSuccess,
      onError,
    });

    setIsSendingInquiry(false);
  };

  const defaultMessage: string =
    currentUser?.generalInquiryDefaultMessage ||
    defaultInquiryMessage(t, 'inquiry.defaultMessage', currentUser!);

  const submitInquiryButton = (
    <SubmitButton
      size={isOnPreview ? 'small' : 'medium'}
      icon="envelopeOutline"
      className={cn(s.submitButton, isOnPreview && s.defaultSubmitButton)}
      disabled={!listing || isSendingInquiry || disabled}
      data-qa-testid="send-inquiry"
    >
      {isSendingInquiry ? t('inquiry.sending.buttonText') : t('inquiry.sendInquiry')}
    </SubmitButton>
  );
  const closeButton = (
    <button title="Close Overlay" onClick={closeDialog} type="button">
      <CustomIcon type="close" />
    </button>
  );

  const contentClassName = isOnPreview ? s.cardContent : s.largeCardContent;
  const cardClassName = isOnPreview ? s.card : s.largeCard;

  return (
    <>
      {shouldShowSuccessCard && !dialog ? (
        <SuccessCard {...successCardProps} />
      ) : (
        <Form
          data-testid="inquiryForm"
          id="inquiryForm"
          initialValues={{
            receiverIds: listing?.listingAgents.length ? [listing.listingAgents[0]?.id] : [],
            message: defaultMessage,
          }}
          validationSchema={validationSchema}
          onSubmit={async values => {
            handleSendInquiry(values);
          }}
        >
          <Card
            title={t('inquiry.title')}
            headerElement={dialog ? closeButton : submitInquiryButton}
            className={cardClassName}
            headerContainerClassName={isOnPreview ? '' : s.headerContainer}
            contentClassName={contentClassName}
            labelQaTestId="inquiry-heading"
          >
            {listing && disabled && (
              <div className={s.disabledOverlayContainer}>
                <div className={s.disabledOverlayMask} />
                <div className={s.disabledOverlayMessage}>
                  {t(`inquiry.${isInIframe() ? 'disabledMessageInIframe' : 'disabledMessage'}`)}
                </div>
              </div>
            )}
            <div
              className={cn({
                [s.mainContent]: isOnPreview,
                [s.largeMainContent]: !isOnPreview,
              })}
            >
              <InquiryFormBody
                listing={listing}
                disabled={disabled}
                isOnPreview={isOnPreview}
                sourcePage={sourcePage}
                sourceContent={sourceContent}
                lastInquiry={lastInquiry}
              />
              {dialog && submitInquiryButton}
            </div>
          </Card>
        </Form>
      )}
    </>
  );
};

export default InquiryForm;
