import { useEffect } from 'react';
import routes from '@root/routes';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbs } from '@components/shared/withBreadcrumbs';
import { useParams, Prompt } from 'react-router-dom';
import { notification } from 'antd';
import Container from '@components/shared/Admin/Container';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { AdminFormSubmitter, Spinner, Breadcrumbs } from '@components/shared';
import { Select, TextInput } from '@components/shared/forms';
import useEnums from '@root/shared/useEnums';
import { AdminUser, MarketSlug } from '@root/types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, update } from '@root/shared/typedApi';
import { mapValues } from 'lodash';
import Layout from '../Layout/Layout';

const UserPage = () => {
  const { id: userId } = useParams<{ id: string }>();
  const { t } = useTranslation('admin');
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      t('breadcrumbs.admin'),
      { content: t('breadcrumbs.users'), link: routes.admin.userSearch },
      t('breadcrumbs.userEdit'),
    ]);
  }, [setBreadcrumbs, t]);

  const {
    data: adminUser,
    isLoading,
    refetch: refetchAdminUser,
  } = useQuery<AdminUser>([routes.api.admin.user(userId)], () =>
    get(routes.api.admin.user(userId)),
  );

  const updateAdminUserMutation = useMutation(
    async ({ user }: { user: Partial<AdminUser>; onSuccess: () => void; onError: () => void }) => {
      await update(routes.api.admin.user(userId), user);
    },
    {
      onSuccess: async (_, { onSuccess }) => {
        refetchAdminUser();
        onSuccess();
      },
      onError: async (response: any, { onError }) => {
        const { messages } = response.body;
        notification.error({ message: messages.join(', ') });
        onError();
      },
    },
  );

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('user.userSettingsForm.validations.firstName')),
    lastName: Yup.string().required(t('user.userSettingsForm.validations.lastName')),
    role: Yup.string().required(t('user.userSettingsForm.validations.professionalRole')),
    marketSlug: Yup.string(),
    phoneNumber: Yup.string()
      .nullable()
      .typeError(t('user.userSettingsForm.validations.phoneNumber'))
      .matches(/^[0-9]+$/, t('user.userSettingsForm.validations.phoneNumber'))
      .min(8, t('user.userSettingsForm.validations.phoneNumberLength'))
      .max(20, t('user.userSettingsForm.validations.phoneNumberLength')),
    phoneExtension: Yup.string()
      .nullable()
      .typeError(t('user.userSettingsForm.validations.phoneExtension'))
      .matches(/^[0-9]+$/, t('user.userSettingsForm.validations.phoneExtension')),
  });

  const { markets, roles } = useEnums();

  const marketOptions: Array<{ value: MarketSlug; label: string }> = markets
    .filter(market => market.isPublic)
    .sort((marketA, marketB) =>
      t(`common:markets.${marketA.id}`).localeCompare(t(`common:markets.${marketB.id}`)),
    )
    .map(market => ({
      value: market.id,
      label: t(`common:markets.${market.id}`),
    }));

  const professionalRoles: Array<{ value: string; label: string }> = roles.map(role => ({
    value: role,
    label: t(`common:roles.${role}`),
  }));

  if (isLoading) {
    return <Spinner />;
  }

  const updateAdminUser = (formValues, { resetForm }) => {
    updateAdminUserMutation.mutate({
      user: {
        id: userId,
        ...mapValues(formValues, formValue => (formValue === '' ? null : formValue)),
      },
      onSuccess: () => {
        notification.success({ message: t('user.successfulEdit') });
      },
      onError: () => {
        resetForm();
      },
    });
  };

  const {
    admin: { userSearch: adminUserSearchRoute },
  } = routes;

  return (
    <Layout>
      <Breadcrumbs
        className="mb-1"
        items={[
          { content: t('breadcrumbs.admin') },
          { content: t('breadcrumbs.users'), link: adminUserSearchRoute },
          {
            content: `${adminUser?.firstName} ${adminUser?.lastName}`,
          },
        ]}
      />
      <Container title={t('user.editPageTitle')} extraPadding>
        <Formik
          enableReinitialize
          initialValues={{
            userId: adminUser?.id,
            email: adminUser?.email,
            firstName: adminUser?.firstName,
            lastName: adminUser?.lastName,
            phoneNumber: adminUser?.phoneNumber || '',
            phoneExtension: adminUser?.phoneExtension || '',
            jobTitle: adminUser?.jobTitle || '',
            role: adminUser?.role,
            marketSlug: adminUser?.marketSlug,
          }}
          validationSchema={validationSchema}
          onSubmit={updateAdminUser}
          validateOnChange={false}
        >
          {({ dirty }) => (
            <Form id="userSettingsForm" data-testid="userSettingsForm">
              <FormThemeContextProvider theme="vertical">
                <div className="flex flex-col">
                  <>
                    <TextInput
                      name="userId"
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      containerClass="w-full flex !flex-row space-x-2"
                      className="!w-[400px]"
                      labelText={t('user.userSettingsForm.labels.id')}
                      disabled
                    />
                  </>
                  <>
                    <TextInput
                      name="email"
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      containerClass="w-full flex !flex-row space-x-2"
                      className="!w-[400px]"
                      labelText={t('user.userSettingsForm.labels.email')}
                      disabled
                    />
                  </>
                  <div>
                    <TextInput
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      name="firstName"
                      containerClass="w-full flex !flex-row space-x-2"
                      className="!w-[400px]"
                      labelText={t('user.userSettingsForm.labels.firstName')}
                      required
                    />
                  </div>
                  <div>
                    <TextInput
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      name="lastName"
                      containerClass="w-full flex !flex-row space-x-2"
                      className="!w-[400px]"
                      labelText={t('user.userSettingsForm.labels.lastName')}
                      required
                    />
                  </div>
                  <div>
                    <TextInput
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      name="phoneNumber"
                      containerClass="w-full flex !flex-row space-x-2"
                      className="!w-[400px]"
                      labelText={t('user.userSettingsForm.labels.phoneNumber')}
                    />
                  </div>
                  <div>
                    <TextInput
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      name="phoneExtension"
                      containerClass="w-full flex !flex-row space-x-2"
                      className="!w-[400px]"
                      labelText={t('user.userSettingsForm.labels.phoneExtension')}
                    />
                  </div>
                  <div>
                    <TextInput
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      name="jobTitle"
                      containerClass="w-full flex !flex-row space-x-2"
                      className="!w-[400px]"
                      labelText={t('user.userSettingsForm.labels.jobTitle')}
                    />
                  </div>
                  <div>
                    <Select
                      data-testid="role"
                      containerClass="w-full flex !flex-row space-x-2"
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      className="!w-[400px]"
                      name="role"
                      labelText={t('user.userSettingsForm.labels.professionalRole')}
                      options={professionalRoles}
                      required
                      isClearable={false}
                    />
                  </div>
                  <div>
                    <Select
                      data-testid="market"
                      containerClass="w-full flex !flex-row space-x-2 !mb-0"
                      labelClass="font-body-medium !w-1/12 py-1 text-right"
                      className="!w-[400px]"
                      name="marketSlug"
                      options={marketOptions}
                      labelText={t('user.userSettingsForm.labels.market')}
                      placeholder={t('common:onboarding.marketPlaceholder')}
                      required
                      isClearable={false}
                    />
                  </div>
                </div>
                <AdminFormSubmitter />
              </FormThemeContextProvider>
              <Prompt when={dirty} message={t('common:prompt.unsavedChanges')} />
            </Form>
          )}
        </Formik>
      </Container>
      <div className="h-[80px]" /> {/* extra margin so save button doesn't overlap form */}
    </Layout>
  );
};

export default UserPage;
