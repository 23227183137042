import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Tabs as AntdTabs } from 'antd';
import { parse } from 'qs';
import TabNav from './TabNav';
import s from './Tabs.module.less';

export type TabOption = {
  value: string;
  label: string;
};
type Props = { tabs: TabOption[]; children: React.ReactNode; className?: string };

const useTabs = (tabs: string[]) => {
  const location = useLocation();
  const history = useHistory();

  const queryString = parse(location.search, { ignoreQueryPrefix: true });
  let currentTab: string = (queryString.page as string | undefined) || tabs[0];

  if (!tabs.includes(currentTab)) {
    /* eslint-disable no-console */
    console.warn(
      `Invalid page queryParam given. Expected ${tabs
        .map(tab => `'${tab}'`)
        .join(' or ')}. Given '${currentTab}'`,
    );
    /* eslint-enable no-console */
    [currentTab] = tabs;
  }

  const changeTab = (tab: string) => {
    history.push(`${location.pathname}?page=${tab}`);
  };

  return { changeTab, currentTab };
};

// eslint-disable-next-line prefer-destructuring
export const TabPane = AntdTabs.TabPane;

export function Tabs({ tabs, children, className }: Props) {
  const tabValues = tabs.map(t => t.value);
  const { currentTab, changeTab } = useTabs(tabValues);

  return (
    <AntdTabs
      className={s.wrapper}
      onChange={changeTab}
      activeKey={currentTab}
      animated={false}
      renderTabBar={() => (
        <TabNav ulClassName={className} tabs={tabs} currentTab={currentTab} changeTab={changeTab} />
      )}
    >
      {children}
    </AntdTabs>
  );
}
