import useEnv from '@shared/useEnv';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { ValidIconTypes } from '@components/shared/CustomIcon';
import { ButtonSize } from '@components/shared/V2Button/Button';
import MultimediaUploader, {
  MIME_TYPES,
  DefaultCloudinaryResponseType,
  DefaultPhotoUploadType,
} from '@components/shared/Admin/MultimediaUploader';

type Props<CloudinaryResponseType, PhotoUploadType> = {
  marketplaceRequest: (
    files: (DefaultPhotoUploadType | PhotoUploadType)[],
  ) => PromiseLike<{ ok: boolean }>;
  multiple?: boolean;
  onSuccess: () => void;
  cloudinaryTransformer?: (
    cloudinaryJson: DefaultCloudinaryResponseType | CloudinaryResponseType,
  ) => DefaultPhotoUploadType | PhotoUploadType;
  buttonClass?: string;
  buttonIcon?: ValidIconTypes;
  buttonSize?: ButtonSize;
  uploadSizeLimitInMb?: number;
};

const PhotosUploader = <CloudinaryResponseType, PhotoUploadType>({
  marketplaceRequest,
  multiple,
  onSuccess,
  cloudinaryTransformer,
  buttonClass = '',
  buttonIcon = undefined,
  buttonSize = undefined,
  uploadSizeLimitInMb = undefined,
}: Props<CloudinaryResponseType, PhotoUploadType>) => {
  const { cloudinaryCloud } = useEnv();
  const { t } = useTranslation('admin');
  const handleSuccess = ({ totalFilesCount }) => {
    onSuccess();
    notification.success({
      message: t('media.photoUploadSuccess', { count: totalFilesCount }),
    });
  };
  const handleError = ({ totalFilesCount }) => {
    notification.error({
      message: t('media.photoUploadFailure', { count: totalFilesCount }),
    });
  };
  return (
    <MultimediaUploader
      accept={MIME_TYPES.imageOnly}
      marketplaceRequest={marketplaceRequest}
      cloudinaryRoute={`https://api.cloudinary.com/v1_1/${cloudinaryCloud}/image/upload`}
      multiple={multiple}
      onSuccess={handleSuccess}
      onError={handleError}
      buttonText={t('media.uploadPhotos')}
      cloudinaryTransformer={cloudinaryTransformer}
      buttonIcon={buttonIcon}
      buttonSize={buttonSize}
      buttonClass={buttonClass}
      uploadSizeLimitInMb={uploadSizeLimitInMb}
    />
  );
};

export default PhotosUploader;
