/* eslint-disable max-len */
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import CountWithSource from '@components/shared/Admin/DataCollectionFields/CountWithSource';
import YesNoMaybeWithSource from '@components/shared/Admin/DataCollectionFields/YesNoMaybeWithSource';

type Props = {
  barbershop: boolean | string;
  barAvailable: boolean | string;
  bikeStorageAvailable: boolean | string;
  cafeAvailable: boolean | string;
  carWash: boolean | string;
  cctv: boolean | string;
  commonAreaWifi: boolean | string;
  communalLobbySpace: boolean | string;
  communalOutdoorSpace: boolean | string;
  communalRoofAccess: boolean | string;
  conferenceCenter: boolean | string;
  controlledElevatorAccess: boolean | string;
  coveredParking: boolean | string;
  destinationDispatchElevators: boolean | string;
  electricVehicleChargingStationsCount: number;
  eventSpace: boolean | string;
  flexSpaceAvailable: boolean | string;
  foodServiceAvailable: boolean | string;
  generalStoreAvailable: boolean | string;
  gymAvailable: boolean | string;
  keyCardBuildingEntry: boolean | string;
  loadingDock: boolean | string;
  lobbyAttendant: boolean | string;
  lobbyBeverageAvailable: boolean | string;
  lobbyFoodAvailable: boolean | string;
  lockerRooms: boolean | string;
  messengerCenter: boolean | string;
  networkingEvents: boolean | string;
  optionToAddInternetServiceProvider: boolean | string;
  parkingAvailable: boolean | string;
  parkingGarage: boolean | string;
  passengerElevatorCount: number;
  petsAllowed: boolean | string;
  pharmacyAvailable: boolean | string;
  propertyManagerOnSite: boolean | string;
  restaurantAvailable: boolean | string;
  serviceElevatorCount: number;
  showers: boolean | string;
  shuttle: boolean | string;
  spaAvailable: boolean | string;
  storageAvailable: boolean | string;
  tenantLounge: boolean | string;
  tenantPortalApp: boolean | string;
  touchFreeElevatorAccess: boolean | string;
  touchlessTurnstileEntry: boolean | string;
  transportationAccessible: boolean | string;
  turnstiles: boolean | string;
  twentyFourSevenAccess: boolean | string;
  twentyFourSevenSecurity: boolean | string;
  urgentCareDoctorOnsite: boolean | string;
  valetParking: boolean | string;
  yogaStudioAvailable: boolean | string;
};

const BuildingAmenitiesList = ({ ...props }: Props) => {
  const { t } = useTranslation('admin');

  const amenities = [
    {
      label: t('building.twentyFourSevenAccess'),
      render: (
        <YesNoMaybeWithSource
          fieldName="twentyFourSevenAccess"
          key="twentyFourSevenAccess"
          pageName="building"
          value={props.twentyFourSevenAccess}
        />
      ),
    },
    {
      label: t('building.twentyFourSevenSecurity'),
      render: (
        <YesNoMaybeWithSource
          fieldName="twentyFourSevenSecurity"
          key="twentyFourSevenSecurity"
          pageName="building"
          value={props.twentyFourSevenSecurity}
        />
      ),
    },
    {
      label: t('building.barAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="barAvailable"
          key="barAvailable"
          pageName="building"
          value={props.barAvailable}
        />
      ),
    },
    {
      label: t('building.barbershop'),
      render: (
        <YesNoMaybeWithSource
          fieldName="barbershop"
          key="barbershop"
          pageName="building"
          value={props.barbershop}
        />
      ),
    },
    {
      label: t('building.bikeStorageAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="bikeStorageAvailable"
          key="bikeStorageAvailable"
          pageName="building"
          value={props.bikeStorageAvailable}
        />
      ),
    },
    {
      label: t('building.cafeAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="cafeAvailable"
          key="cafeAvailable"
          pageName="building"
          value={props.cafeAvailable}
        />
      ),
    },
    {
      label: t('building.carWash'),
      render: (
        <YesNoMaybeWithSource
          fieldName="carWash"
          key="carWash"
          pageName="building"
          value={props.carWash}
        />
      ),
    },
    {
      label: t('building.cctv'),
      render: (
        <YesNoMaybeWithSource fieldName="cctv" key="cctv" pageName="building" value={props.cctv} />
      ),
    },
    {
      label: t('building.commonAreaWifi'),
      render: (
        <YesNoMaybeWithSource
          fieldName="commonAreaWifi"
          key="commonAreaWifi"
          pageName="building"
          value={props.commonAreaWifi}
        />
      ),
    },
    {
      label: t('building.communalLobbySpace'),
      render: (
        <YesNoMaybeWithSource
          fieldName="communalLobbySpace"
          key="communalLobbySpace"
          pageName="building"
          value={props.communalLobbySpace}
        />
      ),
    },
    {
      label: t('building.communalOutdoorSpace'),
      render: (
        <YesNoMaybeWithSource
          fieldName="communalOutdoorSpace"
          key="communalOutdoorSpace"
          pageName="building"
          value={props.communalOutdoorSpace}
        />
      ),
    },
    {
      label: t('building.communalRoofAccess'),
      render: (
        <YesNoMaybeWithSource
          fieldName="communalRoofAccess"
          key="communalRoofAccess"
          pageName="building"
          value={props.communalRoofAccess}
        />
      ),
    },
    {
      label: t('building.conferenceCenter'),
      render: (
        <YesNoMaybeWithSource
          fieldName="conferenceCenter"
          key="conferenceCenter"
          pageName="building"
          value={props.conferenceCenter}
        />
      ),
    },
    {
      label: t('building.controlledElevatorAccess'),
      render: (
        <YesNoMaybeWithSource
          fieldName="controlledElevatorAccess"
          key="controlledElevatorAccess"
          pageName="building"
          value={props.controlledElevatorAccess}
        />
      ),
    },
    {
      label: t('building.coveredParking'),
      render: (
        <YesNoMaybeWithSource
          fieldName="coveredParking"
          key="coveredParking"
          pageName="building"
          value={props.coveredParking}
        />
      ),
    },
    {
      label: t('building.destinationDispatchElevators'),
      render: (
        <YesNoMaybeWithSource
          fieldName="destinationDispatchElevators"
          key="destinationDispatchElevators"
          pageName="building"
          value={props.destinationDispatchElevators}
        />
      ),
    },
    {
      label: t('building.electricVehicleChargingStationsCount'),
      render: (
        <div key="electricVehicleChargingStationsCount">
          <CountWithSource
            fieldName="electricVehicleChargingStationsCount"
            key="electricVehicleChargingStationsCount"
            pageName="building"
            value={props.electricVehicleChargingStationsCount}
          />{' '}
        </div>
      ),
    },
    {
      label: t('building.eventSpace'),
      render: (
        <YesNoMaybeWithSource
          fieldName="eventSpace"
          key="eventSpace"
          pageName="building"
          value={props.eventSpace}
        />
      ),
    },
    {
      label: t('building.gymAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="gymAvailable"
          key="gymAvailable"
          pageName="building"
          value={props.gymAvailable}
        />
      ),
    },
    {
      label: t('building.flexSpaceAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="flexSpaceAvailable"
          key="flexSpaceAvailable"
          pageName="building"
          value={props.flexSpaceAvailable}
        />
      ),
    },
    {
      label: t('building.foodServiceAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="foodServiceAvailable"
          key="foodServiceAvailable"
          pageName="building"
          value={props.foodServiceAvailable}
        />
      ),
    },
    {
      label: t('building.generalStoreAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="generalStoreAvailable"
          key="generalStoreAvailable"
          pageName="building"
          value={props.generalStoreAvailable}
        />
      ),
    },
    {
      label: t('building.keyCardBuildingEntry'),
      render: (
        <YesNoMaybeWithSource
          fieldName="keyCardBuildingEntry"
          key="keyCardBuildingEntry"
          pageName="building"
          value={props.keyCardBuildingEntry}
        />
      ),
    },
    {
      label: t('building.loadingDock'),
      render: (
        <YesNoMaybeWithSource
          fieldName="loadingDock"
          key="loadingDock"
          pageName="building"
          value={props.loadingDock}
        />
      ),
    },
    {
      label: t('building.lobbyAttendant'),
      render: (
        <YesNoMaybeWithSource
          fieldName="lobbyAttendant"
          key="lobbyAttendant"
          pageName="building"
          value={props.lobbyAttendant}
        />
      ),
    },
    {
      label: t('building.lobbyBeverageAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="lobbyBeverageAvailable"
          key="lobbyBeverageAvailable"
          pageName="building"
          value={props.lobbyBeverageAvailable}
        />
      ),
    },
    {
      label: t('building.lobbyFoodAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="lobbyFoodAvailable"
          key="lobbyFoodAvailable"
          pageName="building"
          value={props.lobbyFoodAvailable}
        />
      ),
    },
    {
      label: t('building.lockerRooms'),
      render: (
        <YesNoMaybeWithSource
          fieldName="lockerRooms"
          key="lockerRooms"
          pageName="building"
          value={props.lockerRooms}
        />
      ),
    },
    {
      label: t('building.messengerCenter'),
      render: (
        <YesNoMaybeWithSource
          fieldName="messengerCenter"
          key="messengerCenter"
          pageName="building"
          value={props.messengerCenter}
        />
      ),
    },
    {
      label: t('building.networkingEvents'),
      render: (
        <YesNoMaybeWithSource
          fieldName="networkingEvents"
          key="networkingEvents"
          pageName="building"
          value={props.networkingEvents}
        />
      ),
    },
    {
      label: t('building.optionToAddInternetServiceProvider'),
      render: (
        <YesNoMaybeWithSource
          fieldName="optionToAddInternetServiceProvider"
          key="optionToAddInternetServiceProvider"
          pageName="building"
          value={props.optionToAddInternetServiceProvider}
        />
      ),
    },
    {
      label: t('building.parkingAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="parkingAvailable"
          key="parkingAvailable"
          pageName="building"
          value={props.parkingAvailable}
        />
      ),
    },
    {
      label: t('building.parkingGarage'),
      render: (
        <YesNoMaybeWithSource
          fieldName="parkingGarage"
          key="parkingGarage"
          pageName="building"
          value={props.parkingGarage}
        />
      ),
    },
    {
      label: t('building.passengerElevatorCount'),
      render: (
        <CountWithSource
          fieldName="passengerElevatorCount"
          key="passengerElevatorCount"
          pageName="building"
          value={props.passengerElevatorCount}
        />
      ),
    },
    {
      label: t('building.petsAllowed'),
      render: (
        <YesNoMaybeWithSource
          fieldName="petsAllowed"
          key="petsAllowed"
          pageName="building"
          value={props.petsAllowed}
        />
      ),
    },
    {
      label: t('building.pharmacyAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="pharmacyAvailable"
          key="pharmacyAvailable"
          pageName="building"
          value={props.pharmacyAvailable}
        />
      ),
    },
    {
      label: t('building.propertyManagerOnSite'),
      render: (
        <YesNoMaybeWithSource
          fieldName="propertyManagerOnSite"
          key="propertyManagerOnSite"
          pageName="building"
          value={props.propertyManagerOnSite}
        />
      ),
    },
    {
      label: t('building.restaurantAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="restaurantAvailable"
          key="restaurantAvailable"
          pageName="building"
          value={props.restaurantAvailable}
        />
      ),
    },
    {
      label: t('building.serviceElevatorCount'),
      render: (
        <CountWithSource
          fieldName="serviceElevatorCount"
          key="serviceElevatorCount"
          pageName="building"
          value={props.serviceElevatorCount}
        />
      ),
    },
    {
      label: t('building.showers'),
      render: (
        <YesNoMaybeWithSource
          fieldName="showers"
          key="showers"
          pageName="building"
          value={props.showers}
        />
      ),
    },
    {
      label: t('building.shuttle'),
      render: (
        <YesNoMaybeWithSource
          fieldName="shuttle"
          key="shuttle"
          pageName="building"
          value={props.shuttle}
        />
      ),
    },
    {
      label: t('building.spaAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="spaAvailable"
          key="spaAvailable"
          pageName="building"
          value={props.spaAvailable}
        />
      ),
    },
    {
      label: t('building.storageAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="storageAvailable"
          key="storageAvailable"
          pageName="building"
          value={props.storageAvailable}
        />
      ),
    },
    {
      label: t('building.tenantLounge'),
      render: (
        <YesNoMaybeWithSource
          fieldName="tenantLounge"
          key="tenantLounge"
          pageName="building"
          value={props.tenantLounge}
        />
      ),
    },
    {
      label: t('building.tenantPortalApp'),
      render: (
        <YesNoMaybeWithSource
          fieldName="tenantPortalApp"
          key="tenantPortalApp"
          pageName="building"
          value={props.tenantPortalApp}
        />
      ),
    },
    {
      label: t('building.touchFreeElevatorAccess'),
      render: (
        <YesNoMaybeWithSource
          fieldName="touchFreeElevatorAccess"
          key="touchFreeElevatorAccess"
          pageName="building"
          value={props.touchFreeElevatorAccess}
        />
      ),
    },
    {
      label: t('building.touchlessTurnstileEntry'),
      render: (
        <YesNoMaybeWithSource
          fieldName="touchlessTurnstileEntry"
          key="touchlessTurnstileEntry"
          pageName="building"
          value={props.touchlessTurnstileEntry}
        />
      ),
    },
    {
      label: t('building.transportationAccessible'),
      render: (
        <YesNoMaybeWithSource
          fieldName="transportationAccessible"
          key="transportationAccessible"
          pageName="building"
          value={props.transportationAccessible}
        />
      ),
    },
    {
      label: t('building.turnstiles'),
      render: (
        <YesNoMaybeWithSource
          fieldName="turnstiles"
          key="turnstiles"
          pageName="building"
          value={props.turnstiles}
        />
      ),
    },
    {
      label: t('building.urgentCareDoctorOnsite'),
      render: (
        <YesNoMaybeWithSource
          fieldName="urgentCareDoctorOnsite"
          key="urgentCareDoctorOnsite"
          pageName="building"
          value={props.urgentCareDoctorOnsite}
        />
      ),
    },
    {
      label: t('building.valetParking'),
      render: (
        <YesNoMaybeWithSource
          fieldName="valetParking"
          key="valetParking"
          pageName="building"
          value={props.valetParking}
        />
      ),
    },
    {
      label: t('building.yogaStudioAvailable'),
      render: (
        <YesNoMaybeWithSource
          fieldName="yogaStudioAvailable"
          key="yogaStudioAvailable"
          pageName="building"
          value={props.yogaStudioAvailable}
        />
      ),
    },
  ];
  const orderedAmenities = amenities
    .sort((amenityA, amenityB) => amenityA.label.localeCompare(amenityB.label))
    .map(amenity => {
      return amenity.render;
    });

  return <>{orderedAmenities}</>;
};
export default memo(BuildingAmenitiesList);
