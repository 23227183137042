import { useState } from 'react';
import { maxBy, isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import { Row, Col } from 'antd';
import { Select } from '@components/antd';
import { Area, Rent } from '@root/types';
import cn from 'classnames';
import css from './Financials.module.less';

const { Option } = Select;
const BaseRentColSpan = 12;
const LeaseTermColSpan = 12;
const MonthsInAYear = 12;

type Props = {
  baseRents: Rent[] | null;
  listingArea: Area | null;
  leaseTermChanged: () => void;
  leaseTermDropdownOpened: () => void;
};

const Financials = ({
  baseRents = [],
  listingArea = null,
  leaseTermChanged,
  leaseTermDropdownOpened,
}: Props) => {
  const { t } = useTranslation('listing');

  const [selectedLeaseTerm, setSelectedLeaseTerm] = useState(maxBy(baseRents, 'month'));

  if (isEmpty(baseRents) || !listingArea) return null;

  const handleLeaseTermChange = month => {
    const selectedBaseRent = baseRents!.find(baseRent => baseRent.month === month);
    setSelectedLeaseTerm(selectedBaseRent);
    leaseTermChanged();
  };

  const displayMonthValue = month => {
    if (month % MonthsInAYear === 0) {
      return t('financials.leaseTermDropdown.year', { count: month / MonthsInAYear });
    }
    return t('financials.leaseTermDropdown.month', { count: month });
  };

  const rentPerMonthForSelectedLeaseTerm = Math.round(
    (selectedLeaseTerm!.magnitude / MonthsInAYear) * listingArea.magnitude,
  );

  return (
    <div
      className={cn({
        [css.financalSection]: true,
      })}
    >
      <Row className={css.titleRow}>
        <Col span={BaseRentColSpan}>
          <h3 className="font-body-medium" data-qa-testid="listing-asking-rent-heading">
            {t('financials.baseRentTitle')}
          </h3>
        </Col>
        <Col span={LeaseTermColSpan}>
          <h3 className="font-body-medium" data-qa-testid="listing-lease-term-heading">
            {t('financials.leaseTermTitle')}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col span={BaseRentColSpan} data-qa-testid="listing-asking-rent-year">
          {/* eslint-disable react/jsx-no-literals */}
          <Trans i18nKey="financials.baseRentPerYearPerArea" ns="listing">
            <span className={css.baseRentPerYearPerAreaAmount}>{{ price: selectedLeaseTerm }}</span>
            <span className={css.baseRentPerYearPerAreaUnit}>per</span>
            <span className={css.baseRentPerYearPerAreaUnit}>{{ units: selectedLeaseTerm }}</span>
          </Trans>
          {/* eslint-enable react/jsx-no-literals */}
        </Col>
        <Col span={LeaseTermColSpan}>
          <Select
            data-testid="selectLeaseTerm"
            onFocus={() => leaseTermDropdownOpened()}
            defaultValue={selectedLeaseTerm!.month}
            onChange={handleLeaseTermChange}
            className={css.selectLeaseterm}
            data-qa-testid="listing-lease-years"
          >
            {baseRents!.map(baseRent => (
              <Option value={baseRent.month} key={baseRent.month}>
                {displayMonthValue(baseRent.month)}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className={css.baseRentPerMonth} data-qa-testid="listing-asking-rent-month">
        <Col span={24}>
          {t('units:pricePerPeriod', {
            pricePerPeriod: {
              ...selectedLeaseTerm,
              magnitude: rentPerMonthForSelectedLeaseTerm,
              period: 'month',
              abbreviatedPeriod: false,
            },
          })}
        </Col>
      </Row>
    </div>
  );
};

export default Financials;
