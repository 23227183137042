import PropTypes from 'prop-types';
import { Row, Pagination } from 'antd';
import Results from '@components/shared/Results';

const PaginationRow = ({
  totalCount,
  page,
  pageSize,
  changePage,
  showPagination,
  showResults,
  testId,
}) => {
  if (!showPagination || totalCount < 1) return null;
  return (
    <Row type="flex" justify="space-between" style={{ marginTop: 20 }}>
      <Pagination
        simple
        total={totalCount}
        data-testid={testId}
        current={page}
        pageSize={pageSize}
        onChange={changePage}
      />
      {showResults ? <Results page={page} totalCount={totalCount} pageSize={pageSize} /> : null}
    </Row>
  );
};

PaginationRow.defaultProps = {
  testId: 'pagination',
  showResults: true,
  showPagination: true,
  totalCount: 0,
  pageSize: 0,
  page: 1,
};

PaginationRow.propTypes = {
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  page: PropTypes.number,
  testId: PropTypes.string,
  changePage: PropTypes.func.isRequired,
  showResults: PropTypes.bool,
  showPagination: PropTypes.bool,
};

export default PaginationRow;
