import { ComponentProps, ReactNode } from 'react';
import { Field } from 'formik';
import cn from 'classnames';
import ReactSelect from 'react-select';
import s from '../Dropdowns.module.less';
import FieldWrapper from '../../FieldWrapper';
import RawSelect from '../RawSelect';
import { OptionType, SelectValue } from '../types';

export type Props = {
  name: string;
  labelText?: ReactNode;
  required?: boolean;
  options: OptionType[];
  value?: SelectValue;
  isClearable?: boolean;
  placeholder?: string;
  labelClass?: string;
  containerClass?: string;
  className?: string;
  isDisabled?: boolean;
  'data-testid'?: string;
  components?: any;
  isMulti?: boolean;
  styles?: ComponentProps<typeof ReactSelect>['styles'];
  warningText?: ReactNode;
};

const Select = ({
  name,
  labelText,
  required = false,
  options,
  value,
  isClearable = true,
  placeholder,
  labelClass,
  containerClass,
  isDisabled = false,
  className,
  'data-testid': dataTestId,
  components,
  isMulti = false,
  styles,
  warningText,
}: Props) => (
  <FieldWrapper
    name={name}
    labelText={labelText}
    required={required}
    labelClass={labelClass}
    containerClass={containerClass}
    data-testid={dataTestId}
  >
    <Field name={name}>
      {({ field, form, meta }) => (
        <RawSelect
          name={field.name}
          className={cn(className, meta.error && meta.touched && s.error)}
          placeholder={placeholder}
          value={value || field.value}
          onChange={async newValue => {
            await field.onChange({ target: { name: field.name, value: newValue } });
            form.setFieldTouched(field.name, true);
          }}
          onBlur={field.onBlur}
          options={options}
          isClearable={isClearable}
          isDisabled={isDisabled}
          components={components}
          isMulti={isMulti}
          styles={styles}
        />
      )}
    </Field>
    {warningText && <div>{warningText}</div>}
  </FieldWrapper>
);

export default Select;
