import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation, Trans } from 'react-i18next';
import { components } from 'react-select';
import Footer from '@components/layouts/Truva/Layout/Footer';
import Navigation from '@components/layouts/Truva/Layout/Navigation';
import { parse } from 'qs';
import { NavLink, useLocation } from 'react-router-dom';
import routes from '@root/routes';
import useMarket from '@root/shared/useMarket';
import { Button, CustomIcon, OnlyInDesktop, OnlyInTablet, OnlyInMobile } from '@components/shared';
import { useDispatch } from 'react-redux';
import { ButtonLink } from '@components/shared/V2Button';
import useStickyState from '@shared/useStickyState';
import COLORS from '@shared/colorConstants';
import actions from '@store/actions/homepage';
import Logo from '@components/shared/Logo';
import { CSSTransition } from 'react-transition-group';
import { MarketSlug } from '@root/types';
import { PARAMETERS } from '@root/tracking/constants';
import useCurrentUser from '@root/shared/useCurrentUser';
import cn from 'classnames';
import s from './Homepage.module.less';
import TriptychTailwind from './TriptychTailwind';
import Triptych from './Triptych';
import MarketToggleSelect from '../ListingSearch/MarketSelector/MarketToggleSelect';
import SiteVisitorModal from './SiteVisitorModal';
import HomePageHead from './HomePageHead';
import ExclusiveListingsSection from './ExclusiveListingsSection';
import InternalLinks from './InternalLinks/InternalLinks';

const defaultMarket = 'new_york_city';

const getMarketByValue = (options, marketValue) => {
  const marketOptions = options.map(option => option.options).flat();

  return (
    marketOptions.find(option => option.value === marketValue) ||
    marketOptions.find(option => option.value === defaultMarket)
  );
};

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <span className={s.valueContainer}>
        {/* FIXME: Remove this line because its a <Trans /> or remove this literal */}
        {/* eslint-disable-next-line react/jsx-no-literals */}
        {plurify(props.children)} <CustomIcon className={s.dropdownIndicator} type="chevron-down" />
      </span>
    </components.SingleValue>
  );
};

const plurify = market => {
  if (market.slice(-1) === 's') return market;
  return `${market}'s`;
};

export const RawHomepage = () => {
  const { t } = useTranslation('homepage');
  const { currentUser, hasLoaded: isCurrentUserLoaded } = useCurrentUser();
  const dispatch = useDispatch();
  const flags = useFlags();
  const { options } = useMarket();
  // would normally use useParams, but it doesnt
  // seem to work w/ a root route
  const { search } = useLocation();
  const { market: queryParamMarket } = parse(search, { ignoreQueryPrefix: true });
  const [siteVisitorMarket, setSiteVisitorMarket] = useStickyState<string>('', 'siteVisitorMarket');
  const showSiteVisitorModal =
    isCurrentUserLoaded && !currentUser && !siteVisitorMarket && !queryParamMarket;

  const [selectedMarket, setSelectedMarket] = useState<{ value: MarketSlug; label: string }>(
    getMarketByValue(
      options,
      queryParamMarket || currentUser?.marketSlug || siteVisitorMarket || defaultMarket,
    ),
  );

  useEffect(() => {
    dispatch(actions.loadPage());
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) {
      setSelectedMarket(getMarketByValue(options, currentUser.marketSlug));
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (siteVisitorMarket && !currentUser && !queryParamMarket) {
      setSelectedMarket(getMarketByValue(options, siteVisitorMarket));
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, siteVisitorMarket, queryParamMarket]);

  const toggleMarket = market => {
    setSelectedMarket(getMarketByValue(options, market));
  };

  const handleClickValuePropAndFeatureTriptych = url =>
    dispatch(actions.valuePropAndFeatureTriptychClicked({ url }));

  return (
    <>
      <Navigation />
      <HomePageHead />
      <div>
        <div
          className={cn(
            s.searchContainer,
            'relative h-[520px] bg-cover bg-no-repeat tablet:h-[312px] mobile:h-[173px]',
            flags['outreach.branding-general'] &&
              !currentUser &&
              'tablet:h-[355px] mobile:h-[190px]',
          )}
        >
          {isCurrentUserLoaded && (
            <>
              {flags['outreach.branding-general'] && !currentUser && !showSiteVisitorModal && (
                <CSSTransition
                  in
                  appear
                  timeout={
                    /* this is because of the site visitor modal,
                                  you want it to animate in immediately */
                    currentUser ? 1000 : 0
                  }
                  classNames="lines all"
                >
                  <div
                    className={cn(
                      s.logoOnHomePageBanner,
                      'z-1 absolute left-6 top-4 block h-[29px] w-[177px]',
                      'tablet:top-3 tablet:h-[26px]',
                      'mobile:left-3 mobile:top-[10px] mobile:h-[18px] mobile:w-[107px]',
                    )}
                  >
                    <Logo mode="black" />
                  </div>
                </CSSTransition>
              )}
              {showSiteVisitorModal ? (
                <SiteVisitorModal
                  chooseMarket={market => {
                    if (!market) return;
                    setSiteVisitorMarket(market);
                  }}
                />
              ) : (
                <CSSTransition
                  in
                  appear
                  timeout={
                    /* this is because of the site visitor modal,
                        you want it to animate in immediately */
                    currentUser ? 1000 : 0
                  }
                  classNames="lines all"
                >
                  <div
                    className={cn(
                      s.searchContentContainer,
                      'left-[100px] top-[113px] font-display',
                      'tablet:left-[28px] tablet:top-5 tablet:font-headline',
                      'mobile:left-3 mobile:top-2 mobile:font-body-large-emphasis',
                      flags['outreach.branding-general'] && 'tablet:left-[58px] mobile:left-3',
                      flags['outreach.branding-general'] &&
                        !currentUser &&
                        'tablet:!top-[84px] mobile:!top-[40px]',
                    )}
                  >
                    <h1
                      className={
                        flags['outreach.branding-general'] ? 'text-text-primary' : 'text-white'
                      }
                    >
                      <div>{t('headerText.firstLine')} </div>
                      <div>
                        <MarketToggleSelect
                          name="homepageMarketSelector"
                          options={options}
                          value={selectedMarket}
                          onChange={toggleMarket}
                          menuPlacement="bottom"
                          overrideComponents={{
                            SingleValue,
                          }}
                          overrideCustomStyles={{
                            singleValue: base => ({
                              ...base,
                              color: flags['outreach.branding-general']
                                ? '#1D1E20' // color-text-primary
                                : COLORS.white,
                              marginLeft: '0',
                              marginRight: '0',
                              paddingRight: '8px',
                              position: 'static',
                              transform: 'none',
                              textOverflow: '',
                              borderBottom: `1px dashed ${
                                flags['outreach.branding-general']
                                  ? '#1D1E20' // color-text-primary
                                  : COLORS.white
                              }`,
                            }),
                            dropdownIndicator: base => ({
                              ...base,
                              display: 'none',
                            }),
                            valueContainer: base => ({
                              ...base,
                              padding: '0',
                            }),
                            container: base => ({
                              ...base,
                            }),
                          }}
                          sourcePage={PARAMETERS.homepage}
                          sourceContent={PARAMETERS.marketDropdown}
                        />
                      </div>

                      <div>{t('headerText.thirdLine')} </div>

                      <div>{t('headerText.fourthLine')}</div>
                    </h1>
                    <div className={s.searchButton}>
                      <NavLink to={routes.search(selectedMarket?.value)}>
                        <OnlyInDesktop>
                          <Button type="primary" size="large" icon="magnifying-glass">
                            {t('buttonText')}
                          </Button>
                        </OnlyInDesktop>
                        <OnlyInTablet>
                          <Button type="primary" size="medium" icon="magnifying-glass">
                            {t('buttonText')}
                          </Button>
                        </OnlyInTablet>
                        <OnlyInMobile>
                          <Button type="primary" size="small" icon="magnifying-glass">
                            {t('buttonText')}
                          </Button>
                        </OnlyInMobile>
                      </NavLink>
                    </div>
                  </div>
                </CSSTransition>
              )}
            </>
          )}
          <div
            className={cn(
              'relative opacity-80 desktop:h-[520px] tablet:h-[312px] mobile:h-[173px]',
              flags['outreach.branding-general']
                ? 'bg-gradient-to-r from-[#f5f5ff] from-[37.16%] to-[64.33%]'
                : 'bg-gradient-to-r from-[#140532] from-[31.7%] to-100%',
              flags['outreach.branding-general'] &&
                !currentUser &&
                'tablet:h-[355px] mobile:h-[190px]',
            )}
          />
        </div>
        <ExclusiveListingsSection selectedMarket={selectedMarket} />
        <div className={s.valuePropsAndFeatures}>
          <div
            className={cn(
              s.valuePropsAndFeaturesResponsiveWidth,
              flags['outreach.branding-general'] && 'max-w-[1440px]',
            )}
          >
            {flags['tailwind-spike'] ? (
              <TriptychTailwind onClickCta={handleClickValuePropAndFeatureTriptych} />
            ) : (
              <Triptych>
                <div className={s.valuePropOrFeatureCard}>
                  <div className={s.valuePropOrFeatureCardContent}>
                    <h2>{t('featuresAndValueProps.firstToMarketListings.title')}</h2>
                    <figure className={s.valuePropImageContainer}>
                      <img
                        src="https://media.truva.com/t_medium_image/assets/homepage-photos/first-to-market"
                        alt="First to Market listings"
                      />
                    </figure>
                    <p>
                      <Trans
                        ns="homepage"
                        i18nKey="featuresAndValueProps.firstToMarketListings.description"
                        components={{ noWrap: <span className={s.noWrap} /> }}
                      />
                    </p>
                    <ButtonLink
                      type="secondary"
                      size="medium"
                      href="http://www.vts.com/first-to-market-listings"
                      openInNewTab={false}
                      className={s.ctaButton}
                      onClick={handleClickValuePropAndFeatureTriptych}
                    >
                      {t('featuresAndValueProps.cta')}
                      <CustomIcon className={s.rightArrow} type="chevron-right" />
                    </ButtonLink>
                  </div>
                </div>
                <div className={s.valuePropOrFeatureCard}>
                  <div className={s.valuePropOrFeatureCardContent}>
                    <h2>{t('featuresAndValueProps.virtualTourbook.title')}</h2>
                    <figure className={s.valuePropImageContainer}>
                      <img
                        src="https://media.truva.com/t_medium_image/assets/homepage-photos/tourbook-02"
                        alt="Virtual Tourbook"
                      />
                    </figure>
                    <p>{t('featuresAndValueProps.virtualTourbook.description')}</p>
                    <ButtonLink
                      type="secondary"
                      size="medium"
                      href="http://www.vts.com/canvassing"
                      openInNewTab={false}
                      className={s.ctaButton}
                      onClick={handleClickValuePropAndFeatureTriptych}
                    >
                      {t('featuresAndValueProps.cta')}
                      <CustomIcon className={s.rightArrow} type="chevron-right" />
                    </ButtonLink>
                  </div>
                </div>
                <div className={s.valuePropOrFeatureCard}>
                  <div className={s.valuePropOrFeatureCardContent}>
                    <h2>{t('featuresAndValueProps.subleases.title')}</h2>
                    <figure className={s.valuePropImageContainer}>
                      <img
                        src="https://media.truva.com/t_medium_image/assets/homepage-photos/sublease"
                        alt="Sublease"
                      />
                    </figure>
                    <p>{t('featuresAndValueProps.subleases.description')}</p>
                    <ButtonLink
                      type="secondary"
                      size="medium"
                      href="http://www.vts.com/subleases"
                      openInNewTab={false}
                      className={s.ctaButton}
                      onClick={handleClickValuePropAndFeatureTriptych}
                    >
                      {t('featuresAndValueProps.cta')}
                      <CustomIcon className={s.rightArrow} type="chevron-right" />
                    </ButtonLink>
                  </div>
                </div>
              </Triptych>
            )}
          </div>
        </div>
        <InternalLinks />
        <Footer />
      </div>
    </>
  );
};

export default RawHomepage;
