import { ListingSearchCriteria } from '@components/layouts/Truva/ListingSearch/utils';
import { useFilterOptions } from '@components/layouts/Truva/ListingSearch/utils/FilterOptions/FilterOptionsProvider';
import setActiveSavedSearchId from '@root/store/actions/saved/setActiveSavedSearch';
import { SavedSearch } from '@root/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useFilterAnalytics from './utils/useFilterAnalytics';

const useOnChangeSavedSearch = () => {
  const [_state, dispatchFilter, fetchInitialFilterOptionsFromCriteria] = useFilterOptions();
  const history = useHistory();
  const dispatch = useDispatch();
  const { fireAnalyticEventsForCurrentFilters } = useFilterAnalytics();

  return useCallback(
    (
      savedSearch: SavedSearch | null,
      { createsOrModifiesSavedSearch }: { createsOrModifiesSavedSearch: boolean } = {
        createsOrModifiesSavedSearch: false,
      },
    ) => {
      if (!savedSearch) return;

      dispatch(setActiveSavedSearchId(savedSearch.id));
      const savedSearchCriteria = ListingSearchCriteria.fromSavedSearch(savedSearch);
      savedSearchCriteria.setHistory(history);

      if (!createsOrModifiesSavedSearch) {
        /* Since we're changing the filters here, we need to fetch the filter options just in case 
      the new filter includes submarkets, buildings, etc. We're pushing to history because we are
      setting saved_search_id AND setting the filters and want this to all be one "transaction". */
        fetchInitialFilterOptionsFromCriteria(dispatchFilter, savedSearchCriteria);
        savedSearchCriteria.pushToHistory();

        /* Fire analytic events for all the saved search filters. No need to do that in the 
      else block since the saved search is only being updated or created there - no new filters 
      were applied */
        fireAnalyticEventsForCurrentFilters(savedSearchCriteria);
      } else {
        /* Since we're not changing filters (ie. we're updating or creating a new saved search),
      we just need to set the saved_search_id in the url. We'll replace the current 
      history state so that hitting the back button will take the user to the previous time
      the filters were modified, rather than just removing the saved_search_id from the url. */
        savedSearchCriteria.pushToHistory({ replace: true });
      }
    },
    [
      dispatch,
      dispatchFilter,
      fetchInitialFilterOptionsFromCriteria,
      fireAnalyticEventsForCurrentFilters,
      history,
    ],
  );
};

export default useOnChangeSavedSearch;
