import * as React from 'react';
import classNames from 'classnames';
import s from './Input.module.less';

export type Props = {
  type?: 'number' | 'text' | 'file';
  value?: number | string | undefined;
  defaultValue?: number | string | undefined;
  name: string;
  placeholder?: string;
  labelText: React.ReactNode;
  onChange: (value: string) => void;
  inputClassName?: string;
};

// DEPRECATED. please don't use
const Input = ({
  type = 'text',
  name,
  value = undefined,
  labelText,
  onChange,
  placeholder,
  defaultValue,
  inputClassName,
}: Props) => (
  <div className={s.container}>
    {labelText && (
      <label htmlFor={name} className={s.label}>
        {labelText}
      </label>
    )}
    <input
      className={classNames(s.input, inputClassName)}
      name={name}
      id={name}
      type={type}
      value={value}
      onChange={(event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
      placeholder={placeholder}
      defaultValue={defaultValue}
    />
  </div>
);

export default Input;
