/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Form, SubmitButton } from '@components/shared/forms';
import { Button, Notification, CustomIcon } from '@components/shared';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import api from '@shared/api';
import routes from '@root/routes';
import truncateText from '@shared/truncateText';
import { useBreakpoint } from '@shared/useBreakpoints';
import searchPageActions from '@store/actions/listingSearchPage/analyticsActions';
import { ListingSearchCriteria } from '@components/layouts/Truva/ListingSearch/utils';
import cn from 'classnames';
import qs from 'qs';
import { SavedSearch, StoreState } from '@root/types';
import useHandleClickOutside from '@root/shared/useHandleClickOutside';
import useAnalytics from '@shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import { getSavedSearches } from '@root/store/selectors';
import s from './SaveSearchButton.module.less';
import CreateNewSearchForm from './CreateNewSearchForm';
import UpdateSearchDropdown from './UpdateSeachDropdown';

type Props = {
  criteria: ListingSearchCriteria;
  disabled?: boolean;
  canUpdate?: boolean;
  currentSelectedSearch: SavedSearch | null;
  onChange: (
    savedSearch: SavedSearch | null,
    opts?: { createsOrModifiesSavedSearch: boolean },
  ) => void;
  fetchSavedSearches: () => Promise<void>;
};

export type FormValues = {
  name: string;
  useSearchCriteriaAsName: boolean;
};

const initialValues = { name: '', useSearchCriteriaAsName: false } as FormValues;

const SaveSearchButton = ({
  criteria,
  currentSelectedSearch,
  disabled = false,
  canUpdate = false,
  onChange,
  fetchSavedSearches,
}: Props) => {
  const { t } = useTranslation('filters');

  const dispatch = useDispatch();
  const savedSearches = useSelector((state: StoreState) => getSavedSearches(state));

  const [searchSaved, setSearchSaved] = useState(false);
  const [searchUpdated, setSearchUpdated] = useState(false);
  const [isSaveNewSearchDialogOpen, setisSaveNewSearchDialogOpen] = useState(false);
  const [canUpdateSeachDropdownOpen, setCanUpdateSearchDropdownOpen] = useState(false);
  const [internalCriteria, setInternalCriteria] = useState<ListingSearchCriteria | null>(null);
  const [description, setSearchDescription] = useState<string>('');
  const paramsForFetchDescription = qs.stringify(criteria.toSavedSearchParameters());
  const isCriteriaEqual =
    currentSelectedSearch &&
    criteria.isEqualTo(ListingSearchCriteria.fromSavedSearch(currentSelectedSearch));
  const { isMobile } = useBreakpoint();
  const { savedSearchInteraction } = useAnalytics();
  const savedSearchLinkClicked = () => {
    dispatch(searchPageActions.savedSearchNotificationLinkClicked());
  };
  const showUpdateSearchDropdown = canUpdate && !isSaveNewSearchDialogOpen;
  const canSaveSearch = !(searchSaved || searchUpdated || isCriteriaEqual);
  const canFetchDescription =
    !disabled && (description === '' || canUpdate || !currentSelectedSearch) && canSaveSearch;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('saveSearch:nameRequiredErrorMessage')),
    useSearchCriteriaAsName: Yup.boolean(),
  });

  useEffect(() => {
    if (JSON.stringify(internalCriteria) !== JSON.stringify(criteria)) {
      setInternalCriteria(criteria);
    }
  }, [setInternalCriteria, internalCriteria, criteria]);

  useEffect(() => {
    setSearchSaved(false);
    setSearchUpdated(false);
  }, [internalCriteria]);

  const componentRef = useHandleClickOutside<HTMLDivElement>(() => {
    setisSaveNewSearchDialogOpen(false);
    setCanUpdateSearchDropdownOpen(false);
  });

  const saveTheCurrentSearch = async (values: FormValues) => {
    if (searchSaved) return;

    savedSearchInteraction({
      actionType: 'SAVE_SEARCH_NAME',
      action: PARAMETERS.saveSearchName,
      sourcePage: PARAMETERS.searchResultsPage,
      sourceContent: PARAMETERS.searchCriteriaBar,
      savedSearchCount: savedSearches.length,
      savedSearchName: values.name,
      savedSearchId: currentSelectedSearch?.id ?? null,
    });

    let params = criteria.toSavedSearchParameters();
    if (!values.useSearchCriteriaAsName) {
      params = { ...criteria.toSavedSearchParameters(), ...{ name: values.name } };
    }

    const response = await api.post(routes.api.savedSearches, params);
    if (response.ok) {
      setisSaveNewSearchDialogOpen(false);
      Notification.saveSuccess({
        title: t('saveSearch:saved'),
        /* eslint-disable react/jsx-no-literals */
        text: (
          <Trans i18nKey="viewInFavorites" ns="saveSearch">
            You’ll get notified when a listing matches your search. View or edit it in your
            <a href={routes.savedSearches} onClick={savedSearchLinkClicked}>
              saved searches.
            </a>
          </Trans>
        ),
      });
      setSearchSaved(true);
      const savedSearch = await response.json();
      onChange(savedSearch, { createsOrModifiesSavedSearch: true });
      await fetchSavedSearches();
    }
  };

  const updateSavedSearch = async () => {
    const params = criteria.toSavedSearchParameters();

    const response = await api.put(
      routes.api.updateSavedSearchCriteria(currentSelectedSearch!.id),
      params,
    );
    if (response.ok) {
      setCanUpdateSearchDropdownOpen(false);
      Notification.saveSuccess({
        title: t('saveSearch:saved'),
        /* eslint-disable react/jsx-no-literals */
        text: (
          <Trans i18nKey="viewInFavorites" ns="saveSearch">
            You’ll get notified when a listing matches your search. View or edit it in your
            <a href={routes.savedSearches} onClick={savedSearchLinkClicked}>
              saved searches.
            </a>
          </Trans>
        ),
      });
      setSearchUpdated(true);
      const savedSearch = await response.json();
      onChange(savedSearch, { createsOrModifiesSavedSearch: true });
      await fetchSavedSearches();
    }
  };

  const handleToggleUpdateSearchDropdown = () => {
    if (searchUpdated) {
      return;
    }
    savedSearchInteraction({
      actionType: 'SAVE_CHANGES',
      action: PARAMETERS.saveChanges,
      sourcePage: PARAMETERS.searchResultsPage,
      sourceContent: PARAMETERS.searchCriteriaBar,
      savedSearchCount: savedSearches.length,
      savedSearchName: currentSelectedSearch?.name ?? description ?? null,
      savedSearchId: currentSelectedSearch?.id ?? null,
    });

    setCanUpdateSearchDropdownOpen(!canUpdateSeachDropdownOpen);
  };

  const handleToggleSaveNewSearchDialog = () => {
    if (searchSaved || isCriteriaEqual) {
      return;
    }
    savedSearchInteraction({
      actionType: 'SAVE_SEARCH',
      action: PARAMETERS.saveSearch,
      sourcePage: PARAMETERS.searchResultsPage,
      sourceContent: PARAMETERS.searchCriteriaBar,
      savedSearchCount: savedSearches.length,
      savedSearchName: description,
      savedSearchId: currentSelectedSearch?.id ?? null,
    });
    setisSaveNewSearchDialogOpen(!isSaveNewSearchDialogOpen);
  };

  useEffect(() => {
    let didCancel = false;
    const fetchSavedSearchDescription = async () => {
      const response = await api.fetch(
        `${routes.api.savedSearchDescription}?${paramsForFetchDescription}`,
      );

      // we check for whether subsequent fetches are made and only use the most recent ones
      // to set the description state
      if (response.ok && !didCancel) {
        const body = await response.json();
        setSearchDescription(body.description);
      }
    };
    if (canFetchDescription) fetchSavedSearchDescription();
    return () => {
      didCancel = true;
    };
  }, [canFetchDescription, paramsForFetchDescription]);

  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      setisSaveNewSearchDialogOpen(false);
      setCanUpdateSearchDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [componentRef]);

  return (
    <div ref={componentRef}>
      <Button
        className={cn({ [s.filled]: searchSaved || searchUpdated || isCriteriaEqual }, s.button)}
        onClick={
          showUpdateSearchDropdown
            ? handleToggleUpdateSearchDropdown
            : handleToggleSaveNewSearchDialog
        }
        type="tertiary"
        size="small"
        disabled={disabled}
        icon={searchSaved || searchUpdated || isCriteriaEqual ? 'heart' : 'heart-outline'}
      >
        {searchSaved || searchUpdated || isCriteriaEqual
          ? t('saveSearch:savedTitle')
          : canUpdate
            ? t('saveSearch:update:title')
            : t('saveSearch:unsavedTitle')}
      </Button>
      {isSaveNewSearchDialogOpen && (
        <dialog open={isSaveNewSearchDialogOpen}>
          <div className={s.overlay}>
            <div className={s.overlayHeader}>
              <span className={s.overlayTitle}>{t('saveSearch:nameSearchDialogTitle')}</span>
              <button
                onClick={handleToggleSaveNewSearchDialog}
                type="button"
                className={s.overlayCloseButton}
                data-testid="close-save-search-dialog"
              >
                <CustomIcon type="close" />
              </button>
            </div>
            <Form<FormValues>
              id="createSavedSearch"
              initialValues={initialValues}
              onSubmit={async values => {
                saveTheCurrentSearch(values);
              }}
              validationSchema={validationSchema}
            >
              <CreateNewSearchForm description={description} />
              <div className={s.saveSearchFromDialogButtonWrapper}>
                <SubmitButton size="small" className={s.saveSearchFromDialogButton}>
                  {t('saveSearch:saveSearchSubmitButton')}
                </SubmitButton>
              </div>
            </Form>
          </div>
        </dialog>
      )}
      {canUpdateSeachDropdownOpen && (
        <UpdateSearchDropdown
          options={[
            {
              label: t('saveSearch:update:updateSearch', {
                search_description: `"${truncateText(
                  currentSelectedSearch?.name
                    ? currentSelectedSearch.name
                    : currentSelectedSearch?.description,
                  isMobile ? 180 : 38,
                )}"`,
              }),
              onClick: async () => {
                savedSearchInteraction({
                  actionType: 'SAVE_CHANGES_UPDATE',
                  action: PARAMETERS.saveChangesUpdate,
                  sourcePage: PARAMETERS.searchResultsPage,
                  sourceContent: PARAMETERS.searchCriteriaBar,
                  savedSearchCount: savedSearches.length,
                  savedSearchName: currentSelectedSearch!.name ?? description,
                  savedSearchId: currentSelectedSearch!.id,
                });

                await updateSavedSearch();
              },
            },
            {
              label: t('saveSearch:update:saveNewSearch'),
              onClick: () => {
                savedSearchInteraction({
                  actionType: 'SAVE_CHANGES_NEW',
                  action: PARAMETERS.saveChangesNew,
                  sourceContent: PARAMETERS.searchCriteriaBar,
                  sourcePage: PARAMETERS.searchResultsPage,
                  savedSearchCount: savedSearches.length,
                  savedSearchName: currentSelectedSearch!.name ?? description,
                  savedSearchId: currentSelectedSearch?.id ?? null,
                });

                setCanUpdateSearchDropdownOpen(!canUpdateSeachDropdownOpen);
                setisSaveNewSearchDialogOpen(!isSaveNewSearchDialogOpen);
              },
            },
          ]}
        />
      )}
    </div>
  );
};

export default SaveSearchButton;
