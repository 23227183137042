import { ReactNode } from 'react';
import cn from 'classnames';
import { ErrorMessage } from 'formik';
import { Tooltip, InfoIcon } from '@viewthespace/components';
import Label from '../RawLabel';
import ContextualErrors from '../ContextualErrors';
import { useFormTheme } from '../ThemeContext';
import s from '../forms.module.less';

export type FieldWrapperProps = {
  description?: string;
  descriptionClass?: string;
  labelClass?: string;
  containerClass?: string;
  inputContainerClass?: string;
  name: string;
  labelText?: ReactNode;
  children: ReactNode;
  required?: boolean;
  'data-testid'?: string;
  displayError?: boolean;
  labelQaTestId?: string;
  tooltipContent?: string;
};

const FieldWrapper = ({
  description,
  descriptionClass,
  name,
  labelClass,
  containerClass,
  inputContainerClass,
  labelText,
  required,
  children,
  'data-testid': dataTestId,
  labelQaTestId,
  tooltipContent,
  displayError = true,
}: FieldWrapperProps) => {
  const layout = useFormTheme();
  return (
    <div
      className={cn(s.container, layout === 'horizontal' && s.horizontal, containerClass)}
      data-testid={dataTestId}
    >
      {labelText ? (
        <div className="inline-flex items-center gap-0.5">
          <Label
            name={name}
            className={cn(
              layout === 'horizontal' && s.horizontalLabel,
              layout === 'admin' && '!font-bold !text-black-100',
              labelClass,
            )}
            required={required}
            horizontal={layout === 'horizontal'}
          >
            {labelText}
          </Label>
          {tooltipContent && (
            <Tooltip
              className="whitespace-pre"
              placement="top"
              content={tooltipContent}
              trigger={
                <div>
                  <InfoIcon color="text-link" />
                </div>
              }
            />
          )}
        </div>
      ) : null}
      <div className={cn(s.inputWrapper, inputContainerClass)} data-qa-testid={labelQaTestId}>
        {children}
      </div>
      {description ? (
        <span className={descriptionClass} id={`${name}-description`}>
          {description}
        </span>
      ) : null}
      {displayError ? (
        <div id={`${name}-error`}>
          <ErrorMessage name={name}>
            {(messages: string | string[]) => <ContextualErrors messages={messages} />}
          </ErrorMessage>
        </div>
      ) : null}
    </div>
  );
};
/* eslint-enable react/no-array-index-key */

export default FieldWrapper;
