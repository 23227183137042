import * as React from 'react';
import cn from 'classnames';
import s from './ListingCardSkeleton.module.less';

const ListingCardSkeleton = () => {
  return (
    <div data-testid="listingCardSkeleton" className={s.card}>
      <div className={cn(s.photo, s.shimmer)} />
      <div className={s.listingInfoWrapper}>
        <div className={cn(s.listingInfo1, s.shimmer)} />
        <div className={cn(s.listingInfo2, s.shimmer)} />
        <div className={cn(s.listingInfo3, s.shimmer)} />
        <div className={cn(s.listingInfo4, s.shimmer)} />
      </div>
      <div className={s.extraInfoWrapper} />
      <div className={s.actionButtons}>
        <div className={cn(s.actionButton1, s.shimmer)} />
        <div className={cn(s.actionButton1, s.shimmer)} />
        <div className={cn(s.actionButton2, s.shimmer)} />
      </div>
      <div className={s.priceWrapper}>
        <div className={cn(s.price1, s.shimmer)} />
        <div className={cn(s.price2, s.shimmer)} />
      </div>
    </div>
  );
};

export default ListingCardSkeleton;
