import { useState } from 'react';
import { TourbookOwner } from '@root/types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import { ConfirmationModal, OnlyInDesktopTablet, OnlyInMobile } from '@components/shared';
import { useHistory } from 'react-router-dom';
import useAltText from '@root/shared/useAltText';
import routes from '@root/routes';
import { InfoIcon } from '@viewthespace/components';
import cn from 'classnames';
import SafeRender from '@components/shared/SafeRender';
import s from './ContactInformation.module.less';

type Props = {
  user: TourbookOwner;
  readOnly: boolean;
  darkMode?: boolean;
};

const ContactInformation = ({ user, readOnly, darkMode = true }: Props) => {
  const { t } = useTranslation('tourbook');
  const { getAltTextForUserCompanyLogo } = useAltText();
  const history = useHistory();
  const [isContactInfoModalOpen, setIsContactInfoModalOpen] = useState(false);

  const addStyles = (...args: string[]) => cn({ [s.darkMode]: darkMode }, ...args);

  const phoneNumber = user?.phoneExtension
    ? t('common:formattedPhoneWithExtension', {
        formattedPhone: user?.phoneNumber,
        extension: user?.phoneExtension,
      })
    : t('common:formattedPhone', {
        formattedPhone: user?.phoneNumber,
      });

  const handleClickInfoIcon = () => {
    setIsContactInfoModalOpen(true);
  };

  const goToYourAccount = () => {
    history.push(`${routes.users.account}?page=profile`);
  };

  return user ? (
    <div className={s.container}>
      <span className={s.thumb}>
        {user?.tourbookAvatarImage ? (
          <img
            className={addStyles(s.avatar)}
            src={user?.tourbookAvatarImage?.smallPath}
            alt={user.fullName}
          />
        ) : (
          <div className={addStyles(s.initials)} data-testid="initials">
            {user.firstName[0]}
            {user.lastName[0]}
          </div>
        )}
        {user?.tourbookCompanyImage && (
          <img
            className={addStyles(s.companyLogo)}
            src={user?.tourbookCompanyImage?.smallPath}
            alt={getAltTextForUserCompanyLogo(user.fullName)}
          />
        )}
      </span>

      <div>
        <div className={s.contactInformationText}>
          {t('contactInformation.text')}
          {!readOnly && (
            <button type="button" data-testid="contactInfoIcon" onClick={handleClickInfoIcon}>
              <InfoIcon className="ml-[4px]" size="sm" />
            </button>
          )}
        </div>
        <div className={s.name}>
          {user.firstName} {user.lastName}
        </div>
        {!isEmpty(user.formattedPhoneNumber) && (
          <>
            <OnlyInMobile>
              <a href={`tel:${user.formattedPhoneNumber}`} className={s.phoneNumber}>
                {phoneNumber}
              </a>
            </OnlyInMobile>
            <OnlyInDesktopTablet>
              <div className={s.phoneNumber}>{phoneNumber}</div>
            </OnlyInDesktopTablet>
          </>
        )}
        <a
          href={`mailto:${user.email}`}
          className={cn(
            s.email,
            'block max-w-[calc(100vw_-_130px)] overflow-hidden break-words transition-none mobile:!text-[14px]',
          )}
        >
          {user.email}
        </a>
      </div>

      <ConfirmationModal
        onConfirm={goToYourAccount}
        onClose={() => setIsContactInfoModalOpen(false)}
        title={t('contactInformation.modal.title')}
        confirmationElement={
          <SafeRender
            unsafeContent={t('contactInformation.modal.body', {
              interpolation: { escapeValue: false },
            })}
          >
            <span />
          </SafeRender>
        }
        confirmButtonText={t('contactInformation.modal.confirmButtonText')}
        isOpen={isContactInfoModalOpen}
        hideCancelButton
      />
    </div>
  ) : null;
};

export default ContactInformation;
