import { PARAMETERS, EVENT_TYPES, EVENTS } from '@root/tracking/constants';
import { getSourcePageFrom } from '@root/tracking/helpers';
import { TourbookSummary, Tourbook } from '@root/types';

const seenTourbookExplanationAction = () => (dispatch, getState) => {
  dispatch({
    type: 'TOURBOOK_EXPLANATION_SEEN',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.seenTourbookExplanation,
          sourcePage: getSourcePageFrom(getState())!,
          sourceContent: PARAMETERS.tourbookOverlay,
        },
      },
    },
  });
};
type SeeTourbookExplanationAction = typeof seenTourbookExplanationAction;
const seeTourbookExplanation = () => seenTourbookExplanationAction();

export const tourbookDuplicate = (
  tourbook: TourbookSummary | Tourbook,
  newTourbook: Tourbook,
  ownTourbook: boolean,
) => {
  const isSummary = 'ownerId' in tourbook;
  return {
    type: 'TOURBOOK_DUPLICATE',
    payload: {
      tourbook: tourbook.id,
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: ownTourbook
            ? PARAMETERS.duplicateMyTourbook
            : PARAMETERS.duplicateAnotherTourbook,
          sourcePage: isSummary ? PARAMETERS.manageTourbooksPage : PARAMETERS.tourbookPage,
          sourceContent: isSummary ? PARAMETERS.tourbookOverlay : PARAMETERS.tourbook,
          duplicatedTourbookId: newTourbook.id,
          tourbookId: tourbook.id,
          tourbookName: tourbook.name,
          tourbookOwnerId: isSummary ? tourbook.ownerId : tourbook.owner.id,
          tourbookOwnerVTSId: isSummary ? tourbook.ownerVTSId : tourbook.owner.vtsId,
          listingCount: isSummary
            ? tourbook.listingCount
            : (tourbook.listings && tourbook.listings.length) || 0,
        },
      },
    },
  };
};
type TourbookDuplicateAction = typeof tourbookDuplicate;

const tourbookRetryDuplicate = (tourbook: TourbookSummary | Tourbook, ownTourbook: boolean) => {
  const isSummary = 'ownerId' in tourbook;
  return {
    type: 'TOURBOOK_RETRY_DUPLICATE',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: ownTourbook
            ? PARAMETERS.retryDuplicateMyTourbook
            : PARAMETERS.retryDuplicateAnotherTourbook,
          sourcePage: isSummary ? PARAMETERS.manageTourbooksPage : PARAMETERS.tourbookPage,
          tourbookId: tourbook.id,
          tourbookName: tourbook.name,
          tourbookOwnerId: isSummary ? tourbook.ownerId : tourbook.owner.id,
          tourbookOwnerVTSId: isSummary ? tourbook.ownerVTSId : tourbook.owner.vtsId,
          listingCount: isSummary
            ? tourbook.listingCount
            : (tourbook.listings && tourbook.listings.length) || 0,
        },
      },
    },
  };
};
type TourbookRetryDuplicateAction = typeof tourbookRetryDuplicate;

export type Action =
  | SeeTourbookExplanationAction
  | TourbookDuplicateAction
  | TourbookRetryDuplicateAction;

export default {
  seeTourbookExplanation,
  tourbookDuplicate,
  tourbookRetryDuplicate,
};
