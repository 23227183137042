import useCurrentUser from '@root/shared/useCurrentUser';
import { ValidIconTypes } from '@components/shared/CustomIcon';
import classNames from 'classnames';
import Drink from './Drink';
import s from './Bartender.module.less';

type PassedProps = {
  drinks: Array<{ url: string; tooltipText: string; icon: ValidIconTypes }>;
  className?: string;
};

export default function Bartender({ className, drinks }: PassedProps) {
  const { currentUser } = useCurrentUser();
  if (currentUser?.role !== 'admin') return null;

  return (
    <ul
      data-testid="bartender"
      data-qa-testid="bartender-admin"
      className={classNames(s.bartender, className)}
    >
      {drinks.map(props => (
        <Drink key={props.url} {...props} />
      ))}
    </ul>
  );
}
