import api from '@shared/api';
import routes from '@root/routes';
import { SavedSearch } from '@root/types';
import analyticsActions from './analytics';
import fetchSavedSearches from './fetchSavedSearches';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};

const deleteSavedSearch =
  (
    search: SavedSearch,
    { onSuccess, onFailure }: { onSuccess: () => void; onFailure: () => void },
  ) =>
  dispatch =>
    api
      .delete(routes.api.savedSearch(search.id))
      .then(handleErrors)
      .then(() => {
        onSuccess();
        dispatch(analyticsActions.removedASavedSearch());
        dispatch(fetchSavedSearches());
      })
      .catch(() => {
        onFailure();
      });

export default deleteSavedSearch;
