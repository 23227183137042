import * as React from 'react';
import cn from 'classnames';
import { useParams, Link } from 'react-router-dom';
import routes from '@root/routes';
import { useTranslation } from 'react-i18next';
import { CustomIcon } from '@components/shared';
import styles from './TourbookExternalListing.module.less';

type Props = {
  header?: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  singleButton?: boolean;
};

const DefaultHeader = () => {
  const { t } = useTranslation('tourbook');
  const { tourbookId } = useParams<{ tourbookId: string }>();
  return (
    <>
      <Link to={routes.tourbook(tourbookId)} className={styles.sidebarLink}>
        <CustomIcon type="chevron-left" />
      </Link>
      <span className={styles.sidebarTitle}>{t('externalListing.sidebarTitle')}</span>
    </>
  );
};

export default function Drawer({
  header = <DefaultHeader />,
  footer,
  children,
  singleButton = false,
}: Props) {
  return (
    <aside className={styles.sidebar}>
      <section className={styles.sidebarBack}>{header}</section>
      {children}
      <div className={cn(styles.formButtons, singleButton && styles.singleButton)}>{footer}</div>
    </aside>
  );
}
