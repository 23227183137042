import Helmet from 'react-helmet';
import { useParams } from 'react-router-dom';
import routes from '@root/routes';
import { MarketSlug } from '@root/types';
import FilterOptionsProvider from './utils/FilterOptions/FilterOptionsProvider';
import ListingSearch from './ListingSearch';

interface RouteParams {
  marketSlug: MarketSlug | 'custom';
}

export const ListingSearchWrapper = () => {
  const { marketSlug } = useParams<RouteParams>();

  return (
    <FilterOptionsProvider>
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}${routes.search(marketSlug)}`} />
      </Helmet>
      <ListingSearch />
    </FilterOptionsProvider>
  );
};

export default ListingSearchWrapper;
