import api from '@shared/api';
import routes from '@root/routes';
import { Tourbook } from '@root/types';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};

type ReceiveTourbookFetchAction = {
  type: 'RECEIVE_FETCHED_TOURBOOK';
  payload: {
    tourbook: Tourbook;
  };
};

export const receiveTourbook = (tourbook: Tourbook): ReceiveTourbookFetchAction => ({
  type: 'RECEIVE_FETCHED_TOURBOOK',
  payload: {
    tourbook,
  },
});

export const fetchTourbook = (id: string) => async dispatch => {
  return api
    .fetch(routes.api.tourbook(id))
    .then(handleErrors)
    .then(response => response.json())
    .then((tourbook: Tourbook) => {
      dispatch(receiveTourbook(tourbook));
    });
};

export type Actions = ReceiveTourbookFetchAction;
