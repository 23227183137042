import { MultipathImage } from '@root/types';
import { pipe } from './util';
import {
  PowerpointElement,
  PptxUtils,
  PowerpointTextElementProps,
  h3,
  smallText,
  staticImagesPathsInCloudinary,
} from './globals';
import { PAGE_MARGIN, PAGE_WIDTH } from './portrait';

export type BuildingHeaderAttributes = {
  cityLocalityRegion: string;
  countryCode: string | null;
  displayName: string;
  neighborhood: string | null;
  photos: MultipathImage[];
  crossStreets?: string | null;
};
// eslint-disable-next-line import/prefer-default-export
export const generateBuildingHeaderElements = (
  values: BuildingHeaderAttributes,
  { cloudinary, t, pptx }: PptxUtils,
): PowerpointElement[] => {
  const elements: PowerpointElement[] = [];

  const parts: PowerpointTextElementProps[] = [
    {
      data: values.displayName,
      textOptions: { ...h3 },
    },
  ];

  if (values.neighborhood) {
    parts.push(pipe(), {
      data: values.neighborhood || '',
      textOptions: { ...smallText },
    });
  }

  parts.push(pipe(), {
    data: values.cityLocalityRegion,
    textOptions: { ...smallText },
  });

  if (values.countryCode) {
    parts.push(pipe(), {
      data: t(`country:${values.countryCode}`) || '',
      textOptions: { ...smallText },
    });
  }

  if (values.crossStreets) {
    parts.push(pipe(), {
      data: `Cross street: ${values.crossStreets}`,
      textOptions: { ...smallText },
    });
  }

  elements.push({
    type: 'textBox',
    data: parts,
    placement: { x: 0.77, y: 0.22, w: 6.31, h: 0.48 },
  });

  elements.push({
    type: 'shape',
    shapeType: pptx.ShapeType.line,
    placement: {
      h: 0,
      w: PAGE_WIDTH,
      x: 0,
      y: 0.75,
    },
    shapeOptions: {
      line: {
        color: 'DDDDDD',
        width: 1,
      },
    },
  });

  const imageDimensions = {
    x: PAGE_MARGIN,
    y: 0.26,
    w: 0.4,
    h: 0.4,
  };

  if (values.photos.length > 0) {
    const { cloudinaryId } = values.photos[0];
    const path = cloudinary.url(cloudinaryId!, {
      transformation: [{ gravity: 'face', height: 100, width: 100, crop: 'thumb' }, { radius: 15 }],
    });

    elements.push({
      type: 'image',
      placement: imageDimensions,
      data: path,
    });
  } else {
    elements.push({
      type: 'image',
      placement: imageDimensions,
      data: staticImagesPathsInCloudinary.buildingTemplate,
    });
  }

  return elements;
};
