import React from 'react';
import { Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import css from './styles.module.less';
import s from './DemographicsBuildingForm.module.less';
import { FormikDemographicValues } from './DemographicsBuildingForm';

type DemographicProps = {
  initialValues: { demographics: FormikDemographicValues };
  values: FormikDemographicValues;
  demographicsTranslatedNameMap: { [key: string]: string };
  customInputComponent: React.ReactNode;
};

const Demographics: React.FC<DemographicProps> = ({
  initialValues,
  values,
  demographicsTranslatedNameMap,
  customInputComponent,
}) => {
  const { t } = useTranslation('admin');

  const removeIdFromInitialValues = (object: FormikDemographicValues) => {
    const newObject = { ...object };
    delete newObject.id;
    return newObject;
  };

  return (
    <section className={css.amenities}>
      <h2 className={s.demographicHeader}>{t('building.demographics.title')}</h2>
      {Object.keys(removeIdFromInitialValues(initialValues.demographics)).map((demo, index) => (
        <div key={`${demo}.${index + 1}.parent`} className={s.inputLine}>
          <h3 data-testid={`${demo}.title`}>{demographicsTranslatedNameMap[demo]}</h3>
          <div data-testid={`${demo}.parent`}>
            <FieldArray name={demo}>
              {() =>
                values[demo].map((_: string, valIndex: number) => (
                  <Field
                    key={`${demo}.${valIndex + 1}`}
                    name={`${demo}.${valIndex}`}
                    as={customInputComponent}
                  />
                ))
              }
            </FieldArray>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Demographics;
