import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Error } from '@root/types';

type Props = {
  errors: Error[];
  message?: string;
  hasTitle?: boolean;
};

const AdminError = ({ errors, message, hasTitle = true }: Props) => {
  const { t } = useTranslation('admin');
  if (errors.length < 1) return null;

  return (
    <Alert
      message={hasTitle ? message || t('common.error') : undefined}
      description={
        <ul>
          {errors.map(error => (
            <li key={error.message}>{error.message}</li>
          ))}
        </ul>
      }
      type="error"
      showIcon
    />
  );
};

export default AdminError;
