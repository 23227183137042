import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Notification } from '@components/shared';
import routes from '@root/routes';
import useToggle from '@shared/useToggle';
import { ExternalListingAnalyticsInformation, StoreState } from '@root/types';
import { useHistory } from 'react-router-dom';
import actions from '@store/actions/tourbookExternalListingPage';
import useAnalytics from '@root/shared/useAnalytics';
import useCurrentUser from '@root/shared/useCurrentUser';
import useTabs from './useTabs';
import Location from './Location';
import Listing from './Listing';
import Media from './Media';
import { Form, Layout } from '..';
import submitTourbookExternalListing from '../helpers';

type Props = {
  tourbookId: string;
};

const mapState = (state: StoreState, ownProps) => ({
  tourbookId: ownProps.match.params.tourbookId,
});

const mapDispatch = {
  externalListingPageLoad: (tourbookId: string) =>
    actions.tourbookExternalListingPageLoad(tourbookId),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

export const NewPage = ({ tourbookId, externalListingPageLoad }: Props & ReduxProps) => {
  const history = useHistory();
  const { t } = useTranslation('tourbook');
  const { tourbookInteraction, PARAMETERS } = useAnalytics();

  const { hasLoaded: userHasLoaded, currentUser } = useCurrentUser();

  const [listingCountry, setListingCountry] = useState(currentUser?.marketCountryCode);

  useEffect(() => {
    if (userHasLoaded) {
      setListingCountry(currentUser?.marketCountryCode);
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHasLoaded]);

  useEffect(() => {
    externalListingPageLoad(tourbookId);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToTourbook = () => {
    history.push(routes.tourbook(tourbookId));
  };

  const { isOnLocation, isOnMedia, isOnListing, goToLocation, goToMedia, goToListing } = useTabs({
    tourbookId,
  });

  const { value: isPromptEnabled, setFalse: disablePrompt } = useToggle(true);

  const onSubmitSuccess = async response => {
    disablePrompt();
    goToTourbook();

    Notification.info({
      title: t('externalListing.created'),
      text: <a href="#newest">{t('externalListing.createdLink')}</a>,
    });

    const {
      analyticsInformation,
    }: {
      analyticsInformation: ExternalListingAnalyticsInformation;
    } = await response.json();

    tourbookInteraction({
      action: PARAMETERS.save,
      actionType: 'SAVE_TOURBOOK_EXTERNAL_LISTING',
      sourcePage: PARAMETERS.tourbookExternalListingPage,
      otherAttributes: analyticsInformation,
    });
  };

  const onSubmit = values =>
    submitTourbookExternalListing({
      values,
      tourbookId,
      onSuccess: onSubmitSuccess,
    });

  if (!listingCountry) return null;

  return (
    <Form
      onSubmit={onSubmit}
      isPromptEnabled={isPromptEnabled}
      tourbookId={tourbookId}
      countryCode={listingCountry}
    >
      <Layout goToTourbook={goToTourbook}>
        {isOnLocation ? (
          <Location goToMedia={goToMedia} setListingCountry={setListingCountry} />
        ) : null}
        {isOnMedia ? <Media goToLocation={goToLocation} goToListing={goToListing} /> : null}
        {isOnListing ? <Listing goToMedia={goToMedia} /> : null}
      </Layout>
    </Form>
  );
};

export default connector(NewPage);
