import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ButtonLink, OnlyInDesktop, OnlyInTabletMobile } from '@components/shared';
import { RawSelect } from '@components/shared/forms';
import { useHistory } from 'react-router-dom';
import routes from '@root/routes';
import s from './Portfolio.module.less';

type Props = {
  marketSlugs: string[];
  selectedRegionSlug: string;
  landlordSlug: string;
  landlordId: string;
  isPreview: boolean;
};

const RegionButtonGroup = ({
  marketSlugs,
  selectedRegionSlug,
  landlordSlug,
  landlordId,
  isPreview,
}: Props) => {
  const { t } = useTranslation('');
  const history = useHistory();

  return marketSlugs.length > 1 ? (
    <>
      <OnlyInDesktop>
        <div className="mt-4 flex justify-center gap-1">
          {marketSlugs.map(marketSlug => (
            <ButtonLink
              className={cn(marketSlug === selectedRegionSlug && s.selected)}
              type="tertiary"
              key={marketSlug}
              size="medium"
              href={
                isPreview
                  ? `${routes.landlordPreview(landlordId)}?market=${marketSlug}`
                  : `${routes.landlords(landlordSlug)}?market=${marketSlug}`
              }
            >
              {t(`common:markets.${marketSlug}`)}
            </ButtonLink>
          ))}
        </div>
      </OnlyInDesktop>
      <OnlyInTabletMobile>
        <div className="flex mobile:justify-center">
          <RawSelect
            name="marketSlug"
            className={s.buttonsDeviceSelect}
            value={selectedRegionSlug}
            onChange={slug => {
              if (isPreview) {
                history.push(`${routes.landlordPreview(landlordId)}?market=${slug}`);
              } else {
                history.push(`${routes.landlords(landlordSlug)}?market=${slug}`);
              }
            }}
            options={marketSlugs.map(marketSlug => ({
              value: marketSlug,
              label: t(`common:markets.${marketSlug}`),
            }))}
          />
        </div>
      </OnlyInTabletMobile>
    </>
  ) : null;
};

export default RegionButtonGroup;
