import { MultipathImage } from '@root/types';
import { useTranslation } from 'react-i18next';

type Props = {
  assetImage: MultipathImage | null;
  onUpdate: (params: { useLandlordBannerImage: boolean }) => void;
};

const AlternativeAssetSelector = ({ assetImage, onUpdate }: Props) => {
  const { t } = useTranslation('admin');

  const handleClickAlternativeAsset = () => {
    // Currently only banner image uses this alternative asset selector.
    // We can make this more generic if we ever want to expand it's usage.
    const params = { useLandlordBannerImage: true };
    onUpdate(params);
  };

  return (
    <div
      onClick={() => handleClickAlternativeAsset()}
      role="button"
      className="flex h-[147px] w-[264px] flex-col items-center justify-center gap-1 border border-solid bg-background-primary"
    >
      <span>{t('building.brandedAssets.addModal.bannerImage.inheritFromLandlord')}</span>
      {!!assetImage && <img className="w-30" src={assetImage.rawPath} alt="landlord banner" />}
    </div>
  );
};

export default AlternativeAssetSelector;
