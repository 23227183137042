import { Breakpoint } from '@root/types';

export const breakpoints: { [k: string]: Breakpoint } = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

export const defaultConfig = {
  [breakpoints.MOBILE]: 0,
  [breakpoints.TABLET]: 768,
  [breakpoints.DESKTOP]: 1025,
};
