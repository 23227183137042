import { AbbreviatedRegion, CountryCode, Currency, Locale } from '@root/types';

export const countryToCurrencySymbol = (
  countryCode: CountryCode,
  locale: Locale,
  showOnlyCurrency?: boolean,
) => {
  const currencyCode = countryToCurrencyCode(countryCode);
  return currencyCodeToSymbol(currencyCode, locale, showOnlyCurrency);
};

export const countryToCurrencyCode = (countryCode: CountryCode): Currency => {
  const countryCurrencyCode = {
    US: 'USD',
    GB: 'GBP',
  };

  return (countryCurrencyCode[countryCode] as Currency) || 'USD';
};

export const currencyCodeToSymbol = (
  currencyCode: Currency,
  locale: Locale,
  showOnlyCurrency?: boolean,
) => {
  const currencySymbol = (0)
    .toLocaleString(locale, { style: 'currency', currency: currencyCode, minimumFractionDigits: 0 })
    .replace(/\d/g, '')
    .trim();
  if (showOnlyCurrency) {
    return currencySymbol === 'US$' ? '$' : currencySymbol;
  }
  return currencySymbol === 'US$' ? 'US $' : currencySymbol;
};

export const abbreviatedRegionToCountryCode = (abbreviatedRegion: AbbreviatedRegion) => {
  const abbreviationCountryCode = {
    UK: 'GB',
  };

  return abbreviationCountryCode[abbreviatedRegion] || 'US';
};

export const abbreviatedRegionToCurrencySymbol = (
  abbreviatedRegion: AbbreviatedRegion,
  locale: Locale,
) => {
  const countryCode = abbreviatedRegionToCountryCode(abbreviatedRegion);
  const currencyCode = countryToCurrencyCode(countryCode);
  return currencyCodeToSymbol(currencyCode, locale);
};
