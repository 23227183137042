import { useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Tourbook } from '@root/types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '@components/shared/V2Button';
import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import { CustomIcon } from '@components/shared';
import cn from 'classnames';
import actions from '@store/actions/tourbookPage';
import routes from '@root/routes';
import DuplicateTourbookLoadingState from '@components/shared/DuplicateTourbookLoadingState';
import useCurrentUser from '@shared/useCurrentUser';
import { isInIframe } from '@root/shared/iframeUtils';
import useToggle from '@root/shared/useToggle';
import ModalForOldTourbooks from '@components/layouts/Truva/Tourbook/Analytics/ModalForOldTourbooks/ModalForOldTourbooks';
import TourbookShareButton from '@components/shared/TourbookShareButton/TourbookShareButton';
import Truncate from 'react-truncate';
import useSize from '@react-hook/size';
import useAnalytics from '@root/shared/useAnalytics';
import tourbookStyles from './TourbookHeader/Header.module.less';
import TableOfContentsDropdown from './TableOfContents/TableOfContentsDropdown';

const mapDispatch = {
  tourbookCreateEntry: () => actions.tourbookCreateEntry(),
};

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  tourbook: Tourbook;
  readOnly: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onDownload: () => void;
  isCollapsed: boolean;
  isDuplicating: boolean;
  onDuplicateTourbookClick: () => void;
  onDuplicateTourbook: () => void;
};

const TourbookControls = ({
  tourbook: { name, shareUrl, id, listings, owner, analyticsSupport, firstSharedAt },
  readOnly,
  onEditClick,
  onDeleteClick,
  onDownload,
  tourbookCreateEntry,
  isCollapsed,
  isDuplicating,
  onDuplicateTourbookClick,
  onDuplicateTourbook,
}: Props & ReduxProps) => {
  const { t } = useTranslation('tourbook');
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { tourbookInteraction, PARAMETERS } = useAnalytics();
  const hasCurrentUser = !!currentUser;
  const duplicateTourbook = hasCurrentUser;
  const tourbookOwnerAuth = owner.id === currentUser?.id;

  const isAdmin = hasCurrentUser && currentUser?.role === 'admin';
  const userHasAccessToAnalytics = tourbookOwnerAuth || isAdmin;

  const {
    value: isAnalyticsModalOpen,
    setTrue: openAnalyticsModal,
    setFalse: closeAnalyticsModal,
  } = useToggle();

  const onNewExternalListingClick = () => {
    tourbookCreateEntry();
    history.push(routes.tourbookNewExternalListing(id));
  };

  const titleRef = useRef(null);
  const [titleWidth] = useSize(titleRef);

  const onAnalyticsButtonClicked = () => {
    tourbookInteraction({
      action: PARAMETERS.viewAnalytics,
      actionType: 'TOURBOOK_VIEW_ANALYTICS',
      sourcePage: isInIframe() ? PARAMETERS.marketTourbookPage : PARAMETERS.tourbookPage,
    });

    if (analyticsSupport) {
      history.push(routes.tourbookAnalytics(id));
    } else {
      openAnalyticsModal();
    }
  };

  return (
    <>
      <div
        className={cn([
          tourbookStyles.controlsSection,
          'mobile:px-2 mobile:py-3',
          isCollapsed && tourbookStyles.collapsedHeader,
          isInIframe() && '!fixed left-0 right-0 !z-[52] !mx-3',
        ])}
      >
        <div className={cn(tourbookStyles.controlTitleContainer, 'flex-grow')}>
          <TableOfContentsDropdown listings={listings} readOnly={readOnly} />
          <div
            className={cn([
              tourbookStyles.controlsTitle,
              tourbookStyles.withShrinkingTextTransition,
              !isCollapsed && tourbookStyles.mimicTitleText,
              readOnly && !duplicateTourbook && tourbookStyles.readOnly,
              'w-full overflow-hidden',
            ])}
            data-testid="controlsTitle"
            ref={titleRef}
          >
            {isCollapsed && (
              <Truncate lines={2} trimWhitespace width={titleWidth ? titleWidth - 8 : 0}>
                {name}
              </Truncate>
            )}
          </div>
        </div>
        <div className={tourbookStyles.controlsButtons}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {readOnly && !tourbookOwnerAuth ? (
            hasCurrentUser ? (
              <div className={tourbookStyles.btnContainer}>
                {userHasAccessToAnalytics && (
                  <Button
                    type="secondary"
                    icon="analytics"
                    className={tourbookStyles.analyticsButton}
                    iconClass={tourbookStyles.buttonIcon}
                    onClick={onAnalyticsButtonClicked}
                  >
                    <span className={tourbookStyles.iconText}>{t('header.viewAnalytics')}</span>
                  </Button>
                )}
                <OverflowMenu
                  overflowClassName={tourbookStyles.overflowMenu}
                  overlayClassName={tourbookStyles.overflowDropdown}
                  iconClass={tourbookStyles.overflowIcon}
                  iconType="overflow"
                >
                  <OverflowMenuItem
                    className={tourbookStyles.duplicateButton}
                    onClick={onDuplicateTourbook}
                  >
                    <>
                      <CustomIcon type="duplicate" />
                      {t('header.duplicate')}
                    </>
                  </OverflowMenuItem>
                </OverflowMenu>
              </div>
            ) : null
          ) : (
            <>
              <div className={tourbookStyles.btnContainer}>
                <Button
                  type="secondary"
                  onClick={onNewExternalListingClick}
                  className={tourbookStyles.createButton}
                  icon="plus"
                  iconClass={tourbookStyles.buttonIcon}
                >
                  <span className={tourbookStyles.iconText}>
                    {t('header.createExternalListing')}
                  </span>
                </Button>
                {/* eslint-disable-next-line no-nested-ternary */}
                {userHasAccessToAnalytics ? (
                  <Button
                    type="secondary"
                    icon="analytics"
                    className={tourbookStyles.analyticsButton}
                    iconClass={tourbookStyles.buttonIcon}
                    onClick={onAnalyticsButtonClicked}
                  >
                    <span className={tourbookStyles.iconText}>{t('header.viewAnalytics')}</span>
                  </Button>
                ) : null}
                <TourbookShareButton
                  tourbookId={id}
                  shareUrl={shareUrl}
                  analyticsSupport={analyticsSupport}
                  firstSharedAt={firstSharedAt}
                  iconClassName="mobile:!mr-0"
                  buttonClassName="mobile:flex mobile:items-center mobile:!py-[6px] mobile:!px-[12px]"
                  textClassName="mobile:hidden"
                />
                <OverflowMenu
                  overflowClassName={tourbookStyles.overflowMenu}
                  overlayClassName={cn(tourbookStyles.overflowDropdown, isInIframe() && '!z-[100]')}
                  iconClass={tourbookStyles.overflowIcon}
                  iconType="overflow"
                >
                  {userHasAccessToAnalytics && (
                    <OverflowMenuItem
                      className={cn(
                        isCollapsed && tourbookStyles.collapsed,
                        tourbookStyles.mobileAndCollapsedOnly,
                      )}
                      onClick={onAnalyticsButtonClicked}
                    >
                      <>
                        <CustomIcon type="analytics" />
                        {t('header.viewAnalytics')}
                      </>
                    </OverflowMenuItem>
                  )}
                  <OverflowMenuItem
                    className={cn(
                      isCollapsed && tourbookStyles.collapsed,
                      tourbookStyles.mobileAndCollapsedOnly,
                    )}
                    onClick={onNewExternalListingClick}
                  >
                    <>
                      <CustomIcon type="plus" />
                      {t('header.createExternalListing')}
                    </>
                  </OverflowMenuItem>
                  <OverflowMenuItem onClick={onEditClick}>
                    <>
                      <CustomIcon type="edit" />
                      {t('header.editTourbook')}
                    </>
                  </OverflowMenuItem>
                  <OverflowMenuItem onClick={onDownload}>
                    <>
                      <CustomIcon type="download" />
                      {t('header.downloadTourbook')}
                    </>
                  </OverflowMenuItem>
                  <OverflowMenuItem
                    className={tourbookStyles.duplicateButton}
                    onClick={onDuplicateTourbookClick}
                  >
                    <>
                      <CustomIcon type="duplicate" />
                      {t('header.duplicate')}
                    </>
                  </OverflowMenuItem>
                  <OverflowMenuItem onClick={onDeleteClick}>
                    <>
                      <CustomIcon type="trash" />
                      {t('header.delete')}
                    </>
                  </OverflowMenuItem>
                </OverflowMenu>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={isInIframe() ? '!pt-[58px]' : ''} />
      {isDuplicating && <DuplicateTourbookLoadingState />}
      <ModalForOldTourbooks isModalOpen={isAnalyticsModalOpen} closeModal={closeAnalyticsModal} />
    </>
  );
};

export default connector(TourbookControls);
