import { MultipathImage, PowerpointBuildingEntry, PowerpointTourbook } from '@root/types';
import {
  addElementsToSlide,
  generateDescriptionElement,
  generateElementsFromSections,
  makeElementsRelative,
} from './util';
import { generateFooterElements, PAGE_MARGIN } from './portrait';
import {
  h3,
  smallText,
  PptxUtils,
  PowerpointElement,
  entityDetailsHeadingText,
  SlideSection,
} from './globals';
import { generateBuildingHeaderElements } from './buildingHeader';
import { createBuildingMosaic } from './mosaic';

const defaultPlacements = { valign: 'top', margin: 0 } as const;
export const generateBuildingBasicInfoElements = (
  building: PowerpointBuildingEntry,
  { t, cloudinary }: PptxUtils,
): PowerpointElement[] => {
  const elements: PowerpointElement[] = [];

  elements.push({
    type: 'textBox',
    data: t('tourbook:powerpoint.buildingOwnerLabel').toUpperCase(),
    textOptions: entityDetailsHeadingText(),
    placement: { ...defaultPlacements, x: PAGE_MARGIN, y: 6.86, w: 2.22, h: 0.22 },
  });

  if (building.landlordLogo) {
    elements.push({
      type: 'image',
      data: cloudinary.url(building.landlordLogo.cloudinaryId!, {
        transformation: [
          { fetch_format: 'png' },
          { effect: 'trim' },
          { width: 300, height: 100, crop: 'pad', background: 'white' },
        ],
      }),
      placement: {
        x: PAGE_MARGIN,
        y: 7.12,
        w: 0.66,
        h: 0.22,
        ...defaultPlacements,
      },
    });
  }

  const rowOnePlacements = [
    { ...defaultPlacements, x: 2.6, y: 6.86, w: 2.22, h: 0.42 },
    { ...defaultPlacements, x: 4.94, y: 6.86, w: 2.22, h: 0.42 },
  ];

  if (building.numberOfFloors) {
    elements.push({
      type: 'textBox',
      data: [
        {
          data: t('tourbook:powerpoint.numberOfFloors').toUpperCase(),
          textOptions: entityDetailsHeadingText({ breakLine: true }),
        },
        {
          data: `${building.numberOfFloors}`,
          textOptions: smallText,
        },
      ],
      placement: rowOnePlacements.shift(),
    });
  }

  if (building.rentableBuildingArea) {
    elements.push({
      type: 'textBox',
      data: [
        {
          data: t('tourbook:powerpoint.rentableBuilingAreaLabel').toUpperCase(),
          textOptions: entityDetailsHeadingText({ breakLine: true }),
        },
        {
          data: t('units:area', { area: building.rentableBuildingArea }),
          textOptions: { ...smallText },
        },
      ],
      placement: rowOnePlacements.shift(),
    });
  }

  const rowTwoPlacements = [
    { ...defaultPlacements, x: PAGE_MARGIN, y: 7.41, w: 2.22, h: 0.42, margin: 0 },
    { ...defaultPlacements, x: 2.6, y: 7.41, w: 2.22, h: 0.39, margin: 0 },
  ];

  if (building.yearRenovated) {
    elements.push({
      type: 'textBox',
      data: [
        {
          data: t('tourbook:powerpoint.yearRenovatedLabel').toUpperCase(),
          textOptions: entityDetailsHeadingText({ breakLine: true }),
        },
        {
          data: building.yearRenovated,
          textOptions: { ...smallText },
        },
      ],
      placement: rowTwoPlacements.shift(),
    });
  }

  if (building.yearBuilt) {
    elements.push({
      type: 'textBox',
      data: [
        {
          data: t('tourbook:powerpoint.yearBuiltLabel').toUpperCase(),
          textOptions: entityDetailsHeadingText({ breakLine: true }),
        },
        {
          data: building.yearBuilt,
          textOptions: { ...smallText },
        },
      ],
      placement: rowTwoPlacements.shift(),
    });
  }

  return elements;
};

export const generateNotableTenantElements = (
  notableTenantLogos: MultipathImage[],
  { cloudinary, t }: PptxUtils,
): PowerpointElement[] => {
  const elements: PowerpointElement[] = [];

  if (notableTenantLogos.length > 0) {
    elements.push({
      type: 'textBox',
      data: [
        {
          data: t('tourbook:powerpoint.notableTenantsLabel'),
          textOptions: { ...h3 },
        },
      ],
      placement: { x: PAGE_MARGIN, y: 8.15, w: 2.56, h: 0.3, margin: 0 },
    });

    const imgWidth = 0.66;
    const padding = 0.1;

    notableTenantLogos.forEach((logo, index) => {
      const cloudinaryUrl = cloudinary.url(logo.cloudinaryId!, {
        transformation: [
          { effect: 'trim' },
          { effect: 'grayscale' },
          { width: 300, height: 100, crop: 'pad', background: 'white' },
          { fetch_format: 'png' },
        ],
      });
      elements.push({
        type: 'image',
        data: cloudinaryUrl,
        placement: {
          x: PAGE_MARGIN * (index + 1) + padding * index + index * imgWidth,
          y: 8.53,
          w: imgWidth,
          h: 0.22,
        },
      });
    });
  }
  return elements;
};

export const generateBuildingPageOneSlide = ({
  tourbook,
  building,
  utils,
}: {
  tourbook: PowerpointTourbook;
  building: PowerpointBuildingEntry;
  utils: PptxUtils;
}) => {
  const { pptx, t } = utils;

  const mosaicSlide = pptx.addSlide();

  createBuildingMosaic(utils, mosaicSlide, building.photos);
  const staticElements: PowerpointElement[] = [
    ...generateBuildingHeaderElements(building, utils),
    ...generateFooterElements(tourbook, utils),
  ];
  const relativeElements: SlideSection[] = [
    [
      ...generateDescriptionElement(building.description, {
        heading: t('tourbook:powerpoint.aboutTheBuilding'),
        y: 3.96,
        limit: 1200,
        h: 2.7,
        dynamicSize: true,
      }),
    ],
    [
      ...generateBuildingBasicInfoElements(building, utils),
      ...generateNotableTenantElements(building.notableTenantLogos, utils),
    ],
  ];

  const computedRelativeElements = generateElementsFromSections(
    relativeElements.map(sections => sections.map(makeElementsRelative)),
    {
      x: 0.38,
      y: 3.96,
    },
  );

  // multi-line textboxes have additional inherent padding, 0.27 lines it up with the page margin
  if (
    computedRelativeElements[0]?.type === 'textBox' &&
    Array.isArray(computedRelativeElements[0].data) &&
    computedRelativeElements[0].placement?.x
  )
    computedRelativeElements[0].placement.x = 0.27;

  const buildingPageOneElements = [...staticElements, ...computedRelativeElements];

  addElementsToSlide(mosaicSlide, buildingPageOneElements);
};
