import routes from '@root/routes';
import { useLocation } from 'react-router-dom';
import { stringify } from 'qs';

export default function useLoginUrl() {
  const location = useLocation();
  const locationQueryString = stringify(
    { origin: `${location.pathname}${location.search}` },
    { addQueryPrefix: true },
  );

  return {
    loginUrl: `${routes.login}${locationQueryString}`,
    logoutUrl: `${routes.logout}${locationQueryString}`,
  };
}
