import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import routes from '@root/routes';
import Container from '@components/shared/Admin/Container';
import Form from '../Form';
import Layout from '../../Layout/Layout';

const NewPage = () => {
  const history = useHistory();
  const { t } = useTranslation('admin');

  const onSubmitSuccess = ({ id }: { id: string }) => {
    notification.success({ message: t('landlord.createSuccess') });
    history.push(routes.admin.landlordEdit(id));
  };

  return (
    <Layout>
      <Container title={t('landlord.newTitle')}>
        <Form
          landlord={null}
          submitText={t('common.createButton')}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Container>
    </Layout>
  );
};

export default NewPage;
