import { useState } from 'react';
import * as React from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { updateCurrentUser } from '@store/actions/currentUser';
import useCurrentUser from '@root/shared/useCurrentUser';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '@components/shared';
import actions from '@store/actions/tourbookPage';
import { isInIframe } from '@root/shared/iframeUtils';
import cn from 'classnames';
import styles from './TourbookExternalListing.module.less';
import Layout from '../Layout';
import ListingCard from './ListingCard';

type Props = {
  goToTourbook: () => void;
  children: React.ReactNode; // Aside content
  tourbookUserPolicyAgree: () => void;
  tourbookUserPolicyCancel: () => void;
};

const mapDispatch = {
  tourbookUserPolicyAgree: () => actions.tourbookUserPolicyAgree(),
  tourbookUserPolicyCancel: () => actions.tourbookUserPolicyCancel(),
};

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

export const TourbookExternalListingLayout = ({
  goToTourbook,
  children,
  tourbookUserPolicyAgree,
  tourbookUserPolicyCancel,
}: Props & ReduxProps) => {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation('tourbook');
  const dispatch = useDispatch();

  const [userContentAgreementLoading, setUserContentAgreementLoading] = useState(false);

  const onCancel = () => {
    tourbookUserPolicyCancel();
    goToTourbook();
  };

  return (
    <Layout
      activePage="tourbookExternalListing"
      showFooter={false}
      containerClassName={styles.layoutContainerFullWidth}
    >
      {isInIframe() && (
        <div className="h-[12px] w-full border-b-[1px] border-solid border-black-010 bg-white" />
      )}
      <div className={cn(styles.pageFullWidth, isInIframe() && styles.noNav)}>
        {children}
        <div className={styles.cardContent}>
          <ConfirmationModal
            isOpen={!!currentUser && !currentUser.userContentAgreement}
            isLoading={userContentAgreementLoading}
            closable={false}
            bodyClassName={styles.confirmationModalBody}
            confirmationText={t('externalListing.userContentAgreement.text')}
            confirmButtonText={t('externalListing.userContentAgreement.confirmButton')}
            cancelButtonText={t('externalListing.userContentAgreement.cancelButton')}
            onConfirm={() => {
              setUserContentAgreementLoading(true);
              tourbookUserPolicyAgree();
              dispatch(
                updateCurrentUser({
                  user: { id: currentUser!.id, userContentAgreement: true },
                  onSuccess: () => {},
                  source: 'external tourbook listing',
                  action: 'sign user content agreement',
                }),
              );
            }}
            onClose={onCancel}
            data-testid="userContentAgreement"
          />

          <ListingCard isPreview readOnly />
        </div>
      </div>
    </Layout>
  );
};

export default connector(TourbookExternalListingLayout);
