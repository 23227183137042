/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { notification } from 'antd';
import { flow } from 'lodash/fp';
import ErrorBoundary from '@components/shared/ErrorBoundary';
import routes from '@root/routes';
import useUserRegisterer from '@components/shared/useUserRegisterer';
import { withTranslation } from 'react-i18next';
import useEnv from '@shared/useEnv';
import { useIframeMessageListener } from '@root/shared/iframeUtils';
import { ThemeProvider } from '@viewthespace/components';
import AccountSettings from './AccountSettings';
import Building from './Building';
import BrowseBuilding from './Browse/Building';
import BrowseLocality from './Browse/Locality';
import BrowseRegionList from './Browse/RegionList';
import BrowseRegion from './Browse/Region';
import Homepage from './Homepage';
import Listing from './Listing';
import ListingPreview from './Listing/ListingPreview';
import ListingSearch from './ListingSearch';
import ListingShare from './Listing/ListingShare';
import VtsMarketBuildingShare from './Building/VtsMarketBuildingShare';
import VtsMarketListingShare from './Listing/VtsMarketListingShare';
import VtsMarketLandlordShare from './Landlord/VtsMarketLandlordShare';
import SavedListings from './Saved/Listings';
import SavedSearches from './Saved/Searches';
import SavedBuildings from './Saved/Buildings';
import SavedBuildingOwners from './Saved/BuildingOwners';
import Tourbook from './Tourbook';
import TourbookAnalytics from './Tourbook/Analytics';
import TourbookShare from './Tourbook/TourbookShare';
import Tourbooks from './Tourbooks';

import {
  NewPage as NewTourbookExternalListing,
  EditPage as EditTourbookExternalListing,
} from './TourbookExternalListing';
import Landlord from './Landlord';
import LandlordPreview from './Landlord/LandlordPreview';
import { DesktopUpsellBanner, MobileUpsellBanner } from './UpsellBanner';
import OnboardingModal from './OnboardingModal';
import Playground from './Playground';

import s from './App.module.less';
import '@styles/index.less';
import '@styles/tailwind.css';
import NotFound from './404/NotFound';

const {
  building: buildingRoute,
  buildingPreview: buildingPreviewRoute,
  listing: listingRoute,
  listingPreview: listingPreviewRoute,
  listingShare: listingShareRoute,
  users: usersRoute,
  landlords: landlordsRoute,
  landlordPreview: landlordPreviewRoute,
} = routes;

type Props = {
  notificationsOnLoad?: { notice?: string; alert?: string };
};

const App = ({ notificationsOnLoad }: Props) => {
  useEffect(() => {
    if (!notificationsOnLoad) return;

    const { notice, alert } = notificationsOnLoad;

    if (notice) notification.info({ message: notice });
    if (alert) notification.warning({ message: alert });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useUserRegisterer();

  const { railsEnv } = useEnv();

  useEffect(() => {
    const setVh = () => {
      // this is because mobile doesn't accept vh as a property correctly
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVh();

    window.addEventListener('resize', setVh);
  }, []);

  useIframeMessageListener();

  return (
    <ErrorBoundary>
      <ThemeProvider testAttribute="data-testid" defaultButtonShape="pill">
        <Helmet defaultTitle="VTS Marketplace">
          <meta name="facebook-domain-verification" content="zmbmz9wdbci8r54lt6tdstt9ir4k8d" />
        </Helmet>
        <div id="allContent" className={s.layout}>
          <div id="health-check-id" data-testid="health-check-id" />
          <OnboardingModal />
          <header className={s.header}>
            <DesktopUpsellBanner />
            <MobileUpsellBanner />
          </header>
          <Switch>
            <Route path={routes.search(':marketSlug')} component={ListingSearch} />
            <Route path={routes.savedListings} component={SavedListings} />
            <Route path={routes.savedSearches} component={SavedSearches} />
            <Route path={routes.savedBuildings} component={SavedBuildings} />
            <Route path={routes.savedBuildingOwners} component={SavedBuildingOwners} />
            <Route
              path={routes.tourbookNewExternalListing(':tourbookId')}
              component={NewTourbookExternalListing}
            />
            <Route
              path={routes.tourbookEditExternalListing(':tourbookId', ':externalListingId')}
              component={EditTourbookExternalListing}
            />
            <Route
              path={routes.tourbookAnalytics(':tourbookId')}
              render={() => <TourbookAnalytics />}
            />
            <Route path={routes.tourbook(':tourbookId')} component={Tourbook} />
            <Route path={routes.tourbookShare(':tourbookId')} component={TourbookShare} />
            <Route path={routes.tourbooks} component={Tourbooks} />
            <Route path={listingPreviewRoute(':listingId')} component={ListingPreview} />
            <Route path={listingRoute(':listingId', ':buildingSlug')} component={Listing} />
            <Route
              path={listingShareRoute(':encodedData')}
              render={props => <ListingShare {...props} isPreview={false} />}
            />
            <Route
              path={routes.vtsMarketBuildingShare(':buildingSlug')}
              render={props => <VtsMarketBuildingShare {...props} isPreview={false} />}
            />
            <Route
              path={routes.vtsMarketListingShare(':listingId')}
              render={props => <VtsMarketListingShare {...props} isPreview={false} />}
            />
            <Route
              path={buildingPreviewRoute(':buildingSlug')}
              render={props => <Building {...props} isPreview />}
            />
            <Route
              path={buildingRoute(':buildingSlug')}
              render={props => <Building {...props} isPreview={false} />}
            />
            <Route render={() => <AccountSettings />} path={usersRoute.account} />
            <Route path={landlordsRoute(':slug')} component={Landlord} />
            <Route path={routes.browseBuilding(':buildingSlug')} component={BrowseBuilding} />
            <Route
              path={routes.browseLocality(':localitySlug', ':abbreviatedRegion')}
              component={BrowseLocality}
            />
            <Route path={routes.browseRegion(':abbreviatedName')} component={BrowseRegion} />
            <Route path={routes.browseRegionList} component={BrowseRegionList} />
            <Route path={landlordPreviewRoute(':landlordId')} component={LandlordPreview} />
            <Route
              path={routes.vtsMarketLandlordShare(':slug')}
              component={VtsMarketLandlordShare}
            />
            {railsEnv === 'development' ? (
              <Route exact path="/playground" component={Playground} />
            ) : null}
            <Route exact path="/" component={Homepage} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default flow(withTranslation())(App);
