import api from '@shared/api';
import {
  SearchListing,
  BuildingMapPoint,
  PaginationResponse,
  Shape,
  BuildingsResponse,
  LastInquiry,
} from '@root/types';
import { getListingSearchPageLevelArguments } from '@store/selectors';
import analyticsActions from './analyticsActions';
import otherActions from '../index';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};
const ignoreErrors = () => {};

type ListingResponse = {
  savedStatuses: Array<{ listingId: string; saved: boolean }>;
  inquiries: LastInquiry[];
  listings: SearchListing[];
  pagination: PaginationResponse;
};

type ListingSearchLoadingAction = {
  type: 'LISTING_SEARCH_LOADING';
  payload: {
    loading: boolean;
  };
};

const listingSearchLoading = ({ loading }): ListingSearchLoadingAction => ({
  type: 'LISTING_SEARCH_LOADING',
  payload: { loading },
});

type ReceiveSearchListingsAction = {
  type: 'RECEIVE_SEARCH_LISTINGS';
  payload: ListingResponse;
};
export const receiveListings = (listingResponse: ListingResponse): ReceiveSearchListingsAction => ({
  type: 'RECEIVE_SEARCH_LISTINGS',
  payload: listingResponse,
});

type ReceiveSearchBuildingsAction = {
  type: 'RECEIVE_SEARCH_BUILDINGS';
  payload: { buildings: BuildingMapPoint[]; shapes: Shape[] };
};

export const receiveBuildings = ({
  buildings,
  shapes,
}: {
  buildings: BuildingMapPoint[];
  shapes: Shape[];
}): ReceiveSearchBuildingsAction => ({
  type: 'RECEIVE_SEARCH_BUILDINGS',
  payload: {
    buildings,
    shapes,
  },
});

const loadSearchResults =
  ({
    pageNumber,
    apiBuildingsUrl,
    apiListingsUrl,
  }: {
    pageNumber?: number;
    apiBuildingsUrl: string;
    apiListingsUrl: string;
  }) =>
  (dispatch, getState) => {
    // Setting prerenderReady tells Prerender.io when the page is ready to be cached.
    window.prerenderReady = false;

    dispatch(otherActions.setListingSearchPage({ pageNumber }));
    dispatch(listingSearchLoading({ loading: true }));

    const listingsFetch = api
      .fetch(apiListingsUrl)
      .then(handleErrors)
      .then(response => response.json())
      .then((listingsResponse: ListingResponse) => {
        dispatch(receiveListings(listingsResponse));
      })
      .catch(ignoreErrors);

    const buildingsFetch = api
      .fetch(apiBuildingsUrl)
      .then(handleErrors)
      .then(response => response.json())
      .then((buildingsResponse: BuildingsResponse) => {
        dispatch(receiveBuildings(buildingsResponse));
      })
      .catch(ignoreErrors);

    Promise.all([listingsFetch, buildingsFetch]).then(() => {
      dispatch(listingSearchLoading({ loading: false }));
      dispatch(
        analyticsActions.listingSearchPageLoaded(getListingSearchPageLevelArguments(getState())),
      );
      // Setting prerenderReady tells Prerender.io when the page is ready to be cached.
      window.prerenderReady = true;
    });
  };

export default loadSearchResults;
export type Actions =
  | ListingSearchLoadingAction
  | ReceiveSearchBuildingsAction
  | ReceiveSearchListingsAction;
