import { useRef } from 'react';
import { Button } from '@components/shared';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import { CountryCode } from '@root/types';
import styles from '../TourbookExternalListing.module.less';
import { LocationFields, FormValues, Drawer } from '..';

type Props = {
  goToMedia: () => void;
  setListingCountry: (countryCode: CountryCode) => void;
};

export const Location = ({ goToMedia, setListingCountry }: Props) => {
  const {
    values: { buildingName, longitude, latitude },
    setFieldTouched,
    errors,
    validateForm,
  } = useFormikContext<FormValues>();

  const { t } = useTranslation('tourbook');

  const locationRef = useRef<HTMLDivElement>(null);

  const sectionFields = () => {
    const fields = [
      'longitude',
      'latitude',
      'addressLine1',
      'addressLine2',
      'city',
      'state',
      'zipCode',
      'location',
    ];

    if (buildingName !== null) {
      fields.push('buildingName');
    }

    return fields;
  };

  const isSectionValid = () => {
    let isValid = true;
    sectionFields().forEach(fieldName => {
      if (errors[fieldName]) {
        isValid = false;
      }
    });
    return isValid;
  };

  const scrollToBottom = () => {
    if (locationRef.current && locationRef.current.scrollTo) {
      setTimeout(() => {
        locationRef.current?.scrollTo(0, locationRef.current?.scrollHeight);
      }, 10);
    }
  };

  const onNextButtonClick = async () => {
    await validateForm();
    sectionFields().forEach(field => {
      setFieldTouched(field, true, true);
    });

    if (!longitude || !latitude) {
      return;
    }

    if (isSectionValid()) {
      goToMedia();
    }
  };

  return (
    <Drawer
      singleButton
      footer={
        <Button type="primary" onClick={onNextButtonClick}>
          {t('externalListing.next')}
        </Button>
      }
    >
      <div ref={locationRef} className={styles.formFields}>
        <div className={styles.progress}>
          <div className={cn(styles.bar, styles.activeBar)}></div>
          <div className={cn(styles.bar)}></div>
          <div className={cn(styles.bar)}></div>
        </div>
        <LocationFields scrollToBottom={scrollToBottom} setListingCountry={setListingCountry} />
      </div>
    </Drawer>
  );
};

export default Location;
