import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import useEnvVariable from '@root/shared/useEnv';
import { notification } from 'antd';
import BuildingManagementForm from './BuildingManagementForm';
import { uploadToCloudinary, handleResponse, deletePhoto, updateOrder } from '../helper';
import MarketingMediaHeader from '../MarketingMediaHeader/MarketingMediaHeader';
import { BuildingManagementData } from '../types';

const BuildingManagement = ({ data }: { data: BuildingManagementData[] }) => {
  const { t } = useTranslation('admin');
  const { cloudinaryUploadPreset, cloudinaryCloud } = useEnvVariable();
  const { values, setValues } = useFormikContext();
  const [draggedItem, setDraggedItem] = useState<BuildingManagementData | undefined>(undefined);
  const [, setDragOverIndex] = useState<number | undefined>(undefined);
  const showCancelUploadMediaButton =
    !(
      data[0].cloudinaryId ||
      data[0].name ||
      data[0].title ||
      data[0].email ||
      data[0].telephone
    ) && data.length === 1;

  const [collapseMedia, setCollapseMedia] = useState(true);

  const sortedContact: BuildingManagementData[] = (data as FormikValues)?.sort(
    (a, b) => a.order - b.order,
  );

  const currentManagementLength = data.length;

  const addNewForm = () => {
    const newContact = {
      mediaType: 'building_management',
      title: '',
      name: '',
      email: '',
      telephone: '',
      cloudinaryId: '',
      order: sortedContact[sortedContact.length - 1].order + 1,
    };

    const updatedFormikValues = {
      ...((values as FormikValues) || {}),
      buildingManagement: [...((values as FormikValues)?.buildingManagement || []), newContact],
    };

    setValues(updatedFormikValues);
  };

  const disableButton = () => {
    return !(currentManagementLength === 6 || !data[data.length - 1].name);
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    contactOrder: number,
  ) => {
    const { files } = event.target;
    if (!files) return;

    const cloudinaryResponse = await uploadToCloudinary(
      files[0],
      cloudinaryUploadPreset || '',
      cloudinaryCloud || '',
      'image',
    );

    handleResponse(
      cloudinaryResponse,
      t('marketingMedia.buildingManagement.createSuccessMessage'),
      t('marketingMedia.buildingManagement.createFailureMessage'),
    );
    if (!cloudinaryResponse.secure_url) return;
    const formikBuildingManagementValues = (values as FormikValues)?.buildingManagement || [];
    const index = formikBuildingManagementValues.findIndex(val => val.order === contactOrder);

    const updatedContact = {
      mediaType: 'building_management',
      title: formikBuildingManagementValues[index].title,
      name: formikBuildingManagementValues[index].name,
      email: formikBuildingManagementValues[index].email,
      telephone: formikBuildingManagementValues[index].telephone,
      cloudinaryId: cloudinaryResponse.secure_url || '',
      order: contactOrder,
    };

    if (index !== -1) {
      const newBuildingManagementArray = [
        ...formikBuildingManagementValues.slice(0, index),
        updatedContact,
        ...formikBuildingManagementValues.slice(index + 1),
      ];
      const updatedFormikValues = {
        ...((values as FormikValues) || {}),
        buildingManagement: newBuildingManagementArray,
      };
      setValues(updatedFormikValues);
    } else {
      formikBuildingManagementValues.push(updatedContact);
    }
  };

  const onDelete = (photoOrder: number) => {
    const buildingManagement = (values as FormikValues)?.buildingManagement || [];

    const updatedFormikValues =
      buildingManagement.length === 1
        ? {
            ...(values as FormikValues),
            buildingManagement: [
              {
                mediaType: 'building_management',
                title: '',
                name: '',
                cloudinaryId: '',
                order: 1,
                email: '',
                telephone: '',
              },
            ],
          }
        : {
            ...(values as FormikValues),
            buildingManagement: buildingManagement
              .filter(media => media.order !== photoOrder)
              .map((media, index) => ({
                ...media,
                order: index + 1,
              })),
          };

    const mediaToDelete = buildingManagement.find(media => media.order === photoOrder);
    if (
      mediaToDelete?.cloudinaryId ||
      mediaToDelete?.name ||
      mediaToDelete?.title ||
      mediaToDelete?.email ||
      mediaToDelete?.telephone
    ) {
      notification.success({
        message: t('marketingMedia.buildingManagement.deleteSuccessMessage'),
      });
    }

    setValues(updatedFormikValues);
  };

  const onPhotoDelete = async (contactOrder: number) => {
    const updatedFormikValues = await deletePhoto(values, 'buildingManagement', contactOrder);

    setValues(updatedFormikValues);
  };

  const onDragStart = (item: BuildingManagementData) => {
    setDraggedItem(item);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    setDragOverIndex(index);
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    if (!draggedItem) return;

    const updatedArray = await updateOrder(
      (values as FormikValues)?.buildingManagement || [],
      draggedItem,
      index,
    );

    setValues({
      ...((values as FormikValues) || {}),
      buildingManagement: updatedArray,
    });
    setDraggedItem(undefined);
    setDragOverIndex(undefined);
  };

  return (
    <section>
      <MarketingMediaHeader
        collapseMedia={collapseMedia}
        mediaTitle={t('marketingMedia.buildingManagement.title')}
        mediaLimit={t('marketingMedia.buildingManagement.maxCount')}
        setCollapseMedia={setCollapseMedia}
      />
      {collapseMedia && (
        <BuildingManagementForm
          data={data}
          onUpload={handleFileUpload}
          onPhotoDelete={onPhotoDelete}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragStart={onDragStart}
          addNewForm={addNewForm}
          disableButton={disableButton()}
          showCancelUploadMediaButton={showCancelUploadMediaButton}
          onDelete={onDelete}
        />
      )}
    </section>
  );
};

export default BuildingManagement;
