import { Dispatch } from 'react';
import routes from '@root/routes';
import api from '@shared/api';
import ListingSearchCriteria, { SearchCriteria } from '../Criteria/ListingSearchCriteria';

const initialSearchFiltersExist = (initialFilters: SearchCriteria): boolean => {
  return (
    !!initialFilters.submarkets?.length ||
    !!initialFilters.buildingIds?.length ||
    !!initialFilters.landlords?.length
  );
};

interface ReadonlySubmarketResults {
  readonly name: string;
  readonly slug: string;
}
interface ReadonlyListingSearchFiltersResults {
  submarkets: Array<ReadonlySubmarketResults>;
}

const getInitialListingSearchFilters = async (
  initialFilters: SearchCriteria,
): Promise<ReadonlyListingSearchFiltersResults> => {
  const response = await api.fetch(
    routes.api.listingSearchFilters({
      submarketSlugs: initialFilters.submarkets || [],
      landlordsIds: initialFilters.landlords || [],
      buildingIds: initialFilters.buildingIds || [],
    }),
  );
  return response.json();
};

const fetchOptionsFromCriteria = async (
  dispatch: Dispatch<any>,
  criteria: ListingSearchCriteria,
) => {
  if (initialSearchFiltersExist(criteria.currentFilters)) {
    getInitialListingSearchFilters(criteria.currentFilters).then(initialListingSearchFilters => {
      dispatch({
        type: 'ADD_INITIAL_FILTER_OPTIONS',
        payload: initialListingSearchFilters,
      });
    });
  }
};

export default fetchOptionsFromCriteria;
