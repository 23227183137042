import routes from '@root/routes';
import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import { useTranslation } from 'react-i18next';
import Layout from '../../Layout';
import AlphabeticalList from '../AlphabeticalList';
import { alphabetizeAndGroupItems } from '../utils';

const RegionList = () => {
  const { t } = useTranslation('common');

  const { data: regions } = useQuery([routes.api.browseRegionList], async () =>
    get(routes.api.browseRegionList),
  );

  const itemsToRender = alphabetizeAndGroupItems(regions || []);

  return (
    <Layout>
      <AlphabeticalList
        breadcrumbs={[
          { content: t('home'), link: routes.root },
          { content: t('country.unitedStates') },
        ]}
        title={t('country.unitedStates')}
        items={itemsToRender}
      />
    </Layout>
  );
};

export default RegionList;
