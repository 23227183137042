import { useTranslation } from 'react-i18next';
import { Button, IconTypes, Menu } from '@viewthespace/components';
import cn from 'classnames';

type MenuPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

type ItemNames = 'upload' | 'preview' | 'delete' | 'edit';
export type Item = { label: string; iconName?: IconTypes; onSelect?: () => void };
type HiddenAssetProps = {
  isAssetHidden?: boolean;
  hiddenAssetPlaceholder;
};

type Props = {
  imageClassName: string;
  onUpload?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onPreview?: () => void;
  editButtonName: string;
  editButtonClassName: string;
  downloadButtonName?: string;
  downloadButtonClassName?: string;
  altText: string;
  imagePath: string;
  imageDownloadPath?: string;
  containerClassName?: string;
  menuPlacement?: MenuPlacement;
  items?: (ItemNames | false | string)[];
  additionalMenuItems?: { [key: string]: Item };
  hiddenAssetProps?: HiddenAssetProps;
};

const EditableAsset = ({
  imageClassName,
  editButtonName,
  editButtonClassName,
  downloadButtonName,
  downloadButtonClassName,
  onUpload,
  onDelete,
  onEdit,
  onPreview,
  altText,
  imagePath,
  imageDownloadPath,
  containerClassName,
  hiddenAssetProps,
  menuPlacement = 'right-start',
  items: itemNames = ['upload', 'edit', 'delete'],
  additionalMenuItems,
}: Props) => {
  const { t } = useTranslation('common');
  const { isAssetHidden, hiddenAssetPlaceholder } = hiddenAssetProps || {};

  const itemMap: { [key: string]: Item } = {
    upload: {
      label: `${t('editableAsset.editMenuOptions.uploadNewImage')}`,
      iconName: 'Upload',
      onSelect: onUpload,
    },
    preview: {
      label: `${t('editableAsset.editMenuOptions.viewFullLogo')}`,
      iconName: 'Preview',
      onSelect: onPreview,
    },
    delete: {
      label: `${t('editableAsset.editMenuOptions.delete')}`,
      iconName: 'Trash',
      onSelect: onDelete,
    },
    edit: {
      label: `${t('editableAsset.editMenuOptions.edit')}`,
      iconName: 'Pencil',
      onSelect: onEdit,
    },
    ...additionalMenuItems,
  };

  return (
    <div className={cn('inline-block', containerClassName)}>
      {isAssetHidden ? (
        hiddenAssetPlaceholder
      ) : (
        <img className={imageClassName} src={imagePath} alt={altText} />
      )}
      <Menu
        triggerAction="click"
        placement={menuPlacement as MenuPlacement}
        trigger={
          <Button
            iconName="Pencil"
            variant="secondary-neutral"
            iconTitle={editButtonName}
            className={cn('h-3 w-3 [&>svg]:h-[12px] [&>svg]:w-[12px]', editButtonClassName)}
            size="small"
            backgroundStyle="filled"
            name={editButtonName}
          />
        }
        items={itemNames.filter(Boolean).map(itemName => itemMap[itemName as ItemNames])}
      />
      {!!(downloadButtonName && imageDownloadPath) && (
        <a href={imageDownloadPath} download>
          <Button
            iconName="Download"
            variant="secondary-neutral"
            iconTitle={downloadButtonName}
            className={cn('h-3 w-3 [&>svg]:h-[12px] [&>svg]:w-[12px]', downloadButtonClassName)}
            size="small"
            backgroundStyle="filled"
            name={downloadButtonName}
          />
        </a>
      )}
    </div>
  );
};

export default EditableAsset;
