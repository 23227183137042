import { SubmitButton, CancelButton } from '@components/shared/forms';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import css from './FormSubmitter.module.less';

type Props = {
  showCancelButton?: boolean;
  includePlaceholder?: boolean;
  onCancel?: () => void;
};

const FormSubmitter = ({
  showCancelButton = false,
  includePlaceholder = false,
  onCancel,
}: Props) => {
  const { dirty, isValid } = useFormikContext();
  const { t } = useTranslation();

  return dirty ? (
    <>
      {includePlaceholder && <div className="h-6" />}
      <div className={cn(css.submitFooter, 'z-10')}>
        {showCancelButton && <CancelButton onClick={onCancel} className="!mr-1" />}
        <SubmitButton disabled={!isValid}>{t('common:saveChanges')}</SubmitButton>
      </div>
    </>
  ) : null;
};

export default FormSubmitter;
