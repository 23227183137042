import * as React from 'react';
import s from '../forms.module.less';

type Props = {
  messages: string[] | string | undefined;
};

const ContextualError = ({ children }: { children: React.ReactNode }) => (
  <span className={s.contextualErrors}>{children}</span>
);

export default function ContextualErrors({ messages }: Props) {
  if (!messages) {
    return null;
  }

  return Array.isArray(messages) ? (
    <ul className={s.errorList}>
      {messages.map(msg => (
        <li key={`${msg}-${Math.random()}`}>
          <ContextualError>{msg}</ContextualError>
        </li>
      ))}
    </ul>
  ) : (
    <ContextualError>{messages}</ContextualError>
  );
}
