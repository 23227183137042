import { PowerpointTourbook, TourbookColors } from '@root/types';
import COLORS from '@root/shared/colorConstants';
import { colord } from 'colord';
import { h1, p, PptxUtils, PowerpointElement } from './globals';
import { PAGE_MARGIN } from './portrait';
import { addElementsToSlide } from './util';

const DEFAULT_BACKGROUND_COLORS: TourbookColors = {
  headerColor1: COLORS.white,
  headerColor2: COLORS.indigo120,
};

export const generateCoverElements = (
  utils: PptxUtils,
  tourbook: PowerpointTourbook,
  backgroundColors: TourbookColors = DEFAULT_BACKGROUND_COLORS,
): PowerpointElement[] => {
  const { cloudinary, vtsCloudinary, pptx, t } = utils;

  const elements: PowerpointElement[] = [];

  // prepare props
  const tagline = `PREPARED FOR ${tourbook.clientName || ''}`.toUpperCase();
  const clientImage = tourbook.clientLogoImage?.cloudinaryId;
  const showpreparedFor = tourbook.clientName?.length || clientImage?.length;
  const owner = tourbook?.owner || {};
  const tourbookAvatarImage = owner?.tourbookAvatarImage?.cloudinaryId;
  const tourbookCompanyImage = owner?.tourbookCompanyImage?.cloudinaryId;
  const fullName = owner?.fullName || '';
  const email = owner?.email || '';
  const phoneNumber = owner?.phoneExtension
    ? t('common:formattedPhoneWithExtension', {
        formattedPhone: owner?.phoneNumber,
        extension: owner?.phoneExtension,
      })
    : t('common:formattedPhone', {
        formattedPhone: owner?.phoneNumber,
      });

  const fontColors: TourbookColors = {
    headerColor1: colord(backgroundColors.headerColor1).isDark() ? COLORS.white : COLORS.black100,
    headerColor2: colord(backgroundColors.headerColor2).isDark() ? COLORS.white : COLORS.black100,
  };

  elements.push({
    type: 'textBox',
    data: tourbook.name,
    textOptions: { ...h1, color: fontColors.headerColor1 },
    placement: { x: PAGE_MARGIN, y: 0.8, w: 6.65, h: 0.9, margin: 0 },
  });

  elements.push({
    type: 'textBox',
    data: tourbook.description,
    textOptions: {
      ...p,
      fontSize: 12,
      lineSpacingMultiple: 1.5,
      color: fontColors.headerColor1,
    },
    placement: { x: PAGE_MARGIN, y: 1.74, w: 6.65, h: 1.25 },
  });

  // tagline
  if (showpreparedFor) {
    const taglineLength = Math.min(tagline.length * 0.1, 5.8);

    elements.push({
      type: 'textBox',
      data: tagline,
      textOptions: {
        ...p,
        bold: true,
        color: fontColors.headerColor1,
      },
      placement: { x: PAGE_MARGIN, y: 3.3, w: taglineLength, h: 0.25 },
    });

    // client image
    // TODO: dynamic position based on length of tagline above
    if (clientImage)
      elements.push({
        type: 'image',
        data: cloudinary.url(clientImage, {
          transformation: [
            {
              height: 300,
              width: 300,
              crop: 'pad',
              background: 'white',
              border: '10px_solid_white',
              radius: 'max',
              fetch_format: 'png',
            },
            { radius: 'max' },
          ],
        }),
        placement: { x: PAGE_MARGIN + taglineLength, y: 3, w: 0.8, h: 0.8 },
      });
  }

  const { headerImage } = tourbook;
  if (headerImage) {
    if (headerImage.cropperCanvasData) {
      elements.push({
        type: 'image',
        data: headerImage.largePath,
        placement: { x: 0, y: 4.26, w: 7.52, h: 5 },
      });
    } else {
      elements.push({
        type: 'image',
        data: cloudinary.url(headerImage.cloudinaryId, {
          transformation: [
            { width: 2000, crop: 'limit' },
            { aspectRatio: (7.52 / 5).toPrecision(3), crop: 'crop' },
            {
              crop: 'limit',
              width: 720,
            },
          ],
        }),
        placement: { x: 0, y: 4.26, w: 7.52, h: 5 },
      });
    }
  }

  const triangleHeight = 1.05;
  // this is a hack right now
  // pptxgenjs doesn't support free polygons right now (though, there IS a PR open for one)
  // so we take a rectangle and a right triangle that are contiguous, giving
  // the illusion of a trapezoid
  // TODO: switch to free polygon when pptxgenjs updates
  elements.push({
    type: 'shape',
    placement: { x: 0, y: 7.752, w: 7.52, h: triangleHeight },
    shapeOptions: {
      fill: { color: backgroundColors.headerColor2 },
      line: { type: 'none' },
    },
    shapeType: pptx.ShapeType.rtTriangle,
  });

  elements.push({
    type: 'shape',
    placement: {
      x: 0,
      // nudge the rectangle up into the triangle, without overlap, due to weird fractional scaling
      // there may or may not be a white line where the shapes meet
      // the tradeoff is the triangle appears to "end" a little too early
      y: 7.75 + triangleHeight - 0.01,
      w: 7.52,
      h: 2.28 - triangleHeight,
    },
    shapeOptions: {
      fill: { color: backgroundColors.headerColor2 },
      line: { type: 'none' },
    },
    shapeType: pptx.ShapeType.rect,
  });

  // tourbook avatar
  if (tourbookAvatarImage) {
    elements.push({
      type: 'image',
      data: vtsCloudinary.url(tourbookAvatarImage, {
        format: 'png',
        transformation: [
          {
            height: 300,
            width: 300,
            crop: 'thumb',
            gravity: 'face',
            background: 'transparent',
            radius: 'max',
            border: '4px_solid_white',
          },
        ],
      }),
      placement: { x: PAGE_MARGIN, y: 8.6, w: 0.83, h: 0.83 },
    });
  } else {
    const initials =
      fullName
        ?.split(' ')
        // FIXME: Fix the no-shadow or remove this line
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .map(t => t.slice(0, 1))
        .join('')
        .toUpperCase() || '';

    elements.push({
      type: 'shape',
      placement: { x: PAGE_MARGIN, y: 8.6, w: 0.83, h: 0.83 },
      shapeOptions: {
        fill: { color: backgroundColors.headerColor2 },
        line: { color: fontColors.headerColor2, type: 'solid' },
      },
      shapeType: pptx.ShapeType.ellipse,
    });

    elements.push({
      type: 'textBox',
      data: initials,
      textOptions: { align: 'center', color: fontColors.headerColor2 },
      placement: { x: PAGE_MARGIN, y: 8.6, w: 0.83, h: 0.83, valign: 'middle' },
    });
  }

  // company avatar
  if (tourbookCompanyImage) {
    const tourbookCompanyImagePath = vtsCloudinary.url(tourbookCompanyImage, {
      format: 'png',
      transformation: [
        { fetch_format: 'png' },
        {
          height: 300,
          width: 300,
          crop: 'pad',
          border: '10px_solid_white',
          radius: 'max',
          background: 'white',
        },
        {
          flag: 'relative',
          radius: 'max',
        },
      ],
    });

    elements.push({
      type: 'image',
      data: tourbookCompanyImagePath,
      placement: { x: 1.03, y: 9.04, w: 0.44, h: 0.44 },
    });
  }

  // contact info
  elements.push({
    type: 'textBox',
    data: [fullName, phoneNumber, email].filter(Boolean).join('\n'),
    textOptions: {
      ...p,
      color: fontColors.headerColor2,
      lineSpacingMultiple: 1.5,
    },
    placement: { x: 1.59, y: 8.61, w: 2.64, h: 0.85 },
  });

  return elements;
};

export const createCover = (utils: PptxUtils, tourbook: PowerpointTourbook) => {
  const { pptx } = utils;
  const slide = pptx.addSlide();

  const backgroundColors = tourbook?.colors || DEFAULT_BACKGROUND_COLORS;
  slide.background = { color: backgroundColors.headerColor1 };

  const elements: PowerpointElement[] = generateCoverElements(utils, tourbook, backgroundColors);

  addElementsToSlide(slide, elements);
};

export default createCover;
