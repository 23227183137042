import * as React from 'react';
import cn from 'classnames';
import s from './DataPoint.module.less';

type Props<T> = {
  value: T;
  format?: (value: T) => React.ReactNode;
  label: string;
  containerClassName?: string | null;
  valueClassName?: string | null;
  isAlwaysShown?: boolean;
  labelQaTestId?: string;
};

const DataPoint = <T,>({
  value,
  format = (x: T) => x,
  label,
  containerClassName = null,
  valueClassName = null,
  isAlwaysShown = false,
  labelQaTestId,
}: Props<T>) => {
  if (!value && !isAlwaysShown) return null;
  return (
    <dl className={cn(containerClassName, s.dataPoint)}>
      <dt className={s.dataPointLabel}>{label}</dt>
      <dd className={cn(s.dataPointValue, valueClassName)} data-qa-testid={labelQaTestId}>
        {format(value)}
      </dd>
    </dl>
  );
};

export default DataPoint;
