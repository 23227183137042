import { BuildingListing } from '@root/types';
import { EVENT_TYPES, PARAMETERS, EVENTS } from '@root/tracking/constants';
import { ListingTab } from '@components/layouts/Truva/Building/AvailableSpaces';

const buildingPageLoadedAction = {
  type: 'BUILDING_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.buildingPage,
      },
    },
  },
} as const;
type BuildingPageLoadedAction = typeof buildingPageLoadedAction;
const buildingPageLoaded = (): BuildingPageLoadedAction => buildingPageLoadedAction;

type BuildingToListingClickedAction = {
  type: 'BUILDING_TO_LISTING_CLICKED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourcePage: 'building page';
        sourceContent:
          | typeof PARAMETERS.onMarketListings
          | `${typeof PARAMETERS.onMarketListings} (${ListingTab})`;
        destination: 'listing page';
      };
    };
  };
};
const buildingToListingClicked = (
  listing: BuildingListing,
  tab?: ListingTab | '',
): BuildingToListingClickedAction => ({
  type: 'BUILDING_TO_LISTING_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.buildingPage,
        sourceContent: tab
          ? `${PARAMETERS.onMarketListings} (${tab})`
          : PARAMETERS.onMarketListings,
        destination: PARAMETERS.listingPage,
        ...listing.analyticsInformation,
      },
    },
  },
});

const lightboxPaginationClickedAction = {
  type: 'LIGHTBOX_PAGINATION_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.paginate,
        sourcePage: PARAMETERS.buildingPage,
        sourceContent: PARAMETERS.lightboxGallery,
      },
    },
  },
} as const;
type LightboxPaginationClickedAction = typeof lightboxPaginationClickedAction;
const lightboxPaginationClicked = () => lightboxPaginationClickedAction;

const lightboxThumbnailClickedAction = {
  type: 'LIGHTBOX_THUMBNAIL_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.clickThumbnail,
        sourcePage: PARAMETERS.buildingPage,
        sourceContent: PARAMETERS.lightboxGallery,
      },
    },
  },
} as const;
type LightboxThumbnailClickedAction = typeof lightboxThumbnailClickedAction;
const lightboxThumbnailClicked = () => lightboxThumbnailClickedAction;

const mediaAssetOpenedAction = {
  type: 'MEDIA_ASSET_OPENED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.select,
        sourcePage: PARAMETERS.buildingPage,
        sourceContent: PARAMETERS.buildingPageGallery,
      },
    },
  },
} as const;
type MediaAssetOpenedAction = typeof mediaAssetOpenedAction;
const mediaAssetOpened = () => mediaAssetOpenedAction;

const buildingContactClickedAction = {
  type: 'BUILDING_CONTACT_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.contactInteraction,
        action: PARAMETERS.selectEmail,
        sourcePage: PARAMETERS.buildingPage,
      },
    },
  },
} as const;
type BuildingContactClickedAction = typeof buildingContactClickedAction;
const buildingContactClicked = () => buildingContactClickedAction;

const buildingSpacesFloorPlanClickedAction = {
  type: 'BUILDING_FLOORPLAN_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        action: PARAMETERS.viewFloorPlan,
        sourcePage: PARAMETERS.buildingPage,
        sourceContent: PARAMETERS.onMarketListings,
      },
    },
  },
} as const;
type BuildingSpacesFloorPlanClickedAction = typeof buildingSpacesFloorPlanClickedAction;
const buildingSpacesfloorplanClicked = () => buildingSpacesFloorPlanClickedAction;

const listingTableDirectListingsTabClickedAction = {
  type: 'LISTING_TABLE_INTERACTION',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.listingTableInteraction,
        action: PARAMETERS.clickDirectListingsTab,
        sourcePage: PARAMETERS.buildingPage,
        sourceContent: PARAMETERS.onMarketListings,
      },
    },
  },
} as const;
type ListingTableDirectListingsTabClickedAction = typeof listingTableDirectListingsTabClickedAction;
const listingTableDirectListingsTabClicked = () => listingTableDirectListingsTabClickedAction;

const listingTableAllListingsTabClickedAction = {
  type: 'LISTING_TABLE_INTERACTION',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.listingTableInteraction,
        action: PARAMETERS.clickAllListingsTab,
        sourcePage: PARAMETERS.buildingPage,
        sourceContent: PARAMETERS.onMarketListings,
      },
    },
  },
} as const;
type ListingTableAllListingsTabClickedAction = typeof listingTableAllListingsTabClickedAction;
const listingTableAllListingsTabClicked = () => listingTableAllListingsTabClickedAction;

export default {
  buildingPageLoaded,
  buildingToListingClicked,
  lightboxPaginationClicked,
  lightboxThumbnailClicked,
  mediaAssetOpened,
  buildingContactClicked,
  buildingSpacesfloorplanClicked,
  listingTableDirectListingsTabClicked,
  listingTableAllListingsTabClicked,
};

export type Actions =
  | BuildingPageLoadedAction
  | BuildingToListingClickedAction
  | LightboxPaginationClickedAction
  | LightboxThumbnailClickedAction
  | MediaAssetOpenedAction
  | BuildingContactClickedAction
  | BuildingSpacesFloorPlanClickedAction
  | ListingTableDirectListingsTabClickedAction
  | ListingTableAllListingsTabClickedAction;
