import { NavLink } from 'react-router-dom';
import { RawToggle } from '@components/shared/forms';
import s from './Navigation.module.less';

type OptionType = {
  label: string;
  route: string;
  outsideLink?: boolean;
  key: string;
};
type ToggleOptionType = {
  key: string;
  isToggle: true;
  onToggle: () => void;
  label: string;
  value: boolean;
};

type Props = {
  options: Array<OptionType | ToggleOptionType>;
};

function DropdownMenuItem({ label, route, outsideLink }: OptionType & { key: string }) {
  const LinkComponent = outsideLink ? 'a' : NavLink;
  const linkProps = outsideLink ? { href: route } : { to: route };
  return (
    <li>
      {/* @ts-expect-error */}
      <LinkComponent {...linkProps}>{label}</LinkComponent>
    </li>
  );
}

export default function DropdownMenu({ options }: Props) {
  return (
    <ul className={s.dropdownMenu} data-testid="savedDropdownMenu">
      {options.map(option =>
        'isToggle' in option ? (
          <div className={s.toggleOption} key={option.key}>
            <RawToggle name={option.key} onChange={option.onToggle} checked={option.value} />
            <span role="button" onClick={option.onToggle}>
              {option.label}
            </span>
          </div>
        ) : (
          <DropdownMenuItem {...option} key={option.key} />
        ),
      )}
    </ul>
  );
}
