import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useEnv from '@shared/useEnv';
import COLORS from '@shared/colorConstants';
import { loadMap, createMap, getMapOptions, createMarker, getSymbolPath } from '@shared/googleMaps';
import api from '@shared/api';
import routes from '@root/routes';
import Button from '@components/shared/V2Button';
import useToggle from '@shared/useToggle';
import Geocoder from '@shared/geocoder';
import { useFormikContext } from 'formik';
import { FormValues } from '.';
import s from './TourbookExternalListing.module.less';

const ZOOM_LEVEL = 17;
const US_STARTING_VIEW = { long: -95.7129, lat: 37.0902, zoom: 3 };
const GB_STARTING_VIEW = { long: -3.436, lat: 55.3781, zoom: 4 };

type Props = {
  onLocationError: () => void;
  listingPersisted: boolean;
};

const ExternalListingMap = ({ listingPersisted, onLocationError = () => {} }: Props) => {
  const { t } = useTranslation('tourbook');
  const { googleMapsKey: key } = useEnv();
  const mapRef = useRef<HTMLDivElement>(null);
  const mapObj = useRef() as { current: google.maps.Map | null };
  const geocoder = useRef() as { current: google.maps.Geocoder | Geocoder | null };
  const marker = useRef() as { current: google.maps.Marker | null };

  const {
    value: isMapButtonShowing,
    setTrue: showMapButton,
    setFalse: hideMapButton,
  } = useToggle(true);

  const {
    dirty: isFormDirty,
    values: { addressLine1, city, state, zipCode, submarket, countryCode },
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<FormValues>();

  const setLocation = (location, skipMarketRequest = false) => {
    const latitude = location.lat;
    const longitude = location.lng;
    setFieldValue('latitude', latitude);
    setFieldValue('longitude', longitude);
    setFieldTouched('location');

    if (!submarket && !skipMarketRequest) {
      api.fetch(routes.api.submarkets({ longitude, latitude })).then(async response => {
        if (response.ok) {
          const { name } = await response.json();
          setFieldValue('submarket', name);
        }
      });
    }
  };

  const isStateValid = countryCode !== 'US' || state;
  const isFormValid = addressLine1 && city && zipCode && isStateValid;

  const geocodeOptions =
    countryCode === 'US'
      ? { address: `${addressLine1}, ${city}, ${state} ${zipCode}` }
      : { address: `${addressLine1}, ${city}, ${zipCode}` };

  useEffect(() => {
    showMapButton();
    setFieldValue('latitude', null);
    setFieldValue('longitude', null);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressLine1, city, state, zipCode]);

  useEffect(() => {
    loadMap(key!, () => {
      if (mapObj.current) {
        // updateMarkers();
      } else {
        if (!mapRef.current) return;
        // create map
        const startingPoint = countryCode === 'GB' ? GB_STARTING_VIEW : US_STARTING_VIEW;

        mapObj.current = createMap(
          mapRef.current,
          getMapOptions({
            clickableIcons: false,
            draggableCursor: 'default',
            draggingCursor: 'default',
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            scaleControl: false,
            zoomControl: false,
            ...startingPoint,
            gestureHandling: 'none',
          }),
        );

        geocoder.current = new Geocoder(key!);

        if (isFormValid) {
          geocoder.current?.geocode(geocodeOptions, (results, status) =>
            onGeocodeComplete(results, status, { skipMarketRequest: listingPersisted }),
          );
        }
      }
    });
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef.current, countryCode]);

  const onGeocodeComplete = (
    results,
    status,
    { skipMarketRequest }: { skipMarketRequest: boolean } = { skipMarketRequest: false },
  ) => {
    if (status === 'OK' && results.length > 0 && !results[0].partial_match) {
      const { location } = results[0].geometry;
      const { lng, lat } = location;

      mapObj.current?.setCenter(location);

      marker.current?.setMap(null);
      marker.current = createMarker(
        mapObj.current,
        {
          lng,
          lat,
          label: '',
          icon: {
            path: getSymbolPath().CIRCLE,
            scale: 11,
            fillColor: COLORS.indigo110,
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: '#FFFFFF',
          },
        },
        false,
        false,
      );

      mapObj.current?.setZoom(ZOOM_LEVEL);
      hideMapButton();
      setLocation(location, skipMarketRequest);
    } else {
      setFieldError(
        'location',
        t('externalListing.noResultMapError').replace('[ADDRESS]', addressLine1),
      );
      setFieldTouched('location', true, false);
      onLocationError();
    }
  };

  const onButtonClick = () => {
    geocoder.current?.geocode(geocodeOptions, onGeocodeComplete);
  };

  return (
    <div className={s.mapContainer}>
      {(!isFormValid || !isFormDirty) && <div className={s.mapOverlay}></div>}
      {isMapButtonShowing && (
        <div className={s.mapButton}>
          <Button
            disabled={!isFormValid || !isFormDirty}
            size="small"
            onClick={onButtonClick}
            type="tertiary"
          >
            {t('externalListing.mapButtonLabel')}
          </Button>
        </div>
      )}
      <div className={s.map} data-testid="external-listing-map" ref={mapRef}></div>
    </div>
  );
};

export default ExternalListingMap;
