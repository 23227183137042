import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { AdminBrochure, SpaceFloorPlanPhoto, SpaceAdditionalPhoto } from '@root/types';
import Emdash from '@components/shared/Emdash';
import Typography from '@styles/typography.module.less';
import File from './File';
import FileWithDescription from './FileWithDescription';
import s from './ExistingContent.module.less';

type Props = {
  floorPlanPhotos: SpaceFloorPlanPhoto[];
  additionalPhotos: SpaceAdditionalPhoto[];
  spaceBrochure: AdminBrochure | null;
  buildingBrochure: AdminBrochure | null;
};

const ExistingContent = ({
  floorPlanPhotos,
  additionalPhotos,
  spaceBrochure,
  buildingBrochure,
}: Props) => {
  const { t } = useTranslation('admin');

  return (
    <>
      <div className="font-title">{t('media.existingContent.title')}</div>
      <div className={s.section}>
        <div className={classNames(Typography.bodyLargeEmphasis, s.sectionTitle)}>
          {t('media.existingContent.attachedFloorplansTitle')}
        </div>
        <div>
          {floorPlanPhotos.length > 0 ? (
            floorPlanPhotos.map(floorPlanPhoto => {
              return (
                <File
                  key={floorPlanPhoto.url}
                  className={s.file}
                  filename={floorPlanPhoto.filename}
                  url={floorPlanPhoto.url}
                  uploadedAt={floorPlanPhoto.uploadedAt}
                />
              );
            })
          ) : (
            <Emdash />
          )}
        </div>
      </div>
      <div className={s.section}>
        <div className={classNames(Typography.bodyLargeEmphasis, s.sectionTitle)}>
          {t('media.existingContent.attachedPhotosAndRenderingsTitle')}
        </div>
        <div>
          {additionalPhotos.length > 0 ? (
            additionalPhotos.map(additionalPhoto => {
              return (
                <FileWithDescription
                  key={additionalPhoto.url}
                  className={s.file}
                  filename={additionalPhoto.filename}
                  url={additionalPhoto.url}
                  uploadedAt={additionalPhoto.uploadedAt}
                  description={additionalPhoto.description}
                />
              );
            })
          ) : (
            <Emdash />
          )}
        </div>
      </div>
      <div className={s.section}>
        <div className={classNames(Typography.bodyLargeEmphasis, s.sectionTitle)}>
          {t('media.existingContent.attachedBuildingBrochureTitle')}
        </div>
        <div>
          {buildingBrochure ? (
            <File
              filename={buildingBrochure.name}
              url={buildingBrochure.url}
              uploadedAt={buildingBrochure.uploadedAt}
            />
          ) : (
            <Emdash />
          )}
        </div>
      </div>
      <div className={s.section}>
        <div className={classNames(Typography.bodyLargeEmphasis, s.sectionTitle)}>
          {t('media.existingContent.attachedSpaceBrochureTitle')}
        </div>
        <div>
          {spaceBrochure ? (
            <File
              filename={spaceBrochure.name}
              url={spaceBrochure.url}
              uploadedAt={spaceBrochure.uploadedAt}
            />
          ) : (
            <Emdash />
          )}
        </div>
      </div>
    </>
  );
};

export default ExistingContent;
