import { isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Address } from '@root/types';
import useEnv from '@shared/useEnv';
import EmbeddedMap from '@components/shared/EmbeddedMap';

type Props = {
  address: Address | null;
  latitude?: number | null;
  longitude?: number | null;
  hideLocation?: boolean;
};

const Location = ({
  address = null,
  hideLocation = false,
  latitude = null,
  longitude = null,
}: Props) => {
  const { googleMapsKey: key } = useEnv();
  const { t } = useTranslation('building');

  if (isNull(key) || !address || !address?.street) return null;

  return (
    <div>
      <h3 className="font-headline" data-qa-testid="location-heading">
        {t('location')}
      </h3>
      <EmbeddedMap
        address={{ ...address, formattedAddress: address.formattedAddress.replace('&', 'and') }}
        hideLocation={hideLocation}
        latitude={latitude}
        longitude={longitude}
      />
    </div>
  );
};

export default Location;
