import cn from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from '..';
import s from './ConfirmationModal.module.less';

export type Props = {
  modalContainerClassName?: string;
  bodyClassName?: string;
  onConfirm: () => void;
  onClose: () => void;
  confirmationText?: string;
  confirmationElement?: ReactElement;
  title?: string;
  isOpen: boolean;
  isLoading?: boolean;
  cancelButtonText?: string;
  confirmButtonText?: string;
  hideCancelButton?: boolean;
  closable?: boolean;
  'data-testid'?: string;
  'primary-button-testid'?: string;
};

const ConfirmationModal = ({
  modalContainerClassName,
  bodyClassName,
  onConfirm,
  onClose,
  confirmationText,
  confirmationElement,
  title,
  isOpen,
  isLoading,
  cancelButtonText,
  confirmButtonText,
  hideCancelButton,
  closable = true,
  'data-testid': dataTestId = 'confirmationModal',
  'primary-button-testid': primaryButtonTestId = 'deleteConfirm',
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={cn(s.modalContainer, modalContainerClassName)}
      title={title}
      closeModal={onClose}
      isOpen={isOpen}
      closable={closable}
      data-testid={dataTestId}
    >
      <div className={cn(s.confirmationBody, bodyClassName)}>
        {confirmationElement || confirmationText}
      </div>
      <div className={s.buttons}>
        {hideCancelButton ? null : (
          <Button type="secondary" size="large" onClick={onClose} disabled={isLoading}>
            {cancelButtonText || t('common:no')}
          </Button>
        )}
        <Button
          type="primary"
          size="large"
          onClick={onConfirm}
          loading={isLoading}
          data-testid={primaryButtonTestId}
        >
          {confirmButtonText || t('common:yes')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
