import { useEffect, useState, memo, useRef } from 'react';
import cn from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { range } from 'lodash';
import { SearchListing, StoreState } from '@root/types';
import { getLoadingState } from '@store/selectors';
import useAnalytics from '@shared/useAnalytics';
import { useTranslation } from 'react-i18next';
import usePrevious from '@root/shared/usePrevious';
import { PARAMETERS } from '@root/tracking/constants';
import { ScrollableInvisibleMask } from '@components/shared/InvisibleMask';
import useCurrentUser from '@shared/useCurrentUser';
import useFavoritedListingsQuery from '@root/shared/useFavoritedListingsQuery';
import s from './Cards.module.less';
import ListingCardSkeleton from './ListingCardSkeleton';
import EmptyState from './empty-state.svg';
import useListingSearchCriteria from '../utils/useListingSearchCriteria';
import useListingPreviewModalForListingSearch from '../ListingPreviewModal/useListingPreviewModalForListingSearch';
import { ListingCard } from './ListingCard';

export type PassedProps = {
  searchCardHovered?: (listing: SearchListing | null) => void;
};

const mapState = (state: StoreState) => ({
  ids: state.listings.pagination?.ids,
  listingsById: state.listings.searchResults,
  isLoading: getLoadingState(state, 'listingSearch'),
});

const connector = connect(mapState);
export type ReduxProps = ConnectedProps<typeof connector>;

export const RawCards = ({
  ids = [],
  listingsById,
  searchCardHovered = () => {},
  isLoading,
}: PassedProps & ReduxProps) => {
  const { currentUser } = useCurrentUser();
  const [showScrollableInvisibleMask, setShowScrollableInvisibleMask] = useState(false);
  const { clickToPage } = useAnalytics();
  const cardContainerContentRef = useRef<HTMLUListElement>(null);

  const criteria = useListingSearchCriteria();
  const hasSearchResults = !!ids.length;

  const { isListingSaved } = useFavoritedListingsQuery();

  const makeOnClick = (listing: SearchListing) => () => {
    clickToPage(
      {
        destination: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.listingCard,
        sourcePage: PARAMETERS.searchResultsPage,
        actionType: 'CLICKED_ON_LISTING_CARD',
      },
      {
        isExclusiveListing: listing.isCurrentlyExclusive,
        isFlexibleSizeSearch: !!listing.minMaxArea,
        currentFilters: criteria.toAnalyticsProperties(),
      },
    );
  };

  useEffect(() => {
    // Don't allow scrolling on body
    if (showScrollableInvisibleMask) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showScrollableInvisibleMask]);

  const { t } = useTranslation('redesignedListingSearch');

  // We only want to display skeleton cards on first load.
  // The below logic stops displaying the skeleton cards the
  // first time isLoading changes from true to false
  const previousIsLoading = usePrevious(isLoading);
  const [shouldDisplaySkeleton, setShouldDisplaySkeleton] = useState(true);

  useEffect(() => {
    setShouldDisplaySkeleton(shouldDisplaySkeleton && !previousIsLoading);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const numberOfSkeletonCards = isLoading ? 24 : ids.length;
  const skeletonCards = (
    <ul
      data-testid="skeletonCardList"
      className={cn(s.skeletonCardList, !shouldDisplaySkeleton && s.invisible)}
    >
      {range(numberOfSkeletonCards).map(i => (
        <li className={s.listingCardWrapper} key={i}>
          <ListingCardSkeleton />
        </li>
      ))}
    </ul>
  );

  const { ListingPreviewModal, listingPreviewModalProps, openListingPreviewModal } =
    useListingPreviewModalForListingSearch({
      currentPage: criteria.page(),
      toApiListingsUrl: customFilters => criteria.toApiListingsUrl(customFilters),
      changePage: (newPage: number) => {
        criteria.add('page', newPage);
        criteria.pushToHistory();
      },
      isSaved: isListingSaved,
    });

  return (
    <section className={s.container}>
      {hasSearchResults ? (
        <ul className={s.cardList} id="listing-cards" ref={cardContainerContentRef}>
          {ids.map((listingId, i) => {
            const listing = listingsById[listingId];
            if (!listing) return null;

            return (
              <li className={s.listingCardWrapper} key={listingId}>
                <ListingCard
                  listing={listing}
                  onHover={() => searchCardHovered(listing)}
                  onHoverExit={() => searchCardHovered(null)}
                  openListingPreviewView={() => openListingPreviewModal(i)}
                  loggedInUser={currentUser}
                  onClick={makeOnClick(listing)}
                  onToggleFloorplanPreview={isOpen => setShowScrollableInvisibleMask(isOpen)}
                  onToggleTourbookDialog={isOpen => setShowScrollableInvisibleMask(isOpen)}
                  isSaved={isListingSaved(listingId)}
                />
              </li>
            );
          })}
        </ul>
      ) : (
        !shouldDisplaySkeleton && (
          <div data-testid="noResultsView" className={s.emptySearch}>
            <EmptyState />
            <h4>{t('noResultsFound')}</h4>
            <p>{t('differentSearchCriteria')}</p>
          </div>
        )
      )}
      {skeletonCards}

      {
        // Allows the user to scroll through cards while the tourbook dialog is open
        showScrollableInvisibleMask && (
          <ScrollableInvisibleMask
            zIndex={5}
            height={cardContainerContentRef?.current?.offsetParent?.scrollHeight || 0}
          />
        )
      }

      <ListingPreviewModal {...listingPreviewModalProps} />
    </section>
  );
};

export default connector(memo(RawCards));
