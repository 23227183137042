import { createRef, useRef, useState } from 'react';
import { Carousel } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import CustomIcon from '@components/shared/CustomIcon';
import EmptyImagePlaceholder from '@components/shared/images/empty-image-placeholder.svg';
import type { MultipathImage, Listing, Video } from '@root/types';
import { IconButton, VideoPlayer } from '@components/shared';
import useAnalytics, { ListingPreviewSourcePageType } from '@root/shared/useAnalytics';
import { EVENTS, PARAMETERS } from '@root/tracking/constants';
import useAltText from '@root/shared/useAltText';
import cn from 'classnames';
import s from './Gallery.module.less';

type GalleryProps = {
  photos: Array<MultipathImage>;
  video?: Video | null;
  listing?: Listing | null;
  sourcePage: ListingPreviewSourcePageType;
};

const Gallery = ({ photos, video, listing, sourcePage }: GalleryProps) => {
  const ref = createRef<HTMLDivElement>();
  const carousel = useRef<Carousel>(null);
  const playerRef = useRef<any>();
  const previewVideoEnabled = useFlags()['demand.listing-preview-video'];

  const floorPlanIndex = photos.findIndex(photo => photo.type === 'floorPlan');
  const [shouldRenderAllPhotos, setShouldRenderAllPhotos] = useState(false);
  const { listingPreviewAnalytics, galleryInteraction } = useAnalytics();
  const { getAltText, getAltTextForVideo } = useAltText({ address: listing?.address });

  if (!photos.length && !video) {
    return (
      <div className={s.carouselContainer}>
        <div className={s.imageContainer}>
          <div className={s.image}>
            <EmptyImagePlaceholder
              preserveAspectRatio="none"
              width="100%"
              height="100%"
              data-testid="emptyImagePlaceholder"
            />
          </div>
        </div>
      </div>
    );
  }

  // We don't want to fetch all the photos unnecessarily. Initially only
  // fetch the first, second, and last photos, along with the floorplan.
  const shouldRenderPhoto = (photoIndex: number): boolean =>
    shouldRenderAllPhotos ? true : [0, 1, photos.length - 1, floorPlanIndex].includes(photoIndex);

  const handleGoToPrev = () => {
    carousel?.current?.prev();
    if (listing)
      listingPreviewAnalytics({
        listingPreviewactionType: 'PAGINATE_GALLERY',
        listingPreviewAction: PARAMETERS.paginate,
        listingPreviewEvent: EVENTS.galleryInteraction,
        listing,
        sourcePage,
      });
  };
  const handleGoToNext = () => {
    carousel?.current?.next();
    if (listing)
      listingPreviewAnalytics({
        listingPreviewactionType: 'PAGINATE_GALLERY',
        listingPreviewAction: PARAMETERS.paginate,
        listingPreviewEvent: EVENTS.galleryInteraction,
        listing,
        sourcePage,
      });
  };

  return (
    <div ref={ref}>
      <div className={s.carouselContainer} role="button" tabIndex={0} data-testid="gallery">
        {photos.length > 1 ? (
          <>
            <button
              className="absolute left-2 top-1/2 z-[2] h-4 w-4 rounded-full bg-background-primary/50 hover:bg-background-primary"
              type="button"
              onClick={handleGoToPrev}
              data-testid="previousPhotoBtn"
            >
              <CustomIcon className="pr-[2px]" type="chevron-left" />
            </button>
            <button
              className="absolute right-2 top-1/2 z-[2] h-4 w-4 rounded-full bg-background-primary/50 hover:bg-background-primary"
              type="button"
              onClick={handleGoToNext}
              data-testid="nextPhotoBtn"
            >
              <CustomIcon className="pl-[1px]" type="chevron-right" />
            </button>

            {floorPlanIndex !== -1 && (
              <IconButton
                className={cn(s.floorplanButton, '!border !border-solid !border-border-regular')}
                icon="floor-plan"
                onClick={() => {
                  carousel?.current?.goTo(floorPlanIndex);
                  if (listing)
                    listingPreviewAnalytics({
                      listingPreviewactionType: 'FLOOR_PLAN_CLICKED',
                      listingPreviewAction: PARAMETERS.viewFloorPlan,
                      listingPreviewEvent: EVENTS.galleryInteraction,
                      listing,
                      sourcePage,
                    });
                }}
                data-testid="floorPlanBtn"
              />
            )}
            {previewVideoEnabled && video ? (
              <button
                type="button"
                className="absolute bottom-2 left-7 z-[2] h-4 w-4 !border border-solid !border-border-regular"
                onClick={() => {
                  carousel?.current?.goTo(photos.length);
                  if (listing)
                    galleryInteraction({
                      actionType: 'GALLERY_THUMBNAIL_CLICKED',
                      action: PARAMETERS.viewVideo,
                      sourceContent: PARAMETERS.listingPreview,
                      sourcePage,
                      analyticsInformation: listing.analyticsInformation,
                    });
                }}
                data-testid="videoBtn"
              >
                <img
                  className="h-full rounded-sm object-cover"
                  src={video.thumbnailPosterPath}
                  alt={getAltTextForVideo(sourcePage)}
                />
              </button>
            ) : null}
          </>
        ) : null}

        <Carousel
          dots={false}
          ref={carousel}
          initialSlide={0}
          effect="fade"
          className={s.carousel}
          beforeChange={currentSlide => {
            if (currentSlide === photos.length && playerRef.current) playerRef.current.pause();
          }}
          afterChange={() => setShouldRenderAllPhotos(true)}
        >
          {photos.map((photo, photoIndex) => (
            <div key={photo.path}>
              <div className={s.imageContainer}>
                {shouldRenderPhoto(photoIndex) && (
                  <img
                    className={s.image}
                    alt={getAltText({ type: photo.type, options: { roomName: photo.description } })}
                    src={photo.mediumPath}
                  />
                )}
              </div>
            </div>
          ))}
          {!!video && previewVideoEnabled && (
            <div key={video.cloudinaryId} data-testid="videoContainer">
              <VideoPlayer
                video={video}
                className={s.videoPlayer}
                sourcePage={sourcePage}
                sourceContent={PARAMETERS.listingPreview}
                analyticsInformation={listing?.analyticsInformation}
                fluid={false}
                fill
                playerRef={playerRef}
              />
            </div>
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default Gallery;
