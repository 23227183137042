export type SavedStatuses = { listingId: string; saved: boolean };
type SetSavedStatuses = {
  type: 'RECEIVE_SAVED_STATUSES';
  payload: { savedStatuses: SavedStatuses[] };
};
const setSavedStatuses = (savedStatuses: SavedStatuses[]) => dispatch =>
  dispatch({
    type: 'RECEIVE_SAVED_STATUSES',
    payload: { savedStatuses },
  });

export default setSavedStatuses;
export type Actions = SetSavedStatuses;
