import { RadioGroup, Checkbox as TruvaCheckbox } from '@components/shared/forms';
import { Checkbox } from '@viewthespace/components';
import { AdminMicrosite } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import TextColorSelector from '../../TextColorSelector';
import TextSizeDropdown from '../../TextSizeDropdown';
import ColorPicker from '../../ColorPicker';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import { useMicrositeFormContext } from '../../utils';

export default function BuildingInfo({ id, index, anchorSlug }: ContentBlockProps) {
  const { setFieldTouched, values, setFieldValue } = useFormikContext<Partial<AdminMicrosite>>();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { colorGroups, microsite, resetKey, sendPreviewTo, changeFontColorWithColorChange } =
    useMicrositeFormContext();

  const isBuildingInfoSectionHidden = !values.homeBuildingInfoSectionIsVisible;

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      title={
        flags['market-office.flexible-layouts-on-microsite']
          ? t('microsite.sections.buildingOverview')
          : t('microsite.sections.homeBuildingOverview')
      }
      section="homeBuildingOverview"
      onClick={() => sendPreviewTo({ page: 'home', element: anchorSlug })}
      index={index}
      isHidden={isBuildingInfoSectionHidden}
    >
      <>
        {flags['market-office.hide-amenities-and-certifications'] ? (
          <Checkbox
            label={t('microsite.hideSection')}
            className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
            backgroundStyle="filled"
            isChecked={isBuildingInfoSectionHidden}
            onChange={e => {
              setFieldValue('homeBuildingInfoSectionIsVisible', !e.value);
              setFieldTouched('homeBuildingInfoSectionIsVisible', true);
            }}
          />
        ) : (
          <TruvaCheckbox
            name="homeBuildingInfoSectionIsVisible"
            labelClassName="!mb-0"
            className="!pt-[2px]"
            onChange={() => setFieldTouched('homeBuildingInfoSectionIsVisible', true)}
          >
            {t('microsite.homeBuildingInfoSection.visible.title')}
          </TruvaCheckbox>
        )}
        <ColorPicker
          colorGroups={colorGroups}
          name="homeBuildingInfoBackgroundColor"
          labelText={t('microsite.homeBuildingInfoBackgroundColor.title')}
          onChange={changeFontColorWithColorChange('homeBuildingInfoTextColor')}
        />
        {flags['market-office.text-color-selector'] && (
          <TextColorSelector name="homeBuildingInfoTextColor" />
        )}
        <hr className="text-general-neutral-secondary" />
        <div className="font-body-large-emphasis">
          {t('microsite.homeBuildingInfoSection.sectionTitleAndDescription')}
        </div>
        <RadioGroup
          containerClass="!mb-0"
          optionsInRow
          options={[
            {
              value: 'left',
              label: t('microsite.homeBuildingInfoDescriptionPlacement.left'),
            },
            {
              value: 'right',
              label: t('microsite.homeBuildingInfoDescriptionPlacement.right'),
            },
          ]}
          name="homeBuildingInfoDescriptionPlacement"
          labelText={t('microsite.homeBuildingInfoDescriptionPlacement.title')}
          fast={!!flags['market-office.microsite-performance-optimizations']}
        />
        <RichTextEditorInput
          name="homeBuildingInfoSectionTitle"
          labelText={t('microsite.sectionTitle')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          excludeMenuItems={
            flags['market.microsite-font-sizing-on-editors']
              ? ['bulletList']
              : ['bulletList', 'textSize']
          }
          initialContent={microsite.homeBuildingInfoSectionTitle}
          defaultTextSize={
            flags['market.microsite-font-sizing-on-editors'] ? 'header32' : undefined
          }
          resetKey={resetKey}
          containerClass="!mb-0"
          fast={!!flags['market-office.microsite-performance-optimizations']}
          shouldDebounce
        />
        <TextSizeDropdown
          name="homeBuildingInfoDescriptionTextSizeClassname"
          labelText={t('microsite.homeBuildingInfoDescriptionTextSizeClassname.title')}
          containerClass="!mb-0"
        />
        <hr className="text-general-neutral-secondary" />
        <div className="font-body-large-emphasis">{t('microsite.homeBuildingInfoFacts.title')}</div>
        <RadioGroup
          containerClass="!mb-0"
          optionsInRow
          options={[
            {
              value: 'small',
              label: t('microsite.homeBuildingInfoFacts.small'),
            },
            { value: 'large', label: t('microsite.homeBuildingInfoFacts.large') },
          ]}
          name="homeBuildingInfoFactsSize"
          labelText={t('microsite.homeBuildingInfoFacts.size')}
          fast={!!flags['market-office.microsite-performance-optimizations']}
        />
        <RadioGroup
          containerClass="!mb-0"
          optionsInRow
          options={[
            { value: '1', label: t('microsite.homeBuildingInfoFacts.columns.one') },
            {
              value: '2',
              label: t('microsite.homeBuildingInfoFacts.columns.two'),
            },
          ]}
          name="homeBuildingInfoFactsColumnCount"
          labelText={t('microsite.homeBuildingInfoFacts.columns.title')}
          tooltipContent={t('microsite.homeBuildingInfoFacts.columns.helpText')}
          fast={!!flags['market-office.microsite-performance-optimizations']}
        />
      </>
    </ContentBlockWrapper>
  );
}
