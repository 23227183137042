import api from '@shared/api';
import routes from '@root/routes';
import { EVENTS, EVENT_TYPES } from '@root/tracking/constants';
import { CurrentUser, DeprecatedErrorResponse } from '@root/types';

type Args = {
  user: {
    id: string;
    marketSlug?: string;
    role?: string;
    userContentAgreement?: boolean;
    languagePreference?: string;
  };
  onSuccess: (u: CurrentUser) => void | Promise<void>;
  onError?: (errorResponse: DeprecatedErrorResponse) => void | Promise<void>;
  source?: string;
  action?: string;
};

type ReceiveUpdatedCurrentUserAction = {
  type: 'RECEIVE_UPDATED_CURRENT_USER';
  payload: {
    user: CurrentUser;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'User Account Interaction';
        action: string;
        sourceContent: string;
        userMarket?: string;
        userAccountType?: string;
      };
    };
  };
};
const receiveUpdatedCurrentUser = (
  user: CurrentUser,
  sourceContent: string,
  action: string,
): ReceiveUpdatedCurrentUserAction => ({
  type: 'RECEIVE_UPDATED_CURRENT_USER',
  payload: {
    user,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.userAccountInteraction,
        action,
        sourceContent,
        ...(action === 'update professional info'
          ? {
              userMarket: user.analyticsInformation.userMarket,
              userAccountType: user.analyticsInformation.userAccountType,
            }
          : {}),
      },
    },
  },
});

const updateCurrentUser =
  ({ user, onSuccess, source, action, onError = () => {} }: Args) =>
  async dispatch =>
    api
      .put(routes.api.currentUser, user)
      .then(async response => {
        const parsed = await response.json();
        return { ok: response.ok, parsed };
      })
      .then(
        async (
          response:
            | { ok: true; parsed: CurrentUser }
            | { ok: false; parsed: DeprecatedErrorResponse },
        ) => {
          if (!response.ok) {
            await onError(response.parsed);
            return;
          }
          const shouldDispatchAnalyticsEvent = source && action;
          if (shouldDispatchAnalyticsEvent) {
            await dispatch(receiveUpdatedCurrentUser(response.parsed, source, action));
          }
          await onSuccess(response.parsed);
        },
      );

export default updateCurrentUser;
export type Actions = ReceiveUpdatedCurrentUserAction;
