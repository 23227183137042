import { Button } from '@components/shared';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import TourbookShareButton from '@components/shared/TourbookShareButton/TourbookShareButton';
import routes from '@root/routes';
import { Link } from 'react-router-dom';
import { isInIframe } from '@root/shared/iframeUtils';
import s from './ModalForOldTourbooks.module.less';
import AnalyticsModalIllustration from './analytics-illustration.png';

const TourbookShareAnalyticsCard = ({ tourbookData }) => {
  const { t } = useTranslation('tourbook');

  return (
    <div
      data-testid="shareAnalyticsCard"
      className={cn(
        'flex items-center justify-center lg:h-[723px] desktop:mt-[-16px] tablet:pb-2 mobile:pb-2',
        isInIframe() && '!h-screen',
      )}
    >
      <div
        className={cn(
          'relative w-full max-w-[1186px] rounded-md bg-background-primary p-1.5 px-8 shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)] lg:h-[550px] tablet:h-[858px] mobile:px-2 mobile:py-3',
          isInIframe() && 'mt-8',
        )}
      >
        <div className={cn(s.sharePromptFancyDiv, 'absolute inset-0')} />
        <div className="relative flex h-full w-full flex-col justify-evenly lg:flex-row lg:items-center lg:gap-4 desktop:justify-between mobile:gap-1">
          <div className="shrink-0 font-body-large-emphasis">
            <h3 className="pb-2 font-headline mobile:font-title">
              {t('analytics.sharePrompt.title')}
            </h3>
            <div className="max-w-[640px] lg:max-w-[475px]">
              <p className="mb-1">{t('analytics.sharePrompt.subject')}</p>
              <ul className="!list-disc pl-[19px] pt-1 leading-9 font-body-large marker:text-[22px] marker:text-indigo-100">
                <li className="pb-2">{t('analytics.modal.body1')}</li>
                <li className="pb-2">{t('analytics.modal.body2')}</li>
                <li className="pb-2">{t('analytics.modal.body3')}</li>
              </ul>
            </div>
            <div className="flex w-fit gap-2 md:mt-1 mobile:flex-col mobile:pb-1">
              <Link
                aria-label={t('analytics.backToTourbook')}
                to={routes.tourbook(tourbookData.id)}
              >
                <Button
                  type="secondary"
                  size="medium"
                  onClick={() => routes.tourbook(tourbookData.id)}
                >
                  {t('analytics.backToTourbook')}
                </Button>
              </Link>
              <TourbookShareButton
                tourbookId={tourbookData.id}
                shareUrl={tourbookData.tourbookMetadata.shareUrl}
                analyticsSupport={tourbookData.tourbookMetadata.analyticsSupport}
                firstSharedAt={tourbookData.tourbookMetadata.firstSharedAt}
              />
            </div>
          </div>
          <img
            src={AnalyticsModalIllustration}
            className="z-[1] ml-[-21px] w-[400px] min-w-0 shrink mobile:ml-[-9px] mobile:w-[165px]"
            alt="analytics illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default TourbookShareAnalyticsCard;
