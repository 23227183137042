import { useState } from 'react';
import useQueryParams from '@shared/useQueryParams';

type useLightboxProps = {
  startingCurrentIndex?: number;
  startingLightboxOpen?: boolean;
  startingActiveTab?: 'images' | 'virtualTours';
  startingVisibleElementOnTours?: 'video' | 'tourEmbed';
  onOpen?: () => void;
  onClose?: () => void;
  hasVideo: boolean;
  has3dTour: boolean;
};

const startsOnDefaults = {
  video: {
    startingVisibleElementOnTours: 'video',
    startingActiveTab: 'virtualTours',
    startingLightboxOpen: true,
  } as const,
  threeDTour: {
    startingVisibleElementOnTours: 'tourEmbed',
    startingActiveTab: 'virtualTours',
    startingLightboxOpen: true,
  } as const,
};

export default function useLightbox({
  has3dTour,
  hasVideo,
  startingCurrentIndex,
  startingActiveTab,
  startingLightboxOpen,
  startingVisibleElementOnTours,
  onOpen,
  onClose,
}: useLightboxProps) {
  const { startsOn } = useQueryParams();
  const getQueryStartsOnProps = (): {
    startingVisibleElementOnTours: 'video' | 'tourEmbed';
    startingActiveTab: 'virtualTours';
    startingLightboxOpen: true;
  } | null => {
    if (startsOn === '3dtour' && has3dTour) {
      return startsOnDefaults.threeDTour;
    }
    if (startsOn === 'video' && hasVideo) {
      return startsOnDefaults.video;
    }
    return null;
  };

  const [currentIndex, setCurrentIndex] = useState<number>(startingCurrentIndex || 0);
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(
    startingLightboxOpen || getQueryStartsOnProps()?.startingLightboxOpen || false,
  );

  const setLightboxOpenWithCallbacks = isOpen => {
    setLightboxOpen(isOpen);
    if (isOpen) {
      if (onOpen) onOpen();
    } else if (onClose) onClose();
  };

  const [activeTab, setActiveTab] = useState<'images' | 'virtualTours'>(
    startingActiveTab || getQueryStartsOnProps()?.startingActiveTab || 'images',
  );
  const [visibleElementOnTours, setVisibleElementOnTours] = useState<'video' | 'tourEmbed'>(() => {
    if (startingVisibleElementOnTours) return startingVisibleElementOnTours;
    if (getQueryStartsOnProps()) return getQueryStartsOnProps()!.startingVisibleElementOnTours;

    return hasVideo ? 'video' : 'tourEmbed';
  });

  const closeLightbox = () => {
    setActiveTab('images');
    setLightboxOpenWithCallbacks(false);
  };

  return {
    lightboxOpen,
    currentIndex,
    activeTab,
    visibleElementOnTours,
    setLightboxOpen: setLightboxOpenWithCallbacks,
    setCurrentIndex,
    setActiveTab,
    setVisibleElementOnTours,
    closeLightbox,
  };
}
