import { InlineNotice } from '@viewthespace/components';
import { useTranslation } from 'react-i18next';
import { BuildingLogoTypes } from '../BrandedAssets';

type Props = {
  assetType: BuildingLogoTypes;
  isLandlordAssetHidden: boolean;
};

const InheritingLandlordAssetNotice = ({ assetType, isLandlordAssetHidden }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <div className="mb-[-8px] w-fit [&>*:first-child]:p-0.5 [&_div]:items-center [&_svg]:h-[14px] [&_svg]:min-h-[14px] [&_svg]:w-[14px] [&_svg]:min-w-[14px]">
      <InlineNotice
        content={
          <span className="font-body-small">
            {isLandlordAssetHidden
              ? t(`building.brandedAssets.${assetType}.hiddenInlineNotice`)
              : t(`building.brandedAssets.${assetType}.inlineNotice`)}
          </span>
        }
      />
    </div>
  );
};

export default InheritingLandlordAssetNotice;
