import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextAreaAutosize as TextArea, Checkbox } from '@components/shared/forms';
import { useFormikContext } from 'formik';
import { FormValues } from './SaveSearchButton';
import s from './SaveSearchButton.module.less';

type Props = {
  description: string;
};

const CreateNewSearchForm = ({ description }: Props) => {
  const { t } = useTranslation('filters');
  const {
    values: { name, useSearchCriteriaAsName },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const handleNameInputChange = (form: any) => event => {
    form.setFieldValue('name', event.target.value);
  };

  useEffect(() => {
    if (useSearchCriteriaAsName) {
      setFieldValue('name', description);
    } else {
      setFieldValue('name', '');
    }
  }, [useSearchCriteriaAsName, setFieldValue, description]);

  return (
    <>
      <TextArea
        name="name"
        className={s.nameInputField}
        value={name}
        onChange={handleNameInputChange}
        disabled={useSearchCriteriaAsName}
        onFocus={() => {}}
        minRows={2}
        maxRows={2}
        autoFocus
      ></TextArea>
      <Checkbox
        data-testid="use-search-criteria-as-name-checkbox"
        name="useSearchCriteriaAsName"
        size="small"
        labelClassName={s.useSearchCriteriaCheckbox}
      >
        {t('saveSearch:useCriteriaAsNameTitle')}
      </Checkbox>
    </>
  );
};

export default CreateNewSearchForm;
