import { PARAMETERS, EVENT_TYPES, EVENTS } from '@root/tracking/constants';
import {
  ListingAnalyticsInformation,
  ExternalListingAnalyticsInformation,
  TourbookListingAnalyticsInformation,
} from '@root/types';

const tourbookPageLoadedAction = {
  type: 'TOURBOOK_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.tourbookPage,
      },
    },
  },
} as const;
type TourbookPageLoadedAction = typeof tourbookPageLoadedAction;
const tourbookPageLoaded = () => tourbookPageLoadedAction;

const tourbookViewListingAction = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
) => {
  return {
    type: 'TOURBOOK_VIEW_LISTING',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.clickToPage,
          sourcePage: PARAMETERS.tourbookPage,
          destination: PARAMETERS.listingPage,
          ...listingAnalyticsInformation,
        },
      },
    },
  };
};

type TourbookViewListingAction = typeof tourbookViewListingAction;
const tourbookViewListing = (listingAnalyticsInformation: TourbookListingAnalyticsInformation) => {
  return tourbookViewListingAction(listingAnalyticsInformation);
};

const tourbookListingMapDirectionsClickedAction = (
  listingAnalyticsInformation: ListingAnalyticsInformation,
) => {
  return {
    type: 'TOURBOOK_LISTING_MAP_DIRECTIONS_CLICKED',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.clickToPage,
          sourcePage: PARAMETERS.tourbookPage,
          ...listingAnalyticsInformation,
        },
      },
    },
  };
};

type TourbookListingMapDirectionsClickedAction = typeof tourbookListingMapDirectionsClickedAction;
const tourbookListingMapDirectionsClicked = (
  listingAnalyticsInformation: ListingAnalyticsInformation,
) => {
  return tourbookListingMapDirectionsClickedAction(listingAnalyticsInformation);
};

const tourbookDeleteListingAction = (listingAnalyticsInformation: ListingAnalyticsInformation) => {
  return {
    type: 'TOURBOOK_DELETE_LISTING',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.deleteListing,
          sourcePage: PARAMETERS.tourbookPage,
          ...listingAnalyticsInformation,
        },
      },
    },
  };
};

type TourbookDeleteListingAction = typeof tourbookDeleteListingAction;
const tourbookDeleteListing = (listingAnalyticsInformation: ListingAnalyticsInformation) => {
  return tourbookDeleteListingAction(listingAnalyticsInformation);
};

const tourbookEditAction = () => {
  return {
    type: 'TOURBOOK_EDIT',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.editTourbook,
          sourcePage: PARAMETERS.tourbookPage,
        },
      },
    },
  };
};

type TourbookEditAction = typeof tourbookEditAction;
const tourbookEdit = () => {
  return tourbookEditAction();
};

const tourbookShareAction = shareUrl => {
  return {
    type: 'TOURBOOK_SHARE',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.shareInteraction,
          action: PARAMETERS.copyUrl,
          sourcePage: PARAMETERS.tourbookPage,
          sourceContent: PARAMETERS.tourbook,
          url: shareUrl.substring(0, shareUrl.indexOf('?')),
          isShare: undefined,
        },
      },
    },
  };
};

type TourbookShareAction = typeof tourbookShareAction;
const tourbookShare = shareUrl => {
  return tourbookShareAction(shareUrl);
};

const tourbookDownloadAction = () => {
  return {
    type: 'TOURBOOK_DOWNLOAD',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.downloadTourbook,
          sourcePage: PARAMETERS.tourbookPage,
        },
      },
    },
  };
};

type TourbookDownloadAction = typeof tourbookDownloadAction;
const tourbookDownload = () => {
  return tourbookDownloadAction();
};

const tourbookRetryDownloadAction = () => {
  return {
    type: 'TOURBOOK_RETRY_DOWNLOAD',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.retryDownload,
          sourcePage: PARAMETERS.tourbookPage,
        },
      },
    },
  };
};

type TourbookCreateEntryAction = typeof tourbookCreateEntryAction;
const tourbookCreateEntry = () => {
  return tourbookCreateEntryAction();
};

const tourbookCreateEntryAction = () => {
  return {
    type: 'TOURBOOK_CREATE_ENTRY',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.createEntry,
          sourcePage: PARAMETERS.tourbookPage,
        },
      },
    },
  };
};

type TourbookUserPolicyAgreeAction = typeof tourbookUserPolicyAgreeAction;
const tourbookUserPolicyAgree = () => {
  return tourbookUserPolicyAgreeAction();
};

const tourbookUserPolicyAgreeAction = () => {
  return {
    type: 'TOURBOOK_USER_POLICY_AGREE',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.userPolicyAgree,
          sourcePage: PARAMETERS.tourbookExternalListingPage,
        },
      },
    },
  };
};

type TourbookUserPolicyCancelAction = typeof tourbookUserPolicyCancelAction;
const tourbookUserPolicyCancel = () => {
  return tourbookUserPolicyCancelAction();
};

const tourbookUserPolicyCancelAction = () => {
  return {
    type: 'TOURBOOK_USER_POLICY_CANCEL',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.userPolicyCancel,
          sourcePage: PARAMETERS.tourbookExternalListingPage,
        },
      },
    },
  };
};

type TourbookEditExternalListingAction = typeof tourbookEditExternalListingAction;
const tourbookEditExternalListing = (
  externalListingAnalyticsInformation: ExternalListingAnalyticsInformation,
) => {
  return tourbookEditExternalListingAction(externalListingAnalyticsInformation);
};

const tourbookEditExternalListingAction = (
  externalListingAnalyticsInformation: ExternalListingAnalyticsInformation,
) => {
  return {
    type: 'TOURBOOK_EDIT_EXTERNAL_LISTING',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.editExternalListing,
          sourcePage: PARAMETERS.tourbookPage,
          ...externalListingAnalyticsInformation,
        },
      },
    },
  };
};

type TourbookRetryDownloadAction = typeof tourbookRetryDownloadAction;
const tourbookRetryDownload = () => {
  return tourbookRetryDownloadAction();
};

const lightboxPaginationClickedAction = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
) => {
  return {
    type: 'TOURBOOK_LIGHTBOX_PAGINATION_CLICKED',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.galleryInteraction,
          action: PARAMETERS.paginate,
          sourcePage: PARAMETERS.tourbookPage,
          sourceContent: PARAMETERS.tourbookListingGallery,
          ...listingAnalyticsInformation,
        },
      },
    },
  };
};
type TourbookLightboxPaginationClickedAction = typeof lightboxPaginationClickedAction;
const lightboxPaginationClicked = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
) => lightboxPaginationClickedAction(listingAnalyticsInformation);

const lightboxThumbnailClickedAction = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
) => {
  return {
    type: 'TOURBOOK_LIGHTBOX_THUMBNAIL_CLICKED',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.galleryInteraction,
          action: PARAMETERS.clickThumbnail,
          sourcePage: PARAMETERS.tourbookPage,
          sourceContent: PARAMETERS.tourbookListingGallery,
          ...listingAnalyticsInformation,
        },
      },
    },
  };
};

type TourbookLightboxThumbnailClickedAction = typeof lightboxThumbnailClickedAction;
const lightboxThumbnailClicked = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
) => lightboxThumbnailClickedAction(listingAnalyticsInformation);

const mediaAssetOpenedAction = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
) => {
  return {
    type: 'TOURBOOK_MEDIA_ASSET_OPENED',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.galleryInteraction,
          action: PARAMETERS.select,
          sourcePage: PARAMETERS.tourbookPage,
          sourceContent: PARAMETERS.tourbookListingGallery,
          ...listingAnalyticsInformation,
        },
      },
    },
  };
};
type TourbookMediaAssetOpenedAction = typeof mediaAssetOpenedAction;
const mediaAssetOpened = (listingAnalyticsInformation: TourbookListingAnalyticsInformation) =>
  mediaAssetOpenedAction(listingAnalyticsInformation);

const floorplanOpenedAction = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
  external: boolean,
) => {
  return {
    type: 'TOURBOOK_FLOORPLAN_OPENED',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.galleryInteraction,
          action: PARAMETERS.clickFloorplan,
          sourcePage: PARAMETERS.tourbookPage,
          sourceContent: external
            ? PARAMETERS.tourbookExternalListingGallery
            : PARAMETERS.tourbookListingGallery,
          ...listingAnalyticsInformation,
        },
      },
    },
  };
};
type TourbookFloorplanOpenedAction = typeof floorplanOpenedAction;
const floorplanOpened = (
  listingAnalyticsInformation: TourbookListingAnalyticsInformation,
  external: boolean,
) => floorplanOpenedAction(listingAnalyticsInformation, external);

const beginReorderTourbookListingsAction = {
  type: 'BEGAN_REORDERING_TOURBOOK_LISTINGS',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.reorderTourbookListingsStart,
      },
    },
  },
} as const;
type BeginReorderTourbookListingsAction = typeof beginReorderTourbookListingsAction;
const beginReorderingTourbookListings = () => beginReorderTourbookListingsAction;

const endReorderTourbookListingsAction = {
  type: 'END_REORDERING_TOURBOOK_LISTINGS',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.reorderTourbookListingsEnd,
      },
    },
  },
} as const;
type EndReorderTourbookListingsAction = typeof endReorderTourbookListingsAction;
const endReorderingTourbookListings = () => endReorderTourbookListingsAction;

const reorderedTourbookListingAction = {
  type: 'REORDERED_TOURBOOK_LISTING',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.reorderTourbookListing,
      },
    },
  },
} as const;
type ReorderedTourbookListingAction = typeof reorderedTourbookListingAction;
const reorderedTourbookListing = () => reorderedTourbookListingAction;

const openMapTourbookAction = {
  type: 'TOURBOOK_OPEN_MAP',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.openMap,
      },
    },
  },
} as const;
type OpenMapTourbookAction = typeof openMapTourbookAction;
const openTourbookMap = () => openMapTourbookAction;

const closeMapTourbookAction = {
  type: 'TOURBOOK_CLOSE_MAP',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.closeMap,
      },
    },
  },
} as const;
type CloseMapTourbookAction = typeof closeMapTourbookAction;
const closeTourbookMap = () => closeMapTourbookAction;

type MapAutofocusToggledAction = {
  type: 'TOURBOOK_AUTOFOCUS_TOGGLED';
  payload: {
    meta: {
      analytics: {
        action: 'autofocus toggled';
        eventType: 'track';
        event: 'Map Interaction';
        sourcePage: 'tourbooks';
        sourceContent: 'map';
        autofocus: Boolean;
      };
    };
  };
};

const mapAutofocusToggled = (checked: boolean): MapAutofocusToggledAction => ({
  type: 'TOURBOOK_AUTOFOCUS_TOGGLED',
  payload: {
    meta: {
      analytics: {
        action: PARAMETERS.autofocusToggled,
        eventType: EVENT_TYPES.track,
        event: EVENTS.mapInteraction,
        sourcePage: 'tourbooks',
        sourceContent: 'map',
        autofocus: checked,
      },
    },
  },
});

export type Action =
  | BeginReorderTourbookListingsAction
  | CloseMapTourbookAction
  | EndReorderTourbookListingsAction
  | MapAutofocusToggledAction
  | OpenMapTourbookAction
  | ReorderedTourbookListingAction
  | TourbookCreateEntryAction
  | TourbookDeleteListingAction
  | TourbookDownloadAction
  | TourbookEditAction
  | TourbookEditExternalListingAction
  | TourbookFloorplanOpenedAction
  | TourbookLightboxPaginationClickedAction
  | TourbookLightboxThumbnailClickedAction
  | TourbookListingMapDirectionsClickedAction
  | TourbookMediaAssetOpenedAction
  | TourbookPageLoadedAction
  | TourbookRetryDownloadAction
  | TourbookShareAction
  | TourbookUserPolicyAgreeAction
  | TourbookUserPolicyCancelAction
  | TourbookViewListingAction;

export default {
  beginReorderingTourbookListings,
  closeTourbookMap,
  endReorderingTourbookListings,
  floorplanOpened,
  lightboxPaginationClicked,
  lightboxThumbnailClicked,
  mapAutofocusToggled,
  mediaAssetOpened,
  openTourbookMap,
  reorderedTourbookListing,
  tourbookCreateEntry,
  tourbookDeleteListing,
  tourbookDownload,
  tourbookEdit,
  tourbookEditExternalListing,
  tourbookListingMapDirectionsClicked,
  tourbookPageLoaded,
  tourbookRetryDownload,
  tourbookShare,
  tourbookUserPolicyAgree,
  tourbookUserPolicyCancel,
  tourbookViewListing,
};
