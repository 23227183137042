import { extractColors } from 'extract-colors';

type Props = {
  imgSource: string;
  minColorThreshhold?: number;
  // See https://extract-colors.namide.com/guide/#options
  options?: {
    pixels?: number; // Min 1
    distance?: number; // 0..1
    splitPower?: number; // 2..15
    saturationDistance?: number; // 0..1
    lightnessDistance?: number; // 0..1
    hueDistance?: number; // 0..1
  };
};

// Wrapper around extractColors which provides default extraction options and filters
// out colours under a threshhold. 0.01 = 1% of total image's color.
const colorsInImage = async ({
  imgSource,
  minColorThreshhold = 0.01, // 1%
  options,
}: Props): Promise<string[]> => {
  let extractedColors: string[] = [];

  const defaultExtractOptions = {
    pixels: 1500,
    distance: 0.1,
    splitPower: 2,
    saturationDistance: 0,
    lightnessDistance: 0,
    hueDistance: 0,
    ...options,
  };

  extractedColors = await extractColors(imgSource, {
    ...defaultExtractOptions,
    crossOrigin: 'anonymous',
  }).then(
    colors => {
      return colors
        .filter(color => color.area >= minColorThreshhold)
        .sort((c1, c2) => c2.area - c1.area)
        .map(color => color.hex);
    },
    () => [], // Empty array of rejection.
  );

  return extractedColors;
};

export default colorsInImage;
