import api from '@shared/api';

type GeocodeOptions = { address: string };
type GeocodeCallback = (results: {}, status: string) => void;
type GeocodeResponse = { results: {}; status: string };

export default class Geocoder {
  key: string;

  baseUrl = `https://maps.googleapis.com/maps/api/geocode/json?`;

  constructor(apiKey: string) {
    this.key = apiKey;
  }

  geocode = async (options: GeocodeOptions, callback: GeocodeCallback) => {
    const requestUrl = `${this.baseUrl}key=${this.key}&address=${options.address}`;
    const response = await api.fetch(requestUrl);
    const data = (await response.json()) as GeocodeResponse;

    const { results, status } = data;

    callback(results, status);
  };
}
