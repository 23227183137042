import * as React from 'react';
import { isUndefined } from 'lodash';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import s from './RadioInputComponent.module.less';

export type Props = {
  name: string;
  value: string;
  checked?: boolean;
  onChange: (value: string) => void;
  labelText: React.ReactNode;
  id?: string;
  clearable?: boolean;
  disabled?: boolean;
  title?: string;
};

const RadioInputComponent = ({
  name,
  value,
  checked,
  onChange,
  labelText,
  id,
  clearable = false,
  disabled = false,
  title,
}: Props) => {
  const uniqueId: string = id || `${name}-${value}`;

  return (
    <>
      <input
        className={classNames(s.input, {
          [s.active]: disabled,
        })}
        type="radio"
        data-testid={uniqueId}
        id={uniqueId}
        name={name}
        value={value}
        checked={isUndefined(checked) ? undefined : checked}
        onClick={() => {
          if (clearable && checked) {
            onChange(value);
          }
        }}
        disabled={disabled}
        onChange={(event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
      />

      {disabled === true ? (
        <Tooltip
          data-testid="tooltip1"
          overlayClassName={s.tooltip}
          trigger={['hover', 'click']}
          placement="top"
          title={title}
        >
          <label
            className={classNames(s.label, {
              [s.inactive]: disabled,
            })}
            htmlFor={uniqueId}
          >
            {labelText}
          </label>
        </Tooltip>
      ) : (
        <label
          className={classNames(s.label, {
            [s.inactive]: disabled,
          })}
          htmlFor={uniqueId}
        >
          {labelText}
        </label>
      )}
    </>
  );
};

export default RadioInputComponent;
