import cn from 'classnames';
import contentWidth from '@styles/contentWidth.module.less';
import { isInIframe } from '@root/shared/iframeUtils';

const AnalyticsSkeleton = () => {
  const FactsSkeleton = () => {
    const Fact = () => {
      return (
        <div className="grid h-[100px] grid-cols-[32px_1fr] items-center gap-x-1 rounded bg-background-primary p-1 shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)]">
          <div className="shimmer col-span-1 h-4 w-4 rounded-[3px]" />
          <div className="shimmer col-span-2 col-start-2 h-[20px] w-9 rounded-[3px] mobile:w-full" />
          <div className="shimmer col-span-2 col-start-2 h-4 w-full rounded-[3px] desktop:w-[60%] mobile:col-start-1" />
        </div>
      );
    };
    return (
      <>
        {Fact()}
        {Fact()}
        {Fact()}
      </>
    );
  };

  const PieChartSkeleton = () => {
    return (
      <div className="max-h-[342px] flex-grow rounded bg-background-primary shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)] desktop:max-h-[342px] mobile:max-h-full">
        <section className="flex h-7 w-full items-center border-b-[1px] border-solid border-black-010 pl-2">
          <div className="shimmer h-3 w-28 rounded-[3px] mobile:w-3/4" />
        </section>
        <section className="flex items-end justify-between p-2 desktop:items-center desktop:justify-end tablet:pb-1 tablet:pt-[10px] mobile:flex-col mobile:items-start mobile:pt-30">
          <div className="mb-1 ml-2 w-full desktop:mr-12 desktop:w-[283px] mobile:mb-2 mobile:w-1/2">
            <div className="shimmer mb-[12px] h-2 w-[55%] rounded-[3px]" />
            <div className="shimmer mb-[12px] h-2 w-[80%] rounded-[3px]" />
            <div className="shimmer h-4 w-[20%] rounded-[3px]" />
          </div>
          <div className="shimmer h-32 w-34 shrink-0 rounded-[3px] mobile:h-[307px] mobile:w-full" />
        </section>
      </div>
    );
  };

  const BarChartChartSkeleton = () => {
    const VerticalLine = () => {
      return <div className="h-[289px] w-[1px] bg-black-010" />;
    };
    const HorizontalLine = () => {
      return <div className="h-[1px] w-full bg-black-010" />;
    };
    const BarChartOutline = () => {
      return (
        <>
          <div className="flex flex-row gap-[19%]">
            {VerticalLine()}
            {VerticalLine()}
            {VerticalLine()}
            {VerticalLine()}
            {VerticalLine()}
            {VerticalLine()}
          </div>
          {HorizontalLine()}
        </>
      );
    };

    return (
      <div className="h-fit max-h-[500px] w-full rounded bg-background-primary shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)] mobile:max-h-full">
        <section className="flex h-7 items-center justify-between pl-2 pr-2 mobile:my-2 mobile:flex-col mobile:items-start">
          <div className="shimmer h-3 w-40 rounded-[3px]" />
          <div className="flex">
            <div className="shimmer mr-1 h-2 w-9 rounded-[3px]" />
            <div className="shimmer h-2 w-16 rounded-[3px]" />
          </div>
        </section>
        {HorizontalLine()}
        <section className="flex justify-between gap-3 p-2 mobile:flex-col">
          <div className="ml-8 h-[300px] desktop:w-[70%] tablet:w-[70%]">{BarChartOutline()}</div>
          <div className="shimmer shrink-0 rounded-[3px] desktop:w-[275px] tablet:w-[275px] mobile:h-[307px]" />
        </section>
      </div>
    );
  };

  const DetailsSkeleton = () => {
    const FullWidthSeparator = ({ className }: { className?: string }) => {
      return <div className={cn('col-span-7 my-1 h-[1px] bg-black-010', className)} />;
    };

    const listingRowSkeleton = () => {
      return (
        <>
          <div className="shimmer col-span-1 mb-2 h-[20px] w-[20px] rounded-[3px]"></div>
          <div className="shimmer col-start-2 col-end-2 h-8 w-11 rounded-[3px]"></div>
          <div className="ml-2 flex flex-col">
            <div className="shimmer col-start-3 col-end-3 mb-1 h-3 w-11/12 rounded-[3px]"></div>
            <div className="shimmer col-start-3 col-end-3 h-4 w-4/5 rounded-[3px]"></div>
          </div>
          <div className="shimmer col-start-4 col-end-4 h-[20px] w-[20px] rounded-[3px]"></div>
          <div className="shimmer col-start-5 col-end-5 h-[20px] w-[20px] rounded-[3px]"></div>
          <div className="shimmer col-start-6 col-end-6 h-[20px] w-[20px] rounded-[3px]"></div>
        </>
      );
    };
    return (
      <>
        <div className="grid h-full max-h-[404px] w-full grid-cols-[60px_84px_230px_repeat(3,minmax(90px,240px))] rounded bg-background-primary p-2 shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)] mobile:hidden">
          <div className="shimmer col-span-2 mb-2 h-[20px] w-40 rounded-[3px]" />
          <div className="shimmer col-start-2 col-end-2 h-[20px] w-6 rounded-[3px]" />
          <div className="shimmer col-start-4 col-end-4 h-[20px] w-11 rounded-[3px]" />
          <div className="shimmer col-start-5 col-end-5 h-[20px] w-11 rounded-[3px]" />
          <div className="shimmer col-start-6 col-end-6 h-[20px] w-11 rounded-[3px]" />
          <FullWidthSeparator className="mx-[-16px]" />
          {listingRowSkeleton()}
          <FullWidthSeparator />
          {listingRowSkeleton()}
          <FullWidthSeparator />
          {listingRowSkeleton()}
        </div>
      </>
    );
  };
  return (
    <>
      <div
        className={cn(
          'absolute left-0 right-0 top-[50px] h-9 w-full bg-background-primary shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)]',
          isInIframe() && '!top-0',
        )}
      />
      <div
        className={cn(
          contentWidth.contentContainer,
          '!mt-9 flex flex-col gap-2 pb-2 mobile:!mt-11',
        )}
      >
        <section className="flex gap-2 mobile:flex-col">
          <ul className="grid w-full max-w-[244px] gap-2 tablet:max-w-[178px] mobile:max-w-none mobile:grid-cols-3 mobile:gap-1">
            <FactsSkeleton />
          </ul>
          <PieChartSkeleton />
        </section>
        <BarChartChartSkeleton />
        <DetailsSkeleton />
      </div>
    </>
  );
};

export default AnalyticsSkeleton;
