import { parse, stringify } from 'qs';
import { History } from 'history';
import { mapKeys, camelCase, snakeCase, Dictionary, omit } from 'lodash';
import {
  CeilingHeight,
  LeaseTypes,
  Possession,
  PriceRangeFilter,
  SizeRangeFilter,
  SpaceCondition,
  SearchCriteriaKeys,
  SortOption,
  TermValues,
  MarketSlug,
  AmenityKey,
  Market,
  SavedSearch,
} from '@root/types';
import routes from '@root/routes';
import { Dispatch } from 'react';
import actions from '@store/actions';

export type SearchCriteria = {
  spaceCondition?: SpaceCondition[];
  terms?: TermValues[];
  ceilingHeightType?: CeilingHeight['type'];
  ceilingHeightMagnitude?: CeilingHeight['magnitude'];
  inMeters?: boolean;
  possession?: Possession;
  minSize?: SizeRangeFilter['minSize'];
  maxSize?: SizeRangeFilter['maxSize'];
  minPrice?: PriceRangeFilter['minPrice'];
  maxPrice?: PriceRangeFilter['maxPrice'];
  excludeNegotiable?: PriceRangeFilter['excludeNegotiable'];
  landlords?: string[];
  map?: string;
  submarkets?: string[];
  buildingIds?: string[];
  exclusive: boolean;
  page: number;
  leaseTypes?: LeaseTypes[];
  keywords?: string[];
  amenities?: AmenityKey[];
  sort: SortOption;
  polygons?: string;
};

export type SearchCriteriaWithMultipleOptions = {
  spaceCondition: SpaceCondition;
  terms: string;
  submarkets: string;
  leaseTypes: LeaseTypes;
  keywords: string;
  amenities: AmenityKey;
  landlords: string;
};
export type SearchCriteriaWithMultipleOptionsKeys = keyof SearchCriteriaWithMultipleOptions;

export const DEFAULT_FILTERS = {
  page: 1,
  exclusive: false,
  sort: 'newest' as SortOption,
  inMeters: false,
  excludeNegotiable: false,
  ceilingHeightMagnitude: null,
  map: undefined,
  polygons: undefined,
};

const camelizeKeys = obj => mapKeys(obj, (_v, key) => camelCase(key));
const snakeKeys = obj => mapKeys(obj, (_v, key) => snakeCase(key));

const arraySortToString = (filterValue: Array<string>) => {
  if (!filterValue || filterValue.length === 0) {
    return null;
  }
  return filterValue.sort().join(',');
};

const clearDefaultValues = (filters: Dictionary<any>) => {
  const nonDefaultFilters = { ...filters };
  Object.keys(DEFAULT_FILTERS).forEach(key => {
    if (nonDefaultFilters[key] === DEFAULT_FILTERS[key]) {
      delete nonDefaultFilters[key];
    }
  });
  return nonDefaultFilters;
};

const objectPropertiesAsArray = (keys: Array<SearchCriteriaKeys>, object) => {
  const copyOfObject = { ...object };
  keys.forEach(key => {
    if (object[key]) {
      copyOfObject[key] = Array.isArray(copyOfObject[key])
        ? copyOfObject[key]
        : copyOfObject[key].split(',');
    }
  });
  return copyOfObject;
};

const transformValues = (obj: Dictionary<string | string[]>): SearchCriteria => {
  const searchCriteria: SearchCriteria = {
    ...obj,
    page: obj.page ? Number(obj.page) : 1,
    exclusive: obj.exclusive === 'true',
    inMeters: obj.inMeters === 'true',
    excludeNegotiable: obj.excludeNegotiable === 'true',
    sort: obj.sort ? (obj.sort as SortOption) : 'newest',
    polygons: Array.isArray(obj.polygons) ? obj.polygons.join(',') : obj.polygons,
    map: Array.isArray(obj.map) ? obj.map.join(',') : obj.map,
    ceilingHeightMagnitude: obj.ceilingHeightMagnitude ? Number(obj.ceilingHeightMagnitude) : null,
  };

  return objectPropertiesAsArray(
    [
      'leaseTypes',
      'spaceCondition',
      'terms',
      'amenities',
      'submarkets',
      'buildingIds',
      'landlords',
      'keywords',
    ],
    searchCriteria,
  );
};

const transformValuesFromSavedSearch = filters => ({
  ...filters,
  page: 1,
  submarkets: filters.submarkets ? filters.submarkets.split(',') : [],
  spaceCondition: filters.spaceCondition ? filters.spaceCondition.split(',') : [],
  terms: filters.terms ? filters.terms.split(',') : [],
  leaseTypes: filters.leaseTypes ? filters.leaseTypes.split(',') : [],
  amenities: filters.amenities ? filters.amenities.split(',') : [],
  buildingIds: filters.buildingIds ? filters.buildingIds.split(',') : [],
  landlords: filters.landlords ? filters.landlords.split(',') : [],
  keywords: filters.keywords ? filters.keywords.split(',') : [],
});

class ListingSearchCriteria {
  currentFilters: SearchCriteria;

  marketSlug: string;

  history?: History | null;

  dispatch?: Dispatch<any> | null;

  savedSearchId?: string | null;

  static fromUrl({ queryString, marketSlug = '' }) {
    return new ListingSearchCriteria({ queryString, marketSlug });
  }

  static fromSavedSearch(savedSearch: SavedSearch) {
    const { marketSlug, ...restOfFilters } = savedSearch.criteria;
    const criteria = new ListingSearchCriteria({
      marketSlug: marketSlug as string,
      queryString: '',
      savedSearchId: savedSearch.id,
    });

    criteria.currentFilters = transformValuesFromSavedSearch(restOfFilters);

    return criteria;
  }

  constructor(url: {
    marketSlug: string;
    queryString: string;
    history?: History;
    dispatch?: Dispatch<any>;
    savedSearchId?: string | null;
  }) {
    const { savedSearchId, ...initialValues } = camelizeKeys(
      parse(url.queryString, { ignoreQueryPrefix: true, comma: true }),
    );
    this.savedSearchId = url.savedSearchId || savedSearchId;
    this.currentFilters = {
      ...transformValues(initialValues),
    };
    this.marketSlug = url.marketSlug;
    this.history = url.history;
    this.dispatch = url.dispatch;
  }

  pushToHistory({ replace = false }: { replace?: boolean } = {}) {
    if (this.history) {
      const newUrl = this.toUrl();

      if (replace) this.history.replace(newUrl);
      else this.history.push(newUrl);
    }
  }

  add<T extends SearchCriteriaKeys>(key: T, values: SearchCriteria[T]) {
    this.currentFilters = {
      ...this.currentFilters,
      [key]: values,
    };
    if (key !== 'page') {
      this.resetPage();
    }

    return this.currentFilters;
  }

  addValue<T extends SearchCriteriaWithMultipleOptionsKeys>(
    key: T,
    value: SearchCriteriaWithMultipleOptions[T],
  ) {
    const newValues = [...(this.currentFilters[key] ?? []), value] as SearchCriteria[T];
    return this.add(key, newValues);
  }

  removeAll() {
    this.currentFilters = { ...DEFAULT_FILTERS };
    this.savedSearchId = null;
    this.resetPage();
  }

  remove(key: SearchCriteriaKeys) {
    const { currentFilters } = this;
    const filterCopy: SearchCriteria = { ...currentFilters };

    if (key.toString() === 'map') {
      delete filterCopy['custom-map'];
    }

    delete filterCopy[key];
    this.currentFilters = filterCopy;
    this.resetPage();

    return this.currentFilters;
  }

  removeValue<T extends SearchCriteriaWithMultipleOptionsKeys>(
    key: T,
    valueToDelete: SearchCriteriaWithMultipleOptions[T],
  ) {
    const valuesToFilter = this.currentFilters[key];
    if (!valuesToFilter) return this.currentFilters;
    const newValues = valuesToFilter.filter(currentValue => currentValue !== valueToDelete);

    return newValues.length ? this.add(key, newValues as SearchCriteria[T]) : this.remove(key);
  }

  setSavedSearchId(savedSearchId: string | null): void {
    this.savedSearchId = savedSearchId;
  }

  setHistory(history: History): void {
    this.history = history;
  }

  page() {
    return this.currentFilters.page ? Number(this.currentFilters.page) : 1;
  }

  resetPage() {
    this.currentFilters.page = 1;
  }

  toUrl() {
    const currentFiltersWithSavedSearchId: Dictionary<any> = { ...this.currentFilters };
    if (this.savedSearchId) currentFiltersWithSavedSearchId.savedSearchId = this.savedSearchId;
    const filters = snakeKeys(clearDefaultValues(currentFiltersWithSavedSearchId));

    return `/search/${this.marketSlug}${stringify(filters, {
      addQueryPrefix: true,
      arrayFormat: 'comma',
      encode: false,
      skipNulls: true,
      sort: (a, b) => a.localeCompare(b),
    })}`;
  }

  hasNonDefaultFilters() {
    return !!Object.keys(clearDefaultValues(this.currentFilters)).length;
  }

  toSavedSearchParameters() {
    const parameters = {
      ...omit(this.currentFilters, ['sort', 'page']),
      marketSlug: this.marketSlug,
      submarkets: arraySortToString(this.currentFilters.submarkets as string[]),
      spaceCondition: arraySortToString(this.currentFilters.spaceCondition as string[]),
      terms: arraySortToString(this.currentFilters.terms as string[]),
      leaseTypes: arraySortToString(this.currentFilters.leaseTypes as string[]),
      amenities: arraySortToString(this.currentFilters.amenities as string[]),
      buildingIds: arraySortToString(this.currentFilters.buildingIds as string[]),
      landlords: arraySortToString(this.currentFilters.landlords as string[]),
      keywords: arraySortToString(this.currentFilters.keywords as string[]),
    };

    return parameters;
  }

  toAnalyticsProperties() {
    return {
      market: this.marketSlug,
      ...clearDefaultValues(omit(this.currentFilters, ['sort', 'page'])),
    };
  }

  addMap(coordinates: string) {
    this.remove('submarkets');
    this.remove('polygons');
    this.add('map', coordinates);
  }

  addPolygons(polygons: string) {
    this.remove('map');
    this.add('polygons', polygons);
  }

  addSubmarkets(submarkets: string[]) {
    this.remove('map');
    this.add('submarkets', submarkets);
  }

  removeMap(market: MarketSlug | 'custom') {
    this.remove('map');
    this.marketSlug = market;
  }

  removePolygons(market: MarketSlug | 'custom') {
    this.remove('polygons');
    if (this.dispatch) this.dispatch(actions.setDrawnPolygons([]));
    this.marketSlug = market;
  }

  changeMarket(market: MarketSlug | 'custom') {
    this.remove('map');
    this.remove('submarkets');
    this.remove('polygons');
    if (this.dispatch) this.dispatch(actions.setDrawnPolygons([]));

    this.marketSlug = market;
  }

  parsedPolygons() {
    if (!this.currentFilters.polygons) return [];

    return JSON.parse(this.currentFilters.polygons);
  }

  mapCoordinates() {
    if (!this.isUsingMap()) {
      return null;
    }

    if (this.currentFilters.map) {
      return this.currentFilters.map;
    }

    return null;
  }

  isUsingMap() {
    return (
      Object.keys(this.currentFilters).includes('map') ||
      Object.keys(this.currentFilters).includes('polygons')
    );
  }

  shouldChangeMarket(markets: Market[]) {
    return markets.length !== 1 || markets[0].id !== this.marketSlug;
  }

  shouldConfirmMarketChange(markets: Market[]) {
    return (
      this.shouldChangeMarket(markets) &&
      (Object.keys(this.currentFilters).includes('submarkets') ||
        Object.keys(this.currentFilters).includes('buildingIds'))
    );
  }

  buildingIds() {
    return this.currentFilters.buildingIds || [];
  }

  landlordIds() {
    return this.currentFilters.landlords || [];
  }

  submarketSlugs() {
    return this.currentFilters.submarkets || [];
  }

  amenities(): string[] {
    return this.currentFilters.amenities || [];
  }

  changeMarketBasedOn(markets: Market[]) {
    if (!this.shouldChangeMarket(markets)) return;

    if (markets.length !== 1) {
      this.changeMarket('custom');
    } else {
      this.changeMarket(markets[0].id as MarketSlug);
    }
    this.remove('buildingIds');
    this.remove('submarkets');
  }

  isEqualTo(otherCriteria: ListingSearchCriteria): boolean {
    return this.toUrl() === otherCriteria.toUrl();
  }

  toApiListingsUrl(customFilters?: Partial<SearchCriteria>) {
    const filters = clearDefaultValues({
      ...this.currentFilters,
      ...{ marketSlug: this.marketSlug },
      ...customFilters,
    });
    return `${routes.api.listings}${stringify(filters, {
      addQueryPrefix: true,
      skipNulls: true,
      encode: false,
      arrayFormat: 'comma',
    })}`;
  }

  toApiBuildingsUrl(buildingSlug?: string, removeMarketSlugQueryParam = false) {
    const filtersForBuildings = clearDefaultValues({
      ...this.currentFilters,
      ...{ marketSlug: this.marketSlug },
    });
    delete filtersForBuildings.sort;
    delete filtersForBuildings.page;
    if (removeMarketSlugQueryParam) {
      delete filtersForBuildings.marketSlug;
    }

    const base = buildingSlug ? routes.api.buildingMapMarkers(buildingSlug) : routes.api.buildings;

    return `${base}${stringify(filtersForBuildings, {
      addQueryPrefix: true,
      skipNulls: true,
      encode: false,
      arrayFormat: 'comma',
    })}`;
  }
}

export default ListingSearchCriteria;
