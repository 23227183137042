import cn from 'classnames';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import { Locale } from '@root/types';
import CustomIcon, { ValidIconTypes } from '@components/shared/CustomIcon';
import { LocaleSelect } from '@components/shared/forms';
import StandardComponents from '@components/shared/forms/Dropdowns/StandardComponents';
import selectStyles from '@components/shared/forms/Dropdowns/Dropdowns.module.less';
import s from './Navigation/Navigation.module.less';

type LocaleMapKeys = Locale;
type LocaleIconMap = Record<LocaleMapKeys, ValidIconTypes>;
const localeIconMapping: LocaleIconMap = {
  'en-us': 'lang-selection-flag-en-us',
  'en-gb': 'lang-selection-flag-en-gb',
};
const LocaleOption = props => {
  const { children, isSelected, data } = props;
  const locale: Locale = data.value;
  return (
    <components.Option {...props} className={cn(s.option, isSelected && s.selected)}>
      <CustomIcon className={s.langSelectionIcon} type={localeIconMapping[locale]} />
      {children}
      {isSelected ? (
        <aside className={selectStyles.selectedCheckmark}>
          <CustomIcon className={s.localeCheckmark} type="checkmark" />
        </aside>
      ) : null}
    </components.Option>
  );
};

const LocaleInput = props => {
  const { Input } = StandardComponents;
  const { children } = props;
  return (
    <Input {...props} isHidden>
      {children}
    </Input>
  );
};

const LocaleSingleValue = props => {
  const { data } = props;
  const locale: Locale = data.value;
  return (
    <components.SingleValue {...props} className={s.singleValue}>
      <CustomIcon className={s.langSelectionIcon} type={localeIconMapping[locale]} />
    </components.SingleValue>
  );
};

const LocaleMenu = props => <components.Menu {...props} className={s.menu} />;

const LocaleMenuList = props => <components.MenuList {...props} className={s.menuList} />;

const LocaleSelector = ({
  handleChangeLanguage,
  userLanguagePreference,
}: {
  handleChangeLanguage: (val, analyticsInfo) => void;
  userLanguagePreference: Locale;
}) => {
  const { t } = useTranslation('common');
  return (
    <span className={s.languageSelection}>
      <LocaleSelect
        menuPlacement="top"
        components={{
          Option: LocaleOption,
          SingleValue: LocaleSingleValue,
          Input: LocaleInput,
          Menu: LocaleMenu,
          MenuList: LocaleMenuList,
          DropdownIndicator: null,
          IndicatorSeparator: null,
        }}
        value={userLanguagePreference}
        onChange={val =>
          handleChangeLanguage(val, {
            action: `update language preference to ${val}`,
            source: 'user language preference dropdown',
          })
        }
        name="userSelectedLanguage"
        options={[
          { label: t('footer.languageSelection.en-us'), value: 'en-us' },
          { label: t('footer.languageSelection.en-gb'), value: 'en-gb' },
        ]}
        isClearable={false}
        isSearchable={false}
      />
    </span>
  );
};

export default LocaleSelector;
