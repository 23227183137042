import { useState, useEffect } from 'react';
import api from '@shared/api';
import routes from '@root/routes';
import { AdminBuilding, Demographics as DemographicsType } from '@root/types';
import { Form, Formik } from 'formik';
import { notification } from 'antd';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { AdminFormSubmitter } from '@components/shared';
import { useTranslation } from 'react-i18next';
import { Select } from '@components/shared/forms/Dropdowns';
import Input from './Input';
import s from './DemographicsBuildingForm.module.less';
import Demographics from './Demographics';

type ReactInputElement = React.HTMLProps<HTMLInputElement>;

type CustomInputElement = {
  value?: string;
} & Omit<ReactInputElement, 'value'>;

type DemographicDistance = { id: string; radius: number };

type DemographicsBuildingFormProps = {
  building: AdminBuilding;
  updateBuilding: $TSFixMeFunction;
};

export type FormikDemographicValues = {
  id?: string[];
  demographicDistance: string[];
  population: string[];
  daytimePopulation: string[];
  households: string[];
  averageHouseholdIncome: string[];
  medianHouseholdIncome: string[];
  medianAge: string[];
  averageAge: string[];
  percentCollegeEducated: string[];
  perCapitaIncome: string[];
};

export const createDemographicRequestObject = (values: FormikDemographicValues, index: number) => {
  const keys = Object.keys(values);
  const demographicRequest = {};

  keys.forEach(key => {
    if (values.demographicDistance[index] === '') return;
    let demographicVal: number | null | string | { id: string };

    if (values[key][index] === '') {
      demographicVal = null;
    } else if (key && key === 'id') {
      demographicVal = values[key]![index];
    } else {
      demographicVal = Number(values[key][index]);
    }

    if (key === 'demographicDistance') {
      demographicVal = { id: values[key][index] };
      demographicRequest[key] = demographicVal;
    } else {
      demographicRequest[key] = demographicVal;
    }
  });

  return demographicRequest;
};

export const convertFormikValuesToDemographicsRequestArray = (
  values: FormikDemographicValues,
): Object[] => {
  const requestArray: Object[] = [];
  const NUM_OF_COLS_IN_FORM = 3;

  for (let i = 0; i < NUM_OF_COLS_IN_FORM; i += 1) {
    const updateDemographics = createDemographicRequestObject(values, i);
    if (Object.keys(updateDemographics).length > 0) {
      requestArray.push(updateDemographics);
    }
  }

  return requestArray;
};

const DemographicsBuildingForm = ({ building, updateBuilding }: DemographicsBuildingFormProps) => {
  const { t } = useTranslation('admin');
  const unit = building?.units === 'metric' ? 'KM' : 'Miles';

  // if demographic distance response is not ok
  // this is the default state
  // to make sure dropdown is populated
  const [formattedDemographicDistances, setFormattedDemographicDistances] = useState([
    { label: '3', value: 3 },
    { label: '5', value: 5 },
    { label: '10', value: 10 },
  ]);

  const demographicsTranslatedNameMap = {
    demographicDistance: t('building.demographics.distance', { unit }),
    averageAge: t('building.demographics.average_age'),
    averageHouseholdIncome: t('building.demographics.average_hh_income'),
    daytimePopulation: t('building.demographics.daytime_population'),
    households: t('building.demographics.households'),
    medianAge: t('building.demographics.median_age'),
    medianHouseholdIncome: t('building.demographics.median_hh_income'),
    perCapitaIncome: t('building.demographics.per_capita_income'),
    percentCollegeEducated: t('building.demographics.percent_college_educated'),
    population: t('building.demographics.population'),
  };

  const convertDemographicsResponseToFormikObject = () => {
    const { demographics } = building;
    // to display 3 fields on frontend
    // we need to add empty strings
    const demographicValues = {
      id: ['', '', ''],
      demographicDistance: ['', '', ''],
      averageAge: ['', '', ''],
      averageHouseholdIncome: ['', '', ''],
      daytimePopulation: ['', '', ''],
      households: ['', '', ''],
      medianAge: ['', '', ''],
      medianHouseholdIncome: ['', '', ''],
      perCapitaIncome: ['', '', ''],
      percentCollegeEducated: ['', '', ''],
      population: ['', '', ''],
    };

    // to make sure demographics are shown
    // in an ascending order
    demographics.sort((a: DemographicsType, b: DemographicsType) => {
      let sortIdentifier = 0;
      if (a.demographicDistance.radius && b.demographicDistance.radius) {
        sortIdentifier = a.demographicDistance.radius - b.demographicDistance.radius;
      }
      return sortIdentifier;
    });

    demographics.forEach((demographic: DemographicsType, index: number) => {
      Object.keys(demographic).forEach(demo => {
        if (demo === 'demographicDistance') {
          demographicValues[demo][index] = demographic[demo] === null ? '' : demographic[demo].id;
        } else {
          demographicValues[demo][index] = demographic[demo] === null ? '' : demographic[demo];
        }
      });
    });

    return demographicValues;
  };

  const fetchDemographicDistances = async () => {
    const response = await api.fetch(routes.api.admin.demographicDistance);
    const { demographicDistances } = await response.json();
    if (response.ok) {
      setFormattedDemographicDistances(
        // this converts demographic distance from GET
        // to Select dropdown format
        demographicDistances.map((distance: DemographicDistance) => ({
          label: distance.radius.toString(),
          value: distance.id,
        })),
      );
    }
  };

  useEffect(() => {
    fetchDemographicDistances();
  }, [building]);

  const validateDistanceIsUnique = (distances: string[]) => {
    const distanceCount = {};
    let isUnique = true;

    distances.forEach((dist: string) => {
      if (dist === '') return;
      if (distanceCount[dist] === undefined) {
        distanceCount[dist] = 0;
      }

      distanceCount[dist] += 1;
    });

    if (Object.values(distanceCount).includes(2)) {
      isUnique = false;
    }

    return isUnique;
  };

  const customInputComponent = (props: CustomInputElement) => {
    const [fieldName, relativeDistanceIndex] = props.name!.split('.');

    if (fieldName.toLowerCase() === 'demographicdistance') {
      const demographicDistanceValue: string =
        initialValues.demographics.demographicDistance[Number(relativeDistanceIndex)];
      return (
        <Select
          key={fieldName}
          className={s.distanceSelect}
          options={formattedDemographicDistances}
          name={props.name || ''}
          value={demographicDistanceValue}
          {...props}
        />
      );
    }
    return <Input type="number" labelText="" name={props.name || ''} {...props} />;
  };

  const initialValues = {
    ...building,
    demographics: convertDemographicsResponseToFormikObject(),
  };

  const handleSubmit = async (values: FormikDemographicValues) => {
    if (!validateDistanceIsUnique(values.demographicDistance)) {
      notification.error({ message: t('building.distanceValidationMessage') });
      return;
    }

    const demographicsRequestArray: Object[] =
      convertFormikValuesToDemographicsRequestArray(values);

    const payload = JSON.parse(
      JSON.stringify({ id: building.id, demographics: demographicsRequestArray }, (_, v) =>
        v === '' ? null : v,
      ),
    );

    await updateBuilding(payload);
  };

  return (
    <Formik enableReinitialize initialValues={initialValues.demographics} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form className={s.form}>
          <FormThemeContextProvider theme="vertical">
            <Demographics
              initialValues={initialValues}
              values={values}
              customInputComponent={customInputComponent}
              demographicsTranslatedNameMap={demographicsTranslatedNameMap}
            />
            <AdminFormSubmitter />
          </FormThemeContextProvider>
        </Form>
      )}
    </Formik>
  );
};

export default DemographicsBuildingForm;
