import { ReactNode, useRef } from 'react';
import {
  UploadApiResponse as CloudinaryResponse,
  UploadApiErrorResponse as CloudinaryErrorResponse,
} from 'cloudinary';
import api from '@shared/api';
import useEnv from '@shared/useEnv';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';

export type Props = {
  name: string;
  onLoading: (x: boolean) => void;
  onReady: (x: CloudinaryResponse) => void;
  onError: (x: string) => void;
  buttonClassName?: string;
  children?: ReactNode;
} & Omit<FieldWrapperProps, 'children'>;

const CloudinaryFileAreaInput = ({
  name,
  onLoading,
  onReady,
  onError,
  labelClass,
  containerClass,
  labelText,
  required,
  inputContainerClass,
  buttonClassName,
  children,
}: Props) => {
  const { cloudinaryCloud, cloudinaryUploadPreset } = useEnv();
  const { t } = useTranslation('');

  const uploadToCloudinary = form => async event => {
    if (!event.target.files[0]) {
      return;
    }
    onLoading(true);

    const file = event.target.files[0];

    if (file.size > 15_000_000) {
      await form.setFieldTouched(name);
      await form.setFieldError(name, t('common:formErrors.imageSizeTooLarge'));
      return;
    }

    await form.setFieldError(name, null);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', cloudinaryUploadPreset || '');

    const route = `https://api.cloudinary.com/v1_1/${cloudinaryCloud}/image/upload`;
    const response = await api.fetch(route, { method: 'POST', body: formData });

    onLoading(false);

    /**
     * Have to do the following for the onChange handler to be triggered when
     * the same file is uploaded after being removed after the first upload.
     * We do this because we have functionality that allows a file to be removed after being
     * uploaded. We don't actually remove the file(or trigger an onChange) but set it's cloudinaryId
     * to null in the higher level component that uses the file upload.
     */
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
    if (response.ok) {
      const json: CloudinaryResponse = await response.json();
      onReady(json);
    } else {
      const json: CloudinaryErrorResponse = await response.json();
      onError(json.error.message);
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickButton = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  return (
    <FieldWrapper
      {...{ name, labelClass, containerClass, labelText, required, inputContainerClass }}
    >
      <Field name={name}>
        {({ form }) => (
          <button
            data-testid="cloudinary-input-button"
            type="button"
            onClick={handleClickButton}
            className={buttonClassName}
          >
            <input
              className="hidden"
              name={name}
              data-testid={name}
              id={name}
              type="file"
              onChange={uploadToCloudinary(form)}
              ref={inputRef}
            />
            {children}
          </button>
        )}
      </Field>
    </FieldWrapper>
  );
};

export default CloudinaryFileAreaInput;
