import CustomIcon from '@components/shared/CustomIcon';
import s from './FilterPill.module.less';

export type FilterPillOptions = {
  label: string;
  filterKey: string;
  filter: string;
  value: string | undefined;
};

type FilterPillProps = FilterPillOptions & {
  onPillClick: (filterkey: string, filter: string, value: string | undefined) => void;
};

const FilterPill = ({ label, filterKey, filter, value, onPillClick }: FilterPillProps) => {
  return (
    <div data-testid={`filter-pill-${filterKey}`} className={s.filterPill}>
      <div className={s.label}>{label}</div>
      {onPillClick && (
        <button
          className={s.close}
          onClick={() => onPillClick(filterKey, filter, value)}
          type="button"
        >
          <CustomIcon
            type="close-circle"
            data-testid={`remove-filter-pill-${filterKey}`}
            className={s.icon}
          />
        </button>
      )}
    </div>
  );
};

export default FilterPill;
