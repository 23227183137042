import { CurrentUser, MultipathImage } from '@root/types';
import { useTranslation } from 'react-i18next';
import useAltText from '@root/shared/useAltText';
import PhotoDisplay from './PhotoDisplay';

type PhotoProps = {
  currentUser: CurrentUser;
  isEditable?: boolean;
  image: MultipathImage | undefined;
  className?: string;
  setCloudinaryLoading?: (loading: boolean) => void;
  handleCloudinaryFileInputError?: any;
};
export const YourPhotoDisplay = ({
  currentUser,
  isEditable = false,
  image,
  className,
  setCloudinaryLoading = () => {},
  handleCloudinaryFileInputError,
}: PhotoProps) => {
  const { t } = useTranslation('accountSettings');
  const { getAltTextForProfilePhoto } = useAltText();

  const cloudinaryTransformationOptions = {
    crop: 'thumb',
    width: '200',
    height: '200',
    gravity: 'face',
  };
  return (
    <PhotoDisplay
      className={className}
      currentUser={currentUser}
      name="tourbookAvatarCloudinaryId"
      label={t('yourPhoto')}
      altText={getAltTextForProfilePhoto(currentUser?.firstName, currentUser?.lastName)}
      image={image}
      cloudinaryTransformationOptions={cloudinaryTransformationOptions}
      isEditable={isEditable}
      setCloudinaryLoading={setCloudinaryLoading}
      handleCloudinaryFileInputError={handleCloudinaryFileInputError}
    />
  );
};

export const CompanyLogoDisplay = ({
  currentUser,
  isEditable = false,
  image,
  className,
  setCloudinaryLoading = () => {},
  handleCloudinaryFileInputError,
}: PhotoProps) => {
  const { t } = useTranslation('accountSettings');
  const { getAltTextForCompanyLogo } = useAltText();

  const cloudinaryTransformationOptions = {
    crop: 'pad',
    width: '200',
    height: '200',
  };
  return (
    <PhotoDisplay
      className={className}
      currentUser={currentUser}
      name="tourbookCompanyLogoCloudinaryId"
      label={t('companyLogo')}
      altText={getAltTextForCompanyLogo()}
      image={image}
      cloudinaryTransformationOptions={cloudinaryTransformationOptions}
      isEditable={isEditable}
      setCloudinaryLoading={setCloudinaryLoading}
      handleCloudinaryFileInputError={handleCloudinaryFileInputError}
    />
  );
};

export default {
  YourPhotoDisplay,
  CompanyLogoDisplay,
};
