import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import useEnv from '@shared/useEnv';
import api from '@shared/api';
import get from 'lodash/get';
import Button, { ButtonSize } from '@components/shared/V2Button';
import { MIME_TYPES } from '@components/shared/Admin/MultimediaUploader';
import CustomIcon, { ValidIconTypes } from '@components/shared/CustomIcon';
import { useFlags } from 'launchdarkly-react-client-sdk';

/* eslint-disable camelcase */
export type DefaultCloudinaryResponseType = {
  public_id: string;
  original_filename: string;
  format: string;
  created_at: string;
};
/* eslint-enable camelcase */

export type DefaultVideoUploadType = {
  cloudinaryId: string;
  filename: string;
  processedAt: string;
};

type Props<CloudinaryResponseType, VideoUploadType> = {
  marketplaceRequest: (
    file: VideoUploadType | DefaultVideoUploadType,
  ) => PromiseLike<{ ok: boolean }>;
  cloudinaryTransformer?: (
    json: CloudinaryResponseType | DefaultCloudinaryResponseType,
  ) => VideoUploadType | DefaultVideoUploadType;
  multiple?: boolean;
  accept?: string;
  onSuccess?: () => void;
  onError?: () => void;
  buttonText: string;
  buttonIcon?: ValidIconTypes;
  buttonSize?: ButtonSize;
  buttonClass?: string;
  maxFileSize?: number;
};

const pluckFileAttributesFromCloudinary = (
  cloudinaryJson: DefaultCloudinaryResponseType,
): DefaultVideoUploadType => {
  /* eslint-disable camelcase */
  const { public_id, original_filename, format, created_at } = cloudinaryJson;
  return {
    cloudinaryId: public_id,
    filename: `${original_filename}.${format}`,
    processedAt: created_at,
  };
  /* eslint-enable camelcase */
};

const noop = () => {};

const MultiVideoUploader = <CloudinaryResponseType, VideoUploadType>({
  marketplaceRequest,
  // @ts-expect-error
  cloudinaryTransformer = pluckFileAttributesFromCloudinary,
  onSuccess = noop,
  onError = noop,
  multiple = false,
  accept = MIME_TYPES.videoOnly,
  buttonText,
  buttonIcon = undefined,
  buttonSize = undefined,
  buttonClass = '',
  maxFileSize = 300000000, // 300MB,
}: Props<CloudinaryResponseType, VideoUploadType>) => {
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { cloudinaryCloud, cloudinaryUploadPreset } = useEnv();
  const [isUploading, setIsUploading] = useState(false);
  const [percentUploaded, setPercentUploaded] = useState(0);

  const fileInput = useRef<HTMLInputElement>(null);

  const resetUploadState = () => {
    setIsUploading(false);
    setPercentUploaded(0);
    if (fileInput.current?.value) fileInput.current.value = '';
  };

  const defaultOnSuccess = () => {
    onSuccess();
    resetUploadState();
    notification.success({
      message: t('media.videoUploadSuccess'),
    });
  };

  const defaultOnError = () => {
    onError();
    resetUploadState();
    notification.error({
      message: t('media.videoUploadFailure'),
    });
  };

  const uploadToMarketplace = async uploadedFile => {
    const marketplaceResponse = await marketplaceRequest(uploadedFile);
    if (marketplaceResponse.ok) {
      defaultOnSuccess();
    } else {
      defaultOnError();
    }
  };

  const getSlice = (file: any, start: number, end: number) => {
    let slice = noop;

    if (file.mozSlice) {
      slice = file.mozSlice;
    } else if (file.webkitSlice) {
      slice = file.webkitSlice;
    } else if (file.slice) {
      slice = file.slice;
    }

    // @ts-expect-error
    return slice.bind(file)(start, end);
  };

  const send = (
    piece: Blob | void,
    start: number,
    end: number,
    size: number,
    uploadId: number,
    name: string,
  ) => {
    const formData = new FormData();
    formData.append('file', piece as unknown as Blob, name);
    formData.append('upload_preset', cloudinaryUploadPreset || '');

    return api.fetch(`https://api.cloudinary.com/v1_1/${cloudinaryCloud}/auto/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        'X-Unique-Upload-Id': uploadId,
        'Content-Range': `bytes ${start}-${end}/${size}`,
      },
    });
  };

  const uploadVideoInSlices = (file: File) => {
    const sliceSize = 6000000;
    const { size } = file;
    const XUniqueUploadId = +new Date();
    let start = 0;

    const loop = async () => {
      let end = start + sliceSize;

      if (end > size) {
        end = size;
      }
      const slice = getSlice(file, start, end);

      const response = await send(slice, start, end - 1, size, XUniqueUploadId, file.name);
      const cloudinaryJson = await response.json();
      setPercentUploaded(Math.trunc((end / size) * 100));

      if (end < size) {
        start += sliceSize;
        setTimeout(loop, 3);
      } else if (response.ok) {
        uploadToMarketplace(cloudinaryTransformer(cloudinaryJson));
      } else {
        defaultOnError();
      }
    };

    setTimeout(loop, 3);
  };

  const handleVideoUpload = (attachments: any) => {
    setIsUploading(true);

    attachments.forEach(async (attachment: any) => {
      if (flags['market.max-video-size'] && attachment.size > maxFileSize) {
        onError();
        resetUploadState();
        notification.error({
          message: t('media.videoUploadFailure'),
          description: t('media.videoUploadTooLargeMessage', {
            maxFileSize: maxFileSize / 1000000,
          }),
        });
      } else {
        uploadVideoInSlices(attachment);
      }
    });
  };

  const handleOnChange = (e: any) => {
    const currentTarget = e.currentTarget ? e.currentTarget : e.srcElement;
    const attachments = Array.from(get(currentTarget, 'files'));

    handleVideoUpload(attachments);
  };

  const handleDrop = (e: any) => {
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const attachments = Array.from(e.dataTransfer.files);

      handleVideoUpload(attachments);
    }
  };

  return (
    <div onDrop={handleDrop}>
      <Button onClick={() => fileInput?.current?.click()} size={buttonSize} className={buttonClass}>
        <div>
          {buttonIcon ? <CustomIcon type={buttonIcon}></CustomIcon> : null}
          {isUploading ? t('media.uploadProgress', { percentage: percentUploaded }) : buttonText}
        </div>
        <input
          ref={fileInput}
          type="file"
          multiple={multiple}
          style={{
            position: 'absolute',
            width: 1,
            height: 1,
            top: -500,
          }}
          onChange={handleOnChange}
          accept={accept}
        />
      </Button>
    </div>
  );
};

export default MultiVideoUploader;
