import * as React from 'react';
import cn from 'classnames';
import { Address, Room as IRoom } from '@root/types';
import { CustomIcon } from '@components/shared';
import useAltText from '@root/shared/useAltText';
import Amenities from './Amenities';
import s from './Room.module.less';

type Props = {
  room: IRoom;
  onClick?: React.EventHandler<React.SyntheticEvent>;
  address: Address;
};

const Contents = ({ room, onClick, address, children }: Props & { children?: React.ReactNode }) => {
  const { getAltText } = useAltText({ address });
  return (
    <>
      <button type="button" className={cn(onClick && s.roomImageButton)} onClick={onClick}>
        <figure className={s.imageContainer}>
          <img
            className={s.image}
            src={room.photos[0].smallPath}
            alt={getAltText({
              type: room.photos[0].type,
              options: { roomName: room.photos[0].description },
            })}
          />
          {room.capacity ? (
            <div className={s.chip}>
              <CustomIcon type="person" className={s.chipIcon} />
              {room.capacity}
            </div>
          ) : null}
        </figure>
      </button>
      <p className={s.roomNameText}>{room.name}</p>
      {children}
    </>
  );
};

const Room = ({ room, address, onClick }: Props) => {
  return (
    <div data-testid="room" className={s.container}>
      <section className={s.contents}>
        <Contents address={address} room={room} onClick={onClick} />
      </section>
      {/* this is the hovered version */}
      <aside className={cn(s.hoveredContents)}>
        <Contents address={address} room={room} onClick={onClick}>
          <Amenities amenities={room.amenities} />
        </Contents>
      </aside>
    </div>
  );
};

export default Room;
