import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormikContext } from 'formik';
import { countryToCurrencyCode } from '@root/shared/currencyByCountry';
import { CurrencyInput, InputRange, RadioInput, Toggle } from '@components/shared/forms';
import cn from 'classnames';
import { StoreState } from '@root/types';
import s from './TourbookExternalListing.module.less';
import { FormValues } from './Form';
import {
  formatAskingRentInMoneyPerAreaPerPeriod,
  formatAskingRentInMoneyPerPeriod,
  getDisplayPrice,
  getDisplayPricePerArea,
} from '../Tourbook/helper';

const AskingRentField = () => {
  const { errors, values } = useFormikContext<FormValues>();
  const { t } = useTranslation('tourbook');
  const flags = useFlags();
  const currentUser = useSelector((state: StoreState) => state.currentUser);

  const currency = countryToCurrencyCode(values.countryCode);

  const formattedAskingRentUnitsWithoutCurrency = (): string =>
    values.askingRentUnitsWithoutCurrency === 'Mo' ? '/ mo' : 'sf / yr';

  const oppositeUnits = (): ReactNode => {
    if ((!values.minAskingRent && !values.maxAskingRent) || !values.size || values.size <= 0)
      return null;

    let convertedAskingRent: string | null;
    const minAskingRentInMoneyPerAreaPerPeriod = formatAskingRentInMoneyPerAreaPerPeriod(
      // If we are on range mode, and if there is maxAskingRent, treat minAskingRent
      // as 0 so we still display range.
      values.askingRentUseRange && values.maxAskingRent
        ? values.minAskingRent || 0
        : values.minAskingRent,
      values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
      values.size,
      currency,
    );

    const minAskingRentInMoneyPerPeriod = formatAskingRentInMoneyPerPeriod(
      // If we are on range mode, and if there is maxAskingRent, treat minAskingRent
      // as 0 so we still display range.
      values.askingRentUseRange && values.maxAskingRent
        ? values.minAskingRent || 0
        : values.minAskingRent,
      values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
      values.size,
      currency,
    );
    const maxAskingRentInMoneyPerAreaPerPeriod = values.askingRentUseRange
      ? formatAskingRentInMoneyPerAreaPerPeriod(
          values.maxAskingRent,
          values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
          values.size,
          currency,
        )
      : null;

    const maxAskingRentInMoneyPerPeriod = values.askingRentUseRange
      ? formatAskingRentInMoneyPerPeriod(
          values.maxAskingRent,
          values.askingRentUnitsWithoutCurrency as 'sf/yr' | 'Mo',
          values.size,
          currency,
        )
      : null;

    if (values.askingRentUnitsWithoutCurrency === 'Mo') {
      convertedAskingRent = getDisplayPricePerArea({
        t,
        // TODO: remove `askingRent` when 'external-listing-asking-range' is removed
        askingRent: minAskingRentInMoneyPerAreaPerPeriod,
        minAskingRent: minAskingRentInMoneyPerAreaPerPeriod,
        maxAskingRent: maxAskingRentInMoneyPerAreaPerPeriod,
        acceptRange: flags['external-listing-asking-range'],
      });
    } else {
      convertedAskingRent = getDisplayPrice({
        t,
        // TODO: remove `askingRent` when 'external-listing-asking-range' is removed
        askingRent: minAskingRentInMoneyPerPeriod,
        minAskingRent: minAskingRentInMoneyPerPeriod,
        maxAskingRent: maxAskingRentInMoneyPerPeriod,
        acceptRange: flags['external-listing-asking-range'],
      });
    }

    return <span className={cn(s.convertedAskingRent)}>{convertedAskingRent}</span>;
  };

  return (
    <div className="mb-2">
      {values.askingRentUseRange && flags['external-listing-asking-rent-range'] ? (
        <>
          <InputRange
            name="minMaxAskingRent"
            errorMessage={errors.minAskingRent}
            containerClass="!pt-0 !pb-0"
            labelText={t('externalListing.rentLabel')}
            renderFirstInput={props => (
              <CurrencyInput
                {...props}
                name="minAskingRent"
                placeholder={t('listingCard.askingRent.min')}
                decimal={false}
                containerClass="!mb-0"
                inputContainerClass="!border-none !p-0 text-center"
                inputClass="!pl-4"
                displayError={false}
              />
            )}
            renderSecondInput={props => (
              <CurrencyInput
                {...props}
                name="maxAskingRent"
                placeholder={t('listingCard.askingRent.max')}
                decimal={false}
                displayUnit={formattedAskingRentUnitsWithoutCurrency()}
                showCurrencyUnit={false}
                containerClass={`!mb-0  ${
                  values.askingRentUnitsWithoutCurrency === 'Mo' ? 'w-[140px]' : 'w-12'
                }`}
                inputContainerClass="!border-none !p-0 text-center gap-[4px]"
                inputClass="!p-0 !text-left ml-1"
                unitsClass="!mt-0 !mr-0"
              />
            )}
          />
        </>
      ) : (
        <CurrencyInput
          name="minAskingRent"
          labelText={t('externalListing.rentLabel')}
          labelClass="!text-black-055"
          autoComplete={false}
          currency={currency}
          locale={currentUser?.languagePreference || 'en-us'}
          displayUnit={formattedAskingRentUnitsWithoutCurrency()}
          decimal={false}
        />
      )}
      <div className="flex justify-between">
        <div className="-ml-[4px]">
          <span>
            <RadioInput
              name="askingRentUnitsWithoutCurrency"
              value="sf/yr"
              labelText="sf / yr"
              id="asking-rent-sf/yr"
            />
          </span>
          <span>
            <RadioInput
              name="askingRentUnitsWithoutCurrency"
              value="Mo"
              labelText="mo"
              id="asking-rent-mo"
            />
          </span>
        </div>
        <div>
          <span className="text-sm text-black-055">
            {(values.minAskingRent || values.maxAskingRent) && values.size ? oppositeUnits() : null}
          </span>
        </div>
      </div>
      {flags['external-listing-asking-rent-range'] ? (
        <div className="mt-1 flex items-center gap-1">
          <Toggle name="askingRentUseRange" size="large" />
          <span className="text-md text-black-100">{t('listingCard.askingRent.enterRange')}</span>
        </div>
      ) : null}
    </div>
  );
};

export default AskingRentField;
