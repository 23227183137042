import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import CustomIcon from '@components/shared/CustomIcon';

const getTranslationArgument = (value: boolean | string | number): { count?: number } => {
  if (typeof value === 'number' && value > 0) {
    return { count: value };
  }

  return {};
};

const getTranslationKey = (name: string, value: boolean | string | number): string => {
  if (typeof value === 'string') {
    return `amenities.${name}.${value}`;
  }

  if (name === 'furnished' && !value) {
    return `amenities.furnished.no`;
  }

  return `amenities.${name}`;
};

type Props = {
  name: string;
  value: boolean | string | number;
  amenityClassName?: string;
};

const Amenity = ({ name, value, amenityClassName }: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className={cn('mb-3 flex', amenityClassName)}>
      <div className="mr-2">
        <CustomIcon data-testid="amenityCheckmark" type="checkmark" className="!text-aqua-110" />
      </div>
      <div className="font-body-large">
        {t(getTranslationKey(name, value), getTranslationArgument(value))}
      </div>
    </div>
  );
};

export default Amenity;
