import { Address, MultipathImage } from '@root/types';

type Brochure = {
  name: string;
  count: number;
};

export type ListingEngagement = {
  id: string;
  address: Address;
  heroImage: MultipathImage;
  submarket: string | null;
  totalMediaViews: number;
  numFloorPlanViews: number;
  numImageViews: number;
  numVideoViews: number;
  totalActivity: number;
  numPreviewViews: number;
  numFullDetailViews: number;
  numTourbookListingViews: number;
  numTimesSaved: number;
  numTimesAddedToTourbook: number;
  numImageDownloads: number;
  numFloorPlanDownloads: number;
  totalDownloads: number;
  brochures: Brochure[];
};

export type ListingEngagementOverTime = {
  listingId: string;
  address: Address;
  engagementOverTime: Array<{ date: string; count: number }>;
  totalEngagement: number;
};

export type TourbookAnalyticsOverTimeResponse = {
  tourbookId: string;
  listingEngagementOverTime: ListingEngagementOverTime[];
};

export type TourbookAnalyticsResponse = {
  id: string;
  name: string;
  totalViews: number;
  listingViews: number;
  galleryViews: number;
  numDuplications: number;
  listingEngagement: ListingEngagement[];
  tourbookMetadata: {
    shareUrl: string;
    analyticsSupport: boolean;
    firstSharedAt: string | null;
  };
  relatedListingIds: string[];
};

export const CHART_COLORS = [
  '#4837B9',
  '#887EFC',
  '#CCCCFF',
  '#247F7E',
  '#60D2D2',
  '#BFEDED',
  '#951AA1',
  '#DB65E6',
  '#EFC7F5',
  '#280F96',
  '#6253DA',
  '#1C6363',
  '#009696',
  '#6E1377',
  '#AF1EBE',
  '#130A76',
  '#124040',
  '#450C4B',
  '#096290',
  '#61C5FF',
  '#B6DFF7',
  '#39800B',
  '#76C643',
  '#D1ECC0',
  '#CA6902',
  '#FFB200',
  '#FFE8B3',
  '#98291A',
  '#F65F50',
  '#FDB6B0',
  '#475566',
  '#919DAC',
  '#0B4465',
  '#1684C4',
  '#427D1B',
  '#A3D982',
  '#933D00',
  '#E17605',
  '#6D1D13',
  '#C7200A',
  '#2F3946',
  '#6C7989',
  '#053148',
  '#1E3E09',
  '#662A00',
  '#380F0A',
  '#171D26',
];
