import api from '@shared/api';
import routes from '@root/routes';
import { LastInquiry, QuickInquiryFormFields } from '@root/types';

type ReceiveCreatedInquiry = {
  type: 'RECEIVE_CREATED_INQUIRY';
  payload: LastInquiry;
};
export const receiveCreatedInquiry = (lastInquiry: LastInquiry): ReceiveCreatedInquiry => ({
  type: 'RECEIVE_CREATED_INQUIRY',
  payload: lastInquiry,
});

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};

// TODO: As part of react query migration - Replace usages of with useCreateInquiryMutation from
// 'app/javascript/shared/useCreateInquiryMutation.tsx'
export const createQuickInquiry =
  ({
    body,
    listingId,
    onSuccess,
    onFailure,
  }: {
    listingId: string;
    body?: QuickInquiryFormFields;
    onSuccess: () => void;
    onFailure: () => void;
  }) =>
  dispatch => {
    return api
      .post(routes.api.inquiries.quick(listingId), body)
      .then(handleErrors)
      .then(res => res.json())
      .then((lastInquiry: LastInquiry) => {
        dispatch(receiveCreatedInquiry(lastInquiry));
        onSuccess();
      })
      .catch(onFailure);
  };

export type Actions = ReceiveCreatedInquiry;
