type Props = { name: string };

const Initials = ({ name }: Props) => (
  <span title={name}>
    {name
      .split(' ')
      .map(namePart => namePart[0])
      .join('')}
  </span>
);

export default Initials;
