import * as React from 'react';
import { Card } from 'antd';

type Props = {
  /**
   * The Header Text, with it, no Header
   */
  title?: string;
  /**
   * The Component that goes on the upper right in the header, usually a button
   */
  extra?: React.ReactNode;
  children: React.ReactNode;
  extraPadding?: boolean;
};

const headStyleWithPadding = {
  paddingTop: '5px',
  paddingBottom: '5px',
};

const Container = ({ title, extra, children, extraPadding = false }: Props) => (
  <Card title={title} extra={extra} headStyle={extraPadding ? headStyleWithPadding : {}}>
    {children}
  </Card>
);

export default Container;
