import * as React from 'react';
import { useField } from 'formik';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';
import RawDateInput from '../RawDateInput';

type Props = {
  className?: string;
  disabled?: boolean;
  autoComplete?: string;
  placeholder?: string;
  children?: React.ReactNode;
} & Omit<FieldWrapperProps, 'children'>;

export default function DateInput({
  className,
  description,
  disabled = false,
  name,
  labelClass,
  containerClass,
  labelText,
  placeholder,
  required,
  children,
  autoComplete,
}: Props) {
  const [field, meta, helpers] = useField({ name });
  return (
    <FieldWrapper {...{ description, name, labelClass, containerClass, labelText, required }}>
      <RawDateInput
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
        onChange={newDate => {
          helpers.setValue(newDate, true);
        }}
        hasError={!!meta.touched && !!meta.error}
        name={name}
        autoComplete={autoComplete}
      />
      {children}
    </FieldWrapper>
  );
}
