import { useTranslation } from 'react-i18next';
import { Select } from '@components/shared/forms/Dropdowns';
import css from './styles.module.less';

type Props = {
  containerClass?: string;
  labelText?: string;
  name: string;
  value: boolean | string;
};

const YesNoMaybe = ({ containerClass, labelText, name, value }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Select
      data-testid={name}
      className={css.select}
      containerClass={containerClass}
      isClearable
      labelText={labelText}
      name={name}
      options={[
        {
          label: t('yes'),
          value: true,
        },
        {
          label: t('no'),
          value: false,
        },
      ]}
      placeholder=""
      value={value}
    />
  );
};

export default YesNoMaybe;
