import { FieldWrapper } from '@components/shared/forms';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ImageFit } from '@root/types';
import FillSpace from './FillSpace.svg';
import FitToSpace from './FitToSpace.svg';

const ImageFitSelector = ({
  selectedImageFit,
  fieldName,
  onChange,
  hideLabel = false,
}: {
  selectedImageFit: ImageFit;
  fieldName: string;
  onChange: (fitOption: ImageFit) => void;
  hideLabel?: boolean;
}) => {
  const { t } = useTranslation('admin');

  const fitOptions: ImageFit[] = ['fill_space', 'fit_to_space'];

  const componentMappings = {
    fill_space: { Component: FillSpace },
    fit_to_space: { Component: FitToSpace },
  };

  return (
    <FieldWrapper
      name="imageFit"
      labelText={hideLabel ? '' : t(`microsite.genericContentBlock.imageFit.title`)}
    >
      <div className={cn('flex gap-2', hideLabel && 'pt-2 pb-1')}>
        {fitOptions.map(fitOption => {
          const IllustrationComponent = componentMappings[fitOption].Component;

          return (
            <div key={fitOption}>
              <label
                htmlFor={`${fieldName}-${fitOption}`}
                className="flex items-center cursor-pointer gap-1"
              >
                <input
                  name={`${fieldName}-${fitOption}`}
                  type="radio"
                  id={`${fieldName}-${fitOption}`}
                  value={fitOption}
                  checked={selectedImageFit === fitOption}
                  onChange={() => onChange(fitOption)}
                />
                <IllustrationComponent className="w-[48px] h-[30px]" />
                <span className="font-body-medium">
                  {t(`microsite.genericContentBlock.imageFit.${fitOption}`)}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </FieldWrapper>
  );
};

export default ImageFitSelector;
