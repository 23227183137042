import { ReactNode } from 'react';
import classNames from 'classnames';
import s from './Label.module.less';

type Props = {
  /**
   * This is what is displayed
   */
  children: ReactNode;
  className?: string;
  /**
   * The name of the input its a label for
   */
  name?: string;
  /**
   * This controls whether there is a asterisk next to the label
   */
  required?: boolean;
  /**
   * For use only within Formik Forms
   */
  horizontal?: boolean;
  /**
   * For use only within Formik Forms
   */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label' | 'span' | 'div';
};

const RawLabel = ({
  children,
  className,
  name,
  required = false,
  horizontal = false,
  tag = 'label',
  ...props
}: Props) => {
  const Tag = tag;

  return (
    <Tag
      htmlFor={name}
      className={classNames(
        s.label,
        required && s.required,
        horizontal && s.horizontalStyle,
        className,
      )}
      {...props}
    >
      {children}
    </Tag>
  );
};

export default RawLabel;
