import { MultipathImage, PowerpointTourbook } from '@root/types';
import routes from '@root/routes';
import ListingShareUrlHelper from '@shared/listingShareUrlHelper';
import { BuildingHeaderAttributes, generateBuildingHeaderElements } from './buildingHeader';
import { h3, PowerpointElement, PowerpointTextElementProps, PptxUtils } from './globals';
import { generateFooterElements, PAGE_MARGIN } from './portrait';
import { addElementsToSlide } from './util';

// TODO -- remove deprecated old floor plan header data function
// once TRV-2640 is merged and is approved

export const oldGenerateFloorPlanHeaderData = (
  heading: string | null,
): PowerpointTextElementProps => {
  return {
    data: `${heading}`,
    textOptions: {
      ...h3,
      fontSize: 14,
    },
  };
};
export const newGenerateFloorPlanHeaderData = (
  heading: string | null,
  listingId: string,
  userId: string,
  railsEnv: string,
): PowerpointTextElementProps => {
  const hypertext = () => {
    if (railsEnv === 'production') {
      return 'https://';
    }
    return 'http://';
  };

  const encodedData = ListingShareUrlHelper.encode({
    listingId,
    userId,
    hideLocation: false,
  });

  return {
    data: `${heading}`,
    textOptions: {
      ...h3,
      fontSize: 14,
      color: '#0C63C1',
      hyperlink: {
        url: `${hypertext()}${window.location.host}${routes.listingShare(encodedData)}`,
      },
    },
  };
};
export const generateFloorPlanSlideElements = (
  floorPlan: MultipathImage,
  heading: string | null,
  listingId: string,
  userId: string,
  { cloudinary, railsEnv, flags }: PptxUtils,
  isInternalListing: boolean,
): PowerpointElement[] => {
  const shouldListingHeaderLink = isInternalListing && flags['tourbook-export-listing-link'];

  return [
    {
      type: 'textBox',
      ...(shouldListingHeaderLink
        ? newGenerateFloorPlanHeaderData(heading, listingId, userId, railsEnv)
        : oldGenerateFloorPlanHeaderData(heading)),
      placement: { x: PAGE_MARGIN, y: 1.04, w: 5.72, h: 0.33 },
    },
    {
      type: 'image',
      data: cloudinary.url(floorPlan.cloudinaryId!, {
        transformation: [{ aspectRatio: 1.09, crop: 'pad', background: 'white' }, { angle: 270 }],
      }),
      placement: { h: 7.63, w: 7.0, x: 0.28, y: 1.44 },
    },
  ];
};
export const addFloorPlanSlide = ({
  floorPlan,
  heading,
  building,
  listingId,
  userId,
  tourbook,
  utils,
  isInternalListing,
}: {
  floorPlan: MultipathImage;
  heading: string | null;
  building: BuildingHeaderAttributes;
  listingId: string;
  userId: string;
  tourbook: PowerpointTourbook;
  utils: PptxUtils;
  isInternalListing: boolean;
}) => {
  const { pptx } = utils;

  const floorPlanSlide = pptx.addSlide();
  const floorPlanElements: PowerpointElement[] = [
    ...generateBuildingHeaderElements(building, utils),
    ...generateFloorPlanSlideElements(
      floorPlan,
      heading,
      listingId,
      userId,
      utils,
      isInternalListing,
    ),
    ...generateFooterElements(tourbook, utils),
  ];

  addElementsToSlide(floorPlanSlide, floorPlanElements);
};

export const addFloorPlanToSlide = (utils: PptxUtils, slide, heading, floorPlan) => {
  const { cloudinary } = utils;

  slide.addText(
    [
      {
        text: `${heading}`,
        options: {
          ...h3,
          fontSize: 14,
        },
      },
    ],
    { x: 0.27, y: 1.04, w: 5.72, h: 0.33 },
  );

  const imageOptions = {
    path: cloudinary.url(floorPlan.cloudinaryId, {
      transformation: [{ aspectRatio: 1.09, crop: 'pad', background: 'white' }, { angle: 270 }],
    }),
    h: 7.63,
    w: 7.0,
    x: 0.28,
    y: 1.44,
  };
  slide.addImage(imageOptions);
};

export default addFloorPlanToSlide;
