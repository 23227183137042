import DataPoint from '@components/shared/DataPoint/DataPoint';
import { Listing, PartialListing } from '@root/types';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import formatCeilingHeightMeasure from '@shared/formatCeilingHeightMeasure';
import s from './ListingPreviewModal.module.less';

type Props = {
  partialListing: PartialListing;
  listing: Listing | null;
};

const monthsInAYear = 12;

const ListingInfo = ({ listing, partialListing }: Props) => {
  const {
    finishedCeilingHeightMeasure = null,
    slabToSlabCeilingHeightMeasure = null,
    capacity: listingCapacity,
  } = listing || {};

  const {
    size,
    availableToday,
    capacity: partialListingCapacity,
    dateAvailable,
    leaseType,
    spaceCondition,
    smallestPrice,
  } = partialListing;
  const capacity = listingCapacity || partialListingCapacity || null;

  const { t } = useTranslation('listing');

  const rentPerMonth = (price: number, squareFootage: number) => {
    return Math.round((price / monthsInAYear) * squareFootage);
  };

  const priceAvailable = smallestPrice && size;

  return (
    <>
      {capacity ? (
        <DataPoint
          value={t('common:listing.capacity', { capacity })}
          containerClassName={s.dataPoint}
          valueClassName={s.dataPointValue}
          label={t('estimatedCapacity')}
        />
      ) : null}
      {availableToday ? (
        <DataPoint
          value={t('common:listing.immediateAvailability')}
          label={t('available')}
          containerClassName={s.dataPoint}
          valueClassName={s.dataPointValue}
        />
      ) : (
        <DataPoint
          value={t('units:date', { date: dateAvailable })}
          label={t('available')}
          containerClassName={s.dataPoint}
          valueClassName={s.dataPointValue}
        />
      )}
      {leaseType && (
        <DataPoint
          value={leaseType}
          label={t('leaseType')}
          format={aLeaseType => t(`leaseTypes.${aLeaseType}`)}
          containerClassName={s.dataPoint}
          valueClassName={s.dataPointValue}
        />
      )}
      {spaceCondition && (
        <DataPoint
          value={t(`spaceConditions:${camelCase(spaceCondition)}`)}
          label={t('condition')}
          containerClassName={s.dataPoint}
          valueClassName={s.dataPointValue}
        />
      )}
      {finishedCeilingHeightMeasure || slabToSlabCeilingHeightMeasure ? (
        <DataPoint
          value={{
            slabToSlab: slabToSlabCeilingHeightMeasure,
            finished: finishedCeilingHeightMeasure,
          }}
          label={t('ceilingHeight')}
          format={measure => formatCeilingHeightMeasure(measure)}
          containerClassName={s.dataPoint}
          valueClassName={s.dataPointValue}
        />
      ) : null}
      {priceAvailable && (
        <DataPoint
          containerClassName={s.dataPoint}
          valueClassName={s.dataPointValue}
          value={
            <>
              <div>
                {t('units:pricePerAreaPerPeriod', {
                  pricePerAreaPerPeriod: smallestPrice,
                })}
              </div>
              <div className={s.rentPerMonth}>
                {t('units:pricePerPeriod', {
                  pricePerPeriod: {
                    ...smallestPrice,
                    magnitude: rentPerMonth(smallestPrice.magnitude, size.magnitude),
                    period: 'month',
                    abbreviatedPeriod: false,
                  },
                })}
              </div>
            </>
          }
          label="Asking Rent"
        />
      )}
    </>
  );
};

export default ListingInfo;
