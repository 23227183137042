import useEnv from '@shared/useEnv';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import api from '@shared/api';
import routes from '@root/routes';
import { ValidIconTypes } from '@components/shared/CustomIcon';
import { ButtonSize } from '@components/shared/V2Button/Button';
import MultimediaUploader, {
  MIME_TYPES,
  DefaultPhotoUploadType,
} from '@components/shared/Admin/MultimediaUploader';

type Props = {
  fetchListing: () => void;
  listingId: string;
  buttonClass?: string;
  buttonIcon?: ValidIconTypes;
  buttonSize?: ButtonSize;
};

const FloorPlanUploader = ({
  fetchListing,
  listingId,
  buttonClass = '',
  buttonIcon = undefined,
  buttonSize = undefined,
}: Props) => {
  const { cloudinaryCloud } = useEnv();
  const { t } = useTranslation('admin');

  const floorPlanUploadRequest = (uploadedFiles: DefaultPhotoUploadType[]) => {
    const uploadedFloorPlan = uploadedFiles[0];

    return api.post(routes.api.admin.floorPlanCreate, {
      listing_id: listingId,
      filename: uploadedFloorPlan.filename,
      processed_at: uploadedFloorPlan.processedAt,
      cloudinary_id: uploadedFloorPlan.cloudinaryId,
    });
  };

  const handleSuccess = () => {
    fetchListing();
    notification.success({
      message: t('media.floorPlan.uploadSuccess'),
    });
  };

  const handleError = () => {
    notification.error({
      message: t('media.floorPlan.uploadFailure'),
    });
  };

  return (
    <MultimediaUploader
      accept={MIME_TYPES.imageOnly}
      marketplaceRequest={floorPlanUploadRequest}
      cloudinaryRoute={`https://api.cloudinary.com/v1_1/${cloudinaryCloud}/image/upload`}
      multiple={false}
      onSuccess={handleSuccess}
      onError={handleError}
      buttonText={t('media.floorPlan.upload')}
      buttonIcon={buttonIcon}
      buttonSize={buttonSize}
      buttonClass={buttonClass}
      uploadSizeLimitInMb={20}
    />
  );
};

export default FloorPlanUploader;
