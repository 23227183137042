import { ReactNode } from 'react';
import { Link, useLocation, LinkProps } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import qs from 'qs';
import { omit } from 'lodash';

interface UTMAwareProps extends LinkProps {
  className?: string;
  to: string;
  onClick?: React.MouseEventHandler;
  target?: string;
  dataTestid?: string;
  dataQaTestid?: string;
  children?: ReactNode;
}

const UTMAwareLink = ({
  className,
  to,
  onClick,
  target,
  dataTestid,
  dataQaTestid,
  children,
  onMouseEnter,
  onMouseLeave,
  'aria-label': ariaLabel,
}: UTMAwareProps) => {
  const flags = useFlags();
  const { search } = useLocation();

  const utmTrackingKeys = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term', 'utm_content'];
  const vtsTrackingKeys = ['vts_campaign', 'vts_medium', 'vts_source', 'vts_term', 'vts_content'];

  const hasUTMParams = (): boolean => {
    return utmTrackingKeys.some(utmTrackingKey => search.includes(utmTrackingKey));
  };

  const hasVTSParams = (): boolean => {
    return vtsTrackingKeys.some(vtsTrackingKey => search.includes(vtsTrackingKey));
  };

  const fetchVTSParams = () => {
    const searchTerm = qs.parse(search, { ignoreQueryPrefix: true });

    return {
      vts_campaign: searchTerm.utm_campaign! || searchTerm.vts_campaign || null,
      vts_medium: searchTerm.utm_medium || searchTerm.vts_medium || null,
      vts_source: searchTerm.utm_source || searchTerm.vts_source || null,
      vts_term: searchTerm.utm_term || searchTerm.vts_term || null,
      vts_content: searchTerm.utm_content || searchTerm.vts_content || null,
    };
  };

  const fetchNonTrackingParams = (destination: string) => {
    const params = qs.parse(destination.split('?')[1], { ignoreQueryPrefix: true });
    return omit(params, [...utmTrackingKeys, ...vtsTrackingKeys]);
  };

  const destinationURL = (destination: string): string => {
    if (hasUTMParams() || hasVTSParams()) {
      const href = destination.split('?')[0];
      const vtsParams = fetchVTSParams();
      const nonTrackingParams = fetchNonTrackingParams(destination);

      return `${href}${qs.stringify(
        { ...nonTrackingParams, ...vtsParams },
        {
          skipNulls: true,
          addQueryPrefix: true,
        },
      )}`;
    }
    return destination;
  };

  const toURL = flags['vts-trackable-parameters'] ? destinationURL(to) : to;

  return (
    <Link
      to={toURL}
      target={target}
      data-testid={dataTestid}
      data-qa-testid={dataQaTestid}
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  );
};

export default UTMAwareLink;
