import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import s from './Navigation.module.less';

type Props = {
  basicInfoRef: React.ForwardedRef<HTMLHeadingElement>;
  structureRef: React.ForwardedRef<HTMLHeadingElement>;
  utilitiesRef: React.ForwardedRef<HTMLHeadingElement>;
  windowsRef: React.ForwardedRef<HTMLHeadingElement>;
  amenitiesRef: React.ForwardedRef<HTMLHeadingElement>;
  furnitureRef: React.ForwardedRef<HTMLHeadingElement>;
  kitchenRef: React.ForwardedRef<HTMLHeadingElement>;
  bathroomRef: React.ForwardedRef<HTMLHeadingElement>;
};

const Navigation = ({
  basicInfoRef,
  structureRef,
  utilitiesRef,
  windowsRef,
  amenitiesRef,
  furnitureRef,
  kitchenRef,
  bathroomRef,
}: Props) => {
  const { t } = useTranslation('admin');

  const navigateToSection = ref => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={s.navigation}>
      <span
        role="button"
        onClick={() => navigateToSection(basicInfoRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.basicInfo.title')}
      </span>
      <span
        role="button"
        onClick={() => navigateToSection(structureRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.structure.title')}
      </span>
      <span
        role="button"
        onClick={() => navigateToSection(utilitiesRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.utilities.title')}
      </span>
      <span
        role="button"
        onClick={() => navigateToSection(windowsRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.windows.title')}
      </span>
      <span
        role="button"
        onClick={() => navigateToSection(amenitiesRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.amenities.title')}
      </span>
      <span
        role="button"
        onClick={() => navigateToSection(furnitureRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.furniture.title')}
      </span>
      <span
        role="button"
        onClick={() => navigateToSection(kitchenRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.kitchen.title')}
      </span>
      <span
        role="button"
        onClick={() => navigateToSection(bathroomRef)}
        className={classNames(s.sectionTitle, Typography.bodyMedium)}
      >
        {t('listing.rooms.form.bathroom.title')}
      </span>
    </div>
  );
};

export default Navigation;
