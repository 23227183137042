import cn from 'classnames';
import { isNull, isEmpty } from 'lodash';
import { flow, uniq, flatMap } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import useEnv from '@shared/useEnv';
import NySubwayLine from '@components/shared/NySubwayLine';
import { Address, NearbyStop } from '@root/types';
import googleMapsStaticImageUrlGenerator from '@shared/googleMapsStaticImageUrlGenerator';
import useAltText from '@root/shared/useAltText';
import s from './StaticMapImage.module.less';

const getLinesFrom = (transitOptions: NearbyStop[]) =>
  flow(
    flatMap((stop: NearbyStop) => stop.lines),
    uniq,
  )(transitOptions);

type Props = {
  className: string;
  onScrollToLocation: () => void;
  transitOptions: NearbyStop[];
  buildingAddress: Address;
};

const StaticMapImage = ({
  className,
  onScrollToLocation,
  transitOptions = [],
  buildingAddress,
}: Props) => {
  const { t } = useTranslation('building');
  const { googleMapsKey: key } = useEnv();
  const { getAltText } = useAltText({ address: buildingAddress });
  if (isNull(key)) return null;
  return (
    <div className={cn(className, s.container)}>
      <button type="button" className={s.text} onClick={onScrollToLocation}>
        <figure className={s.map}>
          <img
            data-testid="mapPreview"
            alt={getAltText({ type: 'staticMap' })}
            title="building-page-map-preview"
            src={googleMapsStaticImageUrlGenerator(
              key,
              t('common:fullAddress', { address: buildingAddress }),
            )}
          />
        </figure>
        {!isEmpty(transitOptions) ? (
          <div className={s.transitOptions}>
            {getLinesFrom(transitOptions).map(line => (
              <NySubwayLine key={line} line={line} />
            ))}
          </div>
        ) : null}
        <div className={s.details}>{t('seeLocationDetails')}</div>
      </button>
    </div>
  );
};

export default StaticMapImage;
