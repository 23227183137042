import cn from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import * as Yup from 'yup';
import routes from '@root/routes';
import { Listing } from '@root/types';
import { Card, CustomIcon, Notification, OnlyInDesktopTablet } from '@components/shared';
import {
  Form,
  PhoneNumberInput,
  ReCAPTCHA,
  SubmitButton,
  TextAreaAutosize,
  TextInput,
  NumberInput,
} from '@components/shared/forms';
import useAnalytics, { InquirySourceContent, InquirySourcePage } from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import { create, ResponseError } from '@shared/typedApi';
import useEnv from '@shared/useEnv';
import useLoginUrl from '@shared/useLoginUrl';
import { useMutation } from '@tanstack/react-query';
import s from './InquiryForm.module.less';
import useSuccessCard from './useSuccessCard';

type Props = {
  listing: Listing;
  sourceContent: InquirySourceContent;
  sourcePage?: InquirySourcePage;
  isMarketShare?: Boolean;
  isDialog?: Boolean;
  closeDialog?: () => void;
  contactListIsShown: boolean;
};

export default function SiteVisitorInquiryForm({
  listing,
  sourceContent,
  sourcePage,
  isMarketShare,
  isDialog,
  closeDialog,
  contactListIsShown,
}: Props) {
  const { t } = useTranslation('common');
  const { locale } = useEnv();
  const { SuccessCard, successCardProps, showSuccessCard, shouldShowSuccessCard } = useSuccessCard({
    isOnPreview: false,
  });
  const { inquiryInteraction } = useAnalytics();
  const { loginUrl } = useLoginUrl();

  const validationSchema = Yup.object({
    receiverIds: Yup.array().min(1, t('inquiry.error.requiredTo')),
    message: Yup.string().required(t('inquiry.error.requiredMessage')),
    firstName: Yup.string().required(t('inquiry.error.requiredFirstName')),
    lastName: Yup.string().required(t('inquiry.error.requiredLastName')),
    email: Yup.string()
      .email(t('inquiry.error.validEmail'))
      .required(t('inquiry.error.requiredEmail')),
    company: Yup.string().required(t('inquiry.error.requiredCompanyName')),
    phone: Yup.string().required(t('inquiry.error.requiredPhoneNumber')),
    extension: Yup.string(),
    recaptcha: Yup.string().nullable().required(t('inquiry.error.requiredRecaptcha')),
  });
  type FormValues = Yup.InferType<typeof validationSchema>;

  const inquirySuccess = (values: FormValues) => {
    if (listing) {
      inquiryInteraction({
        action: PARAMETERS.inquiry.send,
        actionType: 'INQUIRY_SEND_MESSAGE',
        listing,
        sourcePage,
        sourceContent,
        otherAttributes: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          isMarketShare,
        },
      });
    }
    if (!isDialog) {
      showSuccessCard();
    }
    Notification.info({
      title: t('inquiry:sentInquiryTitle'),
      text: t('inquiry:sentInquiryBody'),
    });
  };

  const inquiryError = (values: FormValues, responseBody: any) => {
    if (listing) {
      inquiryInteraction({
        action: PARAMETERS.inquiry.error,
        actionType: 'INQUIRY_ERROR',
        listing,
        sourcePage,
        sourceContent,
        otherAttributes: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          isMarketShare,
        },
      });
    }

    window?.grecaptcha?.reset();
    const flaggedLink = responseBody?.flaggedLink;
    Notification.error({
      title: t('inquiry.failureNotification.title'),
      text: t(`inquiry.failureNotification.${flaggedLink ? 'flaggedLinkBody' : 'body'}`),
    });
  };

  const sendInquiryMutation = useMutation(
    async ({ values }: { values: FormValues }) =>
      create(routes.api.inquiries.siteVisitor(listing.id), {
        ...values,
        separateEmailsToEnsurePrivacy: !contactListIsShown,
      }),
    {
      onSuccess: async (_, { values }) => {
        if (isDialog) {
          closeDialog?.();
        }
        inquirySuccess(values);
      },
      onError: async (error: ResponseError, { values }) => {
        inquiryError(values, error?.body);
      },
    },
  );

  return shouldShowSuccessCard ? (
    <SuccessCard {...successCardProps} />
  ) : (
    <Form<FormValues>
      data-testid="siteVisitorInquiryForm"
      id="siteVisitorInquiryForm"
      initialValues={{
        receiverIds: [listing.listingAgents[0].id],
        message: t('inquiry.siteVisitorDefaultMessage'),
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        phone: '',
        extension: '',
        recaptcha: '',
      }}
      validationSchema={validationSchema}
      onSubmit={values => sendInquiryMutation.mutate({ values })}
    >
      <Card
        title={t('inquiry.title')}
        className={cn('mobile:!border-0 mobile:!bg-white', s.largeCard)}
        titleClassName={cn(
          '!text-text-primary-inverse !text-left !py-[10px] !px-2 rounded-t-[2px] !w-full',
          s.gradientBackground,
        )}
        contentClassName={cn(
          'mobile:max-h-[calc(100vh_-_89px)] mobile:overflow-y-scroll',
          s.largeCardContent,
        )}
        headerContainerClassName="relative !p-0"
        headerElement={
          isDialog ? (
            <button
              title="Close Overlay"
              onClick={closeDialog}
              type="button"
              className="absolute right-2"
              data-qa-testid="send-inquiry"
            >
              <CustomIcon type="close" className="!text-white" />
            </button>
          ) : null
        }
      >
        <div className={s.largeMainContent}>
          <div className="flex w-full gap-1">
            <TextInput
              labelClass="!mb-0"
              name="firstName"
              containerClass="w-full !mb-1"
              labelText={t('inquiry.firstName.label')}
              placeholder={t('inquiry.firstName.placeholder')}
              required
            />
            <TextInput
              labelClass="!mb-0"
              name="lastName"
              containerClass="w-full !mb-1"
              labelText={t('inquiry.lastName.label')}
              placeholder={t('inquiry.lastName.placeholder')}
              required
            />
          </div>
          <TextInput
            labelClass="!mb-0"
            name="email"
            containerClass="!mb-1"
            labelText={t('inquiry.email.label')}
            placeholder={t('inquiry.email.placeholder')}
            required
          />
          <TextInput
            labelClass="!mb-0"
            name="company"
            containerClass="!mb-1"
            labelText={t('inquiry.company.label')}
            required
          />
          <div className="flex w-full gap-1">
            <PhoneNumberInput
              labelClass="!mb-0"
              name="phone"
              inputClass="!h-[38px] !rounded-[3px]"
              containerClass="w-2/3"
              labelText={t('inquiry.phoneNumber.label')}
              placeholder={t('inquiry.phoneNumber.placeholder')}
              locale={locale}
              required
            />
            <NumberInput
              labelClass="!mb-0"
              name="extension"
              containerClass={cn(s.hideIncrementArrows, 'w-1/3 !mb-1')}
              labelText={t('inquiry.extension.label')}
              disableIncrement
            />
          </div>
          <TextAreaAutosize
            labelText={t('inquiry.messageLabel')}
            name="message"
            required
            labelClass={cn(s.label, '!mb-0')}
            minRows={3}
            className={s.messageTextArea}
            containerClass={cn(s.fieldContainerClass)}
          />
          <ReCAPTCHA name="recaptcha" className="w-full" containerClass="mt-2" />
          <div className="mb-2 flex flex-wrap items-center gap-1">
            <SubmitButton
              size="medium"
              icon="envelopeOutline"
              className={cn('!m-0', s.submitButton)}
              submittingText={t('inquiry.sendInquiry')}
            >
              {t('inquiry.sendInquiry')}
            </SubmitButton>
            <OnlyInDesktopTablet>
              <div className="min-w-fit text-md text-black-055">
                {/* eslint-disable react/jsx-no-literals */}
                <Trans ns="homepage" i18nKey="siteVisitorModal.alreadyHaveAccount">
                  Already have an account? <a href={loginUrl}>Log in</a>
                </Trans>
                {/* eslint-enable react/jsx-no-literals */}
              </div>
            </OnlyInDesktopTablet>
          </div>
        </div>
      </Card>
    </Form>
  );
}
