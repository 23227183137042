import { TextInput } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import css from '@components/layouts/Admin/BuildingPage/Form/styles.module.less';
import buttonCSS from '@components/shared/V2Button/Button.module.less';
import Button from '@components/shared/V2Button';
import { Close } from '@components/shared/CustomIcon/icons';
import s from './BuildingManagement.module.less';
import FileInput from '../FileInput/FileInputMedia';

const BuildingManagementForm = ({
  data,
  onUpload,
  onPhotoDelete,
  onDragOver,
  onDrop,
  onDragStart,
  disableButton,
  addNewForm,
  showCancelUploadMediaButton,
  onDelete,
}: {
  data: any;
  onUpload: (event: React.ChangeEvent<HTMLInputElement>, contactOrder: number) => void;
  onPhotoDelete: (contactOrder: number) => void;
  onDragOver?: (event, index) => void;
  onDrop?: (event, index) => void;
  onDragStart?: (item) => void;
  disableButton: boolean;
  addNewForm?: () => void;
  showCancelUploadMediaButton?: boolean;
  onDelete: (photoOrder: number) => void;
}) => {
  const { t } = useTranslation('admin');

  return (
    <div className={s.mediaContainer}>
      {data.map((item, index) => (
        <div
          key={`building-management-${item.order}`}
          onDragOver={event => onDragOver?.(event, index)}
          onDrop={event => onDrop?.(event, index)}
          className={s.dragableDiv}
        >
          <div draggable onDragStart={() => onDragStart?.(item)} className={s.dragableDiv}>
            <div className={s.formDiv} data-testid="management-wrapper">
              <FileInput
                cloudinaryId={item.cloudinaryId}
                accept=".png,.jpg,.jpeg,.jpg,.svg"
                onUpload={onUpload}
                order={item.order}
                onPhotoDelete={onPhotoDelete}
                showPhoto
                identifier="building-management"
                selected="photo"
                className={s.draggableUploadArea}
              />
              <div className={s.textInput}>
                <TextInput
                  name={`buildingManagement.${index}.name`}
                  placeholder={t('marketingMedia.buildingManagement.namePlaceholder')}
                  className={s.textfield}
                />
                <TextInput
                  name={`buildingManagement.${index}.title`}
                  placeholder={t('marketingMedia.buildingManagement.titlePlaceholder')}
                  className={s.textfield}
                />
                <div className={s.textContact}>
                  <TextInput
                    name={`buildingManagement.${index}.email`}
                    placeholder={t('marketingMedia.buildingManagement.emailPlaceholder')}
                    className={classNames(s.buildingManagementTextField, s.textfield)}
                  />
                  <TextInput
                    name={`buildingManagement.${index}.telephone`}
                    placeholder={t('marketingMedia.buildingManagement.telPlaceholder')}
                    className={classNames(s.buildingManagementTextField, s.textfield)}
                  />
                </div>
              </div>
              {!showCancelUploadMediaButton ? (
                <div className={s.cancelUploadButton} data-testid="cancel-upload-media-button">
                  <Close onClick={() => onDelete(item.order)} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}
      {disableButton ? (
        <Button
          className={classNames(
            buttonCSS.button,
            buttonCSS.secondary,
            buttonCSS.medium,
            css.previewLink,
            s.addMarketingMediaButton,
          )}
          type="primary"
          icon="plus"
          data-testid="add-person-button"
          onClick={() => addNewForm?.()}
        >
          {t('marketingMedia.buildingManagement.managementInputPlaceholder')}
        </Button>
      ) : null}
    </div>
  );
};

export default BuildingManagementForm;
