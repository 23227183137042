import { useState } from 'react';
import routes from '@root/routes';
import { ButtonLink, Modal } from '@components/shared';
import { useTranslation, Trans } from 'react-i18next';
import { Market } from '@root/types';
import useEnums from '@shared/useEnums';
import useEnv from '@shared/useEnv';
import useLoginUrl from '@shared/useLoginUrl';
import { useBreakpoint } from '@shared/useBreakpoints';
import useAltText from '@root/shared/useAltText';
import s from './SiteVisitorModal.module.less';

type Props = {
  chooseMarket: (marketId: string) => void;
};

const COUNTRY_ORDER = ['US', 'GB'];

const SiteVisitorMarketSelector = ({ chooseMarket }: Props) => {
  const { t } = useTranslation('homepage');
  const { markets } = useEnums();
  const [modalOpen, setModalOpen] = useState(true);
  const { getAltText } = useAltText();
  const { vtsUrl } = useEnv();
  const waitlistUrl = routes.waitlist(vtsUrl!, {
    utm_content: 'market selector cta',
    utm_term: 'homepage',
  });

  const publicMarketsByCountry: Record<string, Market[]> = markets
    .filter(m => m.isPublic)
    .reduce((obj, market) => {
      const marketsOfCountry = obj[market.countryCode];

      return {
        ...obj,
        [market.countryCode]: marketsOfCountry ? [...marketsOfCountry, market] : [market],
      };
    }, {});

  const noPublicMarketsInCountry = (countryCode: string): boolean => {
    return !Object.keys(publicMarketsByCountry).includes(countryCode);
  };

  const { loginUrl } = useLoginUrl();

  const { isMobile } = useBreakpoint();

  return (
    <Modal
      closable={false}
      maskClosable={false}
      isOpen={modalOpen}
      className={s.modal}
      style={!isMobile ? { top: '24px' } : { top: '0px' }}
    >
      <section className={s.header}>{t('siteVisitorModal.title')}</section>
      <section className={s.body}>
        <ul className={s.countries}>
          {COUNTRY_ORDER.map(countryCode => {
            if (noPublicMarketsInCountry(countryCode)) return null;
            return (
              <li key={countryCode}>
                <h3>{t(`country:${countryCode}`)}</h3>
                <ul className={s.markets}>
                  {publicMarketsByCountry[countryCode].map((market: Market) => {
                    const marketName = t(`common:marketSelector.${market.id}`);

                    return (
                      <li key={market.id}>
                        <button
                          type="button"
                          onClick={() => {
                            setModalOpen(false);
                            setTimeout(() => {
                              chooseMarket(market.id);
                            }, 250);
                          }}
                        >
                          <figure>
                            <img
                              alt={getAltText({ type: 'marketPhoto', options: { marketName } })}
                              // eslint-disable-next-line max-len
                              src={`https://media.truva.com/c_fill,g_center,h_200,w_220/assets/market_photos/${market.id}`}
                            />
                          </figure>
                          <p>{marketName}</p>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
        <div className={s.footer}>
          <p className={s.noMarket}>{t('siteVisitorModal.dontSeeMarketText')}</p>
          <div>
            <ButtonLink size="medium" type="secondary" href={waitlistUrl}>
              {t('siteVisitorModal.joinWaitlist')}
            </ButtonLink>
          </div>
          <div className={s.login}>
            {/* eslint-disable react/jsx-no-literals */}
            <Trans ns="homepage" i18nKey="siteVisitorModal.alreadyHaveAccount">
              Already have an account? <a href={loginUrl}>Log in</a>
            </Trans>
            {/* eslint-enable react/jsx-no-literals */}
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default SiteVisitorMarketSelector;
