import { FastField, Field, useFormikContext } from 'formik';
import { CountryCode } from '@root/types';
import Input from '../RawInput';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';
import countryValidationRules from './countryValidationRules';

type Props = {
  className?: string;
  description?: string;
  disabled?: boolean;
  fast?: boolean;
  placeholder?: string;
  autoComplete?: boolean;
  showCharacterCount?: boolean;
  countryCode?: CountryCode;
} & Omit<FieldWrapperProps, 'children'>;

const TextInput = ({
  className,
  description,
  disabled = false,
  fast = false,
  name,
  labelClass,
  containerClass,
  labelText,
  placeholder,
  required,
  autoComplete = true,
  showCharacterCount = false,
  countryCode = 'US',
}: Props) => {
  const Component = fast ? FastField : Field;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { maxLength, forbiddenCharacters } = countryValidationRules[countryCode];

  return (
    <FieldWrapper {...{ description, name, labelClass, containerClass, labelText, required }}>
      <Component name={name}>
        {({ field, meta }) => (
          <Input
            aria-describedby={`${name}-description ${name}-error`}
            autoComplete={autoComplete}
            className={className}
            disabled={disabled}
            {...field}
            onChange={(e: { target: HTMLInputElement }) => {
              let filteredValue = e.target.value.replace(forbiddenCharacters, '');
              if (filteredValue.length > maxLength) {
                filteredValue = filteredValue.slice(0, maxLength);
              }
              filteredValue = filteredValue.toUpperCase();
              setFieldTouched(name, true);
              setFieldValue(name, filteredValue);
            }}
            hasError={meta.touched && meta.error}
            placeholder={placeholder}
            showCharacterCount={showCharacterCount}
            maxLength={maxLength}
          />
        )}
      </Component>
    </FieldWrapper>
  );
};

export default TextInput;
