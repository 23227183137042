import { Field } from 'formik';
import cn from 'classnames';
import { RawLabel } from '..';
import s from './Toggle.module.less';
import { useFormTheme } from '../ThemeContext';

type Props = {
  name: string;
  labelText?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  dataTestId?: string;
  className?: string;
  size?: 'medium' | 'large';
};

const Toggle = ({ name, className, onChange, disabled, dataTestId, labelText, size }: Props) => {
  const layout = useFormTheme();
  return layout === 'admin' ? (
    <Field type="checkbox" name={name}>
      {({ field, form }) => (
        <div className="flex justify-between">
          <RawLabel name={name} className="!mb-0 !font-bold !text-black-100">
            {labelText}
          </RawLabel>
          <span className={cn(s.container, className)}>
            <input
              checked={field.checked}
              onChange={e => {
                if (onChange) {
                  onChange(e.target.checked);
                }
                field.onChange(e);
                form.setFieldTouched(field.name, true);
              }}
              className={s.input}
              name={name}
              type="checkbox"
              id={name}
              disabled={disabled}
              data-testid={dataTestId}
            />
            {/* eslint-disable react/jsx-no-literals */}
            <label className={cn(s.label, size === 'large' && s.large)} htmlFor={name}>
              Toggle
            </label>
            {/* eslint-enable react/jsx-no-literals */}
          </span>
        </div>
      )}
    </Field>
  ) : (
    <>
      <Field type="checkbox" name={name}>
        {({ field }) => (
          <span className={cn(s.container, className)}>
            <input
              checked={field.checked}
              onChange={e => {
                if (onChange) {
                  onChange(e.target.checked);
                }
                field.onChange(e);
              }}
              className={s.input}
              name={name}
              type="checkbox"
              id={name}
              disabled={disabled}
              data-testid={dataTestId}
            />
            {/* eslint-disable react/jsx-no-literals */}
            <label className={cn(s.label, size === 'large' && s.large)} htmlFor={name}>
              Toggle
            </label>
            {/* eslint-enable react/jsx-no-literals */}
          </span>
        )}
      </Field>
      {labelText ? <RawLabel name={name}>{labelText}</RawLabel> : null}
    </>
  );
};

export default Toggle;
