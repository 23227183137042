import { StoreState } from '@root/types';
import { PARAMETERS } from './constants';

export const getSourcePageFrom = (state: StoreState) => {
  switch (state.page.type) {
    case 'LISTING':
      return PARAMETERS.listingPage;
    case 'LISTING_SHARE':
      return PARAMETERS.listingPage;
    case 'LISTING_SEARCH':
      return PARAMETERS.searchResultsPage;
    case 'BUILDING':
      return PARAMETERS.buildingPage;
    case 'LANDLORD':
      return PARAMETERS.landlordPage;
    case 'TOURBOOK':
      return PARAMETERS.tourbookPage;
    case 'MANAGE_TOURBOOKS':
      return PARAMETERS.manageTourbooksPage;
    default:
      return null;
  }
};

export const getListingAnalyticsFromSearchResults = (state: StoreState, listingId) => {
  const listingById = state?.listings?.byId[listingId];
  const listingByIdExists = !!listingById;
  if (listingByIdExists) {
    return {
      ...listingById.analyticsInformation,
      listingAgentsEmails: listingById.listingAgents?.map(({ email }) => email),
      buildingEmployerId: listingById.building.employerId,
    };
  }

  if (state.page.type === 'BUILDING') {
    // If on building page and not preview modal
    const buildingSlug = state.page.pageArguments.slug;
    const listingsByBuildingSlug = state.buildings.listingsBySlug[buildingSlug]!;
    return listingsByBuildingSlug.filter(listing => listing.id === listingId)[0]
      .analyticsInformation;
  }

  return state?.listings?.searchResults[listingId]?.analyticsInformation;
};
