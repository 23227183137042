import { useSelector } from 'react-redux';
import { useListingSearchCriteria } from '@components/layouts/Truva/ListingSearch/utils';
import { useTranslation } from 'react-i18next';
import { OptionType, GroupedOptionType } from '@components/shared/forms/Dropdowns/types';
import { StoreState, Market } from '@root/types';

export default function useMarket() {
  const { t } = useTranslation();
  const allMarkets = useSelector((state: StoreState) => state.enums.markets);
  const criteria = useListingSearchCriteria();
  const currentMarket: Market | undefined = allMarkets.find(
    market => market.id === criteria.marketSlug,
  );

  const publicMarkets: Market[] = allMarkets
    .filter(market => market.isPublic)
    .sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

  /* eslint-disable no-param-reassign */
  const groupedOptions = (markets: Market[]): Array<GroupedOptionType> => {
    return Object.values(
      markets.reduce((accum, market) => {
        if (!accum[market.countryCode]) {
          accum[market.countryCode] = {
            label: t(`country:${market.countryCode}`),
            options: [
              {
                label: t(`common:marketSelector.${market.id}`),
                value: market.id,
              },
            ],
          };
        } else {
          accum[market.countryCode].options.push({
            label: t(`common:marketSelector.${market.id}`),
            value: market.id,
          });
        }

        return accum;
      }, []),
    );
  };
  /* eslint-enable no-param-reassign */

  const defaultValue: OptionType | undefined = groupedOptions(publicMarkets).reduce(
    (previous: OptionType | undefined, current) => {
      return previous || current.options.find(option => option.value === criteria.marketSlug);
    },
    undefined,
  );

  const isOnCustomMarket = criteria.marketSlug === 'custom';

  return {
    isOnCustomMarket,
    isOnNonPublicMarket: isOnCustomMarket || (currentMarket && !currentMarket.isPublic),
    allMarkets,
    currentMarket,
    defaultValue,
    options: groupedOptions(publicMarkets),
  };
}
