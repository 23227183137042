import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import Divider from '@components/shared/Divider';
import { Select, NumberInput } from '@components/shared/forms';
import css from './Structure.module.less';

const Structure = React.forwardRef((_props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
  const { t } = useTranslation('admin');
  const ceilingStyles = [
    { label: t('listing.rooms.form.structure.ceilingStyle.dropCeiling'), value: 'drop_ceiling' },
    {
      label: t('listing.rooms.form.structure.ceilingStyle.drywallPlaster'),
      value: 'drywall_plaster',
    },
    { label: t('listing.rooms.form.structure.ceilingStyle.exposed'), value: 'exposed' },
    { label: t('listing.rooms.form.structure.ceilingStyle.finished'), value: 'finished' },
    {
      label: t('listing.rooms.form.structure.ceilingStyle.otherCeilingType'),
      value: 'other_ceiling_type',
    },
  ];
  const walls = [
    { label: t('listing.rooms.form.structure.walls.drywall'), value: 'drywall' },
    {
      label: t('listing.rooms.form.structure.walls.exposedBrickPainted'),
      value: 'exposed_brick_painted',
    },
    { label: t('listing.rooms.form.structure.walls.exposedBrickRaw'), value: 'exposed_brick_raw' },
    { label: t('listing.rooms.form.structure.walls.plaster'), value: 'plaster' },
  ];
  const floorFinish = [
    { label: t('listing.rooms.form.structure.floorFinish.carpet'), value: 'carpet' },
    { label: t('listing.rooms.form.structure.floorFinish.hardwood'), value: 'hardwood' },
    {
      label: t('listing.rooms.form.structure.floorFinish.highTrafficCarpet'),
      value: 'high_traffic_carpet',
    },
    {
      label: t('listing.rooms.form.structure.floorFinish.polishedConcrete'),
      value: 'polished_concrete',
    },
    { label: t('listing.rooms.form.structure.floorFinish.tile'), value: 'tile' },
    { label: t('listing.rooms.form.structure.floorFinish.vinylLaminate'), value: 'vinyl_laminate' },
  ];

  return (
    <>
      <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-4x', 'u-p-bottom-2x')}>
        {t('listing.rooms.form.structure.title')}
      </h2>
      <section className={css.listingRoomFormSection}>
        <div className={css.ceilingStyle}>
          <Select
            labelText={t(`listing.rooms.form.structure.ceilingStyle.label`)}
            name="ceilingStyle"
            options={ceilingStyles}
            placeholder=""
          />
        </div>
        <div className={css.ceilingHeight}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.structure.ceilingHeight')}
            name="ceilingHeight"
          />
        </div>
        <div className={css.columnCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.structure.columnCount')}
            name="columnCount"
          />
        </div>
        <div className={css.walls}>
          <Select
            labelText={t(`listing.rooms.form.structure.walls.label`)}
            name="walls"
            options={walls}
            placeholder=""
          />
        </div>
        <div className={css.floorFinish}>
          <Select
            labelText={t(`listing.rooms.form.structure.floorFinish.label`)}
            name="floorFinish"
            options={floorFinish}
            placeholder=""
          />
        </div>
      </section>
      <Divider />
    </>
  );
});

export default React.memo(Structure);
