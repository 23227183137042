import { Fragment } from 'react';
import { CustomIcon } from '@components/shared';
import { useTranslation } from 'react-i18next';
import useToggle from '@shared/useToggle';
import { useBreakpoint } from '@root/shared/useBreakpoints';
import useAltText from '@shared/useAltText';
import cn from 'classnames';
import OverflowTooltip from '@components/shared/OverflowTooltip/OverflowTooltip';
import { ListingEngagement } from './utils';
import RelatedListingTag from './RelatedListingTag';

export default function ListingsTable({
  listings,
  relatedListingIds,
}: {
  listings: ListingEngagement[];
  relatedListingIds: string[];
}) {
  const { t } = useTranslation('tourbook');
  const tableClassNames =
    'grid grid-cols-[16px_64px_1fr_repeat(3,minmax(200px,0.6fr))] gap-y-1 gap-x-2 tablet:grid-cols-[16px_64px_1fr_repeat(3,120px)]';
  return (
    <div className="relative mt-[-32px]" data-testid="listingTable">
      <div className="sticky top-7 z-[2] mx-[-16px] bg-background-secondary pt-2 mobile:top-6">
        <div className="mx-2 mt-2 rounded-t-md bg-background-primary shadow-[0px_-2px_4px_rgba(115,_115,_115,_0.25)]">
          <h2 className="px-2 py-1.5 font-subtitle-de-emphasis mobile:border-b mobile:border-solid mobile:border-black-010">
            {t('analytics.tourbookListingEngagementDetails')}
          </h2>
          <div
            role="table"
            className={cn(tableClassNames, 'px-2 font-body-medium-emphasis mobile:hidden')}
          >
            <div />
            <div className="col-span-2">{t('analytics.listing')}</div>
            <div>{t('analytics.listingActivity')}</div>
            <div>{t('analytics.mediaViews')}</div>
            <div>{t('analytics.downloads')}</div>
            <FullWidthSeparator />
          </div>
        </div>
      </div>
      <div
        role="table"
        className={cn(
          tableClassNames,
          'rounded-b-md bg-background-primary px-2 py-1.5 shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)] font-body-medium mobile:flex mobile:flex-col',
        )}
      >
        {listings.map((listing, i) => (
          <Fragment key={listing.id}>
            <ListingRow
              key={listing.id}
              listing={listing}
              isRelated={relatedListingIds.includes(listing.id)}
            />
            {i < listings.length - 1 && <FullWidthSeparator />}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function ListingRow({ listing, isRelated }: { listing: ListingEngagement; isRelated: boolean }) {
  const { t } = useTranslation('tourbook');
  const { value: detailsOpen, toggle: toggleOpenNextDetails } = useToggle(false);
  const { getAltText } = useAltText({ address: listing.address });
  const { isMobile, isDesktop } = useBreakpoint();
  const Wrapper = isMobile ? 'div' : Fragment;
  const noActivity = listing.totalActivity + listing.totalDownloads + listing.totalMediaViews < 1;

  const totalActivity = [
    {
      label: t('analytics.events.tourbookAdds'),
      value: listing.numTimesAddedToTourbook,
    },
    {
      label: t('analytics.events.listingPreviews'),
      value: listing.numPreviewViews,
    },
    {
      label: t('analytics.events.listingSaved'),
      value: listing.numTimesSaved,
    },
    {
      label: t('analytics.events.viewedFullDetails'),
      value: listing.numFullDetailViews,
    },
    {
      label: t('analytics.events.viewedTourbookListing'),
      value: listing.numTourbookListingViews,
    },
  ];

  const totalMediaViews = [
    { label: t('analytics.events.floorPlan'), value: listing.numFloorPlanViews },
    { label: t('analytics.events.images'), value: listing.numImageViews },
    { label: t('analytics.events.virtualTours'), value: listing.numVideoViews },
  ];

  const totalDownloads = [
    { label: t('analytics.events.floorPlanDownloads'), value: listing.numFloorPlanDownloads },
    { label: t('analytics.events.imageDownloads'), value: listing.numImageDownloads },
  ];

  const viewExpandedListingDetais = (activity: Array<{ label: string; value: number }>) => {
    return activity
      .sort((a, b) => a.label.localeCompare(b.label))
      .map(listingActivityItem => (
        <Detail
          key={listingActivityItem.label}
          label={listingActivityItem.label}
          value={listingActivityItem.value}
        />
      ));
  };

  return (
    <>
      <Wrapper>
        <Wrapper {...(isMobile ? { className: 'flex gap-2' } : {})}>
          <button
            aria-label={t('analytics.toggleForMoreDetails')}
            onClick={toggleOpenNextDetails}
            type="button"
            className={cn('mobile:self-start', noActivity && 'cursor-not-allowed text-black-035')}
            disabled={noActivity}
          >
            <CustomIcon type={detailsOpen ? 'chevron-up' : 'chevron-down'} />
          </button>
          <figure>
            <img
              className="h-8 w-8 rounded-md object-cover"
              src={listing.heroImage.smallPath}
              alt={getAltText({ type: listing.heroImage.type })}
            />
          </figure>
          <div className="flex flex-col">
            <div>{t('common:fullBuildingName', { address: listing.address })}</div>
            <div>{listing.address.floorAndSuite}</div>
            <div className="text-black-055">
              {listing.submarket ? (
                <>
                  <span>{listing.submarket}</span>
                  {/* eslint-disable-next-line react/jsx-no-literals */}
                  <span className="px-0.5">|</span>
                </>
              ) : null}
              <span>{t('common:cityLocalityRegion', { address: listing.address })}</span>
            </div>
            {isRelated && (
              <div className="mt-0.5">
                <RelatedListingTag />
              </div>
            )}
          </div>
        </Wrapper>
        <Wrapper {...(isMobile ? { className: 'mt-1 flex justify-between' } : {})}>
          <div>
            <div className="mb-1 hidden mobile:block mobile:font-body-medium-emphasis">
              {t('analytics.listingActivity')}
            </div>
            <div>{listing.totalActivity}</div>
          </div>
          <div>
            <div className="mb-1 hidden mobile:block mobile:font-body-medium-emphasis">
              {t('analytics.mediaViews')}
            </div>
            <div>{listing.totalMediaViews}</div>
          </div>
          <div>
            <div className="mb-1 hidden mobile:block mobile:font-body-medium-emphasis">
              {t('analytics.downloads')}
            </div>
            <div>{listing.totalDownloads}</div>
          </div>
        </Wrapper>
      </Wrapper>
      {detailsOpen && (
        <>
          <div />
          <FullWidthSeparator className="mt-[-8px] desktop:mx-[28px] tablet:mx-0 mobile:mx-0 mobile:mt-0" />
          <div
            data-testid="moreDetailsRow"
            className={cn(
              'col-start-1 col-end-7 grid grid-cols-[16px_64px_1fr_repeat(3,minmax(200px,0.6fr))] gap-x-2 desktop:mb-1',
              'tablet:col-start-3 tablet:col-end-7 tablet:mb-1 tablet:grid-cols-[minmax(0,200px)_minmax(0,200px)_minmax(0,200px)] tablet:justify-between',
              'mobile:col-start-1 mobile:my-1 mobile:flex mobile:flex-col mobile:gap-y-2',
            )}
          >
            {isDesktop && (
              <>
                <div />
                <div />
                <div />
              </>
            )}
            <div className="flex flex-col gap-1 tablet:min-w-20">
              <div className="text-black-055 font-body-medium-emphasis mobile:font-body-small-emphasis">
                {t('analytics.listingActivity')}
              </div>
              {listing.totalActivity > 0 ? (
                <>{viewExpandedListingDetais(totalActivity)}</>
              ) : (
                <div>0</div> // eslint-disable-line react/jsx-no-literals
              )}
            </div>
            <div className="flex flex-col gap-1 tablet:min-w-20">
              <div className="text-black-055 font-body-medium-emphasis mobile:font-body-small-emphasis">
                {t('analytics.mediaViews')}
              </div>
              {listing.totalMediaViews > 0 ? (
                <>{viewExpandedListingDetais(totalMediaViews)}</>
              ) : (
                <div>0</div> // eslint-disable-line react/jsx-no-literals
              )}
            </div>
            <div className="flex flex-col gap-1 tablet:min-w-20">
              <div className="text-black-055 font-body-medium-emphasis mobile:font-body-small-emphasis">
                {t('analytics.downloads')}
              </div>
              {listing.totalDownloads > 0 ? (
                <>
                  {listing.brochures.map((brochure, i) => (
                    <Detail
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${brochure.name}-${i}`}
                      label={t(`${brochure.name}`)}
                      value={brochure.count}
                      className="w-[164px]"
                    />
                  ))}
                  {viewExpandedListingDetais(totalDownloads)}
                </>
              ) : (
                <div>0</div> // eslint-disable-line react/jsx-no-literals
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

function Detail({ label, value, className }: { label: string; value: number; className?: string }) {
  return value > 0 ? (
    <div className="text-text-primary-disabled flex gap-2">
      <div className="w-2.5">{value}</div>
      <OverflowTooltip className={className} tooltipText={label} tooltipTrigger={label} />
    </div>
  ) : null;
}

function FullWidthSeparator({ className }: { className?: string }) {
  return <div className={cn('col-span-6 mx-[-16px] h-[1px] bg-black-010', className)} />;
}
