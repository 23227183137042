import { useRef } from 'react';
import cn from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import css from './AdminTable.module.less';

type Props = {
  index: number;
  moveRow: (fromIndex: number, toIndex: number) => void;
  className: string;
};

const DraggableRow = ({ index, moveRow, className, ...restProps }: Props) => {
  const ref = useRef<HTMLTableRowElement | null>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: 'DraggableRow',
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? 'dropOverDownward' : 'dropOverUpward',
      };
    },
    drop: (item: { type: 'DraggableRow'; index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { type: 'DraggableRow', index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));
  return (
    <tr
      ref={ref}
      className={cn(
        className,
        css.draggableTableRow,
        isOver && dropClassName ? css[dropClassName] : null,
      )}
      data-testid="table-row"
      {...restProps}
    />
  );
};

export default DraggableRow;
