import cn from 'classnames';
import { Placement } from '@popperjs/core';
import IconButton from '@components/shared/IconButton';
import PopperTooltip from '@components/shared/PopperTooltip/PopperTooltip';
import useAltText from '@root/shared/useAltText';
import { Address } from '@root/types';
import s from './FloorplanPreviewButton.module.less';
import InvisibleMask from '../InvisibleMask';

type FloorplanPreviewButtonProps = {
  floorPlan: string;
  floorplanIconOnClick: () => void;
  floorplanPreviewIsOpen: boolean;
  setFloorplanPreviewIsOpen?: (isOpen: boolean) => void;
  toolTipPlacement?: Placement;
  portrait?: boolean;
  address: Address;
};

const FloorplanPreviewButton = ({
  floorPlan,
  floorplanIconOnClick,
  toolTipPlacement = 'right-start',
  portrait = false,
  address,
  floorplanPreviewIsOpen,
  setFloorplanPreviewIsOpen = () => {},
}: FloorplanPreviewButtonProps) => {
  const { getAltText } = useAltText({ address });

  const button = (
    <IconButton
      className={cn({ [s.portrait]: portrait, [s.floorplanIcon]: true })}
      icon="floor-plan"
      data-testid="floorplan-preview-button"
      onClick={() => {
        floorplanIconOnClick();
        setFloorplanPreviewIsOpen(true);
      }}
    />
  );

  const floorplanImage = (
    <div
      role="button"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <img
        src={floorPlan}
        alt={getAltText({ type: 'floorPlan' })}
        data-testid="floor-plan-popper"
      />
    </div>
  );

  return (
    <>
      <PopperTooltip
        triggerElement={button}
        popperElement={floorplanImage}
        trigger="click"
        toolTipPlacement={toolTipPlacement}
        triggerElementClassName={cn({ [s.portrait]: portrait, [s.floorplanIconWrapper]: true })}
        onClose={() => setFloorplanPreviewIsOpen(false)}
        offset={(toolTipPlacement === 'bottom-end' && [32, 16]) || undefined}
        showArrow
        showCloseButton
        popperContentClassName={s.floorplanTooltipContent}
      />
      {floorplanPreviewIsOpen && <InvisibleMask className="z-[2]" />}
    </>
  );
};

export default FloorplanPreviewButton;
