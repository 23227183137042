import * as React from 'react';
import { ClassSpecificProps, getClassNameForButton } from './Button';
import UtmAwareLink from '../UtmAwareLink/UtmAwareLink';

type Props = {
  href: string;
  openInNewTab?: boolean;
  children: React.ReactNode;
  onClick?: (url: string) => void;
  testid?: string;
} & ClassSpecificProps;

const externalLink = link => /^http/.test(link);
const noOp = () => {};

const ButtonLink = ({
  /** If this link is external we use an <a/> else it uses a <Link/> tag */
  href,
  openInNewTab = false,
  disabled = false,
  type,
  size,
  hover = false,
  focus = false,
  className,
  children,
  testid,
  onClick = noOp,
}: Props) => {
  const fullClassname = getClassNameForButton({
    disabled,
    type,
    size,
    hover,
    focus,
    className,
  });

  return externalLink(href) ? (
    <a
      data-testid={testid}
      onClick={() => onClick(href)}
      className={fullClassname}
      href={href}
      {...(openInNewTab ? { target: '_blank' } : {})}
    >
      {children}
    </a>
  ) : (
    <UtmAwareLink
      dataTestid={testid}
      onClick={() => onClick(href)}
      className={fullClassname}
      to={href}
      {...(openInNewTab ? { target: '_blank' } : {})}
    >
      {children}
    </UtmAwareLink>
  );
};

export default ButtonLink;
