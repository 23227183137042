import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FlexibleSizeArea } from '@components/shared';
import { camelCase } from 'lodash';
import useAnalytics from '@root/shared/useAnalytics';
import { SearchListing } from '@root/types';
import BrochureDownloadLink from '@components/shared/BrochureDownloadLink/BrochureDownloadLink';
import s from '../ListingCard.module.less';

type ListingInfoProps = {
  listing: SearchListing;
  portrait: boolean;
  isBuildingPage: boolean;
  showBrochureCol: boolean;
};

const ListingInfo = ({ listing, portrait, isBuildingPage, showBrochureCol }: ListingInfoProps) => {
  const { t } = useTranslation('listing');
  const { brochureInteraction, PARAMETERS } = useAnalytics();

  const {
    name,
    size,
    minMaxArea,
    address,
    neighborhoodName,
    landlordLogo,
    leaseType,
    spaceCondition,
    brochures = [],
  } = listing;

  return (
    <>
      <div
        className={cn({ [s.portrait]: portrait, [s.buildingPage]: isBuildingPage }, s.listingInfo)}
      >
        <div className={s.areaAndCapacity}>
          <div className={s.squareFoot}>{t('units:area', { area: size })}</div>
          <span>
            <FlexibleSizeArea
              flexibleSizeAreaClassName={s.flexibleSizeAreaContainer}
              iconClassName={s.sizeArea}
              textClassName={s.text}
              minMaxArea={minMaxArea}
              showIconTitle={false}
            />
          </span>
        </div>
        <div className={s.separator} />
        {isBuildingPage ? (
          <div className={s.addressContainer}>
            <div className={s.listingName}>{name}</div>
          </div>
        ) : (
          <div className={s.addressContainer}>
            <h2 className={s.buildingName}>{t('common:fullBuildingName', { address })}</h2>
            <h4 className={s.listingName}>{name}</h4>
            <h3 className={s.regionContainer}>
              {neighborhoodName ? (
                <span className={s.neighborhoodName}>{neighborhoodName}</span>
              ) : null}
              <span>{t('common:cityLocalityRegion', { address })}</span>
            </h3>
          </div>
        )}
      </div>
      <div
        className={cn({ [s.portrait]: portrait, [s.buildingPage]: isBuildingPage }, s.extraInfo)}
      >
        <div>
          {!isBuildingPage && (
            <img
              className={s.landlordLogo}
              alt={landlordLogo?.description}
              src={landlordLogo?.path}
              data-testid="landlord-logo"
            />
          )}
          <div
            className={cn(
              { [s.portrait]: portrait, [s.buildingPage]: isBuildingPage },
              s.leaseAndConditionContainer,
            )}
          >
            <div className={s.leaseTypeContainer}>
              <span className={s.leaseTypeLabel}>{t('search.leaseType')}</span>
              <span className={s.leaseType}>{t(`leaseTypes.${leaseType}`)}</span>
            </div>
            <div className={s.conditionContainer}>
              <span className={s.conditionLabel}>{t('search.condition')}</span>
              <span className={s.condition}>
                {spaceCondition ? t(`spaceConditions:${camelCase(spaceCondition)}`) : '-'}
              </span>
            </div>
          </div>
        </div>
      </div>
      {isBuildingPage && showBrochureCol && (
        <div className={cn({ [s.portrait]: portrait }, s.brochure)}>
          {brochures.length > 0 &&
            listing.brochures.map(brochure => (
              <div key={brochure.url} className="pb-[4px] last:pb-0">
                <BrochureDownloadLink
                  brochure={brochure}
                  onClick={() =>
                    brochureInteraction({
                      listing,
                      sourcePage: PARAMETERS.buildingPage,
                      sourceContent: PARAMETERS.onMarketListings,
                      otherAttributes: {
                        attachmentId: brochure.id,
                        attachmentName: brochure.name,
                      },
                    })
                  }
                  lineWrap={1}
                />
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default ListingInfo;
