import api from '@shared/api';
import routes from '@root/routes';
import { PARAMETERS, EVENT_TYPES, EVENTS } from '@root/tracking/constants';
import { getListingAnalyticsFromSearchResults, getSourcePageFrom } from '@root/tracking/helpers';
import {
  TourbookSummary,
  SearchListing,
  Listing,
  ErrorResponse,
  ListingAnalyticsInformation,
  StoreState,
} from '@root/types';
import { addListingToTourbook } from './createTourbookListing';

type SubmissionValues = {
  name: string;
  description?: string;
  listingId?: string;
  listing?: SearchListing | Listing;
};

type ReceiveNewTourbookAction = {
  type: 'RECEIVE_NEW_TOURBOOK';
  payload: {
    tourbook: TourbookSummary;
    meta: {
      analytics: {
        eventType: typeof EVENT_TYPES.track;
        event: typeof EVENTS.tourbookInteraction;
        action: typeof PARAMETERS.createTourbook;
        sourcePage: string;
        sourceContent: typeof PARAMETERS.tourbookOverlay | typeof PARAMETERS.listingPreview;
        destinationTourbookId: string;
        destinationTourbookName: string;
        destinationTourbookOwnerId: string;
        destinationTourbookOwnerVTSId: string;
        destinationTourbookListingCount: number;
      };
    };
  };
};

export const receiveNewTourbook = ({
  tourbook,
  sourcePage,
  sourceContent = PARAMETERS.tourbookOverlay,
  listingAnalyticsInfo,
}: {
  tourbook: TourbookSummary;
  sourcePage: string;
  sourceContent?: typeof PARAMETERS.tourbookOverlay | typeof PARAMETERS.listingPreview;
  listingAnalyticsInfo?: ListingAnalyticsInformation;
}): ReceiveNewTourbookAction => ({
  type: 'RECEIVE_NEW_TOURBOOK',
  payload: {
    tourbook,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.createTourbook,
        sourcePage,
        sourceContent,
        destinationTourbookId: tourbook.id,
        destinationTourbookName: tourbook.name,
        destinationTourbookOwnerId: tourbook.ownerId,
        destinationTourbookOwnerVTSId: tourbook.ownerVTSId,
        destinationTourbookListingCount: tourbook.listingIds ? tourbook.listingIds.length : 0,
        ...listingAnalyticsInfo,
      },
    },
  },
});

type ErrorCreatingTourbookAction = {
  type: 'ERROR_CREATING_NEW_TOURBOOK';
  payload: SubmissionValues;
};

export const errorCreatingTourbook = (values: SubmissionValues): ErrorCreatingTourbookAction => ({
  type: 'ERROR_CREATING_NEW_TOURBOOK',
  payload: values,
});

export const createTourbook =
  (
    values: SubmissionValues,
    {
      onSuccess,
      onError,
    }: {
      onSuccess: (tourbook: TourbookSummary) => void;
      onError: (e: ErrorResponse) => void;
    } = {
      onSuccess: () => {},
      onError: () => {},
    },
  ) =>
  async (dispatch, getState) => {
    await api.post(routes.api.tourbooks('newest'), values).then(async response => {
      if (response.ok) {
        const tourbook: TourbookSummary = await response.json();
        const state: StoreState = getState();
        const sourceContentIsListingPreviewModal = state.ui.islistingPreviewModalOpen;
        const sourceContent = sourceContentIsListingPreviewModal
          ? PARAMETERS.listingPreview
          : PARAMETERS.tourbookOverlay;
        const sourcePage = getSourcePageFrom(state)!;
        const listingAnalyticsInfo = getListingAnalyticsFromSearchResults(state, values.listingId);
        dispatch(
          receiveNewTourbook({
            tourbook,
            sourcePage,
            ...(sourceContentIsListingPreviewModal && {
              sourceContent: PARAMETERS.listingPreview,
              listingAnalyticsInfo,
            }),
          }),
        );
        if (values.listing) {
          dispatch(
            addListingToTourbook({
              tourbook,
              sourceContent,
              sourcePage,
              listingAnalyticsInfo,
            }),
          );
        }
        onSuccess(tourbook);
      } else {
        const errorResponse: ErrorResponse = await response.json();
        dispatch(errorCreatingTourbook(values));
        onError(errorResponse);
      }
    });
  };

export type Actions = ReceiveNewTourbookAction | ErrorCreatingTourbookAction;
