import * as React from 'react';
import classNames from 'classnames';
import { useState } from 'react';
import s from './RawInputRange.module.less';

type InputProps = { onBlur: () => void; onFocus: () => void };

export type Props = {
  renderFirstInput: (inputProps: InputProps) => React.ReactNode;
  renderSecondInput: (inputProps: InputProps) => React.ReactNode;
  hasError?: boolean;
  dividerClass?: string;
  containerClass?: string;
};

const RawInputRange = ({
  renderFirstInput,
  renderSecondInput,
  hasError,
  dividerClass,
  containerClass,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputProps: InputProps = {
    onBlur: () => setIsFocused(false),
    onFocus: () => setIsFocused(true),
  };

  return (
    <div
      className={classNames(
        s.inputRange,
        containerClass,
        hasError && s.error,
        isFocused && s.focused,
      )}
    >
      {renderFirstInput({ ...inputProps })}
      <span className={classNames(s.divider, dividerClass)}>
        {/* eslint-disable-line react/jsx-no-literals */ '-'}
      </span>
      {renderSecondInput({ ...inputProps })}
    </div>
  );
};

export default RawInputRange;
