import cn from 'classnames';
import { CustomIcon } from '@components/shared';
import ColorPicker from '@components/shared/ColorPicker/ColorPicker';
import { ColorGroup } from '@components/shared/ColorPicker/ColorPickerColorGroup';

type Props = {
  title: string;
  name?: string;
  color: string;
  colorGroups?: ColorGroup[];
  isOpen: boolean;
  isFocused: boolean;
  onChangeColor: (color: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
};
const ColorPickerAccordion = ({
  title,
  name,
  color,
  colorGroups,
  isOpen,
  isFocused,
  onChangeColor,
  onOpen,
  onClose,
  onFocus,
  onBlur,
}: Props) => {
  return (
    <div className="border-b border-solid border-black-020 px-3 py-2">
      <button
        className={cn(
          'flex w-full items-center justify-between rounded-[3px] p-1 hover:bg-general-gray-quaternary focus:bg-general-gray-quaternary',
          isFocused && 'bg-general-gray-quaternary',
          isOpen && 'mb-1',
        )}
        type="button"
        onClick={isOpen ? onClose : onOpen}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <div className="flex items-center gap-1">
          <div
            className="h-3 w-3 rounded-[32px] border border-solid border-black-020"
            style={{ backgroundColor: color }}
          />
          <span className="text-left font-body-medium">{title}</span>
        </div>
        <CustomIcon type={isOpen ? 'chevron-up' : 'chevron-down'} />
      </button>
      <div className={cn('overflow-hidden transition-all', isOpen ? 'max-h-[424px]' : 'max-h-0')}>
        <ColorPicker
          color={color}
          name={name}
          colorGroups={colorGroups}
          onChange={onChangeColor}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default ColorPickerAccordion;
