import { useState } from 'react';
import cn from 'classnames';
import { HexAlphaColorPicker as ReactColorfulColorPicker, HexColorInput } from 'react-colorful';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldWrapper, RawInput } from '@components/shared/forms';
import { CustomIcon } from '@components/shared';
import ColorPickerColorGroup, {
  ColorGroup,
} from '@components/shared/ColorPicker/ColorPickerColorGroup';
import s from '@components/shared/ColorPicker/ColorPicker.module.less';

type Props = {
  name: string;
  labelText: string;
  required?: boolean;
  onChange?: (color: string, prevColor: string) => void;
  'data-testid'?: string;
  colorGroups?: ColorGroup[];
};

const normalize = (val: number, max: number, min: number) => {
  return (val - min) / (max - min);
};
export const hexToAlpha = (alphaHexString: string) => {
  if (alphaHexString === '') return 100;
  return Math.round(normalize(parseInt(alphaHexString, 16), 255, 0) * 100);
};

export default function HexAlphaColorPicker({
  name,
  labelText,
  required,
  onChange = () => {},
  'data-testid': dataTestId,
  colorGroups,
}: Props) {
  const [field, _meta, helpers] = useField({ name });
  const [open, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const { t } = useTranslation('admin');
  return (
    <FieldWrapper {...{ name, required, 'data-testid': dataTestId, labelText }}>
      <div className="rounded-[3px] border-[1px] border-solid border-black-020 bg-white p-1">
        <button
          className={cn('flex w-full items-center justify-between ', open && 'mb-1')}
          type="button"
          onClick={open ? onClose : onOpen}
        >
          <div className="flex items-center gap-1">
            <div
              className={cn(
                'relative h-3 w-3 rounded-[32px] border border-solid border-black-020',
                field.value === 'transparent' &&
                  "after:absolute after:bottom-0 after:left-[9px] after:top-0 after:h-[calc(100%_+_1px)] after:w-0.5 after:rotate-45 after:bg-dataviz-critical-2 after:content-['']",
              )}
              style={{ backgroundColor: field.value }}
            />
            <span className="font-body-medium">
              {t('microsite.hexAlphaColorValue', {
                hexValue: field.value.slice(0, 7),
                alphaValue: hexToAlpha(field.value.slice(7, 9)),
              })}
            </span>
          </div>
          <CustomIcon type={open ? 'chevron-up' : 'chevron-down'} />
        </button>

        <div className={cn('overflow-hidden transition-all', open ? 'max-h-[424px]' : 'max-h-0')}>
          <div className="relative rounded-[3px] border border-solid border-black-020 bg-white">
            <ReactColorfulColorPicker
              color={field.value}
              onChange={color => {
                const prevColor = field.value;
                helpers.setValue(color);
                helpers.setTouched(true);
                onChange(color, prevColor);
              }}
              className={cn('!w-full rounded-none p-1', s.colorPicker)}
            />
            <div className="flex items-center gap-1 px-1 pb-1">
              <div className="flex gap-0.5 items-center">
                <label htmlFor={`${name}-hex`} className="font-body-small">
                  {t('common:hex')}
                </label>
                <HexColorInput
                  color={field.value.slice(0, 7)}
                  id={`${name}-hex`}
                  name={`${name}-hex`}
                  onChange={(color: string) => {
                    const newValue = color + field.value.slice(7, 9);
                    if (color.length === 7) {
                      const prevColor = field.value;
                      field.onChange({ target: { name, value: newValue } });
                      onChange(newValue, prevColor);
                    }
                  }}
                  className="w-full rounded-[3px] border border-solid border-black-020 px-1 font-body-medium focus:outline-egg-100"
                />
              </div>

              <div className="flex gap-0.5 items-center">
                <label htmlFor={`${name}-opacity`} className="font-body-small">
                  {t('common:opacity')}
                </label>
                <RawInput
                  className="!py-0"
                  name={`${name}-opacity`}
                  value={hexToAlpha(field.value.slice(7, 9))}
                  onChange={e => {
                    const alpha = parseInt(e.target.value, 10);
                    if (alpha >= 0 && alpha <= 100) {
                      const prevColor = field.value;
                      const alphaString = Math.round((alpha * 255) / 100)
                        .toString(16)
                        .padStart(2, '0');
                      const newValue = field.value.slice(0, 7) + alphaString;
                      field.onChange({
                        target: { name, value: newValue },
                      });
                      onChange(newValue, prevColor);
                    }
                  }}
                />
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span>%</span>
              </div>
            </div>
          </div>
          <div>
            {colorGroups?.map(colorGroup => (
              <ColorPickerColorGroup
                key={colorGroup.title}
                {...colorGroup}
                selectedColor={field.value.slice(0, 7)}
                onChange={color => {
                  const prevColor = field.value;
                  const newValue = color + field.value.slice(7, 9);
                  field.onChange({ target: { name, value: newValue } });
                  onChange(newValue, prevColor);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </FieldWrapper>
  );
}
