import { Dialog } from '@viewthespace/components';
import { useTranslation } from 'react-i18next';
import { BuildingLogoTypes } from '../BrandedAssets';

const DeleteDialog = ({
  isDeleteOpen,
  assetType,
  onClose,
  onConfirm,
}: {
  isDeleteOpen: boolean;
  assetType: BuildingLogoTypes;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation('admin');

  const assetConfig = {
    lettermarkLogo: {
      title: t('building.brandedAssets.deleteModal.lettermarkLogo.title'),
      text: t('building.brandedAssets.deleteModal.lettermarkLogo.text'),
    },
    bannerImage: {
      title: t('building.brandedAssets.deleteModal.bannerImage.title'),
      text: t('building.brandedAssets.deleteModal.bannerImage.text'),
    },
  };

  return (
    <Dialog
      align="default"
      header={assetConfig[assetType].title}
      isOpen={isDeleteOpen}
      onClose={onClose}
      closeOnClickAway
      width={assetType === 'bannerImage' ? 'auto' : 'small'}
      primaryButton={{
        text: t('common:confirm'),
        onClick: onConfirm,
      }}
      secondaryButton={{
        text: t('common:cancel'),
        onClick: onClose,
      }}
    >
      <div className="p-2">
        <span className="font-body-medium">{assetConfig[assetType].text}</span>
      </div>
    </Dialog>
  );
};

export default DeleteDialog;
