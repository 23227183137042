import { useState, useEffect, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { Building, BuildingListing, StoreState } from '@root/types';
import { compact } from 'lodash';
import { PARAMETERS } from '@root/tracking/constants';
import routes from '@root/routes';
import cn from 'classnames';
import { OnlyInDesktopTablet, ErrorBoundary } from '@components/shared';
import Location from '@components/shared/Location';
import Transportation from '@components/shared/Transportation';
import Divider from '@components/shared/Divider';
import Breadcrumbs from '@components/shared/Breadcrumbs';
import Bartender from '@components/shared/Bartender';
import useContactViewChooser from '@components/shared/ContactList/useContactViewChooser';
import { getSessionStorageValues } from '@root/shared/sessionStorageForSiteVisitors';
import Amenities from '@components/shared/Amenity/Amenities';
import { useSelector } from 'react-redux';
import useAnalytics from '@shared/useAnalytics';
import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import Certifications from './Certifications';
import VideoTour from '../Listing/VideoTour';
import BasicInfo from './BasicInfo';
import s from './Building.module.less';
import StaticMapImage from './StaticMapImage';
import AvailableSpaces, { ListingTab } from './AvailableSpaces';
import Images from './Images';
import Header from './Header/Header';
import Skeleton from './Skeleton';

type Props = {
  building: Building;
  listingClicked: (listing: BuildingListing, tab: ListingTab | '') => void;
  hasCurrentUser: boolean;
  isPreview: boolean;
  isSaved: boolean;
};

const videoSourceContent = {
  three_d_tour: PARAMETERS.spotlightTour,
  video: PARAMETERS.buildingVideo,
};

const Layout = ({ building, listingClicked, hasCurrentUser, isPreview, isSaved }: Props) => {
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => setHasRendered(true), []);
  const { clickToPage } = useAnalytics();

  const flags = useFlags();
  const { t } = useTranslation('building');
  const currentUser = useSelector((state: StoreState) => state.currentUser);

  const { arrivedOnMarketLink, arrivedOnShareLink, sessionStorageListingSharer } =
    getSessionStorageValues();

  const listingSharerForDisplay = sessionStorageListingSharer ? [sessionStorageListingSharer] : [];

  const {
    showContactList,
    showLinkSharerContactInfo,
    ContactList,
    LinkSharerContactInfo,
    makeContactEmailSubject,
  } = useContactViewChooser({ arrivedOnMarketLink, arrivedOnShareLink });

  const { data: listings, isLoading } = useQuery<BuildingListing[]>(
    [routes.api.buildingListings(building.slug)],
    async () => get(routes.api.buildingListings(building.slug)),
    { enabled: !isPreview },
  );

  const contactEmailSubject: string = makeContactEmailSubject({
    address: compact([building.address.buildingName, building.address.street]).join(' '),
    userName: currentUser?.firstName,
  });

  const locationSection = useRef<HTMLDivElement | null>(null);
  const handleScrollToLocation = () => {
    if (locationSection.current) {
      locationSection.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  const isLoggedOutUserFromShareLink =
    !hasCurrentUser && (arrivedOnMarketLink || arrivedOnShareLink);
  const showBreadcrumbLink = hasCurrentUser || !isLoggedOutUserFromShareLink;

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const availableSpacesRef = useRef<HTMLDivElement | null>(null);
  const handleScroll = () => {
    if (availableSpacesRef.current) {
      availableSpacesRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  if (isLoading && !isPreview) {
    return <Skeleton />;
  }

  return (
    <div data-testid="building-layout-container">
      <OnlyInDesktopTablet>
        {!!building.marketName && (
          <Breadcrumbs
            items={[
              {
                content: building.marketName,
                link: showBreadcrumbLink ? routes.search(building.marketSlug!) : undefined,
                onClick: () =>
                  clickToPage({
                    sourcePage: PARAMETERS.buildingPage,
                    sourceContent: PARAMETERS.breadcrumb,
                    destination: PARAMETERS.searchResultsPage,
                    actionType: 'BUILDING_TO_BREADCRUMB_CLICKED',
                  }),
              },
              {
                content: building.address.buildingName || building.address.street,
              },
            ]}
          />
        )}
      </OnlyInDesktopTablet>
      <Bartender
        drinks={[
          {
            url: routes.admin.building(building.id),
            tooltipText: 'Admin Page of the Building',
            icon: 'buildings-multiple',
          },
        ]}
        className={s.bartender}
      />
      <ErrorBoundary>
        <Header
          address={building.address}
          neighborhood={building.neighborhood}
          bannerImage={building?.bannerImage}
          lettermarkLogo={building?.lettermarkLogo}
          buildingSlug={building.slug}
          isSaved={isSaved}
          owningCompany={building.owningCompany}
          onClickAvailability={handleScroll}
          listings={listings!}
        />
        {hasRendered && (
          <Images
            address={building.address}
            firstPhotoOrientation={building.firstPhotoOrientation}
            photos={building.photos}
            video={building.video}
            tourEmbedUrl={building.tourEmbedUrl}
            sourcePage={PARAMETERS.buildingPage}
            sourceContent={PARAMETERS.buildingPageGallery}
            imagesZipUrl={routes.api.buildingImagesZipUrl(building.slug)}
            onLightboxOpened={() => setIsLightboxOpen(true)}
            onLightboxClosed={() => setIsLightboxOpen(false)}
          />
        )}
      </ErrorBoundary>
      <div className="grid grid-cols-3 gap-2 tablet:grid-cols-4 mobile:grid-cols-1">
        <ErrorBoundary>
          <div className="col-start-1 col-end-3 tablet:col-end-4">
            <BasicInfo building={building} />
            {showContactList && (
              <ContactList
                contacts={building.listingAgents}
                title={t('tourThisBuilding')}
                emailSubjectName={contactEmailSubject}
                removeBottomBorder
              />
            )}
            {showLinkSharerContactInfo && (
              <div className="desktop:w-[432px] tablet:w-[432px]">
                <LinkSharerContactInfo
                  contacts={listingSharerForDisplay}
                  emailSubjectName={contactEmailSubject}
                  title={t('tourThisBuilding')}
                  sourcePage={PARAMETERS.buildingPage}
                />
              </div>
            )}
          </div>

          <div className="col-start-3 tablet:col-start-4 mobile:hidden">
            <StaticMapImage
              onScrollToLocation={handleScrollToLocation}
              transitOptions={building.transitOptions}
              className={s.mapImage}
              buildingAddress={building.locationAddress}
            />
          </div>
        </ErrorBoundary>
      </div>
      <div ref={availableSpacesRef}>
        <ErrorBoundary>
          <AvailableSpaces
            listingClicked={listingClicked}
            building={building}
            isPreview={isPreview}
          />
        </ErrorBoundary>
      </div>
      <div className="my-10 grid grid-cols-3 gap-2 mobile:my-0 mobile:grid-cols-1">
        <ErrorBoundary>
          <div className="col-start-1 col-end-3 mobile:col-span-full">
            <div className="mb-10 mobile:mb-8 mobile:pt-1">
              <Amenities amenities={building.amenities} />
            </div>
            <Certifications
              leedCertification={building.leedCertification}
              wiredCertification={building.wiredCertification}
            />
          </div>
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <Divider className={s.divider} />
        <VideoTour
          video={building.video}
          sourcePage={PARAMETERS.buildingPage}
          sourceContent={
            flags['media-analytics']
              ? PARAMETERS.spotlightTour
              : videoSourceContent[building.virtualTourSpotlight]
          }
          tourEmbedUrl={building.tourEmbedUrl}
          subtitle={false}
          virtualTourSpotlight={building.virtualTourSpotlight}
          isVisible={!isLightboxOpen}
        />
        <Divider className={cn(s.divider, s.withExtraPadding)} />
      </ErrorBoundary>
      <div ref={locationSection}>
        <ErrorBoundary>
          <Location address={building.locationAddress} />
          <Transportation transitOptions={building.transitOptions} />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Layout;
