import { useEffect } from 'react';
import { AgentContact } from '@root/types';
import Bugsnag from '@shared/bugsnagClient';
import renderingInServer from '@shared/renderingInServer';

export function useSetArrivedOnMarketLink() {
  useEffect(() => {
    sessionStorage.setItem('arrivedOnMarketLink', 'true');
  }, []);
}

export function useSetArrivedOnShareLink() {
  useEffect(() => {
    sessionStorage.setItem('arrivedOnShareLink', 'true');
  }, []);
}

// We keep this in the session storage so we can continue to show the
// contact of the user who shared the listing as the user navigates
// through the site
export function useSetListingSharerAsSessionStorageContacts(listingSharer: AgentContact) {
  const isSSR = renderingInServer();
  useEffect(() => {
    if (!isSSR) {
      sessionStorage.setItem('listingSharer', JSON.stringify(listingSharer));
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingSharer]);
}

export function getSessionStorageValues() {
  let sessionStorageListingSharer: AgentContact | null = null;
  try {
    sessionStorageListingSharer = sessionStorage.getItem('listingSharer')
      ? (JSON.parse(sessionStorage.getItem('listingSharer')!) as AgentContact)
      : null;
  } catch (e) {
    // This is here because session storage can be blocked with cookies,etc
    // So we should log it, but catch the error and allow the page to show
    // the CTA (what shows up if there is nothing in sesionStorage)
    Bugsnag.notify(e);
  }

  let arrivedOnMarketLink = false;
  try {
    arrivedOnMarketLink = !!sessionStorage.getItem('arrivedOnMarketLink');
  } catch (e) {
    Bugsnag.notify(e);
  }

  let arrivedOnShareLink = false;
  try {
    arrivedOnShareLink = !!sessionStorage.getItem('arrivedOnShareLink');
  } catch (e) {
    Bugsnag.notify(e);
  }

  return {
    sessionStorageListingSharer,
    arrivedOnMarketLink,
    arrivedOnShareLink,
  };
}
