import cn from 'classnames';
import { MultipathImage } from '@root/types';
import { useTranslation } from 'react-i18next';
import { HideIcon } from '@viewthespace/components';
import { BuildingLogoTypes } from '../BrandedAssets';

type Props = {
  assetType: BuildingLogoTypes;
  assetImage: MultipathImage | null;
};

const HiddenAssetPlaceholder = ({ assetType, assetImage }: Props) => {
  const { t } = useTranslation('admin');

  let assetSpanClasses = '';
  let assetImageDimensionClasses = '';

  switch (assetType) {
    case 'bannerImage': {
      assetSpanClasses = 'w-full justify-center left-0 top-[60px]';
      assetImageDimensionClasses = '!h-[139px] w-[1440px]';
      break;
    }
    case 'lettermarkLogo': {
      assetSpanClasses = 'w-[110px] flex-col p-[11px] items-center text-center mt-[10px]';
      assetImageDimensionClasses = '!h-[110px] w-[110px]';
      break;
    }
    default: {
      break;
    }
  }

  return (
    <>
      <div className={assetType === 'bannerImage' ? 'relative' : 'inline'}>
        <span
          className={cn(assetSpanClasses, 'absolute z-10 flex gap-x-0.5 font-body-large-emphasis')}
        >
          <HideIcon size="lg" />
          {t(`common:editableAsset.hiddenLandlordAsset.${assetType}`)}
        </span>
        <img
          className={cn(
            assetImageDimensionClasses,
            'rounded-[3px] !border !border-solid !border-black-020 object-cover opacity-25',
          )}
          src={assetImage?.rawPath}
          alt={t(`building.brandedAssets.${assetType}.assetAltText`)}
        />
      </div>
    </>
  );
};

export default HiddenAssetPlaceholder;
