import { ButtonLink, Card } from '@components/shared';
import useEnv from '@shared/useEnv';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import useAnalytics from '@shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import styles from './ContactCardCTA.module.less';

const ContactCardCTA = ({ source }: { source: 'building page' | 'listing page' }) => {
  const { t } = useTranslation('contactCardCTA');
  const { vtsUrl } = useEnv();
  const { clickToPage, anonymousId } = useAnalytics();
  const url = routes.signUp(vtsUrl!, {
    utm_content: 'contact info cta',
    utm_term: source,
    anonymous_id: anonymousId,
  });

  return (
    <Card title={t('title')} className={styles.card}>
      <p className={styles.prompt}>{t('prompt')}</p>
      <ButtonLink
        onClick={() =>
          clickToPage({
            sourcePage: source,
            destination: PARAMETERS.signUpPage,
            sourceContent: PARAMETERS.contactInfoCta,
          })
        }
        href={url}
        type="primary"
        size="large"
      >
        {t('buttonText')}
      </ButtonLink>
    </Card>
  );
};

export default ContactCardCTA;
