import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import { get } from '@shared/typedApi';
import { useQuery } from '@tanstack/react-query';
import { CustomIcon } from '@components/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import {
  InternalLink,
  InternalLinksType,
  topBuildingToInternalLink,
  topSubmarketToInternalLink,
} from './helper';
import InternalLinkGroup from './InternalLinkGroup';

/* eslint-disable react/jsx-no-literals */
type AccordionKey =
  | 'commercialRealEstate'
  | 'topNeighborhoods'
  | 'topBuildings'
  | 'newListings'
  | 'browseListings';
type AccordionExpandedStatus = {
  [key in AccordionKey]: boolean;
};

type AccordionInternalLinkGroupProps = {
  title: string;
  children: ReactNode;
  accordionIsOpen: boolean;
  setAccordionIsOpen: (isOpen: boolean) => void;
};

const AccordionInternalLinkGroup = ({
  title,
  children,
  accordionIsOpen,
  setAccordionIsOpen,
}: AccordionInternalLinkGroupProps) => {
  return (
    <>
      <li className="py-2">
        <button
          className="flex items-center justify-between text-left mobile:w-full"
          type="button"
          onClick={() => setAccordionIsOpen(!accordionIsOpen)}
        >
          <h2 className="mr-1 text-lg font-normal tracking-normal text-text-primary">{title}</h2>
          <CustomIcon type={accordionIsOpen ? 'chevron-up' : 'chevron-down'} />
        </button>
        <div
          data-testid={`internalLinksGroup-${title.toLowerCase()}`}
          className="relative max-h-fit text-justify"
        >
          {children}
        </div>
      </li>
    </>
  );
};

const defaultInternalLinks = {
  markets: null,
  newListings: null,
  browseListings: null,
  topBuildings: null,
  topSubmarkets: null,
};

export const InternalLinks = () => {
  const flags = useFlags();
  const { t } = useTranslation('homepage');

  const { data: internalLinksData } = useQuery([routes.api.internalLinks], async () =>
    get<InternalLinksType>(routes.api.internalLinks),
  );

  const [accordionExpandedStatus, setAccordionExpandedStatus] = useState<AccordionExpandedStatus>({
    commercialRealEstate: false,
    topNeighborhoods: false,
    topBuildings: false,
    newListings: false,
    browseListings: false,
  });

  const setAccordionIsOpen = (accordionKey: AccordionKey, isOpen: boolean): void => {
    setAccordionExpandedStatus({ ...accordionExpandedStatus, [accordionKey]: isOpen });
  };

  const internalLinks = internalLinksData || defaultInternalLinks;

  return (
    <div
      className={
        flags['outreach.branding-general']
          ? 'overflow-x-hidden bg-background-tertiary'
          : 'overflow-x-hidden bg-general-gray-quaternary'
      }
    >
      <div
        className={cn(
          'w-screen px-3',
          flags['outreach.branding-general']
            ? 'm-auto max-w-[1440px] bg-background-tertiary py-2 desktop:py-3 mobile:px-2'
            : 'bg-general-gray-quaternary py-3',
        )}
      >
        <ul className="mb-0 grid grid-cols-5 gap-x-4 tablet:grid-cols-2 mobile:grid-cols-1">
          <AccordionInternalLinkGroup
            title={t('internalLinks.commercialRealEstate')}
            accordionIsOpen={accordionExpandedStatus.commercialRealEstate}
            setAccordionIsOpen={isOpen => setAccordionIsOpen('commercialRealEstate', isOpen)}
          >
            <InternalLinkGroup
              isDisplayed={accordionExpandedStatus.commercialRealEstate}
              links={
                internalLinks.markets
                  ?.sort((marketA, marketB) =>
                    t(`common:marketSelector.${marketA.id}`).localeCompare(
                      t(`common:marketSelector.${marketB.id}`),
                    ),
                  )
                  .map(market => ({
                    id: market.id,
                    url: `/search/${market.id}`,
                    name: t(`common:marketSelector.${market.id}`),
                  })) ?? ([] as InternalLink[])
              }
            />
          </AccordionInternalLinkGroup>
          <AccordionInternalLinkGroup
            title={t('internalLinks.topNeighborhoods')}
            accordionIsOpen={accordionExpandedStatus.topNeighborhoods}
            setAccordionIsOpen={isOpen => setAccordionIsOpen('topNeighborhoods', isOpen)}
          >
            <InternalLinkGroup
              isDisplayed={accordionExpandedStatus.topNeighborhoods}
              links={(internalLinks.topSubmarkets ?? []).map(ts =>
                topSubmarketToInternalLink(ts, t),
              )}
            />
          </AccordionInternalLinkGroup>
          <AccordionInternalLinkGroup
            title={t('internalLinks.topBuildings')}
            accordionIsOpen={accordionExpandedStatus.topBuildings}
            setAccordionIsOpen={isOpen => setAccordionIsOpen('topBuildings', isOpen)}
          >
            <InternalLinkGroup
              isDisplayed={accordionExpandedStatus.topBuildings}
              links={(internalLinks.topBuildings ?? []).map(tB => topBuildingToInternalLink(tB, t))}
            />
          </AccordionInternalLinkGroup>
          <AccordionInternalLinkGroup
            title={t('internalLinks.newListings')}
            accordionIsOpen={accordionExpandedStatus.newListings}
            setAccordionIsOpen={isOpen => setAccordionIsOpen('newListings', isOpen)}
          >
            <InternalLinkGroup
              isDisplayed={accordionExpandedStatus.newListings}
              links={
                internalLinks.newListings?.map(listing => ({
                  id: listing.id,
                  url: `/listing/${listing.buildingSlug}/${listing.id}`,
                  name: `${listing.name}, ${t('common:fullBuildingName', {
                    address: listing.address,
                  })}, ${listing.address.city}`,
                })) ?? ([] as InternalLink[])
              }
            />
          </AccordionInternalLinkGroup>
          <AccordionInternalLinkGroup
            title={t('internalLinks.browseListings')}
            accordionIsOpen={accordionExpandedStatus.browseListings}
            setAccordionIsOpen={isOpen => setAccordionIsOpen('browseListings', isOpen)}
          >
            <InternalLinkGroup
              isDisplayed={accordionExpandedStatus.browseListings}
              links={
                internalLinks.browseListings?.map(browseListing => {
                  const { region, abbreviation } = browseListing;
                  return {
                    id: `${region}-${abbreviation}`,
                    url: `/browse/listings/${abbreviation.toLowerCase()}`,
                    name: `${region}`,
                  };
                }) ?? ([] as InternalLink[])
              }
            />
          </AccordionInternalLinkGroup>
        </ul>
      </div>
    </div>
  );
};

export default InternalLinks;
