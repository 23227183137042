import { useState } from 'react';
import { Props as ConfirmationModalProps } from './ConfirmationModal';
import { ConfirmationModal } from '..';

type Output = {
  ConfirmationModal: typeof ConfirmationModal;
  props: Partial<ConfirmationModalProps> &
    Pick<ConfirmationModalProps, 'isOpen' | 'onClose' | 'onConfirm'>;
  open: () => void;
};
const useConfirmationModal = (props: Partial<ConfirmationModalProps> = {}): Output => {
  const [isOpen, setIsOpen] = useState(false);
  return {
    ConfirmationModal,
    props: {
      ...props,
      isOpen,
      onClose: () => {
        if (props.onClose) props.onClose();
        setIsOpen(false);
      },
      onConfirm: () => {
        if (props.onConfirm) props.onConfirm();
        setIsOpen(false);
      },
    },
    open: () => setIsOpen(true),
  };
};

export default useConfirmationModal;
