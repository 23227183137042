import { Children, cloneElement, useState } from 'react';
import { uniqueId } from 'lodash';
import api from '@shared/api';
import cn from 'classnames';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

type Props = {
  children: Array<JSX.Element>;
  onDrop?: Function;
  onSort: Function;
  className?: string;
};

const SortableList = ({ children, onDrop, onSort, className }: Props) => {
  const dispatch = useDispatch();
  const [droppableId] = useState(uniqueId('droppable-'));

  const move = result => {
    if (!result.destination) {
      // User dropped outside of the list
      return;
    }

    const from = result.source.index;
    const to = result.destination.index;

    const items = children.map(child => child.props.sortableId);
    const withoutItem = items.filter((_: any, index: number) => index !== from);

    const sorted = [
      ...withoutItem.slice(0, to),
      items[from],
      ...withoutItem.slice(to, items.length),
    ];

    if (onDrop) dispatch(onDrop(sorted));

    const payload = { rails_sortable: sorted };

    api.post('/sortable/reorder', payload).finally(onSort);
  };

  return (
    <DragDropContext onDragEnd={move}>
      <Droppable droppableId={droppableId}>
        {droppable => (
          <ul ref={droppable.innerRef} className={cn(className)} {...droppable.droppableProps}>
            {Children.map(children, (child, index) => cloneElement(child, { index }))}
            {droppable.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableList;
