import React from 'react';
import { CustomIcon } from '@components/shared';
import { ValidIconTypes } from '@components/shared/CustomIcon';

type Props = {
  url: string;
  tooltipText: string;
  icon: ValidIconTypes;
};

export default function Drink({ url, tooltipText, icon }: Props) {
  return (
    <li>
      <a href={url}>
        <button type="button" title={tooltipText}>
          <CustomIcon type={icon} />
        </button>
      </a>
    </li>
  );
}
