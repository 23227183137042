import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import FirstToMarketLabel from './First-to-market-label.png';
import s from './ExclusiveLabel.module.less';

type Props = {
  size: 'small' | 'medium' | 'large';
  imgClassName?: string;
  tooltipPlacement?: TooltipPlacement;
};

const ExclusiveLabel = ({ size = 'large', imgClassName, tooltipPlacement = 'topLeft' }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Tooltip
      overlayClassName={s.tooltip}
      trigger="hover"
      placement={tooltipPlacement}
      title={t('firstToMarketTooltip')}
    >
      <img
        className={classNames(s.label, s[size], imgClassName)}
        src={FirstToMarketLabel}
        alt={t('altText:firstToMarket')}
        data-qa-testid="ftm-badge"
      ></img>
    </Tooltip>
  );
};

export default ExclusiveLabel;
