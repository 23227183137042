import { AgentContact, StoreState } from '@root/types';
import { useRef, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { breakpoints, useBreakpoint } from '@shared/useBreakpoints';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { PARAMETERS } from '@root/tracking/constants';
import useAnalytics from '@root/shared/useAnalytics';
import { useSelector } from 'react-redux';
import { getCurrentPageType, getListing } from '@root/store/selectors';
import { useParams } from 'react-router-dom';
import s from './ContactList.module.less';
import DeprecatedContact from './DeprecatedContact';
import Contact from './Contact';

export type Props = {
  contacts: AgentContact[];
  title: string;
  emailSubjectName?: string;
  removeBottomBorder?: boolean;
};

// remove with outreach.branding-general
const deprecatedMaxNumberOfContactsWhenCollapsedByBreakpoint = {
  [breakpoints.MOBILE]: 3,
  [breakpoints.TABLET]: 4,
  [breakpoints.DESKTOP]: 6,
};

const maxNumberOfContactsWhenCollapsedByBreakpoint = {
  [breakpoints.MOBILE]: 4,
  [breakpoints.TABLET]: 4,
  [breakpoints.DESKTOP]: 8,
};

export const ContactList = ({
  contacts,
  title,
  emailSubjectName,
  removeBottomBorder = false,
}: Props) => {
  const { t } = useTranslation('common');
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { breakpoint } = useBreakpoint();
  const expandedContactList = useRef<HTMLDivElement | null>(null);
  const { contactInteraction } = useAnalytics();
  const currentPageType = useSelector(getCurrentPageType);
  const { listingId } = useParams<{ listingId: string }>();
  const listing = useSelector((state: StoreState) => getListing(state, listingId));
  const flags = useFlags();

  const displayRebrandedContacts =
    currentPageType === 'BUILDING' || flags['outreach.branding-general'];

  const maxNumberOfContactsWhenCollapsed = displayRebrandedContacts
    ? maxNumberOfContactsWhenCollapsedByBreakpoint[breakpoint]
    : deprecatedMaxNumberOfContactsWhenCollapsedByBreakpoint[breakpoint];
  const expandCollapseButtonIsVisible = contacts.length > maxNumberOfContactsWhenCollapsed;

  const propertiesForAnalytics = () => {
    switch (currentPageType) {
      case 'LISTING': {
        return { listing, sourcePage: PARAMETERS.listingPage };
      }
      case 'BUILDING':
        return { sourcePage: PARAMETERS.buildingPage };
      default:
        return { listing, sourcePage: PARAMETERS.listingPage };
    }
  };
  const handleExpandContactList = () => {
    contactInteraction({
      action: PARAMETERS.contactShowAllContact,
      actionType: 'CONTACT_SHOW_ALL_CONTACT',
      sourceContent: PARAMETERS.contactSection,
      ...propertiesForAnalytics(),
    });
  };
  const handleRevealContact = (contact: AgentContact, contactRanking: number) => {
    contactInteraction({
      action: PARAMETERS.contactRevealContact,
      actionType: 'CONTACT_REVEAL_CONTACT',
      sourceContent: PARAMETERS.contactSection,
      ...propertiesForAnalytics(),
      otherAttributes: {
        contactRanking,
        contactInfo: {
          name: contact.name,
          role: contact.role,
          company: contact.company,
        },
        contactAvatar: contact.photoUrl ? 'yes' : 'no',
      },
    });
  };

  const handleEmailContact = (contact: AgentContact, contactRanking: number) => {
    contactInteraction({
      action: PARAMETERS.contactSelectContact,
      actionType: 'CONTACT_SELECT_CONTACT',
      sourceContent: PARAMETERS.contactSection,
      ...propertiesForAnalytics(),
      otherAttributes: {
        contactRanking,
        contactInfo: {
          name: contact.name,
          role: contact.role,
          company: contact.company,
        },
        contactAvatar: contact.photoUrl ? 'yes' : 'no',
      },
    });
  };

  const renderContacts = (contactList: AgentContact[]) =>
    contactList.map((contact, i) => (
      <div
        className={
          displayRebrandedContacts
            ? 'mb-2 w-1/2 desktop:w-1/4'
            : ' mb-2 w-1/3 tablet:w-1/2 mobile:w-full'
        }
        // eslint-disable-next-line react/no-array-index-key
        key={i}
      >
        {displayRebrandedContacts ? (
          <Contact
            contact={contact}
            emailSubjectName={emailSubjectName}
            onContactReveal={() => handleRevealContact(contact, i + 1)}
            onContactEmailClick={() => handleEmailContact(contact, i + 1)}
          />
        ) : (
          <DeprecatedContact
            contact={contact}
            emailSubjectName={emailSubjectName}
            onContactReveal={() => handleRevealContact(contact, i + 1)}
            onContactEmailClick={() => handleEmailContact(contact, i + 1)}
          />
        )}
      </div>
    ));

  if (!contacts.length) return null;

  return (
    <div
      className={cn(s.contactListContainer, {
        [s['no-bottom-border']]: removeBottomBorder,
      })}
      data-testid="contactContainer"
    >
      <h2 className="inline text-text-primary font-subtitle" data-qa-testid="tour-space-heading">
        {title}
      </h2>
      {expandCollapseButtonIsVisible && (
        <>
          <span className="px-[12px] text-black-035">
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            {'|'}
          </span>
          <button
            className="py-[1px] text-indigo-100 font-body-medium hover:text-indigo-90"
            type="button"
            onClick={() => {
              if (isCollapsed) {
                handleExpandContactList();
              }
              setIsCollapsed(!isCollapsed);
            }}
          >
            {t(`contactList.${isCollapsed ? 'showAllContacts' : 'showLessContacts'}`)}
          </button>
        </>
      )}
      <div className={s.contactList} data-testid="contactList" data-qa-testid="tour-space-contacts">
        {renderContacts(contacts.slice(0, maxNumberOfContactsWhenCollapsed))}
      </div>
      <div
        className={s.expandedContactList}
        data-testid="expandedContactList"
        style={{ maxHeight: isCollapsed ? 0 : expandedContactList?.current?.scrollHeight || 0 }}
        ref={expandedContactList}
        data-qa-testid="tour-space-contacts"
      >
        {renderContacts(contacts.slice(maxNumberOfContactsWhenCollapsed))}
      </div>
    </div>
  );
};

export default ContactList;
