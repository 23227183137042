import { memo } from 'react';
import { SubmitButton } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import css from './styles.module.less';

const SubmitFooter = () => {
  const { t } = useTranslation('admin');
  const { isValid } = useFormikContext();

  return (
    <div className={css.submitFooter}>
      <SubmitButton disabled={!isValid}>{t('common:save')}</SubmitButton>
    </div>
  );
};

export default memo(SubmitFooter);
