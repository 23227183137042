import { TextInput, TextArea } from '@components/shared/forms';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { CloudinaryImageParams, AdminMicrosite } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ImageUploader from '../../ImageUploader';
import TextColorSelector from '../../TextColorSelector';
import { ContentBlockWrapper } from '..';
import { useMicrositeFormContext } from '../../utils';
import ColorPicker from '../../ColorPicker';

export default function Footer() {
  const { colorGroups, changeFontColorWithColorChange, sendPreviewTo, microsite, resetKey } =
    useMicrositeFormContext();
  const { setFieldValue, values, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  return (
    <ContentBlockWrapper
      contentBlockId="footer"
      wrapInDraggable={false}
      title={t('microsite.sections.footer')}
      section="footer"
      onClick={() => sendPreviewTo({ page: 'home', element: 'footer' })}
    >
      <ColorPicker
        colorGroups={colorGroups}
        name="footerBackgroundColor"
        labelText={t('microsite.backgroundColor')}
        onChange={changeFontColorWithColorChange('footerTextColor')}
      />
      {flags['market-office.text-color-selector'] && <TextColorSelector name="footerTextColor" />}
      <ColorPicker
        colorGroups={colorGroups}
        name="footerBorderColor"
        labelText={t('microsite.borderColor')}
        hasTransparentOption
        data-testid="footerBorderColorPicker"
      />
      <ImageUploader
        img={values.footerLogo as CloudinaryImageParams | null}
        onChange={img => {
          setFieldValue('footerLogo', img);
          setFieldTouched('footerLogo', true);
        }}
        labelText={t('microsite.footerLogo.title')}
        name="footerLogo"
        alt={t('microsite.footerLogo.title')}
      />
      <TextInput
        name="footerLogoLink"
        labelText={t('microsite.footerLogoLink.title')}
        containerClass="!mb-0"
      />
      <TextInput name="facebookUrl" labelText={t('microsite.facebookUrl.title')} />
      <TextInput name="instagramUrl" labelText={t('microsite.instagramUrl.title')} />
      <TextInput name="linkedinUrl" labelText={t('microsite.linkedinUrl.title')} />
      <TextInput name="tiktokUrl" labelText={t('microsite.tiktokUrl.title')} />
      <TextInput name="twitterUrl" labelText={t('microsite.twitterUrl.title')} />
      {flags['market.building-descriptions-support-rich-text'] ? (
        <RichTextEditorInput
          name="footerLeftText"
          labelText={t('microsite.footerLeftText.title')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          initialContent={microsite.footerLeftText}
          containerClass="!mb-0"
          excludeMenuItems={
            flags['market.microsite-font-sizing-on-editors']
              ? ['bulletList']
              : ['bulletList', 'textSize']
          }
          defaultTextSize={
            flags['market.microsite-font-sizing-on-editors'] ? 'footer12' : undefined
          }
          resetKey={resetKey}
          fast={!!flags['market-office.microsite-performance-optimizations']}
          shouldDebounce
        />
      ) : (
        <TextArea
          name="footerLeftText"
          labelText={t('microsite.footerLeftText.title')}
          description={t('microsite.acceptsHtmlDescription')}
          descriptionClass="font-body-small text-black-055"
        />
      )}
      {flags['market.building-descriptions-support-rich-text'] ? (
        <RichTextEditorInput
          name="footerRightText"
          labelText={t('microsite.footerRightText.title')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          initialContent={microsite.footerRightText}
          excludeMenuItems={
            flags['market.microsite-font-sizing-on-editors']
              ? ['bulletList']
              : ['bulletList', 'textSize']
          }
          defaultTextSize={
            flags['market.microsite-font-sizing-on-editors'] ? 'footer12' : undefined
          }
          resetKey={resetKey}
          fast={!!flags['market-office.microsite-performance-optimizations']}
          shouldDebounce
        />
      ) : (
        <TextArea
          name="footerRightText"
          labelText={t('microsite.footerRightText.title')}
          description={t('microsite.acceptsHtmlDescription')}
          descriptionClass="font-body-small text-black-055"
        />
      )}
    </ContentBlockWrapper>
  );
}
