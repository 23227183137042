import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextAreaAutosize as TextArea, Checkbox, SubmitButton } from '@components/shared/forms';
import { useFormikContext } from 'formik';
import { Button } from '@components/shared';
import FormCancellationPrompt from '@components/shared/FormCancellationPrompt';
import routes from '@root/routes';
import { FormValues } from '../../ListingSearch/NamedSaveSearch/SaveSearchButton';
import s from './Searches.module.less';

type Props = {
  description: string;
  setIsBeingEdited: (boolean) => void;
};

const EditSavedSearchForm = ({ description, setIsBeingEdited }: Props) => {
  const { t } = useTranslation('filters');
  const {
    values: { name, useSearchCriteriaAsName },
    setFieldValue,
  } = useFormikContext<FormValues>();
  const handleNameInputChange = (form: any) => event => {
    form.setFieldValue('name', event.target.value);
  };

  useEffect(() => {
    if (useSearchCriteriaAsName) {
      setFieldValue('name', description);
    } else if (name) {
      setFieldValue('name', name);
    }
  }, [useSearchCriteriaAsName, setFieldValue, description, name]);

  return (
    <div className={s.formWrapper}>
      <div className={s.inputFieldWrapper}>
        <TextArea
          name="name"
          inputTestId="saved-search-edit-input"
          className={s.textAreaClassName}
          containerClass={s.textAreaContainer}
          value={name}
          onChange={handleNameInputChange}
          disabled={useSearchCriteriaAsName}
          onFocus={() => {}}
          minRows={1}
          maxRows={2}
          autoFocus
        ></TextArea>
        <Checkbox
          data-testid="use-search-criteria-as-name-checkbox"
          name="useSearchCriteriaAsName"
          size="small"
          labelClassName={s.useSearchCriteriaCheckboxLabel}
          className={s.useSearchCriteriaCheckbox}
          onChange={e => {
            if (!e.target.checked) {
              setFieldValue('name', '');
            }
          }}
        >
          {t('saveSearch:useCriteriaAsNameTitle')}
        </Checkbox>
      </div>

      <p className={s.searchDescriptionInForm}>{description}</p>
      <div className={s.buttonsWrapper}>
        <Button type="secondary" size="small" onClick={() => setIsBeingEdited(false)}>
          {t('saveSearch:cancelButton')}
        </Button>
        <SubmitButton size="small" className={s.saveSearchFromDialogButton}>
          {t('saveSearch:saveSearchSubmitButton')}
        </SubmitButton>
      </div>
      <FormCancellationPrompt enabled currentPagePath={routes.savedSearches} />
    </div>
  );
};

export default EditSavedSearchForm;
