import { ChangeEvent } from 'react';
import cn from 'classnames';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

type Props = {
  name: string;
  disabled?: boolean;
  required?: boolean;
  country?: string;
  placeholder?: string;
  onChange: (
    value: string,
    country: any,
    event: ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => void;
  onBlur?: (e) => void;
  value: string;
  inputClass?: string;
};

export default function RawPhoneNumberInput({
  country = 'us',
  placeholder,
  name,
  disabled,
  required,
  onChange,
  value,
  onBlur,
  inputClass,
}: Props) {
  return (
    <PhoneInput
      containerClass="bg-white border-2 border-gray-300 rounded-lg"
      buttonClass="!bg-transparent"
      dropdownClass=""
      inputClass={cn(
        inputClass,
        '!w-full bg-background-primary border-2 border-gray-300 rounded-lg p-1',
      )}
      country={country}
      placeholder={placeholder}
      preferredCountries={['us', 'ca', 'gb']}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      inputProps={{
        name,
        required,
        id: name,
        autoComplete: 'none',
      }}
    />
  );
}
