import { useEffect, useRef, useState } from 'react';
import { CloudinaryTransformations, CropperCanvasData, Tourbook } from '@root/types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import useAltText from '@root/shared/useAltText';
import Button from '@components/shared/V2Button';
import { isInIframe } from '@root/shared/iframeUtils';
import useSize from '@react-hook/size';
import { IconButton, PopperTooltip, CustomIcon, Notification } from '@components/shared';
import COLORS from '@root/shared/colorConstants';
import { colord } from 'colord';
import { CloudinaryFileButtonInput, Form } from '@components/shared/forms';
import useCloudinary from '@root/shared/useCloudinary';
import { useBreakpoint } from '@root/shared/useBreakpoints';
import useCropper from '@components/shared/Cropper/useCropper';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
import { useConfirmationModal } from '@components/shared/ConfirmationModal';
import { useMutation } from '@tanstack/react-query';
import routes from '@root/routes';
import { update } from '@root/shared/typedApi';
import { useDispatch } from 'react-redux';
import { receiveTourbook } from '@root/store/actions/tourbooks/fetchTourbook';
import { Prompt } from 'react-router-dom';
import ClientInformation from './ClientInformation';
import ContactInformation from './ContactInformation';
import s from './Header.module.less';
import { ColorModule } from './ColorPickerSidePanel';
import EditCoverPhotoDropdown from './EditCoverPhotoDropdown';

type Props = {
  tourbook: Tourbook;
  readOnly: boolean;
  hidden: boolean;
  isCustomizingColors: boolean;
  containsDescriptionBodyElements: boolean;
  colorModules: ColorModule[];
  setIsCustomizingColors: (boolean) => void;
  isPreview: boolean;
};

const BasicHeader = ({
  tourbook,
  readOnly,
  hidden,
  isCustomizingColors,
  containsDescriptionBodyElements,
  colorModules,
  setIsCustomizingColors,
  isPreview,
}: Props) => {
  const { getAltTextForTourbookHeader } = useAltText();
  const { t } = useTranslation('tourbook');
  const cld = useCloudinary();
  const { isMobile, isDesktop } = useBreakpoint();
  const dispatch = useDispatch();
  const updateTourbookInstance = (newTourbook: Tourbook) => dispatch(receiveTourbook(newTourbook));

  const descriptionSection = colorModules[0];
  const contactSection = colorModules[1];

  const headerRef = useRef(null);
  const contactSectionRef = useRef(null);
  const headerHeight = useSize(headerRef)[1];
  const contactSectionHeight = useSize(contactSectionRef)[1];

  const uploadCoverPhotoButtonRef = useRef<HTMLButtonElement>(null);
  const [isCropperInUse, setIsCropperInUse] = useState<boolean>(false);
  const [isCropperViewOnly, setIsCropperViewOnly] = useState(true);
  const [unsavedCoverPhotoId, setUnsavedCoverPhotoId] = useState<string | null>(null);
  const coverPhotoId = unsavedCoverPhotoId || tourbook.headerImage?.cloudinaryId;
  const coverAltText = getAltTextForTourbookHeader(tourbook.name);

  const getImageUrl = (): string | null => {
    if (!coverPhotoId) return null;
    const displayedImage = cld.image(coverPhotoId).format('auto');

    // when cropper is being used - fetch full image
    if (isCropperInUse) return displayedImage.toURL();

    // when image has been repositioned - fetch image with cloudinary transformations applied on BE
    if (tourbook.headerImage?.cropperCanvasData) return tourbook.headerImage?.largePath || null;

    // when it has not been repositioned - let Cloudinary autocrop
    return displayedImage.resize(fill().width(1500).height(1500).gravity(autoGravity())).toURL();
  };
  const imageUrl = getImageUrl();

  const {
    ZoomRange,
    zoomRangeProps,
    Cropper,
    cropperProps,
    isDragging,
    getCanvasData,
    getCloudinaryTransformations,
  } = useCropper({
    imageSrc: imageUrl || '',
    initialCanvasData: unsavedCoverPhotoId ? null : tourbook.headerImage?.cropperCanvasData,
    viewOnly: isCropperViewOnly || isMobile,
  });

  const saveTourbookHeaderMutation = useMutation(
    async ({
      cloudinaryId,
      cropperCanvasData = null,
      cloudinaryTransformations = null,
      deleteHeader = false,
    }: {
      cloudinaryId?: string | null;
      cropperCanvasData?: CropperCanvasData | null;
      cloudinaryTransformations?: CloudinaryTransformations | null;
      onSuccess?: () => void;
      deleteHeader?: boolean;
    }) => {
      const headerCloudinaryImageAttributes = {
        headerCloudinaryImageAttributes: {
          cloudinaryId,
          cropperCanvasData,
          transformations: cloudinaryTransformations,
          id: tourbook.headerImage?.id,
        },
      };
      return update(routes.api.tourbook(tourbook.id), {
        ...(deleteHeader ? { deleteHeader: true } : headerCloudinaryImageAttributes),
      });
    },
    {
      onSuccess: async (updatedTourbook, { onSuccess }) => {
        updateTourbookInstance(updatedTourbook);
        if (onSuccess) onSuccess();
      },
    },
  );

  const {
    open: openCancelConfirmationModal,
    ConfirmationModal,
    props: cancelConfirmationModalProps,
  } = useConfirmationModal({
    onConfirm: () => {
      setUnsavedCoverPhotoId(null);
      setIsCropperViewOnly(true);
      setIsCropperInUse(false);
    },
  });

  const { open: openDeleteConfirmationModal, props: deleteConfirmationModalProps } =
    useConfirmationModal({
      onConfirm: () => {
        saveTourbookHeaderMutation.mutate({
          cloudinaryId: null,
          deleteHeader: true,
          onSuccess: () => {
            Notification.info({
              title: t('header.tourbookCoverPhotoDeleted'),
            });
          },
        });
        setIsCropperInUse(false);
      },
    });

  useEffect(() => {
    if (!isCropperViewOnly) setIsCropperInUse(true);
  }, [isCropperViewOnly]);

  return (
    <>
      <header
        className={cn(
          'relative flex h-auto border-y border-solid border-general-neutral-secondary bg-background-primary shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)] md:min-h-[505px] md:border-x mobile:flex-col',
          hidden && '!hidden',
          isCustomizingColors && 'z-[51] md:!border-[#5E5F61]',
        )}
        ref={headerRef}
      >
        <div className="flex flex-col desktop:w-[53%] tablet:w-[60%]">
          <div
            data-testid={cn(isPreview ? 'previewDescriptionSection' : 'descriptionSection')}
            className={cn(
              'relative flex-grow border-2 border-solid transition-[color] duration-[.5s] mobile:flex mobile:min-h-[104px] mobile:items-center',
            )}
            style={{
              backgroundColor: descriptionSection.color,
              borderColor: descriptionSection.isFocused ? COLORS.egg100 : descriptionSection.color,
              color: colord(descriptionSection.color).isDark() ? 'white' : 'black',
            }}
          >
            <div
              className={cn(
                'flex h-full flex-col justify-center p-3 pr-4 mobile:!p-2',
                containsDescriptionBodyElements && 'justify-around',
              )}
            >
              <div className="flex flex-col gap-1">
                <h4 className="text-inherit font-headline mobile:font-title">{tourbook.name}</h4>
                {tourbook.description && (
                  <span className="whitespace-pre-wrap font-subtitle-de-emphasis">
                    {tourbook.description}
                  </span>
                )}
              </div>
              <ClientInformation name={tourbook.clientName} image={tourbook.clientLogoImage} />
            </div>
            {descriptionSection.isFocused && headerHeight && !isMobile ? (
              <div
                className="absolute inset-0 right-[-1px] bg-egg-100"
                style={{
                  clipPath: `polygon(${
                    (1 - contactSectionHeight / headerHeight) * 63
                  }px 0, 100% 0%, 100% 100%, 0 100%)`,
                  left: `calc(100% - ${64 * (1 - contactSectionHeight / headerHeight)}px)`,
                }}
              />
            ) : null}
          </div>
          <div
            data-testid={cn(isPreview ? 'previewContactSection' : 'contactSection')}
            ref={contactSectionRef}
            className={cn('relative border-2 border-solid transition-[color] duration-[.5s]')}
            style={{
              backgroundColor: contactSection.color,
              borderColor: contactSection.isFocused ? COLORS.egg100 : contactSection.color,
              color: colord(contactSection.color).isDark() ? 'white' : 'black',
            }}
          >
            {tourbook && (
              <div className="flex p-3 mobile:p-2">
                <ContactInformation
                  user={tourbook.owner}
                  readOnly={readOnly || isInIframe()}
                  darkMode={colord(contactSection.color).isDark()}
                />
              </div>
            )}
            {contactSection.isFocused && headerHeight && !isMobile ? (
              <div
                className="absolute inset-0 left-[calc(100%-64px)] bg-egg-100"
                style={{
                  clipPath: `polygon(${
                    (contactSectionHeight / headerHeight) * 62
                  }px 0, 100% 0%, 100% 100%, 0 100%)`,
                }}
              />
            ) : null}
            {!isCustomizingColors && !readOnly && !isPreview && (
              <PopperTooltip
                trigger="hover"
                triggerElementClassName="absolute bottom-2 right-10 mobile:right-2"
                toolTipPlacement="top"
                triggerElement={
                  <IconButton
                    icon="paintbrush"
                    className="!h-4 !w-4 !border !border-solid !border-black-035 !bg-white !text-[14px] text-black-100 hover:!bg-black-005"
                    onClick={() => setIsCustomizingColors(true)}
                    data-testid="customizeColorsButton"
                  />
                }
                popperElementClassName="bg-black-100 font-body-medium text-white"
                popperElement={t('header.customizeColors')}
              />
            )}
          </div>
        </div>
        <div
          className={cn(
            'overflow-hidden bg-black-005 md:absolute md:inset-0 desktop:left-[calc(53%-64px)] tablet:left-[calc(60%-64px)] mobile:relative mobile:order-[-1] mobile:flex mobile:max-h-20 mobile:min-h-[160px]',
            s.headerImageContainer,
            isCustomizingColors && !isPreview && 'brightness-50',
          )}
        >
          {isCropperInUse ? (
            <>
              <Cropper
                {...cropperProps}
                containerClassName="h-full w-full mobile:[&_.cropper-container]:!min-w-full"
                className="h-full w-[720px] min-w-[720px] mobile:w-full mobile:translate-y-[-35%]"
                alt={coverAltText}
              />
              {!isCropperViewOnly && (
                <div
                  className={cn('select-none transition', isDragging ? 'opacity-0' : 'opacity-100')}
                >
                  <div className="pointer-events-none absolute bottom-9 flex w-full justify-center">
                    <ZoomRange
                      {...zoomRangeProps}
                      className="[&_button]:bg-black-100/0 [&_button]:text-white hover:[&_button]:!bg-general-indigo-tertiary hover:[&_button]:!text-general-gray-primary"
                    />
                  </div>
                  <div className="absolute bottom-0 flex h-7 w-full items-center justify-end gap-1 bg-black-100/60 p-2">
                    <div
                      role="button"
                      className="mr-auto flex items-center gap-0.5 text-white font-body-medium"
                      onClick={() => {
                        uploadCoverPhotoButtonRef.current?.click();
                      }}
                    >
                      <CustomIcon type="upload" />
                      {isDesktop ? t('header.uploadNewPhoto') : t('header.uploadNew')}
                    </div>
                    <Button
                      type="tertiary"
                      size="small"
                      onClick={openCancelConfirmationModal}
                      data-testid="cancelCoverPhotoChangesButton"
                    >
                      {t('common:cancel')}
                    </Button>
                    <Button
                      type="tertiary"
                      size="small"
                      className="!text-indigo-100"
                      data-testid="saveCoverPhotoChangesButton"
                      onClick={() => {
                        saveTourbookHeaderMutation.mutate({
                          cloudinaryId: coverPhotoId,
                          cropperCanvasData: getCanvasData(),
                          cloudinaryTransformations: getCloudinaryTransformations(),
                          onSuccess: () => {
                            setUnsavedCoverPhotoId(null);
                            setIsCropperViewOnly(true);
                            Notification.info({
                              title: t('common:changesSaved'),
                            });
                          },
                        });
                      }}
                    >
                      {t('common:saveChanges')}
                    </Button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <img
              className={cn(
                'h-full bg-white object-cover mobile:w-full mobile:translate-y-[-35%]',
                tourbook.headerImage?.cropperCanvasData
                  ? 'w-[720px] min-w-[720px]'
                  : 'w-full object-cover',
              )}
              src={imageUrl || '/images/empty-state-default.jpg'}
              alt={coverAltText}
              draggable={false}
            />
          )}
          {!isPreview && !readOnly && (
            <Form
              id="uploadCoverPhotoForm"
              className={cn(
                'absolute top-0 flex h-full w-full items-center justify-center',
                imageUrl && 'hidden',
              )}
              onSubmit={() => {}}
            >
              <CloudinaryFileButtonInput
                name="coverPhotoUpload"
                buttonContent={
                  <>
                    <CustomIcon type="upload" className="!text-black-100" />
                    {t('header.uploadHeaderImage')}
                  </>
                }
                buttonType="tertiary"
                buttonSize="medium"
                buttonClassName="flex items-center gap-1 whitespace-nowrap"
                buttonRef={uploadCoverPhotoButtonRef}
                onError={() => {}}
                onLoading={() => {}}
                onReady={({ public_id: cloudinaryId }) => {
                  if (isMobile) {
                    saveTourbookHeaderMutation.mutate({ cloudinaryId });
                  } else {
                    setUnsavedCoverPhotoId(cloudinaryId);
                    setIsCropperViewOnly(false);
                  }
                }}
              />
            </Form>
          )}
        </div>

        {!isPreview && !readOnly && isCropperViewOnly && imageUrl ? (
          <EditCoverPhotoDropdown
            className="!absolute right-2 md:bottom-2 mobile:top-[112px]"
            options={[
              {
                label: t('header.uploadNewPhoto'),
                icon: 'upload',
                onClick: () => {
                  uploadCoverPhotoButtonRef.current?.click();
                },
              },
              {
                label: t('common:edit'),
                icon: 'edit',
                onClick: () => {
                  setIsCropperViewOnly(false);
                },
                hidden: isMobile,
              },
              { label: t('common:delete'), icon: 'trash', onClick: openDeleteConfirmationModal },
            ]}
          />
        ) : null}
      </header>
      <ConfirmationModal
        {...cancelConfirmationModalProps}
        confirmationText={t('common:prompt.unsavedChanges')}
        cancelButtonText={t('common:cancel')}
        confirmButtonText={t('common:ok')}
        data-testid="cancelConfirmationModal"
      />
      <ConfirmationModal
        {...deleteConfirmationModalProps}
        title={t('header.deleteCoverPhoto')}
        confirmationText={t('header.irrevertableMessage')}
        cancelButtonText={t('header.doNotDelete')}
        confirmButtonText={t('header.confirm')}
      />
      <Prompt
        when={isCropperInUse && !isCropperViewOnly}
        message={t('common:prompt.unsavedChanges')}
      />
    </>
  );
};

export default BasicHeader;
