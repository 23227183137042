import api from '@shared/api';
import routes from '@root/routes';
import { PARAMETERS, EVENT_TYPES, EVENTS } from '@root/tracking/constants';
import {
  TourbookSummary,
  ListingAnalyticsInformation,
  ExternalListingAnalyticsInformation,
  TourbookListingAnalyticsInformation,
} from '@root/types';
import { getSourcePageFrom, getListingAnalyticsFromSearchResults } from '@root/tracking/helpers';

type RemoveListingInTourbookAction = {
  type: 'LISTING_REMOVED_FROM_TOURBOOK';
  payload: {
    tourbook: TourbookSummary;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Tourbook Interaction';
        action: 'delete listing';
        sourcePage: string;
        sourceContent: typeof PARAMETERS.tourbookOverlay | typeof PARAMETERS.listingPreview;
        destinationTourbookId: string;
        destinationTourbookName: string;
        destinationTourbookOwnerId: string;
        destinationTourbookOwnerVTSId: string;
        destinationTourbookListingCount: number;
      };
    };
  };
};
export const deleteTourbookListingAction = ({
  tourbook,
  sourcePage,
  sourceContent,
  listingAnalyticsInfo,
}: {
  tourbook: TourbookSummary;
  sourcePage: string;
  sourceContent: typeof PARAMETERS.tourbookOverlay | typeof PARAMETERS.listingPreview;
  listingAnalyticsInfo: ListingAnalyticsInformation;
}): RemoveListingInTourbookAction => ({
  type: 'LISTING_REMOVED_FROM_TOURBOOK',
  payload: {
    tourbook,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.tourbookInteraction,
        action: PARAMETERS.deleteListing,
        sourcePage,
        sourceContent,
        destinationTourbookId: tourbook.id,
        destinationTourbookName: tourbook.name,
        destinationTourbookOwnerId: tourbook.ownerId,
        destinationTourbookOwnerVTSId: tourbook.ownerVTSId,
        destinationTourbookListingCount: tourbook.listingCount,
        ...listingAnalyticsInfo,
      },
    },
  },
});

type DeleteExternalListingInTourbookAction = {
  type: 'TOURBOOK_DELETE_EXTERNAL_LISTING';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Tourbook Interaction';
        action: 'delete external listing';
        sourcePage: string;
      } & ExternalListingAnalyticsInformation;
    };
  };
};

const deleteExternalListingInTourbookAction = (
  analyticsInformation: ExternalListingAnalyticsInformation,
): DeleteExternalListingInTourbookAction => {
  return {
    type: 'TOURBOOK_DELETE_EXTERNAL_LISTING',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.tourbookInteraction,
          action: PARAMETERS.deleteExternalListing,
          sourcePage: PARAMETERS.tourbookPage,
          ...analyticsInformation,
        },
      },
    },
  };
};

function deleteTourbookInternalListing({
  tourbookId,
  listingId,
  onSuccess = () => {},
}: {
  tourbookId: string;
  listingId: string;
  onSuccess: (tourbook: TourbookSummary) => void;
}) {
  return async (dispatch, getState) => {
    const path = routes.api.tourbookListing({ tourbookId, listingId });
    const response = await api.delete(path);
    const tourbook: TourbookSummary = await response.json();
    const state = getState();
    const sourceContentIsListingPreviewModal = state.ui.islistingPreviewModalOpen;

    dispatch(
      deleteTourbookListingAction({
        tourbook,
        sourcePage: getSourcePageFrom(state)!,
        sourceContent: sourceContentIsListingPreviewModal
          ? PARAMETERS.listingPreview
          : PARAMETERS.tourbookOverlay,
        listingAnalyticsInfo: getListingAnalyticsFromSearchResults(state, listingId),
      }),
    );

    onSuccess(tourbook);
  };
}

function deleteTourbookExternalListing({
  tourbookId,
  listingId,
  analyticsInformation,
  onSuccess = () => {},
}: {
  tourbookId: string;
  listingId: string;
  analyticsInformation: ExternalListingAnalyticsInformation;
  onSuccess: (tourbook: TourbookSummary) => void;
}) {
  return async dispatch => {
    const path = routes.api.tourbookExternalListing({ tourbookId, externalListingId: listingId });
    const response = await api.delete(path);
    const tourbook: TourbookSummary = await response.json();

    dispatch(deleteExternalListingInTourbookAction(analyticsInformation));

    onSuccess(tourbook);
  };
}

export function deleteTourbookListing({
  tourbookId,
  listingId,
  external,
  analyticsInformation,
  onSuccess = () => {},
}: {
  tourbookId: string;
  listingId: string;
  external: boolean;
  analyticsInformation?: TourbookListingAnalyticsInformation;
  onSuccess: (tourbook: TourbookSummary) => void;
}) {
  return external
    ? deleteTourbookExternalListing({
        tourbookId,
        listingId,
        analyticsInformation: analyticsInformation as ExternalListingAnalyticsInformation,
        onSuccess,
      })
    : deleteTourbookInternalListing({ tourbookId, listingId, onSuccess });
}

export type Actions = RemoveListingInTourbookAction | DeleteExternalListingInTourbookAction;
