import { formType } from '@propTypes';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { AdminListing } from '@root/types';
import { useTranslation } from 'react-i18next';
import Button from '@components/shared/V2Button';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Prompt } from 'react-router-dom';
import BuildingDetailsSection from './BuildingDetailsSection';
import ListingDetailsSection from './ListingDetailsSection';
import s from './EditListing.module.less';

const formItemLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 10 },
  },
};

type Props = {
  listing: AdminListing;
  form: any; // antd3 form, not worth typing
  updateListing: (
    listingId: string,
    body: {},
    message: string,
    successCallback?: () => void,
  ) => void;
};

const ListingForm = ({ listing, form, updateListing }: Props) => {
  const { t } = useTranslation('admin');
  const { floor } = form.getFieldsValue();
  const flags = useFlags();

  return (
    <>
      <Prompt when={form.isFieldsTouched()} message={t('common:prompt.unsavedChanges')} />
      <Form
        className={s.form}
        {...formItemLayout}
        onSubmit={e => {
          e.preventDefault();
          const {
            exclusive,
            finishedCeilingHeight,
            finishedCeilingHeightMagnitude,
            finishedCeilingHeightMeasure,
            finishedCeilingHeightUnit,
            leaseType,
            maximumCapacityPersons,
            notMarketable,
            publicPreview,
            slabToSlabHeight,
            slabToSlabHeightMagnitude,
            slabToSlabHeightMeasure,
            slabToSlabHeightUnit,
            status,
            suite,
            suiteType,
            descriptionHtml,
          } = form.getFieldsValue();

          const formValues = {
            floor,
            status,
            suiteType,
            suite,
            exclusive,
            slabToSlabHeight,
            slabToSlabHeightMagnitude,
            slabToSlabHeightUnit,
            slabToSlabHeightMeasure,
            finishedCeilingHeight,
            finishedCeilingHeightMagnitude,
            finishedCeilingHeightUnit,
            finishedCeilingHeightMeasure,
            leaseType,
            maximumCapacityPersons,
            notMarketable,
            publicPreview,
            ...(flags['market.listing-space-descriptions-support-rich-text'] && {
              descriptionHtml,
            }),
          };

          updateListing(listing.id, formValues, t('listing.updateSuccess'), form.resetFields);
        }}
      >
        <BuildingDetailsSection listing={listing} form={form} />
        <ListingDetailsSection listing={listing} form={form} />
        <Form.Item>
          <Button htmlType="submit" disabled={!floor}>
            {t('listing.save')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

ListingForm.propTypes = {
  listing: formType.isRequired,
  form: formType.isRequired,
  updateListing: PropTypes.func.isRequired,
};

export default Form.create({ name: 'listing' })(ListingForm);
