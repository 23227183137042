/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@components/shared/Admin/Container';
import { isEmpty } from 'lodash/fp';
import Spinner from '@components/shared/Spinner';
import routes from '@root/routes';
import api from '@shared/api';
import { AdminProperty } from '@root/types';
import { useParams } from 'react-router-dom';
import PropertyForm from './PropertyForm';
import ExistingBuildingSuggestions from './ExistingBuildingSuggestions';
import s from './PropertyPage.module.less';
import Layout from '../Layout/Layout';

function PropertyPage() {
  const { id } = useParams<{ id: string }>();
  const [property, setProperty] = useState<AdminProperty | null>(null);

  const fetchProperty = async (propertyId: string) => {
    const response = await api.fetch(routes.api.admin.properties(propertyId));
    if (response.ok) {
      setProperty(await response.json());
    }
  };
  useEffect(() => {
    fetchProperty(id);
  }, [id]);

  const [nearbyBuildings, setNearbyBuildings] = useState([]);
  useEffect(() => {
    if (!property || property.geolocationPrecision !== 'building') return;
    const fetchNearbyBuildings = async () => {
      const response = await api.fetch(
        routes.api.admin.buildingSearch({
          latitude: property!.latitude,
          longitude: property!.longitude,
          distance: 50,
        }),
      );
      if (response.ok) {
        const json = await response.json();
        setNearbyBuildings(json.buildings);
      }
    };
    fetchNearbyBuildings();
  }, [property]);

  const { t } = useTranslation('admin');

  return (
    <Layout>
      <Container>
        {property ? (
          <div>
            <PropertyForm property={property} />
            {!isEmpty(nearbyBuildings) ? (
              <>
                <div className={s['existing-building-suggestions-help']}>
                  {t('building.existingBuildingsSuggestionsHelp')}
                </div>
                <ExistingBuildingSuggestions property={property} buildings={nearbyBuildings} />
              </>
            ) : null}
          </div>
        ) : (
          <div className={s['spinner-wrapper']}>
            <Spinner />
          </div>
        )}
      </Container>
    </Layout>
  );
}

export default PropertyPage;
