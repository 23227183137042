import { capitalize } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import type { AdminBuilding } from '@root/types';
import Input from '../../Input';
import ChannelsHeader from '../Header';
import ChannelsSelect from '../Select';
import ChannelsButton from '../Button';
import { STATUSES as MICROSITE_STATUSES } from '../../../Microsite/utils';

import css from '../../styles.module.less';

type ChannelsMicrositeProps = {
  building: AdminBuilding;
  previewLink: string;
  status: string;
};

const ChannelsMicrosite = ({ previewLink, status, building }: ChannelsMicrositeProps) => {
  const { t } = useTranslation('admin');

  const isMicrositeStatusLiveOrReadyForApproval =
    ['live', 'ready_for_approval'].indexOf(building.micrositeStatus) >= 0;

  const showMicrositePreviewLink = isMicrositeStatusLiveOrReadyForApproval && previewLink;

  return (
    <div>
      <ChannelsHeader channel="microsite" />

      {showMicrositePreviewLink ? (
        <a className={css.buildingURL} href={previewLink} target="_blank" rel="noreferrer">
          {previewLink}
        </a>
      ) : null}

      <ChannelsSelect
        key="microsite"
        containerTestId="building-microsite-status"
        labelText={t(`building.basicInfo.micrositeStatus`)}
        name="micrositeStatus"
        options={MICROSITE_STATUSES.map(statusOption => ({
          label: capitalize(statusOption.replace(/_/g, ' ')),
          value: statusOption,
        }))}
        value={status}
      >
        <ChannelsButton show={Boolean(showMicrositePreviewLink)} link={previewLink}>
          {t('building.viewBuildingButton.preview')}
        </ChannelsButton>
      </ChannelsSelect>

      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={t('building.basicInfo.liveAt')}
          name="micrositePublishedAt"
          disabled
        />
        <Input
          containerClass={css.right}
          labelText={t('building.basicInfo.retiredAt')}
          name="micrositeRetiredAt"
          disabled
        />
      </div>
    </div>
  );
};

export default ChannelsMicrosite;
