/* eslint-disable react/sort-comp */
import { Component } from 'react';
import classNames from 'classnames';
import { Form } from '@ant-design/compatible';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { Table, Row, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import queryStringSerializer from '@shared/queryStringSerializer';
import { startCase } from 'lodash';
import api from '@shared/api';
import addKeyTo from '@shared/addKeyTo';
import { Button } from '@components/shared';
import PaginationRow from '@components/shared/PaginationRow';
import routes from '@root/routes';
import utils from '@styles/utils.module.less';
import { locationType, historyType } from '@propTypes';
import css from './Listings.module.less';

const queryFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return queryParams.query || '';
};

const statusSelectionsFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return queryParams.statuses || [];
};

type ListingsProps = {
  buildingId: string;
  t: $TSFixMeFunction;
  location: locationType;
  history: historyType;
};

type ListingsState = $TSFixMe;

export class Listings extends Component<ListingsProps, ListingsState> {
  state = {
    listings: null,
    page: 1,
    pageSize: 0,
    query: queryFrom(this.props.location),
    statusSelections: statusSelectionsFrom(this.props.location),
    totalCount: 0,
  };

  componentDidMount() {
    this.fetchListings();
  }

  fetchListings = async () => {
    const { buildingId } = this.props;
    const { page, query, statusSelections } = this.state;
    const response = await api.fetch(
      // eslint-disable-next-line max-len
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | ParsedQs | string[] | P... Remove this comment to see the full error message
      routes.api.admin.buildingListings(buildingId, page, query, statusSelections),
    );
    const {
      listings,
      pagination: { page: newPage, totalCount, pageSize },
    } = await response.json();
    this.setState({ listings, page: newPage, totalCount, pageSize });
  };

  pushToHistory = (page = 1) => {
    const { history, buildingId } = this.props;
    const { query, statusSelections } = this.state;

    const params = {
      query,
      page,
      statuses: statusSelections,
    };

    const queryString = queryStringSerializer(params);

    history.push(`${routes.admin.buildingListings(buildingId)}&${queryString}`);
  };

  handleSetStatusFilterSelections = statusSelections => {
    this.setState({ statusSelections }, () => {
      this.pushToHistory();
      this.fetchListings();
    });
  };

  listingsWithKey = () => {
    const { listings } = this.state;
    if (!listings) return [];

    return addKeyTo(listings);
  };

  changePage = page => {
    this.setState(
      {
        page,
      },
      this.fetchListings,
    );
  };

  onSearch = e => {
    e.preventDefault();
    this.setState({ page: 1 }, this.fetchListings);
  };

  render() {
    const { t } = this.props;
    const { page, pageSize, totalCount, query, statusSelections } = this.state;
    return (
      <div data-testid="listedSpaces">
        <Form onSubmit={this.onSearch}>
          <Form.Item>
            <Row className={css.filterRow}>
              <Input
                className={classNames(css.searchInput, 'u-m-right-1x')}
                placeholder={t('building.searchPlaceholder')}
                // eslint-disable-next-line max-len
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | ParsedQs | string[] | ParsedQs[]' i... Remove this comment to see the full error message
                value={query}
                name="query"
                onChange={e => this.setState({ query: e.target.value })}
              />
              <div className="u-m-right-3x">
                <Button icon="magnifying-glass" onClick={this.onSearch} type="primary">
                  {t('building.searchButton')}
                </Button>
              </div>
              <div className={classNames(css.filterStatus, 'u-m-right-3x')}>
                <div className={utils.label}>
                  {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                  {statusSelections.length > 0
                    ? t('listing.filters.statusWithCount', { count: statusSelections.length })
                    : t('listing.filters.status')}
                </div>
                <Select
                  className={css.filterDropdown}
                  placeholder={t('listing.status')}
                  mode="multiple"
                  value={statusSelections}
                  onChange={this.handleSetStatusFilterSelections}
                >
                  <Select.Option value="inactive">{t('listing.statuses.inactive')}</Select.Option>
                  <Select.Option value="requested">{t('listing.statuses.requested')}</Select.Option>
                  <Select.Option value="onboarding">
                    {t('listing.statuses.onboarding')}
                  </Select.Option>
                  <Select.Option value="ready_for_approval">
                    {t('listing.statuses.ready_for_approval')}
                  </Select.Option>
                  <Select.Option value="on_market">{t('listing.statuses.on_market')}</Select.Option>
                  <Select.Option value="off_market">
                    {t('listing.statuses.off_market')}
                  </Select.Option>
                  <Select.Option value="retired">{t('listing.statuses.retired')}</Select.Option>
                </Select>
              </div>
            </Row>
          </Form.Item>
        </Form>
        <Table
          columns={[
            {
              title: t('building.vts.propertyIdHeader'),
              dataIndex: ['space', 'vtsPropertyId'],
            },
            {
              title: t('building.listings.floorHeader'),
              dataIndex: 'floor',
              key: 'floor',
            },
            {
              title: t('building.listings.suiteHeader'),
              dataIndex: 'suite',
              key: 'suite',
            },
            {
              title: t('building.listings.statusHeader'),
              dataIndex: 'status',
              key: 'status',
              render: startCase,
            },
            {
              title: t('building.listings.actionsHeader'),
              key: 'action',
              render: listing => (
                <Link to={routes.admin.listingPage(listing.id)}>
                  {t('building.listings.editListingAction')}
                </Link>
              ),
            },
          ]}
          dataSource={this.listingsWithKey()}
          pagination={false}
        />
        <PaginationRow
          page={page}
          totalCount={totalCount}
          pageSize={pageSize}
          changePage={this.changePage}
        />
      </div>
    );
  }
}

// Because react-router removed withRouter and many libraries (including React)
// are removing support for class components. However, there is not time to update
// this component to a functional component
export default function ListingsWrapper(props: Omit<ListingsProps, 't' | 'history' | 'location'>) {
  const { t } = useTranslation('admin');
  const location = useLocation();
  const history = useHistory();
  return <Listings {...props} t={t} history={history} location={location} />;
}
