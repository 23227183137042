import { ReactNode, useState } from 'react';
import { Dialog, UploadIcon } from '@viewthespace/components';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  alternativeAssetSelection: ReactNode;
};

type AssetSelectorModalProps = Props & {
  isOpen: boolean;
  onCloseModal: () => void;
  onClickAlternativeAsset: () => void;
  onClickAssetUpload: () => void;
};

const AssetSelectorModal = ({
  onClickAlternativeAsset,
  onClickAssetUpload,
  onCloseModal,
  title,
  isOpen,
  alternativeAssetSelection,
}: AssetSelectorModalProps) => {
  const { t } = useTranslation('common');

  return (
    <Dialog header={title} isOpen={isOpen} closeOnClickAway onClose={onCloseModal} width="auto">
      <div
        data-testid="asset-selector-dialog-body"
        className="inline-flex items-center bg-background-secondary px-10 py-5"
      >
        <div
          role="button"
          onClick={onClickAssetUpload}
          className="flex h-[147px] w-[264px] items-center justify-center gap-1 border border-solid bg-background-primary"
        >
          <UploadIcon />
          <span>{t('editableAsset.assetSelector.uploadNewImage')}</span>
        </div>
        <span className="p-2">{t('editableAsset.assetSelector.or')}</span>
        <div role="button" onClick={onClickAlternativeAsset}>
          {alternativeAssetSelection}
        </div>
      </div>
    </Dialog>
  );
};
const useAssetSelectorModal = ({ title, alternativeAssetSelection }: Props) => {
  const [shouldUploadNewAsset, setShouldUploadNewAsset] = useState<boolean | null>(null);
  const [isAssetSelectorModalOpen, setIsAssetSelectorModalOpen] = useState<boolean>(false);
  const handleClickAlternativeAsset = () => {
    setShouldUploadNewAsset(false);
  };
  const handleClickAssetUpload = () => {
    setShouldUploadNewAsset(true);
  };

  const handleCloseModal = () => {
    setShouldUploadNewAsset(null);
    setIsAssetSelectorModalOpen(false);
  };

  return {
    assetSelectorModal: (
      <AssetSelectorModal
        onClickAlternativeAsset={handleClickAlternativeAsset}
        onClickAssetUpload={handleClickAssetUpload}
        onCloseModal={handleCloseModal}
        title={title}
        isOpen={isAssetSelectorModalOpen}
        alternativeAssetSelection={alternativeAssetSelection}
      />
    ),
    shouldUploadNewAsset,
    closeAssetSelectorModal: handleCloseModal,
    openAssetSelectorModal: () => setIsAssetSelectorModalOpen(true),
  };
};

export default useAssetSelectorModal;
