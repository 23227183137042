import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import Divider from '@components/shared/Divider';
import { Select, NumberInput, Checkbox } from '@components/shared/forms';
import css from './Windows.module.less';

const Windows = React.forwardRef((_props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
  const { t } = useTranslation('admin');
  const windowTreatmentOptions = [
    { label: t('listing.rooms.form.windows.windowTreatments.shades'), value: 'shades' },
    { label: t('listing.rooms.form.windows.windowTreatments.curtains'), value: 'curtains' },
    { label: t('listing.rooms.form.windows.windowTreatments.blinds'), value: 'blinds' },
    {
      label: t('listing.rooms.form.windows.windowTreatments.noWindowTreatments'),
      value: 'no_window_treatments',
    },
  ];

  const lightScoreOptions = [
    { label: t('listing.rooms.form.windows.lightScore.highLight'), value: 'high_light' },
    { label: t('listing.rooms.form.windows.lightScore.lowLight'), value: 'low_light' },
    { label: t('listing.rooms.form.windows.lightScore.mediumLight'), value: 'medium_light' },
    { label: t('listing.rooms.form.windows.lightScore.noLightScore'), value: 'no_light_score' },
    { label: t('listing.rooms.form.windows.lightScore.outdoorLight'), value: 'outdoor_light' },
  ];

  return (
    <>
      <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-4x', 'u-p-bottom-2x')}>
        {t('listing.rooms.form.windows.title')}
      </h2>
      <section className={css.listingRoomFormSection}>
        <div className={css.windowCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.windows.windowCount')}
            name="windowCount"
          />
        </div>
        <div className={css.windowTreatments}>
          <Select
            labelText={t(`listing.rooms.form.windows.windowTreatments.label`)}
            name="windowTreatments"
            options={windowTreatmentOptions}
            placeholder=""
          />
        </div>
        <div className={css.windowHeight}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.windows.windowHeight')}
            name="windowHeight"
          />
        </div>
        <div className={css.windowSillHeight}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.windows.windowSillHeight')}
            name="windowSillHeight"
          />
        </div>
        <div className={css.windowSides}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.windows.windowSides')}
            name="windowSides"
          />
        </div>
        <div className={css.lightScore}>
          <Select
            labelText={t(`listing.rooms.form.windows.lightScore.label`)}
            name="lightScore"
            options={lightScoreOptions}
            placeholder=""
          />
        </div>
        <div className={css.windowsOpen}>
          <Checkbox name="windowsOpen">
            <span>{t('listing.rooms.form.windows.windowsOpen')}</span>
          </Checkbox>
        </div>
        <div className={css.atrium}>
          <Checkbox name="atrium">
            <span>{t('listing.rooms.form.windows.atrium')}</span>
          </Checkbox>
        </div>
        <div className={css.viewObstructions}>
          <Checkbox name="viewObstructions">
            <span>{t('listing.rooms.form.windows.viewObstructions')}</span>
          </Checkbox>
        </div>
      </section>
      <Divider />
    </>
  );
});

export default React.memo(Windows);
