import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { format, parseISO } from 'date-fns';
import { CustomIcon } from '@components/shared';
import Typography from '@styles/typography.module.less';
import s from './File.module.less';

type Props = {
  filename: string;
  url: string;
  uploadedAt: string | null;
  className?: string;
};

const File = ({ filename, url, uploadedAt, className }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <div className={cn(s.fileContainer, className)}>
      <CustomIcon className={s.fileIcon} type="media-file" />
      <div className={s.fileInfo}>
        <a
          className={cn(Typography.bodyLargeEmphasis, s.filename)}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {filename}
        </a>
      </div>
      <div>
        {uploadedAt ? (
          <span className={cn(Typography.labelXsmall, s.uploadedAt)}>
            {t('media.existingContent.uploadedAt', {
              date: format(parseISO(uploadedAt), 'MM/dd/yyyy'),
            })}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default File;
