import { FieldWrapper } from '@components/shared/forms';
import { BaseSelect } from '@viewthespace/components';
import {
  TextSize,
  textSizeOptions,
  textSizeExtras,
} from '@viewthespace/components/src/lib/rich-text-editor/TextSizeUtils';
import cn from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  labelText: string;
  defaultTextSize?: TextSize;
  containerClass?: string;
};

export const getTextSizeFromClassName = (name?: string): TextSize | null =>
  (Object.entries(textSizeExtras).find(
    ([_textSize, { className }]) => className === name,
  )?.[0] as TextSize) || null;

const CustomListItemContent = ({
  value,
  label,
  defaultTextSize,
}: {
  value?: TextSize;
  label: string;
  defaultTextSize: TextSize;
}) => {
  const { t } = useTranslation('common');

  if (!value) return null;

  return (
    <div className={cn('flex items-baseline p-0 w-full', textSizeExtras[value].styles)}>
      <span>{label}</span>
      {value === defaultTextSize && (
        <span className="text-[14px] ml-0.5 text-text-subdued font-normal self-center leading-[16px]">
          {t('textSize.default')}
        </span>
      )}
    </div>
  );
};

const TextSizeDropdown = ({
  name,
  labelText,
  defaultTextSize = 'body18',
  containerClass,
}: Props) => {
  const [field, _meta, helpers] = useField(name);
  const currentTextSize = getTextSizeFromClassName(field.value) || defaultTextSize;

  return (
    <FieldWrapper {...{ name, labelText, containerClass }}>
      <BaseSelect
        label=""
        hideLabel
        options={textSizeOptions}
        className="!border-none !outline-none"
        menuClassName="!w-[410px] !h-330px"
        value={textSizeOptions.find(option => option.value === currentTextSize)}
        selectionType="single"
        backgroundStyle="filled"
        onChange={option => {
          if (!option?.value) return;

          const { className } = textSizeExtras[option.value];
          if (className) {
            helpers.setValue(className);
            helpers.setTouched(true);
          }
        }}
        renderListItemContent={({ value, label }) => (
          <CustomListItemContent defaultTextSize={defaultTextSize} value={value} label={label} />
        )}
      />
    </FieldWrapper>
  );
};

export default TextSizeDropdown;
