import cn from 'classnames';
import s from './SkeletonListItem.module.less';

const SkeletonListItem = () => {
  return (
    <div data-testid="skeletonListItem" className={s.item}>
      <div className={s.photoTitleContainer}>
        <div className={cn(s.photo, s.shimmer)} />
        <div className={cn(s.title, s.shimmer)} />
      </div>
      <div className={cn(s.info, s.shimmer)} />
    </div>
  );
};

export default SkeletonListItem;
