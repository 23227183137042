import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { adminMediaType } from '@propTypes';
import routes from '@root/routes';
import { VirtualTourSpotlights } from '@root/types';
import api from '@shared/api';
import VideoSection from '@components/shared/Admin/VideoSection';
import ThreeDContent from '@components/shared/Admin/ThreeDContent/ThreeDContent';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BuildingMediaPhotoSection from './BuildingMediaPhotoSection/BuildingMediaPhotoSection';

type OwnProps = {
  fetchAdminBuilding: $TSFixMeFunction;
  photos?: adminMediaType[];
  archivedPhotos?: adminMediaType[];
  video?: adminMediaType;
  buildingId: string;
  tourEmbedUrl: string | null;
  firstPhotoOrientation: 'landscape' | 'portrait';
  virtualTourSpotlight: VirtualTourSpotlights;
};

// @ts-expect-error ts-migrate(2456) FIXME: Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof BuildingMediaTable.defaultProps;

const BuildingMediaTable = ({
  photos = [],
  archivedPhotos = [],
  video = null,
  archivedVideos = [],
  fetchAdminBuilding,
  buildingId,
  firstPhotoOrientation,
  tourEmbedUrl,
  virtualTourSpotlight,
}: Props) => {
  const { t } = useTranslation('admin');

  const flags = useFlags();
  const newVideoOffset = flags['market-retail.mp-listing-requested-at-date'] ? 1 : 0;

  const videoUploadRequest = uploadedFile =>
    api.post(routes.api.admin.buildingVideoCreate(buildingId), {
      cloudinary_id: uploadedFile.cloudinaryId,
      filename: uploadedFile.filename,
      poster_start_offset: newVideoOffset,
      processed_at: uploadedFile.processedAt,
    });

  const updateVirtualTourSpotlight = async (newValue: Exclude<VirtualTourSpotlights, 'unset'>) => {
    const resp = await api.put(routes.api.admin.building(buildingId), {
      virtualTourSpotlight: newValue,
    });

    if (resp.ok) {
      fetchAdminBuilding();
      return true;
    }
    return false;
  };

  const deleteBuildingVideo = async (videoId = video?.id) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('media.deleteConfirmation'))) {
      const resp = await api.delete(routes.api.admin.buildingVideoDelete(buildingId, videoId));
      const json = await resp.json();
      if (resp.ok) {
        fetchAdminBuilding();
        notification.success({ message: json.message });
      } else {
        notification.error({ message: json.errors[0].message });
      }
    }
  };

  const selectLiveBuildingVideo = async (videoId: string) => {
    const resp = await api.put(routes.api.admin.buildingVideoUpdate(buildingId, videoId), {
      archived: false,
    });
    if (resp.ok) {
      fetchAdminBuilding();
      notification.success({ message: t('media.videoSelectSuccess') });
    } else {
      notification.success({ message: t('media.videoSelectFailure') });
    }
  };

  const hideBuildingVideo = async (videoId: string) => {
    const resp = await api.put(routes.api.admin.buildingVideoUpdate(buildingId, videoId), {
      archived: true,
    });
    if (resp.ok) {
      fetchAdminBuilding();
      notification.success({ message: t('media.videoHideSuccess') });
    } else {
      notification.success({ message: t('media.videoHideFailure') });
    }
  };

  const updateThumbnailPosterTimestamp = async (posterStartOffset: number | null) => {
    const resp = await api.put(routes.api.admin.buildingVideo(buildingId, video?.id), {
      posterStartOffset,
    });
    return resp;
  };

  const handleUpdateTourEmbedUrl = async (value: string) => {
    const resp = await api.put(routes.api.admin.building(buildingId), {
      tour_embed_url: value,
    });
    const json = await resp.json();

    if (resp.ok) {
      fetchAdminBuilding();
      return true;
    }
    notification.error({ message: json.messages });
    return false;
  };

  return (
    <>
      <BuildingMediaPhotoSection
        fetchAdminBuilding={fetchAdminBuilding}
        buildingId={buildingId}
        firstPhotoOrientation={firstPhotoOrientation}
        photos={photos}
        archivedPhotos={archivedPhotos}
      />
      <div className="u-p-top-5x">
        <VideoSection
          data-testid="video-table-container"
          key={video?.id}
          video={video}
          archivedVideos={archivedVideos}
          onSuccess={fetchAdminBuilding}
          spotlightChecked={!!video && !!tourEmbedUrl}
          videoTableTitle={t('media.buildingVideoTitle')}
          videoHelpText={t('media.buildingVideoHelpText')}
          videoSelectText={t('media.buildingSelectVideo')}
          videoUploadRequest={videoUploadRequest}
          onVideoDelete={deleteBuildingVideo}
          onVideoSelect={selectLiveBuildingVideo}
          onVideoHide={hideBuildingVideo}
          onPosterUpdate={updateThumbnailPosterTimestamp}
          onUpdateVirtualTourSpotlight={updateVirtualTourSpotlight}
          virtualTourSpotlight={virtualTourSpotlight}
          totalVariableColumnsWidth="52vw"
        />
      </div>
      <div className="u-p-top-5x">
        <ThreeDContent
          tourEmbedUrl={tourEmbedUrl}
          onVirtualTourSpotlightUpdate={updateVirtualTourSpotlight}
          onTourEmbedUrlUpdate={handleUpdateTourEmbedUrl}
          hasVideo={!!video}
          virtualTourSpotlight={virtualTourSpotlight}
          tableTitle={t('media.building3dContentTitle')}
        />
      </div>
    </>
  );
};

export default BuildingMediaTable;
