import {
  TourbookListing,
  TourbookListingAnalyticsInformation,
  ExternalListingAnalyticsInformation,
  CountryCode,
} from '@root/types';
import { isInIframe } from '@shared/iframeUtils';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Title from '@components/layouts/Truva/Tourbook/Title';
import ExclusiveLabel from '@components/shared/ExclusiveLabel';
import ListingShareUrlHelper from '@shared/listingShareUrlHelper';
import HTMLComment from 'react-html-comment';
import {
  Button,
  ButtonLink,
  CustomIcon,
  OnlyInDesktopTablet,
  OnlyInMobile,
} from '@components/shared';
import { PARAMETERS } from '@root/tracking/constants';
import routes from '@root/routes';
import TourbookMosaicGallery from '@components/layouts/Truva/Tourbook/TourbookMosaicGallery';
import { useTranslation, Trans } from 'react-i18next';
import actions from '@store/actions/tourbookPage';
import { connect } from 'react-redux';
import useAnalytics from '@shared/useAnalytics';
import useCurrentUser from '@shared/useCurrentUser';
import { useBreakpoint } from '@shared/useBreakpoints';
import { countryToCurrencyCode } from '@shared/currencyByCountry';
import { stringify } from 'qs';
import utmParamsForListingView from '@shared/utmParamsForListingView';
import BrochureDownloadLink from '@components/shared/BrochureDownloadLink/BrochureDownloadLink';
import ListingAttributes from '../ListingAttributes';
import ListingDescription, { ListingDescriptionHtml } from '../ListingDescription';
import s from './TourbookListingCard.module.less';

const DEFAULT_COUNTRY_CODE = 'US';

const ViewListingButton = ({ onClick, url }: { onClick: () => void; url: string }) => {
  const { t } = useTranslation('tourbook');

  return (
    <ButtonLink
      onClick={onClick}
      href={url}
      type="tertiary"
      size="medium"
      openInNewTab
      data-testid="view-listing-button"
      className={cn(s.buttonsWithPaddingOverride, s.viewFullDetailsButton)}
    >
      <CustomIcon type="linkOut" className={s.viewFullDetailsLinkOutIcon} />
      <span className={s.text}>{t('listing:listingPreview.viewFullDetails')}</span>
    </ButtonLink>
  );
};

const mapDispatch = {
  tourbookViewListing: listingAnalyticsInformation =>
    actions.tourbookViewListing(listingAnalyticsInformation),
  trackLightboxThumbnailClick: listingAnalyticsInformation =>
    actions.lightboxThumbnailClicked(listingAnalyticsInformation),
  trackLightboxPagination: listingAnalyticsInformation =>
    actions.lightboxPaginationClicked(listingAnalyticsInformation),
  trackMediaAssetOpening: listingAnalyticsInformation =>
    actions.mediaAssetOpened(listingAnalyticsInformation),
  trackMapDirectionsClicked: listingAnalyticsInformation =>
    actions.tourbookListingMapDirectionsClicked(listingAnalyticsInformation),
  tourbookEditExternalListing: externalListingAnalyticsInformation =>
    actions.tourbookEditExternalListing(externalListingAnalyticsInformation),
  trackLightboxFloorPlanClick: (listingAnalyticsInformation, external) =>
    actions.floorplanOpened(listingAnalyticsInformation, external),
};

const connector = connect(null, mapDispatch);

type TourbookListingCardProps = {
  tourbookId: string;
  listing: TourbookListing;
  ownerVTSId: number;
  handleOnRemoveListingClick: () => void;
  onOpenListingPreview?: () => void;
  onHover?: () => void;
  onHoverExit?: () => void;
  readOnly: boolean;
  tourbookViewListing: (listingAnalyticsInformation: TourbookListingAnalyticsInformation) => void;
  trackLightboxThumbnailClick: (
    listingAnalyticsInformation: TourbookListingAnalyticsInformation,
  ) => void;
  trackLightboxPagination: (
    listingAnalyticsInformation: TourbookListingAnalyticsInformation,
  ) => void;
  trackLightboxFloorPlanClick: (
    listingAnalyticsInformation: TourbookListingAnalyticsInformation,
    external: boolean,
  ) => void;
  trackMapDirectionsClicked: (
    listingAnalyticsInformation: TourbookListingAnalyticsInformation,
  ) => void;
  tourbookEditExternalListing: (
    externalListingAnalyticsInformation: ExternalListingAnalyticsInformation,
  ) => void;
};

export const TourbookListingCard = ({
  tourbookId,
  listing,
  ownerVTSId,
  handleOnRemoveListingClick,
  onHover = () => {},
  onHoverExit = () => {},
  onOpenListingPreview = () => {},
  readOnly,
  tourbookViewListing = () => {},
  trackLightboxThumbnailClick,
  trackLightboxPagination,
  trackLightboxFloorPlanClick,
  trackMapDirectionsClicked,
  tourbookEditExternalListing,
}: TourbookListingCardProps) => {
  const { t } = useTranslation('tourbook');
  const { brochureInteraction } = useAnalytics();
  const { isMobile } = useBreakpoint();
  const { currentUser } = useCurrentUser();
  const flags = useFlags();

  const userIsLoggedIn = !!currentUser;

  const {
    address,
    building,
    description,
    id: listingId,
    flaggedAt,
    minAskingRentInMoneyPerAreaPerPeriod,
    minAskingRentInMoneyPerPeriod,
    maxAskingRentInMoneyPerAreaPerPeriod,
    maxAskingRentInMoneyPerPeriod,
  } = listing;

  const listingIdToUpdate = (
    listing.type === 'externalListing'
      ? listing.externalTourbookListingId
      : listing.tourbookListingId
  ) as string;

  const currencyCode = countryToCurrencyCode(
    (address.countryCode as CountryCode) || DEFAULT_COUNTRY_CODE,
  );

  const encodedData = ListingShareUrlHelper.encode({
    listingId,
    userId: ownerVTSId.toString(),
    hideLocation: false,
  });

  // can remove with vts-trackable-parameters flag
  const queryString = stringify(utmParamsForListingView(tourbookId, PARAMETERS.tourbookPage), {
    addQueryPrefix: true,
  });

  // can remove with vts-trackable-parameters flag
  // eslint-disable-next-line no-nested-ternary
  const viewListingUrlWithUtmParams = isInIframe()
    ? `${routes.vtsMarketListingShare(listingId)}${queryString}`
    : userIsLoggedIn
      ? `${routes.listing(listingId, building.slug)}${queryString}`
      : `${routes.listingShare(encodedData)}${queryString}`;

  const getViewListingUrl = () => {
    let viewListingUrl: string;

    if (flags['vts-trackable-parameters']) {
      if (isInIframe()) {
        viewListingUrl = routes.vtsMarketListingShare(listingId);
      } else if (userIsLoggedIn) {
        viewListingUrl = routes.listing(listingId, building.slug);
      } else {
        viewListingUrl = routes.listingShare(encodedData);
      }
    } else {
      viewListingUrl = viewListingUrlWithUtmParams;
    }
    return viewListingUrl;
  };

  const onViewListing = () => {
    tourbookViewListing(listing.analyticsInformation);
  };

  const onOpenDirections = () => {
    trackMapDirectionsClicked(listing.analyticsInformation);
  };

  const openMapDirections = location => {
    const baseMapsURL = `https://www.google.com/maps/dir/?api=1&destination=`;
    const url = `${baseMapsURL}${location.latitude},${location.longitude}`;
    onOpenDirections();
    window.open(url, '_blank');
  };

  const mapButton = location => {
    return (
      <CustomIcon
        className={s.directionsIcon}
        type="location"
        onClick={() => openMapDirections(location)}
      />
    );
  };

  const EditEntryButton = () => {
    return (
      <ButtonLink
        href={routes.tourbookEditExternalListing(tourbookId, listingId)}
        type="tertiary"
        size="small"
        testid="editLink"
        className={s.buttonsWithPaddingOverride}
        onClick={() => {
          tourbookEditExternalListing(
            listing.analyticsInformation as ExternalListingAnalyticsInformation,
          );
        }}
      >
        <CustomIcon className={s.viewFullDetailsLinkOutIcon} type="edit" />
        {isMobile ? '' : t('externalListing.updateEntry')}
      </ButtonLink>
    );
  };

  const DeleteListingButton = () => {
    return (
      <Button
        type="tertiary"
        onClick={handleOnRemoveListingClick}
        data-testid="trashIcon"
        className={s.updatedDeleteIcon}
      >
        <CustomIcon type="trash" />
      </Button>
    );
  };

  if (readOnly && flaggedAt) {
    return null;
  }

  return (
    <div className={s.container} onMouseEnter={onHover} onMouseLeave={onHoverExit} id={listing.id}>
      <HTMLComment text={`${listing.external ? 'External ' : ''}Listing ID: ${listing.id}`} />
      <div className={flaggedAt ? s.flagged : undefined}>
        <div className="mb-1 flex w-full justify-between mobile:mb-2">
          <Title
            address={address}
            neighborhood={building.neighborhood}
            directionsButton={mapButton(listing)}
          />
          <div data-testid="titleButtons">
            <div className={s.linkAndDeleteIcon}>
              <OnlyInDesktopTablet className={s.flexInlineViewportWrapper}>
                {!listing.external && (
                  <>
                    {userIsLoggedIn && (
                      <Button
                        type="tertiary"
                        size="small"
                        className={s.previewButton}
                        onClick={onOpenListingPreview}
                      >
                        {t('preview')}
                      </Button>
                    )}
                    <ViewListingButton onClick={onViewListing} url={getViewListingUrl()} />
                  </>
                )}
                {listing.external && !readOnly ? <EditEntryButton /> : null}
                {!readOnly && <DeleteListingButton />}
              </OnlyInDesktopTablet>
              <OnlyInMobile>
                <div className={s.linkAndDeleteIcon}>
                  {!listing.external && (
                    <ViewListingButton onClick={onViewListing} url={getViewListingUrl()} />
                  )}
                  {listing.external && !readOnly ? <EditEntryButton /> : null}
                  {!readOnly && <DeleteListingButton />}
                </div>
              </OnlyInMobile>
            </div>
          </div>
        </div>
        {listing.type === 'listing' && listing.brochures.length > 0 ? (
          <div className="mb-1 flex gap-3 mobile:mb-2 mobile:flex-col mobile:gap-[4px]">
            {listing.brochures.map(brochure => (
              <div key={brochure.url}>
                <BrochureDownloadLink
                  brochure={brochure}
                  onClick={() =>
                    brochureInteraction({
                      listing,
                      sourceContent: PARAMETERS.tourbookListing,
                      sourcePage: isInIframe()
                        ? PARAMETERS.marketTourbookPage
                        : PARAMETERS.tourbookPage,
                      otherAttributes: {
                        attachmentId: brochure.id,
                        attachmentName: brochure.name,
                      },
                    })
                  }
                  lineWrap={2}
                />
              </div>
            ))}
          </div>
        ) : null}
        <div className={s.mosaicGalleryContainer}>
          {listing.isCurrentlyExclusive && listing.status === 'on_market' ? (
            <div className={s.exclusiveLabelContainer}>
              <ExclusiveLabel size="medium" />
            </div>
          ) : null}
          {listing.status === 'off_market' ? (
            <div className={s.offMarket}>{t('listingCard.offMarket')}</div>
          ) : null}
          <TourbookMosaicGallery
            photos={listing.photos}
            video={listing.video}
            sourceContent={PARAMETERS.tourbookListingGallery}
            sourcePage={PARAMETERS.tourbookPage}
            trackLightboxPagination={() => trackLightboxPagination(listing.analyticsInformation)}
            trackLightboxThumbnailClick={() =>
              trackLightboxThumbnailClick(listing.analyticsInformation)
            }
            trackLightboxFloorPlanClick={() => {
              trackLightboxFloorPlanClick(listing.analyticsInformation, listing.external);
            }}
            imagesZipUrl={listing.createImageZipUrl}
            tourEmbedUrl={listing.tourEmbedUrl}
            address={listing.address}
            external={listing.external}
            analyticsInformation={listing.analyticsInformation}
          />
        </div>
        <ListingAttributes
          askingRentInMoneyPerAreaPerPeriod={listing.askingRentInMoneyPerAreaPerPeriod}
          askingRentInMoneyPerPeriod={listing.askingRentInMoneyPerPeriod}
          availableToday={listing.availableToday}
          condition={listing.condition}
          currencyCode={currencyCode}
          dateAvailable={listing.dateAvailable}
          isExternal={listing.external}
          leaseType={listing.leaseType}
          maxAskingRentInMoneyPerAreaPerPeriod={maxAskingRentInMoneyPerAreaPerPeriod}
          maxAskingRentInMoneyPerPeriod={maxAskingRentInMoneyPerPeriod}
          minAskingRentInMoneyPerAreaPerPeriod={minAskingRentInMoneyPerAreaPerPeriod}
          minAskingRentInMoneyPerPeriod={minAskingRentInMoneyPerPeriod}
          minMaxArea={listing.minMaxArea}
          readOnly={readOnly}
          size={listing.size}
          tourbookListingId={listingIdToUpdate}
        />

        {flags['market.listing-space-descriptions-support-rich-text'] ? (
          <ListingDescriptionHtml
            description={listing.descriptionHtml}
            readOnly={readOnly}
            tourbookListingId={listingIdToUpdate}
          />
        ) : (
          <ListingDescription
            description={description}
            readOnly={readOnly}
            tourbookListingId={listingIdToUpdate}
          />
        )}
      </div>
      {flaggedAt && (
        <>
          <div className={s.flaggedOverlay} />
          <div className={s.flaggedDialogContainer}>
            <div className={s.flaggedDialog}>
              <div>
                <CustomIcon className={s.flaggedIcon} type="info" />
              </div>
              <div>
                {/* eslint-disable react/jsx-no-literals */}
                <Trans i18nKey="flaggedContent.message" ns="tourbook">
                  This entry has been flagged as containing potentially infringing content. Please
                  contact
                  <a href="mailto:support@vts.com"> support@vts.com </a>
                  for more information.
                </Trans>
                {/* eslint-enable react/jsx-no-literals */}
              </div>
              <div>
                <Button type="secondary" size="small" onClick={handleOnRemoveListingClick}>
                  {t('flaggedContent.delete')}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default connector(TourbookListingCard);
