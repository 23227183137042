import { useState, useRef, useEffect } from 'react';
import cn from 'classnames';
import useAltText from '@root/shared/useAltText';

type Props = { userName: string; photoUrl: string; className?: string };

const CompanyLogo = ({ userName, photoUrl, className }: Props) => {
  const { getAltTextForUserCompanyLogo } = useAltText();
  const [imageHasError, setImageHasError] = useState(false);
  const image = useRef<HTMLImageElement | null>(null);
  useEffect(() => {
    if (image.current) {
      image.current.addEventListener('error', () => setImageHasError(true));
    }
  }, []);

  return (
    <span data-testid="company-logo" className={cn('h-full w-full rounded-full', className)}>
      {!imageHasError && (
        <img
          src={photoUrl}
          alt={getAltTextForUserCompanyLogo(userName)}
          ref={image}
          className="rounded-full bg-white"
        />
      )}
    </span>
  );
};

export default CompanyLogo;
