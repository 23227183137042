import { useRef, useState, useLayoutEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import IconButton from '@components/shared/IconButton/IconButton';
import useSize from '@react-hook/size';
import s from './SearchCriteriaBar.module.less';
import FilterPill, { FilterPillOptions } from './FilterPill/FilterPill';

const OVERFLOW_THRESHOLD_IN_PX = 29;

type SearchCriteriaBarProps = {
  options: FilterPillOptions[];
  onFilterPillClick: (filterkey: string, filter: string, value?: string) => void;

  removeAll: () => void;
};

const SearchCriteriaBar = ({ options, onFilterPillClick, removeAll }: SearchCriteriaBarProps) => {
  const { t } = useTranslation();
  const [barIsCollapsed, setBarIsCollapsed] = useState(true);
  const pillSectionRef = useRef<HTMLDivElement | null>(null);
  // unfortunately can't use height here because we need
  // to know if the scroll height of the pills is more than 29px to disable
  // the expand/collapse rather than the height which will be 28px
  const [width] = useSize(pillSectionRef);
  const [pillsAreOverflowing, setPillsAreOverflowing] = useState(false);

  useLayoutEffect(() => {
    const pillSectionHeight = pillSectionRef?.current?.scrollHeight;
    setPillsAreOverflowing(!!pillSectionHeight && pillSectionHeight > OVERFLOW_THRESHOLD_IN_PX);
  }, [pillSectionRef, width]);

  const noOptions = options.length < 1;

  const pillLabelsStillLoading = options.some(option => !option.label);

  return (
    <div
      data-testid="searchCriteriaBar"
      className={cn(s.pillContainer, !barIsCollapsed && s.expanded)}
    >
      <div className={s.titleAndPills}>
        <div className={s.titleSection}>
          <div className={s.title}>{t('searchCriteriaBar:searchCriteria')}</div>
          <IconButton
            data-testid="expand-collapse-button"
            className={cn(s.expandCollapseButton, noOptions && s.hidden)}
            icon={barIsCollapsed ? 'chevron-down' : 'chevron-up'}
            disabled={!pillsAreOverflowing}
            onClick={() => {
              setBarIsCollapsed(!barIsCollapsed);
            }}
          />
        </div>
        <div className={s.pillSection} ref={pillSectionRef}>
          {!pillLabelsStillLoading &&
            options &&
            options.map(({ label, filterKey, filter, value }) => {
              return (
                <div className={s.pill} key={`FilterPill-${filterKey}`}>
                  <FilterPill
                    label={label}
                    filterKey={filterKey}
                    filter={filter}
                    value={value}
                    onPillClick={onFilterPillClick}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className={s.searchControls}>
        <button
          className={cn(s.clearFiltersButton, noOptions && s.hidden)}
          onClick={removeAll}
          type="button"
        >
          {t('searchCriteriaBar:clearFilters')}
        </button>
      </div>
    </div>
  );
};

export default memo(SearchCriteriaBar);
