import { useLocation } from 'react-router';
import { parse } from 'qs';

const useQueryParams = () => {
  const { search } = useLocation();

  return parse(search, { ignoreQueryPrefix: true });
};

export default useQueryParams;
