import { useBreakpoint } from '@shared/useBreakpoints';
import cn from 'classnames';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import CustomIcon from '../CustomIcon';
import InvisibleMask from '../InvisibleMask';

export type SidePanelProps = {
  direction?: 'left' | 'right';
  isOpen?: boolean;
  onClose?: () => void;
  closeOnMaskClick?: boolean;
  children: React.ReactNode;
  className?: string;
  invisibleMaskClassName?: string;
  widthWithUnit?: string;
  fullscreen?: boolean;
};

const SidePanel = ({
  children,
  isOpen = false,
  direction = 'right',
  onClose = () => {},
  widthWithUnit = '495px',
  fullscreen,
  className,
  invisibleMaskClassName,
  closeOnMaskClick = true,
}: SidePanelProps) => {
  const { isMobile } = useBreakpoint();
  const isFullscreen = isNil(fullscreen) ? isMobile : fullscreen;
  const panelIsLeft = direction === 'left';
  const actualWidth = isFullscreen ? '100%' : widthWithUnit;

  const handleOnClose = e => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  useEffect(() => {
    // Re-enable scrolling on unmount just in case
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const closeButton = (
    <button
      data-testid="side-panel-close-button"
      type="button"
      className="u-p-top-2x u-p-right-2x absolute right-0 top-0 z-[31] text-black-100"
      onClick={handleOnClose}
    >
      <CustomIcon type="close" />
    </button>
  );

  return (
    <div>
      <div
        className={cn(
          `fixed z-10 h-full bg-black-003`,
          isFullscreen && `inset-0 overflow-y-auto`,
          'duration-300', // For translate-x
          panelIsLeft ? 'left-0' : 'right-0',
          !isOpen && 'invisible',
          className,
        )}
        style={{
          width: actualWidth,
          ...(!isOpen
            ? {
                transform: panelIsLeft
                  ? `translateX(-${actualWidth})`
                  : `translateX(${actualWidth})`,
              }
            : {}),
        }}
      >
        {closeButton}
        {children}
      </div>
      {isFullscreen || !isOpen ? null : (
        <InvisibleMask
          className={cn('z-[-1] !bg-black-100/50', invisibleMaskClassName)}
          onClick={closeOnMaskClick ? onClose : undefined}
        />
      )}
    </div>
  );
};

export default SidePanel;
