import { createPolyline, latLng, latLngBounds } from '@shared/googleMaps';

class SearchInMapAreaDrawer {
  mapAreaBoundaryPolygon: google.maps.Polyline | null;

  mapAreaBounds: google.maps.LatLngBounds | null;

  constructor() {
    this.mapAreaBoundaryPolygon = null;
    this.mapAreaBounds = null;
  }

  createMapAreaBoundaries = (coordinateString: string | null, map: google.maps.Map) => {
    this.clearMapAreaBoundaries();

    if (!coordinateString) return;
    const coordinates = coordinateString.split(',');
    const southWest = {
      lat: Number(coordinates[0]),
      lng: Number(coordinates[1]),
    };
    const northEast = {
      lat: Number(coordinates[2]),
      lng: Number(coordinates[3]),
    };
    this.mapAreaBounds = latLngBounds(southWest, northEast);

    if (this.mapAreaBounds) {
      const NECorner = this.mapAreaBounds.getNorthEast();
      const SWCorner = this.mapAreaBounds.getSouthWest();
      const NWCorner = latLng(NECorner.lat(), SWCorner.lng());
      const SECorner = latLng(SWCorner.lat(), NECorner.lng());

      const mapAreaPolygon = createPolyline([NWCorner, NECorner, SECorner, SWCorner, NWCorner]);
      mapAreaPolygon.setMap(map);
      this.mapAreaBoundaryPolygon = mapAreaPolygon;
    }
  };

  clearMapAreaBoundaries = () => {
    this.mapAreaBoundaryPolygon?.setMap(null);
    this.mapAreaBoundaryPolygon = null;
  };
}

export default SearchInMapAreaDrawer;
