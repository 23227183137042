// can remove entire file with vts-trackable-parameters flag
import { PARAMETERS } from '@root/tracking/constants';
import { isNull } from 'lodash';

type UtmParamsForListingView = {
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
};

const utmParamsForListingView = (
  tourbookId: string | null,
  sourcePage: string,
): UtmParamsForListingView | {} => {
  if (isNull(tourbookId) || !sourcePage) {
    return {};
  }
  if (sourcePage === PARAMETERS.tourbookPage) {
    return {
      utm_campaign: 'tourbook-share',
      utm_medium: 'web',
      utm_source: 'listing',
      utm_term: tourbookId,
    };
  }
  return {};
};

export default utmParamsForListingView;
