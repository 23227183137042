import { Fragment } from 'react';
import { range } from 'lodash';
import cn from 'classnames';
import { OnlyInDesktop, OnlyInDesktopTablet, OnlyInMobile, OnlyInTablet } from '@components/shared';
import s from './BuildingInfoSkeleton.module.less';

const NUMBER_OF_BOX_ITEMS = 2;

const BuildingInfoSkeleton = () => {
  const infoSkeleton = () => {
    return (
      <>
        {range(NUMBER_OF_BOX_ITEMS).map(i => {
          return (
            <Fragment key={i}>
              <div className={cn(s.box2, s.shimmer)} />
              <div className={cn(s.box3, s.shimmer)} />
            </Fragment>
          );
        })}
        <div className={cn(s.box4, s.shimmer)} />
      </>
    );
  };
  return (
    <>
      <OnlyInDesktopTablet>
        <div className={cn(s.box1, s.shimmer)} />
      </OnlyInDesktopTablet>
      <OnlyInDesktop>{infoSkeleton()}</OnlyInDesktop>
      <OnlyInTablet>
        {range(NUMBER_OF_BOX_ITEMS).map(i => {
          return <Fragment key={i}>{infoSkeleton()}</Fragment>;
        })}
      </OnlyInTablet>
      <OnlyInMobile>{infoSkeleton()}</OnlyInMobile>
    </>
  );
};

export default BuildingInfoSkeleton;
