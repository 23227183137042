import { SpaceCondition } from '@root/types';

const orderSpaceConditions = (
  spaceConditions: SpaceCondition[],
  translate: (spaceConditionKey: string) => string,
) =>
  [...spaceConditions].sort((a, b) => {
    const compareA = translate(`spaceConditions:${a}`);
    const compareB = translate(`spaceConditions:${b}`);

    if (compareA > compareB) return 1;
    if (compareA < compareB) return -1;
    return 0;
  });

export default orderSpaceConditions;
