import s from './UpdateSearchDropdown.module.less';

type OptionType = {
  label: string;
  onClick: any;
};
type Props = {
  options: Array<OptionType>;
};

function DropdownMenuItem({ label, onClick }: OptionType) {
  return (
    <li>
      <button type="button" onClick={onClick}>
        {label}
      </button>
    </li>
  );
}

export default function DropdownMenu({ options }: Props) {
  return (
    <ul className={s.dropdownMenu}>
      {options.map(option => (
        <DropdownMenuItem {...option} key={option.label} />
      ))}
    </ul>
  );
}
