import cn from 'classnames';
import {
  CloudinaryFileAreaInput,
  Form,
  SubmitButton,
  TextAreaAutosize as TextArea,
  TextInput,
  RawLabel as Label,
} from '@components/shared/forms';
import { UploadApiResponse as CloudinaryResponse } from 'cloudinary';
import { Button, Card, CustomIcon, Modal, Notification } from '@components/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useEnv from '@shared/useEnv';
import { isInIframe } from '@shared/iframeUtils';
import { ErrorResponse, Tourbook, NewTourbook, TourbookSummary } from '@root/types';
import { Image } from 'cloudinary-react';
import tourbooksActions from '@store/actions/tourbooks';
import actions from '@store/actions/tourbookPage';
import { useDispatch, connect } from 'react-redux';
import { CompanyLogo } from '@components/shared/CustomIcon/icons';
import s from './EditTourbookModal.module.less';

const mapDispatch = {
  tourbookEdit: () => actions.tourbookEdit(),
};

const connector = connect(null, mapDispatch);

type ModalAction = 'create' | 'edit';

type Props = {
  tourbook?: Tourbook | NewTourbook;
  isModalOpen: boolean;
  onCloseModal: () => void;
  onCreateSuccess?: (tourbookSummary: TourbookSummary) => void;
  tourbookEdit: () => void;
  actionType?: ModalAction;
};

type CloudinaryIdTypes = 'header' | 'clientLogo';

const emptyTourbook = {
  id: '',
  name: '',
  clientLogoCloudinaryId: '',
  headerimage: null,
  clientName: '',
};

const EditTourbookModal = ({
  tourbook = emptyTourbook,
  isModalOpen,
  onCloseModal,
  onCreateSuccess = () => {},
  tourbookEdit = () => {},
  actionType = 'edit',
}: Props) => {
  const { t } = useTranslation('tourbook');
  const dispatch = useDispatch();

  const inIframe = isInIframe();

  const [description, setDescription] = useState(tourbook.description || '');
  const { cloudinaryCdn, cloudinaryCloud } = useEnv();
  const [cloudinaryLoading, setCloudinaryLoading] = useState(false);
  const [headerImageCloudinaryId, setHeaderImageCloudinaryId] = useState<string | null>(
    tourbook.headerImage?.cloudinaryId || null,
  );
  const [clientLogoCloudinaryId, setClientLogoCloudinaryId] = useState<string | null>(
    tourbook.clientLogoCloudinaryId,
  );
  const [isFormDirty, setIsFormDirty] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(90, t('manageDialogs.nameMaxMessage'))
      .required(t('manageDialogs.requiredNameMessage')),
    description: Yup.string().max(160, t('manageDialogs.descriptionMaxMessage')),
    clientName: Yup.string().nullable().max(50, t('manageDialogs.clientNameMaxMessage')),
  });

  const handleCloudinaryFileInputLoading = (loading: boolean) => {
    setCloudinaryLoading(loading);
  };

  const createErrorNotification = (error: string = '') => {
    Notification.error({
      title: t(actionType === 'create' ? 'notification.createError' : 'notification.updateError'),
      text: error,
    });
  };

  const onReady = (type: CloudinaryIdTypes) => (response: CloudinaryResponse) => {
    if (type === 'header') {
      setHeaderImageCloudinaryId(response.public_id);
    } else if (type === 'clientLogo') {
      setClientLogoCloudinaryId(response.public_id);
    }
    setIsFormDirty(true);
  };

  const handleCreate = (data, helpers) => {
    dispatch(
      tourbooksActions.createTourbook(data, {
        onSuccess: (tourbookSummary: TourbookSummary) => {
          Notification.info({
            title: t('toasts.tourbookCreatedTitle'),
            placement: 'bottomLeft',
            duration: 3.5,
          });
          onCloseModal();
          onCreateSuccess(tourbookSummary);
        },

        onError: (response: ErrorResponse) => {
          createErrorNotification();
          response.errors.forEach(error => {
            helpers.setFieldError(error.field!, error.message);
          });
        },
      }),
    );
  };

  const handleEdit = (data, helpers) => {
    dispatch(
      tourbooksActions.updateTourbook(tourbook.id, data, {
        onSuccess: () => {
          Notification.info({
            title: t('notification.updateSuccess'),
            placement: 'bottomLeft',
            duration: 3.5,
          });

          tourbookEdit();
          onCloseModal();
        },

        onError: (response: ErrorResponse) => {
          createErrorNotification();
          response.errors.forEach(error => {
            helpers.setFieldError(error.field!, error.message);
          });
        },
      }),
    );
  };

  const handleSubmit = async (values, helpers) => {
    const data = {
      name: values.name,
      description,
      clientName: values.clientName,
      clientLogoCloudinaryId,
      headerImageCloudinaryId,
    };

    if (actionType === 'create') {
      handleCreate({ ...data, inIframe }, helpers);
    } else {
      handleEdit(data, helpers);
    }
  };

  const closeModal = () => {
    let confirmClose = true;
    if (isFormDirty) {
      // eslint-disable-next-line no-alert
      confirmClose = window.confirm(t('common:prompt.unsavedChanges'));
    }

    if (confirmClose) {
      setDescription(tourbook.description ? tourbook.description : '');
      setHeaderImageCloudinaryId(tourbook.headerImage?.cloudinaryId || null);
      setClientLogoCloudinaryId(tourbook.clientLogoCloudinaryId);
      onCloseModal();
    }
  };

  return (
    <Modal
      destroyOnClose
      closeModal={closeModal}
      isOpen={isModalOpen}
      title={actionType === 'create' ? t('createTourbook') : t('manageDialogs.editTourbook')}
      className={cn(s.modalBody, inIframe ? 'top-0' : 'top-4')}
      wrapClassName="overflow-hidden"
      bodyStyle={{ maxHeight: 'calc(100vh - 97px)', overflow: 'auto' }}
      centered
    >
      <Form
        initialValues={{
          name: tourbook.name,
          description: tourbook.description || '',
          clientName: tourbook.clientName || '',
        }}
        validationSchema={validationSchema}
        id="createTourbookForm"
        onSubmit={handleSubmit}
        setIsDirty={setIsFormDirty}
      >
        <div
          className={cn(
            'flex flex-col items-center overflow-y-scroll mobile:h-[calc(100vh-134px)]',
          )}
        >
          <Card
            title={t('manageDialogs.generalInformation')}
            className="my-2 w-11/12 bg-background-primary !shadow-[0_1px_8px_rgba(115,115,115,0.25)]"
            titleClassName="!p-2"
            contentClassName="!p-2"
          >
            <TextInput
              required
              name="name"
              labelText={t('manageDialogs.title')}
              labelClass="!text-black-055"
              containerClass={s.nameFieldContainer}
              autoComplete={false}
              showCharacterCount
              maxLength={90}
            />
            <TextArea
              name="description"
              labelText={t('manageDialogs.description')}
              labelClass="!text-black-055"
              containerClass={s.descriptionFieldContainer}
              className="max-h-24"
              value={description}
              onChange={e => setDescription(e.target.value)}
              onFocus={() => {}}
              onBlur={() => {}}
              minRows={3}
              showCharacterCount
              maxLength={160}
            />
          </Card>

          <Card
            title={t('manageDialogs.clientInformation')}
            className="mb-2 w-11/12 bg-background-primary !shadow-[0_1px_8px_rgba(115,115,115,0.25)]"
            titleClassName="!p-2"
            contentClassName="!p-2"
          >
            <TextInput
              name="clientName"
              labelText={t('manageDialogs.clientName')}
              labelClass="!text-black-055"
              autoComplete={false}
            />
            <div className="flex justify-between">
              <Label name="clientLogoCloudinaryId">{t('manageDialogs.clientLogo')}</Label>
              {clientLogoCloudinaryId && (
                <button
                  data-testid="removeClientLogoImage"
                  type="button"
                  className="cursor-pointer text-indigo-100 font-body-medium"
                  onClick={() => {
                    setIsFormDirty(true);
                    setClientLogoCloudinaryId('');
                  }}
                >
                  {t('manageDialogs.removeClientLogo')}
                </button>
              )}
            </div>
            <CloudinaryFileAreaInput
              name="clientLogoCloudinaryId"
              onLoading={handleCloudinaryFileInputLoading}
              onReady={onReady('clientLogo')}
              onError={createErrorNotification}
              containerClass="!mb-0"
              buttonClassName="grid bg-black-003 rounded-full overflow-hidden h-10 w-10 box-border border border-solid border-black-020"
            >
              {clientLogoCloudinaryId ? (
                <Image
                  cloudName={cloudinaryCloud}
                  privateCdn={cloudinaryCdn}
                  publicId={clientLogoCloudinaryId}
                  alt={t('tourbookClientLogoImage')}
                  crop="pad"
                  width="200"
                  height="200"
                  className="col-span-full row-start-1 row-end-3"
                />
              ) : (
                <div className="col-span-full row-start-1 row-end-3">
                  <CompanyLogo data-testid="companyLogoIcon" className="m-auto h-10 text-3xl" />
                </div>
              )}
              <div className="col-span-full row-start-2 h-4 self-end bg-black-100 opacity-60">
                <CustomIcon type="camera" />
              </div>
            </CloudinaryFileAreaInput>
          </Card>
        </div>
        <div className="sticky bottom-0 flex w-full justify-end bg-background-primary px-4 py-2 mobile:fixed mobile:justify-center">
          <Button type="secondary" size="medium" onClick={closeModal}>
            {t('manageDialogs.cancel')}
          </Button>
          <SubmitButton
            data-testid="saveTourbookButton"
            className="ml-2"
            disabled={cloudinaryLoading || !isFormDirty}
          >
            {actionType === 'create'
              ? t('manageDialogs.createButtonText')
              : t('manageDialogs.saveButtonText')}
          </SubmitButton>
        </div>
      </Form>
    </Modal>
  );
};

export default connector(EditTourbookModal);
