import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, CustomIcon } from '@components/shared';
import useAnalytics from '@root/shared/useAnalytics';
import { SearchListing } from '@root/types';
import useFloorplanPreviewButton from '@root/shared/useFloorplanPreviewButton';
import useAltText from '@root/shared/useAltText';
import { Tooltip } from 'antd';
import s from '../ListingCard.module.less';

type PhotoContainerProps = {
  listing: SearchListing;
  enableListingPreviewButton: boolean;
  openListingPreviewView: () => void;
  onToggleFloorplanPreview?: (isOpen: boolean) => void;
  portrait: boolean;
  isBuildingPage: boolean;
  isHomepage: boolean;
};

const PhotoContainer = ({
  listing,
  enableListingPreviewButton,
  openListingPreviewView,
  portrait,
  isBuildingPage,
  isHomepage,
  onToggleFloorplanPreview,
}: PhotoContainerProps) => {
  const { t } = useTranslation('listing');
  const { floorplanInteraction, PARAMETERS } = useAnalytics();

  const { address, name, status, isCurrentlyExclusive, photo, floorPlan } = listing;
  const { getAltText } = useAltText({ address: { ...address, floorAndSuite: name } });

  const sourcePage =
    (isBuildingPage && PARAMETERS.buildingPage) ||
    (isHomepage && PARAMETERS.homepage) ||
    PARAMETERS.searchResultsPage;

  const floorplanIconClicked = () =>
    floorplanInteraction({
      listing,
      sourcePage,
      sourceContent: isBuildingPage ? PARAMETERS.onMarketListings : PARAMETERS.listingCard,
    });

  const { FloorplanPreviewButton, floorplanPreviewButtonProps } = useFloorplanPreviewButton({
    address: listing.address,
    floorPlan: floorPlan!, // '!' because we have a guard check around FloorplanPreviewButton
    floorplanIconOnClick: floorplanIconClicked,
    portrait,
    onToggleFloorplanPreview,
  });

  return (
    <div
      className={cn(
        { [s.portrait]: portrait, [s.buildingPage]: isBuildingPage },
        s.offMarketPhotoContainer,
      )}
    >
      {isCurrentlyExclusive && status === 'on_market' ? (
        <Tooltip
          overlayClassName={s.tooltip}
          trigger="hover"
          placement="topLeft"
          title={t('common:firstToMarketTooltip')}
        >
          <>
            <CustomIcon
              type="first-to-market"
              className={cn({ [s.portrait]: portrait }, s.firstToMarketBadge)}
            />
          </>
        </Tooltip>
      ) : null}
      {status === 'off_market' ? <div className={s.offMarket}>{t('offMarket')}</div> : null}
      {floorPlan && <FloorplanPreviewButton {...floorplanPreviewButtonProps} />}
      <img
        className={cn({
          [isHomepage ? s.tallListingPhoto : s.listingPhoto]: photo,
          [s.grayScale]: status !== 'on_market',
        })}
        src={isHomepage ? photo.mediumPath : photo.smallPath}
        alt={getAltText({ type: photo.type, options: { roomName: photo.description } })}
      />
      {enableListingPreviewButton && (
        <Button
          data-testid="listingPreviewModalButton"
          type="secondary"
          size="small"
          className={s.listingPreviewButton}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            openListingPreviewView();
          }}
        >
          {t('listingPreview.previewButton')}
        </Button>
      )}
    </div>
  );
};

export default PhotoContainer;
