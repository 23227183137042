import { useState, useEffect } from 'react';
import Container from '@components/shared/Admin/Container';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Company } from '@root/types';
import routes from '@root/routes';
import { Breadcrumbs } from '@components/shared';
import { useBreadcrumbs } from '@components/shared/withBreadcrumbs';
import api from '@shared/api';
import Form from '../Form';
import Layout from '../../Layout/Layout';

const fetchTenant = async ({ id, callback }) => {
  const response = await api.fetch(routes.api.admin.company(id));
  callback(await response.json());
};

export const EditPage = () => {
  const { t } = useTranslation('admin');
  const { id } = useParams<{ id: string }>();

  const [tenant, setTenant] = useState<Company | null>(null);
  useEffect(() => {
    fetchTenant({ id, callback: setTenant });
  }, [id, setTenant]);

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    if (!tenant) return;

    setBreadcrumbs([t('breadcrumbs.admin'), t('breadcrumbs.tenants'), tenant.name]);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const {
    admin: { companies: adminTenantSearchRoute },
  } = routes;

  return (
    <Layout>
      <Breadcrumbs
        className="mb-1"
        items={[
          { content: t('breadcrumbs.admin') },
          { content: t('breadcrumbs.tenants'), link: adminTenantSearchRoute },
          {
            content: tenant?.name || '',
          },
        ]}
      />
      {tenant ? (
        <Container title={t('tenants.editTenant')}>
          <Form tenant={tenant} submitText={t('common.updateButton')} />
        </Container>
      ) : null}
    </Layout>
  );
};

export default EditPage;
