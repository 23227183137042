import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { Affix } from 'antd';
import { SubmitButton, CurrencyInput } from '@components/shared/forms';
import AdminTable from '@components/shared/AdminTable';
import { CreateAskingRents, Currency, StoreState, Units } from '@root/types';
import utils from '@styles/utils.module.less';
import s from './AskingRentForm.module.less';

type AskingRentProps = {
  term: { year: number; key: number };
  handleEntry: (year: number) => void;
  currency?: Currency | null;
};

type TermListProps = {
  yearMostRecentlyEdited: number;
  setYearMostRecentlyEdited: (year: number) => void;
  currency?: Currency | null;
  units?: Units | null;
};

const AskingRent = ({ term, handleEntry, currency }: AskingRentProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: StoreState) => state.currentUser);

  const {
    setFieldValue,
    values: { askingRents },
  } = useFormikContext<CreateAskingRents>();

  useEffect(() => {
    if (!(term.year in askingRents)) {
      setFieldValue(`askingRents[${term.year}]`, '');
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = value => {
    if (!(parseFloat(value) > 0)) {
      return t('common:required');
    }

    return '';
  };

  return (
    <div className={s.rentInput}>
      <CurrencyInput
        containerClass={s.rentInputContainer}
        required
        onChange={() => {
          handleEntry(term.year);
        }}
        name={`askingRents[${term.year}]`}
        validate={value => validate(value)}
        currency={currency || 'USD'}
        locale={currentUser?.languagePreference || 'en-us'}
      />
    </div>
  );
};

const TermList = ({
  yearMostRecentlyEdited,
  setYearMostRecentlyEdited,
  currency,
  units,
}: TermListProps) => {
  const { t } = useTranslation();
  const {
    setFieldValue,
    setFieldTouched,
    initialValues,
    values: { minimum, maximum, askingRents, visibility },
  } = useFormikContext<CreateAskingRents>();

  const hasExistingRents = !isEmpty(initialValues.askingRents);
  const minimumAndMaximumBothSet = minimum && maximum;

  const isSaveEnabled = () => {
    const isVisibilityChanged = initialValues.visibility !== visibility;

    return isTermRangeChanged() || isVisibilityChanged;
  };

  const renderTerm = term => (
    <div>{t('admin:listing.askingRent.termInYears', { count: term.year })}</div>
  );

  const handleEntry = year => {
    setYearMostRecentlyEdited(year);
  };

  const terms = () =>
    [...Array(maximum - minimum + 1)].map((_, i) => ({
      year: minimum + i,
      key: minimum + i,
    }));

  const handleApplyToAll = () => {
    const newAskingRents = { ...askingRents };
    for (let i = minimum; i <= maximum; i += 1) {
      setFieldTouched(`askingRents[${i}]`, true);
      newAskingRents[i] = askingRents[yearMostRecentlyEdited];
    }

    setFieldValue('askingRents', newAskingRents);
    setYearMostRecentlyEdited(0);
  };

  const renderAction = term => (
    <div className={s.actionColumn}>
      {yearMostRecentlyEdited === term.year && terms().length > 1 ? (
        <span className={utils.buttonLink} role="button" onClick={handleApplyToAll}>
          {t('admin:listing.askingRent.applyToAll')}
        </span>
      ) : null}
    </div>
  );

  const renderAskingRent = term => (
    <AskingRent term={term} handleEntry={handleEntry} currency={currency} />
  );

  const termColumnTitle = <span>{t('admin:listing.askingRent.term')}</span>;

  const rentColumnTitle = (
    <span>
      {t('admin:listing.askingRent.rent', { context: units })}{' '}
      <span className={s.required}>{t('common:asterisk')}</span>
    </span>
  );

  const isTermRangeChanged = () => {
    const isTermRangeModified = () => {
      if (!minimumAndMaximumBothSet) return false;

      const currentlySetAskingRents = {};
      for (let i = minimum; i <= maximum; i += 1) {
        currentlySetAskingRents[i] = askingRents[i];
      }

      return !isEqual(currentlySetAskingRents, initialValues.askingRents);
    };

    const isTermRangeCleared = hasExistingRents && !minimumAndMaximumBothSet;

    return isTermRangeModified() || isTermRangeCleared;
  };

  return (
    <>
      {minimumAndMaximumBothSet ? (
        <>
          <div className={s.header}>{t('admin:listing.askingRent.perTerm')}</div>
          <div className={s.termList}>
            <AdminTable
              className={s.termListHeader}
              rowClassName={s.termListRow}
              loading={false}
              dataSource={terms()}
              columns={[
                { title: termColumnTitle, render: renderTerm },
                {
                  title: rentColumnTitle,
                  render: renderAskingRent,
                },
                { title: '', width: '1%', render: renderAction },
              ]}
            />
          </div>
        </>
      ) : null}
      {isSaveEnabled() ? (
        <Affix offsetBottom={0}>
          <div className={s.submitFooter}>
            <SubmitButton className={s.submitButton} size="large">
              {t('common:saveChanges')}
            </SubmitButton>
          </div>
        </Affix>
      ) : null}
    </>
  );
};

export default TermList;
