import { useTranslation } from 'react-i18next';
import { Button } from '@components/shared';
import { useFormikContext } from 'formik';
import { SubmitButton } from '@components/shared/forms';
import { useBreakpoint } from '@shared/useBreakpoints';
import s from './AccountSettings.module.less';

const FormSubmitSection = ({
  onReset,
  submittingText,
}: {
  onReset?: () => void;
  submittingText?: string;
}) => {
  const { isDesktop } = useBreakpoint();
  const { t } = useTranslation('accountSettings');
  const { resetForm, dirty, isValid } = useFormikContext();

  return (
    <section className={s.buttonSection}>
      <div className={s.buttonRow}>
        <Button
          htmlType="reset"
          onClick={() => {
            if (onReset) onReset();
            resetForm();
          }}
          type="secondary"
          className={s.editStateButton}
          disabled={!dirty}
          size={isDesktop ? 'medium' : 'small'}
        >
          {t('cancelButtonLabel')}
        </Button>
        <SubmitButton
          disabled={!dirty || !isValid}
          data-testid="saveUpdatesButton"
          submittingText={submittingText}
          className={s.submitButton}
          size={isDesktop ? 'large' : 'medium'}
        >
          {t('saveUpdates')}
        </SubmitButton>
      </div>
    </section>
  );
};

export default FormSubmitSection;
