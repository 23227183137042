import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { roomAmenitiesType } from '@propTypes';
import s from './Room.module.less';

const processAmenties = amenitiesObj => {
  const order = [
    'deskCount',
    'electricAdjustableDeskCount',
    'deskChairCount',
    'tableCount',
    'workbenchCount',
    'loungeChairCount',
    'stoolCount',
    'couchesCount',
    'conferenceTableCount',
    'storageClosetArea',
    'kitchenTableCount',
    'kitchenChairCount',
    'refrigeratorCount',
    'sinkCount',
    'dishwasherCount',
    'microwaveCount',
    'ovenCount',
    'stoveCount',
    'lockers',
    'poolTableCount',
    'airHockeyTableCount',
    'pingPongTableCount',
    'vendingMachineCount',
    'bathroomType',
    'bathroomSinkCount',
    'bathroomStallCount',
  ];

  return map(amenitiesObj, (value, name) => ({ value, name }))
    .filter(amenity => ![0, false, '', null].includes(amenity.value))
    .sort((amenityA, amenityB) => order.indexOf(amenityA.name) - order.indexOf(amenityB.name));
};

const Amenities = ({ amenities: amenitiesObj }) => {
  const { t } = useTranslation('listing');
  const amenities = processAmenties(amenitiesObj);
  if (amenities.length < 1) return null;

  const amenitiesTexts = amenities.map(({ name, value }) => {
    switch (typeof value) {
      case 'number':
        return t(`rooms.amenities.${name}`, { count: value });
      case 'string':
        return t(`rooms.amenities.${name}.${value}`);
      case 'boolean':
        return t(`rooms.amenities.${name}`);
      default:
        return t(`rooms.amenities.${name}`);
    }
  });

  return (
    <ul className={s.amenities}>
      {amenitiesTexts.map(amenity => (
        <li key={`${amenity}-${Math.random()}`}>{amenity}</li>
      ))}
    </ul>
  );
};

Amenities.propTypes = {
  amenities: roomAmenitiesType.isRequired,
};
export default Amenities;
