import { InternalLink } from './helper';

type InternalLinkGroupProps = {
  links: InternalLink[];
  isDisplayed: boolean;
};

const InternalLinkGroup = ({ links, isDisplayed }: InternalLinkGroupProps) => (
  <ul
    data-testid="internal-link-group"
    className={`${isDisplayed ? 'mt-2' : ''}`}
    style={{
      display: isDisplayed ? 'block' : 'none',
    }}
  >
    {links.map(internalLink => (
      <li key={internalLink.name} className="my-1 text-left text-md">
        <a href={internalLink.url}>{internalLink.name}</a>
      </li>
    ))}
  </ul>
);

export default InternalLinkGroup;
