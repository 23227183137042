import cn from 'classnames';
import { isInIframe } from '@root/shared/iframeUtils';
import contentWidth from '@styles/contentWidth.module.less';

const FixedPageHeader = ({ children, isCollapsed }) => {
  return (
    <div
      className={cn([
        'sticky left-1/2 top-0 z-10 flex items-center justify-between px-3 py-1.5 desktop:px-4 mobile:h-8 mobile:px-2',
        isCollapsed &&
          "z-10 bg-background-primary shadow-lg before:absolute before:left-1/2 before:top-0 before:z-[-1] before:h-full before:w-screen before:-translate-x-1/2 before:bg-background-primary before:shadow-lg before:content-['']",
        !isCollapsed &&
          !isInIframe() &&
          'ml-[-24px] w-screen bg-background-primary shadow-lg mobile:ml-[-16px] mobile:mt-0 mobile:pl-2',
        isInIframe() && '!before:left-0 !before:translate-x-0 !fixed !left-0 right-0 desktop:!px-3',
      ])}
    >
      <div className={cn(isInIframe() ? 'w-full' : contentWidth.contentContainer)}>{children}</div>
    </div>
  );
};

export default FixedPageHeader;
