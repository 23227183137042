import { useEffect, useState } from 'react';

const useDetectImageBrightness = (imagePath: string) => {
  const [isDark, setIsDark] = useState<boolean | null>(null);

  useEffect(() => {
    if (!imagePath) return;

    const image = new Image();
    image.src = imagePath;
    image.crossOrigin = 'anonymous';
    image.style.display = 'none';

    image.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas?.getContext('2d');

      ctx?.drawImage(image, 0, 0);

      const imageData = ctx?.getImageData(0, 0, canvas?.width!, canvas?.height!);

      const brightness = calculateBrightness(imageData?.data, image.width, image.height);

      setIsDark(brightness < 128);
    };
  }, [imagePath]);

  const calculateBrightness = (imageData, width, height) => {
    let sum = 0;

    for (let i = 0; i < imageData.length; i += 4) {
      const r = imageData[i];
      const g = imageData[i + 1];
      const b = imageData[i + 2];

      const avg = Math.floor((r + g + b) / 3);
      sum += avg;
    }

    const avgBrightness = Math.floor(sum / (width * height));
    return avgBrightness;
  };

  return isDark;
};

export default useDetectImageBrightness;
