import { useDispatch } from 'react-redux';

import { IterableUserAttributes, CurrentUser } from '@root/types';

import { setBugsnagUser } from '@shared/bugsnagClient';
import useEnv from '@shared/useEnv';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';
import routes from '@root/routes';
import {
  receiveCurrentUser,
  receiveNoUser,
} from '@root/store/actions/currentUser/fetchCurrentUser';
import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import { currentUserKey } from '@shared/useCurrentUser';

// This file is mostly about loading and setting the current user
// to actually use the current user, see the useCurrentUser hook
// or the redux store

export const userShapedForLaunchDarkly = (user: CurrentUser | null) =>
  user
    ? {
        key: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        custom: {
          market: user.marketName,
          role: user.role,
        },
      }
    : { anonymous: true };

const useUserRegisterer = (onRegister: () => void = () => {}) => {
  const dispatch = useDispatch();
  const client = useLDClient();
  const { railsEnv } = useEnv();

  const ITERABLE_COOKIES = [
    'iterableEndUserId',
    'iterableEmailCampaignId',
    'iterableTemplateId',
    'iterableMessageId',
  ];
  const [cookies] = useCookies([...ITERABLE_COOKIES]);
  const { iterableEmailCampaignId, iterableTemplateId, iterableMessageId, iterableEndUserId } =
    cookies;

  const { search: urlParams } = useLocation();

  const {
    email,
    templateId: emailIterableTemplateId,
    campaignId: emailIterableCampaignId,
  } = parse(urlParams, { ignoreQueryPrefix: true });

  const decodedEmail = email ? atob(email as string) : undefined;
  const iterableUserAttributes: IterableUserAttributes = {
    iterableEndUserId: iterableEndUserId ?? decodedEmail,
    iterableEmailCampaignId: iterableEmailCampaignId ?? emailIterableCampaignId,
    iterableTemplateId: iterableTemplateId ?? emailIterableTemplateId,
    iterableMessageId,
  };

  useQuery(currentUserKey, () => get<CurrentUser>(routes.api.currentUser), {
    retry: false,
    onSuccess: data => {
      dispatch(receiveCurrentUser(data, iterableUserAttributes));

      if (railsEnv !== 'test') {
        client?.identify(userShapedForLaunchDarkly(data), undefined, onRegister);
        setBugsnagUser(data);
      }
    },
    onError: () => {
      dispatch(receiveNoUser(iterableUserAttributes));
      if (railsEnv === 'test') return;
      client?.identify({ anonymous: true }, undefined, onRegister);
    },
  });

  return null;
};
export default useUserRegisterer;
