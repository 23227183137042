import { Checkbox as TruvaCheckbox, TextInput } from '@components/shared/forms';
import { Checkbox } from '@viewthespace/components';
import { AdminMicrosite } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import TextColorSelector from '../../TextColorSelector';
import ColorPicker from '../../ColorPicker';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import { useMicrositeFormContext } from '../../utils';

export default function Location({ id, index, anchorSlug }: ContentBlockProps) {
  const { setFieldTouched, setFieldValue, values } = useFormikContext<Partial<AdminMicrosite>>();
  const flags = useFlags();
  const sectionTitlesAreRichText = flags['market.building-descriptions-support-rich-text'];
  const { t } = useTranslation('admin');
  const { colorGroups, microsite, resetKey, sendPreviewTo, changeFontColorWithColorChange } =
    useMicrositeFormContext();

  const isLocationSectionHidden = !values.homeGoogleMapSectionIsVisible;

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      title={
        flags['market-office.flexible-layouts-on-microsite']
          ? t('microsite.sections.googleMap')
          : t('microsite.sections.homeGoogleMap')
      }
      section="homeGoogleMap"
      onClick={() => sendPreviewTo({ page: 'home', element: anchorSlug })}
      index={index}
      isHidden={isLocationSectionHidden}
    >
      {flags['market-office.hide-amenities-and-certifications'] ? (
        <Checkbox
          label={t('microsite.hideSection')}
          className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
          backgroundStyle="filled"
          isChecked={isLocationSectionHidden}
          onChange={e => {
            setFieldValue('homeGoogleMapSectionIsVisible', !e.value);
            setFieldTouched('homeGoogleMapSectionIsVisible', true);
          }}
        />
      ) : (
        <TruvaCheckbox
          name="homeGoogleMapSectionIsVisible"
          labelClassName="!mb-0"
          className="!pt-[2px]"
          onChange={() => setFieldTouched('homeGoogleMapSectionIsVisible', true)}
        >
          {t('microsite.homeGoogleMapSectionIsVisible.title')}
        </TruvaCheckbox>
      )}

      <ColorPicker
        colorGroups={colorGroups}
        name="homeGoogleMapSectionBackgroundColor"
        labelText={t('microsite.homeGoogleMapSectionBackgroundColor.title')}
        onChange={changeFontColorWithColorChange('homeGoogleMapSectionTextColor')}
      />
      {flags['market-office.text-color-selector'] && (
        <TextColorSelector name="homeGoogleMapSectionTextColor" />
      )}
      {sectionTitlesAreRichText ? (
        <RichTextEditorInput
          name="homeGoogleMapSectionTitle"
          labelText={t('microsite.sectionTitle')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          excludeMenuItems={
            flags['market.microsite-font-sizing-on-editors']
              ? ['bulletList']
              : ['bulletList', 'textSize']
          }
          initialContent={microsite.homeGoogleMapSectionTitle}
          defaultTextSize={
            flags['market.microsite-font-sizing-on-editors'] ? 'header32' : undefined
          }
          resetKey={resetKey}
          fast={!!flags['market-office.microsite-performance-optimizations']}
          shouldDebounce
        />
      ) : (
        <TextInput name="homeGoogleMapSectionTitle" labelText={t('microsite.sectionTitle')} />
      )}
    </ContentBlockWrapper>
  );
}
