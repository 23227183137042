import cn from 'classnames';

export type ColorGroup = {
  title: string;
  colors?: string[];
  imgPath?: string;
  altText?: string;
};

type Props = ColorGroup & {
  className?: string;
  selectedColor?: string;
  onChange: (color: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const ColorPickerColorGroup = ({
  title,
  colors,
  selectedColor,
  imgPath,
  altText,
  className,
  onChange = () => {},
  onFocus,
  onBlur,
}: Props) => {
  return (
    <div
      data-testid={`colorPickerColorGroup-${title}`}
      className={cn('w-full border-t border-solid border-black-020 p-1', className)}
    >
      <div className="font-body-small">{title}</div>
      <div className="mt-1.5 flex flex-row items-center justify-between">
        <div className="flex flex-row flex-wrap gap-1">
          {colors?.map(color => (
            <div
              className={cn(
                'flex h-[20px] w-[20px] items-center justify-center rounded-[3px] border border-solid border-white hover:border-indigo-90',
                selectedColor === color && '!border-indigo-90',
              )}
              key={color}
            >
              <button
                data-testid={`colorGroupColor-${color}`}
                type="button"
                onClick={() => onChange(color)}
                onFocus={onFocus}
                onBlur={onBlur}
                style={{
                  // Use inline styles to be dynamic based on the prop.
                  backgroundColor: color,
                }}
                className="h-2 w-2 rounded-[3px] border border-solid border-black-035"
              ></button>
            </div>
          ))}
        </div>
        {imgPath && (
          <img
            data-testid="colorGroupImage"
            className="h-4 w-4 rounded-full border border-solid border-black-020 bg-white"
            src={imgPath}
            alt={altText}
          />
        )}
      </div>
    </div>
  );
};

export default ColorPickerColorGroup;
