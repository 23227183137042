import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Checkbox } from '@viewthespace/components';
import { TextInput } from '@components/shared/forms';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { useFormikContext } from 'formik';
import { AdminMicrosite } from '@root/types';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import ColorPicker from '../../ColorPicker';
import TextColorSelector from '../../TextColorSelector';
import { useMicrositeFormContext } from '../../utils';

export default function Certifications({ id, index, anchorSlug }: ContentBlockProps) {
  const { colorGroups, microsite, resetKey, changeFontColorWithColorChange, sendPreviewTo } =
    useMicrositeFormContext();
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();

  const flags = useFlags();
  const { t } = useTranslation('admin');

  const isCertificationsSectionHidden = !values.certificationsSectionIsVisible;

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      title={
        flags['market-office.flexible-layouts-on-microsite']
          ? t('microsite.sections.certifications')
          : t('microsite.sections.featuresCertifications')
      }
      section="featuresCertifications"
      onClick={() => sendPreviewTo({ page: 'features', element: anchorSlug })}
      index={index}
      isHidden={isCertificationsSectionHidden}
    >
      {flags['market-office.hide-amenities-and-certifications'] ? (
        <Checkbox
          label={t('microsite.hideSection')}
          className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
          backgroundStyle="filled"
          isChecked={isCertificationsSectionHidden}
          onChange={e => {
            setFieldValue('certificationsSectionIsVisible', !e.value);
            setFieldTouched('certificationsSectionIsVisible', true);
          }}
        />
      ) : null}
      <ColorPicker
        colorGroups={colorGroups}
        name="certificationsBackgroundColor"
        labelText={t('microsite.backgroundColor')}
        onChange={changeFontColorWithColorChange('certificationsTextColor')}
      />
      {flags['market-office.text-color-selector'] && (
        <TextColorSelector name="certificationsTextColor" />
      )}
      <ColorPicker
        colorGroups={colorGroups}
        name="certificationsBorderColor"
        labelText={t('microsite.borderColor')}
        hasTransparentOption
      />

      {flags['market.building-descriptions-support-rich-text'] ? (
        <RichTextEditorInput
          name="certificationsTitle"
          labelText={t('microsite.sectionTitle')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          excludeMenuItems={
            flags['market.microsite-font-sizing-on-editors']
              ? ['bulletList']
              : ['bulletList', 'textSize']
          }
          initialContent={microsite.certificationsTitle}
          defaultTextSize={
            flags['market.microsite-font-sizing-on-editors'] ? 'header32' : undefined
          }
          resetKey={resetKey}
          fast={!!flags['market-office.microsite-performance-optimizations']}
          shouldDebounce
        />
      ) : (
        <TextInput name="certificationsTitle" labelText={t('microsite.sectionTitle')} />
      )}
    </ContentBlockWrapper>
  );
}
