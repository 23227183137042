import { useState } from 'react';
import { Tag, DuplicateIcon, CheckIcon } from '@viewthespace/components';
import { MicrositeDomain } from '@root/types';
import { useTranslation } from 'react-i18next';

const nsRecordStatusTranslationMapping = {
  valid: 'valid',
  pending: 'pending',
  extra_records: 'extraRecords',
  missing_records: 'missingRecords',
};

export type Props = {
  domainData: MicrositeDomain;
};
const NsRecords = ({ domainData }: Props) => {
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const { nsRecords, nsRecordsStatus } = domainData;
  const { t } = useTranslation('admin');

  const nsRecordsStatusText = t(
    `microsite.nsRecordStatuses.${nsRecordStatusTranslationMapping[nsRecordsStatus]}`,
  );

  return (
    <>
      <div className="flex justify-between">
        <span className="font-body-medium-emphasis text-black-100">
          {t('microsite.domainName.nameservers.title')}
        </span>
        {!!nsRecords?.length && (
          <button
            data-testid="copy-records-button"
            className="mt-[-2px]"
            type="button"
            onClick={async () => {
              setIsCopy(true);
              await navigator.clipboard.writeText(nsRecords.join('\n'));
              setTimeout(() => {
                setIsCopy(false);
              }, 3000);
            }}
          >
            {isCopy ? (
              <div className="flex gap-1">
                <span className="font-body-medium text-text-success">
                  {t('microsite.domainName.nameservers.copied')}
                </span>
                <CheckIcon color="text-success" className="mt-[1px]" />
              </div>
            ) : (
              <div className="flex">
                <DuplicateIcon className="mt-[1px]" />
              </div>
            )}
          </button>
        )}
      </div>
      {nsRecords?.length ? (
        <div className="flex flex-col mt-[-8px] gap-1">
          <Tag
            text={nsRecordsStatusText}
            color={nsRecordsStatus === 'valid' ? 'success' : 'attention'}
          />
          <div className="flex flex-col">
            {nsRecords.map(nsRecord => {
              return (
                <span key={nsRecord} className="font-body-medium text-black-055">
                  {nsRecord}
                </span>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="mt-[-8px]">
          <Tag text={t('microsite.domainName.pending')} color="attention" />
        </div>
      )}
    </>
  );
};

export default NsRecords;
