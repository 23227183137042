import { useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import api from '@shared/api';
import type { DefaultPhotoUploadType } from '@components/shared/Admin/MultimediaUploader';
import { adminMediaType } from '@propTypes';
import CollapsableMediaPhotosSection from '@components/shared/Admin/EditPhotos/CollapsableMediaPhotosSection';
import MediaPhotosSection from '@components/shared/Admin/EditPhotos/MediaPhotosSection';

export type Props = {
  fetchAdminBuilding: () => void;
  photos: adminMediaType[];
  archivedPhotos: adminMediaType[];
  firstPhotoOrientation: 'landscape' | 'portrait';
  buildingId: string;
};

const BuildingMediaPhotoSection = ({
  photos,
  fetchAdminBuilding,
  buildingId,
  firstPhotoOrientation,
  archivedPhotos,
}: Props) => {
  const { t } = useTranslation('admin');

  const [selectedPublicRows, setSelectedPublicRows] = useState([]);
  const [selectedHiddenRows, setSelectedHiddenRows] = useState([]);
  const allSelectedRows = [...selectedPublicRows, ...selectedHiddenRows];

  const handleUploadPhotos = (uploadedFiles: DefaultPhotoUploadType[]) =>
    api.post(routes.api.admin.buildingPhotosCreate(buildingId), {
      photos: uploadedFiles,
    });

  const handleUpdatePhotoPosition = async (photoIds: string[]) => {
    const response = await api.put(routes.api.admin.buildingPhotosOrder(buildingId), {
      photo_ids: photoIds,
    });

    if (response.ok) {
      notification.success({ message: t('common.photos.orderSaveSuccess') });
      fetchAdminBuilding();
    } else {
      notification.error({ message: t('common.photos.orderSaveFailure') });
    }
  };

  const handleDeletePhoto = async (photoId: string) => {
    const response = await api.delete(routes.api.admin.buildingPhotoDelete(buildingId, photoId));
    const json = await response.json();

    if (response.ok) {
      await fetchAdminBuilding();
      notification.success({ message: json.message });
      clearSelectedPhotos();
    } else {
      notification.error({ message: json.errors[0].message });
    }
  };

  const handleFirstPhotoOrientationChange = async (newOrientation: string) => {
    const response = await api.put(routes.api.admin.building(buildingId), {
      firstPhotoOrientation: newOrientation,
    });
    const json = await response.json();

    if (response.ok) {
      await fetchAdminBuilding();
      notification.success({
        message:
          newOrientation === 'landscape' ? t('media.landscapeSuccess') : t('media.portraitSuccess'),
      });
    } else {
      notification.error({ message: json.errors[0].message });
    }
  };

  const handleMakePhotosPublic = async photoIds => {
    const response = await api.put(routes.api.admin.buildingPhotosArchiveToggle(buildingId), {
      photo_ids: photoIds,
      archive: false,
    });

    if (response.ok) {
      notification.success({
        message: t('media.makePhotosPublicSuccess', { count: photoIds.length }),
      });

      fetchAdminBuilding();
      return Promise.resolve();
    }
    notification.error({
      message: t('media.makePhotosPublicFailure', { count: photoIds.length }),
    });
    return Promise.reject();
  };

  const handleHidePhotos = async photoIds => {
    const response = await api.put(routes.api.admin.buildingPhotosArchiveToggle(buildingId), {
      photo_ids: photoIds,
      archive: true,
    });

    if (response.ok) {
      notification.success({
        message: t('media.hidePhotosSuccess', { count: photoIds.length }),
      });
      fetchAdminBuilding();
      return Promise.resolve();
    }
    notification.error({
      message: t('media.hidePhotosFailure', { count: photoIds.length }),
    });
    return Promise.reject();
  };

  const clearSelectedPhotos = () => {
    setSelectedPublicRows([]);
    setSelectedHiddenRows([]);
  };

  return (
    <>
      <MediaPhotosSection
        title={t('media.buildingPhotosTitle')}
        description={t('media.buildingPhotosDescription')}
        photos={photos}
        fetchRecord={fetchAdminBuilding}
        onPhotoUpload={handleUploadPhotos}
        onUpdatePhotoPosition={handleUpdatePhotoPosition}
        onDeletePhoto={handleDeletePhoto}
        onFirstPhotoOrientationChange={handleFirstPhotoOrientationChange}
        firstPhotoOrientation={firstPhotoOrientation}
        handleHidePhotos={handleHidePhotos}
        setSelectedRows={setSelectedPublicRows}
        selectedRows={selectedPublicRows}
        selectedDownloadRows={!allSelectedRows.length}
        downloadUrl={routes.api.admin.buildingPhotosZipDownload(buildingId, allSelectedRows)}
        downloadButtonOnClick={clearSelectedPhotos}
        uploadSizeLimitInMb={20}
      />
      <div className="u-p-top-5x">
        <CollapsableMediaPhotosSection
          title={t('media.buildingArchivedPhotosTitle')}
          description={t('media.buildingArchivedDescription')}
          photos={archivedPhotos}
          makePhotosPublic={handleMakePhotosPublic}
          setSelectedRows={setSelectedHiddenRows}
          selectedRows={selectedHiddenRows}
          onDeletePhoto={handleDeletePhoto}
        />
      </div>
    </>
  );
};

export default BuildingMediaPhotoSection;
