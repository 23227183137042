import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomIcon } from '@components/shared';
import { RawToggle } from '@components/shared/forms';
import cn from 'classnames';
import routes from '@root/routes';
import { BuildingOwnerSummary } from '@root/types';
import useAltText from '@root/shared/useAltText';
import s from './BuildingOwners.module.less';

type Props = {
  owner: BuildingOwnerSummary;
  onDeleteClick: (owner: BuildingOwnerSummary) => void;
  toggleNotification: (slug: string, receivesNotifications: boolean) => Promise<void>;
  onClick: () => void;
};

const BuildingOwnerRow = ({ owner, onDeleteClick, toggleNotification, onClick }: Props) => {
  const { t } = useTranslation('favorites');
  const { getAltTextForCompanyLogo } = useAltText();

  return (
    <li className={s.owner}>
      <div className={s.logo}>
        <img
          className={s.logoImage}
          src={owner.logo.smallPath}
          alt={getAltTextForCompanyLogo(owner.name)}
        />
      </div>
      <div className={cn(s.name, s.nameRow)}>
        <Link to={routes.landlords(owner.slug)} onClick={onClick}>
          {owner.name}
        </Link>
      </div>
      <div className={cn(s.currentListings, s.currentListingsRow)}>
        <label className={s.label}>{t('listingsAvailable')}</label>
        {t('numAvailabilities', { count: owner.numAvailabilities })}
      </div>
      <div className={s.alerts}>
        <label className={s.label}>{t('emailAlerts')}</label>
        <RawToggle
          onChange={receivesNotifications => toggleNotification(owner.slug, receivesNotifications)}
          name={`${owner.id}-alerts`}
          checked={owner.receivesNotifications}
        />
        <span className={s.alertAside}>{t(owner.receivesNotifications ? 'on' : 'off')}</span>
      </div>
      <div className={s.actions}>
        <button
          data-testid={`delete-owner-${owner.slug}`}
          type="button"
          onClick={() => onDeleteClick(owner)}
          className={s.deleteButton}
        >
          <CustomIcon type="trash" />
        </button>
      </div>
    </li>
  );
};

export default BuildingOwnerRow;
