import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';

type Props = {
  requesterName: string;
  requesterEmail: string;
  requestedAt: string;
};

const Header = ({ requesterName, requesterEmail, requestedAt }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <>
      <div className="inline-block font-title">{t('media.contentShootDetails.title')}</div>
      <div className="float-right inline-block w-[400px] text-right font-body-medium-emphasis">
        {t('media.contentShootDetails.requester', {
          name: requesterName,
          email: requesterEmail,
          date: format(parseISO(requestedAt), 'PP'),
          time: format(parseISO(requestedAt), 'p'),
        })}
      </div>
    </>
  );
};

export default Header;
