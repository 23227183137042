import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { SourceContent, SourcePage, threeDTourPlayed } from '@store/actions/videoAnalytics';
import { TourbookListingAnalyticsInformation, Video } from '@root/types';

export const useIFrameInteractionTracking = ({
  shouldTrack,
  sourceContent,
  sourcePage,
  iFrameId,
  analyticsInformation,
}: {
  shouldTrack?: string | null | Video;
  sourceContent: SourceContent;
  sourcePage: SourcePage;
  iFrameId: string;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
}) => {
  const dispatch = useDispatch();
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (shouldTrack) {
      if (!intervalRef.current) {
        const iframeWatcher = setInterval(() => {
          const { activeElement } = document;
          if (activeElement && activeElement.isEqualNode(document.getElementById(iFrameId))) {
            dispatch(
              threeDTourPlayed({
                sourceContent,
                sourcePage,
                analyticsInformation,
              }),
            );
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
              intervalRef.current = undefined;
            }
          }
        }, 100);

        intervalRef.current = iframeWatcher;
      }
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTrack]);
};

export default useIFrameInteractionTracking;
