import { Table, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import CustomIcon from '@components/shared/CustomIcon';
import api from '@shared/api';
import addKeyTo from '@shared/addKeyTo';
import routes from '@root/routes';
import { AdminFloorPlan } from '@root/types';
import FloorPlanUploader from './FloorPlanUploader';
import CollapsibleFloorPlanSection from './CollapsibleFloorPlanSection';
import s from './FloorPlanSection.module.less';

type Props = {
  floorPlan: AdminFloorPlan | null;
  archivedFloorPlans: AdminFloorPlan[];
  fetchListing: () => void;
  listingId: string;
};

const FloorPlanSection = ({ floorPlan, archivedFloorPlans, fetchListing, listingId }: Props) => {
  const { t } = useTranslation('admin');

  const tableData = () => (floorPlan ? addKeyTo([floorPlan]) : []);

  const hideFloorPlan = async (id: string) => {
    const resp = await api.put(routes.api.admin.floorPlanUpdate(id), { archived: true });

    if (resp.ok) {
      fetchListing();
      notification.success({ message: t('media.floorPlan.hideSuccess') });
    } else {
      notification.error({ message: t('media.floorPlan.hideFailure') });
    }
  };

  const renderFile = (path: string, filename: string, processedAt: string) => {
    return (
      <div className={s.fileContainer}>
        <div className={s.fileInfoContainer}>
          {processedAt ? (
            <span className={s.processedAt}>
              {t('media.processedAt', {
                date: format(parseISO(processedAt), 'PP'),
                time: format(parseISO(processedAt), 'p'),
              })}
            </span>
          ) : null}
        </div>
        <div className={s.thumbnailContainer}>
          <div className={s.thumbnail}>
            <img src={path} alt="Floor Plan" />
          </div>
        </div>
      </div>
    );
  };

  const downloadMenuItem = () => {
    return (
      <OverflowMenuItem className={s.overflowItem} onClick={() => {}}>
        <a className={s.downloadLink} href={floorPlan?.downloadPath || ''} download>
          <CustomIcon type="download"></CustomIcon>
          {t('media.floorPlan.download')}
        </a>
      </OverflowMenuItem>
    );
  };

  const hideMenuItem = (row: any) => {
    return (
      <OverflowMenuItem className={s.overflowItem} onClick={() => hideFloorPlan(row.id)}>
        <>
          <CustomIcon type="eye"></CustomIcon>
          {t('media.floorPlan.hide')}
        </>
      </OverflowMenuItem>
    );
  };

  const columns = () => [
    {
      width: '51%',
      render: (row: any) => {
        return renderFile(row.path, row.filename, row.processedAt);
      },
    },
    {
      width: '40%',
      render: () => <span className={s.uploadHelpText}>{t('media.floorPlan.uploadHelpText')}</span>,
    },
    {
      width: '9%',
      render: (row: any) => (
        <OverflowMenu overflowClassName={s.overflowMenu}>
          {downloadMenuItem()}
          {hideMenuItem(row)}
        </OverflowMenu>
      ),
    },
  ];

  return (
    <>
      <div className={s.header}>
        <div className="inline font-title">{t('media.floorPlan.title')}</div>
        <div className={s.uploadButton}>
          <FloorPlanUploader
            fetchListing={fetchListing}
            listingId={listingId}
            buttonClass={s.uploadButtonClass}
            buttonIcon="upload"
            buttonSize="small"
          />
        </div>
      </div>
      <Table
        className={s.tableHeader}
        rowClassName={s.row}
        pagination={false}
        columns={columns()}
        dataSource={tableData()}
      />
      <CollapsibleFloorPlanSection
        archivedFloorPlans={archivedFloorPlans}
        renderFile={renderFile}
        fetchListing={fetchListing}
      />
    </>
  );
};

export default FloorPlanSection;
