import * as React from 'react';
import renderingInServer from './renderingInServer';

export default function useStickyState<T>(
  defaultValue: T,
  key: string,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const isSSR = renderingInServer();

  const getLocalStorageValue = (currentValue: T): T => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : currentValue;
  };

  const [value, setValue] = React.useState<T>(() =>
    isSSR ? defaultValue : getLocalStorageValue(defaultValue),
  );

  React.useEffect(() => {
    setValue(getLocalStorageValue(value));
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSSR]);

  React.useEffect(() => {
    if (!isSSR) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, value]);

  return [value, setValue];
}
