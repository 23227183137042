import { FastField, Field } from 'formik';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';
import RawRadioGroup from '../RawRadioGroup';

type Props = {
  options: { label: string; value: string; disabled?: boolean }[];
  optionsInRow?: boolean;
  disabled?: boolean;
  tooltipContent?: string;
  fast?: boolean;
} & Omit<FieldWrapperProps, 'children'>;
export default function RadioGroup({
  name,
  labelClass,
  labelText,
  required,
  containerClass,
  description,
  options,
  optionsInRow = false,
  disabled = false,
  tooltipContent,
  /**
   * Improves performance, but may cause issues if field is dependent on other fields
   */
  fast = false,
}: Props) {
  const FieldComponent = fast ? FastField : Field;
  return (
    <FieldWrapper
      {...{ description, name, labelClass, containerClass, labelText, required, tooltipContent }}
    >
      <FieldComponent name={name}>
        {({ field, form }) => (
          <RawRadioGroup
            options={options}
            optionsInRow={optionsInRow}
            onChange={event => {
              field.onChange({ target: { name, value: event.target.value } });
              form.setFieldTouched(name, true);
            }}
            name={name}
            value={field.value}
            disabled={disabled}
          />
        )}
      </FieldComponent>
    </FieldWrapper>
  );
}
