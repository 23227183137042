import { CountryCode } from '@root/types';

export const countries: { label: string; value: CountryCode }[] = [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
  },
];

export default countries;
