import routes from '@root/routes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { create, destroy } from '@root/shared/typedApi';
import { SearchListing, PartialListing } from '@root/types';
import { Notification } from '@components/shared';
import { t } from 'i18next';
import useAnalytics from './useAnalytics';

const useToggleListingSaveMutation = ({
  listing,
  onSuccessfulSave,
  analyticsInformation,
  isSaved,
}: {
  listing: SearchListing | PartialListing;
  onSuccessfulSave: () => void;
  analyticsInformation: any;
  isSaved: boolean;
}) => {
  const { id: listingId } = listing || {};
  const { saveInteraction, PARAMETERS } = useAnalytics();
  const queryClient = useQueryClient();

  const saveListingMutation = useMutation(
    () => create(routes.api.listingFavorites(listingId), {}),
    {
      onMutate: () => {
        const previousFavoritedListings = queryClient.getQueryData<SearchListing[]>([
          routes.api.favoritedListings,
        ]) as SearchListing[];

        const currentFavoritedListings = [...previousFavoritedListings, listing];

        queryClient.setQueryData([routes.api.favoritedListings], () => currentFavoritedListings);
      },
      onSuccess: async () => {
        onSuccessfulSave();
        saveInteraction({
          action: PARAMETERS.saveItem,
          ...analyticsInformation,
        });
      },
      onError: async () => {
        Notification.error({
          title: t('common:genericFailureTitle'),
          text: t('common:genericFailureMessage'),
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries([routes.api.favoritedListings]);
      },
    },
  );

  const unsaveListingMutation = useMutation(
    () => destroy(routes.api.listingFavorites(listingId), {}),
    {
      onMutate: () => {
        const previousFavoritedListings = queryClient.getQueryData<SearchListing[]>([
          routes.api.favoritedListings,
        ]) as SearchListing[];

        const currentFavoritedListings = previousFavoritedListings.filter(
          favoritedListing => favoritedListing.id !== listingId,
        );

        queryClient.setQueryData([routes.api.favoritedListings], () => currentFavoritedListings);
      },
      onSuccess: async () => {
        saveInteraction({
          action: PARAMETERS.unsaveItem,
          ...analyticsInformation,
        });
      },
      onError: async () => {
        Notification.error({
          title: t('common:genericFailureTitle'),
          text: t('common:genericFailureMessage'),
        });
      },
      onSettled: async () => {
        await queryClient.invalidateQueries([routes.api.favoritedListings]);
      },
    },
  );

  const handleToggleSaveListing = () => {
    if (isSaved) {
      unsaveListingMutation.mutate();
    } else {
      saveListingMutation.mutate();
    }
  };

  return {
    toggleSaveListing: handleToggleSaveListing,
  };
};

export default useToggleListingSaveMutation;
