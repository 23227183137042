import * as React from 'react';
import cn from 'classnames';
import s from './List.module.less';

type Props<T> = {
  className?: string;
  itemClassName?: string;
  /** How to render each item (all are wrapped with an html li */
  renderItem: (item: T) => React.ReactNode;
  /** The items that get displayed */
  items: T[];
  /** Whether or not the html ul uses display flex or grid or regular */
  type?: 'grid' | 'flex' | null;
  /** This is the key in the items that is used for the key prop in each html li */
  itemKey?: string;
};

function RawList<T = any>(
  { className, itemClassName, items, renderItem, type = null, itemKey = 'id' }: Props<T>,
  ref?: React.ForwardedRef<HTMLUListElement | null>,
) {
  return (
    <ul ref={ref} className={cn(type === 'grid' && s.grid, type === 'flex' && s.flex, className)}>
      {items.map(item => (
        <li key={item[itemKey]} className={itemClassName}>
          {renderItem(item)}
        </li>
      ))}
    </ul>
  );
}

const List = React.forwardRef(RawList) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof RawList>;

export default List;
