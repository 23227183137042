import { useState } from 'react';
import { Form as AntdForm, Input, Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { startCase, isEmpty } from 'lodash';
import Button from '@components/shared/V2Button';
import AdminErrors from '@components/shared/AdminErrors';
import ModelList from '@components/shared/ModelList';
import useFetchedSelect from '@shared/useFetchedSelect';
import useEnums from '@shared/useEnums';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useSave from '@shared/useSave';
import routes from '@root/routes';
import { AdminLandlord, Company, LandlordStatus } from '@root/types';
import { FormInstance } from 'antd/lib/form';
import { Prompt } from 'react-router-dom';
import s from './Form.module.less';

const landlordStatuses: LandlordStatus[] = [
  'inactive',
  'building_owner',
  'onboarding',
  'ready_for_approval',
  'live',
];

type NotableTenant = {
  name: string;
  id: string;
};

type Props = {
  landlord: AdminLandlord | null;
  submitText: string;
  onSubmitSuccess: (responseBody: { id: string }) => void;
};

const Form = ({ landlord, submitText, onSubmitSuccess }: Props) => {
  const { t } = useTranslation('admin');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { markets } = useEnums();
  const regions = [...markets.map(({ id }) => id), 'other'];
  const flags = useFlags();

  const [portfolioForms, setPortfolioForms] = useState<FormInstance[]>([]);
  const registerForm = form => {
    setPortfolioForms(oldForms => [...oldForms, form]);
  };
  const deregisterForm = i => {
    setPortfolioForms(oldForms => oldForms.filter((_form, j) => i !== j));
  };

  const {
    options: companies,
    resetOptions: resetCompanies,
    handleSearch: handleCompanySearch,
  } = useFetchedSelect<Company>({
    route: '/api/admin/companies',
    getOptionsFromResponse: (response: { companies: Company[] }) => response.companies,
  });

  const { errors, onSubmit } = useSave({
    onSuccess: onSubmitSuccess,
    persisted: !!landlord,
    updateRoute: routes.api.admin.landlord(landlord?.id),
    createRoute: routes.api.admin.landlords,
  });

  const showValidationErrorIfNecessary = (attr: string) => {
    const errorsForOurAttribute = errors.filter(error => error.field === attr);
    if (errorsForOurAttribute.length > 0) {
      return {
        validateStatus: 'error',
        help: (
          <ul>
            {errorsForOurAttribute.map(error => (
              <li key={error.message}>{error.message}</li>
            ))}
          </ul>
        ),
      } as const;
    }

    return {};
  };

  const [landlordInfoForm] = AntdForm.useForm();
  /*
  onFinish={values =>
    onSubmit({
      ...values,
      companyId: values.company.value,
      executives: values.executives?.split('\n').filter(e => e !== ''),
    })
  }
  */

  const showPreviewUrl = !!landlord && landlord?.status === 'onboarding';

  return (
    <>
      <Prompt when={isFormDirty} message={t('common:prompt.unsavedChanges')} />
      <AntdForm
        onValuesChange={() => setIsFormDirty(true)}
        form={landlordInfoForm}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        initialValues={
          landlord
            ? {
                ...landlord,
                company: {
                  value: landlord.companyId,
                  label: landlord.companyName,
                },
                executives: landlord.executives?.join('\n'),
              }
            : {}
        }
      >
        {errors.length > 0 ? (
          <div className={s.alertContainer}>
            <AdminErrors errors={errors} />
          </div>
        ) : null}
        {flags['outreach.landlord-company-connection-severed'] ? (
          <AntdForm.Item
            {...showValidationErrorIfNecessary('name')}
            name="name"
            label={t('landlord.name')}
            rules={[{ required: true }]}
          >
            <Input />
          </AntdForm.Item>
        ) : null}
        <AntdForm.Item
          {...showValidationErrorIfNecessary('slug')}
          name="slug"
          label={t('landlord.slug')}
          rules={[{ required: true }]}
          extra={t('landlord.slugExtra')}
        >
          <Input disabled={landlord?.hasPublicPage} />
        </AntdForm.Item>
        {landlord?.hasPublicPage ? (
          <AntdForm.Item label={t('landlord.link')}>
            <a href={landlord.url} target="_blank" rel="noreferrer">
              {landlord.url}
            </a>
          </AntdForm.Item>
        ) : null}
        {!flags['outreach.landlord-company-connection-severed'] ? (
          <AntdForm.Item
            {...showValidationErrorIfNecessary('company')}
            name="company"
            label={t('landlord.company')}
            rules={[{ required: true }]}
          >
            <Select
              onChange={resetCompanies}
              onSearch={handleCompanySearch}
              labelInValue
              showSearch
              allowClear
              filterOption={false}
              disabled={landlord?.hasPublicPage}
            >
              {companies.map(company => (
                <Select.Option key={company.id} value={company.id}>
                  {company.name}
                </Select.Option>
              ))}
            </Select>
          </AntdForm.Item>
        ) : null}
        <AntdForm.Item
          {...showValidationErrorIfNecessary('status')}
          name="status"
          label={t('landlord.status')}
          rules={[{ required: true }]}
        >
          <Select allowClear filterOption={false}>
            {landlordStatuses.map(status => (
              <Select.Option key={status} value={status}>
                {startCase(status)}
              </Select.Option>
            ))}
          </Select>
        </AntdForm.Item>
        {showPreviewUrl ? (
          <AntdForm.Item label={t('landlord.previewUrl')}>
            <a href={landlord.previewUrl} target="_blank" rel="noreferrer">
              {landlord.previewUrl}
            </a>
          </AntdForm.Item>
        ) : null}
        <AntdForm.Item
          {...showValidationErrorIfNecessary('demo')}
          label={t('landlord.demo')}
          name="demo"
          valuePropName="checked"
        >
          <Checkbox />
        </AntdForm.Item>
        <AntdForm.Item
          {...showValidationErrorIfNecessary('inventory')}
          name="inventory"
          label={t('landlord.inventory')}
        >
          <Input />
        </AntdForm.Item>
        <AntdForm.Item
          {...showValidationErrorIfNecessary('space')}
          name="space"
          label={t('landlord.space')}
        >
          <Input />
        </AntdForm.Item>
        <AntdForm.Item
          {...showValidationErrorIfNecessary('underConstruction')}
          name="underConstruction"
          label={t('landlord.underConstruction')}
        >
          <Input />
        </AntdForm.Item>
        <AntdForm.Item
          {...showValidationErrorIfNecessary('premierRetailDestinations')}
          name="premierRetailDestinations"
          label={t('landlord.premierRetailDestinations')}
        >
          <Input />
        </AntdForm.Item>
        <AntdForm.Item
          {...showValidationErrorIfNecessary('description')}
          name="description"
          label={t('landlord.description')}
          extra={t('landlord.descriptionExtra')}
        >
          <Input.TextArea rows={10} />
        </AntdForm.Item>
        <AntdForm.Item
          {...showValidationErrorIfNecessary('executives')}
          name="executives"
          label={t('landlord.executives')}
          extra={t('landlord.executivesExtra')}
        >
          <Input.TextArea rows={5} />
        </AntdForm.Item>
      </AntdForm>
      <h2 className={s.portfolioTitle}>{t('landlord.buildingPortfolio')}</h2>
      <div className={s.portfolioFields}>
        <ModelList<{ region: string; notableTenants: NotableTenant[] }>
          registerForm={registerForm}
          deregisterForm={deregisterForm}
          shouldConfirmDelete={({ region, notableTenants }) =>
            !(isEmpty(region) && isEmpty(notableTenants))
          }
          initialModels={landlord?.portfolios || []}
          modelTemplate={{
            region: '',
            notableTenants: [],
          }}
          addButtonText={t('landlord.addNew')}
        >
          {({ region, notableTenants }, form) => (
            <AntdForm
              form={form}
              initialValues={{
                region,
                notableTenants: notableTenants.map(tenant => ({
                  label: tenant.name,
                  key: tenant.id,
                  value: tenant.id,
                })),
              }}
            >
              <div className={s.regionRow}>
                <div className={s.regionField}>
                  <div className="ant-form-item-label">
                    <label htmlFor="region">{t('landlord.region')}</label>
                  </div>
                  <AntdForm.Item name="region">
                    <Select>
                      {regions.map(id => (
                        <Select.Option key={id} value={id}>
                          {t(`common:markets.${id}`)}
                        </Select.Option>
                      ))}
                    </Select>
                  </AntdForm.Item>
                </div>
                <div className={s.regionField}>
                  <div className="ant-form-item-label">
                    <label htmlFor="notableTenants">{t('landlord.notableTenants')}</label>
                  </div>
                  <AntdForm.Item name="notableTenants">
                    <Select
                      mode="multiple"
                      onChange={resetCompanies}
                      onSearch={handleCompanySearch}
                      labelInValue
                      showSearch
                      allowClear
                      filterOption={false}
                      onBlur={resetCompanies}
                    >
                      {companies.map(company => (
                        <Select.Option key={company.id} value={company.id}>
                          {company.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </AntdForm.Item>
                </div>
              </div>
            </AntdForm>
          )}
        </ModelList>
      </div>

      <Button
        htmlType="button"
        type="primary"
        className={s.submit}
        onClick={async () => {
          const valuesToSubmit = {
            ...(landlordInfoForm.getFieldsValue() as {
              company: { value: string };
              executives: string;
            }),
            portfolios: portfolioForms
              .map(form => form.getFieldsValue())
              .map(portfolio => ({
                notableTenantIds: portfolio.notableTenants.map(tenant => tenant.value),
                region: portfolio.region,
              })),
          };
          onSubmit({
            ...valuesToSubmit,
            companyId: valuesToSubmit.company?.value,
            executives: valuesToSubmit.executives?.split('\n').filter(e => e !== ''),
          });
        }}
      >
        {submitText}
      </Button>
    </>
  );
};

export default Form;
