import { reformatDateString } from '@root/shared/dateUtils';
import { capitalize, camelCase } from 'lodash';
import {
  Locale,
  PowerpointEntry,
  PowerpointExternalListingEntry,
  PowerpointListingEntry,
} from '@root/types';
import { dateFormat } from '@shared/TranslationHelpers/formatDate';
import { FONT_FACE, PowerpointTableElement, PptxUtils } from './globals';
import { CONTENT_WIDTH, PAGE_MARGIN } from './portrait';
import { getDisplayPricePerArea } from '../helper';

const thinGray: PptxGenJS.default.BorderProps = { type: 'solid', pt: 1, color: 'D2D2D2' };
const thickDarkGray: PptxGenJS.default.BorderProps = { type: 'solid', pt: 2, color: 'A5A5A5' };

type EitherKindOfListings = PowerpointListingEntry[] | PowerpointExternalListingEntry[];

const buildingCell = {
  fill: 'F2F2F2',
} as PptxGenJS.default.TableCellProps;

const cellBorders: [
  PptxGenJS.default.BorderProps,
  PptxGenJS.default.BorderProps,
  PptxGenJS.default.BorderProps,
  PptxGenJS.default.BorderProps,
] = [thinGray, thinGray, thinGray, thinGray];

const headerStyles: PptxGenJS.default.TableCellProps = {
  bold: true,
  fontSize: 8,
  valign: 'middle',
  border: cellBorders,
};

const buildingRowStyles: PptxGenJS.default.TableCellProps = {
  ...buildingCell,
  border: cellBorders,
};

const emptyBuildingCell = {
  text: '',
  options: buildingRowStyles,
};

const listingRowStyles: PptxGenJS.default.TableCellProps = {
  align: 'center',
  fontSize: 8,
  valign: 'middle',
};

const listingBorderRowStyles: PptxGenJS.default.TableCellProps = {
  ...listingRowStyles,
  border: cellBorders,
};

const MAX_BUILDING_ROW_HEIGHT = 0.7;
const MAX_LISTING_ROW_HEIGHT = 0.31;
const HEADER_ROW_HEIGHT = 0.33;
const MAX_TABLE_SIZE = 4.57;

// border array =  [top, right, bottom, left]
// working with border cheat sheet ^

export const getBaseTableHeight = (): number => HEADER_ROW_HEIGHT;

export const getBuildingSectionHeight = (tourbookListings: EitherKindOfListings): number => {
  return MAX_BUILDING_ROW_HEIGHT + MAX_LISTING_ROW_HEIGHT * tourbookListings.length;
};

export const canFitNextBuilding = (
  tourbookListings: EitherKindOfListings,
  currentTableHeight: number,
): boolean => {
  return currentTableHeight + getBuildingSectionHeight(tourbookListings) < MAX_TABLE_SIZE;
};

const createHeaderRow = (rows: PptxGenJS.default.TableRow[], { t }: PptxUtils) => {
  rows.push([
    {
      text: '',
      options: {
        ...headerStyles,
        align: 'left',
      },
    },
    {
      text: t('tourbook:externalListing.listing').toUpperCase(),
      options: {
        ...headerStyles,
        align: 'left',
        border: cellBorders,
      },
    },
    {
      text: t('tourbook:listingCard.size').toUpperCase(),
      options: {
        ...headerStyles,
        align: 'center',
        border: cellBorders,
      },
    },
    {
      text: t('tourbook:listingCard.baseRentTitle').toUpperCase(),
      options: {
        ...headerStyles,
        align: 'center',
        border: cellBorders,
      },
    },
    {
      text: t('tourbook:listingCard.available').toUpperCase(),
      options: {
        ...headerStyles,
        align: 'center',
        border: cellBorders,
      },
    },
    {
      text: t('tourbook:listingCard.condition').toUpperCase(),
      options: {
        ...headerStyles,
        align: 'center',
        border: cellBorders,
      },
    },
    {
      text: t('tourbook:listingCard.leaseType').toUpperCase(),
      options: {
        ...headerStyles,
        align: 'center',
        border: cellBorders,
      },
    },
  ]);
};

const createBuildingRow = (
  building: PowerpointEntry,
  entryIndex: string,
  rows: PptxGenJS.default.TableRow[],
  utils: PptxUtils,
) => {
  let addressInfo = '';

  if (building.neighborhood) {
    addressInfo += `${building.neighborhood}, `;
  }

  const abbreviatedRegion = building.address.abbreviatedRegion
    ? ` ${building.address.abbreviatedRegion}`
    : '';

  // eslint-disable-next-line max-len
  addressInfo += `${building.address.city},${abbreviatedRegion} ${building.address.postalCode}`;

  const buildingTextRows = [] as any[];

  if (building.address.buildingName) {
    buildingTextRows.push({
      text: building.address.buildingName,
      options: { align: 'left', fontSize: 8, breakLine: true },
    });
  }

  rows.push([
    {
      text: entryIndex,
      options: {
        align: 'left',
        valign: 'top',
        fontSize: 10,
        bold: true,
        color: '5626FF',
        ...buildingCell,
        border: [thickDarkGray, thinGray, thinGray, thinGray],
      },
    },
    {
      text: buildingTextRows.concat(
        [
          {
            text: building.address.street || '',
            options: { align: 'left', fontSize: 8, breakLine: true },
          },
          {
            text: addressInfo,
            options: { align: 'left', fontSize: 8, breakLine: true },
          },
          {
            text: utils.t(`country:${building.address.countryCode}`),
            options: { align: 'left', fontSize: 8 },
          },
        ].filter(b => b.text?.length),
      ),
      options: buildingRowStyles,
    },
    emptyBuildingCell,
    emptyBuildingCell,
    emptyBuildingCell,
    emptyBuildingCell,
    emptyBuildingCell,
  ]);
};

// this displays multiple PowerpointBuildingEntry in one row, but with line breaks
const createListingRow = (
  tourbookListings: PowerpointListingEntry[] | PowerpointExternalListingEntry[],
  rows: PptxGenJS.default.TableRow[],
  utils: PptxUtils,
) => {
  // listing rows
  const names = [] as any[];
  const sizes = [] as any[];
  const prices = [] as any[];
  const availabilities = [] as any[];
  const conditions = [] as any[];
  const leaseTypes = [] as any[];

  const { flags, locale, t } = utils;

  tourbookListings.forEach((listing: PowerpointListingEntry | PowerpointExternalListingEntry) => {
    const {
      askingRent,
      size,
      availableToday,
      dateAvailable,
      name,
      condition,
      leaseType,
      askingRentInMoneyPerAreaPerPeriod,
      minAskingRentInMoneyPerAreaPerPeriod,
      maxAskingRentInMoneyPerAreaPerPeriod,
      type,
    } = listing;

    const formattedSize = size ? t('units:area', { area: size }) : '-';

    const hasEditedAskingRent =
      flags['tourbook-asking-rent'] &&
      (minAskingRentInMoneyPerAreaPerPeriod || maxAskingRentInMoneyPerAreaPerPeriod);

    let price;

    if (askingRent || hasEditedAskingRent) {
      price = `${getDisplayPricePerArea({
        t,
        askingRent: askingRentInMoneyPerAreaPerPeriod,
        minAskingRent: minAskingRentInMoneyPerAreaPerPeriod,
        maxAskingRent: maxAskingRentInMoneyPerAreaPerPeriod,
        acceptRange: flags['tourbook-asking-rent'],
      })!}${hasEditedAskingRent && type !== 'externalListing' ? ' *' : ''}`;
    } else {
      price = t('tourbook:powerpoint.listing.nullBaseRent');
    }

    const available = availableToday
      ? t('common:listing.immediateAvailability')
      : (dateAvailable &&
          reformatDateString({
            value: dateAvailable,
            beforeFormat: 'yyyy-MM-dd',
            afterFormat: dateFormat(locale as Locale),
          })) ||
        '-';

    names.push({
      text: name || '',
      options: {
        align: 'left',
        fontSize: 8,
        valign: 'middle',
        breakLine: true,
        lineSpacingMultiple: 1.5,
      },
    });

    sizes.push({
      text: formattedSize,
      options: {
        ...listingRowStyles,
        breakLine: true,
        lineSpacingMultiple: 1.5,
      },
    });

    prices.push({
      text: price,
      options: {
        ...listingRowStyles,
        breakLine: true,
        lineSpacingMultiple: 1.5,
      },
    });

    availabilities.push({
      text: available,
      options: {
        ...listingRowStyles,
        breakLine: true,
        lineSpacingMultiple: 1.5,
      },
    });

    conditions.push({
      text: !condition ? '-' : t(`spaceConditions:${camelCase(condition)}`),
      options: {
        ...listingRowStyles,
        breakLine: true,
        lineSpacingMultiple: 1.5,
      },
    });

    leaseTypes.push({
      text: capitalize(leaseType) === '' ? '-' : capitalize(leaseType),
      options: {
        ...listingRowStyles,
        breakLine: true,
        lineSpacingMultiple: 1.5,
      },
    });
  });

  rows.push([
    { text: '', options: { border: cellBorders } },
    {
      text: names,
      options: { border: cellBorders, valign: 'middle' },
    },
    {
      text: sizes,
      options: listingBorderRowStyles,
    },
    {
      text: prices,
      options: listingBorderRowStyles,
    },
    {
      text: availabilities,
      options: listingBorderRowStyles,
    },
    {
      text: conditions,
      options: listingBorderRowStyles,
    },
    {
      text: leaseTypes,
      options: listingBorderRowStyles,
    },
  ]);
};

export const createAllBuildingRows = (
  entry: PowerpointEntry,
  entryLabel: string,
  rows: PptxGenJS.default.TableRow[],
  utils: PptxUtils,
) => {
  createBuildingRow(entry, entryLabel, rows, utils);
  if (entry.type === 'building') {
    createListingRow(entry.listings, rows, utils);
  }
  if (entry.type === 'externalListing') {
    createListingRow([entry], rows, utils);
  }
};

const splitColumns = (width: number, columns: number[]): number[] => {
  const total = columns.reduce((n, sum = 0) => n + sum);
  const fraction = width / total;
  return columns.map(column => column * fraction);
};

export const generateTable = (
  rows: PptxGenJS.default.TableRow[],
  yPosition: number,
): PowerpointTableElement => {
  return {
    type: 'table',
    placement: { w: CONTENT_WIDTH, x: PAGE_MARGIN, y: yPosition },
    data: rows,
    tableOptions: {
      border: thinGray,
      rowH: [HEADER_ROW_HEIGHT],
      colW: splitColumns(CONTENT_WIDTH, [1.5, 10, 4, 4, 4, 4, 4]),
      fontFace: FONT_FACE,
    },
  };
};

export const createBaseRows = (utils: PptxUtils): PptxGenJS.default.TableRow[] => {
  const rows: PptxGenJS.default.TableRow[] = [];

  createHeaderRow(rows, utils);

  return rows;
};

export default createAllBuildingRows;
