import React, { useContext, createContext, useReducer } from 'react';
import {
  FilterOptionsState,
  initialState,
  filterOptionsReducer,
} from './FilterOptionsReducer/FilterOptionsReducer';
import fetchFiltersFromCriteria from './fetchFiltersFromCriteria';
import ListingSearchCriteria from '../Criteria/ListingSearchCriteria';

export const FilterOptionsContext = createContext<
  [
    state: FilterOptionsState,
    dispatch: React.Dispatch<any>,
    fetchOptionsFromCriteria: (
      dispatch: React.Dispatch<any>,
      criteria: ListingSearchCriteria,
    ) => void,
  ]
>([initialState, () => null, () => null]);

export const FilterOptionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filterOptionsReducer, initialState);

  return (
    <FilterOptionsContext.Provider value={[state, dispatch, fetchFiltersFromCriteria]}>
      {children}
    </FilterOptionsContext.Provider>
  );
};

export const useFilterOptions = () => useContext(FilterOptionsContext);

export default FilterOptionsProvider;
