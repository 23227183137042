import { useEffect, useState } from 'react';
import { Result, ConfigProvider } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import routes from '@root/routes';
import { CurrentUser } from '@root/types';
import { setBugsnagUser } from '@shared/bugsnagClient';
import useUserRegisterer from '@components/shared/useUserRegisterer';
import api from '@shared/api';
import '@styles/tailwind.css';
import { ThemeProvider } from '@viewthespace/components';
import BuildingPage from './BuildingPage';
import PropertySearch from './PropertySearch';
import PropertyPage from './PropertyPage';
import UserSearch from './UserSearch';
import UserPage from './UserPage';
import EditListing from './EditListing';
import ListingSearch from './ListingSearch';
import BuildingSearch from './BuildingSearch';
import Companies from './Companies';
import Tenants from './Tenants';
import Landlords from './Landlords';
import TourbookExportTool from './TourbookExportTool';
import AdminContext from './AdminContext';

const {
  admin: {
    root: adminRootRoute,
    vtsPropertySearch: vtsPropertySearchRoute,
    vtsProperty: vtsPropertyRoute,
    building: buildingRoute,
    buildingSearch: buildingSearchRoute,
    userSearch: userSearchRoute,
    userPage: userPageRoute,
    listingPage: listingPageRoute,
    listingSearch: listingSearchRoute,
    companies: companiesPageRoutes,
    landlords: landlordsPageRoutes,
  },
} = routes;

Modal.setAppElement('#layouts-admin');

type Props = {
  currentUser: CurrentUser;
};

function App({ currentUser }: Props) {
  const [requestedListingsCount, setRequestedListingsCount] = useState(0);
  const flags = useFlags();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    fetchRequestedListings();
  }, []);

  const fetchRequestedListings = async () => {
    const response = await api.fetch(
      routes.api.admin.listingSearch({
        statuses: ['requested'],
      }),
    );
    const { pagination } = await response.json();

    setRequestedListingsCount(pagination.totalCount);
  };

  useUserRegisterer();
  if (!currentUser) return null;
  setBugsnagUser(currentUser);

  return (
    /*
			ConfigProvider with getPopupContainer was added to fix popup components,
			such as Dropdown and Select in scrollable containers. Prior to this fix,
			dropdowns would remain in a fixed position on the screen when scrolling.
			See details here:
			https://ant.design/docs/react/faq
			#Select-Dropdown-DatePicker-TimePicker-Popover-Popconfirm-scrolls-with-the-page
		*/
    /* @ts-expect-error */
    <ConfigProvider getPopupContainer={trigger => (trigger ? trigger.parentNode : document.body)}>
      <ThemeProvider testAttribute="data-testid" defaultButtonShape="pill" menuZIndex={11}>
        <AdminContext.Provider
          value={{ currentUser, numRequestedListings: requestedListingsCount }}
        >
          <Switch>
            <Route path={vtsPropertyRoute(':id')} component={PropertyPage} />
            <Route path={vtsPropertySearchRoute} component={PropertySearch} />
            <Route path={buildingRoute(':id')} component={BuildingPage} />
            <Route path={buildingSearchRoute} component={BuildingSearch} />
            <Route path={userPageRoute(':id')} component={UserPage} />
            <Route path={userSearchRoute} component={UserSearch} />
            <Route
              path={listingPageRoute(':id')}
              render={props => (
                <EditListing
                  {...props}
                  refreshRequestedListingsCount={fetchRequestedListings}
                  location={location}
                  history={history}
                  flags={flags}
                />
              )}
            />
            <Route
              path={listingSearchRoute}
              render={props => (
                <ListingSearch
                  {...props}
                  requestedListingsCount={requestedListingsCount}
                  refreshRequestedListingsCount={fetchRequestedListings}
                />
              )}
            />
            {flags['outreach.landlord-company-connection-severed'] ? (
              <Route path={companiesPageRoutes} component={Tenants} />
            ) : (
              <Route path={companiesPageRoutes} component={Companies} />
            )}
            <Route path={landlordsPageRoutes} component={Landlords} />
            <Route path={routes.admin.tourbookExportTool} component={TourbookExportTool} />
            <Route
              path={adminRootRoute}
              exact
              render={() => <Redirect to={buildingSearchRoute} />}
            />
            <Route component={() => <Result status="404" title="404" />} />
          </Switch>
        </AdminContext.Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

const mapStateToProps = ({ currentUser }) => ({
  currentUser,
});

export default connect(mapStateToProps)(App);
