import * as React from 'react';
import { Table } from 'antd';
import addKeyTo from '@shared/addKeyTo';
import DraggableRow from './DraggableRow';

const move = (items: any[], from: number, to: number): any[] => {
  const withoutItem = items.filter((_: any, index: number) => index !== from);
  return [...withoutItem.slice(0, to), items[from], ...withoutItem.slice(to, items.length)];
};

type Props = {
  loading?: boolean;
  columns: Array<{
    title: string | React.ReactNode;
    dataIndex?: string;
    render?: (dataIndex: any, object: any, index: number) => React.ReactNode;
  }>;
  dataSource: any[];
  onChange: (rows: any[]) => void;
  setSelectedRows: (val: any[]) => void;
  selectedRows: string[];
};

const DraggableAdminTable = ({
  columns,
  dataSource,
  loading,
  onChange,
  setSelectedRows,
  selectedRows,
}: Props) => {
  const [rows, setRows] = React.useState(dataSource);
  const moveRow = (fromIndex: number, toIndex: number) => {
    const updatedRows = move(rows, fromIndex, toIndex);
    setRows(updatedRows);
    onChange(updatedRows);
  };

  React.useEffect(() => {
    setRows(dataSource);
  }, [dataSource]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRows([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: onSelectChange,
  };

  return (
    <Table
      pagination={false}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={addKeyTo(rows)}
      loading={loading}
      components={{
        body: {
          row: DraggableRow,
        },
      }}
      onRow={(_record, index): any => ({
        index,
        moveRow,
      })}
    />
  );
};

export default DraggableAdminTable;
