import { useTranslation } from 'react-i18next';
import { CustomIcon } from '@components/shared';
import s from './ShareModal.module.less';

const SuccessfullySent = () => {
  const { t } = useTranslation('listing');

  return (
    <div className={s.successBody}>
      <CustomIcon type="checkmark" className={s.successCheck} />
      <h2>{t('share.spaceShared')}</h2>
      <div className={s.successBodyMessage}>{t('share.successMessage')}</div>
    </div>
  );
};

export default SuccessfullySent;
