import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import Divider from '@components/shared/Divider';
import { Select, NumberInput } from '@components/shared/forms';
import css from './Bathroom.module.less';

const Bathroom = React.forwardRef((_props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
  const { t } = useTranslation('admin');

  const bathroomTypeOptions = [
    {
      label: t('listing.rooms.form.bathroom.bathroomType.unisexSharedSink'),
      value: 'unisex_shared_sink',
    },
    {
      label: t('listing.rooms.form.bathroom.bathroomType.unisexWithSink'),
      value: 'unisex_with_sink',
    },
    { label: t('listing.rooms.form.bathroom.bathroomType.stalls'), value: 'stalls' },
  ];

  return (
    <>
      <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-4x', 'u-p-bottom-2x')}>
        {t('listing.rooms.form.bathroom.title')}
      </h2>
      <section className={css.listingRoomFormSection}>
        <div className={css.bathroomType}>
          <Select
            labelText={t(`listing.rooms.form.bathroom.bathroomType.label`)}
            name="bathroomType"
            options={bathroomTypeOptions}
            placeholder=""
          />
        </div>
        <div className={css.bathroomStallCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.bathroom.bathroomStallCount')}
            name="bathroomStallCount"
          />
        </div>
        <div className={css.bathroomSinkCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.bathroom.bathroomSinkCount')}
            name="bathroomSinkCount"
          />
        </div>
      </section>
      <Divider />
    </>
  );
});

export default React.memo(Bathroom);
