import { ReactNode } from 'react';
import { useField } from 'formik';
import cn from 'classnames';
import { Option, Select } from '@viewthespace/components';
import FieldWrapper from '../../FieldWrapper';

import { SelectValue } from '../types';

export type Props<T> = {
  name: string;
  labelText?: ReactNode;
  required?: boolean;
  options: Option<T>[];
  value?: T;
  isClearable?: boolean;
  placeholder?: string;
  labelClass?: string;
  containerClass?: string;
  className?: string;
  isDisabled?: boolean;
  warningText?: ReactNode;
  'data-testid'?: string;
};

const TerraSelect = <T extends SelectValue>({
  name,
  labelText,
  required = false,
  options,
  value,
  isClearable = false,
  placeholder,
  labelClass,
  containerClass,
  isDisabled = false,
  className,
  warningText,
  'data-testid': dataTestId,
}: Props<T>) => {
  const [field, meta, helpers] = useField({ name });

  const selectedOption = options.find(
    option => option.value === value || option.value === field.value,
  );
  return (
    <FieldWrapper
      name={name}
      labelText={labelText}
      required={required}
      labelClass={labelClass}
      containerClass={containerClass}
      data-testid={dataTestId}
    >
      <Select
        name={field.name}
        hideLabel
        label=""
        className={cn(
          className,
          // Can't just use errorText prop since our other components have a different error color
          meta.error &&
            meta.touched &&
            '[&_.border-border-regular]:border-[#E60C0C] [&_.border-border-regular]:outline-[#E60C0C]',
        )}
        placeholder={placeholder}
        value={selectedOption}
        onChange={newOption => {
          helpers.setValue(newOption?.value);
          helpers.setTouched(true);
        }}
        options={options}
        isClearable={isClearable}
        isDisabled={isDisabled}
        selectionType="single"
        backgroundStyle="filled"
      />
      {warningText && <div>{warningText}</div>}
    </FieldWrapper>
  );
};

export default TerraSelect;
