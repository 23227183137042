import { Form, Formik } from 'formik';
import { AdminBuilding } from '@root/types';
import { useTranslation } from 'react-i18next';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { AdminFormSubmitter } from '@components/shared';
import { Prompt } from 'react-router-dom';
import Certifications from './Certifications';
import css from './styles.module.less';
import { amenitySetValidationSchema } from './validationSchema';
import BuildingAmenitiesList from './BuildingAmenitiesList';

type Props = {
  building: AdminBuilding;
  updateBuilding: Function;
};

const AmenitiesBuildingForm = ({ building, updateBuilding }: Props) => {
  const { t } = useTranslation('admin');

  const initialValues = { ...building, amenitySet: building.amenitySet };

  return (
    <Formik
      enableReinitialize
      initialValues={JSON.parse(JSON.stringify(initialValues, (k, v) => (v == null ? '' : v)))}
      onSubmit={async (values, actions) => {
        const params = JSON.parse(JSON.stringify(values, (k, v) => (v === '' ? null : v)));

        await updateBuilding(params);
        actions.setSubmitting(false);
      }}
      validationSchema={amenitySetValidationSchema(t)}
    >
      {({ values, dirty }) => (
        <Form className={css.form}>
          <FormThemeContextProvider theme="vertical">
            <section className={css.amenities}>
              <h2>{t('building.amenities.title')}</h2>
              <BuildingAmenitiesList
                barbershop={values.amenitySet.barbershop}
                barAvailable={values.amenitySet.barAvailable}
                bikeStorageAvailable={values.amenitySet.bikeStorageAvailable}
                cafeAvailable={values.amenitySet.cafeAvailable}
                carWash={values.amenitySet.carWash}
                cctv={values.amenitySet.cctv}
                commonAreaWifi={values.amenitySet.commonAreaWifi}
                communalLobbySpace={values.amenitySet.communalLobbySpace}
                communalOutdoorSpace={values.amenitySet.communalOutdoorSpace}
                communalRoofAccess={values.amenitySet.communalRoofAccess}
                conferenceCenter={values.amenitySet.conferenceCenter}
                controlledElevatorAccess={values.amenitySet.controlledElevatorAccess}
                coveredParking={values.amenitySet.coveredParking}
                destinationDispatchElevators={values.amenitySet.destinationDispatchElevators}
                electricVehicleChargingStationsCount={
                  values.amenitySet.electricVehicleChargingStationsCount
                }
                eventSpace={values.amenitySet.eventSpace}
                flexSpaceAvailable={values.amenitySet.flexSpaceAvailable}
                foodServiceAvailable={values.amenitySet.foodServiceAvailable}
                generalStoreAvailable={values.amenitySet.generalStoreAvailable}
                gymAvailable={values.amenitySet.gymAvailable}
                keyCardBuildingEntry={values.amenitySet.keyCardBuildingEntry}
                loadingDock={values.amenitySet.loadingDock}
                lobbyAttendant={values.amenitySet.lobbyAttendant}
                lobbyBeverageAvailable={values.amenitySet.lobbyBeverageAvailable}
                lobbyFoodAvailable={values.amenitySet.lobbyFoodAvailable}
                lockerRooms={values.amenitySet.lockerRooms}
                messengerCenter={values.amenitySet.messengerCenter}
                networkingEvents={values.amenitySet.networkingEvents}
                optionToAddInternetServiceProvider={
                  values.amenitySet.optionToAddInternetServiceProvider
                }
                parkingAvailable={values.amenitySet.parkingAvailable}
                parkingGarage={values.amenitySet.parkingGarage}
                passengerElevatorCount={values.amenitySet.passengerElevatorCount}
                petsAllowed={values.amenitySet.petsAllowed}
                pharmacyAvailable={values.amenitySet.pharmacyAvailable}
                propertyManagerOnSite={values.amenitySet.propertyManagerOnSite}
                restaurantAvailable={values.amenitySet.restaurantAvailable}
                serviceElevatorCount={values.amenitySet.serviceElevatorCount}
                showers={values.amenitySet.showers}
                shuttle={values.amenitySet.shuttle}
                spaAvailable={values.amenitySet.spaAvailable}
                storageAvailable={values.amenitySet.storageAvailable}
                tenantLounge={values.amenitySet.tenantLounge}
                tenantPortalApp={values.amenitySet.tenantPortalApp}
                touchFreeElevatorAccess={values.amenitySet.touchFreeElevatorAccess}
                touchlessTurnstileEntry={values.amenitySet.touchlessTurnstileEntry}
                transportationAccessible={values.amenitySet.transportationAccessible}
                turnstiles={values.amenitySet.turnstiles}
                twentyFourSevenAccess={values.amenitySet.twentyFourSevenAccess}
                twentyFourSevenSecurity={values.amenitySet.twentyFourSevenSecurity}
                urgentCareDoctorOnsite={values.amenitySet.urgentCareDoctorOnsite}
                valetParking={values.amenitySet.valetParking}
                yogaStudioAvailable={values.amenitySet.yogaStudioAvailable}
              />
            </section>
            <Certifications
              leedCertification={values.amenitySet.leedCertification}
              wiredCertification={values.amenitySet.wiredCertification}
            />
            <AdminFormSubmitter />
            <Prompt when={dirty} message={t('common:prompt.unsavedChanges')} />
          </FormThemeContextProvider>
        </Form>
      )}
    </Formik>
  );
};

export default AmenitiesBuildingForm;
