import * as Yup from 'yup';

const validationSchema = t => {
  const schemaFields = {
    amenitySet: Yup.object().shape({
      sharedFloorBathroomStallCount: Yup.number().min(0, t('listing.amenities.minZero')),
      windowSidesCount: Yup.number().min(0, t('listing.amenities.minZero')),
      drinkingFountainCount: Yup.number().min(0, t('listing.amenities.minZero')),
    }),
  };
  return Yup.object().shape(schemaFields);
};
export default validationSchema;
