import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, IconButton } from '@components/shared';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getListingCount } from '@root/store/selectors';
import { StoreState } from '@root/types';
import { memo, useEffect, useRef, useState } from 'react';
import useSize from '@react-hook/size';
import ListingSearchFilters from './ListingSearchFilters';
import s from './ListingSearchFiltersPanel.module.less';
import FilterPill, { FilterPillOptions } from '../SearchCriteriaBar/FilterPill/FilterPill';

const OVERFLOW_THRESHOLD_IN_PX = 62;

export type ListingSearchFiltersPanelProps = {
  isOpen: boolean;
  closePanel: () => void;
  removeAll: () => void;
  activeFilters: FilterPillOptions[];
  onFilterPillClick: (filterkey: string, filter: string, value?: string) => void;
  panelSaveSearchSelector: JSX.Element | null;
  showSavedSearchSelector: boolean;
};

const mapState = (state: StoreState) => ({
  listingsCount: getListingCount(state),
});

const connector = connect(mapState);
export type ReduxProps = ConnectedProps<typeof connector>;

export const ListingSearchFiltersPanel = ({
  isOpen,
  closePanel,
  removeAll,
  listingsCount,
  activeFilters,
  onFilterPillClick,
  panelSaveSearchSelector,
  showSavedSearchSelector,
}: ListingSearchFiltersPanelProps & ReduxProps) => {
  const { t } = useTranslation('filters');
  const history = useHistory();
  const [barIsCollapsed, setBarIsCollapsed] = useState(true);
  const pillContainerRef = useRef<HTMLDivElement | null>(null);
  const pillContainerHeight = useSize(pillContainerRef)[1];
  const pillLabelsStillLoading = activeFilters.some(option => !option.label);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const unblock = history.block((_, action) => {
      if (isOpen && action === 'POP') {
        closePanel();

        // Returning false to prevent navigation
        return false;
      }

      unblock();
    });

    return unblock;
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, isOpen]);

  return (
    <div
      data-testid="listingSearchFiltersPanel"
      className={cn(s.panelContainer, !isOpen && s.isOffScreen)}
    >
      <div
        className={cn(s.header, {
          [s.withSavedSearch]: showSavedSearchSelector,
        })}
      >
        <div className="flex justify-between">
          <div className={s.title}>{t('searchCriteriaBar:searchCriteria')}</div>
          <button
            className={s.clearFiltersButton}
            onClick={removeAll}
            type="button"
            disabled={!activeFilters.length}
          >
            {t('clearAll')}
          </button>
        </div>
        {showSavedSearchSelector ? (
          <>
            <div className={cn(s.separator, s.withSavedSearch)} />
            {panelSaveSearchSelector}
          </>
        ) : null}
      </div>
      <div
        className={cn(
          s.currentSearchCriteria,
          { [s.withSavedSearch]: showSavedSearchSelector },
          (!activeFilters.length || pillLabelsStillLoading) && s.isHidden,
        )}
      >
        <div className={cn(s.pillSection, !barIsCollapsed && s.expanded)}>
          <div className={s.pillContainer} ref={pillContainerRef}>
            {!pillLabelsStillLoading &&
              activeFilters &&
              activeFilters.map(({ label, filterKey, filter, value }) => {
                return (
                  <div className={s.pill} key={`FilterPill-${filterKey}`} data-testid="FilterPill">
                    <FilterPill
                      label={label}
                      filterKey={filterKey}
                      filter={filter}
                      value={value}
                      onPillClick={onFilterPillClick}
                    />
                  </div>
                );
              })}
          </div>
        </div>

        <div
          className={cn(s.separatorWrapper, {
            [s.withSavedSearch]: showSavedSearchSelector,
          })}
        >
          <IconButton
            data-testid="expand-collapse-button"
            className={cn(
              s.expandCollapseButton,
              { [s.withSavedSearch]: showSavedSearchSelector },
              !activeFilters.length && s.hidden,
            )}
            icon={barIsCollapsed ? 'chevron-down' : 'chevron-up'}
            disabled={pillContainerHeight < OVERFLOW_THRESHOLD_IN_PX}
            onClick={() => {
              setBarIsCollapsed(!barIsCollapsed);
            }}
          />
          <div
            className={cn(s.separator, {
              [s.withSavedSearch]: showSavedSearchSelector,
            })}
          />
        </div>
      </div>
      <div
        className={cn(s.filterListContainer, {
          [s.withSavedSearchNoFilterOptions]: showSavedSearchSelector && !activeFilters.length,
        })}
      >
        <ListingSearchFilters />
      </div>
      <div className={cn(s.footer, !isOpen && s.isOffScreen)}>
        <Button className={s.viewResultsButton} type="primary" size="small" onClick={closePanel}>
          {`${t('viewResults')} (${listingsCount})`}
        </Button>
      </div>
    </div>
  );
};

export default connector(memo(ListingSearchFiltersPanel));
