import { Select } from '@components/shared/forms';
import css from '../styles.module.less';

type ChannelsSelectProps = {
  labelText: string;
  name: string;
  options: Array<{ label: string; value: string }>;
  value: string;
  children?: React.ReactNode;
  containerTestId: string;
  placeholder?: string;
};

const ChannelsSelect = ({
  name,
  value,
  options,
  labelText,
  children,
  containerTestId,
  placeholder,
}: ChannelsSelectProps) => {
  return (
    <div className={css.sideBySide} data-testid={containerTestId}>
      <Select
        className={css.select}
        containerClass={css.left}
        isClearable={false}
        labelText={labelText}
        name={name}
        options={options}
        placeholder={placeholder}
        value={value}
      />
      {children}
    </div>
  );
};

export default ChannelsSelect;
