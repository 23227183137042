import { useState } from 'react';
import useMarketo from '@shared/useMarketo';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import actions from '@store/actions/buildingPage';
import routes from '@root/routes';
import { BuildingListing, StoreState, Building } from '@root/types';
import { getBuildingBySlug } from '@store/selectors';
import useCurrentUser from '@shared/useCurrentUser';
import { useQuery } from '@tanstack/react-query';
import api from '@shared/api';
import { get } from '@shared/typedApi';
import analyticsActions from '@store/actions/buildingPage/analyticsActions';
import otherActions from '@store/actions/index';
import NotFound from '../404/NotFound';
import Skeleton from './Skeleton';
import TruvaLayout from '../Layout';
import BuildingLayout from './Layout';
import BuildingHead from './BuildingHead';
import { ListingTab } from './AvailableSpaces';

const mapState = (
  state: StoreState,
  ownProps: { match: { params: { buildingSlug: string } } },
) => ({
  previewBuilding: getBuildingBySlug(state, ownProps.match.params.buildingSlug),
});
const mapDispatch = {
  listingClicked: (listing: BuildingListing, tab: ListingTab | '') =>
    actions.buildingToListingClicked(listing, tab),
};

const connector = connect(mapState, mapDispatch);
export type ReduxProps = ConnectedProps<typeof connector>;

const usePageLoadEvent = (building, slug, isPreview) => {
  const dispatch = useDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);

  if (isPreview && !hasLoaded) {
    dispatch(otherActions.setBuildingPage(building!, isPreview));
    dispatch(analyticsActions.buildingPageLoaded());
    setHasLoaded(true);
  }

  const pageLoadedEvent = async (fetchedBuilding?: Building) => {
    await dispatch(otherActions.setBuildingPage(fetchedBuilding || building, isPreview));
    if (!hasLoaded) {
      dispatch(analyticsActions.buildingPageLoaded());
    }
    setHasLoaded(true);
    window.prerenderReady = true;
  };

  return { pageLoadedEvent };
};

export interface Props {
  isPreview?: boolean;
}

const RawBuilding = ({
  listingClicked,
  isPreview = false,
  previewBuilding,
}: Props & ReduxProps) => {
  const { buildingSlug } = useParams<{ buildingSlug: string }>();

  const { currentUser } = useCurrentUser();

  const {
    data: fetchedBuilding,
    isLoading,
    error,
    fetchStatus,
  } = useQuery<Building>(
    [routes.api.building(buildingSlug)],
    () => get(routes.api.building(buildingSlug)),
    {
      onSuccess: async b => {
        await pageLoadedEvent(b);
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !isPreview,
    },
  );

  const { data: savedBuildings } = useQuery(
    [routes.api.saveBuilding()],
    async () => {
      const response = await api.fetch(routes.api.saveBuilding());
      return response.json();
    },
    {
      enabled: !!currentUser,
    },
  );

  const building = isPreview ? previewBuilding : fetchedBuilding;

  const { pageLoadedEvent } = usePageLoadEvent(building, buildingSlug, isPreview);

  const isSaved =
    savedBuildings && savedBuildings.find(savedBuilding => savedBuilding.slug === buildingSlug);

  useMarketo();

  if (isLoading && fetchStatus === 'fetching') {
    return (
      <TruvaLayout>
        <Skeleton />
      </TruvaLayout>
    );
  }

  if (error) {
    return <NotFound />;
  }

  return building ? (
    <TruvaLayout data-testid="buildingPage" showFooter={!!building}>
      <BuildingHead building={building} isPreview={isPreview} />
      <BuildingLayout
        building={building}
        hasCurrentUser={!!currentUser}
        listingClicked={listingClicked}
        isPreview={isPreview}
        isSaved={isSaved}
      />
    </TruvaLayout>
  ) : (
    <NotFound />
  );
};

const EnhancedBuilding = connector(RawBuilding);

export { RawBuilding };
export default EnhancedBuilding;
