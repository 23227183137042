import { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import api from '@shared/api';
import routes from '@root/routes';
import { debounce } from 'lodash';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import actions from '@store/actions/listingSearchPage';
import AsyncFilterDropdown from '../../FilterDropdown/AsyncFilterDropdown';
import { OptionType } from '../../FilterDropdown/types';
import useListingSearchCriteria from '../../utils/useListingSearchCriteria';
import { useFilterOptions } from '../../utils/FilterOptions/FilterOptionsProvider';

interface ReadonlyLandlordResults {
  readonly name: string;
  readonly id: string;
}

const DEBOUNCE_DURATION = 750;

const LandlordFilter = () => {
  const { t } = useTranslation('filters');
  const dispatch = useDispatch();
  const [state, dispatchFilterOptions] = useFilterOptions();
  const [defaultOptions, setDefaultOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const flags = useFlags();
  const { dropdownFilterInteraction } = useFilterInteraction();

  const criteria = useListingSearchCriteria();

  const loadOptions = (inputValue: string, callback: Function) => {
    api
      .fetch(
        routes.api.landlords({
          marketSlug: criteria.marketSlug,
          query: inputValue,
        }),
      )
      .then(async response => {
        const rawSearchResults: ReadonlyLandlordResults[] = await response.json();
        callback(
          rawSearchResults.map(
            (landlords): OptionType => ({
              label: landlords.name,
              value: landlords.id,
            }),
          ),
        );
      });
  };

  const debouncedLoadOptions = debounce(loadOptions, DEBOUNCE_DURATION);

  const landlords = criteria?.currentFilters?.landlords || [];

  const currentFilterOptions = landlords.map(landlordId => {
    return { label: state.landlords[landlordId]?.name, value: landlordId };
  });

  const handleChange = currentValues => {
    const isAdding = criteria.landlordIds().length < currentValues.length;
    const currentLandlordIds: string[] = currentValues?.map(option => option.value) || [];
    const landlordId = isAdding
      ? currentLandlordIds.find(id => !criteria.landlordIds().includes(id))
      : criteria.landlordIds().find(id => !currentLandlordIds.includes(id));

    criteria.add('landlords', currentLandlordIds);

    dispatchFilterOptions({
      type: 'ADD_LANDLORDS_OPTIONS',
      payload: currentValues?.map(option => {
        return { id: option.value, name: option.label };
      }),
    });

    criteria.pushToHistory();

    if (flags['search-analytics-refactor']) {
      dropdownFilterInteraction({
        filterType: 'landlords',
        isAdding,
        value: landlordId!,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatch(actions.landlordFilterChanged(currentValues?.map(option => option.label)));
    }
  };

  useEffect(() => {
    setDefaultOptions([]);
  }, [criteria.marketSlug]);

  const loadDefaultOptions = () => {
    if (defaultOptions.length === 0) {
      setIsLoading(true);
      loadOptions('', response => {
        setDefaultOptions(response);
        setIsLoading(false);
      });
    }
  };

  return (
    <div className="u-p-top-2x" data-testid="landlordsFilter">
      <AsyncFilterDropdown
        loadOptions={debouncedLoadOptions}
        defaultOptions={defaultOptions}
        onFocus={() => loadDefaultOptions()}
        isLoading={isLoading}
        name="landlord filter"
        placeholder={t('landlord.placeHolder')}
        onChange={handleChange}
        value={currentFilterOptions}
      />
    </div>
  );
};

export default LandlordFilter;
