import { PowerpointTourbook } from '@root/types';
import api from '@shared/api';
import Bugsnag from '@shared/bugsnagClient';
import { useFlags } from 'launchdarkly-react-client-sdk';
import routes from '@root/routes';
import { useTranslation } from 'react-i18next';
import useEnv from '@shared/useEnv';
import generateSlidesFromTourbook from '@components/layouts/Truva/Tourbook/ppt-utils';

type NoArgFunction = () => void;

const useDownloadTourbook = () => {
  const { googleMapsKey, cloudinaryCloud, cloudinaryVtsImageCloud } = useEnv();
  const { t } = useTranslation('tourbook');
  const flags = useFlags();
  const { railsEnv } = useEnv() as { railsEnv: 'development' | 'production' | 'test' };
  const { locale } = useEnv();

  const downloadTourbook = async ({
    tourbookId,
    onStart = () => {},
    onFinish = () => {},
    onFailure = () => {},
  }: {
    tourbookId: string;
    onStart?: NoArgFunction;
    onFinish?: NoArgFunction;
    onFailure?: NoArgFunction;
  }) => {
    onStart();
    const response = await api.fetch(routes.api.tourbookPowerpoint(tourbookId));
    if (response.ok) {
      const tourbookPowerpoint: PowerpointTourbook = await response.json();

      try {
        generateSlidesFromTourbook(
          tourbookPowerpoint,
          {
            googleMapsKey: googleMapsKey!,
            cloudinaryCloud: cloudinaryCloud!,
            vtsCloudinaryCloud: cloudinaryVtsImageCloud!,
            t,
            flags,
            railsEnv,
            locale: locale!,
          },
          onFinish,
          onFailure,
        );
      } catch (error) {
        onFailure();
        // eslint-disable-next-line no-console
        console.error(error);
        Bugsnag.notify(error);
      }
    } else {
      onFailure();
    }
  };

  return downloadTourbook;
};

export default useDownloadTourbook;
