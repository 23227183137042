const indexOfFirstMatch = (word, search, searchStartOfWordsOnly = false) => {
  if (searchStartOfWordsOnly) {
    const startOfStringMatch = word.search(new RegExp(`^${search}`, 'i'));
    const startOfOtherWordMatch = word.search(new RegExp(`( |,|-)${search}`, 'i'));

    // Start index of match taking into account offsetting the ( |,|-) in front of the words.
    return startOfOtherWordMatch >= 0 ? startOfOtherWordMatch + 1 : startOfStringMatch;
  }

  return word.toLowerCase().indexOf(search.toLowerCase());
};

export default indexOfFirstMatch;
