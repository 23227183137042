import api from '@shared/api';
import { BuildingOwnerSummary } from '@root/types';
import routes from '@root/routes';

type ReceivedUpdatedSavedBuildingOwnerAction = {
  type: 'RECEIVE_UPDATED_SAVED_BUILDING_OWNER';
  payload: BuildingOwnerSummary;
};

export const receiveUpdatedSavedBuildingOwner = (
  owner: BuildingOwnerSummary,
): ReceivedUpdatedSavedBuildingOwnerAction => ({
  type: 'RECEIVE_UPDATED_SAVED_BUILDING_OWNER',
  payload: owner,
});

const updateSavedBuildingOwner =
  (slug: string, changes: { receivesNotifications: boolean }) => async dispatch => {
    const response = await api.put(routes.api.buildingOwnerSave(slug), changes);

    if (response.ok) {
      const owner: BuildingOwnerSummary = await response.json();
      dispatch(receiveUpdatedSavedBuildingOwner(owner));
    }
  };

export default updateSavedBuildingOwner;

export type Actions = ReceivedUpdatedSavedBuildingOwnerAction;
