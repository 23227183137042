import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useEnv from '@shared/useEnv';
import Button from '@components/shared/V2Button';
import CustomIcon, { ValidIconTypes } from '@components/shared/CustomIcon';
import { ButtonSize } from '@components/shared/V2Button/Button';
import { MIME_TYPES } from '@components/shared/Admin/MultimediaUploader';
import get from 'lodash/get';
import api from '@shared/api';
import filterOutLargeFiles from './utils';

/* eslint-disable camelcase */
export type DefaultCloudinaryResponseType = {
  public_id: string;
  original_filename: string;
  format: string;
  created_at: string;
};
/* eslint-enable camelcase */

export type DefaultPhotoUploadType = {
  cloudinaryId: string;
  filename: string;
  processedAt: string;
};

const pluckFileAttributesFromCloudinary = (
  cloudinaryJson: DefaultCloudinaryResponseType,
): DefaultPhotoUploadType => {
  /* eslint-disable camelcase */
  const { public_id, original_filename, format, created_at } = cloudinaryJson;
  return {
    cloudinaryId: public_id,
    filename: `${original_filename}.${format}`,
    processedAt: created_at,
  };
  /* eslint-enable camelcase */
};

type Props<CloudinaryResponseType, PhotoUploadType> = {
  marketplaceRequest: (
    files: (PhotoUploadType | DefaultPhotoUploadType)[],
  ) => PromiseLike<{ ok: boolean }>;
  cloudinaryRoute: string;
  cloudinaryTransformer?: (
    json: CloudinaryResponseType | DefaultCloudinaryResponseType,
  ) => PhotoUploadType | DefaultPhotoUploadType;
  multiple?: boolean;
  accept: string;
  onSuccess: (args: { totalFilesCount: number }) => void;
  onError: (args: { totalFilesCount: number }) => void;
  buttonText: string;
  buttonIcon?: ValidIconTypes;
  buttonSize?: ButtonSize;
  buttonClass?: string;
  uploadSizeLimitInMb?: number;
};

const MultimediaUploader = <CloudinaryResponseType, PhotoUploadType>({
  onSuccess,
  onError,
  accept,
  marketplaceRequest,
  cloudinaryRoute,
  buttonText,
  multiple,
  // @ts-expect-error
  cloudinaryTransformer = pluckFileAttributesFromCloudinary,
  buttonIcon = undefined,
  buttonSize = 'medium',
  buttonClass = '',
  uploadSizeLimitInMb,
}: Props<CloudinaryResponseType, PhotoUploadType>) => {
  const { t } = useTranslation('admin');
  const { cloudinaryUploadPreset } = useEnv();
  const [totalFilesCount, setTotalFilesCount] = useState<number>(0);
  const [successfullyUploadedFiles, setSuccessfullyUploadedFiles] = useState<
    (PhotoUploadType | DefaultPhotoUploadType)[]
  >([]);
  const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const uploadToMarketplace = async () => {
    const marketplaceResponse = await marketplaceRequest(successfullyUploadedFiles);
    if (marketplaceResponse.ok) {
      onSuccess({ totalFilesCount });
    } else {
      onError({ totalFilesCount });
    }
  };

  const incrementUploadedFilesCount = () => {
    setUploadedFilesCount(prevCount => prevCount + 1);
  };

  useEffect(() => {
    if (uploadedFilesCount === totalFilesCount && isUploading) {
      if (successfullyUploadedFiles.length === totalFilesCount) {
        uploadToMarketplace();
      } else {
        onError({ totalFilesCount });
      }

      setUploadedFilesCount(0);
      setTotalFilesCount(0);
      setSuccessfullyUploadedFiles([]);
      setIsUploading(false);
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFilesCount]);

  useEffect(() => {
    if (isUploading) {
      incrementUploadedFilesCount();
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullyUploadedFiles]);

  const prepareForUpload = (length: number) => {
    setTotalFilesCount(length);
    setIsUploading(true);
  };

  const handleMediaUpload = (e: any) => {
    const currentTarget = e.currentTarget ? e.currentTarget : e.srcElement;
    let attachments: Array<{ size: number; name: string }> = Array.from(
      get(currentTarget, 'files'),
    );

    attachments = filterOutLargeFiles({
      files: attachments,
      maxFileSizeInMb: uploadSizeLimitInMb,
      t,
    });

    if (attachments.length === 0) {
      return;
    }

    prepareForUpload(attachments.length);

    attachments.forEach(async (attachment: any) => {
      const formData = new FormData();

      formData.append('file', attachment);
      formData.append('upload_preset', cloudinaryUploadPreset || '');

      const cloudinaryResponse = await api.fetch(cloudinaryRoute, {
        method: 'POST',
        body: formData,
      });

      const cloudinaryJson = await cloudinaryResponse.json();

      if (cloudinaryResponse.ok) {
        const uploadedFile = cloudinaryTransformer(cloudinaryJson);

        setSuccessfullyUploadedFiles(prevUploadedFiles => prevUploadedFiles.concat(uploadedFile));
      } else {
        incrementUploadedFilesCount();
      }
    });
  };

  const renderUploadingButtonText = () => {
    if (multiple) {
      return t('media.uploadProgress', {
        percentage: Math.trunc((uploadedFilesCount / totalFilesCount) * 100),
      });
    }

    return t('media.uploading');
  };

  const handleDrop = (e: any) => {
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const attachments = Array.from(e.dataTransfer.files);

      handleMediaUpload(attachments);
    }
  };

  return (
    <div onDrop={handleDrop}>
      <Button onClick={() => fileInput?.current?.click()} size={buttonSize} className={buttonClass}>
        <div>
          {buttonIcon ? <CustomIcon type={buttonIcon}></CustomIcon> : null}
          {isUploading ? renderUploadingButtonText() : buttonText}
        </div>
        <input
          ref={fileInput}
          type="file"
          multiple={multiple}
          style={{
            position: 'absolute',
            width: 1,
            height: 1,
            top: -500,
          }}
          onChange={handleMediaUpload}
          accept={accept || MIME_TYPES.imageOnly}
        />
      </Button>
    </div>
  );
};

export default MultimediaUploader;
