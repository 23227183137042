import PropTypes from 'prop-types';

export const propertyType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  vtsId: PropTypes.number,
  street: PropTypes.string,
  locality: PropTypes.string,
  region: PropTypes.string,
  postalCode: PropTypes.string,
  countryCode: PropTypes.string,
});

export const paramsType = PropTypes.shape({
  id: PropTypes.string,
});

export const matchType = PropTypes.shape({
  params: paramsType,
});

export const preloadedDataType = PropTypes.shape({
  properties: PropTypes.arrayOf(propertyType),
});

export const userAnalyticsType = PropTypes.shape({
  userEmail: PropTypes.string.isRequired,
  userId: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userTitle: PropTypes.string,
  userPhone: PropTypes.string,
  userMarket: PropTypes.string,
  userAccountType: PropTypes.string,
  initialSmsOptIn: PropTypes.bool,
});

export const userType = PropTypes.shape({
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  jobTitle: PropTypes.string,
  phoneNumber: PropTypes.string,
  formattedPhoneNumber: PropTypes.string,
  role: PropTypes.string,
  marketName: PropTypes.string.isRequired,
  marketSlug: PropTypes.string.isRequired,
  analyticsInformation: userAnalyticsType,
});

export const notificationsOnLoadType = PropTypes.shape({
  notice: PropTypes.string,
  alert: PropTypes.string,
});

export const configType = PropTypes.shape({
  locale: PropTypes.string.isRequired,
  translationsJSON: PropTypes.string.isRequired,
});

export const areaType = PropTypes.shape({
  magnitude: PropTypes.number.isRequired,
  unit: PropTypes.oneOf(['SquareFoot', 'SquareMeter']).isRequired,
});

export const historyType = PropTypes.object;

export const imageType = PropTypes.shape({
  path: PropTypes.string.isRequired,
  smallPath: PropTypes.string.isRequired,
  mediumPath: PropTypes.string.isRequired,
  largePath: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const roomAmenitiesType = PropTypes.shape({
  deskCount: PropTypes.number,
  electricAdjustableDeskCount: PropTypes.number,
  deskChairCount: PropTypes.number,
  tableCount: PropTypes.number,
  workbenchCount: PropTypes.number,
  loungeChairCount: PropTypes.number,
  stoolCount: PropTypes.number,
  couchesCount: PropTypes.number,
  conferenceTableCount: PropTypes.number,
  storageClosetArea: PropTypes.bool,
  kitchenTableCount: PropTypes.number,
  kitchenChairCount: PropTypes.number,
  refrigeratorCount: PropTypes.number,
  sinkCount: PropTypes.number,
  dishwasherCount: PropTypes.number,
  microwaveCount: PropTypes.number,
  ovenCount: PropTypes.number,
  stoveCount: PropTypes.number,
  lockers: PropTypes.bool,
  poolTableCount: PropTypes.number,
  airHockeyTableCount: PropTypes.number,
  pingPongTableCount: PropTypes.number,
  vendingMachineCount: PropTypes.number,
  bathroomType: PropTypes.oneOf(['stalls', 'unisex_with_sink', 'unisex_shared_sink']),
  bathroomSinkCount: PropTypes.number,
  bathroomStallCount: PropTypes.number,
});

export const roomType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(imageType),
  capacity: PropTypes.string,
  amenities: roomAmenitiesType,
});

export const buildingStatuses = ['inactive', 'onboarding', 'ready_for_approval', 'live', 'retired'];

export const CERTIFICATION_LEVELS = ['certified', 'silver', 'gold', 'platinum'];

export const adminMediaType = PropTypes.shape({
  filename: PropTypes.string.isRequired,
  processedAt: PropTypes.string,
  path: PropTypes.string,
  status: PropTypes.oneOf(['pending', 'successful', 'failed']).isRequired,
  roomType: PropTypes.string,
});

export const formType = PropTypes.shape({
  getFieldValue: PropTypes.func,
  getFieldsValue: PropTypes.func,
  getFieldDecorator: PropTypes.func,
});

export const locationType = PropTypes.shape({
  pathname: PropTypes.string,
});

export const launchDarklyAllFlagsStateType = PropTypes.shape({
  $flagsState: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      version: PropTypes.number,
      $valid: PropTypes.bool,
    }),
  }),
  $valid: PropTypes.bool,
  [PropTypes.string]: PropTypes.bool,
});

export const companyType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: imageType.isRequired,
});

export const errorType = PropTypes.shape({
  field: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
});

export const landlordBuilding = PropTypes.shape({
  primaryName: PropTypes.string.isRequired,
  secondaryName: PropTypes.string,
  area: PropTypes.oneOfType([areaType, PropTypes.string]).isRequired,
  location: PropTypes.string.isRequired,
  numberOfAvailabilities: PropTypes.number,
  availableArea: areaType,
  id: PropTypes.string,
  photoUrl: PropTypes.string.isRequired,
  photoDescription: PropTypes.string,
});
