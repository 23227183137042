/* eslint-disable react/prop-types */
import { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { isEqual } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import configureTranslations from '@root/configureTranslations';
import configureLaunchDarkly from '@root/configureLaunchDarkly';
import configureStore from '@store/configureStore';
import { configType, launchDarklyAllFlagsStateType } from '@propTypes';
import { EnvProvider } from '@shared/useEnv';
import { CloudinaryContextProvider, getCloudinary } from '@shared/useCloudinary';
import { BreakpointProvider } from '@root/shared/useBreakpoints';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';

// remove once antd upgrade to v4 is complete
import '@ant-design/compatible/assets/index.css';

class Admin extends Component {
  static propTypes = {
    truvaConfig: configType.isRequired,
    launchDarklyAllFlagsState: launchDarklyAllFlagsStateType,
    env: PropTypes.shape({
      railsEnv: PropTypes.oneOf(['test', 'production', 'development']),
      launchDarklyClientSideId: PropTypes.string,
    }),
  };

  static defaultProps = {
    env: {},
    launchDarklyAllFlagsState: {},
  };

  constructor(props) {
    super(props);
    const { truvaConfig, env } = props;
    const { locale, translationsJSON, enums } = truvaConfig;

    const translations = JSON.parse(translationsJSON);

    configureTranslations(locale, translations);
    this.store = configureStore({}, {}, undefined, enums, env.railsEnv);
    this.queryClient = new QueryClient();
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  render() {
    const { env, launchDarklyAllFlagsState } = this.props;

    const LaunchedDarklyApp = configureLaunchDarkly(
      App,
      launchDarklyAllFlagsState,
      env.launchDarklyClientSideId,
      env.railsEnv,
    );
    return (
      <QueryClientProvider client={this.queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <Provider store={this.store}>
          <EnvProvider value={env}>
            <CloudinaryContextProvider value={getCloudinary(env)}>
              <BreakpointProvider>
                <BrowserRouter>
                  <DndProvider backend={HTML5Backend}>
                    <LaunchedDarklyApp />
                  </DndProvider>
                </BrowserRouter>
              </BreakpointProvider>
            </CloudinaryContextProvider>
          </EnvProvider>
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default Admin;
