import React, { FunctionComponent } from 'react';
import DOMPurify from 'dompurify';

type SafeRenderProps = {
  children: React.ReactNode;
  unsafeContent: string;
  sanitizer?: (content: string) => string;
};

const SafeRender: FunctionComponent<SafeRenderProps> = ({
  children,
  unsafeContent,
  sanitizer = DOMPurify.sanitize,
}: SafeRenderProps) => {
  return (
    <>
      {React.Children.map(children, child => {
        const sanitizedContent = sanitizer(unsafeContent);

        return React.cloneElement(child as React.ReactElement<$TSFixMe>, {
          dangerouslySetInnerHTML: {
            __html: sanitizedContent,
          },
        });
      })}
    </>
  );
};

export default SafeRender;
