import { NumberInput, TextInput } from '@components/shared/forms';

type Props = {
  containerClass?: string;
  description?: string;
  descriptionClass?: string;
  disabled?: boolean;
  labelText: string;
  name: string;
  required?: boolean;
  type?: string;
};

const Input = ({
  containerClass,
  description,
  descriptionClass,
  disabled = false,
  labelText,
  name,
  required = false,
  type,
}: Props) => {
  switch (type) {
    case 'number':
      return (
        <NumberInput
          data-testid={name}
          containerClass={containerClass}
          description={description}
          descriptionClass={descriptionClass}
          disabled={disabled}
          fast
          labelText={labelText}
          name={name}
          required={required}
        />
      );
    case 'text':
    default:
      return (
        <TextInput
          data-testid={name}
          containerClass={containerClass}
          description={description}
          descriptionClass={descriptionClass}
          disabled={disabled}
          fast
          labelText={labelText}
          name={name}
          required={required}
        />
      );
  }
};

export default Input;
