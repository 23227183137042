import { PowerpointTourbook, PowerpointExternalListingEntry } from '@root/types';
import { BuildingHeaderAttributes, generateBuildingHeaderElements } from './buildingHeader';
import { generateFooterElements, PAGE_MARGIN } from './portrait';
import { addFloorPlanSlide } from './floorplan';
import { createListingMosaic } from './mosaic';
import { buildBuildingMapUrl } from './map';
import {
  truncate,
  addElementsToSlide,
  pipe,
  generateLine,
  generateElementsFromSections,
} from './util';
import { h3, smallText, PptxUtils, PowerpointElement, SlideSection } from './globals';
import { generateListingDetailsElements } from './listing';

const toBuildingAttributes = (
  listing: PowerpointExternalListingEntry,
): BuildingHeaderAttributes => {
  let displayName = listing.primaryName;
  if (listing.displayAddress) {
    displayName = `${listing.primaryName} - ${listing.displayAddress}`;
  }
  return {
    photos: listing.photos,
    displayName,
    cityLocalityRegion: listing.cityLocalityRegion,
    countryCode: listing.countryCode,
    neighborhood: listing.neighborhood,
  };
};

export const generateExternalListingElements = (
  utils: PptxUtils,
  tourbook: PowerpointTourbook,
  externalListing: PowerpointExternalListingEntry,
): PowerpointElement[] => {
  const elements: PowerpointElement[] = [
    {
      type: 'textBox',
      data: externalListing.name,
      placement: {
        x: PAGE_MARGIN,
        y: 1.04,
        w: 5.72,
        h: 0.33,
      },
      textOptions: {
        ...h3,
        fontSize: 14,
      },
    },
    ...generateBuildingHeaderElements(toBuildingAttributes(externalListing), utils),
    ...generateListingDetailsElements(
      {
        ...externalListing,
        capacity: null,
        minMaxArea: null,
        finishedCeilingHeight: null,
        slabToSlabCeilingHeight: null,
        shortestTerm: null,
        floorPlan: null,
        amenities: undefined,
      },
      utils,
      { showSecondRow: false },
    ),
    ...generateFooterElements(tourbook, utils),
  ];

  return elements;
};

export const generateExternalListingSections = (
  utils: PptxUtils,
  externalListing: PowerpointExternalListingEntry,
) => {
  const { googleMapsKey, pptx, t } = utils;
  const sections: SlideSection[] = [];
  const descriptionText: PowerpointElement[] = externalListing.description?.length
    ? [
        {
          type: 'textBox',
          data: t('powerpoint.listing.description'),
          placement: {
            w: 6.85,
            h: 0.3,
            y: 0.21,
          },
          textOptions: {
            ...h3,
            valign: 'top',
            bold: true,
          },
        },
        {
          type: 'textBox',
          data: truncate(externalListing.description, 500),
          placement: {
            w: 6.93,
            h: 0.9,
            y: 0.59,
          },
          textOptions: {
            ...smallText,
            valign: 'top',
          },
        },
      ]
    : [];

  sections.push([generateLine(utils.pptx, 0, 6.8, 0), ...descriptionText]);

  const mapHeight = 1.86;
  const mapWidth = 6.79;
  const textOptions = {
    ...smallText,
  };
  sections.push([
    {
      type: 'image',
      placement: {
        w: mapWidth,
        h: mapHeight,
      },
      data: buildBuildingMapUrl(externalListing, googleMapsKey, mapHeight, mapWidth),
    },
    {
      type: 'shape',
      placement: {
        w: mapWidth,
        h: mapHeight,
      },
      shapeOptions: {
        line: { dashType: 'solid', color: 'BFBFBF' },
      },
      shapeType: pptx.ShapeType.rect,
    },
    {
      type: 'textBox',
      data: [
        { data: externalListing.primaryName || '', textOptions },
        pipe(),
        { data: externalListing.cityLocalityRegion || '', textOptions },
      ],
      placement: {
        w: 6.0,
        h: 0.22,
        y: mapHeight,
        margin: 0,
      },
    },
  ]);

  return sections;
};

export const createExternalListing = (
  utils: PptxUtils,
  tourbook: PowerpointTourbook,
  externalListing: PowerpointExternalListingEntry,
) => {
  const { pptx } = utils;
  const slide = pptx.addSlide();

  createListingMosaic(utils, slide, externalListing.photos, 0.39, false);

  addElementsToSlide(slide, generateExternalListingElements(utils, tourbook, externalListing));

  const externalListingsSections = generateExternalListingSections(utils, externalListing);
  const externalListingsElements = generateElementsFromSections(externalListingsSections, {
    x: PAGE_MARGIN,
    y: 4.98,
  });
  addElementsToSlide(slide, externalListingsElements);

  const floorPlan = externalListing.photos.find(
    photo => photo.description.toLowerCase() === 'floor plan',
  );
  if (floorPlan) {
    addFloorPlanSlide({
      floorPlan,
      heading: externalListing.name,
      building: toBuildingAttributes(externalListing),
      listingId: externalListing.id,
      userId: tourbook.owner.id,
      tourbook,
      utils,
      isInternalListing: false,
    });
  }
};

export default createExternalListing;
