import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export const firstAndLastFrom = (page, pageSize, totalCount) => {
  const first = (page - 1) * pageSize + 1;
  const last = page * pageSize > totalCount ? totalCount : page * pageSize;
  return {
    first,
    last,
    total: totalCount,
  };
};

const Results = ({ page, pageSize, totalCount }) => {
  const { t } = useTranslation('admin');

  return t('common.paginationResults', firstAndLastFrom(page, pageSize, totalCount));
};

Results.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default Results;
