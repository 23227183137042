/* eslint-disable react/sort-comp */
import { Component } from 'react';
import { Form } from '@ant-design/compatible';
import { Select, Input, Row, Table } from 'antd';
import Container from '@components/shared/Admin/Container';
import { flow } from 'lodash/fp';
import { TFunction, useTranslation, withTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import routes from '@root/routes';
import { debounce } from 'lodash';
import api from '@shared/api';
import addKeyTo from '@shared/addKeyTo';
import { useDispatch } from 'react-redux';
import actions from '@store/actions';
import PaginationRow from '@components/shared/PaginationRow';
import CustomIcon from '@components/shared/CustomIcon';
import { parse } from 'qs';
import utils from '@styles/utils.module.less';
import queryStringSerializer from '@shared/queryStringSerializer';
import { AdminUser, Breadcrumbs, AdminPaginationResponse } from '@root/types';
import { Breadcrumbs as Breadcrumb } from '@components/shared';
import s from './UserSearch.module.less';
import Layout from '../Layout/Layout';

const pageFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return queryParams.page ? Number(queryParams.page) : 1;
};
const queryFrom = (location): string => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.query || '') as string;
};
const roleSelectionsFrom = location => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return (queryParams.roles || []) as string[];
};

type Props = {
  form: Form;
  history: $TSFixMe;
  t: TFunction;
  setBreadcrumbs: (crumbs: Breadcrumbs) => void;
  removeBreadcrumbs: () => void;
  location: $TSFixMe;
};

type State = {
  users: AdminUser[];
  pagination: AdminPaginationResponse;
  loading: boolean;
  queryValue: string;
  roles: [];
  roleSelections: string[];
};

class RawUserSearch extends Component<Props, State> {
  columns = [
    { title: this.props.t('user.columnHeaders.firstName'), dataIndex: 'firstName' },
    { title: this.props.t('user.columnHeaders.lastName'), dataIndex: 'lastName' },
    { title: this.props.t('user.columnHeaders.email'), dataIndex: 'email' },
    {
      title: this.props.t('user.columnHeaders.role'),
      dataIndex: 'role',
      render: role => this.props.t(`common:roles.${role}`),
    },
    {
      title: this.props.t('user.columnHeaders.actions'),
      dataIndex: 'id',
      render: id => (
        <Link to={routes.admin.userPage(id)}>{this.props.t('user.editUserButton')}</Link>
      ),
    },
  ];

  state: State = {
    users: [],
    pagination: { page: 1, totalCount: 0, pageSize: 25 },
    loading: true,
    queryValue: queryFrom(this.props.location),
    roles: [],
    roleSelections: roleSelectionsFrom(this.props.location),
  };

  async componentDidMount() {
    this.fetchFilters();
    await this.fetchUsers();
    const { setBreadcrumbs, t } = this.props;
    setBreadcrumbs([t('breadcrumbs.admin'), t('breadcrumbs.users')]);
  }

  async componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    const pageChanged = pageFrom(location) !== pageFrom(prevProps.location);
    const queryChanged = queryFrom(location) !== queryFrom(prevProps.location);
    if (pageChanged || queryChanged) {
      await this.fetchUsers();
    }
  }

  componentWillUnmount() {
    const { removeBreadcrumbs } = this.props;
    removeBreadcrumbs();
  }

  onSearch = debounce(() => {
    this.pushToHistory();
  }, 250);

  onSearchClick = () => {
    this.pushToHistory();
  };

  fetchFilters = async () => {
    const response = await api.fetch(routes.api.admin.userFilters);
    const { roles } = await response.json();
    this.setState({ roles });
  };

  fetchUsers = async () => {
    this.setState({ loading: true });
    const { location } = this.props;
    const res = await api.fetch(
      routes.api.admin.userSearch(
        queryFrom(location),
        pageFrom(location),
        this.state.roleSelections,
      ),
    );
    const { users = [], pagination = {} } = await res.json();
    this.setState({ users, pagination, loading: false });
  };

  showPagination = () => {
    const { pagination } = this.state;
    return pagination && pagination.totalCount > 0;
  };

  handleSetRoleFilterSelections = roleSelections => {
    this.setState({ roleSelections }, () => {
      this.pushToHistory();
      this.fetchUsers();
    });
  };

  pushToHistory = (page = 1) => {
    const { history } = this.props;
    const { queryValue, roleSelections } = this.state;
    const params = {
      query: queryValue,
      page,
      roles: roleSelections,
    };
    const queryString = queryStringSerializer(params);
    history.push(`${routes.admin.userSearch}?${queryString}`);
  };

  changePage = newPage => {
    const { history, location } = this.props;
    const savedSearchValue = queryFrom(location);
    history.push(`${routes.admin.userSearch}?query=${savedSearchValue}&page=${newPage}`);
  };

  renderFilterOptions = options =>
    options.map(option => (
      <Select.Option key={option} value={option}>
        {option}
      </Select.Option>
    ));

  render() {
    const { t } = this.props;
    const { users, loading, pagination, queryValue, roles, roleSelections } = this.state;
    return (
      <Container title={t('user.title')} extraPadding>
        <Form onSubmit={this.onSearchClick}>
          <Form.Item>
            <Row>
              <div className={s.filtersRow}>
                <div className={s.userSearch}>
                  <Input
                    placeholder={t('user.searchPlaceholder')}
                    style={{ width: 225, marginRight: 20 }}
                    aria-label="user-search"
                    name="user-search"
                    value={queryValue}
                    prefix={<CustomIcon type="magnifying-glass" className={s.searchIcon} />}
                    onChange={e =>
                      this.setState({ queryValue: e.target.value }, () => {
                        this.onSearch();
                      })
                    }
                  />
                </div>
                <div>
                  <div className={utils.label}>
                    {roleSelections.length > 0
                      ? t('user.filters.roleWithCount', {
                          count: roleSelections.length,
                        })
                      : t('user.filters.role')}
                  </div>
                  <Select
                    style={{ width: 150, marginRight: 20 }}
                    placeholder="Role"
                    className={s.filterDropdown}
                    mode="multiple"
                    value={roleSelections}
                    onChange={this.handleSetRoleFilterSelections}
                  >
                    {this.renderFilterOptions(roles)}
                  </Select>
                </div>
              </div>
            </Row>
          </Form.Item>
        </Form>
        <Table
          loading={loading}
          columns={this.columns}
          dataSource={addKeyTo(users)}
          pagination={false}
        />
        <PaginationRow
          {...pagination}
          showPagination={this.showPagination()}
          testId="pagination"
          changePage={this.changePage}
        />
      </Container>
    );
  }
}
const EnhancedUserSearch = flow(
  withTranslation('admin'),
  Form.create({ name: 'user-search' }),
)(RawUserSearch);
export { EnhancedUserSearch };
const UserSearchWrapper = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const removeBreadcrumbs = () => dispatch(actions.removeBreadcrumbs());
  const setBreadcrumbs = crumbs => dispatch(actions.addBreadcrumbs(crumbs));
  const { t } = useTranslation('admin');

  return (
    <Layout>
      <Breadcrumb
        className="mb-1"
        items={[{ content: t('breadcrumbs.admin') }, { content: t('breadcrumbs.users') }]}
      />
      <EnhancedUserSearch
        history={history}
        location={location}
        setBreadcrumbs={setBreadcrumbs}
        removeBreadcrumbs={removeBreadcrumbs}
      />
    </Layout>
  );
};

export default UserSearchWrapper;
