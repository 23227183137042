import useAnalytics from '@shared/useAnalytics';
import {
  SearchCriteriaWithMultipleOptions,
  SearchCriteriaWithMultipleOptionsKeys,
} from './Criteria/ListingSearchCriteria';

export default function useFilterInteraction() {
  const { filterInteraction, PARAMETERS } = useAnalytics();

  function checkboxFilterInteraction({
    nowChecked,
    filterType,
    filterValue,
    currentFilters,
  }: {
    nowChecked: boolean;
    filterType: SearchCriteriaWithMultipleOptionsKeys | 'firstToMarket';
    filterValue: SearchCriteriaWithMultipleOptions[SearchCriteriaWithMultipleOptionsKeys] | boolean;
    currentFilters: Object;
  }) {
    const { actionType, filter } = map[filterType];

    filterInteraction({
      action: nowChecked ? PARAMETERS.applyFilter : PARAMETERS.removeFilter,
      actionType,
      filter,
      ...(nowChecked ? { value: filterValue } : {}),
      currentFilters,
      sourceContent: PARAMETERS.sidebar,
    });
  }

  function dropdownFilterInteraction({
    filterType,
    value,
    isAdding,
    currentFilters,
  }: {
    filterType: 'landlords' | 'buildings' | 'submarkets' | 'amenities' | 'keywords';
    value: string;
    isAdding: boolean;
    currentFilters: Object;
  }) {
    const { actionType, filter } = map[filterType];

    filterInteraction({
      action: isAdding ? PARAMETERS.applyFilter : PARAMETERS.removeFilter,
      actionType,
      filter,
      ...(isAdding ? { value } : {}),
      currentFilters,
      sourceContent: PARAMETERS.sidebar,
    });
  }

  function ceilingHeightFilterInteraction({
    value,
    currentFilters,
    units,
    filterType,
  }: {
    value: number | null;
    currentFilters: Object;
    units: 'imperial' | 'metric';
    filterType: 'slabToSlab' | 'finished';
  }) {
    filterInteraction({
      action: value ? PARAMETERS.applyFilter : PARAMETERS.removeFilter,
      actionType: 'CEILING_HEIGHT_FILTER_CHANGED',
      filter: 'ceiling height',
      ...(value ? { value } : {}),
      currentFilters,
      sourceContent: PARAMETERS.sidebar,
      ...(value ? { filterType, units } : {}),
    });
  }

  function priceFilterInteraction({
    value,
    filterType,
    currentFilters,
  }: {
    value: number | boolean | null;
    filterType: 'maxPrice' | 'minPrice' | 'excludeNegotiable';
    currentFilters: Object;
  }) {
    filterInteraction({
      action: value ? PARAMETERS.applyFilter : PARAMETERS.removeFilter,
      actionType: 'PRICE_FILTER_CHANGED',
      filter: 'price',
      ...(value ? { value } : {}),
      filterType,
      currentFilters,
      sourceContent: PARAMETERS.sidebar,
    });
  }

  function sizeFilterInteraction({
    value,
    filterType,
    currentFilters,
  }: {
    value: number | null;
    filterType: 'maxSize' | 'minSize';
    currentFilters: Object;
  }) {
    filterInteraction({
      action: value ? PARAMETERS.applyFilter : PARAMETERS.removeFilter,
      actionType: 'SIZE_FILTER_CHANGED',
      filter: 'size',
      ...(value ? { value } : {}),
      filterType,
      currentFilters,
      sourceContent: PARAMETERS.sidebar,
    });
  }

  function possessionFilterInteraction({
    isRemoving,
    value,
    currentFilters,
  }: {
    isRemoving: boolean;
    value: string | null;
    currentFilters: Object;
  }) {
    filterInteraction({
      action: isRemoving ? PARAMETERS.removeFilter : PARAMETERS.applyFilter,
      actionType: 'POSSESSION_FILTER_CHANGED',
      filter: 'possession',
      ...(isRemoving ? {} : { value }),
      currentFilters,
      sourceContent: PARAMETERS.sidebar,
    });
  }

  function pillRemovalInteraction({
    filter,
    currentFilters,
    filterType,
  }: {
    filter: string;
    filterType?: string;
    currentFilters: Object;
  }) {
    filterInteraction({
      action: PARAMETERS.removeFilter,
      actionType: 'SEARCH_CRITERIA_REMOVED_FROM_PILL',
      filter: mapForRemoval(filter),
      filterType,
      currentFilters,
      sourceContent: PARAMETERS.searchCriteriaBar,
    });
  }

  return {
    checkboxFilterInteraction,
    dropdownFilterInteraction,
    ceilingHeightFilterInteraction,
    priceFilterInteraction,
    sizeFilterInteraction,
    possessionFilterInteraction,
    pillRemovalInteraction,
  };
}

const map: Record<string, { actionType: string; filter: string }> = {
  landlords: {
    actionType: 'LANDLORDS_FILTER_CHANGED',
    filter: 'landlord',
  },
  submarkets: {
    actionType: 'SUBMARKETS_FILTER_CHANGED',
    filter: 'submarkets',
  },
  buildings: {
    actionType: 'BUILDINGS_FILTER_CHANGED',
    filter: 'buildings',
  },
  amenities: {
    actionType: 'AMENITIES_FILTER_CHANGED',
    filter: 'amenities',
  },
  leaseTypes: {
    actionType: 'LEASE_TYPE_FILTER_CHANGED',
    filter: 'lease type',
  },
  terms: {
    actionType: 'TERMS_FILTER_CHANGED',
    filter: 'terms',
  },
  spaceCondition: {
    actionType: 'SPACE_CONDITION_FILTER_CHANGED',
    filter: 'space condition',
  },
  keywords: {
    actionType: 'KEYWORDS_FILTER_CHANGED',
    filter: 'keywords',
  },
  firstToMarket: {
    actionType: 'FIRST_TO_MARKET_FILTER_CHANGED',
    filter: 'first to market',
  },
};

const mapForRemoval = (filter: string): string => {
  const changes = {
    leaseTypes: 'lease type',
    exclusive: 'first to market',
    buildingIds: 'buildings',
    spaceCondition: 'space condition',
  };

  return Object.keys(changes).includes(filter) ? changes[filter] : filter;
};
