import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { parse } from 'qs';
import { ButtonLink } from '@components/shared';
import s from './UpsellBanner.module.less';

const useQueryParams = (): { queryString: string; [k: string]: string } => {
  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  return { ...queryParams, queryString: location.search };
};

export const DesktopUpsellBanner = () => {
  const { upsell, queryString } = useQueryParams();
  const { t } = useTranslation('listing');
  if (upsell !== 'true') return null;
  const url = `https://vts.com/vts-market${queryString}`;

  return (
    <aside className={s.banner}>
      {t('bannerCta')}
      <ButtonLink href={url} type="secondary" size="small" openInNewTab className={s.button}>
        {t('learnMore')}
      </ButtonLink>
    </aside>
  );
};

export const MobileUpsellBanner = () => {
  const { upsell, queryString } = useQueryParams();
  if (upsell !== 'true') return null;

  const url = `https://vts.com/vts-market${queryString}`;

  return (
    <aside className={s.mobileBanner}>
      {/* eslint-disable react/jsx-no-literals */}
      <Trans i18nKey="listing:mobileUpsellCta">
        This could be your space. Learn more about
        <a href={url} rel="noreferrer" target="_blank">
          VTS Market.
        </a>
      </Trans>
      {/* eslint-disable react/jsx-no-literals */}
    </aside>
  );
};
