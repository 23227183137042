import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import useEnv from '@shared/useEnv';
import PhotosUploader from '@components/shared/Admin/PhotosUploader';
import DraggableMediaUploader from '@components/shared/Admin/DraggableMediaUploader';
import { MIME_TYPES, DefaultPhotoUploadType } from '@components/shared/Admin/MultimediaUploader';
import { AdminListingPhoto, AdminLandlordPhoto, FirstPhotoOrientation } from '@root/types';
import Button from '@components/shared/V2Button';
import { Tooltip } from 'antd';
import AspectRatioSelector from '@components/shared/AspectRatioSelector';
import MediaDownloader from '@components/shared/Admin/MediaDownloader';
import s from './MediaPhotosSection.module.less';
import PhotosTable from './PhotosTable';

// TODO: TRV-2844 & TRV-2845 Update these types to include Landlord and Building photos
type PhotoTypes = AdminListingPhoto | AdminLandlordPhoto;

type Props = {
  fetchRecord: () => void;
  photos: PhotoTypes[];
  onPhotoUpload: (uploadedFiles: DefaultPhotoUploadType[]) => PromiseLike<{ ok: boolean }>;
  onUpdatePhotoPosition: (photoIds: string[]) => void;
  onDeletePhoto: (photoId: string) => void;
  title: string;
  description: string;
  onFirstPhotoOrientationChange?: (newOrientation: string) => void;
  firstPhotoOrientation?: FirstPhotoOrientation;
  additionalColumn?: {
    title: React.ReactNode;
    render: (photo: $TSFixMe) => React.ReactNode;
  };
  handleHidePhotos?: (photoIds?: Array<string>) => Promise<void>;
  showHidePhotosButton?: boolean;
  setSelectedRows?: $TSFixMe;
  selectedRows?: Array<string>;
  downloadUrl: string | null;
  selectedDownloadRows: boolean;
  downloadButtonOnClick: () => void;
  uploadSizeLimitInMb?: number;
};

const noop = () => {
  return Promise.reject();
};

const MediaPhotosSection = ({
  title,
  description,
  photos,
  fetchRecord,
  onPhotoUpload,
  onUpdatePhotoPosition,
  onDeletePhoto,
  handleHidePhotos = noop,
  additionalColumn,
  onFirstPhotoOrientationChange,
  firstPhotoOrientation,
  showHidePhotosButton = true,
  selectedRows = [],
  setSelectedRows = () => {},
  downloadUrl,
  selectedDownloadRows,
  downloadButtonOnClick,
  uploadSizeLimitInMb = undefined,
}: Props) => {
  const { t } = useTranslation('admin');
  const { cloudinaryCloud } = useEnv();
  const photoIdKey = photos.map((photo: PhotoTypes) => photo.id).join(',');

  return (
    <div data-testid="media-photos-section">
      <div className={cn(s.mediaTableHeader)}>
        <div>
          <div className="inline font-title">{title}</div>
        </div>
        <div className={s.rightButtons}>
          <PhotosUploader
            marketplaceRequest={onPhotoUpload}
            multiple
            onSuccess={fetchRecord}
            buttonIcon="upload"
            buttonSize="small"
            buttonClass={s.photoUploadButton}
            uploadSizeLimitInMb={uploadSizeLimitInMb}
          />
        </div>
      </div>
      <div className={s.mediaUploader}>
        <DraggableMediaUploader
          accept={MIME_TYPES.imageOnly}
          marketplaceRequest={onPhotoUpload}
          cloudinaryRoute={`https://api.cloudinary.com/v1_1/${cloudinaryCloud}/image/upload`}
          fetchRecord={fetchRecord}
          uploadSizeLimitInMb={uploadSizeLimitInMb}
          multiple
        />
      </div>
      <div className={s.downloadButton}>
        <MediaDownloader
          isDisabled={selectedDownloadRows}
          downloadUrl={downloadUrl}
          onClick={downloadButtonOnClick}
        />
      </div>
      <div className={s.photosTableSubheading}>
        <div>
          <div className="inline font-subtitle">{t('media.publicPhotosTitle')}</div>
          <div>{description}</div>
        </div>
        <div className={s.rightButtons}>
          {showHidePhotosButton ? (
            <Tooltip
              trigger={['hover', 'click']}
              placement="topLeft"
              title={t('media.hidePhotosTooltip')}
            >
              <span>
                <Button
                  onClick={() => handleHidePhotos(selectedRows).then(() => setSelectedRows([]))}
                  type="primary"
                  disabled={!selectedRows.length}
                  className={s.hidePhotosButton}
                >
                  {t('media.hidePhotos')}
                </Button>
              </span>
            </Tooltip>
          ) : null}
          {firstPhotoOrientation && onFirstPhotoOrientationChange ? (
            <AspectRatioSelector
              firstPhotoOrientation={firstPhotoOrientation}
              onChange={onFirstPhotoOrientationChange}
            />
          ) : null}
        </div>
      </div>
      <div data-testid="v2-photos-table">
        {/* key is necessary to rerender when photo is deleted */}
        <PhotosTable
          key={photoIdKey}
          photos={photos}
          onUpdatePhotoPosition={onUpdatePhotoPosition}
          onDeletePhoto={onDeletePhoto}
          additionalColumn={additionalColumn}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        />
      </div>
    </div>
  );
};

export default MediaPhotosSection;
