import * as React from 'react';
import { Table } from 'antd';
import addKeyTo from '@shared/addKeyTo';

export type Props = {
  loading?: boolean;
  columns: Array<{
    title: string | React.ReactNode;
    dataIndex?: string;
    render?: (arg: any) => React.ReactNode;
    width?: string;
  }>;
  dataSource: any[];
  className?: string;
  rowClassName?: string;
  setSelectedRows?: (value: any | never[]) => void;
  selectedRows?: string[];
  enableCheckboxes?: boolean;
};

const AdminTable = ({
  className,
  rowClassName,
  loading,
  columns,
  dataSource,
  setSelectedRows,
  selectedRows,
  enableCheckboxes,
}: Props) => {
  const onSelectChange = selectedRowKeys => {
    if (!setSelectedRows) return;

    setSelectedRows([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: onSelectChange,
  };

  return (
    <Table
      className={className}
      rowClassName={rowClassName}
      rowSelection={enableCheckboxes ? rowSelection : undefined}
      loading={loading}
      columns={columns}
      dataSource={enableCheckboxes ? addKeyTo(dataSource) : dataSource}
      pagination={false}
    />
  );
};

export default AdminTable;
