import { ReactNode } from 'react';
import { Tabs as AntdTabs } from 'antd';
import cn from 'classnames';
import { TabOption } from './Tabs';
import TabNav from './TabNav';
import s from './Tabs.module.less';

const noOp = () => {};
type AlignedType = 'center' | 'left' | 'right';
type Props = {
  currentTab: string;
  tabs: Array<TabOption & { location?: string }>;
  children?: ReactNode;
  changeTab?: (tab: string) => void;
  aligned?: AlignedType;
  className?: string;
  tabClassName?: string;
  selectedClassName?: string;
};

const ControlledTabs = ({
  currentTab,
  tabs,
  changeTab = noOp,
  children,
  aligned = 'center',
  className,
  tabClassName,
  selectedClassName,
}: Props) => (
  <AntdTabs
    className={cn(s.wrapper, s[aligned], className)}
    onChange={noOp}
    activeKey={currentTab}
    animated={false}
    renderTabBar={() => (
      <TabNav
        ulClassName={className}
        liClassName={tabClassName}
        liSelectedClassName={selectedClassName}
        tabs={tabs}
        currentTab={currentTab}
        changeTab={changeTab}
      />
    )}
  >
    {children}
  </AntdTabs>
);

export default ControlledTabs;
