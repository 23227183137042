import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@components/shared/forms/Dropdowns';
import Input from './Input';
import css from './styles.module.less';

type Props = {
  fieldName: string;
  value: string;
  pageName: 'building' | 'listing';
  options: { name: string; value?: string }[];
  sortOptionsByIndex?: boolean;
};

const StringListWithSource = memo(
  ({ fieldName, value, pageName, options, sortOptionsByIndex = false }: Props) => {
    const { t } = useTranslation('admin');

    const metadataPath = `amenitySet.${fieldName}`;

    const sortedOptions = () => {
      const optionsToSore = options.map((option, index) => {
        return {
          label: t(`${pageName}.${fieldName}.${option.name}`),
          value: option.value || option.name,
          index,
        };
      });
      return sortOptionsByIndex
        ? optionsToSore.sort((optionA, optionB) => optionA.index - optionB.index)
        : optionsToSore.sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
    };
    return (
      <fieldset className={css.sideBySide}>
        <Select
          data-testid={metadataPath}
          key={`${pageName}.${fieldName}`}
          className={css.select}
          containerClass={value ? css.left : css.fullWidth}
          isClearable
          labelText={t(`${pageName}.${fieldName}.label`)}
          name={metadataPath}
          options={sortedOptions()}
          placeholder=""
          value={value}
        />
        {value ? (
          <Input
            key={`${pageName}.${fieldName}DataSource`}
            containerClass={css.right}
            labelText={t(`${pageName}.dataSource`)}
            name={`${metadataPath}DataSource`}
          />
        ) : null}
      </fieldset>
    );
  },
);

export default StringListWithSource;
