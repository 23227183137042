import Logo from '@components/shared/Logo';
import s from '@components/shared/Logo/Logo.module.less';
import routes from '@root/routes';
import { MarketSlug } from '@root/types';

type Props = {
  marketSlug: MarketSlug;
};

export default function LogoWrapper({ marketSlug }: Props) {
  return (
    <div className={s.logoNavigationLink}>
      <a data-testid="homeLink" className={s.logo} href={routes.search(marketSlug)}>
        <Logo />
      </a>
    </div>
  );
}
