import { useContext } from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@components/shared';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';
import cn from 'classnames';
import { Drawer, FormValues, MediaFields, ImagesContext, MAX_IMAGE_COUNT } from '..';
import styles from '../TourbookExternalListing.module.less';

type Props = {
  goToLocation: () => void;
  goToListing: () => void;
};

export const Media = ({ goToListing, goToLocation }: Props) => {
  const { images } = useContext(ImagesContext);
  const { t } = useTranslation('tourbook');
  const { setFieldTouched, values, setFieldValue, validateField } = useFormikContext<FormValues>();

  return (
    <Drawer
      footer={
        <>
          <Button
            type="secondary"
            disabled={some(images, i => !i.isUploaded)}
            onClick={() => {
              setFieldValue(
                'photos',
                images.map(i => i.image),
              );
              setFieldTouched('photos', true, true);
              validateField('photos');
              goToLocation();
            }}
          >
            {t('externalListing.previous')}
          </Button>
          <Button
            type="primary"
            disabled={some(images, i => !i.isUploaded)}
            onClick={() => {
              setFieldTouched('photos', true, true);
              if (values.photos.length > 0 && values.photos.length <= MAX_IMAGE_COUNT) {
                goToListing();
              }
            }}
          >
            {t('externalListing.next')}
          </Button>
        </>
      }
    >
      <div className={styles.formFields}>
        <div className={styles.progress}>
          <div className={cn(styles.bar, styles.activeBar)}></div>
          <div className={cn(styles.bar, styles.activeBar)}></div>
          <div className={cn(styles.bar)}></div>
        </div>

        <MediaFields />
      </div>
    </Drawer>
  );
};

export default Media;
