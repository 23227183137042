import * as React from 'react';
import { isUndefined } from 'lodash';
import s from './RadioInput.module.less';

export type Props = {
  name: string;
  value: string;
  checked?: boolean;
  onChange: (value: string) => void;
  labelText: React.ReactNode;
  id?: string;
  /*
   * By default, radio buttons dont clear when you click them again.
   * This allows for this interaction to happen, but it defaults to false since
   * thats different behavior from how Radio buttons usually work.
   */
  clearable?: boolean;
};

const RadioInput = ({
  name,
  value,
  checked,
  onChange,
  labelText,
  id,
  clearable = false,
}: Props) => {
  const uniqueId: string = id || `${name}-${value}`;
  return (
    <>
      <input
        className={s.input}
        type="radio"
        data-testid={uniqueId}
        id={uniqueId}
        name={name}
        value={value}
        checked={isUndefined(checked) ? undefined : checked}
        onClick={() => {
          if (clearable && checked) {
            onChange(value);
          }
        }}
        onChange={(event: React.FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
      />
      <label className={s.label} htmlFor={uniqueId}>
        {labelText}
      </label>
    </>
  );
};

export default RadioInput;
