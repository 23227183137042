import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PARAMETERS } from '@root/tracking/constants';
import useSize from '@react-hook/size';
import { Notification, IconButton, OnlyInTabletMobile, OnlyInDesktop } from '@components/shared';
import routes from '@root/routes';
import { Landlord } from '@root/types';
import cn from 'classnames';
import { Tooltip } from '@viewthespace/components';
import useAnalytics from '@shared/useAnalytics';
import { get, create, destroy } from '@shared/typedApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useCurrentUser from '@root/shared/useCurrentUser';
import BasicInfo from './BasicInfo';
import s from './Landlord.module.less';

const DESKTOP_LANDLORD_NAME_OVERFLOW_THRESHOLD_IN_PX = 40;
const DESKTOP_HEADER_SECTION_OVERFLOW_THRESHOLD_IN_PX = 84;
const BANNER_EXTRA_OVERFLOW = 16;
const LETTERMARK_EXTRA_OVERFLOW = 30;

type Props = {
  landlord: Landlord;
  hasLoadedOnce: boolean;
  savedBuilingOwnerLinkClicked: () => {};
};

export const LandlordHeaderSection = ({
  landlord,
  hasLoadedOnce,
  savedBuilingOwnerLinkClicked,
}: Props) => {
  const { currentUser } = useCurrentUser();
  const hasCurrentUser = !!currentUser;
  const { t } = useTranslation('landlord');
  const { slug } = landlord;
  const client = useQueryClient();
  const { saveInteraction } = useAnalytics();
  const [landlordNameOverflowing, setLandlordNameOverflowing] = useState(false);
  const [basicInfoOverflowing, setBasicInfoOverflowing] = useState(false);

  const landlordTitleSectionRef = useRef<HTMLDivElement | null>(null);
  const landlordNameSectionHeight = useSize(landlordTitleSectionRef)[1];

  useLayoutEffect(() => {
    setLandlordNameOverflowing(
      !!landlordNameSectionHeight &&
        landlordNameSectionHeight > DESKTOP_LANDLORD_NAME_OVERFLOW_THRESHOLD_IN_PX,
    );
  }, [landlordNameSectionHeight]);

  const headerSectionRef = useRef<HTMLDivElement | null>(null);
  const headerSectionHeight = useSize(headerSectionRef)[1];

  useLayoutEffect(() => {
    const lettermarkExtraOverflow = landlord.lettermarkLogo ? LETTERMARK_EXTRA_OVERFLOW : 0;
    const bannerExtraOverflow = landlord.lettermarkLogo ? BANNER_EXTRA_OVERFLOW : 0;
    setBasicInfoOverflowing(
      !!headerSectionHeight &&
        headerSectionHeight >
          DESKTOP_HEADER_SECTION_OVERFLOW_THRESHOLD_IN_PX +
            lettermarkExtraOverflow +
            bannerExtraOverflow,
    );
  }, [headerSectionHeight, landlord.lettermarkLogo]);

  const numberOfFields = [
    landlord.inventory,
    landlord.space,
    landlord.underConstruction,
    landlord.premierRetailDestinations,
  ].filter(i => i).length;

  const { data: isLandlordSaved } = useQuery<boolean>(
    [routes.api.buildingOwnerSave(slug)],
    () => get(routes.api.buildingOwnerSave(slug)),
    { enabled: hasCurrentUser },
  );

  const toggleSaveMutation = useMutation(
    async (shouldBeSaved: boolean) => {
      if (shouldBeSaved) {
        await create(routes.api.buildingOwnerSave(slug), {});
      } else {
        await destroy(routes.api.buildingOwnerSave(slug), {});
      }
    },
    {
      onSuccess: (_, wasSaved) => {
        saveInteraction({
          sourcePage: PARAMETERS.landlordPage,
          action: wasSaved ? PARAMETERS.saveItem : PARAMETERS.unsaveItem,
        });
        client.invalidateQueries([routes.api.buildingOwnerSave(slug)]);
        if (wasSaved) {
          Notification.saveSuccess({
            title: t('landlord:saved'),
            /* eslint-disable react/jsx-no-literals */
            text: (
              <Trans i18nKey="savedSuccessNotification" ns="landlord">
                You’ll get notified if a listing is available from this building owner. View or edit
                it in your
                <a href={routes.savedBuildingOwners} onClick={savedBuilingOwnerLinkClicked}>
                  saved building owners
                </a>
                section.
              </Trans>
            ),
            /* eslint-enable react/jsx-no-literals */
          });
        }
      },
    },
  );

  return landlord ? (
    <>
      <div
        className={cn(
          'flex flex-col mobile:mx-[-16px] mobile:mt-0',
          !basicInfoOverflowing && 'desktop:mt-1',
        )}
        data-testid={`layout-${hasLoadedOnce ? 'fullyLoaded' : 'notFullyLoaded'}`}
      >
        {landlord.bannerImage ? (
          <img
            className="h-[190px] rounded-[3px] object-cover tablet:h-[102px] mobile:h-[56px] mobile:rounded-[0px]"
            src={landlord?.bannerImage.rawPath}
            alt={landlord?.bannerImage.description}
          />
        ) : null}
        <div
          className={cn(
            'flex flex-wrap items-center justify-between desktop:mb-3',
            landlord.bannerImage && landlord.lettermarkLogo && 'mt-[-16px] desktop:mt-[-30px]',
          )}
          ref={headerSectionRef}
        >
          <div
            className={cn(
              'flex grow items-center mobile:w-full',
              basicInfoOverflowing &&
                'desktop:border-b-[1.5px] desktop:border-solid desktop:border-black-010 desktop:pb-2',
            )}
          >
            {landlord.lettermarkLogo ? (
              <img
                className={cn(
                  'mr-2 h-[80px] w-[80px] rounded-[3px] bg-white object-cover desktop:h-[110px] desktop:w-[110px]',
                  'mobile:absolute mobile:top-[60px] mobile:ml-2',
                  landlord.bannerImage &&
                    '!pointer-events-auto ml-2 border-[3px] border-solid border-white mobile:!top-[88px]',
                )}
                src={landlord?.lettermarkLogo.rawPath}
                alt={landlord?.lettermarkLogo.description}
              />
            ) : null}
            <div
              className={cn(
                'flex w-full items-center mobile:mt-2',
                'mobile:ml-2 mobile:mr-2 mobile:min-h-0 mobile:justify-between',
                landlord.bannerImage && 'mt-2', // 16px for BANNER_EXTRA_OVERFLOW
                landlord.lettermarkLogo && 'mobile:ml-[112px] mobile:min-h-[72px]',
                landlord.bannerImage &&
                  landlord.lettermarkLogo &&
                  'mobile:mt-[20px] mobile:!min-h-[60px] mobile:items-start',
                landlord.bannerImage && landlordNameOverflowing && 'desktop:mt-4',
              )}
              ref={landlordTitleSectionRef}
            >
              <h1
                className={cn(
                  'text-black shrink-1 desktop:font-headline tablet:font-title',
                  'mobile:text-2xl mobile:font-subtitle',
                  landlord.bannerImage && landlord.lettermarkLogo && 'mobile:mt-0.5',
                )}
              >
                {landlord.name}
              </h1>
              {hasCurrentUser ? (
                <Tooltip
                  placement="bottom"
                  content={t('saveTooltip')}
                  trigger={
                    <div className={cn(s.saveButton, 'ml-2')}>
                      <IconButton
                        className={cn(
                          !!isLandlordSaved && s.isSaved,
                          'focus:border-2 focus:border-indigo-90',
                          'hover:bg-black-003',
                          'shrink-0',
                        )}
                        icon={isLandlordSaved ? 'heart' : 'heart-outline'}
                        onClick={async () => {
                          toggleSaveMutation.mutate(!isLandlordSaved);
                        }}
                        wrapWithFunction={false}
                        data-testid={
                          isLandlordSaved
                            ? 'favorite-landlord-button-saved'
                            : 'favorite-landlord-button-unsaved'
                        }
                      />
                    </div>
                  }
                  triggerAction="hover"
                />
              ) : null}
            </div>
          </div>
          <OnlyInDesktop>
            <div
              className={cn(
                'shrink-0',
                landlord.bannerImage && 'mt-2',
                basicInfoOverflowing && 'mt-2',
              )}
            >
              <BasicInfo
                inventory={landlord.inventory}
                premierRetailDestinations={landlord.premierRetailDestinations}
                space={landlord.space}
                underConstruction={landlord.underConstruction}
                numberOfFields={numberOfFields}
              />
            </div>
          </OnlyInDesktop>
        </div>
      </div>
      <OnlyInTabletMobile>
        {numberOfFields > 0 ? <div className={s.line}></div> : null}
        <BasicInfo
          inventory={landlord.inventory}
          premierRetailDestinations={landlord.premierRetailDestinations}
          space={landlord.space}
          underConstruction={landlord.underConstruction}
          numberOfFields={numberOfFields}
        />
      </OnlyInTabletMobile>
    </>
  ) : null;
};

export default LandlordHeaderSection;
