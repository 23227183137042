import { IconButton } from '@components/shared';
import cn from 'classnames';
import s from '../FullWidthLightbox.module.less';

type FloorPlanThumbnailProps = {
  onClick: () => void;
  floorPlanIndex: number;
  isSelected: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

const FloorPlanThumbnail = ({
  onClick,
  floorPlanIndex,
  isSelected,
  onMouseOver = () => null,
  onMouseLeave = () => null,
}: FloorPlanThumbnailProps) => {
  if (floorPlanIndex < 0) {
    return <div style={{ width: '32px' }}></div>; // Placeholder div so that map icon button in mobile will remain in center
  }
  return (
    <IconButton
      title="floor plan button"
      onClick={onClick}
      icon="floor-plan"
      className={cn(s.floorPlanThumbnailButton, isSelected && s.selectedFloorPlanThumbnail)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
};

export default FloorPlanThumbnail;
