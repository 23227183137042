import { TourbookListing } from '@root/types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useAnalytics from '@shared/useAnalytics';
import cn from 'classnames';
import { Button, IconButton, OnlyInDesktopTablet, OnlyInMobile } from '@components/shared';
import { useCustomBreakpoint } from '@shared/useBreakpoints';
import { SortableList, SortableListItem } from '@components/shared/sortable';
import tourbooksActions from '@store/actions/tourbooks';
import ListingItem from './ListingItem';
import s from './TableOfContents.module.less';

export type Props = {
  listings: TourbookListing[];
  isReordering?: boolean;
  readOnly?: boolean;
  tourbookId: string;
  fetchTourbook: () => void;
  reorderedTourbookListing: () => void;
  stopReordering: () => void;
  startReordering: () => void;
};

const LISTING_HEIGHT = 62;
const DEFAULT_SPACING_PER_LISTING = 1;
const REORDERING_SPACING_PER_LISTING = 8;
const ADDITIONAL_SPACING_WHEN_REORDERING = 16;
const DESKTOP_NUMBER_OF_LISTINGS_TO_SHOW = 8.5;
const DEFAULT_NUMBER_OF_LISTINGS_TO_SHOW = 6.5;

const screenWidths: { [k: string]: 'smallMobile' | 'desktop' | 'other' } = {
  SMALL_MOBILE: 'smallMobile',
  DESKTOP: 'desktop',
  OTHER: 'other',
};

const breakpointsConfig = {
  [screenWidths.SMALL_MOBILE]: 0,
  [screenWidths.OTHER]: 496,
  [screenWidths.DESKTOP]: 1025,
};

export const TableOfContents = ({
  listings,
  isReordering = false,
  readOnly = false,
  tourbookId,
  fetchTourbook,
  reorderedTourbookListing,
  stopReordering,
  startReordering,
}: Props) => {
  const { tourbookInteraction, PARAMETERS } = useAnalytics();
  const { t } = useTranslation('tourbook');
  const flags = useFlags();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const tableOfContents = useRef<HTMLDivElement | null>(null);
  const breakpoint = useCustomBreakpoint(breakpointsConfig);

  const isDesktop = breakpoint === screenWidths.DESKTOP;
  const isSmallMobile = breakpoint === screenWidths.SMALL_MOBILE;

  const listingsToRender = readOnly ? listings.filter(l => !l.flaggedAt) : listings;
  if (!listingsToRender.length) return null;

  const getReorderButtonText = () => {
    if (isReordering) return t('common:done');
    if (isSmallMobile) return t('reorder');
    return t('reorderListings');
  };

  const hasListingRentBeenEdited = (listing: TourbookListing) => {
    if (listing.type === 'listing') {
      return (
        listing.minAskingRentInMoneyPerAreaPerPeriod || listing.maxAskingRentInMoneyPerAreaPerPeriod
      );
    }
    return false;
  };

  const haveAnyListingRentsBeenEdited = listings.some(listing => hasListingRentBeenEdited(listing));

  const showAskingRentHelpText = readOnly
    ? haveAnyListingRentsBeenEdited && flags['tourbook-asking-rent']
    : haveAnyListingRentsBeenEdited && flags['tourbook-asking-rent'] && !isCollapsed;

  const toggleCollapseButton = () => {
    return (
      <IconButton
        data-testid="expandCollapseTableOfContentsButton"
        className={cn(s.expandCollapseButton, isCollapsed && s.collapsed)}
        icon="chevron-up"
        onClick={() => {
          tourbookInteraction({
            action: isCollapsed
              ? PARAMETERS.tableOfContentsExpand
              : PARAMETERS.tableOfContentsCollapse,
            actionType: isCollapsed
              ? 'TOURBOOK_EXPAND_TABLE_OF_CONTENTS'
              : 'TOURBOOK_COLLAPSE_TABLE_OF_CONTENTS',
            sourceContent: PARAMETERS.tableOfContents,
          });

          setIsCollapsed(!isCollapsed);
        }}
        wrapWithFunction={false}
        disabled={isReordering}
      />
    );
  };

  const getMaxHeight = () => {
    const getNumberOfListingsToShow = () => {
      if (isReordering) return listingsToRender.length;
      if (isDesktop) return DESKTOP_NUMBER_OF_LISTINGS_TO_SHOW;
      return DEFAULT_NUMBER_OF_LISTINGS_TO_SHOW;
    };

    const listingSpacing = isReordering
      ? REORDERING_SPACING_PER_LISTING
      : DEFAULT_SPACING_PER_LISTING;
    const maxHeight = (LISTING_HEIGHT + listingSpacing) * getNumberOfListingsToShow();

    if (isReordering) return maxHeight + ADDITIONAL_SPACING_WHEN_REORDERING;
    if (isCollapsed) return 0;

    return Math.min(maxHeight, tableOfContents?.current?.scrollHeight || 0);
  };
  return (
    <div className={cn(s.tableOfContentsContainer, isCollapsed && s.collapsed)}>
      <div className={s.titleAndReorderButtonContainer}>
        <div className={s.titleAndListingCountContainer}>
          <div className={s.titleContainer}>
            <h2 className={s.title}>{t('tableOfContents')}</h2>
            <OnlyInMobile preventRendering>{toggleCollapseButton()}</OnlyInMobile>
          </div>
          <h2 className={s.listingsCount}>
            {t('numListings', { count: listingsToRender.length })}
          </h2>
          <OnlyInDesktopTablet preventRendering>{toggleCollapseButton()}</OnlyInDesktopTablet>
        </div>
        <div className={s.helpTextAndReorderButtonContainer}>
          {!readOnly && (
            <Button
              icon="arrowsUpDown"
              type="tertiary"
              className={s.reorderButton}
              onClick={isReordering ? stopReordering : startReordering}
            >
              {getReorderButtonText()}
            </Button>
          )}
        </div>
      </div>
      <div
        className={cn(s.tableOfContents, isReordering && s.reordering)}
        data-testid="tableOfContents"
        style={{ maxHeight: getMaxHeight() }}
        ref={tableOfContents}
      >
        <SortableList
          onDrop={tourbooksActions.reorderTourbookListings(tourbookId)}
          onSort={() => {
            fetchTourbook();
            reorderedTourbookListing();
          }}
          className={cn(s.sortableList, isReordering && s.reordering)}
        >
          {listingsToRender.map((listing, i) => (
            <SortableListItem
              index={i}
              sortableId={listing.sortableId}
              key={listing.id}
              draggingClassName={s.isDragging}
              isDragDisabled={!isReordering}
              className={s.sortableListItem}
            >
              <ListingItem
                listing={listing}
                sourceContent={PARAMETERS.tableOfContents}
                key={listing.id}
                draggable={isReordering}
              />
            </SortableListItem>
          ))}
        </SortableList>
      </div>
      {showAskingRentHelpText && (
        <span
          className={cn(s.askingRentHelpText, readOnly && isCollapsed && s.helpTextReadOnlyStyle)}
        >
          {t('modifiedAskingRentHelpText')}
        </span>
      )}
    </div>
  );
};

export default TableOfContents;
