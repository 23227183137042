import { useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SearchListing } from '@root/types';
import IconButton from '@components/shared/IconButton/IconButton';
import { Notification, FlexibleSizeArea, CustomIcon } from '@components/shared';
import cn from 'classnames';
import actions from '@store/actions/listingSearchPage/analyticsActions';
import Divider from '@components/shared/Divider';
import routes from '@root/routes';
import ExclusiveLabel from '@components/shared/ExclusiveLabel';
import { Tooltip } from 'antd';
import useAltText from '@root/shared/useAltText';
import useTourbookIconButton from '@root/shared/useTourbookIconButton';
import s from './ListingCard.module.less';

const { listing: listingRoute } = routes;

type Props = {
  listing: SearchListing;
  onClick?: (e: React.MouseEvent) => void;
  onHover?: () => void;
  onHoverExit?: () => void;
  toggleSaveListing?: (args: {
    listing: SearchListing;
    willBeSaved: boolean;
    onSuccess: ((favorited: boolean) => void) | null;
  }) => void;
  favorited?: boolean;
  loggedInUser?: boolean;
  showSaveAction?: boolean;
  showAddToTourBookAction?: boolean;
  istourbookCreateOnly?: boolean;
};

const ListingCard = ({
  listing,
  onClick = () => {},
  onHover = () => {},
  onHoverExit = () => {},
  toggleSaveListing = () => {},
  favorited = false,
  loggedInUser = false,
  showSaveAction = true,
  showAddToTourBookAction = true,
  istourbookCreateOnly = false,
}: Props) => {
  const { t } = useTranslation('listing');
  const {
    photo,
    size,
    id,
    buildingSlug,
    capacity,
    neighborhoodName,
    smallestPrice,
    shortestLeaseTerm,
    name,
    isCurrentlyExclusive,
    dateAvailable,
    availableToday,
    address,
  } = listing;

  const dispatch = useDispatch();
  const { getAltText } = useAltText({ address });

  const savedListingLinkClicked = () => {
    dispatch(actions.savedListingNotificationLinkClicked());
  };

  const onMarket = listing.status === 'on_market';

  const getAvailibilityMessage = () => {
    if (onMarket && availableToday) {
      return t('search.availableNow');
    }

    if (!dateAvailable || !onMarket) {
      return t('search.notAvailable');
    }

    const isAfterToday = isAfter(new Date(dateAvailable), new Date());

    return isAfterToday ? t('availableBy', { date: dateAvailable }) : t('search.availableNow');
  };

  const displaySuccessfulNotification = () => {
    Notification.saveSuccess({
      title: t('listingSavedToFavorites'),
      /* eslint-disable react/jsx-no-literals */
      text: (
        <Trans i18nKey="viewInFavorites" ns="listing">
          View it in your
          <a href="/saved/listings" onClick={savedListingLinkClicked}>
            saved listings
          </a>
          .
        </Trans>
      ),
      /* eslint-enable react/jsx-no-literals */
    });
  };

  const handleFavoriteSuccess = isFavorited =>
    !isFavorited ? displaySuccessfulNotification : null;

  const {
    TourbookIconButton,
    tourbookButtonProps,
    TourbookOverlay,
    tourbookOverlayProps,
    tourbookDialogIsOpen,
  } = useTourbookIconButton({
    listing,
    createOnly: istourbookCreateOnly,
    useInvisibleMask: true,
  });
  const buildingName = t('common:fullBuildingName', { address });

  return (
    <article className={s.container}>
      <Link
        aria-label={`${buildingName} ${name}`}
        onClick={onClick}
        onMouseEnter={onHover}
        onMouseLeave={onHoverExit}
        className={s.link}
        to={listingRoute(id, buildingSlug)}
        data-testid={`listingCard-${listing.id}`}
      >
        <div className={s.listingCard}>
          {showSaveAction && loggedInUser && (
            <div className={s.actionButtons}>
              <Tooltip trigger={['hover', 'click']} placement="right" title={t('saveTooltip')}>
                <span>
                  <IconButton
                    className={cn({ [s.filled]: favorited })}
                    data-testid="save-to-favorites"
                    icon={favorited ? 'heart' : 'heart-outline'}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      toggleSaveListing({
                        listing,
                        willBeSaved: !favorited,
                        onSuccess: handleFavoriteSuccess(favorited),
                      });
                    }}
                  />
                </span>
              </Tooltip>
              {showAddToTourBookAction ? <TourbookIconButton {...tourbookButtonProps} /> : null}
            </div>
          )}
          <div className={s.offMarketPhotoContainer}>
            {isCurrentlyExclusive && listing.status === 'on_market' ? (
              <ExclusiveLabel size="medium" />
            ) : null}
            {listing.status === 'off_market' ? (
              <div className={s.offMarket}>{t('offMarket')}</div>
            ) : null}
            <img
              className={cn({ [s.listingPhoto]: photo, [s.grayScale]: !onMarket })}
              src={photo.smallPath}
              alt={getAltText({ type: photo.type, options: { roomName: photo.description } })}
            />
          </div>
          <div className={s.listingInfo}>
            <div className={s.areaAndCapacity}>
              <div className={s.squareFoot}>{t('units:area', { area: size })}</div>
              {capacity ? (
                <>
                  <div className={s.spacer} />
                  <div className={s.capacityAndIcon}>
                    <CustomIcon type="person" className="person" />
                    <span className={s.capacity}>{capacity}</span>
                  </div>
                </>
              ) : null}
            </div>
            <div className={s.flexSizeContainer}>
              <FlexibleSizeArea minMaxArea={listing.minMaxArea} />
            </div>
            {neighborhoodName ? <div className={s.neighborhood}>{neighborhoodName}</div> : null}
            <div className={s.buildingName}>{buildingName}</div>
            <div className={s.listingName}>{name}</div>
            <div className={s.divider}>
              <Divider />
            </div>
            <div
              className={
                (dateAvailable && onMarket) || availableToday ? s.availableDate : s.unavailableDate
              }
            >
              {getAvailibilityMessage()}
            </div>
          </div>
          {onMarket && smallestPrice && shortestLeaseTerm && size ? (
            <div className={s.leaseInfo}>
              <div className={s.shortestLeaseTerm}>
                {/* eslint-disable react/jsx-no-literals */}
                {`${t('search.min')} ${shortestLeaseTerm} ${t('search.lease')}`}
                {/* eslint-enable react/jsx-no-literals */}
              </div>
              <div className={s.smallestPriceContainer}>
                <span className={s.from}>{t('search.from')} </span>
                <span className={s.smallestPrice}>{`${t('units:pricePerAreaPerPeriod', {
                  pricePerAreaPerPeriod: smallestPrice,
                })}`}</span>
              </div>
            </div>
          ) : null}
        </div>
      </Link>

      {tourbookDialogIsOpen ? (
        <TourbookOverlay {...tourbookOverlayProps} className={s.overlay} />
      ) : null}
    </article>
  );
};

export default ListingCard;
