import { createPolyline, latLngBounds } from '@shared/googleMaps';

class SubmarketBoundaryDrawer {
  submarketBoundaryPolygons: google.maps.Polyline[];

  constructor() {
    this.submarketBoundaryPolygons = [];
  }

  createSubmarketBoundaries = (selectedSubmarkets, map, customMapCoordinates: string | null) => {
    this.clearSubmarketBoundaries();

    if (selectedSubmarkets.length) {
      const bounds = latLngBounds();
      const boundaryPolygons = selectedSubmarkets.reduce(
        (accumulator, submarket) => [
          ...accumulator,
          ...submarket.boundaries.map(boundary => createPolyline(boundary)),
        ],
        [],
      );

      // extend the bounds of the map so that all of the selectedSubmarkets fit.
      selectedSubmarkets.forEach(submarket =>
        submarket.boundaries.forEach(boundary =>
          boundary.forEach(coordinate => bounds.extend(coordinate)),
        ),
      );

      // put them on the map
      boundaryPolygons.forEach(polygon => {
        polygon.setMap(map);
        this.submarketBoundaryPolygons.push(polygon);
      });

      if (!customMapCoordinates) {
        map.fitBounds(bounds);
      }
    }
  };

  clearSubmarketBoundaries = () => {
    this.submarketBoundaryPolygons.forEach(polygon => {
      polygon.setMap(null);
    });
    this.submarketBoundaryPolygons = [];
  };
}

export default SubmarketBoundaryDrawer;
