import { RadioGroup } from '@components/shared/forms';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

const TextColorSelector = ({ name }: { name: string }) => {
  const { t } = useTranslation('admin');
  const flags = useFlags();
  return (
    <RadioGroup
      containerClass="!mb-0"
      options={[
        { value: '#000000', label: t('microsite.colors.black') },
        { value: '#ffffff', label: t('microsite.colors.white') },
      ]}
      name={name}
      labelText={t('microsite.textColor')}
      data-testid="color-selector"
      fast={!!flags['market-office.microsite-performance-optimizations']}
    />
  );
};

export default TextColorSelector;
