import { useState, useEffect, useRef, useCallback } from 'react';
import actions from '@store/actions/tourbooks';
import cn from 'classnames';
import { TourbookSummary, SearchListing, Listing, BuildingListing } from '@root/types';
import { connect, ConnectedProps } from 'react-redux';
import useFetchTourbookSummaries from '@root/shared/useFetchTourbookSummaries';
import ToggleCard from './ToggleCard';
import CreateTourbookCard from './CreateTourbookCard';
import InfoCard from './InfoCard';
import s from './TourbookOverlay.module.less';
import InvisibleMask from '../InvisibleMask';

const OVERLAY_HEIGHT = 318;

const mapDispatch = {
  seeTourbookExplanation: () => actions.seeTourbookExplanation(),
};

const connector = connect(() => ({}), mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  isOpen: boolean;
  close: () => void;
  listing: SearchListing | Listing | BuildingListing;
  createOnly?: boolean;
  className?: string | null;
  useInvisibleMask?: boolean;
  calculateCorrectedTop?: (height: number) => number | undefined;
};

type DialogCards = 'toggle' | 'createTourbook' | 'info' | 'loading';
export const RawTourbookOverlay = ({
  isOpen,
  close,
  listing,
  seeTourbookExplanation,
  createOnly = false,
  className,
  useInvisibleMask = false,
  calculateCorrectedTop,
}: Props & ReduxProps) => {
  const [orderedTourbooks, setOrderedTourbooks] = useState<TourbookSummary[]>([]);

  const { data: tourbooks, isLoading: summariesLoading } = useFetchTourbookSummaries();

  const chooseCorrectStartingCard = useCallback((): DialogCards => {
    if (createOnly) return 'createTourbook';
    if (summariesLoading) return 'loading';
    return tourbooks && tourbooks.length > 0 ? 'toggle' : 'createTourbook';
  }, [createOnly, summariesLoading, tourbooks]);

  const [activeCard, setActiveCard] = useState<DialogCards>(chooseCorrectStartingCard);
  const goToCreateCard = () => {
    setActiveCard('createTourbook');
  };
  const goToToggleCard = () => {
    setActiveCard('toggle');
  };
  const goToInfoCard = () => {
    setActiveCard('info');
    seeTourbookExplanation();
  };
  const closeAndReset = () => {
    close();
  };

  useEffect(() => {
    setActiveCard(chooseCorrectStartingCard());
  }, [chooseCorrectStartingCard, createOnly, summariesLoading]);

  const dialog = useRef<HTMLDialogElement>(null!);
  const handleKeyDown = event => {
    if (event.key === 'Escape') closeAndReset();
  };
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  useEffect(() => {
    const handleOutsideClick = event => {
      if (dialog && !dialog.current?.contains(event.target)) {
        closeAndReset();
      }
    };
    document.addEventListener('pointerdown', handleOutsideClick);
    return () => {
      document.removeEventListener('pointerdown', handleOutsideClick);
    };
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog]);

  useEffect(() => {
    if (tourbooks) {
      setOrderedTourbooks(previousOrderedTourbooks => {
        const newTourbooks = tourbooks.filter(
          tourbook =>
            !previousOrderedTourbooks
              .map(orderedTourbook => orderedTourbook.id)
              .includes(tourbook.id),
        );

        const updatedTourbooks = previousOrderedTourbooks
          .filter(previousOrderedTourbook =>
            tourbooks.find(tourbook => tourbook.id === previousOrderedTourbook.id),
          )
          .map(
            orderedTourbook =>
              tourbooks.find(tourbook => tourbook.id === orderedTourbook.id) ?? orderedTourbook,
          );
        return [...updatedTourbooks, ...newTourbooks];
      });
    }
  }, [listing?.id, tourbooks]);

  useEffect(() => {
    const sortByListingInTourbook = orderedTourbooksToSort => {
      const sortedTourbooks = [...orderedTourbooksToSort].sort((a, b) => {
        const tourbookAHasListing = a.listingIds.includes(listing.id);
        const tourbookBHasListing = b.listingIds.includes(listing.id);
        if (tourbookAHasListing && !tourbookBHasListing) return -1;
        if (!tourbookAHasListing && tourbookBHasListing) return 1;
        return 0;
      });
      return sortedTourbooks;
    };

    if (tourbooks && orderedTourbooks.length !== tourbooks.length) {
      setOrderedTourbooks(sortByListingInTourbook(tourbooks));
    }
  }, [tourbooks, orderedTourbooks, listing]);

  return (
    <>
      <dialog
        open={isOpen}
        className={cn(s.overlay, isOpen && s.open, className)}
        data-testid="tourbookDialog"
        ref={dialog}
        style={{ height: OVERLAY_HEIGHT, top: calculateCorrectedTop?.(OVERLAY_HEIGHT) }}
      >
        {activeCard === 'toggle' && !createOnly && !!listing ? (
          <ToggleCard
            tourbooks={orderedTourbooks}
            close={closeAndReset}
            goToCreateCard={goToCreateCard}
            listing={listing}
          />
        ) : null}
        {activeCard === 'createTourbook' ? (
          <CreateTourbookCard
            close={closeAndReset}
            goToInfoCard={goToInfoCard}
            goToToggleCard={goToToggleCard}
            listing={listing}
            createOnly={createOnly}
          />
        ) : null}
        {activeCard === 'info' ? (
          <InfoCard close={closeAndReset} goToCreateCard={goToCreateCard} />
        ) : null}
      </dialog>
      {useInvisibleMask && <InvisibleMask className={s.mask} />}
    </>
  );
};

export default connector(RawTourbookOverlay);
