/* eslint-disable react/sort-comp */
/* eslint-disable max-len */
import { createRef, Component } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { InlineNotice } from '@viewthespace/components';
import {
  AdminBuilding,
  BuildingAmenitiesMetadata,
  BuildingDataCollectionMetadata,
  BuildingAmenitySet,
  Demographics,
  MarketingMedia,
} from '@root/types';
import { CustomIcon, Breadcrumbs } from '@components/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Container from '@components/shared/Admin/Container';
import { Alert, notification, Result, Menu } from 'antd';
import { isEmpty } from 'lodash/fp';
import { parse } from 'qs';
import routes from '@root/routes';
import api from '@shared/api';
import s from '@components/layouts/Admin/PropertyPage';
import Spinner from '@components/shared/Spinner';
import { locationType, historyType } from '@propTypes';
import css from './BuildingPage.module.less';
import MenuSwitcher, { TABS } from './MenuSwitcher';
import Layout from '../Layout/Layout';

const {
  api: {
    admin: {
      building: buildingRoute,
      buildingAmenitySet: buildingAmenitySetRoute,
      buildingDemographics: buildingDemographicsRoute,
      buildingMarketingMedia: buildingMarketingMediaRoute,
    },
  },
  admin: { buildingSearch: buildingSearchRoute, buildingListings: buildingListingsRoute },
} = routes;
type DisplayStates = 'fetching' | 'fetched' | 'noBuilding';
const STATES: Record<DisplayStates, DisplayStates> = {
  fetching: 'fetching',
  fetched: 'fetched',
  noBuilding: 'noBuilding',
};
type Props = {
  id: string;
  t: $TSFixMeFunction;
  location: locationType;
  history: historyType;
  flags: Object;
};
type State = {
  display: DisplayStates;
  errors: string[];
  building: AdminBuilding | null;
};

class RawBuildingPage extends Component<Props, State> {
  buildingPage = createRef();

  state: State = {
    display: STATES.fetching,
    building: null,
    errors: [],
  };

  componentDidMount() {
    this.fetchAdminBuilding();
  }

  componentDidUpdate(prevProps: Props) {
    const { id: buildingId } = this.props;
    const { id: prevBuildingId } = prevProps;
    if (prevBuildingId !== buildingId) {
      this.handleRedirect();
    }
  }

  handleRedirect = () => {
    this.fetchAdminBuilding();
    this.scrollToTop();
  };

  scrollToTop = () => {
    (this as $TSFixMe).buildingPage.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  setBuildingAmenitySetState(amenitySet: BuildingAmenitySet) {
    this.setState((state: State) => ({
      ...state,
      building: { ...state.building, amenitySet } as AdminBuilding,
    }));
  }

  setBuildingMarketingMediaSetState(marketingMediaSet: MarketingMedia) {
    this.setState((state: State) => ({
      ...state,
      building: { ...state.building, marketingMedia: marketingMediaSet } as AdminBuilding,
    }));
  }

  setBuildingDemographicsSetState(demographics: Demographics[]) {
    this.setState((state: State) => ({
      ...state,
      building: { ...state.building, demographics } as AdminBuilding,
    }));
  }

  fetchAdminBuilding = async () => {
    const { id: buildingId } = this.props;
    const response = await api.fetch(buildingRoute(buildingId));
    try {
      const building = await response.json();
      this.setState({ building, display: STATES.fetched });
    } catch {
      this.setState({ display: STATES.noBuilding });
    }
  };

  updateBuilding = async params => {
    const { id: buildingId, t } = this.props;
    const response = await api.put(buildingRoute(buildingId), params);

    if (response.status === 422) {
      try {
        const json = await response.json();
        if (
          json.errors &&
          json.errors.length > 0 &&
          json.errors[0].message.includes('error.customer.not_found')
        ) {
          return notification.error({
            message: t('building.noPreviewStatusChangeValidation'),
          });
        }
        if (
          json.errors &&
          json.errors.length > 0 &&
          json.errors[0].message === 'Landlord slug is not present'
        ) {
          return notification.error({
            message: t('building.noLandlordValidation'),
          });
        }
        if (json.errors[0]?.message.includes('error.microsite.not_found')) {
          return notification.error({
            message: 'Microsite not found for this building',
          });
        }
        return notification.error({
          message: json.errors[0]?.message || t('building.somethingWentWrong'),
        });
      } catch {
        return notification.error({ message: t('building.statusValidationMessage') });
      }
    }
    const json = await response.json();
    if (response.ok) {
      notification.info({ message: t('building.saveSuccess') });
      return this.setState({ building: json, errors: [] });
    }
    if (!isEmpty(json.errors)) {
      return this.setState({ errors: json.errors }, () => {
        this.scrollToTop();
      });
    }
    if (!isEmpty(json.messages)) {
      return notification.error({ message: json.messages.join(', ') });
    }
    return notification.error({ message: t('building.saveFailure') });
  };

  updateAmenitySet = async ({
    buildingId,
    body,
  }: {
    buildingId: string;
    body: BuildingAmenitiesMetadata | BuildingDataCollectionMetadata;
  }) => {
    const { t } = this.props;
    const response = await api.put(buildingAmenitySetRoute(buildingId), body);
    const json = await response.json();

    if (response.ok) {
      this.setBuildingAmenitySetState(json);
      this.setState({ errors: [] });
      notification.info({ message: t('building.saveSuccess') });
    } else if (!isEmpty(json.errors)) {
      this.setState({ errors: json.errors }, () => {
        this.scrollToTop();
      });
    } else if (!isEmpty(json.messages)) {
      notification.error({ message: json.messages.join(', ') });
    } else {
      notification.error({ message: t('building.saveFailure') });
    }
  };

  updateBuildingDemographics = async ({
    buildingId,
    body,
  }: {
    buildingId: string;
    body: Demographics[];
  }) => {
    const { t } = this.props;
    const response = await api.put(buildingDemographicsRoute(buildingId), body);
    const json = await response.json();

    if (response.ok) {
      this.setBuildingDemographicsSetState(json);
      this.setState({ errors: [] });
      notification.info({ message: t('building.saveSuccess') });
    } else if (!isEmpty(json.errors)) {
      this.setState({ errors: json.errors }, () => {
        this.scrollToTop();
      });
    } else if (!isEmpty(json.messages)) {
      notification.error({ message: json.messages.join(', ') });
    } else {
      notification.error({ message: t('building.saveFailure') });
    }
  };

  updateBuildingMarketingMedia = async ({
    buildingId,
    body,
  }: {
    buildingId: string;
    body: MarketingMedia;
  }) => {
    const { t } = this.props;
    const response = await api.put(buildingMarketingMediaRoute(buildingId), body);
    const json = await response.json();
    if (response.ok) {
      this.setBuildingMarketingMediaSetState(json);
      this.setState({ errors: [] });
      notification.info({ message: t('building.saveSuccess') });
    } else if (!isEmpty(json.errors)) {
      this.setState({ errors: json.errors }, () => {
        this.scrollToTop();
      });
    } else if (!isEmpty(json.messages)) {
      notification.error({ message: json.messages.join(', ') });
    } else {
      notification.error({ message: t('building.saveFailure') });
    }
  };

  updateIndustryAttributes = async attrs => {
    const { id: buildingId, t } = this.props;

    const response = await api.put(
      routes.api.admin.buildingIndustrialAttributes(buildingId),
      attrs,
    );
    if (response.ok) {
      this.setState({ errors: [] });
      notification.info({ message: t('building.saveSuccess') });
      await this.fetchAdminBuilding();
      return true;
    }

    notification.error({ message: t('building.saveFailure') });
    return false;
  };

  currentPage = (): string => {
    const { location } = this.props;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    return queryParams.tab && TABS.includes(queryParams.tab as string)
      ? (queryParams.tab as string)
      : 'form';
  };

  changePage = ({ key }) => {
    const {
      location: { pathname },
      history,
    } = this.props;
    history.push(`${pathname}?tab=${key}`);
  };

  renderBuildingName = (primaryName, building) =>
    [primaryName, building.secondaryName].filter(obj => obj).join(' - ');

  renderError = error => {
    const { building } = this.state;
    const { t } = this.props;
    if (error.field === 'status') {
      if ('missingFields' in error.amenitySet) {
        return (
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          <Alert
            key={error.field}
            className={classNames(css.missingFieldsMessage, css.error)}
            icon={<CustomIcon type="exclamation-circle" />}
            showIcon
            type="error"
            description={
              <div>
                <div>{error.message}</div>
                <ul data-testid="missingFieldsList" className={css.missingFieldsList}>
                  {error.amenitySet.missingFields.map(missingField => (
                    <li key={missingField}>{missingField}</li>
                  ))}
                </ul>
              </div>
            }
          />
        );
      }
      return (
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        <Alert
          key={error.field}
          className={css.error}
          showIcon
          type="error"
          description={
            <div>
              <div className={css.errorMessage}>{error.message}</div>
              <Link
                className={css.viewListedSpacesText}
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                to={buildingListingsRoute(building.id)}
                target="_blank"
              >
                {t('building.viewListedSpaces')}
              </Link>
            </div>
          }
        />
      );
    }
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <Alert
        key={error.field}
        className={css.error}
        showIcon
        type="error"
        description={
          <div>
            <div>{error.message}</div>
          </div>
        }
      />
    );
  };

  getFilteredTabs = () => {
    const { flags } = this.props;
    const { building } = this.state;
    const buildingHasIndustrial = building && building.assetTypes.includes('INDUSTRIAL');
    const buildingHasRetail = building && building.assetTypes.includes('RETAIL');

    return TABS.map(TAB => {
      if (TAB === 'brandedAssets') {
        return TAB;
      }
      if (TAB === 'industrial' && buildingHasIndustrial && flags['industrial-proof-of-concept']) {
        return TAB;
      }
      if (TAB === 'demographics' && buildingHasRetail) {
        return TAB;
      }
      if (TAB === 'automatedMarketing' && flags['squad-channels.microsite-data-fields']) {
        return TAB;
      }
      if (TAB === 'microsite') {
        return TAB;
      }
      if (
        TAB !== 'demographics' &&
        TAB !== 'industrial' &&
        TAB !== 'automatedMarketing' &&
        TAB !== 'brandedAssets' &&
        TAB !== 'microsite'
      ) {
        return TAB;
      }
      return null;
    }).filter(tab => tab);
  };

  render() {
    const { t } = this.props;
    const { building, display, errors } = this.state;
    const filteredTabs = this.getFilteredTabs();
    if (display === STATES.noBuilding) return <Result status="404" title="404" />;
    const hasErrors = !isEmpty(errors);
    return (
      <Layout
        fixedHeaderContents={
          <div>
            <div className="flex justify-between px-[20px]">
              <h3 className="font-subtitle">
                {building
                  ? `${t('building.buildingPage')} | ${t('common:fullBuildingName', {
                      address: building!.address,
                    })}`
                  : null}
              </h3>
              {building?.hasMicrosite ? (
                <div>
                  <InlineNotice
                    variant="informational"
                    content={t('building.willUpdateMicrosite')}
                    size="small"
                  />
                </div>
              ) : null}
            </div>
            <Menu onClick={this.changePage} selectedKeys={[this.currentPage()]} mode="horizontal">
              {filteredTabs.map(tab => (
                <Menu.Item
                  className={classNames(tab === this.currentPage() && 'font-bold')}
                  key={tab}
                >
                  {t(`building.menuItems.${tab}`)}
                </Menu.Item>
              ))}
            </Menu>
          </div>
        }
        fixedHeaderClassName="!pb-0"
      >
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message */}
        <div ref={this.buildingPage}>
          {display === STATES.fetching ? (
            <Container>
              <div className={s['spinner-wrapper']}>
                <Spinner />
              </div>
            </Container>
          ) : (
            <>
              <Breadcrumbs
                className="mb-1"
                items={[
                  { content: t('breadcrumbs.admin') },
                  { content: t('breadcrumbs.buildings'), link: buildingSearchRoute },
                  { content: t('common:fullBuildingName', { address: building!.address }) },
                ]}
              />
              <Container>
                <div className="flex justify-between">
                  <h3 className="font-subtitle">{t('building.buildingPage')}</h3>
                  {building?.hasMicrosite ? (
                    <div>
                      <InlineNotice
                        variant="informational"
                        content={t('building.willUpdateMicrosite')}
                        size="small"
                      />
                    </div>
                  ) : null}
                </div>
                <h1 className="mb-2 font-headline">
                  {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
                  {this.renderBuildingName(building.primaryName, building)}
                </h1>
                <Menu
                  onClick={this.changePage}
                  selectedKeys={[this.currentPage()]}
                  mode="horizontal"
                >
                  {filteredTabs.map(tab => (
                    <Menu.Item
                      className={classNames(tab === this.currentPage() && 'font-bold')}
                      key={tab}
                    >
                      {t(`building.menuItems.${tab}`)}
                    </Menu.Item>
                  ))}
                </Menu>
                {hasErrors ? errors.map(error => this.renderError(error)) : null}
                <MenuSwitcher
                  building={building!}
                  updateBuilding={this.updateBuilding}
                  updateBuildingDemographics={this.updateBuildingDemographics}
                  updateAmenitySet={this.updateAmenitySet}
                  currentPage={this.currentPage()}
                  fetchAdminBuilding={this.fetchAdminBuilding}
                  updateIndustryAttributes={this.updateIndustryAttributes}
                  errors={errors}
                  updateBuildingMarketingMedia={this.updateBuildingMarketingMedia}
                />
              </Container>
            </>
          )}
        </div>
      </Layout>
    );
  }
}
export { RawBuildingPage };

// This is here because react-router removed withRouter HOC. Ideally, we'd turn this class
// component into a functional component and remove so much of the cruft it has.
// However, there isn't time to do it.
export default function BuildingPageWrapper() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation('admin');
  const flags = useFlags();
  return <RawBuildingPage id={id} history={history} location={location} t={t} flags={flags} />;
}
