import { notification, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import api from '@shared/api';
import { Button } from '@components/shared';
import s from './MediaDownloader.module.less';

type MediaDownloaderType = {
  downloadUrl: string | null;
  isDisabled: boolean;
  onClick: () => void;
};

const MediaDownloader = ({ downloadUrl, isDisabled, onClick }: MediaDownloaderType) => {
  const { t } = useTranslation('admin');

  const handleDownloadPhotos = async event => {
    event.preventDefault();
    const response = await api.fetch(downloadUrl);

    if (response.ok) {
      const { url } = await response.json();
      window.location = url;

      return Promise.resolve().then(() => onClick());
    }
    notification.error({
      message: t('media.downloadPhotosFailure'),
    });

    return Promise.reject();
  };

  return (
    <div className={s.downloadButton}>
      <Tooltip
        trigger={['hover', 'click']}
        placement="topLeft"
        title={t('media.downloadPhotosTooltip')}
      >
        <span>
          <Button onClick={e => handleDownloadPhotos(e)} type="primary" disabled={isDisabled}>
            {t('media.downloadPhotos')}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default MediaDownloader;
