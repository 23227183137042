import { useRef, useEffect, useState, ReactNode } from 'react';
import { Form as FormikForm, Formik, FormikHelpers, FormikConfig, FormikValues } from 'formik';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormThemes, FormThemeContextProvider } from '../ThemeContext';

type Props<FormValues> = {
  /**
   * test id will be on the Formik Form, rather than <Formik />
   */
  'data-testid'?: string;
  /**
   * Unique Id of the Form
   */
  id: string;
  children: ReactNode;
  /**
   * Object Reprsenting Initial Values
   */
  initialValues?: FormValues;
  /**
   * What happens when you submit
   */
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => any;
  /**
   * Yup Object validation schema
   */
  validationSchema?: FormikConfig<FormValues>['validationSchema'];
  className?: string;
  /**
   * Vertical is generally how Marketplace forms are, and Horizontal is how admin forms are
   */
  layout?: FormThemes;
  enableReinitialize?: boolean;
  enablePrompt?: boolean;
  unsavedPromptMessage?: string;
  setIsDirty?: (isDirty: boolean) => void;
  onChange?: (event) => void;
};

export const Form = <Values extends FormikValues>({
  'data-testid': dataTestId,
  id,
  children,
  onSubmit,
  validationSchema,
  initialValues = {} as Values,
  className,
  layout = 'vertical',
  enableReinitialize = false,
  enablePrompt = false,
  unsavedPromptMessage,
  setIsDirty,
  onChange,
}: Props<Values>) => {
  const cancelled = useRef(false);
  useEffect(
    () => () => {
      cancelled.current = true;
    },
    [],
  );

  const { t } = useTranslation('common');
  const [isFormDirty, setIsFormDirty] = useState(false);

  return (
    <>
      <Formik<Values>
        validateOnBlur
        onSubmit={async (values, helpers) => {
          await onSubmit(values, helpers);
          if (!cancelled.current) {
            helpers.setSubmitting(false);
          }
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={enableReinitialize}
      >
        {({ dirty }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setIsFormDirty(dirty);
            if (setIsDirty) {
              setIsDirty(dirty);
            }
          }, [dirty]);

          return (
            <FormikForm
              onChange={onChange}
              className={className}
              role="form"
              data-testid={dataTestId}
              id={id}
            >
              <FormThemeContextProvider theme={layout}>{children}</FormThemeContextProvider>
            </FormikForm>
          );
        }}
      </Formik>
      {enablePrompt && (
        <Prompt when={isFormDirty} message={unsavedPromptMessage || t('prompt.unsavedChanges')} />
      )}
    </>
  );
};

export default Form;
