import { startCase, get } from 'lodash';
import { AdminListing } from '@root/types';
import { useTranslation } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import { Input, Checkbox } from 'antd';

type Props = {
  listing: AdminListing;
  // this is because its an antd 3 form, no use typing it
  form: any;
};

const BuildingDetailsSection = ({ listing, form }: Props) => {
  const { t } = useTranslation('admin');
  const { getFieldDecorator } = form;

  const fullListingUrl = `${document.location.host}${listing.url}`;
  return (
    <>
      <div className="flex items-center gap-1 py-2">
        <h2 className="font-subtitle">{t('listing.buildingDataTitle')}</h2>
      </div>
      <Form.Item label={t('listing.vtsPropertyId')}>
        {getFieldDecorator('vtsPropertyId', { initialValue: listing.space.vtsPropertyId })(
          <Input disabled />,
        )}
      </Form.Item>
      <Form.Item label={t('listing.vtsMarketplaceBuildingId')}>
        {getFieldDecorator('vtsMarketplaceBuildingId', {
          initialValue: get(listing, 'building.id'),
        })(<Input disabled />)}
      </Form.Item>

      <Form.Item label={t('listing.publicPreview')}>
        {getFieldDecorator('publicPreview', {
          initialValue: listing.publicPreview,
          valuePropName: 'checked',
        })(<Checkbox disabled={!listing.isPreviewable} />)}
      </Form.Item>

      <Form.Item label={t('listing.url')}>
        {listing.url ? (
          <a href={listing.url} target="_blank" rel="noopener noreferrer">
            {fullListingUrl}
          </a>
        ) : null}
      </Form.Item>
      <Form.Item label={t('listing.displayAddress')}>
        {getFieldDecorator('displayAddress', {
          initialValue: get(listing, 'building.displayAddress'),
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.buildingName')}>
        {getFieldDecorator('name', {
          initialValue: get(listing, 'building.name'),
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.formattedAddress')}>
        {getFieldDecorator('formattedAddress', {
          initialValue: get(listing, 'building.formattedAddress'),
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.buildingStatus')}>
        {getFieldDecorator('buildingStatus', {
          initialValue: startCase(get(listing, 'building.status')),
        })(<Input disabled />)}
      </Form.Item>
      <Form.Item label={t('listing.buildingDemo')}>
        {getFieldDecorator('buildingDemo', {
          initialValue: listing.building.demo,
          valuePropName: 'checked',
        })(<Checkbox disabled />)}
      </Form.Item>
    </>
  );
};

export default BuildingDetailsSection;
