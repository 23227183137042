import { useState, useEffect } from 'react';
import { CustomIcon, InvisibleMask, OnlyInMobile, OnlyInDesktopTablet } from '@components/shared';
import renderingInServer from '@shared/renderingInServer';
import routes from '@root/routes';
import { updateCurrentUser } from '@store/actions/currentUser';
import { Pages, StoreState, Locale } from '@root/types';
import navActions from '@store/actions/navigation';
import actions from '@store/actions';
import cn from 'classnames';
import useAnalytics from '@shared/useAnalytics';
import useEnv from '@shared/useEnv';
import { useCookies } from 'react-cookie';
import useStickyState from '@shared/useStickyState';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import useLoginUrl from '@shared/useLoginUrl';
import { NavLink } from 'react-router-dom';
import setActiveSavedSearchId from '@store/actions/saved/setActiveSavedSearch';
import useCurrentUser from '@shared/useCurrentUser';
import { isInIframe } from '@root/shared/iframeUtils';
import Logo from './Logo';
import BuildingSearch from './BuildingSearch';
import DropdownMenu from './DropdownMenu';
import s from './Navigation.module.less';
import LocaleSelector from '../LocaleSelection';

type Props = {
  activePage?: Pages;
  stickyNav?: boolean;
};

const mapDispatch = dispatch => ({
  updateUser: ({ user, onSuccess, onError, analyticsInfo }) =>
    dispatch(updateCurrentUser({ user, onSuccess, onError, ...analyticsInfo })),
  searchClicked: () => {
    dispatch(navActions.searchClicked());
    dispatch(setActiveSavedSearchId(null));
  },
  tourbooksClicked: () => dispatch(navActions.tourbooksClicked),
});

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const useVisualizingAnalytics = () => {
  const dispatch = useDispatch();
  const isVisualizingAnalyticsInRedux = useSelector(
    (state: StoreState) => state.ui.visualizeAnalytics,
  );
  const [isVisualizingAnalyticsInLocalStorage, setVisualizingAnalyticsInLocalStorage] =
    useStickyState<boolean>(isVisualizingAnalyticsInRedux, 'visualizingAnalytics');

  useEffect(() => {
    if (isVisualizingAnalyticsInLocalStorage !== isVisualizingAnalyticsInRedux) {
      dispatch(actions.toggleVisualizingAnalytics);
    }
  }, [isVisualizingAnalyticsInLocalStorage, isVisualizingAnalyticsInRedux, dispatch]);

  const toggleVisualizingAnalytics = () => {
    setVisualizingAnalyticsInLocalStorage(!isVisualizingAnalyticsInLocalStorage);
    dispatch(actions.toggleVisualizingAnalytics);
  };

  return {
    toggleVisualizingAnalytics,
    isVisualizingAnalytics: isVisualizingAnalyticsInLocalStorage || isVisualizingAnalyticsInRedux,
  };
};

function Navigation({
  activePage,
  searchClicked,
  tourbooksClicked,
  updateUser,
  stickyNav = false,
}: Props & ReduxProps) {
  const { currentUser, refetch, hasLoaded } = useCurrentUser();
  const { t, i18n } = useTranslation('navigation');
  const { vtsUrl } = useEnv();
  const { clickToPage, anonymousId, PARAMETERS } = useAnalytics();
  const cookieName = 'truva-language-preference';
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const loggedOut = !currentUser;

  const { loginUrl, logoutUrl } = useLoginUrl();

  const { toggleVisualizingAnalytics, isVisualizingAnalytics } = useVisualizingAnalytics();
  const getLocalStorageLanguagePreference = (): Locale => {
    if (renderingInServer()) return 'en-us';

    const supportedLanguages: Locale[] = ['en-gb', 'en-us'];
    const defaultLanguage = 'en-us';
    const { [cookieName]: storedLanguagePreference } = cookies;
    const languagePreference = storedLanguagePreference || (i18n.language as Locale);

    return supportedLanguages.includes(languagePreference) ? languagePreference : defaultLanguage;
  };

  const tourbookPages: Pages[] = ['myTourbooks', 'tourbookExternalListing', 'tourbookShow'];
  const savedPages: Pages[] = [
    'savedBuildingOwners',
    'savedBuildings',
    'savedListings',
    'savedSearches',
  ];
  const [searchOpen, setSearchOpen] = useState(false);
  const [mobileOverlayOpen, setMobileOverlayOpen] = useState(false);

  const userLanguagePreference: Locale =
    currentUser?.languagePreference ?? getLocalStorageLanguagePreference();

  const setLocalStorageLanguagePreference = (languagePreference: Locale) => {
    // We were previously setting cookies to be page specific. We are now making it
    // global, but we need to remove any other existing versions of this cookie. Otherwise
    // they would take precidence over this global one.
    removeCookie(cookieName);
    setCookie(cookieName, languagePreference, { path: '/' });
    window.location.reload();
  };

  const handleChangeLanguagePreference = async (languagePreference, analyticsInfo) => {
    await i18n.changeLanguage(languagePreference);
    if (currentUser) {
      updateUser({
        user: { ...currentUser, languagePreference },
        onSuccess: async () => {
          await refetch();
          window.location.reload();
        },
        onError: errorsObject => {
          // eslint-disable-next-line no-console
          console.error(errorsObject);
        },
        analyticsInfo,
      });
    } else {
      setLocalStorageLanguagePreference(languagePreference);
    }
  };

  if (isInIframe()) return null;

  const loggedInUserNavigation = currentUser ? (
    <ul
      className={cn(
        s.navLinkList,
        searchOpen && s.searchOpened,
        mobileOverlayOpen && s.mobileOverlayOpened,
      )}
    >
      <li key="logo" className={s.mobileClose}>
        <Logo />
        <button className={s.closeButton} type="button" onClick={() => setMobileOverlayOpen(false)}>
          <CustomIcon type="close" />
        </button>
      </li>
      <li
        key="searchLink"
        data-testid="searchLink"
        className={cn(
          s.navigationLink,
          activePage === 'listingSearch' && s.active,
          s.listingSearchLink,
        )}
      >
        <NavLink to={routes.search(currentUser.marketSlug)} onClick={searchClicked}>
          <CustomIcon className={s.icon} type="magnifying-glass"></CustomIcon>
          <span className={s.navText}>{t('search')}</span>
        </NavLink>
      </li>
      <BuildingSearch
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
        setMobileOverlayOpen={setMobileOverlayOpen}
      />
      <li
        key="tourbook"
        className={cn(
          s.navigationLink,
          // @ts-expect-error
          tourbookPages.includes(activePage) && s.active,
        )}
      >
        <NavLink to={routes.tourbooks} onClick={tourbooksClicked}>
          <div>
            <CustomIcon className={s.icon} type="tourbook"></CustomIcon>
            <span className={s.navText}>{t('tourbook')}</span>
          </div>
        </NavLink>
      </li>
      <li
        key="savedItems"
        className={cn(
          s.navigationLink,
          s.dropdownNav,
          // @ts-expect-error
          savedPages.includes(activePage) && s.active,
        )}
      >
        <NavLink to={routes.savedListings}>
          <div>
            <CustomIcon className={s.icon} type="heart-outline"></CustomIcon>
            <span className={s.navText}>{t('saved')}</span>
          </div>
        </NavLink>
        <DropdownMenu
          options={[
            {
              label: t('listings'),
              route: routes.savedListings,
              key: 'savedListings',
            },
            {
              label: t('searches'),
              route: routes.savedSearches,
              key: 'savedSearches',
            },
            {
              label: t('buildings'),
              route: routes.savedBuildings,
              key: 'savedBuildings',
            },
            {
              label: t('buildingOwners'),
              route: routes.savedBuildingOwners,
              key: 'savedBuildingOwners',
            },
          ]}
        />
      </li>
      <li
        key="accountSettings"
        className={cn(
          s.navigationLink,
          s.dropdownNav,
          activePage === 'userAccountSettings' && s.active,
          'max-w-screen-lg',
        )}
      >
        <NavLink to={`${routes.users.account}?page=profile`}>
          <div>
            <CustomIcon className={s.icon} type="person-circle"></CustomIcon>
            <span className={s.navText}>{currentUser?.firstName}</span>
          </div>
        </NavLink>
        <DropdownMenu
          data-testid="accountDropdownMenu"
          options={[
            {
              label: t('account'),
              route: `${routes.users.account}?page=profile`,
              key: 'professionalInfo',
            },
            ...(currentUser.role === 'admin'
              ? [
                  {
                    label: t('admin'),
                    route: '/admin',
                    outsideLink: true,
                    key: 'admin',
                  },
                  {
                    key: 'visualizeAnalytics',
                    isToggle: true,
                    label: 'View analytics',
                    value: isVisualizingAnalytics,
                    onToggle: toggleVisualizingAnalytics,
                  } as const,
                ]
              : []),
            {
              label: t('vtsHome'),
              route: vtsUrl!,
              outsideLink: true,
              key: 'home',
            },
            {
              label: t('signOut'),
              route: logoutUrl,
              outsideLink: true,
              key: 'logout',
            },
          ]}
        />
      </li>
      <li className={cn(s.localeSelector)}>
        <LocaleSelector
          key="localeSelector"
          userLanguagePreference={userLanguagePreference}
          handleChangeLanguage={handleChangeLanguagePreference}
        />
      </li>
    </ul>
  ) : null;

  const loadedUserNavigation = hasLoaded ? (
    <ul
      className={cn(
        s.navLinkList,
        mobileOverlayOpen && s.mobileOverlayOpened,
        loggedOut && s.loggedOut,
      )}
    >
      <li className={cn(s.mobileClose, loggedOut && s.loggedOut)}>
        {!loggedOut ? <Logo /> : null}
        <button className={s.closeButton} type="button" onClick={() => setMobileOverlayOpen(false)}>
          <CustomIcon type="close" />
        </button>
      </li>
      <li className={cn(s.navigationLink, loggedOut && s.loggedOut)}>
        <a
          href={routes.signUp(vtsUrl!, {
            utm_content: 'marketplace nav',
            anonymous_id: anonymousId,
          })}
          onClick={() => {
            clickToPage({
              destination: PARAMETERS.signUpPage,
              sourceContent: PARAMETERS.navigation,
            });
          }}
        >
          {t('signUp')}
        </a>
      </li>
      <li className={cn(s.navigationLink, loggedOut && s.loggedOut)}>
        <a href={loginUrl}>{t('logIn')}</a>
      </li>
      <li className={cn(s.localeSelector, loggedOut && s.loggedOut)}>
        <LocaleSelector
          userLanguagePreference={userLanguagePreference}
          handleChangeLanguage={handleChangeLanguagePreference}
        />
      </li>
    </ul>
  ) : null;

  return (
    <nav
      data-testid="nav"
      className={cn(s.nav, stickyNav && s.stickyNav, loggedOut && s.loggedOut)}
    >
      <OnlyInMobile>
        <button
          className={s.hamburgerMenu}
          type="button"
          onClick={() => setMobileOverlayOpen(true)}
        >
          <CustomIcon type="menuOutlined" className={cn(loggedOut && '!text-black-100')} />
        </button>
        {mobileOverlayOpen && (
          <InvisibleMask className="z-50" onClick={() => setMobileOverlayOpen(false)} />
        )}
      </OnlyInMobile>
      <div className="flex items-center gap-1">
        {loggedOut ? <span className="font-body-medium">{t('poweredBy')}</span> : null}
        <Logo />
      </div>
      <OnlyInDesktopTablet>
        {loggedOut && hasLoaded ? (
          <div className="ml-3 h-[21px] border border-solid border-text-disabled"></div>
        ) : null}
      </OnlyInDesktopTablet>
      {loggedInUserNavigation || loadedUserNavigation}
    </nav>
  );
}

export default connector(Navigation);
