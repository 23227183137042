import cn from 'classnames';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  CustomIcon,
  Notification,
  InvisibleMask,
  IconButton,
  PopperTooltip,
} from '@components/shared';
import { useFormikContext } from 'formik';
import { Form, TextArea, Checkbox, SubmitButton } from '@components/shared/forms';
import { CurrentUser, QuickInquiryFormFields, StoreState } from '@root/types';
import { reformatISODateString } from '@root/shared/dateUtils';
import useHandleOutsideClick from '@shared/useHandleClickOutside';
import actions from '@store/actions/listingSearchPage';
import api from '@shared/api';
import routes from '@root/routes';
import * as Yup from 'yup';
import { defaultInquiryMessage } from '@components/layouts/Truva/AccountSettings/InquirySettingsForm';
import useAnalytics from '@root/shared/useAnalytics';
import {
  getLastInquiryForListing,
  getListingSearchPageLevelArguments,
  getSearchListing,
} from '@root/store/selectors';
import { EVENTS, PARAMETERS } from '@root/tracking/constants';
import { TriggerType } from 'react-popper-tooltip';
import useSize from '@react-hook/size';
import s from './ListingCard.module.less';

const preformatDate = (stringTime: string): string => {
  return reformatISODateString({ value: stringTime, afterFormat: 'yyyy-MM-dd' });
};

const formatTime = (stringTime: string): string => {
  return reformatISODateString({ value: stringTime, afterFormat: 'p' });
};

type QuickInquiryOverlayProps = {
  listingId: string;
  close: () => void;
  quickInquiryDefaultMessage: string | null;
  currentUser: CurrentUser;
  calculateCorrectedTop?: (height: number) => number | undefined;
};

export type QuickInquiryIconButtonProps = {
  listingId: string;
  currentUser: CurrentUser;
  isLocked: boolean;
  isOpen: boolean;
  onClick: () => void;
  trigger: TriggerType;
  disabled: boolean;
};

const ConfirmOneClickButton = () => {
  const formik = useFormikContext();
  const { t } = useTranslation('inquiry');
  return (
    <SubmitButton
      onClick={() => {
        formik.setFieldValue('oneClickInquiryActivated', true);
      }}
      size="small"
    >
      {t('confirmOneClickSubmit')}
    </SubmitButton>
  );
};

const OneClickSetupForm = ({
  message,
  close,
  currentUser,
}: {
  message: string;
  close: () => void;
  currentUser: CurrentUser;
}) => {
  const { t } = useTranslation('inquiry');
  const validationSchema = Yup.object({
    quickInquiryDefaultMessage: Yup.string().required().ensure(),
    oneClickInquiryActivated: Yup.boolean().required(t('defaultMessageRequired')),
  });
  type FormValues = Yup.InferType<typeof validationSchema>;
  const onSubmit = async (values: FormValues) => {
    const response = await api.put(routes.api.currentUserInquirySettings, {
      ...values,
      ...(values.quickInquiryDefaultMessage ===
      defaultInquiryMessage(t, 'defaultMessage', currentUser)
        ? { quickInquiryDefaultMessage: null }
        : {}),
    });

    if (response.ok) {
      if (values.oneClickInquiryActivated) {
        Notification.info({
          title: t('oneClickSetupSuccessTitle'),
          /* eslint-disable react/jsx-no-literals */
          text: (
            <Trans ns="inquiry" i18nKey="oneClickSetupSuccessBody">
              You can edit this in <a href={routes.users.inquirySettings}>account settings</a>
            </Trans>
          ),
          /* eslint-enable react/jsx-no-literals */
        });
      }
      close();
    } else {
      Notification.error({
        title: t('common:genericFailureTitle'),
        text: t('common:genericFailureMessage'),
      });
    }
  };

  return (
    <Form<FormValues>
      id="oneClickInquiryForm"
      initialValues={{
        quickInquiryDefaultMessage: message,
        oneClickInquiryActivated: false,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <div className={s.header}>
        <span className={s.headerText}>
          <CustomIcon type="setup-one-click" className={s.setupOneClickIcon} />
          <h5>{t('setupOneClick')}</h5>
        </span>
        <button type="button" onClick={close}>
          <CustomIcon type="close" className={s.close} />
        </button>
        <p className={s.oneClickExplanation}>{t('oneClickExplanation')}</p>
      </div>
      <TextArea
        labelText={t('yourMessage')}
        name="quickInquiryDefaultMessage"
        className={s.textarea}
        labelClass={s.textArealabel}
      />
      <div className={s.oneClickButtons}>
        <ConfirmOneClickButton />
        <Button type="secondary" size="small" onClick={close}>
          {t('rejectOneClickSubmit')}
        </Button>
      </div>
    </Form>
  );
};

export const QuickInquiryOverlay = ({
  listingId,
  quickInquiryDefaultMessage,
  close,
  currentUser,
  calculateCorrectedTop,
}: QuickInquiryOverlayProps) => {
  const { t } = useTranslation('inquiry');
  const [oneClickSetup, setOneClickSetup] = useState({ open: false, message: '' });

  const validationSchema = Yup.object({
    message: Yup.string().required(t('messageRequired')),
    saveDefaultMessage: Yup.boolean().required(),
  });
  const dispatch = useDispatch();
  const { pageNumber, resultsCount } = useSelector(getListingSearchPageLevelArguments);
  const listing = useSelector((state: StoreState) => getSearchListing(state, listingId));
  const previousInquiryExists = !!useSelector((state: StoreState) =>
    getLastInquiryForListing(state, listingId),
  );

  const { quickInquiryInteraction } = useAnalytics();

  const handleSaveDefaultMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quickInquiryDefaultMessageSet = e.target.checked;
    quickInquiryInteraction({
      action: PARAMETERS.quickInquiry.defaultMessage,
      event: EVENTS.inquiryInteraction,
      actionType: 'QUICK_INQUIRY_DEFAULT_MESSAGE',
      listing,
      oneClickInquiryActivated: currentUser.oneClickInquiryActivated,
      otherAttributes: {
        quickInquiryDefaultMessageSet,
        pageNumber,
        resultsCount,
      },
    });
  };
  const handleSendQuickInquiry = (): void => {
    quickInquiryInteraction({
      action: previousInquiryExists ? PARAMETERS.inquiry.resend : PARAMETERS.inquiry.send,
      event: EVENTS.inquiryInteraction,
      actionType: previousInquiryExists
        ? 'QUICK_INQUIRY_RESEND_MESSAGE'
        : 'QUICK_INQUIRY_SEND_MESSAGE',
      listing,
      oneClickInquiryActivated: currentUser.oneClickInquiryActivated,
      otherAttributes: {
        pageNumber,
        resultsCount,
      },
    });
  };

  const handleQuickInquiryError = (): void => {
    quickInquiryInteraction({
      action: PARAMETERS.inquiry.error,
      event: EVENTS.inquiryInteraction,
      actionType: 'QUICK_INQUIRY_ERROR',
      listing,
      oneClickInquiryActivated: currentUser.oneClickInquiryActivated,
      otherAttributes: {
        pageNumber,
        resultsCount,
      },
    });
  };

  const onSubmit = async (values: QuickInquiryFormFields) => {
    handleSendQuickInquiry();

    await dispatch(
      actions.createQuickInquiry({
        listingId,
        body: values,
        onSuccess: () => {
          Notification.info({
            title: t('sentInquiryTitle'),
            text: t('sentInquiryBody'),
          });
          if (values.saveDefaultMessage) {
            setOneClickSetup({
              open: true,
              message: values.message,
            });
          } else {
            close();
          }
        },
        onFailure: () => {
          handleQuickInquiryError();
          Notification.error({
            title: t('failedQuickInquiryTitle'),
            text: t('failedQuickInquiryBody'),
          });
        },
      }),
    );
  };
  const containerRef = useHandleOutsideClick<HTMLDivElement>(close);
  const initialHeight = 318;
  const height = useSize(containerRef.current)[1] || initialHeight;

  return (
    <>
      <div
        className={s.quickInquiryForm}
        ref={containerRef}
        style={{
          top: calculateCorrectedTop?.(height),
        }}
      >
        {oneClickSetup.open ? (
          <OneClickSetupForm
            close={close}
            message={oneClickSetup.message}
            currentUser={currentUser}
          />
        ) : (
          <Form<QuickInquiryFormFields>
            id="quickInquiryForm"
            initialValues={{
              message:
                quickInquiryDefaultMessage ||
                defaultInquiryMessage(t, 'defaultMessage', currentUser),
              saveDefaultMessage: false,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <div className={s.header}>
              <span className={s.headerText}>
                <h5>{t('inquiry')}</h5>
              </span>
              <button type="button" onClick={close}>
                <CustomIcon type="close" className={s.close} />
              </button>
            </div>
            <TextArea
              name="message"
              labelText={t('yourMessage')}
              labelClass={s.textArealabel}
              containerClass={s.textareaContainer}
              className={s.textarea}
              rows={6}
              required
            />
            {quickInquiryDefaultMessage ? (
              <p className={s.editInAccount}>
                {/* eslint-disable react/jsx-no-literals */}
                <Trans ns="inquiry" i18nKey="referToAccountSettings">
                  Edit default message in
                  <Link to={`${routes.users.account}?page=inquiry`}>Account settings</Link>
                </Trans>
                {/* eslint-enable react/jsx-no-literals */}
              </p>
            ) : (
              <Checkbox
                size="small"
                labelClassName={s.checkbox}
                name="saveDefaultMessage"
                onChange={handleSaveDefaultMessage}
              >
                {t('saveDefaultMessage')}
              </Checkbox>
            )}

            <div className={s.buttons}>
              <SubmitButton size="small">
                <CustomIcon type="envelopeOutline" className={s.envelopeIcon} />
                {t(previousInquiryExists ? 'resendInquiry' : 'sendInquiry')}
              </SubmitButton>
            </div>
          </Form>
        )}
      </div>
      <InvisibleMask className="z-[4]" />
    </>
  );
};

const QuickInquiryIconButton = ({
  listingId,
  currentUser,
  isLocked,
  isOpen,
  onClick = () => {},
  trigger = 'hover',
  disabled,
}: QuickInquiryIconButtonProps) => {
  const { quickInquiryInteraction } = useAnalytics();
  const { t } = useTranslation('inquiry');
  const lastInquiry = useSelector((state: StoreState) =>
    getLastInquiryForListing(state, listingId),
  );
  const { pageNumber, resultsCount } = useSelector(getListingSearchPageLevelArguments);
  const listing = useSelector((state: StoreState) => getSearchListing(state, listingId));

  const button = (
    <span>
      <IconButton
        className={cn({ [s.filled]: !!lastInquiry }, isOpen && s.border)}
        data-testid={isLocked ? 'lockedQuickInquiryButton' : 'quickInquiryButton'}
        icon={lastInquiry ? 'envelopeFilled' : 'envelopeOutline'}
        disabled={disabled}
        onClick={() => {
          if (!isLocked) {
            quickInquiryInteraction({
              action: PARAMETERS.quickInquiry.inquire,
              event: EVENTS.inquiryInteraction,
              actionType: 'QUICK_INQUIRY_INQUIRE',
              listing,
              oneClickInquiryActivated: currentUser.oneClickInquiryActivated,
              otherAttributes: {
                pageNumber,
                resultsCount,
              },
            });
            onClick();
          }
        }}
      />
    </span>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <PopperTooltip
        triggerElement={button}
        toolTipPlacement="bottom"
        trigger={trigger}
        popperElementClassName="opacity-80 bg-black-100 text-background-primary"
        ignoreTouchscreenClicks
        popperElement={
          lastInquiry
            ? t('lastInquirySentNoPerson', {
                sendDate: preformatDate(lastInquiry.lastSentAt),
                sendTime: formatTime(lastInquiry.lastSentAt),
              })
            : t('inquire')
        }
      />
    </div>
  );
};

export default QuickInquiryIconButton;
