import cn from 'classnames';
import CustomIcon from '@components/shared/CustomIcon';
import { isInIframe } from '@root/shared/iframeUtils';
import contentWidth from '@styles/contentWidth.module.less';
import { useTranslation, Trans } from 'react-i18next';
import s from './Footer.module.less';

export const Footer = () => {
  const { t } = useTranslation('common');

  if (isInIframe()) return null;

  return (
    <>
      <hr className={s.borderTop} />
      <div className={s.container}>
        <footer
          data-testid="footer"
          className={cn(contentWidth.contentPadding, contentWidth.contentContainer)}
        >
          <div className={s.linksSection}>
            <ul>
              <li className={s.about}>{t('footer.product')}</li>
              <li className={s.link}>
                <a href="https://www.vts.com/vts-lease">{t('footer.vtsLease')}</a>
              </li>
              <li className={s.link}>
                <a href="https://www.vts.com/vts-market">{t('footer.vtsMarket')}</a>
              </li>
              <li className={s.link}>
                <a href="https://www.vts.com/vts-activate">{t('footer.vtsActivate')}</a>
              </li>
              <li className={s.link}>
                <a href="https://www.vts.com/vts-data">{t('footer.vtsData')}</a>
              </li>
            </ul>
            <ul>
              <li className={s.about}>{t('footer.company')}</li>
              <li className={s.link}>
                <a href="https://www.vts.com/news">{t('footer.press')}</a>
              </li>
              <li className={s.link}>
                <a href="https://www.vts.com/careers">{t('footer.careers')}</a>
              </li>
              <li className={s.link}>
                <a href="https://www.vts.com/about-us">{t('footer.aboutUs')}</a>
              </li>
            </ul>
            <ul>
              <li className={s.about}>{t('footer.productSupport')}</li>
              <li className={s.link}>
                <a href="https://help.vts.com/hc/en-us/categories/360003602293">
                  {t('footer.helpCenter')}
                </a>
              </li>
              <li className={s.link}>
                <a href="mailto:support@vts.com">{t('footer.supportEmail')}</a>
              </li>
            </ul>
          </div>
          <div className={s.termsSection}>
            <div className={s.termsAndConditions}>
              <CustomIcon className={s.logo} type="vts-logo-indigo" />
              <span className={s.copyright}>
                {t('footer.copyrightVts', { year: new Date().getFullYear() })}
              </span>
              <div className={s.divider} />
              <span className={s.rights}>{t('footer.allRightsReserved')}</span>
              <div className={cn(s.divider, s.second)} />
              <span className={s.terms}>
                {/* eslint-disable react/jsx-no-literals */}
                <Trans i18nKey="footer.agreeToTerms" ns="common">
                  By searching, you agree to VTS’s
                  <a href="https://www.vts.com/site_terms">terms and conditions</a> and
                  <a href="https://www.vts.com/privacy">privacy policy.</a>
                </Trans>
                {/* eslint-enable react/jsx-no-literals */}
              </span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
