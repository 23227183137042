import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@components/shared/forms';
import { useFormikContext } from 'formik';
import { AdminMicrosite } from '@root/types';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { Checkbox } from '@viewthespace/components';
import { ContentBlockWrapper, ContentBlockProps } from '..';
import ColorPicker from '../../ColorPicker';
import TextColorSelector from '../../TextColorSelector';
import { isSinglePageLayout, useMicrositeFormContext } from '../../utils';

export default function Availability({ id, index, anchorSlug }: ContentBlockProps) {
  const { colorGroups, changeFontColorWithColorChange, sendPreviewTo, microsite, resetKey } =
    useMicrositeFormContext();
  const flags = useFlags();
  const { t } = useTranslation('admin');
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();
  const isAvailabilitySectionHidden = !values.availabilitySectionIsVisible;

  return (
    <ContentBlockWrapper
      contentBlockId={id}
      title={t('microsite.sections.availability')}
      section="availability"
      onClick={() =>
        sendPreviewTo({
          page: isSinglePageLayout(values.layout!) ? 'home' : 'availability',
          element: anchorSlug,
        })
      }
      index={index}
      isHidden={isAvailabilitySectionHidden}
    >
      {flags['market-office.hide-gallery-contact-leasing'] ? (
        <Checkbox
          label={t('microsite.hideSection')}
          className="[&_input]:!w-[16px] [&_input]:!h-[16px]"
          backgroundStyle="filled"
          isChecked={isAvailabilitySectionHidden}
          onChange={e => {
            setFieldValue('availabilitySectionIsVisible', !e.value);
            setFieldTouched('availabilitySectionIsVisible', true);
          }}
        />
      ) : null}
      <RadioGroup
        name="availabilityStyle"
        options={[
          { value: 'card', label: t('microsite.availabilityStyle.card') },
          { value: 'table', label: t('microsite.availabilityStyle.table') },
        ]}
        labelText={t('microsite.availabilityStyle.title')}
        containerClass="!mb-0"
        data-testid="home-hero-image-style-selector"
        fast={!!flags['market-office.microsite-performance-optimizations']}
      />
      <ColorPicker
        colorGroups={colorGroups}
        name="availabilityBackgroundColor"
        labelText={t('microsite.backgroundColor')}
        onChange={changeFontColorWithColorChange('availabilityTextColor')}
      />
      {flags['market-office.text-color-selector'] && (
        <TextColorSelector name="availabilityTextColor" />
      )}
      {flags['market.customizable-gallery-and-availability-titles'] ? (
        <RichTextEditorInput
          name="availabilityTitle"
          labelText={t('microsite.sectionTitle')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          containerClass="!mb-0"
          excludeMenuItems={['bulletList']}
          initialContent={microsite.availabilityTitle}
          defaultTextSize="header32"
          resetKey={resetKey}
          fast={!!flags['market-office.microsite-performance-optimizations']}
          shouldDebounce
        />
      ) : null}
      <ColorPicker
        colorGroups={colorGroups}
        name="availabilityBorderColor"
        labelText={t('microsite.borderColor')}
        hasTransparentOption
      />
      <ColorPicker
        colorGroups={colorGroups}
        name="availabilityButtonColor"
        labelText={t('microsite.buttonColor')}
        onChange={changeFontColorWithColorChange('availabilityButtonTextColor')}
      />
      {microsite.listingDetailsModalEnabled ? (
        <ColorPicker
          colorGroups={colorGroups}
          name="availabilityIconAndLinkColor"
          labelText={t('microsite.availabilityIconAndLinkColor.title')}
          tooltipContent={t('microsite.availabilityIconAndLinkColor.helpText')}
          labelClass="!mt-[1px]"
        />
      ) : null}
    </ContentBlockWrapper>
  );
}
