import { ReactNode, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CustomIcon } from '@components/shared';
import cn from 'classnames';
import { SortDownIcon, Button, HideIcon } from '@viewthespace/components';
import { useFormikContext } from 'formik';
import { AdminMicrosite } from '@root/types';
import {
  Section,
  useMicrositeFormContext,
  removeBlockFromGenericContentBlocks,
  removeBlockFromContentBlocks,
  getPageForContentBlock,
  decreaseMaxContentBlockPositionOfPage,
  isGenericContentBlock,
  isSinglePageLayout,
} from '../../utils';

export default function Wrapper({
  title,
  children = null,
  section,
  onClick = () => {},
  dragAndDropEnabled = false,
  contentBlockId,
  wrapInDraggable = true,
  index = 0,
  isHidden = false,
}: {
  title: ReactNode;
  children?: ReactNode;
  section: Section | string;
  onClick?: () => void;
  dragAndDropEnabled?: boolean;
  contentBlockId: string;
  wrapInDraggable?: boolean;
  index?: number;
  isHidden?: boolean;
}) {
  const { getIsOpen, setOpen, setClosed, sendPreviewTo } = useMicrositeFormContext();
  const { values, setValues } = useFormikContext<Partial<AdminMicrosite>>();

  // Begin state to control transition animation of block being deleted
  const [isSectionBeingDeleted, setIsSectionBeingDeleted] = useState<Boolean>(false);
  const [collapseSectionContent, setCollapseSectionContent] = useState<Boolean>(false);
  const [fadeOutSection, setFadeOutSection] = useState<Boolean>(false);
  // End state to control transition animation of block being deleted

  const flags = useFlags();

  const isOpen = getIsOpen(section);
  const onSectionButtonClick = () => {
    if (isOpen) setClosed(section);
    else {
      setOpen(section);
      onClick();
    }
  };

  const contentBlock = values.contentBlocks!.find(block => block.id === contentBlockId);

  const canDeleteContentBlock =
    contentBlock &&
    isGenericContentBlock(contentBlock) &&
    isOpen &&
    flags['market-office.flexible-layouts-on-microsite'];

  const showDragAndDropControl = dragAndDropEnabled && !isOpen && !isSectionBeingDeleted;

  const showHiddenIcon =
    isHidden && !isOpen && flags['market-office.hide-amenities-and-certifications'];

  const handleCollapseSectionAnimation = async () => {
    return new Promise(resolve => {
      setCollapseSectionContent(true);
      setTimeout(resolve, 500);
    });
  };

  const handleCloseSection = async () => {
    return new Promise(resolve => {
      setClosed(section);
      setTimeout(resolve, 1000);
    });
  };

  const handleFadeOutSectionAnimation = async () => {
    return new Promise(resolve => {
      setFadeOutSection(true);
      setTimeout(resolve, 500);
    });
  };

  const handleDeleteContentBlock = () => {
    const contentBlocks = values.contentBlocks!;
    const deletedContentBlockIndex = contentBlocks.findIndex(block => block.id === contentBlockId);
    const deletedContentBlock = contentBlocks[deletedContentBlockIndex];

    const updatedGenericContentBlocks = removeBlockFromGenericContentBlocks(
      values.genericContentBlocks!,
      deletedContentBlock.id,
    );
    const updatedContentBlocks = removeBlockFromContentBlocks(contentBlocks, deletedContentBlock);
    const currentPage = getPageForContentBlock(values.pages!, deletedContentBlock);
    const updatedPages = decreaseMaxContentBlockPositionOfPage(values.pages!, currentPage!);

    setValues({
      ...values,
      genericContentBlocks: updatedGenericContentBlocks,
      contentBlocks: updatedContentBlocks,
      pages: updatedPages,
    });

    const isSinglePage = isSinglePageLayout(values.layout!);
    const pageContentBlocks = isSinglePage
      ? contentBlocks
      : contentBlocks.filter(block => block.pageSlug === currentPage?.slug);
    const deletedContentBlockIndexInPage = pageContentBlocks.findIndex(
      block => block.id === contentBlockId,
    );
    const focussedElement =
      pageContentBlocks[deletedContentBlockIndexInPage + 1] ||
      pageContentBlocks[deletedContentBlockIndexInPage - 1];

    sendPreviewTo({
      page: isSinglePage ? 'home' : currentPage?.slug,
      element: focussedElement?.anchorSlug || '',
    });
  };

  const handleClickDeleteContentBlock = async e => {
    e.stopPropagation();
    setIsSectionBeingDeleted(true);
    await handleCollapseSectionAnimation();
    await handleCloseSection();
    await handleFadeOutSectionAnimation();
    handleDeleteContentBlock();
  };

  const SectionHeaderWrapperComponent = dragAndDropEnabled ? 'span' : 'button';
  return (
    <PossiblyDraggableWrapper
      index={index}
      contentBlockId={contentBlockId}
      wrapInDraggable={wrapInDraggable}
      dragAndDropEnabled={dragAndDropEnabled && !isSectionBeingDeleted}
      isOpen={isOpen}
    >
      {({ provided, snapshot }) => {
        return (
          <div className="relative group">
            {/* eslint-disable-next-line no-nested-ternary */}
            {showDragAndDropControl ? (
              <div
                className={cn(
                  'opacity-0 absolute left-[-22px] top-[18px] group-hover:opacity-100',
                  snapshot.isDragging && 'opacity-100',
                )}
              >
                <CustomIcon type="grip-dots" className={cn('[&>svg]:w-[20px] [&>svg]:h-[20px]')} />
              </div>
            ) : null}
            <section
              className={cn(
                'flex border border-solid border-black-020 bg-background-tertiary max-h-[2000px]',
                showDragAndDropControl && 'border-dashed group-hover:bg-general-gray-quaternary',
                snapshot.isDragging &&
                  'border-general-indigo-secondary shadow-[1px_1px_7px_rgba(0,0,0,0.25)] bg-general-gray-quaternary',

                fadeOutSection && 'max-h-0 opacity-0 transition-all duration-500 top-0',
              )}
              data-testid={`${section}Section`}
            >
              <div className="w-full" {...(dragAndDropEnabled ? provided.dragHandleProps : {})}>
                <SectionHeaderWrapperComponent
                  type="button"
                  onClick={onSectionButtonClick}
                  className={cn(
                    'mr-2 w-full p-2 h-[56px] flex items-center',
                    dragAndDropEnabled && 'w-10/12',
                  )}
                >
                  <h3 className="grid gap-1 grid-cols-[16px_1fr_32px] items-center font-subtitle w-full">
                    <span className="mb-[18px] mr-[5px]">
                      <div
                        className={cn('h-[18px] w-[18px]', !isOpen && 'origin-bottom -rotate-90')}
                      >
                        <SortDownIcon />
                      </div>
                    </span>
                    <span className={cn('w-full text-left overflow-hidden text-ellipsis')}>
                      {title}
                    </span>
                    {showHiddenIcon && (
                      <HideIcon
                        className="text-[#696C77]"
                        title="hidden"
                        data-testid="hiddenSectionIcon"
                      />
                    )}
                    {canDeleteContentBlock ? (
                      <Button
                        variant="secondary-neutral"
                        iconName="Trash"
                        iconPosition="right"
                        ariaLabel="Delete content block"
                        name="Delete content block"
                        onClick={event => handleClickDeleteContentBlock(event)}
                        shape="pill"
                        backgroundStyle="filled"
                        fitting="content-fit"
                        className="!border-solid border-text-primary"
                      />
                    ) : null}
                  </h3>
                </SectionHeaderWrapperComponent>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                  className={
                    collapseSectionContent
                      ? cn(
                          'overflow-hidden max-h-0 opacity-0 transition-[opacity,_max-height] duration-1000',
                          isOpen && 'opacity-100 max-h-[2000px]',
                        )
                      : cn('hidden cursor-default', isOpen && '!flex')
                  }
                  onClick={onClick}
                >
                  <div className="flex flex-col gap-2 p-2 pt-0 w-full">{children}</div>
                </div>
              </div>
            </section>
          </div>
        );
      }}
    </PossiblyDraggableWrapper>
  );
}

const PossiblyDraggableWrapper = ({
  contentBlockId,
  wrapInDraggable,
  children,
  index,
  dragAndDropEnabled,
  isOpen,
}: {
  contentBlockId: string;
  wrapInDraggable: boolean;
  children: (args: { provided: any; snapshot: any }) => ReactNode;
  index: number;
  dragAndDropEnabled: boolean;
  isOpen: boolean;
}) => {
  const flags = useFlags();
  return flags['market-office.flexible-layouts-on-microsite'] && wrapInDraggable ? (
    <Draggable
      isDragDisabled={!dragAndDropEnabled || isOpen}
      draggableId={contentBlockId}
      key={contentBlockId}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            {children({ provided, snapshot })}
          </div>
        );
      }}
    </Draggable>
  ) : (
    <>{children({ provided: {}, snapshot: {} })}</>
  );
};
