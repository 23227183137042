import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadApiResponse as CloudinaryResponse } from 'cloudinary';
import { Notification } from '@components/shared';
import { Checkbox } from '@viewthespace/components';
import {
  AdminBuilding,
  CloudinaryImageUpdateParams,
  CropperCanvasData,
  MultipathImage,
} from '@root/types';
import { useMutation } from '@tanstack/react-query';
import routes from '@root/routes';
import { update } from '@root/shared/typedApi';
import useCloudinaryUploader from '@components/shared/Cloudinary/useCloudinaryUploader';
import useCropperModal, {
  CropperModalState,
  OnSaveArgs,
  cropBoxDimensionPresets,
  warningDimensionPresets,
} from '@components/shared/Cropper/useCropperModal';
import EditableAsset from '@components/shared/EditableAsset/EditableAsset';
import AssetUploader from '@components/shared/EditableAsset/AssetUploader';
import AssetLocationPreviewTooltip from '@components/shared/Admin/AssetLocationPreviewTooltip/AssetLocationPreviewTooltip';
import AlternativeAssetSelector from './AlternativeAssetSelector/AlternativeAssetSelector';
import HiddenAssetPlaceholder from './HiddenAssetPlaceholder/HiddenAssetPlaceholder';
import DeleteDialog from './DeleteDialog/DeleteDialog';
import InheritingLandlordAssetNotice from './InheritingLandlordAssetNotice/InheritingLandlordAssetNotice';
import { hideAssetMenuItemOption, menuItemOptions } from './hideAssetMenuItemHelper';

export type BuildingLogoTypes = 'bannerImage' | 'lettermarkLogo';

type Props = {
  building: AdminBuilding;
  refetchBuilding: () => void;
};
type CropperModalCustomAttributes = {
  shouldUseLandlordImage: boolean;
};

const BrandedAssets = ({ building, refetchBuilding }: Props) => {
  const { t } = useTranslation('admin');

  const [uploadNewPhotoType, setUploadNewPhotoType] = useState<BuildingLogoTypes | null>(null);
  const [assetTypeBeingUpdated, setAssetTypeBeingUpdated] = useState<BuildingLogoTypes | null>(
    null,
  );
  const [deleteConfig, setDeleteConfig] = useState<{
    isOpen: boolean;
    assetType: BuildingLogoTypes | null;
  }>({ isOpen: false, assetType: null });

  const updateBuildingMutation = useMutation(
    async (updateParams: {
      bannerImage?: CloudinaryImageUpdateParams;
      lettermarkLogo?: CloudinaryImageUpdateParams;
      useLandlordLettermarkLogo?: boolean;
      useLandlordBannerImage?: boolean | null;
    }) => {
      await update(routes.api.admin.building(building?.id), updateParams);
    },
    {
      onSuccess: async () => {
        refetchBuilding();
        Notification.info({
          title: t('common:changesSaved'),
          placement: 'topRight',
        });
      },
    },
  );

  const {
    lettermarkLogo, // Lettermark that displays on building page (could be inherited from landlord).
    bannerImage, // Banner that displays on building page (could be inherited from landlord).
    landlordLettermarkLogo, // Landlord asset.
    landlordBannerImage, // Landlord asset.
    isUsingLandlordLettermarkLogo, // Is actively using landlord asset.
    isUsingLandlordBannerImage, // Is actively using landlord asset.
  } = building;

  const landlordLettermarkIsHidden = !!(
    lettermarkLogo === null &&
    landlordLettermarkLogo?.rawPath &&
    // False means building wants to hide landlord lettermark logo when possible.
    building.useLandlordLettermarkLogo === false
  );

  const landlordBannerIsHidden = !!(
    bannerImage === null &&
    landlordBannerImage?.rawPath &&
    building.landlord?.displayBannerOnBuildings &&
    // False means building wants to hide landlord banner image when possible.
    building.useLandlordBannerImage === false
  );

  const { CropperModal, cropperModalProps, openCropperModal } =
    useCropperModal<CropperModalCustomAttributes>();

  const onReady = () => async (response: CloudinaryResponse) => {
    cropperModalProps.updateState({ cloudinaryId: response.public_id });
  };

  const onFileUpload = (assetType: BuildingLogoTypes | null) => async (fileUrl: string) => {
    editBrandedAsset({
      assetType: assetType!,
      cloudinaryId: null,
      isNewUpload: true,
      imageSrc: fileUrl,
      ...(assetType === 'lettermarkLogo'
        ? {
            landlordBrandedAsset: landlordLettermarkLogo,
            isUsingLandlordBrandedAsset: isUsingLandlordLettermarkLogo,
          }
        : {
            landlordBrandedAsset: landlordBannerImage,
            isUsingLandlordBrandedAsset: isUsingLandlordBannerImage,
          }),
    });
  };

  const onSaveImageEdit =
    (assetType: BuildingLogoTypes) =>
    ({
      cloudinaryId,
      cropperCanvasData,
      cloudinaryTransformations,
      customAttributes,
    }: OnSaveArgs<CropperModalCustomAttributes>) => {
      const useLandlordImageParam =
        assetType === 'bannerImage' ? 'useLandlordBannerImage' : 'useLandlordLettermarkLogo';
      const params = customAttributes?.shouldUseLandlordImage
        ? // When the user chooses to inherit the landlord's branded asset.
          {
            [useLandlordImageParam]: true,
            // Delete the building's branded asset.
            [assetType]: { cloudinaryId: null },
          }
        : {
            // Otherwise the user is editing or uploading a branded asset.
            [assetType]: {
              cloudinaryId,
              cropperCanvasData,
              transformations: cloudinaryTransformations,
            },
          };
      updateBuildingMutation.mutate(params);
      setAssetTypeBeingUpdated(null);
    };

  const onDeleteConfirm = async () => {
    let mutateProperties = {};

    if (deleteConfig.assetType === 'bannerImage') {
      // On banner deletion, keep banner hidden if it was hidden previously,
      // otherwise default to landlord banner toggle.
      mutateProperties =
        building.useLandlordBannerImage === false ? {} : { useLandlordBannerImage: null };
    }

    updateBuildingMutation.mutate({
      [deleteConfig.assetType!]: {
        // Deletes branded asset based on assetType
        cloudinaryId: null,
      },
      // Additional mutations
      ...mutateProperties,
    });
    setDeleteConfig({ isOpen: false, assetType: null });
  };

  const onHideLandlordAsset = (assetType: BuildingLogoTypes) => {
    updateBuildingMutation.mutate(
      assetType === 'bannerImage'
        ? { useLandlordBannerImage: landlordBannerIsHidden }
        : { useLandlordLettermarkLogo: landlordLettermarkIsHidden },
    );
  };

  type EditImageArgs = {
    assetType: BuildingLogoTypes;
    cloudinaryId: string | null;
    cropperCanvasData?: CropperCanvasData | null;
    imageSrc: string | null;
    landlordBrandedAsset: MultipathImage | null;
    isUsingLandlordBrandedAsset: boolean;
    isNewUpload?: boolean;
  };

  const editBrandedAsset = ({
    assetType,
    cloudinaryId,
    cropperCanvasData,
    imageSrc,
    landlordBrandedAsset,
    isUsingLandlordBrandedAsset,
    isNewUpload = false,
  }: EditImageArgs) => {
    type Args = { shouldUseLandlordImage: boolean };
    type Return = Pick<
      CropperModalState,
      'customAttributes' | 'viewMode' | 'imageSrc' | 'landlordImageSrc'
    >;

    const getSourceRelatedAttributes = ({ shouldUseLandlordImage }: Args): Return => ({
      customAttributes: { shouldUseLandlordImage },
      viewMode: shouldUseLandlordImage ? 'simplePreview' : 'edit',
      landlordImageSrc: shouldUseLandlordImage ? landlordBrandedAsset?.rawPath : null,
    });

    const shouldUseLandlordImage = !isNewUpload && isUsingLandlordBrandedAsset;
    setAssetTypeBeingUpdated(assetType);
    openCropperModal({
      imageSrc: shouldUseLandlordImage ? null : imageSrc,
      cloudinaryId: shouldUseLandlordImage ? null : cloudinaryId,
      initialCanvasData: cropperCanvasData,
      cropBoxDimentions: cropBoxDimensionPresets[assetType],
      warningDimensions: warningDimensionPresets[assetType],
      alt: t(`building.brandedAssets.editModal.${assetType}.alt`),
      ...getSourceRelatedAttributes({ shouldUseLandlordImage }),
      afterUpload: (_state, updateState) =>
        // Unchecks the use landlord image checkbox in the edit modal after upload
        updateState(getSourceRelatedAttributes({ shouldUseLandlordImage: false })),
      renderAdditionalFooterContent:
        landlordBrandedAsset && !isUsingLandlordBrandedAsset
          ? ({ customAttributes }, updateState) => (
              <Checkbox
                isChecked={!!customAttributes?.shouldUseLandlordImage}
                label={t(`building.brandedAssets.editModal.${assetType}.inheritFromLandlord`)}
                onChange={e =>
                  updateState(getSourceRelatedAttributes({ shouldUseLandlordImage: e.value }))
                }
              />
            )
          : null,
    });
  };

  const createErrorNotification = (error: string = '') => {
    Notification.error({
      title: t('notification.updateError'),
      text: error,
    });
  };

  const {
    selectAndUploadToCloudinary,
    CloudinaryUploaderFileInput,
    cloudinaryUploaderFileInputProps,
  } = useCloudinaryUploader({
    uploadToVTS: false,
    onReady: onReady(),
    onFileUpload: onFileUpload(uploadNewPhotoType),
    onError: createErrorNotification,
  });

  const handleSelectUploadNewImage = (assetType: BuildingLogoTypes) => {
    setUploadNewPhotoType(assetType);
    if (selectAndUploadToCloudinary) selectAndUploadToCloudinary();
  };

  return (
    <>
      <div className="mt-3.5">
        <span className="font-title">{t('building.brandedAssets.logo')}</span>
      </div>
      <div className="mt-4 flex flex-col">
        <span className="font-subtitle">{t('building.brandedAssets.lettermarkLogo.title')}</span>
        <div className="flex flex-row">
          <span className="font-body-medium">
            {t('building.brandedAssets.lettermarkLogo.subtitle')}
          </span>
          <AssetLocationPreviewTooltip
            assetType="lettermarkLogo"
            page="building"
            previewImage="https://media.truva.com/assets/buildingpage-lettermark-logo-screenshot.png"
          />
        </div>
        {isUsingLandlordLettermarkLogo || landlordLettermarkIsHidden ? (
          <InheritingLandlordAssetNotice
            assetType="lettermarkLogo"
            isLandlordAssetHidden={landlordLettermarkIsHidden}
          />
        ) : null}
        <div className="pt-2" data-testid="lettermark-logo-display">
          {lettermarkLogo?.rawPath || landlordLettermarkIsHidden ? (
            <div className="flex flex-col">
              <EditableAsset
                imagePath={
                  lettermarkLogo?.rawPath ? lettermarkLogo.rawPath : landlordLettermarkLogo!.rawPath
                }
                imageClassName="!h-[110px] w-[110px] rounded-[3px] !border !border-solid !border-black-020 object-cover"
                altText={t('building.brandedAssets.lettermarkLogo.assetAltText')}
                editButtonClassName="relative ml-1 top-[46px] border border-solid border-black-035"
                editButtonName="lettermark-logo-edit-button"
                onUpload={() => handleSelectUploadNewImage('lettermarkLogo')}
                onDelete={() => setDeleteConfig({ isOpen: true, assetType: 'lettermarkLogo' })}
                onEdit={() =>
                  editBrandedAsset({
                    assetType: 'lettermarkLogo',
                    cloudinaryId: lettermarkLogo?.cloudinaryId!,
                    cropperCanvasData: lettermarkLogo?.cropperCanvasData,
                    imageSrc: null,
                    landlordBrandedAsset: landlordLettermarkLogo,
                    isUsingLandlordBrandedAsset: isUsingLandlordLettermarkLogo,
                  })
                }
                items={menuItemOptions(isUsingLandlordLettermarkLogo, landlordLettermarkIsHidden)}
                additionalMenuItems={hideAssetMenuItemOption({
                  isHidden: landlordLettermarkIsHidden,
                  assetType: 'lettermarkLogo',
                  onSelect: () => onHideLandlordAsset('lettermarkLogo'),
                  t,
                })}
                hiddenAssetProps={{
                  isAssetHidden: landlordLettermarkIsHidden,
                  hiddenAssetPlaceholder: (
                    <HiddenAssetPlaceholder
                      assetType="lettermarkLogo"
                      assetImage={landlordLettermarkLogo}
                    />
                  ),
                }}
              />
              <span className="pt-1 text-black-055 font-body-small">
                {t('building.brandedAssets.lettermarkLogo.imageSize')}
              </span>
            </div>
          ) : (
            <AssetUploader
              placeholderContainerClassName="flex flex-col items-center pl-[10px] pr-1"
              placeholderElementClassName="text-center font-body-medium"
              containerClassName="w-[110px] !h-[110px] !my-0"
              recommendedImageText={t('building.brandedAssets.lettermarkLogo.imageSize')}
              onReady={onReady()}
              onFileUpload={onFileUpload('lettermarkLogo')}
              onError={createErrorNotification}
              testId="lettermark-logo-uploader"
            />
          )}
        </div>
      </div>

      <div className="mt-6 h-[1px] w-full bg-black-010" />
      <div className="mt-6 flex flex-col">
        <span className="font-title">{t('building.brandedAssets.bannerImage.title')}</span>
        <div className="mt-4 flex flex-row">
          <span className="font-body-medium">
            {t('building.brandedAssets.bannerImage.subtitle')}
          </span>
          <AssetLocationPreviewTooltip
            assetType="bannerImage"
            page="building"
            previewImage="https://media.truva.com/assets/buildingpage-banner-screenshot.png"
          />
        </div>
        {isUsingLandlordBannerImage || landlordBannerIsHidden ? (
          <InheritingLandlordAssetNotice
            assetType="bannerImage"
            isLandlordAssetHidden={landlordBannerIsHidden}
          />
        ) : null}
        <div className="pb-3 pt-2" data-testid="banner-image-display">
          {bannerImage?.rawPath || landlordBannerIsHidden ? (
            <>
              <EditableAsset
                containerClassName="h-[139px] relative max-w-[1440px] w-full"
                menuPlacement="top-end"
                hiddenAssetProps={{
                  isAssetHidden: landlordBannerIsHidden,
                  hiddenAssetPlaceholder: (
                    <HiddenAssetPlaceholder
                      assetType="bannerImage"
                      assetImage={landlordBannerImage}
                    />
                  ),
                }}
                imagePath={
                  bannerImage?.rawPath ? bannerImage.rawPath : landlordBannerImage!.rawPath
                }
                imageClassName="!h-[139px] w-[1440px] rounded-[3px] !border !border-solid !border-black-020 object-cover"
                altText={t('building.brandedAssets.bannerImage.assetAltText')}
                editButtonClassName="absolute bottom-1 right-1 border-black-035 border border-solid"
                editButtonName="banner-edit-button"
                onEdit={() =>
                  editBrandedAsset({
                    assetType: 'bannerImage',
                    cloudinaryId: bannerImage?.cloudinaryId!,
                    cropperCanvasData: bannerImage?.cropperCanvasData,
                    imageSrc: null,
                    landlordBrandedAsset: landlordBannerImage,
                    isUsingLandlordBrandedAsset: isUsingLandlordBannerImage,
                  })
                }
                onDelete={() => setDeleteConfig({ isOpen: true, assetType: 'bannerImage' })}
                onUpload={() => handleSelectUploadNewImage('bannerImage')}
                items={menuItemOptions(isUsingLandlordBannerImage, landlordBannerIsHidden)}
                additionalMenuItems={hideAssetMenuItemOption({
                  isHidden: landlordBannerIsHidden,
                  assetType: 'bannerImage',
                  onSelect: () => onHideLandlordAsset('bannerImage'),
                  t,
                })}
              />
              <span className="flex pt-1 text-black-055 font-body-small">
                {t('building.brandedAssets.bannerImage.imageSize')}
              </span>
            </>
          ) : (
            <>
              <AssetUploader
                placeholderElementClassName="pl-1 font-body-medium"
                onReady={onReady()}
                onFileUpload={onFileUpload('bannerImage')}
                onError={createErrorNotification}
                containerClassName="!h-[139px] !my-0 w-full max-w-[1440px]"
                recommendedImageText={t('building.brandedAssets.bannerImage.imageSize')}
                testId="banner-uploader"
                assetSelectorModalProps={
                  landlordBannerImage
                    ? {
                        title: t('building.brandedAssets.addModal.bannerImage.addImage'),
                        alternativeAssetSelection: (
                          <AlternativeAssetSelector
                            assetImage={landlordBannerImage}
                            onUpdate={params => updateBuildingMutation.mutate(params)}
                          />
                        ),
                      }
                    : undefined
                }
              />
            </>
          )}
        </div>
      </div>
      {deleteConfig.isOpen && (
        <DeleteDialog
          isDeleteOpen={deleteConfig.isOpen}
          assetType={deleteConfig.assetType!}
          onClose={() => setDeleteConfig({ isOpen: false, assetType: null })}
          onConfirm={onDeleteConfirm}
        />
      )}
      <CloudinaryUploaderFileInput {...cloudinaryUploaderFileInputProps} />
      {assetTypeBeingUpdated && (
        <CropperModal
          {...cropperModalProps}
          title={t(`building.brandedAssets.editModal.${assetTypeBeingUpdated}.title`)}
          onSave={onSaveImageEdit(assetTypeBeingUpdated)}
        />
      )}
    </>
  );
};

export default BrandedAssets;
