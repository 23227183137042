import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from '@root/routes';
import actions from '@store/actions/navigation';
import { StoreState } from '@root/types';
import LogoSvg from '@components/shared/Logo';
import s from '@components/shared/Logo/Logo.module.less';
import cn from 'classnames';

const mapState = ({ currentUser }: StoreState) => ({
  currentUser,
});

const mapDispatch = {
  navLogoClicked: () => actions.navLogoClicked(),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const Logo = ({ currentUser = null, navLogoClicked }: ReduxProps) => {
  const loggedOut = !currentUser;

  return (
    <div className={cn(s.logoNavigationLink, loggedOut && s.loggedOut, 'vts-marketplace-logo')}>
      {currentUser ? (
        <Link
          data-testid="homeLink"
          className={s.redesignLogo}
          to={routes.root}
          onClick={navLogoClicked}
        >
          <LogoSvg />
        </Link>
      ) : (
        <div className={cn(s.redesignLogo, loggedOut && s.loggedOut)}>
          <LogoSvg mode={loggedOut ? 'short-black' : 'white'} />
        </div>
      )}
    </div>
  );
};

export default connector(Logo);
