import { latLngBounds } from '@shared/googleMaps';

const NUMBER_OF_LEVELS_TO_ZOOM = 1;

class Zoom {
  static fitToMapCoordinates = (coordinateString: string | null, map: google.maps.Map) => {
    if (!coordinateString) return;
    const coordinates = coordinateString.split(',');
    const southWest = {
      lat: Number(coordinates[0]),
      lng: Number(coordinates[1]),
    };
    const northEast = {
      lat: Number(coordinates[2]),
      lng: Number(coordinates[3]),
    };
    const bounds = latLngBounds(southWest, northEast);
    map.fitBounds(bounds);
  };

  static setCenter = (lat: number, lng: number, map: google.maps.Map, zoom: number = 9) => {
    map.setCenter({ lat, lng });
    map.setZoom(zoom);
  };

  static zoomIn = (map: google.maps.Map) => {
    const currentZoom = map.getZoom();
    if (currentZoom === undefined) return;
    map.setZoom(currentZoom + NUMBER_OF_LEVELS_TO_ZOOM);
  };

  static zoomOut = (map: google.maps.Map) => {
    const currentZoom = map.getZoom();
    if (currentZoom === undefined) return;
    map.setZoom(currentZoom - NUMBER_OF_LEVELS_TO_ZOOM);
  };
}

export default Zoom;
