import { Link } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbItem } from '@components/shared/Breadcrumbs';

type Props = {
  title: string;
  items: Record<string, { label: string; value: string }[]>;
  breadcrumbs: BreadcrumbItem[];
};

export default function AlphabeticalList({ items, title, breadcrumbs }: Props) {
  return (
    <div className="min-h-[700px] mobile:pt-1">
      <Breadcrumbs items={breadcrumbs} />
      <h1 className="mb-2 mt-1 font-headline">{title}</h1>
      <ul className="flex flex-wrap gap-x-2">
        {Object.keys(items).map(letter => (
          <li
            key={letter}
            className="my-1 w-[calc(25%_-_16px)] tablet:w-[calc(50%_-_16px)] mobile:w-full"
            data-testid={`letter-${letter}`}
          >
            <h2 className="mb-1 font-title">{letter}</h2>
            <ul>
              {items[letter].map(item => (
                <li className="font-body-large" key={item.value}>
                  <Link to={item.value}>{item.label}</Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}
