import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash';
import { MultipathImage } from '@root/types';
import useAltText from '@root/shared/useAltText';
import s from './ClientInformation.module.less';

type Props = {
  name: string | null;
  image: MultipathImage | null;
};

export default function Description({ name, image }: Props) {
  const { t } = useTranslation('tourbook');
  const { getAltTextForTourbookClientCompanyLogo } = useAltText();
  if (isNull(name) && isNull(image)) return null;

  return (
    <div className={s.clientInformation}>
      <div className="font-body-medium-emphasis mobile:font-body-small-emphasis">
        {t('header.clientLabel', { name })}
      </div>
      {image && (
        <img
          className={s.clientLogo}
          src={image.smallPath}
          alt={getAltTextForTourbookClientCompanyLogo(name)}
        />
      )}
    </div>
  );
}
