import routes from '@root/routes';
import { useQuery } from '@tanstack/react-query';
import { get } from '@root/shared/typedApi';
import { SearchListing } from '@root/types';

const useFavoritedListingsQuery = () => {
  const { data: favoritedListingsData, isLoading: isFavoritedListingsLoading } = useQuery(
    [routes.api.favoritedListings],
    () => get<SearchListing[]>(routes.api.favoritedListings),
  );

  const isListingSaved = (listingId: string) => {
    return !isFavoritedListingsLoading && favoritedListingsData
      ? favoritedListingsData.some(listing => listing.id === listingId)
      : false;
  };

  return {
    favoritedListings: favoritedListingsData,
    isListingSaved,
  };
};

export default useFavoritedListingsQuery;
