import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import utils from '@styles/contentWidth.module.less';
import VideoPlayer from '@components/shared/VideoPlayer';
import { Video, VirtualTourSpotlights } from '@root/types';
import { SourceContent, SourcePage } from '@root/store/actions/videoAnalytics';
import useIFrameInteractionTracking from '@components/shared/useIFrameInteractionTracking';
import { useEffect, useRef } from 'react';
import s from './VideoTour.module.less';

export type Props = {
  video?: Video | null;
  tourEmbedUrl?: string | null;
  sourceContent: SourceContent;
  sourcePage: SourcePage;
  virtualTourSpotlight: VirtualTourSpotlights;
  subtitle?: boolean;
  isVisible?: boolean;
};

const shouldShould3dTour = ({
  tourEmbedUrl,
  video,
  virtualTourSpotlight,
}: {
  video?: Video | null;
  tourEmbedUrl?: string | null;
  virtualTourSpotlight: VirtualTourSpotlights;
}): boolean => {
  if (video && tourEmbedUrl && virtualTourSpotlight === 'three_d_tour') return true;
  if (tourEmbedUrl && !video) return true;

  return false;
};

const VideoTour = ({
  video,
  tourEmbedUrl,
  sourceContent,
  sourcePage,
  subtitle = true,
  virtualTourSpotlight,
  isVisible = true,
}: Props) => {
  const { t } = useTranslation('listing');
  useIFrameInteractionTracking({
    shouldTrack: tourEmbedUrl && (video || tourEmbedUrl),
    sourceContent,
    sourcePage,
    iFrameId: '3dTourVideoTour',
  });

  const playerRef = useRef<any>();

  useEffect(() => {
    if (!isVisible && playerRef?.current?.pause) playerRef.current.pause();
  }, [isVisible]);

  if (!video && !tourEmbedUrl) return null;

  return (
    <div className={s.container}>
      <div>
        <div className={s.titleContainer} data-qa-testid="virtual-tour-heading">
          <h3 className={s.title}>{t('videoTour.title')}</h3>

          {subtitle ? (
            <div className={s.subtitle}>
              <p>{t('videoTour.subtitle')}</p>
            </div>
          ) : null}
        </div>
        <div data-qa-testid="virtual-tour-media">
          {shouldShould3dTour({ tourEmbedUrl, video, virtualTourSpotlight }) ? (
            <iframe
              src={tourEmbedUrl!}
              title="3D Tour"
              id="3dTourVideoTour"
              data-testid="VideoTour3dTour"
              className={cn(utils.contentContainer, s.tour)}
            ></iframe>
          ) : (
            <VideoPlayer
              video={video as Video}
              className={cn(utils.contentContainer, s.video)}
              sourcePage={sourcePage}
              sourceContent={sourceContent}
              testId="spaceVideo"
              playerRef={playerRef}
            />
          )}
        </div>
        {/* eslint-enable no-nested-ternary */}
      </div>
    </div>
  );
};

export default VideoTour;
