import routes from '@root/routes';
import { Listing } from '@root/types';
import { useTranslation } from 'react-i18next';
import PageHead from '@components/shared/PageHead/PageHead';

type Props = {
  listing: Listing;
  isPreview?: boolean;
};

const getListingPreviewTitleT = (listing: Listing): string => {
  const hasNeighborhood = !!listing.building.neighborhood;
  if (hasNeighborhood) {
    return 'meta.previewTitle.withNeighborhood';
  }

  return 'meta.previewTitle.withoutNeighborhood';
};

const getListingPreviewDescriptionT = (listing: Listing): string => {
  if (listing.availableToday) {
    return 'meta.previewDescription.availableNow';
  }

  return 'meta.previewDescription.availableOnDate';
};

const ListingHead = ({ listing, isPreview = false }: Props) => {
  const { t } = useTranslation('listing');

  const {
    building: { photo: buildingPhoto },
    photos: listingPhotos,
    id,
    buildingSlug,
  } = listing;

  const title = t('meta.title', {
    floorSuite: listing.address.floorAndSuite,
    address: listing.address.street,
  });

  const description = t('meta.description', {
    size: listing.area,
    address: listing.address.street,
    floorSuite: listing.address.floorAndSuite,
    city: listing.address.city,
    abbreviatedRegion: listing.address.abbreviatedRegion,
  });

  const previewTitle = t(getListingPreviewTitleT(listing), {
    address: listing.address.street,
    floorSuite: listing.address.floorAndSuite,
    neighborhood: listing.building.neighborhood,
    cityState: t('common:cityState', { address: listing.address }),
  });
  const previewDescription = t(getListingPreviewDescriptionT(listing), {
    size: listing.area,
    availabilityDate: listing.dateAvailable,
  });
  const previewImageUrl = listingPhotos[0] ? listingPhotos[0].path : buildingPhoto?.path;

  const canonicalURL = routes.listing(id, buildingSlug);

  return (
    <PageHead
      title={title}
      description={description}
      previewTitle={previewTitle}
      previewDescription={previewDescription}
      previewImageUrl={previewImageUrl}
      canonicalURL={canonicalURL}
      removeFromSEOIndex={!!listing.building.isDemo || isPreview}
    />
  );
};

export default ListingHead;
