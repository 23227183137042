import { IconButton, InvisibleMask, PopperTooltip } from '@components/shared';
import { RawSelect } from '@components/shared/forms';
import { TourbookListing } from '@root/types';
import { memo, useState } from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { PARAMETERS } from '@root/tracking/constants';
import cn from 'classnames';
import ListingItem from './ListingItem';
import s from './TableOfContentsDropdown.module.less';

export type Props = {
  listings: TourbookListing[];
  readOnly?: boolean;
};

const TableOfContentsDropdown = ({ listings, readOnly = false }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('tourbook');

  const listingsToRender = readOnly ? listings.filter(l => !l.flaggedAt) : listings;

  const isDisabled = listingsToRender.length === 0;

  const options = listingsToRender.map((listing, i) => ({
    value: i,
    label: listing.id,
  }));

  return (
    <>
      <RawSelect
        className={s.tourbookDropdown}
        name="tourbook-contents-dropdown"
        options={options}
        onChange={() => {}}
        menuIsOpen={menuIsOpen}
        components={{
          Option: props => (
            <components.Option {...props}>
              <div
                onClick={() => {
                  setMenuIsOpen(false);
                }}
                role="button"
              >
                <ListingItem
                  listing={listingsToRender[props.value]}
                  sourceContent={PARAMETERS.tableOfContentsDropdown}
                  withinDropdown
                />
              </div>
            </components.Option>
          ),
          SelectContainer: props => (
            <components.SelectContainer {...props}>
              <PopperTooltip
                trigger="hover"
                toolTipPlacement="bottom-start"
                triggerElement={
                  <IconButton
                    disabled={isDisabled}
                    icon="list"
                    className={cn(s.dropdownButton, menuIsOpen && s.focus)}
                    onClick={() => {
                      setMenuIsOpen(!menuIsOpen);
                    }}
                  />
                }
                popperContentClassName={cn(isDisabled && 'w-[229px] text-center')}
                popperElementClassName={s.tooltipText}
                popperElement={
                  isDisabled ? t('tableOfContentsDisabledTooltip') : t('tableOfContents')
                }
              />
              {props.children}
            </components.SelectContainer>
          ),
          Control: () => null,
        }}
        styles={{
          menuList: provided => ({
            ...provided,
            maxHeight: '315px',
          }),
          menu: provided => ({
            ...provided,
            width: 'min(730px, calc(100vw - 32px))',
            '@media only screen and (max-width: 767px)': {
              marginLeft: '-8px',
            },
          }),
          option: provided => ({
            ...provided,
            padding: 0,
            borderBottom: '1px solid #E5E5E5',
            color: 'inherit',
            backgroundColor: 'inherit',
          }),
          container: provided => ({
            ...provided,
            marginRight: '8px',
          }),
        }}
      />
      {menuIsOpen && <InvisibleMask onClick={() => setMenuIsOpen(false)} />}
    </>
  );
};

export default memo(TableOfContentsDropdown);
