import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Select } from '@components/shared/forms';
import Input from './Input';
import {
  COUNTRY_CODES_THAT_DONT_REQUIRE_POSTAL_CODE,
  COUNTRY_CODES_THAT_DONT_REQUIRE_ABBREVIATED_REGION,
} from './validationSchema';
import css from './styles.module.less';

const AvailableCountryCodes = ['US', 'GB', 'CA'];

const Location = ({ building, neighborhoodId, countryCode }) => {
  const { t } = useTranslation('admin');
  const flags = useFlags();
  const availableCountryCodes = flags['market-office.add-united-arab-emirates']
    ? [...AvailableCountryCodes, 'AE']
    : AvailableCountryCodes;

  return (
    <section>
      <h2>{t('building.location.title')}</h2>
      <Input labelText={t('building.street')} name="street" required />
      <div className={css.sideBySide}>
        <Input containerClass={css.left} labelText={t('building.city')} name="locality" required />
        <Input
          containerClass={css.right}
          labelText={t('building.stateRegion')}
          name="abbreviatedRegion"
          required={
            !COUNTRY_CODES_THAT_DONT_REQUIRE_ABBREVIATED_REGION.includes(countryCode?.toLowerCase())
          }
        />
      </div>
      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={t('building.postalCode')}
          name="postalCode"
          required={
            !COUNTRY_CODES_THAT_DONT_REQUIRE_POSTAL_CODE.includes(countryCode?.toLowerCase())
          }
        />
        <Select
          containerClass={css.right}
          className={css.select}
          data-testid="countryCode"
          labelText={t('building.countryCode')}
          name="countryCode"
          options={availableCountryCodes.map(value => ({
            value,
            label: t(`country:${value}`),
          }))}
          value={countryCode}
          isClearable={false}
          required
        />
      </div>
      <Input labelText={t('building.crossStreets')} name="crossStreets" />
      <Select
        className={css.select}
        data-testid="neighborhood"
        labelText={t('building.neighborhood')}
        name="neighborhoodId"
        options={building.shapes.map(shape => ({
          label: shape.name,
          value: shape.id,
        }))}
        placeholder=""
        value={neighborhoodId}
      />

      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={t('building.latitude')}
          name="latitude"
          required
        />
        <Input
          containerClass={css.right}
          labelText={t('building.longitude')}
          name="longitude"
          required
        />
      </div>
      <Input labelText={t('building.formattedAddress')} name="formattedAddress" disabled required />
    </section>
  );
};

export default memo(Location);
