import { memo } from 'react';
import type { AdminBuilding, BrochureTemplates } from '@root/types';
import { BrochureAppendPrepend } from '../BrochureAppendPrepend';
import ChannelsWebsite from './Website/Website';
import ChannelsMicrosite from './Microsite/Microsite';
import ChannelsSiteplan from './Siteplan/Siteplan';
import ChannelsBrochure from './Brochure/Brochure';

export type ChannelsProps = {
  websiteStatus: string;
  brochureStatus: string;
  micrositeStatus: string;
  selectedBrochureTemplate: string;
  brochureTemplates: BrochureTemplates;
  websitePreviewLink: string;
  micrositePreviewLink: string;
  building: AdminBuilding;
};

const Channels = ({
  websiteStatus,
  brochureStatus,
  micrositeStatus,
  selectedBrochureTemplate,
  brochureTemplates,
  websitePreviewLink,
  micrositePreviewLink,
  building,
}: ChannelsProps) => {
  return (
    <section>
      <ChannelsWebsite
        websiteStatus={websiteStatus}
        websitePreviewLink={websitePreviewLink}
        building={building}
      />

      <ChannelsMicrosite
        building={building}
        status={micrositeStatus}
        previewLink={micrositePreviewLink}
      />

      <ChannelsBrochure
        building={building}
        brochureTemplates={brochureTemplates}
        selectedBrochureTemplate={selectedBrochureTemplate}
        status={brochureStatus}
      />

      <BrochureAppendPrepend building={building} />

      <ChannelsSiteplan building={building} />
    </section>
  );
};

export default memo(Channels);
