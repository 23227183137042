import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import addKeyTo from '@shared/addKeyTo';
import { AdminBuildingVideo, AdminVideo as AdminVideoType, AdminListingVideo } from '@root/types';
import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import IconButton from '@components/shared/IconButton';
import CustomIcon from '@components/shared/CustomIcon';
import AdminTable from '@components/shared/AdminTable';
import css from './VideoSection.module.less';

type AdminVideo = AdminListingVideo | AdminBuildingVideo | AdminVideoType;

export type VideoTableProps = {
  archivedVideos: AdminVideo[];
  renderVideoFile: (asset: any) => any;
  videoTableTitle: string;
  videoSelectText: string;
  onVideoDelete: (videoId?: string) => Promise<void>;
  onVideoSelect: (videoId: string) => Promise<void>;
};

const CollapsibleVideoSection = ({
  archivedVideos,
  renderVideoFile,
  videoTableTitle,
  videoSelectText,
  onVideoDelete,
  onVideoSelect,
}: VideoTableProps) => {
  const { t } = useTranslation('admin');

  const [videosTableCollapsed, setSidebarIsCollapsed] = useState(false);

  const toggleSidebar = useCallback(
    () => setSidebarIsCollapsed(!videosTableCollapsed),
    [videosTableCollapsed],
  );

  const tableData = () => (archivedVideos ? addKeyTo(archivedVideos) : []);

  const selectMenuItem = (row: any) => {
    return (
      <OverflowMenuItem className={css.overflowMenuItem} onClick={() => onVideoSelect(row.id)}>
        <>
          <CustomIcon type="eye"></CustomIcon>
          {videoSelectText}
        </>
      </OverflowMenuItem>
    );
  };

  const downloadMenuItem = (row: any) => {
    return (
      <OverflowMenuItem className={css.overflowMenuItem} onClick={() => {}}>
        <a className={css.downloadLink} href={row.downloadPath} download>
          <CustomIcon type="download"></CustomIcon>
          {t('media.downloadVideo')}
        </a>
      </OverflowMenuItem>
    );
  };

  const deleteMenuItem = (row: any) => {
    return (
      <OverflowMenuItem className={css.overflowMenuItem} onClick={() => onVideoDelete(row.id)}>
        <>
          <CustomIcon type="trash"></CustomIcon>
          {t('media.deleteVideo')}
        </>
      </OverflowMenuItem>
    );
  };

  const columns = () => [
    {
      title: t('media.videoTableHeader.filename'),
      width: '18%',
      render: asset => {
        return renderVideoFile(asset);
      },
    },
    {
      title: t('media.videoTableHeader.thumbnailPreview'),
      width: '72%',
      render: ({ thumbnailPosterPath }: AdminVideo) => {
        return (
          <figure className={css.imageContainer}>
            <img alt="videoThumbnail" src={thumbnailPosterPath} />
          </figure>
        );
      },
    },
    {
      title: '',
      width: '10%',
      render: (row: any) => (
        <OverflowMenu overflowClassName={css.overflowMenu}>
          {selectMenuItem(row)}
          {downloadMenuItem(row)}
          {deleteMenuItem(row)}
        </OverflowMenu>
      ),
    },
  ];

  return (
    <>
      <div className={css.collapsibleHeader}>
        <IconButton
          className={css.expandCollapseSidebarButton}
          icon={videosTableCollapsed ? 'chevron-down' : 'chevron-right'}
          data-testid="expand-collapse-sidebar-button"
          onClick={toggleSidebar}
        />
        <div className="inline font-title">{videoTableTitle}</div>
      </div>
      {videosTableCollapsed ? (
        <AdminTable
          className={cn(css.adminTableStyles, css.collapsibleAdminTableStyle)}
          rowClassName={css.row}
          columns={columns()}
          dataSource={tableData()}
        />
      ) : null}
    </>
  );
};

export default CollapsibleVideoSection;
