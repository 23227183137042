import React from 'react';
import { Breadcrumbs } from '@components/shared';
import useDownloadTourbook from '@shared/useDownloadTourbook';
import { object, SchemaOf, string } from 'yup';
import { Form, TextInput, SubmitButton } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import Layout from '../Layout/Layout';

type FormValues = {
  tourbookId: string;
};

export default function TourbookExportTool() {
  const { t } = useTranslation('admin');
  const downloadTourbook = useDownloadTourbook();

  const schema: SchemaOf<FormValues> = object().shape({
    tourbookId: string().required(),
  });

  const onSubmit = async (values, helpers) => {
    await downloadTourbook({
      tourbookId: values.tourbookId,
      onFinish: () => helpers.resetForm(),
      onFailure: () =>
        helpers.setFieldError(
          'tourbookId',
          'There is no tourbook with that id or something went wrong trying to fetch it',
        ),
    });
  };

  return (
    <Layout>
      <Breadcrumbs
        className="mb-1"
        items={[{ content: t('breadcrumbs.admin') }, { content: t('breadcrumbs.tourbookExport') }]}
      />
      <div>
        <Form<FormValues>
          initialValues={{ tourbookId: '' }}
          validationSchema={schema}
          id="tourbookExport"
          onSubmit={onSubmit}
        >
          <TextInput autoComplete={false} name="tourbookId" labelText="Tourbook Id" />
          {/* FIXME: Remove this line because its a <Trans /> or remove this literal */}
          {/* eslint-disable-next-line react/jsx-no-literals */}
          <SubmitButton>Submit</SubmitButton>
        </Form>
      </div>
    </Layout>
  );
}
