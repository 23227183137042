import api from '@shared/api';
import routes from '@root/routes';
import { Action, StoreState, TourbookSummary } from '@root/types';
import { ThunkAction } from 'redux-thunk';

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};
const ignoreErrors = () => {};

type ReceiveTourbooksAction = {
  type: 'RECEIVE_FETCHED_TOURBOOKS';
  payload: {
    tourbooks: TourbookSummary[];
  };
};

export const receiveTourbookSummaries = (tourbooks: TourbookSummary[]): ReceiveTourbooksAction => ({
  type: 'RECEIVE_FETCHED_TOURBOOKS',
  payload: {
    tourbooks,
  },
});

type TourbooksLoadingAction = {
  type: 'TOURBOOKS_LOADED';
};

export const tourbooksLoaded = (): TourbooksLoadingAction => ({
  type: 'TOURBOOKS_LOADED',
});

export const fetchTourbookSummaries =
  (sort = 'newest') =>
  dispatch => {
    api
      .fetch(routes.api.tourbooks(sort))
      .then(handleErrors)
      .then(response => response.json())
      .then((tourbooks: TourbookSummary[]) => {
        dispatch(receiveTourbookSummaries(tourbooks));
        dispatch(tourbooksLoaded());
      })
      .catch(ignoreErrors);
  };

export const fetchTourbookSummariesThunk =
  (sort = 'newest'): ThunkAction<void, StoreState, unknown, Action> =>
  dispatch => {
    fetchTourbookSummaries(sort)(dispatch);
  };

export type Actions = ReceiveTourbooksAction | TourbooksLoadingAction;
