import { ReactNode } from 'react';
import { Tooltip, InfoIcon } from '@viewthespace/components';
import cn from 'classnames';

type Props = {
  tooltipText: ReactNode;
  'data-testid'?: string;
  className?: string;
};

export default function InfoTooltip({ tooltipText, 'data-testid': dataTestId, className }: Props) {
  return (
    <Tooltip
      className="z-[100]"
      content={tooltipText}
      triggerAction="hover"
      placement="top"
      trigger={
        <span data-testid={dataTestId}>
          <InfoIcon className={cn('text-cta-primary-default', className)} />
        </span>
      }
    />
  );
}
