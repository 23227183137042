import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import actions from '@store/actions/tourbookPage';
import { useTranslation } from 'react-i18next';
import useAnalytics from '@shared/useAnalytics';
import useEnv from '@shared/useEnv';
import { addListener, loadMap, createMap, getMapOptions, latLngBounds } from '@shared/googleMaps';
import Markers from '@shared/map/Markers';
import HoveredListingIndicator from '@shared/map/HoveredListingIndicator';
import { CustomIcon } from '@components/shared';
import Zoom from '@root/shared/map/Zoom/Zoom';
import BuildingInfoWindow from '@root/shared/map/BuildingInfoWindow';
import { BuildingMapPoint, TourbookListing, InfoWindowListing, Tourbook } from '@root/types';
import {
  buildingViewedOnMap,
  mapDeclustered,
  mapZoomed,
  clickToScrollToEntry,
} from '@root/shared/map/AnalyticsProps';
import { pick } from 'lodash/fp';
import cn from 'classnames';
import s from './TourbookMap.module.less';
import { scrollToTourbookListingCard } from '../helper';

type Props = {
  tourbook: Tourbook;
  hoveredListing: TourbookListing | null;
  className?: any;
  maxHeight?: number;
  onInfoWindowClicked?: () => void;
};

type BuildingMapGroup = {
  latitude: number;
  longitude: number;
  listings: InfoWindowListing[];
};

const TourbookMap = ({
  tourbook,
  hoveredListing,
  className = {},
  maxHeight,
  onInfoWindowClicked,
}: Props) => {
  const { t } = useTranslation('tourbook');
  const { googleMapsKey: key } = useEnv();
  const dispatch = useDispatch();
  const { mapInteraction, PARAMETERS } = useAnalytics();
  const [useAutofocus, setAutofocus] = useState(false);
  const [height, setHeight] = useState({});
  const mapRef = useRef<HTMLDivElement>(null);
  const mapObj = useRef() as { current: google.maps.Map | null };
  const markers = useRef() as { current: Markers | null };
  const hoveredListingIndicator = useRef() as { current: HoveredListingIndicator };
  const listings = useRef() as { current: TourbookListing[] | null };
  const buildingMapGroup = useRef() as { current: Map<string, BuildingMapGroup> | null };
  const buildingInfoWindow = useRef() as { current: BuildingInfoWindow | null };

  const trackBuildingViewedOnMap = () => {
    mapInteraction(buildingViewedOnMap(PARAMETERS.tourbookPage));
  };
  const trackMapDeclustered = () => {
    mapInteraction(mapDeclustered(PARAMETERS.tourbookPage));
  };
  const trackMapZoomed = () => {
    mapInteraction(mapZoomed(PARAMETERS.tourbookPage));
  };
  const trackMapClickInfoWindowListing = () => {
    mapInteraction(clickToScrollToEntry(PARAMETERS.tourbookPage));
  };

  const trackers = {
    trackBuildingViewedOnMap,
    trackMapClickInfoWindowListing,
    trackMapDeclustered,
  };

  useEffect(() => {
    setHeight({});
    if (maxHeight && maxHeight < (mapRef.current?.clientHeight || 0))
      setHeight({ height: `${maxHeight}px` });
  }, [mapRef, maxHeight]);

  const zoomToPoints = () => {
    const bounds = latLngBounds() as google.maps.LatLngBounds;
    markers.current?.getMarkers().forEach(marker => {
      const position = marker.getPosition();
      if (position) bounds?.extend(position);
    });
    mapObj.current?.fitBounds(bounds);
  };

  const buildBuildingMapGroups = (): Map<string, BuildingMapGroup> | null => {
    if (!listings.current) return null;

    const buildings = new Map<string, BuildingMapGroup>();

    listings.current!.forEach((listing: TourbookListing) => {
      const locationKey = `${listing.longitude}+${listing.latitude}`;

      const partialListing = pick(
        ['address', 'isCurrentlyExclusive', 'name', 'size', 'smallestPrice'],
        listing,
      );

      const infoWindowlisting: InfoWindowListing = {
        ...partialListing,
        photo: listing.photos[0],
        onClick: () => {
          onInfoWindowClicked?.();
          closeInfoWindow();
          scrollToTourbookListingCard({ listingId: listing.id });
        },
      };

      if (buildings.has(locationKey)) {
        const buildingEntry = buildings.get(locationKey);
        buildingEntry!.listings.push(infoWindowlisting);
      } else {
        buildings.set(locationKey, {
          latitude: listing.latitude!,
          longitude: listing.longitude!,
          listings: [infoWindowlisting],
        });
      }
    });

    return buildings;
  };

  const buildingMapPoints = (): BuildingMapPoint[] => {
    if (!buildingMapGroup.current) return [];
    // Build the array of BuildingMapPoint using buildingMapGroup
    const mapPoints = Array.from(buildingMapGroup.current!.values()).map(buildingGroup => {
      return {
        latitude: buildingGroup.latitude,
        longitude: buildingGroup.longitude,
        count: buildingGroup.listings.length,
        exclusive: buildingGroup.listings.some(listing => listing.isCurrentlyExclusive),
      };
    });

    return mapPoints;
  };

  const updateMarkers = (reZoom = true) => {
    if (listings.current && buildingMapGroup.current) {
      markers.current?.createMarkers(buildingMapPoints(), mapObj.current, []);
      if (!reZoom) return;
      zoomToPoints();
    }
  };

  const handleHover = () => {
    if (!mapObj.current) return;

    if (hoveredListing) {
      hoveredListingIndicator.current.addHoveredListingMarker(hoveredListing, mapObj.current);
    } else {
      hoveredListingIndicator.current.clearHoveredListingMarker();
    }
  };

  const handleZoomInClicked = () => {
    if (!mapObj.current) return;
    Zoom.zoomIn(mapObj.current);
    trackMapZoomed();
  };

  const handleZoomOutClicked = () => {
    if (!mapObj.current) return;
    Zoom.zoomOut(mapObj.current);
    trackMapZoomed();
  };

  const fetchBuildingMapMarkers = async (
    building: BuildingMapPoint,
  ): Promise<InfoWindowListing[]> => {
    const locationKey = `${building.longitude}+${building.latitude}`;
    const buildingEntry = buildingMapGroup.current!.get(locationKey);

    return buildingEntry!.listings;
  };

  useEffect(() => {
    loadMap(key!, () => {
      if (mapObj.current && markers.current) {
        listings.current = tourbook.listings.filter(listing => !listing.flaggedAt);
        buildingMapGroup.current = buildBuildingMapGroups();
        updateMarkers();
      } else {
        if (!mapRef.current) return;
        // create map
        const startingPoint = { long: -73.99158, lat: 40.740038 };

        listings.current = tourbook.listings.filter(listing => !listing.flaggedAt);
        buildingMapGroup.current = buildBuildingMapGroups();

        buildingInfoWindow.current = new BuildingInfoWindow({
          translate: t,
          trackers,
          onHoverMask: true,
        });

        mapObj.current = createMap(
          mapRef.current,
          getMapOptions({
            clickableIcons: false,
            draggableCursor: 'default',
            draggingCursor: 'default',
            zoom: 13,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            scaleControl: true,
            zoomControl: false,
            ...startingPoint,
            gestureHandling: 'auto',
          }),
        );

        // dom dependent objects
        markers.current = new Markers({
          isClustered: true,
          trackers,
          buildingInfoWindow: buildingInfoWindow.current,
          fetchBuildingMapMarkers,
          isEventHandlersEnabled: true,
          hideNumbers: true,
        });

        hoveredListingIndicator.current = new HoveredListingIndicator({
          mapMarkerText: t('common:map.markerText'),
          disableAutoPan: true,
          enableZoomToPoint: useAutofocus,
        });

        updateMarkers();
        addMapHandlers();
      }
    });
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourbook]);

  useEffect(() => {
    if (!mapObj?.current?.data) return;

    hoveredListingIndicator.current.clearHoveredListingMarker();
    hoveredListingIndicator.current = new HoveredListingIndicator({
      mapMarkerText: t('common:map.markerText'),
      disableAutoPan: true,
      enableZoomToPoint: useAutofocus,
      shouldUseSearchRedesignMarkerStyles: true,
      hideNumbers: true,
    });
    mapObj.current.setOptions({ gestureHandling: useAutofocus ? 'none' : 'auto' });

    updateMarkers(false);
    handleHover();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useAutofocus]);

  useEffect(handleHover, [hoveredListing]);

  const toggleAutofocus = () => {
    setAutofocus(!useAutofocus);
    dispatch(actions.mapAutofocusToggled(useAutofocus));
  };

  const resetAutofocus = () => {
    setAutofocus(false);
    closeInfoWindow();
    zoomToPoints();
  };

  const closeInfoWindow = () => {
    markers.current?.removeActiveMarker();
    if (buildingInfoWindow.current) {
      buildingInfoWindow.current.close();
    }
  };
  const addMapHandlers = () => {
    addListener(mapObj.current, 'click', () => {
      closeInfoWindow();
    });

    addListener(mapObj.current, 'dblclick', () => {
      mapInteraction({
        actionType: 'MAP_DOUBLE_CLICKED',
        action: PARAMETERS.doubleClick,
        sourceContent: PARAMETERS.map,
        sourcePage: PARAMETERS.tourbookPage,
      });
    });
  };

  return (
    <div className={cn(className, s.noBrowserFocusVisible)} style={height}>
      <div className={s.rightButtonsContainer}>
        <div className={s.zoomButtonsContainer}>
          <div className={s.zoomIn} role="button" onClick={handleZoomInClicked}>
            <CustomIcon type="plus" title={t('map.zoomIn')} />
          </div>
          <div className={s.divider} />
          <div className={s.zoomOut} role="button" onClick={handleZoomOutClicked}>
            <CustomIcon type="minus" title={t('map.zoomOut')} />
          </div>
        </div>
        <div
          className={s.mapAutoFocusButton}
          role="button"
          onClick={toggleAutofocus}
          data-testid="map-autp-focus"
        >
          <div className={s.mapAutoFocusButtonIconContainer}>
            <div className={useAutofocus ? s.enabled : undefined}>
              <CustomIcon type="union" title={t('autofocus.map')} />
            </div>{' '}
          </div>
          <div className={useAutofocus ? s.enabled : undefined}>
            <div className={s.mapAutoFocusButtonText}>
              {t(`autofocus.${useAutofocus ? 'enabled' : 'disabled'}`)}
            </div>
          </div>{' '}
        </div>
        <div
          className={s.mapResetButton}
          role="button"
          onClick={resetAutofocus}
          data-testid="map-reset"
        >
          <div className={s.mapResetButtonIconContainer}>
            <CustomIcon type="map-refresh" title={t('map.reset.tooltip')} />
          </div>
          <div className={s.mapResetButtonText}>{t('map.reset.text')}</div>
        </div>
      </div>
      <div className={s.map} data-testid="tourbook-map" ref={mapRef}></div>
    </div>
  );
};

export default TourbookMap;
