import { ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import routes from '@root/routes';
import {
  ListingIcon,
  TourbookDefaultIcon,
  PlayDefaultIcon,
  DuplicateIcon,
} from '@viewthespace/components';
import { CustomIcon, ErrorBoundary } from '@components/shared';
import { get } from '@shared/typedApi';
import { useTranslation, Trans } from 'react-i18next';
import { isInIframe, sendHostToTourbookAnalyticsPage } from '@root/shared/iframeUtils';
import cn from 'classnames';
import contentWidth from '@styles/contentWidth.module.less';
import useAnalyticsEvent from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import { useDispatch } from 'react-redux';
import actions from '@root/store/actions';
import { CHART_COLORS, TourbookAnalyticsResponse } from './utils';
import ListingsTable from './ListingsTable';
import Layout from '../../Layout';
import NotFound from '../../404/NotFound';
import PieChart from './PieChart';
import ListingEngagementOverTimeChart from './ListingEngagementOverTimeChart';
import TourbookShareAnalyticsCard from './ModalForOldTourbooks/TourbookShareAnalyticsCard';
import AnalyticsSkeleton from './AnalyticsSkeleton/AnalyticsSkeleton';

const Analytics = () => {
  const { pageLoaded } = useAnalyticsEvent();
  const { tourbookId } = useParams<{ tourbookId: string }>();
  const { data, isLoading, error } = useQuery([routes.api.tourbookAnalytics(tourbookId)], () =>
    get<TourbookAnalyticsResponse>(routes.api.tourbookAnalytics(tourbookId)),
  );
  const { t } = useTranslation('tourbook');
  const dispatch = useDispatch();

  const handlePageLoadAnalytics = () => {
    dispatch(actions.setTourbookAnalyticsPage(tourbookId));
    pageLoaded({
      pageName: isInIframe()
        ? PARAMETERS.marketTourbookAnalyticsPage
        : PARAMETERS.tourbookAnalyticsPage,
      actionType: 'TOURBOOK_ANALYTICS_PAGE_LOADED',
    });
  };

  useEffect(() => {
    if (!data) return;
    sendHostToTourbookAnalyticsPage({ id: data.id, name: data.name });
    handlePageLoadAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) {
    return <NotFound />;
  }
  if (isLoading) {
    return (
      <Layout fullWidthBackground containerClassName="bg-background-secondary">
        <AnalyticsSkeleton />
      </Layout>
    );
  }

  const tourbookAnalyticsData = data!;

  const colorByListingId = tourbookAnalyticsData.listingEngagement.reduce(
    (acc, listing, i) => ({
      ...acc,
      [listing.id]: CHART_COLORS[i % tourbookAnalyticsData.listingEngagement.length],
      // loop if we run out of colors
    }),
    {},
  );

  const listingsEngagementTotal = tourbookAnalyticsData.listingEngagement.reduce((accum, obj) => {
    return obj.totalActivity + obj.totalDownloads + obj.totalMediaViews + accum;
  }, 0);

  return (
    <Layout
      fullWidthBackground
      containerClassName="bg-background-secondary mobile:mt-2"
      fixedHeaderContents={
        <div className="flex items-center gap-1">
          <Link
            aria-label={t('analytics.backToTourbook')}
            to={routes.tourbook(tourbookAnalyticsData.id)}
            className="flex h-[40px] w-[40px] items-center justify-center rounded-full border border-solid border-black-020 text-xl text-text-primary hover:bg-black-010 hover:text-text-primary"
          >
            <CustomIcon type="chevron-left" className="pr-0.25" />
          </Link>
          <ul>
            <h1 className="font-subtitle">{t('analytics.pageTitle')}</h1>
            <h2 className="line-clamp-1·text-ellipsis·text-black-055·font-body-medium">
              {tourbookAnalyticsData.name}
            </h2>
          </ul>
          <a
            className="ml-auto flex items-center gap-[6px] self-end font-body-medium"
            target="_blank"
            href="https://help.vts.com/hc/en-us/articles/12815364560411"
            rel="noreferrer"
          >
            <CustomIcon type="question" />
            <span className="pb-[2px] underline underline-offset-2">
              {t('analytics.learnMore')}
            </span>
          </a>
        </div>
      }
    >
      {!tourbookAnalyticsData.tourbookMetadata.firstSharedAt ? (
        <TourbookShareAnalyticsCard tourbookData={tourbookAnalyticsData} />
      ) : (
        <div className={cn('flex flex-col gap-2 pb-2', isInIframe() && 'pt-9')}>
          <div className={cn(contentWidth.contentContainer, 'flex flex-col gap-2')}>
            <section className="flex gap-2 mobile:flex-col">
              <ul
                data-testid="tourbookAnalyticsFacts"
                className="grid w-[244px] gap-2 tablet:w-[178px] mobile:w-full mobile:grid-cols-3 mobile:gap-1"
              >
                <ErrorBoundary>
                  <Fact
                    fact="totalViews"
                    value={tourbookAnalyticsData.totalViews}
                    timesDuplicated={tourbookAnalyticsData.numDuplications}
                  />
                  <Fact fact="listingViews" value={tourbookAnalyticsData.listingViews} />
                  <Fact fact="galleryViews" value={tourbookAnalyticsData.galleryViews} />
                </ErrorBoundary>
              </ul>
              <Card className="flex-grow !p-0">
                <ErrorBoundary>
                  <PieChart
                    listingEngagement={tourbookAnalyticsData.listingEngagement}
                    colorByListingId={colorByListingId}
                    listingsEngagementTotal={listingsEngagementTotal}
                    relatedListingIds={tourbookAnalyticsData.relatedListingIds}
                  />
                </ErrorBoundary>
              </Card>
            </section>
            <Card className="z-[3] flex-grow !p-0">
              <ErrorBoundary>
                <ListingEngagementOverTimeChart
                  colorByListingId={colorByListingId}
                  showEmptyState={!listingsEngagementTotal}
                  relatedListingIds={tourbookAnalyticsData.relatedListingIds}
                />
              </ErrorBoundary>
            </Card>
            <ErrorBoundary>
              <ListingsTable
                listings={tourbookAnalyticsData.listingEngagement}
                relatedListingIds={tourbookAnalyticsData.relatedListingIds}
              />
            </ErrorBoundary>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Analytics;

const Card = ({
  Component = 'div',
  className,
  children,
}: {
  Component?: 'div' | 'li';
  className?: string;
  children: ReactNode;
}) => {
  return (
    <Component
      className={cn(
        'rounded-md bg-background-primary shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)]',
        className,
      )}
    >
      {children}
    </Component>
  );
};

const Fact = ({
  fact,
  value,
  timesDuplicated,
}: {
  fact: 'totalViews' | 'galleryViews' | 'listingViews';
  value: number;
  timesDuplicated?: number;
}) => {
  const { t } = useTranslation('tourbook');
  const icons = {
    totalViews: TourbookDefaultIcon,
    galleryViews: PlayDefaultIcon,
    listingViews: ListingIcon,
  } as const;
  const Icon = icons[fact];

  return (
    <Card
      Component="li"
      className="grid grid-cols-[32px_1fr_41px] grid-rows-[32px_1fr] items-center gap-x-1 gap-y-1 p-1 mobile:grid-cols-[32px_1fr_1fr] mobile:gap-x-0.5 mobile:gap-y-0.5"
    >
      <span
        className={cn(
          'flex h-4 w-4 items-center justify-center rounded-md [@media(max-width:390px)]:hidden',
          fact === 'totalViews' && 'bg-[#8C82FF]',
          fact === 'galleryViews' && 'bg-general-aqua-secondary',
          fact === 'listingViews' && 'bg-general-blue-secondary',
        )}
      >
        <Icon className="text-text-primary-inverse" />
      </span>

      <span className="font-body-medium-emphasis mobile:col-span-2">
        {t(`analytics.facts.${fact}`)}
      </span>
      {fact === 'totalViews' && (timesDuplicated || 0) > 0 ? (
        <span className="flex items-center justify-between self-start rounded-md bg-[#E6E6FF] px-0.5 mobile:hidden mobile:w-0">
          <DuplicateIcon />
          {timesDuplicated}
        </span>
      ) : (
        <span />
      )}
      {/* eslint-disable react/jsx-no-literals */}
      <span className="col-span-3 col-start-1 desktop:col-span-2 desktop:col-start-2 [@media(max-width:390px)]:grid">
        <Trans ns="tourbooks" i18nKey="analytics.fact.totalViewText" count={value}>
          <strong className="pr-1 font-headline-de-emphasis mobile:font-title-de-emphasis">
            {{ count: value }}
          </strong>

          <span className="font-body-medium mobile:font-body-small">Total views</span>
        </Trans>
        {/* eslint-enable react/jsx-no-literals */}
      </span>
    </Card>
  );
};
