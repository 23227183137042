import { useFormikContext } from 'formik';
import { some } from 'lodash';
import AdminErrors from '@components/shared/AdminErrors';
import { FormValues } from '..';

type Props = { show?: boolean };

export default function ErrorMessage({ show = false }: Props) {
  const { errors } = useFormikContext<FormValues>();

  return show && some(errors) ? (
    <AdminErrors
      hasTitle={false}
      errors={[
        {
          field: null,
          message:
            'Sorry, we couldn’t save your changes. Please review the errors highlighted below.',
        },
      ]}
    />
  ) : null;
}
