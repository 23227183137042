import { useState } from 'react';
import { Table, notification } from 'antd';
import { Form, Formik } from 'formik';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { CustomIcon } from '@components/shared';
import Button from '@components/shared/V2Button';
import routes from '@root/routes';
import addKeyTo from '@shared/addKeyTo';
import api from '@shared/api';
import { AsyncSelect } from '@components/shared/forms/Dropdowns';
import { AdminBuildingIndex } from '@root/types';
import SafeRender from '@components/shared/SafeRender';
import css from './styles.module.less';
import s from '../PropertyTable.module.less';

const customStyles = {
  overlay: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1000,
  },
};
const initialValues: {
  selectedBuilding: {
    label: string;
    value: string;
  } | null;
} = {
  selectedBuilding: null,
};
const relinkProperty = async (propertyId, buildingId) =>
  api.put(routes.api.admin.vtsProperty(propertyId), {
    buildingId,
  });
const searchBuildings = async (query, buildingId, t) => {
  const response = await api.fetch(
    routes.api.admin.buildingSearch({
      query,
    }),
  );
  const { buildings }: { buildings: AdminBuildingIndex[] } = await response.json();
  return buildings
    .filter(b => b.id !== buildingId)
    .map(building => ({
      label: `${t('common:fullBuildingName', { address: building.address })}${
        building.owningCompany ? ` ${building.owningCompany.name}` : ''
      }`,
      value: building.id,
    }));
};
const RelinkPropertyModal = ({
  buildingHasManyProperties,
  buildingId,
  buildingName,
  fetchAdminBuilding,
  property,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const { t } = useTranslation('admin');
  const history = useHistory();
  const handleError = async response => {
    try {
      const json = await response.json();
      json.errors.forEach(error => notification.error({ message: error.message }));
    } catch {
      notification.error({
        message: t('property.relinkModal.failure', {
          vtsId: property.vtsId,
        }),
      });
    }
  };
  const relinkBuildingWithOneProperty = async selectedBuilding => {
    const response = await relinkProperty(property.id, selectedBuilding.value);
    if (response.ok) {
      closeModal();
      history.push(routes.admin.building(selectedBuilding.value));
      notification.success({
        message: t('property.relinkModal.success', {
          vtsId: property.vtsId,
          buildingName: selectedBuilding.label,
        }),
      });
    } else {
      handleError(response);
    }
  };
  const relinkBuildingWithMultipleProperties = async selectedBuilding => {
    const response = await relinkProperty(property.id, selectedBuilding.value);
    if (response.ok) {
      closeModal();
      fetchAdminBuilding();
      /* eslint-disable react/no-danger */
      notification.success({
        message: (
          <SafeRender
            unsafeContent={t('property.relinkModal.successWithBuildingLink', {
              vtsId: property.vtsId,
              buildingName: selectedBuilding.label,
              buildingUrl: routes.admin.building(selectedBuilding.value),
            })}
          >
            <span />
          </SafeRender>
        ),
      });
      /* eslint-enable react/no-danger */
    } else {
      handleError(response);
    }
  };
  const onSubmit = ({ selectedBuilding }) => {
    if (buildingHasManyProperties) {
      relinkBuildingWithMultipleProperties(selectedBuilding);
    } else if (
      // eslint-disable-next-line no-alert
      window.confirm(
        t('property.relinkModal.confirmation', {
          buildingName,
          buildingId,
        }),
      )
    ) {
      relinkBuildingWithOneProperty(selectedBuilding);
    }
  };
  return (
    <div>
      <Button onClick={openModal} type="tertiary">
        {t('property.relink')}
      </Button>
      <Modal
        aria={{ labelledby: 'relink-title' }}
        className={css.modalContent}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h2 id="relink-title">{t('property.relinkModal.title')}</h2>
        <button className={css.closeIcon} onClick={closeModal} type="button">
          <CustomIcon type="close" />
        </button>
        <div className={css.modalBody}>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values }) => (
              <Form className={css.form}>
                <AsyncSelect
                  labelText={t('property.relinkModal.searchBuildingName')}
                  loadOptions={query => searchBuildings(query, buildingId, t)}
                  name="selectedBuilding"
                />
                {}
                {values.selectedBuilding?.value ? (
                  <div>
                    <div className={s.relinkedPropertyHeader}>
                      <span className={s.selectedBuildingName}>
                        {values.selectedBuilding.label}
                      </span>
                    </div>
                    <Table
                      pagination={false}
                      dataSource={addKeyTo([property])}
                      columns={[
                        {
                          title: t('property.vtsId'),
                          dataIndex: 'vtsId',
                          key: 'vtsId',
                        },
                        {
                          title: t('property.name'),
                          dataIndex: 'name',
                          key: 'name',
                        },
                        {
                          title: t('property.street'),
                          dataIndex: 'street',
                          key: 'street',
                        },
                      ]}
                    />
                    <Button htmlType="submit">{t('property.relink')}</Button>
                  </div>
                ) : null}
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};
export default RelinkPropertyModal;
