import CarouselGallery from '@components/shared/CarouselGallery';
import MosaicGallery from '@components/shared/MosaicGallery';
import { Video, MultipathImage, FirstPhotoOrientation, Address } from '@root/types';
import { SourceContent, SourcePage } from '@root/store/actions/videoAnalytics';
import s from './Images.module.less';

interface Props {
  video?: Video | null;
  photos: MultipathImage[];
  lightboxPaginationClicked: () => void;
  lightboxThumbnailClicked: () => void;
  sourcePage: SourcePage;
  sourceContent: SourceContent;
  firstPhotoOrientation: FirstPhotoOrientation;
  imagesZipUrl: string | null;
  tourEmbedUrl?: string | null;
  onLightboxOpened?: () => void;
  onLightboxClosed?: () => void;
  address?: Address;
  companyName?: string;
}

const RawImages = ({
  photos,
  video = null,
  lightboxPaginationClicked,
  lightboxThumbnailClicked,
  sourcePage,
  sourceContent,
  firstPhotoOrientation,
  imagesZipUrl,
  tourEmbedUrl,
  onLightboxOpened,
  onLightboxClosed,
  address,
  companyName,
}: Props) => {
  return (
    <div>
      <div className={s.galleryContainer}>
        <CarouselGallery
          address={address}
          photos={photos}
          video={video}
          infinite
          imagesZipUrl={imagesZipUrl}
          tourEmbedUrl={tourEmbedUrl}
          sourcePage={sourcePage}
          sourceContent={sourceContent}
          companyName={companyName}
        />
      </div>
      <div className={s.mediaAssetsContainer}>
        <MosaicGallery
          address={address}
          trackLightboxThumbnailClick={lightboxThumbnailClicked}
          trackLightboxPagination={lightboxPaginationClicked}
          photos={photos}
          video={video}
          tourEmbedUrl={tourEmbedUrl}
          sourcePage={sourcePage}
          sourceContent={sourceContent}
          firstPhotoOrientation={firstPhotoOrientation}
          imagesZipUrl={imagesZipUrl}
          onLightboxOpened={onLightboxOpened}
          onLightboxClosed={onLightboxClosed}
          companyName={companyName}
        />
      </div>
    </div>
  );
};

export default RawImages;
