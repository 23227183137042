import { Tooltip } from '@viewthespace/components';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

interface OverflowTooltipProps {
  tooltipText: string;
  tooltipTrigger: string;
  numberOfLines?: number;
  className?: string;
}

const OverflowTooltip = ({
  numberOfLines = 1,
  tooltipText,
  tooltipTrigger,
  className,
}: OverflowTooltipProps) => {
  // We overwrite line clamp in the inline styles to be dynamic.
  const lineClampStyle = `line-clamp-2 text-ellipsis`;

  const textElementRef = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const element = textElementRef.current! as HTMLDivElement;
    const overflowed = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;
    setIsOverflown(overflowed);
  }, []);

  const textElement = (
    <div
      ref={textElementRef}
      className={cn(lineClampStyle, className)}
      style={{
        // Use inline styles to be dynamic based on the prop.
        WebkitLineClamp: numberOfLines || 'unset',
      }}
    >
      {tooltipTrigger}
    </div>
  );
  return (
    <>
      {isOverflown ? (
        <Tooltip
          placement="top"
          content={tooltipText}
          trigger={textElement}
          triggerAction="hover"
        />
      ) : (
        textElement
      )}
    </>
  );
};

export default OverflowTooltip;
