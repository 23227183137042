import { useSetArrivedOnMarketLink } from '@shared/sessionStorageForSiteVisitors';
import Building from './Building';

export const VtsMarketBuildingShare = props => {
  useSetArrivedOnMarketLink();

  return <Building {...props} />;
};

export default VtsMarketBuildingShare;
