import { useField } from 'formik';
import { throttle } from 'lodash';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';
import RawSliderInput from '../RawSliderInput';

type Props = {
  min?: number;
  max?: number;
  step?: number;
  className?: string;
  hasInput?: boolean;
  hasLegend?: boolean;
  legendSuffix?: string;
} & Omit<FieldWrapperProps, 'children'>;
export default function SliderInput({
  labelText,
  name,
  description,
  descriptionClass,
  labelClass,
  required,
  containerClass,
  min = 0,
  max = 100,
  step = 1,
  hasInput = false,
  className,
  hasLegend = false,
  legendSuffix,
}: Props) {
  const [field, _meta, helpers] = useField(name);
  const onChange = e => {
    helpers.setValue(e.target.value);
    helpers.setTouched(true);
  };
  const throttledOnChange = throttle(onChange, 500);

  return (
    <FieldWrapper
      {...{ name, containerClass, labelClass, labelText, required, description, descriptionClass }}
    >
      <RawSliderInput
        className={className}
        value={field.value}
        min={min}
        max={max}
        step={step}
        onChange={throttledOnChange}
        name={name}
        hasInput={hasInput}
        hasLegend={hasLegend}
        legendSuffix={legendSuffix}
      />
    </FieldWrapper>
  );
}
