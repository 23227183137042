import { Modal } from '@components/shared';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CurrentUser } from '@root/types';
import cn from 'classnames';
import useToggle from '@shared/useToggle';
import actions from '@store/actions/listingPage';
import Form from './Form';
import SuccessfullySent from './SuccessfullySent';
import s from './ShareModal.module.less';

type Props = {
  isOpen: boolean;
  close: () => void;
  listingId: string;
  currentUser: CurrentUser;
  buildingName: string;
  neighborhood: string;
};

const ShareModal = ({
  isOpen,
  close,
  listingId,
  currentUser,
  buildingName,
  neighborhood,
}: Props) => {
  const { t } = useTranslation('listing');
  const {
    value: isDisplayingSuccess,
    setTrue: displaySuccessVariation,
    setFalse: reset,
  } = useToggle();

  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => {
        if (!isDisplayingSuccess) {
          dispatch(actions.dismissShare());
        }
        close();
        reset();
      }}
      title={t('share.title')}
      destroyOnClose
      maskClosable={false}
    >
      <div className={cn(!isDisplayingSuccess && s.hide)}>
        <SuccessfullySent />
      </div>
      <div className={cn(isDisplayingSuccess && s.hide)}>
        <Form
          listingId={listingId}
          displaySuccess={displaySuccessVariation}
          buildingName={buildingName}
          neighborhood={neighborhood}
          currentUser={currentUser}
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
