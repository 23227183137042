import PropTypes from 'prop-types';
import cn from 'classnames';
import s from './NySubwayLine.module.less';

const NySubwayLine = ({ line }) => <i className={cn(s.line, s[line])}>{line}</i>;

const validLines = [
  '1',
  '2',
  '3',
  'A',
  'C',
  'E',
  'B',
  'D',
  'F',
  'M',
  'N',
  'Q',
  'R',
  'W',
  '7',
  'G',
  'J',
  'Z',
  '4',
  '5',
  '6',
  'S',
  'L',
];

NySubwayLine.propTypes = {
  line: PropTypes.oneOf(validLines).isRequired,
};

export default NySubwayLine;
