import { useEffect, useRef } from 'react';

const useHandleClickOutside = <T extends HTMLElement>(onClick: () => void) => {
  const ref = useRef<T | null>(null);
  useEffect(() => {
    const handleOutsideClick = event => {
      if (!ref?.current?.contains(event.target) && document.body.contains(event.target)) {
        onClick();
      }
    };

    document.addEventListener('pointerdown', handleOutsideClick);

    return () => {
      document.removeEventListener('pointerdown', handleOutsideClick);
    };
  });

  return ref;
};

export default useHandleClickOutside;
