/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
import { InfoWindowListing } from '@root/types';
import s from './InfoWindow.module.less';

class InfoWindowGenerator {
  translate: Function;

  constructor(translate: Function) {
    this.translate = translate;
  }

  getVtsLogo =
    () => `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 100 100" xml:space="preserve">
    <style>.st1{fill:white;}</style>
    <path class="st1" d="M50.2 30.2l16.6 11L79 33.1 50.2 13.9 21.4 33.1l12.2 8.1 16.6-11z"/>
    <path class="st1" d="M50.2 65.2L.2 33.8v20.9l50 31.4 50-31.4V33.8l-50 31.4z"/>
  </svg>`;

  getLeftChevron =
    () => `<svg width="10" height="10" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="mask0_8297_28702" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="5" height="8">
  <path d="M1.57175 3.99844L4.11234 6.7205C4.29979 6.92134 4.29979 7.24697 4.11234 7.44781C3.92489 7.64865 3.62097 7.64865 3.43352 7.44781L0.553518 4.36209C0.366066 4.16125 0.366066 3.83562 0.553518 3.63478L3.43352 0.549068C3.62097 0.348227 3.92489 0.348227 4.11234 0.549068C4.29979 0.749909 4.29979 1.07554 4.11234 1.27638L1.57175 3.99844Z" fill="white"/>
  </mask>
  <g mask="url(#mask0_8297_28702)">
  <g filter="url(#filter0_d_8297_28702)">
  <path d="M1.57175 3.99844L4.11234 6.7205C4.29979 6.92134 4.29979 7.24697 4.11234 7.44781C3.92489 7.64865 3.62097 7.64865 3.43352 7.44781L0.553518 4.36209C0.366066 4.16125 0.366066 3.83562 0.553518 3.63478L3.43352 0.549068C3.62097 0.348227 3.92489 0.348227 4.11234 0.549068C4.29979 0.749909 4.29979 1.07554 4.11234 1.27638L1.57175 3.99844Z" fill="#5528FF"/>
  </g>
  <path d="M1.57175 3.99844L4.11234 6.7205C4.29979 6.92134 4.29979 7.24697 4.11234 7.44781C3.92489 7.64865 3.62097 7.64865 3.43352 7.44781L0.553518 4.36209C0.366066 4.16125 0.366066 3.83562 0.553518 3.63478L3.43352 0.549068C3.62097 0.348227 3.92489 0.348227 4.11234 0.549068C4.29979 0.749909 4.29979 1.07554 4.11234 1.27638L1.57175 3.99844Z" fill="#140532"/>
  </g>
  <defs>
  <filter id="filter0_d_8297_28702" x="-5.58691" y="-5.60156" width="15.8398" height="19.1992" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset/>
  <feGaussianBlur stdDeviation="3"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8297_28702"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8297_28702" result="shape"/>
  </filter>
  </defs>
  </svg>  
`;

  getRightChevron =
    () => `<svg width="10" height="10" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_8297_28692" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="5" height="8">
<path d="M3.42825 3.99844L0.887659 6.7205C0.700207 6.92134 0.700207 7.24697 0.887659 7.44781C1.07511 7.64865 1.37903 7.64865 1.56648 7.44781L4.44648 4.36209C4.63393 4.16125 4.63393 3.83562 4.44648 3.63478L1.56648 0.549068C1.37903 0.348227 1.07511 0.348227 0.887659 0.549068C0.700207 0.749909 0.700207 1.07554 0.887659 1.27638L3.42825 3.99844Z" fill="white"/>
</mask>
<g mask="url(#mask0_8297_28692)">
<g filter="url(#filter0_d_8297_28692)">
<path d="M3.42825 3.99844L0.887659 6.7205C0.700207 6.92134 0.700207 7.24697 0.887659 7.44781C1.07511 7.64865 1.37903 7.64865 1.56648 7.44781L4.44648 4.36209C4.63393 4.16125 4.63393 3.83562 4.44648 3.63478L1.56648 0.549068C1.37903 0.348227 1.07511 0.348227 0.887659 0.549068C0.700207 0.749909 0.700207 1.07554 0.887659 1.27638L3.42825 3.99844Z" fill="#5528FF"/>
</g>
<path d="M3.42825 3.99844L0.887659 6.7205C0.700207 6.92134 0.700207 7.24697 0.887659 7.44781C1.07511 7.64865 1.37903 7.64865 1.56648 7.44781L4.44648 4.36209C4.63393 4.16125 4.63393 3.83562 4.44648 3.63478L1.56648 0.549068C1.37903 0.348227 1.07511 0.348227 0.887659 0.549068C0.700207 0.749909 0.700207 1.07554 0.887659 1.27638L3.42825 3.99844Z" fill="#140532"/>
</g>
<defs>
<filter id="filter0_d_8297_28692" x="-5.25293" y="-5.60156" width="15.8398" height="19.1992" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="3"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8297_28692"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8297_28692" result="shape"/>
</filter>
</defs>
</svg>
`;

  /* eslint-enable max-len */

  getMinimumPriceString = (listing: InfoWindowListing) =>
    listing.smallestPrice && listing.size
      ? `<div class="${s.price}">
        ${this.translate('listing:search:from')}
          <strong>
            ${this.translate('units:pricePerAreaPerPeriod', {
              pricePerAreaPerPeriod: listing.smallestPrice,
            })}
           </strong>
      </div>`
      : '';

  getSquareFootage = (listing: InfoWindowListing) =>
    `<strong>
    ${this.translate('units:area', {
      area: listing.size,
    })}
    </strong>`;

  getExclusiveFlag = (listing: InfoWindowListing) =>
    listing.isCurrentlyExclusive
      ? `<span class="${s.exclusiveIcon}">${this.getVtsLogo()}</span>`
      : '';

  getListingPicker = (currentIndex: number, totalListings: number) => {
    if (totalListings === 1) {
      return '';
    }
    return `<div class="${s.infoHeader}">
      <div class="${s.infoListingNavigator}" id="previous-listing">
       ${this.getLeftChevron()}
      </div>
      ${this.translate('listing:search:listing_one_of', {
        currentIndex: currentIndex + 1,
        totalListings,
      })}
      <div class="${s.infoListingNavigator}" id="next-listing"">
        ${this.getRightChevron()}
      </div>
    </div>`;
  };

  getBuildingHeader = (listing: InfoWindowListing) => {
    const buildingName = listing.address.buildingName
      ? `<div>${listing.address.buildingName}</div>`
      : '';
    return `
  <h2 class=${s.buildingHeader}>
  ${buildingName}
  <div>${listing.address.street}</div>
  </h2>
  `;
  };
  /* eslint-enable class-methods-use-this */

  createInfoWindow = ({
    listing,
    currentIndex,
    totalListings,
    onHoverMask = false,
  }: {
    listing: InfoWindowListing;
    currentIndex: number;
    totalListings: number;
    onHoverMask?: boolean;
  }) => {
    return `
      <div class="${s.infoWindowContainer}" id="truva-info-window">
        <div class="${onHoverMask ? s.onHoverMask : s.onHover}">
          ${this.getBuildingHeader(listing)}
          <figure class="${s.photoContainer}">
            <div class="${s.clickToScrollText}">
              ${this.translate('listing:infoWindow:clickToScrollText')}
            </div>
            ${this.getExclusiveFlag(listing)}
            <img src="${listing.photo.smallPath || ''}" />
          </figure>
          <section class="${s.stats}">
            ${this.getSquareFootage(listing)}
            <p>${listing.name}</p>
            ${this.getMinimumPriceString(listing)}
          </section>
        </div>
        ${this.getListingPicker(currentIndex, totalListings)}
       </div>
    `;
  };
}

export default InfoWindowGenerator;
