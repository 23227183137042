import { useTranslation } from 'react-i18next';
import { ContentBlockWrapper } from '..';

import { useMicrositeFormContext } from '../../utils';
import Host from './Host';
import NsRecords from './NsRecords';
import CertificateStatusSection from './CertificateStatusSection';

export default function Domain() {
  const { t } = useTranslation('admin');
  const {
    microsite: { domainData },
  } = useMicrositeFormContext();

  return (
    <ContentBlockWrapper
      contentBlockId="domain"
      wrapInDraggable={false}
      title={t('microsite.sections.domainInformation')}
      section="domain"
    >
      <Host />
      <NsRecords domainData={domainData} />
      {domainData.nsRecordsStatus === 'valid' && (
        <CertificateStatusSection certificateStatus={domainData.certificateStatus} />
      )}
    </ContentBlockWrapper>
  );
}
