import api from '@shared/api';
import routes from '@root/routes';
import { EVENTS, PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';

type PlacesWhereSaveCanHappen = 'brand page' | 'saved brands page';

type SavedBuildingOwnerAction = {
  type: 'SAVED_BUILDING_OWNER';
  payload: {
    slug: string;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Save Interaction';
        action: 'save item';
        sourcePage: PlacesWhereSaveCanHappen;
      };
    };
  };
};
export const savedBuildingOwner = (
  slug,
  sourcePage: PlacesWhereSaveCanHappen,
): SavedBuildingOwnerAction => ({
  type: 'SAVED_BUILDING_OWNER',
  payload: {
    slug,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.saveInteraction,
        action: PARAMETERS.saveItem,
        sourcePage,
      },
    },
  },
});

type UnsavedBuildingOwnerAction = {
  type: 'UNSAVED_BUILDING_OWNER';
  payload: {
    slug: string;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Save Interaction';
        action: 'unsave item';
        sourcePage: PlacesWhereSaveCanHappen;
      };
    };
  };
};
export const unsavedBuildingOwner = (
  slug,
  sourcePage: PlacesWhereSaveCanHappen,
): UnsavedBuildingOwnerAction => ({
  type: 'UNSAVED_BUILDING_OWNER',
  payload: {
    slug,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.saveInteraction,
        action: PARAMETERS.unsaveItem,
        sourcePage,
      },
    },
  },
});

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};
const toogleBuildingOwnerSave =
  (
    slug: string,
    shouldBeSaved: boolean,
    {
      sourcePage = 'brand page',
      onSuccess = () => undefined,
      onFailure = () => undefined,
    }: {
      sourcePage?: 'brand page' | 'saved brands page';
      onFailure?: () => void;
      onSuccess?: () => void;
    },
  ) =>
  async dispatch => {
    if (shouldBeSaved) {
      api
        .post(routes.api.buildingOwnerSave(slug))
        .then(handleErrors)
        .then(() => {
          dispatch(savedBuildingOwner(slug, sourcePage));
          onSuccess();
        })
        .catch(() => {
          onFailure();
        });
    } else {
      api
        .delete(routes.api.buildingOwnerSave(slug))
        .then(handleErrors)
        .then(() => {
          dispatch(unsavedBuildingOwner(slug, sourcePage));
          onSuccess();
        })
        .catch(() => {
          onFailure();
        });
    }
  };

export default toogleBuildingOwnerSave;
export type Actions = SavedBuildingOwnerAction | UnsavedBuildingOwnerAction;
