import { TourbookSummary } from '@root/types/Tourbook';
import api from '@shared/api';
import routes from '@root/routes';
import { useQuery } from '@tanstack/react-query';

type SortOptions = 'newest' | 'oldest' | 'nameaz' | 'nameza';

const useFetchTourbookSummaries = (sort: SortOptions = 'newest') => {
  return useQuery(
    [routes.api.tourbooks(sort)],
    async () => {
      const response = await api.fetch(routes.api.tourbooks(sort));
      return (response?.json() as TourbookSummary[]) ?? [];
    },
    {
      retry: false,
    },
  );
};
export default useFetchTourbookSummaries;
