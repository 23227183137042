import { useTranslation } from 'react-i18next';
import { Button } from '@components/shared';
import { SubmitButton } from '@components/shared/forms';
import cn from 'classnames';
import { Drawer, ListingFields } from '..';
import styles from '../TourbookExternalListing.module.less';

type Props = {
  goToMedia: () => void;
};

export const Listing = ({ goToMedia }: Props) => {
  const { t } = useTranslation('tourbook');

  return (
    <Drawer
      footer={
        <>
          <Button type="secondary" onClick={goToMedia}>
            {t('externalListing.previous')}
          </Button>
          <SubmitButton>{t('common:save')}</SubmitButton>
        </>
      }
    >
      <div className={styles.formFields}>
        <div className={styles.progress}>
          <div className={cn(styles.bar, styles.activeBar)}></div>
          <div className={cn(styles.bar, styles.activeBar)}></div>
          <div className={cn(styles.bar, styles.activeBar)}></div>
        </div>
        <ListingFields />
      </div>
    </Drawer>
  );
};

export default Listing;
