import { Listing } from '@root/types';
import { Button } from '@viewthespace/components';
import { useTranslation } from 'react-i18next';
import { useBreakpoint } from '@shared/useBreakpoints';
import routes from '@root/routes';
import useAltText from '@root/shared/useAltText';
import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import UtmAwareLink from '@components/shared/UtmAwareLink/UtmAwareLink';
import ActionButtons from './ActionButtons';

type Props = { listing: Listing };

const ListingLocation = ({ listing }: Props) => {
  const {
    building: { neighborhood: buildingNeighborhood, slug: buildingSlug },
    name: listingName,
    address: listingAddress,
  } = listing;
  const { t } = useTranslation('listing');
  const { isMobile } = useBreakpoint();
  const { clickToPage } = useAnalytics();

  const { getAltText } = useAltText({ address: listingAddress });

  const { lettermarkLogo } = listing.building;

  const renderLettermarkLogo = () => {
    return (
      <img
        className="h-[90px] w-[90px] rounded-[3px] object-cover object-center"
        alt={getAltText({ type: 'buildingLettermarkLogo' })}
        src={lettermarkLogo!.mediumPath}
      />
    );
  };

  const renderCallsToAction = () => {
    return (
      <div className="flex gap-1 desktop:pt-1 tablet:pt-1 mobile:mt-auto">
        {listing?.spaceProvider?.slug && (
          <UtmAwareLink
            to={routes.landlords(listing.spaceProvider.slug)}
            dataTestid="view-portfolio-button"
            dataQaTestid="landlord-logo"
            onClick={() => {
              clickToPage({
                actionType: 'VIEW_PORTFOLIO_BUTTON_CLICKED',
                sourcePage: PARAMETERS.listingPage,
                sourceContent: PARAMETERS.viewPortfolio,
                destination: PARAMETERS.landlordPage,
              });
            }}
          >
            <Button
              className="!border-solid"
              variant="secondary"
              size="small"
              text={t('viewPortfolio')}
              ariaLabel="view-portfolio-button"
            />
          </UtmAwareLink>
        )}
        <UtmAwareLink
          to={routes.building(buildingSlug)}
          dataTestid="view-building-button"
          dataQaTestid="building-logo"
          onClick={() => {
            clickToPage({
              actionType: 'VIEW_BUILDING_BUTTON_CLICKED',
              sourcePage: PARAMETERS.listingPage,
              sourceContent: PARAMETERS.viewBuilding,
              destination: PARAMETERS.buildingPage,
            });
          }}
        >
          <Button
            className="!border-solid"
            variant="secondary"
            size="small"
            text={t('viewBuilding')}
            ariaLabel="view-building-button"
          />
        </UtmAwareLink>
      </div>
    );
  };

  const buildingNameSection = (
    <div>
      <h1 className="!m-0 font-body-large-emphasis" data-qa-testid="building-name">
        <ul>
          <div>{listing.address.buildingName} </div>
          <div>{listing.address.street}</div>
        </ul>
      </h1>
    </div>
  );

  const addressSection = (
    <section data-qa-testid="listing-address">
      <div>
        <h2 className="m-0 flex flex-wrap items-center font-body-large">{listingName}</h2>
      </div>
      <div>
        <h2 className="m-0 flex flex-wrap items-center font-body-large tablet:block">
          {buildingNeighborhood ? (
            <span className="inline-flex items-center tablet:block">
              {buildingNeighborhood}
              <figure className="mx-1 my-0 h-[16px] w-[1px] bg-black-015 tablet:hidden"></figure>{' '}
            </span>
          ) : null}
          <span>{t('common:cityLocalityRegion', { address: listing.address })}</span>
        </h2>
      </div>
      <div>
        <h2 className="m-0 flex flex-wrap items-center font-body-large">
          {t(`country:${listing.address.countryCode}`)}
        </h2>
      </div>
      {listing.address.crossStreets ? (
        <div className="font-body-medium">
          {t('crossStreets', { crossStreets: listing.address.crossStreets })}
        </div>
      ) : null}
    </section>
  );

  const offMarketSection =
    listing.status === 'off_market' ? (
      <div className="inline-block rounded-[3px] border-2 border-solid border-black-015 px-[12px] py-1 text-coral font-body-medium">
        {t('offMarket')}
      </div>
    ) : null;

  return listing.hideLocation ? (
    <div className="mb-2 mobile:mt-2">
      <div className="flex w-full justify-between">
        {!!lettermarkLogo && (
          <div className="mb-1 cursor-pointer " data-qa-testid="building-logo">
            {renderLettermarkLogo()}
          </div>
        )}
        <div className="grow" />
      </div>
      <h1 className="font-subtitle">
        {t('location.neighborhoodInShare', { neighborhood: buildingNeighborhood })}
      </h1>
      {listing.status === 'off_market' ? (
        <div className="inline-block rounded-[3px] border-2 border-solid border-black-015 px-[12px] py-1 text-coral font-body-medium">
          {t('offMarket')}
        </div>
      ) : null}
    </div>
  ) : (
    <>
      {lettermarkLogo ? (
        <div className="my-2 desktop:mt-0 tablet:mt-0" data-testid="listingLocation">
          <div className="flex w-full justify-between">
            <figure className="desktop:mb-2 tablet:mb-2">{renderLettermarkLogo()}</figure>
            <div className="flex flex-col items-end">
              <ActionButtons listing={listing} />
              {isMobile && renderCallsToAction()}
            </div>
          </div>
          {isMobile && <hr className="my-2 text-general-neutral-tertiary" />}
          {buildingNameSection}
          {addressSection}
          {offMarketSection}
          {!isMobile && renderCallsToAction()}
        </div>
      ) : (
        <div className="my-2 desktop:mt-0 tablet:mt-0" data-testid="listingLocation">
          <div className="flex w-full flex-wrap-reverse justify-between gap-0.5">
            {buildingNameSection}
            <div className="mb-0.5 ml-auto flex shrink-0 flex-col items-end">
              <ActionButtons listing={listing} />
            </div>
          </div>
          {addressSection}
          {isMobile && (
            <>
              <hr className="my-2 text-general-neutral-tertiary" />
              {renderCallsToAction()}
            </>
          )}
          {offMarketSection}
          {!isMobile && renderCallsToAction()}
        </div>
      )}
    </>
  );
};

export default ListingLocation;
