type decodedListingShareUrl = {
  listingId: string;
  userId: string;
  hideLocation: boolean;
};

class ListingShareUrlHelper {
  static SEPARATOR = '&&';

  static encode({
    listingId,
    userId,
    hideLocation = false,
  }: {
    listingId: string;
    userId: string;
    hideLocation?: boolean;
  }): string {
    return btoa(`${listingId}${this.SEPARATOR}${userId}${this.SEPARATOR}${hideLocation}`);
  }

  static decode(encodedData: string): decodedListingShareUrl {
    const [listingId, userId, hideLocation] = atob(encodedData).split(this.SEPARATOR);
    return {
      listingId,
      userId,
      hideLocation: hideLocation ? hideLocation === 'true' : false,
    };
  }
}

export default ListingShareUrlHelper;
