import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { useFormikContext } from 'formik';
import { MultipathImage } from '@root/types';
import { ImageForUpload, FormValues } from '.';

const toImagesForUpload = (images: Omit<MultipathImage, 'type'>[]): ImageForUpload[] =>
  images.map(image => ({
    image,
    name: image.description,
    type: 'image',
    id: uuid(),
    isUploaded: true,
  }));

export const ImagesContext = React.createContext<{
  images: ImageForUpload[];
  setImages: (images: ImageForUpload[]) => void;
}>({
  images: [],
  setImages: () => {},
});

export function ImagesContextProvider({ children }: { children: React.ReactNode }) {
  const { values } = useFormikContext<FormValues>();
  const [images, setImages] = React.useState<ImageForUpload[]>(toImagesForUpload(values.photos));

  return <ImagesContext.Provider value={{ images, setImages }}>{children}</ImagesContext.Provider>;
}
