import { useState, useEffect } from 'react';
import { parse, stringify } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import api from '@shared/api';
import { Pagination } from '@root/types';

const queryFrom = (location: Location) => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return queryParams.query as string | undefined;
};

const pageFrom = (location: Location): number => {
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });
  return queryParams.page ? Number(queryParams.page) : 1;
};

type ValidModelKeys = Exclude<string, 'pagination'>;
export default function useIndexPage<T>({
  modelsKey,
  apiBaseRoute,
  baseRoute,
}: {
  modelsKey: ValidModelKeys;
  apiBaseRoute: string;
  baseRoute: string;
}) {
  const location = useLocation();
  const history = useHistory();
  const [query, setQuery] = useState(queryFrom(location));
  const currentPage = pageFrom(location);
  const [models, setModels] = useState<T[]>([]);
  const [pagination, setPagination] = useState<Partial<Pagination>>({});
  const [isFetching, setIsFetching] = useState(false);

  const fetchModels = async () => {
    setIsFetching(true);
    const queryString = stringify({ page: currentPage, query });
    const response = await api.fetch(`${apiBaseRoute}?${queryString}`);
    const parsed = await response.json();

    setModels(parsed[modelsKey] as T[]);
    setPagination(parsed.pagination as Pagination);
    setIsFetching(false);
  };

  const submitQuery = (e: any /* antd sucks */) => {
    e.preventDefault();

    const queryString = stringify({ page: 1, query });
    history.push(`${baseRoute}?${queryString}`);
  };

  const changePage = (newPage: number) => {
    const queryString = stringify({ page: newPage, query });
    history.push(`${baseRoute}?${queryString}`);
  };

  useEffect(() => {
    fetchModels();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    models,
    isFetching,
    setQuery,
    query,
    submitQuery,
    pagination: { ...pagination, currentPage },
    changePage,
  };
}
