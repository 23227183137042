import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseSelect } from '@viewthespace/components';
import { FieldWrapper } from '@components/shared/forms';
import { useField } from 'formik';
import InterSvg from './inter.svg';
import RobotoSvg from './roboto.svg';
import OpenSansSvg from './open-sans.svg';
import MontserratSvg from './montserrat.svg';
import LatoSvg from './lato.svg';
import PoppinsSvg from './poppins.svg';
import SourceSansProSvg from './source-sans-pro.svg';
import RalewaySvg from './raleway.svg';
import NotoSansSvg from './noto-sans.svg';
import RobotoSlabSvg from './roboto-slab.svg';
import MerriweatherSvg from './merriweather.svg';
import PlayfairDisplaySvg from './playfair-display.svg';

const fonts = [
  { value: 'Inter', label: 'Inter' },
  { value: 'Lato', label: 'Lato' },
  { value: 'Merriweather', label: 'Merriweather' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Noto Sans', label: 'Noto Sans' },
  { value: 'Open Sans', label: 'Open Sans' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Poppins', label: 'Poppins' },
  { value: 'Raleway', label: 'Raleway' },
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Roboto Slab', label: 'Roboto Slab' },
  { value: 'Source Sans Pro', label: 'Source Sans Pro' },
];

const fontIconMap: { [font: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
  Inter: InterSvg,
  Lato: LatoSvg,
  Merriweather: MerriweatherSvg,
  Montserrat: MontserratSvg,
  'Noto Sans': NotoSansSvg,
  'Open Sans': OpenSansSvg,
  'Playfair Display': PlayfairDisplaySvg,
  Poppins: PoppinsSvg,
  Raleway: RalewaySvg,
  Roboto: RobotoSvg,
  'Roboto Slab': RobotoSlabSvg,
  'Source Sans Pro': SourceSansProSvg,
};

type OptionProps = {
  value?: string;
  label: string;
};
const Option = ({ value, label }: OptionProps) => {
  const Icon = fontIconMap[value || ''];

  useEffect(() => {
    const svg = document.getElementById(`${label}-svg`) as SVGAElement | null;
    if (svg) {
      const bbox = svg.getBBox();
      const modifier = 16 / bbox.height;
      svg.setAttribute('width', `${bbox.width * modifier}`);
      svg.setAttribute('height', `${bbox.height * modifier}`);
    }
  });

  if (!value) return null;

  return (
    <div className="w-full">
      <Icon id={`${label}-svg`} />
    </div>
  );
};

export default function FontPicker() {
  const { t } = useTranslation('admin');
  const [fontField, _meta, fontHelpers] = useField({ name: 'font' });
  const selectedOption = fonts.find(option => option.value === fontField.value);

  return (
    <FieldWrapper name="font" labelText={t('microsite.font.title')} containerClass="!mb-0 ">
      <BaseSelect
        name={fontField.name}
        options={fonts}
        label=""
        hideLabel
        data-testid="font-selector"
        isClearable={false}
        selectionType="single"
        backgroundStyle="filled"
        value={selectedOption}
        onChange={option => {
          fontHelpers.setValue(option?.value);
          fontHelpers.setTouched(true);
        }}
        renderListItemContent={({ value, label }) => <Option value={value} label={label} />}
      />
    </FieldWrapper>
  );
}
