/* eslint-disable react/sort-comp */
import { Component } from 'react';
import classNames from 'classnames';
import { Form } from '@ant-design/compatible';
import Button from '@components/shared/V2Button';

import { Table, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import api from '@shared/api';
import routes from '@root/routes';
import addKeyTo from '@shared/addKeyTo';
import PaginationRow from '@components/shared/PaginationRow';
import { historyType } from '@propTypes';
import css from './BuildingPage.module.less';

type Props = {
  buildingId: string;
  t: $TSFixMeFunction;
  history: historyType;
};

type State = $TSFixMe;

export class RawUnlistedSpaces extends Component<Props, State> {
  state = {
    spaces: null,
    page: 1,
    pageSize: 0,
    totalCount: 0,
    query: '',
    isAddingListing: false,
  };

  componentDidMount() {
    this.fetchUnlistedSpaces();
  }

  fetchUnlistedSpaces = async () => {
    const { page, query } = this.state;
    const { buildingId } = this.props;
    const response = await api.fetch(
      routes.api.admin.buildingUnlistedSpaces(buildingId, page, query),
    );

    const {
      spaces,
      pagination: { page: newPage, pageSize, totalCount },
    } = await response.json();

    this.setState({ spaces, page: newPage, pageSize, totalCount });
  };

  unlistedSpacesWithKey = () => {
    const { spaces } = this.state;
    if (!spaces) return [];

    return addKeyTo(spaces);
  };

  addListing = async spaceId => {
    this.setState({ isAddingListing: true });
    const { history } = this.props;
    const response = await api.post(routes.api.admin.listings, { spaceId });
    const listing = await response.json();

    this.setState({ isAddingListing: false });
    history.push(routes.admin.listingPage(listing.id));
  };

  onSearch = e => {
    e.preventDefault();
    this.setState({ page: 1 }, this.fetchUnlistedSpaces);
  };

  changePage = page => {
    this.setState({ page }, this.fetchUnlistedSpaces);
  };

  render() {
    const { t } = this.props;
    const { page, pageSize, totalCount, query } = this.state;
    return (
      <div data-testid="unlistedSpaces">
        <Form onSubmit={this.onSearch}>
          <Form.Item>
            <Row>
              <Input
                className={classNames(css.searchInput, 'u-m-right-3x ')}
                placeholder={t('building.searchPlaceholder')}
                value={query}
                name="query"
                onChange={e => this.setState({ query: e.target.value })}
              />
              <Button icon="magnifying-glass" onClick={this.onSearch} type="primary">
                {t('building.searchButton')}
              </Button>
            </Row>
          </Form.Item>
        </Form>
        <Table
          columns={[
            {
              title: t('building.vts.propertyIdHeader'),
              dataIndex: 'vtsPropertyId',
              key: 'vtsPropertyId',
            },
            {
              title: t('building.unlistedSpaces.spaceIdHeader'),
              dataIndex: 'vtsId',
              key: 'vtsId',
            },
            {
              title: t('building.unlistedSpaces.floorNameHeader'),
              dataIndex: 'floorName',
              key: 'floorName',
            },
            {
              title: t('building.unlistedSpaces.suiteNameHeader'),
              dataIndex: 'suite',
              key: 'suite',
            },
            {
              title: t('building.unlistedSpaces.actionsHeader'),
              render: space => (
                <Button
                  disabled={this.state.isAddingListing}
                  type="tertiary"
                  onClick={() => this.addListing(space.id)}
                >
                  {t('building.unlistedSpaces.addListingButton')}
                </Button>
              ),
            },
          ]}
          dataSource={this.unlistedSpacesWithKey()}
          pagination={false}
        />
        <PaginationRow
          pageSize={pageSize}
          page={page}
          totalCount={totalCount}
          changePage={this.changePage}
        />
      </div>
    );
  }
}

// Because react-router removed withRouter and many libraries (including React)
// are removing support for class components. However, there is not time to update
// this component to a functional component
export default function ListingsWrapper(props: Omit<Props, 't' | 'history'>) {
  const { t } = useTranslation('admin');
  const history = useHistory();
  return <RawUnlistedSpaces {...props} t={t} history={history} />;
}
