import { useQuery } from '@tanstack/react-query';
import routes from '@root/routes';
import { get } from '@shared/typedApi';
import useAnalytics from '@shared/useAnalytics';
import { Listing as ListingType } from '@root/types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useToggle from '@shared/useToggle';
import actions from '@store/actions';
import useMarketo from '@root/shared/useMarketo';
import TruvaLayout from '../Layout';
import ListingLayout from './Layout';
import NotFound from '../404/NotFound';
import ListingHead from './ListingHead';
import ListingSkeleton from './ListingSkeleton/ListingSkeleton';

type PassedProps = {
  isPreview?: boolean;
};

export const Listing = ({ isPreview = false }: PassedProps) => {
  const { pageLoaded, PARAMETERS } = useAnalytics();
  const dispatch = useDispatch();
  const { listingId } = useParams<{ listingId: string }>();
  const { value: pageLoadedOnce, setTrue: setPageLoadedOnce } = useToggle();

  const { data, isLoading, isError } = useQuery<{ listing: ListingType; lastInquiry: any }>(
    [routes.api.listing(listingId)],
    () => get(routes.api.listing(listingId)),
    {
      onSuccess: ({ listing }) => {
        dispatch(actions.setListingPage(listing, isPreview));
        if (!pageLoadedOnce) {
          pageLoaded({
            pageName: PARAMETERS.listingPage,
            actionType: 'LISTING_PAGE_LOADED',
          });
          setPageLoadedOnce();
        }
        setTimeout(() => {
          window.prerenderReady = true;
        }, 500);
      },
      onError: () => {
        dispatch(actions.setNotFoundPage());
      },
    },
  );

  useMarketo();

  if (isLoading) {
    return (
      <TruvaLayout>
        <ListingSkeleton />
      </TruvaLayout>
    );
  }

  if (isError) {
    return <NotFound />;
  }

  return (
    <TruvaLayout>
      <ListingHead listing={data.listing} />
      <ListingLayout listing={data.listing} lastInquiry={data.lastInquiry} />
    </TruvaLayout>
  );
};

export default Listing;
