import { useEffect, useState } from 'react';
import {
  IconButton,
  Modal,
  Notification,
  TourbookOverlay,
  FlexibleSizeArea,
  CustomIcon,
  ErrorBoundary,
} from '@components/shared';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Truncate from 'react-truncate';
import Divider from '@components/shared/Divider';
import cn from 'classnames';
import { Brochure, Listing, PartialListing, TourbookSummary } from '@root/types';
import { useTranslation, Trans } from 'react-i18next';
import routes from '@root/routes';
import ExclusiveLabel from '@components/shared/ExclusiveLabel';
import { ButtonLink } from '@components/shared/V2Button';
import { Tooltip } from 'antd';
import { some } from 'lodash';
import TourbookIconButton from '@components/shared/TourbookIconButton';
import Amenity from '@components/shared/Amenity';
import { filterAndOrderAmenities } from '@shared/amenitiesOrdering';
import InquiryForm from '@components/shared/InquiryForm/InquiryForm';
import BrochureDownloadLink from '@components/shared/BrochureDownloadLink/BrochureDownloadLink';
import { PARAMETERS, EVENTS } from '@root/tracking/constants';
import useAnalytics, { ListingPreviewSourcePageType } from '@shared/useAnalytics';
import { isInIframe } from '@root/shared/iframeUtils';
import { stringify } from 'qs';
import utmParamsForListingView from '@root/shared/utmParamsForListingView';
import useToggleListingSaveMutation from '@root/shared/useToggleListingSaveMutation';
import SafeRender from '@components/shared/SafeRender';
import Gallery from './Gallery';
import s from './ListingPreviewModal.module.less';
import ListingInfo from './ListingInfo';

export type Props = {
  listingIndex: number | null;
  goToNextListing: () => void;
  goToPreviousListing: () => void;
  isOpen: boolean;
  closeModal: () => void;
  afterCloseModal: () => void;
  partialListing: PartialListing | null;
  listingsCount: number;
  listing: Listing | null;
  isSaved: boolean;
  tourbooks?: TourbookSummary[];
  sourcePage: ListingPreviewSourcePageType;
  tourbookId?: string | null;
  isTourbookShare?: boolean;
};

const ListingPreviewModal = ({
  listingIndex,
  goToPreviousListing,
  goToNextListing,
  listingsCount,
  isOpen,
  closeModal,
  afterCloseModal,
  partialListing,
  listing,
  sourcePage,
  tourbooks,
  tourbookId,
  isSaved,
}: Props) => {
  const { t } = useTranslation('listing');
  const { listing: listingRoute } = routes;
  const { listingPreviewAnalytics, clickToPage } = useAnalytics();
  const [tourbookDialogIsOpen, setTourbookDialogIsOpen] = useState(false);
  const flags = useFlags();

  // can remove with vts-trackable-parameters flag
  const queryString = stringify(utmParamsForListingView(tourbookId!, sourcePage), {
    addQueryPrefix: true,
  });

  const {
    size,
    address,
    buildingSlug,
    id,
    isCurrentlyExclusive,
    minMaxArea,
    name: listingName,
    neighborhoodName,
  } = partialListing || {};
  const { amenities, description, descriptionHtml, video } = listing || {};

  const photos = listing?.photos || (partialListing?.photo ? [partialListing?.photo] : []);
  const listingAmenities = amenities ? filterAndOrderAmenities(amenities, t) : null;
  const linkToListing = id && buildingSlug ? listingRoute(id, buildingSlug) : '';

  const isInTourbook = (listingId: string): boolean =>
    some(tourbooks, tourbook => tourbook.listingIds.includes(listingId));

  const handleSuccessfulSaveListing = () => {
    Notification.saveSuccess({
      title: t('listingSavedToFavorites'),
      /* eslint-disable react/jsx-no-literals */
      text: (
        <Trans i18nKey="viewInFavorites" ns="listing">
          View it in your
          <a href="/saved/listings">saved listings</a>.
        </Trans>
      ),
      /* eslint-enable react/jsx-no-literals */
    });
  };

  const { toggleSaveListing } = useToggleListingSaveMutation({
    listing: partialListing!,
    onSuccessfulSave: handleSuccessfulSaveListing,
    analyticsInformation: {
      sourcePage,
      sourceContent: PARAMETERS.listingPreview,
      ...listing?.analyticsInformation,
    },
    isSaved,
  });

  const handleKeyDown = event => {
    if (event.repeat) return;
    if (event.key === 'ArrowLeft') goToPreviousListing();
    if (event.key === 'ArrowRight') goToNextListing();
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  // can remove with vts-trackable-parameters flag
  const viewListingUrlWithUtmParams = isInIframe()
    ? `${routes.vtsMarketListingShare(id!)}${queryString}`
    : `${listingRoute(id!, buildingSlug!)}${queryString}`;

  const viewListingUrl = isInIframe()
    ? routes.vtsMarketListingShare(id!)
    : listingRoute(id!, buildingSlug!);

  return (
    <Modal
      className={cn(
        s.modal,
        isInIframe() && '!right-[min(calc((100%-896px)/2),112px)]',
        /* Core's side bar is 224px - if the screen is large enough then just shift the
         * modal to the left by 112px (half of 224px) to center it perfectly.
         *
         * If the screen modal isn't wide enough to center perfectly, shift it over as
         * far to the left as possible without cutting off the modal. To do that we calculate
         * how much free space the modal has to the left with: (PARENT_WIDTH - MODAL_WIDTH) / 2
         */
      )}
      isOpen={isOpen}
      closeModal={closeModal}
      afterClose={afterCloseModal}
      data-testid="listingPreviewModal"
    >
      <div className={s.listingPreviewWrapper}>
        <div className={s.topRow}>
          <ErrorBoundary>
            {isCurrentlyExclusive ? (
              <ExclusiveLabel
                size="medium"
                imgClassName={s.exclusiveImage}
                tooltipPlacement="bottomLeft"
              />
            ) : null}
            <ButtonLink
              type="tertiary"
              size="small"
              href={
                flags['vts-trackable-parameters'] ? viewListingUrl : viewListingUrlWithUtmParams
              }
              openInNewTab
              className={s.viewFullDetailsButton}
              disabled={!linkToListing}
              onClick={() =>
                listing &&
                clickToPage(
                  {
                    destination: PARAMETERS.listingPage,
                    sourcePage: sourcePage || PARAMETERS.searchResultsPage,
                    sourceContent: PARAMETERS.listingPreview,
                  },
                  { ...listing?.analyticsInformation },
                )
              }
            >
              <CustomIcon type="linkOut" className={s.viewFullDetailsLinkOutIcon} />
              {t('listingPreview.viewFullDetails')}
            </ButtonLink>
          </ErrorBoundary>
        </div>
        <div className={s.galleryWrapper}>
          <ErrorBoundary>
            <Gallery
              listing={listing}
              key={partialListing?.id}
              photos={photos}
              video={video}
              sourcePage={sourcePage}
            />
            <div className={s.actionButtons}>
              <TourbookIconButton
                isInTourbook={id ? isInTourbook(id) : false}
                setTourbookDialogIsOpen={setTourbookDialogIsOpen}
                tourbookDialogIsOpen={tourbookDialogIsOpen}
                showButtonTitle={false}
                toolTipPlacement="bottom"
                disabled={!linkToListing}
              />
              {!isInIframe() && (
                <Tooltip trigger={['hover', 'click']} placement="bottom" title={t('saveTooltip')}>
                  <span>
                    <IconButton
                      className={cn(s.favoriteButton, { [s.filled]: isSaved })}
                      icon={isSaved ? 'heart' : 'heart-outline'}
                      disabled={!linkToListing}
                      data-testid={
                        isSaved
                          ? 'favorite-listing-button-saved'
                          : 'favorite-listing-button-unsaved'
                      }
                      onClick={() => {
                        if (listing) {
                          toggleSaveListing();
                        }
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </ErrorBoundary>
        </div>

        <div className={s.modalContent}>
          <ErrorBoundary>
            <div className={s.listingDetailsWrapper}>
              {partialListing && (
                <>
                  <div className={s.listingSquareFootage}>
                    <span className={s.listingArea}>{t('units:area', { area: size })} </span>
                    {minMaxArea && (
                      <FlexibleSizeArea
                        flexibleSizeAreaClassName={s.flexibleSizeAreaContainer}
                        iconClassName={s.sizeArea}
                        textClassName={s.flexibleSizeAreaText}
                        minMaxArea={minMaxArea}
                        showIconTitle={false}
                      />
                    )}
                  </div>
                  <div className={s.listingAddress}>
                    {!!address && <span>{address.buildingName || address.street}</span>}
                    {address?.buildingName && (
                      <span className={s.buildingSecondaryName}>{address.street}</span>
                    )}
                    <div>{listingName}</div>
                    {neighborhoodName && (
                      <span className={s.neighborhoodName}>{neighborhoodName}</span>
                    )}
                    {!!address && <span>{t('common:cityLocalityRegion', { address })}</span>}
                    <span className={s.country}>{t(`country:${address?.countryCode}`)}</span>
                  </div>
                  <Divider className={s.divider} />
                  <div className={s.scrollableContent} key={id}>
                    <div className={s.listingInfo}>
                      <ListingInfo listing={listing} partialListing={partialListing} />
                    </div>
                    {listing?.brochures && listing.brochures.length > 0 ? (
                      <div>
                        <Divider className="!my-[12px] !mr-1" />
                        <div className="mb-[12px] flex flex-col">
                          {listing.brochures.map((brochure: Brochure) => (
                            <div key={brochure.url} className="pb-1 last:pb-0">
                              <BrochureDownloadLink
                                brochure={brochure}
                                onClick={() =>
                                  listingPreviewAnalytics({
                                    listingPreviewactionType: 'BROCHURE_CLICKED',
                                    listingPreviewAction: PARAMETERS.download,
                                    listingPreviewEvent: EVENTS.attachmentInteraction,
                                    listing,
                                    otherAttributes: {
                                      attachmentId: brochure.id,
                                      attachmentName: brochure.name,
                                      attachmentType: PARAMETERS.brochure,
                                    },
                                    sourcePage,
                                  })
                                }
                                lineWrap={1}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                    {description &&
                      !flags['market.listing-space-descriptions-support-rich-text'] && (
                        <div className={s.descriptionContainer}>
                          <div className={s.descriptionTitle}>
                            {t('listingPreview.aboutTheListing')}
                          </div>
                          <Truncate lines={4} trimWhitespace>
                            {description.split('\n').map((line, i, arr) => {
                              // Needed to preserve new lines
                              /* eslint-disable react/no-array-index-key */
                              const spanWithLine = <span key={i}>{line}</span>;
                              if (i === arr.length - 1) {
                                return spanWithLine;
                              }
                              return [spanWithLine, <br key={`${i}br`} />];
                              /* eslint-enable react/no-array-index-key */
                            })}
                          </Truncate>
                        </div>
                      )}
                    {descriptionHtml &&
                      flags['market.listing-space-descriptions-support-rich-text'] && (
                        <div className={s.descriptionContainer}>
                          <div className={s.descriptionTitle}>
                            {t('listingPreview.aboutTheListing')}
                          </div>
                          <SafeRender unsafeContent={descriptionHtml}>
                            <span />
                          </SafeRender>
                        </div>
                      )}
                    {listingAmenities?.length > 0 && (
                      <>
                        <div className={s.amenitiesTitle}>{t('common:amenities.title')}</div>
                        <div className={s.amenitiesList}>
                          {listingAmenities.map(
                            (amenity: { name: string; value: boolean | string | number }) => (
                              <Amenity
                                {...amenity}
                                key={amenity.name}
                                amenityClassName={s.amenity}
                              />
                            ),
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={s.inquiryFormWrapper}>
              <InquiryForm
                listing={listing}
                key={id}
                isOnPreview
                sourceContent={PARAMETERS.listingPreview}
                sourcePage={sourcePage}
              />
            </div>
          </ErrorBoundary>
        </div>

        <ErrorBoundary>
          <div className={s.positionSection}>
            <div className={s.positionContainer}>
              <IconButton
                className={s.previousListingButton}
                icon="chevron-left"
                disabled={listingIndex === 0}
                data-testid="previousListingButton"
                onClick={goToPreviousListing}
              />
              <div className="text-black-055 font-body-medium">{`${
                (listingIndex || 0) + 1
              } / ${listingsCount}`}</div>
              <IconButton
                className={s.nextListingButton}
                disabled={listingIndex === listingsCount - 1}
                icon="chevron-right"
                data-testid="nextListingButton"
                onClick={goToNextListing}
              />
            </div>
          </div>

          {tourbookDialogIsOpen && listing && (
            <TourbookOverlay
              className={cn(
                '!translate-y-[272px]',
                isInIframe() ? '!translate-x-[-64px]' : '!translate-x-[-112px]',
              )}
              isOpen={tourbookDialogIsOpen}
              close={() => setTourbookDialogIsOpen(false)}
              listing={listing}
            />
          )}
        </ErrorBoundary>
      </div>
    </Modal>
  );
};

export default ListingPreviewModal;
