import { memo } from 'react';
import { throttle } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Select } from '@components/shared/forms';
import { AsyncSelect } from '@components/shared/forms/Dropdowns';
import api from '@shared/api';
import routes from '@root/routes';
import Input from './Input';
import css from './styles.module.less';

const fetchFiberProviders = async (query, page) => {
  const response = await api.fetch(routes.api.admin.fiberProviders(query, page));
  const { fiberProviders } = await response.json();
  return fiberProviders.map(fiberProvider => ({
    label: fiberProvider.name,
    value: fiberProvider.id,
  }));
};

const searchFiberProviders = throttle(query => fetchFiberProviders(query, 1), 250);

type CertificationsProps = {
  fiberProviders?: any;
  leedCertification: string;
  wiredCertification: string;
};

const Certifications = ({
  fiberProviders,
  leedCertification,
  wiredCertification,
}: CertificationsProps) => {
  const { t } = useTranslation('admin');
  return (
    <section>
      <h2>{t('building.certifications.title')}</h2>
      <fieldset className={css.sideBySide}>
        <Select
          className={css.select}
          containerClass={css.left}
          labelText={t('building.leedCertification')}
          name="amenitySet.leedCertification"
          options={[
            { label: t('building.certifications.platinum'), value: 'platinum' },
            { label: t('building.certifications.gold'), value: 'gold' },
            { label: t('building.certifications.silver'), value: 'silver' },
            { label: t('building.certifications.certified'), value: 'certified' },
          ]}
          placeholder=""
          value={leedCertification}
        />
        <Input
          containerClass={css.right}
          labelText={t('building.dataSource')}
          name="amenitySet.leedCertificationDataSource"
        />
      </fieldset>

      <fieldset className={css.sideBySide}>
        <Select
          className={css.select}
          containerClass={css.left}
          labelText={t('building.wiredCertification')}
          name="amenitySet.wiredCertification"
          options={[
            { label: t('building.certifications.platinum'), value: 'platinum' },
            { label: t('building.certifications.gold'), value: 'gold' },
            { label: t('building.certifications.silver'), value: 'silver' },
            { label: t('building.certifications.certified'), value: 'certified' },
          ]}
          placeholder=""
          value={wiredCertification}
        />
        <Input
          containerClass={css.right}
          labelText={t('building.dataSource')}
          name="amenitySet.wiredCertificationDataSource"
        />
      </fieldset>
      {fiberProviders && (
        <AsyncSelect
          className={css.select}
          initialValue={fiberProviders}
          isMulti
          labelText={t('building.fiberProviders')}
          loadOptions={searchFiberProviders}
          mapValue={value => ({ id: value })}
          name="fiberProviders"
          placeholder=""
        />
      )}
    </section>
  );
};

export default memo(Certifications);
