import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import {
  CustomIcon,
  IconButton,
  OnlyInDesktop,
  OnlyInTablet,
  OnlyInMobile,
  OnlyInTabletMobile,
  OnlyInDesktopTablet,
} from '@components/shared';
import useAnalytics from '@shared/useAnalytics';
import { Address, MultipathImage, TourbookListingAnalyticsInformation, Video } from '@root/types';
import { CSSTransition } from 'react-transition-group';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import OverflowMenu from '@components/shared/OverflowMenu';
import { useTranslation } from 'react-i18next';
import api from '@shared/api';
import { isInIframe } from '@root/shared/iframeUtils';
import Asset from './Asset';
import SlideCount from './SlideCount';
import { PreviousArrow, NextArrow } from './Arrows';
import ImageThumbnails from './ImageThumbnails';
import SingleDownloadButtonLink from './SingleDownloadButtonLink';
import DownloadAllImages from './DownloadAllImages';
import MapThumbnail from './MapThumbnail';
import MapIconButton from './MapThumbnail/MapIconButton';
import VirtualToursTab from './VirtualToursTab';
import FloorPlanThumbnail from './FloorPlanThumbnail';
import TabControls from './TabControls';
import s from './FullWidthLightbox.module.less';

export type Props = {
  assets: Array<MultipathImage>;
  currentIndex: number;
  imagesZipUrl: string | null;
  onClose: () => void;
  onChange: (index: number) => void;
  video: Video | null;
  tourEmbedUrl: string | null;
  activeTab: 'images' | 'virtualTours';
  setActiveTab: (newActiveTab: 'images' | 'virtualTours') => void;
  visibleElementOnTours: 'video' | 'tourEmbed';
  setVisibleElementOnTours: (newVisibleElement: 'video' | 'tourEmbed') => void;
  sourcePage: string;
  sourceContent?: string;
  isTabletDisplay?: boolean;
  address: Address;
  companyName?: string;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
};

export const LightboxLayout = ({
  assets,
  imagesZipUrl,
  currentIndex,
  onClose,
  onChange,
  video,
  tourEmbedUrl,
  activeTab,
  setActiveTab,
  visibleElementOnTours,
  setVisibleElementOnTours,
  sourcePage,
  sourceContent,
  isTabletDisplay = false,
  address,
  companyName,
  analyticsInformation,
}: Props) => {
  const { t } = useTranslation('listing');
  const slider = useRef<Slider | null>(null);
  const [showControls, setShowControls] = useState(true);
  const [didMount, setDidMount] = useState(false);
  const [isChangingImages, setIsChangingImages] = useState(false);
  const [isControlsHovered, setIsControlsHovered] = useState(false);
  const anchor = useRef<HTMLAnchorElement | null>(null);

  const { galleryInteraction, videoInteraction, threeDTourInteraction, PARAMETERS } =
    useAnalytics();
  const paginationClicked = () =>
    galleryInteraction({
      sourcePage,
      sourceContent: sourceContent || PARAMETERS.lightboxGallery,
      action: PARAMETERS.paginate,
      actionType: 'GALLERY_PAGINATION_CLICKED',
      analyticsInformation,
    });
  const thumbnailClicked = (action: string = PARAMETERS.clickThumbnail) =>
    galleryInteraction({
      sourcePage,
      sourceContent: sourceContent || PARAMETERS.lightboxGallery,
      action,
      actionType: 'GALLERY_THUMBNAIL_CLICKED',
      analyticsInformation,
    });

  const handleCloseLightbox = () => {
    onClose();
    const analyticsParams = {
      actionType: 'LIGHTBOX_CLOSED',
      action: PARAMETERS.closeLightbox,
      sourcePage,
      sourceContent: sourceContent || PARAMETERS.lightboxGallery,
      analyticsInformation,
    };
    if (activeTab === 'virtualTours') {
      if (visibleElementOnTours === 'video') {
        videoInteraction(analyticsParams);
      } else {
        threeDTourInteraction(analyticsParams);
      }
    } else {
      galleryInteraction(analyticsParams);
    }
  };

  const handleMouseOver = () => {
    if (isTabletDisplay) return;
    if (isControlsHovered) {
      setShowControls(true);
    } else {
      setShowControls(false);
    }
  };

  const onMouseOver = () => {
    if (isTabletDisplay) return;
    setIsControlsHovered(true);
    handleMouseOver();
  };

  const onMouseLeave = () => {
    if (isTabletDisplay) return;
    setIsControlsHovered(false);
    handleMouseOver();
  };

  const sliderSettings = {
    adaptiveHeight: true,
    arrows: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <PreviousArrow
        analyticsEvent={paginationClicked}
        hide={!showControls}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    ),
    nextArrow: (
      <NextArrow
        analyticsEvent={paginationClicked}
        hide={!showControls}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    ),
    afterChange: (index: number) => {
      onChange(index);
      setIsChangingImages(false);
    },
    initialSlide: currentIndex,
    ref: slider,
  };
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const hasVirtualTour = !!video || !!tourEmbedUrl;

  const hoverTimeout = useRef() as any;
  const staticMapIndex = assets.findIndex(photo => photo.description === 'Static Map');
  const floorPlanIndex = assets.findIndex(photo => photo.type === 'floorPlan');
  const isOnStaticMap = staticMapIndex === currentIndex;

  const handleKeyDown = event => {
    if (event.key === 'ArrowLeft' && activeTab === 'images') {
      const previousIndex = (currentIndex + assets.length - 1) % assets.length;
      slider.current.slickGoTo(previousIndex);
      paginationClicked();
    }
    if (event.key === 'ArrowRight' && activeTab === 'images') {
      const nextIndex = (currentIndex + assets.length + 1) % assets.length;
      slider.current.slickGoTo(nextIndex);
      paginationClicked();
    }
    if (event.key === 'Escape') {
      handleCloseLightbox();
    }
  };

  const changeCurrentIndexAndCarousel = (index: number) => {
    if (!isChangingImages) {
      onChange(index);
      setIsChangingImages(true);
    }

    if (slider.current) {
      slider.current.slickGoTo(index);
    }
  };

  // this is because the controls going away is horrible
  // when you are actively developing them. Uncomment this line out
  // if you are developing the controls and want them to stay
  // const { railsEnv } = useEnv();
  const handleMotionStop = () => {
    if (isTabletDisplay) return;
    // if (railsEnv === 'development') return;
    if (!isControlsHovered) {
      setShowControls(false);
      setIsControlsHovered(false);
    }
  };

  const handleMouseMove = () => {
    if (isTabletDisplay) return;
    if (!isControlsHovered) {
      setShowControls(true);
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = setTimeout(handleMotionStop, 1500);
    } else {
      clearTimeout(hoverTimeout.current);
    }
  };

  // FIXME: Either add the exhaustive deps or delete this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setDidMount(true);
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });
  const onDownloadAllLinkClick = async event => {
    event.preventDefault();
    const response = await api.fetch(imagesZipUrl);
    const { url } = await response.json();

    anchor.current!.href = url;
    anchor.current!.click();
  };

  const handleClickMapThumbnail = () => {
    changeCurrentIndexAndCarousel(staticMapIndex);
    thumbnailClicked(PARAMETERS.viewMap);
  };

  const [areControlsVisibleOnMobile, setAreControlsVisibleOnMobile] = useState(true);

  return (
    <CSSTransition
      in={didMount}
      timeout={500}
      classNames={{
        enter: s.enter,
        enterActive: s.enterActive,
      }}
      unmountOnExit
    >
      <div
        data-testid={`full-width-lightbox-${isTabletDisplay ? 'tablet' : 'desktop'}`}
        className={cn(s.newLightbox, isTabletDisplay && s.condensed, isInIframe() && 'mt-[11px]')}
        onMouseMove={handleMouseMove}
      >
        {hasVirtualTour ? (
          <TabControls
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onClose={handleCloseLightbox}
            isTabletDisplay={isTabletDisplay}
            sourcePage={sourcePage}
            analyticsInformation={analyticsInformation}
          />
        ) : (
          <IconButton
            title="close"
            icon="close"
            onClick={handleCloseLightbox}
            className={s.closeButton}
          />
        )}
        <section
          data-testid="imagesSection"
          className={cn(s.tab, activeTab === 'virtualTours' && s.hidden)}
        >
          <Slider {...sliderSettings}>
            {assets.map(asset => (
              <div key={asset.path}>
                <Asset
                  address={address}
                  asset={asset}
                  autoplay={false}
                  companyName={companyName}
                  withTabbedTop={hasVirtualTour}
                  isTabletDisplay={isTabletDisplay}
                  onClick={() => setAreControlsVisibleOnMobile(!areControlsVisibleOnMobile)}
                />
              </div>
            ))}
          </Slider>
          <div
            className={cn({
              [s.controlContainer]: true,
              [s.hidden]: !showControls,
              [s.withVirtualTour]: hasVirtualTour,
            })}
          >
            <OnlyInDesktopTablet>
              <SlideCount currentIndex={currentIndex} totalCount={assets.length} />
            </OnlyInDesktopTablet>
            <OnlyInMobile className={cn(s.fading, !areControlsVisibleOnMobile && s.hidden)}>
              <SlideCount currentIndex={currentIndex} totalCount={assets.length} />
            </OnlyInMobile>

            {isTabletDisplay ? null : (
              <>
                <SingleDownloadButtonLink
                  downloadPath={assets[currentIndex]?.downloadPath}
                  disabled={isOnStaticMap}
                  onMouseOver={onMouseOver}
                  onMouseLeave={onMouseLeave}
                  title={t('gallery.downloadImageTooltip')}
                  onClick={() => {
                    galleryInteraction({
                      actionType: 'GALLERY_IMAGE_DOWNLOADED',
                      sourcePage,
                      sourceContent: sourceContent || PARAMETERS.lightboxGallery,
                      action:
                        currentIndex === floorPlanIndex
                          ? PARAMETERS.downloadFloorPlan
                          : PARAMETERS.downloadImage,
                      analyticsInformation,
                    });
                  }}
                />
                <DownloadAllImages
                  downloadUrl={imagesZipUrl}
                  onMouseOver={onMouseOver}
                  onMouseLeave={onMouseLeave}
                  onClick={() => {
                    galleryInteraction({
                      actionType: 'GALLERY_BULK_DOWNLOAD_IMAGES',
                      sourcePage,
                      sourceContent: sourceContent || PARAMETERS.lightboxGallery,
                      action: PARAMETERS.bulkDownload,
                      analyticsInformation,
                    });
                  }}
                />
              </>
            )}
            <OnlyInDesktopTablet>
              <ImageThumbnails
                address={address}
                assets={assets}
                companyName={companyName}
                currentIndex={currentIndex}
                onSelect={index => {
                  changeCurrentIndexAndCarousel(index);
                  thumbnailClicked();
                }}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                isTabletDisplay={isTabletDisplay}
                isMobileDisplay={false}
                sourcePage={sourcePage}
                sourceContent={sourceContent || PARAMETERS.lightboxGallery}
                analyticsInformation={analyticsInformation}
              />
            </OnlyInDesktopTablet>
            <OnlyInMobile className={cn(s.fading, !areControlsVisibleOnMobile && s.hidden)}>
              <ImageThumbnails
                address={address}
                assets={assets}
                companyName={companyName}
                currentIndex={currentIndex}
                onSelect={index => {
                  changeCurrentIndexAndCarousel(index);
                  thumbnailClicked();
                }}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
                isMobileDisplay
                isTabletDisplay={false}
                sourcePage={sourcePage}
                sourceContent={sourceContent || PARAMETERS.lightboxGallery}
                analyticsInformation={analyticsInformation}
              />
            </OnlyInMobile>
            <OnlyInTablet>
              <OverflowMenu
                overflowClassName={s.overflowMenu}
                overlayClassName={s.overflowOverlay}
                iconClass={s.overflowMenuIcon}
                activeIconClass={s.activeOverlayIcon}
                dropdownPlacement="topRight"
              >
                <a
                  href={assets[currentIndex]?.downloadPath}
                  aria-label="download image"
                  download
                  className={cn([isOnStaticMap && s.disabled])}
                >
                  <OverflowMenuItem
                    disabled={isOnStaticMap}
                    onClick={() => {
                      galleryInteraction({
                        actionType: 'TABLET_GALLERY_IMAGE_DOWNLOADED',
                        sourcePage,
                        sourceContent: sourceContent || PARAMETERS.lightboxGallery,
                        action:
                          currentIndex === floorPlanIndex
                            ? PARAMETERS.downloadFloorPlan
                            : PARAMETERS.downloadImage,
                        analyticsInformation,
                      });
                    }}
                  >
                    <CustomIcon type="download2" />
                    {t('gallery.downloadImageTooltip')}
                  </OverflowMenuItem>
                </a>
                {imagesZipUrl ? (
                  <a ref={anchor} href={imagesZipUrl} aria-label="download image" download>
                    <OverflowMenuItem
                      onClick={e => {
                        onDownloadAllLinkClick(e);
                        galleryInteraction({
                          actionType: 'TABLET_GALLERY_BULK_DOWNLOAD_IMAGES',
                          sourcePage,
                          sourceContent: sourceContent || PARAMETERS.lightboxGallery,
                          action: PARAMETERS.bulkDownload,
                          analyticsInformation,
                        });
                      }}
                    >
                      <CustomIcon type="download-multiple" />
                      {t('gallery.downloadAllImagesTooltip')}
                    </OverflowMenuItem>
                  </a>
                ) : null}
              </OverflowMenu>
            </OnlyInTablet>
            <section className={s.secondaryThumbnails}>
              <OnlyInDesktopTablet>
                <FloorPlanThumbnail
                  onClick={() => {
                    changeCurrentIndexAndCarousel(floorPlanIndex);
                    thumbnailClicked(PARAMETERS.viewFloorPlan);
                  }}
                  floorPlanIndex={floorPlanIndex}
                  isSelected={currentIndex === floorPlanIndex}
                  onMouseOver={onMouseOver}
                  onMouseLeave={onMouseLeave}
                />
              </OnlyInDesktopTablet>
              <OnlyInMobile className={cn(s.fading, !areControlsVisibleOnMobile && s.hidden)}>
                <FloorPlanThumbnail
                  onClick={() => {
                    changeCurrentIndexAndCarousel(floorPlanIndex);
                    thumbnailClicked(PARAMETERS.viewFloorPlan);
                  }}
                  floorPlanIndex={floorPlanIndex}
                  isSelected={currentIndex === floorPlanIndex}
                  onMouseOver={onMouseOver}
                  onMouseLeave={onMouseLeave}
                />
              </OnlyInMobile>

              <OnlyInDesktopTablet>
                <MapThumbnail
                  onClick={handleClickMapThumbnail}
                  mapImage={assets[staticMapIndex]}
                  isSelected={currentIndex === staticMapIndex}
                  onMouseOver={onMouseOver}
                  onMouseLeave={onMouseLeave}
                  address={address}
                />
              </OnlyInDesktopTablet>
              <OnlyInMobile className={cn(s.fading, !areControlsVisibleOnMobile && s.hidden)}>
                <MapIconButton
                  address={address}
                  mapImage={assets[staticMapIndex]}
                  onClick={handleClickMapThumbnail}
                  isSelected={currentIndex === staticMapIndex}
                />
              </OnlyInMobile>
              <OnlyInMobile className={cn(s.fading, !areControlsVisibleOnMobile && s.hidden)}>
                <OverflowMenu
                  overflowClassName={s.overflowMenu}
                  overlayClassName={s.overflowOverlay}
                  iconClass={s.overflowMenuIcon}
                  activeIconClass={s.activeOverlayIcon}
                  dropdownPlacement="topRight"
                >
                  <a
                    href={assets[currentIndex]?.downloadPath}
                    aria-label="download image"
                    download
                    className={cn([isOnStaticMap && s.disabled])}
                  >
                    <OverflowMenuItem
                      disabled={isOnStaticMap}
                      onClick={() => {
                        galleryInteraction({
                          actionType: 'TABLET_GALLERY_IMAGE_DOWNLOADED',
                          sourcePage,
                          sourceContent: sourceContent || PARAMETERS.lightboxGallery,
                          action:
                            currentIndex === floorPlanIndex
                              ? PARAMETERS.downloadFloorPlan
                              : PARAMETERS.downloadImage,
                          analyticsInformation,
                        });
                      }}
                    >
                      <CustomIcon type="download2" />
                      {t('gallery.downloadImageTooltip')}
                    </OverflowMenuItem>
                  </a>
                  {imagesZipUrl ? (
                    <a ref={anchor} href={imagesZipUrl} aria-label="download image" download>
                      <OverflowMenuItem
                        onClick={e => {
                          onDownloadAllLinkClick(e);
                          galleryInteraction({
                            actionType: 'MOBILE_GALLERY_BULK_DOWNLOAD_IMAGES',
                            sourcePage,
                            sourceContent: sourceContent || PARAMETERS.lightboxGallery,
                            action: PARAMETERS.bulkDownload,
                            analyticsInformation,
                          });
                        }}
                      >
                        <CustomIcon type="download-multiple" />
                        {t('gallery.downloadAllImagesTooltip')}
                      </OverflowMenuItem>
                    </a>
                  ) : null}
                </OverflowMenu>
              </OnlyInMobile>
            </section>
          </div>
        </section>
        <section
          data-testid={`virtualToursSection-${isTabletDisplay ? 'tablet' : 'desktop'}`}
          className={cn(s.virtualTourTab, activeTab === 'images' && s.hidden)}
        >
          <VirtualToursTab
            analyticsInformation={analyticsInformation}
            address={address}
            companyName={companyName}
            video={video}
            tourEmbedUrl={tourEmbedUrl}
            firstAsset={assets[0] as MultipathImage}
            isActive={activeTab === 'virtualTours'}
            isVisible={visibleElementOnTours}
            setIsVisible={setVisibleElementOnTours}
            sourcePage={sourcePage}
            isTabletDisplay={isTabletDisplay}
          />
        </section>
      </div>
    </CSSTransition>
  );
};

export default (props: Props) => {
  return (
    <>
      <OnlyInTabletMobile>
        <LightboxLayout {...props} isTabletDisplay />
      </OnlyInTabletMobile>
      <OnlyInDesktop>
        <LightboxLayout {...props} />
      </OnlyInDesktop>
    </>
  );
};
