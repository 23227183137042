import React, { useEffect, useRef, useState } from 'react';
import { CustomIcon } from '@components/shared';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import s from './FileInput.module.less';

interface FileInputProps extends React.HTMLProps<HTMLInputElement> {
  cb?: (file: FileList, name: string) => void;
  filename?: string;
  onClose?: (name: string) => void;
}

export const FileInput = (props: FileInputProps) => {
  const { cb, onClose } = props;
  const [filetype, setFiletype] = useState('');
  const [errorText, setErrorText] = useState('');
  const { t } = useTranslation('admin');
  const MAX_MB_SIZE = 25;
  const chooseFileString = t('building.basicInfo.choose_file');
  const sizeString = t('building.basicInfo.max_size_in_mb', { size: MAX_MB_SIZE });
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const resetInput = () => {
    if (fileUploadRef && fileUploadRef.current) {
      // this makes sure errors show up on every upload and not just first
      fileUploadRef.current.value = '';
      setFiletype('');
    }
  };

  const handleClose = () => {
    resetInput();
    if (fileUploadRef && fileUploadRef.current) {
      if (typeof onClose === 'function') {
        onClose(fileUploadRef.current.name);
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ONE_MB_DECIMAL = 1000000;
    const MAX_SIZE = ONE_MB_DECIMAL * MAX_MB_SIZE;
    const { files, name } = event.target;

    if (!files) return;

    const file = (files as FileList)[0];
    const fileType = file.type.split('/')[1]; // if filetype is application/pdf, store just 'pdf'

    if (file.size > MAX_SIZE) {
      const fileSizeError = t('building.channels.errors.file_size', { size: MAX_MB_SIZE });

      setErrorText(() => fileSizeError);
      resetInput();
    } else if (fileType !== 'pdf') {
      setErrorText(() => t('building.channels.errors.file_type_pdf'));
      resetInput();
    } else if (files.length > 1) {
      setErrorText(() => t('building.channels.errors.multiple_pdfs'));
      resetInput();
    } else {
      setFiletype(fileType);
    }

    if (typeof cb === 'function') {
      cb(files, name);
    }
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (errorText !== '') {
      timerId = setTimeout(() => {
        setErrorText('');
      }, 5000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [errorText]);

  const isFiletypePdf = filetype === 'pdf' || props.filename?.includes('.pdf');

  return (
    <div className={s.positionRelative}>
      <input
        data-testid="building-form-file-input"
        accept={props.accept}
        className={s.uploadInput}
        id={props.id}
        name={props.name}
        onChange={handleFileUpload}
        ref={fileUploadRef}
        type="file"
        {...omit(props, 'cb')}
      />
      <label htmlFor={props.id} className={cn({ [s.errorBorder]: errorText !== '' })}>
        <div className={cn(s.uploadIconAndText, { [s.center]: isFiletypePdf })}>
          <CustomIcon
            className={s.channelsUpload}
            type={isFiletypePdf ? 'pdfFilled' : 'uploadOutlined'}
          />
          <span className={s.chooseFile}>{props.filename || chooseFileString}</span>
        </div>
        {!props.filename ? <span className={s.sizeLimit}>{sizeString}</span> : null}
      </label>
      {props.filename ? (
        <CustomIcon
          data-testid="building-form-file-input-close"
          className={s.closeIcon}
          type="close"
          onClick={() => handleClose()}
        />
      ) : null}
      <span className={s.uploadError}>{errorText}</span>
    </div>
  );
};

export default FileInput;
