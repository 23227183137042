import { useState } from 'react';
import { Error, ErrorResponse } from '@root/types';
import api from '@shared/api';
import { isUndefined } from 'lodash';

const useSave = ({
  onSuccess,
  onFailure,
  persisted = true,
  createRoute,
  updateRoute,
}: {
  onSuccess?: (responseBody?: any) => void | Promise<void>;
  onFailure?: (r: ErrorResponse | null) => void | Promise<void>;
  persisted: boolean;
  createRoute?: string;
  updateRoute?: string;
}) => {
  const [errors, setErrors] = useState<Error[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (values, { useFormData } = { useFormData: false }) => {
    const saveFn = persisted ? api.put : api.post;
    const route = persisted ? updateRoute : createRoute;
    let body = values;
    const options: { useJson?: boolean } = {};

    if (useFormData) {
      body = new FormData();
      Object.keys(values).forEach(key => {
        if (!isUndefined(values[key])) {
          body.append(key, values[key]);
        }
      });
      options.useJson = false;
    }

    await setIsSaving(true);
    await saveFn(route, body, options).then(async response => {
      if (response.ok) {
        setErrors([]);
        let responseBody = null;

        try {
          responseBody = await response.json();
        } catch {
          responseBody = null;
        }
        setIsSaving(false);
        if (onSuccess) await onSuccess(responseBody);
      } else {
        let errorResponse: ErrorResponse | null = null;

        try {
          errorResponse = await response.json();
        } catch {
          errorResponse = null;
        }
        setIsSaving(false);
        if (errorResponse) setErrors(errorResponse.errors);
        if (onFailure) await onFailure(errorResponse);
      }
    });
  };

  return {
    errors,
    onSubmit,
    isSaving,
  };
};

export default useSave;
