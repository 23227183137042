import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import api from '@shared/api';
import VideoSection from '@components/shared/Admin/VideoSection';
import type { DefaultVideoUploadType } from '@components/shared/Admin/MultiVideoUploader';
import ThreeDContent from '@components/shared/Admin/ThreeDContent';

import {
  AdminListingPhoto,
  AdminListingVideo,
  AdminBrochure,
  ContentShootRequest,
  SpaceFloorPlanPhoto,
  SpaceAdditionalPhoto,
  AdminFloorPlan,
  Room,
  VirtualTourSpotlights,
} from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FloorPlanSection from './FloorPlanSection';
import ContentShootDetails from './ContentShootDetails';
import ExistingContent from './ExistingContent';
import ListingMediaPhotoSection from './ListingMediaPhotoSection';

export type Props = {
  fetchListing: () => void;
  photos: AdminListingPhoto[];
  archivedPhotos: AdminListingPhoto[];
  video: AdminListingVideo | null;
  archivedVideos: AdminListingVideo[];
  rooms: Room[];
  tourEmbedUrl: string | null;
  listingId: string;
  contentShootRequest: ContentShootRequest | null;
  formatNotificationErrorMessages: (messages: Array<string>) => JSX.Element;
  spaceFloorPlanPhotos: SpaceFloorPlanPhoto[];
  additionalPhotos: SpaceAdditionalPhoto[];
  spaceBrochure: AdminBrochure | null;
  buildingBrochure: AdminBrochure | null;
  floorPlan: AdminFloorPlan | null;
  archivedFloorPlans: AdminFloorPlan[];
  virtualTourSpotlight: VirtualTourSpotlights;
};

const ListingMediaTable = ({
  photos,
  archivedPhotos,
  video,
  archivedVideos,
  rooms,
  fetchListing,
  listingId,
  tourEmbedUrl,
  formatNotificationErrorMessages,
  contentShootRequest,
  spaceFloorPlanPhotos,
  additionalPhotos,
  spaceBrochure,
  buildingBrochure,
  floorPlan,
  archivedFloorPlans,
  virtualTourSpotlight,
}: Props) => {
  const { t } = useTranslation('admin');

  const flags = useFlags();
  const newVideoOffset = flags['market-retail.mp-listing-requested-at-date'] ? 1 : 0;

  const videoUploadRequest = (uploadedFile: DefaultVideoUploadType) =>
    api.post(routes.api.admin.listingVideoCreate(listingId), {
      cloudinary_id: uploadedFile.cloudinaryId,
      filename: uploadedFile.filename,
      poster_start_offset: newVideoOffset,
      processed_at: uploadedFile.processedAt,
    });

  const updateTourEmbedUrl = async (value: string) => {
    const resp = await api.put(routes.api.admin.listing(listingId), {
      tour_embed_url: value,
    });
    const json = await resp.json();

    if (resp.ok) {
      fetchListing();
      return true;
    }
    notification.error({ message: formatNotificationErrorMessages(json.messages) });
    return false;
  };

  const updateVirtualTourSpotlight = async (newValue: Exclude<VirtualTourSpotlights, 'unset'>) => {
    const resp = await api.put(routes.api.admin.listing(listingId), {
      virtualTourSpotlight: newValue,
    });

    if (resp.ok) {
      fetchListing();
      return true;
    }
    return false;
  };

  const deleteListingVideo = async (videoId = video?.id) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('media.deleteConfirmation'))) {
      const resp = await api.delete(routes.api.admin.listingVideoDelete(listingId, videoId));
      const json = await resp.json();
      if (resp.ok) {
        fetchListing();
        notification.success({ message: json.message });
      } else {
        notification.error({ message: json.errors[0].message });
      }
    }
  };

  const selectLiveListingVideo = async (videoId: string) => {
    const resp = await api.put(routes.api.admin.listingVideoUpdate(listingId, videoId), {
      archived: false,
    });
    if (resp.ok) {
      fetchListing();
      notification.success({ message: t('media.videoSelectSuccess') });
    } else {
      notification.success({ message: t('media.videoSelectFailure') });
    }
  };

  const hideListingVideo = async (videoId: string) => {
    const resp = await api.put(routes.api.admin.listingVideoUpdate(listingId, videoId), {
      archived: true,
    });
    if (resp.ok) {
      fetchListing();
      notification.success({ message: t('media.videoHideSuccess') });
    } else {
      notification.success({ message: t('media.videoHideFailure') });
    }
  };

  const updateThumbnailPosterTimestamp = async (posterStartOffset: number | null) => {
    const resp = await api.put(routes.api.admin.listingVideoUpdate(listingId, video?.id), {
      posterStartOffset,
    });
    return resp;
  };

  return (
    <div className="u-p-top-3x">
      <div>
        <FloorPlanSection
          floorPlan={floorPlan}
          archivedFloorPlans={archivedFloorPlans}
          fetchListing={fetchListing}
          listingId={listingId}
        />
      </div>
      <ListingMediaPhotoSection
        photos={photos}
        fetchListing={fetchListing}
        listingId={listingId}
        archivedPhotos={archivedPhotos}
        rooms={rooms}
      />
      <div className="pt-5">
        <VideoSection
          key={video?.id}
          video={video}
          archivedVideos={archivedVideos}
          onSuccess={fetchListing}
          spotlightChecked={!!video && !!tourEmbedUrl}
          videoTableTitle={t('media.listingVideoTitle')}
          videoHelpText={t('media.listingVideoHelpText')}
          videoSelectText={t('media.listingSelectVideo')}
          videoUploadRequest={videoUploadRequest}
          onVideoDelete={deleteListingVideo}
          onVideoSelect={selectLiveListingVideo}
          onVideoHide={hideListingVideo}
          onPosterUpdate={updateThumbnailPosterTimestamp}
          onUpdateVirtualTourSpotlight={updateVirtualTourSpotlight}
          virtualTourSpotlight={virtualTourSpotlight}
          totalVariableColumnsWidth="52vw"
        />
      </div>
      <div className="pt-5">
        <ThreeDContent
          tourEmbedUrl={tourEmbedUrl}
          onTourEmbedUrlUpdate={updateTourEmbedUrl}
          onVirtualTourSpotlightUpdate={updateVirtualTourSpotlight}
          virtualTourSpotlight={virtualTourSpotlight}
          hasVideo={!!video}
          totalVariableColumnsWidth="52vw"
          tableTitle={t('media.listing3dContentTitle')}
        />
      </div>
      {contentShootRequest ? (
        <div className="pt-5">
          <ContentShootDetails contentShootRequest={contentShootRequest} />
        </div>
      ) : null}
      <div className="pt-5">
        <ExistingContent
          floorPlanPhotos={spaceFloorPlanPhotos}
          additionalPhotos={additionalPhotos}
          spaceBrochure={spaceBrochure}
          buildingBrochure={buildingBrochure}
        />
      </div>
    </div>
  );
};

export default ListingMediaTable;
