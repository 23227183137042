import {
  countryToCurrencyCode,
  countryToCurrencySymbol,
  currencyCodeToSymbol,
} from '@root/shared/currencyByCountry';
import { AmenityKey, ListingAmenityValue, CurrentUser, BuildingAmenityValue } from '@root/types';

const dateRegex = /^\d{2}\/\d{2}\/\d{4}/;

type AmenityValue = ListingAmenityValue | BuildingAmenityValue;
type AmenityObject = {
  key: AmenityKey;
  value: AmenityValue;
};

const Amenities: AmenityObject[] = [
  { key: 'barAvailable', value: 'bar_available' },
  { key: 'barbershop', value: 'barbershop' },
  { key: 'bikeStorageAvailable', value: 'bike_storage_available' },
  { key: 'breakOutSpace', value: 'break_out_space' },
  { key: 'cafeAvailable', value: 'cafe_available' },
  { key: 'carWash', value: 'car_wash' },
  { key: 'cctv', value: 'cctv' },
  { key: 'commonAreaWiFi', value: 'common_area_wifi' },
  { key: 'communalLobbySpace', value: 'communal_lobby_space' },
  { key: 'communalOutdoorSpace', value: 'communal_outdoor_space' },
  { key: 'communalRoofAccess', value: 'communal_roof_access' },
  { key: 'conferenceCenter', value: 'conference_center' },
  { key: 'controlledElevatorAccess', value: 'controlled_elevator_access' },
  { key: 'coveredParking', value: 'covered_parking' },
  { key: 'destinationDispatchElevators', value: 'destination_dispatch_elevators' },
  { key: 'evChargingStations', value: 'electric_vehicle_charging_stations_count' },
  { key: 'eventSpace', value: 'event_space' },
  { key: 'flexSpaceAvailable', value: 'flex_space_available' },
  { key: 'foodServiceAvailable', value: 'food_service_available' },
  { key: 'furnished', value: 'furnished' },
  { key: 'generalStoreAvailable', value: 'general_store_available' },
  { key: 'gymAvailable', value: 'gym_available' },
  { key: 'keyCardBuildingEntry', value: 'key_card_building_entry' },
  { key: 'keyCardSpaceEntry', value: 'key_card_space_entry' },
  { key: 'loadingDock', value: 'loading_dock' },
  { key: 'lobbyAttendant', value: 'lobby_attendant' },
  { key: 'lobbyBeverageAvailable', value: 'lobby_beverage_available' },
  { key: 'lobbyFoodAvailable', value: 'lobby_food_available' },
  { key: 'lockerRooms', value: 'locker_rooms' },
  { key: 'messengerCenter', value: 'messenger_center' },
  { key: 'networkingEvents', value: 'networking_events' },
  { key: 'optionToAddISP', value: 'option_to_add_internet_service_provider' },
  { key: 'parkingAvailable', value: 'parking_available' },
  { key: 'parkingGarage', value: 'parking_garage' },
  { key: 'passengerElevatorCount', value: 'passenger_elevator_count' },
  { key: 'petsAllowed', value: 'pets_allowed' },
  { key: 'pharmacyAvailable', value: 'pharmacy_available' },
  { key: 'phoneBooths', value: 'phone_booths' },
  { key: 'privateOutdoorSpace', value: 'private_outdoor_space' },
  { key: 'propertyManagerOnSite', value: 'property_manager_on_site' },
  { key: 'restaurantAvailable', value: 'restaurant_available' },
  { key: 'serviceElevatorCount', value: 'service_elevator_count' },
  { key: 'sharedPantry', value: 'shared_pantry' },
  { key: 'showers', value: 'showers' },
  { key: 'shuttle', value: 'shuttle' },
  { key: 'spaAvailable', value: 'spa_available' },
  { key: 'storageAvailable', value: 'storage_available' },
  { key: 'tenantLounge', value: 'tenant_lounge' },
  { key: 'tenantPortalApp', value: 'tenant_portal_app' },
  { key: 'touchFreeElevatorAccess', value: 'touch_free_elevator_access' },
  { key: 'touchlessTurnstileEntry', value: 'touchless_turnstile_entry' },
  { key: 'transportationAccessible', value: 'transportation_accessible' },
  { key: 'turnstiles', value: 'turnstiles' },
  { key: 'twentyFourSevenAccess', value: 'twenty_four_seven_access' },
  { key: 'twentyFourSevenSecurity', value: 'twenty_four_seven_security' },
  { key: 'urgentCareDoctorOnsite', value: 'urgent_care_doctor_onsite' },
  { key: 'valetParking', value: 'valet_parking' },
  { key: 'yogaStudioAvailable', value: 'yoga_studio_available' },
];

export const amenityOptions: Array<{ value: AmenityValue; label: string }> = Amenities.map(
  amenity => ({
    value: amenity.value,
    label: `filters:amenityLabels:${amenity.key}`,
  }),
);

export const currencySymbol = (
  market: any | null,
  user: CurrentUser | null,
  locale,
  showOnlyCurrency?: boolean,
): string => {
  const userLocale = user?.languagePreference || locale || 'en-us';
  if (market) {
    const currencyCode = countryToCurrencyCode(market.countryCode);
    return currencyCodeToSymbol(currencyCode, locale, showOnlyCurrency);
  }
  return countryToCurrencySymbol('US', userLocale, showOnlyCurrency);
};

export default dateRegex;
