import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { StoreState } from '@root/types';
import { connect, ConnectedProps } from 'react-redux';
import AmenitiesFilter from './AmenitiesFilter';
import LandLordFilter from './LandlordFilter';
import BuildingsFilter from './BuildingsFilter';
import PossessionFilterList from './PossessionFilter';
import CeilingHeightFilter from './CeilingHeightFilter';
import KeywordFilter from './KeywordFilter';
import SpaceConditionsFilter from './SpaceConditionsFilter';
import SubmarketFilter from './SubmarketFilter';
import TermsFilter from './TermsFilter';
import SizeFilters from './SizeFilter';
import FirstToMarketFilter from './FirstToMarketFilter';
import LeaseTypeFilter from './LeaseTypeFilter/LeaseTypeFilter';
import PriceFilter from './PriceFilter';
import AccordionFilterGroup from './AccordionFilterGroup';
import s from './Filters.module.less';
import { useListingSearchCriteria } from '../utils';

type AccordionKey =
  | 'submarkets'
  | 'buildings'
  | 'landlords'
  | 'leaseTypes'
  | 'terms'
  | 'possession'
  | 'spaceCondition'
  | 'ceilingHeight'
  | 'amenities'
  | 'keywords';
type AccordionExpandedStatus = {
  [key in AccordionKey]?: boolean;
};

const mapState = (state: StoreState) => ({
  activeSavedSearchId: state.savedSearches.activeId,
});

const connector = connect(mapState);
type ReduxProps = ConnectedProps<typeof connector>;

const hasValue = (value): boolean => value || (Array.isArray(value) && value.length === 0);

const ListingSearchFilters = ({ activeSavedSearchId }: ReduxProps) => {
  const { t } = useTranslation('filters');
  const criteria = useListingSearchCriteria();

  const [accordionExpandedStatus, setAccordionExpandedStatus] = useState<AccordionExpandedStatus>(
    {},
  );
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (activeSavedSearchId || isFirstRender) {
      setAccordionExpandedStatus({
        submarkets: hasValue(criteria.currentFilters.submarkets),
        buildings: hasValue(criteria.currentFilters.buildingIds),
        landlords: hasValue(criteria.currentFilters.landlords),
        leaseTypes: hasValue(criteria.currentFilters.leaseTypes),
        terms: hasValue(criteria.currentFilters.terms),
        possession: hasValue(criteria.currentFilters.possession),
        spaceCondition: hasValue(criteria.currentFilters.spaceCondition),
        ceilingHeight:
          hasValue(criteria.currentFilters.ceilingHeightMagnitude) &&
          hasValue(criteria.currentFilters.ceilingHeightType),
        amenities: hasValue(criteria.currentFilters.amenities),
        keywords: hasValue(criteria.currentFilters.keywords),
      });
      setIsFirstRender(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSavedSearchId]);

  const setAccordionIsOpen = (accordionKey: AccordionKey, isOpen: boolean): void => {
    setAccordionExpandedStatus({ ...accordionExpandedStatus, [accordionKey]: isOpen });
  };

  return (
    <ul className={s.filterListContainer} data-testid="listing-search-filters">
      <li>
        <FirstToMarketFilter />
      </li>
      <li className={s.filterListItemContainer}>
        <SizeFilters />
      </li>
      <li className={s.filterListItemContainer}>
        <PriceFilter />
      </li>
      <AccordionFilterGroup
        title={t('submarket.title')}
        accordionIsOpen={!!accordionExpandedStatus.submarkets}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('submarkets', isOpen)}
      >
        <SubmarketFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('buildings.title')}
        accordionIsOpen={!!accordionExpandedStatus.buildings}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('buildings', isOpen)}
      >
        <BuildingsFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('landlord.title')}
        accordionIsOpen={!!accordionExpandedStatus.landlords}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('landlords', isOpen)}
      >
        <LandLordFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('leaseType.title')}
        accordionIsOpen={!!accordionExpandedStatus.leaseTypes}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('leaseTypes', isOpen)}
      >
        <LeaseTypeFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('term.title')}
        accordionIsOpen={!!accordionExpandedStatus.terms}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('terms', isOpen)}
      >
        <TermsFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('availability.title')}
        accordionIsOpen={!!accordionExpandedStatus.possession}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('possession', isOpen)}
      >
        <PossessionFilterList />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('spaceCondition.shortTitle')}
        accordionIsOpen={!!accordionExpandedStatus.spaceCondition}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('spaceCondition', isOpen)}
      >
        <SpaceConditionsFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('ceilingHeight.title')}
        accordionIsOpen={!!accordionExpandedStatus.ceilingHeight}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('ceilingHeight', isOpen)}
      >
        <CeilingHeightFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('amenities.title')}
        accordionIsOpen={!!accordionExpandedStatus.amenities}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('amenities', isOpen)}
      >
        <AmenitiesFilter />
      </AccordionFilterGroup>
      <AccordionFilterGroup
        title={t('keywords.title')}
        accordionIsOpen={!!accordionExpandedStatus.keywords}
        setAccordionIsOpen={isOpen => setAccordionIsOpen('keywords', isOpen)}
      >
        <KeywordFilter />
      </AccordionFilterGroup>
    </ul>
  );
};

export default connector(ListingSearchFilters);

export { ListingSearchFilters as RawListingSearchFilters };
