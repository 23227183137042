import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { getCurrentPageType } from '@store/selectors';
import { useHistory } from 'react-router-dom';
import useCurrentUser from '@root/shared/useCurrentUser';
import routes from '@root/routes';
import { MarketSlug } from '@root/types';
import { updateCurrentUser } from '@store/actions/currentUser';
import { Modal, CustomIcon, Notification } from '@components/shared';
import useEnums from '@shared/useEnums';
import { Form, Select, SubmitButton } from '@components/shared/forms';
import { isInIframe } from '@root/shared/iframeUtils';
import s from './OnboardingModal.module.less';

const OnboardingModal = () => {
  const { t } = useTranslation('common');
  const { currentUser, refetch } = useCurrentUser();
  const currentPage = useSelector(getCurrentPageType);
  const history = useHistory();

  const { markets, changableRoles } = useEnums();
  const marketOptions: Array<{ value: MarketSlug; label: string }> = markets
    .filter(market => market.isPublic)
    .sort((marketA, marketB) =>
      t(`markets.${marketA.id}`).localeCompare(t(`markets.${marketB.id}`)),
    )
    .map(market => ({
      value: market.id,
      label: t(`markets.${market.id}`),
    }));

  const roleOptions = changableRoles.map(role => ({
    label: t(`roles.${role}`),
    value: role,
  }));

  const validationSchema = yup.object().shape({
    marketSlug: yup.string().nullable().required(t('onboarding.needMarket')),
    role: yup.string().nullable().required(t('onboarding.needRole')),
  });
  type FormValues = { marketSlug: MarketSlug; role: string };

  const dispatch = useDispatch();
  const onSubmit = async (values: FormValues) => {
    await dispatch(
      updateCurrentUser({
        user: { id: currentUser!.id, ...values },
        source: 'onboarding modal',
        action: 'update professional info',
        onSuccess: () => {
          if (currentPage === 'LISTING_SEARCH') {
            history.push(routes.search(values.marketSlug));
          }
          refetch();
          Notification.info({
            title: t('saved'),
            /* eslint-disable react/jsx-no-literals */
            text: (
              <Trans ns="common" i18nKey="onboarding.success">
                You can always adjust these details in your
                <a href={`${routes.users.account}?page=profile`}>Account settings</a>
              </Trans>
            ),
            /* eslint-enable react/jsx-no-literals */
          });
        },
      }),
    );
  };

  const isOpen = currentUser?.role === 'unknown' && !isInIframe();

  return (
    <Modal className={s.onboardingModal} isOpen={isOpen} closable={false} maskClosable={false}>
      <section className={s.header}>
        <CustomIcon className={s.logo} type="vts-logo-indigo" />
        <h1 className={s.title}>{t('onboarding.title')}</h1>
        <h2 className={s.subtitle}>{t('onboarding.subtitle')}</h2>
      </section>
      <section className={s.body}>
        <aside className={s.explanation}>{t('onboarding.explanation')}</aside>
        <Form<FormValues>
          validationSchema={validationSchema}
          id="onboarding-modal"
          data-testid="form"
          onSubmit={onSubmit}
        >
          <div className={s.selects}>
            <Select
              containerClass={s.select}
              name="marketSlug"
              options={marketOptions}
              labelText={t('onboarding.market')}
              placeholder={t('onboarding.marketPlaceholder')}
              required
              isClearable={false}
            />
            <Select
              name="role"
              containerClass={s.select}
              options={roleOptions}
              labelText={t('onboarding.role')}
              placeholder={t('onboarding.rolePlaceholder')}
              required
              isClearable={false}
            />
          </div>
          <SubmitButton size="large" className={s.submit}>
            {t('save')}
          </SubmitButton>
        </Form>
      </section>
    </Modal>
  );
};

export default OnboardingModal;
