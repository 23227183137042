import cn from 'classnames';
import { UploadApiResponse as CloudinaryResponse, ImageTransformationOptions } from 'cloudinary';
import { Image } from 'cloudinary-react';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CustomIcon } from '@components/shared';
import { CloudinaryFileButtonInput } from '@components/shared/forms';
import { CurrentUser, MultipathImage } from '@root/types';
import useEnv from '@shared/useEnv';
import s from './PhotoDisplay.module.less';

type Props = {
  currentUser: CurrentUser;
  name: 'tourbookAvatarCloudinaryId' | 'tourbookCompanyLogoCloudinaryId';
  label: string;
  image: MultipathImage | undefined;
  cloudinaryTransformationOptions: ImageTransformationOptions;
  isEditable?: boolean;
  className?: string;
  setCloudinaryLoading: (loading: boolean) => void;
  handleCloudinaryFileInputError: () => void;
  altText: string;
};

const PhotoDisplay = ({
  currentUser,
  name,
  label,
  image,
  cloudinaryTransformationOptions,
  isEditable,
  className,
  setCloudinaryLoading,
  handleCloudinaryFileInputError,
  altText,
}: Props) => {
  const { t } = useTranslation('accountSettings');
  const { setFieldTouched, setFieldValue, touched } = useFormikContext();
  const { cloudinaryVtsImageCdn, cloudinaryVtsImageCloud } = useEnv();
  const [cloudinaryId, setCloudinaryId] = useState<string>('');
  const handleCloudinaryFileInputLoading = (loading: boolean) => {
    setCloudinaryLoading(loading);
  };

  const onReady = (response: CloudinaryResponse) => {
    setFieldTouched(name, true);
    setFieldValue(name, response.public_id);
    setCloudinaryId(response.public_id);
  };

  useEffect(() => {
    if (!touched[name]) {
      // If field is not touched, reset cloudinaryId
      setCloudinaryId(image?.cloudinaryId || '');
    }
  }, [touched, name, image]);

  return (
    <div className={cn(s.container, className)}>
      <label className={s.photoDisplayLabel}>{label}</label>
      <div className={s.thumb}>
        {!isEmpty(cloudinaryId) ? (
          <Image
            {...cloudinaryTransformationOptions}
            cloudName={cloudinaryVtsImageCloud}
            privateCdn={cloudinaryVtsImageCdn}
            publicId={cloudinaryId}
            alt={altText}
          />
        ) : null}
        {isEmpty(cloudinaryId) && name === 'tourbookAvatarCloudinaryId' ? (
          <div className={s.initials}>
            {currentUser?.firstName[0]}
            {currentUser?.lastName && currentUser?.lastName[0]}
          </div>
        ) : null}
        {isEmpty(cloudinaryId) && name === 'tourbookCompanyLogoCloudinaryId' ? (
          <CustomIcon
            className={cn(s.companyLogoIcon, 'flex', 'items-center', 'justify-center')}
            type="company-logo"
          />
        ) : null}
        {isEditable && (
          <>
            <Button className={s.uploadButton} size="small">
              {t('common:upload')}
            </Button>
            <CloudinaryFileButtonInput
              name={name}
              labelText=""
              onLoading={handleCloudinaryFileInputLoading}
              onReady={onReady}
              onError={handleCloudinaryFileInputError}
              buttonClassName={s.uploadButton}
              uploadToCore
            />
          </>
        )}
      </div>
    </div>
  );
};

PhotoDisplay.defaultProps = {
  isEditable: false,
};

export default PhotoDisplay;
