import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Checkbox from '@components/shared/forms/Checkbox';
import { MultipathImage } from '@root/types';
import { NumberInput, TextInput } from '@components/shared/forms';
import Divider from '@components/shared/Divider';
import Typography from '@styles/typography.module.less';

import EmptyImagePlaceholder from '@components/shared/images/empty-image-placeholder.svg';
import css from './BasicInfo.module.less';

type Props = {
  roomPhotos: Array<MultipathImage>;
};

const BasicInfo = React.forwardRef(
  ({ roomPhotos }: Props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
    const { t } = useTranslation('admin');
    return (
      <>
        <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-3x', 'u-p-bottom-2x')}>
          {t('listing.rooms.form.basicInfo.title')}
        </h2>
        <section className={css.listingRoomFormSection}>
          <div className={css.name}>
            <TextInput
              fast
              labelText={t('listing.rooms.form.basicInfo.name')}
              name="name"
              required
            />
          </div>
          <div className={css.capacity}>
            <NumberInput
              fast
              labelText={t('listing.rooms.form.basicInfo.maxCapacity')}
              name="maximumCapacity"
            />
          </div>

          <figure className={css.imageContainer}>
            {roomPhotos.length > 0 ? (
              <img
                className={css.image}
                src={roomPhotos[0].smallPath}
                alt={roomPhotos[0].description}
              />
            ) : (
              <EmptyImagePlaceholder
                style={{ display: 'block', borderRadius: '3px' }}
                width="100%"
                height="100%"
                data-testid="emptyImagePlaceholder"
              />
            )}
          </figure>
          <div className={css.worksAsOffice}>
            <Checkbox name="worksAsOffice">
              <span>{t('listing.rooms.form.basicInfo.worksAsOffice')}</span>
            </Checkbox>
          </div>
        </section>
        <Divider />
      </>
    );
  },
);

export default React.memo(BasicInfo);
