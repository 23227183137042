import { CustomIcon } from '@components/shared';
import cn from 'classnames';
// eslint-disable-next-line import/no-unresolved
import { components } from 'react-select';
import s from './Dropdowns.module.less';

export const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <CustomIcon type="close-circle" />
  </components.ClearIndicator>
);

const DropdownIndicator = props => {
  // eslint-disable-next-line react/destructuring-assignment
  const iconType = props.selectProps.menuIsOpen ? 'dropdown-up' : 'dropdown-down';
  return (
    <components.DropdownIndicator {...props} className={s.dropdownIndicator}>
      <CustomIcon type={iconType} />
    </components.DropdownIndicator>
  );
};

const Placeholder = props => <components.Placeholder {...props} className={s.placeholder} />;

const Option = props => {
  const { children, isSelected, isFocused } = props;
  return (
    <components.Option
      {...props}
      className={cn(s.option, isSelected && s.selected, isFocused && s.focused)}
    >
      {children}
      {isSelected ? (
        <aside className={s.selectedCheckmark}>
          <CustomIcon type="checkmark" />
        </aside>
      ) : null}
    </components.Option>
  );
};

const Input = props => <components.Input {...props} autoComplete="none" />;

const Control = props => <components.Control {...props} className={s.control} />;

const MenuList = props => <components.MenuList {...props} className={s.menuList} />;

export default {
  ClearIndicator,
  Placeholder,
  DropdownIndicator,
  IndicatorSeparator: null,
  Option,
  Control,
  MenuList,
  Input,
};
