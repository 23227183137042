import { Delete } from '@components/shared/CustomIcon/icons';
import classNames from 'classnames';
import s from './HoverMenu.module.less';

type HoverMenuType = {
  photoUrl: string;
  order: number;
  onPhotoDelete?: (photoOrder: number) => void;
  onHoverTitle: string;
  hoverBackdrop: string;
};

type DeleteButtonType = {
  onPhotoDelete?: (photoOrder: number) => void;
  photoOrder: number;
};

type ViewImageType = {
  url: string;
  onHoverTitle: string;
};

const DeleteButton = ({ onPhotoDelete, photoOrder }: DeleteButtonType) => {
  return (
    <div className={s.delete}>
      <Delete onClick={() => onPhotoDelete && onPhotoDelete(photoOrder)} />
    </div>
  );
};

const ViewImageButton = ({ url, onHoverTitle }: ViewImageType) => {
  return (
    <div className={s.viewImage}>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={classNames(s.imageLink, 'u-p-horz-xl u-p-vert-xs')}
      >
        {onHoverTitle}
      </a>
    </div>
  );
};

const HoverMenu = ({
  photoUrl,
  order,
  onPhotoDelete,
  onHoverTitle,
  hoverBackdrop,
}: HoverMenuType) => {
  return (
    <div className={hoverBackdrop}>
      <DeleteButton onPhotoDelete={onPhotoDelete} photoOrder={order} />
      <ViewImageButton url={photoUrl} onHoverTitle={onHoverTitle} />
    </div>
  );
};

export default HoverMenu;
