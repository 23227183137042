import defaultMapStyles from '@shared/googleMapsStyles';
import { getMarkerLabel } from './util';

const pixelsPerInch = 96;

const buildMapStyles = () => {
  let mapStyles = '';

  Object.keys(defaultMapStyles).forEach(property => {
    mapStyles += `&style=feature:${defaultMapStyles[property].featureType}`;

    if (defaultMapStyles[property].elementType) {
      mapStyles += `|element:${defaultMapStyles[property].elementType}`;
    }

    (defaultMapStyles[property].stylers as [any]).forEach(styles => {
      Object.keys(styles).forEach(styleProp => {
        let propValue = styles[styleProp];

        if (styleProp === 'color') {
          propValue = propValue.replace('#', '0x');
        }

        mapStyles += `|${styleProp}:${propValue}`;
      });
    });
  });

  return mapStyles;
};

const buildBase = (h, w, googleMapsKey) => {
  return `https://maps.googleapis.com/maps/api/staticmap?size=${Math.round(
    w * pixelsPerInch,
  )}x${Math.round(h * pixelsPerInch)}&maptype=roadmap&key=${googleMapsKey}${buildMapStyles()}`;
};

export const buildOverviewMapUrl = (buildings, googleMapsKey, h, w) => {
  let url = buildBase(h, w, googleMapsKey);

  buildings.forEach((building, buildingIndex) => {
    let markerSegment = `&markers=color:0x5528ff`;
    markerSegment += `|size:mid`;
    markerSegment += `|label:${getMarkerLabel(buildings.length, buildingIndex, true)}`;
    markerSegment += `|${building.latitude},`;
    markerSegment += `${building.longitude}`;

    url += markerSegment;
  });

  url += buildMapStyles();

  return url;
};

export const buildBuildingMapUrl = (building, googleMapsKey, h, w) => {
  let url = buildBase(h, w, googleMapsKey);

  let markerSegment = `&markers=color:0x5528ff|size:mid`;
  markerSegment += `|${building.latitude},`;
  markerSegment += `${building.longitude}`;

  url += markerSegment;

  return url;
};

export const buildListingMapUrl = (building, googleMapsKey, h, w) => {
  let url = buildBase(h, w, googleMapsKey);

  let markerSegment = `&markers=size:mid`;
  markerSegment += `|${building.latitude},`;
  markerSegment += `${building.longitude}`;

  url += markerSegment;

  return url;
};

export default buildOverviewMapUrl;
