import api from '@shared/api';
import routes from '@root/routes';
import { SearchListing, Listing, ListingStatus } from '@root/types';
import { EVENTS, PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';

type PagesWithToggle =
  | 'home page'
  | 'listing page'
  | 'search results page'
  | 'saved listings page'
  | 'tourbook page'
  | 'building page';
type SavedListingAction = {
  type: 'LISTING_SAVED';
  payload: {
    listingId: string;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Save Interaction';
        sourcePage: PagesWithToggle;
        sourceContent: string | undefined;
        action: 'save item';
        truvaListingId: string;
        buildingName: string;
        listingName: string;
        listingStatus: ListingStatus;
        isExclusiveListing: boolean;
      };
    };
  };
};
export const savedListing = (
  listing: SearchListing | Listing,
  sourcePage: PagesWithToggle,
  sourceContent?: string,
  otherAttributes?: { [x: string]: any },
): SavedListingAction => ({
  type: 'LISTING_SAVED',
  payload: {
    listingId: listing.id,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.saveInteraction,
        sourcePage,
        sourceContent,
        action: PARAMETERS.saveItem,
        ...listing.analyticsInformation,
        ...otherAttributes,
      },
    },
  },
});

type UnsavedListingAction = {
  type: 'LISTING_UNSAVED';
  payload: {
    listingId: string;
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Save Interaction';
        sourcePage: PagesWithToggle;
        sourceContent: string | undefined;
        action: 'unsave item';
        truvaListingId: string;
        buildingName: string;
        listingName: string;
        listingStatus: ListingStatus;
        isExclusiveListing: boolean;
      };
    };
  };
};
export const unsavedListing = (
  listing: SearchListing | Listing,
  sourcePage: PagesWithToggle,
  sourceContent?: string,
  otherAnalyticsAttributes?: { [x: string]: any },
): UnsavedListingAction => ({
  type: 'LISTING_UNSAVED',
  payload: {
    listingId: listing.id,
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.saveInteraction,
        sourcePage,
        sourceContent,
        action: PARAMETERS.unsaveItem,
        ...listing.analyticsInformation,
        ...otherAnalyticsAttributes,
      },
    },
  },
});

const handleErrors = async response => {
  if (response.ok) return response;

  const errors = await response.json();
  throw Error(errors);
};
const ignoreErrors = () => {};

type Arguments = {
  listing: SearchListing | Listing;
  willBeSaved: boolean;
  onSuccess: () => void;
  sourcePage?: PagesWithToggle;
  sourceContent?: string;
  otherAnalyticsAttributes?: { [x: string]: any };
};

const toggleSaveListing =
  ({
    listing,
    willBeSaved,
    onSuccess,
    sourcePage = 'listing page',
    sourceContent,
    otherAnalyticsAttributes,
  }: Arguments) =>
  dispatch => {
    if (willBeSaved) {
      api
        .post(routes.api.listingFavorites(listing.id))
        .then(handleErrors)
        .then(() => {
          dispatch(savedListing(listing, sourcePage, sourceContent, otherAnalyticsAttributes));
          if (onSuccess) onSuccess();
        })
        .catch(ignoreErrors);
    } else {
      api
        .delete(routes.api.listingFavorites(listing.id))
        .then(handleErrors)
        .then(() => {
          dispatch(unsavedListing(listing, sourcePage, sourceContent, otherAnalyticsAttributes));
          if (onSuccess) onSuccess();
        })
        .catch(ignoreErrors);
    }
  };

export default toggleSaveListing;
export type Actions = SavedListingAction | UnsavedListingAction;
