import { TextArea } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import { ContentBlockWrapper } from '..';

export default function Metadata() {
  const { t } = useTranslation('admin');

  return (
    <ContentBlockWrapper
      contentBlockId="metadata"
      wrapInDraggable={false}
      title={t('microsite.sections.metadata')}
      section="metadata"
    >
      <TextArea
        name="googleSitesVerificationCode"
        labelText={t('microsite.googleSitesVerificationCode.title')}
        placeholder={t('microsite.googleSitesVerificationCode.placeholder')}
        className="w-full"
      />
    </ContentBlockWrapper>
  );
}
