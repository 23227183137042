import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioInputComponent from '../RadioInputComponent';
import s from './HeroMedia.module.less';
import styles from '../common.module.less';
import HeroImage from './HeroImage';
import HeroVideo from './HeroVideo';
import MarketingMediaHeader from '../MarketingMediaHeader/MarketingMediaHeader';

type HeroMediaValues = {
  cloudinaryId: string;
  description: string;
  mediaType: string;
  order: number;
  title: string;
};

type MarketingMedia = {
  heroImage: HeroMediaValues[];
  heroVideo: HeroMediaValues[];
};

const HeroMedia = ({ values }: { values: MarketingMedia }) => {
  const { t } = useTranslation('admin');
  const [collapseMedia, setCollapseMedia] = useState(true);
  const [selected, setSelected] = useState('photo');

  const { heroImage, heroVideo } = values;
  const currentMediaType =
    heroImage[0].cloudinaryId === '' &&
    (heroVideo[0].cloudinaryId !== '' || heroVideo[0].title !== '')
      ? 'video'
      : selected;

  let isVideoDisabled = false;
  let isPhotoDisabled = false;

  const onChange = event => {
    setSelected(event);
  };

  if (values.heroImage[0].cloudinaryId !== '' || values.heroImage[0].title !== '') {
    isVideoDisabled = true;
  } else if (values.heroVideo[0].cloudinaryId !== '' || values.heroVideo[0].title !== '') {
    isPhotoDisabled = true;
  } else {
    isPhotoDisabled = false;
    isVideoDisabled = false;
  }

  return (
    <section className={styles.mainSection}>
      <MarketingMediaHeader
        collapseMedia={collapseMedia}
        mediaTitle={t('marketingMedia.heroMedia.title')}
        mediaLimit={
          currentMediaType === 'photo'
            ? t('marketingMedia.heroImage.maxCount')
            : t('marketingMedia.heroVideo.maxCount')
        }
        setCollapseMedia={setCollapseMedia}
      />
      {collapseMedia ? (
        <>
          <div className={s.radioButtonMainDiv}>
            <div className={s.radioButtonDiv}>
              <RadioInputComponent
                name="photo"
                value="photo"
                onChange={onChange}
                labelText={t('marketingMedia.photos')}
                checked={currentMediaType === 'photo'}
                disabled={isPhotoDisabled}
                title={t('marketingMedia.heroMedia.heroImageMessage')}
              />
              <RadioInputComponent
                name="video"
                value="video"
                onChange={onChange}
                labelText={t('marketingMedia.video')}
                checked={currentMediaType === 'video'}
                disabled={isVideoDisabled}
                title={t('marketingMedia.heroMedia.heroVideoMessage')}
              />
            </div>
          </div>
          {currentMediaType === 'photo' ? (
            <HeroImage data={values.heroImage} />
          ) : (
            <HeroVideo data={values.heroVideo} />
          )}
        </>
      ) : null}
    </section>
  );
};

export default HeroMedia;
