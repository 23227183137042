import Select, { components } from 'react-select';
import { compact, isUndefined } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Avatar from '@components/shared/Avatar/Avatar';
import CompanyLogo from '@components/shared/CompanyLogo/CompanyLogo';
import cn from 'classnames';
import { AgentContact } from '@root/types';
import useAnalytics, { InquirySourceContent, InquirySourcePage } from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import { OptionType, SelectValue } from '../types';
import s from './ContactSelect.module.less';
import CustomStyles from './CustomStyles';

export type Props = {
  contacts: AgentContact[];
  inputId: string;
  onChange: (val: SelectValue) => void;
  sourceContent: InquirySourceContent;
  sourcePage: InquirySourcePage;
  isDisabled?: boolean;
  value?: SelectValue;
  analyticsMetadata?: { [x: string]: any };
};

const ContactSelect = ({
  contacts,
  inputId,
  onChange,
  isDisabled,
  value,
  sourceContent,
  sourcePage,
  analyticsMetadata,
}: Props) => {
  const flags = useFlags();

  const options = contacts.map(
    (contact: AgentContact): OptionType => ({ label: contact.name, value: contact.id }),
  );

  const { contactInteraction } = useAnalytics();
  const handleDropdownOnClick = () => {
    contactInteraction({
      action: PARAMETERS.contactDropdown,
      actionType: 'CONTACT_DROPDOWN_CLICK',
      sourceContent,
      sourcePage,
      otherAttributes: analyticsMetadata,
    });
  };
  const handleContactSelect = (newValue: SelectValue) => {
    const contactIndex = contacts.findIndex(c => c.id === newValue);
    contactInteraction({
      action: PARAMETERS.contactSelectContact,
      actionType: 'CONTACT_SELECT_CONTACT',
      sourceContent,
      sourcePage,
      otherAttributes: {
        contactRanking: contactIndex + 1,
        contactInfo: {
          name: contacts[contactIndex].name,
          role: contacts[contactIndex].role,
          company: contacts[contactIndex].company,
        },
        contactAvatar: contacts[contactIndex].photoUrl ? 'yes' : 'no',
        ...analyticsMetadata,
      },
    });
  };

  const defaultValue = options.length ? options[0] : null;

  const getValue = () => {
    // guard clause so that this can be uncontrolled if needed
    if (isUndefined(value)) return defaultValue;

    const foundValue = options.find(option => option.value === value);
    return foundValue || defaultValue;
  };

  const contactPhotos = ({ name, photoUrl, companyLogo }: Partial<AgentContact>) => {
    return flags['outreach.branding-general'] ? (
      <div className={cn('mr-1 flex items-center', companyLogo && '!mr-0.5')}>
        {name ? (
          <Avatar
            name={name}
            photoUrl={photoUrl}
            className="!mr-0 !h-5 !w-5 border-[0.5px] border-solid border-black-100"
          />
        ) : null}
        {companyLogo && (
          <CompanyLogo
            userName={name!}
            photoUrl={companyLogo.path}
            className="relative left-[-4px] !h-5 !w-5 [&>img]:border-[0.5px] [&>img]:border-solid [&>img]:border-black-100"
          />
        )}
      </div>
    ) : (
      <div>{name ? <Avatar name={name} photoUrl={photoUrl} /> : null}</div>
    );
  };

  const Option = props => {
    const { data } = props;
    const contact = contacts.find(c => c.id === data.value);
    const { name: contactName, company, role, photoUrl, companyLogo } = contact || {};

    const roleCompany = compact([role, company]).join(', ');

    return (
      <components.Option {...props}>
        <div className={s.optionContainer}>
          {contactPhotos({ name: contactName, photoUrl, companyLogo })}
          <div>
            <div className={s.contactName}>{contactName}</div>
            {roleCompany.length > 0 && (
              <div className={s.contactDetails}>
                {role ? <div>{role}</div> : null}
                {company ? <div>{company}</div> : null}
              </div>
            )}
          </div>
        </div>
      </components.Option>
    );
  };

  const SingleValue = props => {
    const contact = contacts.find(c => c.id === props.data.value);
    const roleCompany = compact([contact?.role, contact?.company]).join(', ');
    const { name: contactName, company, role, photoUrl, companyLogo } = contact || {};

    return (
      <components.SingleValue {...props}>
        <div className={s.optionContainer}>
          {contact ? (
            <>
              {contactPhotos({ name: contactName, photoUrl, companyLogo })}
              <div>
                <div className={s.contactName}>{contactName}</div>

                {roleCompany.length > 0 && (
                  <div className={s.truncatedContactDetails}>
                    {role ? <span>{role}</span> : null}{' '}
                    {role && company ? <span className={s.contactSeparator}>{' | '}</span> : null}
                    {company ? <span>{company}</span> : null}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </components.SingleValue>
    );
  };

  return (
    <Select
      name="receiverIds"
      placeholder=""
      value={getValue()}
      onChange={(option: OptionType | null) => {
        const newValue = option ? option.value : null;
        handleContactSelect(newValue);
        onChange(newValue);
      }}
      options={options}
      inputId={inputId}
      isSearchable={false}
      isDisabled={isDisabled}
      components={{
        Option,
        SingleValue,
      }}
      styles={CustomStyles}
      menuPortalTarget={document.body}
      onMenuOpen={handleDropdownOnClick}
    />
  );
};

export default ContactSelect;
