import actions from '@store/actions';
import { fetchTourbookSummaries } from './fetchTourbookSummaries';

const manageTourbookPageLoad = () => async dispatch => {
  await dispatch(actions.setManageTourbookPage());
  await dispatch(fetchTourbookSummaries());
  await dispatch(actions.manageTourbookPageLoaded());
};

export default manageTourbookPageLoad;
