import { Button, CustomIcon, Breadcrumbs } from '@components/shared';
import actions from '@store/actions';
import Container from '@components/shared/Admin/Container';
import PaginationRow from '@components/shared/PaginationRow';
import routes from '@root/routes';
import useQueryParams from '@shared/useQueryParams';
import { get } from '@shared/typedApi';
import { RawInput, RawSelect } from '@components/shared/forms';
import { Pagination, AdminBuildingIndex } from '@root/types';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import BuildingsTable from '@components/shared/BuildingsTable';
import { useEffect } from 'react';
import utils from '@styles/utils.module.less';
import { stringify } from 'qs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Layout from '../Layout/Layout';

const getFullQueryParams = (queryParams: any) => ({
  query: queryParams.query || '',
  page: queryParams.page || 1,
  spaceProviders: queryParams.space_providers || [],
  statuses: queryParams.statuses || [],
  cities: queryParams.cities || [],
  states: queryParams.states || [],
  countries: queryParams.countries || [],
});

export default function BuildingSearch() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.addBreadcrumbs([t('breadcrumbs.admin'), t('breadcrumbs.buildings')]));
    return () => {
      dispatch(actions.removeBreadcrumbs());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation('admin');
  const flags = useFlags();
  const history = useHistory();
  const queryParams = useQueryParams();
  const fullQueryParams = getFullQueryParams(queryParams);
  const query = fullQueryParams.query || '';
  const spaceProviders = fullQueryParams.spaceProviders || [];
  const statuses = fullQueryParams.statuses || [];
  const cities = fullQueryParams.cities || [];
  const states = fullQueryParams.states || [];
  const countries = fullQueryParams.countries || [];
  const hasFilterValue =
    query.length > 0 ||
    spaceProviders.length > 0 ||
    statuses.length > 0 ||
    cities.length > 0 ||
    states.length > 0 ||
    countries.length > 0;

  const updateQueryParams = newParams => {
    const queryString = stringify(
      {
        ...queryParams,
        page: undefined,
        ...newParams,
      },
      { addQueryPrefix: true },
    );
    history.push(`${routes.admin.buildingSearch}${queryString}`);
  };

  const { data: filterData } = useQuery([routes.api.admin.buildingFilters], () =>
    get(routes.api.admin.buildingFilters),
  );

  const { data, isLoading } = useQuery<{ buildings: AdminBuildingIndex[]; pagination: Pagination }>(
    [routes.api.admin.buildings, fullQueryParams],
    () => get(routes.api.admin.buildingSearch(fullQueryParams)),
  );

  const pagination = data?.pagination || {};

  return (
    <Layout>
      <Breadcrumbs
        className="mb-1"
        items={[{ content: t('breadcrumbs.admin') }, { content: t('breadcrumbs.buildings') }]}
      />
      <Container
        title={t('building.searchTitle')}
        extra={
          <Button onClick={() => history.push(routes.admin.vtsPropertySearch)}>
            {t('building.addBuildingButton')}
          </Button>
        }
      >
        <div data-testid="searchFilters" className="mb-3 flex flex-wrap gap-1">
          <div className="mt-[20px] min-w-24 max-w-36">
            <RawInput
              className="h-[36px] !pb-[6px] !pt-[6px]"
              placeholder={t('building.placeholder')}
              value={query}
              name="query"
              prefix={<CustomIcon type="magnifying-glass" />}
              onChange={e =>
                updateQueryParams({
                  query: e.target.value,
                })
              }
            />
          </div>
          {flags['outreach.landlord-company-connection-severed'] ? (
            <SelectWithLabel
              className="min-w-24 max-w-36"
              placeholder={t('building.spaceProvider')}
              label={
                spaceProviders.length > 0
                  ? t('filters.spaceProviderWithCount', {
                      count: spaceProviders.length,
                    })
                  : t('filters.spaceProvider')
              }
              name="space_providers"
              options={(filterData?.landlords || []).map(landlord => ({
                value: landlord.id,
                label: landlord.name,
              }))}
              onChange={newSpaceProviders =>
                updateQueryParams({ space_providers: newSpaceProviders })
              }
              value={spaceProviders}
            />
          ) : (
            <SelectWithLabel
              className="min-w-24 max-w-36"
              placeholder={t('building.spaceProvider')}
              label={
                spaceProviders.length > 0
                  ? t('filters.spaceProviderWithCount', {
                      count: spaceProviders.length,
                    })
                  : t('filters.spaceProvider')
              }
              name="space_providers"
              options={(filterData?.companies || []).map(company => ({
                value: company.id,
                label: company.name,
              }))}
              onChange={newSpaceProviders =>
                updateQueryParams({ space_providers: newSpaceProviders })
              }
              value={spaceProviders}
            />
          )}
          <SelectWithLabel
            name="statuses"
            placeholder="Building Status"
            value={statuses}
            label={
              (fullQueryParams.statuses || []).length > 0
                ? t('filters.statusWithCount', { count: fullQueryParams.statuses.length })
                : t('filters.status')
            }
            options={[
              { value: 'inactive', label: t('building.statuses.inactive') },
              { value: 'onboarding', label: t('building.statuses.onboarding') },
              { value: 'ready_for_approval', label: t('building.statuses.ready_for_approval') },
              { value: 'live', label: t('building.statuses.live') },
              { value: 'retired', label: t('building.statuses.retired') },
            ]}
            onChange={s => updateQueryParams({ statuses: s })}
          />
          <SelectWithLabel
            name="cities"
            placeholder="City"
            value={cities}
            options={(filterData?.localities || []).map(city => ({
              label: city,
              value: city,
            }))}
            label={
              cities.length > 0
                ? t('filters.cityWithCount', {
                    count: cities.length,
                  })
                : t('filters.city')
            }
            onChange={c => updateQueryParams({ cities: c })}
          />
          <SelectWithLabel
            name="states"
            placeholder={t('building.stateRegion')}
            value={states}
            options={(filterData?.regions || []).map(region => ({
              label: region,
              value: region,
            }))}
            label={
              states.length > 0
                ? t('filters.stateRegionWithCount', {
                    count: states.length,
                  })
                : t('filters.stateRegion')
            }
            onChange={s => updateQueryParams({ states: s })}
          />
          <SelectWithLabel
            name="countries"
            placeholder="Country"
            value={countries}
            options={(filterData?.countryCodes || [])
              .map(countryCode => ({
                label: t(`country:${countryCode}`),
                value: countryCode,
              }))
              .sort((countryA, countryB) => (countryA.label > countryB.label ? 1 : -1))}
            label={
              countries.length > 0
                ? t('filters.countryWithCount', {
                    count: countries.length,
                  })
                : t('filters.country')
            }
            onChange={c => updateQueryParams({ countries: c })}
          />
          {hasFilterValue && (
            <div className="ml-3 mt-3">
              <button
                className="font-bold text-text-link"
                type="button"
                onClick={() => {
                  history.push(routes.admin.buildingSearch);
                }}
              >
                {t('building.clearAllButton')}
              </button>
            </div>
          )}
        </div>
        <BuildingsTable
          buildings={data ? data.buildings : []}
          loading={isLoading}
          actionButtons={id => (
            <div className="flex w-20 flex-col">
              <Link to={routes.admin.building(id)}>{t('building.editBuildingButton')}</Link>
              <Link to={routes.admin.buildingListings(id)}>
                {t('building.manageListingsButton')}
              </Link>
            </div>
          )}
        />
        <PaginationRow {...pagination} changePage={(page: number) => updateQueryParams({ page })} />
      </Container>
    </Layout>
  );
}

function SelectWithLabel({
  label,
  placeholder,
  name,
  onChange,
  options,
  value,
  className = 'min-w-20 max-w-36',
}: {
  label: string;
  placeholder: string;
  options: { label: string; value: string }[];
  name: string;
  onChange: (val) => void;
  value: string[];
  className?: string;
}) {
  return (
    <div className={className} data-testid={`search-${name}`}>
      <div className={utils.label}>{label}</div>
      <RawSelect
        isMulti
        isClearable={false}
        options={options}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={value}
        components={{
          DropdownIndicator: () => null,
        }}
      />
    </div>
  );
}
