import routes from '@root/routes';
import { LastInquiry, InquiryFormFields, QuickInquiryFormFields } from '@root/types';
import { useDispatch } from 'react-redux';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { create } from '@root/shared/typedApi';

type ReceiveCreatedInquiry = {
  type: 'RECEIVE_CREATED_INQUIRY';
  payload: LastInquiry;
};
const receiveCreatedInquiry = (lastInquiry: LastInquiry): ReceiveCreatedInquiry => ({
  type: 'RECEIVE_CREATED_INQUIRY',
  payload: lastInquiry,
});

const useCreateInquiryMutation = ({
  listingId,
  isQuickInquiry = false,
}: {
  listingId: string;
  isQuickInquiry: boolean;
}): UseMutationResult<
  LastInquiry, // This is the return type from the api call promise.
  unknown,
  // This the parameters to call on the mutate function:
  {
    body: (InquiryFormFields | QuickInquiryFormFields) & { subject: string };
    onSuccess: () => void;
    onError: () => void;
  },
  unknown
> => {
  const dispatch = useDispatch();

  return useMutation(
    async ({
      body,
    }: {
      body: (InquiryFormFields | QuickInquiryFormFields) & { subject: string };
      onSuccess: () => void;
      onError: () => void;
    }): Promise<LastInquiry> => {
      if (isQuickInquiry) {
        return create(routes.api.inquiries.quick(listingId), body);
      }
      return create(routes.api.inquiries.standard(listingId), body);
    },
    {
      onSuccess: async (newLastInquiry: LastInquiry, { onSuccess }) => {
        dispatch(receiveCreatedInquiry(newLastInquiry));
        onSuccess();
      },
      onError: async (_, { onError }) => {
        onError();
      },
    },
  );
};

export default useCreateInquiryMutation;
