import Button from '@components/shared/V2Button';
import copyToClipboard from '@root/shared/copyToClipboard';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { update } from '@shared/typedApi';
import routes from '@root/routes';
import actions from '@store/actions/tourbookPage';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Notification } from '@components/shared';
import cn from 'classnames';
import s from './TourbookShareButton.module.less';

const mapDispatch = {
  tourbookShare: shareUrl => actions.tourbookShare(shareUrl),
};

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

type TourbookProps = {
  tourbookId: string;
  shareUrl: string;
  analyticsSupport: boolean;
  firstSharedAt: string | null;
  buttonClassName?: string;
  iconClassName?: string;
  textClassName?: string;
};

const TourbookShareButton = ({
  tourbookId,
  shareUrl,
  analyticsSupport,
  firstSharedAt,
  tourbookShare,
  buttonClassName,
  iconClassName,
  textClassName,
}: TourbookProps & ReduxProps) => {
  const { t } = useTranslation('tourbook');
  const queryClient = useQueryClient();

  const updateTourbookFirstSharedMutation = useMutation(
    async () => update(routes.api.tourbookFirstShared(tourbookId), {}),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([routes.api.tourbook(tourbookId)]);
      },
    },
  );

  const onClick = () => {
    copyToClipboard({
      text: shareUrl,
      onSuccess: () => {
        // Send tourbook share interaction analytics
        tourbookShare(shareUrl);
        // Update tourbook first_shared time
        if (analyticsSupport && firstSharedAt === null) updateTourbookFirstSharedMutation.mutate();

        // Show notification message with share url
        Notification.info({
          title: t('header.copied'),
          text: (
            <a target="_blank" rel="noreferrer" href={shareUrl}>
              {t('header.clickHereToView')}
            </a>
          ),
          iframe: {
            text: `<>${t('header.clickHereToView')}</>`,
            links: [{ url: shareUrl, method: 'newTab' }],
          },
        });
      },
    });
  };

  return (
    <Button
      className={buttonClassName}
      iconClass={iconClassName}
      type="primary"
      icon="link"
      onClick={onClick}
    >
      <span className={cn(textClassName, s.iconText)}>{t('header.copyShareLink')}</span>
    </Button>
  );
};

export default connector(TourbookShareButton);
