import useEnv from '@shared/useEnv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'classnames';
import { Locale } from '@root/types';
import { parse, format, startOfToday } from 'date-fns';
import { dateFormat } from '@shared/TranslationHelpers/formatDate';
import s from './RawDateInput.module.less';

type Props = {
  onChange: (val: string | null) => void;
  name?: string;
  placeholder?: string;
  value: string | null;
  hasError?: boolean;
  className?: string;
  disabled?: boolean;
  autoComplete?: string;
};

export default function RawDateInput({
  className,
  value,
  onChange,
  name,
  placeholder,
  hasError,
  disabled,
  autoComplete,
}: Props) {
  const { locale } = useEnv();

  let valueAsDate = value ? parse(value, 'yyyy-MM-dd', new Date()) : null;
  if (!(valueAsDate instanceof Date && !Number.isNaN(valueAsDate.getTime()))) {
    valueAsDate = null;
  }

  const dateFormatToDisplay = dateFormat(locale as Locale);

  return (
    <DatePicker
      wrapperClassName={s.wrapper}
      className={cn(s.datePickerInput, hasError && s.hasError, className)}
      selected={valueAsDate}
      onChange={(newDate: Date | null) => {
        const formattedNewDate = newDate ? format(newDate, 'yyyy-MM-dd') : null;
        onChange(formattedNewDate);
      }}
      name={name}
      id={name}
      calendarClassName={s.calendarContainer}
      disabledKeyboardNavigation
      maxDate={new Date('02-29-9999')}
      placeholderText={placeholder}
      disabled={disabled}
      dateFormat={dateFormatToDisplay}
      autoComplete={autoComplete}
      dayClassName={date => {
        return format(date, 'yyyy-MM-dd') === format(valueAsDate || startOfToday(), 'yyyy-MM-dd')
          ? s.selectedDatePickerDay
          : s.datePickerDay;
      }}
    />
  );
}
