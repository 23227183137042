import { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import s from './ExpandableDescription.module.less';

type Props = {
  children: React.ReactNode;
  className?: string;
  labelQaTestId?: string;
};
const ExpandableDescription = ({ children, className, labelQaTestId }: Props) => {
  const { t } = useTranslation('common');

  const [isCollapsed, setIsCollapsed] = useState(true);
  const descriptionRef = useRef<HTMLPreElement | null>(null);
  const [descriptionNeedsExpansion, setDescriptionNeedsExpansion] = useState(false);

  // If the visible height of the element is smaller
  // than the scrollable height (even though scroll is disabled)
  const updateDescriptionNeedsExpansion = () => {
    const { current } = descriptionRef;
    if (current) {
      setDescriptionNeedsExpansion(current.offsetHeight < current.scrollHeight);
    }
  };

  useEffect(() => {
    updateDescriptionNeedsExpansion();
    window.addEventListener('resize', updateDescriptionNeedsExpansion);
    return () => window.removeEventListener('resize', updateDescriptionNeedsExpansion);
  }, []);

  const collapsedClassName = isCollapsed ? s.collapsed : s.expanded;

  return children ? (
    <div className={cn(className, s.container)} data-qa-testid={labelQaTestId}>
      <pre className={collapsedClassName} ref={descriptionRef}>
        {children}
      </pre>
      {isCollapsed && descriptionNeedsExpansion && (
        <span
          className={s.readMoreButton}
          role="button"
          onClick={() => {
            setIsCollapsed(false);
          }}
        >
          {t('readMore')}
        </span>
      )}
    </div>
  ) : null;
};

export default ExpandableDescription;
