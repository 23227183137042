import { useTranslation } from 'react-i18next';
import PageHead from '@components/shared/PageHead/PageHead';

const HomePageHead = () => {
  const { t } = useTranslation('homepage');

  return <PageHead title={t('title')} description={t('description')} />;
};

export default HomePageHead;
