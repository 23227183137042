import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { getSavedBuildingOwners } from '@store/selectors';
import { StoreState, BuildingOwnerSummary } from '@root/types';
import { TabPane, Spinner, ConfirmationModal, Notification } from '@components/shared';
import savedActions from '@store/actions/saved';
import Layout from '../Layout';
import BuildingOwnerRow from './BuildingOwnerRow';
import s from './BuildingOwners.module.less';

const mapState = (state: StoreState) => ({
  savedBuildingOwners: getSavedBuildingOwners(state),
});
const mapDispatch = {
  loadPage: () => savedActions.loadSavedBuildingOwnersPage(),
  fetchSavedBuildingOwners: () => savedActions.fetchSavedBuildingOwners(),
  deleteSavedBuildingOwner: ({
    slug,
    onSuccess,
    onFailure,
  }: {
    slug: string;
    onSuccess: () => void;
    onFailure: () => void;
  }) =>
    savedActions.toggleBuildingOwnerSave(slug, false, {
      onSuccess,
      onFailure,
      sourcePage: 'saved brands page',
    }),
  toggleNotification: (slug: string, receivesNotifications: boolean) =>
    savedActions.updateSavedBuildingOwner(slug, { receivesNotifications }),
  clickedBuildingOwner: () => savedActions.savedBuildingOwnerClick(),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const defaultModalState = { isOpen: false, owner: null };
export const BuildingOwners = ({
  savedBuildingOwners,
  deleteSavedBuildingOwner,
  toggleNotification,
  loadPage,
  clickedBuildingOwner,
  fetchSavedBuildingOwners,
}: ReduxProps) => {
  const { t } = useTranslation('favorites');

  useEffect(() => {
    loadPage();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    owner: BuildingOwnerSummary | null;
  }>(defaultModalState);
  const onDeleteClick = (owner: BuildingOwnerSummary) => {
    setModalState({ isOpen: true, owner });
  };
  const resetModalState = () => setModalState(defaultModalState);
  const onConfirmDelete = () => {
    deleteSavedBuildingOwner({
      slug: modalState.owner!.slug,
      onSuccess: () => {
        fetchSavedBuildingOwners();
        resetModalState();
      },
      onFailure: () => {
        Notification.info({
          title: t('common:genericFailureTitle'),
          text: t('common:genericFailureMessage'),
        });
        resetModalState();
      },
    });
  };

  return (
    <Layout mobileFullWidth active="buildingOwners">
      <TabPane key="buildingOwners">
        <div className={s.container}>
          {savedBuildingOwners ? (
            <>
              <h1 className={s.title}>
                {/* eslint-disable react/jsx-no-literals */}
                <Trans
                  i18nKey="favorites:numSavedBuildingOwners"
                  count={savedBuildingOwners.length}
                >
                  <span className="u-translation--bold">
                    {{ count: savedBuildingOwners.length }}
                  </span>
                  Saved Building Owners
                </Trans>
              </h1>
              <ConfirmationModal
                title={t('confirmRemoveBuildingOwnerTitle')}
                confirmationText={t('confirmRemoveBuildingOwnerBody')}
                onClose={resetModalState}
                onConfirm={onConfirmDelete}
                isOpen={modalState.isOpen}
              />
              {savedBuildingOwners.length > 0 ? (
                <div className={s.headerRow}>
                  <div className={s.logo}>{t('buildingOwner')}</div>
                  <div className={s.name}></div>
                  <div className={s.currentListings}>{t('listingsAvailable')}</div>
                  <div className={s.alerts}>{t('emailAlerts')}</div>
                  <div className={s.actions}></div>
                </div>
              ) : null}
              <ul className={s.owners}>
                {savedBuildingOwners.map(owner => (
                  <BuildingOwnerRow
                    key={owner.id}
                    owner={owner}
                    onDeleteClick={onDeleteClick}
                    toggleNotification={toggleNotification}
                    onClick={clickedBuildingOwner}
                  />
                ))}
              </ul>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </TabPane>
    </Layout>
  );
};

export default connector(BuildingOwners);
