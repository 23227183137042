import { FastField, Field, useFormikContext } from 'formik';
import cn from 'classnames';
import Input from '../RawInput';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';

type Props = {
  className?: string;
  description?: string;
  descriptionClass?: string;
  disabled?: boolean;
  fast?: boolean;
  placeholder?: string;
  autoComplete?: boolean;
  units?: string;
  disableIncrement?: boolean;
} & Omit<FieldWrapperProps, 'children'>;

const NumberInput = ({
  className,
  description,
  descriptionClass,
  disabled = false,
  fast = false,
  name,
  labelClass,
  containerClass,
  labelText,
  placeholder,
  required,
  autoComplete = true,
  units,
  disableIncrement = false,
}: Props) => {
  const Component = fast ? FastField : Field;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleKeyDown = disableIncrement
    ? e => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') e.preventDefault();
      }
    : () => {};

  return (
    <FieldWrapper
      {...{ description, descriptionClass, name, labelClass, containerClass, labelText, required }}
    >
      <Component name={name}>
        {({ field, meta }) => (
          <Input
            aria-describedby={`${name}-description ${name}-error`}
            autoComplete={autoComplete}
            className={cn([className])}
            disabled={disabled}
            {...field}
            hasError={meta.touched && meta.error}
            onChange={(e: { target: HTMLInputElement }) => {
              setFieldTouched(name, true);
              setFieldValue(name, e.target.value);
            }}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            type="number"
            units={units}
          />
        )}
      </Component>
    </FieldWrapper>
  );
};

export default NumberInput;
