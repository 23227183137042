import { useEffect } from 'react';
import { FieldWrapper, TextAreaAutosize } from '@components/shared/forms';
import { useBreakpoint } from '@shared/useBreakpoints';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFormikContext } from 'formik';
import routes from '@root/routes';
import { InquiryFormFields, LastInquiry, Listing } from '@root/types';
import { reformatISODateString } from '@root/shared/dateUtils';
import cn from 'classnames';

import { InquirySourceContent, InquirySourcePage } from '@root/shared/useAnalytics';
import s from './InquiryFormBody.module.less';
import ContactSelect from '../forms/Dropdowns/ContactSelect/ContactSelect';
import { SelectValue } from '../forms/Dropdowns/types';

const requiredLabelWithSubtext = (labelText: string, labelSubtext?: string) => (
  <div data-qa-testid="inquiry-mandatory-message">
    <span>{labelText}</span>
    {/* eslint-disable-next-line react/jsx-no-literals */}
    <span className="ml-[2px] mr-1 text-aqua-100">*</span>
    {labelSubtext && <span className={s.labelSubtext}>{labelSubtext}</span>}
  </div>
);

const preformatDate = (stringTime: string): string => {
  return reformatISODateString({ value: stringTime, afterFormat: 'yyyy-MM-dd' });
};

const formatTime = (stringTime: string): string => {
  return reformatISODateString({ value: stringTime, afterFormat: 'p' });
};

type Props = {
  listing: Listing | null;
  sourceContent: InquirySourceContent;
  sourcePage: InquirySourcePage;
  disabled?: boolean;
  isOnPreview?: boolean;
  lastInquiry?: LastInquiry;
};

const InquiryFormBody = ({
  listing,
  disabled = false,
  isOnPreview = false,
  sourceContent,
  sourcePage,
  lastInquiry,
}: // lastInquiry, uncomment and use with flag
Props) => {
  const defaultSelectedContactIndex = 0;
  const { t } = useTranslation('common');
  const { isMobile } = useBreakpoint();
  const {
    values: { message, receiverIds },
    setFieldValue,
  } = useFormikContext<InquiryFormFields>();

  const showFooter = !!lastInquiry;
  const footerClassName = isOnPreview ? s.footer : s.largeFooter;

  const handleChangeRecipient = (contact: SelectValue) => {
    setFieldValue('receiverIds', [contact]);
  };

  useEffect(() => {
    if (listing?.listingAgents.length) {
      setFieldValue('receiverIds', [listing.listingAgents[defaultSelectedContactIndex].id]);
    }
  }, [listing, setFieldValue]);

  const getInquiredWithText = (): String | null => {
    if (!lastInquiry?.receiverNames?.length) {
      return null;
    }

    return lastInquiry.receiverNames.length > 1
      ? t('inquiry.allLeasingTeamContacts')
      : lastInquiry.receiverNames[0];
  };

  return (
    <div>
      <FieldWrapper
        name="receiverIds"
        labelText={requiredLabelWithSubtext(t('inquiry.to.prefix'), t('inquiry.to.suffix'))}
        labelClass="!mb-0 !text-black-055"
        containerClass={isOnPreview ? s.fieldContainerClass : s.largeFieldContainerClass}
        inputContainerClass={s.fieldInputContainerClass}
        labelQaTestId="inquiry-contact"
      >
        <ContactSelect
          inputId="receiverIds"
          onChange={contact => handleChangeRecipient(contact)}
          contacts={listing?.listingAgents || []}
          value={receiverIds[0]}
          isDisabled={disabled}
          sourcePage={sourcePage}
          sourceContent={sourceContent}
          analyticsMetadata={listing?.analyticsInformation}
        />
      </FieldWrapper>
      <TextAreaAutosize
        labelText={t('inquiry.messageLabel')}
        name="message"
        required
        labelClass="!mb-0 !text-black-055"
        onFocus={() => {}}
        value={message}
        minRows={3}
        maxRows={isMobile ? 6 : undefined}
        className={cn(s.messageTextArea, !isOnPreview && s.large)}
        containerClass={s.fieldContainerClass}
        disabled={disabled}
        labelQaTestId="inquiry-message"
      />
      <section data-qa-testid="inquiry-details">
        <p
          className={cn(
            s.referToAccountSettings,
            !isOnPreview && s.large,
            !!lastInquiry && !isOnPreview && s.noBottomPadding,
          )}
        >
          {/* eslint-disable react/jsx-no-literals */}
          <Trans ns="inquiry" i18nKey="referToAccountSettings">
            Edit default message in
            <Link
              to={`${routes.users.account}?page=inquiry`}
              data-qa-testid="inquiry-account-settings"
            >
              Account settings
            </Link>
          </Trans>
          {/* eslint-enable react/jsx-no-literals */}
        </p>
        {showFooter ? (
          <div className={footerClassName}>
            {t('inquiry.lastInquirySent', {
              inquiredWith: getInquiredWithText(),
              sendDate: preformatDate(lastInquiry.lastSentAt),
              sendTime: formatTime(lastInquiry.lastSentAt),
            })}
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default InquiryFormBody;
