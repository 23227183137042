import React, { useMemo, useRef, useState } from 'react';

import { isNull } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RawInput from '@components/shared/forms/RawInput';
import {
  ListingSearchCriteria,
  useListingSearchCriteria,
} from '@components/layouts/Truva/ListingSearch/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import actions from '@store/actions/listingSearchPage';
import { CeilingHeightMeasurementType } from '@root/types/Listing';
import debounce from 'lodash/debounce';
import s from '../Filters.module.less';
import matchNumericInput from '../../utils/matchNumericInput';

const debounceLength = 1500;

const buildAnalyticStringValue = ({ type, magnitude }: CeilingHeight): string =>
  magnitude ? `${type}-${magnitude}` : `${type}-unset-height`;

type Props = {
  ceilingHeightType: CeilingHeightMeasurementType;
};

type CeilingHeight = {
  type: CeilingHeightMeasurementType;
  magnitude: string | number | null;
};

const MetricCeilingHeightInput = ({ ceilingHeightType }: Props) => {
  const { t } = useTranslation('filters');

  const dispatch = useDispatch();
  const { ceilingHeightFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const criteria = useListingSearchCriteria({
    onUrlChange: c => {
      if (!c.currentFilters.ceilingHeightMagnitude || !c.currentFilters.ceilingHeightType) {
        setCeilingHeight(c.currentFilters.ceilingHeightMagnitude ?? null);
      }
    },
  });

  const [ceilingHeight, setCeilingHeight] = useState<string | number | null>(
    criteria.currentFilters.ceilingHeightMagnitude ?? null,
  );

  const ceilingHeightMetersRef = useRef<HTMLInputElement | null>(null);

  const pushCriteriaUpdates = (
    newCeilingHeightFilter: CeilingHeight,
    criteriaObject: ListingSearchCriteria,
  ) => {
    const currentMagnitude = Number(criteriaObject.currentFilters.ceilingHeightMagnitude) || null;
    let coercedNewMagnitude = Number(newCeilingHeightFilter.magnitude) || null;
    const isMagnitudeCleared = !coercedNewMagnitude;
    coercedNewMagnitude = isMagnitudeCleared ? null : Number(newCeilingHeightFilter.magnitude);

    if (!isMagnitudeCleared) {
      criteriaObject.add('inMeters', true);
    } else {
      criteriaObject.remove('inMeters');
    }
    criteriaObject.add('ceilingHeightMagnitude', coercedNewMagnitude);
    criteriaObject.add(
      'ceilingHeightType',
      isMagnitudeCleared ? undefined : newCeilingHeightFilter.type,
    );

    if (currentMagnitude !== coercedNewMagnitude) {
      if (flags['search-analytics-refactor']) {
        ceilingHeightFilterInteraction({
          value: coercedNewMagnitude,
          currentFilters: criteria.toAnalyticsProperties(),
          units: 'metric',
          filterType: newCeilingHeightFilter.type,
        });
      } else {
        dispatch(
          actions.ceilingHeightFilterChanged(buildAnalyticStringValue(newCeilingHeightFilter)),
        );
      }
    }
    criteriaObject.pushToHistory();
  };

  // FIXME: Either add the exhaustive deps or delete this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCriteria = useMemo(() => debounce(pushCriteriaUpdates, debounceLength), []);

  const handleBlur = () => {
    debouncedUpdateCriteria.cancel();
    const newCeilingHeightFilter = {
      type: ceilingHeightType,
      magnitude: ceilingHeight,
    };
    pushCriteriaUpdates(newCeilingHeightFilter, criteria);
  };

  const handleInputChange = event => {
    const validCeilingHeightValue = matchNumericInput(event.target.value);
    if (validCeilingHeightValue === null || validCeilingHeightValue.length > 10) {
      return;
    }
    setCeilingHeight(validCeilingHeightValue);
    const newCeilingHeightFilter = {
      type: ceilingHeightType,
      magnitude: validCeilingHeightValue,
    };
    debouncedUpdateCriteria.cancel();
    debouncedUpdateCriteria(newCeilingHeightFilter, criteria);
  };

  return (
    <div>
      <>
        <label htmlFor="ceiling-height-magnitude" className={s.ceilingHeightInputLabel}>
          {t('ceilingHeight.minHeight')}
        </label>
        <RawInput
          data-testid="ceiling-height-magnitude-meters"
          ref={ceilingHeightMetersRef}
          className={s.ceilingHeightInput}
          units={t('ceilingHeight.units.m')}
          name="ceiling-height-magnitude"
          onChange={e => handleInputChange(e)}
          onBlur={handleBlur}
          value={!isNull(ceilingHeight) ? ceilingHeight : ''}
        />
      </>
    </div>
  );
};
export default React.memo(MetricCeilingHeightInput);
