import { Video, MultipathImage, Address, TourbookListingAnalyticsInformation } from '@root/types';
import { PARAMETERS } from '@root/tracking/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomIcon, VideoPlayer } from '@components/shared';
import useIFrameInteractionTracking from '@components/shared/useIFrameInteractionTracking';
import useAnalytics from '@shared/useAnalytics';
import cn from 'classnames';
import { SourcePage } from '@root/store/actions/videoAnalytics';
import useAltText from '@root/shared/useAltText';
import s from '../FullWidthLightbox.module.less';
import SingleDownloadButtonLink from '../SingleDownloadButtonLink';

export type Props = {
  video: Video | null;
  tourEmbedUrl: string | null;
  firstAsset: MultipathImage;
  isActive: boolean;
  isVisible: 'video' | 'tourEmbed';
  setIsVisible: (newVisibleElement: 'video' | 'tourEmbed') => void;
  sourcePage: SourcePage;
  isTabletDisplay: boolean;
  address: Address;
  companyName?: string;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
};

const sourcePageToVideoMap = {
  'listing page': {
    '3dtour': PARAMETERS.space3DTour,
    video: PARAMETERS.spaceVideo,
  },
  'building page': {
    '3dtour': PARAMETERS.lightboxGallery,
    video: PARAMETERS.buildingVideo,
  },
  'brand page': {
    video: PARAMETERS.lightboxGallery,
  },
  'tourbook page': {
    '3dtour': PARAMETERS.space3DTour,
    video: PARAMETERS.spaceVideo,
  },
};

const VirtualToursTab = ({
  tourEmbedUrl,
  video,
  firstAsset,
  isActive,
  isVisible,
  setIsVisible,
  sourcePage,
  isTabletDisplay,
  address,
  companyName,
  analyticsInformation,
}: Props) => {
  const requiresControls = !!tourEmbedUrl && !!video;
  const flags = useFlags();
  const { getAltText, getAltTextForVideo } = useAltText({ address });
  const { t } = useTranslation('listing');

  useIFrameInteractionTracking({
    shouldTrack: tourEmbedUrl && (video || tourEmbedUrl),
    sourceContent: flags['media-analytics']
      ? PARAMETERS.lightboxGallery
      : sourcePageToVideoMap[sourcePage || 'listing page']['3dtour'],
    sourcePage,
    iFrameId: isTabletDisplay ? '3dTourLightboxTablet' : '3dTourLightboxDesktop',
    analyticsInformation,
  });

  const { videoInteraction, threeDTourInteraction } = useAnalytics();

  const playerRef = useRef<any>();

  const displayDownloadButton =
    video?.downloadPath && isVisible !== 'tourEmbed' && sourcePage !== 'brand page';

  useEffect(() => {
    if (isVisible === 'tourEmbed' && playerRef.current) playerRef.current.pause();
    if (!isActive && playerRef.current) playerRef.current.pause();
  }, [isVisible, isActive]);
  return (
    <>
      {video ? (
        <div
          className={cn(s.videoContainer, isVisible === 'tourEmbed' && s.hidden)}
          data-testid="videoContainer"
        >
          <VideoPlayer
            video={video}
            className={cn(s.video, requiresControls && s.withControls)}
            sourcePage={sourcePage}
            sourceContent={
              flags['media-analytics']
                ? PARAMETERS.lightboxGallery
                : sourcePageToVideoMap[sourcePage || 'listing page'].video
            }
            analyticsInformation={analyticsInformation}
            testId="spaceVideo"
            fluid={false}
            fill
            playerRef={playerRef}
          />
        </div>
      ) : null}
      {tourEmbedUrl ? (
        <div
          className={cn(s.tourContainer, isVisible === 'video' && s.hidden)}
          data-testid="tourContainer"
        >
          <iframe
            src={tourEmbedUrl}
            title="3D Tour"
            id={isTabletDisplay ? '3dTourLightboxTablet' : '3dTourLightboxDesktop'}
            data-testid="3dTourIframe"
            className={cn(s.tour, requiresControls && s.withControls)}
            sandbox="allow-same-origin allow-scripts"
          ></iframe>
        </div>
      ) : null}
      {displayDownloadButton ? (
        <SingleDownloadButtonLink
          downloadPath={video.downloadPath!}
          onClick={() => {
            videoInteraction({
              sourcePage,
              sourceContent: PARAMETERS.lightboxGallery,
              action: PARAMETERS.downloadVideo,
              actionType: 'LIGHTBOX_VIDEO_DOWNLOADED',
              analyticsInformation,
            });
          }}
          title={t('gallery.downloadVideoTooltip')}
          className={cn(
            'tablet:left-3 mobile:left-2',
            requiresControls ? 'mobile:!bottom-[75px]' : '!bottom-7 ',
          )}
        />
      ) : null}
      {requiresControls && video ? (
        <section className={s.imageThumbnailsSectionVirtualTour}>
          <div data-testid="virtualToursThumbnailsSection" className={s.videoThumbnailsContainer}>
            <div className={s.imageThumbnails}>
              <button
                className={cn(s.thumbnail)}
                type="button"
                onClick={() => {
                  setIsVisible('video');
                  videoInteraction({
                    sourcePage,
                    sourceContent: PARAMETERS.lightboxGallery,
                    action: PARAMETERS.clickVideoThumbnail,
                    actionType: 'LIGHTBOX_VIDEO_THUMBNAIL_CLICKED',
                    analyticsInformation,
                  });
                }}
              >
                <img
                  src={video.thumbnailPosterPath}
                  alt={getAltTextForVideo(sourcePage, companyName)}
                  className={cn(
                    s.imageThumbnailButton,
                    isVisible === 'video' && s.selectedImageThumbnailButton,
                  )}
                />
              </button>

              <button
                className={cn(s.thumbnail, s.tourThumbnail)}
                type="button"
                onClick={() => {
                  setIsVisible('tourEmbed');
                  threeDTourInteraction({
                    sourcePage,
                    sourceContent: PARAMETERS.lightboxGallery,
                    action: PARAMETERS.clickThreeDTourThumbnail,
                    actionType: 'LIGHTBOX_THREE_D_THUMBNAIL_CLICKED',
                    analyticsInformation,
                  });
                }}
              >
                <img
                  src={firstAsset.smallPath}
                  alt={getAltText({ type: '3dTourThumbnailPhoto' })}
                  className={cn(
                    s.imageThumbnailButton,
                    isVisible === 'tourEmbed' && s.selectedImageThumbnailButton,
                  )}
                />
                <CustomIcon className={s.tourIcon} type="3d-tour" />
              </button>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default VirtualToursTab;
