import PropTypes from 'prop-types';
import formatPhoneNumber from '@shared/formatPhoneNumber';
import css from './DeprecatedListingAgentsContactCard.module.less';

const Agent = ({ name, role, email, phone, company, subject, trackContactClick }) => {
  const formatter = formatPhoneNumber;

  return (
    <li className={css.agentDetails}>
      <div>
        {name ? <span>{name}</span> : null}
        {/* eslint-disable react/jsx-no-literals */}
        {role ? <span>, {role}</span> : null}
        {/* eslint-enable react/jsx-no-literals */}
        {company ? (
          <span>
            {/* eslint-disable react/jsx-no-literals */}
            <span className={css.divider}> |</span> {company}
            {/* eslint-enable react/jsx-no-literals */}
          </span>
        ) : null}
      </div>
      {email ? (
        <div>
          {/* eslint-disable react/jsx-no-literals */}
          <a
            className={css.link}
            onClick={() => {
              trackContactClick();
            }}
            href={`mailto:${email}?subject=${subject}`}
          >
            {/* eslint-enable react/jsx-no-literals */}
            {email}
          </a>
        </div>
      ) : null}
      {phone ? <div>{formatter(phone)}</div> : null}
    </li>
  );
};

Agent.defaultProps = {
  name: undefined,
  role: undefined,
  email: undefined,
  phone: undefined,
  company: undefined,
};

Agent.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  company: PropTypes.string,
  subject: PropTypes.string.isRequired,
  trackContactClick: PropTypes.func.isRequired,
};

export default Agent;
