import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Form, RawToggle } from '@components/shared/forms';
import { CustomIcon, Notification } from '@components/shared';
import { SavedSearch, StoreState } from '@root/types';
import updateSavedSearch from '@root/store/actions/saved/updateSavedSearch';
import routes from '@root/routes';
import { getSavedSearches } from '@root/store/selectors';
import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import EditSavedSearchForm from './EditSavedSearchForm';
import s from './Searches.module.less';

type Props = {
  search: SavedSearch;
  onDeleteClick: (s: SavedSearch) => void;
  goToSavedSearch: () => void;
  toggleNotification: (id: string, receivesNotifications: boolean) => Promise<void>;
  isBeingEdited: boolean;
  setIsBeingEdited: (boolean) => void;
  isDisabled: boolean;
  trackSaveSearchName: (nameOrDescription: string) => void;
};

export type FormValues = {
  name: string;
  useSearchCriteriaAsName: boolean;
};

const SearchRow = ({
  search,
  onDeleteClick,
  goToSavedSearch,
  toggleNotification,
  isBeingEdited,
  setIsBeingEdited,
  isDisabled,
  trackSaveSearchName,
}: Props) => {
  const dispatch = useDispatch();
  const savedSearchesCount = useSelector((state: StoreState) => getSavedSearches(state)).length;
  const { savedSearchInteraction } = useAnalytics();

  const { t } = useTranslation('favorites');

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('saveSearch:nameRequiredErrorMessage')),
    useSearchCriteriaAsName: Yup.boolean(),
  });

  const updateSavedSearchName = (name: string | null) => {
    dispatch(
      updateSavedSearch(search.id, { name }, () => {
        setIsBeingEdited(false);
        Notification.success({
          title: t('saveSearch:changesSaved'),
        });
      }),
    );
  };
  const handleEdit = () => {
    savedSearchInteraction({
      actionType: 'EDIT_SAVED_SEARCH',
      action: PARAMETERS.editSavedSearch,
      sourcePage: PARAMETERS.savedSearchPage,
      savedSearchCount: savedSearchesCount,
      savedSearchName: search.name ?? search.description,
      savedSearchId: search.id,
    });
    setIsBeingEdited(true);
  };

  const renderNameLink = () => {
    const name = search.name || search.description;

    return isDisabled ? (
      <span className={s.disabledNameLink}>{name}</span>
    ) : (
      <>
        {/* using an a tag instead of a Link because */}
        {/* the redirect to the criteria happens in rails */}
        <a onClick={goToSavedSearch} href={routes.savedSearch(search.id)}>
          {name}
        </a>
      </>
    );
  };

  return (
    <li className={cn(s.search, isBeingEdited && s.edited)} data-testid={`searchRow-${search.id}`}>
      <div className={s.name}>
        {isBeingEdited ? (
          <Form<FormValues>
            className={s.editSavedSearchForm}
            id="editSavedSearch"
            initialValues={{ name: search.name || '', useSearchCriteriaAsName: !search.name }}
            onSubmit={values => {
              trackSaveSearchName(
                values.useSearchCriteriaAsName ? search.description : values.name,
              );
              updateSavedSearchName(values.useSearchCriteriaAsName ? null : values.name);
            }}
            validationSchema={validationSchema}
          >
            <EditSavedSearchForm
              description={search.description}
              setIsBeingEdited={setIsBeingEdited}
            />
          </Form>
        ) : (
          renderNameLink()
        )}
        <p className={cn(s.searchDescription, isDisabled && s.disabledSearchDescription)}>
          {search.description}
        </p>
      </div>
      <div className={s.alerts}>
        <div>
          <RawToggle
            onChange={receivesNotifications => toggleNotification(search.id, receivesNotifications)}
            name={`${search.id}-alerts`}
            checked={search.receivesNotifications}
            disabled={isDisabled}
          />
          <span className={cn(s.alertAside, isDisabled && s.disabledText)}>
            {t(search.receivesNotifications ? 'on' : 'off')}
          </span>
        </div>
      </div>
      <div className={s.edit}>
        <button
          disabled={isDisabled}
          type="button"
          onClick={() => handleEdit()}
          className={cn(s.editButton, isBeingEdited && s.editting)}
          data-testid={`edit-search-${search.id}`}
        >
          <CustomIcon type="edit" />
        </button>
      </div>
      <div className={s.delete}>
        <button
          data-testid={`delete-search-${search.id}`}
          type="button"
          onClick={() => onDeleteClick(search)}
          className={s.deleteButton}
          disabled={isDisabled}
        >
          <CustomIcon type="trash" />
        </button>
      </div>
    </li>
  );
};

export default SearchRow;
