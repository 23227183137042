const Skeleton = () => {
  return (
    <div data-testid="landlordSkeleton">
      <div className="my-5 flex justify-between gap-1 mobile:flex-col mobile:items-center mobile:justify-center">
        <div className="shimmer h-4 w-3/12 bg-black-005 mobile:w-9/12" />
        <div className="shimmer h-4 w-1/12 bg-black-005 mobile:my-1 mobile:w-3/12" />
      </div>
      <div className="shimmer my-1 mb-2 h-px bg-black-010 mobile:hidden" />
      <div className="space-between my-2 flex gap-1 mobile:hidden">
        <div className="w-2/12 tablet:w-3/12">
          <div className="shimmer h-2 w-3/12 bg-black-005" />
          <div className="shimmer my-[5px] h-3 w-8/12 bg-black-005" />
        </div>
        <div className="w-2/12 tablet:w-3/12">
          <div className="shimmer h-2 w-2/12 bg-black-005" />
          <div className="shimmer my-[5px] h-3 w-5/12 bg-black-005" />
        </div>
        <div className="w-2/12 tablet:w-3/12">
          <div className="shimmer h-2 w-8/12 bg-black-005" />
          <div className="shimmer my-[5px] h-3 w-5/12 bg-black-005" />
        </div>
        <div className="w-2/12 tablet:w-3/12">
          <div className="shimmer h-2 w-11/12 bg-black-005" />
          <div className="shimmer my-[5px] h-3 w-5/12 bg-black-005" />
        </div>
      </div>
      <ul className="relative grid h-[476px] grid-cols-4 grid-rows-2 gap-2 mobile:h-auto mobile:grid-rows-[360px]">
        <li className="shimmer col-span-2 row-span-2 bg-black-005 mobile:col-span-4 mobile:row-span-1 mobile:mx-[-16px]" />
        <li className="shimmer bg-black-005 mobile:hidden" />
        <li className="shimmer bg-black-005 mobile:hidden" />
        <li className="shimmer bg-black-005 mobile:hidden" />
        <li className="shimmer bg-black-005 mobile:hidden" />
        <li className="absolute bottom-2 right-2 h-4 w-10 rounded-2xl bg-black-010 mobile:hidden" />
      </ul>
      <div className="desktop:hidden tablet:hidden">
        <div className="flex flex-row items-start">
          <div className="flex w-6/12 flex-col gap-[5px]">
            <div className="shimmer h-2 w-[115px] bg-black-005" />
            <div className="shimmer h-3 w-[100px] bg-black-005" />
          </div>
          <div className="flex w-4/12 flex-col gap-[5px]">
            <div className="shimmer h-2 w-[48px] bg-black-005" />
            <div className="shimmer h-3 w-[100px] bg-black-005" />
          </div>
        </div>
        <div className="my-3 flex flex-row items-start">
          <div className="flex w-6/12 flex-col gap-[5px]">
            <div className="shimmer h-2 w-[150px] bg-black-005" />
            <div className="shimmer h-3 w-[100px] bg-black-005" />
          </div>
          <div className="flex w-4/12 flex-col gap-[5px]">
            <div className="shimmer h-2 w-[113px] bg-black-005" />
            <div className="shimmer h-3 w-[100px] bg-black-005" />
          </div>
        </div>
        <div className="shimmer my-1 mb-2 h-px bg-black-010" />
      </div>

      <div className="space-between my-2 flex tablet:gap-[8px] mobile:flex-col mobile:items-center">
        <div className="flex w-8/12 flex-col gap-[5px] mobile:w-full">
          <div className="shimmer h-2 w-11/12 bg-black-005 tablet:w-full mobile:w-full" />
          <div className="shimmer h-2 w-10/12 bg-black-005 tablet:w-11/12 mobile:w-11/12" />
          <div className="shimmer h-2 w-11/12 bg-black-005 tablet:w-full mobile:w-full" />
          <div className="shimmer h-2 w-10/12 bg-black-005 tablet:w-11/12 mobile:w-11/12" />
          <div className="shimmer h-2 w-8/12 bg-black-005 tablet:w-10/12 mobile:w-10/12" />
          <div className="shimmer h-2 w-9/12 bg-black-005 tablet:w-10/12 mobile:w-11/12" />
          <div className="shimmer h-2 w-8/12 bg-black-005 tablet:w-9/12 mobile:w-10/12" />
        </div>
        <div className="w-4/12 mobile:my-4 mobile:w-full">
          <div className="shimmer h-[120px] rounded-t bg-black-010 tablet:h-[98px]" />
        </div>
      </div>
      <div className="pb-3 tablet:hidden mobile:hidden">
        <div className="my-5 flex items-center justify-center">
          <div className="shimmer h-4 w-2/12 bg-black-005"></div>
        </div>
        <div className="my-2 flex items-center justify-center gap-1">
          <div className="shimmer h-3 w-1/12 rounded-xl bg-black-005"></div>
          <div className="shimmer h-3 w-1/12 rounded-xl bg-black-005"></div>
          <div className="shimmer h-3 w-1/12 rounded-xl bg-black-005"></div>
        </div>
        <div className="my-3 mb-3 h-px bg-black-010" />
        <div className="my-2 mb-2 flex flex-col items-center justify-center gap-1">
          <div className="shimmer h-3 w-1/12 bg-black-005" />
          <div className="shimmer h-2 w-8 bg-black-005" />
        </div>
      </div>
      <div className="my-5 pb-3 desktop:hidden mobile:hidden">
        <div className="flex justify-between">
          <div className="shimmer h-4 w-[193px] bg-black-005" />
          <div className="shimmer h-4 w-[125px] bg-black-005" />
        </div>
        <div className="my-3 flex gap-1">
          <div className="shimmer h-3 w-[96px] bg-black-005" />
          <div className="shimmer mt-[5px] h-2 w-[80px] bg-black-005" />
        </div>
        <div className="my-2 flex gap-1">
          <div className="shimmer h-2 w-[120px] bg-black-005" />
          <div className="shimmer h-2 w-[80px] bg-black-005" />
          <div className="shimmer h-2 w-[80px] bg-black-005" />
          <div className="shimmer h-2 w-[80px] bg-black-005" />
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
