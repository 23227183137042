import { useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import s from './TextArea.module.less';

export type Props = {
  value?: string | undefined;
  disabled?: boolean;
  defaultValue?: string | undefined;
  name: string;
  placeholder?: string;
  onChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  className?: string;
  hasError?: boolean;
  showCharacterCount?: boolean;
  maxLength?: number;
  rows?: number;
  'data-testid'?: string;
};

const RawTextArea = ({
  name,
  disabled = false,
  value = undefined,
  onChange,
  placeholder,
  defaultValue,
  className,
  onBlur,
  onFocus,
  hasError = false,
  showCharacterCount = false,
  maxLength,
  rows,
  ...props
}: Props) => {
  const [length, setLength] = useState(value?.length || 0);
  const countLength = event => {
    setLength(event.target.value.length);
  };

  return (
    <>
      <textarea
        className={classNames(s.textarea, hasError && s.error, className)}
        name={name}
        disabled={disabled}
        id={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyUp={countLength}
        rows={rows}
        data-testid={props['data-testid']}
      />
      {showCharacterCount && maxLength && (
        <div className={s.characterCount}>
          {/* FIXME: Remove this line because its a <Trans /> or remove this literal */}
          {/* eslint-disable-next-line react/jsx-no-literals */}
          {length} / {maxLength}
        </div>
      )}
    </>
  );
};

export default RawTextArea;
