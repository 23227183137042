/* eslint-disable react/jsx-no-literals */
/* eslint-disable react/no-unescaped-entities */

export const explanationText =
  "This is a place to do styling in development. Please don't commit over this file. To use, in the playground, replace this explanation with your component and begin styling.";

const Explanation = () => <span>{explanationText}</span>;
/* eslint-enable react/jsx-no-literals */
/* eslint-enable react/no-unescaped-entities */

const Playground = () => {
  return (
    <div style={{ border: '16px solid lightgrey', padding: 16 }}>
      <Explanation />
    </div>
  );
};

export default Playground;
