import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SidePanel from '@components/shared/SidePanel/SidePanel';
import useCustomBreakpoint from '@root/shared/useBreakpoints/useCustomBreakpoint';
import Button from '@components/shared/V2Button/Button';
import { cloneDeep, defaults } from 'lodash';
import { ColorGroup } from '@components/shared/ColorPicker/ColorPickerColorGroup';
import { isInIframe, tellModalOpenStatusToHost } from '@root/shared/iframeUtils';
import cn from 'classnames';
import { Prompt } from 'react-router-dom';
import { CustomIcon } from '@components/shared';
import useSize from '@react-hook/size';
import ColorPickerAccordion from './ColorPickerAccordion';

export type ColorModule = {
  title: string;
  color: string;
  defaultColor: string;
  isOpen: boolean;
  isFocused: boolean;
};
type InitialColorModule = {
  title: string;
  defaultColor: string;
  color?: string;
  isOpen?: boolean;
  isFocused?: boolean;
};

type Args = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (colorModules: ColorModule[]) => void;
  colorGroups: ColorGroup[];
  initialColorModules: InitialColorModule[];
};
type Return = { colorModules: ColorModule[]; colorPickerSidePanelProps: ColorPickerSidePanelProps };
export const useColorPickerSidePanel = ({
  isOpen,
  onClose,
  onSave,
  colorGroups,
  initialColorModules,
}: Args): Return => {
  const { t } = useTranslation('tourbook');

  const [colorModules, setColorModules] = useState<ColorModule[]>(
    initialColorModules.map(initialColorModule =>
      defaults(initialColorModule, {
        color: initialColorModule.defaultColor,
        isOpen: false,
        isFocused: false,
      }),
    ),
  );
  const lastSavedColorModules = useRef(colorModules);

  useEffect(() => {
    tellModalOpenStatusToHost(isOpen, 'COLOR_PICKER_SIDE_PANEL');
  }, [isOpen]);

  const onCancel = () => {
    setColorModules(cloneDeep(lastSavedColorModules.current));
    onClose();
  };

  const hasUnsavedColorChange = lastSavedColorModules.current.some(
    (initialColorModule, i) => initialColorModule.color !== colorModules[i].color,
  );

  return {
    colorModules,
    colorPickerSidePanelProps: {
      isOpen,
      onSave: newColorModules => {
        lastSavedColorModules.current = newColorModules;
        onSave(newColorModules);
      },
      colorGroups,
      colorModules,
      updateColorModule: (index, attr, val) =>
        setColorModules(prevColorModules =>
          prevColorModules.map((colorModule, i) =>
            i === index ? { ...prevColorModules[index], [attr]: val } : { ...colorModule },
          ),
        ),
      updateAllColorModules: (attr, val) =>
        setColorModules(prevColorModules =>
          prevColorModules.map(colorModule => ({ ...colorModule, [attr]: val })),
        ),
      onCancel,
      onCloseSidePanel: () => {
        if (
          !hasUnsavedColorChange ||
          // eslint-disable-next-line no-alert
          window.confirm(t('colorPickerSidePanel.leaveWithoutSavingConfirmation'))
        )
          onCancel();
      },
      hasUnsavedColorChange,
    },
  };
};

const customBreakpoints = {
  SMALL_MOBILE: 'smallMobile',
  LARGE_MOBILE_OR_LARGER: 'largeMobileOrLarger',
};
const breakpointConfig = {
  [customBreakpoints.SMALL_MOBILE]: 0,
  [customBreakpoints.LARGE_MOBILE_OR_LARGER]: 400,
};
const MINIMUM_PREVIEW_HEIGHT = 100;

type ColorPickerSidePanelProps = {
  isOpen: boolean;
  hasUnsavedColorChange: boolean;
  onSave: (colorModules: ColorModule[]) => void;
  onCancel: () => void;
  colorGroups: ColorGroup[];
  onCloseSidePanel: () => void;
  colorModules: ColorModule[];
  updateColorModule: (id: number, attr: 'color' | 'isOpen' | 'isFocused', value) => void;
  updateAllColorModules: (attr: 'color' | 'isOpen' | 'isFocused', value) => void;
  previewElement?: JSX.Element;
};
const ColorPickerSidePanel = ({
  isOpen,
  hasUnsavedColorChange,
  onSave,
  onCancel,
  colorGroups,
  colorModules,
  updateColorModule,
  updateAllColorModules,
  onCloseSidePanel,
  previewElement,
}: ColorPickerSidePanelProps) => {
  const { t } = useTranslation('tourbook');
  const isSmallMobile = useCustomBreakpoint(breakpointConfig) === customBreakpoints.SMALL_MOBILE;

  const previewContainerRef = useRef(null);
  const previewRef = useRef(null);
  const previewContainerHeight = useSize(previewContainerRef)[1];
  const previewHeight = useSize(previewRef)[1];

  const hidePreview = !isSmallMobile || previewContainerHeight < MINIMUM_PREVIEW_HEIGHT;
  const scaleOffset = 32 / previewHeight; // shrink by 32px
  const previewScaleFactor =
    previewHeight > previewContainerHeight
      ? previewContainerHeight / previewHeight - scaleOffset
      : 1 - scaleOffset;

  return (
    <>
      <SidePanel
        isOpen={isOpen}
        onClose={onCloseSidePanel}
        closeOnMaskClick={false}
        className={cn('top-0 !z-[60]', isInIframe() && 'rounded-tl-[12px]')}
        invisibleMaskClassName="z-[50] !cursor-default"
        widthWithUnit="300px"
        fullscreen={isSmallMobile}
      >
        <div
          className={cn(
            'full-device-height flex w-full flex-col overflow-y-scroll bg-background-secondary pt-[60px]',
            isInIframe() && 'rounded-tl-[12px]',
          )}
        >
          <div
            className={cn(
              'absolute left-0 right-0 top-0 z-30 border-b border-solid border-black-020 bg-background-secondary px-3 py-2 font-subtitle',
              isInIframe() && 'rounded-tl-[12px]',
            )}
          >
            {t('colorPickerSidePanel.customizeColors')}
          </div>
          {colorModules.map(({ title, color, isOpen: isColorModuleOpen, isFocused }, i) => (
            <ColorPickerAccordion
              key={title}
              title={title}
              color={color}
              isOpen={isColorModuleOpen}
              colorGroups={colorGroups}
              onChangeColor={newColor => updateColorModule(i, 'color', newColor)}
              onOpen={() => {
                updateAllColorModules('isOpen', false);
                updateColorModule(i, 'isOpen', true);
              }}
              onClose={() => updateColorModule(i, 'isOpen', false)}
              onFocus={() => updateColorModule(i, 'isFocused', true)}
              onBlur={() => updateColorModule(i, 'isFocused', false)}
              isFocused={isFocused}
            />
          ))}
          {colorModules.some(({ color, defaultColor }) => color !== defaultColor) && (
            <div className="flex justify-end px-4 py-1">
              <div
                role="button"
                className="flex items-center gap-0.5 font-body-small"
                onClick={() => {
                  colorModules.forEach(({ defaultColor }, i) =>
                    updateColorModule(i, 'color', defaultColor),
                  );
                }}
              >
                <CustomIcon className="text-[12px]" type="reset" />
                {t('colorPickerSidePanel.resetToDefaultColors')}
              </div>
            </div>
          )}
          {previewElement && (
            <div ref={previewContainerRef} className="relative mb-1 mt-3 flex-grow overflow-hidden">
              <div className={cn('flex', hidePreview && 'hidden')}>
                <p className="mb-1 flex max-h-fit w-full justify-center font-body-medium-emphasis">
                  {t('colorPickerSidePanel.preview')}
                </p>
                <div ref={previewRef} className="absolute top-3 flex w-full flex-col items-center">
                  <div
                    style={{ transform: `scale(${previewScaleFactor})` }}
                    className="w-full origin-top overflow-hidden shadow-[0px_1px_10px_rgba(115,_115,_115,_0.15)]"
                  >
                    {previewElement}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={cn(
              'relative mt-auto flex w-full justify-center gap-2 py-2',
              previewElement && hidePreview && '!-mt-4',
            )}
          >
            <Button type="tertiary" className="!px-3" onClick={onCancel}>
              {t('colorPickerSidePanel.cancel')}
            </Button>
            <Button className="!px-3" onClick={() => onSave(colorModules)}>
              {t('colorPickerSidePanel.saveChanges')}
            </Button>
          </div>
        </div>
      </SidePanel>
      <Prompt
        when={hasUnsavedColorChange}
        message={t('colorPickerSidePanel.leaveWithoutSavingConfirmation')}
      />
    </>
  );
};

export default ColorPickerSidePanel;
