import { Col as AntdCol } from 'antd';
import { ReactNode } from 'react';

type SizeType = number | { span: number; order: number };
type Props = {
  lg?: SizeType;
  md?: SizeType;
  sm?: SizeType;
  span?: number;
  children?: ReactNode;
  className?: string;
};

const Col = ({ lg, md, sm, span, className, children }: Props) => (
  <AntdCol lg={lg} md={md} sm={sm} span={span} className={className}>
    {children}
  </AntdCol>
);

export default Col;
