const Skeleton = () => {
  return (
    <div>
      <div className="my-5 flex flex-col gap-1">
        <div className="shimmer h-4 w-10/12 bg-black-005 mobile:h-2" />
        <div className="shimmer hidden h-2 w-8/12 bg-black-005 tablet:block mobile:block" />
        <div className="shimmer hidden h-2 w-5/12 bg-black-005 mobile:block" />
      </div>
      <ul className="relative grid h-[476px] grid-cols-4 grid-rows-2 gap-2 desktop:mb-4 tablet:mb-4 mobile:h-auto mobile:grid-rows-[360px_80px]">
        <li className="shimmer col-span-2 row-span-2 bg-black-005 mobile:col-span-4 mobile:row-span-1 mobile:mx-[-16px]" />
        <li className="shimmer bg-black-005" />
        <li className="shimmer bg-black-005" />
        <li className="shimmer bg-black-005" />
        <li className="shimmer bg-black-005" />
        <li className="absolute bottom-2 right-2 h-4 w-10 rounded-2xl bg-black-010 mobile:hidden" />
      </ul>
      <div className="grid grid-cols-[2fr_1fr] gap-2 mobile:block">
        <div className="flex flex-col mobile:items-center">
          <div className="shimmer mb-4 mt-8 h-5 w-[308px] bg-black-005 mobile:mt-4" />
          <div className="shimmer hidden h-2 w-[110px] bg-black-005 mobile:mb-2 mobile:block" />
          <div className="h-[1px] bg-black-005 mobile:w-full" />
          <ul className="mb-8 mt-4 flex w-9/12 justify-between mobile:mb-4 mobile:mt-2 mobile:w-full">
            <li className="shimmer h-4 w-2/12 bg-black-005 desktop:w-7" />
            <li className="shimmer h-4 w-4/12 bg-black-005 desktop:w-[112px]" />
            <li className="shimmer h-4 w-3/12 bg-black-005 desktop:w-[113px]" />
            <li className="shimmer h-4 w-1/12 bg-black-005 desktop:w-9" />
          </ul>
          <ul className="flex flex-col gap-1 mobile:w-full">
            <li className="shimmer h-2 w-[87%] bg-black-005 mobile:w-[94%]" />
            <li className="shimmer h-2 w-[89%] bg-black-005 mobile:w-[92%]" />
            <li className="shimmer h-2 w-[84%] bg-black-005 mobile:w-[89%]" />
            <li className="shimmer h-2 w-[65%] bg-black-005 mobile:w-[95%]" />
            <li className="shimmer hidden h-2 w-[91%] bg-black-005 mobile:block" />
            <li className="shimmer hidden h-2 w-[97%] bg-black-005 mobile:block" />
            <li className="shimmer hidden h-2 w-[65%] bg-black-005 mobile:block" />
          </ul>
          <div className="shimmer mb-2 mt-7 h-2 w-[160px] bg-black-005 mobile:hidden" />
          <div className="shimmer h-3 w-[223px] bg-black-005 mobile:hidden" />
          <div className="shimmer mb-3 mt-4 h-2 w-[222px] bg-black-005 mobile:hidden" />
          <ul className="grid grid-cols-3 gap-2 mobile:hidden">
            <Contact />
            <Contact />
            <Contact />
          </ul>
        </div>
        <div className="mobile:hidden">
          <div className="shimmer h-[280px] rounded-t bg-black-010" />
          <div className="shimmer h-[60px] rounded-b bg-black-005" />
        </div>
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <li className="flex gap-1">
      <div className="flex flex-col items-center justify-center">
        <div className="shimmer h-4 w-4 rounded-full bg-black-005" />
        <div className="shimmer mt-1 h-[14px] w-[20px] bg-black-005" />
      </div>
      <ul className="flex w-full flex-col gap-1">
        <li className="shimmer h-2 w-full bg-black-005" />
        <li className="shimmer h-2 w-11/12 bg-black-005" />
        <li className="shimmer h-2 w-full bg-black-005" />
      </ul>
    </li>
  );
};

export default Skeleton;
