import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, notification } from 'antd';
import api from '@shared/api';
import useToggle from '@shared/useToggle';
import addKeyTo from '@shared/addKeyTo';
import routes from '@root/routes';
import { AdminFloorPlan } from '@root/types';
import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import CustomIcon from '@components/shared/CustomIcon';
import IconButton from '@components/shared/IconButton';
import s from './FloorPlanSection.module.less';

type Props = {
  archivedFloorPlans: AdminFloorPlan[];
  fetchListing: () => void;
  renderFile: (path: string, filename: string, processedAt: string) => any;
};

const CollapsibleFloorPlanSection = ({ archivedFloorPlans, fetchListing, renderFile }: Props) => {
  const { t } = useTranslation('admin');
  const { value: isDeleting, setTrue: startDeleting, setFalse: stopDeleting } = useToggle();

  const [photosTableCollapsed, setSidebarIsCollapsed] = useState(false);

  const toggleSidebar = useCallback(
    () => setSidebarIsCollapsed(!photosTableCollapsed),
    [photosTableCollapsed],
  );

  const tableData = () => (archivedFloorPlans ? addKeyTo(archivedFloorPlans) : []);

  const selectFloorPlan = async (id: string) => {
    const resp = await api.put(routes.api.admin.floorPlanUpdate(id), { archived: false });

    if (resp.ok) {
      fetchListing();
      notification.success({ message: t('media.floorPlan.selectSuccess') });
    } else {
      notification.error({ message: t('media.floorPlan.selectFailure') });
    }
  };

  const selectMenuItem = (row: any) => {
    return (
      <OverflowMenuItem
        className={s.overflowItem}
        disabled={isDeleting}
        onClick={() => selectFloorPlan(row.id)}
      >
        <>
          <CustomIcon type="eye"></CustomIcon>
          {t('media.floorPlan.select')}
        </>
      </OverflowMenuItem>
    );
  };

  const downloadMenuItem = (row: any) => {
    return (
      <OverflowMenuItem className={s.overflowItem} disabled={isDeleting} onClick={() => {}}>
        <a className={s.downloadLink} href={row.downloadPath} download>
          <CustomIcon type="download"></CustomIcon>
          {t('media.floorPlan.download')}
        </a>
      </OverflowMenuItem>
    );
  };

  const deleteFloorPlan = async (id: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('media.deleteConfirmation'))) {
      startDeleting();
      const resp = await api.delete(routes.api.admin.floorPlanDelete(id));

      if (resp.ok) {
        fetchListing();
        notification.success({ message: t('media.floorPlan.deleteSuccess') });
      } else {
        notification.error({ message: t('media.floorPlan.deleteFailure') });
      }
      stopDeleting();
    }
  };

  const deleteMenuItem = (row: any) => {
    return (
      <OverflowMenuItem
        className={s.overflowItem}
        disabled={isDeleting}
        onClick={() => deleteFloorPlan(row.id)}
      >
        <>
          <CustomIcon type="trash"></CustomIcon>
          {t('admin:listing.mediaManagement.delete')}
        </>
      </OverflowMenuItem>
    );
  };

  const columns = () => [
    {
      width: '91%',
      render: (row: any) => {
        return renderFile(row.path, row.filename, row.processedAt);
      },
    },
    {
      width: '9%',
      render: (row: any) => (
        <OverflowMenu overflowClassName={s.overflowMenu}>
          {selectMenuItem(row)}
          {downloadMenuItem(row)}
          {deleteMenuItem(row)}
        </OverflowMenu>
      ),
    },
  ];

  return (
    <>
      <div className={s.collapsableHeader}>
        <IconButton
          className={s.expandCollapseSidebarButton}
          icon={photosTableCollapsed ? 'chevron-down' : 'chevron-right'}
          data-testid="expand-collapse-sidebar-button"
          onClick={toggleSidebar}
        />
        <div className="inline font-title">{t('media.floorPlan.hiddenTitle')}</div>
      </div>
      {photosTableCollapsed ? (
        <Table
          className={s.tableHeader}
          rowClassName={s.row}
          pagination={false}
          columns={columns()}
          dataSource={tableData()}
        />
      ) : null}
    </>
  );
};

export default CollapsibleFloorPlanSection;
