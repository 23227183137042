const googleMapsStaticImageUrlGenerator = (key, address) =>
  'https://maps.googleapis.com/maps/api/staticmap?' +
  `center=${address}` +
  '&zoom=16' +
  `&key=${key}` +
  '&size=750x244' +
  `&markers=color:red%7C${address}` +
  '&zoom=15' +
  '&format=png' +
  '&maptype=roadmap';
/* eslint-disable max-len */
//  1/17 - the following line adds our custom styles to the static maps, not in use not but will probably be in the future.
// '&style=feature:administrative%7Celement:labels.text.fill%7Ccolor:0xa2a2a2&style=feature:administrative.neighborhood%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:landscape%7Celement:geometry.fill%7Ccolor:0xf5f5f5&style=feature:landscape%7Celement:labels.text.fill%7Ccolor:0xa2a2a2&style=feature:poi%7Celement:geometry%7Ccolor:0xe5f1f0&style=feature:poi%7Celement:labels.icon%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0xa2a2a2&style=feature:poi.business%7Cvisibility:off&style=feature:poi.medical%7Ccolor:0xffe4d7&style=feature:poi.park%7Ccolor:0xc7e4e0&style=feature:poi.park%7Celement:geometry.fill%7Ccolor:0xe5f1f0&style=feature:poi.sports_complex%7Celement:labels.text.fill%7Ccolor:0xa2a2a2&style=feature:poi.sports_complex%7Celement:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:road%7Celement:geometry.stroke%7Cvisibility:off&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8b8b8b&style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0xfefcff&style=feature:transit.station%7Celement:labels.text%7Cvisibility:off&style=feature:water%7Celement:geometry%7Ccolor:0xe8edf7&style=feature:water%7Celement:geometry.fill%7Ccolor:0xcfdeeb&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x8faccb&size=480x360'
/* eslint-enable max-len */

export default googleMapsStaticImageUrlGenerator;
