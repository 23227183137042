import { useState } from 'react';
import { FieldWrapper } from '@components/shared/forms';
import { UploadApiResponse as CloudinaryResponse } from 'cloudinary';
import { Notification } from '@components/shared';
import { useTranslation } from 'react-i18next';
import {
  CloudinaryImageParams,
  CloudinaryTransformations,
  CropperCanvasData,
  ImageFit,
} from '@root/types';
import AssetUploader from '@components/shared/EditableAsset/AssetUploader';
import useCropperModal from '@components/shared/Cropper/useCropperModal';
import EditableAsset from '@components/shared/EditableAsset/EditableAsset';
import useCloudinaryUploader from '@components/shared/Cloudinary/useCloudinaryUploader';
import useCloudinary from '@root/shared/useCloudinary';
import { crop } from '@cloudinary/url-gen/actions/resize';
import { isArray, isNil } from 'lodash';
import cn from 'classnames';
import ImageFitSelector from './ContentBlocks/Generic/ImageFitSelector';

export type Props = {
  img: CloudinaryImageParams | null;
  onChange: (data: { img: CloudinaryImageParams | null; imageFit: ImageFit }) => void;
  labelText: string;
  name: string;
  alt: string;
  imagePreviewClassname?: string;
  cropBoxDimentions?: { width: number; height: number };
  additionalFooterContent?: React.ReactElement;
  isCropBoxResizable?: boolean;
  initialImageFit: ImageFit;
  imageFitFieldName: string;
};

const PLACEHOLDER_IMAGE_CLOUDINARY_ID = 'assets/building-template';

export default function ImageUploaderWithCropper({
  img,
  onChange,
  labelText,
  name,
  alt,
  imagePreviewClassname,
  cropBoxDimentions,
  initialImageFit,
  imageFitFieldName,
}: Props) {
  const { t } = useTranslation('admin');
  const [selectedImageFit, setSelectedImageFit] = useState<ImageFit>(initialImageFit);

  const { CropperModal, cropperModalProps, openCropperModal } = useCropperModal();

  const {
    selectAndUploadToCloudinary,
    CloudinaryUploaderFileInput,
    cloudinaryUploaderFileInputProps,
  } = useCloudinaryUploader({
    onError: () => Notification.error({ title: t('notification.generalError') }),
  });

  const cld = useCloudinary();
  const generateImagePath = (): string => {
    if (!img) return '';

    let image = cld.image(img.cloudinaryId).format('auto');

    const allTransformations = isArray(img.transformations)
      ? img.transformations
      : [img.transformations || {}];

    const cropTransformation = allTransformations.find(
      tr => !isNil(tr.width) && !isNil(tr.height) && !isNil(tr.x) && !isNil(tr.y),
    );

    if (cropTransformation) {
      image = image.resize(
        crop()
          .width(cropTransformation.width!)
          .height(cropTransformation.height!)
          .x(cropTransformation.x!)
          .y(cropTransformation.y!),
      );
    }
    return image.toURL();
  };

  const getImageDownloadPath = (): string =>
    img ? cld.image(img.cloudinaryId).addFlag('attachment').toURL() : '';

  const handleSave = ({ cloudinaryId, cropperCanvasData, cloudinaryTransformations }) => {
    const image = {
      cloudinaryId,
      cropperCanvasData,
      transformations: cloudinaryTransformations,
    };
    const imageFit = selectedImageFit;
    onChange({ img: image, imageFit });
    cropperModalProps.updateState({
      initialCanvasData: cropperCanvasData,
      initialCloudinaryTransformations: cloudinaryTransformations,
    });
  };

  const handleChangeImageFit = (fitOption: ImageFit) => {
    setSelectedImageFit(fitOption);
    if (fitOption !== initialImageFit) {
      cropperModalProps.updateState({
        initialCanvasData: null,
        initialCloudinaryTransformations: null,
        showZoomControls: fitOption === 'fill_space',
      });
    } else {
      cropperModalProps.updateState({
        initialCloudinaryTransformations:
          (img?.transformations as CloudinaryTransformations) || null,
        initialCanvasData: img?.cropperCanvasData || null,
        showZoomControls: fitOption === 'fill_space',
      });
    }
  };

  const ModalTitleWithImageFit = () => {
    return (
      <>
        {t('microsite.genericContentBlock.image.editModalTitle')}
        {selectedImageFit && (
          <ImageFitSelector
            selectedImageFit={selectedImageFit}
            fieldName={`${imageFitFieldName}-modal`}
            onChange={fitOption => handleChangeImageFit(fitOption)}
            hideLabel
          />
        )}
      </>
    );
  };

  const openEditModal = (args: {
    cloudinaryId: string;
    cropperCanvasData?: CropperCanvasData | null;
  }): void => {
    setSelectedImageFit(initialImageFit);
    openCropperModal({
      cloudinaryId: args.cloudinaryId,
      cropBoxDimentions,
      initialCanvasData: args.cropperCanvasData,
      initialCloudinaryTransformations: img?.transformations as CloudinaryTransformations,
      showZoomControls: initialImageFit === 'fill_space',
    });
  };

  return (
    <>
      <CropperModal
        {...cropperModalProps}
        isCropBoxResizable={selectedImageFit === 'fit_to_space'}
        title={ModalTitleWithImageFit()}
        onSave={({ cloudinaryId, cropperCanvasData, cloudinaryTransformations }) => {
          handleSave({ cloudinaryId, cropperCanvasData, cloudinaryTransformations });
        }}
        primaryButtonText={t('common:update')}
      />
      <CloudinaryUploaderFileInput {...cloudinaryUploaderFileInputProps} />
      <FieldWrapper name={name} labelText={labelText} containerClass="!mb-0">
        {img ? (
          <div className="flex flex-col">
            <EditableAsset
              imagePath={generateImagePath()}
              imageDownloadPath={getImageDownloadPath()}
              imageClassName={cn(
                imagePreviewClassname || '!w-[150px] !h-[150px]',
                'rounded-[3px] !border !border-solid !border-black-020 object-cover',
              )}
              altText={alt}
              editButtonClassName="relative ml-1 top-[2px] border border-solid border-black-035"
              editButtonName={t('microsite.editImagePopover.title')}
              downloadButtonClassName="relative ml-1 top-[2px] border border-solid border-black-035"
              downloadButtonName={t('microsite.downloadImagePopover.title')}
              onUpload={() => {
                if (selectAndUploadToCloudinary)
                  selectAndUploadToCloudinary({
                    callback: async (response: CloudinaryResponse) =>
                      openEditModal({ cloudinaryId: response.public_id }),
                  });
              }}
              onEdit={() =>
                openEditModal({
                  cloudinaryId: img.cloudinaryId,
                  cropperCanvasData: img.cropperCanvasData,
                })
              }
              onDelete={() =>
                onChange({
                  img: {
                    cloudinaryId: PLACEHOLDER_IMAGE_CLOUDINARY_ID,
                    transformations: null,
                    cropperCanvasData: null,
                  },
                  imageFit: selectedImageFit,
                })
              }
            />
          </div>
        ) : (
          <AssetUploader
            placeholderElementClassName="pl-1 text-black-055 font-body-medium"
            containerClassName="w-[257px] !h-[52px] !my-0 bg-white"
            testId={`${name}-uploader`}
            onError={() => {}}
            onReady={async (response: CloudinaryResponse) =>
              openEditModal({ cloudinaryId: response.public_id })
            }
          />
        )}
      </FieldWrapper>
    </>
  );
}
