import { Listing, SearchCriteria, StoreState } from '@root/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBreakpoint } from '@shared/useBreakpoints';
import { useSelector } from 'react-redux';
import useAnalytics from '@shared/useAnalytics';
import { EVENTS, PARAMETERS } from '@root/tracking/constants';
import useFetchTourbookSummaries from '@root/shared/useFetchTourbookSummaries';
import { Notification } from '@components/shared';
import { useTranslation } from 'react-i18next';
import ListingPreviewModal, { Props as ListingPreviewModalProps } from './ListingPreviewModal';
import useFetchListing from './useFetchListing/useFetchListing';
import useFetchSearchListingsFromListingPreview from './useFetchSearchListingsFromListingPreview/useFetchSearchListingsFromListingPreview';

type Arguments = {
  currentPage: number;
  toApiListingsUrl: (customFilters?: Partial<SearchCriteria>) => string;
  changePage: (newPage: number) => void;
  isSaved: (listingId: string) => boolean;
};

type ReturnType = {
  openListingPreviewModal: (listingIndex: number) => void;
  ListingPreviewModal: typeof ListingPreviewModal;
  listingPreviewModalProps: ListingPreviewModalProps;
  openListingId: string | null;
};

export default function useListingPreviewModalForListingSearch({
  toApiListingsUrl,
  currentPage,
  changePage,
  isSaved,
}: Arguments): ReturnType {
  const {
    listingsCount = 0,
    listingIdsByIndex,
    pageSize = 24,
    searchListingsById,
    byId,
  } = useSelector((state: StoreState) => ({
    listingsCount: state.listings.pagination?.totalCount,
    listingIdsByIndex: state.listings.listingIdsByIndex,
    ids: state.listings.pagination?.ids,
    pageSize: state.listings.pagination?.pageSize,
    searchListingsById: state.listings.searchResults,
    byId: state.listings.byId,
  }));
  const { t } = useTranslation();
  const [listingIndex, setListingIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const listingIndexesThatNeedToBeFetched = useRef<number[]>([]);
  const pendingAnalyticActions = useRef<Array<{ index: number; fireAction: (l: Listing) => void }>>(
    [],
  );
  const { listingPreviewAnalytics } = useAnalytics();
  const { data: tourbooks } = useFetchTourbookSummaries();

  const fetchListing = useFetchListing();
  const fetchSearchListing = useFetchSearchListingsFromListingPreview();

  const sourcePage = PARAMETERS.searchResultsPage;

  const dispatchOpenListingPreviewAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'OPEN_LISTING_PREVIEW',
      listingPreviewAction: PARAMETERS.openListingPreview,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });

  const dispatchViewListingAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'VIEW_LISTING',
      listingPreviewAction: PARAMETERS.viewListing,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });
  const dispatchClosePreviewAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'CLOSE_LISTING_PREVIEW',
      listingPreviewAction: PARAMETERS.closeListingPreview,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });
  const dispatchPaginateListingPreviewAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'PAGINATE_LISTING',
      listingPreviewAction: PARAMETERS.paginate,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });

  const onError = useCallback(() => {
    Notification.error({
      title: t('common:genericFailureTitle'),
      text: t('common:genericFailureMessage'),
    });
  }, [t]);

  const listingId = listingIndex === null ? null : listingIdsByIndex[listingIndex];
  const searchListing = listingId === null ? null : searchListingsById[listingId];
  const listing = searchListing ? byId[searchListing.id] : null;

  const cacheSearchListingImage = (id: string) => {
    new Image().src = searchListingsById[id].photo.mediumPath;
  };

  const { isMobile } = useBreakpoint();
  useEffect(() => {
    if (isMobile) setIsModalOpen(false);
  }, [isMobile]);

  const calculatePageFromListingIndex = (index: number): number => Math.floor(index / pageSize) + 1;

  const fetchListingByIndex = useCallback(
    (
      index: number,
      successCallback?: (listing: Listing) => void,
      errorCallback?: () => void,
    ): boolean => {
      const hasAlreadyBeenFetched = byId[listingIdsByIndex[index]];
      if (hasAlreadyBeenFetched) return true;

      if (listingIdsByIndex[index]) {
        fetchListing(listingIdsByIndex[index], successCallback, errorCallback);
        return true;
      }

      listingIndexesThatNeedToBeFetched.current.push(index);
      return false;
    },
    [byId, listingIdsByIndex, fetchListing],
  );

  useEffect(() => {
    listingIndexesThatNeedToBeFetched.current = listingIndexesThatNeedToBeFetched.current.filter(
      index => !fetchListingByIndex(index, undefined, onError),
    );
  }, [fetchListingByIndex, listingIdsByIndex, onError]);

  const getListingFromIndex = useCallback(
    (index: number): Listing | null => {
      const currentListingId = listingIdsByIndex[index];
      return currentListingId ? byId[currentListingId] : null;
    },
    [byId, listingIdsByIndex],
  );

  const stringifiedPendingAnalyticsActions = pendingAnalyticActions.current.toString();

  useEffect(() => {
    pendingAnalyticActions.current = pendingAnalyticActions.current.filter(
      ({ index, fireAction }) => {
        const currentListing = getListingFromIndex(index);
        if (currentListing) {
          fireAction(currentListing);

          return false;
        }

        return true;
      },
    );
  }, [byId, getListingFromIndex, listingIdsByIndex, stringifiedPendingAnalyticsActions]);

  const fireAnalyticsAction = (index: number, fireAction: (l: Listing) => void) => {
    const currentListing = getListingFromIndex(index);
    if (currentListing) {
      fireAction(currentListing);
    } else {
      pendingAnalyticActions.current.push({ index, fireAction });
    }
  };

  const fetchCurrentAndAdjacentListingsIfNeccesary = (index: number) => {
    fetchListingByIndex(index, undefined, onError);
    fetchListingByIndex(index + 1, ({ id }) => cacheSearchListingImage(id));
    fetchListingByIndex(index - 1, ({ id }) => cacheSearchListingImage(id));
  };

  const fetchSearchListingsPageContainingIndex = (index: number): void => {
    const apiListingsUrl = toApiListingsUrl({ page: calculatePageFromListingIndex(index) });
    fetchSearchListing(apiListingsUrl, onError);
  };

  const fetchSearchListingsIfNecessary = (index: number) => {
    const OFFSET = 2; // Number of listings away from new page to trigger fetch
    if (listingIdsByIndex[index - OFFSET] === undefined && index > OFFSET) {
      fetchSearchListingsPageContainingIndex(index - OFFSET);
    } else if (listingIdsByIndex[index + OFFSET] === undefined && index + OFFSET < listingsCount) {
      fetchSearchListingsPageContainingIndex(index + OFFSET);
    }
  };

  const setListingIndexAndFetchListings = (index: number) => {
    setListingIndex(index);
    fetchCurrentAndAdjacentListingsIfNeccesary(index);
    fetchSearchListingsIfNecessary(index);
  };

  const goToPreviousListing = () => {
    if (listingIndex === null || listingIndex === 0) return;
    setListingIndexAndFetchListings(listingIndex - 1);
    fireAnalyticsAction(listingIndex, dispatchPaginateListingPreviewAction);
    fireAnalyticsAction(listingIndex - 1, dispatchViewListingAction);
  };

  const goToNextListing = () => {
    if (listingIndex === null || listingIndex >= listingsCount - 1) return;
    setListingIndexAndFetchListings(listingIndex + 1);
    fireAnalyticsAction(listingIndex, dispatchPaginateListingPreviewAction);
    fireAnalyticsAction(listingIndex + 1, dispatchViewListingAction);
  };

  const openListingPreviewModal = (listingIndexInPage: number) => {
    const newListingIndex = (currentPage - 1) * pageSize + listingIndexInPage;
    setListingIndexAndFetchListings(newListingIndex);
    fireAnalyticsAction(newListingIndex, dispatchOpenListingPreviewAction);
    fireAnalyticsAction(newListingIndex, dispatchViewListingAction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (listingIndex !== null) {
      const newPage = calculatePageFromListingIndex(listingIndex);
      changePage(newPage);
      fireAnalyticsAction(listingIndex, dispatchClosePreviewAction);
    }

    setIsModalOpen(false);
    listingIndexesThatNeedToBeFetched.current = [];
  };

  return {
    openListingId: listingId,
    openListingPreviewModal,
    ListingPreviewModal,
    listingPreviewModalProps: {
      isOpen: isModalOpen,
      closeModal,
      afterCloseModal: () => setListingIndex(null),
      goToPreviousListing,
      goToNextListing,
      listingIndex,
      partialListing: searchListing,
      listing,
      tourbooks,
      listingsCount,
      sourcePage,
      isSaved: listingId ? isSaved(listingId) : false,
    },
  };
}
