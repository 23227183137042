import { Breakpoint } from '@root/types';
import thirdPartyUseBreakpoint from 'use-breakpoint';
import { defaultConfig, breakpoints } from '.';

const DEFAULT_BREAKPOINT: Breakpoint = breakpoints.DESKTOP;

const useBreakpoint = (breakpointConfig = defaultConfig) =>
  thirdPartyUseBreakpoint(breakpointConfig, DEFAULT_BREAKPOINT).breakpoint;

export default useBreakpoint;
