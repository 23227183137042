import { CustomIcon, Notification } from '@components/shared';
import { useTranslation, Trans } from 'react-i18next';
import { RawCheckbox } from '@components/shared/forms';
import routes from '@root/routes';
import { TourbookSummary, Listing, SearchListing, BuildingListing, StoreState } from '@root/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { create, destroy } from '@shared/typedApi';
import useAnalyticsEvent from '@root/shared/useAnalytics';
import { useSelector } from 'react-redux';
import { PARAMETERS } from '@root/tracking/constants';
import { getSourcePageFrom } from '@root/tracking/helpers';
import s from './TourbookOverlay.module.less';

type Props = {
  tourbooks: TourbookSummary[];
  close: () => void;
  listing: Listing | SearchListing | BuildingListing;
  goToCreateCard: () => void;
};

const ToggleCard = ({ tourbooks, close, listing, goToCreateCard }: Props) => {
  const { tourbookInteraction } = useAnalyticsEvent();

  const sourcePage = useSelector((state: StoreState) => getSourcePageFrom(state));
  const sourceContentIsListingPreviewModal = useSelector(
    (state: StoreState) => state.ui.islistingPreviewModalOpen,
  );

  const queryClient = useQueryClient();

  const addListingToTourbookMutation = useMutation(
    async ({ tourbookId, listingId }: { listingId: string; tourbookId: string }) =>
      create(routes.api.tourbookListings(tourbookId), { listingId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([routes.api.tourbooks('newest')]);
      },
    },
  );

  const removeInternalListingFromTourbookMutation = useMutation(
    async ({ tourbookId, listingId }: { tourbookId: string; listingId: string }) =>
      destroy(routes.api.tourbookListing({ tourbookId, listingId }), {}),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([routes.api.tourbooks('newest')]);
      },
    },
  );

  const onChangeCheckbox = (tourbook: TourbookSummary) => {
    if (!tourbook.listingIds.includes(listing.id)) {
      addListingToTourbookMutation.mutate(
        {
          listingId: listing.id,
          tourbookId: tourbook.id,
        },
        {
          onSuccess: () => {
            tourbookInteraction({
              actionType: 'LISTING_ASSOCIATED_WITH_TOURBOOK',
              action: PARAMETERS.addListing,
              sourcePage: sourcePage!,
              sourceContent: sourceContentIsListingPreviewModal
                ? PARAMETERS.listingPreview
                : PARAMETERS.tourbookOverlay,
              otherAttributes: {
                destinationTourbookId: tourbook.id,
                destinationTourbookName: tourbook.name,
                destinationTourbookOwnerId: tourbook.ownerId,
                destinationTourbookOwnerVTSId: tourbook.ownerVTSId,
                destinationTourbookListingCount: tourbook.listingIds
                  ? tourbook.listingIds.length + 1
                  : 0,
                ...listing.analyticsInformation,
              },
            });
            Notification.info({
              title: t('toasts.listingAddedTitle'),
              /* eslint-disable react/jsx-no-literals */
              text: (
                <Trans ns="tourbook" i18nKey="toasts.listingAddedText">
                  View in your <a href={routes.tourbooks}>Tourbooks</a>
                </Trans>
              ),
              iframe: {
                text: t('toasts.listingAddedText'),
                links: [{ url: routes.tourbooks, method: 'push' }],
              },
              /* eslint-enable react/jsx-no-literals */
              placement: 'bottomLeft',
              duration: 3.5,
            });
          },
        },
      );
    } else {
      removeInternalListingFromTourbookMutation.mutate(
        {
          listingId: listing.id,
          tourbookId: tourbook.id,
        },
        {
          onSuccess: async () => {
            tourbookInteraction({
              actionType: 'LISTING_DELETED_FROM_TOURBOOK',
              action: PARAMETERS.deleteListing,
              sourcePage: sourcePage!,
              sourceContent: sourceContentIsListingPreviewModal
                ? PARAMETERS.listingPreview
                : PARAMETERS.tourbookOverlay,
              otherAttributes: {
                destinationTourbookId: tourbook.id,
                destinationTourbookName: tourbook.name,
                destinationTourbookOwnerId: tourbook.ownerId,
                destinationTourbookOwnerVTSId: tourbook.ownerVTSId,
                destinationTourbookListingCount: tourbook.listingIds
                  ? tourbook.listingIds.length - 1
                  : 0,
                ...listing.analyticsInformation,
              },
            });
            Notification.info({
              title: t('toasts.listingRemoved'),
              placement: 'bottomLeft',
              duration: 3.5,
            });
          },
        },
      );
    }
  };

  const { t } = useTranslation('tourbook');
  return (
    <>
      <section className={s.toggleCardHeader}>
        <button
          data-testid="goToNewTourbookButton"
          type="button"
          onClick={goToCreateCard}
          className={s.headerButton}
        >
          <CustomIcon type="plus" />
        </button>
        <h3>{t('manageDialogs.addToTourbook')}</h3>
        <button title="Close Overlay" onClick={close} type="button">
          <CustomIcon type="close" />
        </button>
      </section>
      <section className="overflow-scroll p-2">
        <ul className="pb-0">
          {tourbooks.map(tourbook => (
            <li className={s.tourbookRow} key={tourbook.id}>
              <RawCheckbox
                labelClassName="justify-between"
                className={s.tourbookCheckboxText}
                checked={tourbook.listingIds.includes(listing.id)}
                onChange={() => onChangeCheckbox(tourbook)}
                horizontal
              >
                {tourbook.name}
              </RawCheckbox>
            </li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default ToggleCard;
