import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

type Props = {
  labels: String[];
  datasets: any[];
  options?: any;
  plugins?: any[];
  height?: number;
};
const BarChart = ({ height, labels, datasets, options = {}, plugins = [] }: Props) => {
  const data = {
    labels,
    datasets,
  };

  return <Bar data={data} options={options} plugins={plugins} height={height} />;
};

export default BarChart;
