import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import YesNoMaybeWithSource from '@components/shared/Admin/DataCollectionFields/YesNoMaybeWithSource';
import StringListWithSource from '@components/shared/Admin/DataCollectionFields/StringListWithSource';
import CountWithSource from '@components/shared/Admin/DataCollectionFields/CountWithSource';

const ListingAmenitiesList = ({ ...props }) => {
  const { t } = useTranslation('admin');

  const amenities = [
    {
      label: t('listing.breakOutSpace'),
      render: (
        <YesNoMaybeWithSource
          fieldName="breakOutSpace"
          key="breakOutSpace"
          pageName="listing"
          value={props.breakOutSpace}
        ></YesNoMaybeWithSource>
      ),
    },
    {
      label: t('listing.serviceElevatorCount'),
      render: (
        <CountWithSource
          fieldName="serviceElevatorCount"
          key="serviceElevatorCount"
          pageName="listing"
          value={props.serviceElevatorCount}
        />
      ),
    },
    {
      label: t('listing.keyCardSpaceEntry'),
      render: (
        <YesNoMaybeWithSource
          fieldName="keyCardSpaceEntry"
          key="keyCardSpaceEntry"
          pageName="listing"
          value={props.keyCardSpaceEntry}
        ></YesNoMaybeWithSource>
      ),
    },
    {
      label: t('listing.furnished.label'),
      render: (
        <StringListWithSource
          key="furnished"
          pageName="listing"
          fieldName="furnished"
          value={props.furnished}
          options={[
            { name: 'yes', value: 'Yes' },
            { name: 'no', value: 'No' },
            { name: 'partial', value: 'Partial' },
          ]}
        />
      ),
    },
    {
      label: t('listing.passengerElevatorCount'),
      render: (
        <CountWithSource
          fieldName="passengerElevatorCount"
          key="passengerElevatorCount"
          pageName="listing"
          value={props.passengerElevatorCount}
        />
      ),
    },
    {
      label: t('listing.phoneBooths'),
      render: (
        <YesNoMaybeWithSource
          fieldName="phoneBooths"
          key="phoneBooths"
          pageName="listing"
          value={props.phoneBooths}
        ></YesNoMaybeWithSource>
      ),
    },
    {
      label: t('listing.privateOutdoorSpace'),
      render: (
        <YesNoMaybeWithSource
          fieldName="privateOutdoorSpace"
          key="privateOutdoorSpace"
          pageName="listing"
          value={props.privateOutdoorSpace}
        ></YesNoMaybeWithSource>
      ),
    },

    {
      label: t('listing.sharedPantry'),
      render: (
        <YesNoMaybeWithSource
          fieldName="sharedPantry"
          key="sharedPantry"
          pageName="listing"
          value={props.sharedPantry}
        ></YesNoMaybeWithSource>
      ),
    },
  ];
  const orderedAmenities = amenities
    .sort((amenityA, amenityB) => amenityA.label.localeCompare(amenityB.label))
    .map(amenity => {
      return amenity.render;
    });

  return <>{orderedAmenities}</>;
};
export default memo(ListingAmenitiesList);
