import { useTranslation } from 'react-i18next';
import Header from '@components/shared/Header';
import useAnalytics from '@shared/useAnalytics';
import { Listing, Room as IRoom, MultipathImage } from '@root/types';
import { FullWidthLightBox, useLightbox, List } from '@components/shared';
import { PARAMETERS } from '@root/tracking/constants';
import { flatMap, take } from 'lodash';
import useToggle from '@shared/useToggle';
import Room from './Room';
import s from './Rooms.module.less';

type Props = {
  listing: Listing;
};

const sortPhotosInRooms = (rooms: IRoom[], photos: Array<MultipathImage>): MultipathImage[] => {
  const photosInRoomsCloudinaryIds = flatMap(rooms, r => r.photos).map(p => p.cloudinaryId);
  return photos.filter(
    p => p.cloudinaryId && photosInRoomsCloudinaryIds.includes(p.cloudinaryId),
  ) as MultipathImage[];
};

const INITIAL_DISPLAYED_ROOM_LIMIT = 15;
const Rooms = ({ listing }: Props) => {
  const { t } = useTranslation('listing');
  const roomsWithPhotos = listing.rooms.filter(r => r.photos.length !== 0);
  const photosInRooms = sortPhotosInRooms(roomsWithPhotos, listing.photos);
  const { galleryInteraction } = useAnalytics();

  // all the state for the full-width lightbox
  const {
    lightboxOpen,
    currentIndex,
    activeTab,
    visibleElementOnTours,
    setLightboxOpen,
    setCurrentIndex,
    setActiveTab,
    setVisibleElementOnTours,
    closeLightbox,
  } = useLightbox({ hasVideo: false, has3dTour: false });

  const openLightbox = (room: IRoom) => {
    if (!lightboxOpen) {
      const index = photosInRooms.findIndex(p => p.cloudinaryId === room.photos[0].cloudinaryId);
      setCurrentIndex(index);
      setLightboxOpen(true);
      galleryInteraction({
        actionType: 'LIGHTBOX_OPENED',
        action: PARAMETERS.openLightbox,
        sourcePage: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.listingAreasGallery,
      });
    }
  };

  const handleLightboxClose = () => {
    setTimeout(closeLightbox, 250);
  };

  const { value: displayingMore, toggle: toggleDisplayingMore } = useToggle();

  if (roomsWithPhotos.length === 0) {
    return null;
  }

  return (
    <section data-testid="rooms" className={s.container}>
      <Header labelQaTestId="area-heading">{t('rooms.header')}</Header>
      <section data-qa-testid="area-thumbnails">
        <List<IRoom>
          className={s.roomList}
          items={
            displayingMore ? roomsWithPhotos : take(roomsWithPhotos, INITIAL_DISPLAYED_ROOM_LIMIT)
          }
          type="flex"
          renderItem={room => (
            <Room address={listing.address} room={room} onClick={() => openLightbox(room)} />
          )}
        />
        {roomsWithPhotos.length > INITIAL_DISPLAYED_ROOM_LIMIT ? (
          <button className={s.toggleMoreButton} type="button" onClick={toggleDisplayingMore}>
            {t(displayingMore ? 'common:seeLess' : 'common:seeMore')}
          </button>
        ) : null}
      </section>

      {lightboxOpen && (
        <div className={s.lightboxContainer}>
          <FullWidthLightBox
            address={listing.address}
            assets={photosInRooms}
            currentIndex={currentIndex}
            imagesZipUrl={listing.createImageZipUrl}
            onClose={handleLightboxClose}
            onChange={setCurrentIndex}
            video={null}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tourEmbedUrl={null}
            visibleElementOnTours={visibleElementOnTours}
            setVisibleElementOnTours={setVisibleElementOnTours}
            sourcePage={PARAMETERS.listingPage}
            sourceContent={PARAMETERS.listingAreasLightboxGallery}
          />
        </div>
      )}
    </section>
  );
};

export default Rooms;
