import { isNull } from 'lodash';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@components/shared/CustomIcon';
import Header from '@components/shared/Header';
import { CertificationLevel } from '@root/types';
import s from './Certifications.module.less';
import CertificationBox from './CertificationBox';

type Props = {
  wiredCertification: CertificationLevel | null;
  leedCertification: CertificationLevel | null;
};

const Certifications = ({ wiredCertification = null, leedCertification = null }: Props) => {
  const { t } = useTranslation('building');

  const certUpdate = {
    certified: <CustomIcon type="wiredScoreCertified" className={s.certIcon} />,
    silver: <CustomIcon type="wiredScoreSilver" className={s.certIcon} />,
    gold: <CustomIcon type="wiredScoreGold" className={s.certIcon} />,
    platinum: <CustomIcon type="wiredScorePlatinum" className={s.certIcon} />,
  };

  if (isNull(wiredCertification) && isNull(leedCertification)) return null;

  return (
    <section className={s.container}>
      <Header>{t('certifications.title')}</Header>
      <CertificationBox
        icon={wiredCertification != null ? certUpdate[wiredCertification] : null}
        headerText={t('certifications.wiredScore')}
        level={wiredCertification}
        whyDoesItMatterText={t('certifications.wiredScoreMatter')}
        explanationText={t('certifications.wiredScoreExplanation')}
      ></CertificationBox>
      <CertificationBox
        icon={<CustomIcon type="leeds" className={s.certIcon} />}
        headerText={t('certifications.leedCertification')}
        level={leedCertification}
        whyDoesItMatterText={t('certifications.leedCertificationMatter')}
        explanationText={t('certifications.leedCertificationExplanation')}
      />
    </section>
  );
};

export default Certifications;
