import {
  ConvertableArea,
  ConvertableMoneyPerAreaPerPeriod,
  ConvertableMoneyPerPeriod,
  Currency,
} from '@root/types';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import { useState } from 'react';
import tourbooksActions from '@store/actions/tourbooks';
import { IconButton, PopperTooltip } from '@components/shared';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isInIframe } from '@root/shared/iframeUtils';
import AskingRentEditForm from './AskingRentEditForm';
import ListingAttribute from '../ListingAttribute';
import s from './AskingRent.module.less';
import { getDisplayPrice, getDisplayPricePerArea } from '../../helper';

type Props = {
  askingRentInMoneyPerAreaPerPeriod: ConvertableMoneyPerAreaPerPeriod | null;
  askingRentInMoneyPerPeriod: ConvertableMoneyPerPeriod | null;
  size: ConvertableArea | null;
  tourbookListingId: string;
  minAskingRentInMoneyPerAreaPerPeriod?: ConvertableMoneyPerAreaPerPeriod | null;
  minAskingRentInMoneyPerPeriod?: ConvertableMoneyPerPeriod | null;
  maxAskingRentInMoneyPerAreaPerPeriod?: ConvertableMoneyPerAreaPerPeriod | null;
  maxAskingRentInMoneyPerPeriod?: ConvertableMoneyPerPeriod | null;
  isExternal: boolean;
  currencyCode: Currency;
  readOnly: boolean;
};

const AskingRent = ({
  askingRentInMoneyPerAreaPerPeriod,
  askingRentInMoneyPerPeriod,
  currencyCode,
  isExternal = false,
  maxAskingRentInMoneyPerAreaPerPeriod,
  maxAskingRentInMoneyPerPeriod,
  minAskingRentInMoneyPerAreaPerPeriod,
  minAskingRentInMoneyPerPeriod,
  readOnly,
  size,
  tourbookListingId,
}: Props) => {
  const { t } = useTranslation('tourbook');
  const flags = useFlags();
  const dispatch = useDispatch();
  const { tourbookId } = useParams<{ tourbookId: string }>();
  const [isEditing, setIsEditing] = useState(false);

  const hasEditedAskingRent =
    flags['tourbook-asking-rent'] &&
    (minAskingRentInMoneyPerAreaPerPeriod || maxAskingRentInMoneyPerAreaPerPeriod);

  // Remove this whole clause when ff 'external-listing-asking-rent-range' is removed.
  const showExternalWhenFFOn = !isExternal || flags['external-listing-asking-rent-range'];

  const hasSize = size && size?.magnitude > 0;
  const showButtons = !readOnly && hasSize && showExternalWhenFFOn;

  const handleResetEdit = async () => {
    dispatch(
      tourbooksActions.updateTourbookListing({
        tourbookId,
        tourbookListingId,
        updatedAttributes: {
          minAskingRent: null,
          maxAskingRent: null,
        },
      }),
    );
  };

  const editButton = (
    <IconButton
      icon="edit"
      className={s.icon}
      onClick={() => setIsEditing(true)}
      data-testid="edit-rent-button"
    />
  );
  const baseRentLabelWithEditButton = (
    <>
      {t('listingCard.baseRentTitle')}
      {showButtons && hasEditedAskingRent && !isExternal ? (
        <PopperTooltip
          trigger="hover"
          triggerElementClassName={s.tooltip}
          toolTipPlacement="top"
          triggerElement={
            <IconButton
              icon="reset"
              className={s.icon}
              onClick={() => handleResetEdit()}
              data-testid="reset-edited-rent-button"
            />
          }
          popperElementClassName={s.tooltipText}
          popperElement={t('listingCard.askingRent.resetToOriginal')}
        />
      ) : null}
      {showButtons &&
        (isInIframe() ? (
          <PopperTooltip
            trigger="hover"
            triggerElementClassName={s.tooltip}
            toolTipPlacement="top"
            triggerElement={editButton}
            popperElementClassName={s.tooltipText}
            popperElement={t('listingCard.askingRent.editForThisTourbookTooltip')}
          />
        ) : (
          editButton
        ))}
    </>
  );

  if (!isEditing) {
    const shouldTryToDisplayPrice =
      (askingRentInMoneyPerAreaPerPeriod && askingRentInMoneyPerPeriod) || hasEditedAskingRent;

    return hasSize && shouldTryToDisplayPrice ? (
      <div>
        <ListingAttribute
          label={baseRentLabelWithEditButton}
          value={
            <>
              {getDisplayPricePerArea({
                t,
                askingRent: askingRentInMoneyPerAreaPerPeriod,
                minAskingRent: minAskingRentInMoneyPerAreaPerPeriod,
                maxAskingRent: maxAskingRentInMoneyPerAreaPerPeriod,
                acceptRange: flags['tourbook-asking-rent'],
              })}
              {!!hasEditedAskingRent && !isExternal && (
                <>
                  {/* eslint-disable react/jsx-no-literals */}
                  {readOnly ? (
                    <span data-testid="editedFieldAsteriskIndicator"> *</span>
                  ) : (
                    <span className={s.editedLabel} data-testid="editedFieldTextIndicator">
                      {t('listingCard.askingRent.edited')}
                    </span>
                  )}
                  {/* eslint-enable react/jsx-no-literals */}
                </>
              )}
            </>
          }
          labelClassname={s.editableListingAttributeLabel}
        />
        <ListingAttribute
          label=""
          value={getDisplayPrice({
            t,
            askingRent: askingRentInMoneyPerPeriod,
            minAskingRent: minAskingRentInMoneyPerPeriod,
            maxAskingRent: maxAskingRentInMoneyPerPeriod,
            acceptRange: flags['tourbook-asking-rent'],
          })}
          valueClassName={cn(s.pricePerPeriod)}
        />
      </div>
    ) : (
      <ListingAttribute
        label={baseRentLabelWithEditButton}
        value={t('listingCard.nullBaseRent')}
        labelClassname={s.editableListingAttributeLabel}
      />
    );
  }

  return (
    <AskingRentEditForm
      tourbookId={tourbookId}
      tourbookListingId={tourbookListingId}
      currencyCode={currencyCode}
      listingAskingRentInMoneyPerAreaPerPeriod={askingRentInMoneyPerAreaPerPeriod}
      minAskingRentInMoneyPerAreaPerPeriod={minAskingRentInMoneyPerAreaPerPeriod}
      maxAskingRentInMoneyPerAreaPerPeriod={maxAskingRentInMoneyPerAreaPerPeriod}
      stopEditing={() => setIsEditing(false)}
      isExternal={isExternal}
    />
  );
};

export default AskingRent;
