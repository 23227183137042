import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import Divider from '@components/shared/Divider';
import { Select, NumberInput } from '@components/shared/forms';
import css from './Kitchen.module.less';

const Kitchen = React.forwardRef((_props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
  const { t } = useTranslation('admin');

  const beverageDispenserType = [
    {
      label: t('listing.rooms.form.kitchen.beverageDispenserType.beer'),
      value: 'beer',
    },
    {
      label: t('listing.rooms.form.kitchen.beverageDispenserType.coffee'),
      value: 'coffee',
    },
    { label: t('listing.rooms.form.kitchen.beverageDispenserType.water'), value: 'water' },
    { label: t('listing.rooms.form.kitchen.beverageDispenserType.wine'), value: 'wine' },
  ];

  return (
    <>
      <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-4x', 'u-p-bottom-2x')}>
        {t('listing.rooms.form.kitchen.title')}
      </h2>
      <section className={css.listingRoomFormSection}>
        <div className={css.refrigeratorCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.refrigeratorCount')}
            name="refrigeratorCount"
          />
        </div>
        <div className={css.dishwasherCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.dishwasherCount')}
            name="dishwasherCount"
          />
        </div>
        <div className={css.kitchenSinkCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.kitchenSinkCount')}
            name="kitchenSinkCount"
          />
        </div>
        <div className={css.microwaveCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.microwaveCount')}
            name="microwaveCount"
          />
        </div>
        <div className={css.ovenCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.ovenCount')}
            name="ovenCount"
          />
        </div>
        <div className={css.stoveCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.stoveCount')}
            name="stoveCount"
          />
        </div>
        <div className={css.beverageDispenserType}>
          <Select
            labelText={t(`listing.rooms.form.kitchen.beverageDispenserType.label`)}
            name="beverageDispenser"
            options={beverageDispenserType}
            placeholder=""
          />
        </div>
        <div className={css.kitchenTableCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.kitchenTableCount')}
            name="kitchenTableCount"
          />
        </div>
        <div className={css.kitchenChairCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.kitchen.kitchenChairCount')}
            name="kitchenChairCount"
          />
        </div>
      </section>
      <Divider />
    </>
  );
});

export default React.memo(Kitchen);
