import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '@root/types';
import ListingCard from '@components/layouts/Truva/ListingSearch/Cards/ListingCardDeprecated';
import { TabPane } from '@components/shared';
import { Trans } from 'react-i18next';
import actions from '@store/actions/saved';
import { isListingSaved, getSavedListings } from '@store/selectors';
import Layout from '../Layout';
import favoriteStyles from './Listings.module.less';

const mapState = (state: StoreState) => ({
  savedListings: getSavedListings(state),
  isSaved: (listingId: string) => isListingSaved(state, listingId),
});

const mapDispatch = {
  loadPage: () => actions.loadSavedListingsPage(),
  toggleSaveListing: ({ listing, willBeSaved, onSuccess }) =>
    actions.toggleSaveListing({
      listing,
      willBeSaved,
      onSuccess,
      sourcePage: 'saved listings page',
    }),
  clickSavedListing: () => actions.savedListingClick(),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

export const RawSavedListings = ({
  savedListings,
  toggleSaveListing,
  isSaved,
  loadPage,
  clickSavedListing,
}: ReduxProps) => {
  useEffect(() => {
    loadPage();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const count = savedListings.length;

  return (
    <Layout active="listings">
      <TabPane key="listings">
        <h1 className={favoriteStyles.title}>
          <Trans i18nKey="favorites:numSavedListings" count={count}>
            <span className="u-translation--bold">{{ count }}</span>
          </Trans>
        </h1>
        <ul className={favoriteStyles.cardList}>
          {savedListings.map(listing => (
            <li key={listing.id}>
              <ListingCard
                loggedInUser
                favorited={isSaved(listing.id)}
                listing={listing}
                toggleSaveListing={toggleSaveListing}
                onClick={clickSavedListing}
              />
            </li>
          ))}
        </ul>
      </TabPane>
    </Layout>
  );
};

export default connector(RawSavedListings);
