/* eslint-disable no-nested-ternary */
import { useRef, useEffect } from 'react';
import { Carousel } from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import cn from 'classnames';
import { useLightbox, FullWidthLightBox } from '@components/shared';
import { SourcePage, SourceContent } from '@root/store/actions/videoAnalytics';
import { Address, MultipathImage, Video } from '@root/types';
import Thumbnails from '@components/layouts/Truva/Listing/Gallery/Thumbnails';
import useAnalytics from '@shared/useAnalytics';
import actions from '@root/store/actions/listingPage';
import { useDispatch } from 'react-redux';
import useAltText from '@root/shared/useAltText';
import s from './CarouselGallery.module.less';

interface Props {
  photos: MultipathImage[];
  video?: Video | null;
  tourEmbedUrl?: string | null;
  imagesZipUrl?: string | null;
  infinite?: boolean;
  sourcePage: SourcePage;
  sourceContent: SourceContent;
  address?: Address;
  companyName?: string;
}

// THIS IS THE MOBILE ONLY CarouselGallery
const CarouselGallery = ({
  photos,
  infinite,
  video = null,
  tourEmbedUrl = null,
  imagesZipUrl = '',
  sourcePage,
  sourceContent,
  address,
  companyName,
}: Props) => {
  const { t } = useTranslation('building');
  const { galleryInteraction, PARAMETERS } = useAnalytics();
  const { getAltText } = useAltText({ address });

  // const [currentIndex, setCurrentIndex] = useState(0);

  const carousel = useRef<Carousel | null>(null);

  // all the state for the full-width lightbox
  const {
    lightboxOpen,
    currentIndex,
    activeTab,
    visibleElementOnTours,
    setLightboxOpen,
    setCurrentIndex,
    setActiveTab,
    setVisibleElementOnTours,
    closeLightbox,
  } = useLightbox({ hasVideo: !!video, has3dTour: false });

  const dispatch = useDispatch();

  const handleGoToPrev = () => {
    if (carousel.current) {
      carousel.current.prev();
    }
  };

  const handleGoToNext = () => {
    if (carousel.current) {
      carousel.current.next();
    }
  };

  const handleKeyDown = event => {
    if (lightboxOpen || isEmpty(photos)) return;
    if (event.key === 'ArrowLeft') handleGoToPrev();
    if (event.key === 'ArrowRight') handleGoToNext();
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleGoToIndex = (index: number, dontAnimate = false) => {
    if (carousel.current) {
      carousel.current.goTo(index, dontAnimate);
    }
  };

  const toggleOpenLightbox = () => {
    if (!lightboxOpen) {
      setLightboxOpen(true);
      galleryInteraction({
        actionType: 'LIGHTBOX_OPENED',
        action: PARAMETERS.openLightbox,
        sourcePage,
        sourceContent,
      });
    } else {
      setLightboxOpen(false);
    }
  };

  const handleLightboxClose = () => {
    setTimeout(() => {
      closeLightbox();
    }, 250);
  };

  const handleThumbnailClick = (index, thumbnailType) => {
    handleGoToIndex(index);
    dispatch(actions.galleryThumbnailClicked(thumbnailType, sourcePage, sourceContent));
  };

  const lightboxComponent = (
    <FullWidthLightBox
      address={address!}
      assets={photos}
      currentIndex={currentIndex}
      imagesZipUrl={imagesZipUrl}
      onClose={() => {
        // eslint-disable-next-line no-console
        handleLightboxClose();
      }}
      onChange={setCurrentIndex}
      video={video}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      visibleElementOnTours={visibleElementOnTours}
      setVisibleElementOnTours={setVisibleElementOnTours}
      sourcePage={sourcePage}
      tourEmbedUrl={tourEmbedUrl}
      companyName={companyName}
    />
  );

  return !isEmpty(photos) ? (
    <div
      className={s.carouselContainer}
      role="button"
      onKeyDown={() => {}}
      tabIndex={0}
      data-testid="CarouselGallery"
    >
      <Carousel
        dots={false}
        infinite={infinite}
        ref={carousel}
        afterChange={index => setCurrentIndex(index)}
      >
        {photos.map(photo => (
          /* eslint-disable react/jsx-no-literals */
          <div
            role="button"
            onClick={toggleOpenLightbox}
            key={photo.path}
            data-testid={`asset-${photo.description}`}
          >
            {/* eslint-enable react/jsx-no-literals */}
            <div className={s.imageContainer}>
              <img
                className={s.image}
                alt={getAltText({
                  type: photo.type,
                  options: { roomName: photo.description, companyName },
                })}
                src={photo.path}
              />
            </div>
          </div>
        ))}
      </Carousel>
      <p className={cn(s.photoCount)}>
        {t('gallery.photoCount', { currentIndex: currentIndex + 1, photosLength: photos.length })}
      </p>
      {lightboxOpen ? lightboxComponent : null}
      <button
        // eslint-disable-next-line max-len
        className={cn(s.galleryViewAllButton)} // base styles for this  view all button from mosaic gallery are imported
        onClick={() => toggleOpenLightbox()}
        type="button"
      >
        {t('gallery.viewAll')}
      </button>
      <div className={s.thumbnailContainer}>
        <Thumbnails
          address={address!}
          photos={photos}
          currentIndex={currentIndex}
          onChange={handleThumbnailClick}
          video={video}
          sourcePage={sourcePage}
          sourceContent={sourceContent}
          tourEmbedUrl={null}
          handleTourClick={(mediaType: 'video' | 'tourEmbed') => {
            setVisibleElementOnTours(mediaType);
            setActiveTab('virtualTours');
            setLightboxOpen(true);
          }}
          companyName={companyName}
        />
      </div>
    </div>
  ) : null;
};

export default CarouselGallery;
