import { useParams } from 'react-router';
import routes from '@root/routes';
import { Landlord } from '@root/types';
import useMarketo from '@root/shared/useMarketo';
import { get } from '@shared/typedApi';
import { useQuery } from '@tanstack/react-query';
import NotFound from '../404/NotFound';
import Layout from '../Layout';
import LandlordSkeleton from './LandlordSkeleton';
import LandlordLayout from './Layout';
import usePageLoadAnalyticsEvent from './usePageLoadAnalyticsEvent';

const RawLandlord = () => {
  const { slug } = useParams<{ slug: string }>();
  const { hasLoadedOnce, onPageLoad } = usePageLoadAnalyticsEvent();

  const {
    data: landlord,
    isLoading,
    error,
  } = useQuery<Landlord>([routes.api.landlord(slug)], () => get(routes.api.landlord(slug)), {
    onSuccess: async fetchedLandlord => {
      onPageLoad(fetchedLandlord, false);
      window.prerenderReady = true;
    },
  });

  useMarketo();

  if (isLoading) {
    return (
      <Layout>
        <LandlordSkeleton />
      </Layout>
    );
  }

  if (error) {
    return <NotFound />;
  }

  return landlord ? (
    <LandlordLayout landlord={landlord} hasLoadedOnce={hasLoadedOnce} />
  ) : (
    <NotFound />
  );
};

export default RawLandlord;
