import * as React from 'react';
import { Field } from 'formik';
import cn from 'classnames';
import s from './RadioInput.module.less';

export type Props = {
  name: string;
  value: string;
  labelText: React.ReactNode;
  id?: string;
};

const RadioInput = ({ name, value, labelText, id }: Props) => {
  const uniqueId: string = id || `${name}-${value}`;
  return (
    <>
      <Field
        data-testid={uniqueId}
        className={s.input}
        type="radio"
        id={uniqueId}
        name={name}
        value={value}
      />
      <label className={cn(s.label, s[name])} htmlFor={uniqueId}>
        {labelText}
      </label>
    </>
  );
};

export default RadioInput;
