import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import useEnvVariable from '@root/shared/useEnv';
import { useState } from 'react';
import MarketingMediaForm from '../MarketingMediaForm';
import {
  uploadToCloudinary,
  handleResponse,
  deleteMedia,
  deletePhoto,
  updateOrder,
  updatePhoto,
} from '../helper';
import { BuildingMarketingMedia } from '../types';

const HeroImage = ({ data }: { data: BuildingMarketingMedia[] }) => {
  const { t } = useTranslation('admin');
  const { cloudinaryUploadPreset, cloudinaryCloud } = useEnvVariable();
  const showCancelUploadMediaButton =
    !data[0].title.length && !data[0].cloudinaryId.length && data.length === 1;
  const [draggedItem, setDraggedItem] = useState<BuildingMarketingMedia | undefined>(undefined);
  const [, setDragOverIndex] = useState<number | undefined>(undefined);
  const { setValues, values } = useFormikContext();
  const sortedHeroImage: BuildingMarketingMedia[] = (values as FormikValues)?.heroImage.sort(
    (a, b) => a.order - b.order,
  );
  const currentMediaLength = data.length;

  const addNewForm = () => {
    const newMedia = {
      mediaType: 'hero_image',
      title: '',
      description: '',
      cloudinaryId: '',
      order: sortedHeroImage[sortedHeroImage.length - 1].order + 1,
    };

    const updatedFormikValues = {
      ...((values as FormikValues) || {}),
      heroImage: [...((values as FormikValues)?.heroImage || []), newMedia],
    };

    setValues(updatedFormikValues);
  };

  const disableButton = () => {
    return !(currentMediaLength === 5 || !data[data.length - 1].cloudinaryId);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, photoOrder) => {
    const { files } = event.target;
    if (!files) return;

    const cloudinaryResponse = await uploadToCloudinary(
      files[0],
      cloudinaryUploadPreset || '',
      cloudinaryCloud || '',
      'image',
    );

    handleResponse(
      cloudinaryResponse,
      t('media.photoUploadSuccess'),
      t('media.photoUploadFailure'),
    );

    if (!cloudinaryResponse.secure_url) return;

    const updatedFormikValues = await updatePhoto(
      values,
      'heroImage',
      photoOrder,
      'hero_image',
      cloudinaryResponse.secure_url,
    );
    setValues(updatedFormikValues);
  };

  const onDelete = async (photoOrder: number) => {
    const deleteMessage = t('marketingMedia.heroImage.deleteSuccessMessage');
    const updatedFormikValues = await deleteMedia(
      values,
      'heroImage',
      photoOrder,
      deleteMessage,
      'hero_image',
    );

    setValues(updatedFormikValues);
  };

  const onPhotoDelete = async (photoOrder: number) => {
    const updatedFormikValues = await deletePhoto(values, 'heroImage', photoOrder);

    setValues(updatedFormikValues);
  };

  const onDragStart = (item: BuildingMarketingMedia) => {
    setDraggedItem(item);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    setDragOverIndex(index);
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    if (!draggedItem) return;

    const updatedArray = await updateOrder(
      (values as FormikValues)?.heroImage || [],
      draggedItem,
      index,
    );

    setValues({
      ...((values as FormikValues) || {}),
      heroImage: updatedArray,
    });
    setDraggedItem(undefined);
    setDragOverIndex(undefined);
  };

  return (
    <MarketingMediaForm
      data={data}
      onUpload={handleFileUpload}
      onPhotoDelete={onPhotoDelete}
      showCancelUploadMediaButton={showCancelUploadMediaButton}
      onDelete={onDelete}
      addNewForm={addNewForm}
      disableButton={disableButton()}
      identifier="heroImage"
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragStart={onDragStart}
      showDesciption={false}
    />
  );
};

export default HeroImage;
