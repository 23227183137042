import { components } from 'react-select';
import { CustomIcon } from '@components/shared';
import s from './FilterDropdown.module.less';
import indexOfFirstMatch from './helpers/indexOfFirstMatch';

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <CustomIcon type="close-circle" />
    </components.MultiValueRemove>
  );
};

const OptionBoldingStartOfMatchingWords = props => {
  const {
    label,
    value,
    isSelected,
    selectProps: { inputValue },
  } = props;

  const startBold = indexOfFirstMatch(label, inputValue, true);

  return (
    <>
      <components.Option {...props} className={s.checkbox}>
        <input type="checkbox" checked={isSelected} value={value} onChange={() => null} />
        <span className={s.innerCheckbox} />
        <label className={s.labelText}>
          {startBold >= 0 ? (
            <span>
              {label.slice(0, startBold)}
              <strong>{label.slice(startBold, startBold + inputValue.length)}</strong>
              {label.slice(startBold + inputValue.length)}
            </span>
          ) : (
            label
          )}
        </label>
      </components.Option>
    </>
  );
};

const SelectComponents = {
  MultiValueRemove,
  Option: OptionBoldingStartOfMatchingWords,
  DropdownIndicator: null,
  IndicatorSeparator: null,
};

export default SelectComponents;
