import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { Video, MultipathImage, FirstPhotoOrientation, Address } from '@root/types';
import {
  OnlyInMobile,
  OnlyInDesktopTablet,
  OnlyInDesktop,
  OnlyInTablet,
  useLightbox,
  FullWidthLightBox,
} from '@components/shared';
import { SourcePage, SourceContent } from '@root/store/actions/videoAnalytics';
import useAnalytics from '@shared/useAnalytics';
import useAltText from '@root/shared/useAltText';
import s from './MosaicGallery.module.less';

/*
 * Most of the tech debt created by the first photo orientation is fixed. Changing the CSS
 * seemed somewhat dangerous, so we kept that. That means that all css changes have to happen
 * on two stylesheets (ugh)
 */

export const sourceForAsset = ({
  index,
  numPhotos,
  asset,
  isDesktop,
}: {
  index: number;
  numPhotos: number;
  asset: MultipathImage;
  isDesktop: boolean;
}) => {
  if (numPhotos >= 5) {
    if (index === 0) {
      return asset.mediumPath;
    }
    return asset.smallPath;
  }

  if (numPhotos >= 2) {
    return asset.mediumPath;
  }

  if (isDesktop) {
    return asset.largePath;
  }
  return asset.mediumPath;
};

type Props = {
  video?: Video | null;
  photos: MultipathImage[];
  trackLightboxThumbnailClick?: () => void;
  trackLightboxPagination?: () => void;
  sourcePage: SourcePage;
  sourceContent: SourceContent;
  firstPhotoOrientation: FirstPhotoOrientation;
  imagesZipUrl: string | null;
  tourEmbedUrl?: string | null;
  onLightboxOpened?: () => void;
  onLightboxClosed?: () => void;
  address?: Address;
  companyName?: string;
};

// THIS IS THE ASSETS DISPLAYED FOR TABLET AND DESKTOP
const MosaicGallery = ({
  photos,
  video = null,
  trackLightboxThumbnailClick = () => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  trackLightboxPagination = () => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  sourcePage,
  sourceContent,
  firstPhotoOrientation,
  imagesZipUrl,
  tourEmbedUrl = null,
  onLightboxOpened,
  onLightboxClosed,
  address,
  companyName,
}: Props) => {
  const { t } = useTranslation('building');
  const { galleryInteraction, PARAMETERS } = useAnalytics();
  const { getAltText, getAltTextForVideo } = useAltText({ address });

  // all the state for the full-width lightbox
  const {
    lightboxOpen,
    currentIndex,
    activeTab,
    visibleElementOnTours,
    setLightboxOpen,
    setCurrentIndex,
    setActiveTab,
    setVisibleElementOnTours,
    closeLightbox,
  } = useLightbox({
    hasVideo: !!video,
    has3dTour: !!tourEmbedUrl,
    onOpen: onLightboxOpened,
    onClose: onLightboxClosed,
  });

  const openLightbox = (index: number) => {
    if (!lightboxOpen) {
      setCurrentIndex(index);
      setLightboxOpen(true);
    }
  };

  const handleLightboxClose = () => {
    setTimeout(closeLightbox, 250);
  };

  const flags = useFlags();
  const photosWithoutMap = flags['market-office.building-gallery-filters-map']
    ? photos.filter(p => p.type !== 'staticMap')
    : photos;
  const assetsCount = (photosWithoutMap ? photosWithoutMap.length : 0) + (video ? 1 : 0);
  const maxDisplayCount = firstPhotoOrientation === 'portrait' ? 4 : 5;
  const mediaDisplayCount = assetsCount >= maxDisplayCount ? maxDisplayCount : assetsCount;
  const orderedPhotoPaths = photos.map(p => p.path);
  const videoIndexPlacement = firstPhotoOrientation === 'portrait' ? 2 : 4;

  const galleryAssets = [
    ...photosWithoutMap.slice(0, videoIndexPlacement),
    ...(video ? [{ ...video, isVideo: true }] : []),
    ...photosWithoutMap.slice(videoIndexPlacement),
  ].slice(0, maxDisplayCount);

  return assetsCount > 0 ? (
    <div
      data-testid="media-assets"
      className={cn(
        s[`media-container-${mediaDisplayCount}-assets`],
        video && s.withVideo,
        firstPhotoOrientation === 'landscape' && s.landscape,
      )}
    >
      {galleryAssets.map((asset, index) =>
        'isVideo' in asset ? (
          <button
            type="button"
            onClick={() => {
              setActiveTab('virtualTours');
              setVisibleElementOnTours('video');
              openLightbox(currentIndex);
              galleryInteraction({
                actionType: 'LIGHTBOX_OPENED',
                action: PARAMETERS.viewVideo,
                sourcePage,
                sourceContent,
              });
            }}
            className={s[`position-${index}-${mediaDisplayCount}`]}
            key={asset.cloudinaryId}
          >
            <img src={asset.posterPath} alt={getAltTextForVideo(sourcePage, companyName)} />
          </button>
        ) : (
          <button
            type="button"
            onClick={() => {
              openLightbox(orderedPhotoPaths.indexOf(asset.path));
              galleryInteraction({
                actionType: 'LIGHTBOX_OPENED',
                action: PARAMETERS.openLightbox,
                sourcePage,
                sourceContent,
              });
            }}
            className={s[`position-${index}-${mediaDisplayCount}`]}
            key={asset.path}
          >
            <OnlyInMobile>
              <img
                src={asset.mediumPath}
                alt={getAltText({
                  type: asset.type,
                  options: { roomName: asset.description, companyName },
                })}
              />
            </OnlyInMobile>
            <OnlyInDesktop>
              <img
                src={sourceForAsset({
                  index,
                  numPhotos: mediaDisplayCount,
                  asset,
                  isDesktop: true,
                })}
                alt={getAltText({
                  type: asset.type,
                  options: { roomName: asset.description, companyName },
                })}
              />
            </OnlyInDesktop>
            <OnlyInTablet>
              <img
                src={sourceForAsset({
                  index,
                  numPhotos: mediaDisplayCount,
                  asset,
                  isDesktop: false,
                })}
                alt={getAltText({
                  type: asset.type,
                  options: { roomName: asset.description, companyName },
                })}
              />
            </OnlyInTablet>
          </button>
        ),
      )}
      <OnlyInDesktopTablet>
        {lightboxOpen && (
          <FullWidthLightBox
            address={address!}
            assets={photos}
            companyName={companyName}
            currentIndex={currentIndex}
            imagesZipUrl={imagesZipUrl}
            onClose={handleLightboxClose}
            onChange={setCurrentIndex}
            video={video}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tourEmbedUrl={tourEmbedUrl}
            visibleElementOnTours={visibleElementOnTours}
            setVisibleElementOnTours={setVisibleElementOnTours}
            sourcePage={sourcePage}
          />
        )}
      </OnlyInDesktopTablet>
      <button
        className={s.viewAllButton}
        onClick={() => {
          openLightbox(0);
          galleryInteraction({
            actionType: 'LIGHTBOX_OPENED',
            action: PARAMETERS.openLightbox,
            sourcePage,
            sourceContent,
          });
        }}
        type="button"
      >
        {t('gallery.viewAll')}
      </button>
    </div>
  ) : null;
};

export default MosaicGallery;
