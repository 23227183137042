type ReorderTourbookListingsAction = {
  type: 'REORDER_TOURBOOK_LISTINGS';
  payload: {
    tourbookId: string;
    sortableIds: Array<string>;
  };
};

export const reorderTourbookListings = (tourbookId: string) => {
  return (sortableIds: Array<string>): ReorderTourbookListingsAction => {
    return {
      type: 'REORDER_TOURBOOK_LISTINGS',
      payload: {
        tourbookId,
        sortableIds,
      },
    };
  };
};

export type Actions = ReorderTourbookListingsAction;
