import * as Yup from 'yup';

export const COUNTRY_CODES_THAT_DONT_REQUIRE_POSTAL_CODE = ['ae'];
export const COUNTRY_CODES_THAT_DONT_REQUIRE_ABBREVIATED_REGION = ['gb', 'ae'];
export const validationSchema = t => {
  const schemaFields = {
    countryCode: Yup.string().required(
      t('building.requiredFieldValidation', {
        field: t('building.countryCode'),
      }),
    ),
    displayAddress: Yup.string().required(t('building.displayAddressValidation')),
    latitude: Yup.string().required(
      t('building.requiredFieldValidation', {
        field: t('building.latitude'),
      }),
    ),
    longitude: Yup.string().required(
      t('building.requiredFieldValidation', {
        field: t('building.longitude'),
      }),
    ),
    locality: Yup.string().required(
      t('building.requiredFieldValidation', {
        field: t('building.city'),
      }),
    ),
    numberOfFloors: Yup.number().min(1, t('building.floorsMinimum')),
    postalCode: Yup.string().when('countryCode', {
      is: (val: string) => COUNTRY_CODES_THAT_DONT_REQUIRE_POSTAL_CODE.includes(val?.toLowerCase()),
      then: schema => schema.notRequired(),
      otherwise: schema =>
        schema.required(
          t('building.requiredFieldValidation', {
            field: t('building.postalCode'),
          }),
        ),
    }),
    primaryName: Yup.string().required(t('building.nameValidation')),
    rentableBuildingArea: Yup.number().min(1, t('building.rbaMinimum')),
    street: Yup.string().required(
      t('building.requiredFieldValidation', {
        field: t('building.street'),
      }),
    ),
    htmlDescription: Yup.string().test(
      'html',
      'Please enter valid html',
      value => value !== '<p></p>',
    ),

    abbreviatedRegion: Yup.string().when('countryCode', {
      is: (val: string) =>
        COUNTRY_CODES_THAT_DONT_REQUIRE_ABBREVIATED_REGION.includes(val?.toLowerCase()),
      then: schema => schema.notRequired(),
      otherwise: schema =>
        schema.required(
          t('building.requiredFieldValidation', {
            field: t('building.stateRegion'),
          }),
        ),
    }),
  };

  return Yup.object().shape(schemaFields);
};

export const amenitySetValidationSchema = t => {
  const schemaFields = {
    amenitySet: Yup.object().shape({
      entranceCount: Yup.number().min(0, t('building.amenities.minZero')),
      passengerElevatorCount: Yup.number().min(0, t('building.amenities.minZero')),
      serviceElevatorCount: Yup.number().min(0, t('building.amenities.minZero')),
    }),
  };

  return Yup.object().shape(schemaFields);
};
