import cn from 'classnames';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import api from '@shared/api';
import { IconButton } from '@components/shared';
import { useRef } from 'react';
import s from '../FullWidthLightbox.module.less';

type DownloadAllImagesType = {
  downloadUrl: string | null;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onClick: () => void;
};

const DownloadAllImages = ({
  downloadUrl,
  onMouseOver = () => null,
  onMouseLeave = () => null,
  onClick,
}: DownloadAllImagesType) => {
  const { t } = useTranslation('listing');
  const anchor = useRef<HTMLAnchorElement | null>(null);
  const handleClick = async event => {
    onClick();
    event.preventDefault();
    const response = await api.fetch(downloadUrl);
    const { url } = await response.json();

    anchor.current!.href = url;
    anchor.current!.click();
  };

  return downloadUrl ? (
    <Tooltip placement="top" trigger={['hover']} title={t('gallery.downloadAllImagesTooltip')}>
      <a
        className={cn([s.downloadButton, s.downloadAllImageButton])}
        ref={anchor}
        href={downloadUrl}
        aria-label="download all images"
      >
        <IconButton
          icon="download-multiple"
          onClick={handleClick}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
        />
      </a>
    </Tooltip>
  ) : null;
};

export default DownloadAllImages;
