import { LandlordBuilding } from '@root/types';
import { BuildingCard } from '@components/shared/BuildingCard';
import s from './Portfolio.module.less';

type Props = {
  buildings: LandlordBuilding[];
  onBuildingClick: (building: LandlordBuilding) => void;
};

const Buildings = ({ buildings, onBuildingClick }: Props) => (
  <div className={s.buildings}>
    {buildings.map(building => (
      <div key={building.id}>
        <BuildingCard
          building={building}
          onBuildingClick={() => onBuildingClick(building)}
          showLogo
          useTrackingParams
        />
      </div>
    ))}
  </div>
);

export default Buildings;
