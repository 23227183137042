import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { format, parse, startOfToday } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '@shared/TranslationHelpers/formatDate';
import useEnv from '@shared/useEnv';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import { RadioInput } from '@components/shared';
import { Locale, Possession } from '@root/types';
import { uniqueId } from 'lodash';
import actions from '@store/actions/listingSearchPage';
import { useListingSearchCriteria } from '@components/layouts/Truva/ListingSearch/utils';
import s from '../Filters.module.less';
import dateRegex from '../../utils/constants';

export const possessionOptions: Array<{
  label: string;
  translationKey: string;
  value: Possession;
}> = [
  {
    label: 'now',
    value: 'now',
    translationKey: 'filters:possession:now',
  },
  {
    label: 'Within 3 months',
    value: 'threeMonths',
    translationKey: 'filters:possession:threeMonths',
  },
  {
    label: 'Within 6 months',
    value: 'sixMonths',
    translationKey: 'filters:possession:sixMonths',
  },
  {
    label: 'Within 12 months',
    value: 'oneYear',
    translationKey: 'filters:possession:oneYear',
  },
];

const isPossessionCustomDate = possession => {
  const preDefinedRanges = possessionOptions.map(option => option.value);
  return !preDefinedRanges.includes(possession);
};

const PossessionFilterList = () => {
  const { t } = useTranslation('filters');
  const { locale } = useEnv();
  const dispatch = useDispatch();
  const componentUniqueId = useRef(uniqueId('PosessionFilterList'));
  const { possessionFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const criteria = useListingSearchCriteria();
  const selectedPossession = criteria.currentFilters.possession ?? null;
  const [currentCustomDate, setCurrentCustomDate] = useState(
    isPossessionCustomDate(selectedPossession) && selectedPossession?.match(dateRegex)
      ? new Date(parse(selectedPossession, 'dd/MM/yyyy', new Date()))
      : startOfToday(),
  );

  const formattedCurrentCustomDate = () => {
    let currentDate = currentCustomDate;
    if (currentDate === null) {
      currentDate = startOfToday();
      setCurrentCustomDate(currentDate);
    }
    return format(currentDate, 'dd/MM/yyyy');
  };

  const changePossession = (possession: Possession) => () => {
    const isRemoving = selectedPossession === possession;
    if (selectedPossession === possession) {
      criteria.remove('possession');
    } else {
      criteria.add('possession', possession);
    }

    criteria.pushToHistory();
    if (flags['search-analytics-refactor']) {
      possessionFilterInteraction({
        isRemoving,
        value: possession,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatch(actions.possessionFilterChanged(criteria.currentFilters.possession ?? null));
    }
  };

  return (
    <ul className={s.filterList}>
      {possessionOptions.map(possessionOption => (
        <li key={possessionOption.value}>
          <RadioInput
            name={`${componentUniqueId.current}-possession-date`}
            value={possessionOption.value}
            checked={selectedPossession === possessionOption.value}
            onChange={changePossession(possessionOption.value)}
            labelText={t(possessionOption.translationKey)}
            id={`possession-${possessionOption.value}`}
            clearable
          />
        </li>
      ))}
      <li className={s.customListItem} key="customDate">
        <RadioInput
          name={`${componentUniqueId.current}-possession-customDate`}
          value={formattedCurrentCustomDate()}
          onChange={changePossession(formattedCurrentCustomDate())}
          checked={selectedPossession === formattedCurrentCustomDate()}
          labelText={
            <DatePicker
              data-testid="datePickerInput"
              className={s.datePickerInput}
              selected={currentCustomDate}
              disabledKeyboardNavigation
              onChange={value => setCurrentCustomDate(value)}
              calendarClassName={s.calendarContainer}
              dateFormat={dateFormat(locale as Locale)}
              dayClassName={date => {
                return format(date, 'MM/dd/yyyy') === format(currentCustomDate, 'MM/dd/yyyy')
                  ? s.selectedDatePickerDay
                  : s.datePickerDay;
              }}
            />
          }
          id="possession-customDate"
          clearable
        />
      </li>
    </ul>
  );
};
export default PossessionFilterList;
