import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues, useFormikContext } from 'formik';
import useEnvVariable from '@root/shared/useEnv';
import s from '../common.module.less';
import MarketingMediaForm from '../MarketingMediaForm';
import MarketingMediaHeader from '../MarketingMediaHeader/MarketingMediaHeader';
import {
  uploadToCloudinary,
  handleResponse,
  deletePhoto,
  deleteMedia,
  updateOrder,
  updatePhoto,
} from '../helper';
import { BuildingMarketingMedia } from '../types';

const BuildingFeatures = ({ data }: { data: BuildingMarketingMedia[] }) => {
  const { t } = useTranslation('admin');
  const [collapseMedia, setCollapseMedia] = useState(true);
  const { cloudinaryUploadPreset, cloudinaryCloud } = useEnvVariable();
  const showCancelUploadMediaButton =
    !data[0].cloudinaryId && !data[0].description && !data[0].title && data.length === 1;
  const [draggedItem, setDraggedItem] = useState<BuildingMarketingMedia | undefined>(undefined);
  const [, setDragOverIndex] = useState<number | undefined>(undefined);

  const { setValues, values } = useFormikContext();

  const sortedBuildingFeatures: BuildingMarketingMedia[] = (
    values as FormikValues
  )?.buildingFeature.sort((a, b) => a.order - b.order);

  const currentMediaLength = data.length;

  const addNewForm = () => {
    const newMedia = {
      mediaType: 'building_feature',
      title: '',
      description: '',
      cloudinaryId: '',
      order: sortedBuildingFeatures[sortedBuildingFeatures.length - 1].order + 1,
    };

    const updatedFormikValues = {
      ...((values as FormikValues) || {}),
      buildingFeature: [...((values as FormikValues)?.buildingFeature || []), newMedia],
    };

    setValues(updatedFormikValues);
  };

  const disableButton = () => {
    return !(currentMediaLength === 12 || !data[data.length - 1].title);
  };

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, photoOrder) => {
    const { files } = event.target;
    if (!files) return;

    const cloudinaryResponse = await uploadToCloudinary(
      files[0],
      cloudinaryUploadPreset || '',
      cloudinaryCloud || '',
      'image',
    );

    handleResponse(
      cloudinaryResponse,
      t('marketingMedia.buildingFeature.createSuccessMessage'),
      t('marketingMedia.buildingFeature.createFailureMessage'),
    );

    if (!cloudinaryResponse.secure_url) return;

    const updatedFormikValues = await updatePhoto(
      values,
      'buildingFeature',
      photoOrder,
      'building_feature',
      cloudinaryResponse.secure_url,
    );

    setValues(updatedFormikValues);
  };

  const onDelete = async (photoOrder: number) => {
    const deleteMessage = t('marketingMedia.buildingFeature.deleteSuccessMessage');
    const updatedFormikValues = await deleteMedia(
      values,
      'buildingFeature',
      photoOrder,
      deleteMessage,
      'building_feature',
    );
    setValues(updatedFormikValues);
  };

  const onPhotoDelete = async (photoOrder: number) => {
    const updatedFormikValues = await deletePhoto(values, 'buildingFeature', photoOrder);

    setValues(updatedFormikValues);
  };

  const onDragStart = (item: BuildingMarketingMedia) => {
    setDraggedItem(item);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    setDragOverIndex(index);
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    if (!draggedItem) return;

    const updatedArray = await updateOrder(
      (values as FormikValues)?.buildingFeature || [],
      draggedItem,
      index,
    );

    setValues({
      ...((values as FormikValues) || {}),
      buildingFeature: updatedArray,
    });
    setDraggedItem(undefined);
    setDragOverIndex(undefined);
  };

  return (
    <section className={s.mainSection}>
      <MarketingMediaHeader
        collapseMedia={collapseMedia}
        mediaTitle={t('marketingMedia.buildingFeature.title')}
        mediaLimit={t('marketingMedia.buildingFeature.maxCount')}
        setCollapseMedia={setCollapseMedia}
      />
      {collapseMedia && (
        <MarketingMediaForm
          data={data}
          onUpload={handleFileUpload}
          onPhotoDelete={onPhotoDelete}
          showCancelUploadMediaButton={showCancelUploadMediaButton}
          onDelete={onDelete}
          addNewForm={addNewForm}
          disableButton={disableButton()}
          identifier="buildingFeature"
          onDragOver={onDragOver}
          onDrop={onDrop}
          onDragStart={onDragStart}
        />
      )}
    </section>
  );
};

export default BuildingFeatures;
