import { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useBreakpoint } from '@shared/useBreakpoints';
import { isInIframe } from '@root/shared/iframeUtils';
import FixedPageHeader from '@components/shared/FixedPageHeader';

type Args = {
  mobileCollapseScrollPosition?: number;
  desktopCollapseScrollPosition?: number;
};
const defaultCollapseScrollPositions = {
  mobileCollapseScrollPosition: -48,
  desktopCollapseScrollPosition: -50,
};

export default function useFixedHeader({
  mobileCollapseScrollPosition = defaultCollapseScrollPositions.mobileCollapseScrollPosition,
  desktopCollapseScrollPosition = defaultCollapseScrollPositions.desktopCollapseScrollPosition,
}: Args = defaultCollapseScrollPositions) {
  const inIframe = isInIframe();
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(inIframe);

  const { isMobile } = useBreakpoint();
  const scrollPositionToCollapseHeader = isMobile
    ? mobileCollapseScrollPosition
    : desktopCollapseScrollPosition;

  useScrollPosition(
    ({ currPos }) => {
      if (!inIframe) setIsHeaderCollapsed(currPos.y < scrollPositionToCollapseHeader);
    },
    [scrollPositionToCollapseHeader],
  );

  const fixedPageHeaderProps = {
    isCollapsed: isHeaderCollapsed,
  };

  return {
    FixedPageHeader,
    fixedPageHeaderProps,
  };
}
