import { getListingCount } from '@root/store/selectors';
import { StoreState } from '@root/types';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PageHead from '@components/shared/PageHead/PageHead';
import { useListingSearchCriteria } from './utils';
import { useFilterOptions } from './utils/FilterOptions/FilterOptionsProvider';

const getListingSearchTitleT = (submarket: string[]): string => {
  if (submarket && submarket.length === 1) {
    return 'meta.title.withOneSubmarket';
  }
  return 'meta.title.withoutOneSubmarket';
};

const getListingSearchDescriptionT = (submarket: string[]): string => {
  if (submarket && submarket.length === 1) {
    return 'meta.description.withOneSubmarket';
  }
  return 'meta.description.withoutOneSubmarket';
};

const submarketFromSlug = (state, submarketSlug: string) => {
  return state.submarkets[submarketSlug]?.name;
};

/* Gets an array of address pieces, removes all the empty values and duplicates (mostly to handle
   cases like London where we use abbreviated region and country code as both 'UK'). 
   Returns as an comma separated address string.
 */
const formatAddress = (address: string[]): string => {
  const addressSet = new Set<string>();
  address.forEach(addr => !!addr && addressSet.add(addr));

  return Array.from(addressSet.values()).join(', ');
};

const mapState = (state: StoreState) => ({
  listingsCount: getListingCount(state),
});

const connector = connect(mapState);
export type ReduxProps = ConnectedProps<typeof connector>;

export const ListingSearchHead = ({ listingsCount }: ReduxProps) => {
  const { t } = useTranslation('listingSearchHead');
  const criteria = useListingSearchCriteria();
  const [state] = useFilterOptions();

  const city = t(`common:marketInfo.${criteria.marketSlug}.city`, '');
  const abbreviatedRegion = t(`common:marketInfo.${criteria.marketSlug}.abbreviatedRegion`, '');
  const countryCode = t(`common:marketInfo.${criteria.marketSlug}.countryCode`, '');

  const submarketSlugs = criteria.submarketSlugs();
  const submarketName = submarketSlugs && submarketFromSlug(state, submarketSlugs[0]);

  const title = t(getListingSearchTitleT(submarketSlugs), {
    address: formatAddress([city, abbreviatedRegion]),
    submarket: submarketName,
  });

  const description = t(getListingSearchDescriptionT(submarketSlugs), {
    numberOfListings: listingsCount,
    address: formatAddress([city, abbreviatedRegion, countryCode]),
    submarket: submarketName,
  });

  return <PageHead title={title} description={description} />;
};

export default connector(ListingSearchHead);
