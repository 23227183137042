import * as React from 'react';
import cn from 'classnames';
import Divider from '@components/shared/Divider';

type Props = {
  className?: string;
  title: string;
  children: React.ReactNode;
  headerElement?: React.ReactNode;
  'data-testid'?: string;
  contentClassName?: string;
  titleClassName?: string;
  headerContainerClassName?: string;
  labelQaTestId?: string;
};

const Card = ({
  className,
  title,
  headerElement,
  children,
  contentClassName,
  titleClassName,
  headerContainerClassName,
  labelQaTestId,
  ...props
}: Props) => (
  <section
    data-testid={props['data-testid']}
    className={cn('shadow-[0_2px_8px_0_rgba(0,0,0,0.2)]', className)}
  >
    {headerElement ? (
      <div className={cn('flex items-center justify-between px-2 py-0', headerContainerClassName)}>
        <h3
          className={cn(
            'mb-0 px-3 py-2 text-text-primary font-subtitle tablet:text-lg mobile:text-lg',
            titleClassName,
          )}
          data-qa-testid={labelQaTestId}
        >
          {title}
        </h3>
        <div>{headerElement}</div>
      </div>
    ) : (
      <h3
        className={cn(
          'mb-0 px-3 py-2 text-text-primary font-subtitle tablet:text-lg mobile:text-lg',
          titleClassName,
        )}
        data-qa-testid={labelQaTestId}
      >
        {title}
      </h3>
    )}

    <Divider />
    <div className={cn('px-3 py-4', contentClassName)}>{children}</div>
  </section>
);

export default Card;
