import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { compact, isEqual } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import ChartLegend from './ChartLegend';
import { ListingEngagement } from './utils';
import { ListingEngagementMetadata } from './ListingDataList';
import PieChartEmptyState from './PieChartEmptyState.svg';

ChartJS.register(ArcElement, Tooltip);

type Props = {
  listingEngagement: ListingEngagement[];
  colorByListingId: { [listingId: string]: string };
  listingsEngagementTotal: number;
  relatedListingIds: string[];
};

const PieChart = ({
  listingEngagement,
  colorByListingId,
  listingsEngagementTotal,
  relatedListingIds,
}: Props) => {
  const { t } = useTranslation('tourbook');

  const chartRef = useRef(null);
  const [tooltip, setTooltip] = useState<{
    top: number;
    left: number;
    listingData: ListingEngagementMetadata;
  } | null>(null);

  const listingEngagementMetadata: ListingEngagementMetadata[] = useMemo(
    () =>
      listingEngagement
        .map(l => {
          const value = l.totalActivity + l.totalDownloads + l.totalMediaViews;
          return {
            id: l.id,
            value,
            color: colorByListingId[l.id],
            label: compact([
              l.address.buildingName,
              l.address.street,
              l.address.floorAndSuite,
            ]).join(' | '),
            displayValue: value ? `${((value * 100) / listingsEngagementTotal).toFixed(1)}%` : '-',
          };
        })
        .sort(
          (a, b) =>
            // Sort by percentage value then by alphabetically by label name.
            b.value - a.value || new Intl.Collator().compare(a.label, b.label),
        ),
    [colorByListingId, listingEngagement, listingsEngagementTotal],
  );

  return (
    <div data-testid="overallListingEngagementChart" className="flex h-full flex-col">
      <div className="border-b-[1px] border-solid border-black-010">
        <h2 className="px-2 py-1.5 font-subtitle-de-emphasis">
          {t('analytics.overallListingEngagement')}
        </h2>
      </div>
      {listingsEngagementTotal ? (
        <div className="grid h-full grid-cols-[minmax(0,1fr)_275px] gap-2 px-2 py-[12px] mobile:grid-cols-1">
          <div className="flex w-full justify-center desktop:items-center tablet:flex-col tablet:gap-1 mobile:flex-col mobile:gap-2">
            <div className="relative ml-[12px] w-full desktop:max-w-[300px]">
              <Pie
                height={250}
                ref={chartRef}
                data={{
                  datasets: [
                    {
                      data: listingEngagementMetadata,
                      backgroundColor: listingEngagementMetadata.map(l => l.color),
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  parsing: {
                    key: 'value',
                  },
                  elements: {
                    arc: {
                      borderWidth: 1,
                    },
                  },
                  plugins: {
                    legend: {
                      position: 'right',
                    },
                    tooltip: {
                      enabled: false,
                      external: context => {
                        const tooltipModel = context.tooltip;
                        if (!chartRef || !chartRef.current) return;

                        if (tooltipModel.opacity === 0) {
                          if (tooltip) setTooltip(null);
                          return;
                        }
                        const position = context.chart.canvas.getBoundingClientRect();
                        const newTooltipData = {
                          left: position.left + window.scrollX + tooltipModel.caretX + 6,
                          top: position.top + window.scrollY + tooltipModel.caretY - 32,
                          listingData: tooltipModel.dataPoints[0]
                            .raw as unknown as ListingEngagementMetadata,
                        };
                        if (!isEqual(tooltip, newTooltipData)) setTooltip(newTooltipData);
                      },
                    },
                  },
                }}
              />
            </div>
            <div className="flex flex-col px-2 tablet:ml-1 mobile:self-baseline mobile:pl-[25px]">
              <p className="font-body-medium-emphasis desktop:mb-1">
                {t('analytics.mostEngagedListing')}
              </p>
              <div className="ml-[-16px] flex gap-1 desktop:mb-1.5 mobile:mb-0.5 mobile:mt-1">
                <div
                  className="h-[10px] min-w-[10px] self-center rounded-[2px]"
                  style={{ backgroundColor: listingEngagementMetadata[0].color }}
                />
                <p className="font-body-small" data-testid="mostEngagedLabel">
                  {listingEngagementMetadata[0].label}
                </p>
              </div>
              <p
                className="font-headline-de-emphasis tablet:font-title-de-emphasis mobile:font-subtitle-de-emphasis"
                data-testid="mostEngagedPercent"
              >
                {listingEngagementMetadata[0].displayValue}
              </p>
            </div>
          </div>
          <div className="relative">
            <ChartLegend
              listingMetadata={listingEngagementMetadata}
              relatedListingIds={relatedListingIds}
            />
          </div>
          {tooltip && (
            <div
              className={cn(
                'pointer-events-none absolute mb-1 w-[172px] rounded-[3px] bg-white p-1 shadow-[0px_1px_8px_rgba(0,_0,_0,_0.25)] mobile:w-[115px]',
              )}
              style={{ top: tooltip.top, left: tooltip.left }}
            >
              <p className="font-body-small-emphasis">{tooltip.listingData.displayValue} </p>
              <p className="font-body-small">{tooltip.listingData.label} </p>
            </div>
          )}
        </div>
      ) : (
        <div className="flex h-full items-center justify-center gap-4 p-2 mobile:flex-col mobile:gap-2">
          <PieChartEmptyState className="h-[220px] w-[220px] tablet:h-[167px] tablet:w-[167px] mobile:h-[176px] mobile:w-[176px]" />
          <div className="mobile:text-center">
            <p className="mb-1 text-border-regular font-title-de-emphasis">
              {t('analytics.noDataYet')}
            </p>
            <p className="text-border-regular font-body-medium tablet:max-w-[180px]">
              {t('analytics.noDataYetMessage')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PieChart;
