import { TextInput } from '@components/shared/forms';
import { CloudinaryImageParams, AdminMicrosite } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ImageUploader from '../../ImageUploader';
import TextColorSelector from '../../TextColorSelector';
import { ContentBlockWrapper } from '..';
import { useMicrositeFormContext } from '../../utils';
import ColorPicker from '../../ColorPicker';

export default function Nav() {
  const { colorGroups, changeFontColorWithColorChange, sendPreviewTo } = useMicrositeFormContext();
  const { setFieldValue, values, setFieldTouched } = useFormikContext<Partial<AdminMicrosite>>();
  const flags = useFlags();
  const { t } = useTranslation('admin');

  return (
    <ContentBlockWrapper
      wrapInDraggable={false}
      contentBlockId="nav"
      title={t('microsite.sections.nav')}
      section="nav"
      onClick={() => sendPreviewTo({ page: 'home', element: 'header' })}
    >
      <ColorPicker
        colorGroups={colorGroups}
        name="headerBackgroundColor"
        labelText={t('microsite.backgroundColor')}
        onChange={changeFontColorWithColorChange('headerTextColor')}
        data-testid="headerBackgroundColorPicker"
      />
      {flags['market-office.text-color-selector'] && <TextColorSelector name="headerTextColor" />}
      <ColorPicker
        colorGroups={colorGroups}
        name="headerBorderColor"
        labelText={t('microsite.borderColor')}
        hasTransparentOption
      />
      <ImageUploader
        img={values.topNavLogo as CloudinaryImageParams | null}
        onChange={img => {
          setFieldValue('topNavLogo', img);
          setFieldTouched('topNavLogo', true);
        }}
        labelText={t('microsite.topNavLogo.title')}
        name="topNavLogo"
        alt={t('microsite.topNavLogo.title')}
      />
      <TextInput
        name="tenantPortalUrl"
        labelText={t('microsite.tenantPortalUrl.title')}
        containerClass="!mb-0"
      />
    </ContentBlockWrapper>
  );
}
