import routes from '@root/routes';
import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Address } from '@root/types';
import { useParams } from 'react-router-dom';
import Layout from '../../Layout';
import { alphabetizeAndGroupItems, Item } from '../utils';
import AlphabeticalList from '../AlphabeticalList';

const toItems = (
  preprocessedItems: Array<{ address: Address; value: string }>,
  t: TFunction,
): Item[] =>
  preprocessedItems.map(item => ({
    value: item.value,
    label: t('fullBuildingName', { address: item.address }),
  }));

type LocalityData = {
  buildings: Array<{ address: Address; value: string }>;
  locality: {
    name: string;
    slug: string;
    abbreviatedRegion: string;
    region: string;
  };
};

const Locality = () => {
  const { abbreviatedRegion, localitySlug } = useParams<{
    abbreviatedRegion: string;
    localitySlug: string;
  }>();
  const { t } = useTranslation('common');

  const { data: localityData } = useQuery(
    [routes.api.browseLocality({ localitySlug, abbreviatedRegion })],
    async () => get<LocalityData>(routes.api.browseLocality({ localitySlug, abbreviatedRegion })),
  );

  const itemsToRender = alphabetizeAndGroupItems(toItems(localityData?.buildings || [], t));

  const locality = localityData?.locality;

  return locality ? (
    <Layout>
      <AlphabeticalList
        breadcrumbs={[
          { content: t('home'), link: routes.root },
          { content: t('country.unitedStates'), link: routes.browseRegionList },
          {
            content: t('browse.cities', { state: locality.region }),
            link: routes.browseRegion(locality.abbreviatedRegion.toLocaleLowerCase()),
            // specifying key because of New York, New York and other
            // times a locality and region have the same name
            key: `region-${locality.region}`,
          },
          {
            content: t('browse.buildings', { city: locality.name }),
            key: `locality-${locality.name}`,
          },
        ]}
        title={t('browse.buildings', { city: locality.name })}
        items={itemsToRender}
      />
    </Layout>
  ) : (
    <Layout showFooter={false} />
  );
};

export default Locality;
