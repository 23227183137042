import cn from 'classnames';
import Slider from 'react-slick';
import { CurrentUser, SearchListing } from '@root/types';
import IconButton from '@components/shared/IconButton/IconButton';
import mediaConstants from '@shared/mediaConstants';
import lodashParseInt from 'lodash/parseInt';
import useFavoritedListingsQuery from '@root/shared/useFavoritedListingsQuery';
import { ListingCard } from '../../ListingSearch/Cards/ListingCard';
import s from './ListingCardCarousel.module.less';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const mediumBreakpoint = lodashParseInt(mediaConstants['screen-md']);
const smallBreakpoint = lodashParseInt(mediaConstants['screen-sm']);

// onClick must be optional because react slick clones the component and handles the prop passing
const NextArrow = ({ onClick }: { onClick?: () => void }) => (
  <IconButton
    className={cn(s.changeSlideButton, s.nextButton)}
    icon="chevron-right-slate"
    onClick={onClick}
    title="next"
  />
);

// onClick must be optional because react slick clones the component and handles the prop passing
const PreviousArrow = ({ onClick }: { onClick?: () => void }) => (
  <IconButton
    className={cn(s.changeSlideButton, s.prevButton)}
    icon="chevron-left-slate"
    onClick={onClick}
    title="previous"
  />
);

type PassedProps = {
  listings: SearchListing[];
  loggedInUser: CurrentUser | null;
  onClick?: () => void;
  sliderAfterChange?: () => void;
};

const RawListingCardCarousel = ({
  listings,
  loggedInUser,
  onClick,
  sliderAfterChange,
}: PassedProps) => {
  const ids: string[] = listings.map(listing => listing.id);
  const listingsById = {};
  listings.forEach(listing => {
    listingsById[listing.id] = listing;
  });

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    afterChange: sliderAfterChange,
    responsive: [
      {
        breakpoint: mediumBreakpoint,
        settings: {
          dots: true,
        },
      },
      {
        breakpoint: smallBreakpoint,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const { isListingSaved } = useFavoritedListingsQuery();

  return (
    <ul className={s.carouselContainer}>
      <Slider {...settings}>
        {ids.map(listingId => {
          const listing = listingsById[listingId];

          return (
            <li className={s.listingCardWrapper} key={listingId}>
              <ListingCard
                listing={listing}
                loggedInUser={loggedInUser}
                onClick={onClick}
                isSaved={isListingSaved(listingId)}
                noQuickInquiry
              />
            </li>
          );
        })}
      </Slider>
    </ul>
  );
};
export default RawListingCardCarousel;
