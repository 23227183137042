import { isInIframe } from '@root/shared/iframeUtils';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import s from '../FullWidthLightbox.module.less';

type SlideCountProps = {
  currentIndex: number;
  totalCount: number;
};

// the current index we get is an array index, so we need to add 1 to make it human friendly.
const SlideCount = ({ currentIndex, totalCount }: SlideCountProps) => {
  const { t } = useTranslation('lightbox');
  return (
    <div className={cn(s.slideCount, isInIframe() && 'mt-[11px]')}>
      {t('displayCount', { current: currentIndex + 1, total: totalCount })}
    </div>
  );
};

export default SlideCount;
