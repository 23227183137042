import { CustomIcon, Button } from '@components/shared';
import { useTranslation } from 'react-i18next';
import s from './TourbookOverlay.module.less';

type Props = {
  close: () => void;
  goToCreateCard: () => void;
};

export default function InfoCard({ close, goToCreateCard }: Props) {
  const { t } = useTranslation('tourbook');

  return (
    <>
      <div data-testid="addTourbookCard" className={s.infoCardHeader}>
        <button
          data-testid="goToInfoButton"
          type="button"
          onClick={goToCreateCard}
          className={s.headerButton}
        >
          <CustomIcon className={s.infoButton} type="chevron-left" />
        </button>
        <h3>{t('manageDialogs.whatAreTourbooks')}</h3>
        <button onClick={close} type="button">
          <CustomIcon type="close" />
        </button>
      </div>
      <div className="p-2">
        <p>{t('manageDialogs.tourbookExplanation')}</p>
        <Button
          size="small"
          type="secondary"
          onClick={goToCreateCard}
          className="!absolute bottom-2 right-2"
        >
          {t('manageDialogs.back')}
        </Button>
      </div>
    </>
  );
}
