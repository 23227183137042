import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import addKeyTo from '@shared/addKeyTo';
import {
  AdminBuildingVideo,
  AdminVideo as AdminVideoType,
  AdminListingVideo,
  VirtualTourSpotlights,
} from '@root/types';
import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import CustomIcon from '@components/shared/CustomIcon';
import AdminTable from '@components/shared/AdminTable';
import VirtualTourSpotlightPicker from '@components/shared/Admin/VirtualTourSpotlightPicker';
import VideoThumbnailSetter from '@components/layouts/Admin/Landlords/Form/VideoThumbnailSetter';
import MultiVideoUploader, {
  DefaultVideoUploadType,
} from '@components/shared/Admin/MultiVideoUploader';
import CollapsibleVideoSection from './CollapsibleVideoSection';
import css from './VideoSection.module.less';

type AdminVideo = AdminListingVideo | AdminBuildingVideo | AdminVideoType;

export type VideoSectionProps = {
  onSuccess: () => void;
  spotlightChecked: boolean;
  video: AdminVideo | null;
  archivedVideos: AdminVideo[];
  videoTableTitle: string;
  videoHelpText: string;
  videoSelectText: string;
  videoUploadRequest: (file: DefaultVideoUploadType) => PromiseLike<{ ok: boolean }>;
  onVideoDelete: (videoId?: string) => Promise<void>;
  onVideoSelect: (videoId: string) => Promise<void>;
  onVideoHide: (videoId: string) => Promise<void>;
  onUpdateVirtualTourSpotlight?:
    | ((newValue: Exclude<VirtualTourSpotlights, 'unset'>) => Promise<boolean>)
    | null;
  virtualTourSpotlight?: VirtualTourSpotlights | null;
  totalVariableColumnsWidth?: string;
  onPosterUpdate: (posterStartOffset: number | null) => PromiseLike<{ ok: boolean }>;
};

const VideoSection = ({
  onSuccess,
  spotlightChecked,
  video,
  archivedVideos,
  videoTableTitle,
  videoHelpText,
  videoSelectText,
  videoUploadRequest,
  onUpdateVirtualTourSpotlight = null,
  virtualTourSpotlight = null,
  totalVariableColumnsWidth = '52vw',
  onVideoDelete,
  onVideoSelect,
  onVideoHide,
  onPosterUpdate,
}: VideoSectionProps) => {
  const { t } = useTranslation('admin');

  const tableData = () => (video ? addKeyTo([video]) : []);

  const renderVideoFile = (asset: any) => {
    const { path, filename, processedAt, description } = asset;
    return (
      <>
        <div className={css.filename}>
          <a href={path} target="_blank noopener noreferrer">
            {filename || description || t('media.defaultLinkToVideo')}
          </a>
        </div>
        {processedAt && (
          <div className={css.processedAt}>
            {t('media.processedAt', {
              date: format(parseISO(processedAt), 'PP'),
              time: format(parseISO(processedAt), 'p'),
            })}
          </div>
        )}
      </>
    );
  };

  const downloadMenuItem = () => {
    return (
      <OverflowMenuItem className={css.overflowMenuItem} onClick={() => {}}>
        <a className={css.downloadLink} href={video?.downloadPath || ''} download>
          <CustomIcon type="download"></CustomIcon>
          {t('media.downloadVideo')}
        </a>
      </OverflowMenuItem>
    );
  };

  const deleteMenuItem = () => {
    return (
      <OverflowMenuItem className={css.overflowMenuItem} onClick={() => onVideoDelete(video?.id)}>
        <>
          <CustomIcon type="trash"></CustomIcon>
          {t('media.deleteVideo')}
        </>
      </OverflowMenuItem>
    );
  };

  const hideMenuItem = (row: any) => {
    return (
      <OverflowMenuItem className={css.overflowMenuItem} onClick={() => onVideoHide(row.id)}>
        <>
          <CustomIcon type="eye"></CustomIcon>
          {t('media.hideVideo')}
        </>
      </OverflowMenuItem>
    );
  };

  const columns = () => [
    {
      title: t('media.videoTableHeader.filename'),
      width: `calc(${totalVariableColumnsWidth} * (3/10))`,
      render: asset => {
        return renderVideoFile(asset);
      },
    },
    {
      title: t('media.videoTableHeader.thumbnailTimestamp'),
      width: `calc(${totalVariableColumnsWidth} * (4/10))`,
      render: ({ posterStartOffset }: AdminVideo) => {
        return (
          <VideoThumbnailSetter
            initialOffsetInSeconds={posterStartOffset}
            onSaveSuccess={onSuccess}
            onPosterUpdate={onPosterUpdate}
          />
        );
      },
    },
    {
      title: t('media.videoTableHeader.thumbnailPreview'),
      width: `calc(${totalVariableColumnsWidth} * (3/10))`,
      render: ({ thumbnailPosterPath }: AdminVideo) => {
        return (
          <figure className={css.imageContainer}>
            <img alt="videoThumbnail" src={thumbnailPosterPath} />
          </figure>
        );
      },
    },
    {
      title: '',
      width: '20%',
      render: () => (
        <VirtualTourSpotlightPicker
          show={spotlightChecked}
          currentValue={virtualTourSpotlight}
          onChange={onUpdateVirtualTourSpotlight}
          pickerFor="video"
        />
      ),
    },
    {
      title: '',
      width: '18%',
      render: () => <span data-testid="video-upload-help-text">{videoHelpText}</span>,
    },
    {
      title: '',
      width: '10%',
      render: (row: any) => (
        <OverflowMenu overflowClassName={css.overflowMenu}>
          {downloadMenuItem()}
          {hideMenuItem(row)}
          {deleteMenuItem()}
        </OverflowMenu>
      ),
    },
  ];

  return (
    <div data-testid="v2-video-table" className={css.videoTableStyle}>
      <div className={css.videoTableHeader}>
        <div className="inline font-title">{videoTableTitle}</div>
        <div className={css.uploadVideoButton}>
          <MultiVideoUploader
            marketplaceRequest={videoUploadRequest}
            onSuccess={onSuccess}
            buttonText={t('media.uploadVideo')}
            buttonClass={css.uploadButtonClass}
            buttonIcon="upload"
            buttonSize="small"
          />
        </div>
      </div>
      <AdminTable
        className={css.adminTableStyles}
        rowClassName={css.row}
        columns={columns()}
        dataSource={tableData()}
      />
      <CollapsibleVideoSection
        archivedVideos={archivedVideos}
        renderVideoFile={renderVideoFile}
        videoTableTitle={t('media.hiddenVideosTitle')}
        videoSelectText={videoSelectText}
        onVideoDelete={onVideoDelete}
        onVideoSelect={onVideoSelect}
      />
    </div>
  );
};

export default VideoSection;
