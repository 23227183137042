import { connect, ConnectedProps } from 'react-redux';
import { notification } from 'antd';
import { Tooltip } from '@viewthespace/components';
import routes from '@root/routes';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useTranslation, Trans } from 'react-i18next';
import useAnalytics from '@root/shared/useAnalytics';
import { Button, CustomIcon, AddToSavedItemsButton } from '@components/shared';
import { get, destroy, create } from '@shared/typedApi';
import actions from '@store/actions/listingPage';
import searchPageActions from '@store/actions/listingSearchPage';
import useToggle from '@shared/useToggle';
import useCurrentUser from '@shared/useCurrentUser';
import { Listing } from '@root/types';
import ShareModal from '../ShareModal';
import TourbookButtonAndOverlay from './Tourbooks';
import styles from './ActionButtons.module.less';

type Props = {
  listing: Listing;
};

const mapDispatchToProps = {
  openShareModalAction: () => actions.openShareModal(),
  savedListingLinkClicked: () => searchPageActions.savedListingNotificationLinkClicked(),
};

const connector = connect(null, mapDispatchToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export function RawActionButtons({
  listing,
  openShareModalAction,
  savedListingLinkClicked,
}: Props & ReduxProps) {
  const { t } = useTranslation('listing');
  const { currentUser } = useCurrentUser();
  const hasCurrentUser = !!currentUser;

  const {
    value: shareModalIsOpen,
    setTrue: openShareModal,
    setFalse: closeShareModal,
  } = useToggle();
  const { saveInteraction, PARAMETERS } = useAnalytics();

  const { data, isLoading, refetch } = useQuery([routes.api.listingFavorited(listing.id)], () =>
    get(routes.api.listingFavorited(listing.id)),
  );
  const isSaved = !isLoading && data.favorited;

  const unsaveListingMutation = useMutation(
    () => destroy(routes.api.listingFavorites(listing.id), {}),
    {
      onSuccess: () => {
        saveInteraction({
          sourcePage: PARAMETERS.listingPage,
          action: PARAMETERS.unsaveItem,
        });
        refetch();
      },
    },
  );
  const saveListingMutation = useMutation(
    () => create(routes.api.listingFavorites(listing.id), {}),
    {
      onSuccess: () => {
        refetch();
        saveInteraction({
          sourcePage: PARAMETERS.listingPage,
          action: PARAMETERS.saveItem,
        });
        notification.open({
          message: t('listingSavedToFavorites'),
          className: styles.notification,
          /* eslint-disable react/jsx-no-literals */
          description: (
            <Trans i18nKey="viewInFavorites" ns="listing">
              View it in your
              <a href={routes.savedListings} onClick={savedListingLinkClicked}>
                saved listings
              </a>
              .
            </Trans>
          ),
          /* eslint-enable react/jsx-no-literals */
          placement: 'bottomLeft',
          duration: 3.5,
          icon: <CustomIcon type="heart" className={styles.heartIcon} />,
          closeIcon: <CustomIcon type="close" className={styles.closeIcon} />,
        });
      },
    },
  );
  const toggleListingSave = (isCurrentlySaved: boolean) => {
    if (isCurrentlySaved) {
      unsaveListingMutation.mutate();
    } else {
      saveListingMutation.mutate();
    }
  };

  if (!hasCurrentUser) return null;

  return (
    <div className={styles.container}>
      <TourbookButtonAndOverlay hasCurrentUser={hasCurrentUser} listing={listing} />
      <Tooltip
        className="tablet:hidden mobile:hidden"
        placement="bottom"
        content={t('share.ctaText')}
        closeDelay={0}
        trigger={
          <div className="ml-1">
            <Button
              className={styles.shareButton}
              type="tertiary"
              size="small"
              onClick={() => {
                openShareModalAction();
                openShareModal();
              }}
              focus={shareModalIsOpen}
              icon="share"
              data-testid="shareListingButton"
              labelQaTestId="share-listing"
            />
          </div>
        }
      />
      <Tooltip
        className="tablet:hidden mobile:hidden"
        placement="bottom"
        content={t('saveTooltip')}
        closeDelay={0}
        trigger={
          <div className="ml-1">
            <AddToSavedItemsButton
              className={styles.heartButton}
              textClassName="hidden"
              data-testid="saveListingButton"
              isSaved={isSaved}
              onClick={() => toggleListingSave(isSaved)}
            />
          </div>
        }
      />
      <ShareModal
        isOpen={shareModalIsOpen}
        close={closeShareModal}
        listingId={listing.id}
        buildingName={listing.buildingName}
        currentUser={currentUser!}
        neighborhood={listing.building.neighborhood as string}
      />
    </div>
  );
}

export default connector(RawActionButtons);
