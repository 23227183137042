import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from '@shared/useToggle';
import {
  TextInput,
  Select,
  FieldWrapper,
  ContextualErrors,
  ZipcodeInput,
  CountrySelect,
} from '@components/shared/forms';
import { ConfirmationModal } from '@components/shared';
import cn from 'classnames';
import { useFormikContext, Field } from 'formik';
import CustomIcon from '@components/shared/CustomIcon';
import { CountryCode } from '@root/types';
import styles from './TourbookExternalListing.module.less';
import Map from './Map';
import usStates from './usStates';
import { FormValues } from '.';
import countries from './countries';

type RefFn = (node: Element | undefined | null) => void;
type Props = {
  listingPersisted?: boolean;
  isCountryFieldEnabled?: boolean;
  containerRef?: RefFn | null;
  scrollToBottom?: () => void;
  setListingCountry?: (countryCode: CountryCode) => void;
};

export const Location = ({
  listingPersisted = false,
  isCountryFieldEnabled = true,
  containerRef = null,
  scrollToBottom = () => {},
  setListingCountry = () => {},
}: Props) => {
  const {
    setFieldValue,
    values: { state, buildingName, countryCode },
    errors,
    touched,
  } = useFormikContext<FormValues>();

  const { t } = useTranslation('tourbook');
  const buildingNameRef = useRef<HTMLInputElement | null>(null);

  const {
    value: isBuildingNameVisible,
    setTrue: showBuildingName,
    setFalse: hideBuildingName,
  } = useToggle(buildingName !== null);

  const onShowBuildingName = () => {
    setFieldValue('buildingName', '');
    showBuildingName();
    // focus on a timeout because the state update has to happen first
    setTimeout(() => {
      buildingNameRef.current?.focus();
    }, 23);
  };

  const onHideBuildingName = () => {
    setFieldValue('buildingName', null);
    hideBuildingName();
  };

  const [showModal, setShowModal] = useState(false);
  const [newListingCountry, setNewListingCountry] = useState(countryCode);

  // long/lat are not inputs but together they make errors on the location field
  // the errors field works fine, but submitting the form untouches the location
  // field, and sometimes there are errors there, so we need this
  const showLocationErrorsRegardless =
    (touched.longitude || touched.latitude) && !touched.location && errors.location;

  const showState = countryCode !== 'GB';

  return (
    <section ref={containerRef} id="location-fields">
      <div className={styles.locationLabel}>
        <span>{t('externalListing.location')}</span>
      </div>
      {isBuildingNameVisible ? (
        <div className={styles.hiddenInputSection}>
          <TextInput
            required
            name="buildingName"
            labelText={t('externalListing.buildingName')}
            placeholder={t('externalListing.buildingName')}
            autoComplete={false}
            maxLength={35}
            containerClass={styles.hiddenInput}
            ref={buildingNameRef}
          />
          <div role="button" onClick={onHideBuildingName} className={styles.hideBuildingLabel}>
            <CustomIcon type="close-circle" />
          </div>
        </div>
      ) : (
        <div role="button" onClick={onShowBuildingName} className={styles.addBuildingLabel}>
          {t('externalListing.addBuildingName')}
        </div>
      )}
      <TextInput
        required
        name="addressLine1"
        labelText={t('externalListing.addressLabel')}
        labelClass="!text-black-055"
        placeholder={t('externalListing.addressPlaceholder')}
        autoComplete={false}
        maxLength={35}
      />
      <TextInput
        required
        name="addressLine2"
        placeholder={
          countryCode === 'GB'
            ? t('externalListing.address2PlaceholderGB')
            : t('externalListing.address2Placeholder')
        }
        labelText={
          countryCode === 'GB'
            ? t('externalListing.address2LabelGB')
            : t('externalListing.address2Label')
        }
        labelClass="!text-black-055"
        autoComplete={false}
        maxLength={35}
      />
      <CountrySelect
        isDisabled={!isCountryFieldEnabled}
        isClearable={false}
        required
        labelText={t('externalListing.countryLabel')}
        labelClass="!text-black-055"
        onSelect={listingCountry => {
          if (
            listingCountry !== null &&
            typeof listingCountry !== 'number' &&
            listingCountry !== countryCode
          ) {
            setNewListingCountry(listingCountry as CountryCode);
            setShowModal(true);
          }
        }}
        name="countryCode"
        options={countries}
        value={countryCode}
      />
      <TextInput
        required
        name="city"
        placeholder={t('externalListing.cityLabel')}
        labelText={t('externalListing.cityLabel')}
        labelClass="!text-black-055"
        autoComplete={false}
        maxLength={20}
      />
      {showState && (
        <Select
          required
          isClearable
          labelText={t('externalListing.stateLabel')}
          labelClass="!text-black-055"
          name="state"
          options={usStates}
          value={state}
        />
      )}
      <ZipcodeInput
        required
        name="zipCode"
        countryCode={countryCode as CountryCode}
        placeholder={
          countryCode === 'GB'
            ? t('externalListing.postcodePlaceholder')
            : t('externalListing.zipPlaceholder')
        }
        labelText={
          countryCode === 'GB' ? t('externalListing.postcodeLabel') : t('externalListing.zipLabel')
        }
        labelClass="!text-black-055"
        className={styles.zipInput}
        autoComplete={false}
      />
      <FieldWrapper name="location">
        <Field name="location">
          {({ meta }) => (
            <>
              <div
                className={cn({
                  [styles.mapSection]: true,
                  [styles.mapError]: (meta.error && meta.touched) || showLocationErrorsRegardless,
                })}
              >
                <label className={styles.mapLabel}>{t('externalListing.mapLabel')}</label>
                <Map listingPersisted={listingPersisted} onLocationError={scrollToBottom} />
                <span className={styles.mapNote}>{t('externalListing.mapNote')}</span>
              </div>
              {showLocationErrorsRegardless ? (
                <div>
                  <ContextualErrors messages={errors.location} />
                </div>
              ) : null}
            </>
          )}
        </Field>
      </FieldWrapper>
      <TextInput
        name="submarket"
        placeholder={t('externalListing.submarketLabel')}
        labelText={t('externalListing.submarketLabel')}
        labelClass="!text-black-055"
        autoComplete={false}
      />
      <ConfirmationModal
        title={t('externalListing.localeChange.heading')}
        confirmationText={t('externalListing.localeChange.body')}
        cancelButtonText={t('externalListing.localeChange.cancel')}
        confirmButtonText={t('externalListing.localeChange.confirm')}
        onConfirm={() => {
          setFieldValue('countryCode', newListingCountry);
          setListingCountry(newListingCountry);
          setShowModal(false);
        }}
        onClose={() => {
          setShowModal(false);
        }}
        isOpen={showModal}
      />
    </section>
  );
};

export default Location;
