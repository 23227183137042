import useAnalytics from '@root/shared/useAnalytics';
import useMarket from '@root/shared/useMarket';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import searchPageActions from '@store/actions/listingSearchPage';
import ListingSearchCriteria from './Criteria/ListingSearchCriteria';
import useFilterInteraction from './useFilterInteraction';

const defaultCountryCode = 'US';

const useFilterAnalytics = () => {
  const {
    checkboxFilterInteraction,
    sizeFilterInteraction,
    priceFilterInteraction,
    dropdownFilterInteraction,
    possessionFilterInteraction,
    ceilingHeightFilterInteraction,
  } = useFilterInteraction();
  const { PARAMETERS, mapInteraction } = useAnalytics();
  const dispatch = useDispatch();
  const flags = useFlags();
  const { currentMarket } = useMarket();

  const fireAnalyticEventsForCurrentFilters = (criteria: ListingSearchCriteria) => {
    if (!flags['search-analytics-refactor']) return;

    const currentAnalyticFilters = criteria.toAnalyticsProperties();

    if (criteria.currentFilters.exclusive) {
      checkboxFilterInteraction({
        nowChecked: true,
        filterType: 'firstToMarket',
        filterValue: true,
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.minSize !== undefined) {
      sizeFilterInteraction({
        value: Number(criteria.currentFilters.minSize),
        filterType: 'minSize',
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.maxSize !== undefined) {
      sizeFilterInteraction({
        value: Number(criteria.currentFilters.maxSize),
        filterType: 'maxSize',
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.minPrice !== undefined) {
      priceFilterInteraction({
        value: Number(criteria.currentFilters.minPrice),
        filterType: 'minPrice',
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.maxPrice !== undefined) {
      priceFilterInteraction({
        value: Number(criteria.currentFilters.maxPrice),
        filterType: 'maxPrice',
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.excludeNegotiable) {
      priceFilterInteraction({
        value: criteria.currentFilters.excludeNegotiable,
        filterType: 'excludeNegotiable',
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.submarkets !== undefined) {
      criteria.currentFilters.submarkets.forEach(submarketSlug => {
        dropdownFilterInteraction({
          filterType: 'submarkets',
          isAdding: true,
          value: submarketSlug,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (criteria.currentFilters.buildingIds !== undefined) {
      criteria.currentFilters.buildingIds.forEach(buildingId => {
        dropdownFilterInteraction({
          filterType: 'buildings',
          isAdding: true,
          value: buildingId,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (criteria.currentFilters.landlords !== undefined) {
      criteria.currentFilters.landlords.forEach(landlordId => {
        dropdownFilterInteraction({
          filterType: 'landlords',
          isAdding: true,
          value: landlordId,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (criteria.currentFilters.leaseTypes !== undefined) {
      criteria.currentFilters.leaseTypes.forEach(leaseType => {
        checkboxFilterInteraction({
          nowChecked: true,
          filterType: 'leaseTypes',
          filterValue: leaseType,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (criteria.currentFilters.terms !== undefined) {
      criteria.currentFilters.terms.forEach(term => {
        checkboxFilterInteraction({
          nowChecked: true,
          filterType: 'terms',
          filterValue: term,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (criteria.currentFilters.possession !== undefined) {
      possessionFilterInteraction({
        isRemoving: false,
        value: criteria.currentFilters.possession,
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.spaceCondition !== undefined) {
      criteria.currentFilters.spaceCondition.forEach(spaceCondition => {
        checkboxFilterInteraction({
          nowChecked: true,
          filterType: 'spaceCondition',
          filterValue: spaceCondition,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (
      criteria.currentFilters.ceilingHeightType !== undefined &&
      criteria.currentFilters.ceilingHeightMagnitude !== undefined
    ) {
      const countryCode = currentMarket?.countryCode || defaultCountryCode;
      const useImperialMeasurements = countryCode === 'US';

      ceilingHeightFilterInteraction({
        filterType: criteria.currentFilters.ceilingHeightType,
        units: useImperialMeasurements ? 'imperial' : 'metric',
        value: criteria.currentFilters.ceilingHeightMagnitude,
        currentFilters: currentAnalyticFilters,
      });
    }

    if (criteria.currentFilters.amenities !== undefined) {
      criteria.currentFilters.amenities.forEach(amenity => {
        dropdownFilterInteraction({
          filterType: 'amenities',
          isAdding: true,
          value: amenity,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (criteria.currentFilters.keywords !== undefined) {
      criteria.currentFilters.keywords.forEach(keyword => {
        dropdownFilterInteraction({
          filterType: 'keywords',
          isAdding: true,
          value: keyword,
          currentFilters: currentAnalyticFilters,
        });
      });
    }

    if (criteria.currentFilters.polygons !== undefined) {
      mapInteraction({
        actionType: 'MAP_BOUNDARY_DRAWING_FINISHED',
        action: PARAMETERS.finishPolygon,
        sourcePage: PARAMETERS.searchResultsPage,
        sourceContent: PARAMETERS.map,
      });
    }

    if (criteria.currentFilters.map !== undefined) {
      dispatch(searchPageActions.mapCustomAreaSearched());
    }
  };

  return { fireAnalyticEventsForCurrentFilters };
};

export default useFilterAnalytics;
