import { useState } from 'react';
import { Form } from '@ant-design/compatible';

import { AutoComplete, Modal, Row, notification, Input } from 'antd';
import Button from '@components/shared/V2Button';
import api from '@shared/api';
import routes from '@root/routes';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import { useHistory } from 'react-router-dom';

const {
  api: {
    admin: { buildingSearch: buildingSearchQueryRoute, buildingLink: buildingLinkRoute },
  },
  admin: { building: buildingRoute },
} = routes;

type Props = {
  visible?: boolean;
  onCancel: () => void;
  propertyId: string;
};

function RawLinkBuildingModal({ visible = false, onCancel, propertyId }: Props) {
  const history = useHistory();
  const { t } = useTranslation('admin');
  const [buildings, setBuildings] = useState([]);
  const [selectedBuildingId, setSelectedBuildingId] = useState<string | undefined>();

  async function handleSearch(query = '') {
    if (query.length === 0) return;

    const res = await api.fetch(buildingSearchQueryRoute({ query }));
    const data = await res.json();

    setBuildings(data.buildings);
  }

  async function handleLink() {
    try {
      await api.post(buildingLinkRoute, { propertyId, buildingId: selectedBuildingId });
      history.push(buildingRoute(selectedBuildingId!));
    } catch (e) {
      // @ts-expect-error
      notification.error({ message: e.message });
    }
  }

  /* eslint-disable react/jsx-no-bind */
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Link to Existing Building"
      footer={
        <>
          <Button key="cancel" type="secondary" onClick={onCancel}>
            {t('common.cancelButton')}
          </Button>
          <Button key="link" onClick={handleLink} disabled={isEmpty(selectedBuildingId)}>
            {t('building.linkButton')}
          </Button>
        </>
      }
    >
      <Form layout="inline">
        <Row>
          <Form.Item label={t('building.searchLabel')}>
            <AutoComplete
              placeholder={t('building.searchPlaceholder')}
              style={{ width: 200 }}
              onSearch={handleSearch}
              autoFocus
              options={buildings.map(({ address, id }) => ({
                value: id,
                label: t('common:fullBuildingName', { address }),
              }))}
              onSelect={buildingId => setSelectedBuildingId(buildingId)}
            >
              <Input data-testid="building_search_input" name="building_search_input" />
            </AutoComplete>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
}
/* eslint-enable react/jsx-no-bind */

export default RawLinkBuildingModal;
