import api from '@shared/api';
import routes from '@root/routes';
import { ConvertableMoneyPerAreaPerPeriod, ErrorResponse, TourbookListing } from '@root/types';

type ReceiveUpdatedTourbookListingAction = {
  type: 'RECEIVE_UPDATED_TOURBOOK_LISTING';
  payload: {
    tourbookId: string;
    tourbookListing: TourbookListing;
  };
};

export const receiveUpdatedTourbookListing = (
  tourbookId: string,
  tourbookListing: TourbookListing,
): ReceiveUpdatedTourbookListingAction => ({
  type: 'RECEIVE_UPDATED_TOURBOOK_LISTING',
  payload: {
    tourbookId,
    tourbookListing,
  },
});

export type TourbookUpdatedAttributes = {
  askingRentUnits?: string | undefined;
  minAskingRent?: ConvertableMoneyPerAreaPerPeriod | null;
  maxAskingRent?: ConvertableMoneyPerAreaPerPeriod | null;
  description?: string;
};

type UpdateTourbookListingParams = {
  tourbookId: string;
  tourbookListingId: string;
  updatedAttributes: TourbookUpdatedAttributes;
  onSuccess?: () => void;
  onError?: (e: ErrorResponse) => void;
};

export const updateTourbookListing =
  ({
    tourbookId,
    tourbookListingId,
    updatedAttributes,
    onSuccess = () => {},
    onError = () => {},
  }: UpdateTourbookListingParams) =>
  async dispatch => {
    await api
      .put(routes.api.tourbookTourbookListing({ tourbookId, tourbookListingId }), updatedAttributes)
      .then(async response => {
        if (response.ok) {
          const tourbookListing: TourbookListing = await response.json();
          dispatch(receiveUpdatedTourbookListing(tourbookId, tourbookListing));
          onSuccess();
        } else {
          const errorResponse: ErrorResponse = await response.json();
          onError(errorResponse);
        }
      });
  };

export type Actions = ReceiveUpdatedTourbookListingAction;
