import * as React from 'react';
import { CountryCode, MultipathImage } from '@root/types';
import { object, SchemaOf, string, number, array, boolean, mixed } from 'yup';
import FormCancellationPrompt from '@components/shared/FormCancellationPrompt';
import routes from '@root/routes';
import _ from 'lodash';
import { FormGb, FormUs, ImagesContextProvider } from '.';

type Props = {
  tourbookId: string;
  children: React.ReactNode;
  initialValues?: Partial<FormValues>;
  isPromptEnabled: boolean;
  countryCode: CountryCode;
  onSubmit: (values: Partial<FormValues>) => void;
};

export type FormValues = {
  askingRentUseRange: boolean;
  buildingName: string | undefined | null;
  addressLine1: string;
  addressLine2: string | undefined | null;
  city: string;
  state: string;
  zipCode: string;
  longitude: number | null;
  latitude: number | null;
  countryCode: CountryCode;
  photos: Array<Omit<MultipathImage, 'type' | 'cropperCanvasData' | 'transformations' | 'id'>>;
  size: number | '' | null;
  askingRent?: number | null;
  minAskingRent?: number | null;
  maxAskingRent?: number | null;
  askingRentUnitsWithoutCurrency: string;
  condition: string | undefined | null;
  leaseType: string;
  description: string | undefined | null;
  descriptionHtml: string | undefined | null;
  availabilityDate: string | null | undefined;
  availableImmediately: boolean;
  location?: string;
  submarket: string | null | undefined;
};

export const defaultValidationSchema = (t: Function): SchemaOf<FormValues> =>
  object({
    // location tab
    askingRentUseRange: boolean().defined(),
    buildingName: string()
      .nullable()
      .max(30, t('externalListing.buildingNameMax'))
      .label(t('externalListing.buildingName')),
    addressLine1: string()
      .max(30, t('externalListing.addressMax'))
      .required()
      .label(t('externalListing.addressLabel')),
    addressLine2: string()
      .required()
      .max(30, t('externalListing.address2Max'))
      .label(t('externalListing.address2Label')),
    city: string()
      .max(20, t('externalListing.cityMax'))
      .required()
      .label(t('externalListing.cityLabel')),
    state: string()
      .max(20, t('externalListing.stateMax'))
      .nullable()
      .required()
      .label(t('externalListing.stateLabel')),
    zipCode: string().required().label(t('externalListing.zipLabel')),
    longitude: number().nullable().required(),
    latitude: number().nullable().required(),
    countryCode: mixed().required(),
    // media tab
    photos: array()
      .of(
        object({
          path: string().defined(),
          smallPath: string().defined(),
          mediumPath: string().defined(),
          largePath: string().defined(),
          rawPath: string().defined(),
          downloadPath: string().defined(),
          description: string().ensure(),
          altText: string().ensure(),
          cloudinaryId: string().defined(),
        }),
      )
      .required()
      .min(1, t('externalListing.requiredPhotoMessage'))
      .max(10, t('externalListing.lessThan10PhotoMessage')),
    // listing tab
    size: number()
      .min(1, t('externalListing.sizeLabelError'))
      .nullable()
      .required()
      .label(t('externalListing.sizeLabel')),
    askingRent: number(),
    minAskingRent: number()
      .nullable()
      .when(
        ['maxAskingRent', 'askingRentUseRange'],
        // @ts-ignore: Ignore, this is the right way to use 2+ conditions.
        (maxAskingRent, askingRentUseRange, schema) => {
          return _.isNumber(maxAskingRent) && askingRentUseRange
            ? schema.max(maxAskingRent, t('externalListing.invalidRangeError'))
            : schema;
        },
      ),
    maxAskingRent: number().nullable(),
    askingRentUnitsWithoutCurrency: string().required(),
    condition: string().required().label(t('externalListing.condition')),
    leaseType: string().required().label(t('externalListing.leaseTypeLabel')),
    description: string().nullable(),
    descriptionHtml: string().nullable(),
    availableImmediately: boolean().defined(),
    availabilityDate: string()
      .when(['availableImmediately'], {
        is: true,
        then: string().nullable().notRequired(),
        otherwise: string().nullable().required(),
      })
      .label(t('externalListing.available')),
    location: string().when(['longitude', 'latitude'], {
      is: (lng, lat) => !lng || !lat,
      then: string().required(t('externalListing.confirmMapError')),
      otherwise: string().notRequired().nullable(),
    }),
    submarket: string().notRequired().nullable(),
  });

export const defaultFormValues = ({
  countryCode,
  initialValues,
}: {
  countryCode: CountryCode;
  initialValues: Partial<FormValues>;
}) => ({
  askingRentUseRange: false,
  buildingName: null,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  countryCode,
  longitude: null,
  latitude: null,
  photos: [],
  size: '' as '', // cast to empty string to avoid type error
  askingRentUnitsWithoutCurrency: 'sf/yr',
  condition: '',
  description: '',
  descriptionHtml: '',
  leaseType: '',
  availabilityDate: null,
  availableImmediately: false,
  submarket: '',
  ...initialValues,
});

export default function Form({
  tourbookId,
  children,
  onSubmit,
  isPromptEnabled,
  initialValues,
  countryCode,
}: Props) {
  const FormForCountry = countryCode === 'GB' ? FormGb : FormUs;

  return (
    <FormForCountry onSubmit={onSubmit} initialValues={initialValues}>
      <ImagesContextProvider>{children}</ImagesContextProvider>
      <FormCancellationPrompt<FormValues>
        enabled={isPromptEnabled}
        checkIfShouldTriggerPrompt={(dirty, touched, location) => {
          const onlyLocationIsTouched = Object.keys(touched).length === 1 && touched.location;
          const isEffectivelyDirty = dirty && !onlyLocationIsTouched;
          // use this instead of dirty because the location field can be touched on the edit form
          // before anything has been touched (i.e. location is not a real field but it needs to be
          // set therefore its touched)

          return (
            isEffectivelyDirty &&
            location.pathname !== routes.tourbookNewExternalListing(tourbookId)
          );
        }}
      />
    </FormForCountry>
  );
}
