import { range } from 'lodash';
import cn from 'classnames';
import { isInIframe } from '@root/shared/iframeUtils';
import SkeletonHeader from './SkeletonHeader';
import SkeletonListItem from './SkeletonListItem';
import s from './TourbookSkeleton.module.less';

const NUMBER_OF_LIST_ITEMS = 5;

type Props = {
  visible: boolean;
};

const TourbookSkeleton = ({ visible = false }: Props) => {
  return (
    <div className={cn(s.container, !visible && s.hidden)} data-testid="tourbookSkeleton">
      <div className={s.backgroundOverlay} />
      <div className={cn(s.dropdownButton, s.shimmer, isInIframe() && 'invisible')} />
      <SkeletonHeader />
      <div className={cn(s.tableOfContentsTitle, s.shimmer)} />
      {range(NUMBER_OF_LIST_ITEMS).map(i => (
        <SkeletonListItem key={i} />
      ))}
    </div>
  );
};

export default TourbookSkeleton;
