import { PARAMETERS, EVENTS, EVENT_TYPES } from '@root/tracking/constants';

const navLogoClicked = () => {
  return {
    type: 'NAV_LOGO_CLICKED',
    payload: {
      meta: {
        analytics: {
          eventType: EVENT_TYPES.track,
          event: EVENTS.clickToPage,
          sourceContent: PARAMETERS.truvaLogo,
          destination: PARAMETERS.homepage,
          skipPageInfo: true,
        },
      },
    },
  };
};

const searchBuildingClicked = () => ({
  type: 'SEARCH_BUILDING_CLICKED',
  payload: {
    meta: {
      analytics: {
        sourceContent: PARAMETERS.searchBar,
        destination: PARAMETERS.buildingPage,
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
      },
    },
  },
});

const searchClicked = () => ({
  type: 'NAV_SEARCH_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.searchButton,
        destination: PARAMETERS.searchResultsPage,
        skipPageInfo: true,
      },
    },
  },
});

const savedItemsClicked = savedPage => ({
  type: 'NAV_SAVED_ITEMS_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.navigation,
        destination: savedPage,
        skipPageInfo: true,
      },
    },
  },
});

const searchLocationAdded = locationSlugs => ({
  type: 'SEARCH_LOCATION_ADDED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.filterInteraction,
        action: PARAMETERS.applyFilter,
        filterLocation: locationSlugs,
      },
    },
  },
});

const tourbooksClicked = {
  type: 'TOURBOOKS_NAV_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.navigation,
        destination: PARAMETERS.manageTourbooksPage,
      },
    },
  },
} as const;
type TourbooksClickedAction = typeof tourbooksClicked;

const accountClicked = {
  type: 'ACCOUNT_NAV_ITEM_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.navListItem,
        destination: PARAMETERS.professionalInfoPage,
      },
    },
  },
} as const;
type AccountClickedAction = typeof accountClicked;

const nameClicked = {
  type: 'NAME_ICON_IN_NAV_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.navIcon,
        destination: PARAMETERS.professionalInfoPage,
      },
    },
  },
} as const;
type NameClickedAction = typeof nameClicked;

export type Actions = TourbooksClickedAction | AccountClickedAction | NameClickedAction;

export default {
  navLogoClicked,
  searchBuildingClicked,
  searchClicked,
  searchLocationAdded,
  savedItemsClicked,
  tourbooksClicked,
  accountClicked,
  nameClicked,
};
