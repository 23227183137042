import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import actions from '@store/actions/listingSearchPage';
import { RawInput, RawLabel } from '@components/shared/forms';
import { debounce } from 'lodash';
import { SizeRangeFilter } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import s from '../Filters.module.less';
import { ListingSearchCriteria, useListingSearchCriteria } from '../../utils';
import matchNumericInput from '../../utils/matchNumericInput';
import stringIsANumber from '../../utils/stringIsANumber';

const debounceLength = 1500;

const SizeFilters = () => {
  const { t } = useTranslation('filters');
  const { sizeFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const minSizeRef = useRef<HTMLInputElement | null>(null);
  const maxSizeRef = useRef<HTMLInputElement | null>(null);

  const criteria = useListingSearchCriteria({
    onUrlChange: c => {
      setSizeFilter({
        minSize: c.currentFilters.minSize ?? '',
        maxSize: c.currentFilters.maxSize ?? '',
      });
    },
  });
  const [sizeFilter, setSizeFilter] = useState<SizeRangeFilter>({
    minSize: criteria.currentFilters.minSize ?? '',
    maxSize: criteria.currentFilters.maxSize ?? '',
  });

  const dispatch = useDispatch();

  const dispatchAnalytics = (newSizeFilter: { minSize: string; maxSize: string }): void => {
    dispatch(
      actions.sizeFilterChanged(
        newSizeFilter.minSize.length > 0 ? newSizeFilter.minSize : 'unset',
        newSizeFilter.maxSize.length > 0 ? newSizeFilter.maxSize : 'unset',
      ),
    );
  };

  const updateCriteria = (
    newSizeFilter: SizeRangeFilter,
    criteriaObject: ListingSearchCriteria,
    minOrMax: 'minSize' | 'maxSize',
  ) => {
    const [currentMinSize, currentMaxSize, newMinSize, newMaxSize] = [
      Number(criteriaObject.currentFilters.minSize),
      Number(criteriaObject.currentFilters.maxSize),
      Number(newSizeFilter.minSize || undefined),
      Number(newSizeFilter.maxSize || undefined),
    ];

    let criteriaChanged = false;

    if (!(Number.isNaN(currentMinSize) && Number.isNaN(newMinSize))) {
      criteriaChanged ||= currentMinSize !== newMinSize;
    }

    if (!(Number.isNaN(currentMaxSize) && Number.isNaN(newMaxSize))) {
      criteriaChanged ||= currentMaxSize !== newMaxSize;
    }

    criteriaObject.remove('minSize');
    criteriaObject.remove('maxSize');

    if (newSizeFilter.minSize && stringIsANumber(newSizeFilter.minSize)) {
      criteriaObject.add('minSize', newSizeFilter.minSize);
    }

    if (newSizeFilter.maxSize && stringIsANumber(newSizeFilter.maxSize)) {
      criteriaObject.add('maxSize', newSizeFilter.maxSize);
    }

    if (criteriaChanged) {
      if (flags['search-analytics-refactor']) {
        const value = newSizeFilter[minOrMax];
        sizeFilterInteraction({
          value: value ? Number(value) : null,
          filterType: minOrMax,
          currentFilters: criteriaObject.toAnalyticsProperties(),
        });
      } else {
        dispatchAnalytics(newSizeFilter);
      }
    }
    criteriaObject.pushToHistory();
  };

  // FIXME: Either add the exhaustive deps or delete this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCriteria = useMemo(() => debounce(updateCriteria, debounceLength), []);

  const handleKeyDown = (event, newFilter: SizeRangeFilter, minOrMax: 'minSize' | 'maxSize') => {
    setSizeFilter(newFilter);
    if (event.key === 'Enter') {
      debouncedUpdateCriteria.cancel();
      updateCriteria(newFilter, criteria, minOrMax);
    }
  };

  const handleBlur = (minOrMax: 'minSize' | 'maxSize') => {
    debouncedUpdateCriteria.cancel();
    updateCriteria(sizeFilter, criteria, minOrMax);
  };

  const onInputChanged = (value: string, minOrMax: 'minSize' | 'maxSize') => {
    const validSizeValue = matchNumericInput(value);
    if (validSizeValue === null || validSizeValue.length > 10) {
      return;
    }

    setSizeFilter(oldSizeFilter => {
      const newSizeFilter = {
        ...oldSizeFilter,
        [minOrMax]: validSizeValue,
      };
      debouncedUpdateCriteria.cancel();
      debouncedUpdateCriteria(newSizeFilter, criteria, minOrMax);
      return newSizeFilter;
    });
  };

  return (
    <>
      <div className={s.rangeContainer}>
        <div className={s.rangeInputContainer}>
          <RawLabel className={s.label} name="Min Size" tag="div">
            {t('size.minSizePillLabel')}
          </RawLabel>
          <RawInput
            ref={minSizeRef}
            className={s.input}
            name="Min Size"
            ariaLabel={t('size.minSizePillLabel')}
            value={sizeFilter.minSize}
            onChange={event => {
              onInputChanged(event.currentTarget.value, 'minSize');
            }}
            onKeyDown={e =>
              handleKeyDown(
                e,
                { ...sizeFilter, minSize: minSizeRef.current?.value ?? '' },
                'minSize',
              )
            }
            onBlur={() => handleBlur('minSize')}
            units={t('size.sf')}
          />
        </div>
        <div className={s.rangeInputContainer}>
          <RawLabel className={s.label} name="Max Size" tag="div">
            {t('size.maxSizePillLabel')}
          </RawLabel>
          <RawInput
            ref={maxSizeRef}
            className={s.input}
            name="Max Size"
            ariaLabel={t('size.maxSizePillLabel')}
            value={sizeFilter.maxSize}
            onKeyDown={e =>
              handleKeyDown(
                e,
                { ...sizeFilter, maxSize: maxSizeRef.current?.value ?? '' },
                'maxSize',
              )
            }
            onChange={event => {
              onInputChanged(event.currentTarget.value, 'maxSize');
            }}
            onBlur={() => handleBlur('maxSize')}
            units={t('size.sf')}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(SizeFilters);
