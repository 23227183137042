import { memo } from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { Actions } from '@cloudinary/url-gen';
import { trim } from '@cloudinary/url-gen/actions/videoEdit';

import useCloudinary from '@shared/useCloudinary';

type Props = {
  className?: string;
  cloudinaryId: string;
  posterStartOffset?: string | null;
  altText: string;
  containerClassName?: string;
};

const videoPlayCircle = Actions.NamedTransformation.name('play_circle_thumbnail');
const CloudinaryVideoThumbnail = ({
  cloudinaryId,
  altText,
  containerClassName,
  className,
  posterStartOffset = '0',
}: Props) => {
  const cld = useCloudinary();
  const image = cld
    .video(cloudinaryId)
    .resize(thumbnail().width(200))
    .namedTransformation(videoPlayCircle)
    .videoEdit(trim().startOffset(posterStartOffset || 0))
    .format('png');

  return (
    <figure className={containerClassName}>
      <AdvancedImage cldImg={image} className={className} alt={altText} />
    </figure>
  );
};

export default memo(CloudinaryVideoThumbnail);
