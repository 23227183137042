import cn from 'classnames';
import s from './SkeletonHeader.module.less';

const SkeletonHeader = () => {
  return (
    <div data-testid="skeletonHeader" className={cn(s.header, s.shimmer)}>
      <div className={cn(s.photo, s.shimmer)} />
      <div className={cn(s.descriptionSection)}>
        <div className={cn(s.title, s.shimmer)} />
        <div className={s.boxCircleContainer}>
          <div className={cn(s.box, s.shimmer)} />
          <div className={cn(s.circle, s.shimmer)} />
        </div>
      </div>
      <div className={cn(s.contactSection)}>
        <div className={cn(s.circle, s.shimmer)} />
        <div className={cn(s.box1, s.shimmer)} />
        <div className={cn(s.box2, s.shimmer)} />
      </div>
    </div>
  );
};

export default SkeletonHeader;
