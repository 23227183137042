import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { get } from '@root/shared/typedApi';
import type { PaginationResponse, SearchListing, LastInquiry } from '@root/types';

type ListingResponse = {
  savedStatuses: Array<{ listingId: string; saved: boolean }>;
  inquiries: LastInquiry[];
  listings: SearchListing[];
  pagination: PaginationResponse;
};

export type ReceiveSearchListingsFromListingPreviewAction = {
  type: 'RECEIVE_SEARCH_LISTINGS_FROM_LISTING_PREVIEW';
  payload: ListingResponse;
};
export const receiveListings = (
  listingResponse: ListingResponse,
): ReceiveSearchListingsFromListingPreviewAction => ({
  type: 'RECEIVE_SEARCH_LISTINGS_FROM_LISTING_PREVIEW',
  payload: listingResponse,
});

const useFetchSearchListingsFromListingPreview = (): ((
  apiListingsUrl: string,
  onError?: () => void,
) => Promise<void>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // If the fetchQuery fails, it will throw error.
  const fetchSearchListing = async (apiListingsUrl: string, onError: () => void = () => {}) => {
    try {
      const listingSearchResponseData: ListingResponse = await queryClient.fetchQuery({
        queryKey: [apiListingsUrl],
        queryFn: async () => get<ListingResponse>(apiListingsUrl),
      });

      if (listingSearchResponseData) {
        dispatch(receiveListings(listingSearchResponseData));
      }
    } catch {
      onError();
    }
  };

  return fetchSearchListing;
};

export default useFetchSearchListingsFromListingPreview;
