import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { AdminLandlord } from '@root/types';
import { Breadcrumbs } from '@components/shared';
import api from '@shared/api';
import routes from '@root/routes';
import Container from '@components/shared/Admin/Container';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Form from '../Form';
import Layout from '../../Layout/Layout';

const EditPage = () => {
  const { t } = useTranslation('admin');
  const { id } = useParams<{ id: string }>();
  const [landlord, setLandlord] = useState<AdminLandlord | null>(null);
  const flags = useFlags();

  const fetchLandlord = async () => {
    const response = await api.fetch(routes.api.admin.landlord(id));
    setLandlord(await response.json());
  };

  useEffect(() => {
    fetchLandlord();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmitSuccess = () => {
    notification.success({
      message: t('landlord.updateSuccess', {
        name: flags['outreach.landlord-company-connection-severed']
          ? landlord?.name
          : landlord!.companyName,
      }),
    });
    fetchLandlord();
  };

  const onSaveSuccess = ({ message }: { message?: string } = {}) => {
    if (message) notification.success({ message });
    fetchLandlord();
  };

  const {
    admin: { landlords: adminLandlordSearchRoute },
  } = routes;

  return (
    <Layout>
      <Breadcrumbs
        className="mb-1"
        items={[
          { content: t('breadcrumbs.admin') },
          { content: t('breadcrumbs.landlords'), link: adminLandlordSearchRoute },
          {
            content: `${landlord?.name || ''}`,
          },
        ]}
      />
      {landlord ? (
        <Container title={t('landlord.edit')}>
          <Form
            fetchLandlord={fetchLandlord}
            landlord={landlord}
            submitText={t('common.updateButton')}
            onSubmitSuccess={onSubmitSuccess}
            onSaveSuccess={onSaveSuccess}
          />
        </Container>
      ) : null}
    </Layout>
  );
};

export default EditPage;
