import { Listing, MinMaxArea } from '@root/types';
import { useTranslation } from 'react-i18next';
import FlexibleSizeArea from '@components/shared/FlexibleSizeArea';
import { camelCase } from 'lodash';
import DataPoint from '@components/shared/DataPoint/DataPoint';
import formatCeilingHeightMeasure from '@shared/formatCeilingHeightMeasure';
import css from './BasicInfo.module.less';

const formatArea = ({ area, flexArea }: { area: string; flexArea: MinMaxArea | null }) => {
  return (
    <span>
      {area}
      {flexArea ? (
        <FlexibleSizeArea minMaxArea={flexArea} labelQaTestId="listing-min-max-area" />
      ) : null}
    </span>
  );
};

type Props = { listing: Listing };

const BasicInfo = ({ listing }: Props) => {
  const { t } = useTranslation('listing');

  const {
    dateAvailable,
    leaseType,
    capacity,
    area,
    availableToday,
    condition,
    slabToSlabCeilingHeightMeasure,
    finishedCeilingHeightMeasure,
    building,
  } = listing;
  const {
    isIndustrial,
    gradeLevelDoors,
    dockHighDoors,
    driveInDoors,
    minimumIndoorClearHeight,
    fireSprinklers,
    percentOffice,
    parkingSpaces,
  } = building;

  return (
    <div className={css.basicInfo}>
      <DataPoint<{ area: string; flexArea: MinMaxArea | null }>
        value={{
          area: t('units:area', { area }),
          flexArea: listing.minMaxArea,
        }}
        format={formatArea}
        isAlwaysShown
        label={t('size')}
        labelQaTestId="listing-size"
      />
      {capacity ? (
        <DataPoint
          value={t('common:listing.capacity', { capacity })}
          label={t('estimatedCapacity')}
          labelQaTestId="listing-capacity"
        />
      ) : null}
      {availableToday ? (
        <DataPoint
          value={t('common:listing.immediateAvailability')}
          label={t('available')}
          labelQaTestId="listing-availability"
        />
      ) : (
        <DataPoint
          value={t('units:date', { date: dateAvailable })}
          label={t('available')}
          labelQaTestId="listing-availability"
        />
      )}
      {isIndustrial ? (
        <>
          {driveInDoors ? (
            <DataPoint value={driveInDoors} label={t('building:details.driveInDoors')} />
          ) : null}
          {gradeLevelDoors ? (
            <DataPoint value={gradeLevelDoors} label={t('building:details.gradeLevelDoors')} />
          ) : null}
          {dockHighDoors ? (
            <DataPoint value={dockHighDoors} label={t('building:details.dockHighDoors')} />
          ) : null}
          {fireSprinklers ? (
            <DataPoint value={fireSprinklers} label={t('building:details.fireSprinklers')} />
          ) : null}
          {minimumIndoorClearHeight ? (
            <DataPoint
              label={t('building:details.minimumIndoorClearHeight')}
              value={t('units:length', {
                length: {
                  magnitude: minimumIndoorClearHeight.magnitude,
                  unit: minimumIndoorClearHeight.units.length,
                },
              })}
            />
          ) : null}
          {parkingSpaces ? (
            <DataPoint label={t('building:details.parkingSpaces')} value={parkingSpaces} />
          ) : null}
          {percentOffice ? (
            <DataPoint label={t('building:details.percentOffice')} value={`${percentOffice}%`} />
          ) : null}
        </>
      ) : null}
      <DataPoint
        value={leaseType}
        label={t('leaseType')}
        format={aLeaseType => t(`leaseTypes.${aLeaseType}`)}
        labelQaTestId="listing-lease-type"
      />
      {condition ? (
        <DataPoint
          valueClassName={css.conditionValue}
          value={t(`spaceConditions:${camelCase(condition)}`)}
          label={t('condition')}
          labelQaTestId="listing-condition"
        />
      ) : null}
      {finishedCeilingHeightMeasure || slabToSlabCeilingHeightMeasure ? (
        <DataPoint
          value={{
            slabToSlab: slabToSlabCeilingHeightMeasure,
            finished: finishedCeilingHeightMeasure,
          }}
          label={t('ceilingHeight')}
          format={measure => formatCeilingHeightMeasure(measure)}
          labelQaTestId="listing-ceiling-height"
        />
      ) : null}
    </div>
  );
};

export default BasicInfo;
