import { AgentContact } from '@root/types';
import { ContactInfoIcon, MailIcon, MobileIcon } from '@viewthespace/components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Avatar from '../Avatar';
import PopperTooltip from '../PopperTooltip';
import CompanyLogo from '../CompanyLogo/CompanyLogo';

type ContactProps = {
  contact: AgentContact;
  emailSubjectName?: string;
  compact?: boolean;
  onContactReveal?: () => void;
  onContactEmailClick?: () => void;
};
const Contact = ({
  contact,
  emailSubjectName,
  compact = true,
  onContactReveal = () => {},
  onContactEmailClick = () => {},
}: ContactProps) => {
  const { t } = useTranslation('listing');
  const { name, email, phone, company, role, userAvatar, phoneExtension, companyLogo } = contact;

  const phoneWithoutExtension = t('common:formattedPhone', { formattedPhone: phone });
  const phoneWithExtension =
    phone &&
    t('common:formattedPhoneWithExtension', {
      formattedPhone: phone,
      extension: phoneExtension,
    });

  const emailAndPhone = (
    <div>
      <div className="flex items-center">
        <MailIcon className="tablet:hidden mobile:hidden" />
        <a
          className="font-body-medium desktop:ml-1"
          href={`mailto:${email}?subject=${emailSubjectName || ''}`}
          target="_blank"
          rel="noreferrer"
          onClick={onContactEmailClick}
        >
          {email}
        </a>
      </div>
      {!!phone && (
        <div className="flex items-center">
          <MobileIcon className="tablet:hidden mobile:hidden" />
          <span className="font-body-medium desktop:ml-1">
            {phoneExtension ? phoneWithExtension : phoneWithoutExtension}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={cn(
        'flex desktop:items-start tablet:flex-col mobile:flex-col',
        compact && 'desktop:flex-col',
      )}
    >
      <div className={cn('flex items-center', !compact && 'pr-0.5')}>
        <Avatar
          name={name}
          photoUrl={userAvatar?.path}
          className=" !mr-0 !h-4 !w-4 border-[0.5px] border-solid border-black-100"
        />
        {companyLogo && (
          <CompanyLogo
            userName={name}
            photoUrl={companyLogo.path}
            className="relative left-[-4px] !h-4 !w-4 [&>img]:border-[0.5px] [&>img]:border-solid [&>img]:border-black-100"
          />
        )}
        {compact && (
          <PopperTooltip
            triggerElement={
              <ContactInfoIcon className="ml-0.5 !h-[20px] !w-[20px] text-indigo-100" />
            }
            onClick={onContactReveal}
            popperElementClassName="mt-0.5 bg-background-primary p-2 !border-none shadow-[0_1px_8px_rgba(115,115,115,0.25)]"
            popperElement={emailAndPhone}
            trigger="click"
          />
        )}
      </div>
      <div className={cn(compact ? 'pt-1' : 'pt-1 desktop:pt-0')}>
        <div className="text-black-100 font-body-medium">{name}</div>
        {!!role && <div className="text-black-055 font-body-medium">{role}</div>}
        {!!company && <div className="text-black-055 font-body-medium">{company}</div>}
        {!compact && emailAndPhone}
      </div>
    </div>
  );
};

export default Contact;
