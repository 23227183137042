import i18next from 'i18next';
import round from 'lodash/round';
import { AllAmenities } from '@root/types';
import { h3, smallText, PowerpointTextElement, PptxUtils } from './globals';
import { PAGE_MARGIN } from './portrait';

type PreparedAmenities = [string[], string[], string[]];
export function prepareAmenities(
  amenities: Partial<AllAmenities>,
  utils: PptxUtils,
): PreparedAmenities {
  const t = (key, tOpts = {}) => {
    const fullKey = `common:amenities.${key}`;
    return i18next.exists(fullKey) ? utils.t(fullKey, tOpts) : '';
  };

  const formatAmenity = (key, val) => {
    // furnished listings is a strange exception
    if (key === 'furnished') return utils.t(`common:amenities.${key}.${val}`);

    return t(key, { count: val });
  };

  const inChunks: PreparedAmenities = [[], [], []];
  return Object.entries(amenities)
    .map(([amenity, val]) => formatAmenity(amenity, val))
    .sort()
    .reduce((arrays, amenity, index) => {
      if (amenity) arrays[index % 3].push(amenity);
      return arrays;
    }, inChunks);
}

export const generateAmenitiesElements = (
  amenities: Partial<AllAmenities> = {},
  { model, x = PAGE_MARGIN, y }: { model: 'Listing' | 'Building'; y: number; x?: number },
  utils: PptxUtils,
): PowerpointTextElement[] => {
  const elements: PowerpointTextElement[] = [];
  if (Object.keys(amenities).length < 1) return elements;

  elements.push({
    type: 'textBox',
    data:
      model === 'Building'
        ? utils.t('tourbook:powerpoint.buildingAmenities')
        : utils.t('tourbook:powerpoint.listingAmenities'),
    placement: {
      x,
      y,
      w: 6.85,
      h: 0.3,
    },
    textOptions: { ...h3 },
  });

  prepareAmenities(amenities, utils)
    .map(column =>
      column.reduce((text, amenity) => {
        return text === '' ? amenity : `${text.trim()}\n${amenity}`;
      }, ''),
    )
    .forEach((columnText, i) => {
      elements.push({
        type: 'textBox',
        data: columnText,
        placement: {
          x: round(x + i * 2.42, 2),
          y: y + 0.37,
          w: 2.13,
          h: 1.5,
          valign: 'top',
        },
        textOptions: {
          ...smallText,
        },
      });
    });

  return elements;
};
