import { ValidIconTypes } from '@components/shared/CustomIcon';
import useSize from '@react-hook/size';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import IconButton from '../IconButton';
import s from './FloatingActionButton.module.less';

export type Props = {
  icon: ValidIconTypes;
  getDialogContent: (closeDialog: () => void) => React.ReactNode;
  disabled?: boolean;
};

const FloatingActionButton = ({ icon, getDialogContent, disabled = false }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialog = useRef<HTMLDivElement>(null!);
  const dialogHeight = useSize(dialog)[1] || 0;

  useEffect(() => {
    const handleOutsideClick = event => {
      if (isDialogOpen && dialog.current) {
        const { left, top, right, bottom } = dialog.current.getBoundingClientRect();

        // we can't just check whether a child was clicked since dropdowns use a portal
        if (
          event.clientX < left ||
          event.clientX > right ||
          event.clientY < top ||
          event.clientY > bottom
        ) {
          setIsDialogOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [setIsDialogOpen, isDialogOpen]);

  return (
    <div
      data-testid="floatingActionButtonWrapper"
      className={cn(s.wrapper, isDialogOpen ? s.open : s.closed, disabled && s.disabled)}
      style={{ height: isDialogOpen ? dialogHeight : undefined }}
    >
      <div
        data-testid="dialogWrapper"
        className={cn(s.dialogWrapper, isDialogOpen ? s.open : s.closed)}
        ref={dialog}
      >
        {getDialogContent(() => setIsDialogOpen(false))}
      </div>
      <div
        data-testid="buttonWrapper"
        className={cn(s.buttonWrapper, isDialogOpen ? s.hidden : s.visible)}
      >
        <IconButton
          data-testid="floatingActionButton"
          icon={icon}
          className={cn(s.actionButton, disabled && s.disabled)}
          title="inquire"
          disabled={disabled}
          onClick={() => {
            setIsDialogOpen(true);
          }}
        />
      </div>
    </div>
  );
};

export default FloatingActionButton;
