// eslint-disable-next-line import/prefer-default-export
export const MIME_TYPES = {
  allDocuments:
    'application/pdf, .pdf, application/msword, .doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, application/vnd.ms-excel.sheet.macroEnabled.12, .xlsm, application/vnd.ms-excel, .xls, application/vnd.ms-powerpoint, .ppt, application/vnd.openxmlformats-officedocument.presentationml.presentation, .pptx, application/vnd.oasis.opendocument.text, .oth, .dwg, .dxf, .tiff, text/plain, image/psd, video/*, image/*, text/csv',
  excelOnly:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx, application/vnd.ms-excel.sheet.macroEnabled.12, .xlsm, application/vnd.ms-excel, .xls, text/plain, text/csv',
  imagePdf: 'application/pdf, .pdf, image/*',
  imageOnly: 'image/*',
  imageJpegPngOnly: 'image/jpeg, image/png',
  pdfWord:
    'application/pdf, .pdf, application/msword, .doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .docx',
  videoOnly: '.mov,.mp4,.mpg,.avi,.wmv,.webm,.m4v',
};
