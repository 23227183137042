import { Select as AntdSelect } from 'antd';
import CustomIcon from '@components/shared/CustomIcon';
import cn from 'classnames';

import s from './Select.module.less';

type SelectProps = {
  className: string;
  dropdownClass: string;
  props: object;
};
const Select = ({ className, dropdownClass, ...props }: SelectProps) => (
  <AntdSelect
    {...props}
    className={cn({ [s.selectCustom]: true, [className]: !!className })}
    dropdownRender={dropdown => (
      <div className={cn({ [s.selectCustomDropdown]: true, [dropdownClass]: !!dropdownClass })}>
        {dropdown}
      </div>
    )}
  />
);

Select.defaultProps = {
  size: 'large',
  menuItemSelectedIcon: <CustomIcon type="checkmark" className={s.selectedCheckmark} />,
} as Partial<SelectProps>;

Select.Option = AntdSelect.Option;

export default Select;
