import { useEffect, useState } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { Modal as AntdModal } from 'antd';
import { CustomIcon } from '@components/shared';
import { isInIframe, tellModalOpenStatusToHost } from '@root/shared/iframeUtils';
import s from './Modal.module.less';

type Props = {
  destroyOnClose?: boolean;
  title?: React.ReactNode;
  isOpen: boolean;
  closeModal?: () => void;
  afterClose?: () => void;
  children: React.ReactNode;
  footer?: React.ReactNode;
  maskClosable?: boolean;
  closable?: boolean;
  className?: string;
  wrapClassName?: string;
  'data-testid'?: string;
  style?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
  centered?: boolean;
};

const Modal = ({
  destroyOnClose = false,
  title,
  isOpen: visible,
  closeModal,
  children,
  footer = null,
  maskClosable = true,
  closable = true,
  className,
  wrapClassName,
  afterClose,
  'data-testid': dataTestId = 'modal',
  style,
  bodyStyle,
  centered,
}: Props) => {
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    if (visible) {
      setClosed(false);
    }

    tellModalOpenStatusToHost(visible);
  }, [visible]);

  if (!visible && closed) {
    return null;
  }

  return (
    <AntdModal
      afterClose={() => {
        setClosed(true);
        if (afterClose) afterClose();
      }}
      className={cn(s.modal, className, isInIframe() && 'right-[112px]')}
      wrapClassName={wrapClassName}
      closeIcon={closable ? <CustomIcon type="close" /> : null}
      title={title}
      visible={visible}
      footer={footer}
      onCancel={closeModal}
      destroyOnClose={destroyOnClose}
      closable={closable}
      maskClosable={maskClosable}
      style={style}
      bodyStyle={bodyStyle}
      centered={centered}
    >
      <div data-testid={dataTestId}>{children}</div>
    </AntdModal>
  );
};

export default Modal;
