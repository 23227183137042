import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import CustomIcon from '@components/shared/CustomIcon';
import IconButton from '@components/shared/IconButton/IconButton';
import { LandlordBuilding } from '@root/types';
import routes from '@root/routes';
import cn from 'classnames';
import useAltText from '@root/shared/useAltText';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BuildingLink from './BuildingLink';
import s from './BuildingCard.module.less';
import UTMAwareLink from '../UtmAwareLink/UtmAwareLink';

type BuildingCardProps = {
  building: LandlordBuilding;
  onBuildingClick?: () => void;
  showSaveButton?: boolean;
  isSaved?: boolean;
  onSave?: (id: string, slug: string) => void;
  onUnsave?: (id: string, slug: string) => void;
  showLogo?: boolean;
  useTrackingParams?: boolean;
};

const BuildingCard = ({
  building,
  onBuildingClick = () => null,
  showSaveButton = false,
  isSaved = false,
  onSave = () => null,
  onUnsave = () => null,
  showLogo = false,
  // Need this because it's a shared component used on the Saved Building page and Landlord page
  useTrackingParams = false,
}: BuildingCardProps) => {
  const { t } = useTranslation('landlord');
  const flags = useFlags();
  const { getAltText } = useAltText({ address: building.address });
  const toggleSave = () => {
    if ('slug' in building) {
      if (isSaved) {
        onUnsave(building.id, building.slug);
      } else {
        onSave(building.id, building.slug);
      }
    }
  };

  const renderBuildingCardLocation = () => (
    <h5 className={s.location}>
      {building.address.countryCode === 'GB'
        ? building.address.city
        : t('common:cityState', { address: building.address })}
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <span className="mx-1">|</span>
      {t(`country:${building.address.countryCode}`)}
    </h5>
  );

  const renderBuildingCardContent = () => {
    return (
      <>
        {showLogo ? (
          <CustomIcon
            type="vts-logo-indigo"
            className="absolute left-2 top-2 z-[100] h-3 w-3 rounded-[50%] bg-white p-[6px] text-[12px]"
            data-testid="vtsLogo"
          />
        ) : null}
        <div className="flex h-full">
          <div className="h-full flex-1 grow-[3]">
            <img
              className="h-full w-full rounded-l-[3px] object-cover"
              src={building.photoUrl || '/images/empty-state-default.jpg'}
              alt={getAltText({ type: building.photoType || 'buildingPhoto' })}
            />
          </div>
          <div
            className={cn(
              'flex  flex-1 grow-[2]  flex-col p-2',
              showSaveButton ? 'min-w-[210px] pr-6' : 'min-w-[180px]',
            )}
          >
            <h4 className={s.name}>
              <span className={s.truncateName} title={building.address.buildingName || undefined}>
                {building.address.buildingName}
              </span>
              <span className={s.truncateName} title={building.address.street}>
                {building.address.street}
              </span>
            </h4>
            <div className={s.location}>{renderBuildingCardLocation()}</div>
            <div className={s.area}>{t('units:area', { area: building.area })}</div>
            {building.availableArea && building.availableArea.magnitude > 100 ? (
              <div className={s.area}>
                {t('building.availableArea', {
                  ...building.availableArea,
                  count: building.availableArea?.magnitude || 0,
                })}
              </div>
            ) : null}
            <div className="flex-1" />
            <BuildingLink numberOfAvailabilities={building.numberOfAvailabilities} />
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className={cn(
        s.buildingCard,
        'relative h-[271px] cursor-pointer rounded-[3px] lg:h-[305px]',
        'hover:outline hover:outline-2 hover:outline-egg-100',
      )}
    >
      {showSaveButton && (
        <IconButton
          className={cn({ [s.heartIcon]: isSaved, [s.heartButton]: true })}
          icon={isSaved ? 'heart' : 'heart-outline'}
          onClick={toggleSave}
          title={t('saveToFavorites')}
        />
      )}
      {flags['vts-trackable-parameters'] && useTrackingParams ? (
        <UTMAwareLink
          to={routes.building(building.slug)}
          className="text-inherit no-underline hover:text-inherit hover:no-underline"
          onClick={onBuildingClick}
        >
          {renderBuildingCardContent()}
        </UTMAwareLink>
      ) : (
        <NavLink
          to={routes.building(building.slug)}
          className="text-inherit no-underline hover:text-inherit hover:no-underline"
          onClick={onBuildingClick}
        >
          {renderBuildingCardContent()}
        </NavLink>
      )}
    </div>
  );
};

export default BuildingCard;
