import { EVENTS, PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';

const lightboxOpened = {
  type: 'LANDLORD_LIGHTBOX_OPENED',
  payload: {
    meta: {
      analytics: {
        action: PARAMETERS.openLightbox,
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        sourcePage: PARAMETERS.landlordPage,
        sourceContent: PARAMETERS.brandGallery,
      },
    },
  },
} as const;
type LightboxOpenedAction = typeof lightboxOpened;

const galleryNavigated = {
  type: 'LANDLORD_GALLERY_PAGINATED',
  payload: {
    meta: {
      analytics: {
        action: PARAMETERS.paginate,
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        sourcePage: PARAMETERS.landlordPage,
        sourceContent: PARAMETERS.lightboxGallery,
      },
    },
  },
} as const;
type GalleryNavigatedAction = typeof galleryNavigated;

const landlordThumbnailClicked = {
  type: 'LANDLORD_THUMBNAIL_CLICKED',
  payload: {
    meta: {
      analytics: {
        action: PARAMETERS.clickThumbnail,
        eventType: EVENT_TYPES.track,
        event: EVENTS.galleryInteraction,
        sourcePage: PARAMETERS.landlordPage,
        sourceContent: PARAMETERS.lightboxGallery,
      },
    },
  },
} as const;
type LandlordThumbnailClickedAction = typeof landlordThumbnailClicked;

type BuildingClickedAction = {
  type: 'LANDLORD_BUILDING_CLICKED';
  payload: {
    meta: {
      analytics: {
        eventType: 'track';
        event: 'Click to Page';
        sourcePage: 'brand page';
        destination: 'building page';
        buildingId: string;
        buildingName: string;
      };
    };
  };
};
const buildingClicked = ({ id, primaryName }): BuildingClickedAction => ({
  type: 'LANDLORD_BUILDING_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.landlordPage,
        destination: PARAMETERS.buildingPage,
        buildingId: id,
        buildingName: primaryName,
      },
    },
  },
});

const savedBuildingOwnerNotificationLinkClicked = {
  type: 'NOTIFICATION_SAVED_BUILDING_OWNER_LINK_CLICKED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourceContent: PARAMETERS.toast,
        destination: PARAMETERS.savedBuildingOwnersPage,
      },
    },
  },
} as const;
type SavedBuildingOwnerNotifLinkClickedAction = typeof savedBuildingOwnerNotificationLinkClicked;

export type Action =
  | LightboxOpenedAction
  | GalleryNavigatedAction
  | LandlordThumbnailClickedAction
  | BuildingClickedAction
  | SavedBuildingOwnerNotifLinkClickedAction;

export {
  lightboxOpened,
  galleryNavigated,
  landlordThumbnailClicked,
  buildingClicked,
  savedBuildingOwnerNotificationLinkClicked,
};
