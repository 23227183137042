const defaultMapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#a2a2a2' }],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  { featureType: 'landscape', elementType: 'geometry.fill', stylers: [{ color: '#f5f5f5' }] },
  { featureType: 'landscape', elementType: 'labels.text.fill', stylers: [{ color: '#a2a2a2' }] },
  { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#e5f1f0' }] },
  { featureType: 'poi', elementType: 'labels.icon', stylers: [{ color: '#bdbdbd' }] },
  { featureType: 'poi', elementType: 'labels.text', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi', elementType: 'labels.text.fill', stylers: [{ color: '#a2a2a2' }] },
  { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.medical', stylers: [{ color: '#ffe4d7' }] },
  { featureType: 'poi.park', stylers: [{ color: '#c7e4e0' }] },
  { featureType: 'poi.park', elementType: 'geometry.fill', stylers: [{ color: '#e5f1f0' }] },
  {
    featureType: 'poi.sports_complex',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#a2a2a2' }],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }],
  },
  { featureType: 'road', elementType: 'geometry.stroke', stylers: [{ visibility: 'off' }] },
  { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#8b8b8b' }] },
  { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#fefcff' }] },
  { featureType: 'transit.station', elementType: 'labels.text', stylers: [{ visibility: 'off' }] },
  { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#e8edf7' }] },
  { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: '#cfdeeb' }] },
  { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#8faccb' }] },
];

export default defaultMapStyles;
