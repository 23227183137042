import { CustomIcon } from '@components/shared';
import cn from 'classnames';
import s from './Spinner.module.less';

type Props = {
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  className?: string;
  iconClassName?: string;
};

const Spinner = ({ size = 'small', className, iconClassName }: Props) => {
  return (
    <span
      data-testid="spinner-icon"
      className={cn(
        {
          [s.spinner__small]: size === 'small',
          [s.spinner__medium]: size === 'medium',
          [s.spinner__large]: size === 'large',
          [s.spinner__xlarge]: size === 'xlarge',
        },
        className,
      )}
    >
      <CustomIcon type="loadingOutlined" className={cn(s['spinner-container'], iconClassName)} />
    </span>
  );
};

export default Spinner;
