import { useTranslation } from 'react-i18next';
import Header from '@components/shared/Header';
import Amenity from '@components/shared/Amenity';
import { BuildingAmenities, ListingAmenities } from '@root/types';
import { filterAndOrderAmenities } from '@shared/amenitiesOrdering';

type Props = {
  amenities: BuildingAmenities | ListingAmenities;
};

const Amenities = ({ amenities: a }: Props) => {
  const { t } = useTranslation('common');
  const amenities = filterAndOrderAmenities(a, t);

  if (amenities.length === 0) return null;
  return (
    <section>
      <Header labelQaTestId="amenities-heading">{t('amenities.title')}</Header>
      <div
        className="grid grid-cols-3 gap-x-2 tablet:grid-cols-2 mobile:grid-cols-2"
        data-qa-testid="amenities-list"
      >
        {amenities.map(amenity => (
          <div className="mb-1" key={amenity.name}>
            <Amenity {...amenity} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Amenities;
