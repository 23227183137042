import { useTranslation } from 'react-i18next';
import { AdminListing } from '@root/types';
import { Formik, Form } from 'formik';
import { FormThemeContextProvider } from '@components/shared/forms/ThemeContext';
import { SubmitButton } from '@components/shared/forms';
import { Prompt } from 'react-router-dom';
import validationSchema from './validationSchema';
import css from './styles.module.less';
import ListingAmenitiesList from './ListingAmenitiesList';

type Props = {
  listing: AdminListing;
  updateListing: (
    listingId: string,
    body: AdminListing & { amenitySet: {} },
    message: string,
    successCallback?: () => void,
  ) => void;
};

const AmenitiesListingForm = ({ listing, updateListing }: Props) => {
  const { t } = useTranslation('admin');

  const initialValues = { ...listing, amenitySet: listing.amenitySet };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={JSON.parse(JSON.stringify(initialValues, (k, v) => (v == null ? '' : v)))}
        onSubmit={async (values, actions) => {
          const params = JSON.parse(JSON.stringify(values, (k, v) => (v === '' ? null : v)));
          await updateListing(listing.id, params, t('listing.updateSuccess'));
          actions.setSubmitting(false);
        }}
        validationSchema={validationSchema(t)}
      >
        {({ dirty, isValid, values }) => (
          <Form className={css.form}>
            <FormThemeContextProvider theme="vertical">
              <section className={css.amenities}>
                <div className="font-title">{t('listing.amenities.title')}</div>
                <div className={css.amenities}>
                  <ListingAmenitiesList
                    breakOutSpace={values.amenitySet.breakOutSpace}
                    furnished={values.amenitySet.furnished}
                    keyCardSpaceEntry={values.amenitySet.keyCardSpaceEntry}
                    passengerElevatorCount={values.amenitySet.passengerElevatorCount}
                    phoneBooths={values.amenitySet.phoneBooths}
                    privateOutdoorSpace={values.amenitySet.privateOutdoorSpace}
                    serviceElevatorCount={values.amenitySet.serviceElevatorCount}
                    sharedPantry={values.amenitySet.sharedPantry}
                  />
                </div>
              </section>
              {dirty ? (
                <div className={css.submitFooter}>
                  <SubmitButton data-testid="submit-button" disabled={!isValid}>
                    {t('common:saveChanges')}
                  </SubmitButton>
                </div>
              ) : null}
            </FormThemeContextProvider>
            <Prompt when={dirty} message={t('common:prompt.unsavedChanges')} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AmenitiesListingForm;
