import useEnv from '@root/shared/useEnv';
import api from '@shared/api';
import {
  UploadApiResponse as CloudinaryResponse,
  UploadApiErrorResponse as CloudinaryErrorResponse,
} from 'cloudinary';
import { useRef, useState } from 'react';
import { MIME_TYPES } from '../Admin/MultimediaUploader';

type SelectAndUploadToCloudinaryArgs = {
  callback?: (response: CloudinaryResponse) => void;
};

type Output = {
  uploadToCloudinary: (file) => void;
  onFileSelect: (file) => void;
  selectAndUploadToCloudinary?: (args?: SelectAndUploadToCloudinaryArgs) => void;
  CloudinaryUploaderFileInput: typeof CloudinaryUploaderFileInput;
  cloudinaryUploaderFileInputProps: CloudinaryUploaderFileInputProps;
  isUploading: boolean;
};

export default function useCloudinaryUploader({
  uploadToVTS = false,
  onReady,
  onFileUpload,
  onError,
}: {
  uploadToVTS?: boolean;
  onReady?: (response: CloudinaryResponse) => void;
  onFileUpload?: (fileUrl: string) => void;
  onError: (message: string) => void;
}): Output {
  const fileInput = useRef<HTMLInputElement>(null);
  const onReadyCallbackRef = useRef<((response: CloudinaryResponse) => void) | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const {
    cloudinaryCloud,
    cloudinaryUploadPreset,
    cloudinaryVtsImageUploadPreset,
    cloudinaryVtsImageCloud,
  } = useEnv();

  const cloudName = uploadToVTS ? cloudinaryVtsImageCloud : cloudinaryCloud;
  const cloudinaryPreset = uploadToVTS ? cloudinaryVtsImageUploadPreset : cloudinaryUploadPreset;

  const uploadToCloudinary = async file => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', cloudinaryPreset || '');

    const route = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
    const response = await api.fetch(route, { method: 'POST', body: formData });
    if (response.ok) {
      const json: CloudinaryResponse = await response.json();
      setIsUploading(false);
      if (onReady) onReady(json);
      if (onReadyCallbackRef.current) onReadyCallbackRef.current(json);
    } else {
      const json: CloudinaryErrorResponse = await response.json();
      setIsUploading(false);
      onError(json.message);
    }
  };

  const onFileSelect = async file => {
    if (onFileUpload) {
      const blobUrl = URL.createObjectURL(file);
      onFileUpload(blobUrl);
    }

    uploadToCloudinary(file);
  };

  return {
    isUploading,
    uploadToCloudinary,
    onFileSelect,
    selectAndUploadToCloudinary: ({ callback }: SelectAndUploadToCloudinaryArgs = {}) => {
      fileInput?.current?.click();
      onReadyCallbackRef.current = callback || null;
    },
    CloudinaryUploaderFileInput,
    cloudinaryUploaderFileInputProps: {
      inputRef: fileInput,
      onFileSelect,
    },
  };
}

type CloudinaryUploaderFileInputProps = {
  inputRef: React.ForwardedRef<HTMLInputElement>;
  onFileSelect: (file) => void;
  mimetypes?: string;
};
const CloudinaryUploaderFileInput = ({
  inputRef,
  onFileSelect,
  mimetypes = MIME_TYPES.imageOnly,
}: CloudinaryUploaderFileInputProps) => {
  return (
    <input
      ref={inputRef}
      type="file"
      className="hidden"
      onChange={async event => {
        const { files } = event.target;
        if (files) {
          onFileSelect(files[0]);
        }
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      }}
      accept={mimetypes}
      data-testid="cloudinaryUploaderFileInput"
    />
  );
};
