import { memo } from 'react';
import cn from 'classnames';
import { IconButton } from '@components/shared';
import s from './ListingSearchFiltersSidebar.module.less';
import ListingSearchFilters from './ListingSearchFilters';

type Props = {
  isCollapsed: boolean;
  toggleSidebar: () => void;
};

const ListingSearchFiltersSidebar = ({ isCollapsed, toggleSidebar }: Props) => {
  return (
    <div className={s.sidebar} data-testid="listing-search-filters-sidebar">
      <IconButton
        className={s.expandCollapseSidebarButton}
        icon={isCollapsed ? 'chevron-right' : 'chevron-left'}
        data-testid="expand-collapse-sidebar-button"
        onClick={toggleSidebar}
      />
      <div className={cn(s.listingSearchFiltersContainer, isCollapsed && s.transparent)}>
        <ListingSearchFilters />
      </div>
    </div>
  );
};

export default memo(ListingSearchFiltersSidebar);
