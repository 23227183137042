import cn from 'classnames';
import { Address } from '@root/types';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/jsx-no-literals
const Separator = () => <span className="inline-block px-1 py-0 text-black-010">|</span>;

// tells us which labels should have preview styling
type PreviewStatus = {
  buildingName?: boolean;
  street?: boolean;
  floorAndSuite?: boolean;
  cityState?: boolean;
  submarket?: boolean;
};

type Props = {
  address: Address;
  neighborhood: string | null;
  directionsButton?: any;
  isPreview?: boolean;
  previewStatus?: PreviewStatus | null;
};

const Title = ({
  address,
  neighborhood = null,
  directionsButton = null,
  isPreview = false,
  previewStatus = {},
}: Props) => {
  const { t } = useTranslation('tourbook');
  const hasBuildingName = !!address.buildingName;

  return (
    <div className="relative">
      {isPreview && <div className="text-aqua-100 font-subtitle">{t('preview')}</div>}
      <div className="flex">
        {directionsButton}
        <h3
          className={cn(
            'font-subtitle',
            hasBuildingName && previewStatus?.buildingName && 'text-black-055',
            !hasBuildingName && previewStatus?.street && 'text-black-055',
          )}
        >
          {address.buildingName || address.street}
        </h3>
      </div>
      <div className="font-body-medium">
        {hasBuildingName && (
          <p
            className={cn(
              'font-body-medium-emphasis',
              previewStatus?.street ? 'text-black-055' : '',
            )}
          >
            {address.street}
          </p>
        )}

        {address.floorAndSuite && (
          <p className={previewStatus?.floorAndSuite ? 'text-black-055' : ''}>
            {address.floorAndSuite}
          </p>
        )}
        <p>
          {neighborhood && (
            <>
              <span className={previewStatus?.submarket ? 'text-black-055' : ''}>
                {neighborhood}
              </span>
              <Separator />
            </>
          )}
          <span className={previewStatus?.cityState ? 'text-black-055' : ''}>
            {t('common:cityLocalityRegion', { address })}
          </span>
          <Separator />
          {t(`country:${address.countryCode}`)}
        </p>
      </div>
    </div>
  );
};

export default Title;
