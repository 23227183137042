import { useTranslation } from 'react-i18next';
import Button from '@components/shared/V2Button';
import s from './BuildingCard.module.less';

type Props = {
  numberOfAvailabilities: number;
};

const BuildingLink = ({ numberOfAvailabilities }: Props) => {
  const { t } = useTranslation('landlord');
  return (
    <div>
      {numberOfAvailabilities === 0 ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <span className={s.buildingLink}>{t`building.viewBuilding`}</span>
      ) : (
        <>
          <Button className="h-auto w-auto min-w-20" size="medium">
            {t('building.numberOfAvailabilities', { count: numberOfAvailabilities })}
          </Button>
        </>
      )}
    </div>
  );
};

export default BuildingLink;
