import { RawInput, RawLabel, RawCheckbox } from '@components/shared/forms';
import useMarket from '@root/shared/useMarket';

import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import actions from '@store/actions/listingSearchPage';
import useEnv from '@root/shared/useEnv';
import { PriceRangeFilter, StoreState } from '@root/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import debounce from 'lodash/debounce';
import { useListingSearchCriteria } from '../../utils';

import s from '../Filters.module.less';
import { currencySymbol } from '../../utils/constants';
import ListingSearchCriteria from '../../utils/Criteria/ListingSearchCriteria';
import matchNumericInput from '../../utils/matchNumericInput';
import stringIsANumber from '../../utils/stringIsANumber';

const debounceLength = 1500;

const getInitialState = (criteria: ListingSearchCriteria): PriceRangeFilter => {
  return {
    minPrice: criteria.currentFilters.minPrice ?? '',
    maxPrice: criteria.currentFilters.maxPrice ?? '',
    excludeNegotiable: criteria.currentFilters.excludeNegotiable ?? false,
  };
};

const PriceFilter = () => {
  const { t } = useTranslation('filters');
  const { priceFilterInteraction } = useFilterInteraction();

  const minPriceRef = useRef<HTMLInputElement | null>(null);
  const maxPriceRef = useRef<HTMLInputElement | null>(null);

  const criteria = useListingSearchCriteria({
    onUrlChange: c => {
      setPriceFilter(getInitialState(c));
    },
  });
  const currentUser = useSelector((state: StoreState) => state.currentUser);
  const { currentMarket } = useMarket();
  const dispatch = useDispatch();
  const flags = useFlags();
  const { locale } = useEnv();
  const [priceFilter, setPriceFilter] = useState<PriceRangeFilter>(getInitialState(criteria));

  const dispatchAnalytics = (
    newPriceFilter: PriceRangeFilter,
    excludeNegotiable: boolean | undefined,
  ) => {
    dispatch(
      actions.priceFilterChanged(
        newPriceFilter.minPrice.length > 0 ? newPriceFilter.minPrice : 'unset',
        newPriceFilter.maxPrice.length > 0 ? newPriceFilter.maxPrice : 'unset',
        !excludeNegotiable,
      ),
    );
  };

  const onPriceNegotiableToggle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.checked;
    if (value === true) {
      criteria.add('excludeNegotiable', true);
    } else {
      criteria.remove('excludeNegotiable');
    }

    if (flags['search-analytics-refactor']) {
      priceFilterInteraction({
        value,
        filterType: 'excludeNegotiable',
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatchAnalytics(priceFilter, criteria.currentFilters.excludeNegotiable);
    }
    criteria.pushToHistory();
  };

  const updateCriteria = (
    newPriceFilter: PriceRangeFilter,
    criteriaObject: ListingSearchCriteria,
    maxOrMin,
  ) => {
    const [currentMinPrice, currentMaxPrice, newMinPrice, newMaxPrice] = [
      Number(criteriaObject.currentFilters.minPrice),
      Number(criteriaObject.currentFilters.maxPrice),
      Number(newPriceFilter.minPrice || undefined),
      Number(newPriceFilter.maxPrice || undefined),
    ];

    let criteriaChanged = false;

    if (!(Number.isNaN(currentMinPrice) && Number.isNaN(newMinPrice))) {
      criteriaChanged ||= currentMinPrice !== newMinPrice;
    }

    if (!(Number.isNaN(currentMaxPrice) && Number.isNaN(newMaxPrice))) {
      criteriaChanged ||= currentMaxPrice !== newMaxPrice;
    }

    criteriaObject.remove('minPrice');
    criteriaObject.remove('maxPrice');

    if (newPriceFilter.minPrice && stringIsANumber(newPriceFilter.minPrice)) {
      criteriaObject.add('minPrice', newPriceFilter.minPrice);
    }

    if (newPriceFilter.maxPrice && stringIsANumber(newPriceFilter.maxPrice)) {
      criteriaObject.add('maxPrice', newPriceFilter.maxPrice);
    }

    if (criteriaChanged) {
      if (flags['search-analytics-refactor']) {
        const value = newPriceFilter[maxOrMin];
        priceFilterInteraction({
          value: value ? Number(value) : null,
          filterType: maxOrMin,
          currentFilters: criteriaObject.toAnalyticsProperties(),
        });
      } else {
        dispatchAnalytics(newPriceFilter, criteriaObject.currentFilters.excludeNegotiable);
      }
    }
    criteriaObject.pushToHistory();
  };

  // FIXME: Either add the exhaustive deps or delete this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateCriteria = useMemo(() => debounce(updateCriteria, debounceLength), []);

  const handleKeyDown = (event, newFilter, maxOrMin) => {
    if (event.key === 'Enter') {
      updateCriteria(newFilter, criteria, maxOrMin);
    }
  };

  const handleBlur = maxOrMin => {
    debouncedUpdateCriteria.cancel();
    updateCriteria(priceFilter, criteria, maxOrMin);
  };

  const onInputChanged = (priceValue: string, minOrMax: 'minPrice' | 'maxPrice') => {
    const validPriceValue = matchNumericInput(priceValue);
    if (validPriceValue === null || validPriceValue.length > 10) {
      return;
    }
    setPriceFilter(oldPriceFilter => {
      const newPriceFilter = {
        ...oldPriceFilter,
        [minOrMax]: validPriceValue,
      };
      debouncedUpdateCriteria.cancel();
      debouncedUpdateCriteria(newPriceFilter, criteria, minOrMax);
      return newPriceFilter;
    });
  };

  const currency = currencySymbol(currentMarket, currentUser, locale, true);

  return (
    <>
      <div className={s.rangeContainer}>
        <div className={s.rangeInputContainer}>
          <RawLabel className={s.label} name="Min Price" tag="div">
            {t('price.minPriceLabel')}
          </RawLabel>
          <RawInput
            ref={minPriceRef}
            className={s.input}
            name="Min Price"
            ariaLabel={t('price.minPriceLabel')}
            value={priceFilter.minPrice}
            onChange={event => {
              onInputChanged(event.currentTarget.value, 'minPrice');
            }}
            onKeyDown={e => {
              handleKeyDown(
                e,
                { ...priceFilter, minPrice: minPriceRef.current?.value },
                'minPrice',
              );
            }}
            onBlur={() => handleBlur('minPrice')}
            units={t('price.sf/yr')}
            currencyValue={currency}
          />
        </div>
        <div className={s.rangeInputContainer}>
          <RawLabel className={s.label} name="Max Price" tag="div">
            {t('price.maxPriceLabel')}
          </RawLabel>
          <RawInput
            ref={maxPriceRef}
            className={s.input}
            name="Max Price"
            ariaLabel={t('price.maxPriceLabel')}
            value={priceFilter.maxPrice}
            onChange={event => {
              onInputChanged(event.currentTarget.value, 'maxPrice');
            }}
            onKeyDown={e => {
              handleKeyDown(
                e,
                { ...priceFilter, maxPrice: maxPriceRef.current?.value },
                'maxPrice',
              );
            }}
            onBlur={() => handleBlur('maxPrice')}
            units={t('price.sf/yr')}
            currencyValue={currency}
          />
        </div>
      </div>
      <div className={s.excludeNegotiable}>
        <RawCheckbox
          checked={!!criteria.currentFilters.excludeNegotiable}
          name="excludeNegotiable"
          onChange={onPriceNegotiableToggle}
          size="small"
        >
          {t('price.excludeNegotiable')}
        </RawCheckbox>
      </div>
    </>
  );
};

export default React.memo(PriceFilter);
