import LogoSvgWhite from './vts-marketplace-logo-white.svg';
import LogoSvgShortBlack from './vts-marketplace-logo-short-black.svg';
import LogoSvgBlack from './vts-marketplace-logo-black.svg';
import s from './Logo.module.less';

/**
 * 'white': White vts logo with 'marketplace' text.
 * 'black': Black vts logo with 'marketplace' text.
 * 'short-black': Just the black vts logo.
 */
type Props = {
  mode?: 'white' | 'short-black' | 'black';
};

export default function Logo({ mode = 'white' }: Props) {
  let logo = <LogoSvgWhite fill="currentColor" />;

  switch (mode) {
    case 'short-black':
      logo = <LogoSvgShortBlack fill="currentColor" />;
      break;
    case 'black':
      logo = <LogoSvgBlack fill="currentColor" />;
      break;
    case 'white':
      logo = <LogoSvgWhite fill="currentColor" />;
      break;
    default:
      logo = <LogoSvgWhite fill="currentColor" />;
      break;
  }
  return (
    <>
      <figure className={s.logoContainer}>{logo}</figure>
      <figure className={s.mobileLogoContainer}>{logo} </figure>
    </>
  );
}
