import { useState } from 'react';
import cn from 'classnames';
import ColorPicker from '@components/shared/ColorPicker';
import { FastField, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { FieldWrapper } from '@components/shared/forms';
import { CustomIcon } from '@components/shared';
import { ColorGroup } from '@components/shared/ColorPicker/ColorPickerColorGroup';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  name: string;
  labelText: string;
  labelClass?: string;
  required?: boolean;
  onChange?: (color: string, prevColor: string) => void;
  'data-testid'?: string;
  hasTransparentOption?: boolean;
  colorGroups?: ColorGroup[];
  tooltipContent?: string;
};
export default function ColorPickerAccordion({
  name,
  labelText,
  labelClass,
  required,
  onChange = () => {},
  'data-testid': dataTestId,
  hasTransparentOption = false,
  colorGroups,
  tooltipContent,
}: Props) {
  const flags = useFlags();
  const FieldComponent = flags['market.microsite-performance-optimizations'] ? FastField : Field;
  const [open, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);
  const { t } = useTranslation('admin');

  return (
    <FieldWrapper
      {...{
        name,
        required,
        containerClass: '!mb-0',
        'data-testid': dataTestId,
        labelText,
        labelClass,
        tooltipContent,
      }}
    >
      <FieldComponent name={name}>
        {({ field, form }) => (
          <div className="rounded-[3px] border-[1px] border-solid border-black-020 bg-white p-1">
            <button
              className={cn('flex w-full items-center justify-between ', open && 'mb-1')}
              type="button"
              onClick={open ? onClose : onOpen}
            >
              <div className="flex items-center gap-1">
                <div
                  className={cn(
                    'relative h-3 w-3 rounded-[32px] border border-solid border-black-020',
                    field.value === 'transparent' &&
                      "after:absolute after:bottom-0 after:left-[9px] after:top-0 after:h-[calc(100%_+_1px)] after:w-0.5 after:rotate-45 after:bg-dataviz-critical-2 after:content-['']",
                  )}
                  style={{ backgroundColor: field.value }}
                />
                <span className="font-body-medium">
                  {t(
                    field.value === 'transparent'
                      ? 'common:transparent'
                      : 'microsite.hexColorValue',
                    {
                      value: field.value,
                    },
                  )}
                </span>
              </div>
              <CustomIcon type={open ? 'chevron-up' : 'chevron-down'} />
            </button>
            <div
              className={cn('overflow-hidden transition-all', open ? 'max-h-[424px]' : 'max-h-0')}
            >
              <ColorPicker
                color={field.value}
                name={name}
                onChange={color => {
                  const prevColor = field.value;
                  field.onChange({ target: { name, value: color } });
                  form.setFieldTouched(name, true);
                  onChange(color, prevColor);
                }}
                showTransparencyOption
                transparencyDisabled={!hasTransparentOption}
                colorGroups={colorGroups}
              />
            </div>
          </div>
        )}
      </FieldComponent>
    </FieldWrapper>
  );
}
