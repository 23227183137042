/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useState, useEffect } from 'react';
import { Address, MultipathImage, TourbookListingAnalyticsInformation } from '@root/types';
import { range } from 'lodash';
import { IconButton } from '@components/shared';
import useToggle from '@shared/useToggle';
import useAnalytics from '@shared/useAnalytics';
import cn from 'classnames';
import useAltText from '@root/shared/useAltText';
import s from '../FullWidthLightbox.module.less';

type Props = {
  assets: Array<MultipathImage>;
  currentIndex: number;
  onSelect: (index: number) => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  isTabletDisplay: boolean;
  isMobileDisplay: boolean;
  sourcePage: string;
  sourceContent: string;
  address: Address;
  companyName?: string;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
};

type ImageWithPosition = MultipathImage & { position: number };

const getSelectedImageDescription = (
  currentImage: MultipathImage | undefined,
): string | undefined => {
  if (!currentImage) return undefined;
  const { description } = currentImage;
  if (['Listing image', 'Static Map', 'Building image', 'Floor plan'].includes(description)) {
    return undefined;
  }

  return description;
};

const getFirstRowImages = (
  displayableAssets: ImageWithPosition[],
  indices: number[],
  countOfAssetsInCollapsedRow: number,
): ImageWithPosition[] => {
  if (displayableAssets.length <= countOfAssetsInCollapsedRow) return displayableAssets;

  return indices.map(index => {
    return displayableAssets.find(image => image.position === index)!;
  });
};

const getInitialIndices = (
  currentIndex: number,
  displayableAssetPositions: number[],
  countOfAssetsInCollapsedRow: number,
): number[] => {
  if (displayableAssetPositions.length < countOfAssetsInCollapsedRow) {
    return range(0, displayableAssetPositions.length);
  }
  if (
    currentIndex < countOfAssetsInCollapsedRow ||
    !displayableAssetPositions.includes(currentIndex)
  ) {
    return range(countOfAssetsInCollapsedRow);
  }

  return range(currentIndex - (countOfAssetsInCollapsedRow - 1), currentIndex + 1);
};

export default function ImageThumbnails({
  assets,
  currentIndex,
  onSelect,
  onMouseOver = () => null,
  onMouseLeave = () => null,
  isTabletDisplay,
  isMobileDisplay,
  sourcePage,
  sourceContent,
  address,
  companyName,
  analyticsInformation,
}: Props) {
  const { getAltText } = useAltText({ address });

  let countOfAssetsInCollapsedRow = 9;
  if (isTabletDisplay) {
    countOfAssetsInCollapsedRow = 5;
  }

  if (isMobileDisplay) {
    countOfAssetsInCollapsedRow = 999;
  }
  const lessThanSixAssets = assets?.length <= 5;
  const { value: expanded, toggle: toggleExpanded } = useToggle(false);
  const { galleryInteraction, PARAMETERS } = useAnalytics();

  const images: ImageWithPosition[] = assets.map((img, index) => ({
    ...img,
    position: index,
  }));

  const displayableAssets = images.filter(
    image => !['staticMap', 'floorPlan'].includes(image.type),
  );
  const displayableAssetPositions = displayableAssets.map(i => i.position);

  const [visibleIndices, setVisibleIndices] = useState(
    getInitialIndices(currentIndex, displayableAssetPositions, countOfAssetsInCollapsedRow),
  );

  const displayedImages = expanded
    ? displayableAssets
    : getFirstRowImages(displayableAssets, visibleIndices, countOfAssetsInCollapsedRow);

  useEffect(() => {
    if (visibleIndices.includes(currentIndex) || !displayableAssetPositions.includes(currentIndex))
      return;
    if (currentIndex < visibleIndices[0]) {
      setVisibleIndices(range(currentIndex, currentIndex + countOfAssetsInCollapsedRow));
    } else {
      setVisibleIndices(range(currentIndex - countOfAssetsInCollapsedRow + 1, currentIndex + 1));
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const selectedImageDescription: string | undefined = getSelectedImageDescription(
    assets[currentIndex],
  );

  const DisplayImage = ({ cloudinaryId, position, smallPath, altText }) => {
    const isSelectedImage = currentIndex === position;

    return (
      <button
        key={cloudinaryId}
        className={s.thumbnail}
        type="button"
        onClick={() => onSelect(position)}
      >
        <img
          src={smallPath}
          alt={altText}
          className={cn(s.imageThumbnailButton, isSelectedImage && s.selectedImageThumbnailButton)}
        />
      </button>
    );
  };

  const handleToggleThumbnails = () => {
    toggleExpanded();
    galleryInteraction({
      sourcePage,
      sourceContent,
      action: PARAMETERS.allThumbnails,
      actionType: 'GALLERY_ALL_THUMBNAILS_TOGGLED',
      analyticsInformation,
    });
  };

  return (
    <section className={s.imageThumbnailsSection}>
      {selectedImageDescription ? (
        <p className={s.photoDescription}>{selectedImageDescription}</p>
      ) : null}
      <div
        data-testid="imageThumbnailsContainer"
        className={cn(s.imageThumbnailsContainer, expanded && s.expanded)}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={cn(s.imageThumbnails, lessThanSixAssets && isTabletDisplay && s.justifyLeft)}
        >
          {displayedImages.map(image => {
            return (
              <DisplayImage
                key={image.cloudinaryId}
                cloudinaryId={image.cloudinaryId}
                position={image.position}
                smallPath={image.smallPath}
                altText={getAltText({
                  type: image.type,
                  options: { roomName: image.description, companyName },
                })}
              />
            );
          })}
        </div>
        {displayableAssets.length > countOfAssetsInCollapsedRow ? (
          <IconButton
            data-testid="seeMoreThumbnailsButton"
            icon={expanded ? 'chevron-up' : 'chevron-down'}
            className={cn(s.seeMoreThumbnailsButton, expanded && s.expanded)}
            onClick={handleToggleThumbnails}
          />
        ) : null}
      </div>
    </section>
  );
}
