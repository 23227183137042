import { useTranslation } from 'react-i18next';
import css from '../styles.module.less';

type ChannelsHeaderProps = {
  channel: 'website' | 'brochure' | 'microsite' | 'siteplan';
};

const ChannelsHeader = ({ channel }: ChannelsHeaderProps) => {
  const { t } = useTranslation('admin');

  const translationMap = {
    brochure: t(`building.channels.brochureTitle`),
    microsite: t('building.channels.micrositeTitle'),
    siteplan: t('building.channels.sitemapTitle'),
    website: t('building.channels.websiteTile'),
  };

  return (
    <>
      <h2>{translationMap[channel]}</h2>
      <p className={css.fakeLabel}>{t('building.basicInfo.url')}</p>
    </>
  );
};

export default ChannelsHeader;
