import { useState } from 'react';
import * as React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';
import s from './TextAreaAutosize.module.less';

type Props = {
  placeholder?: string;
  className?: string;
  onBlur?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  showCharacterCount?: boolean;
  maxLength?: number;
  disabled?: boolean;
  minRows?: number;
  maxRows?: number;
  autoFocus?: boolean;
  inputTestId?: string;
} & Omit<FieldWrapperProps, 'children'>;

const TextAreaAutosize = ({
  name,
  labelClass,
  containerClass,
  labelText,
  placeholder,
  required,
  className,
  onBlur,
  onFocus,
  onChange,
  value,
  showCharacterCount,
  maxLength,
  disabled,
  minRows,
  maxRows,
  inputTestId,
  labelQaTestId,
  // autoFocus is generally discouraged; use with caution
  // See: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/no-autofocus.md
  autoFocus,
}: Props) => {
  const [length, setLength] = useState(value?.length || 0);
  const countLength = event => {
    setLength(event.target.value.length);
  };
  return (
    <FieldWrapper {...{ name, labelClass, containerClass, labelText, required }}>
      <Field name={name}>
        {({ field, meta, form }) => (
          <>
            <TextareaAutosize
              data-testid={inputTestId}
              {...field}
              aria-label={name}
              placeholder={placeholder}
              className={classNames(s.textarea, meta.error && meta.touched && s.error, className)}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={event => {
                if (onChange) onChange(event);
                form.setFieldTouched(name, true);
                field.onChange(event);
              }}
              value={value || field.value}
              onKeyUp={countLength}
              disabled={disabled}
              minRows={minRows}
              maxRows={maxRows}
              autoFocus={autoFocus}
              data-qa-testid={labelQaTestId}
            />
            {showCharacterCount && maxLength && (
              <div className={s.characterCount}>
                {/* // FIXME: Remove this line because its a <Trans /> or remove this literal */}
                {/*   eslint-disable-next-line react/jsx-no-literals */}
                {length} / {maxLength}
              </div>
            )}
          </>
        )}
      </Field>
    </FieldWrapper>
  );
};

export default TextAreaAutosize;
