import cn from 'classnames';
import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SearchListing } from '@root/types';
import s from '../ListingCard.module.less';

type LeaseInfoContainerProps = {
  listing: SearchListing;
  portrait: boolean;
  isBuildingPage: boolean;
  priceAvailable: boolean;
};

const LeaseInfoContainer = ({
  listing,
  portrait,
  isBuildingPage,
  priceAvailable,
}: LeaseInfoContainerProps) => {
  const { t } = useTranslation('listing');
  const { availableToday, dateAvailable, smallestPrice, status } = listing;

  const getAvailibilityMessage = () => {
    if (status === 'on_market' && availableToday) {
      return t('search.availableNow');
    }

    if (!dateAvailable || status !== 'on_market') {
      return t('search.notAvailable');
    }

    const isAfterToday = isAfter(new Date(dateAvailable), new Date());

    return isAfterToday ? t('availableBy', { date: dateAvailable }) : t('search.availableNow');
  };

  return (
    <div
      className={cn(
        { [s.portrait]: portrait, [s.buildingPage]: isBuildingPage },
        s.leaseInfoContainer,
      )}
    >
      <div
        className={cn(
          { [s.portrait]: portrait, [s.buildingPage]: isBuildingPage },
          s.smallestPriceContainer,
        )}
      >
        <span className={s.from}>{priceAvailable ? t('search.from') : null} </span>
        <span className={s.smallestPrice}>
          {priceAvailable
            ? `${t('units:pricePerAreaPerPeriod', {
                pricePerAreaPerPeriod: smallestPrice,
              })}`
            : null}
        </span>
      </div>
      <div
        className={cn({ [s.portrait]: portrait, [s.buildingPage]: isBuildingPage }, [
          (dateAvailable && status === 'on_market') || availableToday
            ? s.availableDate
            : s.unavailableDate,
        ])}
      >
        {getAvailibilityMessage()}
      </div>
    </div>
  );
};

export default LeaseInfoContainer;
