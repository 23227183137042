import { StoreState, ConvertableMoneyPerAreaPerPeriod, Currency } from '@root/types';
import { useTranslation } from 'react-i18next';
import { getTourbookById } from '@store/selectors';
import tourbooksActions from '@store/actions/tourbooks';
import { Button } from '@components/shared';
import { TourbookUpdatedAttributes } from '@root/store/actions/tourbooks/updateTourbookListing';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import { CurrencyInput, Form, RawInputRange, SubmitButton, Toggle } from '@components/shared/forms';
import cn from 'classnames';
import useAnalytics from '@root/shared/useAnalytics';
import { boolean, number, object } from 'yup';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import ListingAttribute from '../ListingAttribute';
import s from './AskingRent.module.less';
import { formatAskingRentInMoneyPerAreaPerPeriod } from '../../helper';

const mapState = (state: StoreState, ownProps) => ({
  tourbook: getTourbookById(state, ownProps.tourbookId),
});

const connector = connect(mapState);
type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  tourbookId: string;
  tourbookListingId: string;
  listingAskingRentInMoneyPerAreaPerPeriod?: ConvertableMoneyPerAreaPerPeriod | null;
  minAskingRentInMoneyPerAreaPerPeriod?: ConvertableMoneyPerAreaPerPeriod | null;
  maxAskingRentInMoneyPerAreaPerPeriod?: ConvertableMoneyPerAreaPerPeriod | null;
  currencyCode: Currency;
  stopEditing: () => void;
  isExternal?: boolean;
};

type AskingRentFormValues = {
  min?: number | '' | undefined;
  max?: number | '' | undefined;
  isRange: boolean;
};

const AskingRentEditForm = ({
  tourbookId,
  tourbook,
  tourbookListingId,
  listingAskingRentInMoneyPerAreaPerPeriod,
  minAskingRentInMoneyPerAreaPerPeriod,
  maxAskingRentInMoneyPerAreaPerPeriod,
  currencyCode,
  stopEditing,
  isExternal = false,
}: Props & ReduxProps) => {
  const { t } = useTranslation('tourbook');
  const dispatch = useDispatch();
  const { tourbookInteraction, PARAMETERS } = useAnalytics();

  const originalAskingRent = tourbook?.listings.find(listing => {
    return listing.type === 'listing' && listing.tourbookListingId === tourbookListingId;
  });

  const handleSubmit = async ({ min, max, isRange }: AskingRentFormValues) => {
    let updatedAttributes = {
      minAskingRent:
        !min && (!isRange || !max)
          ? null
          : formatAskingRentInMoneyPerAreaPerPeriod(min || 0, 'sf/yr', null, currencyCode),
      maxAskingRent:
        max === min || !isRange || !max
          ? null
          : formatAskingRentInMoneyPerAreaPerPeriod(max, 'sf/yr', null, currencyCode),
      askingRentUnits: undefined, // TODO: Remove this when 'external-listing-asking-rent-range'.
    } as TourbookUpdatedAttributes;

    // TODO: Eventually we want to remove external_listings reliance on asking_rent_units
    // since it's not needed as the asking_rents /min/max are stored with the units already.
    // Remove as part of removing 'external-listing-asking-rent-range'.
    if (isExternal) {
      updatedAttributes = { ...updatedAttributes, askingRentUnits: `${currencyCode}/sf/yr` };
    }

    dispatch(
      tourbooksActions.updateTourbookListing({
        tourbookId,
        tourbookListingId,
        updatedAttributes,
        onSuccess: () => {
          stopEditing();
          tourbookInteraction({
            action: PARAMETERS.saveRentOverride,
            actionType: 'TOURBOOK_SAVE_RENT_OVERRIDE',
            sourceContent: PARAMETERS.tourbookListing,
            otherAttributes: {
              askingRentListing: listingAskingRentInMoneyPerAreaPerPeriod
                ? listingAskingRentInMoneyPerAreaPerPeriod.magnitude
                : null,
              askingRentOverride: isRange ? `${min || 0}-${max}` : min,
              askingRentUnits: `${currencyCode} / sf/yr`, // For some reason this contains spaces.
              listingId: tourbookListingId,
              askingRentRange: isRange,
            },
          });
        },
      }),
    );
  };

  const AskingRentField = () => {
    const { values, errors } = useFormikContext<AskingRentFormValues>();
    return (
      <ListingAttribute
        label={t('listingCard.baseRentTitle')}
        value={
          <div>
            {values.isRange ? (
              <RawInputRange
                hasError={!!errors.min}
                dividerClass={s.inputRangeDivider}
                renderFirstInput={props => (
                  <CurrencyInput
                    {...props}
                    name="min"
                    placeholder={t('listingCard.askingRent.min')}
                    decimal={false}
                    containerClass={s.rangeInputContainer}
                    inputContainerClass={s.inputContainer}
                    inputClass={s.minInput}
                    displayError={false}
                  />
                )}
                renderSecondInput={props => (
                  <CurrencyInput
                    {...props}
                    name="max"
                    placeholder={t('listingCard.askingRent.max')}
                    decimal={false}
                    displayUnit="sf/yr"
                    showCurrencyUnit={false}
                    containerClass={s.rangeInputContainer}
                    inputContainerClass={s.inputContainer}
                    inputClass={s.maxInput}
                    unitsClass={s.units}
                  />
                )}
              />
            ) : (
              <CurrencyInput
                currency={currencyCode}
                displayUnit="sf/yr"
                name="min"
                decimal={false}
                containerClass={s.nonRangeInputContainer}
                inputContainerClass={cn(s.inputContainer, !!errors.min && s.error)}
                unitsClass={s.units}
                displayError={false}
              />
            )}
            {!!errors.min && <div className={s.error}>{errors.min}</div>}
            <div className={s.toggleContainer}>
              <Toggle name="isRange" size="large" />

              <span className={s.enterRangeText}>{t('listingCard.askingRent.enterRange')}</span>
            </div>
            <div className={s.buttonsWrapper}>
              <Button
                type="secondary"
                size="small"
                onMouseDown={stopEditing}
                onClick={stopEditing}
                className={s.cancelButton}
              >
                {t('listingCard.askingRent.cancel')}
              </Button>
              <SubmitButton size="small" type="primary" className={s.saveButton} hideSubmitting>
                {t('listingCard.askingRent.save')}
              </SubmitButton>
            </div>
          </div>
        }
        labelClassname={s.editableListingAttributeLabel}
      />
    );
  };

  const initialMin =
    minAskingRentInMoneyPerAreaPerPeriod?.magnitude ??
    originalAskingRent?.askingRentInMoneyPerAreaPerPeriod?.magnitude;
  const initialMax = maxAskingRentInMoneyPerAreaPerPeriod?.magnitude;

  return (
    <Form
      id={tourbookListingId}
      initialValues={{
        min: initialMin ? Math.round(initialMin) : initialMin,
        max: initialMax ? Math.round(initialMax) : initialMax,
        isRange: !!maxAskingRentInMoneyPerAreaPerPeriod?.magnitude,
      }}
      onSubmit={handleSubmit}
      validationSchema={object({
        isRange: boolean().defined(),
        min: number()
          .nullable()
          // @ts-ignore: Ignore, this is the right way to use 2+ conditions.
          .when(['max', 'isRange'], (max, isRange, schema) => {
            return _.isNumber(max) && isRange
              ? schema.max(max, t('listingCard.askingRent.errors.invalidRangeMessage'))
              : schema;
          }),
        max: number().nullable(),
      })}
    >
      <AskingRentField />
    </Form>
  );
};

export default connector(AskingRentEditForm);
