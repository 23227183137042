import { useSelector } from 'react-redux';
import { StoreState, UserRole, ChangeableUserRole } from '@root/types';

const useEnums = () => {
  const enums = useSelector((state: StoreState) => state.enums);
  const allRoles: UserRole[] = [
    'unknown',
    'agency_broker',
    'agency_and_tenant_rep_broker',
    'landlord',
    'tenant_rep_broker',
    'admin',
  ];

  const changeableUserRoles: ChangeableUserRole[] = [
    'agency_broker',
    'agency_and_tenant_rep_broker',
    'landlord',
    'tenant_rep_broker',
  ];

  return {
    ...enums,
    roles: allRoles,
    changableRoles: changeableUserRoles,
  };
};

export default useEnums;
