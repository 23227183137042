import { connect, ConnectedProps, useDispatch } from 'react-redux';
import UtmAwareLink from '@components/shared/UtmAwareLink/UtmAwareLink';
import { useTranslation, Trans } from 'react-i18next';
import { useBreakpoint, useCustomBreakpoint } from '@shared/useBreakpoints';
import { CurrentUser, SearchListing, StoreState } from '@root/types';
import { getCurrentPageType } from '@store/selectors';
import { Notification, IconButton, ErrorBoundary } from '@components/shared';
import cn from 'classnames';
import actions from '@store/actions/listingSearchPage';
import routes from '@root/routes';
import { PARAMETERS } from '@root/tracking/constants';
import { Tooltip } from 'antd';
import useTourbookIconButton from '@root/shared/useTourbookIconButton';
import { useRef } from 'react';
import useToggleListingSaveMutation from '@root/shared/useToggleListingSaveMutation';
import useQuickInquirySettings from './useQuickInquirySettings';
import { LeaseInfoContainer, ListingInfo, PhotoContainer } from './utils';
import s from './ListingCard.module.less';

const cardOrientation: { [k: string]: 'portrait' | 'landscape' } = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

const config = {
  [cardOrientation.PORTRAIT]: 0,
  [cardOrientation.LANDSCAPE]: 575,
};

const buildingPageConfig = {
  [cardOrientation.PORTRAIT]: 0,
  [cardOrientation.LANDSCAPE]: 720,
};

const mapState = (state: StoreState) => ({
  page: getCurrentPageType(state),
});

const connector = connect(mapState);
export type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  listing: SearchListing;
  onClick?: (e: React.MouseEvent) => void;
  onHover?: () => void;
  onHoverExit?: () => void;
  openListingPreviewView?: () => void;
  onToggleTourbookDialog?: (isOpen: boolean) => void;
  onToggleQuickInquiryDialog?: (isOpen: boolean) => void;
  onToggleFloorplanPreview?: (isOpen: boolean) => void;
  loggedInUser?: CurrentUser | null;
  showSaveAction?: boolean;
  showAddToTourBookAction?: boolean;
  istourbookCreateOnly?: boolean;
  showBrochureCol?: boolean;
  noQuickInquiry?: boolean;
  containerHeight?: number;
  isSaved: boolean;
};

const RawListingCard = ({
  listing,
  onClick = () => {},
  onHover = () => {},
  onHoverExit = () => {},
  openListingPreviewView = () => {},
  loggedInUser = null,
  showSaveAction = true,
  showAddToTourBookAction = true,
  istourbookCreateOnly = false,
  showBrochureCol = false,
  noQuickInquiry = false,
  onToggleFloorplanPreview,
  onToggleTourbookDialog,
  onToggleQuickInquiryDialog,
  page,
  isSaved,
}: Props & ReduxProps) => {
  const { t } = useTranslation('listing');
  const { size, name, id, buildingSlug, address, status, smallestPrice, shortestLeaseTerm } =
    listing;
  const priceAvailable = !!(status === 'on_market' && smallestPrice && shortestLeaseTerm && size);

  const dispatch = useDispatch();
  const [isHomepage, isBuildingPage] = [page === 'HOMEPAGE', page === 'BUILDING'];
  const breakpoint = useCustomBreakpoint(isBuildingPage ? buildingPageConfig : config);
  const { isMobile } = useBreakpoint();
  const portraitCard = breakpoint === cardOrientation.PORTRAIT || isHomepage;

  const addStyles = (...args: string[]) =>
    cn({ [s.portrait]: portraitCard, [s.buildingPage]: isBuildingPage }, ...args);

  const savedListingLinkClicked = () => {
    dispatch(actions.savedListingNotificationLinkClicked());
  };

  const getAnalyticsInformation = () => {
    let sourcePage: string = PARAMETERS.searchResultsPage;
    let sourceContent: string = PARAMETERS.listingCard;

    if (isHomepage) {
      sourcePage = PARAMETERS.homepage;
    }

    if (isBuildingPage) {
      sourcePage = PARAMETERS.buildingPage;
      sourceContent = PARAMETERS.onMarketListings;
    }
    return {
      sourcePage,
      sourceContent,
      ...listing.analyticsInformation,
    };
  };

  const handleSuccessfulSaveListing = () => {
    Notification.saveSuccess({
      title: t('listingSavedToFavorites'),
      /* eslint-disable react/jsx-no-literals */
      text: (
        <Trans i18nKey="viewInFavorites" ns="listing">
          View it in your
          <a href="/saved/listings" onClick={savedListingLinkClicked}>
            saved listings
          </a>
          .
        </Trans>
      ),
      /* eslint-enable react/jsx-no-literals */
    });
  };

  const { toggleSaveListing } = useToggleListingSaveMutation({
    listing,
    onSuccessfulSave: handleSuccessfulSaveListing,
    analyticsInformation: getAnalyticsInformation(),
    isSaved,
  });

  const listingCardRef = useRef<HTMLElement>(null);
  const calculateCorrectedTop = height => {
    const containerHeight = document.getElementById('listing-cards-container')?.scrollHeight || 0;
    let offset = portraitCard ? 48 : 8; // the default offset of the overlay
    const topPosition = listingCardRef?.current?.offsetTop || 0;
    if (!topPosition || !containerHeight || topPosition + offset + height < containerHeight) {
      return undefined; // there's enough room - no need to overide the "top"
    }

    // there's not enough room - shift the overlay up so that the bottom is above the buttons
    offset = portraitCard ? 3 : 42;
    return offset - height;
  };

  const {
    QuickInquiryIconButton,
    quickInquiryButtonProps,
    QuickInquiryOverlay,
    quickInquiryOverlayProps,
    quickInquiryDialogIsOpen,
  } = useQuickInquirySettings({
    listing,
    trigger: 'hover',
    onToggleQuickInquiryDialog,
    calculateCorrectedTop,
  });

  const {
    TourbookIconButton,
    tourbookButtonProps,
    TourbookOverlay,
    tourbookOverlayProps,
    tourbookDialogIsOpen,
  } = useTourbookIconButton({
    listing,
    createOnly: istourbookCreateOnly,
    useInvisibleMask: true,
    onToggleTourbookDialog,
    calculateCorrectedTop,
  });

  const saveListingButtonOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    toggleSaveListing();
  };

  return (
    <article className={s.container} ref={listingCardRef}>
      <ErrorBoundary>
        <UtmAwareLink
          aria-label={`${address.buildingName || address.street} ${name}`}
          onClick={onClick}
          onMouseEnter={onHover}
          onMouseLeave={onHoverExit}
          className={s.link}
          to={routes.listing(id, buildingSlug)}
          dataTestid={`listingCard-${id}`}
        >
          <div className={addStyles(s.listingCard)} data-qa-testid="view-listing-card">
            <PhotoContainer
              listing={listing}
              enableListingPreviewButton={!!loggedInUser && !isMobile && !isHomepage}
              openListingPreviewView={openListingPreviewView}
              portrait={portraitCard}
              isBuildingPage={isBuildingPage}
              isHomepage={isHomepage}
              onToggleFloorplanPreview={onToggleFloorplanPreview}
            />
            <ListingInfo
              listing={listing}
              portrait={portraitCard}
              isBuildingPage={isBuildingPage}
              showBrochureCol={showBrochureCol}
            />
            <div className={addStyles(s.availabilityPriceWrapper)}>
              {loggedInUser && (
                <div className={addStyles(s.actionButtons)}>
                  {showSaveAction ? (
                    <Tooltip
                      trigger={['hover', 'click']}
                      placement="bottom"
                      title={t('saveTooltip')}
                    >
                      <span>
                        <IconButton
                          className={cn(
                            isSaved && s.filled,
                            'focus:border-2',
                            'focus:border-indigo-90',
                          )}
                          icon={isSaved ? 'heart' : 'heart-outline'}
                          onClick={saveListingButtonOnClick}
                          wrapWithFunction={false}
                          data-testid={
                            isSaved
                              ? 'favorite-listing-button-saved'
                              : 'favorite-listing-button-unsaved'
                          }
                        />
                      </span>
                    </Tooltip>
                  ) : null}
                  {showAddToTourBookAction ? <TourbookIconButton {...tourbookButtonProps} /> : null}
                  {!noQuickInquiry ? <QuickInquiryIconButton {...quickInquiryButtonProps} /> : null}
                </div>
              )}
              {isHomepage || (isBuildingPage && !portraitCard) || priceAvailable ? (
                <LeaseInfoContainer
                  listing={listing}
                  portrait={portraitCard}
                  isBuildingPage={isBuildingPage}
                  priceAvailable={priceAvailable}
                />
              ) : null}
            </div>
          </div>
        </UtmAwareLink>

        {quickInquiryDialogIsOpen ? <QuickInquiryOverlay {...quickInquiryOverlayProps} /> : null}
        {tourbookDialogIsOpen ? (
          <TourbookOverlay {...tourbookOverlayProps} className={addStyles(s.overlay)} />
        ) : null}
      </ErrorBoundary>
    </article>
  );
};

export default connector(RawListingCard);
