import { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Table, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import addKeyTo from '@shared/addKeyTo';
import Button from '@components/shared/V2Button';
import Container from '@components/shared/Admin/Container';
import { useBreadcrumbs } from '@components/shared/withBreadcrumbs';
import PaginationRow from '@components/shared/PaginationRow';
import useIndexPage from '@shared/useIndexPage';
import s from './IndexPage.module.less';
import Layout from '../../Layout/Layout';

export const IndexPage = () => {
  const { t } = useTranslation('admin');

  const history = useHistory();
  const {
    models: companies,
    isFetching,
    query,
    setQuery,
    submitQuery,
    pagination,
    changePage,
  } = useIndexPage({
    modelsKey: 'companies',
    apiBaseRoute: routes.api.admin.companiesPlain,
    baseRoute: routes.admin.companies,
  });

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([t('breadcrumbs.admin'), t('breadcrumbs.companies')]);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { title: t('companies.nameColumnHeader'), dataIndex: 'name' },
    {
      title: t('companies.logoColumnHeader'),
      dataIndex: 'logo',
      render: logo => (
        <figure className={s.thumbnail}>
          <img src={logo.path} alt={logo.description} />
        </figure>
      ),
    },
    {
      title: t('companies.actionsColumnHeader'),
      dataIndex: 'id',
      render: id => (
        <>
          <Link to={routes.admin.company(id)}>{t('companies.editCompany')}</Link>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Container
        title={t('companies.title')}
        extra={
          <Button onClick={() => history.push(routes.admin.companyCreate)}>
            {t('companies.addCompanyButton')}
          </Button>
        }
      >
        <Form onSubmit={submitQuery}>
          <Row>
            <Form.Item>
              <Input
                placeholder={t('companies.searchPlaceholder')}
                style={{ width: 200, marginRight: 20 }}
                name="user-search"
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
              <Button onClick={submitQuery} icon="magnifying-glass">
                {t('common.searchButton')}
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={addKeyTo(companies)}
          pagination={false}
        />
        <PaginationRow {...pagination} changePage={changePage} />
      </Container>
    </Layout>
  );
};

IndexPage.propTypes = {};

export default IndexPage;
