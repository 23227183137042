import { AgentContact } from '@root/types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Avatar from '../Avatar';
import CustomIcon from '../CustomIcon';
import PopperTooltip from '../PopperTooltip';
import s from './DeprecatedContact.module.less';

type ContactProps = {
  contact: AgentContact;
  emailSubjectName?: string;
  compact?: boolean;
  onContactReveal?: () => void;
  onContactEmailClick?: () => void;
};
const DeprecatedContact = ({
  contact,
  emailSubjectName,
  compact = true,
  onContactReveal = () => {},
  onContactEmailClick = () => {},
}: ContactProps) => {
  const { t } = useTranslation('listing');
  const { name, email, phone, company, role, photoUrl, phoneExtension } = contact;

  const phoneWithoutExtension = t('common:formattedPhone', { formattedPhone: phone });
  const phoneWithExtension =
    phone &&
    t('common:formattedPhoneWithExtension', {
      formattedPhone: phone,
      extension: phoneExtension,
    });

  const emailAndPhone = (
    <div className={s.moreInfo}>
      <div className={s.emailContainer}>
        <CustomIcon type="email" className={s.emailIcon} />
        <a
          className={s.email}
          href={`mailto:${email}?subject=${emailSubjectName || ''}`}
          target="_blank"
          rel="noreferrer"
          onClick={onContactEmailClick}
        >
          {email}
        </a>
      </div>
      {!!phone && (
        <div className={s.phoneContainer}>
          <CustomIcon type="phone" className={s.phoneIcon} />
          <span className={s.phone}>
            {phoneExtension ? phoneWithExtension : phoneWithoutExtension}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div className={s.contact}>
      <div className={classNames(s.leftSide, compact && s.compact)}>
        <Avatar name={name} photoUrl={photoUrl} className={s.avatar} />
        {compact && (
          <PopperTooltip
            triggerElement={<CustomIcon type="contact-info" className={s.contactInfoIcon} />}
            onClick={onContactReveal}
            popperElementClassName={s.moreInfoContainer}
            popperElement={emailAndPhone}
            trigger="click"
          />
        )}
      </div>
      <div>
        <div className={s.name}>{name}</div>
        {!!role && <div className={s.role}>{role}</div>}
        {!!company && <div className={s.company}>{company}</div>}
        {!compact && emailAndPhone}
      </div>
    </div>
  );
};

export default DeprecatedContact;
