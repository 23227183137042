import { useState, useRef, useEffect } from 'react';

const usePhotoPreview = (file: Blob | null, initialValue?: string) => {
  const [photoPreview, setPhotoPreview] = useState<string | null | undefined>(initialValue);
  const fileReader = useRef(new FileReader());
  fileReader.current.addEventListener('load', () => {
    setPhotoPreview(fileReader.current.result as string);
  });

  useEffect(() => {
    if (file) {
      fileReader.current.readAsDataURL(file);
    }
  }, [file]);

  return {
    photoPreview,
    resetPhotoPreview: () => setPhotoPreview(undefined),
  };
};

export default usePhotoPreview;
