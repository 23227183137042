import useCurrentUser from '@shared/useCurrentUser';
import { useTranslation } from 'react-i18next';
import ContactCard from './ContactCard';
import ContactList from './ContactList';

type ReturnType = {
  showContactList: boolean;
  showLinkSharerContactInfo: boolean;
  ContactList: typeof ContactList;
  LinkSharerContactInfo: typeof ContactCard;
  makeContactEmailSubject: ({
    userName,
    address,
  }: {
    userName?: string;
    address: string;
  }) => string;
};

const components = {
  ContactList,
  LinkSharerContactInfo: ContactCard,
};

export default function useContactViewChooser({
  arrivedOnShareLink,
  arrivedOnMarketLink,
}: {
  arrivedOnShareLink: boolean;
  arrivedOnMarketLink: boolean;
}): ReturnType {
  const { currentUser } = useCurrentUser();
  const userIsLoggedIn = !!currentUser;
  const { t } = useTranslation('common');

  const makeContactEmailSubject = ({
    userName,
    address,
  }: {
    userName?: string;
    address: string;
  }): string =>
    userName
      ? t('inquiry.subject.field', {
          address,
          first_name: userName,
        })
      : t('inquiry.logged_out_subject.field', {
          address,
        });

  return {
    showContactList: userIsLoggedIn || arrivedOnMarketLink,
    showLinkSharerContactInfo: !userIsLoggedIn && arrivedOnShareLink,
    ...components,
    makeContactEmailSubject,
  };
}
