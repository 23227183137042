import PropTypes from 'prop-types';
import utils from '@styles/utils.module.less';
import { Col } from 'antd';
import s from './DataPoint.module.less';

const DataPoint = ({ label, value, format, href }) => {
  const ValueWrapper = href ? 'a' : 'div';
  return value ? (
    <Col lg={6} md={12} sm={12} className={s.dataPoint}>
      <div className={utils.label}>{label}</div>
      <ValueWrapper className={href && utils.link} href={href}>
        {format ? format(value) : value}
      </ValueWrapper>
    </Col>
  ) : null;
};

DataPoint.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  format: PropTypes.func,
  href: PropTypes.string,
};

DataPoint.defaultProps = {
  value: null,
  format: null,
  href: null,
};

export default DataPoint;
