import { Form, TextInput, SubmitButton } from '@components/shared/forms';
import { CustomIcon, Button, Notification } from '@components/shared';
import routes from '@root/routes';
import * as Yup from 'yup';
import { SearchListing, Listing, BuildingListing, StoreState } from '@root/types';
import { useTranslation, Trans } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from '@shared/api';
import { PARAMETERS } from '@root/tracking/constants';
import { getSourcePageFrom } from '@root/tracking/helpers';
import { useSelector } from 'react-redux';
import useAnalyticsEvent from '@root/shared/useAnalytics';
import s from './TourbookOverlay.module.less';

type Props = {
  close: () => void;
  createOnly: boolean;
  goToInfoCard: () => void;
  goToToggleCard: () => void;
  listing?: SearchListing | Listing | BuildingListing;
};

const CreateTourbookCard = ({
  close,
  createOnly,
  goToInfoCard,
  goToToggleCard,
  listing,
}: Props) => {
  const { t } = useTranslation('tourbook');

  const queryClient = useQueryClient();

  const { tourbookInteraction } = useAnalyticsEvent();

  const sourcePage = useSelector((state: StoreState) => getSourcePageFrom(state));
  const sourceContentIsListingPreviewModal = useSelector(
    (state: StoreState) => state.ui.islistingPreviewModalOpen,
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(90, t('manageDialogs.nameMaxMessage'))
      .required(t('manageDialogs.requiredNameMessage')),
  });
  type FormValues = Yup.InferType<typeof validationSchema>;
  const initialValues = { name: '' } as FormValues;

  const createTourbookMutation = useMutation(
    async ({
      name,
      listingId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      helpers,
    }: {
      name: string;
      listingId: string | undefined;
      helpers: any;
    }) => {
      const response = await api.post(routes.api.tourbooks('newest'), { name, listingId, listing });
      return response.json();
    },
    {
      onSuccess: async (tourbook, values) => {
        values.helpers.setFieldValue('name', '', false);

        tourbookInteraction({
          actionType: 'NEW_TOURBOOK_CREATED',
          action: PARAMETERS.createTourbook,
          sourcePage: sourcePage!,
          sourceContent: sourceContentIsListingPreviewModal
            ? PARAMETERS.listingPreview
            : PARAMETERS.tourbookOverlay,
          otherAttributes: {
            destinationTourbookId: tourbook.id,
            destinationTourbookName: tourbook.name,
            destinationTourbookOwnerId: tourbook.ownerId,
            destinationTourbookOwnerVTSId: tourbook.ownerVTSId,
            destinationTourbookListingCount: tourbook.listingIds ? tourbook.listingIds.length : 0,
            ...listing?.analyticsInformation,
          },
        });

        if (listing) {
          tourbookInteraction({
            actionType: 'LISTING_ASSOCIATED_WITH_TOURBOOK',
            action: PARAMETERS.addListing,
            sourcePage: sourcePage!,
            sourceContent: sourceContentIsListingPreviewModal
              ? PARAMETERS.listingPreview
              : PARAMETERS.tourbookOverlay,
            otherAttributes: {
              destinationTourbookId: tourbook.id,
              destinationTourbookName: tourbook.name,
              destinationTourbookOwnerId: tourbook.ownerId,
              destinationTourbookOwnerVTSId: tourbook.ownerVTSId,
              destinationTourbookListingCount: tourbook.listingIds ? tourbook.listingIds.length : 0,
              ...listing.analyticsInformation,
            },
          });
        }

        if (!createOnly) {
          goToToggleCard();
          /* eslint-disable react/jsx-no-literals */
          Notification.info({
            placement: 'bottomLeft',
            duration: 3.5,
            title: t('toasts.tourbookCreatedTitle'),
            text: (
              <Trans i18nKey="toasts.tourbookCreatedText" ns="tourbook">
                View your new <a href={routes.tourbook(tourbook.id)}>Tourbook</a>
              </Trans>
            ),
          });
          /* eslint-enable react/jsx-no-literals */
        }
        await queryClient.invalidateQueries([routes.api.tourbooks('newest')]);
      },
    },
  );

  return (
    <>
      <div data-testid="addTourbookCard" className={s.addCardHeader}>
        <button
          data-testid="goToInfoButton"
          type="button"
          onClick={goToInfoCard}
          className={s.headerButton}
        >
          <CustomIcon className={s.infoButton} type="info" />
        </button>
        <h3>{t('manageDialogs.createATourbook')}</h3>
        <button onClick={close} type="button">
          <CustomIcon type="close" />
        </button>
      </div>
      <CustomIcon className={s.tourbookIcon} type="tourbook" />
      <div className="p-2 pb-0">
        <Form<FormValues>
          id="createTourbook"
          className={s.createForm}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={async (values, helpers) => {
            createTourbookMutation.mutate({
              name: values.name!,
              listingId: listing?.id,
              helpers,
            });
          }}
        >
          <TextInput
            name="name"
            labelText={t('manageDialogs.name')}
            containerClass={s.nameFieldContainer}
            autoComplete={false}
            maxLength={90}
          />
          <div className={s.ctaButtons}>
            {!createOnly ? (
              <Button
                size="small"
                className={s.backButton}
                type="secondary"
                onClick={goToToggleCard}
              >
                {t('manageDialogs.back')}
              </Button>
            ) : (
              <Button size="small" className={s.backButton} type="secondary" onClick={close}>
                {t('listingCard.listingDescription.cancel')}
              </Button>
            )}
            <SubmitButton
              className={s.createTourbookButton}
              size="small"
              data-testid="createTourbookButton"
            >
              {t('manageDialogs.createButtonText')}
            </SubmitButton>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateTourbookCard;
