import actions from '@store/actions/listingSearchPage';
import { SpaceCondition } from '@root/types';
import CheckboxFilter from '../CheckboxFilter';

export const conditions: Array<{ label: string; value: SpaceCondition; translationKey: string }> = [
  {
    label: 'Pre-built',
    value: 'pre-built',
    translationKey: 'filters:spaceCondition:preBuilt',
  },
  {
    label: '2nd generation',
    value: '2nd generation',
    translationKey: 'filters:spaceCondition:secondGeneration',
  },
  {
    label: 'Pre-existing',
    value: 'pre-existing',
    translationKey: 'filters:spaceCondition:preExisting',
  },
  {
    label: 'Turnkey',
    value: 'turnkey',
    translationKey: 'filters:spaceCondition:turnkey',
  },
  {
    label: 'White box',
    value: 'white box',
    translationKey: 'filters:spaceCondition:whiteBox',
  },
  {
    label: 'Core and shell',
    value: 'core and shell',
    translationKey: 'filters:spaceCondition:coreAndShell',
  },
  {
    label: 'Raw',
    value: 'raw',
    translationKey: 'filters:spaceCondition:raw',
  },
  {
    label: 'Challenged',
    value: 'challenged',
    translationKey: 'filters:spaceCondition:challenged',
  },
  {
    label: 'Under construction',
    value: 'under construction',
    translationKey: 'filters:spaceCondition:underConstruction',
  },
];

const SpaceConditionFilters = () => {
  return (
    <CheckboxFilter
      filters={conditions}
      filterType="spaceCondition"
      filterDispatchAction={actions.spaceConditionFilterChanged}
    />
  );
};

export default SpaceConditionFilters;
