import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { Table } from 'antd';
import useSuccessCheckmark from '@shared/useSuccessCheckmark';
import { RawInput } from '@components/shared/forms';
import { VirtualTourSpotlights } from '@root/types';
import VirtualTourSpotlightPicker from '@components/shared/Admin/VirtualTourSpotlightPicker';
import css from '@components/shared/Admin/ThreeDContent/ThreeDContent.module.less';

export type Props = {
  tableTitle: string;
  tourEmbedUrl: string | null;
  onTourEmbedUrlUpdate: (value: string) => Promise<boolean>;
  onVirtualTourSpotlightUpdate: (
    newValue: Exclude<VirtualTourSpotlights, 'unset'>,
  ) => Promise<boolean>;
  virtualTourSpotlight: VirtualTourSpotlights;
  hasVideo: boolean;
  totalVariableColumnsWidth?: string;
};

const ThreeDContent = ({
  tableTitle,
  tourEmbedUrl,
  onTourEmbedUrlUpdate,
  onVirtualTourSpotlightUpdate,
  virtualTourSpotlight,
  hasVideo,
  totalVariableColumnsWidth = '52vw',
}: Props) => {
  const { t } = useTranslation('admin');
  const { showCheckmark, Checkmark, checkmarkProps } = useSuccessCheckmark();
  const [tourEmbedUrlValue, setTourEmbedUrlValue] = useState(tourEmbedUrl || '');
  const didMountRef = useRef(false);

  const handleChange = event => {
    setTourEmbedUrlValue(event.target.value);
  };

  // FIXME: Either add the exhaustive deps or delete this line
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateTourEmbedUrl = useCallback(
    debounce(async value => {
      if (await onTourEmbedUrlUpdate(value)) showCheckmark();
    }, 1250),
    [],
  );

  const columns = () => [
    {
      title: t('media.listing3dTourEmbedLink'),
      width: `calc(${totalVariableColumnsWidth} - 80px)`,
      render: () => <RawInput value={tourEmbedUrlValue} onChange={handleChange} />,
    },
    {
      width: '80px',
      render: () => <Checkmark {...checkmarkProps} />,
    },
    {
      render: () => (
        <VirtualTourSpotlightPicker
          show={hasVideo && !!tourEmbedUrl}
          currentValue={virtualTourSpotlight}
          onChange={onVirtualTourSpotlightUpdate}
          pickerFor="three_d_tour"
        />
      ),
    },
  ];

  useEffect(() => {
    if (didMountRef.current) {
      updateTourEmbedUrl(tourEmbedUrlValue);
    } else {
      didMountRef.current = true;
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourEmbedUrlValue]);

  return (
    <>
      <div className="pb-2 font-title">{tableTitle}</div>
      <Table
        className={css.adminTableStyles}
        columns={columns()}
        dataSource={[{ key: 1 }]}
        pagination={false}
      />
    </>
  );
};

export default ThreeDContent;
