import { PowerpointTourbook } from '@root/types';
import { generateLine, pipeElement } from './util';
import {
  footerSmallText,
  PowerpointElement,
  PowerpointTextElementProps,
  PptxUtils,
} from './globals';

export const PAGE_MARGIN = 0.38;
export const PAGE_WIDTH = 7.5;
export const CONTENT_WIDTH = PAGE_WIDTH - PAGE_MARGIN * 2;

const generateImageBorder = (pptx: PptxGenJS.default): PowerpointElement => {
  return {
    type: 'shape',
    placement: { x: PAGE_MARGIN, y: 9.41, w: 0.4, h: 0.4 },
    shapeOptions: {
      line: { dashType: 'solid', color: 'BFBFBF', width: 0.5 },
    },
    shapeType: pptx.ShapeType.ellipse,
  };
};

export const generateFooterElements = (
  tourbook: PowerpointTourbook,
  utils: PptxUtils,
): PowerpointElement[] => {
  const { pptx, t } = utils;
  const elements: PowerpointElement[] = [];

  const phoneNumber = tourbook?.owner?.phoneExtension
    ? t('common:formattedPhoneWithExtension', {
        formattedPhone: tourbook?.owner?.phoneNumber,
        extension: tourbook?.owner?.phoneExtension,
      })
    : t('common:formattedPhone', {
        formattedPhone: tourbook?.owner?.phoneNumber,
      });

  elements.push(generateLine(pptx, 9.3, PAGE_WIDTH));

  let x = PAGE_MARGIN;
  const avatarDimensions = {
    y: 9.41,
    w: 0.4,
    h: 0.4,
  };

  if (tourbook.owner.tourbookCompanyImage) {
    elements.push({
      type: 'image',
      data: tourbook.owner.tourbookCompanyImage.path,
      placement: {
        ...avatarDimensions,
        x,
      },
      imageOptions: { rounding: true },
    });

    elements.push(generateImageBorder(pptx));
    x = 0.78;
  } else if (tourbook.owner.tourbookAvatarImage) {
    elements.push({
      type: 'image',
      data: tourbook.owner.tourbookAvatarImage.path,
      placement: {
        ...avatarDimensions,
        x,
      },
      imageOptions: { rounding: true },
    });

    elements.push(generateImageBorder(pptx));
    x = 0.78;
  }

  let footerTextElements: PowerpointTextElementProps[] = [
    {
      data: tourbook.owner.fullName,
      textOptions: {
        ...footerSmallText,
        bold: true,
      },
    },
  ];

  if (tourbook.owner.formattedPhoneNumber) {
    footerTextElements = [
      ...footerTextElements,
      pipeElement(),
      {
        data: phoneNumber,
        textOptions: {
          ...footerSmallText,
        },
      },
    ];
  }

  footerTextElements = [
    ...footerTextElements,
    pipeElement(),
    {
      data: tourbook.owner.email,
      textOptions: {
        ...footerSmallText,
      },
    },
  ];

  elements.push({
    type: 'textBox',
    data: footerTextElements,
    placement: {
      x,
      y: 9.43,
      w: 4.82,
      h: 0.44,
    },
  });

  elements.push({
    type: 'slideNumber',
    placement: { x: 7.04, y: 9.53, w: 0.4, h: 0.44 },
    textOptions: { fontSize: 8 },
  });

  return elements;
};
