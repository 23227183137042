import { useState } from 'react';
import { Placement } from '@popperjs/core';
import { Address } from '@root/types';
import { FloorplanPreviewButton } from '@components/shared';

type Arguments = {
  floorPlan: string;
  floorplanIconOnClick: () => void;
  toolTipPlacement?: Placement;
  portrait?: boolean;
  address: Address;
  onToggleFloorplanPreview?: (isOpen: boolean) => void;
};

const useFloorplanPreviewButton = ({
  address,
  floorPlan,
  floorplanIconOnClick,
  portrait,
  onToggleFloorplanPreview,
}: Arguments) => {
  const [floorplanPreviewIsOpen, setFloorplanPreviewIsOpen] = useState(false);

  const setFloorplanPreviewIsOpenWithCallback = (isOpen: boolean) => {
    onToggleFloorplanPreview?.(isOpen);
    setFloorplanPreviewIsOpen(isOpen);
  };

  const floorplanPreviewButtonProps = {
    address,
    floorPlan,
    floorplanIconOnClick,
    toolTipPlacement: (portrait ? 'bottom-end' : 'right-start') as Placement,
    portrait,
    floorplanPreviewIsOpen,
    setFloorplanPreviewIsOpen: setFloorplanPreviewIsOpenWithCallback,
  };

  return {
    FloorplanPreviewButton,
    floorplanPreviewButtonProps,
    floorplanPreviewIsOpen,
  };
};

export default useFloorplanPreviewButton;
