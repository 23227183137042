import Pptxgen from 'pptxgenjs';
import { Cloudinary } from 'cloudinary-core';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { TFunction } from 'i18next';
import Bugsnag from '@shared/bugsnagClient';
import { PowerpointTourbook, Locale } from '@root/types';
import { createBuilding } from './ppt/building';
import { PptxUtils } from './ppt/globals';
import { createOverview } from './ppt/overview';
import { createCover } from './ppt/cover';
import { createExternalListing } from './ppt/externalListing';

// General PPT Generation Guidelines
// 1. Never provide null values when adding text.  Always provide at least an empty string
// 2. When reusing options, always copy/destructure the options.  Dont use them directly
// EG:
// GOOD
// slide.addText({ text: 'whatever', options: { ...smallText } }, { x, y });
// BAD
// slide.addText({ text: 'whatever', options: smallText }, { x, y });

const generateSlidesFromTourbook = (
  tourbook: PowerpointTourbook,
  {
    googleMapsKey,
    cloudinaryCloud,
    vtsCloudinaryCloud,
    t,
    flags,
    railsEnv,
    locale,
  }: {
    googleMapsKey: string;
    cloudinaryCloud: string;
    vtsCloudinaryCloud: string;
    t: TFunction;
    flags: LDFlagSet;
    railsEnv: 'development' | 'test' | 'production';
    locale: Locale;
  },
  onFileReady: Function,
  onFailure,
) => {
  const pptx: Pptxgen = new Pptxgen();

  const cloudinary = new Cloudinary({ cloud_name: cloudinaryCloud });
  const vtsCloudinary = new Cloudinary({ cloud_name: vtsCloudinaryCloud });

  const utils: PptxUtils = {
    cloudinary,
    vtsCloudinary,
    // TODO: remove toImageUrl
    toImageUrl: cloudinary.url,
    googleMapsKey,
    pptx,
    t,
    flags,
    railsEnv,
    locale,
  };

  pptx.defineLayout({ name: 'Portrait', width: 7.5, height: 10 });

  // Set presentation to use new layout
  pptx.layout = 'Portrait';

  pptx.author = `${tourbook.owner.firstName} ${tourbook.owner.lastName}`;
  pptx.title = tourbook.name;
  pptx.subject = 'VTS Marketplace Tourbook';
  pptx.company = 'VTS';

  createCover(utils, tourbook);

  createOverview(utils, tourbook);

  tourbook.entries.forEach(tourbookEntry => {
    // eslint-disable-next-line default-case
    switch (tourbookEntry.type) {
      case 'building': {
        createBuilding(tourbook, tourbookEntry, utils);
        break;
      }
      case 'externalListing': {
        createExternalListing(utils, tourbook, tourbookEntry);
      }
    }
  });

  pptx
    .writeFile({ fileName: `${tourbook.name}.pptx` })
    .then(() => {
      onFileReady();
    })
    .catch(error => {
      onFailure();
      // eslint-disable-next-line no-console
      console.error(error);
      Bugsnag.notify(error);
    });
};

export default generateSlidesFromTourbook;
