import { OnlyInDesktopTablet, OnlyInMobile } from '@components/shared';
import cn from 'classnames';
import BuildingInfoSkeleton from './BuildingInfoSkeleton';
import GallerySkeleton from './GallerySkeleton';
import s from './ListingDetailsSkeleton.module.less';

const ListingDetailsSkeleton = () => {
  return (
    <>
      <OnlyInDesktopTablet>
        <section>
          <div className={cn(s.box1, s.shimmer)} />
          <div className={cn(s.box2, s.shimmer)} />
          <div className={cn(s.box3, s.shimmer)} />
          <div className={cn(s.box4, s.shimmer)} />
        </section>
        <div className={cn(s.box5, s.shimmer)} />
        <div className={cn(s.box6, s.shimmer)} />
        <div className={cn(s.box5, s.shimmer)} />
        <div className={cn(s.box6, s.shimmer)} />
        <div className={cn(s.box5, s.shimmer)} />
        <div className={cn(s.box5, s.shimmer)} />
        <div className={cn(s.box7, s.shimmer)} />
        <div className={cn(s.box8, s.shimmer)} />
      </OnlyInDesktopTablet>
      <OnlyInMobile>
        <section>
          <div className={cn(s.box1, s.shimmer)} />
          <div className={cn(s.box2, s.shimmer)} />
          <div className={cn(s.box3, s.shimmer)} />
          <div className={cn(s.box4, s.shimmer)} />
        </section>
        <GallerySkeleton />
        <section>
          <div className={cn(s.box9, s.shimmer)} />
          <div className={cn(s.box5, s.shimmer)} />
          <div className={cn(s.box6, s.shimmer)} />
          <div className={cn(s.box5, s.shimmer)} />
          <div className={cn(s.box6, s.shimmer)} />
          <div className={cn(s.box5, s.shimmer)} />
          <div className={cn(s.box10, s.shimmer)} />
        </section>
        <BuildingInfoSkeleton />
        <div className={cn(s.box7, s.shimmer)} />
        <div className={cn(s.circle, s.shimmer)} />
      </OnlyInMobile>
    </>
  );
};

export default ListingDetailsSkeleton;
