import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { IconButton } from '@components/shared';
import useAnalytics from '@shared/useAnalytics';
import { TourbookListingAnalyticsInformation } from '@root/types';
import s from '../FullWidthLightbox.module.less';

export type Props = {
  activeTab: 'images' | 'virtualTours';
  setActiveTab: (tab: 'images' | 'virtualTours') => void;
  onClose: () => void;
  isTabletDisplay: boolean;
  sourcePage: string;
  analyticsInformation?: TourbookListingAnalyticsInformation | undefined;
};

export default function TabControls({
  activeTab,
  setActiveTab,
  onClose,
  isTabletDisplay = false,
  sourcePage,
  analyticsInformation,
}: Props) {
  const { t } = useTranslation('common');
  const { galleryInteraction, PARAMETERS } = useAnalytics();

  const handleSetActiveTab = (newActiveTab: 'images' | 'virtualTours') => {
    setActiveTab(newActiveTab);
    galleryInteraction({
      sourcePage,
      sourceContent: PARAMETERS.lightboxGallery,
      action:
        newActiveTab === 'images' ? PARAMETERS.clickImagesTab : PARAMETERS.clickVirtualToursTab,
      actionType: 'LIGHTBOX_TAB_CONTROL_CLICKED',
      analyticsInformation,
    });
  };

  return (
    <div className={s.tabControls}>
      <ul className={s.tabs}>
        <li>
          <button
            type="button"
            onClick={() => handleSetActiveTab('images')}
            className={cn(s.tabButton, activeTab === 'images' && s.active)}
          >
            {t('images')}
          </button>
        </li>
        <li>
          <button
            type="button"
            data-testid={`virtual-tours-tab-${isTabletDisplay ? 'tablet' : 'desktop'}`}
            onClick={() => handleSetActiveTab('virtualTours')}
            className={cn(s.tabButton, activeTab === 'virtualTours' && s.active)}
          >
            {t('virtualTours')}
          </button>
        </li>
      </ul>
      <IconButton title="close" icon="close" className={s.tabCloseButton} onClick={onClose} />
    </div>
  );
}
