import { AdminLandlord, CropperCanvasData } from '@root/types';
import api from '@shared/api';
import routes from '@root/routes';
import { useTranslation } from 'react-i18next';
import { Notification } from '@components/shared';
import { Switch, ToggleEvent, Dialog, InlineNotice } from '@viewthespace/components';
import { UploadApiResponse as CloudinaryResponse } from 'cloudinary';
import { useState } from 'react';
import cn from 'classnames';
import useCloudinaryUploader from '@components/shared/Cloudinary/useCloudinaryUploader';
import useCropperModal, {
  ViewModes,
  cropBoxDimensionPresets,
  warningDimensionPresets,
  LogoTypes,
} from '@components/shared/Cropper/useCropperModal';
import AssetLocationPreviewTooltip from '@components/shared/Admin/AssetLocationPreviewTooltip/AssetLocationPreviewTooltip';
import EditableAsset from '@components/shared/EditableAsset/EditableAsset';
import AssetUploader from '@components/shared/EditableAsset/AssetUploader';
import { useFlags } from 'launchdarkly-react-client-sdk';
import css from './BrandedAssets.module.less';

type Props = {
  landlord: AdminLandlord;
  fetchLandlord: () => void;
};

const cropperModalViewMode: {
  bannerImage: ViewModes;
  wordmarkLogo: ViewModes;
  lettermarkLogo: ViewModes;
} = {
  bannerImage: 'edit',
  wordmarkLogo: 'preview',
  lettermarkLogo: 'edit',
};

const BrandedAssets = ({ landlord, fetchLandlord }: Props) => {
  const { t } = useTranslation('admin');

  const { CropperModal, cropperModalProps, openCropperModal } = useCropperModal();
  const [uploadNewPhotoType, setUploadNewPhotoType] = useState<LogoTypes | null>(null);
  const [assetTypeBeingUpdated, setAssetTypeBeingUpdated] = useState<LogoTypes | null>(null);
  const flags = useFlags();

  const [deleteConfig, setDeleteConfig] = useState<{
    isOpen: boolean;
    assetType: LogoTypes | null;
  }>({ isOpen: false, assetType: null });

  const handleToggleChange = async (event: ToggleEvent<HTMLInputElement>) => {
    await updateLandlord({ displayBannerOnBuildings: event.value, skipMarketChanges: true });
  };

  const updateLandlord = async payload => {
    const apiResponse = await api.put(routes.api.admin.landlord(landlord?.id), payload);
    if (apiResponse.ok) {
      fetchLandlord();
      Notification.info({
        title: t('common:changesSaved'),
        text: t('landlord.updateSuccess', {
          name: flags['outreach.landlord-company-connection-severed']
            ? landlord?.name
            : landlord!.companyName,
        }),
        placement: 'topRight',
      });
      setAssetTypeBeingUpdated(null);
    }
  };

  type EditImageArgs = {
    assetType: LogoTypes;
    cloudinaryId: string | null;
    cropperCanvasData?: CropperCanvasData | null;
    imageSrc: string | null;
    editable?: boolean;
    trimImage?: boolean;
  };

  const editBrandedAsset = ({
    assetType,
    cloudinaryId,
    cropperCanvasData,
    imageSrc,
    editable = true,
    trimImage = false,
  }: EditImageArgs) => {
    setAssetTypeBeingUpdated(assetType);
    openCropperModal({
      imageSrc,
      cloudinaryId,
      initialCanvasData: cropperCanvasData,
      cropBoxDimentions: cropBoxDimensionPresets[assetType],
      warningDimensions: warningDimensionPresets[assetType],
      viewMode: cropperModalViewMode[assetType],
      trimImage,
      ...(editable
        ? {}
        : { notEditableMessage: t('landlord.brandedAssets.editModal.nonEditable') }),
    });
  };

  const onReady = (cropperViewMode: ViewModes | null) => async (response: CloudinaryResponse) => {
    const cloudinaryId = response.public_id;

    if (cropperViewMode === 'preview') {
      editBrandedAsset({
        assetType: 'wordmarkLogo',
        cloudinaryId,
        imageSrc: null,
        editable: false,
        trimImage: true,
      });
    } else {
      cropperModalProps.updateState({ cloudinaryId });
    }
  };

  const onFileUpload = (assetType: LogoTypes | null) => async (blobUrl: string) => {
    editBrandedAsset({ assetType: assetType!, cloudinaryId: null, imageSrc: blobUrl });
  };

  const onSave =
    (assetType: LogoTypes) =>
    ({ cloudinaryId, cropperCanvasData, cloudinaryTransformations }) => {
      updateLandlord({
        [assetType]: {
          cloudinaryId,
          cropperCanvasData,
          transformations: cloudinaryTransformations,
        },
        skipMarketChanges: true,
      });
    };

  const createErrorNotification = (error: string = '') => {
    Notification.error({
      title: t('notification.updateError'),
      text: error,
    });
  };

  const {
    selectAndUploadToCloudinary,
    CloudinaryUploaderFileInput,
    cloudinaryUploaderFileInputProps,
  } = useCloudinaryUploader({
    uploadToVTS: false,
    onFileUpload: onFileUpload(uploadNewPhotoType),
    onReady: onReady(cropperModalViewMode[uploadNewPhotoType || 'lettermarkLogo']),
    onError: createErrorNotification,
  });

  const onUpload = (assetType: LogoTypes) => {
    setUploadNewPhotoType(assetType);
    if (selectAndUploadToCloudinary) selectAndUploadToCloudinary();
  };

  const onClose = () => {
    setDeleteConfig({ isOpen: false, assetType: null });
  };

  const onDeleteConfirm = async () => {
    await updateLandlord({
      [deleteConfig.assetType!]: {
        cloudinaryId: null,
      },
      ...(deleteConfig.assetType === 'bannerImage' && { displayBannerOnBuildings: false }),
      skipMarketChanges: true,
    });
    setDeleteConfig({ isOpen: false, assetType: null });
  };

  return (
    <>
      {deleteConfig.isOpen && (
        <DeleteDialog
          isDeleteOpen={deleteConfig.isOpen}
          assetType={deleteConfig.assetType!}
          onClose={onClose}
          onConfirm={onDeleteConfirm}
        />
      )}
      <CloudinaryUploaderFileInput {...cloudinaryUploaderFileInputProps} />
      <div className="mt-3.5">
        <span className="font-title">{t('landlord.brandedAssets.logos')}</span>
      </div>
      <div className="mt-4 flex flex-col">
        <span className="font-subtitle">{t('landlord.brandedAssets.lettermarkLogo.title')}</span>
        <div className="flex flex-row">
          <span className="font-body-medium">
            {t('landlord.brandedAssets.lettermarkLogo.subtitle')}
          </span>
          <AssetLocationPreviewTooltip
            assetType="lettermarkLogo"
            page="landlord"
            previewImage="https://media.truva.com/assets/lettermark-logo-screenshot.png"
          />
        </div>

        <div className="pt-2">
          {landlord.lettermarkLogo?.cloudinaryId ? (
            <div className="flex flex-col">
              <EditableAsset
                imagePath={landlord.lettermarkLogo?.rawPath}
                menuPlacement="right-start"
                imageClassName="!h-[110px] w-[110px] rounded-[3px] !border !border-solid !border-black-020 object-cover"
                altText="Lettermark logo that appears on the Lanldord page"
                editButtonClassName="relative ml-1 top-[46px] border border-solid border-black-035"
                editButtonName="lettermark-logo-edit-button"
                onUpload={() => onUpload('lettermarkLogo')}
                onDelete={() => setDeleteConfig({ isOpen: true, assetType: 'lettermarkLogo' })}
                onEdit={() =>
                  editBrandedAsset({
                    assetType: 'lettermarkLogo',
                    cloudinaryId: landlord.lettermarkLogo?.cloudinaryId!,
                    cropperCanvasData: landlord.lettermarkLogo?.cropperCanvasData,
                    imageSrc: null,
                  })
                }
              />
              <span className="pt-1 text-black-055 font-body-small">
                {t('landlord.brandedAssets.lettermarkLogo.imageSize')}
              </span>
            </div>
          ) : (
            <AssetUploader
              placeholderContainerClassName="flex flex-col items-center pl-[10px] pr-1"
              placeholderElementClassName="text-center font-body-medium"
              containerClassName="w-[110px] !h-[110px] !my-0"
              recommendedImageText={t('landlord.brandedAssets.lettermarkLogo.imageSize')}
              onReady={onReady(cropperModalViewMode.lettermarkLogo)}
              onFileUpload={onFileUpload('lettermarkLogo')}
              onError={createErrorNotification}
              testId="lettermark-logo-uploader"
            />
          )}
        </div>
      </div>

      <div className="mt-5 flex flex-col">
        <span className="font-subtitle">{t('landlord.brandedAssets.wordmarkLogo.title')}</span>
        <div className="flex flex-row">
          <span className="font-body-medium">
            {t('landlord.brandedAssets.wordmarkLogo.subtitle')}
          </span>
          <AssetLocationPreviewTooltip
            assetType="wordmarkLogo"
            page="landlord"
            previewImage="https://media.truva.com/assets/wordmark-logo-screenshot.png"
          />
        </div>

        <div className="pt-2">
          {landlord.wordmarkLogo?.cloudinaryId ? (
            <div className="flex max-w-[1440px] gap-x-1">
              {/* The above div can be removed when company logo has been migrated
               to use the wordmark logo */}
              <div className="flex flex-col">
                <EditableAsset
                  imagePath={landlord.wordmarkLogo?.rawPath}
                  menuPlacement="right-start"
                  imageClassName="mt-[-6px] !max-h-[20px] !max-w-[120px] rounded-[3px] !border !border-solid !border-black-020 object-contain"
                  altText="Wordmark Logo that appears on the search page"
                  editButtonClassName="ml-1 border border-solid border-black-035"
                  editButtonName="wordmark-logo-edit-button"
                  onUpload={() => onUpload('wordmarkLogo')}
                  onDelete={() => setDeleteConfig({ isOpen: true, assetType: 'wordmarkLogo' })}
                  onPreview={() =>
                    editBrandedAsset({
                      assetType: 'wordmarkLogo',
                      cloudinaryId: landlord.wordmarkLogo?.cloudinaryId!,
                      cropperCanvasData: landlord.wordmarkLogo?.cropperCanvasData,
                      imageSrc: null,
                      editable: false,
                      trimImage: true,
                    })
                  }
                  items={['upload', 'preview', 'delete']}
                />
                <span className="pt-0.5 text-black-055 font-body-small">
                  {t('landlord.brandedAssets.wordmarkLogo.imageSize')}
                </span>
              </div>
              {/* The inline notice can be removed when company logo has been migrated to use the
              wordmark logo */}
              {flags['outreach.landlord-company-connection-severed'] ? null : (
                <div className="ml-auto [&>*:first-child]:p-0.5 [&_div]:items-center [&_svg]:mt-[-12px] [&_svg]:h-[22px] [&_svg]:w-[18px]">
                  <InlineNotice
                    className="h-6"
                    variant="attention"
                    content={
                      <div className="flex flex-col">
                        <span className="font-body-small">
                          {t('landlord.brandedAssets.wordmarkLogo.attentionNotice.part1')}
                        </span>
                        <span className="mr-auto font-body-small">
                          {t('landlord.brandedAssets.wordmarkLogo.attentionNotice.part2')}
                        </span>
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="flex max-w-[1440px] gap-x-1">
              {/* The above div can be removed when company logo has been migrated
               to use the wordmark logo */}
              <AssetUploader
                placeholderElementClassName="pl-[4px] font-body-medium"
                containerClassName="!w-[257px] !h-[52px] !my-0"
                onReady={onReady(cropperModalViewMode.wordmarkLogo)}
                onFileUpload={onFileUpload('wordmarkLogo')}
                onError={createErrorNotification}
                recommendedImageText={t('landlord.brandedAssets.wordmarkLogo.imageSize')}
                testId="wordmark-logo-uploader"
              />
              {/* The inline notice can be removed when company logo has been migrated to use the
              wordmark logo */}
              {flags['outreach.landlord-company-connection-severed'] ? null : (
                <div className="ml-auto [&>*:first-child]:p-0.5 [&_div]:items-center [&_svg]:mt-[-12px] [&_svg]:h-[22px] [&_svg]:w-[18px]">
                  <InlineNotice
                    className="h-6"
                    variant="attention"
                    content={
                      <div className="flex flex-col">
                        <span className="font-body-small">
                          {t('landlord.brandedAssets.wordmarkLogo.attentionNotice.part1')}
                        </span>
                        <span className="mr-auto font-body-small">
                          {t('landlord.brandedAssets.wordmarkLogo.attentionNotice.part2')}
                        </span>
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 h-[1px] w-full bg-black-010" />
      <div className="mt-6 flex flex-col">
        <span className="font-title">{t('landlord.brandedAssets.bannerImage.title')}</span>
        <div className="mt-4 flex flex-row">
          <span className="font-body-medium">
            {t('landlord.brandedAssets.bannerImage.subtitle')}
          </span>
          <AssetLocationPreviewTooltip
            assetType="bannerImage"
            page="landlord"
            previewImage="https://media.truva.com/assets/banner-landlord-page-screenshot.png"
          />
        </div>

        <div className="pb-3 pt-2">
          {landlord.bannerImage?.cloudinaryId ? (
            <>
              <EditableAsset
                containerClassName="h-[139px] relative max-w-[1440px] w-full"
                imagePath={landlord.bannerImage.rawPath}
                menuPlacement="top-end"
                imageClassName="!h-[139px] w-[1440px] rounded-[3px] !border !border-solid !border-black-020 object-cover"
                altText="Panoramic Banner that appears on the Landlord page"
                editButtonClassName="absolute bottom-1 right-1 border-black-035 border border-solid"
                editButtonName="banner-edit-button"
                onUpload={() => onUpload('bannerImage')}
                onDelete={() => setDeleteConfig({ isOpen: true, assetType: 'bannerImage' })}
                onEdit={() =>
                  editBrandedAsset({
                    assetType: 'bannerImage',
                    cloudinaryId: landlord.bannerImage?.cloudinaryId!,
                    cropperCanvasData: landlord.bannerImage?.cropperCanvasData,
                    imageSrc: null,
                  })
                }
              />
              <span className="flex pt-1 text-black-055 font-body-small">
                {t('landlord.brandedAssets.bannerImage.imageSize')}
              </span>
            </>
          ) : (
            <AssetUploader
              placeholderElementClassName="pl-1 font-body-medium"
              onReady={onReady(cropperModalViewMode.bannerImage)}
              onFileUpload={onFileUpload('bannerImage')}
              onError={createErrorNotification}
              containerClassName="!h-[139px] !my-0 w-full max-w-[1440px]"
              recommendedImageText={t('landlord.brandedAssets.bannerImage.imageSize')}
              testId="banner-uploader"
            />
          )}
        </div>
        <div className="flex flex-row pb-3">
          <Switch
            name="DisplayBannerOnBuildingToggle"
            labelPosition="right"
            label={t('landlord.brandedAssets.bannerImage.displayOnBuildingsLabel')}
            className={cn('font-body-medium-emphasis', css.bannerImageToggle)}
            onChange={handleToggleChange}
            isOn={landlord.displayBannerOnBuildings}
            isDisabled={!landlord.bannerImage?.cloudinaryId}
            testId="banner-toggle"
          />
        </div>
      </div>
      {assetTypeBeingUpdated && (
        <CropperModal
          {...cropperModalProps}
          onSave={onSave(assetTypeBeingUpdated)}
          title={t(`landlord.brandedAssets.editModal.${assetTypeBeingUpdated}.title`)}
        />
      )}
    </>
  );
};

const DeleteDialog = ({
  isDeleteOpen,
  assetType,
  onClose,
  onConfirm,
}: {
  isDeleteOpen: boolean;
  assetType: LogoTypes;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const { t } = useTranslation('admin');

  const assetConfig = {
    lettermarkLogo: {
      title: t('landlord.brandedAssets.deleteModal.lettermarkLogo.title'),
      text: t('landlord.brandedAssets.deleteModal.lettermarkLogo.text'),
    },
    wordmarkLogo: {
      title: t('landlord.brandedAssets.deleteModal.wordmarkLogo.title'),
      text: t('landlord.brandedAssets.deleteModal.wordmarkLogo.text'),
    },
    bannerImage: {
      title: t('landlord.brandedAssets.deleteModal.bannerImage.title'),
      text: t('landlord.brandedAssets.deleteModal.bannerImage.text'),
    },
  };

  return (
    <Dialog
      align="default"
      header={assetConfig[assetType].title}
      isOpen={isDeleteOpen}
      onClose={onClose}
      closeOnClickAway
      width="small"
      primaryButton={{
        text: t('common:confirm'),
        onClick: onConfirm,
      }}
      secondaryButton={{
        text: t('common:cancel'),
        onClick: onClose,
      }}
    >
      <div className="p-2">
        <span className="font-body-medium">{assetConfig[assetType].text}</span>
      </div>
    </Dialog>
  );
};

export default BrandedAssets;
