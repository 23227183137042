import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ButtonLink } from '@components/shared/V2Button';
import routes from '@root/routes';
import { MarketSlug, SearchListing } from '@root/types';
import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import actions from '@store/actions/homepage';
import cn from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SavedStatuses } from '@store/actions/homepage/setSavedStatuses';
import useCurrentUser from '@shared/useCurrentUser';
import { InfoIcon } from '@viewthespace/components';
import s from './Homepage.module.less';
import ListingCardCarousel from './ListingCardCarousel';

type Props = { selectedMarket: { value: MarketSlug; label: string } };

type ExclusiveListings = {
  listings: SearchListing[];
  savedStatuses: SavedStatuses[];
};

const ExclusiveListingsSection = ({ selectedMarket }: Props) => {
  const flags = useFlags();
  const { t } = useTranslation('homepage');

  const dispatch = useDispatch();
  const { currentUser, hasLoaded } = useCurrentUser();

  const marketSlug = selectedMarket.value;
  const firstToMarketLink = `/search/${marketSlug}?exclusive=true`;

  const { data: exclusiveListingsData, isLoading: dataIsLoading } = useQuery(
    [routes.api.homepage.exclusiveListings(marketSlug)],
    async () => get<ExclusiveListings>(routes.api.homepage.exclusiveListings(marketSlug)),
    {
      enabled: !!hasLoaded,
    },
  );

  const handleFirstToMarketCarouselPaginated = () => {
    dispatch(actions.firstToMarketCarouselPaginated());
  };

  const handleFirstToMarketCarouselCardClicked = () => {
    dispatch(actions.firstToMarketCarouselCardClicked());
  };

  const handleClickViewAllExclusive = () => {
    dispatch(actions.viewAllExclusiveListingsClicked());
  };

  const showExclusiveListings =
    hasLoaded &&
    !dataIsLoading &&
    exclusiveListingsData &&
    exclusiveListingsData.listings.length >= 3;

  if (!showExclusiveListings) return null;

  return (
    <div className={flags['outreach.branding-general'] ? 'bg-background-tertiary' : 'bg-black-003'}>
      <div
        className={cn(
          s.firstToMarketListings,
          flags['outreach.branding-general']
            ? 'm-auto max-w-[1440px] bg-background-tertiary'
            : 'bg-black-003',
        )}
        data-testid="exclusiveListingsSection"
      >
        <div className={s.firstToMarketListingsResponsiveWidth}>
          <div className={s.titleRow}>
            <h2 className={cn(s.homePageTitle, s.firstToMarketListingsTitle)}>
              {t('exclusiveListings.title')}
            </h2>
            <Tooltip
              overlayClassName={s.tooltip}
              trigger="click"
              title={t('exclusiveListings.ftmTooltip')}
            >
              <span>
                <InfoIcon className="ml-[4px] align-sub text-indigo-100" size="sm" />
              </span>
            </Tooltip>
          </div>
          <ListingCardCarousel
            listings={exclusiveListingsData!.listings}
            loggedInUser={currentUser}
            onClick={handleFirstToMarketCarouselCardClicked}
            sliderAfterChange={handleFirstToMarketCarouselPaginated}
          />
          <div className={s.exclusiveListingCtaPositioner}>
            <ButtonLink
              className={s.exclusiveListingCta}
              type="primary"
              size="large"
              href={firstToMarketLink}
              onClick={handleClickViewAllExclusive}
            >
              {t('exclusiveListings.cta')}
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveListingsSection;
