import analyticsActions from './analyticsActions';
import actions from '../index';
import { fetchTourbook } from '../tourbooks/fetchTourbook';

const tourbookPageLoad =
  (id: string, isShare: boolean = false) =>
  async dispatch => {
    try {
      await dispatch(actions.setTourbookPage(id, isShare));
      await dispatch(fetchTourbook(id));
      await dispatch(analyticsActions.tourbookPageLoaded());
    } catch {
      await dispatch(actions.setNotFoundPage());
    }
  };

export default tourbookPageLoad;
