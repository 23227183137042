import { FunctionComponent } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import lodashParseInt from 'lodash/parseInt';
import mediaConstants from '@shared/mediaConstants';

import s from './Triptych.module.less';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const smallBreakpoint = lodashParseInt(mediaConstants['screen-sm']);

type TriptychProps = {
  className?: string;
};
const Triptych: FunctionComponent<TriptychProps> = ({ children, className }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: smallBreakpoint,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={cn(className, s.sliderContainer)}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default Triptych;
