import { range } from 'lodash';
import cn from 'classnames';
import s from './TourThisSpaceSkeleton.module.less';

const NUMBER_OF_CONTACTS = 3;

const TourThisSpaceSkeleton = () => {
  const contactSkeleton = index => {
    return (
      <div className={s.container} key={index}>
        <section className={s.section1}>
          <div className={cn(s.circle, s.shimmer)} />
          <div className={cn(s.box2, s.shimmer)} />
        </section>
        <section className={s.section2}>
          <div className={cn(s.box3, s.shimmer)} />
          <div className={cn(s.box4, s.shimmer)} />
          <div className={cn(s.box3, s.shimmer)} />
        </section>
      </div>
    );
  };
  return (
    <div className={s.mainContainer}>
      <div className={cn(s.box1, s.shimmer)} />
      <div className={s.contactsContainer}>
        {range(NUMBER_OF_CONTACTS).map(i => {
          return contactSkeleton(i);
        })}
      </div>
    </div>
  );
};

export default TourThisSpaceSkeleton;
