import { createInfoWindow } from '@shared/googleMaps';
import { InfoWindowListing } from '@root/types';
import InfoWindowGenerator from './InfoWindowGenerator';

type Trackers = {
  trackMapClickInfoWindowListing: Function;
};

type InfoWindowInitialArguments = {
  translate: Function;
  trackers: Trackers | null;
  onHoverMask?: boolean;
};

class BuildingInfoWindow {
  /* eslint-disable @typescript-eslint/lines-between-class-members */
  infoWindow: google.maps.InfoWindow;
  infoCardListings: InfoWindowListing[];
  visibleListingIndex: number;
  infoWindowGenerator: InfoWindowGenerator;
  trackers: Trackers | null;
  onHoverMask: boolean;
  /* eslint-enable @typescript-eslint/lines-between-class-members */

  constructor({ translate, trackers, onHoverMask = false }: InfoWindowInitialArguments) {
    this.infoWindow = this.setUpNewInfoWindow();
    this.infoCardListings = [];
    this.visibleListingIndex = 0;
    this.infoWindowGenerator = new InfoWindowGenerator(translate);
    this.trackers = trackers;
    this.onHoverMask = onHoverMask;
  }

  // public
  setUpNewInfoWindow = () => {
    const infoWindow = createInfoWindow();
    infoWindow.addListener('domready', () => {
      const markerInfoWindow = document.querySelector('#truva-info-window');
      if (!markerInfoWindow) return;

      markerInfoWindow.addEventListener('click', () => {
        const currentListing = this.getCurrentListing();
        if (this.trackers) {
          this.trackers.trackMapClickInfoWindowListing(currentListing);
        }
        if (currentListing.onClick) {
          currentListing.onClick();
        }
      });

      // If the marker only has one listing, it won't have the carousel header
      if (this.infoCardListings.length > 1) {
        this.removeInfoWindowCarouselHandlers();
        this.addInfoWindowCarouselHandlers();
      }
    });

    return infoWindow;
  };

  setInfoWindow = (
    marker: google.maps.Marker,
    map: google.maps.Map,
    listings: InfoWindowListing[],
  ) => {
    this.infoCardListings = listings;
    this.resetIndex();

    const customInfoWindow = this.getInfoWindowContent();
    this.infoWindow.setContent(customInfoWindow);
    this.infoWindow.open(map, marker);
  };

  close = () => {
    this.infoWindow.close();
  };

  // private
  private getCurrentListing = () => this.infoCardListings[this.visibleListingIndex];

  private getInfoWindowContent = () => {
    const listingData = this.getCurrentListing();
    const infoWinfowData = {
      listing: listingData,
      currentIndex: this.visibleListingIndex,
      totalListings: this.infoCardListings.length,
      onHoverMask: this.onHoverMask,
    };
    return this.infoWindowGenerator.createInfoWindow(infoWinfowData);
  };

  private removeInfoWindowCarouselHandlers = () => {
    document
      .querySelector('#previous-listing')
      ?.removeEventListener('click', this.updateInfoWindowToPreviousListing);

    document
      .querySelector('#next-listing')
      ?.removeEventListener('click', this.updateInfoWindowToNextListing);
  };

  private addInfoWindowCarouselHandlers = () => {
    document
      .querySelector('#previous-listing')
      ?.addEventListener('click', this.updateInfoWindowToPreviousListing);

    document
      .querySelector('#next-listing')
      ?.addEventListener('click', this.updateInfoWindowToNextListing);
  };

  private updateInfoWindowToPreviousListing = (event: Event) => {
    event.stopPropagation();
    this.decrementIndex();
    const customInfoWindow = this.getInfoWindowContent();
    this.infoWindow.setContent(customInfoWindow);
  };

  private updateInfoWindowToNextListing = (event: Event) => {
    event.stopPropagation();
    this.incrementIndex();
    const customInfoWindow = this.getInfoWindowContent();
    this.infoWindow.setContent(customInfoWindow);
  };

  private decrementIndex = () => {
    this.visibleListingIndex =
      this.visibleListingIndex === 0
        ? this.infoCardListings.length - 1
        : this.visibleListingIndex - 1;
  };

  private incrementIndex = () => {
    this.visibleListingIndex =
      this.visibleListingIndex === this.infoCardListings.length - 1
        ? 0
        : this.visibleListingIndex + 1;
  };

  private resetIndex = () => {
    this.visibleListingIndex = 0;
  };
}

export default BuildingInfoWindow;
