import { useState, useEffect } from 'react';
import api from '@shared/api';
import { stringify } from 'qs';

function useFetchedSelect<T>({
  route,
  getOptionsFromResponse = x => x,
}: {
  route: string;
  getOptionsFromResponse: (a: any) => T[];
}) {
  const [options, setOptions] = useState<T[]>([]);

  const fetchOptions = async (query: string) => {
    const queryString = stringify({ query });
    const response = await api.fetch(`${route}?${queryString}`);
    const parsedResponse = await response.json();
    setOptions(getOptionsFromResponse(parsedResponse));
  };

  const handleSearch = (query: string) => {
    fetchOptions(query);
  };

  const resetOptions = () => fetchOptions('');

  useEffect(() => {
    resetOptions();
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    options,
    resetOptions,
    handleSearch,
  };
}

export default useFetchedSelect;
