import OverflowMenu from '@components/shared/OverflowMenu';
import OverflowMenuItem from '@components/shared/OverflowMenuItem';
import CustomIcon from '@components/shared/CustomIcon';
import { DraggableAdminTable } from '@components/shared/AdminTable';
import useToggle from '@shared/useToggle';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { AdminLandlordPhoto, AdminListingPhoto } from '@root/types';
import css from './MediaPhotosSection.module.less';

// TODO: TRV-2844 & TRV-2845 Update these types to include Landlord and Building photos
type PhotoTypes = AdminListingPhoto | AdminLandlordPhoto;

type Props = {
  photos: PhotoTypes[];
  onUpdatePhotoPosition: (photoIds: string[]) => void;
  onDeletePhoto: (photoId: string) => void;
  additionalColumn?: {
    title: React.ReactNode;
    render: (photo: any) => React.ReactNode;
  };
  setSelectedRows: (val: any) => void;
  selectedRows: string[];
};

const PhotosTable = ({
  photos,
  onUpdatePhotoPosition,
  onDeletePhoto,
  additionalColumn,
  setSelectedRows,
  selectedRows,
}: Props) => {
  const { t } = useTranslation('admin');
  const { value: isDeleting, setTrue: startDeleting, setFalse: stopDeleting } = useToggle();

  const updatePhotoPositions = async (updatedPhotoList: PhotoTypes[]) => {
    const photoIds = updatedPhotoList.map(photo => photo.id);

    onUpdatePhotoPosition(photoIds);
  };

  const deletePhotoButtonHandler = async (id: string) => {
    startDeleting();
    // eslint-disable-next-line no-alert
    if (window.confirm(t('media.deleteConfirmation'))) {
      onDeletePhoto(id);
    }
    stopDeleting();
  };

  const renderPosition = (_position, _photo, index) => (
    <div data-testid="position-cell">{index + 1}</div>
  );

  const renderThumbnail = (path: string) => {
    return (
      <div className={css.thumbnailContainer}>
        <img src={path} alt="Space" />
      </div>
    );
  };

  const renderDescription = (path: string, photo: PhotoTypes) => {
    const { filename, processedAt }: any = photo;

    return (
      <div className={css.fileContainer}>
        <div className={css.filename}>{filename}</div>
        {processedAt ? (
          <div className={css.processedAt}>
            {t('media.processedAt', {
              date: format(parseISO(processedAt), 'PP'),
              time: format(parseISO(processedAt), 'p'),
            })}
          </div>
        ) : null}
      </div>
    );
  };

  const columns = () => [
    {
      title: t('media.mediaManagement.columns.position'),
      dataIndex: 'position',
      render: renderPosition,
    },
    {
      title: t('media.mediaManagement.columns.thumbnail'),
      dataIndex: 'path',
      render: renderThumbnail,
    },
    {
      title: t('media.mediaManagement.columns.fileName'),
      dataIndex: 'description',
      render: renderDescription,
    },
    ...(additionalColumn ? [additionalColumn] : []),
    {
      title: '',
      key: 'actions',
      width: '10%',
      render: (_, photo: PhotoTypes) => (
        <OverflowMenu overflowClassName={css.overflowMenu}>
          <a href={photo.downloadPath} aria-label="download image" download>
            <OverflowMenuItem className={css.overflowMenuItem} onClick={() => {}}>
              <>
                <CustomIcon type="download"></CustomIcon>
                {t('media.mediaManagement.download')}
              </>
            </OverflowMenuItem>
          </a>
          <OverflowMenuItem
            disabled={isDeleting}
            className={css.overflowMenuItem}
            onClick={() => deletePhotoButtonHandler(photo.id)}
          >
            <>
              <CustomIcon type="trash"></CustomIcon>
              {t('media.mediaManagement.delete')}
            </>
          </OverflowMenuItem>
        </OverflowMenu>
      ),
    },
  ];

  return (
    <div className={css.tableContainer}>
      <DraggableAdminTable
        columns={columns()}
        dataSource={photos}
        onChange={updatePhotoPositions}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
      />
    </div>
  );
};

export default PhotosTable;
