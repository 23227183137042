import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import { AsyncSelect } from '@components/shared/forms/Dropdowns';
import api from '@shared/api';
import routes from '@root/routes';
import StringListWithSource from '@components/shared/Admin/DataCollectionFields/StringListWithSource';
import YesNoMaybeWithSource from '@components/shared/Admin/DataCollectionFields/YesNoMaybeWithSource';
import CountWithSource from '@components/shared/Admin/DataCollectionFields/CountWithSource';
import css from './styles.module.less';

const fetchFiberProviders = async (query, page) => {
  const response = await api.fetch(routes.api.admin.fiberProviders(query, page));
  const { fiberProviders } = await response.json();
  return fiberProviders.map(fiberProvider => ({
    label: fiberProvider.name,
    value: fiberProvider.id,
  }));
};

const searchFiberProviders = throttle(query => fetchFiberProviders(query, 1), 250);

type Props = {
  architecturalClassification: string;
  constructionType: string;
  energyStarRated: string;
  entranceCount: number;
  fiberProviders: any;
  flexSpaceOwnerOperated: string;
  flexSpaceThirdParty: string;
  hotelAttached: string;
  hvacFiltration: string;
  landmarkBuilding: string;
  lobbyAttendantAfterHours: string;
  lobbyAttendantBusinessHours: string;
  mobileConnectivityImprovements?: string;
  parking: string;
  parkingRatio: number;
  passengerElevatorPosition: string;
  thirteenthFloor: string;
  passengerElevatorType: string;
  restaurant: string;
  rentableBuildingAreaOffice: number;
  rentableBuildingAreaRetail: number;
  rentableBuildingAreaOther: number;
  storyClassification: string;
  walkScore: number;
  wellScore: number;
};

const BuildingDataCollectionList = ({ ...props }: Props) => {
  const { t } = useTranslation('admin');

  const amenities = [
    {
      label: t('building.entranceCount'),
      render: (
        <CountWithSource
          fieldName="entranceCount"
          key="entranceCount"
          pageName="building"
          value={props.entranceCount}
        />
      ),
    },
    {
      label: t('building.fiberProviders'),
      render: (
        <AsyncSelect
          key="fiberProviders"
          className={css.select}
          initialValue={props.fiberProviders}
          isMulti
          labelText={t('building.fiberProviders')}
          loadOptions={searchFiberProviders}
          mapValue={value => ({ id: value })}
          name="fiberProviders"
          placeholder=""
        />
      ),
    },
    {
      label: t('building.landmarkBuilding'),
      render: (
        <YesNoMaybeWithSource
          fieldName="landmarkBuilding"
          key="landmarkBuilding"
          pageName="building"
          value={props.landmarkBuilding as string}
        />
      ),
    },
    {
      label: t('building.mobileConnectivityImprovements'),
      render: (
        <YesNoMaybeWithSource
          fieldName="mobileConnectivityImprovements"
          key="mobileConnectivityImprovements"
          pageName="building"
          value={props.mobileConnectivityImprovements as string}
        />
      ),
    },
    {
      label: t('building.passengerElevatorPosition.label'),
      render: (
        <StringListWithSource
          key="passengerElevatorPosition"
          pageName="building"
          fieldName="passengerElevatorPosition"
          value={props.passengerElevatorPosition}
          options={[{ name: 'core' }, { name: 'side' }]}
        />
      ),
    },
    {
      label: t('building.thirteenthFloor'),
      render: (
        <YesNoMaybeWithSource
          fieldName="thirteenthFloor"
          key="thirteenthFloor"
          pageName="building"
          value={props.thirteenthFloor as string}
        />
      ),
    },
    {
      label: t('building.architecturalClassification.label'),
      render: (
        <StringListWithSource
          key="architecturalClassification"
          pageName="building"
          fieldName="architecturalClassification"
          value={props.architecturalClassification}
          options={[
            { name: 'colonialNeoColonial' },
            { name: 'neoclassicalGreekRevival' },
            { name: 'renaissanceRevival' },
            { name: 'gothicRevival' },
            { name: 'artNouveau' },
            { name: 'castIronArchitecture' },
            { name: 'beauxArts' },
            { name: 'artDeco' },
            { name: 'internationalStyle' },
            { name: 'brutalism' },
            { name: 'newFormalism' },
            { name: 'googieSpaceAge' },
            { name: 'highTech' },
            { name: 'postmodernism' },
            { name: 'deconstructivism' },
          ]}
        />
      ),
    },
    {
      label: t('building.constructionType.label'),
      render: (
        <StringListWithSource
          key="constructionType"
          pageName="building"
          fieldName="constructionType"
          value={props.constructionType}
          sortOptionsByIndex
          options={[
            { name: 'concrete' },
            { name: 'masonry' },
            { name: 'steel' },
            { name: 'other' },
          ]}
        />
      ),
    },
    {
      label: t('building.energyStarRated'),
      render: (
        <YesNoMaybeWithSource
          fieldName="energyStarRated"
          key="energyStarRated"
          pageName="building"
          value={props.energyStarRated as string}
        />
      ),
    },
    {
      label: t('building.flexSpaceThirdParty'),
      render: (
        <YesNoMaybeWithSource
          fieldName="flexSpaceThirdParty"
          key="flexSpaceThirdParty"
          pageName="building"
          value={props.flexSpaceThirdParty as string}
        />
      ),
    },
    {
      label: t('building.flexSpaceOwnerOperated'),
      render: (
        <YesNoMaybeWithSource
          fieldName="flexSpaceOwnerOperated"
          key="flexSpaceOwnerOperated"
          pageName="building"
          value={props.flexSpaceOwnerOperated as string}
        />
      ),
    },
    {
      label: t('building.hotelAttached'),
      render: (
        <YesNoMaybeWithSource
          pageName="building"
          fieldName="hotelAttached"
          key="hotelAttached"
          value={props.hotelAttached as string}
        />
      ),
    },
    {
      label: t('building.hvacFiltration.label'),
      render: (
        <StringListWithSource
          key="hvacFiltration"
          pageName="building"
          fieldName="hvacFiltration"
          value={props.hvacFiltration}
          options={[
            { name: 'merv13' },
            { name: 'merv12' },
            { name: 'merv11' },
            { name: 'standard' },
          ]}
        />
      ),
    },
    {
      label: t('building.lobbyAttendantAfterHours.label'),
      render: (
        <StringListWithSource
          key="lobbyAttendantAfterHours"
          pageName="building"
          fieldName="lobbyAttendantAfterHours"
          value={props.lobbyAttendantAfterHours}
          sortOptionsByIndex
          options={[{ name: 'yesMondayToFriday' }, { name: 'yesSevenDays' }, { name: 'no' }]}
        />
      ),
    },
    {
      label: t('building.lobbyAttendantBusinessHours'),
      render: (
        <YesNoMaybeWithSource
          fieldName="lobbyAttendantBusinessHours"
          key="lobbyAttendantBusinessHours"
          pageName="building"
          value={props.lobbyAttendantBusinessHours as string}
        />
      ),
    },
    {
      label: t('building.parking.label'),
      render: (
        <StringListWithSource
          key="parking"
          pageName="building"
          fieldName="parking"
          value={props.parking}
          sortOptionsByIndex
          options={[
            { name: 'outdoor' },
            { name: 'subterranean' },
            { name: 'valet' },
            { name: 'none' },
          ]}
        />
      ),
    },
    {
      label: t('building.parkingRatio'),
      render: (
        <CountWithSource
          fieldName="parkingRatio"
          key="parkingRatio"
          pageName="building"
          value={props.parkingRatio}
        />
      ),
    },
    {
      label: t('building.passengerElevatorType.label'),
      render: (
        <StringListWithSource
          key="passengerElevatorType"
          pageName="building"
          fieldName="passengerElevatorType"
          value={props.passengerElevatorType}
          sortOptionsByIndex
          options={[
            { name: 'destinationDispatch' },
            { name: 'keycard' },
            { name: 'smart' },
            { name: 'standard' },
            { name: 'none' },
          ]}
        />
      ),
    },
    {
      label: t('building.rentableBuildingAreaOffice'),
      render: (
        <CountWithSource
          fieldName="rentableBuildingAreaOffice"
          key="rentableBuildingAreaOffice"
          pageName="building"
          value={props.rentableBuildingAreaOffice}
        />
      ),
    },
    {
      label: t('building.rentableBuildingAreaRetail'),
      render: (
        <CountWithSource
          fieldName="rentableBuildingAreaRetail"
          key="rentableBuildingAreaRetail"
          pageName="building"
          value={props.rentableBuildingAreaRetail}
        />
      ),
    },
    {
      label: t('building.rentableBuildingAreaOther'),
      render: (
        <CountWithSource
          fieldName="rentableBuildingAreaOther"
          key="rentableBuildingAreaOther"
          pageName="building"
          value={props.rentableBuildingAreaOther}
        />
      ),
    },
    {
      label: t('building.restaurant.label'),
      render: (
        <StringListWithSource
          key="restaurant"
          pageName="building"
          fieldName="restaurant"
          value={props.restaurant}
          options={[
            { name: 'openToPublic' },
            { name: 'privateToTenant' },
            { name: 'deliveryAvailable' },
          ]}
        />
      ),
    },
    {
      label: t('building.storyClassification.label'),
      render: (
        <StringListWithSource
          key="storyClassification"
          pageName="building"
          fieldName="storyClassification"
          value={props.storyClassification}
          sortOptionsByIndex
          options={[
            { name: 'lowRise' },
            { name: 'midRise' },
            { name: 'highRise' },
            { name: 'superTall' },
          ]}
        />
      ),
    },
    {
      label: t('building.walkScore'),
      render: (
        <CountWithSource
          fieldName="walkScore"
          key="walkScore"
          pageName="building"
          value={props.walkScore}
        />
      ),
    },
    {
      label: t('building.wellScore'),
      render: (
        <CountWithSource
          fieldName="wellScore"
          key="wellScore"
          pageName="building"
          value={props.wellScore}
        />
      ),
    },
  ];

  const orderedAmenities = amenities
    .sort((amenityA, amenityB) => amenityA.label.localeCompare(amenityB.label))
    .map(amenity => amenity.render);

  return <>{orderedAmenities}</>;
};
export default memo(BuildingDataCollectionList);
