import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { TFunction } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PARAMETERS } from '@root/tracking/constants';
import useAnalyticsEvent from '@root/shared/useAnalytics';
import NotFound from '@components/layouts/Truva/404/NotFound';
import actions from '@root/store/actions';
import Layout from '@components/layouts/Truva/Layout';
import { ControlledTabs, TabPane } from '@components/shared';
import routes from '@root/routes';
import { StoreState } from '@root/types';
import useCurrentUser from '@root/shared/useCurrentUser';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import s from './AccountSettings.module.less';
import ProfileForm from './ProfileForm';
import InquirySettingsForm from './InquirySettingsForm';
import { AccountSettingsTab, ValidTabs } from '.';
import EmailNotifications from './EmailNotifications';

const deprecatedTabs = (
  t: TFunction,
): Array<{
  value: AccountSettingsTab;
  label: Capitalize<AccountSettingsTab>;
  location: string;
}> => [
  {
    value: 'profile',
    label: t('profileForm.pageTitle'),
    location: `${routes.users.account}?page=profile`,
  },
  {
    value: 'inquiry',
    label: t('inquiry.pageTitle'),
    location: `${routes.users.account}?page=inquiry`,
  },
];

const tabs = (
  t: TFunction,
): Array<{
  value: AccountSettingsTab;
  label: Capitalize<AccountSettingsTab>;
  location: string;
}> => [
  {
    value: 'profile',
    label: t('profileForm.pageTitle'),
    location: `${routes.users.account}?page=profile`,
  },
  {
    value: 'inquiry',
    label: t('inquiry.pageTitle'),
    location: `${routes.users.account}?page=inquiry`,
  },
  {
    value: 'email_notifications',
    label: t('emailNotifications.pageTitle'),
    location: `${routes.users.account}?page=email_notifications`,
  },
];

export const AccountSettings = () => {
  const { t } = useTranslation('accountSettings');
  const flags = useFlags();

  const dispatch = useDispatch();
  const { pageLoaded } = useAnalyticsEvent();
  const { refetch: refetchUser } = useCurrentUser();
  const currentUser = useSelector((state: StoreState) => state.currentUser);

  const loadPage = async () => {
    await dispatch(actions.setAccountSettingsPage());
    refetchUser();
  };

  const pageQueryString = new URLSearchParams(useLocation().search).get('page');
  const tabName =
    (ValidTabs.includes(pageQueryString as AccountSettingsTab) &&
      (pageQueryString as AccountSettingsTab)) ||
    'profile';

  useEffect(() => {
    loadPage().then(() => {
      switch (tabName) {
        case 'profile':
          pageLoaded({
            pageName: PARAMETERS.profilePage,
            actionType: 'ACCOUNT_SETTINGS_PAGE_LOADED',
          });
          break;
        case 'inquiry':
          pageLoaded({
            pageName: PARAMETERS.inquiryPage,
            actionType: 'ACCOUNT_SETTINGS_PAGE_LOADED',
          });
          break;
        case 'email_notifications':
          pageLoaded({
            pageName: PARAMETERS.emailNotificationsPage,
            actionType: 'ACCOUNT_SETTINGS_PAGE_LOADED',
          });
          break;
        default:
          break;
      }
    });
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabName]);

  if (!ValidTabs.includes(pageQueryString as AccountSettingsTab)) {
    return <NotFound />;
  }

  if (isEmpty(currentUser)) {
    return null;
  }

  return (
    <Layout activePage="userAccountSettings" showFooter={false}>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className="pt-2">
        <div className={s.questionsMessage}>
          {/* eslint-disable react/jsx-no-literals */}
          <Trans i18nKey="questionsMessage" ns="accountSettings">
            Questions or issues with your account? Contact VTS Marketplace support at
            <a className="text-indigo-100" href="mailto:support@vts.com">
              support@vts.com
            </a>
            <a className="text-indigo-100" href="https://help.vts.com/hc/en-us">
              help center
            </a>
          </Trans>
          {/* eslint-enable react/jsx-no-literals */}
        </div>
        <h1 className="font-headline">{t('pageTitle')}</h1>
        <div className={s.mainContent}>
          <ControlledTabs
            className={cn(s.tabs, 'border-b-[1px] border-solid border-black-010')}
            tabClassName={s.tabListItem}
            currentTab={tabName}
            tabs={
              flags['outreach.tourbook-analytics-email-preferences'] ? tabs(t) : deprecatedTabs(t)
            }
          >
            <TabPane key="profile">
              <ProfileForm currentUser={currentUser!} />
            </TabPane>
            <TabPane key="inquiry">
              <InquirySettingsForm currentUser={currentUser!} />
            </TabPane>
            {flags['outreach.tourbook-analytics-email-preferences'] ? (
              <TabPane key="email_notifications">
                <EmailNotifications currentUser={currentUser!} />
              </TabPane>
            ) : null}
          </ControlledTabs>
        </div>
      </div>
    </Layout>
  );
};

export default AccountSettings as React.FC;
