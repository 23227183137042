import analyticsActions from './analyticsActions';
import {
  createTourbookListing,
  addListingToTourbook,
  Actions as CreateTourbookListingActions,
} from './createTourbookListing';
import {
  deleteTourbookListing,
  Actions as DeleteTourbookListingActions,
} from './deleteTourbookListing';
import {
  fetchTourbookSummaries,
  receiveTourbookSummaries,
  Actions as FetchTourbookSummariesActions,
} from './fetchTourbookSummaries';
import {
  createTourbook,
  receiveNewTourbook,
  Actions as CreateTourbookActions,
} from './createTourbook';
import {
  updateTourbook,
  receiveUpdatedTourbook,
  Actions as UpdateTourbookActions,
} from './updateTourbook';
import {
  updateTourbookListing,
  receiveUpdatedTourbookListing,
  Actions as UpdateTourbookListingActions,
} from './updateTourbookListing';
import { fetchTourbook, receiveTourbook, Actions as FetchTourbookActions } from './fetchTourbook';
import { deleteTourbook, RemoveTourbookAction } from './deleteTourbook';
import manageTourbookPageLoad from './manageTourbookPageLoad';
import {
  reorderTourbookListings,
  Actions as ReorderTourbookListingsActions,
} from './reorderTourbookListings';

export type Actions =
  | CreateTourbookActions
  | CreateTourbookListingActions
  | DeleteTourbookListingActions
  | FetchTourbookActions
  | FetchTourbookSummariesActions
  | RemoveTourbookAction
  | ReorderTourbookListingsActions
  | UpdateTourbookActions
  | UpdateTourbookListingActions;

export default {
  addListingToTourbook,
  createTourbook,
  createTourbookListing,
  deleteTourbook,
  deleteTourbookListing,
  fetchTourbook,
  fetchTourbookSummaries,
  manageTourbookPageLoad,
  receiveNewTourbook,
  receiveTourbook,
  receiveTourbookSummaries,
  receiveUpdatedTourbook,
  receiveUpdatedTourbookListing,
  reorderTourbookListings,
  updateTourbook,
  updateTourbookListing,
  ...analyticsActions,
};
