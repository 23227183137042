import { OnlyInDesktop, OnlyInTablet } from '@components/shared';
import { range } from 'lodash';
import cn from 'classnames';
import s from './AmenitiesSkeleton.module.less';

const DESKTOP_BOX_ITEMS = 6;
const TABLET_BOX_ITEMS = 4;

const AmenitiesSkeleton = () => {
  return (
    <div className={s.mainContainer}>
      <div className={cn(s.box1, s.shimmer)} />
      <div className={s.container}>
        <OnlyInDesktop>
          {range(DESKTOP_BOX_ITEMS).map(i => {
            return <div className={cn(s.box2, s.shimmer)} key={i} />;
          })}
        </OnlyInDesktop>
        <OnlyInTablet>
          {range(TABLET_BOX_ITEMS).map(i => {
            return <div className={cn(s.box2, s.shimmer)} key={i} />;
          })}
        </OnlyInTablet>
      </div>
    </div>
  );
};

export default AmenitiesSkeleton;
