import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminLandlord } from '@root/types';
import api from '@shared/api';
import routes from '@root/routes';
import { notification } from 'antd';
import { RawInput } from '@components/shared/forms';
import { QueryStringTabs, TabPane } from '@components/shared/DeprecatedTabs';
import CollapsableMediaPhotosSection from '@components/shared/Admin/EditPhotos/CollapsableMediaPhotosSection';
import MediaPhotosSection from '@components/shared/Admin/EditPhotos/MediaPhotosSection';
import { DefaultPhotoUploadType } from '@components/shared/Admin/MultimediaUploader';
import VideoSection from '@components/shared/Admin/VideoSection';
import useSuccessCheckmark from '@root/shared/useSuccessCheckmark';
import { DefaultVideoUploadType } from '@components/shared/Admin/MultiVideoUploader';
import { PopperTooltip } from '@components/shared';
import LandlordFields from './LandlordFields';
import s from './Form.module.less';
import BrandedAssets from './BrandedAssets';

type Props = {
  landlord: AdminLandlord | null;
  submitText: string;
  onSubmitSuccess: (responseBody: { id: string }) => void;
  onSaveSuccess?: (args: { message?: string }) => void;
  fetchLandlord?: () => void;
};

const CaptionInput = ({
  description,
  id,
  handleChangePhotoCaption,
}: {
  id: string;
  description: string;
  handleChangePhotoCaption: (cap: string, id: string, callback: () => void) => void;
}) => {
  const { showCheckmark, checkmarkProps, Checkmark } = useSuccessCheckmark();
  const { t } = useTranslation('admin');

  return (
    <div className={s.captionInput}>
      <RawInput
        defaultValue={description}
        onChange={() => {}}
        onBlur={e => handleChangePhotoCaption(e.currentTarget.value, id, showCheckmark)}
        data-testid={`photoCaptionInput-${id}`}
        placeholder={t('media.enterCaption')}
      />
      <Checkmark {...checkmarkProps} />
    </div>
  );
};

const Form = ({
  landlord,
  submitText,
  onSubmitSuccess,
  onSaveSuccess = () => {},
  fetchLandlord = () => {},
}: Props) => {
  const { t } = useTranslation('admin');

  const [selectedPublicRows, setSelectedPublicRows] = useState([]);
  const [selectedHiddenRows, setSelectedHiddenRows] = useState([]);
  const allSelectedRows = [...selectedPublicRows, ...selectedHiddenRows];
  const handleUploadPhotos = (uploadedFiles: DefaultPhotoUploadType[]) =>
    api.post(routes.api.admin.landlordPhotoCreate(landlord?.id), {
      photos: uploadedFiles,
    });

  const handleUpdatePhotoPosition = async (updatedPhotoList: string[]) => {
    const response = await api.put(routes.api.admin.landlordPhotoOrder(landlord?.id), {
      photoIds: updatedPhotoList,
    });

    if (response.ok) {
      onSaveSuccess({ message: t('common.photos.orderSaveSuccess') });
    } else {
      notification.error({ message: t('common.photos.orderSaveFailure') });
    }
  };

  const handleDeletePhoto = async (photoId: string) => {
    // eslint-disable-next-line no-alert
    const response = await api.delete(
      routes.api.admin.landlordPhotoDelete({ landlordId: landlord?.id, photoId }),
    );

    if (response.ok) {
      onSaveSuccess({ message: t('landlord.photoDeleteSuccess') });
      clearSelectedPhotos();
    } else {
      notification.error({ message: t('landlord.photoDeleteFailure') });
    }
  };

  const handleFirstPhotoOrientationChange = async (newOrientation: string) => {
    await api.put(routes.api.admin.landlord(landlord?.id), {
      firstPhotoOrientation: newOrientation,
      skipMarketChanges: true,
    });
    onSaveSuccess({
      message:
        newOrientation === 'landscape' ? t('media.landscapeSuccess') : t('media.portraitSuccess'),
    });
  };

  const videoUploadRequest = (uploadedFile: DefaultVideoUploadType) =>
    api.post(routes.api.admin.landlordVideoCreate(landlord?.id), {
      cloudinaryId: uploadedFile.cloudinaryId,
      filename: uploadedFile.filename,
      processed_at: uploadedFile.processedAt,
    });

  const handleVideoDelete = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('media.deleteConfirmation'))) {
      const resp = await api.delete(
        routes.api.admin.landlordVideoDelete({
          landlordId: landlord?.id,
          videoId: landlord?.video?.id,
        }),
      );
      const json = await resp.json();
      if (resp.ok) {
        fetchLandlord();
        notification.success({ message: json.message });
      } else {
        notification.error({ message: json.errors[0].message });
      }
    }
  };

  const handleVideoSelect = async (videoId: string) => {
    const resp = await api.put(
      routes.api.admin.landlordVideoUpdate({ landlordId: landlord!.id, videoId }),
      {
        archived: false,
      },
    );
    if (resp.ok) {
      fetchLandlord();
      notification.success({ message: t('media.videoSelectSuccess') });
    } else {
      notification.success({ message: t('media.videoSelectFailure') });
    }
  };

  const handleVideoHide = async (videoId: string) => {
    const resp = await api.put(
      routes.api.admin.landlordVideoUpdate({ landlordId: landlord!.id, videoId }),
      {
        archived: true,
      },
    );
    if (resp.ok) {
      fetchLandlord();
      notification.success({ message: t('media.videoHideSuccess') });
    } else {
      notification.success({ message: t('media.videoHideFailure') });
    }
  };

  const handlePosterUpdate = async (posterStartOffset: number | null) => {
    const resp = await api.put(
      routes.api.admin.landlordVideoUpdate({
        landlordId: landlord?.id,
        videoId: landlord?.video?.id,
      }),
      { posterStartOffset },
    );
    return resp;
  };

  const handleChangePhotoCaption = async (caption, photoId, showCheckmark) => {
    const response = await api.put(
      routes.api.admin.landlordPhotoUpdate({ photoId, landlordId: landlord!.id }),
      {
        description: caption,
      },
    );
    if (response.ok) {
      fetchLandlord();
      showCheckmark();
    }
  };

  const handleMakePhotosPublic = async photoIds => {
    const response = await api.put(routes.api.admin.landlordPhotosArchiveToggle(landlord!.id), {
      photo_ids: photoIds,
      archive: false,
    });

    if (response.ok) {
      notification.success({
        message: t('media.makePhotosPublicSuccess', { count: photoIds.length }),
      });

      fetchLandlord();
      return Promise.resolve();
    }
    notification.error({
      message: t('media.makePhotosPublicFailure', { count: photoIds.length }),
    });
    return Promise.reject();
  };

  const handleHidePhotos = async photoIds => {
    const response = await api.put(routes.api.admin.landlordPhotosArchiveToggle(landlord!.id), {
      photo_ids: photoIds,
      archive: true,
    });

    if (response.ok) {
      notification.success({
        message: t('media.hidePhotosSuccess', { count: photoIds.length }),
      });
      fetchLandlord();
      return Promise.resolve();
    }
    notification.error({
      message: t('media.hidePhotosFailure', { count: photoIds.length }),
    });
    return Promise.reject();
  };

  const clearSelectedPhotos = () => {
    setSelectedPublicRows([]);
    setSelectedHiddenRows([]);
  };

  return (
    <QueryStringTabs destroyInactiveTabPane tabs={['form', 'assets', 'media']}>
      <TabPane key="form" tab={t(`landlord.menuItems.form`)}>
        <LandlordFields
          landlord={landlord}
          submitText={submitText}
          onSubmitSuccess={onSubmitSuccess}
        />
      </TabPane>
      <TabPane
        disabled={!landlord}
        key="assets"
        tab={
          landlord ? (
            <span>{t(`landlord.menuItems.brandedAssets`)}</span>
          ) : (
            <PopperTooltip
              trigger="hover"
              toolTipPlacement="top"
              triggerElement={<span>{t(`landlord.menuItems.brandedAssets`)}</span>}
              popperElementClassName="!bg-[#000000]"
              popperElement={
                <span className="!text-white font-body-large">{t(`landlord.disabledTab`)}</span>
              }
            />
          )
        }
      >
        {landlord ? (
          <BrandedAssets landlord={landlord} fetchLandlord={fetchLandlord} />
        ) : (
          t('landlord.landlordBeforeMedia')
        )}
      </TabPane>
      <TabPane
        key="media"
        disabled={!landlord}
        tab={
          landlord ? (
            <span>{t(`landlord.menuItems.media`)}</span>
          ) : (
            <PopperTooltip
              trigger="hover"
              toolTipPlacement="top"
              triggerElement={<span>{t(`landlord.menuItems.media`)}</span>}
              popperElementClassName="!bg-[#000000]"
              popperElement={
                <span className="!text-white font-body-large">{t(`landlord.disabledTab`)}</span>
              }
            />
          )
        }
        className="u-p-top-1x"
      >
        {landlord ? (
          <>
            <MediaPhotosSection
              title={t('landlord.media.photos')}
              description={t('landlord.media.photosDescription')}
              photos={landlord.photos}
              fetchRecord={fetchLandlord}
              onPhotoUpload={handleUploadPhotos}
              onUpdatePhotoPosition={handleUpdatePhotoPosition}
              onDeletePhoto={handleDeletePhoto}
              onFirstPhotoOrientationChange={handleFirstPhotoOrientationChange}
              firstPhotoOrientation={landlord.firstPhotoOrientation}
              handleHidePhotos={handleHidePhotos}
              setSelectedRows={setSelectedPublicRows}
              selectedRows={selectedPublicRows}
              selectedDownloadRows={!allSelectedRows.length}
              uploadSizeLimitInMb={20}
              downloadUrl={routes.api.admin.landlordPhotosZipDownload(
                landlord!.id,
                allSelectedRows,
              )}
              downloadButtonOnClick={clearSelectedPhotos}
              additionalColumn={{
                title: t('media.captionTitle'),
                render: ({ description, id }) => (
                  <CaptionInput
                    handleChangePhotoCaption={handleChangePhotoCaption}
                    id={id}
                    description={description}
                  />
                ),
              }}
            />
            <div className="u-p-top-5x u-p-bottom-5x">
              <CollapsableMediaPhotosSection
                title={t('media.buildingArchivedPhotosTitle')}
                description={t('landlord.media.archivedPhotosDescription')}
                photos={landlord!.archivedPhotos}
                makePhotosPublic={handleMakePhotosPublic}
                setSelectedRows={setSelectedHiddenRows}
                selectedRows={selectedHiddenRows}
                onDeletePhoto={handleDeletePhoto}
              />
            </div>
            <div className="u-p-top-5x">
              <VideoSection
                data-testid="video-table-container"
                key={landlord.video?.id}
                video={landlord.video}
                archivedVideos={landlord.archivedVideos}
                onSuccess={fetchLandlord}
                spotlightChecked={false}
                videoTableTitle={t('landlord.media.video')}
                videoHelpText={t('landlord.media.videoHelpText')}
                videoSelectText={t('landlord.media.selectVideo')}
                videoUploadRequest={videoUploadRequest}
                onVideoDelete={handleVideoDelete}
                onVideoSelect={handleVideoSelect}
                onVideoHide={handleVideoHide}
                onPosterUpdate={handlePosterUpdate}
              />
            </div>
          </>
        ) : (
          t('landlord.landlordBeforeMedia')
        )}
      </TabPane>
    </QueryStringTabs>
  );
};

export default Form;
