/* eslint-disable react/prop-types */
import { Component } from 'react';
import { stringify } from 'qs';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import renderingInServer from '@shared/renderingInServer';
import { trackIfIsInIframe } from '@shared/iframeUtils';
import deeplyCamelize from '@shared/deeplyCamelize';
import configureStore from '@store/configureStore';
import configureTranslations from '@root/configureTranslations';
import configureLaunchDarkly from '@root/configureLaunchDarkly';
import { EnvProvider } from '@shared/useEnv';
import { CloudinaryContextProvider, getCloudinary } from '@shared/useCloudinary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BreakpointProvider } from '@root/shared/useBreakpoints';
import {
  preloadedDataType,
  configType,
  notificationsOnLoadType,
  launchDarklyAllFlagsStateType,
} from '@propTypes';
import App from './App';

// remove once antd upgrade to v4 is complete
import '@ant-design/compatible/assets/index.css';

class Truva extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    queryParameters: PropTypes.objectOf(PropTypes.string),
    entity: PropTypes.string,
    data: preloadedDataType,
    truvaConfig: configType.isRequired,
    notificationsOnLoad: notificationsOnLoadType,
    launchDarklyAllFlagsState: launchDarklyAllFlagsStateType,
    env: PropTypes.shape({
      railsEnv: PropTypes.oneOf(['test', 'production', 'development']),
      googleMapsKey: PropTypes.string,
      googleReCAPTCHAKey: PropTypes.string,
      cloudinaryCloud: PropTypes.string,
      cloudinaryUploadPreset: PropTypes.string,
      cloudinaryVtsImageCdn: PropTypes.string,
      cloudinaryVtsImageCloud: PropTypes.string,
      cloudinaryVtsImageUploadPreset: PropTypes.string,
      launchDarklyClientSideId: PropTypes.string,
      marketoAccountId: PropTypes.string,
    }),
  };

  static defaultProps = {
    data: {},
    queryParameters: {},
    entity: null,
    notificationsOnLoad: {},
    env: {
      googleMapsKey: '',
      cloudinaryCloud: '',
      cloudinaryUploadPreset: '',
    },
    launchDarklyAllFlagsState: {},
  };

  constructor(props) {
    super(props);

    const { data, entity, truvaConfig, env, queryParameters, launchDarklyAllFlagsState } = props;
    const { locale, translationsJSON, enums } = truvaConfig;

    this.store = configureStore(
      {},
      deeplyCamelize(data),
      entity,
      enums,
      env.railsEnv,
      launchDarklyAllFlagsState['vts-trackable-parameters'], // Remove this argument when removing vts-trackable-parameters
    );

    const translations = JSON.parse(translationsJSON);

    configureTranslations(locale, translations);
    this.queryClient = new QueryClient();

    trackIfIsInIframe(queryParameters, env.vtsUrl);
  }

  render() {
    const {
      url,
      queryParameters,
      notificationsOnLoad,
      env,
      launchDarklyAllFlagsState,
      truvaConfig: { locale },
    } = this.props;

    const LaunchedDarklyApp = configureLaunchDarkly(
      App,
      launchDarklyAllFlagsState,
      env.launchDarklyClientSideId,
      env.railsEnv,
    );

    return (
      <QueryClientProvider client={this.queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <CloudinaryContextProvider value={getCloudinary(env)}>
          <BreakpointProvider>
            <EnvProvider value={{ ...env, locale }}>
              <Provider store={this.store}>
                {renderingInServer() ? (
                  <StaticRouter location={{ pathname: url, search: stringify(queryParameters) }}>
                    <LaunchedDarklyApp />
                  </StaticRouter>
                ) : (
                  <BrowserRouter>
                    <LaunchedDarklyApp notificationsOnLoad={notificationsOnLoad} />
                  </BrowserRouter>
                )}
              </Provider>
            </EnvProvider>
          </BreakpointProvider>
        </CloudinaryContextProvider>
      </QueryClientProvider>
    );
  }
}

export default Truva;
