import { Button, CustomIcon, InvisibleMask } from '@components/shared';
import { RawSelect } from '@components/shared/forms';
import { memo, useState } from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ValidIconTypes } from '@components/shared/CustomIcon';

type Option = {
  label: string;
  icon?: ValidIconTypes;
  hidden?: boolean;
  onClick?: () => void;
};
export type Props = {
  className?: string;
  options: Option[];
};

const EditCoverPhotoDropdown = ({ className, options }) => {
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const { t } = useTranslation('tourbook');
  const visibleOptions = options.filter(({ hidden }) => !hidden);

  return (
    <>
      <RawSelect
        className={className}
        name="tourbook-contents-dropdown"
        options={visibleOptions}
        onChange={() => {}}
        menuIsOpen={menuIsOpen}
        components={{
          Option: props => {
            const { label, icon, onClick } = props.data;
            return (
              <components.Option {...props}>
                <div
                  onClick={() => {
                    onClick();
                    setMenuIsOpen(false);
                  }}
                  role="button"
                  className="flex items-center gap-1 px-2 py-1 font-body-medium hover:bg-egg-50 focus:bg-egg-50"
                >
                  {icon ? <CustomIcon type={icon} /> : null}
                  {label}
                </div>
              </components.Option>
            );
          },
          SelectContainer: props => (
            <components.SelectContainer {...props}>
              <Button
                type="tertiary"
                size="small"
                className={cn(
                  'flex items-center gap-1 hover:!bg-white focus:!bg-white',
                  menuIsOpen ? '!bg-white' : '!bg-white/80',
                )}
                onClick={() => setMenuIsOpen(true)}
              >
                <CustomIcon type="edit" />
                {t('header.editCoverPhoto')}
              </Button>
              {props.children}
            </components.SelectContainer>
          ),
          Control: () => null,
        }}
        styles={{
          menu: provided => ({
            ...provided,
            width: '240px',
            right: 0,
          }),
          option: provided => ({
            ...provided,
            padding: 0,
            color: 'inherit',
            backgroundColor: 'inherit',
          }),
        }}
      />
      {menuIsOpen && <InvisibleMask onClick={() => setMenuIsOpen(false)} />}
    </>
  );
};

export default memo(EditCoverPhotoDropdown);
