import { useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormikContext, ErrorMessage } from 'formik';
import { Button } from '@components/shared';
import { MultipathImage } from '@root/types';
import api from '@shared/api';
import { useTranslation } from 'react-i18next';
import { omit, uniqueId, merge } from 'lodash';
import { v4 as uuid } from 'uuid';
import useEnv from '@root/shared/useEnv';
import cn from 'classnames';
import { FormValues, ImagesContext, MAX_IMAGE_COUNT } from '..';
import Images from './Images';
import s from '../TourbookExternalListing.module.less';

const useMedia = () => {
  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched: { photos: isPhotosTouched },
    values,
    validateField,
  } = useFormikContext<FormValues>();

  const { cloudinaryCloud, cloudinaryUploadPreset } = useEnv();

  const uploader = useRef<HTMLInputElement | null>(null);
  const { images, setImages } = useContext(ImagesContext);
  const [updateQueue, setUpdateQueue] = useState<any[]>([]);
  const [droppableId] = useState(uniqueId('TourbookExternalListingMedia-'));

  const noMoreImages = images.length >= MAX_IMAGE_COUNT;
  const bufferToBase64 = (buffer: ArrayBuffer) =>
    btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));

  const commit = (photos: Array<Omit<MultipathImage, 'type'>>) => {
    const uploadedPhotos = photos.filter(photo => photo.path?.startsWith('http'));
    setFieldValue('photos', uploadedPhotos);
    setFieldTouched('photos', true, true);
    validateField('photos');
  };

  const reorderImages = ({ source, destination }) => {
    if (!destination) return;

    const from = source.index;
    const to = destination.index;

    const reordered = [...images];
    const [removed] = reordered.splice(from, 1);
    reordered.splice(to, 0, removed);
    setImages(reordered);
    commit(reordered.map(i => i.image));
  };

  const removeImage = id => {
    const newImages = images.filter(img => img.id !== id);
    setImages(newImages);
    commit(newImages.map(i => i.image));
  };

  const uploadImage = async (id, file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', cloudinaryUploadPreset || '');

    const route = `https://api.cloudinary.com/v1_1/${cloudinaryCloud}/image/upload`;
    const response = await api.fetch(route, { method: 'POST', body: formData });
    const { url, public_id: cloudinaryId } = await response.json();
    return { id, cloudinaryId, url };
  };

  const toggleAsFloorPlan = (id, e) => {
    // On initial image upload, we fetch the filename and store it with image.name
    // and image.description. When we persist the image, we only store image.description
    // In the event that an image is set as the floor plan, its description is
    // overridden with 'Floor plan' and therefore we lose its original filename after
    // it's persisted
    const maybeFloorPlanImage = images.find(img => img.id === id);
    const floorPlanProperties = e.target.checked
      ? { image: { description: 'Floor plan' }, isFloorPlan: true }
      : {
          image: {
            description:
              maybeFloorPlanImage?.name === 'Floor plan'
                ? 'Listing Photo'
                : maybeFloorPlanImage?.name,
          },
          isFloorPlan: false,
        };
    const newImages = images.map(img =>
      img.id === id
        ? merge(img, floorPlanProperties)
        : merge(img, {
            image: { description: img.name === 'Floor plan' ? 'Listing Photo' : img.name },
            isFloorPlan: false,
          }),
    );
    setImages(newImages);
    commit(newImages.map(i => i.image));
  };

  const processFiles = async (files: FileList) => {
    if (images.length === MAX_IMAGE_COUNT) return;
    const imageData = await Promise.all(
      Array.from(files)
        .filter((f: File) => f.type.startsWith('image/') || f.type === 'application/pdf')
        .map(async (f: File) => {
          const imageBuffer = await f.arrayBuffer();
          const encodedImage = bufferToBase64(imageBuffer);
          const id = uuid();
          const imageURI = `data:${f.type};id=${id};base64,${encodedImage}`;

          return {
            id, // this is arbitrary as long as they're unique
            name: f.name,
            type: f.type,
            image: {
              path: imageURI,
              smallPath: imageURI,
              mediumPath: imageURI,
              largePath: imageURI,
              rawPath: imageURI,
              downloadPath: imageURI,
              description: f.name,
              altText: f.name,
              cloudinaryId: id,
            },
            isFloorPlan: false,
            isUploaded: false,
            file: f,
          };
        }),
    );

    setImages([...images, ...imageData.map(img => omit(img, ['file']))]);

    Promise.all(imageData.map(img => uploadImage(img.id, img.file))).then(updates => {
      setUpdateQueue([...updateQueue, ...updates]);
    });
  };

  const selectFile = async e => {
    e?.preventDefault();
    e?.stopPropagation();
    const files = uploader.current?.files;
    if (!files) return;
    processFiles(files);
  };

  const dropFile = files => {
    processFiles(files);
  };

  const {
    getRootProps,
    getInputProps,
    open: openFileSelect,
    isDragActive,
  } = useDropzone({
    noClick: true,
    onDrop: dropFile,
    accept: { 'image/*': ['application/pdf'] },
    maxFiles: MAX_IMAGE_COUNT,
  });

  useEffect(() => {
    const photos = values.photos || [];
    setImages(
      photos.map((photo: Omit<MultipathImage, 'type'>) => ({
        name: photo.description,
        type: 'image/*',
        image: photo,
        id: uuid(),
        isFloorPlan: photo.description.toLowerCase() === 'floor plan',
        isUploaded: photo.path?.startsWith('http'),
      })),
    );
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!updateQueue.length) return;
    const updates = images.map(img => {
      const queued = updateQueue.find(q => q.id === img.id);
      if (!queued) return img;
      const { url, cloudinaryId } = queued;
      const cloudinaryImage = {
        path: url?.replace(`/image/upload`, `/image/upload/f_auto/t_large_image`),
        smallPath: url?.replace(`/image/upload`, `/image/upload/f_auto/t_small_image`),
        mediumPath: url?.replace(`/image/upload`, `/image/upload/f_auto/t_medium_image`),
        largePath: url?.replace(`/image/upload`, `/image/upload/f_auto/t_large_image`),
        rawPath: url,
        downloadPath: url,
        description: img.name,
        altText: img.name,
        cloudinaryId,
      };
      return {
        ...img,
        image: cloudinaryImage,
        isFloorPlan: false,
        isUploaded: true,
      };
    });
    setUpdateQueue([]);
    setImages(updates);
    commit(updates.map(update => update.image));
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQueue]);

  return {
    droppableId,
    commit,
    reorderImages,
    photosHasDisplayableErrors: !!isPhotosTouched && !!errors.photos,
    removeImage,
    toggleAsFloorPlan,
    selectFile,
    dropFile,
    noMoreImages,
    getRootProps,
    isDragActive,
    openFileSelect,
    getInputProps,
    uploader,
    images,
  };
};

type RefFn = (node: Element | undefined | null) => void;
type Props = {
  containerRef?: RefFn | null;
};
export const MediaFields = ({ containerRef = null }: Props) => {
  const {
    droppableId,
    reorderImages,
    photosHasDisplayableErrors,
    removeImage,
    toggleAsFloorPlan,
    selectFile,
    noMoreImages,
    getRootProps,
    isDragActive,
    openFileSelect,
    getInputProps,
    images,
    uploader,
  } = useMedia();
  const { t } = useTranslation('tourbook');
  const tm = message => t(`externalListing.media.${message}`);

  return (
    <section ref={containerRef} id="media-fields">
      <div className={s.locationLabel}>{`${tm('heading')} (${
        images.length
      }/${MAX_IMAGE_COUNT})`}</div>
      <div
        className={cn(
          s.dropzone,
          photosHasDisplayableErrors && s.hasError,
          noMoreImages && s.disabled,
          isDragActive ? s.isDropping : null,
        )}
        data-testid="media-dropzone"
        {...getRootProps()}
      >
        <div>
          <Button
            className={cn(s.upload, noMoreImages && s.disabled)}
            type="secondary"
            size="small"
            icon="uploadOutlined"
            disabled={noMoreImages}
            onClick={openFileSelect}
          >
            {tm('upload')}
            <input
              type="file"
              className={s.uploader}
              name="upload"
              ref={uploader}
              onChange={selectFile}
              disabled={noMoreImages}
              data-testid="media-upload"
              {...getInputProps()}
            />
          </Button>
        </div>
        <div className={s.droptext}>{tm('dragDrop')}</div>
      </div>
      <ErrorMessage name="photos">{msg => <span className={s.error}>{msg}</span>}</ErrorMessage>
      <Images
        droppableId={droppableId}
        reorderImages={reorderImages}
        images={images}
        removeImage={removeImage}
        toggleAsFloorPlan={toggleAsFloorPlan}
      />
    </section>
  );
};

export default MediaFields;
