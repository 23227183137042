import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import ListingDataList, { ListingEngagementMetadata } from './ListingDataList';

type Props = { listingMetadata: ListingEngagementMetadata[]; relatedListingIds: string[] };

const ChartLegend = ({ listingMetadata, relatedListingIds }: Props) => {
  const { t } = useTranslation('tourbook');

  return (
    <div
      data-testid="chartLegend"
      className={cn(
        'absolute bottom-0 left-0 right-0 top-0 flex flex-col rounded-md border-[1px] border-solid border-black-010 bg-background-primary mobile:static mobile:max-h-32',
      )}
    >
      <section className="border-b-[1px] border-solid border-black-010 p-1 font-body-medium-emphasis">
        {t('analytics.chartLegend', { count: listingMetadata.length })}
      </section>
      <section className="h-full overflow-y-auto p-1 pb-0">
        <ListingDataList listingsData={listingMetadata} relatedListingIds={relatedListingIds} />
      </section>
    </div>
  );
};

export default ChartLegend;
