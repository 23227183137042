import { useTranslation } from 'react-i18next';
import { AdminRoom } from '@root/types';
import ListingRoomSection from './ListingRoomSection';
import s from './ListingRooms.module.less';

type Props = {
  rooms: AdminRoom[];
  listingId: string;
  fetchListing: Function;
};

const ListingRooms = ({ listingId, rooms, fetchListing }: Props) => {
  const { t } = useTranslation('admin');

  const filteredRoomsByType = {
    reception_area: [] as any,
    main_area: [] as any,
    kitchen: [] as any,
    breakout_area: [] as any,
    game_room_lounge: [] as any,
    room: [] as any,
    copy_room: [] as any,
    call_room: [] as any,
    bathroom: [] as any,
    balcony: [] as any,
    storage_room: [] as any,
    server_room: [] as any,
  };

  rooms.forEach(room => {
    filteredRoomsByType[room.roomType].push(room);
  });

  return (
    <div className={s.container}>
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.reception_area}
        header={t('listing.rooms.receptionAreas')}
        roomType="reception_area"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.main_area}
        header={t('listing.rooms.mainAreas')}
        roomType="main_area"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.kitchen}
        header={t('listing.rooms.kitchens')}
        roomType="kitchen"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.breakout_area}
        header={t('listing.rooms.breakoutAreas')}
        roomType="breakout_area"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.game_room_lounge}
        header={t('listing.rooms.gameRooms')}
        roomType="game_room_lounge"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.room}
        header={t('listing.rooms.rooms')}
        roomType="room"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.copy_room}
        header={t('listing.rooms.copyRooms')}
        roomType="copy_room"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.call_room}
        header={t('listing.rooms.callRooms')}
        roomType="call_room"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.bathroom}
        header={t('listing.rooms.bathrooms')}
        roomType="bathroom"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.balcony}
        header={t('listing.rooms.balconies')}
        roomType="balcony"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.storage_room}
        header={t('listing.rooms.storageRooms')}
        roomType="storage_room"
      />
      <ListingRoomSection
        fetchListing={fetchListing}
        listingId={listingId}
        rooms={filteredRoomsByType.server_room}
        header={t('listing.rooms.serverRooms')}
        roomType="server_room"
      />
    </div>
  );
};

export default ListingRooms;
