import Select, { ActionMeta } from 'react-select';
import { OptionTypeBase, ValueType, OptionType } from './types';
import DropdownStyles from './DropdownStyles';
import SelectComponents from './SelectComponents';

type Props = {
  name: string;
  onChange: (val: ValueType<OptionTypeBase>, action: ActionMeta<OptionType>) => void;
  options: OptionType[];
  placeholder: string;
  value: OptionType[];
};

const FilterDropdown = ({ name, options, value, placeholder, onChange }: Props) => {
  // Filter options by looking at the start of each word.
  const filterOption = (candidate, input) => {
    return candidate.label
      .split(/[ ,-]+/)
      .some(label => label.toLowerCase().startsWith(input.toLowerCase()));
  };
  return (
    <div data-testid={`select-container-${name}`}>
      <Select
        styles={DropdownStyles}
        value={value}
        isMulti
        isClearable={false}
        name={name}
        placeholder={placeholder || ''}
        components={SelectComponents}
        options={options}
        maxMenuHeight={208}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        tabSelectsValue={false}
        filterOption={filterOption}
        onChange={onChange}
      />
    </div>
  );
};

export default FilterDropdown;
