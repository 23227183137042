import { ReactNode } from 'react';
import { CustomIcon } from '@components/shared';
import s from './Filters.module.less';

type Props = {
  title: string;
  children: ReactNode;
  accordionIsOpen: boolean;
  setAccordionIsOpen: (isOpen: boolean) => void;
};

export const AccordionFilterGroup = ({
  title,
  children,
  accordionIsOpen,
  setAccordionIsOpen,
}: Props) => {
  return (
    <>
      <li className={s.collapsibleFilterListItemContainer}>
        <button
          className={s.titleContainer}
          type="button"
          onClick={() => setAccordionIsOpen(!accordionIsOpen)}
        >
          <div className={s.title}>{title}</div>
          <CustomIcon type={accordionIsOpen ? 'chevron-up' : 'chevron-down'} />
        </button>
        <div data-testid={`filterGroup-${title.toLowerCase()}`} className={s.accordionContent}>
          {accordionIsOpen && children}
        </div>
      </li>
    </>
  );
};

export default AccordionFilterGroup;
