import { useTranslation } from 'react-i18next';
import { RawInput, RawLabel } from '@components/shared/forms';
import s from './ContentShootDetails.module.less';

type Props = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const PropertyContentContact = ({ firstName, lastName, email, phone }: Props) => {
  const { t } = useTranslation('admin');

  const noop = () => {};

  return (
    <div className={s.section}>
      <span className={s.sectionTitle}>
        {t('media.contentShootDetails.propertyContentContact.title')}
      </span>
      <span className={s.propertyContentContactSubtext}>
        {t('media.contentShootDetails.propertyContentContact.subtext')}
      </span>
      <div>
        <div>
          <div className={s.propertyContentContactDetail}>
            <div>
              <RawLabel name="contactFirstName">
                {t('media.contentShootDetails.propertyContentContact.firstName')}
              </RawLabel>
            </div>
            <RawInput
              name="contactFirstName"
              className={s.detail}
              disabled
              value={firstName}
              onChange={noop}
            />
          </div>
          <div className={s.propertyContentContactDetail}>
            <div>
              <RawLabel name="contactLastName">
                {t('media.contentShootDetails.propertyContentContact.lastName')}
              </RawLabel>
            </div>
            <RawInput
              name="contactLastName"
              className={s.detail}
              disabled
              value={lastName}
              onChange={noop}
            />
          </div>
        </div>
        <div>
          <div className={s.propertyContentContactDetail}>
            <div>
              <RawLabel name="contactEmail">
                {t('media.contentShootDetails.propertyContentContact.email')}
              </RawLabel>
            </div>
            <RawInput
              name="contactEmail"
              className={s.detail}
              disabled
              value={email}
              onChange={noop}
            />
          </div>
          <div className={s.propertyContentContactDetail}>
            <div>
              <RawLabel name="contactPhone">
                {t('media.contentShootDetails.propertyContentContact.phone')}
              </RawLabel>
            </div>
            <RawInput
              name="contactPhone"
              className={s.detail}
              disabled
              value={phone}
              onChange={noop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyContentContact;
