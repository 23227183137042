import * as React from 'react';
import cn from 'classnames';
import EmptyImagePlaceholder from '@components/shared/images/empty-image-placeholder.svg';
import { CustomIcon } from '@components/shared';
import { Room as IRoom } from '@root/types';
import Amenities from './Amenities';
import s from './Room.module.less';

type Props = {
  room: IRoom;
  onClick?: React.EventHandler<React.SyntheticEvent>;
};

const Room = ({ room, onClick }: Props) => (
  <>
    <div role="button" className={cn(onClick && s.roomImageButton)} onClick={onClick}>
      <figure className={s.imageContainer}>
        {room.photos.length > 0 ? (
          <img
            className={s.image}
            src={room.photos[0].smallPath}
            alt={room.photos[0].description}
          />
        ) : (
          <EmptyImagePlaceholder
            style={{ display: 'block', borderRadius: '3px' }}
            width="100%"
            height="100%"
            data-testid="emptyImagePlaceholder"
          />
        )}
        {room.capacity ? (
          <div className={s.chip}>
            <CustomIcon type="person" className={s.chipIcon} />
            {room.capacity}
          </div>
        ) : null}
      </figure>
    </div>
    <div className={s.roomText}>
      {room.name}
      <Amenities amenities={room.amenities} />
    </div>
  </>
);

export default Room;
