import { createRef, PureComponent } from 'react';
import { ButtonLink, Breadcrumbs } from '@components/shared';
import { parse } from 'qs';
import classNames from 'classnames';
import api from '@shared/api';
import {
  AdminListing,
  ListingAmenitiesMetadata,
  ListingDataCollectionMetadata,
  ListingAmenitySet,
} from '@root/types';
import { InlineNotice } from '@viewthespace/components';
import { Link } from 'react-router-dom';
import routes from '@root/routes';
import { isEmpty } from 'lodash/fp';
import { Trans, withTranslation } from 'react-i18next';
import { Alert, notification, Menu } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Container from '@components/shared/Admin/Container';
import { matchType, locationType, historyType } from '@propTypes';
import { QueryStringTabs, TabPane } from '@components/shared/DeprecatedTabs';
import ListingMediaTable from './ListingMediaTable';
import ListingRooms from './ListingRooms';
import ListingForm from './ListingForm';
import ListingContacts from './ListingContacts';
import AskingRentForm from './AskingRentForm';
import Amenities from './Amenities';
import DataCollectionListingForm from './DataCollection';
import s from './ListingFormContainer.module.less';
import {
  formatAmenityPayload,
  formatDataCollectionPayload,
} from './utils/updateAmenitySetBodyBuilder';
import Layout from '../Layout/Layout';

const {
  api: {
    admin: { listing: apiAdminListingRoute, listingAmenitySet: listingAmenitySetRoute },
  },
  admin: { building: adminBuildingRoute, listingSearch: adminListingSearchRoute },
} = routes;

const TABS = ['form', 'amenities', 'dataCollection', 'media', 'rooms', 'contacts', 'rent'];

type RawListingFormContainerProps = {
  t: Function;
  match: matchType.isRequired;
  refreshRequestedListingsCount: Function;
  location: locationType;
  history: historyType;
  flags: Object;
};
export class RawListingFormContainer extends PureComponent<RawListingFormContainerProps> {
  listingForm = createRef<HTMLDivElement>();

  state: { listing: AdminListing | null; errors: { field: string; message: string }[] } = {
    listing: null,
    errors: [],
  };

  componentDidMount() {
    this.fetchListing();
  }

  setListingState(listing: AdminListing) {
    this.setState(state => ({
      ...state,
      listing,
    }));
  }

  setListingAmenitySetState(amenitySet: ListingAmenitySet) {
    this.setState((state: { listing: AdminListing | null }) => ({
      ...state,
      listing: { ...state.listing, amenitySet },
    }));
  }

  formatNotificationErrorMessages = (messages: string[]) => (
    <div>
      {messages.map(message => (
        <p className={s.notificationErrorMessage} key={message}>
          {message}
        </p>
      ))}
    </div>
  );

  updateListing = async (
    listingId: string,
    body: AdminListing & { amenitySet: {} },
    message: string,
    successCallback: Function = () => {},
  ) => {
    const { refreshRequestedListingsCount } = this.props;
    const resp = await api.put(apiAdminListingRoute(listingId), body);
    const json = await resp.json();

    if (resp.ok) {
      this.setListingState(json);
      refreshRequestedListingsCount();
      this.setState({ errors: null });
      successCallback();
      notification.success({ message });
    } else if (!isEmpty(json.errors)) {
      this.setState({ errors: json.errors }, () => {
        this.listingForm.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    } else {
      notification.error({ message: this.formatNotificationErrorMessages(json.messages) });
    }
  };

  updateAmenitySet = async ({
    listingId,
    body,
    message,
    successCallback = () => {},
  }: {
    listingId: string;
    body: ListingAmenitiesMetadata | ListingDataCollectionMetadata;
    message: string;
    successCallback?: Function;
  }) => {
    const { refreshRequestedListingsCount } = this.props;

    const resp = await api.put(listingAmenitySetRoute(listingId), body);
    const json = await resp.json();
    if (resp.ok) {
      this.setListingAmenitySetState(json);
      refreshRequestedListingsCount();
      this.setState({ errors: null });
      successCallback();
      notification.success({ message });
    } else if (!isEmpty(json.errors)) {
      this.setState({ errors: json.errors }, () => {
        this.listingForm.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    } else {
      notification.error({ message: this.formatNotificationErrorMessages(json.messages) });
    }
  };

  fetchListing = async () => {
    const { match } = this.props;
    const {
      params: { id: listingId },
    } = match;

    const resp = await api.fetch(apiAdminListingRoute(listingId));
    const listing = await resp.json();
    this.setListingState(listing);
  };

  errorsMessage = () => {
    const { listing, errors } = this.state;
    const { t } = this.props;

    return errors.map(error => {
      if (error.field === 'status') {
        return (
          <div key={error.field}>
            <div className={s.errorMessage}>{error.message}</div>
            <Link
              className={s.viewBuildingDataText}
              to={adminBuildingRoute(listing?.building.id || '')}
              target="_blank"
            >
              {t('listing.viewBuildingData')}
            </Link>
          </div>
        );
      }
      return (
        <div key={error.field} className={s.errorMessage}>
          {error.message}
        </div>
      );
    });
  };

  renderBuildingName = (building, buildingSuite) =>
    [building.name, building.displayAddress, buildingSuite].filter(obj => obj).join(' - ');

  renderEditListingPageHeader = (listing: AdminListing, headertitle: string) => {
    const { t } = this.props;
    return (
      <>
        <div className="flex justify-between">
          <h3 className="font-subtitle">{headertitle}</h3>
          <ButtonLink
            size="small"
            type="secondary"
            href={routes.admin.building(listing.building.id)}
          >
            {t('building.editBuildingButton')}
          </ButtonLink>
        </div>
        <h1 className="mb-2 font-headline">
          {this.renderBuildingName(listing.building, listing.suite)}
        </h1>
      </>
    );
  };

  currentPage = (): string => {
    const { location } = this.props;
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });
    return queryParams.page && TABS.includes(queryParams.page as string)
      ? (queryParams.page as string)
      : 'form';
  };

  changePage = ({ key }) => {
    const {
      location: { pathname },
      history,
    } = this.props;
    history.push(`${pathname}?page=${key}`);
  };

  render() {
    const { listing, errors } = this.state;
    const { t } = this.props;
    if (!listing) return null;

    const hasErrors = !isEmpty(errors);
    const errorMessage = hasErrors ? this.errorsMessage() : null;

    const fixedHeaderProps = {
      fixedHeaderContents: (
        <div>
          <div className="flex justify-between px-[20px] py-1">
            <h3 className="font-subtitle">
              {`${t('listing.listingPage')} | ${this.renderBuildingName(
                listing.building,
                listing.suite,
              )}`}
            </h3>
            <div className="ml-auto">
              <ButtonLink
                size="small-header"
                type="secondary"
                href={routes.admin.building(listing.building.id)}
              >
                {t('building.editBuildingButton')}
              </ButtonLink>
            </div>
          </div>
          <Menu onClick={this.changePage} selectedKeys={[this.currentPage()]} mode="horizontal">
            {TABS.map(tab => (
              <Menu.Item
                className={classNames(tab === this.currentPage() && 'font-bold')}
                key={tab}
              >
                {t(`listing.menuItems.${tab}`)}
              </Menu.Item>
            ))}
          </Menu>
        </div>
      ),
      fixedHeaderClassName: '!pb-0',
    };

    return (
      <Layout {...fixedHeaderProps}>
        <Breadcrumbs
          className="mb-1"
          items={[
            { content: t('breadcrumbs.admin') },
            { content: t('breadcrumbs.listings'), link: adminListingSearchRoute },
            {
              content: this.renderBuildingName(listing.building, listing.suite),
            },
          ]}
        />
        <div ref={this.listingForm} className={s.listingForm}>
          <Container>
            {this.renderEditListingPageHeader(listing, t('listing.listingPage'))}
            {!listing.current && (
              <InlineNotice
                variant={listing.currentListingId ? 'attention' : 'error'}
                /* eslint-disable react/jsx-no-literals */
                content={
                  listing.currentListingId ? (
                    <Trans ns="admin" i18nKey="listing.nonCurrentListingNotice">
                      Warning! This is not the current listing for this space. It has since been
                      re-listed.
                      <Link to={routes.admin.listingPage(listing.currentListingId)}>
                        Edit the current listing
                      </Link>
                      .
                    </Trans>
                  ) : (
                    t('listing.nonCurrentListingAndNoCurrentListingNotice')
                  )
                }
              />
              /* eslint-enable react/jsx-no-literals */
            )}
            <QueryStringTabs tabs={TABS} destroyInactiveTabPane>
              <TabPane key="form" tab={t(`listing.menuItems.form`)}>
                {hasErrors ? (
                  <Alert message="" showIcon type="error" description={errorMessage} />
                ) : null}
                <ListingForm listing={listing} updateListing={this.updateListing} />
              </TabPane>
              <TabPane key="amenities" tab={t(`listing.menuItems.amenities`)}>
                <Amenities
                  listing={listing}
                  updateListing={(
                    listingId: string,
                    body: AdminListing & { amenitySet: {} },
                    message: string,
                    successCallback: Function = () => {},
                  ) => {
                    this.updateAmenitySet({
                      listingId,
                      body: formatAmenityPayload(body.amenitySet),
                      message,
                      successCallback,
                    });
                  }}
                />
              </TabPane>
              <TabPane key="dataCollection" tab={t(`listing.menuItems.dataCollection`)}>
                <DataCollectionListingForm
                  listing={listing}
                  updateListing={(
                    listingId: string,
                    body: AdminListing & { amenitySet: {} },
                    message: string,
                    successCallback: Function = () => {},
                  ) => {
                    this.updateAmenitySet({
                      listingId,
                      body: formatDataCollectionPayload(body.amenitySet),
                      message,
                      successCallback,
                    });
                  }}
                />
              </TabPane>
              <TabPane key="media" tab={t(`listing.menuItems.media`)}>
                <ListingMediaTable
                  fetchListing={this.fetchListing}
                  contentShootRequest={listing.contentShootRequest}
                  photos={listing.photos}
                  archivedPhotos={listing.archivedPhotos}
                  video={listing.video}
                  archivedVideos={listing.archivedVideos}
                  rooms={listing.rooms}
                  listingId={listing.id}
                  tourEmbedUrl={listing.tourEmbedUrl}
                  formatNotificationErrorMessages={this.formatNotificationErrorMessages}
                  spaceFloorPlanPhotos={listing.space.floorPlanPhotos}
                  additionalPhotos={listing.space.additionalPhotos}
                  spaceBrochure={listing.space.brochure}
                  buildingBrochure={listing.building.brochure}
                  floorPlan={listing.floorPlan}
                  archivedFloorPlans={listing.archivedFloorPlans}
                  virtualTourSpotlight={listing.virtualTourSpotlight}
                />
              </TabPane>
              <TabPane key="rooms" tab={t(`listing.menuItems.rooms`)}>
                <ListingRooms
                  listingId={listing.id}
                  rooms={listing.rooms}
                  fetchListing={this.fetchListing}
                />
              </TabPane>
              <TabPane key="contacts" tab={t(`listing.menuItems.contacts`)}>
                <ListingContacts
                  data={listing.listingAgents}
                  editable={!listing.usingBuildingContacts}
                  onSort={this.fetchListing}
                  buildingId={listing.building.id}
                />
              </TabPane>
              <TabPane key="rent" tab={t(`listing.menuItems.rent`)}>
                <AskingRentForm
                  fetchListing={this.fetchListing}
                  listingId={listing.id}
                  currency={listing.building.currency}
                  units={listing.building.units}
                  rents={listing.baseRents}
                  visibility={listing.isListingPriceVisible}
                />
              </TabPane>
            </QueryStringTabs>
          </Container>
        </div>
      </Layout>
    );
  }
}

const EnhancedListingFormContainer = withLDConsumer()(
  withTranslation('admin')(RawListingFormContainer),
);
export default EnhancedListingFormContainer;
