import { useState } from 'react';
import { Table, notification } from 'antd';
import Modal from '@components/shared/Modal';
import { useTranslation } from 'react-i18next';
import { CustomIcon } from '@components/shared';
import { AdminListingSpace, ConvertableArea } from '@root/types';
import Button from '@components/shared/V2Button';
import routes from '@root/routes';
import { formatArea } from '@shared/TranslationHelpers';
import api from '@shared/api';
import s from './RelinkArchivedSpaceModal.module.less';

const RelinkArchivedSpaceModal = ({ spaceId, buildingId, listingId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const [tableData, setTableData] = useState<[AdminListingSpace, AdminListingSpace]>();
  const [showTable, setShowTable] = useState(false);
  const [newSpaceId, setNewSpaceId] = useState<string>('');
  const [disableSubmit, setDisableSubmit] = useState(true);

  const { t } = useTranslation('admin');

  const clearSearch = () => {
    setNewSpaceId('');
  };

  const setSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSpaceId(e.target.value);
    setDisableSubmit(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      performSearch();
    }
  };

  const getSpaceDetails = async (vtsSpaceId: string) => {
    const response = await api.post(routes.api.admin.spacesRelinkPreview, {
      vtsId: vtsSpaceId,
      buildingId,
    });

    return response;
  };

  const createSpaceRow = (spaceDetails: AdminListingSpace, rowType: string) => {
    const {
      vtsId,
      vtsPropertyId,
      vtsPropertyName,
      streetAddress,
      floorName,
      suite,
      remeasuredArea,
      availabilityType,
      condition,
      floorComposition,
    } = spaceDetails;

    return {
      key: rowType,
      vtsId: `${vtsId} (${rowType} space)`,
      vtsPropertyId,
      vtsPropertyName,
      streetAddress,
      floorName,
      suite,
      remeasuredArea: remeasuredArea && formatArea(remeasuredArea as unknown as ConvertableArea),
      availabilityType,
      condition,
      floorComposition,
    };
  };

  const performSearch = async () => {
    const originalResponse = await getSpaceDetails(spaceId);
    const newResponse = await getSpaceDetails(newSpaceId);

    if (originalResponse.ok && newResponse.ok) {
      const originalSpace = await originalResponse.json();
      const newSpace = await newResponse.json();

      setTableData([createSpaceRow(originalSpace, 'original'), createSpaceRow(newSpace, 'new')]);

      setShowTable(true);

      setDisableSubmit(false);
    } else {
      setShowTable(false);
      notification.error({
        message: t('listing.relinkModal.error', { id: newSpaceId }),
      });
    }
  };

  const handleError = async response => {
    try {
      const json = await response.json();
      json.errors.forEach(error => notification.error({ message: error.message }));
    } catch {
      notification.error({
        message: t('listing.relinkModal.failure', { id: newSpaceId }),
      });
    }
  };

  const relinkListingToSpace = async () =>
    api.post(routes.api.admin.spaceRelink, {
      newSpaceId,
      listingId,
    });

  const relinkListing = async () => {
    const response = await relinkListingToSpace();

    if (response.ok) {
      closeModal();
      notification.success({
        message: t('listing.relinkModal.success', { id: newSpaceId }),
      });
    } else {
      handleError(response);
    }
  };

  const handleSubmit = () => {
    if (
      // eslint-disable-next-line no-alert
      window.confirm(t('listing.relinkModal.confirmation', { id: newSpaceId }))
    ) {
      relinkListing();
    }
  };

  return (
    <div className="u-p-top-1x">
      <Button onClick={openModal} type="tertiary" data-testid="open-modal-button">
        {t('listing.relinkButton')}
      </Button>
      <Modal className={s.modalContent} isOpen={isOpen} closeModal={closeModal}>
        <h2 id="relink-title">{t('listing.relinkModal.title')}</h2>
        <div className={s.searchSection}>
          <p>{t('listing.relinkModal.searchLabel')}</p>
          <input
            value={newSpaceId}
            onKeyDown={handleKeyDown}
            onChange={setSearchValue}
            data-testid="spaceIdSearch"
          ></input>
          {newSpaceId && (
            <button className={s.clearButton} onClick={clearSearch} type="button">
              <CustomIcon type="close-circle" />
            </button>
          )}
          <Button
            className={s.searchButton}
            onClick={performSearch}
            type="tertiary"
            data-testid="searchButton"
          >
            {t('listing.relinkModal.search')}
          </Button>
        </div>
        <div className={s.notes}>
          <p>{t('listing.relinkModal.notes')}</p>
        </div>
        {showTable && (
          <Table
            className={s.spaceTable}
            pagination={false}
            dataSource={tableData || [{}, {}]}
            columns={[
              {
                title: t('listing.vtsSpaceId'),
                dataIndex: 'vtsId',
                key: 'vtsId',
              },
              {
                title: t('listing.vtsPropertyId'),
                dataIndex: 'vtsPropertyId',
                key: 'vtsPropertyId',
              },
              {
                title: t('listing.vtsPropertyName'),
                dataIndex: 'vtsPropertyName',
                key: 'vtsPropertyName',
              },
              {
                title: t('listing.streetAddress'),
                dataIndex: 'streetAddress',
                key: 'streetAddress',
              },
              {
                title: t('listing.columnHeaders.floorName'),
                dataIndex: 'floorName',
                key: 'floorName',
              },
              {
                title: t('listing.columnHeaders.suiteName'),
                dataIndex: 'suite',
                key: 'suite',
              },
              {
                title: t('listing.size'),
                dataIndex: 'remeasuredArea',
                key: 'remeasuredArea',
              },
              {
                title: t('listing.availabilityStatus'),
                dataIndex: 'availabilityType',
                key: 'availabilityType',
              },
              {
                title: t('listing.condition'),
                dataIndex: 'condition',
                key: 'condition',
              },
              {
                title: t('listing.floorComposition'),
                dataIndex: 'floorComposition',
                key: 'floorComposition',
              },
            ]}
          />
        )}
        <div className={s.submitButton}>
          <Button onClick={handleSubmit} disabled={disableSubmit} data-testid="submitButton">
            {t('listing.relinkModal.submitButton')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default RelinkArchivedSpaceModal;
