import { ReactNode } from 'react';
import { Field } from 'formik';
import cn from 'classnames';
import s from '../Dropdowns.module.less';
import FieldWrapper from '../../FieldWrapper';
import RawAsyncSelect from '../RawAsyncSelect';
import { OptionType } from '../types';

export type Props = {
  name: string;
  labelText?: ReactNode;
  required?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  labelClass?: string;
  containerClass?: string;
  className?: string;
  loadOptions: (val: string, callback?: Function) => void;
  initialValue?: OptionType | OptionType[];
  isMulti?: boolean;
  mapValue?: Function;
  'data-testid'?: string;
};

const Select = ({
  name,
  labelText,
  required = false,
  loadOptions,
  isClearable = true,
  placeholder,
  labelClass,
  containerClass,
  className,
  initialValue,
  isMulti = false,
  mapValue = v => v,
  'data-testid': dataTestId,
}: Props) => (
  <FieldWrapper
    name={name}
    labelText={labelText}
    required={required}
    labelClass={labelClass}
    containerClass={containerClass}
    data-testid={dataTestId}
  >
    <Field name={name}>
      {({ field, form, meta }) => (
        <RawAsyncSelect
          name={field.name}
          className={cn(className, meta.error && meta.touched && s.error)}
          placeholder={placeholder}
          onChange={async option => {
            if (isMulti && Array.isArray(option)) {
              await field.onChange({
                target: { name: field.name, value: option.map(o => mapValue(o.value)) },
              });
            } else {
              await field.onChange({ target: { name: field.name, value: mapValue(option) } });
            }
            form.setFieldTouched(field.name, true);
          }}
          initialValue={initialValue}
          onBlur={field.onBlur}
          loadOptions={loadOptions}
          isClearable={isClearable}
          isMulti={isMulti}
        />
      )}
    </Field>
  </FieldWrapper>
);

export default Select;
