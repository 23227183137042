import routes from '@root/routes';
import { useQuery } from '@tanstack/react-query';
import { get } from '@shared/typedApi';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Layout from '../../Layout';
import AlphabeticalList from '../AlphabeticalList';
import { alphabetizeAndGroupItems, Item } from '../utils';

type LocalitiesType = {
  localities: Item[];
  region: {
    name: string;
    abbreviatedName: string;
  };
};

const Region = () => {
  const { t } = useTranslation('common');
  const { abbreviatedName } = useParams<{ abbreviatedName: string }>();

  const { data: localitiesData } = useQuery(
    [routes.api.browseLocalities({ region: abbreviatedName })],
    async () => get<LocalitiesType>(routes.api.browseLocalities({ region: abbreviatedName })),
  );

  const itemsToRender = alphabetizeAndGroupItems(localitiesData?.localities || []);

  const region = localitiesData?.region;

  return region ? (
    <Layout>
      <AlphabeticalList
        breadcrumbs={[
          { content: t('home'), link: routes.root },
          { content: t('country.unitedStates'), link: routes.browseRegionList },
          { content: t('browse.cities', { state: region.name }) },
        ]}
        title={t('browse.cities', { state: region.name })}
        items={itemsToRender}
      />
    </Layout>
  ) : (
    <Layout showFooter={false} />
  );
};

export default Region;
