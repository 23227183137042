import { useTranslation } from 'react-i18next';
import { PopperTooltip } from '@components/shared';
import { InfoIcon } from '@viewthespace/components';
import { LogoTypes } from '@components/shared/Cropper/useCropperModal';

type Props = {
  assetType: LogoTypes;
  page: 'building' | 'landlord';
  previewImage: string;
};

const AssetLocationPreviewTooltip = ({ assetType, page, previewImage }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <PopperTooltip
      showArrow
      toolTipPlacement="top"
      trigger="click"
      triggerElement={<InfoIcon className="mb-1 ml-1 text-indigo-100" size="sm" />}
      popperElement={
        <figure>
          <img src={previewImage} alt={t(`${page}.brandedAssets.${assetType}.previewAltText`)} />
        </figure>
      }
    />
  );
};

export default AssetLocationPreviewTooltip;
