import { useEffect } from 'react';
import cn from 'classnames';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useFormikContext, ErrorMessage } from 'formik';
import { CurrentUser, ErrorResponse } from '@root/types';
import * as yup from 'yup';
import {
  Form as SharedForm,
  TextInput,
  TextArea,
  SubmitButton,
  Checkbox,
} from '@components/shared/forms';
import { useMutation } from '@tanstack/react-query';
import CreatableSelect from '@components/shared/forms/CreatableSelect';
import { create, ResponseError } from '@shared/typedApi';
import routes from '@root/routes';
import actions from '@store/actions/listingPage';
import ListingShareUrlHelper from '@shared/listingShareUrlHelper';
import s from './ShareModal.module.less';

type Props = {
  listingId: string;
  displaySuccess: () => void;
  currentUser: CurrentUser;
  buildingName: string;
  neighborhood: string;
};

type FormValues = {
  custom: boolean | null | undefined;
  to: Array<string | undefined> | string;
  subject: string;
  message: string | null | undefined;
};

export const getSubjectLine = ({
  t,
  place,
  currentUser,
}: {
  t: TFunction;
  place: string;
  currentUser: CurrentUser;
}): string =>
  t('share.defaultSubject', {
    firstName: currentUser.firstName,
    place,
  });

// Subject Input is a separate component because it
// changes when you go from custom to non-custom share
const SubjectInput = ({
  customSubject,
  noncustomSubject,
}: {
  customSubject: string;
  noncustomSubject: string;
}) => {
  const { t } = useTranslation('listing');

  const {
    values: { custom },
    setFieldValue,
  } = useFormikContext<FormValues>();

  useEffect(() => {
    setFieldValue('subject', custom ? customSubject : noncustomSubject);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custom]);

  return (
    <TextInput
      name="subject"
      labelText={t('share.subject')}
      placeholder={t('share.subjectPlaceholder')}
      required
    />
  );
};

const SharePreviewLink = ({ listingId, userId }: { listingId: string; userId: string }) => {
  const { t } = useTranslation('listing');
  const {
    values: { custom },
  } = useFormikContext<FormValues>();

  const encodedData = ListingShareUrlHelper.encode({
    listingId,
    userId,
    hideLocation: !!custom,
  });

  return (
    <a href={routes.listingShare(encodedData)} target="_blank" rel="noreferrer">
      {t('share.preview')}
    </a>
  );
};

const Form = ({ listingId, displaySuccess, currentUser, buildingName, neighborhood }: Props) => {
  const { t } = useTranslation('listing');
  const flags = useFlags();

  const validationSchema = yup.object().shape({
    custom: yup.boolean().nullable(),
    to: yup
      .array()
      .required(t('share.noRecipient'))
      .max(10, () => t('share.maxCountEmails', { count: 10 }))
      .of(
        yup
          .string()
          .trim()
          .email(context =>
            t('share.invalidRecipientEmail', {
              invalidEmail: context.value,
            }),
          ),
      ),
    subject: yup.string().max(100, t('share.maxLengthSubject')).required(t('share.noSubject')),
    message: yup.string().max(2000, t('share.yourMessageMaxLength')).nullable(),
  });

  const dispatch = useDispatch();

  const customSubject = getSubjectLine({ t, currentUser, place: neighborhood });
  const noncustomSubject = getSubjectLine({ t, currentUser, place: buildingName });

  const submitListingShareMutation = useMutation(
    async ({
      listingToShareId,
      values,
      helpers, // eslint-disable-line @typescript-eslint/no-unused-vars
    }: {
      listingToShareId: string;
      values: FormValues;
      helpers: any;
    }) => create(routes.api.listingShares(listingToShareId), values),
    {
      onSuccess: async (_, { values, helpers }) => {
        const successAction = values.custom
          ? actions.successfulCustomShare
          : actions.successfulShare;
        await helpers.resetForm();
        await dispatch(successAction());
        displaySuccess();
      },
      onError: async (response: ResponseError, { helpers }) => {
        const errorResponse: ErrorResponse = response.body;

        dispatch(actions.shareError());
        errorResponse.errors.forEach(error => {
          if (error.field) {
            helpers.setFieldError(error.field, error.message);
          } else {
            helpers.setFieldTouched('nonFieldErrors');
            helpers.setFieldError('nonFieldErrors', error.message);
          }
        });
      },
    },
  );

  return (
    <>
      <SharedForm<FormValues>
        id="shareForm"
        onSubmit={(values, helpers) =>
          submitListingShareMutation.mutate({ listingToShareId: listingId, values, helpers })
        }
        validationSchema={validationSchema}
        initialValues={{
          subject: noncustomSubject,
          to: '',
          message: '',
          custom: false,
        }}
        className={s.form}
      >
        <div className={cn(s.customShareContainer, s.withBottomSpace)}>
          <Checkbox name="custom" labelClassName={s.noMarginCheckbox}>
            {t('share.customShareExplanation')}
          </Checkbox>
          {flags['demand.remove-listing-share-preview-button'] ? null : (
            <SharePreviewLink listingId={listingId} userId={currentUser.id} />
          )}
        </div>
        <CreatableSelect
          name="to"
          labelText={t('share.recipientsEmails')}
          placeholder={t('share.recipientsEmailsPlaceholder')}
          required
        />
        <SubjectInput customSubject={customSubject} noncustomSubject={noncustomSubject} />
        <TextArea
          name="message"
          labelText={t('share.yourMessage')}
          placeholder={t('share.messagePlaceholder')}
        />
        <div className={s.buttonContainer}>
          <ErrorMessage
            name="nonFieldErrors"
            render={msg => <div className={s.errorMessage}>{msg}</div>}
          />
          <SubmitButton size="large">{t('share.ctaText')}</SubmitButton>
        </div>
      </SharedForm>
    </>
  );
};

export default Form;
