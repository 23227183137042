import cn from 'classnames';
import CustomIcon, { ValidIconTypes } from '@components/shared/CustomIcon';
import s from './IconButton.module.less';

export type CustomIconButtonProps = {
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  icon: ValidIconTypes;
  onClick?: (e) => void;
  title?: string;
  wrapWithFunction?: boolean;
};
const IconButton = ({
  className = '',
  onMouseOver = () => null,
  onMouseLeave = () => null,
  disabled,
  icon,
  onClick = () => null,
  title = '',
  wrapWithFunction = true,
  ...props
}: CustomIconButtonProps) => {
  const testId = props['data-testid'] || 'icon-button';

  /* eslint-disable jsx-a11y/mouse-events-have-key-events */
  const component = (
    <button
      className={cn(s.iconButton, className, [disabled && s.disabled])}
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      title={title}
      type="submit"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <CustomIcon type={icon} className={cn([disabled && s.disabled])} />
    </button>
  );
  /* eslint-enable jsx-a11y/mouse-events-have-key-events */

  const WrappedComponent = () => component;
  return wrapWithFunction ? <WrappedComponent /> : component;
};

export default IconButton;
