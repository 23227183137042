import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AmenityKey } from '@root/types/Listing';
import actions from '@store/actions/listingSearchPage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import { useMemo } from 'react';
import { amenityOptions } from '../../utils/constants';
import FilterDropdown from '../../FilterDropdown/FilterDropdown';
import useListingSearchCriteria from '../../utils/useListingSearchCriteria';

const AmenitiesFilter = () => {
  const { t } = useTranslation('filters');
  const flags = useFlags();
  const { dropdownFilterInteraction } = useFilterInteraction();

  const criteria = useListingSearchCriteria();
  const dispatch = useDispatch();

  const orderedAmenities = useMemo(
    () =>
      amenityOptions.sort((amenityA, amenityB) =>
        t(amenityA.label).localeCompare(t(amenityB.label)),
      ),
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amenityOptions],
  );

  const translatedOptions = orderedAmenities.map(option => {
    return { ...option, label: t(option.label) };
  });

  const currentFilterOptions =
    translatedOptions.filter(option =>
      criteria.currentFilters.amenities?.includes(option.value as AmenityKey),
    ) || [];

  const handleChange = currentValues => {
    const isAdding = criteria.amenities().length < currentValues.length;
    const currentAmenities: string[] = currentValues?.map(option => option.value) || [];
    const amenity = isAdding
      ? currentAmenities.find(a => !criteria.amenities().includes(a))
      : criteria.amenities().find(a => !currentAmenities.includes(a));

    criteria.add(
      'amenities',
      currentValues?.map(option => option.value as AmenityKey),
    );
    criteria.pushToHistory();
    if (flags['search-analytics-refactor']) {
      dropdownFilterInteraction({
        filterType: 'amenities',
        isAdding,
        value: amenity!,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatch(actions.amenitiesFilterChanged(criteria.currentFilters.amenities as string[]));
    }
  };

  return (
    <div className="u-p-top-2x">
      <FilterDropdown
        name="amenities"
        options={translatedOptions}
        value={currentFilterOptions}
        onChange={handleChange}
        placeholder={t('amenities.placeholder')}
      />
    </div>
  );
};

export default AmenitiesFilter;
