import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Layout from '../Layout';
import EmptyState from './empty-state.svg';
import s from './NotFound.module.less';

const NotFound = ({ isTransientPage = false, blankPage = false }) => {
  const { t } = useTranslation('common');
  const tl = msg => t(`notFound.${msg}`);
  const msg = isTransientPage ? tl('message.transient') : tl('message.default');
  const subTitle = isTransientPage ? tl('subtitle.transient') : tl('subtitle.default');

  return (
    <Layout showFooter={false} containerClassName={s.outerWrapper}>
      <div className={cn(s.wrapper, blankPage && 'invisible')}>
        <EmptyState />
        <h3>{subTitle}</h3>
        <p>{msg}</p>
      </div>
    </Layout>
  );
};

export default NotFound;
