import { isNull } from 'lodash';
import { Address } from '@root/types';
import useEnv from '@shared/useEnv';
import s from './EmbeddedMap.module.less';

type Props = {
  hideLocation: boolean;
  longitude: number | null;
  latitude: number | null;
  address: Address;
};

const EmbeddedMap = ({ address, hideLocation, latitude, longitude }: Props) => {
  const { googleMapsKey: key } = useEnv();
  if (isNull(key)) return null;
  const embedUrl = hideLocation
    ? `https://www.google.com/maps/embed/v1/view?center=${latitude},${longitude}&key=${key}&zoom=18`
    : `https://www.google.com/maps/embed/v1/place?q=${address.formattedAddress}&key=${key}&zoom=18`;
  return (
    <div className={s.iframeContainer} data-qa-testid="location-map">
      <iframe
        data-testid="embeddedMap"
        title="listing-page-location"
        className={s.googleMapIframe}
        width="100%"
        src={embedUrl}
      />
    </div>
  );
};

export default EmbeddedMap;
