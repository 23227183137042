import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import useEnvVariable from '@root/shared/useEnv';
import s from '../common.module.less';
import MarketingMediaForm from '../MarketingMediaForm';
import MarketingMediaHeader from '../MarketingMediaHeader/MarketingMediaHeader';
import {
  uploadToCloudinary,
  handleResponse,
  deleteMedia,
  deletePhoto,
  updatePhoto,
} from '../helper';
import { BuildingMarketingMedia } from '../types';

const Neighborhood = ({ data }: { data: BuildingMarketingMedia[] }) => {
  const { t } = useTranslation('admin');
  const [collapseMedia, setCollapseMedia] = useState(true);
  const { cloudinaryUploadPreset, cloudinaryCloud } = useEnvVariable();
  const { setValues, values } = useFormikContext();
  const showCancelUploadMediaButton =
    !data[0].cloudinaryId && !data[0].description && !data[0].title && data.length === 1;

  const handleNeighborhoodFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    const cloudinaryResponse = await uploadToCloudinary(
      files[0],
      cloudinaryUploadPreset || '',
      cloudinaryCloud || '',
      'image',
    );

    handleResponse(
      cloudinaryResponse,
      t('marketingMedia.neighborhood.createSuccessMessage'),
      t('marketingMedia.neighborhood.createFailureMessage'),
    );

    if (!cloudinaryResponse.secure_url) return;

    const updatedFormikValues = await updatePhoto(
      values,
      'neighborhood',
      1,
      'neighborhood',
      cloudinaryResponse.secure_url,
    );

    setValues(updatedFormikValues);
  };

  const onDelete = async (photoOrder: number) => {
    const deleteMessage = t('marketingMedia.neighborhood.deleteSuccessMessage');
    const updatedFormikValues = await deleteMedia(
      values,
      'neighborhood',
      photoOrder,
      deleteMessage,
      'neighborhood',
    );

    setValues(updatedFormikValues);
  };

  const onPhotoDelete = async (photoOrder: number) => {
    const updatedFormikValues = await deletePhoto(values, 'neighborhood', photoOrder);

    setValues(updatedFormikValues);
  };

  return (
    <section className={s.mainSection}>
      <MarketingMediaHeader
        collapseMedia={collapseMedia}
        mediaTitle={t('marketingMedia.neighborhood.title')}
        mediaLimit={t('marketingMedia.neighborhood.maxCount')}
        setCollapseMedia={setCollapseMedia}
      />
      {collapseMedia && (
        <MarketingMediaForm
          data={data}
          onUpload={handleNeighborhoodFileUpload}
          onPhotoDelete={onPhotoDelete}
          showCancelUploadMediaButton={showCancelUploadMediaButton}
          onDelete={onDelete}
          identifier="neighborhood"
        />
      )}
    </section>
  );
};

export default Neighborhood;
