import { Field } from 'formik';
import RawTextArea from '../RawTextArea';
import s from '../RawTextArea/TextArea.module.less';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';

type Props = {
  placeholder?: string;
  className?: string;
  showCharacterCount?: boolean;
  maxLength?: number;
  disabled?: boolean;
  'data-testid'?: string;
  rows?: number;
} & Omit<FieldWrapperProps, 'children'>;

const TextArea = ({
  name,
  labelClass,
  containerClass,
  labelText,
  placeholder = '',
  required,
  className,
  showCharacterCount = false,
  maxLength,
  disabled,
  rows,
  description,
  descriptionClass,
  ...props
}: Props) => {
  return (
    <FieldWrapper
      {...{ name, containerClass, labelClass, labelText, required, description, descriptionClass }}
      inputContainerClass={s.flex}
    >
      <Field name={name}>
        {({ field, meta }) => (
          <RawTextArea
            data-testid={props['data-testid']}
            {...field}
            hasError={meta.error}
            placeholder={placeholder}
            className={className}
            showCharacterCount={showCharacterCount}
            maxLength={maxLength}
            disabled={disabled}
            rows={rows}
          />
        )}
      </Field>
    </FieldWrapper>
  );
};

export default TextArea;
