import { useSetArrivedOnMarketLink } from '@shared/sessionStorageForSiteVisitors';
import Landlord from './Landlord';

export const VtsMarketLandlordShare = props => {
  useSetArrivedOnMarketLink();

  return <Landlord {...props} />;
};

export default VtsMarketLandlordShare;
