import { useTranslation } from 'react-i18next';
import { LightBulbIcon, Tooltip } from '@viewthespace/components';

export default function RelatedListingTag() {
  const { t } = useTranslation('tourbook');
  return (
    <Tooltip
      className="z-[100] max-w-[250px]"
      placement="top"
      content={t('analytics.relatedListingViewTooltip')}
      trigger={
        <span className="rounded-full bg-general-green-tertiary px-1 py-0.5 font-body-small">
          <span className="pr-0.5">
            <LightBulbIcon className="mt-[-2px]" />
          </span>
          <span>{t('analytics.relatedListingView')}</span>
        </span>
      }
      triggerAction="hover"
    />
  );
}
