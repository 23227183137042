import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CustomIcon, IconButton, Spinner } from '@components/shared';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { CloudinaryTransformations, CropperCanvasData } from '@root/types';
import { generateCloudinaryTransformations, generateZoomBounds } from './utils';

type ZoomBounds = { min: number; max: number; current: number; step: number };
export type CropBoxDimentions = { width: number; height: number };

export type onZoomProps = {
  newHeight?: number | null;
  newWidth?: number | null;
};

type Props = {
  imageSrc: string;
  maxZoomAmount?: number;
  numberOfZoomSteps?: number;
  enableCropbox?: boolean;
  initialCanvasData?: CropperCanvasData | null;
  initialCloudinaryTransformations?: CloudinaryTransformations | null;
  cropBoxDimentions?: CropBoxDimentions | null;
  cropBoxZoomAdjustment?: number;
  viewOnly?: boolean;
  onZoom?: ({ newHeight, newWidth }: onZoomProps) => void;
  isCropBoxResizable?: boolean;
  enableZoom?: boolean;
};
type UseCropperOutput = {
  isReady: boolean;
  ZoomRange: typeof ZoomRange;
  zoomRangeProps: ZoomRangeProps;
  Cropper: typeof CropperComponent;
  cropperProps: CropperComponentProps;
  isDragging: boolean;
  getCanvasData: () => CropperCanvasData | null;
  getCloudinaryTransformations: () => CloudinaryTransformations | null;
};

const useCropper = ({
  imageSrc,
  maxZoomAmount = 5,
  numberOfZoomSteps = 50,
  cropBoxDimentions,
  initialCanvasData,
  initialCloudinaryTransformations,
  onZoom = () => {},
  viewOnly = false,
  isCropBoxResizable = false,
  enableZoom = true,
}: Props): UseCropperOutput => {
  const cropperRef = useRef<ReactCropperElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [zoomBounds, setZoomBounds] = useState<ZoomBounds>({
    min: 0,
    max: maxZoomAmount,
    current: 0,
    step: maxZoomAmount / numberOfZoomSteps,
  });

  const cropper = cropperRef.current?.cropper;

  const [isReady, setIsReady] = useState(false);
  useEffect(() => setIsReady(false), [imageSrc, isCropBoxResizable]);

  useEffect(() => {
    if (viewOnly) cropper?.disable();
    else cropper?.enable();
  }, [viewOnly, cropper, isReady]);

  const setCropBoxSize = useCallback(() => {
    if (cropper && cropBoxDimentions && isReady) {
      if (isCropBoxResizable) {
        const { height, width, naturalHeight, naturalWidth, left, top } = cropper.getCanvasData();
        const zoom = Math.max(width / naturalWidth, height / naturalHeight);
        // If the cropbox is resizable, we want to set the cropbox to the existing position
        // If no existing positon is set, we want to default to selecting the entire image
        cropper.setCropBoxData({
          height:
            initialCloudinaryTransformations?.height != null
              ? initialCloudinaryTransformations?.height * zoom
              : naturalHeight * zoom,
          width:
            initialCloudinaryTransformations?.width != null
              ? initialCloudinaryTransformations?.width * zoom
              : naturalWidth * zoom,
          top:
            initialCloudinaryTransformations?.y != null
              ? initialCloudinaryTransformations?.y * zoom + top
              : top * zoom,
          left:
            initialCloudinaryTransformations?.x != null
              ? initialCloudinaryTransformations?.x * zoom + left
              : left * zoom,
        });
      } else {
        const { height, width } = cropper.getContainerData();
        cropper.setCropBoxData({
          ...cropBoxDimentions,
          top: height / 2 - cropBoxDimentions.height / 2,
          left: width / 2 - cropBoxDimentions.width / 2,
        });
      }
    }
  }, [cropBoxDimentions, cropper, isReady, isCropBoxResizable, initialCloudinaryTransformations]);

  useEffect(() => {
    setCropBoxSize();
  }, [cropBoxDimentions, isReady, setCropBoxSize]);

  useEffect(() => {
    if (cropper && initialCanvasData && isReady) {
      cropper.setCanvasData(initialCanvasData);
    }
  }, [initialCanvasData, cropper, setCropBoxSize, isReady]);

  const zoomTo = useCallback(
    (zoomAmount: number) => {
      if (cropper && isReady && zoomAmount) {
        cropper.zoomTo(zoomAmount, {
          x: cropper.getContainerData().width / 2,
          y: cropper.getContainerData().height / 2,
        });

        // For some reason changing the zoom can affect the cropbox size
        if (!isCropBoxResizable) {
          setCropBoxSize();
        }
      }
    },
    [isReady, cropper, setCropBoxSize, isCropBoxResizable],
  );

  const getCloudinaryTransformations = useCallback(() => {
    if (!cropper) return null;

    const cropBox = cropBoxDimentions
      ? cropper.getCropBoxData()
      : { left: 0, top: 0, ...cropper.getContainerData() };

    return generateCloudinaryTransformations({
      cropBox,
      canvasData: cropper.getCanvasData(),
      zoom: zoomBounds.current,
    });
  }, [cropBoxDimentions, cropper, zoomBounds]);

  useEffect(() => {
    const cloudinaryData = getCloudinaryTransformations();
    onZoom({ newHeight: cloudinaryData?.height, newWidth: cloudinaryData?.width });
  }, [getCloudinaryTransformations, onZoom, zoomBounds]);

  useEffect(() => {
    const minImageDimentions = cropBoxDimentions || cropper?.getContainerData();
    const canvasData = cropper?.getCanvasData();
    if (cropper && isReady && minImageDimentions?.width && canvasData?.width) {
      const newZoomBounds = generateZoomBounds({
        minImageDimentions,
        canvasData,
        maxZoomAmount,
        numberOfZoomSteps,
        useCanvasDimentions: !!initialCanvasData,
      });
      setZoomBounds(newZoomBounds);
      zoomTo(newZoomBounds.current);

      const handleZoom = e => {
        let currentZoom = e.detail.ratio;
        if (currentZoom > newZoomBounds.max) {
          e.preventDefault();
          currentZoom = newZoomBounds.max;
          zoomTo(newZoomBounds.max);
        } else if (currentZoom < newZoomBounds.min) {
          e.preventDefault();
          currentZoom = newZoomBounds.min;
          zoomTo(newZoomBounds.min);
        }
        setZoomBounds(prevZoomBounds => ({ ...prevZoomBounds, current: currentZoom }));
      };

      const cropperRefCurrent = cropperRef.current!;
      cropperRefCurrent.addEventListener('zoom', handleZoom);
      return () => cropperRefCurrent.removeEventListener('zoom', handleZoom);
    }
    return () => {};
  }, [
    isReady,
    maxZoomAmount,
    imageSrc,
    numberOfZoomSteps,
    zoomTo,
    cropBoxDimentions,
    initialCanvasData,
    cropper,
  ]);

  const handleKeyDown = event => {
    if (event.key === '-') zoomTo(zoomBounds.current - zoomBounds.step);
    if (event.key === '=') zoomTo(zoomBounds.current + zoomBounds.step);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return {
    isReady,
    ZoomRange,
    zoomRangeProps: {
      zoomBounds,
      isReady,
      zoomTo,
    },
    Cropper: CropperComponent,
    cropperProps: {
      isDragging,
      setIsDragging,
      isReady,
      onReady: () => setIsReady(true),
      imageSrc,
      cropperRef,
      viewOnly,
      enableCropbox: !!cropBoxDimentions,
      isCropBoxResizable,
      enableZoom,
    },
    isDragging,
    getCanvasData: () => cropper?.getCanvasData() || null,
    getCloudinaryTransformations,
  };
};

type ZoomRangeProps = {
  zoomBounds: ZoomBounds;
  className?: string;
  isReady: boolean;
  zoomTo: (number) => void;
  inputClassName?: string;
};
export const ZoomRange = ({
  zoomBounds,
  className,
  isReady,
  zoomTo,
  inputClassName,
}: ZoomRangeProps) => {
  const handleClickZoomIn = () => {
    zoomTo(zoomBounds.current + zoomBounds.step);
  };

  const handleClickZoomOut = () => {
    zoomTo(zoomBounds.current - zoomBounds.step);
  };
  return (
    <div
      className={cn(
        className,
        'pointer-events-auto flex items-center gap-1 rounded-[50px] bg-black-100/60 p-1 text-white font-body-medium',
        isReady ? 'opacity-100' : 'opacity-0',
      )}
    >
      <IconButton
        data-testid="minus"
        onClick={handleClickZoomOut}
        icon="minus"
        title="zoomOutButton"
        className="!h-[20px] !w-[20px] !bg-transparent  [&_svg]:h-[14px] [&_svg]:w-[14px]"
      />
      <input
        data-testid="cropperZoom"
        type="range"
        min={zoomBounds.min}
        max={zoomBounds.max}
        step={zoomBounds.step}
        className={cn(
          'h-0.5 w-20 cursor-pointer appearance-none rounded-none accent-white [&::-webkit-slider-thumb]:rounded-[50px] [&::-webkit-slider-thumb]:shadow-[0_0_0_3px_rgba(85,_40,_255,_1)]',
          inputClassName,
        )}
        value={zoomBounds.current}
        onChange={e => {
          zoomTo(e.target.valueAsNumber);
        }}
      />
      <IconButton
        data-testid="plus"
        onClick={handleClickZoomIn}
        icon="plus"
        title="zoomInButton"
        className="!h-[20px] !w-[20px] !bg-transparent [&_svg]:h-[14px] [&_svg]:w-[14px]"
      />
    </div>
  );
};

type CropperComponentProps = {
  containerClassName?: string;
  className?: string;
  dragTextClassName?: string;
  isDragging: boolean;
  setIsDragging: (boolean) => void;
  viewOnly: boolean;
  isReady: boolean;
  enableCropbox: boolean;
  onReady: () => void;
  imageSrc: string;
  cropperRef: React.ForwardedRef<ReactCropperElement>;
  alt?: string;
  isCropBoxResizable?: boolean;
  enableZoom?: boolean;
};
const CropperComponent = ({
  containerClassName,
  className,
  dragTextClassName,
  isDragging,
  setIsDragging,
  enableCropbox,
  viewOnly,
  isReady,
  onReady,
  imageSrc,
  cropperRef,
  alt,
  isCropBoxResizable,
  enableZoom,
}: CropperComponentProps) => {
  const { t } = useTranslation('common');

  return (
    <div className={containerClassName}>
      {!isReady && <Spinner className="absolute inset-[50%] ml-[-8px] mt-[-24px] text-[32px]" />}
      <Cropper
        key={`${imageSrc}-${isCropBoxResizable}`}
        className={cn(
          className,
          // eslint-disable-next-line no-nested-ternary
          viewOnly
            ? '[&_.cropper-drag-box]:!cursor-auto [&_.cropper-face]:!cursor-auto'
            : isDragging
              ? '[&_.cropper-drag-box]:!cursor-grabbing [&_.cropper-face]:!cursor-grabbing'
              : '[&_.cropper-drag-box]:!cursor-grab [&_.cropper-face]:!cursor-grab',
        )}
        src={imageSrc}
        alt={alt}
        dragMode="move"
        cropBoxMovable={false}
        cropBoxResizable={isCropBoxResizable}
        viewMode={enableCropbox ? 1 : 3}
        autoCropArea={1}
        guides={false}
        ref={cropperRef}
        responsive={false}
        restore={false}
        autoCrop={enableCropbox}
        toggleDragModeOnDblclick={false}
        ready={onReady}
        cropmove={() => setIsDragging(true)}
        cropend={() => setIsDragging(false)}
        background={false}
        zoomable={enableZoom}
      />
      {!isCropBoxResizable && (
        <div
          className={cn(
            'select-none transition',
            isDragging || viewOnly || !isReady ? 'opacity-0' : 'opacity-100',
          )}
        >
          <div className="pointer-events-none absolute top-2 flex w-full justify-center">
            <div
              className={cn(
                'flex items-center gap-1 rounded-[3px] bg-black-100/60 p-1 text-white font-body-medium',
                dragTextClassName,
              )}
            >
              <CustomIcon type="move" />
              {t('cropper.dragImageToReposition')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default useCropper;
