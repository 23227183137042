import { useEffect, useRef, useState } from 'react';
import { useDispatch, connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, flatMap, isEqual, pick } from 'lodash/fp';
import useEnv from '@shared/useEnv';
import {
  addListener,
  addListenerOnce,
  loadMap,
  createMap,
  getMapOptions,
  latLngBounds,
  getPolygonBounds,
  showMapLogs,
} from '@shared/googleMaps';
import {
  BuildingMapPoint,
  InfoWindowListing,
  MarkerTrackers,
  SearchListing,
  StoreState,
  Submarket,
} from '@root/types';
import routes from '@root/routes';
import Button from '@components/shared/V2Button';
import searchPageActions from '@store/actions/listingSearchPage';
import actions from '@store/actions';
import BuildingInfoWindow from '@shared/map/BuildingInfoWindow';
import Markers from '@shared/map/Markers';
import HoveredListingIndicator from '@shared/map/HoveredListingIndicator';
import api from '@shared/api';
import Zoom from '@root/shared/map/Zoom/Zoom';
import { CustomIcon } from '@components/shared';
import useAnalytics from '@shared/useAnalytics';
import useEnums from '@shared/useEnums';
import { isNull } from 'lodash';
import { buildingViewedOnMap, mapDeclustered, mapZoomed } from '@root/shared/map/AnalyticsProps';
import Drawer from './Drawer';
import SubmarketBoundaryDrawer from './SubmarketBoundaryDrawer';
import SearchInMapAreaDrawer from './SearchInMapAreaDrawer';
import s from './Map.module.less';
import { useListingSearchCriteria, ListingSearchCriteria } from '../utils';

type PassedProps = {
  buildingSearchResults: BuildingMapPoint[];
  customMapCoordinates: string | null;
  onRedoSearch: (bounds: string) => void;
  selectedSubmarkets: Submarket[];
  onMapReset: () => void;
  userMovedMap: boolean;
  marketSlug: string; // this is also a type
  hoveredListing: null | SearchListing;
  onDrawComplete: (polygons: string, cancelDrawing: Function) => void;
};

const mapDispatchToProps = dispatch => ({
  onMapMove: () => dispatch(actions.mapMoved),
});

const mapState = (state: StoreState) => ({
  isDrawing: state.map.isDrawing,
  drawnPolygons: state.map.drawnPolygons,
});

const connector = connect(mapState, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;

// Used to fetch listing information for the map BuildingInfoWindow
const fetchBuildingMapMarkers = async (
  building: BuildingMapPoint,
): Promise<InfoWindowListing[]> => {
  const criteria = ListingSearchCriteria.fromUrl({
    queryString: window.location.search,
  });
  const response = await api.fetch(criteria.toApiBuildingsUrl(building.slug, true));

  const listings: SearchListing[] = await response.json();

  return listings.map((listing: SearchListing) => {
    const partialListing = pick(
      ['address', 'isCurrentlyExclusive', 'name', 'photo', 'size', 'smallestPrice'],
      listing,
    );

    const infoWindowlisting: InfoWindowListing = {
      ...partialListing,
      onClick: () => window.open(routes.listing(listing.id, listing.buildingSlug), '_blank'),
    };
    return infoWindowlisting;
  });
};

export const Map = ({
  buildingSearchResults,
  customMapCoordinates,
  onRedoSearch,
  onMapReset,
  selectedSubmarkets,
  marketSlug,
  hoveredListing,
  userMovedMap,
  isDrawing,
  onDrawComplete,
  drawnPolygons,
  onMapMove,
}: PassedProps & ReduxProps) => {
  // persistant objects
  // TODO : Add types
  // TODO : Figure out which things can be instantiated right away
  const mapRef = useRef<HTMLDivElement>(null);
  const mapObj = useRef() as { current: google.maps.Map | null };
  const drawer = useRef() as { current: any | null };
  const userSelectedSubmarkets = useRef(selectedSubmarkets);
  const userSelectedMapArea = useRef(customMapCoordinates);
  const marketPosition = useRef(marketSlug);
  const submarketBoundaryDrawer = useRef() as { current: SubmarketBoundaryDrawer | null };
  const searchInMapAreaDrawer = useRef() as { current: SearchInMapAreaDrawer | null };
  const buildingInfoWindow = useRef() as { current: BuildingInfoWindow | null };
  const hoveredListingIndicator = useRef() as { current: any | null };
  const isMapAutoZooming = useRef(false) as { current: boolean | null };
  const markers = useRef() as { current: Markers | null };
  const previousZoomRef = useRef(null) as { current: number | null };
  const [mapMovedAfterSearchInMapArea, setMapMovedAfterSearchInMapArea] = useState(false);
  const { t } = useTranslation();
  const { googleMapsKey: key } = useEnv();
  const dispatch = useDispatch();
  const criteria = useListingSearchCriteria();
  const { PARAMETERS, mapInteraction, clickToPage } = useAnalytics();

  const trackBuildingViewedOnMap = () => {
    mapInteraction(buildingViewedOnMap(PARAMETERS.searchResultsPage));
  };
  const trackMapDeclustered = () => {
    mapInteraction(mapDeclustered(PARAMETERS.searchResultsPage));
  };
  const trackMapZoomed = () => {
    mapInteraction(mapZoomed(PARAMETERS.searchResultsPage));
  };
  const trackMapClickInfoWindowListing = (listing: SearchListing) => {
    clickToPage(
      {
        actionType: 'CLICK_TO_LISTING_FROM_MAP',
        sourceContent: PARAMETERS.map,
        sourcePage: PARAMETERS.searchResultsPage,
        destination: PARAMETERS.listingPage,
      },
      {
        isExclusiveListing: listing.isCurrentlyExclusive,
        isFlexibleSizeSearch: !!listing.minMaxArea,
        currentFilters: criteria.toAnalyticsProperties(),
      },
    );
  };

  const trackers = {
    trackBuildingViewedOnMap,
    trackMapClickInfoWindowListing,
    trackMapDeclustered,
  };

  const { marketMapCenters } = useEnums();
  const startingPoint = marketMapCenters[marketSlug] ?? marketMapCenters.new_york_city;
  const hasDrawnPolygons = drawnPolygons.length > 0;

  const log = (msg: string) => {
    const showLogs = false;

    if (showMapLogs() || showLogs) {
      // eslint-disable-next-line no-console
      console.log(msg);
    }
  };

  const mapMoved = () => {
    if (isMapAutoZooming.current) return;
    onMapMove();
    setMapMovedAfterSearchInMapArea(true);
  };

  const closeInfoWindow = () => {
    markers.current?.removeActiveMarker();
    if (buildingInfoWindow.current) {
      buildingInfoWindow.current.close();
    }
  };

  const addMapHandlers = () => {
    addListener(mapObj.current, 'dblclick', () => {
      mapInteraction({
        actionType: 'MAP_DOUBLE_CLICKED',
        action: PARAMETERS.doubleClick,
        sourcePage: PARAMETERS.searchResultsPage,
        sourceContent: PARAMETERS.map,
      });
    });

    addListener(mapObj.current, 'click', () => {
      log('close infowindow');
      closeInfoWindow();
    });

    addListener(mapObj.current, 'dragend', () => {
      if (buildingInfoWindow.current) {
        buildingInfoWindow.current.infoWindow.setOptions({ disableAutoPan: true });
      }
      log(`drag end`);
      mapMoved();
    });

    addListener(mapObj.current, 'idle', () => {
      log(`idle, repainting to fix cluster issues`);
      markers.current?.rerenderMarkers();
    });

    addListenerOnce(mapObj.current, 'idle', () => {
      if (!previousZoomRef.current && mapObj.current?.getZoom()) {
        previousZoomRef.current = mapObj.current?.getZoom() || null;
      }

      addListener(mapObj.current, 'zoom_changed', () => {
        const currentZoom = mapObj.current?.getZoom();
        // sometimes this listener gets hit even though the zoom has not
        // changed (eg. after clicking "Search in map area")
        if (!currentZoom || previousZoomRef.current === currentZoom) return;

        log(`zoom changed to level ${currentZoom}`);

        if (buildingInfoWindow.current) {
          buildingInfoWindow.current.infoWindow.setOptions({ disableAutoPan: true });
        }
        mapMoved();
        previousZoomRef.current = currentZoom;
      });
    });
  };

  const zoomToSearch = () => {
    if (!mapObj.current) return;
    log(`autozooming true`);
    isMapAutoZooming.current = true;

    const marketBounds = flatMap('envelope', userSelectedSubmarkets.current);

    const bounds = latLngBounds();
    // this should perhaps be its own function.  something like "setZoom"
    if (drawer.current.drawnPolygons.length > 0) {
      log('zoom to drawing');
      const drawnBounds = getPolygonBounds(drawer.current.drawnPolygons[0]);
      marketBounds.forEach(point => drawnBounds?.extend(point));
      if (drawnBounds) mapObj.current?.fitBounds(drawnBounds, 0);
    } else if (isEmpty(userSelectedSubmarkets.current) && !userSelectedMapArea) {
      log('zooming to points');

      // zoom to points
      markers.current?.getMarkers().forEach(marker => {
        const position = marker.getPosition();
        if (position) bounds?.extend(position);
      });
      if (!userMovedMap) {
        mapObj.current?.fitBounds(bounds, 0);
      }
    } else if (!userMovedMap && !userSelectedMapArea) {
      // zoom to market
      log('zooming to markets');
      marketBounds.forEach(point => bounds?.extend(point));
      mapObj.current?.fitBounds(bounds, 0);
    } else if (!userMovedMap) {
      // zoom to map area
      log('zooming to map area');
      Zoom.fitToMapCoordinates(customMapCoordinates, mapObj.current);
    }

    log(`autozooming false`);
    isMapAutoZooming.current = false;
  };

  const resetZoom = (currentMarketPosition: string) => {
    if (!mapObj.current) return;
    log(`autozooming true`);
    isMapAutoZooming.current = true;

    log(`Reset zoom to market default`);
    const { lat, long, zoom } = marketMapCenters[currentMarketPosition];
    mapObj.current.panTo({ lat, lng: long });
    mapObj.current.setZoom(zoom);

    log(`autozooming false`);
    isMapAutoZooming.current = false;
  };

  const trackMapBoundaryDrawingStarted = () => {
    mapInteraction({
      actionType: 'MAP_BOUNDARY_DRAWING_STARTED',
      action: PARAMETERS.startPolygon,
      sourcePage: PARAMETERS.searchResultsPage,
      sourceContent: PARAMETERS.map,
    });
  };
  const trackMapBoundaryDrawingFinished = () => {
    mapInteraction({
      actionType: 'MAP_BOUNDARY_DRAWING_FINISHED',
      action: PARAMETERS.finishPolygon,
      sourcePage: PARAMETERS.searchResultsPage,
      sourceContent: PARAMETERS.map,
    });
  };

  // use effects
  useEffect(() => {
    loadMap(key!, () => {
      if (mapObj.current || !mapRef.current) return;
      const mapOptions = {
        ...getMapOptions(startingPoint),
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        zoomControl: false,
      };

      mapObj.current = createMap(mapRef.current, mapOptions);

      // dom dependent objects
      hoveredListingIndicator.current = new HoveredListingIndicator({
        mapMarkerText: t('common:map.markerText'),
        shouldUseSearchRedesignMarkerStyles: true,
      });
      buildingInfoWindow.current = new BuildingInfoWindow({
        translate: t,
        trackers,
      });
      drawer.current = new Drawer({
        map: mapObj.current,
        setUserMovedMap: onMapMove,
        onDrawComplete,
        closeInfoWindow,
        trackers: {
          trackMapBoundaryDrawingStarted,
          trackMapBoundaryDrawingFinished,
        },
      });
      markers.current = new Markers({
        isClustered: true,
        trackers: trackers as MarkerTrackers,
        buildingInfoWindow: buildingInfoWindow.current,
        isEventHandlersEnabled: true,
        fetchBuildingMapMarkers,
      });
      submarketBoundaryDrawer.current = new SubmarketBoundaryDrawer();
      searchInMapAreaDrawer.current = new SearchInMapAreaDrawer();

      // setup
      addMapHandlers();
      Zoom.fitToMapCoordinates(customMapCoordinates, mapObj.current);
    });

    if (marketPosition.current !== marketSlug) {
      marketPosition.current = marketSlug;
      if (marketSlug !== 'custom') {
        // Only reset zoom if the new market is non-custom
        log(`New non-custom market ${marketSlug}`);
        log(`resetting map because of toggle`);
        resetZoom(marketPosition.current);
      }
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketSlug, mapRef.current]);

  // This is required because drawer is a ref, and when criteria changes (which is referenced
  // in onDrawComplete) drawer no longer has access to the onDrawComplete with the up to date
  // criteria. See TRV-3287
  useEffect(() => {
    if (!drawer.current) return;
    drawer.current.onDrawComplete = onDrawComplete;
  }, [onDrawComplete]);

  useEffect(() => {
    if (!mapObj.current) return;
    if (drawnPolygons.length === 0) {
      log('clearing drawn polygons');
      drawer.current.clearDrawnPolygons();
    }
  }, [drawnPolygons]);

  if (!isEqual(selectedSubmarkets, userSelectedSubmarkets.current)) {
    // because selectedSubmarkets is an array, react cannot accurately check
    // when selectedSubmarkets changes so we force the check and prevent the
    // dependent hooks from running more than necessary
    userSelectedSubmarkets.current = selectedSubmarkets;
  }
  if (!isEqual(customMapCoordinates, userSelectedMapArea.current)) {
    // because selectedSubmarkets is an array, react cannot accurately check
    // when selectedSubmarkets changes so we force the check and prevent the
    // dependent hooks from running more than necessary
    userSelectedMapArea.current = customMapCoordinates;
  }

  useEffect(() => {
    if (!mapObj.current) return;
    log('setting market boundaries');
    submarketBoundaryDrawer.current?.createSubmarketBoundaries(
      userSelectedSubmarkets.current,
      mapObj.current,
      userSelectedMapArea.current,
    );
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectedSubmarkets.current]);

  useEffect(() => {
    if (!mapObj.current || isNull(userSelectedMapArea.current)) return;
    log('setting custom map area boundaries');
    searchInMapAreaDrawer.current?.createMapAreaBoundaries(customMapCoordinates, mapObj.current);
    mapObj.current.fitBounds(
      searchInMapAreaDrawer.current?.mapAreaBounds as google.maps.LatLngBounds,
      0,
    );
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelectedMapArea.current]);

  useEffect(() => {
    if (!mapObj.current) return;
    log('on search results changed');

    // zoom management
    isMapAutoZooming.current = true;

    // autozoom will happen here
    drawer.current.buildPolygons(drawnPolygons, mapObj.current);
    markers.current?.createMarkers(
      buildingSearchResults,
      mapObj.current,
      userSelectedSubmarkets.current,
    );
    submarketBoundaryDrawer.current?.createSubmarketBoundaries(
      userSelectedSubmarkets.current,
      mapObj.current,
      userSelectedMapArea.current,
    );
    searchInMapAreaDrawer.current?.createMapAreaBoundaries(customMapCoordinates, mapObj.current);

    if (buildingSearchResults.length !== 0 || drawer.current.drawnPolygons.length !== 0) {
      log(`zooming to search results`);
      zoomToSearch();
    }

    // now we watch for manual map movement again
    isMapAutoZooming.current = false;
    log(`setting map as loaded`);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingSearchResults, mapObj.current]);

  useEffect(() => {
    if (!mapObj.current) return;

    if (hoveredListing) {
      const hoveredListingBuildingSlug = hoveredListing.buildingSlug;
      const hoveredListingBuilding = buildingSearchResults.filter(
        building => building.slug === hoveredListingBuildingSlug,
      );
      hoveredListingIndicator.current.addHoveredListingMarker(
        hoveredListingBuilding[0],
        mapObj.current,
      );
    } else {
      hoveredListingIndicator.current.clearHoveredListingMarker();
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredListing]);

  // event handlers for buttons
  const handleOnRedoSearchInCustomArea = () => {
    if (!mapObj.current) return;
    dispatch(searchPageActions.mapCustomAreaSearched());
    dispatch(actions.setDrawnPolygons([]));
    const bounds = mapObj.current.getBounds()?.toUrlValue() as string;
    onRedoSearch(bounds);
    setMapMovedAfterSearchInMapArea(false);
  };

  const handleOnMapReset = () => {
    if (!mapObj.current) return;
    dispatch(actions.setDrawnPolygons([]));
    closeInfoWindow();
    dispatch(actions.mapReset);

    log(`current market position: ${marketPosition.current}`);
    if (marketPosition.current === 'custom') {
      // Do a search in current map view if map is reset on custom market
      handleOnRedoSearchInCustomArea();
    } else {
      onMapReset();

      resetZoom(marketPosition.current);
    }
    log('on map reset');
  };

  const handleOnDrawClicked = () => {
    if (!mapObj.current) return;
    buildingInfoWindow.current?.close();
    dispatch(actions.mapDrawStart);
    drawer.current.startDrawing();
  };

  const handleOnCancelClicked = () => {
    if (!mapObj.current) return;
    drawer.current.cancelDrawing();
    dispatch(actions.mapDrawEnd);
  };

  const handleZoomInClicked = () => {
    if (!mapObj.current) return;
    Zoom.zoomIn(mapObj.current);
    trackMapZoomed();
  };

  const handleZoomOutClicked = () => {
    if (!mapObj.current) return;
    Zoom.zoomOut(mapObj.current);
    trackMapZoomed();
  };

  const wasSearchInMapAreaJustClicked =
    searchInMapAreaDrawer.current?.mapAreaBounds && !mapMovedAfterSearchInMapArea;
  const showSeachInMapAreaButton = userMovedMap && !isDrawing && !wasSearchInMapAreaJustClicked;

  return (
    <section className={s.section} data-testid="mapContainer">
      <div className={s.mapContainer}>
        <div className={s.topButtonsContainer}>
          {isDrawing ? (
            <Button
              data-testid="map-draw"
              type="quarternary"
              size="medium"
              className={s.mapDrawButton}
              onClick={handleOnCancelClicked}
            >
              {t('listingSearch:map.cancelDrawing')}
            </Button>
          ) : (
            <Button
              data-testid="map-draw"
              type="quarternary"
              size="medium"
              className={s.mapDrawButton}
              onClick={handleOnDrawClicked}
            >
              {t('listingSearch:map.draw')}
            </Button>
          )}
          {showSeachInMapAreaButton && (
            <Button
              data-testid="searchInMapArea"
              type="quarternary"
              size="medium"
              className={s.redoSearchButton}
              onClick={handleOnRedoSearchInCustomArea}
            >
              {t('listingSearch:map.redoSearchInMapArea')}
            </Button>
          )}
        </div>
        <div className={s.rightButtonsContainer}>
          <div className={s.zoomButtonsContainer}>
            <div className={s.zoomIn} role="button" onClick={handleZoomInClicked}>
              <CustomIcon type="plus" title={t('listingSearch:map.zoomIn')} />
            </div>
            <div className={s.divider} />
            <div className={s.zoomOut} role="button" onClick={handleZoomOutClicked}>
              <CustomIcon type="minus" title={t('listingSearch:map.zoomOut')} />
            </div>
          </div>
          {(userMovedMap || hasDrawnPolygons) && !isDrawing && (
            <div
              className={s.mapResetButton}
              role="button"
              onClick={handleOnMapReset}
              data-testid="map-reset"
            >
              <div className={s.mapResetButtonIconContainer}>
                <CustomIcon type="map-refresh" title={t('listingSearch:map.reset')} />
              </div>
              <div className={s.mapResetButtonText}>{t('listingSearch:map.reset')}</div>
            </div>
          )}
        </div>
        <div className={s.map} data-testid="map" ref={mapRef}></div>
      </div>
    </section>
  );
};

export default connector(Map);
