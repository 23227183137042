import { useState, useEffect } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import routes from '@root/routes';
import { Divider, Spinner, PopperTooltip, Notification } from '@components/shared';
import { Form, TextArea, Toggle } from '@components/shared/forms';
import useCurrentUser from '@root/shared/useCurrentUser';
import useAnalyticsEvent from '@root/shared/useAnalytics';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, update } from '@root/shared/typedApi';
import { compact } from 'lodash';
import { CurrentUser } from '@root/types';
import { PARAMETERS } from '@root/tracking/constants';
import { InfoIcon } from '@viewthespace/components';
import s from './AccountSettings.module.less';
import FormSubmitSection from './FormSubmitSection';

type FormValues = {
  generalInquiryDefaultMessage: string;
  quickInquiryDefaultMessage: string;
  oneClickInquiryActivated: boolean;
};

const regexToRemoveDoubleSpaces = /\s+/g;
const regexToRemoveCarriageReturn = /[\n\r]/g;

const formatMessageForAnalytics = (message: string) => {
  return message
    .replace(regexToRemoveDoubleSpaces, ' ')
    .replace(regexToRemoveCarriageReturn, '')
    .trim();
};

export const defaultInquiryMessage = (
  t: TFunction,
  translationKey: string,
  currentUser: CurrentUser,
): string => {
  return t(translationKey, {
    signature:
      currentUser.phoneNumber && currentUser.phoneExtension
        ? compact([
            `${currentUser.firstName} ${currentUser.lastName}`,
            t('common:formattedPhoneWithExtension', {
              formattedPhone: currentUser.phoneNumber,
              extension: currentUser.phoneExtension,
            }),
            currentUser.email,
          ]).join('\n')
        : compact([
            `${currentUser.firstName} ${currentUser.lastName}`,
            t('common:formattedPhone', {
              formattedPhone: currentUser.phoneNumber,
            }),
            currentUser.email,
          ]).join('\n'),
  });
};

const useInquirySettings = (currentUser: CurrentUser) => {
  const { t } = useTranslation('inquiry');

  const [inquirySettingsState, setInquirySettingsState] = useState<
    FormValues & { fetched: boolean }
  >({
    fetched: false,
    quickInquiryDefaultMessage: '',
    generalInquiryDefaultMessage: '',
    oneClickInquiryActivated: false,
  });

  const { refetch } = useQuery(
    [routes.api.currentUserInquirySettings],
    async () => get<FormValues>(routes.api.currentUserInquirySettings),
    {
      onSuccess: inquirySettings => {
        const {
          oneClickInquiryActivated,
          quickInquiryDefaultMessage,
          generalInquiryDefaultMessage,
        } = inquirySettings;

        setInquirySettingsState({
          oneClickInquiryActivated,
          quickInquiryDefaultMessage:
            quickInquiryDefaultMessage || defaultInquiryMessage(t, 'defaultMessage', currentUser),
          generalInquiryDefaultMessage:
            generalInquiryDefaultMessage ||
            defaultInquiryMessage(t, 'common:inquiry:defaultMessage', currentUser),
          fetched: true,
        });
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [currentUser, refetch]);

  return {
    ...inquirySettingsState,
  };
};

type Props = {
  currentUser: CurrentUser;
};

const InquirySettingsForm = ({ currentUser }: Props) => {
  const { t } = useTranslation('accountSettings');
  const { userAccountInteraction } = useAnalyticsEvent();
  const { refetch: refetchUser } = useCurrentUser();

  const {
    fetched,
    quickInquiryDefaultMessage,
    generalInquiryDefaultMessage,
    oneClickInquiryActivated,
  } = useInquirySettings(currentUser);

  const updateInquirySettingsMutation = useMutation(
    async ({ inquirySettings }: { inquirySettings: FormValues; onSuccess: (values) => void }) => {
      await update(routes.api.currentUserInquirySettings, inquirySettings);
    },
    {
      onSuccess: async (_, { inquirySettings, onSuccess }) => {
        Notification.info({
          title: t('inquiry.notification.title'),
          text: t('inquiry.notification.text'),
        });
        onSuccess(inquirySettings);
        userAccountInteraction({
          action: PARAMETERS.updateInquirySettings,
          actionType: 'UPDATE_USER_INQUIRY_SETTINGS',
          sourceContent: 'inquiry tab',
          sourcePage: 'account settings',
          otherAttributes: {
            generalInquiry: formatMessageForAnalytics(inquirySettings.generalInquiryDefaultMessage),
            quickInquiry: formatMessageForAnalytics(inquirySettings.quickInquiryDefaultMessage),
            oneClickInquiry: inquirySettings.oneClickInquiryActivated ? 'on' : 'off',
          },
        });
        refetchUser();
      },
    },
  );

  const updateInquirySettings = (formValues: FormValues, helpers) => {
    updateInquirySettingsMutation.mutate({
      inquirySettings: formValues,
      onSuccess: values => helpers.resetForm({ values }),
    });
  };

  const validationSchema = Yup.object().shape({
    generalInquiryDefaultMessage: Yup.string().required(t('inquiry:defaultMessageRequired')),
    quickInquiryDefaultMessage: Yup.string().required(t('inquiry:defaultMessageRequired')),
    oneClickInquiryActivated: Yup.boolean(),
  });

  return fetched ? (
    <Form<FormValues>
      id="inquirySettingsForm"
      initialValues={{
        generalInquiryDefaultMessage,
        quickInquiryDefaultMessage,
        oneClickInquiryActivated,
      }}
      className={s.inquirySettingsForm}
      validationSchema={validationSchema}
      onSubmit={updateInquirySettings}
    >
      <div className="flex items-center justify-between pb-1">
        <h2 className="font-subtitle">{t('inquiry.generalInquiry.header')}</h2>
      </div>
      <div className={s.explanationText}>
        {t('inquiry.generalInquiry.explanationText')}
        <PopperTooltip
          showArrow
          trigger="click"
          triggerElement={<InfoIcon className="ml-[4px] text-indigo-100" size="sm" />}
          popperElementClassName={s.generalInquiryPopperImage}
          popperElement={
            <figure>
              <img
                src="https://media.truva.com/assets/general-inquiry-screenshot.png"
                alt="screenshot of the general inquiry modal"
              />
            </figure>
          }
        />
      </div>
      <Divider />
      <section className={s.inquirySection}>
        <TextArea
          labelText={t('inquiry.messageLabel')}
          name="generalInquiryDefaultMessage"
          data-testid="generalInquiryDefaultMessageTextbox"
          labelClass="!mb-0"
          rows={10}
          required
        />
      </section>
      <div className="flex items-center justify-between pb-1 pt-3">
        <h2 className="font-subtitle">{t('inquiry.quickInquiry.header')}</h2>
      </div>
      <div className={s.explanationText}>
        {t('inquiry.quickInquiry.explanationText')}
        <PopperTooltip
          showArrow
          trigger="click"
          triggerElement={<InfoIcon className="ml-[4px] text-indigo-100" size="sm" />}
          popperElementClassName={s.quickInquiryPopperImage}
          popperElement={
            <figure>
              <img
                src="https://media.truva.com/assets/quick-inquiry-screenshot"
                alt="screenshot of quick inquiry and what it looks like on the search page"
              />
            </figure>
          }
        />
      </div>
      <Divider />
      <section className={s.inquirySection}>
        <TextArea
          labelText={t('inquiry.messageLabel')}
          name="quickInquiryDefaultMessage"
          data-testid="quickInquiryDefaultMessageTextbox"
          labelClass="!mb-0"
          rows={5}
          required
        />

        <section>
          <p className="font-body-large-emphasis">{t('inquiry.oneClick.header')}</p>
          <p className={s.explanationText}>{t('inquiry.oneClick.explanationText')}</p>
          <div>
            <Toggle name="oneClickInquiryActivated" labelText={t('inquiry.oneClick.label')} />
          </div>
        </section>
      </section>
      <FormSubmitSection submittingText={t('common:saving')} />
    </Form>
  ) : (
    <Spinner />
  );
};

export default InquirySettingsForm;
