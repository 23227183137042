import { PARAMETERS } from '@root/tracking/constants';

export const buildingViewedOnMap = (
  sourcePage: typeof PARAMETERS.searchResultsPage | typeof PARAMETERS.tourbookPage,
) => {
  return {
    actionType: 'BUILDING_VIEWED_ON_MAP',
    action: PARAMETERS.viewThumbnailData,
    sourceContent: PARAMETERS.map,
    sourcePage,
  };
};

export const mapDeclustered = (
  sourcePage: typeof PARAMETERS.searchResultsPage | typeof PARAMETERS.tourbookPage,
) => {
  return {
    actionType: 'MAP_DECLUSTERED',
    action: PARAMETERS.decluster,
    sourceContent: PARAMETERS.map,
    sourcePage,
  };
};

export const mapZoomed = (
  sourcePage: typeof PARAMETERS.searchResultsPage | typeof PARAMETERS.tourbookPage,
) => {
  return {
    actionType: 'MAP_ZOOMED',
    action: PARAMETERS.zoom,
    sourceContent: PARAMETERS.map,
    sourcePage,
  };
};

export const clickToScrollToEntry = (sourcePage: typeof PARAMETERS.tourbookPage) => {
  return {
    action: PARAMETERS.mapEntryClickToScroll,
    actionType: 'MAP_CLICK_TO_SCROLL_TO_ENTRY',
    sourceContent: PARAMETERS.map,
    sourcePage,
  };
};
