import * as React from 'react';
import { isUndefined } from 'lodash';
import cn from 'classnames';
import s from './RawToggle.module.less';

type Props = {
  name: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  dataTestId?: string;
  size?: 'medium' | 'large';
};

const RawToggle = ({ name, onChange = () => {}, checked, dataTestId, disabled, size }: Props) => {
  let controlledAttributes: {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
  } = {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked),
  };

  if (!isUndefined(checked)) {
    controlledAttributes = { checked, onChange: () => onChange(!checked) };
  }

  return (
    <span className={s.container}>
      <input
        {...controlledAttributes}
        className={cn(s.input, size === 'large' && s.large)}
        name={name}
        type="checkbox"
        id={name}
        disabled={disabled}
        data-testid={dataTestId}
      />
      {/* eslint-disable react/jsx-no-literals */}
      <label className={cn(s.label, size === 'large' && s.large)} htmlFor={name}>
        Toggle
      </label>
      {/* eslint-enable react/jsx-no-literals */}
    </span>
  );
};

export default RawToggle;
