import { ConvertableMoneyPerAreaPerPeriod, ConvertableMoneyPerPeriod, Currency } from '@root/types';
import { isNumber } from 'lodash';
import { TFunction } from 'i18next';
import tourbookListingCardStyles from './TourbookListingCard/TourbookListingCard.module.less';

const MOBILE_SCROLL_OFFSET = -88;
const DEFAULT_SCROLL_OFFSET = -112;

export const scrollToTourbookListingCard = ({
  listingId,
  draggable = false,
  isMobile = false,
}: {
  listingId: string;
  draggable?: boolean;
  isMobile?: boolean;
}) => {
  if (draggable) return;

  const scrollOffset = isMobile ? MOBILE_SCROLL_OFFSET : DEFAULT_SCROLL_OFFSET;
  const listingCard = document.getElementById(listingId);
  if (listingCard) {
    const y = listingCard.getBoundingClientRect().top + window.scrollY + scrollOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
    listingCard.classList.add(tourbookListingCardStyles.tourbookListingCardHover);

    // Remove the class so that the animation can fire again.
    setTimeout(() => {
      listingCard.classList.remove(tourbookListingCardStyles.tourbookListingCardHover);
    }, 2000);
  }
};

type GetDisplayPricePerAreaProps = {
  t: TFunction;
  askingRent?: ConvertableMoneyPerAreaPerPeriod | null;
  minAskingRent?: ConvertableMoneyPerAreaPerPeriod | null;
  maxAskingRent?: ConvertableMoneyPerAreaPerPeriod | null;
  acceptRange: boolean;
};

export const getDisplayPricePerArea = ({
  t,
  askingRent,
  minAskingRent,
  maxAskingRent,
  acceptRange = true,
}: GetDisplayPricePerAreaProps): string | null => {
  return t('units:pricePerAreaPerPeriodRange', {
    pricePerAreaPerPeriodRange: {
      min: (acceptRange && minAskingRent) || askingRent,
      max: acceptRange && minAskingRent ? maxAskingRent : null,
    },
  });
};

type GetDisplayPriceProps = {
  t: TFunction;
  askingRent?: ConvertableMoneyPerPeriod | null;
  minAskingRent?: ConvertableMoneyPerPeriod | null;
  maxAskingRent?: ConvertableMoneyPerPeriod | null;
  acceptRange: boolean;
};

export const getDisplayPrice = ({
  t,
  askingRent,
  minAskingRent,
  maxAskingRent,
  acceptRange = true,
}: GetDisplayPriceProps): string | null => {
  return t('units:pricePerPeriodRange', {
    pricePerPeriodRange: {
      min: (acceptRange && minAskingRent) || askingRent,
      max: acceptRange && minAskingRent ? maxAskingRent : null,
      abbreviatedPeriod: true,
    },
  });
};

export const formatAskingRentInMoneyPerPeriod = (
  askingRent: number | undefined | null,
  units: 'sf/yr' | 'Mo',
  area: number | '' | null,
  currency: Currency,
): ConvertableMoneyPerPeriod | null => {
  if (!isNumber(askingRent)) return null;

  if (units === 'Mo') {
    return {
      magnitude: askingRent,
      type: 'money/period',
      units: {
        money: currency,
        period: 'mo',
      },
    };
  }

  return area
    ? {
        magnitude: Math.round((askingRent * area) / 12),
        type: 'money/period',
        units: {
          money: currency,
          period: 'mo',
        },
      }
    : null;
};

export const formatAskingRentInMoneyPerAreaPerPeriod = (
  askingRent: number | undefined | null,
  units: 'sf/yr' | 'Mo',
  area: number | '' | null,
  currency: Currency,
): ConvertableMoneyPerAreaPerPeriod | null => {
  if (!isNumber(askingRent)) return null;
  if (units === 'sf/yr') {
    return {
      magnitude: askingRent,
      type: 'money/area/period',
      units: {
        area: 'sf',
        money: currency,
        period: 'year',
      },
    };
  }

  return area
    ? {
        magnitude: Math.round((askingRent * 12) / area),
        type: 'money/area/period',
        units: {
          area: 'sf',
          money: currency,
          period: 'year',
        },
      }
    : null;
};
