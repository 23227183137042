import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import useEnvVariable from '@root/shared/useEnv';
import api from '@shared/api';
import routes from '@root/routes';
import { AdminBuilding } from '@root/types';
import type { DefaultCloudinaryResponseType } from '@components/shared/Admin/MultimediaUploader';
import { Button, Modal } from '@components/shared';
import css from '../styles.module.less';
import s from './BrochureAppendPrepend.module.less';
import { FileInput } from '../FileInput';

export type DocType = 'PDF_PREPEND' | 'PDF_APPEND';

export type BrochureAppendPrependDocType = {
  id: number;
  attr_config_id: number;
  type: DocType;
  value: string;
  name: string;
  filename: string;
  cloudinaryId?: string;
  removed?: boolean;
};

const {
  api: {
    admin: { appendPrependLink: appendPrependRoute },
  },
} = routes;

export const BrochureAppendPrepend = ({ building }: { building: AdminBuilding }) => {
  const { t } = useTranslation('admin');
  const { cloudinaryUploadPreset, cloudinaryCloud } = useEnvVariable();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentDocType, setCurrentDocType] = useState('');
  const [brochureAppendPrependDocs, setBrochureAppendPrependDocs] = useState<
    Array<BrochureAppendPrependDocType>
  >([]);

  const fetchDocs = async () => {
    try {
      const response = await api.fetch(
        appendPrependRoute(building?.owningCompany?.id, building?.slug),
      );
      const data = await response.json();

      if (response.ok) {
        setBrochureAppendPrependDocs(() => [...data]);
      }
    } catch {
      notification.error(t('common.error'));
    }
  };

  const handlePdfUpload = async (files: FileList, type: string) => {
    if (files.length === 0) return;

    const file = Array.from(files)[0];

    const { cloudinaryJson } = await uploadDocToCloudinary(file);
    const { response } = await sendBrochureAttributes(cloudinaryJson, type as DocType);

    handleResponse(
      response,
      () => fetchDocs(),
      t('building.channels.pdfUploadSuccess'),
      t('building.channels.errors.pdfUploadFailed'),
    );
  };

  const uploadDocToCloudinary = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', cloudinaryUploadPreset || '');

    const cloudinaryResponse = await api.fetch(
      `https://api.cloudinary.com/v1_1/${cloudinaryCloud}/image/upload`,
      {
        method: 'POST',
        body: formData,
      },
    );

    const cloudinaryJson: DefaultCloudinaryResponseType = await cloudinaryResponse.json();

    return { cloudinaryJson };
  };

  const sendBrochureAttributes = async (
    cloudinaryJson: DefaultCloudinaryResponseType,
    type: DocType,
  ) => {
    const { public_id: cloudinaryId, original_filename: originalFilename, format } = cloudinaryJson;
    const filename = `${originalFilename}.${format}`;

    const { response } = await updateBrochureAttributes({ cloudinaryId, filename, type });

    return { response };
  };

  const removeBrochureDoc = async () => {
    const { response } = await updateBrochureAttributes({
      removeDoc: true,
      type: currentDocType as DocType,
    });
    const onSuccess = () => {
      fetchDocs();
      setShowConfirmationModal(false);
    };

    handleResponse(
      response,
      onSuccess,
      t('building.channels.brochurePdfRemovalSuccess'),
      t('building.channels.errors.brochurePdfRemovalFailed'),
    );
  };

  const updateBrochureAttributes = async ({
    removeDoc,
    cloudinaryId,
    filename,
    type,
  }: {
    removeDoc?: boolean;
    cloudinaryId?: string;
    filename?: string;
    type?: DocType;
  }) => {
    const modifiedDocsData =
      brochureAppendPrependDocs?.map(doc => {
        const newDoc = { ...doc };
        if (removeDoc && type && doc.type === type) {
          newDoc.removed = true;
        }

        if (!removeDoc && type && doc.type === type) {
          newDoc.cloudinaryId = cloudinaryId;
          newDoc.filename = filename!;
        }
        return newDoc;
      }) || [];

    const response = await api.put(
      appendPrependRoute(building?.owningCompany?.id, building?.slug),
      {
        brochure_attrs: [...modifiedDocsData],
      },
    );

    return { response };
  };

  const handleResponse = (
    response: Response,
    onSuccess: () => void,
    successMessage?: string,
    errorMessage?: string,
  ) => {
    if (response.ok) {
      if (successMessage) {
        notification.success({
          message: successMessage,
        });
      }
      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } else if (errorMessage) {
      notification.error({
        message: errorMessage,
      });
    }
  };

  const handleClose = (type: string) => {
    setShowConfirmationModal(true);
    setCurrentDocType(type);
  };

  useEffect(() => {
    fetchDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  if (brochureAppendPrependDocs.length === 0) return null;

  return (
    <div className={css.prependAppendContainer}>
      <div>
        <span className={css.pdfAppendPrependText}>{t(`building.channels.prepend`)}</span>
        <FileInput
          id="prepend-file"
          name="PDF_PREPEND"
          accept=".pdf"
          cb={handlePdfUpload}
          filename={
            brochureAppendPrependDocs?.find(doc => doc.value && doc.type === 'PDF_PREPEND')
              ?.filename
          }
          onClose={handleClose}
        />
      </div>
      <div>
        <span className={css.pdfAppendPrependText}>{t(`building.channels.append`)}</span>
        <FileInput
          id="append-file"
          name="PDF_APPEND"
          accept=".pdf"
          cb={handlePdfUpload}
          filename={
            brochureAppendPrependDocs?.find(doc => doc.value && doc.type === 'PDF_APPEND')?.filename
          }
          onClose={handleClose}
        />
      </div>
      <Modal
        isOpen={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        className={s.modalContainer}
      >
        <div className={s.modalContentWrapper}>
          <div className={s.modalHeader}>{t('building.channels.removePdf')}</div>
          <p className={s.modalBodyContent}>{t('building.channels.confirmPdfRemovalMessage')}</p>
          <div className={s.buttonsContainer}>
            <Button
              className={s.cancelButton}
              type="tertiary"
              onClick={() => setShowConfirmationModal(false)}
            >
              {t('common.cancelButton')}
            </Button>
            <Button
              className={s.removeButton}
              type="secondary"
              onClick={() => removeBrochureDoc()}
              data-testid="remove-button"
            >
              {t('common.removeButton')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BrochureAppendPrepend;
