import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, ButtonLink } from '@components/shared';
import useCurrentUser from '@root/shared/useCurrentUser';
import { isInIframe, sendHostToMarketListingsPage } from '@root/shared/iframeUtils';

import routes from '@root/routes';
import EmptyStateSvg from './empty-state.svg';

type Props = {
  readOnly: boolean;
};

const EmptyState = ({ readOnly }: Props) => {
  const { t } = useTranslation('tourbook');
  const { currentUser } = useCurrentUser();
  const inIframe = isInIframe();
  const showCTA = !readOnly || inIframe;

  return (
    <div
      className={cn(
        'mx-auto mt-8 grid max-w-[384px] grid-cols-1 justify-items-center pb-9',
        '[&>svg]:h-[296px] [&>svg]:w-[385px]',
        'mobile:mt-6 mobile:px-2 mobile:pt-0 mobile:[&>svg]:h-[250px] mobile:[&>svg]:w-[300px]',
      )}
    >
      <EmptyStateSvg />
      <h2 className="font-title">{t('showEmptyState.header')}</h2>
      {showCTA && (
        <>
          <div className="mb-4 mt-1 text-center font-body-large">{t('showEmptyState.cta')}</div>
          {inIframe ? (
            <Button
              type="primary"
              size="medium"
              onClick={() => {
                sendHostToMarketListingsPage();
              }}
            >
              {t('showEmptyState.searchButton')}
            </Button>
          ) : (
            <ButtonLink type="primary" href={routes.search(currentUser!.marketSlug)} size="large">
              {t('showEmptyState.searchButton')}
            </ButtonLink>
          )}
        </>
      )}
    </div>
  );
};

export default EmptyState;
