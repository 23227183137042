import cn from 'classnames';
import s from './InvisibleMask.module.less';

type Props = {
  className?: string;
  onClick?: () => void;
};

/**
 * Usage: give it a class for its z-index and it will block clicks
 * so that your modal can stand alone. You can even give it a
 * background w/ low opacity if you want
 */
const InvisibleMask = ({ className, onClick }: Props) => (
  <div
    role="button"
    data-testid="invisibleMask"
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      if (onClick) onClick();
    }}
    className={cn(s.invisibleMask, className)}
  />
);

export default InvisibleMask;
