import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Video, MultipathImage, Address, TourbookListingAnalyticsInformation } from '@root/types';
import {
  OnlyInMobile,
  OnlyInDesktop,
  OnlyInTablet,
  useLightbox,
  FullWidthLightBox,
} from '@components/shared';
import { SourcePage, SourceContent } from '@root/store/actions/videoAnalytics';
import useAnalytics from '@shared/useAnalytics';
import listingGalleryStyle from '@components/layouts/Truva/Listing/Gallery/Gallery.module.less';
import useAltText from '@root/shared/useAltText';
import s from './TourbookMosaicGallery.module.less';

export const sourceForAsset = ({
  index,
  numPhotos,
  asset,
  isDesktop,
}: {
  index: number;
  numPhotos: number;
  asset: MultipathImage;
  isDesktop: boolean;
}) => {
  if (numPhotos >= 5) {
    if (index === 0) {
      return asset.mediumPath;
    }
    return asset.smallPath;
  }

  if (numPhotos >= 2) {
    return asset.mediumPath;
  }

  if (isDesktop) {
    return asset.largePath;
  }
  return asset.mediumPath;
};

type Props = {
  video?: Video | null;
  photos: MultipathImage[];
  trackLightboxThumbnailClick?: () => void;
  trackLightboxPagination?: () => void;
  trackLightboxFloorPlanClick?: () => void;
  sourcePage: SourcePage;
  sourceContent: SourceContent | null;
  noLightbox?: boolean;
  imagesZipUrl?: string | null;
  tourEmbedUrl?: string | null;
  address: Address;
  external: boolean;
  analyticsInformation?: TourbookListingAnalyticsInformation;
};

// THIS IS THE ASSETS DISPLAYED FOR TABLET AND DESKTOP
const TourbookMosaicGallery = ({
  photos,
  video = null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackLightboxThumbnailClick = () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackLightboxPagination = () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackLightboxFloorPlanClick = () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourcePage,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sourceContent,
  noLightbox = false,
  imagesZipUrl = null,
  tourEmbedUrl = null,
  address,
  external,
  analyticsInformation,
}: Props) => {
  const { t } = useTranslation('tourbook');

  // all the state for the full-width lightbox
  const {
    lightboxOpen,
    currentIndex,
    activeTab,
    visibleElementOnTours,
    setLightboxOpen,
    setCurrentIndex,
    setActiveTab,
    setVisibleElementOnTours,
    closeLightbox,
  } = useLightbox({
    startingVisibleElementOnTours: video ? 'video' : 'tourEmbed',
    has3dTour: !!tourEmbedUrl,
    hasVideo: !!video,
  });

  const { galleryInteraction, PARAMETERS } = useAnalytics();
  const { getAltText } = useAltText({ address });

  const openLightbox = (index: number) => {
    if (!lightboxOpen) {
      setCurrentIndex(index);
      setLightboxOpen(true);
    }
  };

  const handleLightboxClose = () => {
    setTimeout(closeLightbox, 250);
  };

  const assetsCount = (photos ? photos.length : 0) + (video ? 1 : 0);
  const assets: Array<MultipathImage | (Video & { isVideo: true })> = [...photos];

  let mediaDisplayCount;
  if (assetsCount >= 5) {
    mediaDisplayCount = 5;
  } else if (assetsCount === 4) {
    // only show 3 media for aesthetic reasons
    mediaDisplayCount = 3;
  } else {
    mediaDisplayCount = assetsCount;
  }

  const galleryAssets: Array<MultipathImage | (Video & { isVideo: true })> = [];

  const floorPlanIndex = photos.findIndex(photo => photo?.type === 'floorPlan');
  const floorPlan = photos[floorPlanIndex];
  if (floorPlan) {
    galleryAssets.push(floorPlan);
  }

  if (video) {
    galleryAssets.unshift({ ...video, isVideo: true });
    assets.push({ ...video, isVideo: true });
  }

  const imagesSubset = photos
    .filter(photo => photo?.type !== 'floorPlan')
    .slice(0, mediaDisplayCount - galleryAssets.length);
  galleryAssets.unshift(...imagesSubset);

  // special ordering for assets on tablet
  const tabletAssets = [...galleryAssets];
  if (tabletAssets.length >= 3) {
    if (video) {
      tabletAssets.splice(1, 0, { ...video, isVideo: true });
    }
    if (floorPlan) {
      tabletAssets.splice(2, 0, floorPlan);
    }
  }

  // Swap the order of the video so it appears in the top right corner
  // which is the second last element in galleryAssets
  if ((galleryAssets.length === 5 || galleryAssets.length === 3) && video && !floorPlan) {
    // eslint-disable-next-line prefer-destructuring
    galleryAssets[galleryAssets.length - 1] = galleryAssets[galleryAssets.length - 2];
    galleryAssets[galleryAssets.length - 2] = { ...video, isVideo: true };
  }

  // Swap the order of the video so it appears last
  if (galleryAssets.length === 2 && video && floorPlan) {
    galleryAssets[0] = floorPlan;
    galleryAssets[1] = { ...video, isVideo: true };
  }

  const viewTourbookListingEvent = () => {
    galleryInteraction({
      sourcePage: PARAMETERS.tourbookPage,
      sourceContent: external
        ? PARAMETERS.tourbookExternalListingGallery
        : PARAMETERS.tourbookListingGallery,
      action: external ? PARAMETERS.viewTourbookExternalListing : PARAMETERS.viewTourbookListing,
      actionType: 'VIEW_TOURBOOK_LISTING',
      analyticsInformation,
    });
  };

  const openLightboxEvent = (action: string) => {
    galleryInteraction({
      sourcePage: PARAMETERS.tourbookPage,
      sourceContent: external
        ? PARAMETERS.tourbookExternalListingGallery
        : PARAMETERS.tourbookListingGallery,
      action,
      actionType: 'LIGHTBOX_OPENED',
      analyticsInformation,
    });
  };

  const openImage = (asset: MultipathImage) => () => {
    if (asset.type === 'floorPlan') {
      openLightbox(floorPlanIndex);
      openLightboxEvent(PARAMETERS.viewFloorPlan);
    } else {
      const position = photos.map(p => p.cloudinaryId).indexOf(asset.cloudinaryId);
      openLightbox(position);
      openLightboxEvent(PARAMETERS.openLightbox);
    }
    viewTourbookListingEvent();
  };

  const tabletDisplayCount = galleryAssets.length > 3 ? 3 : galleryAssets.length;

  const handleVideoClick = () => {
    setActiveTab('virtualTours');
    openLightbox(currentIndex);
    openLightboxEvent(PARAMETERS.viewVideo);
    viewTourbookListingEvent();
  };

  const lightboxComponent = (
    <FullWidthLightBox
      address={address!}
      assets={photos}
      currentIndex={currentIndex}
      onClose={handleLightboxClose}
      onChange={setCurrentIndex}
      video={video}
      imagesZipUrl={imagesZipUrl}
      tourEmbedUrl={tourEmbedUrl}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      visibleElementOnTours={visibleElementOnTours}
      setVisibleElementOnTours={setVisibleElementOnTours}
      sourcePage={sourcePage}
      sourceContent={
        external ? PARAMETERS.externalListingLightboxGallery : PARAMETERS.lightboxGallery
      }
      analyticsInformation={analyticsInformation}
    />
  );

  const galleryImageComponent = (
    asset: MultipathImage,
    index: number,
    displayCount: number,
    isDesktop: boolean,
  ) => {
    let imgClass = '';
    if (isDesktop) {
      if (asset.type === 'floorPlan') {
        imgClass = s.floorPlanImg;
      }
    } else {
      imgClass = s.mobileImage;
    }

    return (
      <button
        type="button"
        onClick={openImage(asset)}
        className={s[`position-${index}-${displayCount}`]}
        key={asset.cloudinaryId || asset.path}
      >
        <img
          src={sourceForAsset({
            index,
            numPhotos: displayCount,
            asset,
            isDesktop,
          })}
          alt={getAltText({ type: asset.type, options: { roomName: asset.description } })}
          className={imgClass}
        />
      </button>
    );
  };

  return assetsCount > 0 ? (
    <>
      {photos.length > 0 ? (
        <OnlyInMobile>
          <div
            data-testid="media-assets"
            className={cn(
              s[`media-container-1-assets`],
              video && s.withVideo,
              noLightbox && s.noLightbox,
            )}
          >
            {assetsCount > 1 && !noLightbox ? (
              <div className={s.viewAllDynamicPlacement}>
                <button
                  className={listingGalleryStyle.viewAllBtn}
                  type="button"
                  onClick={() => {
                    openLightbox(0);
                    openLightboxEvent(PARAMETERS.openLightbox);
                    viewTourbookListingEvent();
                  }}
                >
                  {t('mosaicGallery.viewAll')}
                </button>
              </div>
            ) : (
              ''
            )}
            {galleryImageComponent(photos[0], 0, 1, false)}
          </div>
        </OnlyInMobile>
      ) : (
        ''
      )}
      <OnlyInTablet>
        <div
          data-testid="media-assets"
          className={cn(
            s[`media-container-${tabletDisplayCount}-assets`],
            video && s.withVideo,
            noLightbox && s.noLightbox,
          )}
        >
          {!noLightbox && (
            <div className={s.viewAllDynamicPlacement}>
              <button
                className={listingGalleryStyle.viewAllBtn}
                type="button"
                onClick={() => {
                  openLightbox(0);
                  openLightboxEvent(PARAMETERS.openLightbox);
                  viewTourbookListingEvent();
                }}
              >
                {t('mosaicGallery.viewAll')}
              </button>
            </div>
          )}
          {tabletAssets.map(
            (asset, index) =>
              // tablet view shows at most 3 images
              index < 3 &&
              ('isVideo' in asset ? (
                <button
                  type="button"
                  onClick={handleVideoClick}
                  className={s[`position-${index}-${tabletDisplayCount}`]}
                  key={asset.cloudinaryId}
                >
                  <img
                    src={asset.thumbnailPosterPath}
                    alt={getAltText({ type: 'videoThumbnailPhoto' })}
                  />
                </button>
              ) : (
                galleryImageComponent(asset, index, tabletDisplayCount, true)
              )),
          )}
        </div>
      </OnlyInTablet>
      <OnlyInDesktop>
        <div
          data-testid="media-assets"
          className={cn(
            s[`media-container-${mediaDisplayCount}-assets`],
            video && s.withVideo,
            noLightbox && s.noLightbox,
          )}
        >
          {!noLightbox && (
            <div className={s.viewAllDynamicPlacement}>
              <button
                className={listingGalleryStyle.viewAllBtn}
                type="button"
                onClick={() => {
                  openLightbox(0);
                  openLightboxEvent(PARAMETERS.openLightbox);
                  viewTourbookListingEvent();
                }}
              >
                {t('mosaicGallery.viewAll')}
              </button>
            </div>
          )}
          {galleryAssets.map((asset, index) =>
            'isVideo' in asset ? (
              <button
                type="button"
                onClick={handleVideoClick}
                className={s[`position-${index}-${mediaDisplayCount}`]}
                key={asset.cloudinaryId}
              >
                <img
                  src={asset.thumbnailPosterPath}
                  alt={getAltText({ type: 'videoThumbnailPhoto' })}
                />
              </button>
            ) : (
              galleryImageComponent(asset, index, mediaDisplayCount, true)
            ),
          )}
        </div>
      </OnlyInDesktop>
      {!noLightbox && lightboxOpen && lightboxComponent}
    </>
  ) : (
    // preview / no images scenario
    <div data-testid="media-assets-preview" className={cn(s.previewContainer)}></div>
  );
};

export default TourbookMosaicGallery;
