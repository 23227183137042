import { useTranslation } from 'react-i18next';
import { connect, useDispatch, ConnectedProps } from 'react-redux';
import { PARAMETERS } from '@root/tracking/constants';
import { Card, OnlyInMobile, ExpandableDescription, VideoPlayer } from '@components/shared';
import { Row, Col } from '@components/antd';
import routes from '@root/routes';
import { Landlord } from '@root/types';
import {
  galleryNavigated,
  landlordThumbnailClicked,
  savedBuildingOwnerNotificationLinkClicked,
} from '@store/actions/landlordPage';
import SafeRender from '@components/shared/SafeRender';
import Portfolio from './Portfolio';
import Layout from '../Layout';
import LandlordHeaderSection from './LandlordHeaderSection';
import RawImages from '../Building/Images/RawImages';
import LandlordHead from './LandlordHead';
import s from './Landlord.module.less';

const mapDispatch = {
  savedBuilingOwnerLinkClicked: () => savedBuildingOwnerNotificationLinkClicked,
};

const connector = connect(null, mapDispatch);
type PassedProps = {
  landlord: Landlord;
  hasLoadedOnce: boolean;
  isPreview?: boolean;
};
type ReduxProps = ConnectedProps<typeof connector>;

export const LandlordLayout = ({
  savedBuilingOwnerLinkClicked,
  hasLoadedOnce,
  landlord,
  isPreview = false,
}: ReduxProps & PassedProps) => {
  const { t } = useTranslation('landlord');
  const dispatch = useDispatch();

  return (
    <Layout showFooter={!!landlord}>
      <LandlordHead landlord={landlord} isPreview={isPreview} />
      <LandlordHeaderSection
        landlord={landlord}
        savedBuilingOwnerLinkClicked={savedBuilingOwnerLinkClicked}
        hasLoadedOnce={hasLoadedOnce}
      />
      <div className={s.mediaAssets}>
        <RawImages
          photos={landlord.photos}
          video={landlord.video}
          lightboxPaginationClicked={() => dispatch(galleryNavigated)}
          lightboxThumbnailClicked={() => dispatch(landlordThumbnailClicked)}
          sourcePage={PARAMETERS.landlordPage}
          sourceContent={PARAMETERS.brandGallery}
          firstPhotoOrientation={landlord.firstPhotoOrientation}
          imagesZipUrl={routes.api.landlordImagesZipUrl(landlord.slug)}
          companyName={landlord.name}
        />
      </div>
      <Row gutter={16}>
        <Col lg={16} md={16} sm={24}>
          {/* eslint-disable react/no-danger */}
          <ExpandableDescription>
            <SafeRender unsafeContent={landlord.description}>
              <span />
            </SafeRender>
          </ExpandableDescription>
          {/* eslint-enable react/no-danger */}
          {landlord.video ? (
            <OnlyInMobile>
              <VideoPlayer
                video={landlord.video}
                className={s.mobileVideo}
                sourcePage={PARAMETERS.landlordPage}
                sourceContent={PARAMETERS.spotlightTour}
                testId="mobileVideo"
              />
            </OnlyInMobile>
          ) : null}
        </Col>
        {landlord.executives && landlord.executives.length > 0 ? (
          <Col className={s.cardColumn} lg={8} md={8} sm={24}>
            <Card title={t('keyExecutives')}>
              <ul className={s.keyExecutiveList}>
                {landlord.executives.map(executive => (
                  <li key={executive}>{executive}</li>
                ))}
              </ul>
            </Card>
          </Col>
        ) : null}
      </Row>
      <Portfolio
        landlordSlug={landlord.slug}
        landlordId={landlord.id}
        isPreview={isPreview}
        marketSlugs={landlord.marketSlugs}
      />
    </Layout>
  );
};

export default connector(LandlordLayout);
