import { compact } from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { AgentContact } from '@root/types';
import Avatar from '@components/shared/ListingAgentOption/Avatar';
import Select from '@components/antd/Select';

import s from './ListingAgentOption.module.less';

type ListingAgentOptionProps = {
  listingAgent: AgentContact;
  displayAgentContact?: boolean;
  isCompact?: boolean;
};
const ListingAgentOption = ({
  listingAgent,
  displayAgentContact = false,
  isCompact = false,
}: ListingAgentOptionProps) => {
  const { t } = useTranslation('listing');
  const { name, email, phone, company, role, photoUrl } = listingAgent;
  const formattedPhoneNumber = t('common:formattedPhone', { formattedPhone: phone });
  const roleCompany = compact([role, company]).join(', ');
  const emailPhone = compact([email, formattedPhoneNumber]).join(' | ');

  return (
    <div className={s.optionContainer}>
      <div>
        <Avatar name={name} photoUrl={photoUrl} isCompact={isCompact} />
      </div>
      <div>
        <div className={s.contactName}>{name}</div>
        {roleCompany.length > 0 && <div className={s.contactDetails}>{roleCompany}</div>}
        {displayAgentContact && emailPhone.length > 0 && (
          <div data-testid="listing-agent-email-phone" className={s.contactDetails}>
            {emailPhone}
          </div>
        )}
      </div>
    </div>
  );
};

type ListingAgentDropdownProps = {
  listingAgents: AgentContact[];
  onChange?: (arg: string[]) => void;
  displayAgentContact?: boolean;
  selectedAgentIndex?: number;
  isCompact?: boolean;
};

export const ListingAgentDropdown = ({
  listingAgents,
  onChange,
  displayAgentContact = false,
  selectedAgentIndex = 0,
  isCompact = false,
}: ListingAgentDropdownProps) => {
  const triggerChange = changedValue => {
    if (onChange) {
      onChange([changedValue]);
    }
  };

  return (
    <Select
      onChange={triggerChange}
      dropdownClassName={classNames(s.selectDropdown, isCompact && s.compact)}
      className={classNames(
        isCompact ? s.compactSelectContainer : s.selectContainer,
        displayAgentContact ? s['selectContainer--displayAgentContact'] : '',
      )}
      defaultValue={() =>
        listingAgents.length ? (
          <ListingAgentOption
            listingAgent={listingAgents[selectedAgentIndex]}
            displayAgentContact={displayAgentContact}
            isCompact={isCompact}
          />
        ) : null
      }
    >
      {listingAgents.map(agent => (
        <Select.Option key={agent.id} data-testid="listingAgentDropdown">
          <ListingAgentOption
            listingAgent={agent}
            displayAgentContact={displayAgentContact}
            isCompact={isCompact}
          />
        </Select.Option>
      ))}
    </Select>
  );
};

export default ListingAgentOption;
