import * as React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Checkbox from '@components/shared/forms/RawCheckbox';
import { useListingSearchCriteria } from '@components/layouts/Truva/ListingSearch/utils';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import s from '../Filters.module.less';
import {
  SearchCriteriaWithMultipleOptions,
  SearchCriteriaWithMultipleOptionsKeys,
} from '../../utils/Criteria/ListingSearchCriteria';

type Props = {
  filters: Array<{
    label: string;
    value: SearchCriteriaWithMultipleOptions[SearchCriteriaWithMultipleOptionsKeys];
    translationKey: string;
  }>;
  filterType: SearchCriteriaWithMultipleOptionsKeys;
  filterDispatchAction;
  sortFunction?: Function;
};

const defaultSortFunction = t => {
  return (filterA, filterB) => t(filterA.translationKey).localeCompare(t(filterB.translationKey));
};

const CheckboxFilter = ({ filters, filterType, filterDispatchAction, sortFunction }: Props) => {
  const criteria = useListingSearchCriteria();
  const flags = useFlags();
  const { checkboxFilterInteraction } = useFilterInteraction();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const toggleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nowChecked = e.target.checked;
    const filterValue = e.target.value;
    if (nowChecked) {
      criteria.addValue(filterType, filterValue);
    } else {
      criteria.removeValue(filterType, filterValue);
    }

    if (flags['search-analytics-refactor']) {
      checkboxFilterInteraction({
        nowChecked,
        filterType,
        filterValue,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatch(filterDispatchAction(criteria.currentFilters[filterType] || []));
    }
    criteria.pushToHistory();
  };

  return (
    <>
      <ul className={s.filterList}>
        {filters
          ?.sort(sortFunction ? sortFunction(t) : defaultSortFunction(t))
          .map(({ label, value, translationKey }) => (
            <li key={label}>
              <Checkbox
                checked={(criteria.currentFilters[filterType] as string[])?.includes(value)}
                name={`${label}`}
                value={value}
                className={s.checkbox}
                onChange={toggleCheckbox}
              >
                {t(translationKey)}
              </Checkbox>
            </li>
          ))}
      </ul>
    </>
  );
};

export default CheckboxFilter;
