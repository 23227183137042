import { useTranslation } from 'react-i18next';
import { GB_SPACE_CONDITIONS, LEASE_TYPES, US_SPACE_CONDITIONS } from '@root/types';

import { Select, NumberInput, TextArea, DateInput, Checkbox } from '@components/shared/forms';
import { useFormikContext } from 'formik';
import { camelCase } from 'lodash';
import cn from 'classnames';
import orderSpaceConditions from '@root/shared/orderSpaceConditions';
import RichTextEditorInput from '@components/shared/forms/RichTextEditorInput';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormValues } from '.';
import styles from './TourbookExternalListing.module.less';
import AskingRentField from './AskingRentField';

type RefFn = (node: Element | undefined | null) => void;
type Props = {
  containerRef?: RefFn | null;
};

export const ListingFields = ({ containerRef = null }: Props) => {
  const { t } = useTranslation('tourbook');
  const flags = useFlags();

  const {
    values: { availableImmediately, countryCode, descriptionHtml },
  } = useFormikContext<FormValues>();

  const spaceConditions = countryCode === 'GB' ? GB_SPACE_CONDITIONS : US_SPACE_CONDITIONS;
  const orderedSpaceConditions = orderSpaceConditions([...spaceConditions], t);

  return (
    <section ref={containerRef} id="listing-fields">
      <div className={cn(styles.locationLabel, styles.bottomMargin)}>
        <span>{t('externalListing.listing')}</span>
      </div>
      <NumberInput
        name="size"
        required
        placeholder={t('externalListing.sizePlaceholder')}
        labelText={t('externalListing.sizeLabel')}
        labelClass="!text-black-055"
        autoComplete={false}
        units="sf"
      />
      <AskingRentField />
      <DateInput
        name="availabilityDate"
        labelText={t('externalListing.available')}
        labelClass="!text-black-055"
        placeholder={t('externalListing.blankDate')}
        required
        disabled={availableImmediately}
        autoComplete="none"
      >
        <Checkbox
          labelClassName={styles.availableNowCheckbox}
          name="availableImmediately"
          size="small"
        >
          {t('externalListing.availableNow')}
        </Checkbox>
      </DateInput>
      <Select
        required
        labelText={t('externalListing.condition')}
        labelClass="!text-black-055"
        name="condition"
        isClearable={false}
        options={orderedSpaceConditions.map(spaceCondition => ({
          label: t(`spaceConditions:${camelCase(spaceCondition)}`),
          value: spaceCondition,
        }))}
      />
      <Select
        required
        labelText={t('externalListing.leaseTypeLabel')}
        labelClass="!text-black-055"
        name="leaseType"
        isClearable={false}
        options={LEASE_TYPES.map(leaseType => ({
          label: t(`admin:listing.leaseTypes.${leaseType}`),
          value: leaseType,
        }))}
      />
      {flags['market.listing-space-descriptions-support-rich-text'] ? (
        <RichTextEditorInput
          name="descriptionHtml"
          labelText={t('externalListing.description')}
          hideLabel
          editorClassName="min-h-[100px] bg-white"
          initialContent={descriptionHtml}
          excludeMenuItems={['textSize', 'textAlign']}
        />
      ) : (
        <TextArea
          className={styles.textArea}
          labelText={t('externalListing.description')}
          labelClass="!text-black-055"
          placeholder={t('externalListing.description')}
          name="description"
        />
      )}
    </section>
  );
};

export default ListingFields;
