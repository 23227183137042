import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import UtmAwareLink from '@components/shared/UtmAwareLink/UtmAwareLink';

export type BreadcrumbItem = {
  content: string;
  link?: string;
  onClick?: () => void;
  key?: string;
  persistTrackingParams?: boolean;
};

type PassedProps = {
  className?: string;
  items: BreadcrumbItem[];
};

export default function Breadcrumbs({ items, className }: PassedProps) {
  const divider = '/';
  return (
    <ul
      data-testid="breadcrumbs"
      data-qa-testid="breadcrumbs"
      className={cn('flex gap-0.5 text-sm text-black-055', className)}
    >
      {items.map((item, index) => (
        <Fragment key={item.key || item.content}>
          <li>
            {
              // eslint-disable-next-line no-nested-ternary
              item.link ? (
                item.persistTrackingParams ? (
                  <UtmAwareLink className="text-text-link" to={item.link} onClick={item.onClick}>
                    {item.content}
                  </UtmAwareLink>
                ) : (
                  <Link className="text-text-link" to={item.link} onClick={item.onClick}>
                    {item.content}
                  </Link>
                )
              ) : (
                <span>{item.content}</span>
              )
            }
          </li>
          {index !== items.length - 1 && <li className="text-black-020">{divider}</li>}
        </Fragment>
      ))}
    </ul>
  );
}
