import { range } from 'lodash';
import cn from 'classnames';
import { useBreakpoint } from '@root/shared/useBreakpoints';
import { Breakpoint } from '@root/types';
import s from './GallerySkeleton.module.less';

const GallerySkeleton = () => {
  const BOX_ITEMS_NUMBER_BY_BREAKPOINT: { [k in Breakpoint] } = {
    desktop: 8,
    tablet: 5,
    mobile: 3,
  };

  const { breakpoint } = useBreakpoint();
  const numberOfBoxItems = BOX_ITEMS_NUMBER_BY_BREAKPOINT[breakpoint];

  return (
    <div className={s.mainContainer}>
      <div className={cn(s.box1, s.shimmer)}>
        <div className={cn(s.circle1, s.shimmer)} />
        <div className={cn(s.circle2, s.shimmer)} />
      </div>
      <div className={s.itemsContainer}>
        {range(numberOfBoxItems).map(i => {
          return <div className={cn(s.box2, s.shimmer)} key={i} />;
        })}
      </div>
    </div>
  );
};

export default GallerySkeleton;
