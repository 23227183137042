import {
  PowerpointTourbook,
  PowerpointEntry,
  PowerpointListingEntry,
  PowerpointExternalListingEntry,
} from '@root/types';
import { generateLine, addSlides, getMarkerLabel } from './util';
import { PAGE_MARGIN, PAGE_WIDTH, generateFooterElements } from './portrait';
import { FONT_FACE, PptxUtils, PowerpointElement, PowerpointSlide } from './globals';
import { buildOverviewMapUrl } from './map';
import {
  createAllBuildingRows,
  getBaseTableHeight,
  canFitNextBuilding,
  createBaseRows,
  getBuildingSectionHeight,
  generateTable,
} from './overviewTable';

const mapWidth = 6.86;
const mapHeight = 3.74;

const hasModifiedAskingRent = (listing: PowerpointListingEntry) =>
  listing.minAskingRentInMoneyPerAreaPerPeriod || listing.maxAskingRentInMoneyPerAreaPerPeriod;

const askingRentModified = (tourbook: PowerpointTourbook) =>
  tourbook.entries.some(entry => {
    if (entry.type === 'building') {
      return entry.listings.some(listing => hasModifiedAskingRent(listing));
    }
    return false;
  });

const generateBaseElements = (
  utils: PptxUtils,
  tourbook: PowerpointTourbook,
): PowerpointElement[] => {
  const { t, googleMapsKey, pptx } = utils;
  let elements: PowerpointElement[] = [];

  elements.push(generateLine(utils.pptx, 0.75, PAGE_WIDTH));

  elements.push({
    type: 'textBox',
    data: t('tourbook:powerpoint.listingOverview'),
    textOptions: {
      color: '000000',
      fontSize: 13,
      bold: true,
      fontFace: FONT_FACE,
      lineSpacingMultiple: 0.9,
    },
    placement: { x: PAGE_MARGIN, y: 0.51, w: 6.3 },
  });

  elements.push({
    type: 'image',
    data: buildOverviewMapUrl(tourbook.entries, googleMapsKey, mapHeight, mapWidth),
    placement: { x: PAGE_MARGIN, y: 1.02, w: mapWidth, h: mapHeight },
  });

  // add border
  elements.push({
    type: 'shape',
    placement: { x: PAGE_MARGIN, y: 1.02, w: mapWidth, h: mapHeight },
    shapeOptions: {
      line: { dashType: 'solid', color: 'BFBFBF' },
    },
    shapeType: pptx.ShapeType.rect,
  });

  if (askingRentModified(tourbook)) {
    elements.push({
      type: 'textBox',
      data: t('tourbook:modifiedAskingRentHelpText'),
      textOptions: {
        color: '737373',
        fontSize: 8,
        fontFace: FONT_FACE,
      },
      placement: { x: 4.4, y: 4.9, w: 3 },
    });
  }

  elements = elements.concat(generateFooterElements(tourbook, utils));

  return elements;
};

export const createOverview = (utils: PptxUtils, tourbook: PowerpointTourbook) => {
  const slides: PowerpointSlide[] = generateOverviewSlides(utils, tourbook);

  addSlides(utils.pptx, slides);
};

export const generateOverviewSlides = (
  utils: PptxUtils,
  tourbook: PowerpointTourbook,
): PowerpointSlide[] => {
  const slides: PowerpointSlide[] = [];

  let slide: PowerpointSlide = { elements: generateBaseElements(utils, tourbook) };

  let currentTableHeight = getBaseTableHeight();

  let rows: PptxGenJS.default.TableRow[] = createBaseRows(utils);

  // Y-position of the table changes based on whether the modified asking rent help
  // text is present on the powerpoint.
  const yPosition = askingRentModified(tourbook) ? 5.17 : 4.94;

  tourbook.entries.forEach((tourbookEntry: PowerpointEntry, entryIndex: number) => {
    // this is the transformed thing that we get all info from
    const listings: PowerpointExternalListingEntry[] | PowerpointListingEntry[] =
      tourbookEntry.type === 'externalListing' ? [tourbookEntry] : tourbookEntry.listings;

    if (canFitNextBuilding(listings, currentTableHeight)) {
      // update table height
      currentTableHeight += getBuildingSectionHeight(listings);

      // add rows to table
      createAllBuildingRows(
        tourbookEntry,
        getMarkerLabel(tourbook.entries.length, entryIndex, false),
        rows,
        utils,
      );
    } else {
      // finish previous slide
      slide.elements.push(generateTable(rows, yPosition));
      slides.push(slide);

      // add slide
      slide = { elements: generateBaseElements(utils, tourbook) };

      // reset rows and height
      rows = createBaseRows(utils);
      currentTableHeight = getBaseTableHeight();

      // add rows to table
      currentTableHeight += getBuildingSectionHeight(listings);
      createAllBuildingRows(
        tourbookEntry,
        getMarkerLabel(tourbook.entries.length, entryIndex, false),
        rows,
        utils,
      );
    }
  });

  // finish previous slide
  slide.elements.push(generateTable(rows, yPosition));
  slides.push(slide);

  return slides;
};

export default createOverview;
