import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Metric from '@components/shared/Metric';
import BrochureDownloadLink from '@components/shared/BrochureDownloadLink/BrochureDownloadLink';
import useAnalytics from '@root/shared/useAnalytics';
import type { Brochure, Building } from '@root/types';
import ExpandableDescription from '@components/shared/ExpandableDescription';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import { useBreakpoint } from '@shared/useBreakpoints';
import SafeRender from '@components/shared/SafeRender';
import NotableTenants from '../NotableTenants';
import s from './BasicInfo.module.less';

type Props = {
  building: Building;
};

const Separator = ({
  pipe = false,
  alwaysShow = false,
}: {
  pipe?: boolean;
  alwaysShow?: boolean;
}) => (
  <span
    className={cn(
      'inline-block px-1 py-0',
      pipe && 'w-2.5  text-black-010',
      alwaysShow && 'mobile:inline',
    )}
  >
    {pipe ? '|' : '-'}
  </span>
);

const BasicInfo = ({ building }: Props) => {
  const { t } = useTranslation('building');
  const { brochureInteraction, PARAMETERS } = useAnalytics();
  const {
    numberOfFloors,
    rentableBuildingArea,
    yearRenovated,
    yearBuilt,
    brochures = [],
    address,
    htmlDescription,
  } = building;

  const flags = useFlags();
  const { isDesktop } = useBreakpoint();

  const renderBasicInfoList = () => (
    <div>
      <ul
        className={cn(s.infoContainer, '!mb-2 desktop:!gap-x-12 tablet:!gap-x-4')}
        data-testid="basic-building-info"
      >
        {/* scratch-code to remove w/ industrial-proof-of-concept */}
        {building.isIndustrial ? (
          <li>
            <Metric
              label={t('details.propertyType')}
              value={building.assetTypes.map(assetType => t(`common:${assetType}`)).join(', ')}
            />
          </li>
        ) : null}
        {numberOfFloors ? (
          <li>
            <Metric
              label={t('details.floors')}
              labelClassName="font-body-medium"
              className="font-subtitle-de-emphasis"
              value={numberOfFloors}
            />
          </li>
        ) : null}
        {rentableBuildingArea ? (
          <li>
            <Metric
              label={t('details.rentableBuildingArea')}
              labelClassName="font-body-medium"
              className="font-subtitle-de-emphasis"
              value={t('units:area', { area: rentableBuildingArea })}
            />
          </li>
        ) : null}
        {yearRenovated ? (
          <li>
            <Metric
              labelClassName="font-body-medium"
              className="font-subtitle-de-emphasis"
              value={yearRenovated}
              label={t('details.yearRenovated')}
            />
          </li>
        ) : null}
        {yearBuilt ? (
          <li>
            <Metric
              labelClassName="font-body-medium"
              className="font-subtitle-de-emphasis"
              value={yearBuilt}
              label={t('details.yearBuilt')}
            />
          </li>
        ) : null}
        {/* scratch-code to remove w/ industrial-proof-of-concept */}
        {building.isIndustrial ? (
          <>
            {building.driveInDoors ? (
              <li>
                <Metric label={t('details.driveInDoors')} value={building.driveInDoors} />
              </li>
            ) : null}
            {building.gradeLevelDoors ? (
              <li>
                <Metric label={t('details.gradeLevelDoors')} value={building.gradeLevelDoors} />
              </li>
            ) : null}
            {building.dockHighDoors ? (
              <li>
                <Metric label={t('details.dockHighDoors')} value={building.dockHighDoors} />
              </li>
            ) : null}
            {building.fireSprinklers ? (
              <li>
                <Metric label={t('details.fireSprinklers')} value={building.fireSprinklers} />
              </li>
            ) : null}
            {building.minimumIndoorClearHeight ? (
              <li>
                <Metric
                  label={t('details.minimumIndoorClearHeight')}
                  value={t('units:length', {
                    length: {
                      magnitude: building.minimumIndoorClearHeight.magnitude,
                      unit: building.minimumIndoorClearHeight.units.length,
                    },
                  })}
                />
              </li>
            ) : null}
            {building.percentOffice ? (
              <li>
                <Metric label={t('details.percentOffice')} value={`${building.percentOffice}%`} />
              </li>
            ) : null}
            {building.parkingSpaces ? (
              <li>
                <Metric label={t('details.parkingSpaces')} value={building.parkingSpaces} />
              </li>
            ) : null}
          </>
        ) : null}
      </ul>
    </div>
  );

  const oneBrochureCreator = buildingBrochure => (
    <div
      key={buildingBrochure.url}
      className="pb-1 pr-2 desktop:pb-0 tablet:last:pb-0 mobile:last:pb-0"
    >
      <BrochureDownloadLink
        brochure={buildingBrochure}
        onClick={() =>
          brochureInteraction({
            sourcePage: PARAMETERS.buildingPage,
            otherAttributes: {
              attachmentId: buildingBrochure.id,
              attachmentName: buildingBrochure.name,
            },
          })
        }
        lineWrap={2}
      />
    </div>
  );

  return (
    <div data-testid="basic-info-container" className={s.container}>
      {building.owningCompany || (brochures && brochures.length > 0) ? (
        <>
          <div className={cn('mb-2 desktop:inline-flex desktop:items-start')}>
            {building.owningCompany && (
              <h3 className="flex whitespace-nowrap font-subtitle">
                {building.owningCompany.name}
              </h3>
            )}

            {isDesktop && building.owningCompany && building.brochures?.length > 0 && (
              <figure className="mx-2 mt-0.5 h-[22px] w-[1px] bg-black-035"></figure>
            )}

            {building.brochures?.length > 0 && (
              <div className="pt-1 desktop:mt-0.5 desktop:inline-flex desktop:items-baseline desktop:pt-0">
                {brochures.map((buildingBrochure: Brochure) =>
                  oneBrochureCreator(buildingBrochure),
                )}
              </div>
            )}
          </div>
        </>
      ) : null}
      <hr className="mb-2 text-general-neutral-tertiary" />
      {renderBasicInfoList()}
      {building.description && (
        <div className="font-subtitle">
          {address.buildingName || address.street}
          {address.buildingName ? (
            <div className="inline">
              <Separator />
              {address.street}
            </div>
          ) : null}
        </div>
      )}

      {flags['market.building-descriptions-support-rich-text'] && htmlDescription && (
        <div className={cn(s.descriptionContainer, '!mt-1')}>
          <ExpandableDescription>
            <SafeRender unsafeContent={htmlDescription}>
              <span />
            </SafeRender>
          </ExpandableDescription>
        </div>
      )}

      {!flags['market.building-descriptions-support-rich-text'] && building.description && (
        <div className={cn(s.descriptionContainer, '!mt-1')}>
          <ExpandableDescription>{building.description}</ExpandableDescription>
        </div>
      )}

      {isEmpty(building.notableTenants) ? null : (
        <NotableTenants notableTenants={building.notableTenants} />
      )}
    </div>
  );
};

export default BasicInfo;
