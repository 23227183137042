import { useState } from 'react';
import { CustomIcon } from '@components/shared';
import cn from 'classnames';
import s from './Checkmark.module.less';

const Checkmark = ({ isShown, className }: { isShown: boolean; className?: any }) => (
  <CustomIcon className={cn(s.checkmark, isShown && s.show, className)} type="checkmark" />
);

export default function useSuccessCheckmark() {
  const [isShown, setIsShown] = useState(false);

  const showCheckmark = () => {
    setIsShown(true);
    setTimeout(() => setIsShown(false), 2250);
  };

  return {
    checkmarkProps: { isShown },
    showCheckmark,
    Checkmark,
  };
}
