import { capitalize } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { buildingStatuses } from '@propTypes';
import type { AdminBuilding } from '@root/types';
import Input from '../../Input';
import ChannelsHeader from '../Header';
import ChannelsSelect from '../Select';
import ChannelsButton from '../Button';
import type { BuildingStatuses } from '../types';
import css from '../../styles.module.less';

type WebsiteProps = {
  websiteStatus: string;
  websitePreviewLink: string;
  building: AdminBuilding;
};

const ChannelsWebsite = ({ websitePreviewLink, websiteStatus, building }: WebsiteProps) => {
  const { t } = useTranslation('admin');

  const isWebsiteStatusLiveOrReadyForApproval =
    ['live', 'ready_for_approval'].indexOf(building.websiteStatus) >= 0;

  const showWebsitePreviewLink = isWebsiteStatusLiveOrReadyForApproval && websitePreviewLink;

  return (
    <>
      <ChannelsHeader channel="website" />

      {showWebsitePreviewLink ? (
        <a className={css.buildingURL} href={websitePreviewLink} target="_blank" rel="noreferrer">
          {websitePreviewLink}
        </a>
      ) : null}

      <ChannelsSelect
        containerTestId="building-website-status"
        labelText={t(`building.basicInfo.websiteStatus`)}
        name="websiteStatus"
        options={(buildingStatuses as BuildingStatuses).map(statusOption => ({
          label: capitalize(statusOption.replace(/_/g, ' ')),
          value: statusOption,
        }))}
        value={websiteStatus}
      >
        <ChannelsButton show={Boolean(showWebsitePreviewLink)} link={websitePreviewLink}>
          {t('building.viewBuildingButton.preview')}
        </ChannelsButton>
      </ChannelsSelect>

      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={t('building.basicInfo.liveAt')}
          name="websitePublishedAt"
          disabled
        />
        <Input
          containerClass={css.right}
          labelText={t('building.basicInfo.retiredAt')}
          name="websiteRetiredAt"
          disabled
        />
      </div>
    </>
  );
};

export default ChannelsWebsite;
