import { Brochure } from '@root/types';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import CustomIcon from '../CustomIcon';
import PopperTooltip from '../PopperTooltip';

type BrochureDownloadLinkProps = {
  brochure: Brochure;
  onClick: (brochure) => void;
  lineWrap?: number;
};

const BrochureDownloadLink = ({ brochure, onClick, lineWrap = 1 }: BrochureDownloadLinkProps) => {
  // We overwrite line clamp in the inline styles to be dynamic.
  const lineClampStyle = `line-clamp-2 text-ellipsis`;
  const colorStyles = '!text-indigo-100 hover:!text-indigo-90';

  const linkText = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const element = linkText.current! as HTMLDivElement;
    const overflowed = element
      ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight
      : false;
    setIsOverflown(overflowed);
  }, []);

  const brochuredDownloadLink = (
    <div
      role="link"
      data-href={brochure.url}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        window.open(brochure.url, '_blank');
        onClick(brochure);
      }}
      className={cn('flex', colorStyles)}
    >
      <CustomIcon
        type="brochure-download"
        className={cn('h-full cursor-pointer pr-1 text-[18px]')}
      />
      <div
        ref={linkText}
        style={{
          // Use inline styles to be dynamic based on the prop.
          WebkitLineClamp: lineWrap || 'unset',
        }}
        className={cn(lineClampStyle, 'font-body-medium', colorStyles, 'cursor-pointer')}
        data-qa-testid="brochure"
      >
        {brochure.name}
      </div>
    </div>
  );

  return isOverflown ? (
    <PopperTooltip
      triggerElement={brochuredDownloadLink}
      popperElement={brochure.name}
      trigger="hover"
      toolTipPlacement="top"
      popperElementClassName="font-body-medium !text-white bg-black-100"
    />
  ) : (
    brochuredDownloadLink
  );
};

export default BrochureDownloadLink;
