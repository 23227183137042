import actions from '@store/actions/listingSearchPage';
import { TermValues } from '@root/types';
import CheckboxFilter from '../CheckboxFilter';

type TermOption = {
  value: TermValues;
  label: string;
  translationKey: string;
};

const terms: TermOption[] = [
  {
    value: '<1',
    label: '1 years or less',
    translationKey: 'filters:term:1orLess',
  },
  {
    value: '1-3',
    label: '1-3 years',
    translationKey: 'filters:term:1to3',
  },
  {
    value: '3-5',
    label: '3-5 years',
    translationKey: 'filters:term:3to5',
  },
  {
    value: '5-10',
    label: '5-10 years',
    translationKey: 'filters:term:5to10',
  },
  {
    value: '10-15',
    label: '10-15 years',
    translationKey: 'filters:term:10to15',
  },
];

// Term has to overwrite the sortFunction because it has numbers in front.
const sortFunction = () => (_a, _b) => 0;

const TermsFilter = () => {
  return (
    <CheckboxFilter
      filters={terms}
      filterType="terms"
      filterDispatchAction={actions.termsFilterChanged}
      sortFunction={sortFunction}
    />
  );
};

export default TermsFilter;
