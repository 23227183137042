import routes from '@root/routes';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { get } from '@root/shared/typedApi';
import type { Listing, ListingResponse } from '@root/types';

export type ReceiveListingAction = { type: 'RECEIVE_FETCH_LISTING'; payload: ListingResponse };
const receiveListing = (listingResponse: ListingResponse): ReceiveListingAction => ({
  type: 'RECEIVE_FETCH_LISTING',
  payload: listingResponse,
});

const useFetchListing = (): ((
  listingId: string,
  onSuccess?: (listing: Listing) => void,
  onError?: () => void,
) => Promise<void>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const fetchListing = async (
    listingId: string,
    onSuccess: (listing: Listing) => void = () => {},
    onError: () => void = () => {},
  ) => {
    try {
      const listingResponseData: ListingResponse = await queryClient.fetchQuery({
        queryKey: [routes.api.listing(listingId)],
        queryFn: async () => get<ListingResponse>(routes.api.listing(listingId)),
      });

      if (listingResponseData) {
        dispatch(receiveListing(listingResponseData));
        onSuccess(listingResponseData.listing);
      }
    } catch {
      onError();
    }
  };

  return fetchListing;
};

export default useFetchListing;
