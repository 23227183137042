import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import typographyStyles from '@root/styles/typography.module.less';
import Spinner from '@components/shared/Spinner';
import { buildingClicked } from '@store/actions/landlordPage/analyticsActions';
import useQueryParams from '@root/shared/useQueryParams';
import routes from '@root/routes';
import { useQuery } from '@tanstack/react-query';
import { LandlordPortfolio } from '@root/types';
import { get } from '@shared/typedApi';
import s from './Portfolio.module.less';
import SelectedRegion from './SelectedRegion';
import Buildings from './Buildings';
import RegionLinks from './RegionLinks';

type Props = {
  marketSlugs: string[];
  landlordSlug: string;
  landlordId: string;
  isPreview: boolean;
};

const Portfolio = ({ marketSlugs, landlordSlug, landlordId, isPreview = false }: Props) => {
  const { t } = useTranslation('landlord');
  const dispatch = useDispatch();

  const { market } = useQueryParams();
  const selectedMarket: string = (market as string | undefined) || marketSlugs[0];
  const { data, isLoading } = useQuery<LandlordPortfolio>(
    [routes.api.landlordPortfolio({ landlordSlug, regionSlug: selectedMarket })],
    async () => get(routes.api.landlordPortfolio({ landlordSlug, regionSlug: selectedMarket })),
    {
      enabled: !!selectedMarket,
    },
  );

  const trackBuildingClick = building => {
    dispatch(buildingClicked(building));
  };

  if (marketSlugs.length < 1) return null;

  return (
    <section className={s.container}>
      <div className={s.header}>
        <h2 className={cn(typographyStyles.headline, s.landlordTitle)}>{t('ourPortfolio')}</h2>
        <RegionLinks
          marketSlugs={marketSlugs}
          selectedRegionSlug={selectedMarket as string}
          landlordSlug={landlordSlug}
          landlordId={landlordId}
          isPreview={isPreview}
        />
      </div>
      <div className={s.line} />
      {isLoading ? (
        <div className={s.spinnerContainer}>
          <Spinner size="large" />
        </div>
      ) : (
        <>
          <SelectedRegion
            hideRegionName={selectedMarket === 'other' && marketSlugs.length === 1}
            selectedRegionSlug={selectedMarket}
            portfolio={data!}
            buildingCount={data!.buildings.length}
          />
          <Buildings buildings={data!.buildings} onBuildingClick={trackBuildingClick} />
        </>
      )}
    </section>
  );
};

export default Portfolio;
