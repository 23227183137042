import { useTranslation } from 'react-i18next';
import Spinner from '@components/shared/Spinner';
import { createPortal } from 'react-dom';
import s from './DuplicateTourbookLoadingState.module.less';

const DuplicateTourbookLoadingState = () => {
  const { t } = useTranslation('tourbook');
  return (
    <>
      {createPortal(
        <>
          <div className={s.duplicateModal}>
            <div className={s.spinnerWrapper}>
              <Spinner size="xlarge" />
            </div>
            {t('header.duplicateLoading')}
          </div>
          <div className={s.containerEffect}></div>
        </>,
        document.body,
      )}
    </>
  );
};

export default DuplicateTourbookLoadingState;
