import { useDispatch } from 'react-redux';
import rootActions from '@store/actions';
import { useState } from 'react';
import useAnalytics from '@root/shared/useAnalytics';
import { PARAMETERS } from '@root/tracking/constants';
import { Landlord } from '@root/types';

const usePageLoadAnalyticsEvent = () => {
  const dispatch = useDispatch();
  const { pageLoaded } = useAnalytics();
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  const onPageLoad = (fetchedLandlord: Landlord, isPreview: boolean) => {
    dispatch(rootActions.setLandlordPage(fetchedLandlord.analyticsInformation, isPreview));
    if (!hasLoadedOnce) {
      pageLoaded({
        pageName: PARAMETERS.landlordPage,
        actionType: 'PAGE_LOADED:LANDLORD',
      });
      setHasLoadedOnce(true);
    }
  };

  return { onPageLoad, hasLoadedOnce };
};

export default usePageLoadAnalyticsEvent;
