import i18next from 'i18next';
import { compact } from 'lodash';
import { format, parse } from 'date-fns';
import { SearchCriteria } from './Criteria/ListingSearchCriteria';
import { FilterPillOptions } from '../SearchCriteriaBar/FilterPill/FilterPill';
import dateRegex, { amenityOptions, currencySymbol } from './constants';
import {
  FilterOptionsState,
  LandLordsType,
  SubmarketsType,
  BuildingsType,
} from './FilterOptions/FilterOptionsReducer/FilterOptionsReducer';
import { convertMagnitudeToUnits } from '../Filters/CeilingHeightFilter/ImperialCeilingHeightInput';

const filterPillMap = {
  raw: 'filters:spaceCondition:raw',
  'pre-built': 'filters:spaceCondition:preBuilt', // start Space Condition filters
  '2nd generation': 'filters:spaceCondition:secondGeneration',
  'pre-existing': 'filters:spaceCondition:preExisting',
  turnkey: 'filters:spaceCondition:turnkey',
  'white box': 'filters:spaceCondition:whiteBox',
  'core and shell': 'filters:spaceCondition:coreAndShell',
  challenged: 'filters:spaceCondition:challenged',
  'under construction': 'filters:spaceCondition:underConstruction',
  '<1': 'filters:term:1orLess',
  '1-3': 'filters:term:1to3',
  '3-5': 'filters:term:3to5',
  '5-10': 'filters:term:5to10',
  '10-15': 'filters:term:10to15',
  now: 'filters:possession:availableNow',
  threeMonths: 'filters:possession:threeMonths',
  sixMonths: 'filters:possession:sixMonths',
  oneYear: 'filters:possession:oneYear',
  slabToSlab: 'filters:ceilingHeight.slabToSlab',
  finished: 'filters:ceilingHeight.finished',
  direct: 'filters:leaseType.direct',
  flex: 'filters:leaseType.flex',
  sublease: 'filters:leaseType.sublease',
  ...amenityOptions.reduce((obj, amenity) => {
    return { ...obj, [amenity.value]: amenity.label };
  }, {}),
};

const formatFilter = (
  label: string,
  filterKey: string,
  filter: string,
  value: string | undefined = undefined,
): FilterPillOptions => {
  return {
    label,
    filterKey,
    filter,
    value,
  };
};

const sortByPillMapLocale = (filterA, filterB) =>
  i18next.t(filterPillMap[filterA]).localeCompare(i18next.t(filterPillMap[filterB]));

// This format is used by the onClick delete for the pills to find what filter to remove.
// Ex: 'spaceCondition|pre-existing'
const filterKeyWithValue = (key: string, value: string) => {
  return `${key}|${value}`;
};

const multiSelectFilter = (multiSelectValues: Array<string>, filterKey: string, filter: string) => {
  return (
    multiSelectValues
      ?.sort((a, b) => sortByPillMapLocale(a, b))
      ?.map(filterValue => {
        const labelText = i18next.t(filterPillMap[filterValue]);
        return (
          labelText &&
          formatFilter(labelText, filterKeyWithValue(filterKey, filterValue), filter, filterValue)
        );
      }) || []
  );
};

// This format is used by the onClick delete for the pills to find what filter to remove.
// Ex: 'possession' or if multiple keys to remove, comma separated -> 'maxsize,minsize'.
const filterKey = (filters: Array<string> | string) => {
  return Array.isArray(filters) ? filters.join(',') : filters;
};

const getCustomMapShapeFilter = (currentFilters: SearchCriteria) => {
  const { map } = currentFilters;
  let mapShapeFilter: FilterPillOptions | null = null;
  if (map) {
    mapShapeFilter = formatFilter(
      i18next.t('filters:customMapShapeFilter'),
      filterKey('map'),
      'custom map area',
    );
  }
  return mapShapeFilter;
};

const getCustomDrawnShapeFilter = (currentFilters: SearchCriteria) => {
  const { polygons } = currentFilters;
  let drawnShapeFIlter: FilterPillOptions | null = null;
  if (polygons) {
    drawnShapeFIlter = formatFilter(
      i18next.t('filters:customDrawnShapeFilter'),
      filterKey('polygons'),
      'custom drawn shape',
    );
  }
  return drawnShapeFIlter;
};

const getSizeFilter = (currentFilters: SearchCriteria) => {
  const { maxSize, minSize } = currentFilters;
  let sizeFilter: FilterPillOptions | null = null;
  let labelText;
  if (minSize != null && maxSize == null) {
    labelText = i18next.t('filters:size:titleWithMinOnly', { minSize });
  } else if (minSize == null && maxSize != null) {
    labelText = i18next.t('filters:size:titleWithMaxOnly', { maxSize });
  } else if (minSize != null && maxSize != null) {
    labelText = i18next.t('filters:size:titleWithRange', { minSize, maxSize });
  }
  sizeFilter = formatFilter(labelText, filterKey(['maxSize', 'minSize']), 'size');

  return labelText && sizeFilter;
};

const getExclusiveFilter = (currentFilters: SearchCriteria) => {
  const { exclusive } = currentFilters;
  let exclusiveFilter: FilterPillOptions | null = null;
  if (exclusive) {
    exclusiveFilter = formatFilter(
      i18next.t('filters:exclusive'),
      filterKey('exclusive'),
      'first to market',
    );
  }
  return exclusiveFilter;
};

const getCeilingHeightFilters = (currentFilters: SearchCriteria) => {
  const { ceilingHeightType, ceilingHeightMagnitude, inMeters } = currentFilters;
  let ceilingHeightFilter: FilterPillOptions | null = null;
  if (ceilingHeightMagnitude && ceilingHeightType) {
    const ceilingHeightTypeLabel = i18next.t(`filters:ceilingHeight:type:${ceilingHeightType}`);
    let labelText;
    if (inMeters) {
      labelText = i18next.t(`filters:ceilingHeight:minHeightMt`, {
        meters: ceilingHeightMagnitude,
      });
    } else {
      const { feet, inches } = convertMagnitudeToUnits(ceilingHeightMagnitude);
      const hasFeet = !!feet;
      const hasInches = !!inches;
      const units = { ft: feet, in: inches };
      if (hasFeet && hasInches) {
        labelText = i18next.t(`filters:ceilingHeight:minHeightFtIn`, units);
      } else if (hasFeet && !hasInches) {
        labelText = i18next.t(`filters:ceilingHeight:minHeightFt`, units);
      } else if (!hasFeet && hasInches) {
        labelText = i18next.t(`filters:ceilingHeight:minHeightIn`, units);
      }
    }
    ceilingHeightFilter = formatFilter(
      `${labelText} ${ceilingHeightTypeLabel}`,
      filterKey(['ceilingHeightType', 'ceilingHeightMagnitude']),
      'ceiling height',
    );
  }
  return ceilingHeightFilter;
};

const getPossessionFilter = (currentFilters: SearchCriteria) => {
  const { possession } = currentFilters;
  let possessionFilter: FilterPillOptions | null = null;
  if (possession) {
    let labelText;

    if (possession?.match(dateRegex)) {
      const customDateObject = new Date(parse(possession, 'dd/MM/yyyy', new Date()));
      labelText = i18next.t('filters:possession:available', {
        date: format(customDateObject, 'yyyy-MM-dd'),
      });
    } else labelText = i18next.t(filterPillMap[possession]);
    possessionFilter = labelText && formatFilter(labelText, filterKey('possession'), 'possession');
  }
  return possessionFilter;
};

const getTermFilter = (criteriaFilters: SearchCriteria) => {
  const { terms } = criteriaFilters;
  return (
    // Not sorting this because the number's infront of the text, mess up sort order.
    terms?.map(term => {
      const labelText = i18next.t(filterPillMap[term]);
      return labelText && formatFilter(labelText, filterKeyWithValue('terms', term), 'terms', term);
    }) || []
  );
};

const getSpaceConditionFilter = (criteriaFilters: SearchCriteria) => {
  const { spaceCondition } = criteriaFilters;
  return multiSelectFilter(spaceCondition as string[], 'spaceCondition', 'space condition');
};

const getLeaseTypeFilters = (criteriaFilters: SearchCriteria) => {
  const { leaseTypes } = criteriaFilters;

  return multiSelectFilter(leaseTypes as string[], 'leaseTypes', 'lease type');
};

const getAmenitiesFilter = (criteriaFilters: SearchCriteria) => {
  const { amenities } = criteriaFilters;
  return multiSelectFilter(amenities as string[], 'amenities', 'amenities');
};

const getPriceFilter = (criteriaFilters: SearchCriteria, metadata) => {
  const { maxPrice, minPrice } = criteriaFilters;
  const { currentMarket, currentUser, locale } = metadata;
  const currency = currencySymbol(currentMarket, currentUser, locale);
  let priceFilter: FilterPillOptions | null = null;
  let labelText;
  if (minPrice != null && maxPrice == null) {
    labelText = i18next.t('filters:price:titleWithCurrencyAndMinOnly', {
      currency,
      minPrice,
    });
  } else if (maxPrice != null) {
    labelText = i18next.t('filters:price:titleWithCurrencyAndRange', {
      currency,
      minPrice,
      maxPrice,
    });
  }
  priceFilter = formatFilter(labelText, filterKey(['maxPrice', 'minPrice']), 'price');
  return labelText && priceFilter;
};

const getExcludeNegotiableFilter = (currentFilters: SearchCriteria) => {
  const { excludeNegotiable } = currentFilters;
  let excludeNegotiableFilter: FilterPillOptions | null = null;
  if (excludeNegotiable) {
    excludeNegotiableFilter = formatFilter(
      i18next.t('filters:price.excludeNegotiable'),
      filterKey('excludeNegotiable'),
      'price',
    );
  }
  return excludeNegotiableFilter;
};

const getLandlordsFilter = (currentFilters: SearchCriteria, landlordsState: LandLordsType) => {
  const { landlords } = currentFilters;
  return (
    landlords
      ?.map(filterValue => {
        return formatFilter(
          landlordsState[filterValue]?.name,
          filterKeyWithValue('landlords', filterValue),
          'landlord',
          filterValue,
        );
      })
      .sort((a, b) => (a.label || '').localeCompare(b.label)) || []
  );
};

const getSubmarketFilter = (currentFilters: SearchCriteria, submarketsState: SubmarketsType) => {
  const { submarkets } = currentFilters;

  return (
    submarkets
      ?.map(filterValue => {
        return formatFilter(
          submarketsState[filterValue]?.name,
          filterKeyWithValue('submarkets', filterValue),
          'submarket',
          submarketsState[filterValue]?.slug,
        );
      })
      .sort((a, b) => (a.label || '').localeCompare(b.label)) || []
  );
};

const getBuildingFilter = ({ buildingIds }: SearchCriteria, buildingsState: BuildingsType) =>
  buildingIds
    ?.map(buildingId =>
      formatFilter(
        buildingsState[buildingId]?.displayName,
        filterKeyWithValue('buildingIds', buildingId),
        'building',
        buildingId,
      ),
    )
    .sort((a, b) => (a.label || '').localeCompare(b.label)) || [];

const getKeywordsFilter = ({ keywords }: SearchCriteria) =>
  keywords
    ?.sort()
    .map(keyword =>
      formatFilter(
        `Keyword: ${keyword}`,
        filterKeyWithValue('keywords', keyword),
        'keyword',
        keyword,
      ),
    ) || [];

const getSearchCriteriaPills = (
  currentFilters: SearchCriteria,
  filterOptionsState: FilterOptionsState,
  metadata: any,
): FilterPillOptions[] => {
  return compact([
    getCustomMapShapeFilter(currentFilters),
    getCustomDrawnShapeFilter(currentFilters),
    getExclusiveFilter(currentFilters),
    getSizeFilter(currentFilters),
    getPriceFilter(currentFilters, metadata),
    getExcludeNegotiableFilter(currentFilters),
    ...getSubmarketFilter(currentFilters, filterOptionsState.submarkets),
    ...getBuildingFilter(currentFilters, filterOptionsState.buildings),
    ...getLandlordsFilter(currentFilters, filterOptionsState.landlords),
    ...getLeaseTypeFilters(currentFilters),
    ...getTermFilter(currentFilters),
    getPossessionFilter(currentFilters),
    ...getSpaceConditionFilter(currentFilters),
    getCeilingHeightFilters(currentFilters),
    ...getAmenitiesFilter(currentFilters),
    ...getKeywordsFilter(currentFilters),
  ]);
};

export default getSearchCriteriaPills;
