export const COLORS = {
  indigo90: '#8C82FF',
  indigo: '#5528ff',
  indigo110: '#280f96',
  indigo120: '#140532',
  egg50: '#ffd87f',
  egg100: '#ffb200',
  black35: '#a6a6a6',
  white: '#ffffff',
  black20: '#CCCCCC',
  black55: '#737373',
  black100: '#000000',
};

export const COLORS_WITHOUT_HASHTAG = {
  indigo90: '8C82FF',
  indigo: '5528ff',
  indigo110: '280f96',
};

export default COLORS;
