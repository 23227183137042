import { Row as AntdRow } from 'antd';
import { ReactNode } from 'react';
import cn from 'classnames';
import s from './antdOverrides.module.less';

type Props = {
  gutter?: number;
  block?: boolean;
  children?: ReactNode;
  className?: string;
};
const Row = ({ block = false, gutter = 0, children = null, className }: Props) => (
  <AntdRow gutter={gutter} className={cn(block && s.rowBlock, className)}>
    {children}
  </AntdRow>
);

export default Row;
