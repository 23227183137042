import { parse, format, parseISO } from 'date-fns';

export const reformatDateString = ({
  value,
  beforeFormat,
  afterFormat,
}: {
  value: string;
  beforeFormat: string;
  afterFormat: string;
}): string => {
  const asDate = parse(value, beforeFormat, new Date());
  return format(asDate, afterFormat);
};

export const reformatISODateString = ({
  value,
  afterFormat,
}: {
  value: string;
  afterFormat: string;
}): string => {
  const asDate = parseISO(value);
  return format(asDate, afterFormat);
};
