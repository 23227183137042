import redirectToLoginIfUnauthorized from '@shared/redirectToLoginIfUnauthorized';
import api from './api';

export class ResponseError extends Error {
  body?: any;

  constructor({ message, body }: { message?: string | null; body?: any }) {
    if (message) super(message);
    else if (body) super(JSON.stringify(body));
    else super('Something went wrong');

    this.body = body;
    this.name = 'ApiResponseError';
  }
}

const getCsrfToken = () => {
  const tokenElement = document.head.querySelector('[name="csrf-token"]');

  // @ts-expect-error
  return tokenElement && tokenElement.content;
};

export const csrfTokenHeader = () => ({
  'X-CSRF-TOKEN': getCsrfToken(),
});

const defaultHeaders = (useJson = true) => ({
  ...csrfTokenHeader(),
  ...(useJson ? { 'Content-Type': 'application/json' } : {}),
});

const getUseJson = options =>
  Object.prototype.hasOwnProperty.call(options, 'useJson') ? options.useJson : true;

async function handleResponse(response: Response) {
  if (process.env.NODE_ENV === 'test') {
    if (response.ok) {
      return response.json();
    }
    throw new ResponseError({ body: await response.json() });
  }

  const isJson = response.headers.get('Content-Type')?.includes('application/json');

  if (response.ok) {
    const body = isJson ? await response.json() : null;
    return body;
  }

  throw new ResponseError({ body: isJson ? await response.json() : null });
}

export const get = async <T = any>(url: string, options: Object = {}): Promise<T> => {
  const response = await api.fetch(url, options);
  const json = await handleResponse(response);
  return json;
};

type MutationOptions = { useJson?: boolean; headers: Object };
export const create = async <T>(
  url: string,
  body: Object,
  options: MutationOptions = { useJson: true, headers: {} },
): Promise<T> => {
  const useJson = getUseJson(options);
  const requestBody = useJson ? JSON.stringify(body) : body;
  const requestOptions = {
    // Setting useJson false to avoid double-JSONing so we can replace
    // @shared/api eventually.
    useJson: false,
    headers: { ...defaultHeaders(useJson), ...options.headers },
  };

  const response = await api.post(url, requestBody, requestOptions);

  redirectToLoginIfUnauthorized(response);
  const json = await handleResponse(response);
  return json;
};

export const update = async <T = any>(
  url: string,
  body: Object,
  options: MutationOptions = { useJson: true, headers: {} },
): Promise<T> => {
  const useJson = getUseJson(options);
  const requestBody = useJson ? JSON.stringify(body) : body;
  const requestOptions = {
    // Setting useJson false to avoid double-JSONing so we can replace
    // @shared/api eventually
    useJson: false,
    headers: { ...defaultHeaders(useJson), ...options.headers },
  };

  const response = await api.put(url, requestBody, requestOptions);

  return handleResponse(response);
};

export const destroy = async <T>(
  url: string,
  body: Object,
  options: { headers: Object } = { headers: {} },
): Promise<T> => {
  // When removing @shared/api, we will then need to JSON-ify this body.
  const response = await api.delete(url, body, {
    headers: { ...defaultHeaders(), ...options.headers },
  });

  redirectToLoginIfUnauthorized(response);
  return handleResponse(response);
};
