import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '@root/types';
import savedActions from '@store/actions/saved';
import { getSavedSearches } from '@store/selectors';
import { CustomIcon } from '@components/shared';
import { isEmpty } from 'lodash';
import useOnChangeSavedSearch from '@components/layouts/Truva/ListingSearch/useOnChangeSavedSearch';
import SaveSearchButton from './SaveSearchButton';
import SaveSearchSelector from '../SaveSearchSelector';
import s from './NamedSaveSearch.module.less';
import { useListingSearchCriteria, ListingSearchCriteria } from '../utils';

const mapState = (state: StoreState) => ({
  savedSearches: getSavedSearches(state),
  activeSavedSearchId: state.savedSearches.activeId,
});
const mapDispatch = {
  fetchSavedSearches: () => savedActions.fetchSavedSearches(),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

type Props = {
  isSaveSearchDisabled: boolean;
};

function NamedSavedSearch({
  fetchSavedSearches,
  savedSearches,
  isSaveSearchDisabled,
  activeSavedSearchId,
}: Props & ReduxProps) {
  const criteria = useListingSearchCriteria();
  useEffect(() => {
    fetchSavedSearches();
  }, [fetchSavedSearches]);
  const onChange = useOnChangeSavedSearch();

  const selectedSavedSearch = savedSearches.find(ss => ss.id === activeSavedSearchId) || null;

  const canUpdate =
    !!selectedSavedSearch &&
    !criteria.isEqualTo(ListingSearchCriteria.fromSavedSearch(selectedSavedSearch));

  return (
    <section data-testid="savedSearchSection" className={s.container}>
      {!isEmpty(savedSearches) && (
        <>
          <CustomIcon type="magnifying-glass-heart" className={s.magnifyingGlassHeart} />
          <SaveSearchSelector
            name="SavedSearchSelector"
            savedSearches={savedSearches}
            value={activeSavedSearchId}
            currentSelectedSearch={selectedSavedSearch}
            onChange={onChange}
          />
        </>
      )}
      <SaveSearchButton
        criteria={criteria}
        canUpdate={canUpdate}
        currentSelectedSearch={selectedSavedSearch}
        onChange={onChange}
        fetchSavedSearches={fetchSavedSearches}
        disabled={isSaveSearchDisabled}
      />
    </section>
  );
}

export default connector(NamedSavedSearch);
