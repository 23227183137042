import Card from '@components/shared/Card';
import { useState } from 'react';
import { CustomIcon } from '@components/shared';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import s from './InquiryForm.module.less';

type Props = {
  handleSendAnotherInquiry: () => void;
  isOnPreview?: boolean;
};

function SuccessCard({ handleSendAnotherInquiry, isOnPreview = false }: Props) {
  const { t } = useTranslation('common');

  const contentClassName = isOnPreview ? s.cardContent : s.largeCardContent;
  const cardClassName = isOnPreview ? s.card : s.largeCard;

  return (
    <Card
      title={t('inquiry.title')}
      className={cardClassName}
      contentClassName={cn(contentClassName, !isOnPreview && s.successCardContent)}
      headerContainerClassName={cn(!isOnPreview && s.headerContainer)}
      headerElement={<></>} // Empty fragment to justify title to the left
    >
      <div className={s.successBody}>
        <CustomIcon type="checkmark" className={s.successCheck} />
        <h2>{t('inquiry.success.header')}</h2>
        <button type="button" className={s.successBodyMessage} onClick={handleSendAnotherInquiry}>
          {t('inquiry.success.message')}
        </button>
      </div>
    </Card>
  );
}

export default function useSuccessCard({ isOnPreview = false }: { isOnPreview?: boolean }) {
  const [hasSuccessfullySentInquiry, setHasSuccessfullySentInquiry] = useState(false);
  const handleSendAnotherInquiry = () => {
    setHasSuccessfullySentInquiry(false);
  };

  return {
    SuccessCard,
    successCardProps: { isOnPreview, handleSendAnotherInquiry },
    shouldShowSuccessCard: hasSuccessfullySentInquiry,
    showSuccessCard: () => setHasSuccessfullySentInquiry(true),
  };
}
