import { memo } from 'react';
import { AdvancedImage, responsive, lazyload, placeholder } from '@cloudinary/react';
import { compact } from 'lodash';
import { Actions } from '@cloudinary/url-gen';

import useCloudinary from '@shared/useCloudinary';

type Props = {
  className?: string;
  cloudinaryId: string;
  altText: string;
  containerClassName?: string;
  isResponsive?: boolean;
  format?: string;
  skipInvisibleWatermark?: boolean;
  useLazyloading?: boolean;
  usePlaceholder?: boolean;
  noContainer?: boolean;
};

const invisibleWatermark = Actions.NamedTransformation.name('invisible_watermark');
const CloudinaryImage = ({
  cloudinaryId,
  altText,
  containerClassName,
  className,
  isResponsive = true,
  format = 'auto',
  skipInvisibleWatermark = false,
  useLazyloading = false,
  usePlaceholder = false,
  noContainer = false,
}: Props) => {
  const cld = useCloudinary();
  let image = cld.image(cloudinaryId).format(format);

  if (!skipInvisibleWatermark) {
    image = image.namedTransformation(invisibleWatermark);
  }

  const plugins = compact([
    useLazyloading ? lazyload() : null,
    isResponsive ? responsive({ steps: [200, 400, 800, 1200, 1500, 2000, 2250, 2500] }) : null,
    usePlaceholder ? placeholder() : null,
  ]);

  return noContainer ? (
    <AdvancedImage cldImg={image} className={className} plugins={plugins} alt={altText} />
  ) : (
    <figure className={containerClassName}>
      <AdvancedImage cldImg={image} className={className} plugins={plugins} alt={altText} />
    </figure>
  );
};

export default memo(CloudinaryImage);
