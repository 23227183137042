import { useEffect, useMemo, useState } from 'react';
import { eachDayOfInterval, format, startOfToday, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { get } from '@shared/typedApi';
import { compact, isEqual } from 'lodash';
import cn from 'classnames';
import routes from '@root/routes';
import MultiSelectCheckboxDropdown from '@components/shared/MultiSelectCheckboxDropdown/MultiSelectCheckboxDropdown';
import DateRangeDropdown from '@components/shared/DateRangeDropdown/DateRangeDropdown';
import BarChart from '@components/shared/BarChart/BarChart';
import xAxisCrosshairPlugin, {
  CrossHairPosition,
} from '@components/shared/BarChart/xAxisCrosshairPlugin';
import { useBreakpoint } from '@shared/useBreakpoints';
import { TourbookAnalyticsOverTimeResponse, ListingEngagementOverTime } from './utils';
import ListingDataList, { ListingEngagementMetadata } from './ListingDataList';
import ChartLegend from './ChartLegend';
import BarChartEmptyState from './BarChartEmptyState.svg';

type MEDIA_FILTER = 'MEDIA';
type LISTING_ACTIVTIES_FILTER = 'LISTING';
type DOWNLOADS_FILTER = 'DOWNLOAD';

export type TourbookAnalyticsFilterOption =
  | MEDIA_FILTER
  | LISTING_ACTIVTIES_FILTER
  | DOWNLOADS_FILTER;

export type FilterOption = {
  value: TourbookAnalyticsFilterOption;
  label: 'Listing activity' | 'Media views' | 'Downloads';
};

type BarChartToolTipElement = {
  title: String;
  top: number;
  left: number;
  listingsData: ListingEngagementMetadata[];
  visible: boolean;
};

const filterOptions = [
  { value: 'LISTING', label: 'Listing activity' } as FilterOption,
  { value: 'MEDIA', label: 'Media views' } as FilterOption,
  { value: 'DOWNLOAD', label: 'Downloads' } as FilterOption,
];

type Props = {
  colorByListingId: { [listingId: string]: string };
  showEmptyState?: boolean;
  relatedListingIds: string[];
};

const ListingEngagementOverTimeChart = ({
  colorByListingId,
  showEmptyState,
  relatedListingIds,
}: Props) => {
  const { tourbookId } = useParams<{ tourbookId: string }>();
  const { t } = useTranslation('tourbook');
  const { isMobile } = useBreakpoint();

  const [listingEngagementOverTime, setListingEngagementOverTime] = useState<
    ListingEngagementOverTime[]
  >([]);

  const today = startOfToday();
  const [startDate, setStartDate] = useState<Date>(subDays(today, 13));
  const [endDate, setEndDate] = useState<Date>(today);

  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>(filterOptions);
  const filterBy = selectedFilters.map(option => option.value);
  const chartFilterOptions = {
    filterBy,
    startDate: format(startDate, 'yyyy-MM-dd'),
    endDate: format(endDate, 'yyyy-MM-dd'),
  };

  const [tooltip, setTooltip] = useState<BarChartToolTipElement | null>(null);
  const [mouseOnTooltip, setMouseOnTooltip] = useState<boolean>(false);
  const [crossHairPosition, setCrossHairPosition] = useState<CrossHairPosition>();

  const fetchAnalyticsOverTimeRoute = routes.api.tourbookAnalyticsOverTime({
    tourbookId,
    ...chartFilterOptions,
  });

  const { isLoading } = useQuery(
    [fetchAnalyticsOverTimeRoute],
    () => get<TourbookAnalyticsOverTimeResponse>(fetchAnalyticsOverTimeRoute),
    {
      cacheTime: 0,
      onSuccess: data => {
        setListingEngagementOverTime(data.listingEngagementOverTime);
      },
    },
  );

  const handleUpdateFilters = newFilters => {
    setListingEngagementOverTime([]);
    setSelectedFilters(newFilters);
  };

  const datasets = useMemo(
    () =>
      listingEngagementOverTime.map((listing: ListingEngagementOverTime) => {
        const engagementData = listing.engagementOverTime.map(listingEngagement => {
          // Have to parse dates this way to avoid issues with timezone shifting the string date.
          const dateParts = listingEngagement.date.split('-').map(part => parseInt(part, 10));
          const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

          return {
            x: listingEngagement.count,
            y: format(date, 'MMM d'),
            year: format(date, 'yyyy'),
            listingId: listing.listingId,
          };
        });

        return {
          label: compact([
            listing.address.buildingName,
            listing.address.street,
            listing.address.floorAndSuite,
          ]).join(' | '),
          data: engagementData,
          maxBarThickness: 30,
          backgroundColor: colorByListingId[listing.listingId],
          totalEngagement: listing.totalEngagement,
          listingId: listing.listingId,
        };
      }),
    [colorByListingId, listingEngagementOverTime],
  );

  const listingMetadata: ListingEngagementMetadata[] = useMemo(
    () =>
      datasets
        .sort(
          (a, b) =>
            // Sort by total engagment then by alphabetically by label name.
            b.totalEngagement - a.totalEngagement || new Intl.Collator().compare(a.label, b.label),
        )
        .map(listing => {
          return {
            id: listing.listingId,
            value: listing.totalEngagement,
            color: listing.backgroundColor,
            label: listing.label,
            displayValue: listing.totalEngagement.toString(),
          };
        }),
    [datasets],
  );

  const hasBarChartData = listingMetadata.some(listing => listing.value > 0);

  useEffect(() => {
    const barChartTooltip = document.getElementById('barChartTooltip');
    barChartTooltip?.addEventListener('mouseenter', () => {
      setMouseOnTooltip(true);
    });
    barChartTooltip?.addEventListener('mouseleave', () => {
      setTooltip(null);
      setMouseOnTooltip(false);
    });
  }, []);

  return (
    <div data-testid="listingEngagementOverTimeChart">
      <div className="border-b-[1px] border-solid border-black-010">
        <div className="flex items-center justify-between px-2 py-1.5 mobile:flex-col mobile:items-baseline">
          <h2 className="font-subtitle-de-emphasis">{t('analytics.listingEngagementOverTime')}</h2>
          {!showEmptyState && (
            <div className="flex gap-3 mobile:mt-1.5">
              <MultiSelectCheckboxDropdown
                controlText={t('analytics.filter')}
                options={filterOptions}
                selectedOptions={selectedFilters}
                onSelect={handleUpdateFilters}
              />
              <DateRangeDropdown
                maxSelectableDays={13}
                startDate={startDate}
                endDate={endDate}
                onChange={([start, end]) => {
                  setListingEngagementOverTime([]);
                  setStartDate(start);
                  setEndDate(end);
                }}
                calendarPlacement={isMobile ? 'bottom-start' : 'bottom-end'}
              />
            </div>
          )}
        </div>
      </div>
      {!showEmptyState ? (
        <>
          <div className="grid h-full grid-cols-[minmax(0,1fr)_275px] gap-2 px-2 py-[12px] mobile:grid-cols-1">
            <div className="relative flex flex-col items-center gap-0.5 tablet:flex-col tablet:gap-1">
              <div className="w-full">
                <BarChart
                  height={352}
                  labels={eachDayOfInterval({ start: startDate, end: endDate }).map(date =>
                    format(date, 'MMM d'),
                  )}
                  datasets={datasets}
                  plugins={[xAxisCrosshairPlugin]}
                  options={{
                    // Turns the graph horizontal.
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                      x: {
                        stacked: true,
                        // Suggested max number on x-axis. Will go higher if data is higher
                        // Otherwise it will have the max as 25 in all data is under it
                        suggestedMax: 25,
                      },
                      y: {
                        stacked: true,
                        grid: {
                          // Hide the y-axis lines
                          display: false,
                        },
                        ticks: {
                          // Show all the date markers on the y-axis
                          autoSkip: false,
                        },
                      },
                    },
                    interaction: {
                      // Lets the interaction data (which is also used in the tooltip), include data
                      // all items that would intersect based on the Y coordinate of the position.
                      mode: 'y',
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      xAxisCrosshair: {
                        stayOn: tooltip?.visible,
                        crossHairPosition,
                        setCrossHairPosition,
                      },
                      tooltip: {
                        enabled: false,
                        external: context => {
                          const tooltipModel = context.tooltip;

                          const mouseIsOffBar = tooltipModel.opacity === 0;

                          if (mouseIsOffBar && !mouseOnTooltip) {
                            if (tooltip) setTooltip({ ...tooltip, visible: false });
                            return;
                          }
                          const position = context.chart.canvas.getBoundingClientRect();

                          const listingEngagement: ListingEngagementMetadata[] =
                            tooltipModel.dataPoints
                              .map(
                                (listing: {
                                  raw: { listingId: string };
                                  dataset: { label: string; backgroundColor: string };
                                  formattedValue: string;
                                }) => {
                                  return {
                                    id: listing.raw.listingId,
                                    label: listing.dataset.label,
                                    color: listing.dataset.backgroundColor,
                                    value: parseInt(listing.formattedValue, 10),
                                    displayValue: listing.formattedValue,
                                  };
                                },
                              )
                              .sort(
                                (a, b) =>
                                  // Sort by total engagment then by alphabetically by label name.
                                  b.value - a.value ||
                                  new Intl.Collator().compare(a.label, b.label),
                              );

                          const newLeft =
                            position.left + window.scrollX + tooltipModel['_eventPosition'].x; // eslint-disable-line dot-notation
                          const newTop =
                            position.top + window.scrollY + tooltipModel['_eventPosition'].y; // eslint-disable-line dot-notation

                          const newTooltipData = {
                            title: `${tooltipModel.title[0]} ${tooltipModel.dataPoints[0].raw.year}`, // eslint-disable-line max-len
                            left: tooltip?.visible ? tooltip?.left : newLeft,
                            top: tooltip?.visible ? tooltip?.top : newTop,
                            listingsData: listingEngagement,
                            visible: true,
                          };

                          if (!isEqual(tooltip, newTooltipData)) setTooltip(newTooltipData);
                        },
                      },
                    },
                  }}
                ></BarChart>
                {!hasBarChartData && !isLoading ? (
                  <div
                    className={cn(
                      'absolute flex h-[103px] w-32 items-center justify-center bg-background-primary p-1',
                      'inset-1/2 translate-x-[-50%] translate-y-[-75%]', // Center it in the chart
                    )}
                  >
                    <div className="flex flex-col items-center">
                      <div className="font-body-medium-emphasis">{t('analytics.noDataToShow')}</div>
                      <div className="mt-2 font-body-medium">
                        {t('analytics.selectDifferentFilter')}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="text-black-055 font-body-small">
                {t('analytics.listingEngagement')}
              </div>
            </div>
            <div className="relative">
              <ChartLegend
                listingMetadata={listingMetadata}
                relatedListingIds={relatedListingIds}
              />
            </div>
          </div>
          <div
            id="barChartTooltip"
            className={cn(
              'absolute mb-1 w-[202px] rounded-[3px] bg-white pl-1 pt-1 shadow-[0px_1px_8px_rgba(0,_0,_0,_0.25)]',
              tooltip?.visible
                ? 'opacity-100'
                : 'opacity-0 transition-opacity delay-300 duration-500',
            )}
            style={{ top: (tooltip?.top || 0) - 75, left: (tooltip?.left || 0) + 5 }}
          >
            {tooltip && (
              <>
                <p className="mb-1 font-body-medium-emphasis">{tooltip.title} </p>
                <div className="max-h-[220px] overflow-y-auto pr-1">
                  <ListingDataList
                    listingsData={tooltip.listingsData}
                    relatedListingIds={relatedListingIds}
                    showRelatedListingTag={false}
                  />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center gap-4 mobile:mb-2 mobile:flex-col mobile:gap-2">
          <BarChartEmptyState
            preserveAspectRatio="none"
            className="h-[276px] w-[65%] px-4 py-3 mobile:h-[327px] mobile:w-[100%]"
          />
          <div className="mobile:text-center">
            <p className="mb-1 text-border-regular font-title-de-emphasis">
              {t('analytics.noDataYet')}
            </p>
            <p className="text-border-regular font-body-medium md:max-w-[180px]">
              {t('analytics.noDataYetMessage')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListingEngagementOverTimeChart;
