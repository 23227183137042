import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Select } from '@components/shared/forms';
import Button from '@components/shared/V2Button';
import { CreateAskingRents } from '@root/types';
import s from './AskingRentForm.module.less';

type Props = {
  setYearMostRecentlyEdited: (year: number) => void;
};

const TermRange = ({ setYearMostRecentlyEdited }: Props) => {
  const { t } = useTranslation('admin');
  const {
    setFieldValue,
    setFieldTouched,
    values: { minimum, maximum, askingRents },
  } = useFormikContext<CreateAskingRents>();

  const termRangeOptions = () =>
    [...Array(20)]
      .map((_, i) => i + 1)
      .map(term => ({
        value: term,
        label: term.toString(),
      }));

  const minimumTermRangeOptions = () => {
    let minimumTerms = termRangeOptions();

    if (maximum) {
      minimumTerms = minimumTerms.filter(term => term.value <= maximum);
    }

    return minimumTerms;
  };

  const maximumTermRangeOptions = () => {
    let maximumTerms = termRangeOptions();

    if (minimum) {
      maximumTerms = maximumTerms.filter(term => term.value >= minimum);
    }

    return maximumTerms;
  };

  const handleClear = () => {
    const askingRentTerms = Object.keys(askingRents);

    askingRentTerms.forEach(term => {
      setFieldTouched(`askingRents[${term}]`, false);
    });

    setFieldValue('minimum', undefined);
    setFieldValue('maximum', undefined);

    setFieldValue('askingRents', {});
    setYearMostRecentlyEdited(0);
  };

  return (
    <>
      <div className={s.header}>{t('listing.askingRent.termRange')}</div>
      <div>
        <div className={s.termRangeSelect}>
          <Select
            name="minimum"
            labelText={t('listing.askingRent.minimum')}
            options={minimumTermRangeOptions()}
            isClearable={false}
            placeholder=""
            value={minimum}
          />
        </div>
        <div className={s.termRangeDashContainer}>{t('common:dash')}</div>
        <div className={s.termRangeSelect}>
          <Select
            name="maximum"
            labelText={t('listing.askingRent.maximum')}
            options={maximumTermRangeOptions()}
            isClearable={false}
            placeholder=""
            value={maximum}
          />
        </div>
        <div className={s.termRangeYears}>{t('listing.askingRent.years')}</div>
        {minimum && maximum ? (
          <div className={s.clearButton}>
            <Button type="tertiary" onClick={handleClear}>
              {t('listing.askingRent.clearSelection')}
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TermRange;
