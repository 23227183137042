import { Placement } from '@popperjs/core';
import IconButton from '@components/shared/IconButton';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import PopperTooltip from '@components/shared/PopperTooltip/PopperTooltip';
import s from './TourbookIconButton.module.less';

export type TourbookIconButtonProps = {
  isInTourbook: boolean;
  tourbookDialogIsOpen?: boolean;
  setTourbookDialogIsOpen: (isOpen: boolean) => void;
  className?: string;
  showButtonTitle?: boolean;
  toolTipPlacement?: Placement;
  disabled?: boolean;
};

const TourbookIconButton = ({
  isInTourbook,
  tourbookDialogIsOpen,
  setTourbookDialogIsOpen,
  className,
  showButtonTitle = true,
  toolTipPlacement = 'right-start',
  disabled = false,
}: TourbookIconButtonProps) => {
  const { t } = useTranslation('tourbook');

  const button = (
    <IconButton
      className={cn(
        { [s.filled]: isInTourbook, [s.tourbookIcon]: true, [s.withBorder]: tourbookDialogIsOpen },
        className,
      )}
      data-testid="tourbook-button"
      icon={isInTourbook ? 'tourbook-filled' : 'tourbook'}
      title={showButtonTitle ? t('manageDialogs.addToTourbook') : undefined}
      disabled={disabled}
    />
  );

  const handleOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    setTourbookDialogIsOpen(true);
  };

  return (
    <PopperTooltip
      triggerElement={button}
      popperElement={t('manageDialogs.addToTourbook')}
      trigger="hover"
      toolTipPlacement={toolTipPlacement}
      popperElementClassName={s.popperElementWrapper}
      onClick={handleOnClick}
      ignoreTouchscreenClicks
    />
  );
};

export default TourbookIconButton;
