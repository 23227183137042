import COLORS from '@root/shared/colorConstants';

export type CrossHairPosition = {
  y: number;
  left: number;
  right: number;
};

const xAxisCrosshairPlugin = {
  id: 'xAxisCrosshair',
  afterDraw(
    chart,
    _args,
    options: {
      stayOn: boolean;
      crossHairPosition: CrossHairPosition;
      setCrossHairPosition: (crossHairPosition: CrossHairPosition) => void;
    },
  ) {
    // eslint-disable-next-line no-underscore-dangle
    const isHoveringOnBar = chart?.tooltip?._active && chart.tooltip._active.length;

    if (isHoveringOnBar || options?.stayOn) {
      const activePoint = chart.tooltip._active[0]; // eslint-disable-line no-underscore-dangle
      const { ctx } = chart;

      const drawCrosshairFromChartData = () => {
        const { y } = activePoint.element;
        const { left, right } = chart.chartArea;

        ctx.moveTo(left, y);
        ctx.lineTo(right, y);

        // Save the coordinate for when stayOn is true and they can't be fetched.
        options?.setCrossHairPosition({ y, left, right });
      };

      ctx.save();
      ctx.beginPath();

      if (options?.stayOn) {
        if (isHoveringOnBar) {
          drawCrosshairFromChartData();
        } else {
          // Else the mouse is not hovering on the bar, but we need to keep the crosshair showing.
          // So we use the crossHairPosition data to draw it instead.
          const settings: CrossHairPosition = options?.crossHairPosition;
          if (settings) {
            ctx.moveTo(settings.left, settings.y);
            ctx.lineTo(settings.right, settings.y);
          }
        }
      } else {
        drawCrosshairFromChartData();
      }

      ctx.lineWidth = 2;
      ctx.strokeStyle = COLORS.black35;
      ctx.setLineDash([4, 4]);
      ctx.stroke();
      ctx.restore();
    }
  },
};

export default xAxisCrosshairPlugin;
