type Props = {
  zIndex?: number;
  height?: number;
  onClick?: () => void;
};

/**
 * Similar to ./InvisibleMask only this is meant to cover only a portion of the screen.
 * Can be used alongside InvisibleMask to allow scrolling within a certain element while
 * still preventing clicks in the whole page.
 *
 * For this use case:
 * 1. ScrollableInvisibleMask should be rendered within the scrollable element (portals would
 * work too)
 * 2. zIndex should be higher than the z-index of the other InvisibleMask
 * 3. height should be the scrollheight of the scrollable element
 */
const ScrollableInvisibleMask = ({ zIndex, height, onClick }: Props) => {
  return (
    <div
      role="button"
      data-testid="scrollableInvisibleMask"
      style={{ zIndex, height }}
      className="absolute w-full"
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick();
      }}
    />
  );
};

export default ScrollableInvisibleMask;
