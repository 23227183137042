import { useState, useEffect } from 'react';
import * as React from 'react';
import { Form, Modal } from 'antd';
import type { FormInstance } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';
import Button from '@components/shared/V2Button';
import CustomIcon from '@components/shared/CustomIcon';
import s from './ModelList.module.less';

type Props<T> = {
  initialModels: T[];
  modelTemplate: T;
  children: (model: T & { key: number }, form: FormInstance) => React.ReactNode;
  registerForm?: (form: FormInstance) => void;
  deregisterForm?: (i: number) => void;
  addButtonText: string;
  shouldConfirmDelete?: (model: T) => boolean;
};

const ModelRow = ({ children, registerForm }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (registerForm) {
      registerForm(form);
    }
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children(form);
};

const ModelList = <T,>({
  initialModels,
  modelTemplate,
  children,
  registerForm,
  deregisterForm,
  addButtonText,
  shouldConfirmDelete,
}: Props<T>) => {
  const { t } = useTranslation('common');
  const [models, setModels] = useState<(T & { key: number })[]>(
    initialModels.map(m => ({ ...m, key: Math.random() })),
  );

  const handleModelAddClick = () => {
    const key = Math.random();
    const newModel = { ...modelTemplate, key };

    setModels(oldModels => [...oldModels, newModel]);
  };

  const handleTrashClick = (i, form) => {
    const deleteModel = () => {
      setModels(models.filter((_, j) => i !== j));
      if (deregisterForm) deregisterForm(i);
    };
    if (shouldConfirmDelete && shouldConfirmDelete(form.getFieldsValue())) {
      Modal.confirm({
        content: t('modelList.confirmation.description'),
        okText: t('modelList.confirmation.okText'),
        okType: 'danger',
        cancelText: t('modelList.confirmation.cancelText'),
        autoFocusButton: 'cancel',
        onOk: deleteModel,
      });
    } else {
      deleteModel();
    }
  };

  return (
    <div>
      {models.map((model, i) => (
        <div className={s.row} key={model.key}>
          <ModelRow registerForm={registerForm}>
            {form => (
              <>
                {children(model, form)}
                <button type="button" className={s.trash} onClick={() => handleTrashClick(i, form)}>
                  <CustomIcon type="trash" />
                </button>
              </>
            )}
          </ModelRow>
        </div>
      ))}
      <Button className={s.addNew} onClick={handleModelAddClick}>
        {addButtonText}
      </Button>
    </div>
  );
};

export default ModelList;
