import * as React from 'react';
import cn from 'classnames';

type Props = {
  value: React.ReactNode;
  // use of any is anti-pattern, ideally this should be simpler (i.e. no format)
  format?: (value: any) => JSX.Element | string | Element;
  valueClassName?: string;
  labelClassname?: string;
  label: string | React.ReactNode;
};

const ListingAttribute = ({
  value = null,
  format = x => x,
  label,
  valueClassName,
  labelClassname,
}: Props) => {
  const defaultValue = val => {
    if (!val) return '-';
    return format(val);
  };

  return (
    <div>
      <header>
        <h6 className={cn(labelClassname, 'text-black-055 font-body-medium')}>{label}</h6>
      </header>
      <div className={cn(valueClassName, 'mb-0 font-body-medium')}>{defaultValue(value)}</div>
    </div>
  );
};

export default ListingAttribute;
