import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import routes from '@root/routes';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';

type Props = {
  requestedListingsCount: number;
};

const SidebarMenu = ({ requestedListingsCount }: Props) => {
  const location = useLocation();
  const { t } = useTranslation('admin');
  const flags = useFlags();

  return (
    <ul className="flex flex-col">
      <MenuItem
        isSelected={
          location.pathname.startsWith(routes.admin.buildingSearch) ||
          location.pathname.startsWith(routes.admin.vtsPropertySearch)
        }
        href={routes.admin.buildingSearch}
      >
        {t('sidebar.buildingsLink')}
      </MenuItem>
      <MenuItem
        href={routes.admin.listingSearch}
        isSelected={location.pathname.startsWith(routes.admin.listingSearch)}
      >
        {requestedListingsCount > 0
          ? t('sidebar.listingsLinkWithCount', { count: requestedListingsCount })
          : t('sidebar.listingsLink')}
      </MenuItem>
      <MenuItem
        href={routes.admin.userSearch}
        isSelected={location.pathname.startsWith(routes.admin.userSearch)}
      >
        {t('sidebar.usersLink')}
      </MenuItem>
      <MenuItem
        href={routes.admin.companies}
        isSelected={location.pathname.startsWith(routes.admin.companies)}
      >
        {flags['outreach.landlord-company-connection-severed']
          ? t('sidebar.tenantsLink')
          : t('sidebar.companiesLink')}
      </MenuItem>
      <MenuItem
        href={routes.admin.landlords}
        isSelected={location.pathname.startsWith(routes.admin.landlords)}
      >
        {t('sidebar.landlordsLink')}
      </MenuItem>
      <MenuItem
        href={routes.admin.tourbookExportTool}
        isSelected={location.pathname.startsWith(routes.admin.tourbookExportTool)}
      >
        {t('sidebar.tourbookExportToolLink')}
      </MenuItem>
    </ul>
  );
};

export default SidebarMenu;

const MenuItem = ({
  children,
  isSelected,
  href,
}: {
  children: ReactNode;
  href: string;
  isSelected: boolean;
}) => (
  <li
    className={cn(
      'border-r-4 border-solid border-transparent',
      isSelected && 'mr-[-1px] !border-indigo-100 bg-general-indigo-quaternary font-bold',
    )}
  >
    <Link
      className={cn('block h-full w-full py-2 pl-2', !isSelected && 'text-black-100')}
      to={href}
    >
      {children}
    </Link>
  </li>
);
