import { EVENTS, PARAMETERS, EVENT_TYPES } from '@root/tracking/constants';

const savedSearchPageLoadedAction = {
  type: 'SAVED_SEARCH_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.savedSearchPage,
      },
    },
  },
} as const;
type SavedSearchPageLoadedAction = typeof savedSearchPageLoadedAction;
const savedSearchPageLoaded = () => savedSearchPageLoadedAction;

const savedListingsPageLoadedAction = {
  type: 'SAVED_LISTINGS_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.savedListingsPage,
      },
    },
  },
} as const;
type SavedListingsPageLoadedAction = typeof savedListingsPageLoadedAction;
const savedListingsPageLoaded = () => savedListingsPageLoadedAction;

const goToSavedSearchAction = {
  type: 'GO_TO_SAVED_SEARCH',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        sourcePage: PARAMETERS.savedSearchPage,
        destination: PARAMETERS.searchResultsPage,
      },
    },
  },
} as const;
type GoToSavedSeachAction = typeof goToSavedSearchAction;
const goToSavedSearch = () => goToSavedSearchAction;

const removedASavedSearchAction = {
  type: 'REMOVED_A_SAVED_SEARCH',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.saveInteraction,
        action: PARAMETERS.confirmDeleteSavedSearch,
      },
    },
  },
} as const;
type RemovedASavedSearchAction = typeof removedASavedSearchAction;
const removedASavedSearch = () => removedASavedSearchAction;

const loadedSavedBuildingOwnersPageAction = {
  type: 'SAVED_BUILDING_OWNER_PAGE_LOADED',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.page,
        event: PARAMETERS.savedBuildingOwnersPage,
      },
    },
  },
} as const;
type LoadedSavedBuildingOwnersPageAction = typeof loadedSavedBuildingOwnersPageAction;
const loadedSavedBuildingOwnersPage = () => loadedSavedBuildingOwnersPageAction;

const clickToSavedBuildingsFromToastAction = {
  type: 'CLICK_TO_SAVED_BUILDINGS',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        destination: PARAMETERS.savedBuildingsPage,
        sourceContent: PARAMETERS.toast,
      },
    },
  },
} as const;
type ClickToSavedBuildingsFromToastAction = typeof clickToSavedBuildingsFromToastAction;
const clickToSavedBuildingsFromToast = () => clickToSavedBuildingsFromToastAction;

const savedListingClickAction = {
  type: 'CLICKED_SAVED_LISTING',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        destination: PARAMETERS.listingPage,
        sourceContent: PARAMETERS.savedItem,
        sourcePage: PARAMETERS.savedListingsPage,
      },
    },
  },
} as const;
type SavedListingClickAction = typeof savedListingClickAction;
const savedListingClick = () => savedListingClickAction;

const savedBuildingClickAction = {
  type: 'CLICKED_SAVED_BUILDING',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        destination: PARAMETERS.buildingPage,
        sourceContent: PARAMETERS.savedItem,
        sourcePage: PARAMETERS.savedBuildingsPage,
      },
    },
  },
} as const;
type SavedBuildingClickAction = typeof savedBuildingClickAction;
const savedBuildingClick = () => savedBuildingClickAction;

const savedBuildingOwnerClickAction = {
  type: 'CLICKED_SAVED_BUILDING_OWNER',
  payload: {
    meta: {
      analytics: {
        eventType: EVENT_TYPES.track,
        event: EVENTS.clickToPage,
        destination: PARAMETERS.landlordPage,
        sourceContent: PARAMETERS.savedItem,
        sourcePage: PARAMETERS.savedBuildingOwnersPage,
      },
    },
  },
} as const;
type SavedBuildingOwnerClickAction = typeof savedBuildingOwnerClickAction;
const savedBuildingOwnerClick = () => savedBuildingOwnerClickAction;

export type Actions =
  | SavedSearchPageLoadedAction
  | SavedListingsPageLoadedAction
  | GoToSavedSeachAction
  | RemovedASavedSearchAction
  | LoadedSavedBuildingOwnersPageAction
  | ClickToSavedBuildingsFromToastAction
  | SavedListingClickAction
  | SavedBuildingClickAction
  | SavedBuildingOwnerClickAction;

export default {
  savedListingsPageLoaded,
  savedSearchPageLoaded,
  goToSavedSearch,
  removedASavedSearch,
  loadedSavedBuildingOwnersPage,
  clickToSavedBuildingsFromToast,
  savedListingClick,
  savedBuildingClick,
  savedBuildingOwnerClick,
};
