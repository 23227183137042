import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomIcon } from '@components/shared';
import CloudinaryUploader from '@components/shared/Cloudinary/CloudinaryUploader';
import { UploadApiResponse as CloudinaryResponse } from 'cloudinary';
import css from './EditableAsset.module.less';

type Props = {
  placeholderContainerClassName?: string;
  placeholderElementClassName?: string;
  containerClassName?: string;
  recommendedImageText?: string;
  testId?: string;
  onError: () => void;
  onFileUpload?: (fileUrl: string) => void;
  onReady: (response: CloudinaryResponse) => void;
  assetSelectorModalProps?: {
    title: string;
    alternativeAssetSelection: ReactNode;
  };
  uploadHelpText?: string;
};

const AssetUploader = ({
  placeholderContainerClassName,
  placeholderElementClassName,
  containerClassName,
  recommendedImageText,
  onReady,
  onFileUpload,
  onError,
  testId,
  assetSelectorModalProps,
  uploadHelpText,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex flex-col">
      <CloudinaryUploader
        placeholderElement={
          <div className={placeholderContainerClassName}>
            <CustomIcon type="camera" className={css.cameraIcon} />
            <span className={placeholderElementClassName}>
              {uploadHelpText || t('editableAsset.logoUploadHelpText')}
            </span>
          </div>
        }
        containerClassName={containerClassName}
        onReady={onReady}
        onFileUpload={onFileUpload}
        onError={onError}
        dataTestId={testId}
        assetSelectorModalProps={assetSelectorModalProps}
      />
      {!!recommendedImageText && (
        <span className="pt-1 text-black-055 font-body-small">{recommendedImageText}</span>
      )}
    </div>
  );
};

export default AssetUploader;
