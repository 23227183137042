import React, { useEffect, useRef, useState } from 'react';
import {
  ConfirmationModal,
  Modal,
  Button,
  Notification,
  List,
  CustomIcon,
  OnlyInDesktop,
  ErrorBoundary,
} from '@components/shared';
import { useHistory } from 'react-router-dom';
import { TourbookListing, Tourbook, PartialListing } from '@root/types';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import routes from '@root/routes';
import tourbooksActions from '@store/actions/tourbooks';
import actions from '@store/actions/tourbookPage';
import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';
import emailHref from '@shared/emailHref';
import useHash from '@shared/useHash';
import contentWidth from '@styles/contentWidth.module.less';
import { CSSTransition } from 'react-transition-group';
import useDownloadTourbook from '@shared/useDownloadTourbook';
import api from '@shared/api';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useBreakpoint } from '@shared/useBreakpoints';
import { PARAMETERS } from '@root/tracking/constants';
import { isInIframe, sendHostToTourbookPage } from '@root/shared/iframeUtils';
import useFavoritedListingsQuery from '@root/shared/useFavoritedListingsQuery';
import tourbooksStyles from '../Tourbooks/Tourbooks.module.less';
import tourbookStyles from './Tourbook.module.less';
import TourbookMap from './TourbookMap';
import TourbookEmptyState from './TourbookEmptyState';
import TourbookHeader from './TourbookHeader';
import TourbookControls from './TourbookControls';
import TourbookListingCard from './TourbookListingCard';
import TableOfContents from './TableOfContents';
import useListingPreviewModal from '../ListingSearch/ListingPreviewModal/useListingPreviewModal';

export type Props = {
  tourbook: Tourbook;
  fetchTourbook: () => void;
  readOnly: boolean;
  tourbookDownload: () => void;
  tourbookRetryDownload: () => void;
  isDuplicating: boolean;
  setIsDuplicating: (boolean) => void;
};

const mapDispatch = {
  deleteTourbook: (
    tourbook: Tourbook,
    listingsCount: number,
    callbacks: { onSuccess: () => void; onError: () => void },
  ) => tourbooksActions.deleteTourbook(tourbook, listingsCount, callbacks),
  removeListingInTourbook: (listing: TourbookListing | null, tourbookId, onSuccessCallback) =>
    tourbooksActions.deleteTourbookListing({
      onSuccess: () => {
        onSuccessCallback(listing);
      },
      tourbookId,
      listingId: listing?.id || '',
      external: listing?.external || false,
      analyticsInformation: listing?.analyticsInformation,
    }),
  beginReorderingTourbookListings: actions.beginReorderingTourbookListings,
  endReorderingTourbookListings: actions.endReorderingTourbookListings,
  reorderedTourbookListing: actions.reorderedTourbookListing,
  openMap: actions.openTourbookMap,
  closeMap: actions.closeTourbookMap,
  tourbookDownload: () => actions.tourbookDownload(),
  tourbookRetryDownload: () => actions.tourbookRetryDownload(),
};

const connector = connect(null, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

const defaultModalState = {
  edit: false,
  delete: false,
  duplicate: false,
  removeListing: false,
};

export const TourbookLayoutContent = ({
  tourbook,
  removeListingInTourbook,
  fetchTourbook,
  deleteTourbook,
  readOnly,
  beginReorderingTourbookListings,
  endReorderingTourbookListings,
  reorderedTourbookListing,
  openMap,
  closeMap,
  tourbookDownload,
  tourbookRetryDownload,
  setIsDuplicating,
  isDuplicating,
}: Props & ReduxProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const hash = useHash();

  const newestRef = useRef<HTMLDivElement | null>(null);
  const cardListRef = useRef<HTMLUListElement | null>(null);
  const { t } = useTranslation('tourbook');
  const [listingToRemove, setListingToRemove] = useState<TourbookListing | null>(null);
  const [hoveredListing, setHoveredListing] = useState<TourbookListing | null>(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [maxMapHeight, setMaxMapHeight] = useState<number | undefined>(undefined);
  const containsFlaggedListings =
    tourbook.listings &&
    tourbook.listings.some((tourbookListing: TourbookListing) => {
      return !!tourbookListing.flaggedAt;
    });

  const [modalState, setModalState] = useState<{
    edit: boolean;
    delete: boolean;
    duplicate: boolean;
    removeListing: boolean;
  }>(defaultModalState);

  const downloadTourbook = useDownloadTourbook();

  const onRetryDownloadClick = () => {
    tourbookRetryDownload();
    onDownload();
  };

  const onDownload = async () => {
    downloadTourbook({
      tourbookId: tourbook.id,
      onFinish: () => {
        tourbookDownload();
      },
      onFailure: () => {
        const href = emailHref('support@vts.com', {
          subject: t('notification.downloadError.supportEmail.subject'),
          body: t('notification.downloadError.supportEmail.body', {
            tourbookUrl: window.location.href,
          }),
        });
        Notification.error({
          title: t('notification.downloadError.notificationTitle'),
          text: (
            <>
              {/* eslint-disable react/jsx-no-literals */}
              <Trans t={t} i18nKey="notification.downloadError.notificationText">
                Please retry your download, or contact&nbsp;
                <a href={href}>support@vts.com</a>
                &nbsp;if the issue persists.
              </Trans>
              {/* eslint-enable react/jsx-no-literals */}
              <Button
                type="primary"
                className={tourbookStyles.notificationRetryButton}
                onClick={onRetryDownloadClick}
              >
                {t('notification.downloadError.retryButton')}
              </Button>
            </>
          ),
          iframe: {
            text: t('notification.downloadError.notificationText'),
            links: [{ url: href, method: 'newTab' }],
          },
        });
      },
    });
  };

  const onRetryDuplicationClick = async () => {
    dispatch(tourbooksActions.tourbookRetryDuplicate(tourbook, !readOnly));
    await onDuplicateTourbook();
  };

  const triggerFailureNotification = () => {
    Notification.error({
      title: t('notification.duplicationError.title'),
      text: (
        <>
          {/* eslint-disable react/jsx-no-literals */}
          <Trans t={t} i18nKey="notification.duplicationError.text"></Trans>
          {/* eslint-enable react/jsx-no-literals */}
          <br></br>
          <Button
            type="primary"
            size="small"
            className={tourbookStyles.retryDuplicationButton}
            onClick={onRetryDuplicationClick}
          >
            {t('notification.duplicationError.retryButton')}
          </Button>
        </>
      ),
      iframe: {
        text: t('notification.duplicationError.text'),
      },
    });
  };

  const onDuplicateTourbook = async () => {
    onCloseModal();
    try {
      setIsDuplicating(true);
      const response = await api.post(routes.api.tourbookDuplicate(tourbook.id), { inIframe });
      setIsDuplicating(false);
      if (response.ok) {
        const newTourbook = await response.json();
        dispatch(tourbooksActions.tourbookDuplicate(tourbook, newTourbook, !readOnly));
        history.push(routes.tourbook(newTourbook.id));
        Notification.info({
          title: t('notification.duplicationSuccess.title'),
          text: t('notification.duplicationSuccess.text'),
          placement: 'bottomLeft',
          duration: 3.5,
        });
      } else {
        triggerFailureNotification();
      }
    } catch {
      setIsDuplicating(false);
      triggerFailureNotification();
    }
  };

  const onEditClick = () => {
    setModalState({ ...defaultModalState, edit: true });
  };

  const onDeleteClick = () => {
    setModalState({ ...defaultModalState, delete: true });
  };

  const onDuplicateTourbookModalClick = () => {
    setModalState({ ...defaultModalState, duplicate: true });
  };

  const onCloseModal = () => {
    setModalState(defaultModalState);
  };

  const handleDelete = () => {
    const listingsCount = tourbook.listings?.length ? tourbook.listings.length : 0;
    deleteTourbook(tourbook, listingsCount, {
      onSuccess: () => {
        Notification.info({
          title: t('notification.deleteSuccess'),
          placement: 'bottomLeft',
          duration: 3.5,
        });

        history.push(routes.tourbooks);
      },
      onError: () =>
        Notification.error({
          title: t('notification.deleteError'),
          placement: 'bottomLeft',
          duration: 3.5,
        }),
    });
    onCloseModal();
  };

  const onRemoveListingClick = listing => {
    setListingToRemove(listing);
    setModalState({ ...defaultModalState, removeListing: true });
  };

  const fullListingName = (listing: TourbookListing | null): string => {
    if (!listing) return '';

    return listing?.name
      ? `${listing.address.buildingName || listing.address.street}, ${listing?.name}`
      : `${listing.address.buildingName || listing.address.street}`;
  };

  const removeListingSuccessCallback = (listing: TourbookListing) => {
    Notification.info({
      title: t('notification.deleteListingSuccess.title'),
      text: t('notification.deleteListingSuccess.text', { listingName: fullListingName(listing) }),
      placement: 'bottomLeft',
    });
  };

  const onRemoveListingConfirmClick = async () => {
    await removeListingInTourbook(listingToRemove, tourbook.id, removeListingSuccessCallback);
    fetchTourbook();
    onCloseModal();
  };

  const inIframe = isInIframe();
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(inIframe);

  const { isMobile } = useBreakpoint();
  const scrollPositionToCollapseHeader = isMobile ? -300 : -170;

  useScrollPosition(
    ({ currPos }) => {
      if (!inIframe) setIsHeaderCollapsed(currPos.y < scrollPositionToCollapseHeader);
    },
    [scrollPositionToCollapseHeader],
  );

  const ensureMapClosed = () => {
    if (isMapOpen) {
      setIsMapOpen(false);
      closeMap();
    }
  };

  const onMapCloseClick = () => {
    ensureMapClosed();
  };

  const onMapOpenClick = () => {
    setIsMapOpen(true);
    openMap();
  };

  const [isReordering, setIsReordering] = React.useState(false);
  const startReordering = () => {
    ensureMapClosed();
    setIsReordering(true);
    beginReorderingTourbookListings();
  };
  const stopReordering = () => {
    setIsReordering(false);
    endReorderingTourbookListings();
  };

  useEffect(() => {
    if (hash !== '#newest') return;
    const { current } = newestRef;
    if (!current) return;
    window.scrollTo({ top: current.offsetTop - 50, behavior: 'smooth' });
  }, [hash]);

  useEffect(() => {
    const handler = () => {
      if (cardListRef.current) setMaxMapHeight(cardListRef.current.clientHeight);
    };

    handler();

    window.visualViewport?.addEventListener('resize', handler);
    return () => {
      window.visualViewport?.removeEventListener('resize', handler);
    };
  }, []);

  useEffect(() => {
    setMaxMapHeight(cardListRef.current?.clientHeight);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardListRef.current]);

  useEffect(() => {
    sendHostToTourbookPage({ id: tourbook.id, name: tourbook.name });
  }, [tourbook]);

  const listingFormatterForPreviewModal = (listing: TourbookListing): PartialListing => {
    return {
      ...listing,
      buildingSlug: listing.building.slug,
      spaceCondition: listing.condition,
      photo: listing.photos[0],
      neighborhoodName: listing.building.neighborhood,
      capacity: null,
      address: listing.address,
    };
  };

  const { isListingSaved } = useFavoritedListingsQuery();

  const marketplaceListings = tourbook.listings.filter(listing => !listing.external);

  const { ListingPreviewModal, listingPreviewModalProps, openListingPreviewModal } =
    useListingPreviewModal({
      partialListings: marketplaceListings.map(l => listingFormatterForPreviewModal(l)),
      sourcePage: PARAMETERS.tourbookPage,
      isSaved: isListingSaved,
    });

  const handleOpenListingPreviewModal = listingId => {
    const listingIndex = marketplaceListings.findIndex(listing => listing.id === listingId);
    openListingPreviewModal(listingIndex);
  };

  return (
    <>
      <CSSTransition
        in={isMapOpen}
        timeout={1000}
        classNames={{
          enter: tourbookStyles.mobileMapEnter,
          enterActive: tourbookStyles.mobileMapEnterActive,
          exit: tourbookStyles.mobileMapExit,
          exitActive: tourbookStyles.mobileMapExitActive,
        }}
        unmountOnExit
      >
        <div className={cn(tourbookStyles.mobileMapContainer)} data-testid="mobile-tourbook-map">
          <Button
            type="primary"
            size="small"
            onClick={onMapCloseClick}
            className={tourbookStyles.mobileMapBackButton}
            data-testid="mapClose"
          >
            <CustomIcon type="close" />
          </Button>
          <TourbookMap
            className={tourbookStyles.mobileMap}
            tourbook={tourbook}
            hoveredListing={hoveredListing}
            onInfoWindowClicked={ensureMapClosed}
          />
        </div>
      </CSSTransition>

      <ErrorBoundary>
        <TourbookControls
          tourbook={tourbook}
          onDeleteClick={onDeleteClick}
          onDownload={onDownload}
          onEditClick={onEditClick}
          readOnly={readOnly}
          isCollapsed={isHeaderCollapsed}
          isDuplicating={isDuplicating}
          onDuplicateTourbookClick={
            containsFlaggedListings ? onDuplicateTourbookModalClick : onDuplicateTourbook
          }
          onDuplicateTourbook={onDuplicateTourbook}
        />
        <TourbookHeader
          tourbook={tourbook}
          isModalOpen={modalState.edit}
          onCloseModal={onCloseModal}
          readOnly={readOnly}
          hidden={false}
        />
      </ErrorBoundary>
      <Modal
        isOpen={modalState.removeListing}
        closeModal={onCloseModal}
        title={
          listingToRemove?.external
            ? t('removeListingModal.titleExternalListing')
            : t('removeListingModal.title')
        }
      >
        <div className={tourbooksStyles.modal}>
          {listingToRemove?.external
            ? t('removeListingModal.confirmationTextExternalListing', {
                listingName: fullListingName(listingToRemove),
              })
            : t('removeListingModal.confirmationText', {
                listingName: fullListingName(listingToRemove),
              })}
          <div className={tourbooksStyles.buttons}>
            <Button type="secondary" size="large" onClick={onCloseModal}>
              {t('common:cancel')}
            </Button>
            <Button type="primary" size="large" onClick={onRemoveListingConfirmClick}>
              {listingToRemove?.external ? t('common:delete') : t('common:remove')}
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        title={t('deleteModal.title')}
        confirmationText={t('deleteModal.confirmationText')}
        isOpen={modalState.delete}
        cancelButtonText={t('common:cancel')}
        confirmButtonText={t('common:delete')}
        onClose={onCloseModal}
        onConfirm={handleDelete}
      />
      <ConfirmationModal
        title={t('duplicateModal.title')}
        confirmationText={t('duplicateModal.confirmationText')}
        isOpen={modalState.duplicate}
        cancelButtonText={t('common:cancel')}
        confirmButtonText={t('duplicateModal.duplicate')}
        onClose={onCloseModal}
        onConfirm={onDuplicateTourbook}
        primary-button-testid="duplicate-tourbook"
      />
      {tourbook.listings.length ? (
        <>
          <ErrorBoundary>
            {!isMapOpen && (
              <>
                <div className={tourbookStyles.mobileFooterButtonContainer}>
                  <Button
                    size="small"
                    onClick={onMapOpenClick}
                    className={tourbookStyles.mobileFooterButton}
                  >
                    <CustomIcon type="pin-drop" />
                    <span className={tourbookStyles.viewAll}>{t('viewMap')}</span>
                  </Button>
                </div>
              </>
            )}
          </ErrorBoundary>
          <ErrorBoundary>
            <TableOfContents
              listings={tourbook.listings}
              isReordering={isReordering}
              tourbookId={tourbook.id}
              fetchTourbook={fetchTourbook}
              reorderedTourbookListing={reorderedTourbookListing}
              readOnly={readOnly}
              stopReordering={stopReordering}
              startReordering={startReordering}
            />
          </ErrorBoundary>
          <div
            className={cn(
              tourbookStyles.cardListAndMapContainer,
              readOnly && tourbookStyles.readOnly,
              contentWidth.contentContainer,
            )}
          >
            <section data-testid="showListingsSection" className={tourbookStyles.listings}>
              <ErrorBoundary>
                <List<TourbookListing>
                  ref={cardListRef}
                  className={tourbookStyles.cardList}
                  items={tourbook.listings}
                  renderItem={listing => (
                    <>
                      <div ref={newestRef}></div>
                      <TourbookListingCard
                        tourbookId={tourbook.id}
                        listing={listing}
                        readOnly={readOnly}
                        ownerVTSId={tourbook.owner.vtsId}
                        handleOnRemoveListingClick={() => onRemoveListingClick(listing)}
                        onHover={() => setHoveredListing(listing)}
                        onHoverExit={() => setHoveredListing(null)}
                        onOpenListingPreview={() => handleOpenListingPreviewModal(listing.id)}
                      />
                    </>
                  )}
                />
              </ErrorBoundary>
            </section>
            <OnlyInDesktop>
              <ErrorBoundary>
                <TourbookMap
                  className={cn(
                    tourbookStyles.mapContainer,
                    isHeaderCollapsed && tourbookStyles.belowHeader,
                  )}
                  tourbook={tourbook}
                  hoveredListing={hoveredListing}
                  maxHeight={maxMapHeight}
                />
              </ErrorBoundary>
            </OnlyInDesktop>
          </div>
          <ErrorBoundary>
            <ListingPreviewModal {...listingPreviewModalProps} tourbookId={tourbook.id} />
          </ErrorBoundary>
        </>
      ) : (
        <TourbookEmptyState readOnly={readOnly || inIframe} />
      )}
    </>
  );
};

export default connector(TourbookLayoutContent);
