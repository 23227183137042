const DropdownStyles = {
  option: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: isFocused ? '#ffb200' : 'transparent',
    cursor: 'pointer',
    display: 'flex',
  }),
  control: provided => ({
    ...provided,
    boxShadow: 'none',
    borderColor: '#cccccc',
    ':hover': {
      borderColor: '#cccccc',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: '#A6A6A6',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: '4px',
    color: '#000000',
    maxWidth: '95%',
  }),
  multiValueRemove: provided => ({
    ...provided,
    cursor: 'pointer',
    borderRadius: '4px',
    'span svg path': {
      fill: '#000000',
    },
    '&:hover': {
      backgroundColor: '#cccccc',
    },
  }),
  loadingIndicator: () => ({
    display: 'none',
  }),
  menuList: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '4px',
  }),
  valueContainer: provided => ({
    ...provided,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  }),
};

export default DropdownStyles;
