import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, CustomIcon } from '@components/shared';
import s from './EmptyState.module.less';
import TourbookCoverIllustration from './images/TourbookCoverIllustration.svg';
import TourbookCardIllustration from './images/TourbookCardIllustration.svg';
import TourbookAnalyticsIllustration from './images/TourbookAnalyticsIllustration.svg';

import Layout from '../Layout';

type Props = {
  onClickCreateTourbook: () => void;
};

const EmptyState = ({ onClickCreateTourbook }: Props) => {
  const { t } = useTranslation('tourbook');

  return (
    <Layout
      containerClassName={cn(
        'flex justify-center desktop:!py-7 tablet:!px-3 tablet:!py-7 mobile:!py-2 mobile:!px-2',
      )}
    >
      <div className="relative flex w-full max-w-[1183px] flex-col justify-center rounded-md bg-background-primary shadow-[0px_1px_8px_rgba(115,_115,_115,_0.25)] desktop:px-6 desktop:pb-7 tablet:px-4.5 tablet:pb-7 mobile:px-2 mobile:pb-9 mobile:pt-3">
        <div className={cn(s.fancyDiv, 'absolute inset-0 z-[1]')} />
        <div className="z-[2] max-w-[976px] border-b border-solid border-general-neutral-tertiary pb-4 desktop:pt-7 tablet:pt-4 mobile:pb-2">
          <div className="mb-2 flex items-center gap-1">
            <span className="font-headline">{t('tourbooksEmptyState.tourbooks')}</span>
            <CustomIcon type="tourbook" className="text-indigo-100 [&>svg]:h-3 [&>svg]:w-3" />
          </div>
          <p className="font-body-large">{t('tourbooksEmptyState.description')}</p>
        </div>
        <div className="flex max-w-[976px] flex-row pt-5 desktop:gap-12 tablet:flex-col tablet:gap-5 mobile:flex-col mobile:gap-5">
          <div className="z-[2] flex flex-col desktop:gap-y-3 tablet:flex-row tablet:gap-x-4 mobile:flex-row mobile:gap-x-2">
            <div className="mobile:h-[92px] mobile:w-[129px]">
              <TourbookCoverIllustration
                className={cn(
                  'rounded !shadow-[0_9px_30px_-6px_rgba(0,0,0,0.12)]',
                  'desktop:h-[185px] desktop:w-[260px] tablet:h-[142px] tablet:w-[200px] mobile:h-[92px] mobile:w-[129px]',
                )}
              />
            </div>

            <div className="flex flex-col gap-0.5 tablet:max-w-[360px] tablet:pt-3.5 mobile:max-w-[195px]">
              <span className="font-subtitle mobile:font-body-medium-emphasis">
                {t('tourbooksEmptyState.tourbookIllustration.title')}
              </span>
              <span className="font-body-large mobile:font-body-medium">
                {t('tourbooksEmptyState.tourbookIllustration.description')}
              </span>
            </div>
          </div>
          <div className="z-[2] flex flex-col desktop:gap-y-3 tablet:flex-row tablet:gap-x-4 mobile:flex-row mobile:gap-x-2">
            <div className="mobile:h-[92px] mobile:w-[129px]">
              <TourbookCardIllustration
                className={cn(
                  'rounded !shadow-[0_9px_30px_-6px_rgba(0,0,0,0.12)]',
                  'h-[185px] w-[260px] tablet:h-[142px] tablet:w-[200px] mobile:h-[92px] mobile:w-[129px]',
                )}
              />
            </div>

            <div className="flex flex-col gap-0.5 tablet:max-w-[360px] tablet:pt-3.5 mobile:max-w-[195px]">
              <span className="font-subtitle mobile:font-body-medium-emphasis">
                {t('tourbooksEmptyState.tourbookMediaIllustration.title')}
              </span>
              <span className="font-body-large mobile:font-body-medium">
                {t('tourbooksEmptyState.tourbookMediaIllustration.description')}
              </span>
            </div>
          </div>
          <div className="z-[2] flex flex-col desktop:mr-2 desktop:gap-y-3 tablet:flex-row tablet:gap-x-4 mobile:flex-row mobile:gap-x-2">
            <div className="mobile:h-[92px] mobile:w-[129px]">
              <TourbookAnalyticsIllustration
                className={cn(
                  'rounded !shadow-[0_9px_30px_-6px_rgba(0,0,0,0.12)]',
                  'h-[185px] w-[260px] tablet:h-[142px] tablet:w-[200px] mobile:h-[92px] mobile:w-[129px]',
                )}
              />
            </div>
            <div className="flex flex-col gap-0.5 tablet:max-w-[360px] tablet:pt-3.5 mobile:max-w-[220px]">
              <span className="font-subtitle mobile:font-body-medium-emphasis">
                {t('tourbooksEmptyState.tourbookAnalyticsIllustration.title')}
              </span>
              <span className="font-body-large mobile:font-body-medium">
                {t('tourbooksEmptyState.tourbookAnalyticsIllustration.description')}
              </span>
            </div>
          </div>
        </div>
        <Button
          onClick={() => onClickCreateTourbook()}
          className="z-[2] mt-3 h-5 w-[175px] !px-3 !py-1"
          size="large"
          data-testid="createTourbook"
        >
          {t('createTourbook')}
        </Button>
      </div>
    </Layout>
  );
};

export default EmptyState;
