import React from 'react';
import { useTranslation } from 'react-i18next';
import { RawSelect } from '@components/shared/forms';
import { components } from 'react-select';
import { StoreState } from '@root/types';
import { connect, ConnectedProps } from 'react-redux';
import { IconButton, OnlyInDesktopTablet } from '@components/shared';
import { camelCase } from 'lodash';
import useAnalytics from '@shared/useAnalytics';
import { useListingSearchCriteria } from '../utils';
import s from './PaginationSection.module.less';

const mapState = (state: StoreState) => ({
  pageSize: state.listings.pagination?.pageSize,
  currentPage: state.listings.pagination?.currentPage,
  listingsCount: state.listings.pagination?.totalCount,
  ids: state.listings.pagination?.ids,
});

const connector = connect(mapState);
export type ReduxProps = ConnectedProps<typeof connector>;

function PaginationSection({
  pageSize = 0,
  listingsCount = 0,
  currentPage = 0,
  ids = [],
}: ReduxProps) {
  const criteria = useListingSearchCriteria();
  const { t } = useTranslation('listingSearch');
  const { PARAMETERS, sortInteraction } = useAnalytics();

  const offset = (currentPage - 1) * pageSize + 1;
  const numberOfPages = Math.ceil(listingsCount / pageSize);

  const options = Array.from({ length: numberOfPages }).map((_, i) => {
    return { value: i + 1, label: (i + 1).toString() };
  });

  const isOnLastPage = criteria.currentFilters.page === options[options.length - 1]?.value;

  const changePage = newPage => {
    criteria.add('page', newPage);
    criteria.pushToHistory();
  };

  const increasePage = () => {
    criteria.add(
      'page',
      criteria.currentFilters.page + 1 > numberOfPages ? 1 : criteria.currentFilters.page + 1,
    );
    criteria.pushToHistory();
  };

  const decreasePage = () => {
    criteria.add(
      'page',
      criteria.currentFilters.page === 1 ? numberOfPages : criteria.currentFilters.page - 1,
    );
    criteria.pushToHistory();
  };

  const onSortByClick = newSort => {
    criteria.add('sort', newSort);
    sortInteraction({
      actionType: 'CHANGE_SEARCH_SORT',
      sortValue: t(`sortingOptions.${camelCase(newSort)}`),
      sourcePage: PARAMETERS.searchResultsPage,
      sourceContent: PARAMETERS.paginationBar,
    });
    criteria.pushToHistory();
  };

  const SortSelector = () => (
    <RawSelect
      className={s.sortSelector}
      components={{
        Menu: props => <components.Menu {...props} className={s.sortMenu} />,
      }}
      onChange={onSortByClick}
      isSearchable={false}
      name="seach-sort"
      menuPlacement="top"
      value={criteria.currentFilters.sort}
      options={[
        { value: 'newest', label: t('sortingOptions.newest') },
        { value: 'largest', label: t('sortingOptions.largest') },
        { value: 'smallest', label: t('sortingOptions.smallest') },
        { value: 'available_soonest', label: t('sortingOptions.availableSoonest') },
        { value: 'available_latest', label: t('sortingOptions.availableLatest') },
      ]}
    />
  );

  return (
    <div data-testid="pagination" className={s.paginationContainer}>
      <div className={s.buttonContainer}>
        <IconButton
          className={s.paginationButtons}
          icon="chevron-left"
          disabled={criteria.currentFilters.page === 1}
          data-testid="pagination-decrease"
          onClick={decreasePage}
        />
        <RawSelect
          className={s.miniPagination}
          components={{
            Menu: props => <components.Menu {...props} className={s.paginationMenu} />,
            IndicatorsContainer: props => (
              <components.IndicatorsContainer
                {...props}
                className={s.paginationIndicatorsContainer}
              />
            ),
          }}
          isDisabled={!ids.length}
          isSearchable={false}
          menuPlacement="top"
          name="pagination"
          onChange={changePage}
          options={options}
          value={criteria.currentFilters.page}
          placeholder="-"
        />
        <IconButton
          className={s.paginationButtons}
          disabled={isOnLastPage || !ids.length}
          icon="chevron-right"
          data-testid="pagination-increase"
          onClick={increasePage}
        />
        {ids.length ? (
          <OnlyInDesktopTablet>
            <div data-testid="pagination-results" className={s.resultsCounter}>{`${offset} - ${
              offset + ids.length - 1
            } of ${listingsCount}`}</div>
          </OnlyInDesktopTablet>
        ) : null}
      </div>
      <SortSelector />
    </div>
  );
}

export default connector(PaginationSection);
