import * as Yup from 'yup';

const validationSchema = t => {
  const number = Yup.number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable(true)
    .typeError(t('listing.rooms.form.numberFormat'));

  return Yup.object().shape({
    name: Yup.string().required(
      t('listing.rooms.form.requiredFieldValidation', {
        field: t('listing.rooms.form.basicInfo.name'),
      }),
    ),
    maximumCapacity: number
      .positive(t('listing.rooms.form.positiveIntegerFormat'))
      .integer(t('listing.rooms.form.positiveIntegerFormat')),
    ceilingHeight: number.positive(t('listing.rooms.form.positiveNumberFormat')),
    columnCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    windowCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    windowHeight: number.positive(t('listing.rooms.form.positiveNumberFormat')),
    windowSillHeight: number.positive(t('listing.rooms.form.positiveNumberFormat')),
    windowSides: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    vendingMachineCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    pingPongTableCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    poolTableCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    airHockeyTableCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    bathroomStallCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    bathroomSinkCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    refrigeratorCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    dishwasherCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    kitchenSinkCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    microwaveCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    ovenCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    stoveCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    kitchenTableCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    kitchenChairCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    deskCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    electricAdjustableDeskCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    deskChairCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    tableCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    conferenceTableCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    couchesCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    loungeChairCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    stoolCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
    workbenchCount: number
      .min(0, t('listing.rooms.form.nonNegativeIntegerFormat'))
      .integer(t('listing.rooms.form.nonNegativeIntegerFormat')),
  });
};
export default validationSchema;
