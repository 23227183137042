import { NearbyStop } from '@root/types';
import { isEmpty, round } from 'lodash';
import { useTranslation } from 'react-i18next';
import NySubwayLine from '@components/shared/NySubwayLine';
import s from './Transportation.module.less';

const processedDistance = (distance: number): string => round(distance, 2).toString().slice(1);

export type Props = {
  transitOptions: NearbyStop[];
};

const Transportation = ({ transitOptions = [] }: Props) => {
  const { t } = useTranslation('building');
  return isEmpty(transitOptions) ? null : (
    <section className={s.container}>
      <h3 className="font-title·text-text-primary" data-qa-testid="transportation-heading">
        {t('transportation')}
      </h3>
      <h4 className="text-text-primary font-body-large">{t('nearbyTrains')}</h4>
      <ul className={s.transitList}>
        {transitOptions.map(({ lines, distanceInMiles, stopName }, index) => (
          /* eslint-disable react/no-array-index-key */
          <li key={index} className={s.subwayLine}>
            <span className={s.lines}>
              {lines.map((line, idx) => (
                /* eslint-disable-next-line react/jsx-no-literals */
                <NySubwayLine line={line} key={`${line}-${idx}`} />
                /* esline-enable react/no-array-index-key */
              ))}
            </span>
            <strong className={s.distance}>
              {t('distance', {
                magnitude: processedDistance(distanceInMiles),
              })}
            </strong>
            <span className={s.stopName}>{t('stop', { stopName })}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Transportation;
