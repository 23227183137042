import actions from '..';
import savedActions from '.';

const loadSavedSearchesPage = () => async dispatch => {
  await dispatch(actions.setSavedSearchesPage());
  dispatch(savedActions.fetchSavedSearches());
  dispatch(savedActions.savedSearchPageLoaded());
};

export default loadSavedSearchesPage;
