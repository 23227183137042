import { createContext, useContext } from 'react';
import {
  AdminMicrosite,
  AdminMicrositeContentBlock,
  AdminMicrositePage,
  GenericContentBlock,
  MicrositeLayouts,
  MicrositePageSlugs,
} from '@root/types';
import { ColorGroup } from '@components/shared/ColorPicker/ColorPickerColorGroup';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { TFunction } from 'react-i18next';
import { PreviewSection } from './EditMicrosite';

const htmlWithContentSchema = (message: string) =>
  z.string().refine(
    value => {
      // jsdom doesn't support DOMParser well, so we need to skip this validation in tests
      if (process.env.NODE_ENV === 'test') {
        return true;
      }

      const parser = new DOMParser();
      const documentWithBodyOfValue = parser.parseFromString(value, 'text/html');

      return documentWithBodyOfValue.body.innerText.length > 0;
    },
    { message },
  );

export const micrositeFormSchema = (t: TFunction) =>
  z.object({
    font: z.string({
      invalid_type_error: t('microsite.font.missing'),
      required_error: t('microsite.font.missing'),
    }),
    tenantPortalUrl: z.string().url(t('microsite.invalidUrl')).optional(),
    tiktokUrl: z.string().url(t('microsite.invalidUrl')).optional(),
    facebookUrl: z.string().url(t('microsite.invalidUrl')).optional(),
    linkedinUrl: z.string().url(t('microsite.invalidUrl')).optional(),
    twitterUrl: z.string().url(t('microsite.invalidUrl')).optional(),
    instagramUrl: z.string().url(t('microsite.invalidUrl')).optional(),
    footerLogoLink: z.string().url(t('microsite.invalidUrl')).optional(),
    customAddressHtml: htmlWithContentSchema(t('microsite.customAddressText.missing')),
  });

export const micrositeFormValidationSchema = (t: TFunction) =>
  toFormikValidationSchema(micrositeFormSchema(t));

export const SECTIONS = [
  'sitewide',
  'homeHeader',
  'homeBuildingOverview',
  'homeContentBlock',
  'homeGoogleMap',
  'nav',
  'footer',
  'featuresContentBlock',
  'featuresAmenities',
  'featuresCertifications',
  'availability',
  'contactLeasingContacts',
  'contactContentBlock',
  'gallery',
] as const;
export const STATUSES = [
  'onboarding',
  'ready_for_approval',
  'approved_domain_setup',
  'live',
  'inactive',
  'retired',
] as const;
export type Section = (typeof SECTIONS)[number] | string;

export const MicrositeFormContext = createContext<{
  getIsOpen: (section: Section) => boolean;
  setOpen: (section: Section) => void;
  setClosed: (section: Section) => void;
  colorGroups: Array<ColorGroup>;
  changeFontColorWithColorChange: (font: string) => (color: string, prevColor: string) => void;
  microsite: AdminMicrosite;
  sendPreviewTo: (args: Partial<PreviewSection>) => void;
  resetKey: number;
  hasVideo: boolean;
  buildingId: string;
  refetch: () => void;
}>({
  getIsOpen: () => false,
  setOpen: () => {},
  setClosed: () => {},
  colorGroups: [],
  changeFontColorWithColorChange: () => () => {},
  microsite: {} as any,
  sendPreviewTo: () => {},
  resetKey: 0,
  hasVideo: false,
  buildingId: '',
  refetch: () => {},
});

export const MicrositeFormContextProvider = MicrositeFormContext.Provider;
export const useMicrositeFormContext = () => useContext(MicrositeFormContext);

export const isGenericContentBlock = (block: AdminMicrositeContentBlock) => {
  return block.type === 'AR::MicrositeGenericContentBlock';
};

export const getNewContentBlocks = ({
  contentBlocks,
  destinationPageId,
  destinationPageSlug,
  destinationIndex,
  movedContentBlockId,
}: {
  contentBlocks: AdminMicrositeContentBlock[];
  destinationPageId: string;
  destinationPageSlug: MicrositePageSlugs;
  destinationIndex: number;
  movedContentBlockId: string;
}): AdminMicrositeContentBlock[] => {
  const contentBlock: AdminMicrositeContentBlock = {
    ...(contentBlocks.find(
      block => block.id === movedContentBlockId,
    ) as AdminMicrositeContentBlock),
    pageId: destinationPageId,
    pageSlug: destinationPageSlug,
  };
  const contentBlocksOfDestination = contentBlocks
    .filter(block => block.pageId === destinationPageId && block.id !== movedContentBlockId)
    .sort((a, b) => a.position - b.position);
  const newContentBlocksOfDestination: AdminMicrositeContentBlock[] = [
    ...contentBlocksOfDestination.slice(0, destinationIndex),
    contentBlock,
    ...contentBlocksOfDestination.slice(destinationIndex),
  ].map((block, index) => ({ ...block, position: index * 10 }));

  const uneffectedContentBlocks = contentBlocks.filter(
    block => block.pageId !== destinationPageId && block.id !== movedContentBlockId,
  );
  return [...uneffectedContentBlocks, ...newContentBlocksOfDestination];
};

export const getNewGenericContentBlocks = ({
  genericContentBlocks,
  contentBlockId,
  destinationPageSlug,
}: {
  genericContentBlocks: GenericContentBlock[];
  contentBlockId: string;
  destinationPageSlug: string;
}): GenericContentBlock[] => {
  const indexOfChangedBlock = genericContentBlocks.findIndex(gcb => gcb.id === contentBlockId);
  return [
    ...genericContentBlocks.slice(0, indexOfChangedBlock),
    { ...genericContentBlocks[indexOfChangedBlock], page: destinationPageSlug },
    ...genericContentBlocks.slice(indexOfChangedBlock + 1),
  ];
};

export const isSinglePageLayout = (layout: MicrositeLayouts) => {
  return layout === 'single_page';
};

export const removeBlockFromGenericContentBlocks = (
  genericContentBlocks: GenericContentBlock[],
  genericContentBlockId: string,
) => {
  return genericContentBlocks!.filter(block => block.id !== genericContentBlockId);
};

export const removeBlockFromContentBlocks = (
  contentBlocks: AdminMicrositeContentBlock[],
  contentBlock: AdminMicrositeContentBlock,
) => {
  return contentBlocks
    .filter(block => block.id !== contentBlock.id)
    .map(block => {
      if (block.pageSlug === contentBlock.pageSlug && block.position > contentBlock.position) {
        return { ...block, position: block.position - 10 };
      }
      return block;
    });
};

export const getPageForContentBlock = (
  pages: AdminMicrositePage[],
  contentBlock: AdminMicrositeContentBlock,
) => {
  return pages.find(page => page.slug === contentBlock.pageSlug);
};

export const decreaseMaxContentBlockPositionOfPage = (
  pages: AdminMicrositePage[],
  page: AdminMicrositePage,
) => {
  const updatedPage = {
    ...page,
    maxContentBlockPosition: page!.maxContentBlockPosition - 10,
  };
  return pages.map(p => (p === page ? updatedPage : p));
};

export const sanitizeDomain = (domain: string) => {
  return domain.replace(/^(https?:\/\/(www\.)?|http:\/\/(www\.)?|www\.)/, '').toLowerCase();
};

export const isPageVisible = (values: AdminMicrosite, page: MicrositePageSlugs) => {
  const {
    genericContentBlocks,
    certificationsSectionIsVisible,
    amenitiesSectionIsVisible,
    gallerySectionIsVisible,
    contactsSectionIsVisible,
    availabilitySectionIsVisible,
  } = values;

  const visibleGenericContentBlocks = genericContentBlocks.filter(
    value => value.page === page && value.visible,
  );

  switch (page) {
    case 'features':
      return (
        visibleGenericContentBlocks.length > 0 ||
        certificationsSectionIsVisible ||
        amenitiesSectionIsVisible
      );
    case 'gallery':
      return visibleGenericContentBlocks.length > 0 || gallerySectionIsVisible;
    case 'availability':
      return visibleGenericContentBlocks.length > 0 || availabilitySectionIsVisible;
    case 'contact':
      return visibleGenericContentBlocks.length > 0 || contactsSectionIsVisible;
    case 'home':
      return true;
    default:
      return visibleGenericContentBlocks.length > 0;
  }
};
