import {
  Locale,
  MinMaxArea,
  ConvertableArea,
  ConvertableMoneyPerAreaPerPeriod,
  ConvertableMoneyPerPeriod,
  Currency,
} from '@root/types';
import useEnv from '@shared/useEnv';
import { useTranslation } from 'react-i18next';
import { reformatDateString } from '@shared/dateUtils';
import FlexibleSizeArea from '@components/shared/FlexibleSizeArea';
import { dateFormat } from '@shared/TranslationHelpers/formatDate';
import { parse } from 'date-fns';
import { camelCase } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ListingAttribute from './ListingAttribute';
import s from './ListingAttributes.module.less';
import AskingRent from './AskingRent';

// Because this is used for the preview version the condition,
// lease type and description can be different
type Props = {
  askingRentInMoneyPerAreaPerPeriod: ConvertableMoneyPerAreaPerPeriod | null;
  askingRentInMoneyPerPeriod: ConvertableMoneyPerPeriod | null;
  availableToday: boolean;
  condition: string | undefined | null;
  currencyCode: Currency;
  dateAvailable: string | undefined | null;
  isExternal?: boolean;
  leaseType: string;
  maxAskingRentInMoneyPerAreaPerPeriod?: ConvertableMoneyPerAreaPerPeriod | null;
  maxAskingRentInMoneyPerPeriod?: ConvertableMoneyPerPeriod | null;
  minAskingRentInMoneyPerAreaPerPeriod?: ConvertableMoneyPerAreaPerPeriod | null;
  minAskingRentInMoneyPerPeriod?: ConvertableMoneyPerPeriod | null;
  minMaxArea: MinMaxArea | null;
  readOnly?: boolean;
  showNullAskingRent?: boolean;
  size: ConvertableArea | null;
  tourbookListingId: string;
};

const formatArea = ({ area, flexArea }: { area: String; flexArea: MinMaxArea }) => {
  return (
    <span>
      {area}
      {flexArea ? <FlexibleSizeArea minMaxArea={flexArea} /> : null}
    </span>
  );
};

const ListingAttributes = ({
  askingRentInMoneyPerAreaPerPeriod,
  askingRentInMoneyPerPeriod,
  availableToday,
  condition,
  currencyCode,
  dateAvailable,
  isExternal = false,
  leaseType,
  maxAskingRentInMoneyPerAreaPerPeriod,
  maxAskingRentInMoneyPerPeriod,
  minAskingRentInMoneyPerAreaPerPeriod,
  minAskingRentInMoneyPerPeriod,
  minMaxArea,
  readOnly = false,
  showNullAskingRent = false, // Remove this field with ff 'tourbook-asking-rent' removal.
  size,
  tourbookListingId,
}: Props) => {
  const { t } = useTranslation('tourbook');
  const { locale } = useEnv();
  const flags = useFlags();

  const postListingPriceValue =
    size?.magnitude && size?.magnitude > 0 ? askingRentInMoneyPerAreaPerPeriod : 0;

  const deprecatedAskingRent =
    (askingRentInMoneyPerAreaPerPeriod && askingRentInMoneyPerPeriod) || showNullAskingRent ? (
      <div>
        <>
          <ListingAttribute
            label={t('listingCard.baseRentTitle')}
            value={postListingPriceValue}
            format={({
              magnitude,
              units: { money, area, period },
            }: ConvertableMoneyPerAreaPerPeriod) =>
              t('units:pricePerAreaPerPeriod', {
                pricePerAreaPerPeriod: {
                  magnitude: Math.round(magnitude!),
                  area,
                  currency: money,
                  period,
                  abbreviatedPeriod: true,
                },
              })
            }
          />
          <ListingAttribute
            label=""
            value={askingRentInMoneyPerPeriod}
            valueClassName="font-body-small text-black-055"
            format={({ magnitude, units: { money, period } }: ConvertableMoneyPerPeriod) =>
              t('units:pricePerPeriod', {
                pricePerPeriod: {
                  magnitude: Math.round(magnitude),
                  currency: money,
                  period,
                  abbreviatedPeriod: true,
                },
              })
            }
          />
        </>
      </div>
    ) : (
      <ListingAttribute
        label={t('listingCard.baseRentTitle')}
        value={t('listingCard.nullBaseRent')}
      />
    );

  return (
    <>
      <div className={s.listingAttributes}>
        <ListingAttribute
          label={t('listingCard.size')}
          value={{
            area: size ? t('units:area', { area: size }) : '-',
            flexArea: minMaxArea,
          }}
          format={formatArea}
        />
        {flags['tourbook-asking-rent'] ? (
          <AskingRent
            askingRentInMoneyPerAreaPerPeriod={askingRentInMoneyPerAreaPerPeriod}
            askingRentInMoneyPerPeriod={askingRentInMoneyPerPeriod}
            currencyCode={currencyCode}
            isExternal={isExternal}
            maxAskingRentInMoneyPerAreaPerPeriod={maxAskingRentInMoneyPerAreaPerPeriod}
            maxAskingRentInMoneyPerPeriod={maxAskingRentInMoneyPerPeriod}
            minAskingRentInMoneyPerAreaPerPeriod={minAskingRentInMoneyPerAreaPerPeriod}
            minAskingRentInMoneyPerPeriod={minAskingRentInMoneyPerPeriod}
            readOnly={readOnly}
            size={size}
            tourbookListingId={tourbookListingId}
          />
        ) : (
          deprecatedAskingRent
        )}
        {availableToday ? (
          <ListingAttribute
            label={t('listingCard.available')}
            value={t('common:listing.immediateAvailability') as string}
            valueClassName="capitalize"
          />
        ) : (
          <ListingAttribute
            label={t('listingCard.available')}
            value={dateAvailable}
            format={(initialDate: string) => {
              if (!initialDate) return '-';
              const asDate = parse(initialDate, 'yyyy-MM-dd', new Date());
              if (!(asDate instanceof Date && !Number.isNaN(asDate.getTime()))) return '-';
              return reformatDateString({
                value: initialDate,
                beforeFormat: 'yyyy-MM-dd',
                afterFormat: dateFormat(locale as Locale),
              });
            }}
            valueClassName="capitalize"
          />
        )}
        <ListingAttribute
          label={t('listingCard.condition')}
          value={condition ? t(`spaceConditions:${camelCase(condition)}`) : '-'}
          valueClassName="capitalize"
        />
        <ListingAttribute
          label={t('listingCard.leaseType')}
          value={leaseType}
          format={lT => t(`listingCard.leaseTypes.${lT}`)}
          valueClassName="capitalize"
        />
      </div>
    </>
  );
};

export default ListingAttributes;
