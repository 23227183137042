import { useState } from 'react';
import {
  UploadApiResponse as CloudinaryResponse,
  UploadApiErrorResponse as CloudinaryErrorResponse,
} from 'cloudinary';
import { Spinner } from '@components/shared';
import api from '@shared/api';
import useEnv from '@shared/useEnv';
import { Field } from 'formik';
import Input from '../../RawInput';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';
import s from '../../RawInput/Input.module.less';

export type Props = {
  name: string;
  onLoading: (boolean) => void;
  onReady: (string) => void;
  onError: (string) => void;
} & Omit<FieldWrapperProps, 'children'>;

const CloudinaryFileInput = ({
  name,
  onLoading,
  onReady,
  onError,
  labelClass,
  containerClass,
  labelText,
  required,
}: Props) => {
  const { cloudinaryCloud, cloudinaryUploadPreset } = useEnv();
  const [loading, setLoading] = useState(false);

  const uploadToCloudinary = async event => {
    setLoading(true);
    onLoading(true);

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', cloudinaryUploadPreset || '');

    const route = `https://api.cloudinary.com/v1_1/${cloudinaryCloud}/image/upload`;
    const response = await api.fetch(route, { method: 'POST', body: formData });

    setLoading(false);
    onLoading(false);

    if (response.ok) {
      const json: CloudinaryResponse = await response.json();
      onReady(json.public_id);
    } else {
      const json: CloudinaryErrorResponse = await response.json();
      onError(json.error.message);
    }
  };

  return (
    <FieldWrapper {...{ name, labelClass, containerClass, labelText, required }}>
      <Field name={name}>
        {({ meta }) => (
          <>
            <Input
              className={s.cloudinaryFileInput}
              type="file"
              name={name}
              onChange={uploadToCloudinary}
              hasError={loading || (meta.touched && meta.error)}
            />
            {loading ? <Spinner /> : ''}
          </>
        )}
      </Field>
    </FieldWrapper>
  );
};

export default CloudinaryFileInput;
