import analyticsActions from './analyticsActions';
import type { Action as AnalyticsActions, Thumbnail as ThumbnailType } from './analyticsActions';
import listingSharePageLoad from './listingSharePageLoad';
import type { Actions as SharePageLoadActions } from './listingSharePageLoad';

export type Actions = AnalyticsActions | SharePageLoadActions;
export type Thumbnail = ThumbnailType;

export default {
  ...analyticsActions,
  listingSharePageLoad,
};
