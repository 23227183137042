import { BuildingAmenities, ListingAmenities } from '@root/types';
import { map } from 'lodash';

export const getTranslationKey = (name: string, value: boolean | string | number): string => {
  const base = 'common:amenities';

  if (typeof value === 'string') {
    return `${base}.${name}.${value}`;
  }

  return `${base}.${name}`;
};

const makeAmenity = (value, name) => {
  if (value === 'no' || value === 'No') {
    return { name, value: false };
  }
  return { name, value };
};

const orderAmenities = (amenities, translate) =>
  amenities.sort((a, b) => {
    const compareA = translate(getTranslationKey(a.name, a.value));
    const compareB = translate(getTranslationKey(b.name, b.value));

    if (compareA > compareB) return 1;
    if (compareA < compareB) return -1;
    return 0;
  });

export const filterAndOrderAmenities = (
  amenitiesObject: Partial<ListingAmenities | BuildingAmenities>,
  translate: (amenitykey: string) => string,
) => {
  const amenities = map(amenitiesObject, makeAmenity);
  const filteredAmenities = amenities.filter(amenity => amenity.value);
  return orderAmenities(filteredAmenities, translate);
};

export default { filterAndOrderAmenities };
