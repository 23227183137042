import { Address, BackendPhotoModels } from '@root/types';
import bugsnagClient from '@shared/bugsnagClient';
import { useTranslation } from 'react-i18next';

export type Options = {
  roomName?: string;
  companyName?: string;
  marketName?: string;
};

export type AltTextTypes =
  | BackendPhotoModels
  | 'videoThumbnailPhoto'
  | '3dTourThumbnailPhoto'
  | 'marketPhoto';

function assertUnreachable(type: 'companyLogo' | 'tourbookHeaderPhoto') {
  // eslint-disable-next-line no-console
  console.error(`This should be unreachable. The type is ${type}`);
  bugsnagClient.notify(`There is a photo type that doesnt have alt text defined for: ${type}`);
  return '';
}

export default function useAltText({ address }: { address?: Address } = {}) {
  const { street, buildingName, floorAndSuite } = address || {};
  const { t } = useTranslation();

  const getAltTextForProfilePhoto = (firstName: string, lastName: string) => {
    return t('altText:profilePhoto', { firstName, lastName });
  };

  const getAltTextForCompanyLogo = (companyName?: string) => {
    if (companyName) {
      return t('altText:companyLogo', { companyName });
    }
    return t('altText:logoWithNoCompany', { companyName });
  };

  const getAltTextForTourbookClientCompanyLogo = (companyName?: string | null) => {
    if (companyName) {
      return t('altText:companyLogo', { companyName });
    }
    return t('altText:tourbookClientLogoWithNoCompanyName');
  };

  const getAltTextFor3DTourThumbnail = (photoText: string) => {
    return t('altText:3dTourThumbnail', { photoText });
  };

  const getAltTextForListingAgent = (name: string) => {
    return t('altText:listingAgentAvatar', { name });
  };

  const getAltTextForTourbookHeader = (tourbookName: string) =>
    t('altText:tourbookHeader', { tourbookName });

  const getAltTextForUserCompanyLogo = (userName: string) => t('altText:userCompany', { userName });

  const getAltTextForVideo = (sourcePage: string, companyName?: string) => {
    switch (sourcePage) {
      case 'listing page':
        return buildingName
          ? t('altText:listingVideoWithbuildingName', {
              listingName: floorAndSuite,
              displayAddress: street,
              buildingName,
            })
          : t('altText:listingVideoWithNoBuildingName', {
              listingName: floorAndSuite,
              displayAddress: street,
            });
      case 'building page':
        return t('altText:buildingVideo', { buildingName, displayAddress: street });
      case 'brand page':
        return t('altText:landlordVideo', { companyName });
      default:
        return '';
    }
  };

  const getAltText = ({
    type,
    options = {},
  }: {
    type: AltTextTypes;
    options?: Options;
  }): string | undefined => {
    const { roomName, companyName, marketName } = options;

    switch (type) {
      case 'listingPhoto':
        return t(
          `altText:${
            buildingName
              ? 'listingPhotoWithBuildingNameAndWithNoRoom'
              : 'listingPhotoWithNoBuildingNameAndWithNoRoom'
          }`,
          {
            listingName: floorAndSuite,
            buildingName,
            displayAddress: street,
          },
        );
      case 'buildingPhoto':
        return buildingName
          ? t('altText:buildingPhotoWithName', {
              buildingName,
              displayAddress: street,
            })
          : t('altText:buildingPhotoWithNoName', {
              displayAddress: street,
            });
      case 'buildingLettermarkLogo':
        return buildingName
          ? t('altText:buildingLettermarkLogoWithName', { buildingName })
          : t('altText:buildingLettermarkLogoWithNoName');
      case 'listingRoomPhoto':
        return t(
          `altText:${
            buildingName
              ? 'listingPhotoWithBuildingNameAndWithRoom'
              : 'listingPhotoWithNoBuildingNameAndWithRoom'
          }`,
          {
            roomName,
            listingName: floorAndSuite,
            buildingName,
            displayAddress: street,
          },
        );
      case 'floorPlan':
        return buildingName
          ? t('altText:floorPlanWithBuildingName', {
              listingName: floorAndSuite,
              buildingName,
              displayAddress: street,
            })
          : t('altText:floorPlanWithNoBuildingName', {
              buildingName,
              listingName: floorAndSuite,
              displayAddress: street,
            });
      case 'staticMap':
        return buildingName
          ? t('altText:staticMapWithBuildingName', {
              buildingName,
              displayAddress: street,
            })
          : t('altText:staticMapWithNoBuildingName', { displayAddress: street });
      case 'videoThumbnailPhoto':
        return buildingName
          ? t('altText:listingVideoWithbuildingName', {
              listingName: floorAndSuite,
              displayAddress: street,
              buildingName,
            })
          : t('altText:listingVideoWithNoBuildingName', {
              listingName: floorAndSuite,
              displayAddress: street,
            });
      case '3dTourThumbnailPhoto':
        return buildingName
          ? t('altText:3dTourThumbnailWithBuildingName', {
              listingName: floorAndSuite,
              displayAddress: street,
              buildingName,
            })
          : t('altText:3dTourThumbnailWithNoBuildingName', {
              listingName: floorAndSuite,
              displayAddress: street,
            });
      case 'landlordPhoto': {
        return t('altText:landlordPhoto', { companyName });
      }
      case 'marketPhoto': {
        return t('altText:marketPhoto', { marketName });
      }
      default:
        return assertUnreachable(type);
    }
  };

  return {
    getAltText,
    getAltTextForCompanyLogo,
    getAltTextFor3DTourThumbnail,
    getAltTextForListingAgent,
    getAltTextForProfilePhoto,
    getAltTextForTourbookHeader,
    getAltTextForUserCompanyLogo,
    getAltTextForTourbookClientCompanyLogo,
    getAltTextForVideo,
  };
}
