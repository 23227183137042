import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { RawInput, RawLabel } from '@components/shared/forms';
import { useState } from 'react';
import useSuccessCheckmark from '@shared/useSuccessCheckmark';

import s from './Form.module.less';

type Props = {
  onPosterUpdate: (posterStartOffset: number | null) => PromiseLike<{ ok: boolean }>;
  initialOffsetInSeconds: number | null;
  onSaveSuccess: (args: { message?: string }) => void;
};

const matchTimeRegex = (input: string) => {
  return input.match(/^(\d*?):?(\d*\.?\d*)$/);
};

const formatWithMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const minutesString = `${minutes <= 9 ? '0' : ''}${minutes}`;
  const remainingSeconds = seconds % 60;
  const roundedRemainingSeconds = Number.isInteger(remainingSeconds)
    ? remainingSeconds
    : remainingSeconds.toFixed(2);
  const secondsString = `${remainingSeconds <= 9 ? '0' : ''}${roundedRemainingSeconds}`;
  return `${minutesString}:${secondsString}`;
};

const parseIntoSeconds = (input: string) => {
  const matches = matchTimeRegex(input) || [];
  const minutes = parseFloat(matches[1]) || 0;
  const seconds = parseFloat(matches[2]) || 0.0;
  const roundedSeconds = Math.round(seconds * 100) / 100;

  return minutes * 60 + roundedSeconds;
};

const VideoThumbnailSetter = ({ onPosterUpdate, initialOffsetInSeconds, onSaveSuccess }: Props) => {
  const { t } = useTranslation('admin');

  const initialFormattedOffset = formatWithMinutes(initialOffsetInSeconds || 0);
  const [formattedOffset, setFormattedOffset] = useState(initialFormattedOffset);
  const [changesAreUnsaved, setChangesAreUnsaved] = useState(false);
  const { showCheckmark, Checkmark, checkmarkProps } = useSuccessCheckmark();

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    if (!matchTimeRegex(input)) {
      return;
    }

    setFormattedOffset(input);
    setChangesAreUnsaved(true);
  };

  const saveChanges = async (newOffset: string) => {
    if (!changesAreUnsaved) {
      return;
    }

    const resp = await onPosterUpdate(parseIntoSeconds(newOffset));
    if (resp.ok) {
      setChangesAreUnsaved(false);
      onSaveSuccess({});
      showCheckmark();
    } else {
      notification.error({ message: t('landlord.videoThumbnailTimestampUpdateFailure') });
    }
  };

  const reformat = (input: string) =>
    setFormattedOffset(formatWithMinutes(parseIntoSeconds(input)));

  return (
    <div className={s.thumbnailTimestamp}>
      <div className={s.thumbnailTimestampContainer}>
        <RawInput
          value={formattedOffset}
          onChange={onChange}
          onBlur={(e: React.FormEvent<HTMLInputElement>) => {
            saveChanges(e.currentTarget.value);
            reformat(e.currentTarget.value);
          }}
        />
        <Checkmark {...checkmarkProps} className={s.checkmark} />
      </div>
      <RawLabel className={s.thumbnailTimestampLabel} name="videoThumbnailTimestampMessage">
        {t('landlord.videoThumbnailTimestampMessage')}
      </RawLabel>
    </div>
  );
};

export default VideoThumbnailSetter;
