import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { startCase } from 'lodash';
import { Row, Input, Form } from 'antd';
import routes from '@root/routes';
import { AdminLandlordSummary } from '@root/types';
import { Breadcrumbs } from '@components/shared';
import useIndexPage from '@shared/useIndexPage';
import addKeyTo from '@shared/addKeyTo';
import AdminTable from '@components/shared/AdminTable';
import Container from '@components/shared/Admin/Container';
import PaginationRow from '@components/shared/PaginationRow';
import Button from '@components/shared/V2Button';
import { Tag } from '@viewthespace/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Layout from '../../Layout/Layout';

const IndexPage = () => {
  const { t } = useTranslation('admin');
  const history = useHistory();
  const flags = useFlags();

  const {
    models: landlords,
    isFetching,
    query,
    setQuery,
    submitQuery,
    pagination,
    changePage,
  } = useIndexPage<AdminLandlordSummary>({
    modelsKey: 'landlords',
    apiBaseRoute: routes.api.admin.landlords,
    baseRoute: routes.admin.landlords,
  });

  return (
    <Layout>
      <Breadcrumbs
        className="mb-1"
        items={[{ content: t('breadcrumbs.admin') }, { content: t('breadcrumbs.landlords') }]}
      />
      <Container
        title={t('landlord.title')}
        extra={
          <Button type="primary" onClick={() => history.push(routes.admin.landlordCreate)}>
            {t('landlord.addLandlord')}
          </Button>
        }
      >
        <Form onFinish={submitQuery}>
          <Row>
            <Form.Item>
              <Input
                placeholder={t('landlord.searchPlaceholder')}
                style={{ width: 200, marginRight: 20 }}
                name="query"
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
              <Button
                htmlType="submit"
                type="primary"
                onClick={submitQuery}
                icon="magnifying-glass"
              >
                {t('common.searchButton')}
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <AdminTable
          loading={isFetching}
          dataSource={addKeyTo(landlords)}
          columns={[
            flags['outreach.landlord-company-connection-severed']
              ? {
                  title: t('landlord.name'),
                  dataIndex: 'name',
                }
              : {
                  title: t('landlord.name'),
                  dataIndex: 'companyName',
                },
            {
              title: '',
              dataIndex: 'demo',
              render: (demo: boolean) =>
                demo && <Tag testId="demoTag" color="informational" text={t('landlord.demoTag')} />,
            },
            {
              title: t('landlord.status'),
              dataIndex: 'status',
              render: status => startCase(status),
            },
            {
              title: t('landlord.actions'),
              render: (landlord: AdminLandlordSummary) => (
                <Link to={routes.admin.landlordEdit(landlord.id)}>{t('landlord.edit')}</Link>
              ),
            },
          ]}
        />
        <PaginationRow {...pagination} changePage={changePage} />
      </Container>
    </Layout>
  );
};

export default IndexPage;
