import { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Table, Row, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import routes from '@root/routes';
import addKeyTo from '@shared/addKeyTo';
import Button from '@components/shared/V2Button';
import { Breadcrumbs } from '@components/shared';
import Container from '@components/shared/Admin/Container';
import { useBreadcrumbs } from '@components/shared/withBreadcrumbs';
import PaginationRow from '@components/shared/PaginationRow';
import useIndexPage from '@shared/useIndexPage';
import Layout from '../../Layout/Layout';

export const IndexPage = () => {
  const { t } = useTranslation('admin');
  const history = useHistory();
  const {
    models: companies,
    isFetching,
    query,
    setQuery,
    submitQuery,
    pagination,
    changePage,
  } = useIndexPage({
    modelsKey: 'companies',
    apiBaseRoute: routes.api.admin.companiesPlain,
    baseRoute: routes.admin.companies,
  });

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([t('breadcrumbs.admin'), t('breadcrumbs.tenants')]);
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { title: t('tenants.nameColumnHeader'), dataIndex: 'name' },
    {
      title: t('tenants.logoColumnHeader'),
      dataIndex: 'logo',
      render: logo => (
        <figure className="h-5">
          <img src={logo.path} alt={logo.description} className="h-full w-auto" />
        </figure>
      ),
    },
    {
      title: t('tenants.actionsColumnHeader'),
      dataIndex: 'id',
      render: id => (
        <>
          <Link to={routes.admin.company(id)}>{t('tenants.editTenant')}</Link>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <Breadcrumbs
        className="mb-1"
        items={[{ content: t('breadcrumbs.admin') }, { content: t('breadcrumbs.tenants') }]}
      />
      <Container
        title={t('tenants.title')}
        extra={
          <Button onClick={() => history.push(routes.admin.companyCreate)}>
            {t('tenants.addTenantButton')}
          </Button>
        }
      >
        <Form onSubmit={submitQuery}>
          <Row>
            <Form.Item>
              <Input
                placeholder={t('tenants.searchPlaceholder')}
                style={{ width: 200, marginRight: 20 }}
                name="user-search"
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
              <Button onClick={submitQuery} icon="magnifying-glass">
                {t('common.searchButton')}
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={addKeyTo(companies)}
          pagination={false}
        />
        <PaginationRow {...pagination} changePage={changePage} />
      </Container>
    </Layout>
  );
};

IndexPage.propTypes = {};

export default IndexPage;
