import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import useEnvVariable from '@root/shared/useEnv';
import MarketingMediaForm from '../MarketingMediaForm';
import {
  uploadToCloudinary,
  handleResponse,
  deleteMedia,
  deletePhoto,
  updatePhoto,
} from '../helper';
import { BuildingMarketingMedia } from '../types';

const HeroVideo = ({ data }: { data: BuildingMarketingMedia[] }) => {
  const { t } = useTranslation('admin');
  const { cloudinaryUploadPreset, cloudinaryCloud } = useEnvVariable();
  const showCancelUploadMediaButton = !data[0].cloudinaryId && !data[0].title && data.length === 1;

  const { setValues, values } = useFormikContext();

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    const cloudinaryResponse = await uploadToCloudinary(
      files[0],
      cloudinaryUploadPreset || '',
      cloudinaryCloud || '',
      'video',
    );

    handleResponse(
      cloudinaryResponse,
      t('media.videoUploadSuccess'),
      t('media.videoUploadFailure'),
    );

    if (!cloudinaryResponse.secure_url) return;

    const updatedFormikValues = await updatePhoto(
      values,
      'heroVideo',
      1,
      'hero_video',
      cloudinaryResponse.secure_url,
    );

    setValues(updatedFormikValues);
  };

  const onDelete = async (order: number) => {
    const deleteMessage = t('marketingMedia.heroVideo.deleteSuccessMessage');
    const updatedFormikValues = await deleteMedia(
      values,
      'heroVideo',
      order,
      deleteMessage,
      'hero_video',
    );

    setValues(updatedFormikValues);
  };

  const onVideoDelete = async (order: number) => {
    const updatedFormikValues = await deletePhoto(values, 'heroVideo', order);

    setValues(updatedFormikValues);
  };

  return (
    <MarketingMediaForm
      data={data}
      onUpload={handleFileUpload}
      onPhotoDelete={onVideoDelete}
      showCancelUploadMediaButton={showCancelUploadMediaButton}
      onDelete={onDelete}
      identifier="heroVideo"
      showDesciption={false}
      selected="video"
      accept=".mov,.mp4,.avi,.wmv,.webm,.m4v"
    />
  );
};

export default HeroVideo;
