import { ContentShootRequest } from '@root/types';
import Header from './Header';
import FirstToMarket from './FirstToMarket';
import Availability from './Availability';
import PropertyContentContact from './PropertyContentContact';
import Directions from './Directions';

type Props = {
  contentShootRequest: ContentShootRequest;
};

const ContentShootDetails = ({ contentShootRequest }: Props) => {
  const {
    requester,
    requestedAt,
    availableForContentShoot,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhone,
    directions,
    additionalComments,
    firstToMarket,
  } = contentShootRequest;

  return (
    <>
      <Header
        requesterName={`${requester.firstName} ${requester.lastName}`}
        requesterEmail={requester.email}
        requestedAt={requestedAt}
      />
      {firstToMarket === null ? null : <FirstToMarket firstToMarket={firstToMarket} />}
      <Availability availableForContentShoot={availableForContentShoot} />
      <PropertyContentContact
        firstName={contactFirstName}
        lastName={contactLastName}
        email={contactEmail}
        phone={contactPhone}
      />
      <Directions directions={directions} additionalComments={additionalComments} />
    </>
  );
};

export default ContentShootDetails;
