import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import Divider from '@components/shared/Divider';
import { Select } from '@components/shared/forms';
import Checkbox from '@components/shared/forms/Checkbox';
import css from './Utilities.module.less';

const Utilities = React.forwardRef((_props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
  const { t } = useTranslation('admin');
  const functionalLighting = [
    {
      label: t('listing.rooms.form.utilities.functionalLighting.fluorescentLighting'),
      value: 'fluorescent_lighting',
    },
    {
      label: t('listing.rooms.form.utilities.functionalLighting.ledLighting'),
      value: 'led_lighting',
    },
    {
      label: t('listing.rooms.form.utilities.functionalLighting.ledLightingFluorescentLighting'),
      value: 'led_lighting_fluorescent_lighting',
    },
    {
      label: t('listing.rooms.form.utilities.functionalLighting.spotLighting'),
      value: 'spot_lighting',
    },
  ];

  const decorativeLighting = [
    {
      label: t('listing.rooms.form.utilities.decorativeLighting.industrialLighting'),
      value: 'industrial_lighting',
    },
    {
      label: t('listing.rooms.form.utilities.decorativeLighting.modernLighting'),
      value: 'modern_lighting',
    },
  ];
  const electric = [
    { label: t('listing.rooms.form.utilities.electric.inCeiling'), value: 'in_ceiling' },
    {
      label: t('listing.rooms.form.utilities.electric.inCounter'),
      value: 'in_counter',
    },
    { label: t('listing.rooms.form.utilities.electric.inFloor'), value: 'in_floor' },
    { label: t('listing.rooms.form.utilities.electric.inPillars'), value: 'in_pillars' },
    { label: t('listing.rooms.form.utilities.electric.inWall'), value: 'in_wall' },
  ];
  const heatType = [
    { label: t('listing.rooms.form.utilities.heatType.electric'), value: 'electric' },
    {
      label: t('listing.rooms.form.utilities.heatType.exposedRadiators'),
      value: 'exposed_radiators',
    },
    {
      label: t('listing.rooms.form.utilities.heatType.forcedAir'),
      value: 'forced_air',
    },
    {
      label: t('listing.rooms.form.utilities.heatType.radiantFlooring'),
      value: 'radiant_flooring',
    },
  ];

  return (
    <>
      <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-4x', 'u-p-bottom-2x')}>
        {t('listing.rooms.form.utilities.title')}
      </h2>
      <section className={css.listingRoomFormSection}>
        <div className={css.functionalLighting}>
          <Select
            labelText={t(`listing.rooms.form.utilities.functionalLighting.label`)}
            name="functionalLighting"
            options={functionalLighting}
            placeholder=""
          />
        </div>
        <div className={css.decorativeLighting}>
          <Select
            labelText={t(`listing.rooms.form.utilities.decorativeLighting.label`)}
            name="decorativeLighting"
            options={decorativeLighting}
            placeholder=""
          />
        </div>
        <div className={css.electric}>
          <Select
            labelText={t(`listing.rooms.form.utilities.electric.label`)}
            name="electric"
            options={electric}
            placeholder=""
          />
        </div>
        <div className={css.heatType}>
          <Select
            labelText={t(`listing.rooms.form.utilities.heatType.label`)}
            name="heatType"
            options={heatType}
            placeholder=""
          />
        </div>
        <div className={css.ventilation}>
          <Checkbox name="ventilation">
            <span>{t('listing.rooms.form.utilities.ventilation')}</span>
          </Checkbox>
        </div>
        <div className={css.hvac}>
          <Checkbox name="hvac">
            <span>{t('listing.rooms.form.utilities.hvac')}</span>
          </Checkbox>
        </div>
        <div className={css.airConditioning}>
          <Checkbox name="airConditioning">
            <span>{t('listing.rooms.form.utilities.airConditioning')}</span>
          </Checkbox>
        </div>
        <div className={css.overtimeAc}>
          <Checkbox name="overtimeAc">
            <span>{t('listing.rooms.form.utilities.overtimeAc')}</span>
          </Checkbox>
        </div>
        <div className={css.independentTemperatureControl}>
          <Checkbox name="independentTemperatureControl">
            <span>{t('listing.rooms.form.utilities.independentTemperatureControl')}</span>
          </Checkbox>
        </div>
      </section>
      <Divider />
    </>
  );
});

export default React.memo(Utilities);
