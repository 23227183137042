import fetchSavedSearches from './fetchSavedSearches';
import deleteSavedSearch from './deleteSavedSearch';
import analyticsActions from './analytics';
import updateSavedSearch from './updateSavedSearch';
import updateAllSavedSearchNotifications from './updateAllSavedSearchNotifications';
import toggleBuildingOwnerSave, {
  savedBuildingOwner,
  unsavedBuildingOwner,
} from './toggleBuildingOwnerSave';
import fetchSavedBuildingOwners, { receiveBuidingOwners } from './fetchSavedBuildingOwners';
import updateSavedBuildingOwner, {
  receiveUpdatedSavedBuildingOwner,
} from './updateSavedBuildingOwner';
import toggleSaveListing, { savedListing, unsavedListing } from './toggleSaveListing';
import fetchSavedListings, { receiveSavedListings } from './fetchSavedListings';
import loadSavedListingsPage from './loadSavedListingsPage';
import loadSavedBuildingOwnersPage from './loadSavedBuildingOwnersPage';
import loadSavedSearchesPage from './loadSavedSearchesPage';
import type { Actions as FetchSavedSearchActions } from './fetchSavedSearches';
import type { Actions as AnalyticsActions } from './analytics';
import type { Actions as UpdateSavedSearchActions } from './updateSavedSearch';
import type { Actions as updateAllSavedSearchNotificationsActions } from './updateAllSavedSearchNotifications';
import type { Actions as ToggleBuildingOwnerSaveActions } from './toggleBuildingOwnerSave';
import type { Actions as FetchSavedBuildingOwnersActions } from './fetchSavedBuildingOwners';
import type { Actions as UpdateSavedBuildingOwnerActions } from './updateSavedBuildingOwner';
import type { Actions as FetchSavedListingsActions } from './fetchSavedListings';
import type { Actions as ToggleSavedListingActions } from './toggleSaveListing';
import type { Actions as SetActiveSavedSearchIdActions } from './setActiveSavedSearch';
import setActiveSavedSearchId from './setActiveSavedSearch';

export type Actions =
  | FetchSavedSearchActions
  | AnalyticsActions
  | UpdateSavedSearchActions
  | updateAllSavedSearchNotificationsActions
  | ToggleBuildingOwnerSaveActions
  | FetchSavedBuildingOwnersActions
  | UpdateSavedBuildingOwnerActions
  | FetchSavedListingsActions
  | ToggleSavedListingActions
  | SetActiveSavedSearchIdActions;

export default {
  fetchSavedSearches,
  deleteSavedSearch,
  updateSavedSearch,
  updateAllSavedSearchNotifications,
  toggleBuildingOwnerSave,
  savedBuildingOwner,
  unsavedBuildingOwner,
  fetchSavedBuildingOwners,
  receiveBuidingOwners,
  updateSavedBuildingOwner,
  receiveUpdatedSavedBuildingOwner,
  fetchSavedListings,
  receiveSavedListings,
  toggleSaveListing,
  savedListing,
  unsavedListing,
  loadSavedListingsPage,
  loadSavedBuildingOwnersPage,
  loadSavedSearchesPage,
  setActiveSavedSearchId,
  ...analyticsActions,
};
