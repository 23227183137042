import { useTranslation } from 'react-i18next';
import s from './ContentShootDetails.module.less';

type Props = {
  firstToMarket: boolean;
};

const FirstToMarket = ({ firstToMarket }: Props) => {
  const { t } = useTranslation('admin');

  return (
    <>
      <div className={s.firstToMarketSection}>
        <span className={s.sectionTitle}>{t('media.contentShootDetails.firstToMarket')}</span>
        <div>
          <span>{firstToMarket ? t('common:yes') : t('common:no')}</span>
        </div>
      </div>
    </>
  );
};

export default FirstToMarket;
