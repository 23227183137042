import cn from 'classnames';
import s from './RawSliderInput.module.less';
import RawInput from '../RawInput';

type Props = {
  min: number;
  max: number;
  step: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: number | string;
  className?: string;
  hasInput?: boolean;
  hasLegend?: boolean;
  legendSuffix?: string;
};

export default function RawSliderInput({
  min,
  max,
  step,
  onChange,
  name,
  value,
  className,
  hasInput = false,
  hasLegend = false,
  legendSuffix,
}: Props) {
  const percentage = (parseInt(value as string, 10) / max) * 100;
  return (
    <div className="flex w-full gap-1">
      <div className={cn('relative grow', !hasInput && 'w-full')}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            width: `calc(${percentage}% - ${(percentage / 100) * 16}px)`,
            background: '#280f96',
            height: 4,
            top: 14,
            borderRadius: '3px 0 0 3px',
          }}
        />
        <input
          className={cn(s.sliderInput, className)}
          type="range"
          min={min}
          max={max}
          step={step}
          onChange={onChange}
          value={value}
          name={name}
        />
        {hasLegend ? (
          <>
            <br />
            <div className="flex mt-1 justify-between">
              <span className="text-md text-gray-500">
                {min}
                {legendSuffix}
              </span>
              <span className="text-md text-gray-500">
                {max}
                {legendSuffix}
              </span>
            </div>
          </>
        ) : null}
      </div>
      {hasInput ? (
        <>
          <div className="w-6">
            <RawInput className="!p-0.5" type="number" value={value} onChange={onChange} />
          </div>
          <span>{legendSuffix}</span>
        </>
      ) : null}
    </div>
  );
}
