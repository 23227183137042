import * as React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import addKeyTo from '@shared/addKeyTo';
import { AdminBuildingIndex, AdminBuilding } from '@root/types';
import { upperFirst, lowerCase } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tag } from '@viewthespace/components';
import s from './BuildingsTable.module.less';

type Props = {
  buildings: AdminBuildingIndex[] | AdminBuilding[];
  loading: boolean;
  actionButtons: (id: string) => React.ReactNode;
};

const BuildingsTable = ({ buildings, loading, actionButtons }: Props) => {
  const { t } = useTranslation('admin');

  const renderSpaceProvider = value => {
    if (!value) {
      /* eslint-disable-next-line react/jsx-no-literals */
      return <span className={s['column-empty-state']}>&#8212;</span>;
    }

    return value.name;
  };

  const flags = useFlags();

  const updatedBuildings: Array<(AdminBuilding | AdminBuildingIndex) & { countryName?: string }> = [
    ...buildings,
  ];
  updatedBuildings.forEach(building => {
    // @ts-ignore
    // eslint-disable-next-line
    building.countryName = t(`country:${building.countryCode}`);
  });

  const renderStatus = status => upperFirst(lowerCase(status));

  const renderBuildingPill = (demo: boolean) => {
    return demo && <Tag testId="demoTag" color="informational" text={t('building.demoTag')} />;
  };

  const columns = () => {
    const buildingColumns = [
      {
        title: t('building.columnHeaders.displayName'),
        dataIndex: 'address',
        render: address => t('common:fullBuildingName', { address }),
      },
      {
        title: '',
        dataIndex: 'demo',
        render: (demo: boolean) => renderBuildingPill(demo),
      },
      {
        title: t('building.city'),
        dataIndex: 'locality',
      },
      {
        title: t('building.stateRegion'),
        dataIndex: 'abbreviatedRegion',
      },
      flags['outreach.landlord-company-connection-severed']
        ? {
            title: t('building.spaceProvider'),
            dataIndex: 'landlord',
            render: renderSpaceProvider,
          }
        : {
            title: t('building.spaceProvider'),
            dataIndex: 'owningCompany',
            render: renderSpaceProvider,
          },
      { title: t('building.columnHeaders.status'), dataIndex: 'status', render: renderStatus },
      {
        title: t('building.columnHeaders.actions'),
        dataIndex: 'id',
        render: id => actionButtons(id),
      },
    ];
    const buildingColumnsWithCountry = [...buildingColumns];
    buildingColumnsWithCountry.splice(3, 0, {
      title: t('building.countryCode'),
      dataIndex: 'countryName',
    });
    return buildingColumnsWithCountry;
  };

  return (
    <Table
      data-testid="buildings-table"
      loading={loading}
      columns={columns()}
      dataSource={addKeyTo(updatedBuildings)}
      pagination={false}
    />
  );
};

export default BuildingsTable;
