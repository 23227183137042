export type Item = { value: string; label: string };
export const alphabetizeAndGroupItems = (items: Item[]): Record<string, Item[]> => {
  const alphabetizedItems = items.sort((a, b) =>
    a.label.toLocaleLowerCase().localeCompare(b.label.toLocaleLowerCase()),
  );
  const groupedItems = alphabetizedItems.reduce((acc, item) => {
    const firstLetter = item.label.charAt(0).toLocaleUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(item);
    return acc;
  }, {});
  return groupedItems;
};
