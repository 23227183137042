import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OnlyInMobile, OnlyInDesktopTablet, CustomIcon } from '@components/shared';
import Checkbox from '@components/shared/forms/RawCheckbox';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import { Tooltip } from 'antd';
import { useListingSearchCriteria } from '@components/layouts/Truva/ListingSearch/utils';
import actions from '@store/actions/listingSearchPage';
import { useDispatch } from 'react-redux';
import s from './FirstToMarketFilter.module.less';

const FirstToMarketFilter = () => {
  const { t } = useTranslation('filters');
  const criteria = useListingSearchCriteria();
  const flags = useFlags();
  const { checkboxFilterInteraction } = useFilterInteraction();

  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      criteria.add('exclusive', true);
    } else {
      criteria.remove('exclusive');
    }

    if (flags['search-analytics-refactor']) {
      checkboxFilterInteraction({
        nowChecked: checked,
        filterType: 'firstToMarket',
        filterValue: checked,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatch(actions.filterFirstToMarketChanged(criteria.currentFilters.exclusive));
    }
    criteria.pushToHistory();
  };

  return (
    <div className={s.firstToMarketContainer}>
      <Checkbox
        onChange={handleChange}
        checked={criteria.currentFilters.exclusive}
        name="truva-exclusive-filter"
        size="xSmall"
      >
        <OnlyInDesktopTablet>
          <Tooltip
            overlayClassName={s.tooltip}
            trigger="hover"
            placement="bottomLeft"
            title={t('exclusiveTooltip')}
          >
            <div className={s.exclusiveFilter}>
              <div className={s.icon}>
                <CustomIcon type="vts-logo-indigo" />
              </div>
              <div className={s.exclusiveLabel}>{t('filters:exclusive')}</div>
            </div>
          </Tooltip>
        </OnlyInDesktopTablet>
        <OnlyInMobile>
          <span className={s.exclusiveIcon}>
            <CustomIcon type="vts-logo-indigo" />
          </span>
          <h2 className={s.exclusiveLabel}>{t('filters:exclusive')}</h2>
        </OnlyInMobile>
      </Checkbox>
    </div>
  );
};

export default FirstToMarketFilter;
