import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cn from 'classnames';
import { CustomIcon, Spinner } from '@components/shared';
import { RawCheckbox } from '@components/shared/forms';
import { useTranslation } from 'react-i18next';
import { ImageForUpload } from '..';
import s from '../TourbookExternalListing.module.less';

type Props = {
  droppableId: string;
  reorderImages: (args: { source: { index: number }; destination: { index: number } }) => void;
  images: ImageForUpload[];
  removeImage: (id: string) => void;
  toggleAsFloorPlan: (id: string, e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function Images({
  droppableId,
  reorderImages,
  images,
  removeImage,
  toggleAsFloorPlan,
}: Props) {
  const { t } = useTranslation('tourbook');

  return (
    <div className={s.images}>
      <DragDropContext onDragEnd={reorderImages}>
        <Droppable droppableId={droppableId}>
          {({ droppableProps, innerRef, placeholder }) => (
            <div {...droppableProps} ref={innerRef}>
              {images.map((image, index) => (
                <Draggable key={image.id} draggableId={image.id} index={index}>
                  {(
                    { innerRef: draggableInnerRef, draggableProps, dragHandleProps },
                    { isDragging },
                  ) => (
                    <div
                      ref={draggableInnerRef}
                      {...draggableProps}
                      {...dragHandleProps}
                      // eslint-disable-next-line react/no-unknown-property
                      index={index}
                      className={cn(s.draggable, isDragging && s.isDragging)}
                    >
                      <div
                        className={cn({
                          [s.preview]: true,
                          [s.uploading]: !image.isUploaded,
                        })}
                        key={image.id}
                      >
                        {!image.isUploaded ? (
                          <Spinner className={s.spinner} iconClassName={s.spinnerIcon} />
                        ) : null}
                        <img src={image.image.smallPath} alt={image.name}></img>
                        <button
                          type="button"
                          className={s.trash}
                          onClick={() => {
                            removeImage(image.id);
                          }}
                          data-testid={`remove-image-${image.id}`}
                        >
                          <CustomIcon type="trash" className={s.trashIcon}></CustomIcon>
                        </button>
                        {image.isFloorPlan ? (
                          <CustomIcon
                            type="floor-plan"
                            className={s.floorPlanIcon}
                            data-testid="floorPlanIcon"
                          />
                        ) : null}
                        <RawCheckbox
                          name={`floorplan-${image.id}`}
                          labelClassName={s.toggleAsFloorPlanContainer}
                          checked={image.isFloorPlan}
                          size="small"
                          onChange={e => toggleAsFloorPlan(image.id, e)}
                          data-testid={`floor-plan-image-${image.id}`}
                        >
                          {t('externalListing.setAsFloorPlan')}
                        </RawCheckbox>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
