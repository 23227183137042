import { ComponentProps } from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames';
import { CustomIcon } from '@components/shared';
import {
  OptionType,
  GroupedOptionType,
  SelectValue,
} from '@components/shared/forms/Dropdowns/types';
import { useBreakpoint } from '@shared/useBreakpoints';
import useAnalytics from '@shared/useAnalytics';
import COLORS from '@shared/colorConstants';
import s from './MarketToggleSelect.module.less';

export type Props = {
  options: Array<OptionType | GroupedOptionType>;
  name: string;
  value?: OptionType;
  onChange: (val: SelectValue) => void;
  isDisabled?: boolean;
  overrideComponents?: ComponentProps<typeof Select>['components'];
  overrideCustomStyles?: ComponentProps<typeof Select>['styles'];
  menuPlacement?: ComponentProps<typeof Select>['menuPlacement'];
  sourcePage: 'homepage' | 'search results page';
  sourceContent: 'market dropdown' | 'search criteria bar';
  showCurrentFilters?: boolean;
};

const MarketToggleSelect = ({
  options,
  name,
  value,
  onChange,
  isDisabled = false,
  overrideComponents = {},
  overrideCustomStyles = {},
  menuPlacement = 'top',
  sourcePage,
  sourceContent,
  showCurrentFilters = false,
}: Props) => {
  const { filterInteraction, PARAMETERS } = useAnalytics();
  const { isMobile } = useBreakpoint();
  const customStyles = {
    container: base => ({
      ...base,
      display: 'inline-block',
      pointerEvents: 'all',
    }),
    control: () => ({
      display: 'flex',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: COLORS.indigo,
      '&:hover': {
        color: COLORS.indigo110,
      },
      paddingRight: '0',
      borderBottom: `1px dashed ${COLORS.indigo}`,
    }),
    group: base => ({
      ...base,
      padding: '0',
    }),
    groupHeading: base => ({
      ...base,
      cursor: 'inherit',
      fontWeight: '',
      fontSize: '',
      marginBottom: '0',
    }),
    indicatorSeparator: () => ({
      margin: '1px',
    }),
    indicatorsContainer: base => ({
      ...base,
      'div[class*="indicatorContainer"]': {
        paddingLeft: '0px',
        ...(isMobile && { padding: '2px', height: '100%' }),
      },
    }),
    menu: base => ({
      ...base,
      cursor: 'pointer',
      minWidth: '210px',
      zIndex: 2,
    }),
    menuList: base => ({
      ...base,
      paddingTop: '0',
      paddingBottom: '0',
      borderRadius: '4px',
      maxHeight: '400px',
    }),
    option: base => ({
      ...base,
      backgroundColor: 'white',
      color: '',
      cursor: 'inherit',
      display: 'flex',
      fontSize: '',
      justifyContent: 'space-between',
      '&:active': {
        backgroundColor: COLORS.egg50,
      },
      '&:hover': {
        backgroundColor: COLORS.egg50,
      },
    }),
    placeholder: base => ({
      ...base,
      position: 'static',
      transform: 'none',
      paddingRight: '12px',
      textOverflow: '',
    }),
    singleValue: base => ({
      ...base,
      color: COLORS.indigo,
      marginLeft: '0',
      marginRight: '0',
      paddingRight: '8px',
      position: 'static',
      transform: 'none',
      textOverflow: '',
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
      borderBottom: `1px dashed ${COLORS.indigo}`,
    }),
    ...overrideCustomStyles,
  };

  const formatGroupLabel = data => (
    <div className={s.group}>
      <span>{data.label}</span>
    </div>
  );

  const GroupHeading = props => {
    return <components.GroupHeading {...props} className={s.groupHeading} />;
  };

  const Option = props => {
    const { children, isSelected, isFocused } = props;
    return (
      <components.Option
        {...props}
        className={cn(s.option, isSelected && s.selected, isFocused && s.focused)}
      >
        {children}
        {isSelected ? (
          <aside className={s.selectedCheckmark}>
            <CustomIcon type="checkmark" />
          </aside>
        ) : null}
      </components.Option>
    );
  };

  return (
    <Select
      name={name}
      value={value}
      options={options}
      isSearchable={false}
      inputId={name}
      components={{
        Option,
        GroupHeading,
        ...overrideComponents,
      }}
      styles={customStyles}
      onChange={option => {
        // @ts-expect-error
        const newValue = option ? option.value : null;
        onChange(newValue);
        filterInteraction({
          actionType: 'SELECT_MARKET_FROM_DROPDOWN',
          sourcePage,
          sourceContent,
          action: PARAMETERS.applyFilter,
          filter: PARAMETERS.market,
          value: newValue,
          ...(showCurrentFilters ? { currentFilters: { market: newValue } } : {}),
        });
      }}
      menuPlacement={menuPlacement}
      formatGroupLabel={formatGroupLabel}
      isDisabled={isDisabled}
    />
  );
};

export default MarketToggleSelect;
