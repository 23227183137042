import { CircleDown } from '@components/shared/CustomIcon/icons';
import s from './MarketingMediaHeader.module.less';

type MarketingMediaHeaderProps = {
  mediaTitle: string;
  mediaLimit: string;
  collapseMedia: boolean;
  setCollapseMedia: (close: boolean) => void;
};

const MarketingMediaHeader = (props: MarketingMediaHeaderProps) => {
  return (
    <div className={s.mediaHeader}>
      <span data-testid="marketing-hero-media-title" className={s.mediaTitle}>
        {props.mediaTitle}
      </span>
      <span className={s.mediaLimitText}>{props.mediaLimit}</span>
      <span
        data-collapsed={props.collapseMedia}
        className={props.collapseMedia ? s.closeIcon : s.openIcon}
        data-testid="collapse-button"
        role="button"
        onClick={() => props.setCollapseMedia(!props.collapseMedia)}
      >
        <CircleDown />
      </span>
    </div>
  );
};

export default MarketingMediaHeader;
