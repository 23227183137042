import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useFlags } from 'launchdarkly-react-client-sdk';
import type { Thumbnail } from '@store/actions/listingPage';
import CustomIcon from '@root/components/shared/CustomIcon';
import { OnlyInMobile, OnlyInTablet, OnlyInDesktop } from '@root/components/shared';
import actions from '@store/actions/listingPage';
import type { Address, MultipathImage, Video } from '@root/types';
import useAltText from '@root/shared/useAltText';
import css from './Thumbnails.module.less';

const MOBILE_DISPLAY_MAX = 4;
const TABLET_DISPLAY_MAX = 6;
const DESKTOP_DISPLAY_MAX = 8;

interface ThumbnailsProps extends ViewportAwareThumbnailsProps {
  maxThumbnailsCount: number;
  address: Address;
}

export const Thumbnails = ({
  maxThumbnailsCount = DESKTOP_DISPLAY_MAX,
  photos,
  currentIndex,
  onChange,
  video = null,
  tourEmbedUrl = null,
  handleTourClick,
  sourcePage,
  sourceContent,
  address,
  companyName,
}: ThumbnailsProps) => {
  const flags = useFlags();
  const dispatch = useDispatch();
  const { t } = useTranslation('listing');
  const { getAltText, getAltTextForVideo } = useAltText({ address });

  const floorPlanIndex = photos.findIndex(photo => photo.type === 'floorPlan');
  const staticMapIndex = photos.findIndex(photo => photo.type === 'staticMap');
  const floorPlan = photos[floorPlanIndex];
  const staticMap = photos[staticMapIndex];

  const rotatingThumbnails = photos.filter(
    photo => photo.type !== 'floorPlan' && photo.type !== 'staticMap',
  );

  const numberOfNonPhotoThumbnails = [floorPlan, staticMap, video, tourEmbedUrl].reduce(
    (accumulator: number, thumbnail: any) => (thumbnail ? accumulator + 1 : accumulator),
    0,
  );

  const rotatingThumbnailsCount = maxThumbnailsCount - numberOfNonPhotoThumbnails;

  // If you only have one photo, that photo is the staticMapImage
  if (photos.length === 1 && !tourEmbedUrl && !video) return null;

  let startIndex = 0;
  let endIndex = rotatingThumbnailsCount - 1;

  if (currentIndex >= rotatingThumbnailsCount && currentIndex < rotatingThumbnails.length) {
    startIndex = currentIndex - (rotatingThumbnailsCount - 1);
    endIndex = currentIndex;
  }

  const numberOfVisibleThumbnails =
    rotatingThumbnails.length + numberOfNonPhotoThumbnails > maxThumbnailsCount
      ? maxThumbnailsCount
      : rotatingThumbnails.length + numberOfNonPhotoThumbnails;

  return (
    <div
      data-qa-testid="listing-image-thumbnails"
      className={css.filmstrip}
      style={{
        gridTemplateColumns: `repeat(${numberOfVisibleThumbnails}, 1fr)`,
      }}
    >
      {rotatingThumbnails
        .map((photo, index) => (
          <figure
            key={photo.path}
            className={cn({
              [css.invisible]: !(index >= startIndex && index <= endIndex),
            })}
          >
            <button type="button" onClick={() => onChange(index, 'clickThumbnail')}>
              <img
                className={cn({
                  [css.image]: true,
                  [css.selected]: currentIndex === index,
                })}
                src={photo.smallPath}
                alt={getAltText({
                  type: photo.type,
                  options: { roomName: photo.description, companyName },
                })}
              />
            </button>
          </figure>
        ))
        .filter(photo => photo)}

      {floorPlan ? (
        <figure>
          <button
            type="button"
            className={cn(css.image, css.floorPlanBtn, css.fullOpacity, {
              [css.selected]: currentIndex === floorPlanIndex,
            })}
            onClick={() => {
              onChange(floorPlanIndex, 'viewFloorPlan');
            }}
            title="Floor Plan"
          >
            <CustomIcon type="floor-plan" className={css.floorPlanIcon} />
            <div>{t('gallery.floorPlan')}</div>
          </button>
        </figure>
      ) : null}

      {staticMap ? (
        <figure>
          <button
            type="button"
            onClick={() => {
              onChange(staticMapIndex, 'viewMap');
            }}
          >
            <img
              src={staticMap.smallPath}
              alt={getAltText({ type: 'staticMap' })}
              className={cn(css.image, css.fullOpacity, {
                [css.selected]: currentIndex === staticMapIndex,
              })}
            />
          </button>
        </figure>
      ) : null}

      {video ? (
        <figure className={css.videoThumbnail}>
          <button
            type="button"
            onClick={() => {
              dispatch(actions.galleryThumbnailClicked('viewVideo', sourcePage, sourceContent));
              handleTourClick('video');
            }}
          >
            <img
              src={video.thumbnailPosterPath}
              alt={getAltTextForVideo(sourcePage || 'listing page', companyName)}
              className={cn(css.image, css.fullOpacity)}
            />
          </button>
        </figure>
      ) : null}

      {tourEmbedUrl ? (
        <figure className={cn(css.threeDTourThumbnail)}>
          <button
            type="button"
            title="3d Tour"
            className={css.tourButton}
            onClick={() => {
              dispatch(
                actions.galleryThumbnailClicked(
                  flags['media-analytics'] ? 'view3DTour' : 'open3DTour',
                  sourcePage,
                  sourceContent,
                ),
              );
              handleTourClick('tourEmbed');
            }}
          >
            {!isEmpty(rotatingThumbnails) ? (
              <img
                className={cn(css.image, css.fullOpacity)}
                src={(rotatingThumbnails as MultipathImage[])[0].smallPath}
                alt={getAltText({ type: '3dTourThumbnailPhoto' })}
              />
            ) : null}
            <CustomIcon type="3d-tour" className={css.tourLogo} />
          </button>
        </figure>
      ) : null}
    </div>
  );
};

interface ViewportAwareThumbnailsProps {
  photos: Array<MultipathImage>;
  currentIndex: number;
  onChange: (index: number, thumbnailType: keyof Thumbnail) => void;
  video: Video | null;
  tourEmbedUrl: string | null;
  handleTourClick: (mediaType: 'video' | 'tourEmbed') => void;
  sourcePage?: string;
  sourceContent?: string;
  address: Address;
  companyName?: string;
}

const ViewportAwareThumbnails = (props: ViewportAwareThumbnailsProps) => (
  <>
    <OnlyInDesktop>
      <Thumbnails maxThumbnailsCount={DESKTOP_DISPLAY_MAX} {...props} />
    </OnlyInDesktop>
    <OnlyInTablet>
      <Thumbnails maxThumbnailsCount={TABLET_DISPLAY_MAX} {...props} />
    </OnlyInTablet>
    <OnlyInMobile>
      <Thumbnails maxThumbnailsCount={MOBILE_DISPLAY_MAX} {...props} />
    </OnlyInMobile>
  </>
);

export default ViewportAwareThumbnails;
