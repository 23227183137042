import { memo } from 'react';
import cn from 'classnames';
import { upperFirst, lowerCase, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Checkbox from '@components/shared/forms/Checkbox';
import buttonCSS from '@components/shared/V2Button/Button.module.less';
import { buildingStatuses } from '@propTypes';
import { Select } from '@components/shared/forms';
import Input from './Input';
import css from './styles.module.less';

const BasicInfo = ({ building, status }) => {
  const { t } = useTranslation('admin');

  return (
    <section>
      <h2>{t('building.basicInfo.title')}</h2>
      <Select
        className={css.select}
        labelText={t('building.basicInfo.assetTypes')}
        name="assetTypes"
        required
        isMulti
        isDisabled
        placeholder=""
        options={[
          { value: 'INDUSTRIAL', label: t('common:industrial'), isFixed: true },
          { value: 'RETAIL', label: t('common:retail'), isFixed: true },
          { value: 'OFFICE', label: t('common:office'), isFixed: true },
        ]}
      />
      {isEmpty(building.assetTypes) ? (
        <p className="-mt-[12px] mb-2 text-sm text-black-055">
          {t('building.basicInfo.assetTypesDescription')}
        </p>
      ) : null}

      <Input labelText={t('building.basicInfo.displayAddress')} name="displayAddress" required />
      <Input labelText={t('building.basicInfo.buildingName')} name="name" />
      <p className={css.fakeLabel}>{t('building.basicInfo.url')}</p>
      <a
        className={css.buildingURL}
        href={building.url}
        target="_blank"
        rel="noreferrer"
      >{`${window.location.host}${building.url}`}</a>

      <div className={css.sideBySide} data-testid="building-status">
        <Select
          className={css.select}
          containerClass={css.left}
          isClearable={false}
          labelText={t(`building.basicInfo.status`)}
          name="status"
          options={buildingStatuses.map(statusOption => ({
            label: upperFirst(lowerCase(statusOption)),
            value: statusOption,
          }))}
          placeholder=""
          value={status}
        />
        {building.url && building.status !== 'retired' ? (
          <a
            className={cn(buttonCSS.button, buttonCSS.secondary, buttonCSS.medium, css.previewLink)}
            href={building.url}
            target="_blank"
            rel="noreferrer"
          >
            {t('building.viewBuildingButton.preview')}
          </a>
        ) : null}
      </div>

      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={t('building.basicInfo.liveAt')}
          name="liveAt"
          disabled
        />
        <Input
          containerClass={css.right}
          labelText={t('building.basicInfo.retiredAt')}
          name="retiredAt"
          disabled
        />
      </div>
      <Input labelText={t('building.basicInfo.permalinkLabel')} name="slug" disabled />
      <p className={css.fakeLabel}>{t('building.id')}</p>
      <p>{building.id}</p>
      <Checkbox aria-describedby="demo-description" name="demo">
        <span className={css.checkboxLabel}>{t('building.basicInfo.demo')}</span>
      </Checkbox>
      <p className={css.description} id="demo-description">
        {t('building.basicInfo.demoDescription')}
      </p>
    </section>
  );
};

export default memo(BasicInfo);
