import { ReactNode } from 'react';
import cn from 'classnames';
import s from './OverflowMenuItem.module.less';

type Props = {
  children: ReactNode;
  onClick: (e) => void;
  disabled?: boolean;
  className?: string;
};

const OverflowMenuItem = ({ onClick, children, disabled = false, className }: Props) => {
  return (
    <button
      disabled={!!disabled}
      type="button"
      onClick={onClick}
      className={cn(s.button, className)}
    >
      {children}
    </button>
  );
};

export default OverflowMenuItem;
