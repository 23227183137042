import * as React from 'react';
import s from './Header.module.less';

type Props = {
  children: React.ReactNode;
  labelQaTestId?: string;
};

const Header = ({ children, labelQaTestId }: Props) => (
  <h3 className={s.title} data-qa-testid={labelQaTestId}>
    {children}
  </h3>
);

export default Header;
