import { capitalize } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { buildingStatuses } from '@propTypes';
import type { AdminBuilding } from '@root/types';
import ChannelsHeader from '../Header';
import ChannelsSelect from '../Select';
import type { BuildingStatuses } from '../types';
import css from '../../styles.module.less';
import Input from '../../Input';

type ChannelsBrochureProps = {
  building: AdminBuilding;
  status: string;
  brochureTemplates: any;
  selectedBrochureTemplate: string;
};

const ChannelsBrochure = ({
  status,
  building,
  brochureTemplates,
  selectedBrochureTemplate,
}: ChannelsBrochureProps) => {
  const { t } = useTranslation('admin');

  const isBrochureStatusLiveOrReadyForApproval =
    ['live', 'ready_for_approval'].indexOf(building.brochureStatus) >= 0;

  return (
    <>
      <ChannelsHeader channel="brochure" />

      {isBrochureStatusLiveOrReadyForApproval && building.automatedBrochure ? (
        <a
          className={css.buildingURL}
          href={building.automatedBrochure.url}
          target="_blank"
          rel="noreferrer"
        >
          {building.automatedBrochure.name}
        </a>
      ) : null}

      <ChannelsSelect
        containerTestId="building-brochure-status"
        labelText={t(`building.basicInfo.brochureStatus`)}
        name="brochureStatus"
        options={(buildingStatuses as BuildingStatuses).map(statusOption => ({
          label: capitalize(statusOption.replace(/_/g, ' ')),
          value: statusOption,
        }))}
        value={status}
      />

      <div className={css.sideBySide}>
        <Input
          containerClass={css.left}
          labelText={t('building.basicInfo.liveAt')}
          name="brochurePublishedAt"
          disabled
        />
        <Input
          containerClass={css.right}
          labelText={t('building.basicInfo.retiredAt')}
          name="brochureRetiredAt"
          disabled
        />
      </div>

      <ChannelsSelect
        containerTestId="building-brochure-template"
        labelText={t(`building.channels.brochureTemplate`)}
        name="selectedBrochureTemplate"
        options={brochureTemplates?.map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
        placeholder="Select template"
        value={selectedBrochureTemplate}
      />
    </>
  );
};

export default ChannelsBrochure;
