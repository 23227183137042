import COLORS from '@root/shared/colorConstants';

const CustomStyles = {
  control: base => ({
    ...base,
    borderColor: COLORS.black20,
    boxShadow: 'none',
    '&:active': {
      borderColor: COLORS.egg100,
      boxShadow: 'none',
    },
    '&:hover': {
      borderColor: COLORS.egg100,
      boxShadow: 'none',
    },
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 1000,
  }),
  option: base => ({
    ...base,
    backgroundColor: 'white',
    '&:active': {
      backgroundColor: COLORS.egg50,
    },
    '&:hover': {
      backgroundColor: COLORS.egg50,
    },
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    'svg[class*="css"]': {
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
      '&:hover': {
        color: COLORS.black100,
      },
    },
  }),
  singleValue: base => ({
    ...base,
    height: '64px',
    position: 'static',
    transform: 'none',
    display: 'flex',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
    borderRadius: 4,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    height: '64px',
  }),
};

export default CustomStyles;
