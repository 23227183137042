import { useState } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import s from './Input.module.less';

export type InputProps = {
  'aria-describedby'?: string;
  'data-testid'?: string;
  ariaLabel?: string;
  disabled?: boolean;
  type?: 'number' | 'text' | 'file';
  value?: number | string | undefined;
  defaultValue?: number | string | undefined;
  name?: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  allowChange?: null | ((value: string) => boolean);
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  hasError?: boolean;
  autoComplete?: boolean;
  readOnly?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  showCharacterCount?: boolean;
  maxLength?: number;
  units?: string;
  currencyValue?: string;
  prefix?: React.ReactNode;
};

const RawInput = React.forwardRef(
  (
    {
      'data-testid': dataTestid,
      'aria-describedby': ariaDescribedby,
      ariaLabel,
      disabled = false,
      type = 'text',
      name,
      value = undefined,
      onChange,
      placeholder,
      defaultValue,
      className,
      onBlur,
      hasError = false,
      autoComplete = true,
      readOnly = false,
      onFocus,
      showCharacterCount = false,
      maxLength,
      units,
      onKeyDown,
      currencyValue,
      allowChange,
      prefix,
    }: InputProps,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [length, setLength] = useState(value?.toString().length || 0);
    const [isFocused, setIsFocused] = useState(false);
    const countLength = event => {
      setLength(event.target.value.length);
    };

    const handleOnChange = e => {
      if (onChange && (isNil(allowChange) || allowChange(e.target.value || ''))) {
        onChange(e);
      }
    };

    return units || prefix ? (
      <span className={classNames(s.unitInput, hasError && s.error, isFocused && s.focus)}>
        {currencyValue && (
          <span data-testid="currencyInput" className={s.currencyInput}>
            {currencyValue}
          </span>
        )}
        {prefix && (
          <span data-testid="prefix" className={s.currencyInput}>
            {prefix}
          </span>
        )}
        <input
          ref={ref}
          aria-describedby={ariaDescribedby}
          aria-label={ariaLabel}
          data-testid={dataTestid}
          className={classNames(s.input, className, units && s.unitInput, prefix && s.prefixInput)}
          disabled={disabled}
          name={name}
          id={name}
          type={type}
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onBlur={e => {
            setIsFocused(false);
            if (onBlur) onBlur(e);
          }}
          // none is used instead of web standard off because Chrome does not respect off
          // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
          autoComplete={autoComplete ? 'on' : 'none'}
          onFocus={e => {
            setIsFocused(true);
            if (onFocus) onFocus(e);
          }}
          readOnly={readOnly}
          onKeyUp={countLength}
          onKeyDown={onKeyDown}
        />
        {units ? (
          <span data-testid="numberInput-units" className={classNames(s.units)}>
            {units}
          </span>
        ) : null}
      </span>
    ) : (
      <>
        <input
          ref={ref}
          aria-describedby={ariaDescribedby}
          data-testid={dataTestid}
          className={classNames(s.input, hasError && s.error, className)}
          disabled={disabled}
          name={name}
          id={name}
          type={type}
          value={value}
          onChange={handleOnChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onBlur={onBlur}
          // none is used instead of web standard off because Chrome does not respect off
          // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
          autoComplete={autoComplete ? 'on' : 'none'}
          onFocus={onFocus}
          readOnly={readOnly}
          onKeyUp={countLength}
          onKeyDown={onKeyDown}
        />
        {showCharacterCount && maxLength && (
          <div className={s.characterCount}>{`${length} / ${maxLength}`}</div>
        )}
      </>
    );
  },
);

export default RawInput;
