import * as React from 'react';
import cn from 'classnames';
import useScrollToTop from '@shared/useScrollToTop';
import { Pages } from '@root/types';
import ErrorBoundary from '@components/shared/ErrorBoundary';
import useFixedPageHeader from '@root/shared/useFixedPageHeader';
import contentWidth from '@styles/contentWidth.module.less';
import Navigation from './Navigation';
import Footer from './Footer';
import s from './Layout.module.less';

type Props = {
  children?: React.ReactNode;
  containerClassName?: string;
  mobileFullWidth?: boolean;
  'data-testid'?: string;
  showFooter?: boolean;
  activePage?: Pages;
  stickyNav?: boolean;
  fullWidthBackground?: boolean;
  fixedHeaderContents?: React.ReactNode;
};

const Layout = ({
  children = null,
  mobileFullWidth = false,
  containerClassName,
  showFooter = true,
  activePage,
  stickyNav = false,
  fullWidthBackground = false,
  fixedHeaderContents,
  ...props
}: Props) => {
  useScrollToTop();

  const { FixedPageHeader, fixedPageHeaderProps } = useFixedPageHeader();

  return (
    <>
      <Navigation activePage={activePage} stickyNav={stickyNav} />
      {fixedHeaderContents ? (
        <FixedPageHeader {...fixedPageHeaderProps}>{fixedHeaderContents}</FixedPageHeader>
      ) : null}
      <main
        className={cn(
          contentWidth.contentPadding,
          !fullWidthBackground && contentWidth.contentContainer,
          s.content,
          mobileFullWidth && s.mobileFullWidth,
          containerClassName,
        )}
        // eslint-disable-next-line react/destructuring-assignment
        data-testid={props['data-testid']}
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
      {showFooter ? <Footer /> : ''}
    </>
  );
};

export default Layout;
