import { useState, useReducer } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import routes from '@root/routes';
import useAnalytics from '@root/shared/useAnalytics';
import { EVENTS, PARAMETERS } from '@root/tracking/constants';
import { CurrentUser, SearchListing, StoreState } from '@root/types';
import api from '@shared/api';
import actions from '@store/actions/listingSearchPage';
import { getLastInquiryForListing, getListingSearchPageLevelArguments } from '@store/selectors';
import { Notification } from '@components/shared';
import { defaultInquiryMessage } from '@components/layouts/Truva/AccountSettings/InquirySettingsForm';
import QuickInquiryIconButton, {
  QuickInquiryOverlay,
  QuickInquiryIconButtonProps,
} from './QuickInquiries';

const useQuickInquirySettings = ({
  listing,
  trigger,
  onToggleQuickInquiryDialog,
  calculateCorrectedTop,
}: {
  listing: SearchListing;
  trigger: QuickInquiryIconButtonProps['trigger'];
  onToggleQuickInquiryDialog?: (isOpen: boolean) => void;
  calculateCorrectedTop?: (height: number) => number | undefined;
}) => {
  const { t } = useTranslation('inquiry');
  type QuickInquiryState = {
    quickInquiryDefaultMessage: string | null;
    quickInquiryDialogIsOpen: boolean;
  };

  const initialState = {
    quickInquiryDefaultMessage: null,
    quickInquiryDialogIsOpen: false,
  };
  const reduxDispatch = useDispatch();
  const { quickInquiryInteraction } = useAnalytics();
  const currentUser = useSelector((state: StoreState) => state.currentUser) as CurrentUser;
  const { pageNumber, resultsCount } = useSelector((state: StoreState) =>
    getListingSearchPageLevelArguments(state),
  );
  const lastInquiry = useSelector((state: StoreState) =>
    getLastInquiryForListing(state, listing.id),
  );

  function reducer(
    state: QuickInquiryState,
    action: {
      type: 'quickInquiry' | 'oneClickInquiry' | 'close';
      payload?: Partial<QuickInquiryState>;
    },
  ): QuickInquiryState {
    switch (action.type) {
      case 'quickInquiry': {
        return {
          quickInquiryDefaultMessage: action.payload!.quickInquiryDefaultMessage as string | null,
          quickInquiryDialogIsOpen: true,
        };
      }
      case 'close':
      case 'oneClickInquiry': {
        return initialState;
      }
      default: {
        return initialState;
      }
    }
  }

  const [state, localDispatch] = useReducer(reducer, initialState);
  const [isLocked, setIsLocked] = useState(false);
  const unlockInquiry = () => setIsLocked(false);

  const lockInquiry = () => {
    setIsLocked(true);
    setTimeout(unlockInquiry, 1000);
  };

  const onClick = async () => {
    if (isLocked) return;
    const response = await api.fetch(routes.api.currentUserInquirySettings);
    const { quickInquiryDefaultMessage, oneClickInquiryActivated } = await response.json();

    if (oneClickInquiryActivated) {
      localDispatch({ type: 'oneClickInquiry' });
      const previousInquiryExists = !!lastInquiry;
      quickInquiryInteraction({
        action: previousInquiryExists ? PARAMETERS.inquiry.resend : PARAMETERS.inquiry.send,
        event: EVENTS.inquiryInteraction,
        actionType: previousInquiryExists
          ? 'QUICK_INQUIRY_RESEND_MESSAGE'
          : 'QUICK_INQUIRY_SEND_MESSAGE',
        listing,
        oneClickInquiryActivated: currentUser.oneClickInquiryActivated,
        otherAttributes: {
          pageNumber,
          resultsCount,
        },
      });
      reduxDispatch(
        actions.createQuickInquiry({
          listingId: listing.id,
          body: {
            message:
              quickInquiryDefaultMessage || defaultInquiryMessage(t, 'defaultMessage', currentUser),
            saveDefaultMessage: false,
          },
          onSuccess: () => {
            Notification.info({
              title: t('inquiry:sentInquiryTitle'),
              /* eslint-disable react/jsx-no-literals */
              text: (
                <>
                  <p>{t('inquiry:sentInquiryBody')}</p>
                  <Trans ns="inquiry" i18nKey="sentInquiryOneClickBody">
                    One-click is on, edit this on
                    <a href={routes.users.inquirySettings}>account settings</a>
                  </Trans>
                </>
              ),
              /* eslint-enable react/jsx-no-literals */
            });
          },
          onFailure: () => {
            quickInquiryInteraction({
              action: PARAMETERS.inquiry.error,
              event: EVENTS.inquiryInteraction,
              actionType: 'QUICK_INQUIRY_ERROR',
              listing,
              oneClickInquiryActivated: currentUser.oneClickInquiryActivated,
              otherAttributes: {
                pageNumber,
                resultsCount,
              },
            });

            Notification.error({
              title: t('inquiry:failedQuickInquiryTitle'),
              text: t('inquiry:failedQuickInquiryBody'),
            });
            setIsLocked(false);
          },
        }),
      );

      lockInquiry();
    } else {
      localDispatch({ type: 'quickInquiry', payload: { quickInquiryDefaultMessage } });
      onToggleQuickInquiryDialog?.(true);
    }
  };

  const handleClose = () => {
    localDispatch({ type: 'close' });
    onToggleQuickInquiryDialog?.(false);
  };

  return {
    QuickInquiryIconButton,
    quickInquiryButtonProps: {
      listingId: listing.id,
      isOpen: state.quickInquiryDialogIsOpen,
      disabled: !listing.hasContacts,
      currentUser,
      trigger,
      onClick,
      isLocked,
    },
    QuickInquiryOverlay,
    quickInquiryOverlayProps: {
      listingId: listing.id,
      close: () => {
        handleClose();
      },
      quickInquiryDefaultMessage: state.quickInquiryDefaultMessage,
      currentUser,
      calculateCorrectedTop,
    },
    quickInquiryDialogIsOpen: state.quickInquiryDialogIsOpen,
  };
};

export default useQuickInquirySettings;
