import routes from '@root/routes';
import { Landlord } from '@root/types';
import { useTranslation } from 'react-i18next';
import PageHead from '@components/shared/PageHead/PageHead';

type Props = {
  landlord: Landlord;
  isPreview: boolean;
};

const getLandlordDescriptionT = (landlord: Landlord): string => {
  const hasMarkets = landlord.marketSlugs && landlord.marketSlugs.length > 0;
  if (hasMarkets) {
    return 'landlord:meta.description.withMarkets';
  }

  return 'landlord:meta.description.withoutMarkets';
};

const marketCity = (t, marketSlug) => {
  const fullMarketName = t(`common:markets.${marketSlug}`);
  return fullMarketName.split(', ')[0]; // Leave out the country code.
};

const LandlordHead = ({ landlord, isPreview = false }: Props) => {
  const { t } = useTranslation();

  const marketsList = landlord.marketSlugs.map(marketSlug => marketCity(t, marketSlug)).join(', ');

  const title = t('landlord:meta.title', { name: landlord.name });
  const description = t(getLandlordDescriptionT(landlord), { name: landlord.name, marketsList });
  const canonicalUrl = routes.landlords(landlord.slug);

  return (
    <PageHead
      title={title}
      description={description}
      canonicalURL={canonicalUrl}
      removeFromSEOIndex={landlord.isDemo || isPreview}
    />
  );
};

export default LandlordHead;
