import { useTranslation } from 'react-i18next';
import RadioInput from '@components/shared/Form/RadioInput';
import { VirtualTourSpotlights } from '@root/types';
import { TFunction } from 'i18next';
import useSuccessCheckmark from '@shared/useSuccessCheckmark';

export type Props = {
  pickerFor: Exclude<VirtualTourSpotlights, 'unset'>;
  currentValue?: VirtualTourSpotlights | null;
  show: boolean;
  onChange?: ((newValue: Exclude<VirtualTourSpotlights, 'unset'>) => Promise<boolean>) | null;
};

function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here");
}

const getLabelText = (t: TFunction, pickerFor: Exclude<VirtualTourSpotlights, 'unset'>): string => {
  // eslint-disable-next-line default-case
  switch (pickerFor) {
    case 'three_d_tour':
      return t('media.spotlightTour');
    case 'video':
      return t('media.spotlightVideo');
    default:
      return assertUnreachable(pickerFor);
  }
};

export default function VirtualTourSpotlightPicker({
  pickerFor,
  currentValue = null,
  show,
  onChange = null,
}: Props): JSX.Element | null {
  const { t } = useTranslation('admin');
  const { showCheckmark, Checkmark, checkmarkProps } = useSuccessCheckmark();

  if (!show) return null;

  const handleChange = async () => {
    if (onChange && (await onChange(pickerFor))) showCheckmark();
  };

  return (
    <>
      <div>
        <RadioInput
          value={pickerFor}
          name="virtualTourSpotlight"
          checked={currentValue === pickerFor}
          labelText={getLabelText(t, pickerFor)}
          onChange={handleChange}
        />
        <Checkmark {...checkmarkProps} />
      </div>
    </>
  );
}
