import { notification } from 'antd';
import Button from '@components/shared/V2Button';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import routes from '@root/routes';
import api from '@shared/api';
import BuildingsTable from '@components/shared/BuildingsTable';
import { AdminBuilding, AdminProperty } from '@root/types';

type Props = {
  property: AdminProperty;
  buildings: AdminBuilding[];
};

export default function ExistingBuildingSuggestions({ property, buildings }: Props) {
  const { t } = useTranslation('admin');
  const history = useHistory();

  const linkBuilding = async selectedBuildingId => {
    try {
      await api.post(routes.api.admin.buildingLink, {
        propertyId: property.id,
        buildingId: selectedBuildingId,
      });

      history.push(routes.admin.building(selectedBuildingId));
    } catch (e) {
      // @ts-expect-error
      notification.error({ message: e.message });
    }
  };

  const buildingTableActionButtons = id => (
    <>
      <a
        style={{ paddingRight: 20 }}
        href={routes.admin.building(id)}
        target="_blank noopener noreferrer"
      >
        {t('building.viewBuilding')}
      </a>
      <Button type="secondary" onClick={() => linkBuilding(id)} disabled={!!property.buildingId}>
        {t('building.linkBuilding')}
      </Button>
    </>
  );

  return (
    <BuildingsTable
      buildings={buildings}
      loading={false}
      actionButtons={buildingTableActionButtons}
    />
  );
}
