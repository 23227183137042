import { ReactNode } from 'react';
import labelStyles from '@styles/labels.module.less';
import cn from 'classnames';
import s from './Metric.module.less';

type Props = {
  value: ReactNode | null;
  label: string;
  className?: string;
  labelClassName?: string;
};
const Metric = ({ value = null, label, className, labelClassName }: Props) => (
  <div className={className}>
    <label className={cn(labelStyles.labelText, labelClassName)}>{label}</label>
    <span className={s.value}>{value}</span>
  </div>
);

export default Metric;
