import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '@root/types';
import { getSavedSearches } from '@store/selectors';
import {
  ListingSearchCriteria,
  useListingSearchCriteria,
} from '@components/layouts/Truva/ListingSearch/utils';
import { Button } from '@components/shared';
import savedActions from '@store/actions/saved';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import SaveSearchButton from '@components/layouts/Truva/ListingSearch/NamedSaveSearch/SaveSearchButton';
import useCurrentUser from '@root/shared/useCurrentUser';
import useOnChangeSavedSearch from '@components/layouts/Truva/ListingSearch/useOnChangeSavedSearch';
import s from './MobileSearchCriteriaBar.module.less';
import { FilterPillOptions } from './FilterPill/FilterPill';
import ListingSearchFiltersPanel from '../Filters/ListingSearchFiltersPanel';
import SaveSearchSelector from '../SaveSearchSelector';

export type SearchCriteriaBarProps = {
  options: FilterPillOptions[];
  onFilterPillClick: (filterkey: string, filter: string, value?: string) => void;
  removeAll: () => void;
};

export type ReduxProps = ConnectedProps<typeof connector>;

const mapStateToProps = (state: StoreState) => ({
  activeSavedSearchId: state.savedSearches.activeId,
  savedSearches: getSavedSearches(state),
});

const mapDispatch = {
  fetchSavedSearches: () => savedActions.fetchSavedSearches(),
};

const connector = connect(mapStateToProps, mapDispatch);

export const MobileSearchCriteriaBar = ({
  options,
  onFilterPillClick,
  removeAll,
  savedSearches,
  activeSavedSearchId,
  fetchSavedSearches,
}: SearchCriteriaBarProps & ReduxProps) => {
  const openFiltersButtonRef = useRef<HTMLButtonElement>(null);
  const criteria = useListingSearchCriteria();
  const [filterPanelIsOpen, setFilterPanelIsOpen] = useState(false);
  const { currentUser } = useCurrentUser();
  const userLoggedIn = !!currentUser;
  const { t } = useTranslation('filters');

  useEffect(() => {
    fetchSavedSearches();
  }, [fetchSavedSearches]);

  const areNoFilterOptions = options.length < 1;
  const selectedSavedSearch = savedSearches.find(ss => ss.id === activeSavedSearchId) || null;
  const showSavedSearchSelector = userLoggedIn && savedSearches.length > 0;

  const canUpdate =
    !!selectedSavedSearch &&
    !criteria.isEqualTo(ListingSearchCriteria.fromSavedSearch(selectedSavedSearch));

  const onChange = useOnChangeSavedSearch();

  return (
    <div>
      {showSavedSearchSelector ? (
        <div className="mb-2">
          <SaveSearchSelector
            name="SavedSearchSelector"
            savedSearches={savedSearches}
            value={activeSavedSearchId}
            onChange={onChange}
            currentSelectedSearch={selectedSavedSearch}
          />
        </div>
      ) : null}
      <div data-testid="searchCriteriaBar" className={classNames(s.wrapper)}>
        <Button
          buttonRef={openFiltersButtonRef}
          data-testid="openFiltersButton"
          className={classNames(s.openFiltersButton, s.saveSearchAndFilterButtons)}
          type="tertiary"
          size="small"
          icon="filters"
          onClick={() => setFilterPanelIsOpen(true)}
        >
          {`${t('mobileFilterButtonTitle')} `}
          {areNoFilterOptions ? '' : `(${options.length})`}
        </Button>
        {userLoggedIn ? (
          <div className={classNames(s.saveSearchAndFilterButtons)}>
            <SaveSearchButton
              criteria={criteria}
              canUpdate={canUpdate}
              currentSelectedSearch={selectedSavedSearch}
              onChange={onChange}
              fetchSavedSearches={fetchSavedSearches}
              disabled={areNoFilterOptions}
            />
          </div>
        ) : null}

        {createPortal(
          <ListingSearchFiltersPanel
            isOpen={filterPanelIsOpen}
            closePanel={() => {
              setFilterPanelIsOpen(false);
              openFiltersButtonRef?.current?.focus();
            }}
            removeAll={removeAll}
            activeFilters={options}
            onFilterPillClick={onFilterPillClick}
            panelSaveSearchSelector={
              <SaveSearchSelector
                name="SavedSearchSelector"
                savedSearches={savedSearches}
                value={activeSavedSearchId}
                onChange={onChange}
                isInSearchFilter
                currentSelectedSearch={selectedSavedSearch}
              />
            }
            showSavedSearchSelector={showSavedSearchSelector}
          />,
          document.body,
        )}
      </div>
    </div>
  );
};

export default connector(MobileSearchCriteriaBar);
