import { CloudinaryTransformations } from '@root/types';

type ZoomBounds = { min: number; max: number; current: number; step: number };

type GenerateCloudinaryTransformationsArgs = {
  cropBox: Cropper.CropBoxData;
  canvasData: Cropper.CanvasData;
  zoom: number;
};
export const generateCloudinaryTransformations = ({
  cropBox,
  canvasData,
  zoom,
}: GenerateCloudinaryTransformationsArgs): CloudinaryTransformations => ({
  width: Math.round(cropBox.width / zoom),
  height: Math.round(cropBox.height / zoom),
  x: Math.round((cropBox.left - canvasData.left) / zoom),
  y: Math.round((cropBox.top - canvasData.top) / zoom),
  crop: 'crop',
  fetchFormat: 'auto',
});

type GenerateZoomBoundsArgs = {
  minImageDimentions: Cropper.ContainerData;
  canvasData: Cropper.CanvasData;
  maxZoomAmount: number;
  numberOfZoomSteps: number;
  useCanvasDimentions?: boolean;
};
export const generateZoomBounds = ({
  minImageDimentions,
  canvasData,
  maxZoomAmount,
  numberOfZoomSteps,
  useCanvasDimentions = true,
}: GenerateZoomBoundsArgs): ZoomBounds => {
  const min = Math.max(
    minImageDimentions.width / canvasData.naturalWidth,
    minImageDimentions.height / canvasData.naturalHeight,
  );
  const max = min * maxZoomAmount;

  return {
    min,
    max,
    current: useCanvasDimentions
      ? Math.max(
          canvasData.width / canvasData.naturalWidth,
          canvasData.height / canvasData.naturalHeight,
        )
      : min,
    step: (max - min) / numberOfZoomSteps,
  };
};
