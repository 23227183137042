import api from '@shared/api';
import { SavedSearch } from '@root/types';
import routes from '@root/routes';

type ReceivedUpdatedSavedSearchAction = {
  type: 'RECEIVE_UPDATED_SAVED_SEARCH';
  payload: SavedSearch;
};

const receiveUpdatedSavedSearch = (savedSearch: SavedSearch): ReceivedUpdatedSavedSearchAction => ({
  type: 'RECEIVE_UPDATED_SAVED_SEARCH',
  payload: savedSearch,
});

const updateSavedSearch =
  (
    id: string,
    changes: { receivesNotifications?: boolean; name?: string | null },
    successCallback?: Function,
  ) =>
  async dispatch => {
    const response = await api.put(routes.api.savedSearch(id), changes);

    if (response.ok) {
      const savedSearch: SavedSearch = await response.json();
      dispatch(receiveUpdatedSavedSearch(savedSearch));
      if (successCallback) successCallback(response);
    }
  };

export default updateSavedSearch;

export type Actions = ReceivedUpdatedSavedSearchAction;
