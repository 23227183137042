import { FieldWrapper } from '@components/shared/forms';
import { UploadApiResponse as CloudinaryResponse } from 'cloudinary';
import { Notification } from '@components/shared';
import { useTranslation } from 'react-i18next';
import { CloudinaryImageParams, CloudinaryTransformations } from '@root/types';
import cn from 'classnames';
import AssetUploader from '@components/shared/EditableAsset/AssetUploader';
import EditableAsset from '@components/shared/EditableAsset/EditableAsset';
import useCloudinaryUploader from '@components/shared/Cloudinary/useCloudinaryUploader';
import useCloudinary from '@root/shared/useCloudinary';

export type Props = {
  img: CloudinaryImageParams | null;
  onChange: (img: CloudinaryImageParams | null) => void;
  labelText: string;
  name: string;
  alt: string;
  imageClassName?: string;
  transformations?: CloudinaryTransformations | CloudinaryTransformations[] | null;
  urlTransformations?: string;
  helpText?: string;
};

const DEFAULT_TRANSFORMATIONS = {
  fetchFormat: 'auto',
  effect: 'trim:15',
};
const DEFAULT_URL_TRANSFORMATIONS = 'f_auto/e_trim:15';

export default function ImageUploader({
  img,
  onChange,
  labelText,
  name,
  alt,
  imageClassName,
  transformations = DEFAULT_TRANSFORMATIONS,
  urlTransformations = DEFAULT_URL_TRANSFORMATIONS,
  helpText,
}: Props) {
  const { t } = useTranslation('admin');
  const cld = useCloudinary();
  const {
    selectAndUploadToCloudinary,
    CloudinaryUploaderFileInput,
    cloudinaryUploaderFileInputProps,
  } = useCloudinaryUploader({
    onError: () => Notification.error({ title: t('notification.generalError') }),
  });

  const getImagePath = (): string => {
    if (!img) return '';

    let image = cld.image(img.cloudinaryId);
    if (urlTransformations) {
      image = image.addTransformation(urlTransformations);
    }

    return image.toURL();
  };

  const getImageDownloadPath = (): string =>
    img ? cld.image(img.cloudinaryId).addFlag('attachment').toURL() : '';

  return (
    <>
      <CloudinaryUploaderFileInput {...cloudinaryUploaderFileInputProps} />
      <FieldWrapper name={name} labelText={labelText} containerClass="!mb-0">
        {img ? (
          <div className="flex flex-col">
            <EditableAsset
              imagePath={getImagePath()}
              imageDownloadPath={getImageDownloadPath()}
              imageClassName={cn(
                'h-[36px] rounded-[3px] !border !border-solid !border-black-020 object-contain',
                imageClassName,
              )}
              altText={alt}
              editButtonClassName="relative ml-1 top-[2px] border border-solid border-black-035"
              editButtonName={t('microsite.editImagePopover.title')}
              downloadButtonClassName="relative ml-1 top-[2px] border border-solid border-black-035"
              downloadButtonName={t('microsite.downloadImagePopover.title')}
              onUpload={() => {
                if (selectAndUploadToCloudinary)
                  selectAndUploadToCloudinary({
                    callback: async (response: CloudinaryResponse) =>
                      onChange({
                        cloudinaryId: response.public_id,
                        transformations,
                        cropperCanvasData: null,
                      }),
                  });
              }}
              items={['upload', 'delete']}
              onDelete={() => onChange(null)}
            />
          </div>
        ) : (
          <AssetUploader
            placeholderElementClassName="pl-1 text-black-055 font-body-medium"
            containerClassName="w-[257px] !h-[52px] !my-0 bg-white"
            testId={`${name}-uploader`}
            onError={() => {}}
            uploadHelpText={t('microsite.topNavLogo.uploadHelpText')}
            onReady={async (response: CloudinaryResponse) =>
              onChange({
                cloudinaryId: response.public_id,
                transformations,
                cropperCanvasData: null,
              })
            }
          />
        )}
        {!!helpText && <p className="pt-1 text-black-055 font-body-small">{helpText}</p>}
      </FieldWrapper>
    </>
  );
}
