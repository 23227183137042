import { useRef, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomIcon from '@components/shared/CustomIcon';
import { MIME_TYPES } from '@components/shared/Admin/MultimediaUploader';
import cn from 'classnames';
import { UploadApiResponse as CloudinaryResponse } from 'cloudinary';
import useAssetSelectorModal from '@components/shared/useAssetSelectorModal/useAssetSelectorModal';
import useCloudinaryUploader from './useCloudinaryUploader';

type Props = {
  placeholderElement?: ReactNode;
  containerClassName?: string;
  dataTestId?: string;
  onReady: (response: CloudinaryResponse) => void;
  onFileUpload?: (file: string) => void;
  onError: (message: string) => void;
  assetSelectorModalProps?: {
    title: string;
    alternativeAssetSelection: ReactNode;
  };
};

const CloudinaryUploader = ({
  placeholderElement,
  dataTestId,
  containerClassName,
  onReady,
  onFileUpload,
  onError,
  assetSelectorModalProps,
}: Props) => {
  const { t } = useTranslation();
  const { onFileSelect } = useCloudinaryUploader({
    uploadToVTS: false,
    onReady,
    onFileUpload,
    onError,
  });

  const {
    assetSelectorModal,
    shouldUploadNewAsset,
    closeAssetSelectorModal,
    openAssetSelectorModal,
  } = useAssetSelectorModal({
    title: '',
    alternativeAssetSelection: null,
    ...assetSelectorModalProps,
  });

  const handleClick = async (event: any) => {
    const { files } = event.target;

    onFileSelect(files[0]);
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (shouldUploadNewAsset) {
      fileInput?.current?.click();
    }
    closeAssetSelectorModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUploadNewAsset]);

  const handleClickUploader = () => {
    if (assetSelectorModalProps) {
      openAssetSelectorModal();
      return;
    }
    fileInput?.current?.click();
  };

  return (
    <>
      <div
        role="button"
        className={cn(
          'mb-8 mt-3 flex h-[200px] flex-col items-center justify-center rounded-md',
          'border-2 border-dashed border-black-035',
          'bg-black-003',
          containerClassName,
        )}
        onClick={handleClickUploader}
      >
        {placeholderElement || (
          <>
            <CustomIcon type="uploadOutlined" className="pb-2" />
            <div>{t('common.uploadPhotoHelpText')}</div>
          </>
        )}

        <input
          ref={fileInput}
          type="file"
          data-testid={dataTestId || 'file-input'}
          style={{
            position: 'absolute',
            width: 1,
            height: 1,
            top: -500,
          }}
          onChange={handleClick}
          accept={MIME_TYPES.imageOnly}
        />
      </div>
      {assetSelectorModal}
    </>
  );
};

export default CloudinaryUploader;
