import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch } from 'react-redux';
import routes from '@root/routes';
import api from '@shared/api';
import actions from '@store/actions/listingSearchPage';
import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import useMarket from '@root/shared/useMarket';
import useFilterInteraction from '@components/layouts/Truva/ListingSearch/utils/useFilterInteraction';
import { OptionType } from '../../FilterDropdown/types';
import AsyncFilterDropdown from '../../FilterDropdown/AsyncFilterDropdown';
import { useListingSearchCriteria } from '../../utils';
import { useFilterOptions } from '../../utils/FilterOptions/FilterOptionsProvider';

type MarketBuildingsResponse = ReadonlyArray<{
  id: string;
  displayName: string;
}>;

const DEBOUNCE_DURATION = 750;

const BuildingsFilter = () => {
  const { t } = useTranslation('filters');
  const dispatch = useDispatch();
  const criteria = useListingSearchCriteria();
  const { isOnNonPublicMarket } = useMarket();
  const { dropdownFilterInteraction } = useFilterInteraction();
  const flags = useFlags();

  const [storedOptions, dispatchFilterOptions] = useFilterOptions();
  const [defaultOptions, setDefaultOptions] = useState<OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDefaultOptions([]);
  }, [criteria.marketSlug]);

  const loadOptions = (inputValue: string, callback: Function) => {
    api.fetch(routes.api.marketBuildings(criteria.marketSlug, inputValue)).then(async response => {
      const rawSearchResults: MarketBuildingsResponse = await response.json();
      callback(
        rawSearchResults.map(
          (building): OptionType => ({
            label: building.displayName,
            value: building.id,
          }),
        ),
      );
    });
  };

  const debouncedLoadOptions = debounce(loadOptions, DEBOUNCE_DURATION);

  const loadDefaultOptions = () => {
    if (defaultOptions.length === 0) {
      setIsLoading(true);
      loadOptions('', response => {
        setDefaultOptions(response);
        setIsLoading(false);
      });
    }
  };

  const handleChange = currentValues => {
    const dispatchPayload = currentValues?.map(option => ({
      id: option.value,
      displayName: option.label,
    }));

    const isAdding = criteria.buildingIds().length < currentValues.length;
    const currentBuildingIds: string[] = currentValues?.map(option => option.value) || [];
    const buildingId = isAdding
      ? currentBuildingIds.find(id => !criteria.buildingIds().includes(id))
      : criteria.buildingIds().find(id => !currentBuildingIds.includes(id));

    criteria.add('buildingIds', currentBuildingIds);

    dispatchFilterOptions({
      type: 'ADD_BUILDING_OPTIONS',
      payload: dispatchPayload,
    });

    criteria.pushToHistory();

    if (flags['search-analytics-refactor']) {
      dropdownFilterInteraction({
        filterType: 'buildings',
        isAdding,
        value: buildingId!,
        currentFilters: criteria.toAnalyticsProperties(),
      });
    } else {
      dispatch(actions.buildingsFilterChanged(dispatchPayload));
    }
  };

  const values =
    criteria.currentFilters.buildingIds
      ?.map(buildingId => ({
        value: buildingId,
        label: storedOptions.buildings[buildingId]?.displayName,
      }))
      .sort((a, b) => (a.label || '').localeCompare(b.label)) || [];

  return (
    <div className="u-p-top-2x" data-testid="buildingsFilter">
      <AsyncFilterDropdown
        name="buildings"
        loadOptions={debouncedLoadOptions}
        onFocus={() => loadDefaultOptions()}
        defaultOptions={defaultOptions}
        isLoading={isLoading}
        value={values}
        onChange={handleChange}
        placeholder={t('buildings.placeholder')}
        disabled={isOnNonPublicMarket}
      />
    </div>
  );
};

export default BuildingsFilter;
