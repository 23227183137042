import Pptxgenjs from 'pptxgenjs';
import { MultipathImage, PowerpointBuildingEntry } from '@root/types';
import { Cloudinary } from 'cloudinary-core';
import { round } from 'lodash';
import {
  PptxUtils,
  SlideImage,
  PowerpointImageElement,
  staticImagesPathsInCloudinary,
} from './globals';
import { addElementsToSlide } from './util';
import { PAGE_MARGIN } from './portrait';
import { buildBuildingMapUrl } from './map';

const GUTTER_SIZE = 0.04;
const MEDIA_WIDTH = 6.86;
const MEDIA_HEIGHT = 2.8;
const BASE_X = PAGE_MARGIN;
const BASE_Y = 1.02;

const tiles =
  (vertical = true) =>
  (
    quantity = 1,
    startX = BASE_X,
    startY = BASE_Y,
    _width = MEDIA_WIDTH,
    _height = MEDIA_HEIGHT,
    gutter = GUTTER_SIZE,
  ) => {
    const width = (vertical ? _width : _width / 2) - gutter / 2;
    const height = (!vertical ? _height : _height / 2) - gutter / 2;
    return '.'
      .repeat(quantity)
      .split('')
      .map((_, i) => ({
        x: round(startX + ((vertical ? 0 : 1) * i * (_width + gutter)) / 2, 2),
        y: round(startY + ((vertical ? 1 : 0) * i * (_height + gutter)) / 2, 2),
        w: round(width - (!vertical ? gutter / 2 : 0), 2),
        h: round(height - (vertical ? gutter / 2 : 0), 2),
      }));
  };

const verticalTiles = tiles(true);
const horizontalTiles = tiles(false);

const layoutListing = (quantity = 1) => {
  switch (quantity) {
    case 1:
      return [
        {
          x: BASE_X,
          y: BASE_Y,
          w: MEDIA_WIDTH,
          h: MEDIA_HEIGHT,
        },
      ];

    case 2:
      return horizontalTiles(2);

    case 3:
    case 4:
      return [
        horizontalTiles(2, BASE_X, BASE_Y, (4 * MEDIA_WIDTH) / 3, MEDIA_HEIGHT)[0],
        ...verticalTiles(
          2,
          BASE_X + (2 * MEDIA_WIDTH) / 3 + GUTTER_SIZE,
          BASE_Y,
          MEDIA_WIDTH / 3 - GUTTER_SIZE / 2,
          MEDIA_HEIGHT,
        ),
      ];

    case 5:
      return [
        horizontalTiles(2)[0],
        ...verticalTiles(
          2,
          BASE_X + MEDIA_WIDTH / 2 + GUTTER_SIZE / 2,
          BASE_Y,
          MEDIA_WIDTH / 4 - GUTTER_SIZE / 2,
          MEDIA_HEIGHT - GUTTER_SIZE / 4,
        ),
        ...verticalTiles(
          2,
          BASE_X + (3 * MEDIA_WIDTH) / 4 + (3 * GUTTER_SIZE) / 4,
          BASE_Y,
          MEDIA_WIDTH / 4 - (3 * GUTTER_SIZE) / 4,
          MEDIA_HEIGHT - GUTTER_SIZE / 4,
        ),
      ];

    default:
      return [];
  }
};

const layoutBuilding = (quantity = 1) => {
  switch (quantity) {
    case 1:
      return [
        {
          x: BASE_X,
          y: BASE_Y,
          w: MEDIA_WIDTH,
          h: MEDIA_HEIGHT,
        },
      ];

    case 2:
      return [
        horizontalTiles(2, BASE_X, BASE_Y, (4 * MEDIA_WIDTH) / 3, MEDIA_HEIGHT)[0],
        horizontalTiles(
          2,
          BASE_X + (2 * MEDIA_WIDTH) / 3 + GUTTER_SIZE,
          BASE_Y,
          (2 * MEDIA_WIDTH) / 3 - GUTTER_SIZE / 2,
          MEDIA_HEIGHT,
        )[0],
      ];

    case 3:
      return [
        horizontalTiles(2, BASE_X, BASE_Y, (4 * MEDIA_WIDTH) / 3, MEDIA_HEIGHT)[0],
        ...verticalTiles(
          2,
          BASE_X + (2 * MEDIA_WIDTH) / 3 + GUTTER_SIZE,
          BASE_Y,
          MEDIA_WIDTH / 3 - GUTTER_SIZE / 2,
          MEDIA_HEIGHT,
        ),
      ];

    case 4:
      return [
        horizontalTiles(2, BASE_X, BASE_Y, MEDIA_WIDTH, MEDIA_HEIGHT)[0],
        horizontalTiles(
          2,
          BASE_X + (3 * MEDIA_WIDTH) / 4 + (3 * GUTTER_SIZE) / 4,
          BASE_Y,
          MEDIA_WIDTH / 2 - (3 * GUTTER_SIZE) / 4,
          MEDIA_HEIGHT - GUTTER_SIZE / 4,
        )[0],
        ...verticalTiles(
          2,
          BASE_X + MEDIA_WIDTH / 2 + GUTTER_SIZE / 2,
          BASE_Y,
          MEDIA_WIDTH / 4 - GUTTER_SIZE / 4,
          MEDIA_HEIGHT,
        ).reverse(),
      ];

    case 5:
      return [
        horizontalTiles(2)[0],
        ...verticalTiles(
          2,
          BASE_X + MEDIA_WIDTH / 2 + GUTTER_SIZE / 2,
          BASE_Y,
          MEDIA_WIDTH / 4 - GUTTER_SIZE / 2,
          MEDIA_HEIGHT - GUTTER_SIZE / 4,
        ),
        ...verticalTiles(
          2,
          BASE_X + (3 * MEDIA_WIDTH) / 4 + (3 * GUTTER_SIZE) / 4,
          BASE_Y,
          MEDIA_WIDTH / 4 - (3 * GUTTER_SIZE) / 4,
          MEDIA_HEIGHT - GUTTER_SIZE / 4,
        ),
      ];

    default:
      return [];
  }
};

const addOffset = (offset: number) => (coords: Omit<SlideImage, 'path'>) => ({
  ...coords,
  y: coords.y + offset,
});

const constructPath =
  (cloudinary: Cloudinary, photos: Array<MultipathImage>) =>
  (coords: Omit<SlideImage, 'path'>, index: number): Pptxgenjs.ImageProps & { path: string } => {
    const isStaticMap = photos[index].description === 'Static Map';
    const path = isStaticMap
      ? photos[index].path
      : cloudinary.url(photos[index].cloudinaryId!, {
          transformation: [
            { crop: 'fit', width: 1440, height: 1920 },
            { aspectRatio: (coords.w / coords.h).toPrecision(3), crop: 'crop' },
            {
              crop: 'limit',
              width: 720,
              height: 960,
            },
            { transformation: 'invisible_watermark' },
            { fetchFormat: 'jpg' },
          ],
        });
    return {
      ...coords,
      ...(isStaticMap ? { sizing: { type: 'cover', w: coords.w, h: coords.h } } : {}),
      path,
    };
  };

const addFirstToMarketLabel = (slide, yOffset = 0) => {
  slide.addImage({
    x: 0.42,
    y: 1.12 + yOffset,
    w: 1.18,
    h: 0.236,
    path: 'https://media-acceptance.truva.com/image/upload/v1624470091/nnj4c4mbbyk9x3bcyze2.png',
  });
};

const generateListingMosaicElements = (
  { cloudinary, googleMapsKey },
  images: Array<MultipathImage>,
  offset = 0,
  listingBuilding?: PowerpointBuildingEntry,
  isExclusive = false,
): PowerpointImageElement[] => {
  const photos = (images?.filter(photo => photo?.cloudinaryId?.length) || [])
    .filter(photo => photo?.description.toLowerCase() !== 'floor plan')
    .slice(0, 5);
  if (!photos.length && listingBuilding) {
    photos.push({
      description: 'Static Map',
      path: buildBuildingMapUrl(listingBuilding, googleMapsKey, MEDIA_HEIGHT, MEDIA_WIDTH),
    } as MultipathImage);
  }
  const elements: PowerpointImageElement[] = layoutListing(photos.length)
    .map(addOffset(offset))
    .map(constructPath(cloudinary, photos))
    .map(image => {
      const { h, w, x, y, path } = image;
      const { sizing } = image;
      const imageOptions = sizing ? { imageOptions: { sizing } } : {};
      return {
        type: 'image',
        data: path,
        placement: { h, w, x, y },
        ...imageOptions,
      };
    });

  if (isExclusive) {
    elements.push({
      type: 'image',
      data: staticImagesPathsInCloudinary.firstToMarket,
      placement: { x: 0.5, y: 1.53, w: 1.18, h: 0.236 },
    });
  }

  return elements;
};

const createListingMosaic = (
  utils: PptxUtils,
  slide: Pptxgenjs.Slide,
  images: Array<MultipathImage>,
  offset = 0,
  isExclusive = false,
  listingBuilding?: PowerpointBuildingEntry,
) => {
  addElementsToSlide(slide, generateListingMosaicElements(utils, images, offset, listingBuilding));
  if (isExclusive) addFirstToMarketLabel(slide, offset);
};

const generateBuildingMosaicElements = (
  { cloudinary },
  images: Array<MultipathImage>,
  offset = 0,
): PowerpointImageElement[] => {
  const photos = (images?.filter(photo => photo.path) || [])
    .filter(photo => photo?.description.toLowerCase() !== 'floor plan')
    .slice(0, 5);
  return layoutBuilding(photos.length)
    .map(addOffset(offset))
    .map(constructPath(cloudinary, photos))
    .map(image => {
      const { h, w, x, y, path } = image;
      return {
        type: 'image',
        data: path,
        placement: { h, w, x, y },
      };
    });
};

const createBuildingMosaic = (
  utils: PptxUtils,
  slide: Pptxgenjs.Slide,
  images: Array<MultipathImage>,
  offset = 0,
) => {
  if (images.length > 0) {
    addElementsToSlide(slide, generateBuildingMosaicElements(utils, images, offset));
  }
};

export {
  generateListingMosaicElements,
  createListingMosaic,
  generateBuildingMosaicElements,
  createBuildingMosaic,
};
