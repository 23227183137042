import { Listing, StoreState, PartialListing } from '@root/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBreakpoint } from '@shared/useBreakpoints';
import { useSelector } from 'react-redux';
import useAnalytics, { ListingPreviewSourcePageType } from '@shared/useAnalytics';
import { EVENTS, PARAMETERS } from '@root/tracking/constants';
import normalize from '@shared/normalize';
import useFetchTourbookSummaries from '@shared/useFetchTourbookSummaries';
import { Notification } from '@components/shared';
import { useTranslation } from 'react-i18next';
import ListingPreviewModal, { Props as ListingPreviewModalProps } from './ListingPreviewModal';
import useFetchListing from './useFetchListing/useFetchListing';

type Arguments = {
  partialListings: PartialListing[];
  sourcePage: ListingPreviewSourcePageType;
  isSaved: (listingId: string) => boolean;
};

type ReturnType = {
  openListingPreviewModal: (listingIndex: number) => void;
  ListingPreviewModal: typeof ListingPreviewModal;
  listingPreviewModalProps: ListingPreviewModalProps;
};

export default function useListingPreviewModal({
  partialListings,
  sourcePage,
  isSaved,
}: Arguments): ReturnType {
  const { t } = useTranslation();

  const { byId } = useSelector((state: StoreState) => ({
    byId: state.listings.byId,
  }));

  const [listingIndex, setListingIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const listingIndexesThatNeedToBeFetched = useRef<number[]>([]);
  const pendingAnalyticActions = useRef<Array<{ index: number; fireAction: (l: Listing) => void }>>(
    [],
  );

  const { isMobile } = useBreakpoint();
  useEffect(() => {
    if (isMobile) setIsModalOpen(false);
  }, [isMobile]);

  const listingsCount = partialListings.length;
  const listingIds = partialListings.map((listing: PartialListing) => listing.id);
  const listingId = listingIndex === null ? null : listingIds[listingIndex];
  const listing = listingId ? byId[listingId] : null;

  const partialListingsById = normalize(partialListings);
  const partialListing = listingId === null ? null : partialListingsById[listingId];

  const { data: tourbooks } = useFetchTourbookSummaries();

  const { listingPreviewAnalytics } = useAnalytics();

  const dispatchOpenListingPreviewAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'OPEN_LISTING_PREVIEW',
      listingPreviewAction: PARAMETERS.openListingPreview,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });

  const dispatchViewListingAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'VIEW_LISTING',
      listingPreviewAction: PARAMETERS.viewListing,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });
  const dispatchClosePreviewAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'CLOSE_LISTING_PREVIEW',
      listingPreviewAction: PARAMETERS.closeListingPreview,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });
  const dispatchPaginateListingPreviewAction = currentListing =>
    listingPreviewAnalytics({
      listingPreviewactionType: 'PAGINATE_LISTING',
      listingPreviewAction: PARAMETERS.paginate,
      listingPreviewEvent: EVENTS.listingPreviewInteraction,
      listing: currentListing,
      sourcePage,
    });

  const onError = useCallback(() => {
    Notification.error({
      title: t('common:genericFailureTitle'),
      text: t('common:genericFailureMessage'),
    });
  }, [t]);

  const cachePartialListingImage = (id: string) => {
    new Image().src = partialListingsById[id].photo.mediumPath;
  };

  const fetchListing = useFetchListing();

  const fetchListingByIndex = useCallback(
    (
      index: number,
      successCallback?: (listing: Listing) => void,
      errorCallback?: () => void,
    ): boolean => {
      const hasAlreadyBeenFetched = byId[listingIds[index]];
      if (hasAlreadyBeenFetched) return true;

      if (listingIds[index]) {
        fetchListing(listingIds[index], successCallback, errorCallback);
        return true;
      }

      listingIndexesThatNeedToBeFetched.current.push(index);
      return false;
    },
    [byId, listingIds, fetchListing],
  );

  useEffect(() => {
    listingIndexesThatNeedToBeFetched.current = listingIndexesThatNeedToBeFetched.current.filter(
      index => !fetchListingByIndex(index),
    );
  }, [fetchListingByIndex, listingIds]);

  const getListingFromIndex = useCallback(
    (index: number): Listing | null => {
      const currentListingId = listingIds[index];
      return currentListingId ? byId[currentListingId] : null;
    },
    [byId, listingIds],
  );

  const stringifiedPendingAnalyticsActions = pendingAnalyticActions.current.toString();

  useEffect(() => {
    pendingAnalyticActions.current = pendingAnalyticActions.current.filter(
      ({ index, fireAction }) => {
        const currentListing = getListingFromIndex(index);
        if (currentListing) {
          fireAction(currentListing);

          return false;
        }

        return true;
      },
    );
  }, [byId, getListingFromIndex, listingIds, stringifiedPendingAnalyticsActions]);

  const fireAnalyticsAction = (index: number, fireAction: (l: Listing) => void) => {
    const currentListing = getListingFromIndex(index);
    if (currentListing) {
      fireAction(currentListing);
    } else {
      pendingAnalyticActions.current.push({ index, fireAction });
    }
  };

  const fetchCurrentAndAdjacentListingsIfNeccesary = (index: number) => {
    fetchListingByIndex(index, undefined, onError);
    fetchListingByIndex(index + 1, ({ id }) => cachePartialListingImage(id));
    fetchListingByIndex(index - 1, ({ id }) => cachePartialListingImage(id));
  };

  const setListingIndexAndFetchListings = (index: number) => {
    setListingIndex(index);
    fetchCurrentAndAdjacentListingsIfNeccesary(index);
  };

  const goToPreviousListing = () => {
    if (listingIndex === null || listingIndex === 0) return;
    setListingIndexAndFetchListings(listingIndex - 1);
    fireAnalyticsAction(listingIndex, dispatchPaginateListingPreviewAction);
    fireAnalyticsAction(listingIndex - 1, dispatchViewListingAction);
  };

  const goToNextListing = () => {
    if (listingIndex === null || listingIndex >= listingsCount - 1) return;
    setListingIndexAndFetchListings(listingIndex + 1);
    fireAnalyticsAction(listingIndex, dispatchPaginateListingPreviewAction);
    fireAnalyticsAction(listingIndex + 1, dispatchViewListingAction);
  };

  const openListingPreviewModal = (listingIndexInPage: number) => {
    const newListingIndex = listingIndexInPage;
    setListingIndexAndFetchListings(newListingIndex);
    fireAnalyticsAction(newListingIndex, dispatchOpenListingPreviewAction);
    fireAnalyticsAction(newListingIndex, dispatchViewListingAction);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    if (listingIndex !== null) {
      fireAnalyticsAction(listingIndex, dispatchClosePreviewAction);
    }

    setIsModalOpen(false);
    listingIndexesThatNeedToBeFetched.current = [];
  };

  return {
    openListingPreviewModal,
    ListingPreviewModal,
    listingPreviewModalProps: {
      isOpen: isModalOpen,
      closeModal,
      afterCloseModal: () => setListingIndex(null),
      goToPreviousListing,
      goToNextListing,
      listingIndex,
      partialListing,
      listing,
      tourbooks,
      listingsCount,
      isSaved: listingId ? isSaved(listingId) : false,
      sourcePage,
    },
  };
}
