import { useEffect, useState } from 'react';
import { StoreState } from '@root/types';
import { getTourbookById } from '@store/selectors';
import { connect, ConnectedProps } from 'react-redux';
import tourbooksActions from '@store/actions/tourbooks';
import actions from '@store/actions/tourbookPage';
import cn from 'classnames';
import darkContentCss from '@styles/darkContentSection.module.less';
import contentWidth from '@styles/contentWidth.module.less';
import useCurrentUser from '@root/shared/useCurrentUser';
import Layout from '../Layout';
import LayoutContent from './LayoutContent';
import NotFound from '../404/NotFound';
import TourbookSkeleton from './TourbookSkeleton';
import tourbookStyles from './Tourbook.module.less';

const mapState = (state: StoreState, ownProps) => ({
  tourbook: getTourbookById(state, ownProps.match.params.tourbookId),
  tourbookId: ownProps.match.params.tourbookId,
});

const mapDispatch = {
  fetchTourbook: (id: string) => tourbooksActions.fetchTourbook(id),
  tourbookPageLoad: (id: string) => actions.tourbookPageLoad(id),
};

const connector = connect(mapState, mapDispatch);
type ReduxProps = ConnectedProps<typeof connector>;

export const RawTourbook = ({
  tourbook,
  fetchTourbook,
  tourbookId,
  tourbookPageLoad,
}: ReduxProps) => {
  const [loading, setLoading] = useState(true);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const { currentUser } = useCurrentUser();
  const fetchCurrentTourbook = () => fetchTourbook(tourbookId);
  useEffect(() => {
    setLoading(true);
    tourbookPageLoad(tourbookId).then(() => {
      setLoading(false);
    });
  }, [tourbookId, tourbookPageLoad]);

  if (!loading && !(tourbook && currentUser)) return <NotFound />;

  return (
    <Layout
      activePage="tourbookShow"
      containerClassName={cn(
        tourbookStyles.tourbookShow,
        contentWidth.contentContainer,
        contentWidth.contentPadding,
        darkContentCss.darkContentSection,
        isDuplicating && tourbookStyles.blurFilter,
      )}
    >
      <>
        <TourbookSkeleton visible={loading} />
        {!loading && tourbook && currentUser ? (
          <LayoutContent
            tourbook={tourbook}
            fetchTourbook={fetchCurrentTourbook}
            readOnly={currentUser.uid !== tourbook.owner.vtsId}
            isDuplicating={isDuplicating}
            setIsDuplicating={setIsDuplicating}
          />
        ) : null}
      </>
    </Layout>
  );
};

export default connector(RawTourbook);
