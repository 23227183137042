import { createContext, useContext } from 'react';
import * as React from 'react';

export type FormThemes = 'vertical' | 'horizontal' | 'admin';

const FormThemeContext = createContext<FormThemes | undefined>(undefined);
type FormThemeContextProps = {
  children: React.ReactNode;
  theme: FormThemes;
};
export const FormThemeContextProvider = ({ children, theme }: FormThemeContextProps) => (
  <FormThemeContext.Provider value={theme}>{children}</FormThemeContext.Provider>
);

export const useFormTheme = (): FormThemes => {
  const context = useContext(FormThemeContext);
  if (context === undefined) {
    throw new Error('useFormTheme must be used within a FormThemeContextProvider');
  }
  return context;
};
