import { createRef, useState, useRef, useEffect } from 'react';
import { Carousel, Tooltip } from 'antd';
import { PARAMETERS } from '@root/tracking/constants';
import useAnalytics from '@shared/useAnalytics';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Thumbnail } from '@store/actions/listingPage';
import mediaConstants from '@shared/mediaConstants';
import ExclusiveLabel from '@components/shared/ExclusiveLabel';
import EmptyImagePlaceholder from '@components/shared/images/empty-image-placeholder.svg';
import { useLightbox, FullWidthLightBox, CustomIcon } from '@components/shared';
import type { Address, MultipathImage, Video } from '@root/types';
import useAltText from '@root/shared/useAltText';
import Thumbnails from './Thumbnails';
import s from './Gallery.module.less';

type GalleryProps = {
  photos: Array<MultipathImage>;
  isCurrentlyExclusive: boolean;
  lightboxOpened: () => void;
  galleryPaginationClicked: () => void;
  galleryThumbnailClicked: (thumbnailType: keyof Thumbnail) => void;
  video: Video | null;
  tourEmbedUrl: string | null;
  imagesZipUrl: string | null;
  onLightboxOpened?: () => void;
  onLightboxClosed?: () => void;
  address: Address;
};

const srcSetFor = photo =>
  `${photo.largePath} 1500w, ${photo.mediumPath} 900w, ${photo.smallPath} 400w`;

const Gallery = ({
  photos,
  isCurrentlyExclusive,
  lightboxOpened,
  galleryPaginationClicked,
  galleryThumbnailClicked,
  video = null,
  tourEmbedUrl = null,
  imagesZipUrl,
  onLightboxOpened,
  onLightboxClosed,
  address,
}: GalleryProps) => {
  const { t } = useTranslation('listing');
  const { galleryInteraction } = useAnalytics();
  const { getAltText } = useAltText({ address });

  const [activeRawPhotoUrl, setActiveRawPhotoUrl] = useState('');
  const [duringSsr, setDuringSsr] = useState(true);

  // all the state for the full-width lightbox
  const {
    lightboxOpen,
    currentIndex,
    activeTab,
    visibleElementOnTours,
    setLightboxOpen,
    setCurrentIndex,
    setActiveTab,
    setVisibleElementOnTours,
    closeLightbox,
  } = useLightbox({
    hasVideo: !!video,
    has3dTour: !!tourEmbedUrl,
    onOpen: onLightboxOpened,
    onClose: onLightboxClosed,
  });

  const ref = createRef<HTMLDivElement>();
  const carousel = useRef<Carousel>(null);
  const floorPlanIndex = photos.findIndex(photo => photo.type === 'floorPlan');
  const staticMapIndex = photos.findIndex(photo => photo.type === 'staticMap');
  const isOnStaticMap = staticMapIndex === currentIndex;

  /* START Handlers for the Gallery thumbnails */
  const handleGoToPrev = e => {
    galleryPaginationClicked();

    e.preventDefault();
    e.stopPropagation();

    const carouselNode = carousel.current;
    if (carouselNode) {
      carouselNode.prev();
    }
  };

  const handleGoToNext = e => {
    galleryPaginationClicked();

    e.preventDefault();
    e.stopPropagation();

    const carouselNode = carousel.current;
    if (carouselNode) {
      carouselNode.next();
    }
  };

  const handleGoToIndex = (index, dontAnimate = false) => {
    const carouselNode = carousel.current;
    if (carouselNode) {
      carouselNode.goTo(index, dontAnimate);
    }
  };

  const handleThumbnailClick = (index, thumbnailType) => {
    handleGoToIndex(index);
    galleryThumbnailClicked(thumbnailType);
  };

  const handleKeyDown = event => {
    if (lightboxOpen) return;

    if (event.target.tagName !== 'BODY' && ref.current && !ref.current.contains(event.target)) {
      return;
    }

    if (event.key === 'ArrowLeft') handleGoToPrev(event);
    if (event.key === 'ArrowRight') handleGoToNext(event);
  };
  /* END Handlers for the Gallery thumbnails */

  /* START Handlers for the lightbox */
  const openLightbox = () => {
    if (!lightboxOpen) {
      setLightboxOpen(true);
    }
  };

  const handleLightboxClose = () => {
    setTimeout(closeLightbox, 250);
  };
  /* END Handlers for the lightbox */

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    handleGoToIndex(currentIndex, false);
  }, [currentIndex]);

  useEffect(() => {
    setDuringSsr(false);
  }, []);
  useEffect(() => {
    setActiveRawPhotoUrl(photos.length > 1 ? photos[currentIndex].downloadPath : '');
    // FIXME: Either add the exhaustive deps or delete this line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  if (photos.length < 1) {
    return (
      <div className={s.carouselContainer}>
        <div className={s.imageContainer}>
          <div className={s.image}>
            <EmptyImagePlaceholder
              preserveAspectRatio="none"
              width="100%"
              height="100%"
              data-testid="emptyImagePlaceholder"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div ref={ref}>
      <div
        className={s.carouselContainer}
        onClick={() => {
          openLightbox();
          if (!lightboxOpen) {
            lightboxOpened();
          }
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        data-testid="gallery"
      >
        {isCurrentlyExclusive && <ExclusiveLabel size="large" />}
        {photos.length > 1 ? (
          <>
            <button
              className="absolute left-2 top-1/2 z-[2] h-4 w-4 rounded-full bg-background-primary/50 hover:bg-background-primary"
              type="button"
              onClick={handleGoToPrev}
              data-testid="previousPhotoBtn"
            >
              <CustomIcon className="pr-[2px]" type="chevron-left" />
            </button>
            <button
              className="absolute right-2 top-1/2 z-[2] h-4 w-4 rounded-full bg-background-primary/50 hover:bg-background-primary"
              type="button"
              onClick={handleGoToNext}
              data-testid="nextPhotoBtn"
            >
              <CustomIcon className="pl-[1px]" type="chevron-right" />
            </button>
          </>
        ) : null}

        {activeRawPhotoUrl && (
          <Tooltip
            overlayClassName={cn(s.tooltip, isOnStaticMap && 'hidden')}
            trigger="hover"
            placement="top"
            title={t('gallery.downloadImageTooltip')}
          >
            <div className={s.downloadImageContainer}>
              <a
                href={activeRawPhotoUrl}
                className={cn(s.downloadImageBtn, isOnStaticMap && 'disabled')}
                aria-label="download image"
                data-qa-testid="listing-image-download"
                onClick={e => {
                  e.stopPropagation();

                  if (isOnStaticMap) {
                    e.preventDefault();
                    return;
                  }

                  galleryInteraction({
                    actionType: 'DOWNLOAD_IMAGE',
                    action:
                      currentIndex === floorPlanIndex
                        ? PARAMETERS.downloadFloorPlan
                        : PARAMETERS.downloadImage,
                    sourcePage: PARAMETERS.listingPage,
                    sourceContent: PARAMETERS.listingGallery,
                  });
                }}
              >
                <CustomIcon type="download2" className={s.downloadImageIcon} />
              </a>
            </div>
          </Tooltip>
        )}

        <Carousel
          dots={false}
          ref={carousel}
          afterChange={index => setCurrentIndex(index)}
          className={duringSsr ? s.duringSsr : ''}
          initialSlide={currentIndex}
        >
          {photos.map(photo => (
            <div key={photo.path}>
              <div className={s.imageContainer}>
                <img
                  className={s.image}
                  alt={getAltText({ type: photo.type, options: { roomName: photo.description } })}
                  src={photo.largePath}
                  /* eslint-disable-next-line react/jsx-no-literals */
                  sizes={`(min-width: ${mediaConstants['screen-md-min']}) 66.6vw, 100vw`}
                  srcSet={srcSetFor(photo)}
                />
              </div>
            </div>
          ))}
        </Carousel>
        <p className={s.photoCount}>
          {t('gallery.photoCount', { currentIndex: currentIndex + 1, photosLength: photos.length })}
        </p>
        <div className={s.viewAllDynamicPlacement}>
          <button className={s.viewAllBtn} data-qa-testid="listing-view-all-images" type="button">
            {t('gallery.viewAll')}
          </button>
        </div>
        {lightboxOpen && (
          <FullWidthLightBox
            address={address}
            assets={photos}
            currentIndex={currentIndex}
            imagesZipUrl={imagesZipUrl}
            onClose={handleLightboxClose}
            onChange={setCurrentIndex}
            video={video}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tourEmbedUrl={tourEmbedUrl}
            visibleElementOnTours={visibleElementOnTours}
            setVisibleElementOnTours={setVisibleElementOnTours}
            sourcePage={PARAMETERS.listingPage}
          />
        )}
      </div>
      <Thumbnails
        address={address}
        photos={photos}
        currentIndex={currentIndex}
        onChange={handleThumbnailClick}
        video={video}
        tourEmbedUrl={tourEmbedUrl}
        handleTourClick={(mediaType: 'video' | 'tourEmbed') => {
          setVisibleElementOnTours(mediaType);
          setActiveTab('virtualTours');
          openLightbox();
        }}
      />
    </div>
  );
};

export default Gallery;
