import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CertificationLevel } from '@root/types';
import cn from 'classnames';
import s from './Certifications.module.less';

const CERTIFICATION_LEVELS = ['certified', 'silver', 'gold', 'platinum'];

type Props = {
  icon: ReactNode;
  headerText: string;
  level: CertificationLevel | null;
  children?: ReactNode;
  whyDoesItMatterText: string;
  explanationText: string;
};

const CertificationBox = ({
  icon,
  headerText,
  level = null,
  children = null,
  whyDoesItMatterText,
  explanationText,
}: Props) => {
  const { t } = useTranslation('building');

  if (!level) return null;
  return (
    <div className={s.certificationContainer}>
      <div className={s.headerRow}>
        <div className={s.labelAndPills}>
          <h4 className={s.certificationLabel}>{headerText}</h4>
          <ul className={s.scalePills}>
            {CERTIFICATION_LEVELS.map(certificationLevel => (
              <li
                key={certificationLevel}
                className={cn(s.pill, level === certificationLevel && s.selectedPill)}
              >
                {t(`certifications.${certificationLevel}`)}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {children}
      <div className={cn(s.row, s.certCard)}>
        {icon}
        <section>
          <strong>{whyDoesItMatterText}</strong>
          <p>{explanationText}</p>
        </section>
      </div>
    </div>
  );
};

export default CertificationBox;
