import * as React from 'react';
import { Form as SharedForm } from '@components/shared/forms';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SchemaOf } from 'yup';
import { FormValues, defaultValidationSchema } from '.';
import { defaultFormValues } from './Form';

type Props = {
  children: React.ReactNode;
  initialValues?: Partial<FormValuesUs>;
  onSubmit: (values: FormValuesUs) => void;
};

export type FormValuesUs = FormValues;

const validationSchema = (t: TFunction): SchemaOf<FormValuesUs> => defaultValidationSchema(t);

export default function FormUs({ children, onSubmit, initialValues = {} }: Props) {
  const { t } = useTranslation('tourbook');

  return (
    <SharedForm<FormValuesUs>
      initialValues={defaultFormValues({ initialValues, countryCode: 'US' })}
      validationSchema={validationSchema(t)}
      id="createExternalTourbookListingForm"
      onSubmit={onSubmit}
    >
      {children}
    </SharedForm>
  );
}
