import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Typography from '@styles/typography.module.less';
import Divider from '@components/shared/Divider';
import { Select, NumberInput, Checkbox } from '@components/shared/forms';
import css from './Furniture.module.less';

const Furniture = React.forwardRef((_props, ref: React.ForwardedRef<HTMLHeadingElement>) => {
  const { t } = useTranslation('admin');

  const deskStyleTypeOptions = [
    {
      label: t('listing.rooms.form.furniture.desks.deskStyleType.industrialDesk'),
      value: 'industrial_desk',
    },
    {
      label: t('listing.rooms.form.furniture.desks.deskStyleType.modernDesk'),
      value: 'modern_desk',
    },
    {
      label: t('listing.rooms.form.furniture.desks.deskStyleType.noDeskStyle'),
      value: 'no_desk_style',
    },
    {
      label: t('listing.rooms.form.furniture.desks.deskStyleType.traditionalDesk'),
      value: 'traditional_desk',
    },
  ];

  const tableStyleTypeOptions = [
    {
      label: t('listing.rooms.form.furniture.tables.tableStyleType.industrialTable'),
      value: 'industrial_table',
    },
    {
      label: t('listing.rooms.form.furniture.tables.tableStyleType.modernTable'),
      value: 'modern_table',
    },
    {
      label: t('listing.rooms.form.furniture.tables.tableStyleType.noTableStyle'),
      value: 'no_table_style',
    },
    {
      label: t('listing.rooms.form.furniture.tables.tableStyleType.traditionalTable'),
      value: 'traditional_table',
    },
  ];

  const tableShapeTypeOptions = [
    {
      label: t('listing.rooms.form.furniture.tables.tableShapeType.circularTable'),
      value: 'circular_table',
    },
    {
      label: t('listing.rooms.form.furniture.tables.tableShapeType.otherTableShape'),
      value: 'other_table_shape',
    },
    {
      label: t('listing.rooms.form.furniture.tables.tableShapeType.ovalTable'),
      value: 'oval_table',
    },
    {
      label: t('listing.rooms.form.furniture.tables.tableShapeType.rectangularTable'),
      value: 'rectangular_table',
    },
    {
      label: t('listing.rooms.form.furniture.tables.tableShapeType.squareTable'),
      value: 'square_table',
    },
  ];

  return (
    <>
      <h2 ref={ref} className={classNames(Typography.subtitle, 'u-p-top-4x', 'u-p-bottom-2x')}>
        {t('listing.rooms.form.furniture.title')}
      </h2>
      <h3 className={classNames(Typography.bodyLargeEmphasis, 'u-p-bottom-2x')}>
        {t('listing.rooms.form.furniture.desks.title')}
      </h3>
      <section className={css.deskSection}>
        <div className={css.deskCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.desks.deskCount')}
            name="deskCount"
          />
        </div>
        <div className={css.deskStyleType}>
          <Select
            labelText={t(`listing.rooms.form.furniture.desks.deskStyleType.label`)}
            name="deskStyle"
            options={deskStyleTypeOptions}
            placeholder=""
          />
        </div>
        <div className={css.deskStorage}>
          <Checkbox name="deskStorage">
            <span>{t('listing.rooms.form.furniture.desks.deskStorage')}</span>
          </Checkbox>
        </div>
        <div className={css.deskElectric}>
          <Checkbox name="deskElectric">
            <span>{t('listing.rooms.form.furniture.desks.deskElectric')}</span>
          </Checkbox>
        </div>
        <div className={css.deskWired}>
          <Checkbox name="deskWired">
            <span>{t('listing.rooms.form.furniture.desks.deskWired')}</span>
          </Checkbox>
        </div>
        <div className={css.electricAdjustableDeskCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.desks.electricAdjustableDeskCount')}
            name="electricAdjustableDeskCount"
          />
        </div>
        <div className={css.deskChairCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.desks.deskChairCount')}
            name="deskChairCount"
          />
        </div>
      </section>
      <h3 className={classNames(Typography.bodyLargeEmphasis, 'u-p-vert-2x')}>
        {t('listing.rooms.form.furniture.tables.title')}
      </h3>
      <section className={css.tableSection}>
        <div className={css.tableCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.tables.tableCount')}
            name="tableCount"
          />
        </div>
        <div className={css.tableStyleType}>
          <Select
            labelText={t(`listing.rooms.form.furniture.tables.tableStyleType.label`)}
            name="tableStyle"
            options={tableStyleTypeOptions}
            placeholder=""
          />
        </div>
        <div className={css.tableShapeType}>
          <Select
            labelText={t(`listing.rooms.form.furniture.tables.tableShapeType.label`)}
            name="tableShape"
            options={tableShapeTypeOptions}
            placeholder=""
          />
        </div>
        <div className={css.tableElectric}>
          <Checkbox name="tableElectric">
            <span>{t('listing.rooms.form.furniture.tables.tableElectric')}</span>
          </Checkbox>
        </div>
        <div className={css.tableWired}>
          <Checkbox name="tableWired">
            <span>{t('listing.rooms.form.furniture.tables.tableWired')}</span>
          </Checkbox>
        </div>
        <div className={css.conferenceTableCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.tables.conferenceTableCount')}
            name="conferenceTableCount"
          />
        </div>
      </section>
      <h3 className={classNames(Typography.bodyLargeEmphasis, 'u-p-vert-2x')}>
        {t('listing.rooms.form.furniture.seating.title')}
      </h3>
      <section className={css.seatingSection}>
        <div className={css.couchesCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.seating.couchesCount')}
            name="couchesCount"
          />
        </div>
        <div className={css.loungeChairCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.seating.loungeChairCount')}
            name="loungeChairCount"
          />
        </div>
        <div className={css.stoolCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.seating.stoolCount')}
            name="stoolCount"
          />
        </div>
        <div className={css.workbenchCount}>
          <NumberInput
            fast
            labelText={t('listing.rooms.form.furniture.seating.workbenchCount')}
            name="workbenchCount"
          />
        </div>
      </section>
      <Divider />
    </>
  );
});

export default React.memo(Furniture);
