import { Route, Switch } from 'react-router-dom';
import routes from '@root/routes';
import IndexPage from './IndexPage';
import EditPage from './EditPage';
import NewPage from './NewPage';

const Landlords = () => (
  <Switch>
    <Route exact path={routes.admin.landlords} component={IndexPage} />
    <Route path={routes.admin.landlordCreate} component={NewPage} />
    <Route path={routes.admin.landlordEdit(':id')} component={EditPage} />
  </Switch>
);

export default Landlords;
