import tourbookActions from '@store/actions/tourbooks';
import actions from '..';
import savedActions from '.';

const loadSavedListingsPage = () => async dispatch => {
  await dispatch(actions.setSavedListingsPage());
  dispatch(savedActions.fetchSavedListings());
  dispatch(tourbookActions.fetchTourbookSummaries());
  dispatch(savedActions.savedListingsPageLoaded());
};

export default loadSavedListingsPage;
