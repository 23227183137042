import { Button } from '@components/shared';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import s from './AddToSavedItemsButton.module.less';

type Props = {
  isSaved: boolean;
  onClick: (willBeSaved: boolean) => void | Promise<void>;
  className?: string;
  textClassName?: string;
  'data-testid'?: string;
};
const AddToSavedItemsButton = ({ isSaved, onClick, className, textClassName, ...props }: Props) => {
  const { t } = useTranslation('listing');

  return (
    <Button
      className={cn(className, isSaved && s.isSaved)}
      type="tertiary"
      icon={isSaved ? 'heart' : 'heart-outline'}
      size="small"
      onClick={() => onClick(!isSaved)}
      // eslint-disable-next-line react/destructuring-assignment
      data-testid={props['data-testid']}
      iconClass={cn(isSaved && '!text-text-link')}
      labelQaTestId="save-listing"
    >
      <span className={textClassName}>{isSaved ? t('common:saved') : t('common:save')}</span>
    </Button>
  );
};

export default AddToSavedItemsButton;
