import { CustomIcon } from '@components/shared';
import { Trans, useTranslation } from 'react-i18next';

const CTALink = ({
  translationKey,
  href,
  onClick,
  imageSrc,
}: {
  translationKey: string;
  href: string;
  onClick: () => void;
  imageSrc: string;
}) => {
  const { t } = useTranslation('homepage');

  return (
    <li className="relative flex flex-col">
      <h2 className="pb-4 text-2xl font-bold">
        {t(`featuresAndValueProps.${translationKey}.title`)}
      </h2>
      <figure className="mb-2 drop-shadow-lg">
        <img src={imageSrc} alt={t(`featuresAndValueProps.${translationKey}.altText`)} />
      </figure>
      <p className="h-10">
        <Trans
          ns="homepage"
          i18nKey={`featuresAndValueProps.${translationKey}.description`}
          components={{ noWrap: <span /> }}
        />
      </p>
      <a className="flex items-baseline" href={href} onClick={onClick}>
        {t('featuresAndValueProps.cta')}
        <CustomIcon className="pl-1" type="chevron-right" />
      </a>
    </li>
  );
};

const Triptych = ({ onClickCta }) => {
  return (
    <ul className="mb-4 flex gap-4">
      <CTALink
        imageSrc="https://media.truva.com/t_medium_image/assets/homepage-photos/first-to-market"
        translationKey="firstToMarketListings"
        href="https://www.vts.com/first-to-market-listings"
        onClick={onClickCta}
      />
      <CTALink
        imageSrc="https://media.truva.com/t_medium_image/assets/homepage-photos/tourbook-02"
        href="https://www.vts.com/canvassing"
        translationKey="virtualTourbook"
        onClick={onClickCta}
      />
      <CTALink
        imageSrc="https://media.truva.com/t_medium_image/assets/homepage-photos/sublease"
        href="https://www.vts.com/sublease"
        translationKey="subleases"
        onClick={onClickCta}
      />
    </ul>
  );
};

export default Triptych;
