import cn from 'classnames';
import { IconButton } from '@components/shared';
import s from '../FullWidthLightbox.module.less';

type ArrowProps = {
  onClick?: () => void;
  hide?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  analyticsEvent: () => void;
  overrideClassName?: string;
};

// The titles of these IconButtons are important and are used explicitly in FullWidthLightbox.tsx
// Do not change one without changing the other!

export const PreviousArrow = ({
  onClick = () => null,
  hide,
  onMouseOver = () => null,
  onMouseLeave = () => null,
  analyticsEvent,
  overrideClassName,
}: ArrowProps) => {
  return (
    <IconButton
      icon="chevron-left"
      title="previous-image"
      onClick={() => {
        onClick();
        analyticsEvent();
      }}
      className={cn([overrideClassName || s.arrow, s.previousArrow, hide && s.hide])}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
};

export const NextArrow = ({
  onClick = () => null,
  hide,
  onMouseOver = () => null,
  onMouseLeave = () => null,
  analyticsEvent,
  overrideClassName,
}: ArrowProps) => {
  return (
    <IconButton
      icon="chevron-right"
      title="next-image"
      onClick={() => {
        onClick();
        analyticsEvent();
      }}
      className={cn([overrideClassName || s.arrow, s.nextArrow, hide && s.hide])}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
};
