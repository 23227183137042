import { useState } from 'react';
import classNames from 'classnames';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';
import s from './InputRange.module.less';

type InputProps = {
  onBlur: () => void;
  onFocus: () => void;
};

export type Props = {
  renderFirstInput: (inputProps: InputProps) => React.ReactNode;
  renderSecondInput: (inputProps: InputProps) => React.ReactNode;
  errorMessage?: string;
  dividerClass?: string;
  containerClass?: string;
} & Omit<FieldWrapperProps, 'children'>;

const InputRange = ({
  renderFirstInput,
  renderSecondInput,
  errorMessage,
  dividerClass,
  containerClass,
  name,
  labelText,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputProps: InputProps = {
    onBlur: () => setIsFocused(false),
    onFocus: () => setIsFocused(true),
  };
  return (
    <FieldWrapper name={name} labelText={labelText}>
      <div
        className={classNames(
          s.inputRange,
          containerClass,
          errorMessage && s.error,
          isFocused && s.focused,
        )}
      >
        {renderFirstInput({ ...inputProps })}
        <span className={classNames(s.divider, dividerClass)}>
          {/* eslint-disable-line react/jsx-no-literals */ '-'}
        </span>
        {renderSecondInput({ ...inputProps })}
      </div>
      {!!errorMessage && <div className={s.errorMessage}>{errorMessage}</div>}
    </FieldWrapper>
  );
};

export default InputRange;
