import type {
  MultipathImage,
  Area,
  Video,
  NearbyStop,
  CountryCode,
  BuildingAmenities,
  AgentContact,
  BuildingStatus,
  MediaStatus,
  DateString,
  Brochure,
  MinMaxArea,
  LengthUnit,
  ConvertableMoneyPerAreaPerPeriod,
  ConvertableMoneyPerPeriod,
  ConvertableArea,
  ConvertableLength,
  VirtualTourSpotlights,
  Currency,
  Address,
  Units,
  MarketSlug,
} from '.';

export const LISTING_STATUSES = [
  'inactive',
  'requested',
  'onboarding',
  'ready_for_approval',
  'on_market',
  'off_market',
  'retired',
] as const;
export type ListingStatus = (typeof LISTING_STATUSES)[number];

export const SUITE_NAME_TYPES = ['room', 'suite', 'unit', 'building', 'space', ''] as const;
export type SuiteType = (typeof SUITE_NAME_TYPES)[number];

export const LEASE_TYPES = ['direct', 'flex', 'sublease'] as const;
export type LeaseType = (typeof LEASE_TYPES)[number];

export type Possession = 'now' | 'threeMonths' | 'sixMonths' | 'oneYear' | string;

export type SpaceProvider = {
  id?: string;
  name: string | null;
  logo: MultipathImage | null;
  slug: string | null;
};

export type Rent = {
  month: number;
  magnitude: number;
  period: 'month' | 'year';
  area: 'SquareFoot' | 'SquareMeter' | 'SquareFeet' | 'Square Feet'; // TODO LV - DELETE SQUARE FEET!
  currency: Currency;
};

export type ListingOnlyAmenities = {
  expandable: boolean;
  breakOutSpace: boolean | null;
  furnished: 'Yes' | 'No' | 'Partial' | null;
  keyCardSpaceEntry: boolean | null;
  phoneBooths: boolean | null;
  privateOutdoorSpace: boolean | null;
  sharedPantry: boolean | null;
  // Below are shared amenities
  passengerElevatorCount: number | null;
  serviceElevatorCount: number | null;
};

// These should match AR::ListingAmenitySet::AMENITIES
export type ListingAmenityValue =
  | 'break_out_space'
  | 'furnished'
  | 'key_card_space_entry'
  | 'phone_booths'
  | 'private_outdoor_space'
  | 'shared_pantry'
  // Below are shared amenities between listing and building amenity sets
  | 'passenger_elevator_count'
  | 'service_elevator_count';

export type ListingAmenities = BuildingAmenities & ListingOnlyAmenities;
export type AllAmenities = ListingAmenities;
export type AmenityKey = keyof AllAmenities;

export type AvailabilityType =
  | 'immediate'
  | 'not available'
  | 'arranged'
  | 'specified'
  | 'month to month'
  | 'available';

export const US_SPACE_CONDITIONS = [
  '2nd generation',
  'challenged',
  'core and shell',
  'floor and shell',
  'pre-built',
  'pre-existing',
  'raw',
  'turnkey',
  'under construction',
  'white box',
] as const;

// These are sorted alphabetically by translated name
export const GB_SPACE_CONDITIONS = [
  '2nd generation',
  'category a+',
  'challenged',
  'core and shell',
  'floor and shell',
  'pre-built',
  'pre-existing',
  'raw',
  'turnkey',
  'under construction',
  'white box',
] as const;

export type SpaceCondition =
  | (typeof US_SPACE_CONDITIONS)[number]
  | (typeof GB_SPACE_CONDITIONS)[number];

export type AmenityType =
  | 'Bike Storage Available'
  | 'Lobby Attendant'
  | 'Communal Outdoor Space'
  | '24/7 Access'
  | 'Gym Available';

export type BuildingInListing = {
  id: string;
  description: string | null;
  employerId: number | null;
  formattedAddress: string;
  htmlDescription: string | null;
  isDemo: boolean;
  latitude: number | null;
  longitude: number | null;
  lettermarkLogo: MultipathImage | null;
  neighborhood: string | null;
  notableTenants: string[];
  numberOfFloors: number | null;
  owningCompany: string | null;
  photo: MultipathImage | null;
  rentableArea: Area | null;
  slug: string;
  transitOptions: NearbyStop[];
  video: Video | null;
  yearBuilt: number | null;
  yearRenovated: number | null;

  // industrial attributes, may be temporary
  isIndustrial: boolean;
  dockHighDoors: number | null;
  fireSprinklers: string | null;
  gradeLevelDoors: number | null;
  minimumIndoorClearHeight: ConvertableLength | null;
  driveInDoors: number | null;
  percentOffice: number | null;
  parkingSpaces: number | null;
};

export type BuildingInTourbookListing = {
  id: string;
  neighborhood: string | null;
  slug: string;
};

export type RoomAmenities = {
  deskCount?: number | null;
  electricAdjustableDeskCount?: number | null;
  deskChairCount?: number | null;
  tableCount?: number | null;
  workbenchCount?: number | null;
  loungeChairCount?: number | null;
  stoolCount?: number | null;
  couchesCount?: number | null;
  conferenceTableCount?: number | null;
  storageClosetArea?: boolean | null;
  kitchenTableCount?: number | null;
  kitchenChairCount?: number | null;
  refrigeratorCount?: number | null;
  sinkCount?: number | null;
  dishwasherCount?: number | null;
  microwaveCount?: number | null;
  ovenCount?: number | null;
  stoveCount?: number | null;
  lockers?: boolean | null;
  poolTableCount?: number | null;
  airHockeyTableCount?: number | null;
  pingPongTableCount?: number | null;
  vendingMachineCount?: number | null;
  bathroomType?: 'stalls' | 'unisex_with_sink' | 'unisex_shared_sink' | null;
  bathroomSinkCount?: number | null;
  bathroomStallCount?: number | null;
};

export type Room = {
  id: string;
  name: string;
  photos: MultipathImage[];
  capacity: string | null;
  amenities: RoomAmenities;
  roomType:
    | 'balcony'
    | 'bathroom'
    | 'breakout_area'
    | 'call_room'
    | 'copy_room'
    | 'game_room_lounge'
    | 'kitchen'
    | 'main_area'
    | 'reception_area'
    | 'room'
    | 'server_room'
    | 'storage_room'
    | null;
  maximumCapacity: number | null;
  worksAsOffice: boolean | null;
  ceilingStyle:
    | 'drop_ceiling'
    | 'drywall_plaster'
    | 'exposed'
    | 'finished'
    | 'other_ceiling_type'
    | null;
  ceilingHeight: UnitAndMagnitudeMeasure;
  columnCount: number | null;
  walls: 'drywall' | 'exposed_brick_painted' | 'exposed_brick_raw' | 'plaster' | null;
  floorFinish:
    | 'drop_ceiling'
    | 'carpet'
    | 'hardwood'
    | 'high_traffic_carpet'
    | 'polished_concrete'
    | 'tile'
    | 'vinyl_laminate'
    | null;
  functionalLighting:
    | 'fluorescent_lighting'
    | 'led_lighting'
    | 'led_lighting_fluorescent_lighting'
    | 'spot_lighting'
    | null;
  decorativeLighting: 'industrial_lighting' | 'modern_lighting' | null;
  electric: 'in_ceiling' | 'in_counter' | 'in_floor' | 'in_pillars' | 'in_wall' | null;
  heatType: 'electric' | 'exposed_radiators' | 'forced_air' | 'radiant_flooring' | null;
  ventilation: boolean | null;
  hvac: boolean | null;
  airConditioning: boolean | null;
  overtimeAc: boolean | null;
  independentTemperatureControl: boolean | null;
  windowCount: number | null;
  windowTreatments: 'shades' | 'curtains' | 'blinds' | 'no_window_treatments' | null;
  windowHeight: UnitAndMagnitudeMeasure;
  windowSillHeight: UnitAndMagnitudeMeasure;
  windowSides: number | null;
  lightScore:
    | 'high_light'
    | 'low_light'
    | 'medium_light'
    | 'no_light_score'
    | 'outdoor_light'
    | null;
  windowsOpen: boolean | null;
  atrium: boolean | null;
  viewObstructions: boolean | null;
  vendingMachineCount: number | null;
  pingPongTableCount: number | null;
  poolTableCount: number | null;
  airHockeyTableCount: number | null;
  storageClosetArea: boolean | null;
  lockers: boolean | null;
  bathroomType: 'unisex_shared_sink' | 'unisex_with_sink' | 'stalls' | null;
  bathroomSinkCount: number | null;
  bathroomStallCount: number | null;
  refrigeratorCount: number | null;
  dishwasherCount: number | null;
  kitchenSinkCount: number | null;
  microwaveCount: number | null;
  ovenCount: number | null;
  stoveCount: number | null;
  beverageDispenser: 'beer' | 'coffee' | 'water' | 'wine' | null;
  kitchenTableCount: number | null;
  kitchenChairCount: number | null;
  deskCount: number | null;
  deskStyle: 'industrial_desk' | 'modern_desk' | 'no_desk_style' | 'traditional_desk' | null;
  deskStorage: boolean | null;
  deskElectric: boolean | null;
  deskWired: boolean | null;
  electricAdjustableDeskCount: number | null;
  deskChairCount: number | null;
  tableCount: number | null;
  tableStyle: 'industrial_table' | 'modern_table' | 'no_table_style' | 'traditional_table' | null;
  tableShape:
    | 'circular_table'
    | 'other_table_shape'
    | 'oval_table'
    | 'rectangular_table'
    | 'square_table'
    | null;
  tableElectric: boolean | null;
  tableWired: boolean | null;
  conferenceTableCount: number | null;
  couchesCount: number | null;
  loungeChairCount: number | null;
  stoolCount: number | null;
  workbenchCount: number | null;
};

export type AdminRoom = Room & { roomType: string };

export type ListingResponse = { listing: Listing; lastInquiry: LastInquiry };

export type LastInquiry = {
  listingId: string;
  lastSentAt: string;
  receiverNames: string[];
};

export type Listing = {
  /** Address for display purposes */
  address: Address;
  amenities: ListingAmenities;
  analyticsInformation: ListingAnalyticsInformation;
  area: Area | null;
  availabilityType: AvailabilityType | null;
  availableToday: boolean;
  baseRents: Rent[];
  brochures: Brochure[];
  building: BuildingInListing;
  buildingName: string;
  buildingSlug: string;
  capacity: number | null;
  condition: SpaceCondition | null;
  /** url that returns a link to the gallery images in a zip and lasts 1 hour,
   * null if there are no images
   */
  createImageZipUrl: string | null;
  dateAvailable: string | null;
  daysOnMarket: number | null;
  description: string | null;
  descriptionHtml: string | null;
  finishedCeilingHeight: string | null;
  finishedCeilingHeightMeasure: UnitAndMagnitudeMeasure | null;
  floorName: string | null;
  /** whether or not the listing should hide its location when displaying itself */
  hideLocation: boolean;
  id: string;
  isCurrentlyExclusive: boolean;
  leaseType: LeaseType | null;
  listingAgents: AgentContact[];
  listingSharer: AgentContact | null;
  /** Address for displaying on the map */
  locationAddress: Address;
  marketName: string | null;
  marketShapeSlug: string | null;
  marketSlug: MarketSlug | null;
  minMaxArea: MinMaxArea | null;
  name: string;
  photos: Array<MultipathImage>;
  rooms: Room[];
  slabToSlabCeilingHeight: string | null;
  slabToSlabCeilingHeightMeasure: UnitAndMagnitudeMeasure | null;
  spaceProvider: SpaceProvider | null;
  status: ListingStatus;
  suiteName: string | null;
  tourEmbedUrl: string | null;
  video: Video | null;
  /** what shows up in the virtual tour section of the listing page */
  virtualTourSpotlight: VirtualTourSpotlights;
  vtsSpaceId: number;
};

export type ListingAnalyticsInformation = {
  truvaListingId: string;
  buildingName: string;
  landlordName: string | null;
  listingName: string;
  listingStatus: ListingStatus;
  listingArea: Area | null;
  listingMinMaxArea: MinMaxArea | null;
  isExclusiveListing: boolean;
};

/**
 * Representation of the Listing for ListingCards
 */
export type SearchListing = {
  analyticsInformation: ListingAnalyticsInformation;
  address: Address;
  availableToday: boolean;
  baseRents: Array<Rent>;
  brochures: Brochure[];
  buildingId: string;
  buildingSlug: string;
  capacity: number | null;
  dateAvailable: string | null;
  floorName: string | null;
  floorPlan: string | null;
  furnished: boolean;
  id: string;
  isCurrentlyExclusive: boolean;
  minMaxArea: MinMaxArea | null;
  name: string;
  neighborhoodName: string | null;
  photo: MultipathImage;
  landlordLogo: MultipathImage | null;
  landlordName: string;
  leaseType: LeaseType | null;
  shortestLeaseTerm: string | null;
  size: ConvertableArea | null;
  smallestPrice: Rent | null;
  spaceCondition: SpaceCondition | null;
  status: ListingStatus;
  suiteName: string | null;
  hasContacts: boolean;
};

type PartialListingKeys =
  | 'availableToday'
  | 'buildingSlug'
  | 'capacity'
  | 'dateAvailable'
  | 'id'
  | 'isCurrentlyExclusive'
  | 'leaseType'
  | 'minMaxArea'
  | 'name'
  | 'neighborhoodName'
  | 'photo'
  | 'size'
  | 'smallestPrice'
  | 'spaceCondition'
  | 'address';

export type PartialListing = Pick<SearchListing, PartialListingKeys>;

export type PriceRangeFilter = {
  minPrice: string;
  maxPrice: string;
  excludeNegotiable?: boolean;
};

export type SizeRangeFilter = { minSize: string; maxSize: string };

export type CeilingHeightMeasurementType = 'slabToSlab' | 'finished';
export type CeilingHeight = {
  type: CeilingHeightMeasurementType;
  magnitude: number | null;
};

export type UnitAndMagnitudeMeasure = {
  unit: LengthUnit;
  magnitude: number;
};

export type LeaseTypes = 'direct' | 'flex' | 'sublease';

export type TermValues = '<1' | '1-3' | '3-5' | '5-10' | '10-15';

export type ListingDataCollectionMetadata = {
  ceilingType: string | null;
  ceilingTypeDataSource: string | null;
  currentSpaceCondition: string | null;
  currentSpaceConditionDataSource: string | null;
  exposedColumns: boolean | null;
  exposedColumnsDataSource: string | null;
  exposureEast: boolean | null;
  exposureEastDataSource: string | null;
  exposureNorth: boolean | null;
  exposureNorthDataSource: string | null;
  exposureSouth: boolean | null;
  exposureSouthDataSource: string | null;
  exposureWest: boolean | null;
  exposureWestDataSource: string | null;
  floorType: string | null;
  floorTypeDataSource: string | null;
  gym: boolean | null;
  gymDataSource: string | null;
  mainPantryKitchen: string | null;
  mainPantryKitchenDataSource: string | null;
  officeFronts: string | null;
  officeFrontsDataSource: string | null;
  officeLayout: string | null;
  officeLayoutDataSource: string | null;
  privateBathroomsShower: boolean | null;
  privateBathroomsShowerDataSource: string | null;
  receptionCeilingType: string | null;
  receptionCeilingTypeDataSource: string | null;
  receptionFloorType: string | null;
  receptionFloorTypeDataSource: string | null;
  viewLandmarkDetail: string | null;
  viewLandmarkDetailDataSource: string | null;
  windowSidesCount: number | null;
  windowSidesCountDataSource: string | null;
  windowViews: string[] | null;
  windowViewsDataSource: string | null;
};

export type ListingAmenitiesMetadata = {
  breakOutSpace: boolean | null;
  breakOutSpaceDataSource: string | null;
  furnished: string | null;
  furnishedDataSource: string | null;
  keyCardSpaceEntry: boolean | null;
  keyCardSpaceEntryDataSource: string | null;
  passengerElevatorCount: number | null;
  passengerElevatorCountDataSource: string | null;
  phoneBooths: boolean | null;
  phoneBoothsDataSource: string | null;
  privateOutdoorSpace: boolean | null;
  privateOutdoorSpaceDataSource: string | null;
  serviceElevatorCount: number | null;
  serviceElevatorCountDataSource: string | null;
  sharedPantry: boolean | null;
  sharedPantryDataSource: string | null;
};

export type ListingAmenitySet = ListingAmenitiesMetadata & ListingDataCollectionMetadata;

export type AdminListing = {
  amenitySet: ListingAmenitySet;
  baseRents: Rent[];
  buildingCeilingHeightUnits: string | null;
  contentShootRequest: ContentShootRequest | null;
  current: boolean;
  currentListingId: string | null;
  isListingPriceVisible?: boolean;
  exclusive: boolean;
  exclusiveUntil: string | null;
  finishedCeilingHeight: number | null;
  finishedCeilingHeightUnit: LengthUnit;
  finishedCeilingHeightMagnitude: number | null;
  finishedCeilingHeightMeasure: ConvertableLength | null;
  floor: string | null;
  floorPlan: AdminFloorPlan | null;
  archivedFloorPlans: AdminFloorPlan[];
  id: string;
  isPreviewable: boolean;
  leaseType: LeaseType;
  listingAgents: AgentContact[];
  maximumCapacityPersons: number | null;
  notMarketable: boolean | null;
  offMarketAt: string | null;
  onMarketAt: string | null;
  usingBuildingContacts: boolean;
  photos: AdminListingPhoto[];
  archivedPhotos: AdminListingPhoto[];
  posterStartOffset: number | null;
  publicPreview: boolean;
  requestedAt: string;
  rooms: AdminRoom[];
  slabToSlabHeight: number | null;
  slabToSlabHeightUnit: LengthUnit;
  slabToSlabHeightMagnitude: number | null;
  slabToSlabHeightMeasure: ConvertableLength | null;
  status: ListingStatus;
  suiteType: SuiteType | null;
  suite: string | null;
  tourEmbedUrl: string | null;
  url: string | null;
  video: AdminListingVideo | null;
  archivedVideos: AdminListingVideo[];
  virtualTourSpotlight: VirtualTourSpotlights;
  building: {
    id: string;
    primaryName: string | null;
    secondaryName: string | null;
    displayAddress: string | null;
    name: string | null;
    formattedAddress: string;
    status: BuildingStatus;
    locality: string | null;
    abbreviatedRegion: string | null;
    brochure: AdminBrochure | null;
    currency: Currency | null;
    units: Units | null;
    demo: boolean;
  };
  space: {
    vtsId: number;
    vtsPropertyId: number;
    suite: string | null;
    floorName: string;
    maximumContiguousArea: Area | null;
    remeasuredArea: Area | null;
    floorComposition: string | null;
    availabilityDate: string | null;
    availabilityType: AvailabilityType | null;
    condition: SpaceCondition | null;
    floorPlanPhotos: SpaceFloorPlanPhoto[];
    additionalPhotos: SpaceAdditionalPhoto[];
    brochure: AdminBrochure | null;
    descriptionHtml: string | null;
    integrationEnabledForDescription: boolean;
  };
};

export type AdminListingSpace = {
  key: string;
  vtsId: string | null;
  vtsPropertyId: number | null;
  vtsPropertyName: string | null;
  streetAddress: string | null;
  floorName: string | null;
  suite: string | null;
  remeasuredArea: string | null;
  availabilityType: string | null;
  condition: string | null;
  floorComposition: string | null;
};

export type AdminListingPhoto = {
  id: string;
  position: number;
  status: MediaStatus;
  filename: string;
  processedAt: DateString | null;
  path: string;
  downloadPath: string;
  roomType: string | null;
  roomId: string | null;
  listingRoomId: string | null;
};

export type AdminListingVideo = {
  filename: string;
  id: string;
  path: string;
  downloadPath: string | null;
  posterStartOffset: number | null;
  processedAt: DateString;
  status: MediaStatus;
  thumbnailPosterPath: string;
};

export type AdminFloorPlan = {
  id: string;
  filename: string;
  processedAt: DateString;
  path: string;
  downloadPath: string;
};

/** Represents External Listings, this version is for creation and updating
 * See: app/serializers/external_listing_serializer.rb
 */
export type ExternalListing = {
  id: string;
  buildingName: string | null;
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  state: string;
  zipCode: string;
  longitude: number | null;
  latitude: number | null;
  countryCode: CountryCode;
  photos: MultipathImage[]; // no static maps so always a cloudinary id
  size: ConvertableArea;
  askingRentMagnitude: number | null;
  askingRentUnitsWithoutCurrency: 'Mo' | 'sf/yr';
  minAskingRentMagnitude: number | null;
  maxAskingRentMagnitude: number | null;
  condition: SpaceCondition | null;
  leaseType: LeaseType;
  description: string | null;
  availabilityDate: string | null;
  availableImmediately: boolean;
};

export type TourbookListingAnalyticsInformation =
  | ListingAnalyticsInformation
  | ExternalListingAnalyticsInformation;

type CommonTourbookListingAttributes = {
  id: string;
  address: Address;
  analyticsInformation: TourbookListingAnalyticsInformation;
  askingRent: ConvertableMoneyPerAreaPerPeriod | null;
  askingRentInMoneyPerAreaPerPeriod: ConvertableMoneyPerAreaPerPeriod | null;
  askingRentInMoneyPerPeriod: ConvertableMoneyPerPeriod | null;
  askingRentUnits: 'Usd/Mo' | 'Usd/sf/yr' | null;
  availableToday: boolean;
  building: BuildingInTourbookListing;
  condition: SpaceCondition | null;
  createImageZipUrl: string | null;
  dateAvailable: string | null;
  description: string;
  descriptionHtml: string | null;
  flaggedAt: string | null;
  isCurrentlyExclusive: boolean;
  latitude: number | null;
  leaseType: LeaseType;
  longitude: number | null;
  maxAskingRentInMoneyPerAreaPerPeriod: ConvertableMoneyPerAreaPerPeriod | null;
  maxAskingRentInMoneyPerPeriod: ConvertableMoneyPerPeriod | null;
  minAskingRentInMoneyPerAreaPerPeriod: ConvertableMoneyPerAreaPerPeriod | null;
  minAskingRentInMoneyPerPeriod: ConvertableMoneyPerPeriod | null;
  minMaxArea: MinMaxArea | null;
  name: string;
  photos: MultipathImage[];
  size: ConvertableArea | null;
  smallestPrice: Rent | null;
  sortableId: string;
  status: ListingStatus;
  tourbookListingId: string | null;
  tourEmbedUrl: string | null;
  video: Video | null;
};

/** Represents a Listing in a tourbook if its from the Marketplace */
export type TourbookListingFromMarketplace = CommonTourbookListingAttributes & {
  type: 'listing';
  external: false;
  brochures: Brochure[];
};

export type ExternalListingAnalyticsInformation = {
  address: string;
  askingRent: string;
  askingRentRange: boolean;
  askingRentUnits: string;
  available: string;
  buildingName: string;
  city: string;
  condition: string;
  country: string;
  description: string;
  externalListingId: string;
  floorSuite: string;
  floorplan: boolean;
  isExternal: boolean;
  latitude: number;
  leaseType: string;
  listingSize: number;
  longitude: number;
  mediaCount: number;
  postalCode: string;
  stateRegion: string;
};

/** Represents a Listing in a tourbook if its created by the owner */
export type TourbookListingFromExternal = CommonTourbookListingAttributes & {
  type: 'externalListing';
  external: true;
  externalTourbookListingId: string;
};

/** Represents Listings in Tourbooks, can be external or not */
export type TourbookListing = TourbookListingFromMarketplace | TourbookListingFromExternal;

export type ContentShootRequest = {
  requestedAt: string;
  directions: string | null;
  additionalComments: string | null;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  availableForContentShoot: boolean;
  requester: {
    firstName: string;
    lastName: string;
    email: string;
  };
  firstToMarket: boolean | null;
};

export type SpaceFloorPlanPhoto = {
  filename: string;
  url: string;
  uploadedAt: string;
};

export type SpaceAdditionalPhoto = {
  filename: string;
  url: string;
  uploadedAt: string;
  description: string | null;
};

export type AdminBrochure = {
  name: string;
  url: string;
  uploadedAt: string | null;
};

export type CreateAskingRents = {
  askingRents: Record<number, number>;
  minimum: number;
  maximum: number;
  visibility: boolean;
};

export type InquiryFormFields = {
  receiverIds: string[];
  message: string;
};

export type QuickInquiryFormFields = { message: string; saveDefaultMessage: boolean };
