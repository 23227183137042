import dataSource from '@components/layouts/Admin/utils/amenitySetHelper';
import { BuildingAmenitiesMetadata, BuildingDataCollectionMetadata } from '@root/types';

export const formatAmenityPayload = (body): BuildingAmenitiesMetadata => ({
  barAvailableDataSource: dataSource(body, 'barAvailable'),
  barAvailable: body.barAvailable,
  barbershopDataSource: dataSource(body, 'barbershop'),
  barbershop: body.barbershop,
  bikeStorageAvailableDataSource: dataSource(body, 'bikeStorageAvailable'),
  bikeStorageAvailable: body.bikeStorageAvailable,
  cafeAvailableDataSource: dataSource(body, 'cafeAvailable'),
  cafeAvailable: body.cafeAvailable,
  carWashDataSource: dataSource(body, 'carWash'),
  carWash: body.carWash,
  cctvDataSource: dataSource(body, 'cctv'),
  cctv: body.cctv,
  commonAreaWifiDataSource: dataSource(body, 'commonAreaWifi'),
  commonAreaWifi: body.commonAreaWifi,
  communalLobbySpaceDataSource: dataSource(body, 'communalLobbySpace'),
  communalLobbySpace: body.communalLobbySpace,
  communalOutdoorSpaceDataSource: dataSource(body, 'communalOutdoorSpace'),
  communalOutdoorSpace: body.communalOutdoorSpace,
  communalRoofAccessDataSource: dataSource(body, 'communalRoofAccess'),
  communalRoofAccess: body.communalRoofAccess,
  conferenceCenterDataSource: dataSource(body, 'conferenceCenter'),
  conferenceCenter: body.conferenceCenter,
  controlledElevatorAccessDataSource: dataSource(body, 'controlledElevatorAccess'),
  controlledElevatorAccess: body.controlledElevatorAccess,
  coveredParkingDataSource: dataSource(body, 'coveredParking'),
  coveredParking: body.coveredParking,
  destinationDispatchElevatorsDataSource: dataSource(body, 'destinationDispatchElevators'),
  destinationDispatchElevators: body.destinationDispatchElevators,
  electricVehicleChargingStationsCountDataSource: dataSource(
    body,
    'electricVehicleChargingStationsCount',
  ),
  electricVehicleChargingStationsCount: body.electricVehicleChargingStationsCount,
  eventSpaceDataSource: dataSource(body, 'eventSpace'),
  eventSpace: body.eventSpace,
  flexSpaceAvailableDataSource: dataSource(body, 'flexSpaceAvailable'),
  flexSpaceAvailable: body.flexSpaceAvailable,
  foodServiceAvailableDataSource: dataSource(body, 'foodServiceAvailable'),
  foodServiceAvailable: body.foodServiceAvailable,
  generalStoreAvailableDataSource: dataSource(body, 'generalStoreAvailable'),
  generalStoreAvailable: body.generalStoreAvailable,
  gymAvailableDataSource: dataSource(body, 'gymAvailable'),
  gymAvailable: body.gymAvailable,
  keyCardBuildingEntryDataSource: dataSource(body, 'keyCardBuildingEntry'),
  keyCardBuildingEntry: body.keyCardBuildingEntry,
  leedCertificationDataSource: dataSource(body, 'leedCertification'),
  leedCertification: body.leedCertification,
  loadingDockDataSource: dataSource(body, 'loadingDock'),
  loadingDock: body.loadingDock,
  lobbyAttendantDataSource: dataSource(body, 'lobbyAttendant'),
  lobbyAttendant: body.lobbyAttendant,
  lobbyBeverageAvailableDataSource: dataSource(body, 'lobbyBeverageAvailable'),
  lobbyBeverageAvailable: body.lobbyBeverageAvailable,
  lobbyFoodAvailableDataSource: dataSource(body, 'lobbyFoodAvailable'),
  lobbyFoodAvailable: body.lobbyFoodAvailable,
  lockerRoomsDataSource: dataSource(body, 'lockerRooms'),
  lockerRooms: body.lockerRooms,
  messengerCenter: body.messengerCenter,
  messengerCenterDataSource: dataSource(body, 'messengerCenter'),
  networkingEvents: body.networkingEvents,
  networkingEventsDataSource: dataSource(body, 'networkingEvents'),
  optionToAddInternetServiceProvider: body.optionToAddInternetServiceProvider,
  optionToAddInternetServiceProviderDataSource: dataSource(
    body,
    'optionToAddInternetServiceProvider',
  ),
  parkingAvailable: body.parkingAvailable,
  parkingAvailableDataSource: dataSource(body, 'parkingAvailable'),
  parkingGarage: body.parkingGarage,
  parkingGarageDataSource: dataSource(body, 'parkingGarage'),
  passengerElevatorCount: body.passengerElevatorCount,
  passengerElevatorCountDataSource: dataSource(body, 'passengerElevatorCount'),
  petsAllowed: body.petsAllowed,
  petsAllowedDataSource: dataSource(body, 'petsAllowed'),
  pharmacyAvailable: body.pharmacyAvailable,
  pharmacyAvailableDataSource: dataSource(body, 'pharmacyAvailable'),
  propertyManagerOnSite: body.propertyManagerOnSite,
  propertyManagerOnSiteDataSource: dataSource(body, 'propertyManagerOnSite'),
  restaurantAvailable: body.restaurantAvailable,
  restaurantAvailableDataSource: dataSource(body, 'restaurantAvailable'),
  serviceElevatorCount: body.serviceElevatorCount,
  serviceElevatorCountDataSource: dataSource(body, 'serviceElevatorCount'),
  showers: body.showers,
  showersDataSource: dataSource(body, 'showers'),
  shuttle: body.shuttle,
  shuttleDataSource: dataSource(body, 'shuttle'),
  spaAvailable: body.spaAvailable,
  spaAvailableDataSource: dataSource(body, 'spaAvailable'),
  storageAvailable: body.storageAvailable,
  storageAvailableDataSource: dataSource(body, 'storageAvailable'),
  tenantLounge: body.tenantLounge,
  tenantLoungeDataSource: dataSource(body, 'tenantLounge'),
  tenantPortalApp: body.tenantPortalApp,
  tenantPortalAppDataSource: dataSource(body, 'tenantPortalApp'),
  touchFreeElevatorAccess: body.touchFreeElevatorAccess,
  touchFreeElevatorAccessDataSource: dataSource(body, 'touchFreeElevatorAccess'),
  touchlessTurnstileEntry: body.touchlessTurnstileEntry,
  touchlessTurnstileEntryDataSource: dataSource(body, 'touchlessTurnstileEntry'),
  transportationAccessible: body.transportationAccessible,
  transportationAccessibleDataSource: dataSource(body, 'transportationAccessible'),
  turnstiles: body.turnstiles,
  turnstilesDataSource: dataSource(body, 'turnstiles'),
  twentyFourSevenAccess: body.twentyFourSevenAccess,
  twentyFourSevenAccessDataSource: dataSource(body, 'twentyFourSevenAccess'),
  twentyFourSevenSecurity: body.twentyFourSevenSecurity,
  twentyFourSevenSecurityDataSource: dataSource(body, 'twentyFourSevenSecurity'),
  urgentCareDoctorOnsite: body.urgentCareDoctorOnsite,
  urgentCareDoctorOnsiteDataSource: dataSource(body, 'urgentCareDoctorOnsite'),
  valetParking: body.valetParking,
  valetParkingDataSource: dataSource(body, 'valetParking'),
  wiredCertification: body.wiredCertification,
  wiredCertificationDataSource: dataSource(body, 'wiredCertification'),
  yogaStudioAvailable: body.yogaStudioAvailable,
  yogaStudioAvailableDataSource: dataSource(body, 'yogaStudioAvailable'),
});

export const formatDataCollectionPayload = ({
  amenitySet: body,
  fiberProviders = null,
}: {
  amenitySet: any;
  fiberProviders?: Array<{ id: string }> | null;
}): BuildingDataCollectionMetadata => ({
  fiberProviders,
  architecturalClassification: body.architecturalClassification,
  architecturalClassificationDataSource: dataSource(body, 'architecturalClassification'),
  constructionType: body.constructionType,
  constructionTypeDataSource: dataSource(body, 'constructionType'),
  energyStarRated: body.energyStarRated,
  energyStarRatedDataSource: dataSource(body, 'energyStarRated'),
  entranceCount: body.entranceCount,
  entranceCountDataSource: dataSource(body, 'entranceCount'),
  flexSpaceOwnerOperated: body.flexSpaceOwnerOperated,
  flexSpaceOwnerOperatedDataSource: dataSource(body, 'flexSpaceOwnerOperated'),
  flexSpaceThirdParty: body.flexSpaceThirdParty,
  flexSpaceThirdPartyDataSource: dataSource(body, 'flexSpaceThirdParty'),
  hotelAttached: body.hotelAttached,
  hotelAttachedDataSource: dataSource(body, 'hotelAttached'),
  hvacFiltration: body.hvacFiltration,
  hvacFiltrationDataSource: dataSource(body, 'hvacFiltration'),
  landmarkBuilding: body.landmarkBuilding,
  landmarkBuildingDataSource: dataSource(body, 'landmarkBuilding'),
  lobbyAttendantAfterHours: body.lobbyAttendantAfterHours,
  lobbyAttendantAfterHoursDataSource: dataSource(body, 'lobbyAttendantAfterHours'),
  lobbyAttendantBusinessHours: body.lobbyAttendantBusinessHours,
  lobbyAttendantBusinessHoursDataSource: dataSource(body, 'lobbyAttendantBusinessHours'),
  mobileConnectivityImprovements: body.mobileConnectivityImprovements,
  mobileConnectivityImprovementsDataSource: dataSource(body, 'mobileConnectivityImprovements'),
  parking: body.parking,
  parkingDataSource: dataSource(body, 'parking'),
  parkingRatio: body.parkingRatio,
  parkingRatioDataSource: dataSource(body, 'parkingRatio'),
  passengerElevatorPosition: body.passengerElevatorPosition,
  passengerElevatorPositionDataSource: dataSource(body, 'passengerElevatorPosition'),
  passengerElevatorType: body.passengerElevatorType,
  passengerElevatorTypeDataSource: dataSource(body, 'passengerElevatorType'),
  rentableBuildingAreaOffice: body.rentableBuildingAreaOffice,
  rentableBuildingAreaOfficeDataSource: dataSource(body, 'rentableBuildingAreaOffice'),
  rentableBuildingAreaOther: body.rentableBuildingAreaOther,
  rentableBuildingAreaOtherDataSource: dataSource(body, 'rentableBuildingAreaOther'),
  rentableBuildingAreaRetail: body.rentableBuildingAreaRetail,
  rentableBuildingAreaRetailDataSource: dataSource(body, 'rentableBuildingAreaRetail'),
  restaurant: body.restaurant,
  restaurantDataSource: dataSource(body, 'restaurant'),
  storyClassification: body.storyClassification,
  storyClassificationDataSource: dataSource(body, 'storyClassification'),
  thirteenthFloor: body.thirteenthFloor,
  thirteenthFloorDataSource: dataSource(body, 'thirteenthFloor'),
  walkScore: body.walkScore,
  walkScoreDataSource: dataSource(body, 'walkScore'),
  wellScore: body.wellScore,
  wellScoreDataSource: dataSource(body, 'wellScore'),
});
