import { FastField, Field, useField, useFormikContext } from 'formik';
import { Locale, CountryCode } from '@root/types';
import RawPhoneNumberInput from '../RawPhoneNumberInput';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper';

type Props = {
  locale?: Locale;
  fast?: boolean;
  className?: string;
  inputClass?: string;
  description?: string;
  placeholder?: string;
  disabled?: boolean;
} & Omit<FieldWrapperProps, 'children'>;

type LocaleCountryMap = Record<Locale, Lowercase<CountryCode>>;
const localeCountryMapping: LocaleCountryMap = {
  'en-us': 'us',
  'en-gb': 'gb',
};

/**
 * When using a Formik-wrapped phone number input, you get the form value keyed by the name prop,
 * like a standard input (i.e. if you give the prop name as 'phone' the form will have
 * `{ phone: '12345123' }`), but also a field called phoneNumberObject will be added to the form
 * values. The value of phoneNumberObject is:
 * ```js
 * { country: string; dialCode: string; formatted: string; digits: string; }
 * ```
 * an example looks like this:
 * ```js
 * { country: 'us', dialCode: '1', formatted: '+1 (311) 525-1251', digits: '13115251251' }
 * ```
 */

export default function PhoneNumberInput({
  name,
  description,
  locale,
  labelClass,
  containerClass,
  inputClass,
  required,
  labelText,
  placeholder,
  disabled,
  fast = false,
}: Props) {
  const country = locale ? localeCountryMapping[locale] : 'us';
  const Component = fast ? FastField : Field;
  const [field, , helpers] = useField<string>(name);
  const { setFieldValue } = useFormikContext();

  return (
    <FieldWrapper {...{ description, name, labelClass, containerClass, labelText, required }}>
      <Component name={name}>
        {() => (
          <RawPhoneNumberInput
            {...field}
            placeholder={placeholder}
            country={country}
            disabled={disabled}
            onChange={(phone, countryInfo, _event, formattedValue) => {
              helpers.setValue(formattedValue);
              setFieldValue('phoneNumberObject', {
                country: countryInfo.countryCode,
                dialCode: countryInfo.dialCode,
                formatted: formattedValue,
                digits: phone,
              });
              helpers.setTouched(true);
            }}
            inputClass={inputClass}
          />
        )}
      </Component>
    </FieldWrapper>
  );
}
