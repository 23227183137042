import { useField } from 'formik';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { RawSelect, RawInput, RawLabel } from '..';
import { useFormTheme } from '../ThemeContext';
import s from '../forms.module.less';

type Props = {
  containerClass?: string;
  'data-testid'?: string;
  labelText?: ReactNode;
  required?: boolean;
  labelClass?: string;
  unitsDisabled?: boolean;
  magnitudeName: string;
  unitsName: string;
};

const LengthInput = ({
  containerClass,
  'data-testid': dataTestId,
  labelText,
  labelClass,
  required = false,
  magnitudeName,
  unitsName,
  unitsDisabled = true,
}: Props) => {
  const layout = useFormTheme();
  const [magnitudeField] = useField({ name: magnitudeName });
  const [unitsField] = useField({ name: unitsName });
  const { t } = useTranslation();

  return (
    <div
      className={cn(s.container, layout === 'horizontal' && s.horizontal, containerClass)}
      data-testid={dataTestId}
    >
      {labelText ? (
        <RawLabel
          className={cn(layout === 'horizontal' && s.horizontalLabel, labelClass)}
          required={required}
          horizontal={layout === 'horizontal'}
        >
          {labelText}
        </RawLabel>
      ) : null}
      <div className={s.unitInputs}>
        <RawInput
          name={magnitudeName}
          value={magnitudeField.value}
          onChange={magnitudeField.onChange}
          type="number"
        />

        <RawSelect
          name={unitsName}
          value={unitsField.value}
          onChange={unitsField.onChange}
          isDisabled={unitsDisabled}
          options={[
            { value: 'Inches', label: t('common:inches') },
            { value: 'Centimeters', label: t('common:centimeters') },
          ]}
        />
      </div>
    </div>
  );
};

export default LengthInput;
